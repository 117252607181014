import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import IconBack from 'assets/images/socials/icon__back.svg';
import Logo from 'assets/images/socials/others__logo.svg';
import { Link, useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import IconEmptyExercise from 'assets/images/icon__exercise-details-empty.svg';
import { useSelector } from 'react-redux';
import { PreparePath } from 'utils/sharedUtils/sharedConst';
import { AllIcon } from 'PREPAREsrc/pages/Settings/InjectMethod/Icon';

export const PostUKRNews = ({ data }) => {
  const navigate = useNavigate();

  const { workspaceName } = useSelector((state) => {
    return state?.prepare?.workspace;
  });

  const params = useParams();

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <>
      <div className="ukrnews">
        <div
          className="ukrnews__header"
          style={{
            background:
              sessionStorage.getItem('mediaModuleSocialMediaType') === 'sites'
                ? data?.siteColor
                  ? data?.siteColor
                  : ''
                : sessionStorage.getItem('mediaModuleSocialMediaType') ===
                  'profiles'
                ? !params?.postId
                  ? data?.sitedata?.color
                    ? data?.sitedata?.color
                    : ''
                  : data?.siteColor
                  ? data?.siteColor
                  : ''
                : null,
          }}
        >
          <StylishNewButton
            customButton
            className={'button--icon'}
            onClick={handleBack}
          >
            <img src={IconBack} alt="" />
          </StylishNewButton>
          <div className="logo bg-white rounded-5">
            {sessionStorage.getItem('mediaModuleSocialMediaType') ===
            'sites' ? (
              <>
                {!!data?.site_image ? (
                  <img
                    src={data?.site_image}
                    alt=""
                    className="me-3"
                    style={{
                      display: 'block',
                      height: '2.5rem',
                      width: '2.5rem',
                      borderRadius: '50%',
                    }}
                  />
                ) : null}
              </>
            ) : sessionStorage.getItem('mediaModuleSocialMediaType') ===
              'profiles' ? (
              <>
                {!params?.postId ? (
                  <>
                    {!!data?.sitedata?.site_image ? (
                      <img
                        src={data?.sitedata?.site_image}
                        alt=""
                        className="me-3"
                        style={{
                          display: 'block',
                          height: '2.5rem',
                          width: '2.5rem',
                          borderRadius: '50%',
                        }}
                      />
                    ) : null}
                  </>
                ) : (
                  <>
                    {!!data?.site_image ? (
                      <img
                        src={data?.site_image}
                        alt=""
                        className="me-3"
                        style={{
                          display: 'block',
                          height: '2.5rem',
                          width: '2.5rem',
                          borderRadius: '50%',
                        }}
                      />
                    ) : null}
                  </>
                )}
              </>
            ) : null}

            {sessionStorage.getItem('mediaModuleSocialMediaType') ===
            'sites' ? (
              <>
                {!!data?.siteIcon ? (
                  <>
                    <span
                      className="has-img-h-40 me-3"
                      style={{ color: data?.siteColor ? data?.siteColor : '' }}
                    >
                      {
                        AllIcon.find((icon) => icon.name === data.siteIcon)
                          ?.icon
                      }
                    </span>
                  </>
                ) : null}
              </>
            ) : sessionStorage.getItem('mediaModuleSocialMediaType') ===
              'profiles' ? (
              <>
                {!params?.postId ? (
                  <>
                    {!!data?.sitedata?.icon ? (
                      <>
                        <span
                          className="has-img-h-40 me-3"
                          style={{
                            color: data?.sitedata?.color
                              ? data?.sitedata?.color
                              : '',
                          }}
                        >
                          {
                            AllIcon.find(
                              (icon) => icon.name === data?.sitedata?.icon
                            )?.icon
                          }
                        </span>
                      </>
                    ) : null}
                  </>
                ) : (
                  <>
                    {!!data?.siteIcon ? (
                      <>
                        <span
                          className="has-img-h-40 me-3"
                          style={{
                            color: data?.siteColor ? data?.siteColor : '',
                          }}
                        >
                          {
                            AllIcon.find((icon) => icon.name === data?.siteIcon)
                              ?.icon
                          }
                        </span>
                      </>
                    ) : null}
                  </>
                )}
              </>
            ) : null}
            <h4 className="m-0">UKR News</h4>
          </div>
        </div>
        <div className="ukrnews__main">
          <div className="row justify-content-center">
            <div className="col-lg-10 col-xl-6">
              {sessionStorage.getItem('mediaModuleSocialMediaType') ===
              'sites' ? (
                <div className="p-4 p-md-5" style={{ background: '#F5F5F5' }}>
                  <h6 className="mb-1 text-center">
                    Posted By: {data?.profileName || 'Unknown'}
                  </h6>
                  {data?.postPublishTime ? (
                    <p className="mb-4 text-center">
                      {`${moment(data?.postPublishTime).fromNow()} at ${moment(
                        data?.postPublishTime
                      ).format('MMM Do YYYY HH:mm')} (${moment(
                        data?.postPublishTime
                      )
                        .tz(moment.tz.guess())
                        .format('Z')})`}
                    </p>
                  ) : null}
                  {data?.post ? <p className="m-0">{data?.post}</p> : null}
                  {data?.mediaFile ? (
                    <div className="d-flex mt-3">
                      <img
                        src={data?.mediaFile}
                        alt=""
                        style={{ width: '200px', height: 'auto' }}
                      />
                    </div>
                  ) : null}
                  {data?.comments && !!data?.comments.length ? (
                    <div className="mt-5">
                      {data?.comments.map((i, idx) =>
                        i?.status === 'drafted' ? null : (
                          <div key={i?.id}>
                            {idx !== 0 ? (
                              <hr className="dashed thin my-4" />
                            ) : null}
                            <div className="d-flex align-items-start">
                              <div className="avatar flex-shrink-0">
                                {i?.profileAvatar ? (
                                  <img src={i?.profileAvatar} alt="" />
                                ) : null}
                              </div>
                              <div className="ms-3">
                                {i?.profileName ? (
                                  <h6 className="mb-2">{i?.profileName}</h6>
                                ) : null}
                                {i?.comment ? (
                                  <p className="m-0">{i?.comment}</p>
                                ) : null}
                                {i?.commentImg ? (
                                  <div className="d-flex mt-3">
                                    <img
                                      src={i?.commentImg}
                                      alt=""
                                      style={{ width: '100px', height: 'auto' }}
                                    />
                                  </div>
                                ) : null}
                                <p className="m-0 mt-3 weight-500 d-flex align-items-md-center flex-column flex-md-row txt--sml">
                                  {i?.publishTime ? (
                                    <>{moment(i?.publishTime).fromNow()}</>
                                  ) : null}
                                  <span className="mt-2 mt-md-0 ms-md-4">
                                    {i?.likes || '0'} Likes
                                  </span>
                                  <span className="mt-2 mt-md-0 ms-md-4">
                                    Reply
                                  </span>
                                </p>
                              </div>
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  ) : null}
                </div>
              ) : null}
              {sessionStorage.getItem('mediaModuleSocialMediaType') ===
              'profiles' ? (
                <>
                  {!params?.postId ? (
                    <>
                      {data?.profiledata?.posts &&
                      !!data?.profiledata?.posts?.length ? (
                        <>
                          {data?.profiledata?.posts.map((i, idx) => (
                            <div key={i.id}>
                              {idx !== 0 ? (
                                <hr className="dashed my-5" />
                              ) : null}
                              <div
                                className="p-4 p-md-5 position-relative"
                                style={{ background: '#F5F5F5' }}
                              >
                                <Link
                                  className="stretched-link"
                                  to={`${PreparePath}/dashboard/media/profiles/${
                                    workspaceName &&
                                    workspaceName.charAt(0).toUpperCase()
                                  }/${data?.sitedata?.slug}/${
                                    data?.profiledata?.username
                                  }/${i?.id}`}
                                ></Link>
                                <h6 className="mb-1 text-center">
                                  Posted By:{' '}
                                  {data?.profiledata?.profileName || 'Unknown'}
                                </h6>
                                {i?.publishTime ? (
                                  <p className="mb-4 text-center">
                                    {`${moment(
                                      i?.publishTime
                                    ).fromNow()} at ${moment(
                                      i?.publishTime
                                    ).format('MMM Do YYYY HH:mm')} (${moment(
                                      i?.publishTime
                                    )
                                      .tz(moment.tz.guess())
                                      .format('Z')})`}
                                  </p>
                                ) : null}
                                {i?.post ? (
                                  <p className="m-0">{i?.post}</p>
                                ) : null}
                                {i?.mediaFile ? (
                                  <div className="d-flex mt-3">
                                    <img
                                      src={i?.mediaFile}
                                      alt=""
                                      style={{ width: '200px', height: 'auto' }}
                                    />
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          ))}
                        </>
                      ) : (
                        <div className="text-center p-5">
                          <div className="d-flex justify-content-center mb-4">
                            <img
                              src={IconEmptyExercise}
                              alt=""
                              style={{ opacity: '0.5' }}
                            />
                          </div>
                          <p className="weight-600 mb-4">No Post Found!</p>
                        </div>
                      )}
                    </>
                  ) : (
                    <div
                      className="p-4 p-md-5"
                      style={{ background: '#F5F5F5' }}
                    >
                      <h6 className="mb-1 text-center">
                        Posted By: {data?.profileName || 'Unknown'}
                      </h6>
                      {data?.postPublishTime ? (
                        <p className="mb-4 text-center">
                          {`${moment(
                            data?.postPublishTime
                          ).fromNow()} at ${moment(
                            data?.postPublishTime
                          ).format('MMM Do YYYY HH:mm')} (${moment(
                            data?.postPublishTime
                          )
                            .tz(moment.tz.guess())
                            .format('Z')})`}
                        </p>
                      ) : null}
                      {data?.post ? <p className="m-0">{data?.post}</p> : null}
                      {data?.mediaFile ? (
                        <div className="d-flex mt-3">
                          <img
                            src={data?.mediaFile}
                            alt=""
                            style={{ width: '200px', height: 'auto' }}
                          />
                        </div>
                      ) : null}
                      {data?.comments && !!data?.comments.length ? (
                        <div className="mt-5">
                          {data?.comments.map((i, idx) => (
                            <div key={i?.id}>
                              {idx !== 0 ? (
                                <hr className="dashed thin my-4" />
                              ) : null}
                              <div className="d-flex align-items-start">
                                <div className="avatar flex-shrink-0">
                                  {i?.profileAvatar ? (
                                    <img src={i?.profileAvatar} alt="" />
                                  ) : null}
                                </div>
                                <div className="ms-3">
                                  {i?.profileName ? (
                                    <h6 className="mb-2">{i?.profileName}</h6>
                                  ) : null}
                                  {i?.comment ? (
                                    <p className="m-0">{i?.comment}</p>
                                  ) : null}
                                  {i?.commentImg ? (
                                    <div className="d-flex mt-3">
                                      <img
                                        src={i?.commentImg}
                                        alt=""
                                        style={{
                                          width: '100px',
                                          height: 'auto',
                                        }}
                                      />
                                    </div>
                                  ) : null}
                                  <p className="m-0 mt-3 weight-500 d-flex align-items-md-center flex-column flex-md-row txt--sml">
                                    {i?.publishTime ? (
                                      <>{moment(i?.publishTime).fromNow()}</>
                                    ) : null}
                                    <span className="mt-2 mt-md-0 ms-md-4">
                                      {i?.likes || '0'} Likes
                                    </span>
                                    <span className="mt-2 mt-md-0 ms-md-4">
                                      Reply
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      ) : null}
                    </div>
                  )}
                </>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
