import Network from 'PREPAREsrc/service/Network';
import API from 'PREPAREsrc/service/api';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import React, { useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { endLoading, startLoading } from 'reducers/loading/loading.action';
import { PreparePath } from 'utils/sharedUtils/sharedConst';
import IconEdit from 'assets/images/icon__edit.svg';
import IconDelete from 'assets/images/icon__delete.svg';
import IconView from 'assets/images/icon__eye.svg';
import StylishNewTableAction from 'components/DesignSystems/New/StylishNewTableAction';
import moment from 'moment';
import { StylishConfirmDialog } from 'components/DesignSystems/New/StylishConfirmDialog';
import { AllIcon } from 'assets/Icon/Icon';
import StylishNewTable from 'components/DesignSystems/New/StylishNewTable';
import IconDownload from 'assets/images/icon__download.svg';
import fileDownload from 'js-file-download';

export const MissionView = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { selectedWorkspace: workspaceId, type: workspaceType } = useSelector(
    (state) => state?.prepare?.workspace
  );

  const [mission, setMission] = useState();
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [toBeDeletedMission, setToBeDeletedMission] = useState();
  const [missionTimelines, setMissionTimelines] = useState();
  const [deleteTimelineId, setDeleteTimelineId] = useState();
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);

  const perPage = 10;

  useEffect(() => {
    if (workspaceType && workspaceType !== 'T&E')
      navigate(`${PreparePath}/dashboard/plan`);
  }, [workspaceType, workspaceId]);

  const fetchMission = async () => {
    try {
      dispatch(startLoading());
      const response = await Network.get(API.fetchMissionById, {
        id,
        workspaceId,
      });
      if (response !== undefined && response?.status === 200) {
        setMission(response?.data?.response?.dataset[0]);
        dispatch(endLoading());
      }
    } catch (error) {
      console.error(error);
      dispatch(endLoading());
    }
  };

  useEffect(() => {
    if (id) {
      fetchMission();
    }
  }, [workspaceType, workspaceId, id]);

  const fetchAllMissionTimeline = async () => {
    try {
      dispatch(startLoading());
      const response = await Network.get(API.fetchAllMissionTimeline, {
        workspaceId,
        limit: perPage,
        page,
        mission_id: id,
      });
      if (response !== undefined && response?.status === 200) {
        setMissionTimelines(response?.data?.response?.dataset);
        setTotal(response?.data?.response?.total);
        dispatch(endLoading());
      }
    } catch (error) {
      console.error(error);
      dispatch(endLoading());
    }
  };

  useEffect(() => {
    if (id) {
      fetchAllMissionTimeline();
    }
  }, [workspaceType, workspaceId, id, page]);

  const onDownload = async (file) => {
    let fullFileName = file.split('/')[file.split('/').length - 1];
    try {
      dispatch(startLoading());
      const downloadFile = await Network.post(API.generateSignedUrl, {
        url: file,
      });
      const res = await fetch(downloadFile.data.response.singedUrl, {
        headers: {},
      });

      fileDownload(
        await res.blob(),
        fullFileName.split('-')[fullFileName.split('-').length - 1]
      );
    } catch (error) {
      console.log('Error: ', error);
      toast.error('Failed to download file', toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };

  const columns = [
    {
      dataField: 'name',
      text: 'Name',
      attrs: { title: 'Name' },
    },
    {
      dataField: 'mission_name',
      text: 'Mission',
      attrs: { title: 'Mission' },
    },
    {
      dataField: 'start_date_time',
      isDummyField: true,
      text: 'Start Date/Time',
      attrs: { title: 'Start Date/Time' },
      formatter: (_, row) =>
        moment(row?.start_date).format('DD MMM YY - hh:mm a'),
    },
    {
      dataField: 'status',
      isDummyField: true,
      text: 'Status',
      attrs: { title: 'Status' },
      formatter: (_, row) => (
        <span
          className="status status--sml"
          style={{
            textTransform: 'capitalize',
            background: row?.status_color,
          }}
        >
          {row?.status_title}
        </span>
      ),
    },
    {
      dataField: 'profiles',
      isDummyField: true,
      text: 'Profile(s)',
      attrs: { title: 'Profile(s)' },
      formatter: (_, row) =>
        row?.profiles?.map((i, idx) => i?.name)?.join(', '),
    },
    {
      dataField: 'event_cell',
      isDummyField: true,
      text: 'Event Cell(s)',
      attrs: { title: 'Event Cell(s)' },
      formatter: (_, row) =>
        row?.cells?.map((i, idx) => (
          <span
            style={{
              textTransform: 'capitalize',
            }}
            key={idx}
          >
            {`${idx !== 0 ? ', ' : ''}${i?.name}`}
          </span>
        )),
    },
    {
      dataField: 'notification',
      isDummyField: true,
      text: 'Notification',
      attrs: { title: 'Notification' },
      formatter: (_, row) => (
        <div className="d-flex align-items-center">
          <span
            style={{
              background: row?.notification_method_color,
              width: '1.5rem',
              height: '1.5rem',
            }}
            className="rounded-5 me-2 d-flex align-items-center justify-content-center has-img-h-16"
          >
            {
              AllIcon.find(
                (icon) => icon.name === row?.notification_method_icon
              )?.icon
            }
          </span>
          {row?.notification_method_name}
        </div>
      ),
    },
    {
      dataField: 'last_updated',
      isDummyField: true,
      text: 'Last Updated',
      attrs: { title: 'Last Updated' },
      formatter: (_, row) => moment(row?.updatedAt).fromNow(),
    },
    {
      dataField: 'action',
      isDummyField: true,
      text: 'Action',
      formatter: (cell, row, rowIndex) => (
        <StylishNewTableAction
          name="Timeline"
          cell={cell}
          row={row}
          rowIndex={rowIndex}
          onDelete={(row) => {
            setDeleteTimelineId(row?.id);
            setShowDeleteConfirmation(true);
          }}
          onEdit={() =>
            navigate(
              `${PreparePath}/dashboard/plan/missions/timeline/${row?.id}?page=1`
            )
          }
          onView={() =>
            navigate(
              `${PreparePath}/dashboard/plan/missions/timeline/view/${row?.id}`
            )
          }
        />
      ),
      headerStyle: {
        width: '100px',
      },
      headerAlign: 'center',
      align: 'center',
      attrs: { title: 'Action' },
    },
  ];

  function getFilenameFromUrl(url) {
    const parts = url.split('/');
    const filename = parts[parts.length - 1];
    const prefix = filename.split('-')[0];

    const filteredName = filename.replace(`${prefix}-`, '');

    return filteredName;
  }

  const expandRow = {
    parentClassName: 'parent-expand-tr',
    className: 'child-expand-tr',
    renderer: (row) => {
      return (
        <>
          <div className="row">
            <div className="col-md-6 mb-4">
              <span className="weight-600 d-block mb-1">
                Primary Action Officer
              </span>
              {row?.primary_action_officer || '-'}
            </div>
            <div className="col-md-6 mb-4">
              <span className="weight-600 d-block mb-1">Vignette</span>
              {row?.vignette ? (
                <span
                  className="d-flex align-items-center"
                  onClick={() => onDownload(row?.vignette)}
                >
                  <span className="color-brand">
                    {row?.vignette &&
                      row?.vignette
                        ?.split('/')
                        .pop()
                        .replace(/%20/g, ' ')
                        .split('-')
                        .slice(1)
                        .join('-')}
                  </span>
                  <span className="ms-3 cursor-pointer">
                    <img src={IconDownload} alt="" />
                  </span>
                </span>
              ) : (
                'No Vignette Attached'
              )}
            </div>
            <div className="col-md-12 mb-4">
              <span className="weight-600 d-block mb-1">Remarks</span>
              {row?.remarks || '-'}
            </div>
            <div className="col-md-12 mb-4">
              <span className="weight-600 d-block mb-2">Assigned Systems</span>
              <table className="table no-filter">
                <thead>
                  <tr className="empty">
                    <th>System</th>
                    <th>Component</th>
                    <th>Platform Type</th>
                    <th>MSN Quantity</th>
                    <th>Cell</th>
                    <th>Location/Launch Point</th>
                  </tr>
                </thead>
                <tbody>
                  {(!!row?.systems?.length && (
                    <>
                      {row?.systems?.map((i) => (
                        <tr key={i?.mission_systems_id}>
                          <td title="System">{i?.system_name || '-'}</td>
                          <td title="Component">
                            {i?.system_component
                              ?.map((data) => data.system_component_name)
                              ?.join(', ') || '-'}
                          </td>
                          <td title="Platform Type">
                            {i?.system_platform_type || '-'}
                          </td>
                          <td title="MSN Quantity">
                            {i?.system_msn_quantity || '-'}
                          </td>
                          <td title="Cell">
                            {i?.system_event_cell_type === 'red_cell'
                              ? 'Red Cell'
                              : i?.system_event_cell_type === 'blue_cell'
                              ? 'Blue Cell'
                              : '-'}
                          </td>
                          <td title="Location/Launch Point">
                            {i?.location_name || '-'}
                          </td>
                        </tr>
                      ))}
                    </>
                  )) || (
                    <tr className="empty">
                      <td
                        className="text-center weight-600 react-bs-table-no-data"
                        colSpan={'6'}
                      >
                        No Data Found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="col-md-12 mb-4">
              <span className="weight-600 d-block mb-2">Test Objectives</span>
              <table className="table no-filter">
                <thead>
                  <tr className="empty">
                    <th>COIC</th>
                    <th>Associations</th>
                  </tr>
                </thead>
                <tbody>
                  {(!!row?.test_objectives?.length && (
                    <>
                      {row?.test_objectives?.map((i) => (
                        <tr key={i?.id}>
                          <td title="COIC">
                            {i?.coic
                              ? `COIC #${i.coic} ${i?.description}`
                              : '-'}
                          </td>
                          <td title="Associations">
                            {i?.associations
                              ?.map(
                                (data) =>
                                  `${data.association_type} ${data.association_item} ${data.association_description}`
                              )
                              ?.join(', ') || '-'}
                          </td>
                        </tr>
                      ))}
                    </>
                  )) || (
                    <tr className="empty">
                      <td
                        className="text-center weight-600 react-bs-table-no-data"
                        colSpan={'2'}
                      >
                        No Data Found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="button-group">
              <StylishNewButton
                customButton
                className="button--icon primary"
                onClick={() =>
                  navigate(
                    `${PreparePath}/dashboard/plan/missions/timeline/view/${row?.id}`
                  )
                }
              >
                <img src={IconView} alt="" />
              </StylishNewButton>
              <StylishNewButton
                customButton
                className="button--icon primary"
                onClick={() =>
                  navigate(
                    `${PreparePath}/dashboard/plan/missions/timeline/${row?.id}?page=1`
                  )
                }
              >
                <img src={IconEdit} alt="" />
              </StylishNewButton>
              <StylishNewButton
                customButton
                className="button--icon primary"
                onClick={() => {
                  setDeleteTimelineId(row?.id);
                  setShowDeleteConfirmation(true);
                }}
              >
                <img src={IconDelete} alt="" />
              </StylishNewButton>
            </div>
          </div>
        </>
      );
    },
    showExpandColumn: true,
    expandByColumnOnly: true,
    expandHeaderColumnRenderer: ({ isAnyExpands }) => {
      if (isAnyExpands) {
        return <i className="fa fa-chevron-down" aria-hidden="true"></i>;
      }
      return <i className="fa fa-chevron-right" aria-hidden="true"></i>;
    },
    expandColumnRenderer: ({ expanded }) => {
      if (expanded) {
        return (
          <>
            <div className="d-flex align-items-center justify-content-md-center">
              <span className="me-2">Details</span>
              <i className="fa fa-chevron-down" aria-hidden="true"></i>
            </div>
          </>
        );
      }
      return (
        <>
          <div className="d-flex align-items-center justify-content-md-center">
            <span className="me-2">Details</span>
            <i className="fa fa-chevron-right" aria-hidden="true"></i>
          </div>
        </>
      );
    },
  };

  const handleDeleteMission = async () => {
    if (!toBeDeletedMission) return;
    try {
      dispatch(startLoading());
      const response = await Network.post(API?.deleteMission, {
        id: toBeDeletedMission,
      });
      if (response?.status === 200 && response !== undefined) {
        setShowDeleteConfirmation(false);
        setToBeDeletedMission();
        navigate(`${PreparePath}/dashboard/plan/missions`);
        dispatch(endLoading());
      }
    } catch (error) {
      console.error(error);
      dispatch(endLoading());
    }
  };

  const handleDeleteTimeline = async () => {
    dispatch(startLoading());
    try {
      const response = await Network.post(API.deleteMissionTimeline, {
        id: deleteTimelineId,
        workspaceId,
      });
      if (response?.status === 200 && response !== undefined) {
        setShowDeleteConfirmation(false);
        setDeleteTimelineId();
        fetchAllMissionTimeline();
      }
    } catch (error) {
      console.log(error);
      dispatch(endLoading());
    }
  };

  return (
    <>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <StylishNewButton
              onClick={() => navigate(`${PreparePath}/dashboard/plan/missions`)}
            >
              <i className="fa fa-angle-left me-2" aria-hidden="true"></i>
              Back
            </StylishNewButton>
          </li>
          <li className="breadcrumb-item">
            <StylishNewButton
              onClick={() => navigate(`${PreparePath}/dashboard/plan/missions`)}
            >
              Mission
            </StylishNewButton>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            {mission?.name}
          </li>
        </ol>
      </nav>
      <div className="d-flex align-items-center mb-4">
        <h4 className="d-flex align-items-start m-0 me-4">
          {mission?.name || ''}
        </h4>
        <Dropdown className="flex-shrink-0 ms-auto position-relative zIndex--1">
          <Dropdown.Toggle className="button--icon">
            <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
          </Dropdown.Toggle>
          <Dropdown.Menu align="end">
            <Link
              className="dropdown-item"
              to={`${PreparePath}/dashboard/plan/edit-mission/${mission?.id}`}
            >
              <img src={IconEdit} alt="" />
              Edit Mission
            </Link>
            <Dropdown.Item
              onClick={() => {
                setToBeDeletedMission(mission?.id);
                setShowDeleteConfirmation(true);
              }}
            >
              <img src={IconDelete} alt="" />
              Delete Mission
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div className="form-block mb-4">
        <div className="row">
          <div className="col-md-3 mb-4">
            <p className="m-0">
              <span className="weight-600 d-block mb-1">Start</span>
              {(mission?.start_date &&
                moment(mission?.start_date)?.format('DD MMM YY - hh:mm a')) ||
                '-'}
            </p>
          </div>
          <div className="col-md-3 mb-4">
            <p className="m-0">
              <span className="weight-600 d-block mb-1">End</span>
              {(mission?.end_date &&
                moment(mission?.end_date)?.format('DD MMM YY - hh:mm a')) ||
                '-'}
            </p>
          </div>
          <div className="col-md-3 mb-4">
            <p className="m-0">
              <span className="weight-600 d-block mb-1">Mission Type</span>
              {mission?.mission_type_name || '-'}
            </p>
          </div>
          <div className="col-md-3 mb-4">
            <p className="m-0">
              <span className="weight-600 d-block mb-1">
                Primary Action Officer
              </span>
              {mission?.primary_action_officer_name ||
                mission?.primary_action_officer_email ||
                '-'}
            </p>
          </div>
          <div className="col-md-12 col-xl-6 mb-4 mb-xl-0">
            <p className="m-0">
              <span className="weight-600 d-block mb-1">Mission Objective</span>
              {mission?.objective || '-'}
            </p>
          </div>
          <div className="col-md-12 col-xl-6">
            <p className="m-0">
              <span className="weight-600 d-block mb-1">Admin Remarks</span>
              {(mission?.admin_remarks && (
                <span
                  dangerouslySetInnerHTML={{
                    __html: mission?.admin_remarks || '-',
                  }}
                />
              )) ||
                '-'}
            </p>
          </div>
        </div>
      </div>
      <StylishNewButton
        className="button--primary w-100 w-md-auto mb-4"
        onClick={() =>
          navigate(
            `${PreparePath}/dashboard/plan/missions/timeline/create-timeline?missionId=${mission?.id}`
          )
        }
      >
        Add Timeline Event
      </StylishNewButton>
      <div className="form-block">
        <h5>Mission Timeline Events</h5>
        <StylishNewTable
          classes="no-filter"
          headerClasses="empty"
          keyField="id"
          columns={columns}
          rows={missionTimelines || []}
          expandRow={expandRow}
          pagination={true}
          total={total}
          page={page}
          perPage={perPage}
          onPageChange={setPage}
        />
      </div>
      {(showDeleteConfirmation && !!toBeDeletedMission && (
        <StylishConfirmDialog
          show={showDeleteConfirmation}
          onClose={() => {
            setToBeDeletedMission();
            setShowDeleteConfirmation(false);
          }}
          onConfirm={handleDeleteMission}
          dialogTitle={'Delete Confirmation'}
          dialogContent={'Are you sure you want to delete this mission?'}
        />
      )) ||
        null}

      {showDeleteConfirmation && !!deleteTimelineId && (
        <StylishConfirmDialog
          show={showDeleteConfirmation}
          onClose={() => {
            setDeleteTimelineId();
            setShowDeleteConfirmation(false);
          }}
          onConfirm={handleDeleteTimeline}
          dialogTitle={'Delete Confirmation'}
          dialogContent={'Are you sure you want to delete this timeline?'}
        />
      )}
    </>
  );
};
