import { Offcanvas, Row } from 'react-bootstrap';
import React, { useState } from 'react';
import { StylishNewButton } from '../DesignSystems/New/StylishNewButton';
import { FormProvider, useForm } from 'react-hook-form';
import { EditEntityDrawerProps } from './EntityListProps';
import DrawerWrapper, { DrawerFooter } from './DrawerWrapper';
import FormTextField from './formFields/FormTextField';
import FormTextAreaField from './formFields/FormTextAreaField';
import { ResourceSite, useUpdateResourceSite } from './hooks/resourceSiteHooks';
import { selectGroupGuid, useAppSelector } from '../../slices/commonSelectors';
import FormSelectField from './formFields/FormSelectField';
import GeoLocationComponent from 'components/GeoLocationComponent/GeoLocationComponent';
import { resource_site_types } from 'components/ICS/ICSConstants';

type FormFields = {
  name: string;
  description: string;
  geolocation?: string;
  type:
    | 'Staging Area'
    | 'Incident Command Post'
    | 'Warehouse'
    | 'Hotel/Lodging';
  archived: boolean;
};

const ResourceSiteEditDrawer: React.FC<EditEntityDrawerProps<ResourceSite>> = ({
  toggle,
  selectedEntity: editedEntity,
}) => {
  const form = useForm<FormFields>({
    mode: 'onChange',
    defaultValues: {
      ...editedEntity,
    },
  });
  const { handleSubmit } = form;

  const group_guid = useAppSelector(selectGroupGuid);

  const mutation = useUpdateResourceSite(editedEntity.id);

  const [geolocation, setGeolocation] = useState(editedEntity.geolocation);

  const submitResourceSiteEdit = async (formData: FormFields) => {
    const payload = {
      id: editedEntity.id,
      ...formData,
      archived: false,
      geolocation: geolocation,
    };
    await mutation.mutateAsync(payload);
    toggle();
  };

  return (
    <DrawerWrapper toggle={toggle} title="Edit ResourceSite">
      <FormProvider {...form}>
        <form
          onSubmit={handleSubmit(submitResourceSiteEdit)}
          className="d-flex flex-column justify-content-between flex-grow-1"
        >
          <Offcanvas.Body>
            <Row>
              <FormTextField
                title="Name"
                fieldName="name"
                rules={{
                  required: { value: true, message: 'This field is required' },
                  maxLength: {
                    value: 300,
                    message: 'The maximum length is 300 characters',
                  },
                }}
                variant="half"
              />
            </Row>
            <Row>
              <FormTextAreaField
                title="Description"
                fieldName="description"
                rules={{
                  maxLength: {
                    value: 300,
                    message: 'The maximum length is 300 characters',
                  },
                }}
                variant="full"
              />
            </Row>
            <Row>
              <FormSelectField
                title="Site Type"
                fieldName="type"
                rules={{
                  required: { value: true, message: 'This field is required' },
                }}
                options={resource_site_types}
                variant="half"
              />
            </Row>
            <Row>
              <div className="m-0">
                <label className="form-label">Location:</label>
                <GeoLocationComponent
                  location={geolocation}
                  setLocation={setGeolocation}
                />
              </div>
            </Row>
          </Offcanvas.Body>
          <DrawerFooter>
            <div className="button-group">
              <StylishNewButton
                className="button--secondary button--reverse"
                type="button"
                onClick={toggle}
              >
                Close
              </StylishNewButton>
              <StylishNewButton
                className="button--primary"
                type="submit"
                disabled={mutation.isPending}
              >
                Save
              </StylishNewButton>
            </div>
          </DrawerFooter>
        </form>
      </FormProvider>
    </DrawerWrapper>
  );
};

export default ResourceSiteEditDrawer;
