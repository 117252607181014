import React, { useState, useRef, useEffect } from 'react';
import { StylishNewButton } from '../DesignSystems/New/StylishNewButton';
import { SharedIcon } from '../SharedIcon/SharedIcon';
import { MdEdit } from 'react-icons/md';
import { useUpdateDisasterChat, useDeleteDisasterChat } from './hooks/useDisasterChats';
import * as dayjs from 'dayjs'


const DisasterChatItem = ({ chat, onClick }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [newName, setNewName] = useState(chat.name);
    const inputRef = useRef(null);
    const editDisasterChat = useUpdateDisasterChat();
    const deleteDisasterChat = useDeleteDisasterChat();

    const handleRename = () => {
        if (newName !== chat.name) {
            editDisasterChat.mutate({ name: newName, dchat_id: chat.id });
        }
        setIsEditing(false);
    };

    const handleClickOutside = (event) => {
        if (inputRef.current && !inputRef.current.contains(event.target)) {
            setIsEditing(false);
        }
    };

    useEffect(() => {
        if (isEditing) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isEditing]);

    const handleDelete = () => {
        if (window.confirm("Delete this DisasterChat session?")) {
            deleteDisasterChat.mutate(chat.id);
        }
    };

    return (
      <li className="disaster-chat-item" style={{ marginBottom: '8px' }}>
        {isEditing ? (
          <input
            ref={inputRef}
            type="text"
            value={newName}
            onChange={(e) => setNewName(e.target.value)}
            onBlur={handleRename}
            onKeyDown={(e) => e.key === 'Enter' && handleRename()}
        />
      ) : (
        <StylishNewButton
          className="button--secondary me-3"
          onClick={onClick}
        >
          <span>
              <span style={{ fontWeight: 'bold', display: 'block', textAlign: 'left' }}>{chat.name}</span>
              <div>
                <em>Last Modified: </em>{dayjs(new Date(chat.timestamp).toLocaleString()).format('YYYY-MM-DD HH:mm UTC')}
              </div>
            </span>
        </StylishNewButton>
      )}
      <div className="options">
        <StylishNewButton
            className="button button--link"
            onClick={() => setIsEditing(true)}
        >
            <MdEdit /> Rename
        </StylishNewButton>
        <StylishNewButton
            className="button button--link delete"
            onClick={handleDelete}
        >
            <SharedIcon iconName="delete" classes="me-2" /> Delete
        </StylishNewButton>
      </div>
    </li>
  );
};

export default DisasterChatItem;
