import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import StylishNewSelect from 'components/DesignSystems/New/StylishNewSelect';
import StylishDateTimePicker from 'components/DesignSystems/form/StylishDateTimePicker';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { PreparePath } from 'utils/sharedUtils/sharedConst';
import { MissionTimelineItem } from './MissionTimelineItem';
import IconEmpty from 'assets/images/icon__exercise-details-empty.svg';
import Network from 'PREPAREsrc/service/Network';
import API from 'PREPAREsrc/service/api';
import { endLoading, startLoading } from 'reducers/loading/loading.action';
import moment from 'moment';
import { sanitizeHtml } from 'utils/sanitizeHtml';
import { SharedIcon } from 'components/SharedIcon/SharedIcon';
import classNames from 'classnames';

const status = ['Draft', 'Scheduled', 'In-progress', 'Complete', 'Incomplete'];

export const MissionTimeline = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { type: workspaceType, selectedWorkspace } = useSelector(
    (state) => state?.prepare?.workspace
  );

  useEffect(() => {
    if (workspaceType && workspaceType !== 'T&E')
      navigate(`${PreparePath}/dashboard/plan`);
  }, [workspaceType]);
  useEffect(() => {
    if (selectedWorkspace) {
      fetchAllMissions();
      allPlanStatusLabelList();
    }
  }, [selectedWorkspace]);

  const [selectedMission, setSelectedMission] = useState('');
  const [startDateTime, setStartDateTime] = useState();
  const [endDateTime, setEndDateTime] = useState();
  const [allMissions, setAllMissions] = useState([]);
  const [selectedMissionFullData, setSelectedMissionFullData] = useState(null);
  const [allTestObjective, setAllTestObjective] = useState(null);
  const [allTimeline, setAllTimeline] = useState(null);
  const [allStatusLabel, setAllStatusLabel] = useState([]);

  useEffect(() => {
    if (selectedMission && startDateTime && endDateTime) {
      getMissionTimelineData(selectedMission.value);
    } else if (selectedMission && !startDateTime && !endDateTime) {
      getMissionTimelineData(selectedMission.value);
    }
  }, [startDateTime, endDateTime]);

  const fetchAllMissions = async () => {
    try {
      dispatch(startLoading());
      const response = await Network.get(API.fetchAllMissions, {
        workspaceId: selectedWorkspace,
      });
      if (response?.status === 200 && response !== undefined) {
        const allMissions = response.data.response.dataset.map((item) => ({
          label: item.name,
          value: item.id,
        }));
        setAllMissions(allMissions);
        dispatch(endLoading());
      }
    } catch (error) {
      console.error(error);
      dispatch(endLoading());
    }
  };
  const allPlanStatusLabelList = async () => {
    dispatch(startLoading());
    try {
      const statusLabel = await Network.get(API.planStatusLabelList, {
        workspaceId: selectedWorkspace,
      });
      setAllStatusLabel(
        statusLabel.data.response.dataset
          .map((item) => ({
            value: {
              id: item.id,
              status: item.title,
              statusColor: item.color,
            },
            label: (
              <>
                <span
                  style={{
                    backgroundColor: item.color,
                    height: '20px',
                    width: '20px',
                    borderRadius: '4px',
                  }}
                  className="me-2"
                ></span>
                {item.title}
              </>
            ),
            name: item.title,
          }))
          .filter((item) => status.includes(item.name))
      );
    } catch (error) {
      toast.error(error.response.data.response.status.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };

  const onChangeMission = (e) => {
    if (e) {
      setSelectedMission(e);
      getMissionTimelineData(e.value);
    } else {
      setSelectedMission('');
    }
  };

  const getMissionTimelineData = async (missionId) => {
    try {
      dispatch(startLoading());
      const response = await Network.get(API.getMissionTimelineData, {
        workspaceId: selectedWorkspace,
        missionId,
        startDate: startDateTime,
        endDate: endDateTime,
      });
      if (response?.status === 200 && response !== undefined) {
        let data = response.data.response.dataset[0];
        data = {
          ...data,
          mission_admin_remarks: sanitizeHtml(data?.mission_admin_remarks),
        };
        let objectiveData = [];
        data.mission_timeline.forEach((item) => {
          const obj = item.test_objective;
          if (obj.length > 0) {
            objectiveData = [...objectiveData, ...obj];
          }
        });
        const timeline = Object.groupBy(data.mission_timeline, (item) =>
          moment(item.mission_timeline_start_date).format('YYYY-MM-DD')
        );
        const timelineList = Object.keys(timeline).map((key) => {
          return {
            date: key,
            timelines: timeline[key],
          };
        });
        setAllTimeline(timelineList);
        setAllTestObjective(objectiveData);
        setSelectedMissionFullData(data);
        dispatch(endLoading());
      }
    } catch (error) {
      console.error(error);
      dispatch(endLoading());
    }
  };
  const onResetFilter = () => {
    setStartDateTime(undefined);
    setEndDateTime(undefined);
  };
  const addTimeLineEvent = () => {
    navigate(
      `${PreparePath}/dashboard/plan/missions/timeline/create-timeline?missionId=${selectedMission.value}`
    );
  };

  return (
    <>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <StylishNewButton
              onClick={() =>
                navigate(`${PreparePath}/dashboard/plan/missions/timeline`)
              }
            >
              <SharedIcon iconName="keyboard_arrow_left" classes="me-2" />
              Back
            </StylishNewButton>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Mission Timeline
          </li>
        </ol>
      </nav>
      <div className="d-md-flex align-items-end mb-4">
        <div className="mb-3 mb-md-0 w-100 w-md-auto">
          <label className="form-label">Mission Name</label>
          <StylishNewSelect
            options={allMissions}
            value={selectedMission}
            onChange={(e) => onChangeMission(e)}
            isClearable={true}
          />
        </div>
        {(!!selectedMission && (
          <StylishNewButton
            className="button--primary ms-auto w-100 w-md-auto"
            onClick={addTimeLineEvent}
          >
            Add Timeline Event
          </StylishNewButton>
        )) ||
          null}
      </div>
      {(!!selectedMission && selectedMissionFullData && (
        <>
          <div className="form-block mb-4">
            <div className="row">
              <div className="col-md-6 col-xl-3 mb-4">
                <span className="d-block weight-600">Start</span>
                {moment(selectedMissionFullData?.mission_start_date).format(
                  'DD MMM YY - hh:mm a'
                )}
              </div>
              <div className="col-md-6 col-xl-3 mb-4">
                <span className="d-block weight-600">End</span>
                {moment(selectedMissionFullData?.mission_end_date).format(
                  'DD MMM YY - hh:mm a'
                )}
              </div>
              <div className="col-md-6 col-xl-3 mb-4">
                <span className="d-block weight-600">Mission Type</span>
                {selectedMissionFullData?.mission_type_name}
              </div>
              <div className="col-md-6 col-xl-3 mb-4">
                <span className="d-block weight-600">
                  Primary Action Officer
                </span>
                {selectedMissionFullData?.primary_action_officer_name ||
                  selectedMissionFullData?.primary_action_officer_email ||
                  ''}
              </div>
              <div className="col-xl-6 mb-4 mb-xl-0">
                <span className="d-block weight-600">Mission Objective</span>
                {selectedMissionFullData?.mission_objective}
              </div>
              <div className="col-xl-6">
                <span className="d-block weight-600">Admin Remarks</span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: selectedMissionFullData?.mission_admin_remarks,
                  }}
                ></span>
              </div>
            </div>
          </div>
          <div className="d-md-flex align-items-center mb-4">
            <div className="row flex-grow-1">
              <div className="col-md-6 mb-2 mb-md-0">
                <StylishDateTimePicker
                  value={startDateTime}
                  type="datetime-local"
                  onChange={(e) => setStartDateTime(e)}
                  dateLabel={'Start Date'}
                  timeLabel={'Start Time'}
                  maxDate={endDateTime ? endDateTime : undefined}
                />
              </div>
              <div className="col-md-6">
                <StylishDateTimePicker
                  value={endDateTime}
                  type="datetime-local"
                  onChange={(e) => setEndDateTime(e)}
                  dateLabel={'End Date'}
                  timeLabel={'End Time'}
                  minDate={startDateTime ? startDateTime : undefined}
                />
              </div>
            </div>
            <StylishNewButton
              onClick={onResetFilter}
              type="button"
              className={classNames('button--link ms-md-3 mt-4 flex-shrink-0', {disabled: !startDateTime && !endDateTime})}
              disabled={!startDateTime && !endDateTime}
            >
              Clear
            </StylishNewButton>
          </div>
          <div className="row">
            <div className="col-12 mb-4 mb-xl-0">
              <h5 className="mb-4">Mission Timeline Events</h5>
              {(allTimeline && !!allTimeline?.length && (
                <div className="train__msel-timeline container">
                  {allTimeline
                    ?.sort((a, b) => new Date(a.date) - new Date(b.date))
                    .map((item) => (
                      <>
                        <MissionTimelineItem
                          key={item.date}
                          item={item}
                          missionId={selectedMission?.value}
                          statusLabel={allStatusLabel}
                          updateMissionTimeList={() =>
                            getMissionTimelineData(selectedMission.value)
                          }
                        />
                      </>
                    ))}
                </div>
              )) || (
                <div className="bg-gray-800 text-center p-5">
                  <div className="py-0 py-md-4">
                    <div className="d-flex justify-content-center mb-4">
                      <img src={IconEmpty} alt="" />
                    </div>
                    <p className="weight-600">
                      A fresh start. Create the first entry.
                    </p>
                    <StylishNewButton
                      className="button--primary ms-auto"
                      onClick={addTimeLineEvent}
                    >
                      Add Timeline Event
                    </StylishNewButton>
                  </div>
                </div>
              )}
            </div>
            {/* <div className="col-xl-6">
              <h5 className="mb-4">Test Objectives</h5>
              {(allTestObjective && !!allTestObjective?.length && (
                <div className="bg-gray-700 p-3">
                  {allTestObjective?.map((data, idx) => (
                    <div key={data?.id}>
                      {(idx !== 0 && <hr className="dashed g-600 my-3" />) ||
                        null}
                      <h6 className="mb-1">COIC {data?.coic}</h6>
                      <p className="m-0">{data?.coic_description}</p>
                      {(data?.objective_association &&
                        !!data?.objective_association?.length &&
                        data?.objective_association?.map((obj, idx) => (
                          <p
                            className={`m-0 ${(idx !== 0 && 'mt-2') || 'mt-3'}`}
                            key={obj?.id}
                          >
                            <strong className="weight-600 me-3">
                              {obj?.type} {obj?.item}
                            </strong>
                            {obj?.description || '-'}
                          </p>
                        ))) ||
                        null}
                    </div>
                  ))}
                </div>
              )) || (
                <div className="bg-gray-800 text-center p-5">
                  <div className="py-0 py-md-4">
                    <div className="d-flex justify-content-center mb-4">
                      <img src={IconEmpty} alt="" />
                    </div>
                    <p className="weight-600">No data found.</p>
                  </div>
                </div>
              )}
            </div> */}
          </div>
        </>
      )) || (
        <div className="bg-gray-800 text-center p-5">
          <div className="py-0 py-md-4">
            <div className="d-flex justify-content-center mb-4">
              <img src={IconEmpty} alt="" />
            </div>
            <p className="weight-600">
              Please select a Mission to view respective data.
            </p>
          </div>
        </div>
      )}
    </>
  );
};
