import React, { useState, useEffect, cloneElement, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import IconChooseMedia from 'assets/images/icon__choosemedia.svg';
import ChooseMediaDialog from '../ChooseMediaModal/ChooseMediaDialog';
import AddRelationshipsDialog from '../Personas/AddRelationshipsDialog';
import { useSelector } from 'react-redux';
import IconDelete from 'assets/images/icon__delete.svg';
import IconLink from 'assets/images/icon__visit.svg';
import API from '../../../service/api';
import Network from '../../../service/Network';
import { useForm, Controller } from 'react-hook-form';
import StylishNewInput from 'components/DesignSystems/New/StylishNewInput';
import StylishNewTextArea from 'components/DesignSystems/New/StylishNewTextArea';
import Help from '../../../component/Help/Help';
import { EditProfilesHelp, useSelfHostedKnowledgeBase } from 'assets/data/HelpContent';
import AddProfilestoPersonaDialog from '../Networks/AddPersonasDialog';
import ReviewProfilesDialog from '../Networks/ReviewPersonasDialog';
import StylishNewSelect from 'components/DesignSystems/New/StylishNewSelect';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import IconData from 'assets/images/icon__data.svg';
import { inputStringLength, toastConfig } from 'assets/data/config';
import IconTimes from 'assets/images/icon__times.svg';
import { PreparePath } from 'utils/sharedUtils/sharedConst';
import { toast } from 'react-toastify';
import moment from 'moment';
import StylishHelp from 'components/DesignSystems/New/StylishHelp';
import { StylishConfirmDialog } from 'components/DesignSystems/New/StylishConfirmDialog';
import IconUnlink from 'assets/images/icon__unlink.svg';
import IconEdit from 'assets/images/icon__edit.svg';
import IconView from 'assets/images/icon__eye.svg';
import { LinkedPersonasDialog } from './LinkedPersonas';
import { endLoading, startLoading } from "../../../../reducers/loading/loading.action";

export default function EditProfile() {
  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();

  const [showChooseMediaDialog, setShowChooseMediaDialog] = useState(false);
  const [showAddRelationshipsDialog, setShowAddRelationshipsDialog] = useState(
    false
  );
  const [imageAvatarFrom, setImageAvatarFrom] = useState(false);
  const [imageBannerFrom, setImageBannerFrom] = useState(false);
  const [viewSites, setViewSites] = useState([]);
  const [typeModalProfile, setTypeModalProfile] = useState(false);
  const [imageAvatar, setImageAvatar] = useState(null);
  const [imageBanner, setImageBanner] = useState(null);
  const [isVisibleAvatar, setIsVisibleAvatar] = useState(false);
  const [isVisibleBanner, setIsVisibleBanner] = useState(false);
  const [showFollowerCount, setShowFollowerCount] = useState(false);
  const [name, setName] = useState('');
  const [userName, setUserName] = useState('');
  const [profileData, setProfileData] = useState('');
  const [deleteProfile, setDeleteProfile] = useState(null);
  const [showLinkedPersonsDialog, setShowLinkedPersonsDialog] = useState(false);
  const [linkedPersonas, setLinkedPersonas] = useState([]);
  const [isPersonasUpdated, setIsPersonasUpdated] = useState(false);

  const selfHostedKnowledgeBase = useSelfHostedKnowledgeBase();

  const [
    showAddProfilestoPersonaDialog,
    setShowAddProfilestoPersonaDialog,
  ] = useState(false);
  const [showReviewProfilesDialog, setShowReviewProfilesDialog] = useState(
    false
  );
  const [keyPressForProfileName, setKeyPressForProfileName] = useState('');

  const reduxUser = useSelector((state) => state.prepare.user);
  const workspaceId = useSelector(
    (state) => state.prepare.workspace.selectedWorkspace
  );
  const isMounted = useRef(true);

  const params = useParams();

  const navigate = useNavigate();

  const profileId = params.id;

  const dispatch = useDispatch();

  const mediaSiteList = async () => {
    dispatch(startLoading());
    try {
      const response = await Network.get(API.mediasiteList, {
        workspaceId: workspaceId,
      });
      const mediaList = response.data.response.dataset.map(
        (updatedMediaList) => {
          return {
            label: updatedMediaList.displayName,
            id: updatedMediaList.id,
          };
        }
      );
      setViewSites(mediaList);
    } catch (error) {
    } finally {
      dispatch(endLoading());
    }
  };
  useEffect(() => {
    mediaSiteList();
  }, [reduxUser]);

  useEffect(() => {
    if (isMounted.current) {
      isMounted.current = false;
      return;
    }
    if (!!workspaceId) {
      navigate(-1);
    }
  }, [workspaceId]);

  const mediaProfileDetailsByProfileId = async () => {
    dispatch(startLoading());
    try {
      const profiles = await Network.get(API.mediaProfileDetailsByProfileId, {
        workspaceId: workspaceId,
        profileId: profileId,
      });
      setProfileData(profiles.data.response.dataset);
      setName(profiles.data.response.dataset?.name);
      setValue('name', profiles.data.response.dataset?.name);
      setUserName(`@${profiles.data.response.dataset?.username}`);
      setValue('description', profiles.data.response.dataset?.description);
      setValue('followers', profiles.data.response.dataset?.followerCount);
      setValue('following', profiles.data.response.dataset?.followingCount);
      if (profiles.data.response.dataset?.avatar) {
        setImageAvatar(profiles.data.response.dataset?.avatar);
        setIsVisibleAvatar(true);
      }
      if (profiles.data.response.dataset?.banner) {
        setImageBanner(profiles.data.response.dataset?.banner);
        setIsVisibleBanner(true);
      }
      // setValue(
      //   'siteDropDown',
      //   viewSites?.filter(
      //     (data) => data.id === profiles?.data?.response?.dataset?.mediaSiteId
      //   )[0]
      // );
      setValue(
        'siteDropDown',
        viewSites?.filter(
          (data) => data.id === profiles?.data?.response?.dataset?.mediaSiteId
        )[0]?.label
      );
    } catch (error) {
      console.log('error', error);
    } finally {
      dispatch(endLoading());
    }
  };

  useEffect(() => {
    if (profileId) {
      mediaProfileDetailsByProfileId();
    }
  }, [profileId, viewSites, isPersonasUpdated]);

  useEffect(() => {
    if (profileData && !!profileData?.mediapersonaprofilerelation?.length) {
      const personas = profileData?.mediapersonaprofilerelation;
      setLinkedPersonas(personas);
    }
  }, [profileData]);

  const handleUnlinkPersona = (personaId) => {
    const personas = linkedPersonas.filter(
      (persona) => persona?.personaId !== personaId
    );
    setLinkedPersonas(personas);
  };

  const onSubmit = async (data) => {
    const personas =
      linkedPersonas?.map((i) => ({ mediaPersonaId: i?.personaId })) || [];
    dispatch(startLoading());
    try {
      const newProfile = await Network.post(API.updateMediaProfile, {
        name: data?.name,
        username: userName?.slice(1),
        description: data?.description,
        mediaSiteId: data?.siteDropDown?.id,
        followerCount: data?.followers || 0,
        followingCount: data?.following || 0,
        avatar: imageAvatar,
        banner: imageBanner,
        workspaceId: workspaceId,
        isUserGenerated: true,
        profileId: profileId,
        updatedAt: moment().toISOString(),
        personas,
      });
      navigate(`${PreparePath}/dashboard/media/profiles`);
      // onClose(newProfile?.data?.response?.MediaProfile);
      toast.success('Profile updated successfully', toastConfig);
    } catch (error) {
      toast.error('Something went wrong', toastConfig);
      console.log(error);
    } finally {
      dispatch(endLoading());
    }
  };

  const onSubmitImageAvatar = (data) => {
    setShowChooseMediaDialog(false);
    setImageAvatar(data);
    setIsVisibleAvatar(true);
  };
  const onSubmitImageBanner = (data) => {
    setShowChooseMediaDialog(false);
    setImageBanner(data);
    setIsVisibleBanner(true);
  };
  const onChooseMediaDialog = () => {
    setShowChooseMediaDialog(true);
  };
  const onChooseMediaDialogClose = () => {
    setShowChooseMediaDialog(false);
  };

  const onAddRelationshipsDialogClose = () => {
    setShowAddRelationshipsDialog(false);
  };

  const onAddProfilestoPersonaDialogClose = () => {
    setShowAddProfilestoPersonaDialog(false);
  };

  const onReviewProfilesDialog = () => {
    setShowReviewProfilesDialog(true);
    setShowAddProfilestoPersonaDialog(false);
  };

  const onSubmitImageAvatarFrom = (data) => {
    if (data !== undefined) {
      setImageAvatarFrom(data);
    }
  };
  const onSubmitImageBannerFrom = (data) => {
    if (data !== undefined) {
      setImageBannerFrom(data);
    }
  };

  const onReviewProfilesDialogClose = () => {
    setShowReviewProfilesDialog(false);
  };
  const onKeyPressForProfileName = () => {
    let profileName = document.getElementById('name');
    setKeyPressForProfileName(profileName.value);
  };

  const handleKeyPress = (e) => {
    const keyCode = e.keyCode || e.which;
    const keyValue = String.fromCharCode(keyCode);
    const isValidKey = /[0-9]|Backspace/.test(keyValue);
    if (!isValidKey) {
      e.preventDefault();
    }
  };

  const onDeleteProfile = (row) => {
    setDeleteProfile(row.id);
  };

  const onDeleteConfirm = async () => {
    dispatch(startLoading());
    try {
      await Network.post(API.deleteMediaProfile, { profileId: deleteProfile });
      navigate(-1);
      setDeleteProfile(null);
      toast.success('Profile deleted successfully', toastConfig);
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(endLoading());
    }
  };

  return (
    <>
      <div className="d-flex align-items-center mb-4">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb mb-0">
            <li className="breadcrumb-item">
              <StylishNewButton onClick={() => navigate(-1)}>
                <i className="fa fa-angle-left me-2" aria-hidden="true"></i>
                Back
              </StylishNewButton>
            </li>
            <li className="breadcrumb-item">
              <StylishNewButton
                onClick={() =>
                  navigate(`${PreparePath}/dashboard/media/profiles`)
                }
              >
                Profile
              </StylishNewButton>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              {keyPressForProfileName || name}
            </li>
          </ol>
        </nav>
        <StylishHelp
          classes={'flex-shrink-0 ms-auto sml'}
          title={EditProfilesHelp.title}
          content={EditProfilesHelp.content}
          link={selfHostedKnowledgeBase || EditProfilesHelp.link}
        />
      </div>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-block mb-4">
          <div className="d-flex align-items-center mb-3">
            <h4 className="mb-0"></h4>
            <Dropdown className="flex-shrink-0 ms-auto">
              <Dropdown.Toggle className="button--icon">
                <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
              </Dropdown.Toggle>
              <Dropdown.Menu align="end">
                {/* <Dropdown.Item>
                  <img src={IconLink} alt="" />
                  Permalink
                </Dropdown.Item> */}
                <Dropdown.Item onClick={() => onDeleteProfile(profileData)}>
                  <img src={IconDelete} alt="" />
                  Delete Profile
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>

          <div className="mb-3">
            <label className="form-label">Site</label>
            <Controller
              control={control}
              name="siteDropDown"
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                // <StylishNewSelect
                //   options={viewSites}
                //   value={value || ''}
                //   onChange={onChange}
                //   isClearable={false}
                //   isSearchable={false}
                //   isMulti={false}
                //   isDisabled={true}
                //   placeholder="Select a Site"
                // />
                <StylishNewInput
                  value={value}
                  onChange={onChange}
                  disabled={true}
                  readOnly={true}
                />
              )}
            />
            {errors.siteDropDown && (
              <span className="form-text form-error">
                This field is required
              </span>
            )}
          </div>
          <div className="row mb-3">
            <div className="col-md-6 mb-3 mb-md-0">
              <label className="form-label">Name</label>
              <Controller
                control={control}
                name="name"
                // defaultValue={updateDialogData?.name}
                rules={{
                  required: true,
                  pattern: /^[A-Za-z]*[A-Za-z][A-Za-z0-9-. _]*$/,
                }}
                render={({ field: { onChange, value } }) => (
                  <StylishNewInput
                    type="text"
                    // name=""
                    className="form-control"
                    onChange={onChange}
                    value={value || ''}
                    id="name"
                    onKeyUp={() => {
                      onKeyPressForProfileName();
                    }}
                  />
                )}
              />
              {errors.name?.type === 'required' && (
                <span className="form-text form-error">
                  This field is required
                </span>
              )}
              {errors?.name?.type === 'pattern' && (
                <span className="form-text form-error">
                  Please provide a valid name
                </span>
              )}
            </div>
            <div className="col-md-6">
              <label className="form-label">User Name</label>
              <Controller
                control={control}
                name="userName"
                id="userName"
                disabled="true"
                render={({ field: { onChange, value } }) => (
                  <StylishNewInput
                    id="userName"
                    value={userName}
                    prefix={'@'}
                    type="text"
                    readOnly={true}
                    disabled
                    className="form-control"
                  />
                )}
              />
            </div>
          </div>

          <div className="mb-3">
            <label className="form-label">Description</label>
            <Controller
              control={control}
              name="description"
              // defaultValue={updateDialogData?.description}
              maxLength={inputStringLength}
              render={({ field: { onChange, value } }) => (
                <StylishNewTextArea
                  className="form-control form-textarea"
                  onChange={onChange}
                  value={value || ''}
                ></StylishNewTextArea>
              )}
            />{' '}
          </div>

          <div className="mb-3">
            <label className="form-label d-block">Follower / Following</label>

            <span
              className="badge cursor-pointer"
              onClick={() => {
                setShowFollowerCount(!showFollowerCount);
              }}
            >
              <img src={IconData} alt="" />
              Data
            </span>

            {showFollowerCount ? (
              <div className="row mt-3">
                <div className="col-md-6 mb-3 mb-md-0">
                  <label className="form-label">Followers</label>
                  <Controller
                    control={control}
                    name="followers"
                    // defaultValue={updateDialogData?.followers || 900}
                    // rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <StylishNewInput
                        type="number"
                        onKeyPress={handleKeyPress}
                        onChange={onChange}
                        value={value || ''}
                        className="form-control"
                      />
                    )}
                  />
                </div>
                <div className="col-md-6">
                  <label className="form-label">Following</label>
                  <Controller
                    control={control}
                    name="following"
                    // defaultValue={updateDialogData?.following || 400}
                    render={({ field: { onChange, value } }) => (
                      <StylishNewInput
                        type="number"
                        onKeyPress={handleKeyPress}
                        onChange={onChange}
                        value={value || ''}
                        className="form-control"
                      />
                    )}
                  />
                </div>
              </div>
            ) : null}
          </div>
          <div className="row">
            <div className="col-md-12 mb-3">
              <label className="form-label d-block">Avatar</label>

              <span
                className="badge cursor-pointer"
                onClick={() => {
                  onChooseMediaDialog();
                  setTypeModalProfile('Avatar');
                }}
              >
                <img src={IconChooseMedia} className="me-3" alt="" />
                Choose Media
              </span>

              {isVisibleAvatar && (
                <>
                  <div className="d-flex align-items-start mt-3">
                    <div className="me-3">
                      <img
                        src={imageAvatar}
                        className="rounded-5 w-100 h-auto d-block"
                        alt=""
                      />
                    </div>
                    <StylishNewButton
                      customButton
                      className={'button--icon flex-shrink-0'}
                      onClick={(e) => {
                        setIsVisibleAvatar(false);
                        setImageAvatar(null);
                      }}
                    >
                      <img src={IconTimes} alt="" />
                    </StylishNewButton>
                  </div>
                </>
              )}
              {/* {isVisibleAvatar && (
                    <img src={imageAvatar} className="me-3" alt="" />
                  )} */}
            </div>
            <div className="col-md-12">
              <label className="form-label d-block">Banner</label>

              <span
                className="badge cursor-pointer"
                onClick={() => {
                  onChooseMediaDialog();
                  setTypeModalProfile('Banner');
                }}
              >
                <img src={IconChooseMedia} className="me-3" alt="" />
                Choose Media
              </span>

              {isVisibleBanner && (
                <>
                  <div className="d-flex align-items-start mt-3">
                    <div className="me-3">
                      <img
                        src={imageBanner}
                        className="rounded-5 w-100 h-auto d-block"
                        alt=""
                      />
                    </div>
                    <StylishNewButton
                      customButton
                      className={'button--icon flex-shrink-0'}
                      onClick={(e) => {
                        setIsVisibleBanner(false);
                        setImageBanner(null);
                      }}
                    >
                      <img src={IconTimes} alt="" />
                    </StylishNewButton>
                  </div>
                </>
              )}
            </div>
          </div>
          <hr className="dashed" />
          <div className="d-md-flex align-items-md-center mb-4">
            <StylishNewButton
              type="button"
              className={'button--primary w-100 w-md-auto mb-3 mb-md-0'}
              onClick={() => setShowLinkedPersonsDialog(true)}
            >
              Link Existing Persona
            </StylishNewButton>
            <p className="m-0 ms-md-4">
              {linkedPersonas?.length || '0'} Linked Persona(s)
            </p>
          </div>
          {(linkedPersonas && !!linkedPersonas?.length && (
            <div className="row">
              {linkedPersonas?.map((persona, idx) => (
                <div key={idx} className="col-md-6 col-lg-4 col-xl-3 mb-4">
                  <div className="border-700 rounded h-100 d-flex flex-column overflow-hidden">
                    <div className="p-3 flex-grow-1 d-flex flex-column">
                      <div className="d-flex align-items-start mb-4">
                        <div className="profile-img">
                          {(persona?.avatar && (
                            <img src={persona?.avatar} alt="" />
                          )) ||
                            null}
                        </div>

                        <Dropdown className="flex-shrink-0 ms-auto">
                          <Dropdown.Toggle className="button--icon">
                            <i
                              className="fa fa-ellipsis-v"
                              aria-hidden="true"
                            ></i>
                          </Dropdown.Toggle>
                          <Dropdown.Menu align="end">
                            <Dropdown.Item
                              onClick={() =>
                                handleUnlinkPersona(persona?.personaId)
                              }
                            >
                              <img src={IconUnlink} alt="" />
                              Unlink Persona
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                      <h4 className="m-0 break-word">{persona?.personaName}</h4>

                      <p className="m-0">{persona?.personaRole}</p>
                      <p className="color-brand mb-0 mt-auto pt-4">
                        {persona?.linkedProfiles || 0} Linked Profiles
                      </p>
                    </div>
                    <div className="bg-gray-700 flex-shrink-0 p-3 mt-auto d-flex align-items-center justify-content-center">
                      <Link
                        to={`${PreparePath}/dashboard/media/edit-personas/useblankpersona/${persona.personaId}`}
                        className="text-white weight-500 d-flex align-items-center justify-content-center flex-grow-1 cursor-pointer"
                      >
                        <img src={IconEdit} className="me-2" alt="" />
                        Edit
                      </Link>
                      <div className="separator--y separator--800  flex-shrink-0"></div>

                      <Link
                        to={`${PreparePath}/dashboard/media/view-persona/${persona.personaId}`}
                        className="text-white weight-500 d-flex align-items-center justify-content-center flex-grow-1"
                      >
                        <img src={IconView} className="img-h-16 me-2" alt="" />
                        View
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )) ||
            null}
        </div>

        <div className="button-group justify-content-end">
          <StylishNewButton className="button--secondary button--reverse">
            Cancel
          </StylishNewButton>
          <StylishNewButton className="button--primary" type="submit">
            Save
          </StylishNewButton>
        </div>
      </form>
      {showChooseMediaDialog && (
        <ChooseMediaDialog
          show={showChooseMediaDialog}
          onClose={onChooseMediaDialogClose}
          onSubmitImageAvatar={onSubmitImageAvatar}
          onSubmitImageBanner={onSubmitImageBanner}
          typeModalProfile={typeModalProfile}
          onSelectedImageAvatar={imageAvatar}
          onSelectedImageBanner={imageBanner}
          imageAvatarFrom={imageAvatarFrom}
          imageBannerFrom={imageBannerFrom}
          onSubmitImageBannerFrom={onSubmitImageBannerFrom}
          onSubmitImageAvatarFrom={onSubmitImageAvatarFrom}
        />
      )}
      {showAddRelationshipsDialog && (
        <AddRelationshipsDialog
          show={showAddRelationshipsDialog}
          onClose={onAddRelationshipsDialogClose}
          // updateDialogData={updateDialogData}
        />
      )}
      {showAddProfilestoPersonaDialog && (
        <AddProfilestoPersonaDialog
          show={showAddProfilestoPersonaDialog}
          onClose={onAddProfilestoPersonaDialogClose}
          onReviewProfilesDialog={onReviewProfilesDialog}
          // updateDialogData={updateDialogData}
        />
      )}

      {showReviewProfilesDialog && (
        <ReviewProfilesDialog
          show={showReviewProfilesDialog}
          onClose={onReviewProfilesDialogClose}
        />
      )}

      {deleteProfile && (
        <StylishConfirmDialog
          show={deleteProfile}
          onClose={() => setDeleteProfile(null)}
          dialogTitle={'Delete Profile'}
          dialogContent={
            'Deleting the profile, will delete the posts and comments created with this profile - Are you sure?'
          }
          onConfirm={onDeleteConfirm}
        />
      )}

      {(showLinkedPersonsDialog && (
        <LinkedPersonasDialog
          show={showLinkedPersonsDialog}
          setIsPersonasUpdated={setIsPersonasUpdated}
          onClose={() => setShowLinkedPersonsDialog(false)}
          data={profileData}
          workspaceId={workspaceId}
        />
      )) ||
        null}
    </>
  );
}
