import React, {useState, useEffect} from 'react'
import {useSelector,useDispatch} from 'react-redux'

import Box from '@mui/material/Box';
import Dialog, { Header, Body, Footer } from 'components/DesignSystems/dialog/Dialog';
import Title from 'components/DesignSystems/text/Title';
import Info from 'components/DesignSystems/text/Info';
import Button from 'components/DesignSystems/buttons/StylishButton';

import { CloseButton } from 'components/DesignSystems/buttons/CloseButton';

import StylishNewTable from 'components/DesignSystems/New/StylishNewTable';
import StylishNewSelect from 'components/DesignSystems/New/StylishNewSelect';
import StylishNewInput from 'components/DesignSystems/New/StylishNewInput';

import generateUUID from 'utils/sharedUtils/generateUUID';

import { Type } from 'react-bootstrap-table2-editor';

import ManageThresholdRiskLevelsDialog from './ManageThresholdRiskLevelsDialog'


import {
	upsertHVA,
  fetchHVAData,
  fetchHVATemplateRisks,
  fetchHVATemplateCategories,
  fetchHVATemplateColumns,
  fetchHVAs,
} from 'actions/alertActions'

import {defaultRiskLevels} from 'components/MapComponent/constants/defaultRiskLevels'

import "./HVA.css"

const columns = [
  {
    dataField: 'name',
    text: 'Name',
    sort: true,
    headerAttrs: { title: 'Sort By:' },
    attrs: { title: 'Name' },
  },
  {
    dataField: 'description',
    text: 'Description',
    sort: true,
    headerAttrs: { title: 'Sort By:' },
    attrs: { title: 'Description' },
  },
];

const nonEditableColumns = ["Risk %"]


// Guide the user through the necessary minimum steps required to set up Alerts
export default function HVA({
	onClose,
  hva
})
{
  const reduxDispatch = useDispatch()

  const reduxCurrentlySelectedGroup = useSelector((state) => {
    return state.app.currentlySelectedGroup;
  });

  const reduxHVAs = useSelector((state)=> {
    return state.app.HVAs
  })

  const reduxHVAData = useSelector((state)=> {
    return state.app.HVAData
  })

  const reduxHVATemplateRisks = useSelector((state)=> {
    return state.app.HVATemplateRisks
  })

  const reduxHVATemplateCategories = useSelector((state)=> {
    return state.app.HVATemplateCategories
  }) || []

  const reduxHVATemplateColumns = useSelector((state)=> {
    return state.app.HVATemplateColumns
  })

  const isFetchHVADataLoaded = useSelector((state)=> {
    return state.app.isFetchHVADataLoaded
  })
  const isFetchHVATemplateCategoriesLoaded = useSelector((state)=> {
    return state.app.isFetchHVATemplateCategoriesLoaded
  })
  const isFetchHVATemplateRisksLoaded = useSelector((state)=> {
    return state.app.isFetchHVATemplateRisksLoaded
  })
  const isFetchHVATemplateColumnsLoaded = useSelector((state)=> {
    return state.app.isFetchHVATemplateColumnsLoaded
  })

  const isUpsertHVALoaded = useSelector((state)=> {
    return state.app.isUpsertHVALoaded
  })

  const isFetchHVAsLoaded = useSelector((state)=> {
    return state.app.isFetchHVAsLoaded
  })


  const [isLoaded, setIsLoaded] = useState(true);
  const [name, setName] = useState(!!hva&&hva.name||"")
  const [selectedCategory, setSelectedCategory] = useState()
  const [hvaData, setHVAData] = useState([])
  const [cols, setCols] = useState([])
  const [manageRiskLevelsRow, setManageRiskLevelsRow] = useState()

  function defaultHVA(categories,risks,columns)
  {
    return risks.map(r=>{
      const defaultCellValue = 0
      let riskRow = {
        ...r,
        hazard_id: r.id,
        id: generateUUID(),
        risk_type: !!r.hazard_type && r.hazard_type || r.risk_type,
        
      }

      riskRow['cell_data'] = columns.map(c=>{

          let cellValue = defaultCellValue

          // This direct placement is only for table
          riskRow[c.name] = cellValue

          const cd = {
            id: generateUUID(),
            name: c.name,
            hva_col_id: c.id,
            level: cellValue,
            level_range: [] // TODO weather/range handling
          }

          return cd
        })

      return riskRow
    })
  }

  function prepExistingHVAData(existingHVAData,risks,columns)
  {
    return existingHVAData.map(r=>{
      const riskRow = {
        ...r,
        name: r.hazard_name || r.template_risk_name
      }
      columns.forEach(c=>{
        riskRow[c.name] = riskRow.cell_data.find(cd=>cd.hva_col_id===c.id).level
      })
      return riskRow
    })
  }

  useEffect(() => {
    if (!isLoaded && (isUpsertHVALoaded && isFetchHVAsLoaded)) {
      onClose()
    }

  }, [isUpsertHVALoaded, isFetchHVAsLoaded, isLoaded, onClose])

  useEffect(()=>{
    if(!!reduxCurrentlySelectedGroup 
      && !!reduxCurrentlySelectedGroup.group_guid 
      && (!reduxHVATemplateRisks.length
        || !reduxHVATemplateCategories.length
        || !reduxHVATemplateColumns.length))
    {
      reduxDispatch(fetchHVATemplateCategories())
      reduxDispatch(fetchHVATemplateRisks())
      reduxDispatch(fetchHVATemplateColumns())
    }
  },[reduxCurrentlySelectedGroup])

  useEffect(()=>{
    if(!!hva
      && !!reduxCurrentlySelectedGroup 
      && !!reduxCurrentlySelectedGroup.group_guid 
      && !reduxHVAData)
    {
      reduxDispatch(fetchHVAData(hva.id))
    }
  },[reduxCurrentlySelectedGroup,hva])

  useEffect(()=>{
    if(!!reduxHVATemplateColumns)
    {
      setCols([
        {
          dataField: 'name',
          text: 'Name',
          sort: true,
          headerAttrs: { title: 'Sort By:' },
          attrs: { title: 'Name' },
          editable:(content, row, rowIndex, columnIndex) => false
        },
        ...reduxHVATemplateColumns.map(c=>{
          const returnCol = {
            ...c,
            dataField: c.name,
            text: c.name,
            sort: true,
            headerAttrs: { title: 'Sort By:' },
            attrs: { title: c.name },
            editor: {
              type: Type.SELECT,
              options: defaultRiskLevels.map(r=>{return {label:r.name,value:r.id}})
            },
            editable: (content, row, rowIndex, columnIndex) => !nonEditableColumns.find(nec=>nec===c.name)&&row.hazard_type==="event",
            formatter: (cell,row)=>{
              if(row.hazard_type==="weather")
              {
                return (
                  <>
                    {c.name!=="Risk Level" && (
                      <span>N/A</span>
                    ) || (
                      <span><button onClick={()=>setManageRiskLevelsRow(row)}>Manage</button></span>
                    )}
                  </>
                  
                )
              }
              else
              {
                return (
                  <span>{cell}</span>
                )
              }
              
            }
          }
          // TODO figure out why formatter isn't updating when table values update
          // if(c.name==="Risk %")
          // {
          //   returnCol.formatter = (cell,row)=>{
          //     return (
          //       <span>{parseInt(cell)*100}%</span>
          //     )
          //   }
          // }

          if(!!nonEditableColumns.find(nec=>nec===c.name)) delete returnCol.editor

          return returnCol
      })])
    }
  },[reduxHVATemplateColumns])

  useEffect(()=>{
    if(!!reduxHVATemplateCategories && !!reduxHVATemplateCategories.length)
    {
      setSelectedCategory({label:reduxHVATemplateCategories[0].name,value:reduxHVATemplateCategories[0].id})
    }
  },[reduxHVATemplateCategories])

  useEffect(()=>{
    if(!hva 
      && !!reduxHVATemplateRisks
      && !!reduxHVATemplateCategories
      && !!reduxHVATemplateColumns)
    {
      setHVAData(defaultHVA(reduxHVATemplateCategories,reduxHVATemplateRisks,reduxHVATemplateColumns))
    }
  },[hva,reduxHVATemplateRisks,reduxHVATemplateCategories,reduxHVATemplateColumns])

  useEffect(()=>{
    if(!!reduxHVAData
      && !!hva
      && !!reduxHVATemplateRisks
      && !!reduxHVATemplateColumns)
    {
      setHVAData(prepExistingHVAData(reduxHVAData,reduxHVATemplateRisks,reduxHVATemplateColumns))
    }
  },[reduxHVAData,reduxHVATemplateColumns])

  const views = ["Risk Levels","Areas of Responsibility (AORs)"]

  function cellDataUpdated(row,column,value)
  {
    let newHVAData = [...hvaData]
    const idx = newHVAData.findIndex(r=>r.id===row.id)
    newHVAData[idx][column.name] = parseInt(value)
    newHVAData[idx]['cell_data'][newHVAData[idx]['cell_data'].findIndex(cd=>cd.name===column.name)].level = parseInt(value)
    
    if(column.name!=="Risk Level")
    {
      // Update the Risk % (and, if the Risk Level is not 0, update that as well)
      let riskPercentage = 0
      let averageVal = 0
      const filteredCols = newHVAData[idx]['cell_data'].filter(col=>!["Risk %","Risk Level"].includes(col.name))
      filteredCols.forEach(col=>{
        riskPercentage += (col.level/(defaultRiskLevels.length-1))
        averageVal += col.level
      })
      riskPercentage = (riskPercentage/filteredCols.length).toFixed(2)
      averageVal = averageVal/(defaultRiskLevels.length-1)

      var closest = defaultRiskLevels.map(rl=>rl.id).reduce(function(prev, curr) {
        return (Math.abs(curr - averageVal) < Math.abs(prev - averageVal) ? curr : prev);
      });

      newHVAData[idx]['cell_data'][newHVAData[idx]['cell_data'].findIndex(cd=>cd.name==="Risk %")].level = riskPercentage
      newHVAData[idx]["Risk %"] = riskPercentage

      newHVAData[idx]['cell_data'][newHVAData[idx]['cell_data'].findIndex(cd=>cd.name==="Risk Level")].level = closest
      newHVAData[idx]["Risk Level"] = closest
    }
    


    setHVAData(newHVAData)
  }

  function cellEditCallback(oldValue,newValue,row,column)
  {
    cellDataUpdated(row,column,newValue)
  }

  function createHVAForUpsert()
  {
    return {
      id: !!hva&&hva.id || generateUUID(),
      name: name,
      org_id: !!hva&&hva.org_id || reduxCurrentlySelectedGroup.group_guid,
      data: hvaData
    }
  }

  function saveClicked()
  {
    setIsLoaded(false)
    reduxDispatch(upsertHVA(createHVAForUpsert()))
  }

  const saveHVADisabled = !name.length || !isLoaded

  const categoryOptions = reduxHVATemplateCategories.map(r=>{return {label:r.name,value:r.id}})

  const filteredHVADataRows = !!selectedCategory && hvaData.filter(d=>d.cat_id===selectedCategory.value) || []
 
	return (
    <Dialog onClose={onClose} className="HVAModal">
      <Header>
          <Title>HVA</Title>
          <CloseButton onClick={onClose} />
      </Header>
      <Body className="HVAModal-Body">
        {!!((!!hva&&isFetchHVADataLoaded)||!hva) 
        && !!isFetchHVATemplateCategoriesLoaded 
        && !!isFetchHVATemplateRisksLoaded
        && !!isFetchHVATemplateColumnsLoaded && (
          <div className="HVAModal-Body-Inner">
            <div>
              <Info>Name</Info>
              <StylishNewInput
                value={name}
                onChange={e=>setName(e.target.value)}
              />
            </div>
            <div>

            </div>
            <Info>Category</Info>
            <StylishNewSelect
              value={selectedCategory}
              options={categoryOptions}
              onChange={(selection)=>setSelectedCategory(selection)}
            />
            {!!selectedCategory && (
              <div className="HVAModal-Table">
              <StylishNewTable
                keyField={'id'}
                columns={cols}
                rows={filteredHVADataRows}
                //expandRow={expandRow}
                cellEditCallback={cellEditCallback}
                hasPagination={true}
              />
              </div>
            )}
            
          </div>
        ) || (
          <div>Loading HVA...</div>
        )}
        
      </Body>  
      <Footer className="AlertSetupModal-Footer"> 
          <Button className="m-0 AlertSetupModalBackButton" disabled={saveHVADisabled} onClick={()=>saveClicked()}>Save</Button>
      </Footer>
      {!!manageRiskLevelsRow && (
        <ManageThresholdRiskLevelsDialog
          onClose={()=>setManageRiskLevelsRow()}
          hazard={manageRiskLevelsRow}
          hvaData={hvaData}
          setHVAData={setHVAData}
        />
      )}
    </Dialog>
	)
}