import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router';
import QuickCreatePersonaDialog from './QuickCreatePersonaDialog';
import { PreparePath } from 'utils/sharedUtils/sharedConst';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
export default function CreatePersonaDialog({ onClose, show, dialogType }) {
  const [
    showQuickCreatePersonaDialog,
    setShowQuickCreatePersonaDialog,
  ] = useState(false);

  const navigate = useNavigate();

  const onQuickCreatePersonaDialog = () => {
    setShowQuickCreatePersonaDialog(true);
  };
  const onQuickCreatePersonaDialogClose = () => {
    setShowQuickCreatePersonaDialog(false);
  };
  const onSaveCloseModal = () => {
    onClose(true);
  };

  return (
    <>
      <Modal
        show={show}
        onHide={onClose}
        centered
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton closeVariant="white">
          <Modal.Title>Create Persona</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p className="mb-0">
            Default attributes will provide default biodata fields to fill out,
            while also allowing you to create your own. Use a blank persona to
            custom label and fill every field.
          </p>

          <div className="mt-5 button-group flex-wrap flex-md-nowrap">
            <StylishNewButton
              className="button--primary button--reverse px-3 flex-grow-1 w-100 w-md-auto mt-3 mt-md-0 ms-0 ms-md-3"
              type="button"
              onClick={onQuickCreatePersonaDialog}
            >
              Quick Create Persona
            </StylishNewButton>
            <StylishNewButton
              className="button--primary button--reverse px-3 flex-grow-1 w-100 w-md-auto mt-3 mt-md-0 ms-0 ms-md-3"
              onClick={() => {
                navigate(
                  `${PreparePath}/dashboard/media/personas/useblankpersona`
                );
              }}
              type="button"
            >
              Use Blank Persona
            </StylishNewButton>
            <StylishNewButton
              className="button--primary button--reverse px-3 flex-grow-1 w-100 w-md-auto mt-3 mt-md-0 ms-0 ms-md-3"
              type="button"
              onClick={() => {
                navigate(
                  `${PreparePath}/dashboard/media/personas/usedefaultpersona`
                );
              }}
            >
              Use Default Attributes
            </StylishNewButton>
          </div>

          <hr className="mb-0" />
        </Modal.Body>

        <Modal.Footer>
          <div className="button-group">
            <StylishNewButton
              className="button--secondary button--reverse"
              type="button"
              onClick={onClose}
            >
              Cancel
            </StylishNewButton>
          </div>
        </Modal.Footer>
      </Modal>
      {showQuickCreatePersonaDialog && (
        <QuickCreatePersonaDialog
          show={showQuickCreatePersonaDialog}
          onClose={onQuickCreatePersonaDialogClose}
          onSaveCloseModal={onSaveCloseModal}
          // updateDialogData={updateDialogData}
        />
      )}
    </>
  );
}
