import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Network from '../../../../../service/Network';
import API from '../../../../../service/api';
import { toast } from 'react-toastify';
import { AddInjectModal } from '../Modals/AddInjectOwnerModal';
import { AllIcon } from '../Icon';
import AddMethodModal from '../Modals/AddMethodModal';
import moment from 'moment';
import { useNavigate } from 'react-router';
import { HelpPlanInjectDetail, useSelfHostedKnowledgeBase } from 'assets/data/HelpContent';
import { inputStringLength, toastConfig } from 'assets/data/config';
import { AddEditFromTo } from '../Modals/AddEditFromTo';
import { PreparePath } from 'utils/sharedUtils/sharedConst';
import StylishHelp from 'components/DesignSystems/New/StylishHelp';
import StylishNewInput from 'components/DesignSystems/New/StylishNewInput';
import StylishNewSelect from 'components/DesignSystems/New/StylishNewSelect';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import StylishDateTimePicker from 'components/DesignSystems/form/StylishDateTimePicker';
import { endLoading, startLoading } from "../../../../../../reducers/loading/loading.action";

export const MSELInjectDetails = (props) => {
  const [allStatus, setAllStatus] = useState([]);
  const [allOwner, setAllOwner] = useState([]);
  const [allMethod, setAllMethod] = useState([]);
  const [showAddOwnerModal, setShowAddOwnerModal] = useState(false);
  const [showAddMethodModal, setShowAddMethodModal] = useState(false);
  const [eventList, setEventList] = useState([]);
  const [allFromTo, setAllFromTo] = useState([]);
  const [showAddFromModal, setShowAddFromModal] = useState(false);
  const [showAddToModal, setShowAddToModal] = useState(false);
  const [allListLoaded, setAllListLoaded] = useState(false);
  const [dialogType, setDialogType] = useState('');
  const [disableDateTime, setDisableDateTime] = useState(false);

  const {
    control,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors },
    trigger,
    clearErrors,
    setError,
  } = useForm();

  const selfHostedKnowledgeBase = useSelfHostedKnowledgeBase()

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const typeList = [
    { value: 'key', label: 'Key' },
    { value: 'enabling', label: 'Enabling' },
    { value: 'supporting', label: 'Supporting' },
  ];

  const customFilter = (option, searchText) => {
    if (option?.value === 'new') return true;
    if (option?.data?.title?.toLowerCase().includes(searchText.toLowerCase())) {
      return true;
    } else {
      return false;
    }
  };

  const { selectedWorkspace } = useSelector((state) => state.prepare.workspace);
  const reduxUser = useSelector((state) => state.prepare.user);

  const allPlanStatusLabelList = async () => {
    dispatch(startLoading());
    try {
      const sts = await Network.get(API.planStatusLabelList, {
        workspaceId: selectedWorkspace,
      });
      setAllStatus([
        ...sts.data.response.dataset.map((e) => {
          return {
            value: e.id,
            title: e.title,
            label: (
              <div className="d-flex align-items-center" key={e.id}>
                <span className="txt--sml">{e.title}</span>
              </div>
            ),
          };
        }),
      ]);
    } catch (error) {
      toast.error(error?.response?.data?.response?.status?.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };

  const getAllInjectMethod = async () => {
    dispatch(startLoading());
    try {
      const injectMethods = await Network.get(API.getAllInjectMethods, {
        workspaceId: selectedWorkspace,
      });
      setAllMethod([
        { value: 'new', label: 'Add New' },

        ...injectMethods.data.response.dataset.map((e) => ({
          value: e.id,
          title: e.name,
          label: (
            <div className="d-flex align-items-center" key={e.id}>
              <span
                className="status status--icon sml me-2"
                style={{ backgroundColor: e.color }}
              >
                {AllIcon.find((icon) => icon.name === e.icon)?.icon}
              </span>
              <span className="txt--sml">{e.name}</span>
            </div>
          ),
        })),
      ]);
    } catch (error) {
      toast.error(error?.response?.data?.response?.status?.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };

  const getInjectOwnerList = async () => {
    dispatch(startLoading());
    try {
      const injectOwner = await Network.get(API.getPlanInjectOwnerList, {
        workspaceId: selectedWorkspace,
      });
      setAllOwner([
        { value: 'new', label: 'Add New' },
        ...injectOwner.data.response.dataset.map((e) => {
          return {
            value: e.id,
            title: e.title,
            label: (
              <div className="d-flex align-items-center" key={e.id}>
                <span className="txt--sml">{e.title}</span>
              </div>
            ),
          };
        }),
      ]);
    } catch (error) {
      toast.error(error?.response?.data?.response?.status?.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };

  const fetchSingleInject = async () => {
    dispatch(startLoading());
    try {
      const selectedInject = await Network.get(API.fetchSingleInject, {
        id: props.injectId,
      });

      const injectFormData = getValues();
      const statusData = allStatus.find(
        (sts) => sts.value === selectedInject.data.response.dataset[0].statusId
      );
      const typeData = typeList.find(
        (type) => type.value === selectedInject.data.response.dataset[0].type
      );
      const methodData = allMethod.find(
        (method) =>
          method.value === selectedInject.data.response.dataset[0].planMethodId
      );
      const ownerData = allOwner.find(
        (owner) =>
          owner.value === selectedInject?.data?.response?.dataset[0]?.ownerId
      );
      const eventData = eventList.find(
        (event) =>
          event.value === selectedInject.data.response.dataset[0].eventId
      );
      const fromData = allFromTo.find(
        (from) => from.value === selectedInject.data.response.dataset[0].from_id
      );
      const toData = allFromTo.find(
        (to) => to.value === selectedInject.data.response.dataset[0].to_id
      );

      !injectFormData.eventList && setValue('eventList', eventData);
      !injectFormData?.Owner && setValue('Owner', ownerData);
      !injectFormData.methods && setValue('methods', methodData);
      !injectFormData.type && setValue('type', typeData);
      !injectFormData.status && setValue('status', statusData);
      setDisableDateTime(statusData?.title === 'Unscheduled');
      !injectFormData.from && setValue('from', fromData);
      !injectFormData.to && setValue('to', toData);
      !injectFormData.dateTime &&
        setValue(
          'dateTime',
          statusData?.title === 'Unscheduled'
            ? null
            : moment(selectedInject.data.response.dataset[0].startDate).toDate()
        );
      !injectFormData.title &&
        setValue('title', selectedInject.data.response.dataset[0].injectTitle);
      props.onNameChange(selectedInject.data.response.dataset[0].injectTitle);
    } catch (error) {
      toast.error(error?.response?.data?.response?.status?.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };

  const fetchEvents = async () => {
    dispatch(startLoading());
    try {
      const events = await Network.get(API.fetchEvents, {
        workspaceId: selectedWorkspace,
      });
      setEventList([
        ...events.data.response.dataset.map((e) => {
          return {
            value: e.id,
            label: (
              <div className="d-flex align-items-center" key={e.id}>
                <span className="txt--sml">{e.name}</span>
              </div>
            ),
          };
        }),
      ]);
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(endLoading());
    }
  };

  const fromToList = async () => {
    dispatch(startLoading());
    try {
      const fromTo = await Network.get(API.fromToList, {
        workspaceId: selectedWorkspace,
      });
      setAllFromTo([
        { value: 'new', label: 'Add New' },
        ...fromTo.data.response.dataset.map((e) => {
          return {
            value: e.id,
            label: e.title,
            title: e.title,
          };
        }),
      ]);
    } catch (error) {
      toast.error(error?.response?.data?.response?.status?.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };

  useEffect(() => {
    fetchEvents();
    allPlanStatusLabelList();
    getInjectOwnerList();
    getAllInjectMethod();
    fromToList();
  }, []);

  useEffect(() => {
    if (
      allStatus.length &&
      allMethod.length &&
      allOwner.length &&
      eventList.length &&
      allFromTo.length &&
      !allListLoaded
    ) {
      fetchSingleInject();
      setAllListLoaded(true);
    }
  }, [eventList, allOwner, allMethod, allStatus, allFromTo]);

  const onSubmit = (data) => {
    dispatch(startLoading());
    try {
      Network.post(API.inject, {
        id: props.injectId,
        workspaceId: selectedWorkspace,
        statusId: data.status.value,
        eventId: data.eventList.value,
        from: data.from.value,
        to: data.to.value,
        ownerId: data.Owner.value,
        planMethodId: data.methods.value,
        startDate: data.status.title === 'Unscheduled' ? null : data.dateTime,
        type: data.type.value,
        title: data.title,
      })
        .then(() => {
          props.onNextClick(1);
          props.onNameChange(data.title);
        })
        .catch();
    } catch (error) {
      toast.error(error?.response?.data?.response?.status?.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };

  const submitAndExit = async () => {
    const data = getValues();
    dispatch(startLoading());
    try {
      Network.post(API.inject, {
        id: props.injectId,
        workspaceId: selectedWorkspace,
        statusId: data.status.value,
        eventId: data.eventList.value,
        from: data.from.value,
        to: data.to.value,
        ownerId: data.Owner.value,
        planMethodId: data.methods.value,
        startDate: data.dateTime,
        type: data.type.value,
        title: data.title,
      })
        .then(() => {
          navigate(-1, {
            replace: true,
          });
          props.onNameChange(data.title);
        })
        .catch();
    } catch (error) {
      toast.error(error?.response?.data?.response?.status?.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };

  const onCloseAfterSaveOwner = (afterSave) => {
    if (afterSave?.id) {
      setAllOwner([
        ...allOwner,
        {
          label: (
            <div className="d-flex align-items-center" key={afterSave.id}>
              <span className="txt--sml">{afterSave.title}</span>
            </div>
          ),
          value: afterSave?.id,
          title: afterSave.title,
        },
      ]);

      clearErrors('Owner');
      setValue('Owner', {
        label: (
          <div className="d-flex align-items-center" key={afterSave.id}>
            <span className="txt--sml">{afterSave.title}</span>
          </div>
        ),
        value: afterSave?.id,
      });
    } else {
      // setOwnerAfterSave("");
      setValue('Owner', '');
    }
    setShowAddOwnerModal(false);
    setDialogType('New');
  };

  const onCloseAfterSaveMethod = (afterSave) => {
    if (afterSave?.id) {
      setAllMethod([
        ...allMethod,
        {
          label: (
            <div className="d-flex align-items-center" key={afterSave.id}>
              <span
                className="status status--icon sml me-2"
                style={{ backgroundColor: afterSave.color }}
              >
                {AllIcon.find((icon) => icon.name === afterSave.icon)?.icon}
              </span>
              <span className="txt--sml">{afterSave.name}</span>
            </div>
          ),
          value: afterSave?.id,
          title: afterSave.title,
        },
      ]);
      getAllInjectMethod();

      clearErrors('methods');
      setValue('methods', {
        label: (
          <div className="d-flex align-items-center" key={afterSave.id}>
            <span
              className="status status--icon sml me-2"
              style={{ backgroundColor: afterSave.color }}
            >
              {AllIcon.find((icon) => icon.name === afterSave.icon)?.icon}
            </span>
            <span className="txt--sml">{afterSave.name}</span>
          </div>
        ),
        value: afterSave?.id,
      });
    } else {
      // setOwnerAfterSave("");
      setValue('methods', '');
    }
    setShowAddMethodModal(false);
    // setUpdateDialogData(null);
    setDialogType('New');
  };

  const onCloseAfterSaveFrom = (afterSave) => {
    setShowAddFromModal(false);
    if (afterSave?.id) {
      setAllFromTo([
        ...allFromTo,
        {
          label: afterSave?.title,
          value: afterSave?.id,
          title: afterSave.title,
        },
      ]);
      setValue('from', {
        label: afterSave?.title,
        title: afterSave.title,

        value: afterSave?.id,
      });
    } else {
      setValue('from', '');
    }
  };

  const onCloseAfterSaveTo = (afterSave) => {
    setShowAddToModal(false);
    if (afterSave?.id) {
      setAllFromTo([
        ...allFromTo,
        {
          label: afterSave?.title,
          value: afterSave?.id,
          title: afterSave.title,
        },
      ]);
      // fromToList();
      setValue('to', {
        label: afterSave?.title,
        value: afterSave?.id,
      });
    } else {
      setValue('to', '');
    }
  };

  return (
    <>
      <div className="d-flex align-items-center mb-4">
        <h4 className="m-0">Inject Details</h4>
        <StylishHelp
          classes={'ms-auto sml flex-shrink-0'}
          title={HelpPlanInjectDetail.title}
          content={HelpPlanInjectDetail.content}
          link={selfHostedKnowledgeBase || HelpPlanInjectDetail.link}
        />
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-block">
          <div className="row">
            <div className="col-md-6 mb-3">
              <label className="form-label">Title</label>
              <Controller
                control={control}
                name={'title'}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, value } }) => (
                  <StylishNewInput
                    onChange={(e) => {
                      onChange(e);
                    }}
                    value={value}
                    type="text"
                    name="title"
                    maxLength={inputStringLength}
                    className="form-control"
                    placeholder="Title"
                  />
                )}
              />
              {errors.title && (
                <span className="form-text form-error">
                  This is a required field
                </span>
              )}
            </div>
            <div className="col-md-6 mb-3">
              <label className="form-label">Status</label>
              <Controller
                control={control}
                name="status"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <StylishNewSelect
                    options={allStatus}
                    // defaultValue={value}
                    // onChange={onChange}
                    onChange={(event) => {
                      if (!event.value) {
                        setError('status');
                      } else if (event.value) {
                        clearErrors('status');
                        console.log('data: ', event.title);
                        if (event.title === 'Unscheduled') {
                          setDisableDateTime(true);
                        } else {
                          setDisableDateTime(false);
                        }
                      }
                      return onChange(event);
                    }}
                    value={value}
                    placeholder={'Select...'}
                    isClearable={false}
                    isSearchable={true}
                    isMulti={false}
                    isDisabled={false}
                  />
                )}
              />
              {getValues('status')?.title === 'Unscheduled' && (
                <span className="form-text form-error">
                  On saving the form the date and time will be removed
                </span>
              )}
              {errors.status && (
                <span className="form-text form-error">
                  This is a required field
                </span>
              )}
            </div>
            <div className="col-md-6 mb-3">
              <label className="form-label">Scenario</label>
              <Controller
                control={control}
                name="eventList"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <StylishNewSelect
                    options={eventList}
                    value={value}
                    onChange={(event) => {
                      if (!event.value) {
                        setError('eventList');
                      } else if (event.value) {
                        clearErrors('eventList');
                      }
                      return onChange(event);
                    }}
                    isClearable={false}
                    isSearchable={true}
                    isMulti={false}
                    isDisabled={false}
                  />
                )}
              />
              {errors.eventList && (
                <span className="form-text form-error">
                  This is a required field
                </span>
              )}
            </div>
            <div className="col-md-6 mb-3">
              <label className="form-label">Date &amp; Time (-12)</label>
              <Controller
                control={control}
                name="dateTime"
                rules={{ required: !disableDateTime }}
                render={({ field: { onChange, value } }) => (
                  <StylishDateTimePicker
                    onChange={onChange}
                    selected={value}
                    selectsStart
                    value={value}
                    isClearable={true}
                    timeFormat="HH:mm"
                    timeIntervals={20}
                    timeCaption="time"
                    dateFormat={
                      reduxUser?.timeFormat === 'hour12Mode'
                        ? 'MMMM d, yyyy hh:mm a'
                        : 'MMMM d, yyyy HH:mm'
                    }
                    placeholderText={'Choose Start Date'}
                    disableDateTimeLabel={true}
                    type="datetime-local"
                    disabled={disableDateTime}
                  />
                )}
              />
              {errors.dateTime && (
                <span className="form-text form-error">
                  This is a required field
                </span>
              )}
            </div>
            <div className="col-md-6 mb-3">
              <label className="form-label">Type</label>
              <Controller
                control={control}
                name="type"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <StylishNewSelect
                    options={typeList}
                    defaultValue={value}
                    // filterOption={customFilterwithNoAddNewFeild}
                    value={value}
                    onChange={(event) => {
                      if (!event.value) {
                        setError('type');
                      } else if (event.value) {
                        clearErrors('type');
                      }
                      return onChange(event);
                    }}
                    placeholder={'Select...'}
                    isClearable={false}
                    isSearchable={true}
                    isMulti={false}
                    isDisabled={false}
                  />
                )}
              />
              {errors.type && (
                <span className="form-text form-error">
                  This is a required field
                </span>
              )}
            </div>
            <div className="col-md-6 mb-3">
              <label className="form-label">Owner</label>
              <Controller
                control={control}
                name="Owner"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <StylishNewSelect
                    options={allOwner}
                    defaultValue={value}
                    value={value}
                    filterOption={customFilter}
                    onChange={(e) => {
                      if (e?.value === 'new') {
                        setShowAddOwnerModal(true);
                      } else if (!e) {
                        setError('Owner');
                      } else if (e.value) {
                        clearErrors('Owner');
                      }
                      return onChange(e);
                    }}
                    isClearable={true}
                    isSearchable={true}
                    isMulti={false}
                    isDisabled={false}
                  />
                )}
              />
              {errors.Owner && (
                <span className="form-text form-error">
                  This is a required field
                </span>
              )}
            </div>
            <div className="col-md-6 mb-3">
              <label className="form-label">From</label>
              <Controller
                control={control}
                name="from"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <StylishNewSelect
                    options={allFromTo}
                    defaultValue={value}
                    value={value}
                    filterOption={customFilter}
                    onChange={(e) => {
                      if (e?.value === 'new') {
                        setShowAddFromModal(true);
                      }
                      if (!!e) {
                        clearErrors('from');
                      }
                      if (!e) {
                        setValue('from', '');
                        setError('from');
                      }
                      return onChange(e);
                    }}
                    isClearable={true}
                    isSearchable={true}
                    isMulti={false}
                    isDisabled={false}
                  />
                )}
              />
              {errors.from && (
                <span className="form-text form-error">
                  This is a required field
                </span>
              )}
            </div>

            <div className="col-md-6 mb-3">
              <label className="form-label">To</label>
              <Controller
                control={control}
                name="to"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <StylishNewSelect
                    options={allFromTo}
                    defaultValue={value}
                    filterOption={customFilter}
                    onChange={(e) => {
                      if (e?.value === 'new') {
                        setShowAddToModal(true);
                      }
                      if (!!e) {
                        clearErrors('to');
                      }
                      if (!e) {
                        setValue('to', '');
                        setError('to');
                      }
                      return onChange(e);
                    }}
                    value={value}
                    isClearable={true}
                    isSearchable={true}
                    isMulti={false}
                    isDisabled={false}
                  />
                )}
              />
              {errors.to && (
                <span className="form-text form-error">
                  This is a required field
                </span>
              )}
            </div>
            <div className="col-md-6">
              <label className="form-label">Inject Method</label>
              <Controller
                control={control}
                name="methods"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <StylishNewSelect
                    options={allMethod}
                    defaultValue={value}
                    filterOption={customFilter}
                    value={value}
                    onChange={(e) => {
                      if (e?.value === 'new') {
                        setShowAddMethodModal(true);
                      } else if (!e) {
                        setError('methods');
                      } else if (e.value) {
                        clearErrors('methods');
                      }
                      return onChange(e);
                    }}
                    isClearable={true}
                    isSearchable={true}
                    isMulti={false}
                    isDisabled={false}
                  />
                )}
              />
              {errors.methods && (
                <span className="form-text form-error">
                  This is a required field
                </span>
              )}
            </div>
          </div>
        </div>
        <div className="col-md-12 mt-4">
          <div className="button-group justify-content-md-end d-block d-md-flex">
            <StylishNewButton
              className="button button--tertiary button--reverse w-100 w-md-auto"
              type="button"
              onClick={async () => {
                const valid = await trigger();
                if (valid) {
                  submitAndExit();
                }
              }}
            >
              Save &amp; Exit
            </StylishNewButton>
            <StylishNewButton
              className="button button--primary w-100 w-md-auto ms-0 ms-md-3 mt-3 mt-md-0"
              type="submit"
            >
              Next
            </StylishNewButton>
          </div>
        </div>
      </form>
      {showAddOwnerModal && (
        <AddInjectModal
          show={showAddOwnerModal}
          onClose={(afterSave) => onCloseAfterSaveOwner(afterSave)}
        />
      )}
      {showAddMethodModal && (
        <AddMethodModal
          show={showAddMethodModal}
          onClose={(afterSave) => onCloseAfterSaveMethod(afterSave)}
        />
      )}
      {showAddFromModal && (
        <AddEditFromTo
          show={showAddFromModal}
          onClose={(afterSave) => onCloseAfterSaveFrom(afterSave)}
          nameOfModal={'From'}
        />
      )}
      {showAddToModal && (
        <AddEditFromTo
          show={showAddToModal}
          onClose={(afterSave) => onCloseAfterSaveTo(afterSave)}
          nameOfModal={'To'}
        />
      )}
    </>
  );
};
