import React from 'react';
import { useNavigate } from 'react-router-dom';
import SearchBar from '../../component/SearchBar/SearchBar';
import StylishNewSelect from 'components/DesignSystems/New/StylishNewSelect';
import StylishNewTable from 'components/DesignSystems/New/StylishNewTable';
import StylishNewTableAction from 'components/DesignSystems/New/StylishNewTableAction';
import { SharedIcon } from 'components/SharedIcon/SharedIcon';

export const ReportDashboard = () => {
  const navigate = useNavigate();

  const msel_list_perpage = [
    { value: '5', label: '5 per page' },
    { value: '15', label: '15 per page' },
    { value: '30', label: '30 per page' },
    { value: '50', label: '50 per page' },
  ];

  const columns_report_data = [
    {
      dataField: 'incident_name',
      text: 'Incident Name',
      sort: true,
      headerAttrs: { title: 'Sort By:' },
      attrs: { title: 'Incident Name' },
    },
    {
      dataField: 'incident_type',
      text: 'Incident Type',
      sort: true,
      attrs: { title: 'Incident Type' },
    },
    {
      dataField: 'start_date',
      text: 'Start Date',
      sort: true,
      attrs: { title: 'Start Date' },
    },
    {
      dataField: 'end_date',
      text: 'End Date',
      sort: true,
      attrs: { title: 'End Date' },
    },
    {
      dataField: 'escalated',
      text: 'Escalated',
      sort: true,
      attrs: { title: 'Escalated' },
    },
    {
      dataField: '',

      text: 'Action',
      isDummyField: true,
      formatter: (cell, row, rowIndex) => (
        <StylishNewTableAction
          name="Report"
          cell={cell}
          row={row}
          rowIndex={rowIndex}
          onDelete={onDeleteReportData}
          onEdit={onEditReportData}
          onView={onViewReportData}
        />
      ),
      headerStyle: {
        width: '100px',
      },
      headerAlign: 'center',
      align: 'center',
      attrs: { title: 'Action' },
    },
  ];

  const row_report_data = [
    {
      id: 1,
      incident_name: 'Manistique Spill',
      incident_type: 'Process Area – Major Liquid Hydrocarbon Release',
      start_date: '06 Sep 22 -1100 (-12)',
      end_date: '11 Sep 22 -1100 (-12)',
      escalated: 'Yes',
    },
    {
      id: 2,
      incident_name: 'Manistique Spill',
      incident_type: 'Process Area – Major Liquid Hydrocarbon Release',
      start_date: '06 Sep 22 -1100 (-12)',
      end_date: '11 Sep 22 -1100 (-12)',
      escalated: 'Yes',
    },
    {
      id: 3,
      incident_name: 'Manistique Spill',
      incident_type: 'Process Area – Major Liquid Hydrocarbon Release',
      start_date: '06 Sep 22 -1100 (-12)',
      end_date: '11 Sep 22 -1100 (-12)',
      escalated: 'Yes',
    },
    {
      id: 4,
      incident_name: 'Manistique Spill',
      incident_type: 'Process Area – Major Liquid Hydrocarbon Release',
      start_date: '06 Sep 22 -1100 (-12)',
      end_date: '11 Sep 22 -1100 (-12)',
      escalated: 'Yes',
    },
    {
      id: 5,
      incident_name: 'Manistique Spill',
      incident_type: 'Process Area – Major Liquid Hydrocarbon Release',
      start_date: '06 Sep 22 -1100 (-12)',
      end_date: '11 Sep 22 -1100 (-12)',
      escalated: 'Yes',
    },
  ];

  const expandRow = {
    parentClassName: 'parent-expand-tr',
    className: 'child-expand-tr',
    renderer: (row) => {
      return (
        <>
          <div className="row">
            <div className="col-md-12 mb-4">
              <div className="d-flex align-items-center">
                <label className="form-label m-0 me-3">Ongoing</label>
                <div className="form-switcher d-inline-flex">
                  <input type="checkbox" id="is__ongoing" />
                  <label htmlFor="is__ongoing"></label>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-3">
              <label className="form-label d-block mb-0">Location</label>
              None
            </div>
            <div className="col-md-4 mb-3">
              <label className="form-label d-block mb-0">
                Location - Sub Entity
              </label>
              None
            </div>
            <div className="col-md-4 mb-3">
              <label className="form-label d-block mb-0">
                Location - Enterprise
              </label>
              None
            </div>
            <div className="col-md-4 mb-3">
              <label className="form-label d-block mb-0">
                Incident Location (asset)
              </label>
              None
            </div>
            <div className="col-md-4 mb-3">
              <label className="form-label d-block mb-0">
                ISC Personnel Directly Involved
              </label>
              None
            </div>
            <div className="col-md-4 mb-3 mb-md-0">
              <label className="form-label d-block mb-0">Teams Activated</label>
              None
            </div>
            <div className="col-md-12">
              <label className="form-label d-block mb-0">Brief Synopsis</label>
              <p className="m-0">
                On April 14, 2017, the Duty Officer notified of an oil gas
                blowout on Well #3 in Prudhoe Bay, Alaska. The incident began at
                0815 local time. The well is venting gas and a mist of oil is
                blowing off the pad into snow covered tundra. The pad has been
                evacuated until the well can be shut in. There is no estimate of
                the amount of oil and gas being discharged. Notification only,
                no requests for additional resources at this time.
              </p>
            </div>
          </div>
          <div className="button-group mt-4">
            <button type="button" className="button--icon primary">
              <SharedIcon iconName="visibility" />
            </button>
            <button type="button" className="button--icon primary ms-2">
              <SharedIcon iconName="stylus" />
            </button>
            <button type="button" className="button--icon primary ms-2">
              <SharedIcon iconName="delete" />
            </button>
          </div>
        </>
      );
    },
    showExpandColumn: true,
    expandByColumnOnly: true,
    expandHeaderColumnRenderer: ({ isAnyExpands }) => {
      if (isAnyExpands) {
        return <i className="fa fa-chevron-down" aria-hidden="true"></i>;
      }
      return <i className="fa fa-chevron-right" aria-hidden="true"></i>;
    },
    expandColumnRenderer: ({ expanded }) => {
      if (expanded) {
        return (
          <>
            <div className="d-flex align-items-center justify-content-md-center">
              <span className="me-2">Details</span>
              <i className="fa fa-chevron-down" aria-hidden="true"></i>
            </div>
          </>
        );
      }
      return (
        <>
          <div className="d-flex align-items-center justify-content-md-center">
            <span className="me-2">Details</span>
            <i className="fa fa-chevron-right" aria-hidden="true"></i>
          </div>
        </>
      );
    },
  };

  const onDeleteReportData = () => {
    return false;
  };

  const onEditReportData = () => {
    return false;
  };

  const onViewReportData = (row) => {
    navigate(`/dashboard/report/details/${row.id}`);
  };

  const createHandler = () => {
    navigate('/dashboard/report/create');
  };

  return (
    <>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <button onClick={() => navigate(-1)}>
              <SharedIcon iconName="keyboard_arrow_left" classes="me-2" />
              Back
            </button>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Incident Report list
          </li>
        </ol>
      </nav>
      <div className="d-md-flex align-items-center mb-4">
        <button
          className="button button--primary w-100 w-md-auto mb-3 mb-md-0"
          onClick={createHandler}
        >
          Create Incident Report
        </button>
        <div className="ms-auto">
          <SearchBar />
        </div>
      </div>
      <div className="form-block">
        <div className="d-md-flex align-items-center mb-3">
          <p className="m-0 me-md-3 mb-3 mb-md-0 weight-600">
            5 Incident Report
          </p>
          {/* appears non-functional but should use PaginationDropdown if revived */}
          <StylishNewSelect
            classes="ms-auto"
            options={msel_list_perpage}
            defaultValue={msel_list_perpage[0]}
            isClearable={true}
            isSearchable={false}
            isMulti={false}
            isDisabled={false}
          />
        </div>
        <StylishNewTable
          keyField={'id'}
          columns={columns_report_data}
          rows={row_report_data}
          onDelete={onDeleteReportData}
          onEdit={onEditReportData}
          expandRow={expandRow}
        />
      </div>
    </>
  );
};
