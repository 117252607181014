import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import allTimeZones from 'assets/data/timezones.json';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import API from '../../../service/api';
import Network from '../../../service/Network';
import { toast } from 'react-toastify';
import { updateWorkSpaceTimeZone } from '../../../store/workspaces/workspaces.action';
import { getLoaclTimeZoneAndOffset } from '../../../utils/dashboardApplication';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import StylishNewSelect from 'components/DesignSystems/New/StylishNewSelect';
import { StylishConfirmDialog } from 'components/DesignSystems/New/StylishConfirmDialog';
import { toastConfig } from 'assets/data/config';
import { endLoading, startLoading } from "../../../../reducers/loading/loading.action";

export default function TimezoneModal({ onClose, show, selectedTz, tzOffset }) {
  const [showSecondModal, setshowSecondModal] = useState(false);
  const [timeZone, setTimeZone] = useState();
  const [timezoneOffset, setTimeZoneOffset] = useState();
  const [selectedTimeZone, setSelectedTimeZone] = useState();

  const dispatch = useDispatch();

  const workspaceId = useSelector(
    (state) => state.prepare.workspace.selectedWorkspace
  );

  const { control, setError, clearErrors, setValue, handleSubmit } = useForm();

  const closeModal = () => {
    setshowSecondModal(false);
  };

  useEffect(() => {
    setTimeZone(
      allTimeZones.map((e) => {
        return {
          value: e.value,
          label: e.text,
        };
      })
    );
    const fullTimeZone = allTimeZones.find((tz) => tz.value === selectedTz);
    setValue('timezones', {
      value: fullTimeZone.value,
      label: fullTimeZone.text,
    });
    setTimeZoneOffset(timezoneOffset);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTz, tzOffset]);

  const onReset = () => {
    setshowSecondModal(true);
  };

  const onSelectTimeZone = (tzValue) => {
    const fullTimeZone = allTimeZones.find((tz) => tz.value === tzValue);
    setSelectedTimeZone(tzValue);
    setTimeZoneOffset(fullTimeZone.offset * 60);
  };

  const onResetConfirm = () => {
    const { timezone, offset } = getLoaclTimeZoneAndOffset();
    setValue(
      'timezones',
      timeZone.find((tZ) => tZ.value === timezone)
    );
    setSelectedTimeZone(timezone);
    setTimeZoneOffset(offset);
    setshowSecondModal(false);
  };

  const onSubmit = async () => {
    if (timezoneOffset === undefined || showSecondModal === true) return false;
    dispatch(startLoading());
    try {
      await Network.post(API.addWorkspaceLocation, {
        timezone: selectedTimeZone,
        offset: timezoneOffset,
        workspaceId: workspaceId,
      });
      toast.success('Timezone updated successfully', toastConfig);
      dispatch(updateWorkSpaceTimeZone(selectedTimeZone, timezoneOffset));
      onClose();
    } catch (error) {
      toast.error(error.response.data.response.status.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };

  useEffect(() => {
    const listener = (event) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        handleSubmit(onSubmit)();
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, []);

  return (
    <Modal
      show={show}
      onHide={onClose}
      centered
      backdrop="static"
      keyboard={false}
      size="lg"
    >
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title>Set Timezone</Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <div className="">
            <Controller
              // onKeyDown={handleKeyDown}
              control={control}
              name="timezones"
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <StylishNewSelect
                  options={timeZone}
                  defaultValue={value}
                  value={value}
                  onChange={(e) => {
                    if (!e) {
                      setError('timezones');
                    } else if (e.value) {
                      onSelectTimeZone(e.value);
                      clearErrors('timezones');
                    }
                    return onChange(e);
                  }}
                  placeholder={'Select Timezone'}
                  isClearable={false}
                  isSearchable={true}
                  isMulti={false}
                  isDisabled={false}
                />
              )}
            />
          </div>
        </Modal.Body>
        <div>
          <Modal.Footer>
            <div className="button-group">
              <StylishNewButton
                className="button button--primary"
                // onClick={onSubmit}
                type="submit"
              >
                Submit
              </StylishNewButton>
              <StylishNewButton
                className="button button--tertiary"
                onClick={onReset}
              >
                Reset To Local Time
              </StylishNewButton>
              <StylishNewButton
                className="button button--secondary button--reverse"
                onClick={onClose}
              >
                Cancel
              </StylishNewButton>
            </div>
          </Modal.Footer>
        </div>
      </form>
      {showSecondModal && (
        <StylishConfirmDialog
          show={showSecondModal}
          dialogButtonText={'Yes'}
          onClose={closeModal}
          dialogTitle={'Reset Timezone'}
          dialogContent={'Are you sure you want to Reset Your Timezone?'}
          onConfirm={onResetConfirm}
        />
      )}
    </Modal>
  );
}
