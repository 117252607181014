import {
  getChecklistFormAssetAndIncidentType,
  getTeamChecklistFormAssetAndIncidentType,
  upsertInitialIncidentReportChecklist,
  upsertInitialIncidentReportTeamChecklist,
} from 'actions/ReportActions';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import StylishNewSelect from 'components/DesignSystems/New/StylishNewSelect';
import { BPIncidentTypes } from 'components/IncidentConfig/IncidentTypes';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import generateUUID from 'utils/sharedUtils/generateUUID';
import {
  EntitySub,
  TeamsActivated as ConstantTeamsActivated,
} from './ReportConstant';
import TinyMCEWithFormController from 'components/DesignSystems/New/TinyMCEWithFormController';
import { StylishConfirmDialog } from '../DesignSystems/New/StylishConfirmDialog';

export default function CreateChecklist({
  onChangeTab,
  selectedChecklist,
  type,
  setIsCreateChecklist,
}) {
  let locationAssetOptions = EntitySub.filter(
    (item) => item.Asset !== 'N/A'
  ).map((item) => {
    return {
      value: item.Asset,
      label: item.Asset,
    };
  });

  const locationAsset = useSelector((state) => {
    return state.app.locationAsset.map((asset) => {
      return {
        label: asset.location_asset,
        value: asset.location_asset_id,
      };
    });
  });

  if (!!locationAsset && Array.isArray(locationAsset)) {
    locationAssetOptions = locationAsset;
  }

  const [responsibilityOptions, setResponsibilityOptions] = useState();
  const [showCancelModal, setShowCancelModal] = useState(false);

  const reduxNestedCategoryItems = useSelector((state) => {
    return state.app.nestedCategoryItems;
  });

  useEffect(() => {
    if (!!reduxNestedCategoryItems) {
      const formattedOptions = reduxNestedCategoryItems.map((item) => {
        return {
          ...item,
          label: item.category_item_name,
          value: item.id,
        };
      });

      setResponsibilityOptions([...formattedOptions]);
    }
  }, [reduxNestedCategoryItems]);

  const distributionLists = useSelector((state) => {
    return state.app.distributionListItems;
  });

  let TeamsActivated;

  if (!!distributionLists && Array.isArray(distributionLists)) {
    TeamsActivated = distributionLists.map((list) => {
      return { value: list.name, label: list.name };
    });
  } else {
    TeamsActivated = ConstantTeamsActivated;
  }

  const reduxDispatch = useDispatch();
  const [isLoaded, setIsLoaded] = useState(true);
  const [isTeamLoaded, setIsTeamLoaded] = useState(true);
  const [checklistId, setChecklistId] = useState(null);
  //Site Checklist
  const hasAddInitialIncidentReportChecklistError = useSelector(
    (state) => state.app.hasAddInitialIncidentReportChecklistError
  );
  const isAllInitialIncidentReportChecklistLoaded = useSelector(
    (state) => state.app.isAllInitialIncidentReportChecklistLoaded
  );
  const isAddInitialIncidentReportChecklistLoaded = useSelector(
    (state) => state.app.isAddInitialIncidentReportChecklistLoaded
  );

  //Team Checklist
  const hasAddInitialIncidentReportTeamChecklistError = useSelector(
    (state) => state.app.hasAddInitialIncidentReportTeamChecklistError
  );
  const isAllInitialIncidentReportTeamChecklistLoaded = useSelector(
    (state) => state.app.isAllInitialIncidentReportTeamChecklistLoaded
  );
  const isAddInitialIncidentReportTeamChecklistLoaded = useSelector(
    (state) => state.app.isAddInitialIncidentReportTeamChecklistLoaded
  );

  useEffect(() => {
    if (!!selectedChecklist) {
      patchEditData(selectedChecklist);
    } else {
      reset();
    }
  }, [selectedChecklist]);
  const patchEditData = (checklistData) => {
    const locationAssetData = locationAssetOptions.find(
      (item) => item.value === checklistData.asset
    );
    setValue(
      'asset',
      !!locationAssetData
        ? locationAssetData
        : { value: checklistData.asset, label: checklistData.asset }
    );
    setValue('incident_type', {
      value: checklistData.incident_type,
      label: checklistData.incident_type,
    });
    setValue('teams_activated', {
      value: checklistData.teams_activated,
      label: checklistData.teams_activated,
    });
    setValue('site_checklist', checklistData.site_checklist);
    setValue('team_checklist', checklistData.team_checklist);
    setChecklistId(checklistData.id);
  };
  useEffect(() => {
    if (
      !isLoaded &&
      isAllInitialIncidentReportChecklistLoaded &&
      hasAddInitialIncidentReportChecklistError
    ) {
      setIsLoaded(true);
    } else if (
      !isLoaded &&
      isAllInitialIncidentReportChecklistLoaded &&
      isAddInitialIncidentReportChecklistLoaded
    ) {
      onChangeTab(undefined, 'Manage Checklist');
      setIsCreateChecklist(false);
    }
  }, [
    isAddInitialIncidentReportChecklistLoaded,
    isAllInitialIncidentReportChecklistLoaded,
    isLoaded,
    onChangeTab,
    hasAddInitialIncidentReportChecklistError,
  ]);

  useEffect(() => {
    if (
      !isLoaded &&
      isAllInitialIncidentReportChecklistLoaded &&
      hasAddInitialIncidentReportChecklistError
    ) {
      setIsLoaded(true);
    } else if (
      !isTeamLoaded &&
      isAllInitialIncidentReportTeamChecklistLoaded &&
      isAddInitialIncidentReportTeamChecklistLoaded
    ) {
      setIsCreateChecklist(false);
    }
  }, [
    isAllInitialIncidentReportTeamChecklistLoaded,
    isAddInitialIncidentReportTeamChecklistLoaded,
    isTeamLoaded,
    onChangeTab,
    hasAddInitialIncidentReportTeamChecklistError,
  ]);

  const {
    handleSubmit,
    control,
    setValue,
    reset,
    getValues,
    formState: { errors, isValid, isSubmitted, isDirty },
  } = useForm({ mode: 'onChange' });

  const onSubmit = (data) => {
    const updatedData = {
      asset: data.asset.label,
      incident_type: data.incident_type.value,
      site_checklist: data.site_checklist,
      team_checklist: data.team_checklist,
      teams_activated: data.teams_activated?.value,
      id: checklistId ? checklistId : generateUUID(),
    };
    if (type === 'Site Checklist') {
      reduxDispatch(upsertInitialIncidentReportChecklist(updatedData));
      setIsLoaded(false);
    } else if (type === 'Team Checklist') {
      reduxDispatch(upsertInitialIncidentReportTeamChecklist(updatedData));
      setIsTeamLoaded(false);
    }
  };

  const handleCancel = () => {
    if (isDirty) {
      setShowCancelModal(true);
    } else {
      onCancel();
    }
  };

  const onCancel = () => {
    reset();
    setShowCancelModal(false);
    setIsCreateChecklist(false);
    onChangeTab(undefined, 'Manage Checklist');
  };

  const onChangeValue = async (value, onChange) => {
    onChange(value);
    const asset = getValues('asset')?.label;
    const incident_type = getValues('incident_type')?.value;
    const teams_activated = getValues('teams_activated')?.value;
    if (
      type === 'Site Checklist' &&
      asset &&
      asset !== '' &&
      incident_type &&
      incident_type !== ''
    ) {
      const filteredChecklist = await reduxDispatch(
        getChecklistFormAssetAndIncidentType(asset, [incident_type])
      );
      if (filteredChecklist) {
        patchEditData(filteredChecklist[0]);
      }
    }
    if (
      type === 'Team Checklist' &&
      asset &&
      asset !== '' &&
      incident_type &&
      incident_type !== '' &&
      teams_activated &&
      teams_activated !== ''
    ) {
      const filteredChecklist = await reduxDispatch(
        getTeamChecklistFormAssetAndIncidentType(
          asset,
          [incident_type],
          [teams_activated]
        )
      );
      if (filteredChecklist) {
        patchEditData(filteredChecklist[0]);
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="form-block">
        <div className="row">
          <div className="col-md-6 mb-3">
            <label className="form-label">Area of Responsibility</label>
            <Controller
              control={control}
              name="asset"
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <StylishNewSelect
                  options={responsibilityOptions}
                  defaultValue={value}
                  value={value}
                  onChange={(e) => onChangeValue(e, onChange)}
                  placeholder={'Select an Area of Responsibility'}
                  isClearable={true}
                  isSearchable={true}
                  isMulti={false}
                  isDisabled={false}
                />
              )}
            />
            {errors.asset && (
              <span className="form-text form-error">
                This field is required
              </span>
            )}
          </div>
          <div className="col-md-6 mb-3">
            <label className="form-label">Incident Type</label>
            <Controller
              control={control}
              name="incident_type"
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <StylishNewSelect
                  options={BPIncidentTypes}
                  defaultValue={value}
                  value={value}
                  onChange={(e) => onChangeValue(e, onChange)}
                  placeholder={'Incident Type'}
                  isClearable={true}
                  isSearchable={true}
                  isMulti={false}
                  isDisabled={false}
                />
              )}
            />
            {errors.incident_type && (
              <span className="form-text form-error">
                This field is required
              </span>
            )}
          </div>
          {type === 'Team Checklist' && (
            <div className="col-md-12 mb-3">
              <label className="form-label">Relevant Teams Identified</label>
              <Controller
                control={control}
                name="teams_activated"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <StylishNewSelect
                    options={TeamsActivated}
                    defaultValue={value}
                    value={value}
                    onChange={(e) => onChangeValue(e, onChange)}
                    placeholder={'Relevant Teams Identified'}
                    isClearable={true}
                    isSearchable={true}
                    isMulti={false}
                    isDisabled={false}
                  />
                )}
              />
              {errors.incident_type && (
                <span className="form-text form-error">
                  This field is required
                </span>
              )}
            </div>
          )}
          {type === 'Site Checklist' && (
            <div className="col-md-12 mb-3">
              <label className="form-label">Site Checklist</label>
              <TinyMCEWithFormController
                name="site_checklist"
                control={control}
                defaultValue={selectedChecklist?.site_checklist}
                placeholder="Write Site Checklist"
                readOnly={false}
                rules={{ required: true, maxLength: 1000 }}
              />
              {errors.site_checklist?.type === 'required' && (
                <span className="form-text form-error">
                  This field is required
                </span>
              )}
              {errors.site_checklist?.type === 'maxLength' && (
                <span className="form-text form-error">
                  This field has a maximum length of 1000 characters.
                </span>
              )}
            </div>
          )}
          {type === 'Team Checklist' && (
            <div className="col-md-12 mb-3">
              <label className="form-label">Team Checklist</label>
              <TinyMCEWithFormController
                name="team_checklist"
                control={control}
                defaultValue={selectedChecklist?.team_checklist}
                placeholder="Write Team Checklist"
                readOnly={false}
                rules={{ required: true, maxLength: 1000 }}
              />
              {errors.team_checklist?.type === 'required' && (
                <span className="form-text form-error">
                  This field is required
                </span>
              )}
              {errors.team_checklist?.type === 'maxLength' && (
                <span className="form-text form-error">
                  This field has a maximum length of 1000 characters.
                </span>
              )}
            </div>
          )}
        </div>
      </div>
      <div className="col-md-12 mt-4">
        <div className="button-group justify-content-end align-items-md-end">
          <StylishNewButton
            className="button--secondary w-100 w-md-auto"
            onClick={handleCancel}
          >
            Cancel
          </StylishNewButton>
          <StylishNewButton
            disabled={isSubmitted && !isValid}
            className="button button--primary w-100 w-md-auto"
            type={'submit'}
            onClick={() => handleSubmit(onSubmit)}
          >
            Save
          </StylishNewButton>
          {isSubmitted && !isValid && (
            <span className="form-text form-error mt-4">
              Form Validation Error
            </span>
          )}
        </div>
      </div>
      <StylishConfirmDialog
        dialogTitle={'Confirm Cancel'}
        dialogContent="There are unsaved changes are you sure?"
        show={showCancelModal}
        onClose={() => setShowCancelModal(false)}
        onConfirm={onCancel}
      />
      {!isLoaded || (!isTeamLoaded && <div>loading...</div>)}
    </form>
  );
}
