import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import IconEmptyExercise from 'assets/images/icon__exercise-details-empty.svg';
import { useNavigate, useParams } from 'react-router-dom';
import SearchBar from '../../../component/SearchBar/SearchBar';
import ReactPaginate from 'react-paginate';
import Help from '../../../component/Help/Help';
import { MediaSiteView, useSelfHostedKnowledgeBase } from 'assets/data/HelpContent';
import { endLoading, startLoading } from 'reducers/loading/loading.action';
import { contentBlockLength } from 'assets/data/config';
import Network from 'PREPAREsrc/service/Network';
import API from 'PREPAREsrc/service/api';
import { toast } from 'react-toastify';
import { AllIcon } from 'assets/Icon/Icon';
import moment from 'moment';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { PreparePath } from 'utils/sharedUtils/sharedConst';
import { sanitizeHtml } from '../../../../utils/sanitizeHtml';

export default function SitesDetails() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();

  const selfHostedKnowledgeBase = useSelfHostedKnowledgeBase()

  const { selectedWorkspace, workspaceName } = useSelector((state) => {
    return state?.prepare?.workspace;
  });

  const perPage = 10;

  const [slug, setSlug] = useState();
  const [filteredMediaSiteItem, setFilteredMediaSiteItem] = useState();
  const [mediaSiteItemPostsList, setMediaSiteItemPostsList] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(1);
  const [postLength, setPostLength] = useState(0);

  useEffect(() => {
    setSlug(params.slug);
  }, [params]);

  useEffect(() => {
    fetchFilteredMediaSiteItem();
  }, [slug, selectedWorkspace]);

  const fetchFilteredMediaSiteItem = async () => {
    dispatch(startLoading());
    try {
      const response = await Network.get(API.mediasiteList, {
        workspaceId: selectedWorkspace,
      });
      const filteredItem = response.data.response.dataset.find(
        (e) => e.slug === slug
      );
      setFilteredMediaSiteItem(filteredItem);
    } catch (error) {
    } finally {
      dispatch(endLoading());
    }
  };

  useEffect(() => {
    if (!!filteredMediaSiteItem) {
      fetchMediaSitePostList();
    }
  }, [filteredMediaSiteItem, searchTerm]);

  const fetchMediaSitePostList = async () => {
    dispatch(startLoading());
    try {
      const data = await Network.get(API.fetchMediaSitePostList, {
        workspaceId: selectedWorkspace,
        siteId: filteredMediaSiteItem?.id,
        search: searchTerm,
        limit: perPage,
        page: page,
      });
      setMediaSiteItemPostsList(data.data.response.sitePostsData[0].posts);
      setPostLength(data.data.response.total);
    } catch (error) {
      toast.error(error?.response?.data?.response?.status?.msg);
    } finally {
      dispatch(endLoading());
    }
  };

  const onChangeSearchTerm = (termSearched) => {
    setSearchTerm(termSearched);
    setPage(1);
  };

  const truncate = (str, amount) => {
    return str.length > amount ? str.substring(0, amount - 3) + '...' : str;
  };

  const handlePageClick = (event) => {
    setPage(event.selected + 1);
  };

  const handleRedirect = (profileUsername, postId) => {
    if (!profileUsername && !postId) return;
    sessionStorage.setItem('mediaModuleSocialMediaType', 'sites');
    sessionStorage.removeItem('mediaModuleSocialMediaProfilesSiteId');
    sessionStorage.removeItem('mediaModuleSocialMediaProfilesProfileId');
    navigate(
      `${PreparePath}/dashboard/media/sites/${
        workspaceName && workspaceName.charAt(0).toUpperCase()
      }/${slug}/${profileUsername}/${postId}`
    );
  };

  return (
    <>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <button onClick={() => navigate(-1)}>
              <i className="fa fa-angle-left me-2" aria-hidden="true"></i>
              Back
            </button>
          </li>
          <li className="breadcrumb-item">
            <StylishNewButton
              onClick={() => navigate(`${PreparePath}/dashboard/media/sites`)}
            >
              Sites
            </StylishNewButton>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            {filteredMediaSiteItem?.displayName}
          </li>
        </ol>
      </nav>

      <div className="d-md-flex align-items-end mb-4">
        {filteredMediaSiteItem &&
        (filteredMediaSiteItem?.icon || filteredMediaSiteItem?.displayName) ? (
          <div className="bg-gray-700 d-flex align-items-center py-3 px-4 rounded-5">
            <div
              className={`flex-shrink-0 bg-white rounded-5 d-flex align-items-center justify-content-center overflow-hidden ${
                !!filteredMediaSiteItem?.icon ? 'has-img-h-32' : ''
              }`}
              style={{
                color: filteredMediaSiteItem?.color,
                height: '3rem',
                width: '3rem',
              }}
            >
              {!!filteredMediaSiteItem?.site_image ? (
                <img
                  src={filteredMediaSiteItem?.site_image}
                  alt=""
                  style={{
                    display: 'block',
                    height: '100%',
                    width: '100%',
                  }}
                />
              ) : null}
              {!!filteredMediaSiteItem?.icon ? (
                <>
                  {
                    AllIcon.find(
                      (icon) => icon.name === filteredMediaSiteItem.icon
                    )?.icon
                  }
                </>
              ) : null}
            </div>
            <div className="ms-4">
              <h5 className="m-0">{filteredMediaSiteItem?.displayName}</h5>
              <p className="m-0">
                {filteredMediaSiteItem?.mediaType
                  .replace(/_/g, ' ')
                  .replace(/\w\S*/g, (w) =>
                    w.replace(/^\w/, (c) => c.toUpperCase())
                  )}
              </p>
            </div>
          </div>
        ) : null}
        <div className="ms-auto d-flex align-items-center mt-3 mt-md-0">
          <SearchBar onChangeSearchTerm={onChangeSearchTerm} />
          <Help
            classes={'ms-3 flex-shrink-0'}
            title={MediaSiteView.title}
            content={MediaSiteView.content}
            link={selfHostedKnowledgeBase || MediaSiteView.link}
          />
        </div>
      </div>

      {!!mediaSiteItemPostsList && !!mediaSiteItemPostsList?.length ? (
        <>
          <div className="form-block">
            {mediaSiteItemPostsList.map((post, index) => {
              const truncatedPost =
                post?.post?.length > contentBlockLength
                  ? `${post?.post?.slice(0, contentBlockLength)}....`
                  : post?.post;
              const cleanPost = sanitizeHtml(truncatedPost);
              return (
                <div key={post?.postId}>
                  {index !== 0 ? <hr className="dashed my-4" /> : null}
                  <div className="d-flex align-items-start position-relative">
                    <div className="avatar flex-shrink-0">
                      {post?.profileAvatar && (
                        <img src={post?.profileAvatar} alt="" />
                      )}
                    </div>
                    <div className="ms-4">
                      {post?.profileName ? (
                        <h6 className="m-0">{post?.profileName}</h6>
                      ) : null}
                      {post?.profileUsername || post?.publishTime ? (
                        <p className="txt--sml">
                          {`${post?.profileUsername} - ${moment(
                            post?.publishTime
                          ).fromNow()} at ${moment(post?.publishTime).format(
                            'MMM Do YYYY HH:mm'
                          )} (${moment(post?.publishTime)
                            .tz(moment.tz.guess())
                            .format('Z')})`}
                        </p>
                      ) : null}
                      <p
                        className="mb-0 mt-3"
                        dangerouslySetInnerHTML={{
                          __html: cleanPost,
                        }}
                      ></p>
                    </div>
                    <span
                      className="stretched-link cursor-pointer"
                      onClick={() =>
                        post?.profileUsername &&
                        post?.postId &&
                        handleRedirect(post?.profileUsername, post?.postId)
                      }
                    ></span>
                  </div>
                </div>
              );
            })}
          </div>
          {setPostLength > perPage && (
            <div className="d-flex justify-content-center justify-content-md-end mt-2 mt-xl-0">
              <ReactPaginate
                className="pagination"
                breakLabel="..."
                nextLabel="Next"
                onPageChange={handlePageClick}
                pageCount={Math.ceil(setPostLength / perPage)}
                previousLabel="Prev"
              />
            </div>
          )}
        </>
      ) : (
        <div className="bg-gray-800 text-center p-5">
          <div className="d-flex justify-content-center mb-4">
            <img src={IconEmptyExercise} alt="" />
          </div>
          <p className="weight-600 m-0">No post found.</p>
        </div>
      )}
    </>
  );
}
