import { FormProvider, useForm } from 'react-hook-form';
import { Offcanvas, Row } from 'react-bootstrap';
import FormTextAreaField from './formFields/FormTextAreaField';
import DrawerWrapper, { DrawerFooter } from './DrawerWrapper';
import { StylishNewButton } from '../DesignSystems/New/StylishNewButton';
import React from 'react';
import { IncidentInfo, updateIcsInfo } from './hooks/incidentInfoHooks';
import FormCheckboxField from './formFields/FormCheckboxField';

type IncidentInfoEditProps = {
  toggle: () => void;
  incidentInfo: IncidentInfo;
};

type FormFields = {
  situation_summary: string;
  operational_period_command_emphasis: string;
  special_medical_emergency_procedures: string;
  medical_air_assets_yn: boolean;
};

const IncidentInfoEditDrawer: React.FC<IncidentInfoEditProps> = ({
  toggle,
  incidentInfo,
}) => {
  const form = useForm<FormFields>({
    defaultValues: {
      ...incidentInfo,
    },
  });

  const { handleSubmit } = form;

  const mutation = updateIcsInfo();

  const updateIncidentInfo = async (formData: FormFields) => {
    const payload = {
      ...formData,
    };
    await mutation.mutateAsync(payload);
    toggle();
  };

  return (
    <DrawerWrapper toggle={toggle} title="Edit Situation Overview">
      <FormProvider {...form}>
        <form
          onSubmit={handleSubmit(updateIncidentInfo)}
          className="d-flex flex-column justify-content-between flex-grow-1"
        >
          <Offcanvas.Body>
            <Row>
              <FormTextAreaField
                title="Summary"
                fieldName="situation_summary"
                defaultValue={incidentInfo.situation_summary}
              />
            </Row>
            <Row>
              <FormTextAreaField
                title="Operational Period Command Emphasis"
                fieldName="operational_period_command_emphasis"
                defaultValue={incidentInfo.operational_period_command_emphasis}
              />
            </Row>
            <Row>
              <FormTextAreaField
                title="Special Medical Emergency Procedures"
                fieldName="operational_period_command_emphasis"
                defaultValue={incidentInfo.special_medical_emergency_procedures}
              />
            </Row>
            <Row>
              <FormCheckboxField
                title="Medical Air Assets"
                fieldName="medical_air_assets_yn"
              />
            </Row>
          </Offcanvas.Body>
          <DrawerFooter>
            <div className="button-group">
              <StylishNewButton
                className="button--secondary button--reverse"
                type="button"
                onClick={toggle}
              >
                Close
              </StylishNewButton>
              <StylishNewButton
                className="button--primary"
                type="submit"
                disabled={mutation.isPending}
              >
                Save
              </StylishNewButton>
            </div>
          </DrawerFooter>
        </form>
      </FormProvider>
    </DrawerWrapper>
  );
};

export default IncidentInfoEditDrawer;
