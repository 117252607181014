import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Network from '../../../../../service/Network';
import API from '../../../../../service/api';
import { useState } from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { HelpTrainingObjectiveReview, useSelfHostedKnowledgeBase } from 'assets/data/HelpContent';
import { PreparePath } from 'utils/sharedUtils/sharedConst';
import StylishNewTable from 'components/DesignSystems/New/StylishNewTable';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import StylishHelp from 'components/DesignSystems/New/StylishHelp';
import {
  endLoading,
  startLoading,
} from '../../../../../../reducers/loading/loading.action';

export default function TrainingObjectiveReview(props) {
  const [allTrainingObjective, setAllTrainingObjective] = useState([]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const selfHostedKnowledgeBase = useSelfHostedKnowledgeBase()

  const workspaceId = useSelector(
    (state) => state.prepare.workspace.selectedWorkspace
  );
  const reduxUser = useSelector((state) => state.prepare.user);

  const eo_columns = [
    {
      dataField: 'title',
      text: 'Title',
      sort: true,
      headerAttrs: { title: 'Sort By:' },
      attrs: { title: 'Title' },
    },
  ];

  const at_columns = [
    {
      dataField: 'levelWar',
      text: 'Level of War',
      sort: true,
      headerAttrs: { title: 'Sort By:' },
      attrs: { title: 'Level of War' },
    },
    {
      dataField: 'taskNumber',
      text: 'Task Number',
      sort: true,
      attrs: { title: 'Task Number' },
    },
    {
      dataField: 'taskTitle',
      text: 'Task Title',
      sort: true,
      attrs: { title: 'Task Title' },
    },
  ];

  const ctp_columns = [
    {
      dataField: 'title',
      text: 'Title',
      sort: true,
      headerAttrs: { title: 'Sort By:' },
      attrs: { title: 'Title' },
    },
  ];

  const ccama_columns = [
    {
      dataField: 'title',
      text: 'Core Capability',
      sort: true,
      headerAttrs: { title: 'Sort By:' },
      attrs: { title: 'Core Capability' },
    },
    {
      dataField: 'area',
      text: 'Associated Mission Area (s)',
      sort: true,
      attrs: { title: 'Associated Mission Area (s)' },
    },
  ];

  const jstp_columns = [
    {
      dataField: 'title',
      text: 'Title',
      sort: true,
      headerAttrs: { title: 'Sort By:' },
      attrs: { title: 'Title' },
    },
  ];

  const fetchTrainingObjectiveDetail = () => {
    dispatch(startLoading());
    try {
      Network.get(API.reviewTrainingObjective, {
        workspaceId: workspaceId,
        trainingObjectiveId: props.id,
      }).then((resp) => {
        setAllTrainingObjective(
          resp.data.response.dataset.map((e) => {
            return {
              participantId: e?.participantId,
              requestedMethod: e?.requestedMethod,
              requiredRuns: e?.requiredRuns,
              platformType: e?.platformType,
              participantName: e?.participantName,
              task: e?.task,
              commandPriorities: e?.commandPriorities,
              accrediatedTasks: e?.accrediatedTasks,
              exerciseObjectives: e?.exerciseObjectives,
              trainingPriorities: e?.trainingPriorities,
              platformTitle: e?.platformTitle,
              measure: e?.measure,
              standard: e?.standard,
              condition: e?.condition,
              jmet: e?.levelWar + ' ' + e?.taskNumber + ' ' + e?.taskTitle,
              priority: (
                <span
                  className="status status--sml"
                  style={{ backgroundColor: e?.color }}
                >
                  {e?.priority_title}
                </span>
              ),
              exerciseObjective: e?.exerciseObjective,
              sourcetitle: e?.sourcetitle,
              para: e?.para,
              paratitle: e?.paratitle,
              competence: e?.competence,
              title: e?.title,
              organizationalcapability: e?.organizationalcapability,
              strategicpriority: e?.strategicpriority,
              corecapability: e?.corecapability.map((e) => ({
                ...e,
                title: e.title,
                area: e.missionAreas.map((element) => element.area).join(', '),
              })),
            };
          })
        );
      });
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(endLoading());
    }
  };

  const saveAndExit = () => {
    navigate(`${PreparePath}/dashboard/plan/prepare/training-objective`);
  };

  useEffect(() => {
    if (props.id) {
      fetchTrainingObjectiveDetail();
    }
  }, [reduxUser]);

  return (
    <>
      {props.participantType === 'DOD' ? (
        <div>
          {/* DOD Version */}
          <div className="d-flex align-items-center mb-4">
            <h4 className="m-0 me-4">
              {props?.participantName} Review Objective
            </h4>
            <StylishHelp
              classes={'ms-auto sml flex-shrink-0'}
              title={HelpTrainingObjectiveReview.title}
              content={HelpTrainingObjectiveReview.content}
              link={selfHostedKnowledgeBase || HelpTrainingObjectiveReview.link}
            />
          </div>
          <div className="form-block">
            <div className="">
              <h4 className="mb-4">Details</h4>
              <div className="row">
                <div className="col-md-6 mb-3">
                  <label className="form-label d-block mb-0">
                    Platform Type
                  </label>
                  {allTrainingObjective[0]?.platformType}
                </div>
                <div className="col-md-6 mb-3">
                  <label className="form-label d-block mb-0">
                    JMET ID/Name
                  </label>
                  {allTrainingObjective[0]?.jmet}
                </div>
                <div className="col-md-6 mb-3">
                  <label className="form-label d-block mb-0">
                    Requested Method
                  </label>
                  {allTrainingObjective[0]?.requestedMethod}
                </div>
                <div className="col-md-6 mb-3">
                  <label className="form-label d-block mb-0">
                    Required Runs
                  </label>
                  {allTrainingObjective[0]?.requiredRuns}
                </div>
                <div className="col-md-6 mb-3">
                  <label className="form-label d-block mb-0">
                    Objective Priority
                  </label>
                  {allTrainingObjective[0]?.priority}
                </div>
                <div className="col-md-6 mb-0 mb-md-3">
                  <label className="form-label d-block mb-0">
                    Classification
                  </label>
                  Unclassified
                </div>
              </div>
              <hr className="dashed" />
              <h4 className="mb-4">Performance</h4>
              <div className="row">
                <div className="col-md-6 mb-3">
                  <label className="form-label d-block mb-0">Task</label>
                  {allTrainingObjective[0]?.task}
                </div>
                <div className="col-md-6 mb-3">
                  <label className="form-label d-block mb-0">Standard</label>
                  {allTrainingObjective[0]?.standard}
                </div>
                <div className="col-md-6 mb-3">
                  <label className="form-label d-block mb-0">Condition</label>
                  {allTrainingObjective[0]?.condition}
                </div>
                <div className="col-md-6 mb-0 mb-md-3">
                  <label className="form-label d-block mb-0">Measures</label>
                  {allTrainingObjective[0]?.measure &&
                    allTrainingObjective[0].measure.map(
                      (measureData, index) => (
                        <span className="d-block" key={index}>
                          M{index + 1} - {measureData.measureTitle}
                        </span>
                      )
                    )}
                </div>
              </div>
              <hr className="dashed" />
              <h4 className="mb-4">Associations</h4>
              {props.workspaceType === 'DOD' ? (
                <>
                  <div className="mb-5">
                    <p className="weight-600">Exercise Objectives</p>
                    <StylishNewTable
                      keyField={'id'}
                      columns={eo_columns}
                      rows={allTrainingObjective[0]?.exerciseObjectives || []}
                    />
                  </div>
                  <div className="mb-5">
                    <p className="weight-600">Accredited Tasks</p>
                    <StylishNewTable
                      keyField={'id'}
                      columns={at_columns}
                      rows={allTrainingObjective[0]?.accrediatedTasks || []}
                    />
                  </div>
                  <div className="mb-5">
                    <p className="weight-600">Command Training Priorities</p>
                    <StylishNewTable
                      keyField={'id'}
                      columns={ctp_columns}
                      rows={allTrainingObjective[0]?.commandPriorities || []}
                    />
                  </div>
                  <div className="mb-0">
                    <p className="weight-600">Strategic Priorities</p>
                    <StylishNewTable
                      keyField={'id'}
                      columns={jstp_columns}
                      rows={allTrainingObjective[0]?.trainingPriorities || []}
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className="mb-5">
                    <p className="weight-600">
                      Organizational Capability Targets
                    </p>
                    <StylishNewTable
                      keyField={'id'}
                      columns={eo_columns}
                      rows={
                        allTrainingObjective[0]?.organizationalcapability || []
                      }
                    />
                  </div>
                  <div className="mb-5">
                    <p className="weight-600">
                      Core Capabilities and Associated Mission Area (s)
                    </p>
                    <StylishNewTable
                      keyField={'id'}
                      columns={ccama_columns}
                      rows={allTrainingObjective[0]?.corecapability || []}
                    />
                  </div>
                  <div className="mb-0">
                    <p className="weight-600">Strategic Priorities</p>
                    <StylishNewTable
                      keyField={'id'}
                      columns={jstp_columns}
                      rows={allTrainingObjective[0]?.strategicpriority || []}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="col-md-12 mt-4">
            <div className="button-group d-block d-md-flex">
              <StylishNewButton
                type="submit"
                className="button button--primary button--reverse w-100 w-md-auto"
                onClick={() => props.onPrevClick(2)}
              >
                Previous
              </StylishNewButton>
              <StylishNewButton
                type="submit"
                className="button button--primary  w-100 w-md-auto ms-auto mt-3 mt-md-0"
                onClick={saveAndExit}
              >
                Complete
              </StylishNewButton>
            </div>
          </div>
          {/* DOD Version */}
        </div>
      ) : (
        <div>
          {/* HSEEP Version */}
          <div className="d-flex align-items-center mb-4">
            <div>
              <h4 className="m-0">
                {props?.participantName} Exercise Objective
              </h4>
              <span className="d-block">
                {allTrainingObjective[0]?.platformTitle}
              </span>
            </div>
            <StylishHelp
              classes={'ms-auto sml flex-shrink-0'}
              title={HelpTrainingObjectiveReview.title}
              content={HelpTrainingObjectiveReview.content}
              link={selfHostedKnowledgeBase || HelpTrainingObjectiveReview.link}
            />
          </div>
          <div className="form-block">
            <div className="">
              <h4 className="mb-4">Details</h4>
              <div className="row">
                <div className="col-md-6    mb-md-3">
                  <label className="form-label d-block mb-0">
                    Platform Type
                  </label>
                  {allTrainingObjective[0]?.platformType}
                </div>
              </div>
              <hr className="dashed" />
              <h4 className="mb-4">Performance</h4>
              <div className="row">
                <div className="col-md-12 mb-3">
                  <label className="form-label d-block mb-0">
                    Exercise Objective
                  </label>
                  {allTrainingObjective[0]?.exerciseObjective}
                </div>
                <div className="col-md-4 mb-3">
                  <label className="form-label d-block mb-0">Priority</label>
                  {allTrainingObjective[0]?.priority}
                </div>
                <div className="col-md-4 mb-3">
                  <label className="form-label d-block mb-0">
                    Required Runs
                  </label>
                  {allTrainingObjective[0]?.requiredRuns}
                </div>
                <div className="col-md-4 mb-3">
                  <label className="form-label d-block mb-0">
                    Requested Method
                  </label>
                  {allTrainingObjective[0]?.requestedMethod}
                </div>
                <div className="col-md-4 mb-3">
                  <label className="form-label d-block mb-0">
                    Source (Plan. Framework. SOP. etc. )
                  </label>
                  {allTrainingObjective[0]?.sourcetitle}
                </div>
                <div className="col-md-4 mb-3">
                  <label className="form-label d-block mb-0">
                    Reference (Paragraph)
                  </label>
                  {allTrainingObjective[0]?.para}
                </div>
                <div className="col-md-4 mb-3">
                  <label className="form-label d-block mb-0">
                    Paragraph Title / Description
                  </label>
                  {allTrainingObjective[0]?.paratitle}
                </div>
                <div className="col-md-12 mb-3">
                  <label className="form-label d-block mb-0">
                    Level / Competency
                  </label>
                  {allTrainingObjective[0]?.competence}
                </div>
                {allTrainingObjective[0]?.task &&
                  allTrainingObjective[0]?.task.map((e, index) => (
                    <>
                      <div className="col-md-4 mb-3" key={index}>
                        <label className="form-label d-block mb-0">
                          Task (from Plan, SOP, etc.) / Behavior (From PTB)
                        </label>
                        {e?.task}
                      </div>
                      <div className="col-md-4 mb-3" key={index}>
                        <label className="form-label d-block mb-0">
                          Standard / Measurement
                        </label>
                        {e?.measure}
                      </div>
                      <div className="col-md-4 mb-3" key={index}>
                        <label className="form-label d-block mb-0">
                          Code / Condition
                        </label>
                        {e?.code}
                      </div>
                    </>
                  ))}
              </div>
              <hr className="dashed" />
              <h4 className="mb-4">Associations</h4>
              {props.workspaceType === 'DOD' ? (
                <>
                  <div className="mb-5">
                    <p className="weight-600">Exercise Objectives</p>
                    <StylishNewTable
                      keyField={'id'}
                      columns={eo_columns}
                      rows={allTrainingObjective[0]?.exerciseObjectives || []}
                    />
                  </div>
                  <div className="mb-5">
                    <p className="weight-600">Accredited Tasks</p>
                    <StylishNewTable
                      keyField={'id'}
                      columns={at_columns}
                      rows={allTrainingObjective[0]?.accrediatedTasks || []}
                    />
                  </div>
                  <div className="mb-5">
                    <p className="weight-600">Command Training Priorities</p>
                    <StylishNewTable
                      keyField={'id'}
                      columns={ctp_columns}
                      rows={allTrainingObjective[0]?.commandPriorities || []}
                    />
                  </div>
                  <div className="mb-0">
                    <p className="weight-600">Strategic Priorities</p>
                    <StylishNewTable
                      keyField={'id'}
                      columns={jstp_columns}
                      rows={allTrainingObjective[0]?.trainingPriorities || []}
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className="mb-5">
                    <p className="weight-600">
                      Organizational Capability Targets
                    </p>
                    <StylishNewTable
                      keyField={'id'}
                      columns={eo_columns}
                      rows={
                        allTrainingObjective[0]?.organizationalcapability || []
                      }
                    />
                  </div>
                  <div className="mb-5">
                    <p className="weight-600">
                      Core Capabilities and Associated Mission Area (s)
                    </p>
                    <StylishNewTable
                      keyField={'id'}
                      columns={ccama_columns}
                      rows={allTrainingObjective[0]?.corecapability || []}
                    />
                  </div>
                  <div className="mb-0">
                    <p className="weight-600">Strategic Priorities</p>
                    <StylishNewTable
                      keyField={'id'}
                      columns={jstp_columns}
                      rows={allTrainingObjective[0]?.strategicpriority || []}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="col-md-12 mt-4">
            <div className="button-group d-block d-md-flex">
              <StylishNewButton
                type="submit"
                className="button button--primary button--reverse w-100 w-md-auto"
                onClick={() => props.onPrevClick(1)}
              >
                Previous
              </StylishNewButton>
              <StylishNewButton
                type="submit"
                className="button button--primary  w-100 w-md-auto ms-auto mt-3 mt-md-0"
                onClick={saveAndExit}
              >
                Complete
              </StylishNewButton>
            </div>
          </div>
          {/* HSEEP Version */}
        </div>
      )}
    </>
  );
}
