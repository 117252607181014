import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useForm, Controller, set } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import API from '../../../../../service/api';
import Network from '../../../../../service/Network';
import moment from 'moment';
import { inputStringLength, toastConfig } from 'assets/data/config';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import StylishNewInput from 'components/DesignSystems/New/StylishNewInput';
import StylishDateTimePicker from 'components/DesignSystems/form/StylishDateTimePicker';
import StylishNewTextArea from 'components/DesignSystems/New/StylishNewTextArea';

import {
  endLoading,
  startLoading,
} from '../../../../../../reducers/loading/loading.action';

export default function AddEditPlanMeetingsDialog({
  onClose,
  show,
  dialogType,
  updateDialogData,
  title = '',
}) {
  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    setError,
    clearErrors,
  } = useForm();

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const dispatch = useDispatch();

  const { selectedWorkspace, timezone, type: workspaceType } = useSelector(
    (state) => state.prepare.workspace
  );
  const exerciseId = useSelector(
    (state) => state.prepare.exercise.exerciseDetailsId
  );
  const { eventId } = useSelector((state) => state.prepare.planDashboard);

  const reduxUser = useSelector((state) => state.prepare.user);

  const onSubmit = async (data) => {
    if (
      moment(data.startexDate).isAfter(moment(data.endexDate)) ||
      moment(data.startexDate).isSame(moment(data.endexDate))
    ) {
      setError('startexDate', { type: 'custom' });

      setError('endexDate', { type: 'custom' });

      return;
    } else {
      clearErrors('startexDate');

      clearErrors('endexDate');
    }

    const inputData = {
      name: data.name,
      location: data.location,
      startDate: data.startexDate,
      endDate: data.endexDate,
      agenda: data.agenda,
      workspaceId: selectedWorkspace,
      planEventId: workspaceType === 'T&E' ? eventId : exerciseId,
    };
    dispatch(startLoading());
    try {
      const newTraining = await Network.post(
        dialogType === 'Add' ? API.addMeetings : API.updateMeetings,
        dialogType === 'Add'
          ? inputData
          : {
              ...inputData,
              id: updateDialogData.id,
            }
      );
      if (dialogType === 'Add') {
        toast.success('Plan meeting added successfully', toastConfig);
      } else {
        toast.success('Plan meeting updated successfully', toastConfig);
      }
      onClose(newTraining.data.response.dataset[0]);
    } catch (error) {
      toast.error(error.response.data.response.status.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };

  useEffect(() => {
    if (dialogType === 'Edit' && updateDialogData?.id) {
      if (reduxUser?.timeFormat === 'hour12Mode') {
        setValue(
          'startexDate',
          moment(updateDialogData?.startDate || updateDialogData?.start).format(
            'MM/DD/YYYY HH:mm'
          )
        );
        setStartDate(
          moment(updateDialogData?.startDate || updateDialogData?.start)
        );
        setValue(
          'endexDate',
          moment(updateDialogData?.endDate || updateDialogData?.end).format(
            'MM/DD/YYYY HH:mm'
          )
        );
        setEndDate(moment(updateDialogData?.endDate || updateDialogData?.end));
      } else {
        setValue(
          'startexDate',
          moment(updateDialogData?.startDate || updateDialogData?.start).format(
            'MM/DD/YYYY HH:mm'
          )
        );
        setStartDate(
          moment(updateDialogData?.startDate || updateDialogData?.start)
        );
        setValue(
          'endexDate',
          moment(updateDialogData?.endDate || updateDialogData?.end).format(
            'MM/DD/YYYY HH:mm'
          )
        );
        setEndDate(moment(updateDialogData?.endDate || updateDialogData?.end));
      }
      setValue('name', updateDialogData.name);
      setValue('location', updateDialogData.location);
      setValue('agenda', updateDialogData.agenda);
    } else if (dialogType === 'Add') {
      if (reduxUser?.timeFormat === 'hour12Mode') {
        setValue('startexDate', moment().format('MM/DD/YYYY HH:mm'));
        setValue('endexDate', moment().format('MM/DD/YYYY HH:mm'));
        setStartDate(moment());
        setEndDate(moment());
      } else {
        setValue('startexDate', moment().format('MM/DD/YYYY HH:mm'));
        setValue('endexDate', moment().format('MM/DD/YYYY HH:mm'));
        setStartDate(moment());
        setEndDate(moment());
      }
    }
  }, [updateDialogData?.id, dialogType]);

  useEffect(() => {
    const listener = (event) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        event.preventDefault();
        handleSubmit(onSubmit)();
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, []);

  return (
    <Modal
      show={show}
      onHide={onClose}
      centered
      backdrop="static"
      keyboard={false}
      size="lg"
    >
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title>{title || 'Planning Meeting'}</Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <div className="row">
            <div className="col-md-6 mb-3">
              <label className="form-label">
                Name
                <span aria-label="Required field" className="required">
                  *
                </span>
              </label>
              <Controller
                control={control}
                name={'name'}
                defaultValue={updateDialogData?.name}
                className="form-control"
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, value } }) => (
                  <StylishNewInput
                    onChange={(e) => {
                      onChange(e);
                    }}
                    value={value}
                    type="text"
                    maxLength={inputStringLength}
                    className="form-control"
                  />
                )}
              />
              {errors?.name?.type === 'required' && (
                <span className="form-text form-error">
                  This is a required field
                </span>
              )}
              {errors?.name?.pattern === 'pattern' && (
                <span className="form-text form-error">
                  Alpha Values are required{' '}
                </span>
              )}
            </div>
            <div className="col-md-6 mb-3">
              <label className="form-label">
                Location
                <span aria-label="Required field" className="required">
                  *
                </span>
              </label>
              <Controller
                control={control}
                name={'location'}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <StylishNewInput
                    onChange={(e) => {
                      onChange(e);
                    }}
                    value={value}
                    type="text"
                    maxLength={inputStringLength}
                    className="form-control"
                    // {...register('location', { required: true })}
                  />
                )}
              />

              {errors?.location && (
                <span className="form-text form-error">
                  This is a required field
                </span>
              )}
            </div>
            <div className="col-md-12 mb-3">
              <label className="form-label">
                Start Date {timezone.split('/')[0]}
              </label>
              <Controller
                control={control}
                name="startexDate"
                rules={{ required: true }}
                render={({ field: { value, onChange } }) => (
                  <StylishDateTimePicker
                    onChange={(date) => {
                      setStartDate(date || null);
                      if (
                        date &&
                        (moment(date).isAfter(moment(endDate)) || !endDate)
                      ) {
                        setValue('endexDate', date);
                        setEndDate(date);
                      }

                      return onChange(date);
                    }}
                    selected={value}
                    selectsStart
                    value={value}
                    endDate={endDate}
                    isClearable={true}
                    timeFormat="HH:mm"
                    timeIntervals={20}
                    timeCaption="time"
                    dateFormat={
                      reduxUser?.timeFormat === 'hour12Mode'
                        ? 'MMMM d, yyyy hh:mm a'
                        : 'MMMM d, yyyy HH:mm'
                    }
                    placeholderText={'Choose Start Date'}
                    disableDateTimeLabel={true}
                    type="datetime-local"
                  />
                )}
              />
              {errors.startexDate?.type === 'required' && (
                <span className="form-text form-error">
                  This is a required field
                </span>
              )}
              {/* {errors.startexDate?.type === 'custom' && (
                <span className="form-text form-error multiline">
                  Start Date cannot be same or greater than end Date
                </span>
              )} */}
            </div>
            <div className="col-md-12 mb-3">
              <label className="form-label">
                End Date {timezone.split('/')[0]}
              </label>
              <Controller
                control={control}
                name="endexDate"
                rules={{ required: true }}
                render={({ field: { value, onChange } }) => (
                  <StylishDateTimePicker
                    onChange={(date) => {
                      setEndDate(date);
                      return onChange(date);
                    }}
                    value={value}
                    selected={value || endDate}
                    selectsEnd
                    minDate={
                      !!startDate
                        ? new Date(moment(startDate).toISOString())
                        : null
                    }
                    isClearable={true}
                    timeFormat="HH:mm"
                    timeIntervals={20}
                    timeCaption="time"
                    dateFormat={
                      reduxUser?.timeFormat === 'hour12Mode'
                        ? 'MMMM d, yyyy hh:mm a'
                        : 'MMMM d, yyyy HH:mm'
                    }
                    placeholderText={'Choose End Date'}
                    type="datetime-local"
                    disableDateTimeLabel={true}
                  />
                )}
              />
              {errors.endexDate?.type === 'required' && (
                <span className="form-text form-error">
                  This is a required field
                </span>
              )}
              {errors.endexDate?.type === 'custom' && (
                <span className="form-text form-error multiline">
                  End Time cannot be same or lesser than start Date
                </span>
              )}
            </div>
            <div className="col-md-12">
              <label className="form-label">Agenda</label>
              <Controller
                control={control}
                name="agenda"
                rules={{ maxLength: 999 }}
                render={({ field: { onChange, value } }) => (
                  <StylishNewTextArea value={value || ''} onChange={onChange} />
                )}
              />
              {errors?.agenda?.type === 'maxLength' && (
                <span className="form-text form-error">
                  Max of 999 characters allowed
                </span>
              )}
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div className="button-group">
            <StylishNewButton
              className="button button--secondary button--reverse"
              onClick={onClose}
            >
              Close
            </StylishNewButton>
            <StylishNewButton className="button button--primary" type="submit">
              Save
            </StylishNewButton>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
