import React, { useState, useEffect, useRef } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import IconUpload from 'assets/images/icon__upload.svg';
import { StylishNewSearchBar } from 'components/DesignSystems/New/StylishNewSearchBar';
import API from '../../../service/api';
import Network from '../../../service/Network';
import { toast } from 'react-toastify';
import { useForm, Controller } from 'react-hook-form';
import StylishNewInput from 'components/DesignSystems/New/StylishNewInput';
import { productionEnv } from 'constants/debug';
import IconTimes from 'assets/images/icon__times.svg';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { toastConfig } from 'assets/data/config';
import { endLoading, startLoading } from "../../../../reducers/loading/loading.action";



export default function ChooseMediaDialog({
  onClose,
  show,
  dialogType,
  onSubmitImageAvatar,
  imageAvatarFrom,
  onSubmitImageAvatarFrom,
  onSubmitImageBanner,
  imageBannerFrom,
  onSubmitImageBannerFrom,
  typeModalProfile,
  onSelectedImageAvatar,
  onSelectedImageBanner,
}) {
  const unsplashClientId = window.env.UNSPLASH_CLIENT_ID;

  const dispatch = useDispatch();

  const [uploadedFile, setUploadedFile] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

  const unsplashContainerRef = useRef();

  const {
    register,
    handleSubmit,
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();

  const [images, setImages] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [selectedImageAvatar, setSelectedImageAvatar] = useState(null);
  const [selectedImageBanner, setSelectedImageBanner] = useState(null);
  const [isVisibleUploadedAvatar, setIsVisibleUploadedAvatar] = useState(false);
  const [isVisibleUploadedBanner, setIsVisibleUploadedBanner] = useState(false);
  const [avatarImageUploadError, setAvatarImageUploadError] = useState(false);
  const [bannerImageUploadError, setBannerImageUploadError] = useState(false);

  useEffect(() => {
    if (typeModalProfile === 'Avatar') {
      if (onSelectedImageAvatar !== null && imageAvatarFrom != null) {
        if (imageAvatarFrom === 'uploadedFile') {
          setIsVisibleUploadedAvatar(true);
          setUploadedFile(onSelectedImageAvatar);
        }
        if (imageAvatarFrom === 'mediaUrl') {
          setValue('mediaUrl', onSelectedImageAvatar);
        }
        if (imageAvatarFrom === 'unsplash') {
          setSelectedImageAvatar(onSelectedImageAvatar);
        }
      }
    }
    if (typeModalProfile === 'Banner') {
      if (onSelectedImageBanner !== null && imageBannerFrom != null) {
        if (imageBannerFrom === 'uploadedFile') {
          setIsVisibleUploadedBanner(true);
          setUploadedFile(onSelectedImageBanner);
        }
        if (imageBannerFrom === 'mediaUrl') {
          setValue('mediaUrl', onSelectedImageBanner);
        }
        if (imageBannerFrom === 'unsplash') {
          setSelectedImageBanner(onSelectedImageBanner);
        }
      }
    }
  }, []);

  const onSubmit = async (data) => {
    onSubmitImageAvatar(selectedImageAvatar?.urls?.thumb);
    onSubmitImageBanner(selectedImageBanner?.urls?.thumb);
  };

  const changeHandler = async (imageFile) => {
    dispatch(startLoading());
    try {
      const formData = new FormData();
      formData.append('file', imageFile);
      const resp = await Network.post(API.publicSingleFileUpload, formData)
        .then((data) => {
          setUploadedFile(data?.data?.response?.dataset[0]?.fileUrl);
          setValue('mediaUrl', '');
          if (typeModalProfile === 'Avatar') {
            setIsVisibleUploadedAvatar(true);
            setSelectedImageAvatar(null);
          }
          if (typeModalProfile === 'Banner') {
            setSelectedImageBanner(null);
            setIsVisibleUploadedBanner(true);
          }

          toast.success('File upload successful', toastConfig);
        })
        .catch((error) => {
          console.error('Error uploading image:', error);
        });
    } catch (error) {
      toast.error(error?.response?.data?.response?.status?.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };

  const handleFileInputChange = async (event) => {
    const file = event.target.files[0];
    if (typeModalProfile === 'Avatar') {
      if (file && Math.ceil(file?.size / 1000) > 100) {
        setAvatarImageUploadError(true);
        return;
      } else {
        setAvatarImageUploadError(false);
        changeHandler(file);
      }
    }
    if (typeModalProfile === 'Banner') {
      if (file && Math.ceil(file?.size / 1000) > 500) {
        setBannerImageUploadError(true);
        return;
      } else {
        setBannerImageUploadError(false);
        changeHandler(file);
      }
    }
  };

  const fetchImages = async () => {
    try {
      fetch(
        `https://api.unsplash.com/search/photos?query=${typeModalProfile == 'Avatar'
          ? searchTerm || 'human face'
          : searchTerm || 'nature'
        }&page=${page}&orientation=${typeModalProfile == 'Avatar' ? 'squarish' : 'landscape'
        }&per_page=15 &client_id=${unsplashClientId}`
      )
        .then((res) => res.json())
        .then((res) => {
          const newImages = res?.results;
          setImages((prevImages) => [...prevImages, ...newImages]);
          // setImages((prevState) => ({ ...prevState, newImages }));

          if (newImages.length === 0) {
            setHasMore(false);
          }
        })

        .catch((res) => console.log('error', res));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchImages();
  }, [page, searchTerm]);

  useEffect(() => {
    setImages([]);
  }, [searchTerm]);

  const onChangeSearchTerm = (term) => {
    setPage(1);
    setSearchTerm(term);
  };

  const handleScroll = () => {
    const container = unsplashContainerRef.current;
    if (
      Math.ceil(container.scrollTop + container.clientHeight) ==
      container.scrollHeight ||
      Math.floor(container.scrollTop + container.clientHeight) ==
      container.scrollHeight
    ) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  useEffect(() => {
    const container = unsplashContainerRef.current;
    container.addEventListener('scroll', handleScroll);
    return () => {
      container.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleImageClick = (image) => {
    typeModalProfile === 'Avatar'
      ? setSelectedImageAvatar(image)
      : setSelectedImageBanner(image);
    setValue('mediaUrl', '');
    setUploadedFile(null);

    typeModalProfile === 'Avatar'
      ? setIsVisibleUploadedAvatar(false)
      : setIsVisibleUploadedBanner(false);
  };

  return (
    <Modal
      show={show}
      onHide={onClose}
      centered
      backdrop="static"
      keyboard={false}
      size="lg"
    >
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title>{dialogType}Choose Media</Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <div className="mb-3">
            <label className="form-label">Upload Media</label>
            {((typeModalProfile === 'Avatar' && !isVisibleUploadedAvatar) ||
              (typeModalProfile === 'Banner' && !isVisibleUploadedBanner)) && (
                <div className="form-upload">
                  <div>
                    <span className="d-flex justify-content-center mb-2">
                      <img src={IconUpload} alt="" />
                    </span>
                    <span className="d-block text-center">
                      Drop Your Files Here or Click to Upload <br />
                      {typeModalProfile === 'Avatar'
                        ? 'Max upload size 100KB'
                        : null}
                      {typeModalProfile === 'Banner'
                        ? 'Max upload size 500KB'
                        : null}
                    </span>
                  </div>
                  <StylishNewInput
                    type="file"
                    name="file"
                    multiple
                    accept=".png, .jpg, jpeg"
                    // onChange={(e) => changeHandler(e)}
                    onChange={handleFileInputChange}
                  />
                </div>
              )}
            {typeModalProfile === 'Avatar' && avatarImageUploadError && (
              <span className="form-text form-error">
                Max upload size 100KB
              </span>
            )}
            {typeModalProfile === 'Banner' && bannerImageUploadError && (
              <span className="form-text form-error">
                Max upload size 500KB
              </span>
            )}
            {(isVisibleUploadedAvatar || isVisibleUploadedBanner) && (
              <>
                <div className="d-flex align-items-start">
                  <div className="me-3">
                    <img
                      src={uploadedFile}
                      className="rounded-5 w-100 h-auto d-block"
                      // style={{ height: '200px', width: 'auto' }}
                      alt=""
                    />
                  </div>
                  <StylishNewButton
                    customButton
                    className={'button--icon flex-shrink-0'}
                    onClick={() => {
                      setUploadedFile(null);
                      typeModalProfile === 'Avatar' &&
                        setIsVisibleUploadedAvatar(false);
                      typeModalProfile === 'Banner' &&
                        setIsVisibleUploadedBanner(false);
                    }}
                  >
                    <img src={IconTimes} alt="" />
                  </StylishNewButton>
                </div>
              </>
            )}
          </div>
          {/* <div className="mb-3">
            <label className="form-label">Media URL</label>
            <Controller
              control={control}
              name="mediaUrl"
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <StylishNewInput
                  value={value || ''}
                  onChange={(e) => {
                    onChange(e);
                    setUploadedFile(null);
                    if (typeModalProfile === 'Avatar') {
                      setIsVisibleUploadedAvatar(false);
                      setSelectedImageAvatar(null);
                    }
                    if (typeModalProfile === 'Banner') {
                      setSelectedImageBanner(null);
                      setIsVisibleUploadedBanner(false);
                    }
                  }}
                  type="text"
                  className="form-control"
                  placeholder="Enter a media URL"
                  name="mediaUrl"
                />
              )}
            />
          </div> */}

          <div className="mb-3">
            <label className="form-label">Search Images</label>
            <StylishNewSearchBar
              onChangeSearchTerm={onChangeSearchTerm}
              value={searchTerm}
            />
          </div>
          <div
            ref={unsplashContainerRef}
            className="gallery--unsplash cursor-pointer"
            style={{
              maxHeight: typeModalProfile == 'Avatar' ? '432px' : '297px',
            }}
          >
            {images.map((image, index) => (
              <img
                key={index}
                src={image.urls.regular}
                alt={image.alt_description}
                onClick={() => handleImageClick(image)}
                className={
                  typeModalProfile == 'Avatar'
                    ? selectedImageAvatar === image ||
                      selectedImageAvatar === image.urls.thumb
                      ? 'selected'
                      : ''
                    : selectedImageBanner === image ||
                      selectedImageBanner === image.urls.thumb
                      ? 'selected'
                      : ''
                }
              />
            ))}
            {hasMore && (
              <div className="loading mt-3">Loading more images...</div>
            )}
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div className="button-group">
            <button
              className="button button--secondary button--reverse"
              // onClick={onClose}
              onClick={() => {
                onClose();
                // onSubmitImage(selectedImage?.urls?.thumb);
              }}
            >
              Cancel
            </button>
            <button
              className="button button--primary"
              type="submit"
              // onClick={() => handleSubmit(onSubmit)}
              onClick={() => {
                if (typeModalProfile === 'Avatar') {
                  if (!!uploadedFile && uploadedFile?.length > 0) {
                    onSubmitImageAvatar(uploadedFile);
                    onSubmitImageAvatarFrom('uploadedFile');
                  } else if (
                    !!getValues('mediaUrl') &&
                    getValues('mediaUrl') !== null
                  ) {
                    onSubmitImageAvatar(getValues('mediaUrl'));
                    onSubmitImageAvatarFrom('mediaUrl');
                  } else if (selectedImageAvatar?.urls?.thumb !== null) {
                    onSubmitImageAvatar(selectedImageAvatar?.urls?.thumb);
                    onSubmitImageAvatarFrom('unsplash');
                  } else {
                    onSubmitImageAvatar(null);
                    onSubmitImageAvatarFrom(null);
                  }
                }
                if (typeModalProfile === 'Banner') {
                  if (!!uploadedFile && uploadedFile?.length > 0) {
                    onSubmitImageBanner(uploadedFile);
                    onSubmitImageBannerFrom('uploadedFile');
                  } else if (
                    !!getValues('mediaUrl') &&
                    getValues('mediaUrl') !== null
                  ) {
                    onSubmitImageBanner(getValues('mediaUrl'));
                    onSubmitImageBannerFrom('mediaUrl');
                  } else if (selectedImageBanner?.urls?.thumb !== null) {
                    onSubmitImageBanner(selectedImageBanner?.urls?.regular);
                    onSubmitImageBannerFrom('unsplash');
                  } else {
                    onSubmitImageBanner(null);
                    onSubmitImageBannerFrom(null);
                  }
                }
              }}
            >
              Ok
            </button>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
