import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import {
  addNotificationPageData,
  clearNotificationPageData,
  sendNotificationByGroup,
  upsertNotificationByGroup,
} from 'actions/notificationActions';
import { getUserById } from 'actions/profileActions';
import generateUUID from 'utils/sharedUtils/generateUUID';
import validateEmailAddress from 'utils/validateEmailAddress';
import Accordion from 'react-bootstrap/Accordion';
import SelectTemplateDialog from './SelectTemplateDialog';
import AddDistributionListDialog from './AddDistributionListDialog';
import AddNewRecipientsDialog from './AddNewRecipientsDialog';
import validatePagerAddress from 'utils/validatePagerAddress';
import ManageDistributionListsDialog from './ManageDistributionListsDialog';
import { StylishNewCheckbox } from 'components/DesignSystems/New/StylishNewCheckbox';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import StylishNewInput from 'components/DesignSystems/New/StylishNewInput';
import StylishNewTextArea from 'components/DesignSystems/New/StylishNewTextArea';
import { StylishNewChip } from 'components/DesignSystems/New/StylishNewChip';
import { StylishConfirmDialog } from 'components/DesignSystems/New/StylishConfirmDialog';
import { toast } from 'react-toastify';
import { toastConfig } from 'assets/data/config';
import moment from 'moment';
import { UnavailableUserNotificationDialog } from './UnavailableUserNotificationDialog';
import { SharedIcon } from 'components/SharedIcon/SharedIcon';
import { Alert } from 'react-bootstrap';

const allRecipientTypesOptions = [
  'Email',
  // 'SMS',
  // 'Pager',
  'Text-To-Speech (cell)',
  'Text-To-Speech (home)',
];

function NotificationComposeScreen(
  {
    selectTemplateDialogOpen,
    setSelectTemplateDiaglogOpen,
    selectedNotification,
    setSelectedNotification,
    setCurrentLoggedInstance,
    viewType,
    setViewType,
  },
  ref
) {
  const reduxDispatch = useDispatch();
  const navigate = useNavigate();
  const reduxFeatureFlags = useSelector((state) => {
    return state.app.featureFlags;
  });

  const [allRecipientTypes, setAllRecipientTypes] = useState(
    allRecipientTypesOptions
  );

  const [recipientTypes, setRecipientTypes] = useState(
    allRecipientTypesOptions
  );

  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [SMSMessage, setSMSMessage] = useState('');
  const [TTSMessage, setTTSMessage] = useState('');
  const [pagerMessage, setPagerMessage] = useState('');

  const [emailRecipients, setEmailRecipients] = useState([]);
  const [SMSRecipients, setSMSRecipients] = useState([]);
  const [TTSHomeRecipients, setTTSHomeRecipients] = useState([]);
  const [TTSCellRecipients, setTTSCellRecipients] = useState([]);
  const [pagerRecipients, setPagerRecipients] = useState([]);

  const [selectedCheckBoxOptions, setSelectedCheckBoxOptions] = useState([]);

  const [usersNotInDICE, setUsersNotInDICE] = useState();
  const [usersNotInGroup, setUsersNotInGroup] = useState();
  const [EmailRecipientFailures, setEmailRecipientFailures] = useState();
  const [SMSRecipientFailures, setSMSRecipientFailures] = useState();
  const [TTSCellRecipientFailures, setTTSCellRecipientFailures] = useState();
  const [TTSHomeRecipientFailures, setTTSHomeRecipientFailures] = useState();
  const [PagerRecipientFailures, setPagerRecipientFailures] = useState();

  const [isValidForEmail, setIsValidForEmail] = useState();
  const [isValidForSMS, setIsValidForSms] = useState();
  const [isValidForPager, setIsValidForPager] = useState();
  const [isValidForTTSCell, setIsValidForTTSCell] = useState();
  const [isValidForTTSHome, setIsValidForTTSHome] = useState();
  const [emailMessageMaxLength, setEmailMessageMaxLength] = useState(1000);
  const [smsMessageMaxLength, setSMSMessageMaxLength] = useState(160);
  const [pagerMessageMaxLength, setPagerMessageMaxLength] = useState(160);
  const [ttsMessageMaxLength, setTTSMessageMaxLength] = useState(3000);
  const [
    addDistributionListDialogOpen,
    setAddDistributionListDialogOpen,
  ] = useState(false);
  const [addNewRecipientsDialogOpen, setAddNewRecipientsDialogOpen] = useState(
    false
  );
  const [fetchedUserProfile, setFetchedUserProfile] = useState(false);
  const [
    showUnavailableUserNotificationDailog,
    setShowUnavailableUserNotificationDailog,
  ] = useState(false);
  const [unavailableUsersData, setUnavailableUsersData] = useState(null);

  const [disabledGuids, setDisabledGuids] = useState([]);

  const [groupMembersToNotify, setGroupMembersToNotify] = useState([]);

  const [nonMembers, setNonMembers] = useState([]);

  const [distributionLists, setDistributionLists] = useState([]);

  const [
    manageDistributionListsDialogOpen,
    setManageDistributionListsDialogOpen,
  ] = useState(false);

  const distributionListItems = useSelector((state) => {
    return state.app.distributionListItems;
  });

  const [allNotifications, setAllNotifications] = useState(null);

  useEffect(() => {
    console.log(reduxFeatureFlags);

    if (reduxFeatureFlags) {
      const newOptions = [];
      newOptions.push('SMS');

      setAllRecipientTypes([
        ...Array.from(new Set([...recipientTypes, ...newOptions])),
      ]);
    }
  }, [reduxFeatureFlags]);

  useImperativeHandle(ref, () => ({
    confirmSave: (viewType) => {
      const isFilled =
        subject !== '' ||
        message !== '' ||
        SMSMessage !== '' ||
        pagerMessage !== '' ||
        emailRecipients.length > 0 ||
        SMSRecipients.length > 0 ||
        TTSCellRecipients.length > 0 ||
        TTSHomeRecipients.length > 0 ||
        pagerRecipients.length > 0 ||
        nonMembers.length > 0 ||
        selectedDistributionLists.length > 0 ||
        groupMembersToNotify.length > 0 ||
        recipientTypes.length !== allRecipientTypesOptions.length ||
        templateTitle !== '';

      if (viewType === 'Notifications Log' && isFilled) {
        setShowConfirmDialogType('Switch Tab');
        setShowConfirmDialog(true);
      } else {
        setViewType(viewType);
      }
    },
    openManageDistributionListModal: () => {
      setManageDistributionListsDialogOpen(true);
    },
  }));

  useEffect(() => {
    setDistributionLists(distributionListItems);
  }, [distributionListItems]);

  const [selectedDistributionLists, setSelectedDistributionLists] = useState(
    []
  );

  const [saveAsTemplate, setSaveAsTemplate] = useState(false);

  const [templateTitle, setTemplateTitle] = useState('');
  const [templateTitleError, setTemplateTitleError] = useState(false);

  const reduxValidateRBACPermissionForActionResult =
    useSelector((state) => {
      return state.app.validateRBACPermissionForActionResult;
    }) || [];

  const reduxCurrentlySelectedGroup = useSelector((state) => {
    return state.app.currentlySelectedGroup;
  });

  const reduxSendNotificationResult = useSelector((state) => {
    return state.app.sendNotificationResult;
  });

  const isSendNotificationLoaded = useSelector((state) => {
    return state.app.isSendNotificationLoaded;
  });

  const isUpsertNotificationLoaded = useSelector((state) => {
    return state.app.isUpsertNotificationLoaded;
  });

  const reduxUpsertedNotification = useSelector((state) => {
    return state.app.upsertedNotification;
  });

  const reduxNotifications = useSelector((state) => {
    return state.app.notifications;
  });

  const isFetchRostersForGroupsLoaded = useSelector((state) => {
    return state.app.isFetchRostersForGroupsLoaded;
  });

  const reduxFocusUser = useSelector((state) => {
    return state.app.focusUser;
  });

  const reduxNotificationsPageData = useSelector((state) => {
    return state.app.notificationPageData;
  });

  const [showConfirmDialogType, setShowConfirmDialogType] = useState('');
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [showConfirmDialogData, setShowConfirmDialogData] = useState(null);

  useEffect(() => {
    if (!!reduxNotifications && !!reduxNotifications.length) {
      setAllNotifications(
        reduxNotifications.map((i) => {
          return { ...i, id: generateUUID() };
        })
      );
    } else {
      setAllNotifications([]);
    }
  }, [reduxNotifications]);

  useEffect(() => {
    if (!!reduxSendNotificationResult) {
      setUsersNotInDICE(reduxSendNotificationResult.usersNotInDICE);
      setUsersNotInGroup(reduxSendNotificationResult.usersNotInGroup);
      setEmailRecipientFailures(
        reduxSendNotificationResult.EmailRecipientFailures
      );
      setSMSRecipientFailures(reduxSendNotificationResult.SMSRecipientFailures);
      setTTSCellRecipientFailures(
        reduxSendNotificationResult.TTSCellRecipientFailures
      );
      setTTSHomeRecipientFailures(
        reduxSendNotificationResult.TTSHomeRecipientFailures
      );
      setPagerRecipientFailures(
        reduxSendNotificationResult.PagerRecipientFailures
      );
    }
  }, [reduxSendNotificationResult]);

  useEffect(() => {
    if (!!selectedNotification) {
      setNotificationPageData(selectedNotification);
    } else {
      setRecipientTypes(allRecipientTypes);
      setEmailRecipients([]);
      setSMSRecipients([]);
      setTTSCellRecipients([]);
      setTTSHomeRecipients([]);
      setPagerRecipients([]);
      setSubject('');
      setMessage('');
      setSMSMessage('');
      setTTSMessage('');
      setPagerMessage('');
      setSelectedCheckBoxOptions([]);
      setSaveAsTemplate(false);
      setTemplateTitle('');
      setGroupMembersToNotify([]);
      setSelectedDistributionLists([]);
      setNonMembers([]);
    }
  }, [selectedNotification]);

  useEffect(() => {
    if (!!reduxNotificationsPageData) {
      setNotificationPageData(reduxNotificationsPageData);
    }
  }, [reduxNotificationsPageData]);

  const setNotificationPageData = (pageData) => {
    setRecipientTypes(pageData.settings.recipientSettings.recipientTypes || []);
    setEmailRecipients(
      backwardsCompatibleRecipientsFormat(
        pageData.settings.recipientSettings.emailRecipientSettings
          .emailRecipients
      )
    );
    setSMSRecipients(
      backwardsCompatibleRecipientsFormat(
        pageData.settings.recipientSettings.SMSRecipientSettings.SMSRecipients
      )
    );
    setTTSCellRecipients(
      backwardsCompatibleRecipientsFormat(
        pageData.settings.recipientSettings.TTSRecipientSettings &&
          pageData.settings.recipientSettings.TTSRecipientSettings
            .TTSCellRecipients
      )
    );
    setTTSHomeRecipients(
      backwardsCompatibleRecipientsFormat(
        pageData.settings.recipientSettings.TTSRecipientSettings &&
          pageData.settings.recipientSettings.TTSRecipientSettings
            .TTSHomeRecipients
      )
    );
    setPagerRecipients(
      backwardsCompatibleRecipientsFormat(
        pageData.settings.recipientSettings.pagerRecipientSettings &&
          pageData.settings.recipientSettings.pagerRecipientSettings
            .pagerRecipients
      )
    );
    setSubject(
      pageData.subject ||
        pageData.settings.recipientSettings.emailRecipientSettings.emailSubject
    );
    const nonMembersOnTemplates = [
      ...backwardsCompatibleRecipientsFormat(
        pageData.settings.recipientSettings.emailRecipientSettings
          .emailRecipients
      ),
      ...backwardsCompatibleRecipientsFormat(
        pageData.settings.recipientSettings.SMSRecipientSettings.SMSRecipients
      ),
      ...backwardsCompatibleRecipientsFormat(
        pageData.settings.recipientSettings.TTSRecipientSettings &&
          pageData.settings.recipientSettings.TTSRecipientSettings
            .TTSCellRecipients
      ),
      ...backwardsCompatibleRecipientsFormat(
        pageData.settings.recipientSettings.TTSRecipientSettings &&
          pageData.settings.recipientSettings.TTSRecipientSettings
            .TTSHomeRecipients
      ),
      ...backwardsCompatibleRecipientsFormat(
        pageData.settings.recipientSettings.pagerRecipientSettings &&
          pageData.settings.recipientSettings.pagerRecipientSettings
            .pagerRecipients
      ),
    ];
    setNonMembers(nonMembersOnTemplates);
    setTemplateTitle(pageData.template_title);
    setMessage(
      pageData.message ||
        pageData.settings.recipientSettings.emailRecipientSettings.emailMessage
    );
    setSMSMessage(
      pageData.settings.recipientSettings.SMSRecipientSettings.SMSMessage || ''
    );
    setTTSMessage(
      (pageData.settings.contentSettings &&
        pageData.settings.contentSettings.TTSMessage) ||
        (pageData.settings.recipientSettings.TTSMessageRecipientSettings &&
          pageData.settings.recipientSettings.TTSMessageRecipientSettings
            .TTSMessage) ||
        ''
    );
    setPagerMessage(
      (pageData.settings.contentSettings &&
        pageData.settings.contentSettings.pagerMessage) ||
        pageData.settings.recipientSettings.pagerRecipientSettings
          .pagerMessage ||
        ''
    );
    setGroupMembersToNotify(
      pageData.settings.recipientSettings.groupMembersToNotify || []
    );

    if (pageData.settings.recipientSettings.distributionLists) {
      setSelectedDistributionLists(
        pageData.settings.recipientSettings.distributionLists
      );
    }
  };

  useEffect(() => {
    setIsValidForEmail(true);
    if (!!recipientTypes.find((t) => t === 'Email')) {
      // If there are no recipients, return false
      if (
        selectedCheckBoxOptions.length ||
        (emailRecipients.length &&
          !emailRecipients
            .map((r) => getEmailValidationsForItem(r.content))
            .filter((r) => !!r.length).length) ||
        groupMembersToNotify.length ||
        selectedDistributionLists.length ||
        !!nonMembers.filter((m) => m.type === 'emailRecipients').length
      ) {
        if (groupMembersToNotify.length || selectedDistributionLists.length) {
          setIsValidForEmail(true);
        }
      } else {
        setIsValidForEmail(false);
      }

      if (
        !subject.length ||
        !message.length ||
        message.length > emailMessageMaxLength
      ) {
        setIsValidForEmail(false);
      }
    }

    setIsValidForSms(true);
    if (!!recipientTypes.find((t) => t === 'SMS')) {
      // If there are no recipients, return false
      if (
        selectedCheckBoxOptions.length ||
        (SMSRecipients.length &&
          !SMSRecipients.map((r) => getSMSValidationsForItem(r.content)).filter(
            (r) => !!r.length
          ).length) ||
        groupMembersToNotify.length ||
        selectedDistributionLists.length ||
        !!nonMembers.filter((m) => m.type === 'SMSRecipients').length
      ) {
        if (groupMembersToNotify.length || selectedDistributionLists.length) {
          setIsValidForSms(true);
        }
      } else {
        setIsValidForSms(false);
      }

      if (!SMSMessage.length || SMSMessage.length > smsMessageMaxLength) {
        setIsValidForSms(false);
      }
    }

    setIsValidForTTSCell(true);
    if (!!recipientTypes.find((t) => t === 'Text-To-Speech (cell)')) {
      // If there are no recipients, return false
      if (
        selectedCheckBoxOptions.length ||
        (TTSCellRecipients.length &&
          !TTSCellRecipients.map((r) =>
            getTTSValidationsForItem(r.content)
          ).filter((r) => !!r.length).length) ||
        groupMembersToNotify.length ||
        selectedDistributionLists.length ||
        !!nonMembers.filter((m) => m.type === 'TTSCellRecipients').length
      ) {
        if (groupMembersToNotify.length || selectedDistributionLists.length) {
          setIsValidForTTSCell(true);
        }
      } else {
        setIsValidForTTSCell(false);
      }

      if (!TTSMessage.length || TTSMessage.length > ttsMessageMaxLength) {
        setIsValidForTTSCell(false);
      }
    }

    setIsValidForTTSHome(true);
    if (!!recipientTypes.find((t) => t === 'Text-To-Speech (home)')) {
      // If there are no recipients, return false
      if (
        selectedCheckBoxOptions.length ||
        (TTSHomeRecipients.length &&
          !TTSHomeRecipients.map((r) =>
            getTTSValidationsForItem(r.content)
          ).filter((r) => !!r.length).length) ||
        groupMembersToNotify.length ||
        selectedDistributionLists.length ||
        !!nonMembers.filter((m) => m.type === 'TTSHomeRecipients').length
      ) {
        if (groupMembersToNotify.length || selectedDistributionLists.length) {
          setIsValidForTTSHome(true);
        }
      } else {
        setIsValidForTTSHome(false);
      }

      if (!TTSMessage.length || TTSMessage.length > ttsMessageMaxLength) {
        setIsValidForTTSHome(false);
      }
    }

    setIsValidForPager(true);
    if (!!recipientTypes.find((t) => t === 'Pager')) {
      // If there are no recipients, return false
      if (
        selectedCheckBoxOptions.length ||
        (pagerRecipients.length &&
          !pagerRecipients
            .map((r) => getEmailValidationsForItem(r.content))
            .filter((r) => !!r.length).length) ||
        groupMembersToNotify.length ||
        selectedDistributionLists.length ||
        !!nonMembers.filter((m) => m.type === 'pagerRecipients').length
      ) {
        if (groupMembersToNotify.length || selectedDistributionLists.length) {
          setIsValidForPager(true);
        }
      } else {
        setIsValidForPager(false);
      }
      if (!pagerMessage.length || pagerMessage.length > pagerMessageMaxLength) {
        setIsValidForPager(false);
      }
    }
  }, [
    recipientTypes,
    selectedCheckBoxOptions,
    emailRecipients,
    SMSRecipients,
    subject,
    pagerMessage,
    TTSHomeRecipients,
    TTSCellRecipients,
    pagerRecipients,
    TTSMessage,
    SMSMessage,
    message,
    groupMembersToNotify,
    selectedDistributionLists,
    nonMembers,
  ]);

  const reduxRostersForGroups = useSelector((state) => {
    let rosters = state.app.rostersForGroups;
    if (Array.isArray(rosters) && rosters.length) {
      rosters = rosters
        .filter((member) => {
          return member.group_guid === reduxCurrentlySelectedGroup.group_guid;
        })
        .map((member) => {
          return {
            ...member,
            role_string: member.role_assignments.reduce((acc, item) => {
              acc = acc ? acc + ', ' + item.name : item.name;

              return acc;
            }, ''),
          };
        });
    }
    return rosters || [];
  });

  // Note: We're in the midst of refactoring the shape of this object.
  // So there will need to be some redundancy while we sort out the back-end
  // Consult  https://github.com/DisasterTechInc/COMS/blob/develop/src/routes/Notifications/NotificationsConstants.js#L3
  // to see the intended shape for this object when resolving redunancy.

  function createNotificationObject() {
    let newNotification = {
      notification_id:
        !!selectedNotification &&
        saveAsTemplate &&
        templateTitle === selectedNotification.template_title
          ? selectedNotification.notification_id
          : generateUUID(),
      template_title: saveAsTemplate ? templateTitle : undefined,
      subject: subject,
      message: message,
      group_guid: reduxCurrentlySelectedGroup.group_guid,
      settings: {
        recipientSettings: {
          recipientTypes: recipientTypes,
          emailRecipientSettings: {
            emailRecipients: nonMembers.filter(
              (ele) => ele.type === 'emailRecipients'
            ),
            emailSubject: subject,
            emailMessage: message,
          },
          SMSRecipientSettings: {
            SMSRecipients: nonMembers.filter(
              (ele) => ele.type === 'SMSRecipients'
            ),
            SMSMessage: SMSMessage,
          },
          TTSRecipientSettings: {
            TTSCellRecipients: nonMembers.filter(
              (ele) => ele.type === 'TTSCellRecipients'
            ),
            TTSHomeRecipients: nonMembers.filter(
              (ele) => ele.type === 'TTSHomeRecipients'
            ),
            TTSMessage: TTSMessage,
          },
          pagerRecipientSettings: {
            pagerRecipients: nonMembers.filter(
              (ele) => ele.type === 'pagerRecipients'
            ),
            pagerMessage: pagerMessage,
          },
          groupMembersToNotify: groupMembersToNotify,
          distributionLists: selectedDistributionLists,
          disabledGuids: disabledGuids,
        },
        contentSettings: {
          TTSMessage: TTSMessage,
          pagerMessage: pagerMessage,
        },
      },
    };
    return newNotification;
  }

  function backwardsCompatibleRecipientsFormat(recipients) {
    if (!recipients || (!!recipients && !recipients.length)) {
      return [];
    } else {
      if (!!recipients[0].id) {
        return recipients;
      } else {
        return recipients.map((r) => {
          return { id: generateUUID, content: r };
        });
      }
    }
  }

  const handleFormSubmit = () => {
    const notification = createNotificationObject();

    let fData = [];
    notification.settings.recipientSettings.distributionLists.map((d) => {
      const data =
        distributionListItems.find((dL) => dL.id === d)?.members || [];
      // if (data?.length) {
      fData = [...fData, ...data];
      // }
    });
    const gMN = notification.settings.recipientSettings.groupMembersToNotify;
    fData = Array.from(new Set([...fData, ...gMN]));

    const fAvail = fData.map((fd) => {
      const uAvail = reduxRostersForGroups.find((rg) => rg.user_guid === fd);
      return {
        user_guid: uAvail.user_guid,
        user_name: uAvail.user_name,
        email_address: uAvail.email_address,
        unavailability: uAvail.unavailability,
      };
    });

    const unavailableUsers = [];

    fAvail
      .map((fa) => {
        const fud = fa.unavailability.find(
          (fau) =>
            fau.start_time <= moment().toISOString() &&
            fau.end_time >= moment().toISOString()
        );
        if (fud) {
          unavailableUsers.push(fa);
          return null;
        } else {
          return fa;
        }
      })
      .filter((data) => data !== null);

    if (unavailableUsers.length) {
      setUnavailableUsersData(unavailableUsers);
      setShowUnavailableUserNotificationDailog(true);
    }

    // console.log('notification: ', fData);
    // console.log('notification: ', fAvail);
    // console.log('notification: ', finalData);
    // console.log('notification: ', unavailableUsers);

    if (saveAsTemplate) {
      if (templateTitle === '' || !templateTitle) {
        setTemplateTitleError(true);
        return;
      }
      const currentTemplate = reduxNotifications.findIndex(
        (ele) => ele.notification_id === notification.notification_id
      );
      if (currentTemplate === -1) {
        const sameNameTemplate = reduxNotifications.findIndex(
          (ele) => ele.template_title === notification.template_title
        );
        if (sameNameTemplate !== -1) {
          const errorMessage =
            `Duplicate Template Name\n\n` +
            'Failed Create Notification Template';
          console.warn(errorMessage);
          toast.error(errorMessage, toastConfig);
          return;
        }
      }
    }

    if (notification.template_title) {
      setSelectedNotification(notification);
    }
    setShowConfirmDialogType('Send Notification');
    setShowConfirmDialogData(notification);
    setShowConfirmDialog(true);
  };

  const onConfirmedSendNotification = () => {
    reduxDispatch(upsertNotificationByGroup(showConfirmDialogData));
    reduxDispatch(sendNotificationByGroup(showConfirmDialogData));
    setCurrentLoggedInstance();
    setDisabledGuids([]);
    setShowConfirmDialog(false);
    setShowConfirmDialogData(null);
    setShowConfirmDialogType('');
  };

  useEffect(() => {
    !!templateTitle && setTemplateTitleError(false);
  }, [templateTitle]);

  const handleTemplateSave = () => {
    if (
      groupMembersToNotify.length === 0 &&
      nonMembers.length === 0 &&
      selectedDistributionLists.length === 0
    ) {
      toast.error('Please select recipient to save template.', toastConfig);
      return;
    }
    if (templateTitle === '' || !templateTitle) {
      setTemplateTitleError(true);
      return;
    }
    const notification = createNotificationObject();
    const currentTemplate = reduxNotifications.findIndex(
      (ele) => ele.notification_id === notification.notification_id
    );
    if (currentTemplate === -1) {
      const sameNameTemplate = reduxNotifications.findIndex(
        (ele) => ele.template_title === notification.template_title
      );
      if (sameNameTemplate !== -1) {
        const errorMessage =
          `Duplicate Template Name.\n\n` +
          'Failed Create Notification Template';
        toast.error(errorMessage, toastConfig);
        console.warn(errorMessage);
        return;
      }
    }

    setSelectedNotification(notification);
    reduxDispatch(upsertNotificationByGroup(notification));
  };

  function getEmailValidationsForItem(item) {
    let validation_messages = [];
    if (!item) {
      validation_messages = [...validation_messages, `Email cannot be blank`];
    }
    if (!validateEmailAddress(item)) {
      validation_messages = [...validation_messages, `Invalid email address`];
    }
    return validation_messages;
  }

  function getSMSValidationsForItem(item) {
    let validation_messages = [];
    if (!item) {
      validation_messages = [
        ...validation_messages,
        `SMS number cannot be blank`,
      ];
    }
    if (
      !item.match(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/g)
    ) {
      validation_messages = [...validation_messages, `Invalid SMS number`];
    }
    return validation_messages;
  }

  function getTTSValidationsForItem(item) {
    let validation_messages = [];
    if (!item) {
      validation_messages = [
        ...validation_messages,
        `Text-To-Speech number cannot be blank`,
      ];
    }
    if (
      !item.match(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/g)
    ) {
      validation_messages = [
        ...validation_messages,
        `Invalid Text-To-Speech number`,
      ];
    }
    return validation_messages;
  }

  function getPagerValidationsForItem(item) {
    let validation_messages = [];
    if (!item) {
      validation_messages = [
        ...validation_messages,
        `Pager address cannot be blank`,
      ];
    }
    if (!validatePagerAddress(item)) {
      validation_messages = [...validation_messages, `Invalid pager address`];
    }
    return validation_messages;
  }

  function recipientTypeClicked(type) {
    if (!!recipientTypes.find((t) => t === type)) {
      setRecipientTypes(recipientTypes.filter((t) => t !== type));
    } else {
      setRecipientTypes([...recipientTypes, type]);
    }
  }

  useEffect(() => {
    if (
      !!reduxFocusUser &&
      !!reduxFocusUser.user_guid &&
      !!fetchedUserProfile
    ) {
      setFetchedUserProfile(false);
      navigate(`/profile/${reduxFocusUser.user_guid}`);
    }
  }, [reduxFocusUser]);

  const typeKey = {
    Email: 'emailRecipients',
    SMS: 'SMSRecipients',
    'Text-To-Speech (home)': 'TTSHomeRecipients',
    'Text-To-Speech (cell)': 'TTSCellRecipients',
    Pager: 'pagerRecipients',
  };

  useEffect(() => {
    setNonMembers(
      nonMembers.filter(
        (item) => !!recipientTypes.find((type) => typeKey[type] === item.type)
      )
    );
  }, [recipientTypes]);

  const handleRecipientNameClick = (recipient) => {
    setFetchedUserProfile(true);
    reduxDispatch(getUserById(recipient));
  };

  const handleRecipientDeleteClick = (recipient) => {
    if (nonMembers.find((m) => m.id === recipient.id)) {
      setNonMembers(nonMembers.filter((m) => m.id !== recipient.id));
    } else {
      setGroupMembersToNotify(
        groupMembersToNotify.filter((member) => member !== recipient)
      );
    }
  };

  const handleDistributionListDeleteClick = (listId) => {
    setSelectedDistributionLists(
      selectedDistributionLists.filter((list) => list !== listId)
    );
  };

  const handleDistributionListAddToSelectedList = (listId) => {
    setSelectedDistributionLists([...selectedDistributionLists, listId]);
  };

  const handleDisableGuid = (guid) => {
    if (!!disabledGuids.find((t) => t === guid)) {
      setDisabledGuids(disabledGuids.filter((t) => t !== guid));
    } else {
      setDisabledGuids([...disabledGuids, guid]);
    }
  };

  const handleMessageInput = (message) => {
    if (message.length <= emailMessageMaxLength) setMessage(message);
  };

  const renderTTSCellMessage =
    Array.isArray(recipientTypes) &&
    !!recipientTypes.find((t) => t === 'Text-To-Speech (cell)');
  const renderTTSHomeMessage =
    Array.isArray(recipientTypes) &&
    !!recipientTypes.find((t) => t === 'Text-To-Speech (home)');
  const renderTTSMessage = renderTTSHomeMessage || renderTTSCellMessage;

  if (!reduxNotifications) {
    return <div>Loading Notifications...</div>;
  }

  const onSwitchSaveNotification = () => {
    const notification = createNotificationObject();
    reduxDispatch(addNotificationPageData(notification));
    setViewType('Notifications Log');
  };

  return (
    <>
      <Alert variant="info" className="mb-4 d-flex align-items-center">
        <SharedIcon iconName="info" classes="me-2" />
        Notifications require Recipients, Subject, and Message to be set. You
        will only be able to save a notification template if a role is selected
        or a recipient is added
      </Alert>
      <div className="form-block mb-4">
        <h4 className="mb-4">
          Select Type
          <span aria-label="Required field" className="required">
            *
          </span>
        </h4>
        {allRecipientTypes.map((type, idx) => (
          <div
            key={`key-${idx}`}
            className={`${idx !== allRecipientTypes.length - 1 ? 'mb-2' : ''}`}
          >
            <StylishNewCheckbox
              className={'d-inline-flex'}
              key={`recipient-type-checkbox-${type}`}
              checked={!!recipientTypes.find((t) => t === type)}
              onClick={() => recipientTypeClicked(type)}
              label={type}
            />
          </div>
        ))}
        <p className="fst-italic weight-500 mt-4 mb-0">
          Note: Notifications with custom messages and recipients can be
          defined.
        </p>
      </div>

      <div className="form-block mb-4">
        <h4 className="mb-4">
          Recipients
          <span aria-label="Required field" className="required">
            *
          </span>
        </h4>
        {!!isFetchRostersForGroupsLoaded && !!reduxRostersForGroups.length && (
          <>
            {!!groupMembersToNotify && !!groupMembersToNotify.length ? (
              <h5 className="mb-2">Selected Recipients</h5>
            ) : null}
            {!!groupMembersToNotify && !!groupMembersToNotify.length && (
              <>
                <div className="form-chip-group mb-4">
                  {groupMembersToNotify
                    .filter((member) =>
                      reduxRostersForGroups.find(
                        (person) => person.user_guid === member
                      )
                    )
                    .map((member) => {
                      const user = reduxRostersForGroups.find(
                        (person) => person.user_guid === member
                      );
                      const name = user.user_name || user.email_address;
                      return (
                        <>
                          <StylishNewChip
                            key={`key-${member}`}
                            label={name}
                            handleDelete={() => {
                              handleRecipientDeleteClick(member);
                            }}
                            handleDisable={() => handleDisableGuid(member)}
                            disabled={
                              !!disabledGuids.find((guid) => guid === member)
                            }
                          />
                        </>
                      );
                    })}
                </div>
              </>
            )}
            {!!nonMembers && !!nonMembers.length && (
              <>
                <div className="form-chip-group mb-4">
                  {nonMembers.map((member) => {
                    return (
                      <StylishNewChip
                        key={`key-${member.id}`}
                        label={member.content}
                        handleDelete={() => {
                          handleRecipientDeleteClick(member);
                        }}
                        handleDisable={() => handleDisableGuid(member)}
                        disabled={
                          !!disabledGuids.find((guid) => guid === member)
                        }
                      />
                    );
                  })}
                </div>
              </>
            )}
          </>
        )}
        {selectedDistributionLists.length ? (
          <h5 className="mb-2">Selected Team(s)</h5>
        ) : null}
        {selectedDistributionLists.length ? (
          <Accordion defaultActiveKey="0" flush className="mb-4">
            {selectedDistributionLists.map((listId) => {
              const item = distributionLists.find((list) => list.id === listId);
              if (!item) {
                return null;
              }
              return (
                <Accordion.Item eventKey={item.id}>
                  <Accordion.Header className="d-flex align-items-center">
                    {item.name}
                    <StylishNewButton
                      customButton
                      className="button--icon ms-auto"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        handleDistributionListDeleteClick(item.id);
                      }}
                    >
                      <SharedIcon iconName="delete" />
                    </StylishNewButton>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="form-chip-group">
                      {item.members
                        .filter((member) => {
                          return (
                            !!reduxRostersForGroups &&
                            !!reduxRostersForGroups.length &&
                            reduxRostersForGroups.some(
                              (person) => person.user_guid === member
                            )
                          );
                        })
                        .map((member) => {
                          const user = reduxRostersForGroups.find(
                            (person) => person.user_guid === member
                          );
                          const name = user.user_name || user.email_address;
                          return (
                            <>
                              <StylishNewChip
                                key={`key-${member}`}
                                label={name}
                                disabled={
                                  !!disabledGuids.find(
                                    (guid) => member === guid
                                  )
                                }
                                handleDisable={() => handleDisableGuid(member)}
                              />
                            </>
                          );
                        })}
                      {!!item.nonMembers &&
                        item.nonMembers.map((member) => {
                          return (
                            <StylishNewChip
                              key={`key-${member.id}`}
                              label={member.content}
                              handleDisable={() => handleDisableGuid(member.id)}
                              disabled={
                                !!disabledGuids.find(
                                  (guid) => guid === member.id
                                )
                              }
                            />
                          );
                        })}
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              );
            })}
          </Accordion>
        ) : null}

        <div className="button-group d-block d-md-flex">
          <StylishNewButton
            className={'button--secondary w-100 w-md-auto'}
            onClick={() => setAddDistributionListDialogOpen(true)}
          >
            Select Relevant Team(s)
          </StylishNewButton>
          <StylishNewButton
            className={
              'button--secondary w-100 w-md-auto ms-0 ms-md-3 mt-2 mt-md-0'
            }
            onClick={() => setAddNewRecipientsDialogOpen(true)}
          >
            Add New Recipients
          </StylishNewButton>
        </div>
      </div>

      <div className="form-block mb-4">
        {!!recipientTypes.length && (
          <>
            <h4 className="mb-4">Message</h4>
            {recipientTypes.find((t) => t === 'Email') && (
              <>
                <div className="mb-4">
                  <label className="form-label">Email Subject</label>
                  <StylishNewInput
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                  />
                </div>
                <div className="mb-4">
                  <label className="form-label">Email Message</label>
                  <StylishNewTextArea
                    value={message}
                    onChange={(e) => handleMessageInput(e.target.value)}
                  />
                  <span className="form-info d-flex mt-2 weight-500">
                    Character Count: {message?.length || 0} - Characters
                    Remaining: {emailMessageMaxLength - message?.length || 0}
                  </span>
                  {message?.length > emailMessageMaxLength && (
                    <span className="form-text form-error">
                      You cannot send more than {emailMessageMaxLength}{' '}
                      characters via Pager
                    </span>
                  )}
                </div>
              </>
            )}
            {!!recipientTypes.find((t) => t === 'SMS') && (
              <div className="mb-4">
                <label className="form-label">SMS Message</label>
                <StylishNewTextArea
                  value={SMSMessage}
                  onChange={(e) => setSMSMessage(e.target.value)}
                />
                <span className="form-info d-flex mt-2 weight-500">
                  Character count: {SMSMessage.length || 0} - Characters
                  Remaining: {smsMessageMaxLength - SMSMessage.length || 0}
                </span>
                {SMSMessage.length > smsMessageMaxLength && (
                  <span className="form-text form-error">
                    You cannot send more than {smsMessageMaxLength} characters
                    via SMS
                  </span>
                )}
              </div>
            )}
            {!!recipientTypes.find((t) => t === 'Pager') && (
              <div className="mb-4">
                <label className="form-label">Pager Message</label>
                <StylishNewTextArea
                  value={pagerMessage}
                  onChange={(e) => setPagerMessage(e.target.value)}
                />
                <span className="form-info d-flex mt-2 weight-500">
                  Character count: {pagerMessage.length || 0} - Characters
                  Remaining: {pagerMessageMaxLength - pagerMessage.length || 0}
                </span>
                {pagerMessage.length > pagerMessageMaxLength && (
                  <span className="form-text form-error">
                    You cannot send more than {pagerMessageMaxLength} characters
                    via Pager
                  </span>
                )}
              </div>
            )}
            {renderTTSMessage && (
              <div className="mb-4">
                <label className="form-label">Text-To-Speech Message</label>
                <StylishNewTextArea
                  value={TTSMessage}
                  onChange={(e) => setTTSMessage(e.target.value)}
                />
                <span className="form-info d-flex mt-2 weight-500">
                  Character count: {TTSMessage.length || 0} - Characters
                  Remaining: {ttsMessageMaxLength - TTSMessage.length || 0}
                </span>
                {TTSMessage.length > ttsMessageMaxLength && (
                  <span className="form-text form-error">
                    You cannot send more than {ttsMessageMaxLength} characters
                    via Text-To-Speech
                  </span>
                )}
              </div>
            )}
            <hr className="dashed" />
          </>
        )}

        <StylishNewCheckbox
          checked={saveAsTemplate}
          onClick={() => {
            setSaveAsTemplate(!saveAsTemplate);
          }}
          label="Save as Template"
          disabled={
            !reduxValidateRBACPermissionForActionResult[
              'Manage Notification Templates'
            ]
          }
        />
        {saveAsTemplate && (
          <div className="mt-4">
            <label className="form-label">Template Title</label>
            <StylishNewInput
              value={templateTitle}
              onChange={(e) => setTemplateTitle(e.target.value)}
            />
            {templateTitleError ? (
              <span className="form-text form-error">
                This is a required field
              </span>
            ) : null}
            <StylishNewButton
              onClick={handleTemplateSave}
              className="button--primary mt-3"
              disabled={
                !reduxValidateRBACPermissionForActionResult[
                  'Manage Notification Templates'
                ]
              }
            >
              Save
            </StylishNewButton>
          </div>
        )}
      </div>

      {!reduxValidateRBACPermissionForActionResult[
        'Manage Notification Templates'
      ] && (
        <span className="form-info d-flex mt-5 weight-500">
          You do not have permission to Manage Notification Templates.
          Permissions are managed on the &nbsp;
          <Link to="/groups">groups</Link>
          &nbsp; page
        </span>
      )}

      {!reduxValidateRBACPermissionForActionResult['Send Notification'] && (
        <span className="form-info d-flex mt-5 weight-500">
          You do not have permission to Send Notifications. Permissions are
          managed on the &nbsp;
          <Link to="/groups">groups</Link>
          &nbsp; page
        </span>
      )}

      {!recipientTypes.length && (
        <span className="form-info d-flex mt-5 weight-500">
          At least one recipient type is required for a Notification
        </span>
      )}
      <hr className="dashed my-4" />
      <div className="d-flex align-items-center mb-5">
        {!isUpsertNotificationLoaded && (
          <span className="form-text d-flex align-items-center weight-500 me-3">
            <i class="fa fa-spinner fa-pulse fa-2x me-2"></i> Saving
            Notification...
          </span>
        )}
        {!isSendNotificationLoaded && (
          <span className="form-text d-flex align-items-center weight-500 me-3">
            <i class="fa fa-spinner fa-pulse fa-2x me-2"></i> Sending
            Notification...
          </span>
        )}
        {!!isSendNotificationLoaded && reduxSendNotificationResult && (
          <span className="form-text d-flex align-items-center weight-500 me-3">
            <i class="fa fa-check-circle fa-2x me-2" aria-hidden="true"></i>{' '}
            Notification Sent!
          </span>
        )}
        {!!isUpsertNotificationLoaded &&
          reduxUpsertedNotification &&
          !!saveAsTemplate && (
            <span className="form-text d-flex align-items-center weight-500 me-3">
              <i class="fa fa-check-circle fa-2x me-2" aria-hidden="true"></i>{' '}
              Notification Template Saved!
            </span>
          )}
        <StylishNewButton
          onClick={handleFormSubmit}
          className="button--primary ms-auto"
          disabled={
            // !isValidForSMS ||
            // !isValidForEmail ||
            // !isValidForTTSCell ||
            // !isValidForTTSHome ||
            // !isValidForPager ||
            !recipientTypes.length ||
            !reduxValidateRBACPermissionForActionResult['Send Notification'] ||
            !(
              nonMembers.length ||
              groupMembersToNotify.length ||
              selectedDistributionLists.length
            )
          }
        >
          Send
        </StylishNewButton>
      </div>

      {selectTemplateDialogOpen && (
        <SelectTemplateDialog
          show={selectTemplateDialogOpen}
          selectedNotification={selectedNotification}
          availableNotifications={allNotifications}
          setSelectedNotification={setSelectedNotification}
          setSaveAsTemplate={setSaveAsTemplate}
          onClose={() => setSelectTemplateDiaglogOpen(false)}
        />
      )}
      {addDistributionListDialogOpen && (
        <AddDistributionListDialog
          show={addDistributionListDialogOpen}
          distributionLists={distributionLists}
          setDistributionLists={setDistributionLists}
          selectedDistributionLists={selectedDistributionLists}
          setSelectedDistributionLists={setSelectedDistributionLists}
          roster={reduxRostersForGroups}
          onClose={() => setAddDistributionListDialogOpen(false)}
          recipientTypes={recipientTypes}
        />
      )}
      {addNewRecipientsDialogOpen && (
        <AddNewRecipientsDialog
          show={addNewRecipientsDialogOpen}
          groupMembersToNotify={groupMembersToNotify}
          setGroupMembersToNotify={setGroupMembersToNotify}
          distributionLists={distributionLists}
          setDistributionLists={setDistributionLists}
          handleDistributionListAddToSelectedList={
            handleDistributionListAddToSelectedList
          }
          roster={reduxRostersForGroups}
          onClose={() => setAddNewRecipientsDialogOpen(false)}
          recipientTypes={recipientTypes}
          nonMembers={nonMembers}
          setNonMembers={setNonMembers}
        />
      )}
      {manageDistributionListsDialogOpen && (
        <ManageDistributionListsDialog
          show={manageDistributionListsDialogOpen}
          distributionLists={distributionLists}
          setDistributionLists={setDistributionLists}
          selectedDistributionLists={selectedDistributionLists}
          setSelectedDistributionLists={setSelectedDistributionLists}
          roster={reduxRostersForGroups}
          recipientTypes={recipientTypes}
          onClose={() => setManageDistributionListsDialogOpen(false)}
        />
      )}

      {showConfirmDialog && (
        <StylishConfirmDialog
          show={showConfirmDialog}
          dialogTitle={
            showConfirmDialogType === 'Send Notification'
              ? 'Send Notification'
              : showConfirmDialogType === 'Switch Tab'
              ? 'Save Notification'
              : null
          }
          dialogContent={
            showConfirmDialogType === 'Send Notification'
              ? 'Are you sure you want to send this Notification?'
              : showConfirmDialogType === 'Switch Tab'
              ? 'Do you want to save this notification work?'
              : null
          }
          dialogButtonText={
            showConfirmDialogType === 'Send Notification'
              ? 'Send'
              : showConfirmDialogType === 'Switch Tab'
              ? 'Save'
              : null
          }
          onClose={() => {
            if (showConfirmDialogType === 'Switch Tab') {
              reduxDispatch(clearNotificationPageData());
              setViewType('Notifications Log');
            }
            setShowConfirmDialog(false);
          }}
          onConfirm={
            showConfirmDialogType === 'Send Notification'
              ? onConfirmedSendNotification
              : showConfirmDialogType === 'Switch Tab'
              ? onSwitchSaveNotification
              : null
          }
        />
      )}

      {showUnavailableUserNotificationDailog && (
        <UnavailableUserNotificationDialog
          show={showUnavailableUserNotificationDailog}
          unavailableUsers={unavailableUsersData}
          onClose={() => {
            setShowUnavailableUserNotificationDailog(false);
            setUnavailableUsersData(null);
          }}
        />
      )}
    </>
  );
}

export default forwardRef(NotificationComposeScreen);
