import React, { useState } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { useParams } from 'react-router';
import IconTick from 'assets/images/icon__tick--blue.svg';
import { MSELInjectDetails } from './InjectDetails/InjectDetails';
import { MSELLocation } from './Location/Location';
import { MSELObjectivesMethods } from './ObjectivesMethods/ObjectivesMethods';
import { MSELReview } from './Review/Review';
import { MSELTriggerResponse } from './TriggerResponse/TriggerResponse';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { PreparePath } from 'utils/sharedUtils/sharedConst';
import { SharedIcon } from 'components/SharedIcon/SharedIcon';

export const MSELStepForm = () => {
  const location = useLocation();
  const params = useParams();
  const [step, setStep] = useState(location.state?.step || 0);

  const [injectTitle, setInjectTitle] = useState('');

  const navigate = useNavigate();

  const nextPage = (stepCount) => {
    setStep(stepCount);
    setSteps(
      steps.map((step, index) => {
        if (index === stepCount) {
          return {
            ...step,
            isValid: true,
          };
        } else {
          return { ...step, isValid: false };
        }
      })
    );
  };

  const renderStepMarker = (index) => {
    return (
      <span className="step-marker">
        {step <= index ? index + 1 : <img src={IconTick} alt="" />}
      </span>
    );
  };

  const changeName = (name) => setInjectTitle(name);

  const [steps, setSteps] = useState([
    {
      label: 'Inject Details',
      isValid: true,
      component: (
        <MSELInjectDetails
          onNextClick={nextPage}
          onPrevClick={nextPage}
          injectId={params.id}
          onNameChange={changeName}
        />
      ),
      className: 'step-item col-4 col-md mb-3 mb-md-0',
    },
    {
      label: 'Triger and Response',
      isValid: false,
      component: (
        <MSELTriggerResponse
          onPrevClick={nextPage}
          onNextClick={nextPage}
          injectId={params.id}
        />
      ),
      className: 'step-item col-4 col-md mb-3 mb-md-0',
    },
    {
      label: 'Location',
      isValid: false,
      component: (
        <MSELLocation
          onNextClick={nextPage}
          onPrevClick={nextPage}
          injectId={params.id}
        />
      ),
      className: 'step-item col-4 col-md mb-3 mb-md-0',
    },
    {
      label: 'Objectives and methods',
      isValid: false,
      component: (
        <MSELObjectivesMethods
          onPrevClick={nextPage}
          onNextClick={nextPage}
          injectId={params.id}
        />
      ),
      className: 'step-item col-4 col-md',
    },
    {
      label: 'Review',
      isValid: false,
      component: (
        <MSELReview
          onPrevClick={nextPage}
          onNextClick={nextPage}
          injectId={params.id}
        />
      ),
      className: 'step-item col-4 col-md',
    },
  ]);

  return (
    <>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <StylishNewButton onClick={() => navigate(-1)}>
              <SharedIcon iconName="keyboard_arrow_left" classes="me-2" />
              Back
            </StylishNewButton>
          </li>
          <li className="breadcrumb-item">
            <Link to={`${PreparePath}/dashboard/plan/prepare/msel-list`}>
              Inject
            </Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            {injectTitle}
          </li>
        </ol>
      </nav>
      <div className="steps my-4 my-md-5">
        <div className="row">
          <div
            className={
              steps[0].className +
              (step === 0 ? ' step-current' : ' step-completed')
            }
          >
            <span>
              {renderStepMarker(0)}
              <span className="step-title">Inject Details</span>
              <span className="step-divider"></span>
            </span>
          </div>
          <div
            className={
              steps[1].className +
              (step === 1 ? ' step-current' : step > 1 ? ' step-completed' : '')
            }
          >
            <span>
              {renderStepMarker(1)}
              <span className="step-title">Trigger &amp; Response</span>
              <span className="step-divider"></span>
            </span>
          </div>
          <div
            className={
              steps[2].className +
              (step === 2 ? ' step-current' : step > 2 ? ' step-completed' : '')
            }
          >
            <span>
              {renderStepMarker(2)}
              <span className="step-title">Location</span>
              <span className="step-divider"></span>
            </span>
          </div>
          <div
            className={
              steps[3].className +
              (step === 3 ? ' step-current' : step > 3 ? ' step-completed' : '')
            }
          >
            <span>
              {renderStepMarker(3)}
              <span className="step-title">Objectives &amp; Methods</span>
              <span className="step-divider"></span>
            </span>
          </div>
          <div
            className={
              steps[4].className +
              (step === 4 ? ' step-current' : step > 4 ? ' step-completed' : '')
            }
          >
            <span className="step-last-content">
              {renderStepMarker(4)}
              <span className="step-title">Review</span>
            </span>
          </div>
        </div>
      </div>
      <div className="plan-msel">
        {steps[step].component}
      </div>
    </>
  );
};
