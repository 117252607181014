import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Network from '../../../../../service/Network';
import API from '../../../../../service/api';
import { useNavigate } from 'react-router-dom';
import AddEditAccreditedTaskDialog from './AddEditAccreditedTaskDialog';
import AddEditCommandPriorityDialog from './AddEditCommandPriorityDialog';
import { toast } from 'react-toastify';
import { useForm, Controller } from 'react-hook-form';
import ConfirmModal from '../../../../../component/ConfirmModal/ConfirmModal';
import { HelpTasksPriorities, useSelfHostedKnowledgeBase } from 'assets/data/HelpContent';
import StylishNewTableAction from 'components/DesignSystems/New/StylishNewTableAction';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import StylishNewSelect from 'components/DesignSystems/New/StylishNewSelect';
import StylishNewTable from 'components/DesignSystems/New/StylishNewTable';
import { PreparePath } from 'utils/sharedUtils/sharedConst';
import StylishHelp from 'components/DesignSystems/New/StylishHelp';
import { toastConfig } from 'assets/data/config';
import { StylishConfirmDialog } from 'components/DesignSystems/New/StylishConfirmDialog';
import { endLoading, startLoading } from "../../../../../../reducers/loading/loading.action";

export default function Tasks_Priorities(props) {
  const [page, setPage] = useState(1);
  const [pageAcc, setPageAcc] = useState(1);
  const [orderBy, setOrderBy] = useState('');
  const [total, setTotal] = useState(0);
  const [totalAccreditedTasks, setTotalAccreditedTasks] = useState(0);
  const [perPage, setPerPage] = useState(5);
  const [perPageAcc, setPerPageAcc] = useState(5);
  const [accreditedTasks, setAccreditedTasks] = useState([]);
  const [coreCapabilities, setCoreCapabilities] = useState([]);
  const [coreCapabilitiesData, setCoreCapabilitiesData] = useState([]);
  const [postData, setPostData] = useState([]);
  const [dialogType, setDialogType] = useState('Add');
  const [updateDialogData, setUpdateDialogData] = useState(null);
  const [deleteAccreditedTasks, setDeleteAccreditedTasks] = useState(null);
  const [
    showAccreditedTasksAddEditDialog,
    setShowAccreditedTasksAddEditDialog,
  ] = useState(false);
  const [commandPriority, setCommandPriority] = useState([]);
  const [deleteCommandPriority, setDeleteCommandPriority] = useState(null);
  const [
    showCommandPriorityAddEditDialog,
    setShowCommandPriorityAddEditDialog,
  ] = useState(false);

  const selfHostedKnowledgeBase = useSelfHostedKnowledgeBase();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { control, setValue } = useForm();

  const { selectedWorkspace, workspaceName } = useSelector((state) => {
    return state.prepare.workspace;
  });
  const exerciseId = useSelector(
    (state) => state.prepare.exercise.exerciseDetailsId
  );
  const reduxUser = useSelector((state) => state.prepare.user);
  const workspaceType = useSelector((state) => state.prepare.workspace.type);

  const columnsForCoreCapabilities = [
    {
      dataField: 'title',
      text: 'Core Capabilities',
      sort: true,
      headerAttrs: { title: 'Sort By:' },
      attrs: { title: 'Core Capabilities' },
    },
    {
      dataField: 'area',
      text: 'Associated Mission Area(s)',
      attrs: { title: 'Associated Mission Area(s)' },
    },
  ];

  const columnsForAccreditedTasks = [
    {
      dataField: 'levelWar',
      text: 'Level',
      sort: true,
      headerAttrs: { title: 'Sort By:' },
      attrs: { title: 'Level' },
    },
    {
      dataField: 'taskNumber',
      text: 'Number',
      sort: true,
      attrs: { title: 'Number' },
    },
    {
      dataField: 'taskTitle',
      text: 'Title',
      sort: true,
      attrs: { title: 'Title' },
    },
    {
      dataField: '',

      text: 'Action',
      isDummyField: true,
      formatter: (cell, row, rowIndex) => (
        <StylishNewTableAction
          name="Accredited Task"
          cell={cell}
          row={row}
          rowIndex={rowIndex}
          onDelete={onDeleteAccreditedTasks}
          onEdit={onEditgetAccreditedTasks}
        />
      ),
      attrs: { title: 'Action' },
      headerStyle: {
        width: '100px',
      },
      headerAlign: 'center',
      align: 'center',
    },
  ];

  const columnsForCommandPriority = [
    {
      dataField: 'title',
      text: 'Title',
      sort: true,
      onSort: (field, order) => {
        if (order === 'asc') {
          setOrderBy('title_ASC');
        } else if (order === 'desc') {
          setOrderBy('title_DESC');
        } else {
          setOrderBy('');
        }
      },

      headerAttrs: { title: 'Sort By:' },
      attrs: { title: 'Title' },
    },
    {
      dataField: '',

      text: 'Action',
      isDummyField: true,
      formatter: (cell, row, rowIndex) => (
        <StylishNewTableAction
          name="Command Priority"
          cell={cell}
          row={row}
          rowIndex={rowIndex}
          onDelete={onDeleteCommandPriority}
          onEdit={onEditgetCommandPriority}
        />
      ),
      attrs: { title: 'Action' },
      headerStyle: {
        width: '100px',
      },
      headerAlign: 'center',
      align: 'center',
    },
  ];

  //Command Priority Functions
  const onDeleteCommandPriority = (row) => {
    setDeleteCommandPriority(row.id);
  };
  const onEditgetCommandPriority = (row) => {
    setUpdateDialogData(row);
    setDialogType('Edit');
    setShowCommandPriorityAddEditDialog(true);
  };
  const onAddCommandPriority = () => {
    setDialogType('Add');
    setShowCommandPriorityAddEditDialog(true);
  };

  const OnAddOrEditCommandPriority = (afterSave) => {
    setUpdateDialogData(null);
    setDialogType('New');
    setShowCommandPriorityAddEditDialog(false);
    if (afterSave?.id) {
      getAllCommandPriority();
    }
  };

  const onDeleteConfirmCommandPriority = async () => {
    dispatch(startLoading());
    try {
      await Network.post(API.deleteCommandPriority, {
        id: deleteCommandPriority,
      });
      setDeleteCommandPriority(null);
      getAllCommandPriority();
      toast.success('Command Priority deleted successfully', toastConfig);
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(endLoading());
    }
  };

  const getAllCommandPriority = () => {
    dispatch(startLoading());
    try {
      Network.get(API.fetchAllCommandPriority, {
        limit: perPage,
        page: page,
        workspaceId: selectedWorkspace,
        planEventId: exerciseId,
        sort: orderBy,
      })
        .then((resp) => {
          setCommandPriority(resp.data.response.dataset);
          setTotal(resp.data.response.total);
        })
        .catch(console.log)
        .finally(() => {
          dispatch(endLoading());
        });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (exerciseId) {
      if (workspaceType != undefined && workspaceType === 'DOD') {
        getAllCommandPriority();
        setValue('page', { value: perPage, label: perPage + ' per page' });
        setValue('pageAcc', {
          value: perPageAcc,
          label: perPageAcc + ' per page',
        });
      } else {
        setValue('page', { value: perPage, label: perPage + ' per page' });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, perPage, reduxUser, perPageAcc, orderBy, workspaceType]);

  //Accredited Tasks Functions
  const onDeleteAccreditedTasks = (row) => {
    setDeleteAccreditedTasks(row.id);
  };
  const onEditgetAccreditedTasks = (row) => {
    setUpdateDialogData(row);
    setDialogType('Edit');
    setShowAccreditedTasksAddEditDialog(true);
  };
  const onAddAccreditedTasks = (data) => {
    setDialogType('Add');
    setUpdateDialogData(data);
    setShowAccreditedTasksAddEditDialog(true);
  };

  const OnAddOrEditAccreditedTasks = (afterSave) => {
    setUpdateDialogData(null);
    setDialogType('New');
    setShowAccreditedTasksAddEditDialog(false);
    if (afterSave?.id) {
      if (workspaceType === 'DOD') {
        getAllAccreditedTasks();
      } else {
        getAllCoreCapabilities();
      }
    }
  };
  const onDeleteConfirmAccreditedTasks = async () => {
    dispatch(startLoading());
    try {
      await Network.post(API.deleteAccreditedTask, {
        id: deleteAccreditedTasks,
      });
      setDeleteAccreditedTasks(null);
      getAllAccreditedTasks();
      toast.success('Accredited Task deleted successfully', toastConfig);
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(endLoading());
    }
  };

  const getAllAccreditedTasks = async () => {
    dispatch(startLoading());
    try {
      Network.get(API.fetchAllAccreditedTasks, {
        limit: perPageAcc,
        page: pageAcc,
        workspaceId: selectedWorkspace,
        planEventId: exerciseId,
      })
        .then((resp) => {
          setAccreditedTasks(resp.data.response.dataset);
          setTotalAccreditedTasks(resp.data.response.total);
        })
        .catch(console.log)
        .finally(() => {
          dispatch(endLoading());
        });
    } catch (error) {
      console.log(error);
    }
  };

  const getAllCoreCapabilities = async () => {
    dispatch(startLoading());
    try {
      Network.get(API.fetchAllCoreCapabilities, {
        limit: perPage,
        page: page,
        workspaceId: selectedWorkspace,
        planEventId: exerciseId,
      })
        .then((resp) => {
          const coreData = resp.data.response.dataset.map((ele) => {
            return {
              ...ele,
              title: ele?.title,
              area: ele?.areas.map((element) => element.area).join(', '),
            };
          });
          setPostData(
            coreData.map((cD) => ({
              coreCapabilitiesId: cD.id,
              planEventId: exerciseId,
              workspaceId: selectedWorkspace,
            }))
          );
          setCoreCapabilities(coreData);
          if (resp.data.response.total) {
            setTotal(resp.data.response.total);
          }
        })
        .catch(console.log)
        .finally(() => {
          dispatch(endLoading());
        });
    } catch (error) {
      console.log(error);
    }
  };

  const saveAndExit = () => {
    navigate(`${PreparePath}/dashboard/plan/prepare/exercise-details`);
  };

  useEffect(() => {
    if (exerciseId) {
      if (workspaceType === 'DOD') {
        getAllAccreditedTasks();
      } else {
        getAllCoreCapabilities();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, perPage, coreCapabilitiesData, pageAcc, reduxUser, workspaceType]);

  return (
    <>
      {workspaceType === 'DOD' ? (
        <div>
          <div className="d-flex align-items-center mb-4">
            <h4 className="mb-0">
              {props?.exerciseDetailName?.eventName || workspaceName} Tasks &
              Priorites
            </h4>
            <StylishHelp
              classes={'ms-auto sml flex-shrink-0'}
              title={HelpTasksPriorities.title}
              content={HelpTasksPriorities.content}
              link={selfHostedKnowledgeBase || HelpTasksPriorities.link}
            />
          </div>
          <div className="form-block">
            <StylishNewButton
              className="button button--primary  mb-3 w-100 w-md-auto"
              onClick={() => onAddAccreditedTasks(accreditedTasks)}
            >
              Add Accredited Task
            </StylishNewButton>
            <div className="mb-4">
              <div className="row mb-2">
                <div className="col-md-12">
                  <div className="d-flex align-items-center">
                    <p className="m-0 me-3 weight-600">
                      Accredited Tasks: {totalAccreditedTasks}
                    </p>
                    <div className="ms-auto flex-shrink-0">
                      <Controller
                        control={control}
                        name="pageAcc"
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => (
                          <StylishNewSelect
                            options={[
                              { value: 5, label: '5 per page' },
                              { value: 15, label: '15 per page' },
                              { value: 30, label: '30 per page' },
                              { value: 60, label: '60 per page' },
                              { value: 120, label: '120 per page' },
                            ]}
                            defaultValue={value}
                            value={value || { value: 5, label: '5 per page' }}
                            onChange={(e) => {
                              setPerPageAcc(e.value) && onChange(e);
                              setPageAcc(1);
                            }}
                            placeholder={'Select per page'}
                            isClearable={false}
                            isSearchable={true}
                            isMulti={false}
                            isDisabled={false}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <StylishNewTable
                keyField={'id'}
                columns={columnsForAccreditedTasks}
                rows={accreditedTasks.map((data) => ({
                  id: data.id,
                  taskTitle: data.jmets.taskTitle,
                  levelWar: data.jmets.levelWar,
                  taskNumber: data.jmets.taskNumber,
                  jmetId: data.jmets.id,
                }))}
                pagination
                page={pageAcc}
                perPage={perPageAcc}
                total={totalAccreditedTasks}
                onPageChange={(page) => setPageAcc(page)}
              />
              {showAccreditedTasksAddEditDialog && (
                <AddEditAccreditedTaskDialog
                  show={showAccreditedTasksAddEditDialog}
                  onClose={OnAddOrEditAccreditedTasks}
                  dialogType={dialogType}
                  updateDialogData={updateDialogData}
                />
              )}
              {deleteAccreditedTasks && (
                <StylishConfirmDialog
                  show={deleteAccreditedTasks}
                  onClose={() => setDeleteAccreditedTasks(null)}
                  dialogType={'Delete'}
                  dialogTitle={'Delete Accredited Task'}
                  dialogContent={'Are you sure you want this?'}
                  updateDialogData={deleteAccreditedTasks}
                  onConfirm={onDeleteConfirmAccreditedTasks}
                />
              )}
            </div>
            <div className="m-0">
              <StylishNewButton
                className="button button--primary mb-3 w-100 w-md-auto"
                onClick={onAddCommandPriority}
              >
                Add Command Priority
              </StylishNewButton>
              <div className="row mb-2">
                <div className="col-md-12">
                  <div className="d-flex align-items-center">
                    <p className="m-0 me-3 weight-600">
                      Command Training Priorities: {total}
                    </p>
                    <div className="ms-auto flex-shrink-0">
                      <Controller
                        control={control}
                        name="page"
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => (
                          <StylishNewSelect
                            options={[
                              { value: 5, label: '5 per page' },
                              { value: 15, label: '15 per page' },
                              { value: 30, label: '30 per page' },
                              { value: 60, label: '60 per page' },
                              { value: 120, label: '120 per page' },
                            ]}
                            defaultValue={value}
                            value={value || { value: 5, label: '5 per page' }}
                            onChange={(e) => {
                              setPerPage(e.value) && onChange(e);
                              setPage(1);
                            }}
                            placeholder={'Select per page'}
                            isClearable={false}
                            isSearchable={true}
                            isMulti={false}
                            isDisabled={false}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <StylishNewTable
                keyField={'id'}
                columns={columnsForCommandPriority}
                rows={commandPriority}
                pagination
                page={page}
                perPage={perPage}
                total={total}
                onPageChange={(page) => setPage(page)}
              />
              {showCommandPriorityAddEditDialog && (
                <AddEditCommandPriorityDialog
                  show={showCommandPriorityAddEditDialog}
                  onClose={OnAddOrEditCommandPriority}
                  dialogType={dialogType}
                  updateDialogData={updateDialogData}
                />
              )}
              {deleteCommandPriority && (
                <StylishConfirmDialog
                  show={deleteCommandPriority}
                  onClose={() => setDeleteCommandPriority(null)}
                  dialogType={'Delete'}
                  dialogTitle={'Delete Command Priority'}
                  dialogContent={'Are you sure you want this?'}
                  updateDialogData={deleteCommandPriority}
                  onConfirm={onDeleteConfirmCommandPriority}
                />
              )}
            </div>
          </div>
          <div className="col-md-12 mt-4">
            <div className="button-group d-block d-md-flex">
              <StylishNewButton
                type="submit"
                className="button button--primary button--reverse w-100 w-md-auto"
                onClick={() => props.onPrevClick(3)}
              >
                Previous
              </StylishNewButton>
              <StylishNewButton
                type="button"
                className="button button--tertiary button--reverse ms-auto mt-3 mt-md-0 w-100 w-md-auto"
                onClick={saveAndExit}
              >
                Save &amp; Exit
              </StylishNewButton>
              <StylishNewButton
                type="submit"
                className="button button--primary  w-100 w-md-auto mt-3 mt-md-0 ms-0 ms-md-3"
                onClick={() => props.onNextClick(5)}
              >
                Next
              </StylishNewButton>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div className="form-block">
            <div className="m-0">
              <StylishNewButton
                className="button button--primary mb-3 w-100 w-md-auto"
                onClick={onAddAccreditedTasks}
              >
                Add/Update Capabilities
              </StylishNewButton>
              <div className="row mb-2">
                <div className="col-md-12">
                  <div className="d-flex align-items-center">
                    <p className="m-0 me-3 weight-600">
                      Core Capabilities and Associated Mission Area(s): {total}
                    </p>
                    <div className="ms-auto flex-shrink-0">
                      <Controller
                        control={control}
                        name="page"
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => (
                          <StylishNewSelect
                            options={[
                              { value: 5, label: '5 per page' },
                              { value: 15, label: '15 per page' },
                              { value: 30, label: '30 per page' },
                              { value: 60, label: '60 per page' },
                              { value: 120, label: '120 per page' },
                            ]}
                            defaultValue={value}
                            value={value}
                            onChange={(e) => {
                              setPerPage(e.value) && onChange(e);
                              setPage(1);
                            }}
                            placeholder={'Select per page'}
                            isClearable={false}
                            isSearchable={true}
                            isMulti={false}
                            isDisabled={false}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <StylishNewTable
                keyField={'id'}
                columns={columnsForCoreCapabilities}
                rows={coreCapabilities}
                pagination
                page={page}
                perPage={perPage}
                total={total}
                onPageChange={(page) => setPage(page)}
              />
              {showAccreditedTasksAddEditDialog && (
                <AddEditAccreditedTaskDialog
                  show={showAccreditedTasksAddEditDialog}
                  onClose={OnAddOrEditAccreditedTasks}
                  dialogType={dialogType}
                  updateDialogData={updateDialogData}
                  postData={postData}
                />
              )}
              {deleteAccreditedTasks && (
                <StylishConfirmDialog
                  show={deleteAccreditedTasks}
                  onClose={() => setDeleteAccreditedTasks(null)}
                  dialogType={'Delete'}
                  dialogTitle={'Delete Core Capabilities'}
                  dialogContent={'Are you sure you want this?'}
                  updateDialogData={deleteAccreditedTasks}
                  onConfirm={onDeleteConfirmAccreditedTasks}
                />
              )}
            </div>
          </div>
          <div className="col-md-12 mt-4">
            <div className="button-group d-block d-md-flex">
              <StylishNewButton
                type="submit"
                className="button button--primary button--reverse w-100 w-md-auto"
                onClick={() => props.onPrevClick(3)}
              >
                Previous
              </StylishNewButton>
              <StylishNewButton
                type="button"
                className="button button--tertiary button--reverse ms-auto mt-3 mt-md-0 w-100 w-md-auto"
                onClick={saveAndExit}
              >
                Save &amp; Exit
              </StylishNewButton>
              <StylishNewButton
                type="submit"
                className="button button--primary  w-100 w-md-auto mt-3 mt-md-0 ms-0 ms-md-3"
                onClick={() => props.onNextClick(5)}
              >
                Next
              </StylishNewButton>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
