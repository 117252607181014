import React from 'react';
import { AppHeader } from '../headers/appHeader/AppHeader';
import ReportPageInner from './ReportPageInner';
import { useNewUIFlag } from '../../utils/featureFlags';

export default function ReportPage() {
  const newUI = useNewUIFlag();
  return (
    <div className="site">
      {!newUI ? <AppHeader context="Report" /> : null}
      <div className="site-main">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <ReportPageInner />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
