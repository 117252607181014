import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';

export default function ReviewProfilesDialog({
  onClose,
  show,
  profileSelectCount,
  profileSelect,
  isCloseModals,
  profileSelectToCreatePersonaPage,
}) {
  return (
    <Modal
      show={show}
      onHide={onClose}
      centered
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title>Review Profile(s)</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="bg-gray-700 rounded p-5 text-center">
          <p className="mb-0">You Are Adding {profileSelectCount} Profile(s)</p>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <div className="button-group">
          <StylishNewButton
            className="button--secondary button--reverse"
            onClick={onClose}
          >
            Cancel
          </StylishNewButton>
          <StylishNewButton
            className="button--primary"
            type="submit"
            onClick={() => {
              onClose(true);
              isCloseModals(true);
              profileSelectToCreatePersonaPage(profileSelect);
            }}
          >
            Ok
          </StylishNewButton>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
