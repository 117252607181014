import React, {useState, useEffect} from 'react'
import {useSelector,useDispatch} from 'react-redux'

import generateUUID from 'utils/sharedUtils/generateUUID';


import Box from '@mui/material/Box';
import Dialog, { Header, Body, Footer } from 'components/DesignSystems/dialog/Dialog';
import Title from 'components/DesignSystems/text/Title';
import Info from 'components/DesignSystems/text/Info';
import Button from 'components/DesignSystems/buttons/StylishButton';

import { CloseButton } from 'components/DesignSystems/buttons/CloseButton';

import StylishNewInput from 'components/DesignSystems/New/StylishNewInput';
import StylishNewSelect from 'components/DesignSystems/New/StylishNewSelect';

import "./ManageThresholdRiskLevelsDialog.css"

function Threshold({
  risk_levels,
  t,
  setT,
  removeT,
})
{

  function riskLevelSelected(rl)
  {
    setT({...t,risk_level:rl.value})
  }

  function lbUpdate(val)
  {
    setT({...t,lower_Bound:val})
  }

  function ubUpdate(val)
  {
    setT({...t,upper_Bound:val})
  }

  return (
    <div className="Threshold">
      <div>
        <Info>From:</Info>
        <StylishNewInput
          value={t.lower_bound}
          onChange={e=>lbUpdate(e.target.value)}
        />
      </div>
      <div>
        <Info>To:</Info>
        <StylishNewInput
          value={t.upper_bound}
          onChange={e=>ubUpdate(e.target.value)}
        />
      </div>
      <div>
        <Info>Risk Level:</Info>
        <StylishNewSelect
          value={!!t.risk_level && {label:t.risk_level,value:t.risk_level}}
          options={risk_levels.map(rl=>{return{label:rl,value:rl}})}
          onChange={(selection)=>riskLevelSelected(selection)}
        />
      </div>
      <button onClick={()=>removeT()}>Remove</button>
    </div>
  )
}

// Guide the user through the necessary minimum steps required to set up Alerts
export default function ManageThresholdRiskLevelsDialog({
	onClose,
  hazard,
  hvaData,
  setHVAData
})
{
  const reduxDispatch = useDispatch()

  const reduxCurrentlySelectedGroup = useSelector((state) => {
    return state.app.currentlySelectedGroup;
  });

  

  const [thresholds,setThresholds] = useState(hazard.cell_data[hazard.cell_data.length-1].level_range||[])

  function doneButtonClicked()
  {
    setHVAData(hvaData.map(d=>{
      if(d.id===hazard.id)
      {
        return {
          ...d,
          cell_data: d.cell_data.map(cd=>{
            if(cd.name==="Risk Level")
            {
              return {
                ...cd,
                level_range: thresholds
              }
            }
            else
            {
              return cd
            }
          })
        }
      }
      else
      {
        return d
      }
    }))
    onClose()
  }

  function setT(newT)
  {
    setThresholds(thresholds.map(t=>{
      return (t.id===newT.id && newT) || t
    }))
  }

  function addThreshold(minOrMax)
  {
    let newThreshold = {
      id: generateUUID(),
      lower_bound:0,
      upper_bound:10,
      risk_level: null
    }
    if(minOrMax==="min")
    {

      setThresholds([newThreshold,...thresholds])
    }
    else
    {
      setThresholds([...thresholds,newThreshold])
    }
  }

	return (
    <Dialog onClose={onClose} className="HVAModal">
      <Header>
          <Title>Risk Levels ({hazard.name})</Title>
          <CloseButton onClick={onClose} />
      </Header>
      <Body className="HVAModal-Body">

        <div className="Thresholds-Wrap">
          <Info>Min: {hazard.bounds[0]}</Info>
          {hazard.bounds==="infinity" && (
            <div>
              <button onClick={()=>addThreshold("min")}>Add Negative Threshold</button>
            </div>
          )}
          <div className="Thresholds">
            {thresholds.map(t=>{
              return(
                <>
                <hr />
              <Threshold
                t={t}
                setT={setT}
                key={"Threshold-"+t.id}
                risk_levels={reduxCurrentlySelectedGroup.risk_levels}
                removeT={()=>setThresholds(thresholds.filter(th=>th.id!==t.id))}
              />
              <hr/>
              </>)
            })}
          </div>
          <div>
            <button onClick={()=>addThreshold("max")}>Add Positive Threshold</button>
          </div>
          <Info>Max: {hazard.bounds[1]}</Info>
        </div>

      </Body>  
      <Footer className="AlertSetupModal-Footer"> 
          <Button className="m-0 AlertSetupModalBackButton" onClick={()=>doneButtonClicked()}>Done</Button>
      </Footer>
    </Dialog>
	)
}