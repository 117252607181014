import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import IconFile from 'images/icons/icon__file.svg';
import IconUpload from 'images/icons/icon__upload.svg';
import IconClose from 'images/icons/icon__times.svg';
import StylishNewInput from 'components/DesignSystems/New/StylishNewInput';
import StylishNewTextArea from 'components/DesignSystems/New/StylishNewTextArea';
import { BPIncidentTypes } from 'components/IncidentConfig/IncidentTypes';
import {
  CallTypes,
  TeamsActivated as ConstantTeamsActivated,
} from './ReportConstant';
import { fetchRostersForGroups } from 'actions/roleActions';
import { Controller, useForm } from 'react-hook-form';
import apiConfig from 'constants/apiConfig';
import axios from 'axios';
import generateUUID from 'utils/sharedUtils/generateUUID';
import {
  getAllQuestionByAor,
  getChecklistFormAssetAndIncidentType,
  getTeamChecklistFormAssetAndIncidentType,
  upsertInitialIncidentReport,
} from 'actions/ReportActions';
import { fetchAllDistributionList } from 'actions/notificationActions';

import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import StylishNewSelect from 'components/DesignSystems/New/StylishNewSelect';
import { StylishNewCheckbox } from 'components/DesignSystems/New/StylishNewCheckbox';

import { getAllAOR, getAllLocationEntity } from 'actions/locationAction';

import StylishDateTimePicker from 'components/DesignSystems/form/StylishDateTimePicker';
import { StylishInputPhone } from 'components/DesignSystems/New/StylishInputPhone';
import CoreComponentCreationDialog from 'components/AI2FP/CoreComponentCreationDialog';
import { sanitizeHtml } from '../../utils/sanitizeHtml';

import PlanAI from 'components/DisasterGPT/PlanAI';
import PageTitle from 'components/headers/PageTitle';
import { useNewUIFlag } from 'utils/featureFlags';
import ReactMarkdown from 'react-markdown';
import { dgptSitrepToInitialIncidentReportSummary } from '../../actions/dgptActions';


export default function CreateEditInitialIncidentReport({
  selectedReport,
  onChangeTab,
}) {
  const navigate = useNavigate();
  const reduxDispatch = useDispatch();

  const locationEntity = useSelector((state) => {
    return state.app.locationEntity;
  });

  const locationSubEntity = useSelector((state) => {
    return state.app.locationSubEntity;
  });
  const locationEnterprise = useSelector((state) => {
    return state.app.locationEnterprise;
  });
  const locationAsset = useSelector((state) => {
    return state.app.locationAsset;
  });
  const distributionLists = useSelector((state) => {
    return state.app.distributionListItems;
  });
  const reduxFeatureFlags = useSelector((state) => {
    return state.app.featureFlags;
  });

  const dgptSitrepToInitialIncidentReport = useSelector((state) => {
    return state.app.dgptSitrepToInitialIncidentReport;
  });
  const [isAutoSitrepOpen, setIsAutoSitrepOpen] = useState(false);
  const clearAutoSitrep = () => {
    reduxDispatch(dgptSitrepToInitialIncidentReportSummary(undefined));
  };

  const allAor = useSelector((state) => {
    const allAors = state.app.allAor.map((aor) => ({
      label: aor.name,
      value: aor.id,
    }));
    return [...allAors, { label: 'Add New', value: 'Add New' }];
  });
  const [TeamsActivated, setTeamsActivated] = useState([]);
  useEffect(() => {
    if (!!distributionLists && Array.isArray(distributionLists)) {
      setTeamsActivated(
        distributionLists.map((list) => {
          return { value: list.name, label: list.name };
        })
      );
    } else {
      setTeamsActivated(ConstantTeamsActivated);
    }
  }, [distributionLists]);

  const reduxRostersForGroups = useSelector((state) => {
    return state.app.rostersForGroups || [];
  });

  const reduxCurrentlySelectedGroup = useSelector((state) => {
    return state.app.currentlySelectedGroup;
  });

  useEffect(() => {
    if (reduxCurrentlySelectedGroup) {
      reduxDispatch(
        fetchAllDistributionList(reduxCurrentlySelectedGroup.group_guid)
      );
      reduxDispatch(getAllLocationEntity());
      reduxDispatch(getAllAOR());
    }
  }, [reduxCurrentlySelectedGroup]);

  const isAllInitialIncidentReportLoaded = useSelector((state) => {
    return state.app.isAllInitialIncidentReportLoaded;
  });
  const isAddInitialIncidentReportLoaded = useSelector((state) => {
    return state.app.isAddInitialIncidentReportLoaded;
  });
  const hasInitialIncidentReportError = useSelector((state) => {
    return state.app.hasInitialIncidentReportError;
  });
  const reduxRostersForTheGroup = useSelector((state) => {
    let rosters = state.app.rostersForGroups;
    if (Array.isArray(rosters) && rosters.length) {
      rosters = rosters
        .filter((member) => {
          return member.group_guid === reduxCurrentlySelectedGroup.group_guid;
        })
        .map((member) => {
          return {
            ...member,
            label:
              member.user_name ||
              member.profile_settings.userName ||
              member.email_address,
            value: member.user_guid,
          };
        });
    }
    return rosters || [];
  });

  const reduxNestedCategoryItems = useSelector((state) => {
    return state.app.nestedCategoryItems;
  });

  
  useEffect(() => {
    if (!reduxRostersForGroups.length) {
      reduxDispatch(
        fetchRostersForGroups([reduxCurrentlySelectedGroup.group_guid])
      );
    }
  }, [reduxCurrentlySelectedGroup]);

  const [incidentAfterActionReport, setIncidentAfterActionReport] = useState(
    []
  );
  const [exerciseAfterActionReport, setExerciseAfterActionReport] = useState(
    []
  );
  const [type, setType] = useState('New');
  const [locationEntityOptions, setLocationEntityOptions] = useState([]);
  const [locationSubEntityOptions, setLocationSubEntityOptions] = useState([]);
  const [locationEnterpriseOptions, setLocationEnterpriseOptions] = useState(
    []
  );
  const [locationAssetOptions, setLocationAssetOptions] = useState([]);
  const [isLoaded, setIsLoaded] = useState(true);
  const [checklistData, setChecklistData] = useState();
  const [teamChecklistData, setTeamChecklistData] = useState();
  const [showError, setShowError] = useState(false);
  const [phnError, setPhnError] = useState('');
  const options = [
    { value: true, label: 'Yes' },
    { value: false, label: 'No' },
  ];
  const [aorQuestion, setAorQuestion] = useState([]);
  const [selectedTeamLocation, setSelectedTeamLocation] = useState(null);
  const [showAddNewAorDialog, setShowAddNewAorDialog] = useState(false);
  const [dgptUsed, setDgptUsed] = useState(false);
  const [planAIInit, setPlanAIInit] = useState(false);
  const [formReady, setFormReady] = useState(false);

  const [incidentDescription, setIncidentDescription] = useState(dgptSitrepToInitialIncidentReport?.sitrepMarkdown||'')

  const {
    handleSubmit,
    control,
    setValue,
    reset,
    getValues,
    watch,
    clearErrors,
    setError,
    formState: { errors, isValid, isSubmitted },
  } = useForm({ mode: 'onChange' });

  useEffect(() => {
  if (selectedReport && selectedReport.incident_description) {
    setValue('incidentDescription', selectedReport.incident_description);
  } else if (dgptSitrepToInitialIncidentReport && dgptSitrepToInitialIncidentReport?.sitrepMarkdown) {
    setValue('incidentDescription', dgptSitrepToInitialIncidentReport?.sitrepMarkdown);
  }
}, [selectedReport, dgptSitrepToInitialIncidentReport, setValue,handleSubmit,
    control,
    setValue,
    reset,
    getValues,
    watch,
    clearErrors,
    setError, errors, isValid, isSubmitted ]);


  const newUI = useNewUIFlag();

  const watchCurrentStatus = watch('current_status', 'In Review');

  const onSubmit = async (data) => {
    if (showError) {
      return;
    }
    let iir = {
      incident_name: data.incidentName,
      group_guid: reduxCurrentlySelectedGroup.group_guid,
      start_date: data.startDate,
      location_id: selectedTeamLocation?.location_id,
      is_in_system: data.in_system.value,
      calling_persion:
        data.calling_person && data.calling_person.value !== ''
          ? data.calling_person.value
          : null,
      calling_persion_name: data.calling_persion_name,
      calling_persion_number: data.call_number,
      call_type: data.callType.value,
      is_qi: data?.isQI?.value,
      incident_description: incidentDescription||data.incidentDescription,
      is_facility_muster: data.isFacilityMuster.value,
      is_facility_report_to_abandon_stations:
        data.isFacilityReportToAbandonStations.value,
      teams_activated_list: data.teamsActivated.map((e) => e.value),
      incident_types: data.incidentType.map((e) => e.value),
      site_checklist: data.siteChecklist,
      imt_checklist: data.imtChecklist,
      resources_activated: data.resourcesActivated,
      incident_after_action_report: incidentAfterActionReport,
      exercise_after_action_report: exerciseAfterActionReport,
      current_status: data.current_status ? data.current_status : 'In Review',
      notify_duty_crisis_advisor: data.notify_duty_crisis_advisor,
      incident_id:
        selectedReport && selectedReport.incident_id
          ? selectedReport.incident_id
          : null,
      checklist_id:
        checklistData && checklistData.length > 0
          ? checklistData.map((site) => site.id)
          : [],
      team_checklist_id:
        teamChecklistData && teamChecklistData.length > 0
          ? teamChecklistData.map((team) => team.id)
          : [],
      alreadyEscalated:
        selectedReport &&
        (selectedReport.current_status.includes('Escalated') ||
          selectedReport.current_status.includes('Forms Generated')),
      aor_id: data?.aor?.value,
      answer: data?.answer,
    };
    if (type === 'New') {
      iir = {
        ...iir,
        id: generateUUID(),
        type: 'Create',
      };
    } else {
      iir = {
        ...iir,
        id: selectedReport.id,
        type: 'Update',
      };
    }
    setIsLoaded(false);
    await reduxDispatch(upsertInitialIncidentReport(iir));
    navigate('/report', { replace: true });
  };
  const uploadFile = async (file, type) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('group_guid', reduxCurrentlySelectedGroup.group_guid);
    try {
      const resp = await axios.post(apiConfig.apiGateway.uploadFile, formData);
      if (type === 'IncidentAfterActionReport') {
        setIncidentAfterActionReport([
          ...incidentAfterActionReport,
          resp.data.fileUrl,
        ]);
      } else {
        setExerciseAfterActionReport([
          ...exerciseAfterActionReport,
          resp.data.fileUrl,
        ]);
      }
    } catch (error) {
      //Error File Upload
    }
  };
  const deleteFile = async (index, type, url) => {
    if (type === 'IncidentAfterActionReport') {
      const temp = [...incidentAfterActionReport];
      temp.splice(index, 1);
      setIncidentAfterActionReport(temp);
    } else {
      const temp = [...exerciseAfterActionReport];
      temp.splice(index, 1);
      setExerciseAfterActionReport(temp);
    }
  };
  useEffect(() => {
    if (
      !!distributionLists &&
      distributionLists.length > 0 &&
      selectedReport &&
      selectedReport?.teams_activated_list?.length > 0
    ) {
      let selectedTeams = [];
      selectedTeams = [
        ...selectedTeams,
        ...distributionLists
          .filter((data) =>
            selectedReport.teams_activated_list.find(
              (ta) => ta.trim() === data.name
            )
          )
          .map((data) => {
            return {
              label: data.name,
              value: data.name,
            };
          }),
      ];
      selectedTeams = [
        ...selectedTeams,
        ...ConstantTeamsActivated.filter((data) =>
          selectedReport.teams_activated_list.find(
            (ta) => ta.trim() === data.value
          )
        ),
      ];
      setValue('teamsActivated', selectedTeams);
    }
  }, [distributionLists]);
  useEffect(() => {
    if (selectedReport) {
      if (selectedReport.calling_persion) {
        const selectedPerson =
          selectedReport.calling_persion &&
          reduxRostersForTheGroup.find(
            (data) => data.value === selectedReport.calling_persion
          );
        if (selectedPerson) {
          setValue('calling_person', {
            label:
              selectedPerson.user_name ||
              selectedPerson.profile_settings.userName ||
              selectedPerson.email_address,
            value: selectedPerson.user_guid,
          });
        }
      }
      setType('Edit');
      setValue('incidentName', selectedReport.incident_name);
      setValue(
        'startDate',
        selectedReport.start_date ? moment(selectedReport.start_date) : moment()
      );
      if (!!selectedReport.location_id) {
        setSelectedTeamLocation({ location_id: selectedReport.location_id });
      }

      setValue(
        'in_system',
        options.find((data) => data.value === selectedReport.is_in_system)
      );

      setValue('calling_persion_name', selectedReport.calling_persion_name);
      setValue('call_number', selectedReport.calling_persion_number);
      if (selectedReport.is_qi) {
        setValue(
          'isQI',
          options.find((data) => data.value === selectedReport.is_qi)
        );
      } else {
        setValue('isQI', options[1]);
      }

      setValue(
        'callType',
        CallTypes.find((data) => data.value === selectedReport.call_type)
      );

      setValue('incidentDescription', selectedReport.incident_description);
      if (selectedReport.is_facility_muster) {
        setValue(
          'isFacilityMuster',
          options.find(
            (data) => data.value === selectedReport.is_facility_muster
          )
        );
      } else {
        setValue('isFacilityMuster', options[1]);
      }
      if (selectedReport.is_facility_report_to_abandon_stations) {
        setValue(
          'isFacilityReportToAbandonStations',
          options.find(
            (data) =>
              data.value ===
              selectedReport.is_facility_report_to_abandon_stations
          )
        );
      } else {
        setValue('isFacilityReportToAbandonStations', options[1]);
      }
      if (
        selectedReport.teams_activated_list &&
        selectedReport.teams_activated_list.length > 0
      ) {
        setValue(
          'teamsActivated',
          ConstantTeamsActivated.filter((data) =>
            selectedReport.teams_activated_list.find(
              (ta) => ta.trim() === data.value
            )
          )
        );
      }
      if (
        selectedReport.incident_types &&
        selectedReport.incident_types.length > 0
      ) {
        setValue(
          'incidentType',
          BPIncidentTypes.filter((data) =>
            selectedReport.incident_types.find((it) => it.trim() === data.value)
          )
        );
      }
      setValue('siteChecklist', selectedReport.site_checklist);
      setValue('imtChecklist', selectedReport.imt_checklist);
      setValue('resourcesActivated', selectedReport.resources_activated);
      setValue('current_status', selectedReport.current_status);
      setValue(
        'notify_duty_crisis_advisor',
        selectedReport.notify_duty_crisis_advisor
      );
      setExerciseAfterActionReport(selectedReport.exercise_after_action_report);
      setIncidentAfterActionReport(selectedReport.incident_after_action_report);
      if (selectedReport.aor_id) {
        setValue('aor', {
          label: selectedReport.aor_name,
          value: selectedReport.aor_id,
        });
        getAllQuestionByAorId(selectedReport.aor_id);
      }
      if (selectedReport.checklist_data) {
        setChecklistData(selectedReport.checklist_data);
      }
      if (selectedReport.team_checklist_data) {
        setTeamChecklistData(selectedReport.team_checklist_data);
      }
    } else {
      reset();
      setValue('startDate', moment().toDate());
    }
  }, [selectedReport]);

  useEffect(() => {
    if (!!locationEntity && locationEntity.length > 0) {
      setLocationEntityOptions(
        locationEntity.map((data) => {
          return {
            label: data.location_entity,
            value: data.location_entity,
          };
        })
      );
    } else {
      setLocationAssetOptions([]);
    }
    if (!!locationSubEntity && locationSubEntity.length > 0) {
      setLocationSubEntityOptions(
        locationSubEntity.map((data) => {
          return {
            label: data.location_sub_entity,
            value: data.location_sub_entity,
          };
        })
      );
    } else {
      setLocationSubEntityOptions([]);
    }
    if (!!locationEnterprise && locationEnterprise.length > 0) {
      setLocationEnterpriseOptions(
        locationEnterprise.map((data) => {
          return {
            label: data.location_enterprise,
            value: data.location_enterprise,
          };
        })
      );
    } else {
      setLocationEnterpriseOptions([]);
    }
    if (!!locationAsset && locationAsset.length > 0) {
      setLocationAssetOptions(
        locationAsset.map((data) => {
          return {
            label: data.location_asset,
            value: data.location_asset,
          };
        })
      );
    } else {
      setLocationAssetOptions([]);
    }
  }, [locationEntity, locationSubEntity, locationEnterprise, locationAsset]);
  useEffect(() => {
    if (
      !isLoaded &&
      isAllInitialIncidentReportLoaded &&
      hasInitialIncidentReportError
    ) {
      setIsLoaded(true);
    }
  }, [
    isAllInitialIncidentReportLoaded,
    isAddInitialIncidentReportLoaded,
    isLoaded,
    onChangeTab,
    hasInitialIncidentReportError,
  ]);

  async function resolveChecklistData() {
    // const { location_id } = selectedTeamLocation || {};

    // const location_name = reduxNestedCategoryItems.find(
    //   (catItem) => catItem.id === location_id
    // )?.category_item_name;

    const incident_type = getValues('incidentType')?.map((it) => it.value);
    const teams_activated = getValues('teamsActivated')?.map((ta) => ta.value);
    const location_name = getValues('aor')?.label;

    if (
      location_name &&
      location_name !== '' &&
      incident_type &&
      incident_type.length > 0
    ) {
      const filteredChecklist = await reduxDispatch(
        getChecklistFormAssetAndIncidentType(location_name, incident_type)
      );

      if (filteredChecklist) {
        setChecklistData(filteredChecklist);
      } else {
        setChecklistData(null);
      }
    }
    if (
      location_name &&
      location_name !== '' &&
      incident_type &&
      incident_type.length > 0 &&
      teams_activated &&
      teams_activated.length > 0
    ) {
      const filteredTeamChecklist = await reduxDispatch(
        getTeamChecklistFormAssetAndIncidentType(
          location_name,
          incident_type,
          teams_activated
        )
      );
      if (filteredTeamChecklist) {
        setTeamChecklistData(filteredTeamChecklist);
      } else {
        setTeamChecklistData(null);
      }
    }
  }

  const onChangeValue = async (value, onChange) => {
    onChange(value);
    resolveChecklistData();
  };

  useEffect(() => {
    resolveChecklistData();
  }, [selectedTeamLocation]);
  const getAllQuestionByAorId = async (aor_id) => {
    const aorQuestion = await reduxDispatch(getAllQuestionByAor(aor_id));
    let aorQuestionWithAnswer = [];

    if (
      !!selectedReport &&
      selectedReport.aor_id &&
      selectedReport.aor_id !== aor_id
    ) {
      aorQuestionWithAnswer = aorQuestion;
    }

    if (
      !!selectedReport &&
      !!selectedReport.answer &&
      selectedReport.answer.length > 0 &&
      aorQuestion.length > 0 &&
      selectedReport.aor_id === aor_id
    ) {
      aorQuestion.forEach((q) => {
        const answer = selectedReport.answer.find((ans) => q.id === ans.id);
        aorQuestionWithAnswer.push({
          id: q.id,
          question: q.question,
          options: q.options.map((op) => {
            return {
              ...op,
              id: q.id,
            };
          }),
          answer: answer
            ? { label: answer.answer, value: answer.answer, id: q.id }
            : undefined,
        });
      });
    } else {
      aorQuestionWithAnswer = aorQuestion;
    }
    if (aorQuestionWithAnswer.length > 0) {
      setAorQuestion(
        aorQuestionWithAnswer.map((q, index) => {
          if (q.answer) {
            setValue(`answer.${index}`, { ...q.answer, id: q.id });
          }
          if (q.answer === undefined) {
            setValue(`answer.${index}`, null);
          }
          return {
            ...q,
            options: q.options.map((e) => {
              return {
                ...e,
                id: q.id,
              };
            }),
          };
        })
      );
    } else {
      setAorQuestion([]);
    }
  };

  // Empty useEffect to auto-fill data for sitreps
  useEffect(() => {
    if (!!planAIInit) {
      setFormReady(true);
    }
  }, [planAIInit]);

  useEffect(() => {});

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="pb-5">
        {newUI && <PageTitle title="Initial Incident Report Form" />}
        <StylishNewButton
          className="button--secondary mb-4 w-100 w-md-auto"
          onClick={() => onChangeTab(undefined, 'View')}
        >
          View All Initial Incident Reports
        </StylishNewButton>
        <div className="form-block">
          <div className="row">
            <div className="col-md-6 mb-3">
              <label className="form-label">
                Incident Name
                <span aria-label="Required field" className="required">
                  *
                </span>
              </label>
              <Controller
                control={control}
                name="incidentName"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <StylishNewInput
                    type="text"
                    onChange={(e) => onChange(e.target.value)}
                    value={value}
                  />
                )}
              />
              {errors.incidentName && (
                <span className="form-text form-error">
                  This field is required
                </span>
              )}
            </div>
            <div className="col-md-6 mb-3">
              <Controller
                control={control}
                name="startDate"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <StylishDateTimePicker
                    dateLabel={'Start Date'}
                    onChange={onChange}
                    value={value}
                    isClearable={true}
                    placeholderText={'Choose Start Date'}
                  />
                )}
              />
              {errors.startDate && (
                <span className="form-text form-error">
                  This field is required
                </span>
              )}
            </div>
            <div className="col-md-6 mb-3">
              <label className="form-label">
                Type of Call
                <span aria-label="Required field" className="required">
                  *
                </span>
              </label>
              <Controller
                control={control}
                name="callType"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <StylishNewSelect
                    options={CallTypes}
                    defaultValue={value}
                    value={value}
                    onChange={(e) => {
                      if (e?.value === 'Drill') {
                        setValue('notify_duty_crisis_advisor', false);
                      } else if (e?.value === 'Incident') {
                        setValue('notify_duty_crisis_advisor', true);
                      }
                      onChange(e);
                    }}
                    placeholder={'Select Type of Call'}
                    isClearable={true}
                    isSearchable={true}
                    isMulti={false}
                    isDisabled={false}
                  />
                )}
              />
              {errors.callType && (
                <span className="form-text form-error">
                  This field is required
                </span>
              )}
            </div>
            <div className="col-md-6 mb-3">
              <label className="form-label">
                AOR ( Area of Responsibility)
                <span aria-label="Required field" className="required">
                  *
                </span>
              </label>
              <Controller
                control={control}
                name="aor"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <StylishNewSelect
                    options={allAor}
                    defaultValue={value}
                    value={value}
                    onChange={(e) => {
                      if (e) {
                        if (e.value === 'Add New') {
                          setShowAddNewAorDialog(true);
                          return;
                        }
                        getAllQuestionByAorId(e.value);
                      } else {
                        setAorQuestion([]);
                      }
                      onChangeValue(e, onChange);
                    }}
                    placeholder={'Select AOR ( Area of Responsibility)'}
                    isClearable={true}
                    isSearchable={true}
                    isMulti={false}
                    isDisabled={false}
                  />
                )}
              />
              {errors.aor && (
                <span className="form-text form-error">
                  This field is required
                </span>
              )}
            </div>

            <div className="col-md-12">
              <hr className="dashed" />
            </div>
            <div className="col-md-6 mb-3">
              <label className="form-label">Person Calling Is In System?</label>
              <Controller
                control={control}
                name="in_system"
                defaultValue={{ value: false, label: 'No' }}
                render={({ field: { onChange, value } }) => (
                  <StylishNewSelect
                    options={options}
                    defaultValue={value}
                    value={value}
                    onChange={(e) => {
                      reset({
                        ...getValues(),
                        calling_person: '',
                        calling_persion_name: '',
                        call_number: '',
                        in_system: e,
                      });
                      onChange(e);
                    }}
                    isClearable={false}
                    isSearchable={true}
                    isMulti={false}
                    isDisabled={false}
                  />
                )}
              />
            </div>
            {!watch('in_system')?.value && (
              <div className="col-md-6 mb-3">
                <label className="form-label">
                  Name of Person Calling
                  <span aria-label="Required field" className="required">
                    *
                  </span>
                </label>
                <Controller
                  control={control}
                  name="calling_persion_name"
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <StylishNewInput
                      type="text"
                      value={value}
                      onChange={(e) => onChange(e.target.value)}
                    />
                  )}
                />
                {errors.calling_persion_name && (
                  <span className="form-text form-error">
                    This field is required
                  </span>
                )}
              </div>
            )}
            {watch('in_system')?.value && (
              <div className="col-md-6 mb-3">
                <label className="form-label">
                  Select User
                  <span aria-label="Required field" className="required">
                    *
                  </span>
                </label>
                <Controller
                  control={control}
                  name="calling_person"
                  render={({ field: { onChange, value } }) => (
                    <StylishNewSelect
                      options={reduxRostersForTheGroup}
                      defaultValue={value}
                      value={value}
                      onChange={(e) => {
                        setValue(
                          'call_number',
                          reduxRostersForTheGroup.find(
                            (item) => item.value === e.value
                          ).profile_settings.mobile_phone_number || '',
                          { shouldValidate: true }
                        );
                        onChange(e);
                      }}
                      placeholder={'Select User'}
                      isClearable={true}
                      isSearchable={true}
                      isMulti={false}
                      isDisabled={false}
                    />
                  )}
                />
                {errors.calling_person && (
                  <span className="form-text form-error">
                    This field is required
                  </span>
                )}
              </div>
            )}
            <div className="col-md-6 mb-3">
              <label className="form-label">
                Call Back Number
                <span aria-label="Required field" className="required">
                  *
                </span>
              </label>
              <Controller
                control={control}
                name="call_number"
                rules={{ required: !watch('in_system')?.value }}
                render={({ field: { onChange, value } }) => (
                  // <StylishNewInput
                  //     type="text"
                  //     value={value}
                  //     disabled={watch('in_system')?.value}
                  //     onChange={(e) => onChange(e.target.value)}
                  // />
                  <StylishInputPhone
                    defaultCountry="US"
                    value={value}
                    onChange={onChange}
                    isValid={(isValid) => {
                      if (isValid) {
                        clearErrors('call_number');
                        setPhnError('');
                        setShowError(false);
                      } else {
                        setError('call_number', {
                          type: 'custom',
                          // message: 'Please input a valid phone number',
                        });
                        setPhnError('Please input a valid phone number');
                        setShowError(true);
                      }
                    }}
                  />
                )}
              />
              {errors.call_number?.type === 'required' && (
                <span className="form-text form-error">
                  This field is required
                </span>
              )}
              {!!showError && (
                <span className="form-text form-error">{phnError}</span>
              )}
            </div>
            {/* <div className="col-md-6 mb-3">
            <label className="form-label">Type of Call</label>
            <Controller
              control={control}
              name="callType"
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <StylishNewSelect
                  options={CallTypes}
                  defaultValue={value}
                  value={value}
                  onChange={onChange}
                  placeholder={'Select Type of Call'}
                  isClearable={true}
                  isSearchable={true}
                  isMulti={false}
                  isDisabled={false}
                />
              )}
            />
            {errors.callType && (
              <span className="form-text form-error">
                This field is required
              </span>
            )}
          </div> */}
            {watch('callType')?.value === 'Incident' && (
              <div className="col-md-12 my-3">
                <Controller
                  control={control}
                  name="notify_duty_crisis_advisor"
                  render={({ field: { onChange, value } }) => (
                    <StylishNewCheckbox
                      checked={value}
                      onClick={() =>
                        watch('notify_duty_crisis_advisor') === true
                          ? onChange(false)
                          : onChange(true)
                      }
                      key={'Notify On Duty Crisis Advisor'}
                      label={'Notify On Duty Crisis Advisor'}
                    />
                  )}
                />
              </div>
            )}
            {!!aorQuestion ? (
              aorQuestion.map((q, index) => {
                return (
                  <div className="col-md-6 mb-3">
                    <label className="form-label">{q.question}</label>
                    <Controller
                      control={control}
                      name={`answer.${index}`}
                      rules={{ required: true }}
                      defaultValue={q.answer}
                      render={({ field: { onChange, value } }) => (
                        <StylishNewSelect
                          options={q.options}
                          defaultValue={value}
                          value={value}
                          onChange={onChange}
                          placeholder={'Select...'}
                          isClearable={true}
                          isSearchable={true}
                          isMulti={false}
                          isDisabled={false}
                        />
                      )}
                    />
                    {errors?.answer?.[index] && (
                      <span className="form-text form-error">
                        This field is required
                      </span>
                    )}
                  </div>
                );
              })
            ) : (
              <div className="col-md-6 mb-3">
                <label className="form-label">
                  Is this a QI (Qualified Individual) Regulatory Drill?
                </label>
                <Controller
                  control={control}
                  name="isQI"
                  defaultValue={{ value: false, label: 'No' }}
                  render={({ field: { onChange, value } }) => (
                    <StylishNewSelect
                      options={options}
                      defaultValue={value}
                      value={value}
                      onChange={onChange}
                      isClearable={true}
                      isSearchable={true}
                      isMulti={false}
                      isDisabled={false}
                    />
                  )}
                />
              </div>
            )}

            <div className="col-md-12 mb-3">
              <label className="form-label">
                Brief Synopsis of Incident
                <span aria-label="Required field" className="required">
                  *
                </span>
              </label>
              <Controller
                control={control}
                name="incidentDescription"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <StylishNewTextArea
                    value={incidentDescription||value}
                    onChange={(e) => {onChange(e.target.value);setIncidentDescription(e.target.value)}}
                  />
                )}
              />
              {!!reduxFeatureFlags.includes('PLANAI') && (
                <div>
                  <span className="form-text planai-required">
                    *Required for PlanAI
                  </span>
                </div>
              )}
              {errors.incidentDescription?.type === 'required' && (
                <span className="form-text form-error">
                  *This field is required
                </span>
              )}
            </div>
            <div className="col-md-6 mb-3">
              <label className="form-label">Did the Facility Muster?</label>
              <Controller
                control={control}
                name="isFacilityMuster"
                defaultValue={{ value: false, label: 'No' }}
                render={({ field: { onChange, value } }) => (
                  <StylishNewSelect
                    options={options}
                    defaultValue={value}
                    value={value}
                    onChange={onChange}
                    isClearable={true}
                    isSearchable={true}
                    isMulti={false}
                    isDisabled={false}
                  />
                )}
              />
            </div>
            <div className="col-md-6 mb-3">
              <label className="form-label">
                Did the Facility report to Abandon Stations?
              </label>
              <Controller
                control={control}
                name="isFacilityReportToAbandonStations"
                defaultValue={{ value: false, label: 'No' }}
                render={({ field: { onChange, value } }) => (
                  <StylishNewSelect
                    options={options}
                    defaultValue={value}
                    value={value}
                    onChange={onChange}
                    isClearable={true}
                    isSearchable={true}
                    isMulti={false}
                    isDisabled={false}
                  />
                )}
              />
            </div>
            <div className="col-md-6 mb-3">
              <label className="form-label">
                Incident Type
                <span aria-label="Required field" className="required">
                  *
                </span>
              </label>
              <Controller
                control={control}
                name="incidentType"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <StylishNewSelect
                    options={BPIncidentTypes}
                    defaultValue={value}
                    value={value}
                    onChange={(e) => onChangeValue(e, onChange)}
                    placeholder={'Select Incident Type'}
                    isClearable={true}
                    isSearchable={true}
                    isMulti={true}
                    isDisabled={false}
                  />
                )}
              />
              {!!reduxFeatureFlags.includes('PLANAI') && (
                <div>
                  <span className="form-text planai-required">
                    *Required for PlanAI
                  </span>
                </div>
              )}
              {errors.incidentType && (
                <span className="form-text form-error">
                  This field is required
                </span>
              )}
            </div>
            <div className="col-md-6 mb-3">
              <label className="form-label">
                Relevant Teams Identified
                <span aria-label="Required field" className="required">
                  *
                </span>
              </label>
              <Controller
                control={control}
                name="teamsActivated"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <StylishNewSelect
                    options={TeamsActivated}
                    defaultValue={value}
                    value={value}
                    onChange={(e) => onChangeValue(e, onChange)}
                    placeholder={'Select Relevant Teams Identified'}
                    isClearable={true}
                    isSearchable={true}
                    isMulti={true}
                    isDisabled={false}
                  />
                )}
              />
              {errors.teamsActivated && (
                <span className="form-text form-error">
                  This field is required
                </span>
              )}
            </div>

            <div className="col-md-12">
              <label className="form-label">
                Resources Activated
                <span aria-label="Required field" className="required">
                  *
                </span>
              </label>
              <Controller
                control={control}
                name="resourcesActivated"
                rules={{ maxLength: 1000, required: true }}
                render={({ field: { onChange, value } }) => (
                  <StylishNewTextArea
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                  />
                )}
              />
              {errors.resourcesActivated?.type === 'required' && (
                <span className="form-text form-error">
                  This field is required
                </span>
              )}
              {errors.resourcesActivated?.type === 'maxLength' && (
                <span className="form-text form-error">
                  *Maximum 1000 character allowed
                </span>
              )}
            </div>
            {checklistData &&
              checklistData.length > 0 &&
              checklistData.map((site, index) => {
                const clean = sanitizeHtml(site.site_checklist);
                return (
                  <div className="col-md-12 mb-3" key={site.id}>
                    <label className="form-label">
                      Site Checklist {index + 1}
                    </label>
                    <div className="bg-gray-700 rounded p-4">
                      <div
                        className="dangerously-loaded-inner-html"
                        dangerouslySetInnerHTML={{
                          __html: clean,
                        }}
                      ></div>
                    </div>
                  </div>
                );
              })}
            {teamChecklistData &&
              teamChecklistData.length > 0 &&
              teamChecklistData.map((team, index) => {
                const clean = sanitizeHtml(team.team_checklist);
                return (
                  <div className="col-md-12 mb-3" key={team.id}>
                    <label className="form-label">
                      Teams Checklist {index + 1}
                    </label>
                    <div className="bg-gray-700 rounded p-4">
                      <div
                        className="dangerously-loaded-inner-html"
                        dangerouslySetInnerHTML={{
                          __html: clean,
                        }}
                      ></div>
                    </div>
                  </div>
                );
              })}
            <div className="col-md-12">
              <hr className="dashed" />
            </div>
            <h4>Attachments</h4>
            {!!incidentAfterActionReport &&
              incidentAfterActionReport.length > 0 && (
                <div className="col-md-12 mb-2">
                  <label className="form-label m-0">
                    Incident After Action Report (File Upload)
                  </label>
                  <div className="row">
                    {incidentAfterActionReport.map((file, index) => {
                      return (
                        <div key={file} className="col-md-3 col-xl-2 my-2">
                          <div className="cards cards__uploaded-file">
                            <span
                              className="remove"
                              onClick={() =>
                                deleteFile(
                                  index,
                                  'IncidentAfterActionReport',
                                  file.trim()
                                )
                              }
                            >
                              <img src={IconClose} alt="" />
                            </span>
                            <span className="icon">
                              <img src={IconFile} alt="" />
                            </span>
                            <span className="title">
                              {file.trim().split('/').pop()}
                            </span>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            <div className="col-md-12 mb-3">
              <label className="form-label">
                Incident After Action Report (File Upload)
              </label>
              <div className="form-upload">
                <div>
                  <span className="d-flex justify-content-center mb-2">
                    <img src={IconUpload} alt="" />
                  </span>
                  <span className="d-block">
                    Drop Your Files Here or Click to Upload
                  </span>
                </div>
                <input
                  type="file"
                  onInput={(e) =>
                    uploadFile(e.target.files[0], 'IncidentAfterActionReport')
                  }
                />
              </div>
            </div>
            {!!exerciseAfterActionReport &&
              exerciseAfterActionReport.length > 0 && (
                <div className="col-md-12 mb-2">
                  <label className="form-label m-0">
                    Exercise After Action Report (File Upload)
                  </label>
                  <div className="row">
                    {exerciseAfterActionReport.map((file, index) => {
                      return (
                        <div key={file} className="col-md-3 col-xl-2 my-2">
                          <div className="cards cards__uploaded-file">
                            <span
                              className="remove"
                              onClick={() =>
                                deleteFile(
                                  index,
                                  'ExerciseAfterActionReport',
                                  file.trim()
                                )
                              }
                            >
                              <img src={IconClose} alt="" />
                            </span>
                            <span className="icon">
                              <img src={IconFile} alt="" />
                            </span>
                            <span className="title">
                              {file.trim().split('/').pop()}
                            </span>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            <div className="col-md-12 mb-3">
              <label className="form-label">
                Exercise After Action Report (File Upload)
              </label>
              <div className="form-upload">
                <div>
                  <span className="d-flex justify-content-center mb-2">
                    <img src={IconUpload} alt="" />
                  </span>
                  <span className="d-block">
                    Drop Your Files Here or Click to Upload
                  </span>
                </div>
                <input
                  type="file"
                  onInput={(e) =>
                    uploadFile(e.target.files[0], 'ExerciseAfterActionReport')
                  }
                />
              </div>
            </div>
          </div>
          {!!reduxFeatureFlags.includes('DISASTERGPT') && (
            <div>
              {!!dgptSitrepToInitialIncidentReport && (
                <div>
                  <div className="col-md-12">
                    <hr className="dashed" />
                  </div>
                  <h2>Auto-SITREP</h2>
                  <StylishNewButton
                    className="button--secondary"
                    onClick={() => setIsAutoSitrepOpen(!isAutoSitrepOpen)}
                  >
                    {(!!isAutoSitrepOpen && `Collapse `) || `Expand `}AutoSITREP
                  </StylishNewButton>
                  <StylishNewButton
                    className="button--secondary ms-3"
                    onClick={() => clearAutoSitrep()}
                  >
                    Clear AutoSITREP
                  </StylishNewButton>
                  {isAutoSitrepOpen && (
                    <div>
                      <ReactMarkdown>
                        {dgptSitrepToInitialIncidentReport?.sitrepMarkdown
                          .replaceAll('{{', '')
                          .replaceAll('}}', '')}
                      </ReactMarkdown>
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
          {!!reduxFeatureFlags.includes('PLANAI') && (
            <div>
              <PlanAI
                incidentTypes={getValues('incidentType')}
                incidentDescription={getValues('incidentDescription')}
                planAIInit={planAIInit}
                setPlanAIInit={setPlanAIInit}
                formReady={formReady}
                setFormReady={setFormReady}
              />
            </div>
          )}
        </div>

        <div className="col-md-12 mt-4 mt-md-5">
          <div className="button-group flex-column align-items-md-end">
            <StylishNewButton
              disabled={isSubmitted && !isValid}
              className="button--primary w-100 w-md-auto ms-auto"
              type={'submit'}
              onClick={() => handleSubmit(onSubmit)}
            >
              {!selectedReport ? 'Send' : 'Save'}
            </StylishNewButton>
            {isSubmitted && !isValid && (
              <span className="form-text form-error mt-4">
                Form Validation Error
              </span>
            )}
          </div>
          {!isLoaded && <div>loading...</div>}
        </div>
      </form>
      {showAddNewAorDialog && (
        <CoreComponentCreationDialog
          show={showAddNewAorDialog}
          onClose={() => {
            setShowAddNewAorDialog(false);
          }}
          ccType="Areas of Responsibility"
          existingCC={null}
        />
      )}
    </>
  );
}
