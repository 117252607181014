import React from 'react';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import Network from '../../../../../service/Network';
import API from '../../../../../service/api';
import { toast } from 'react-toastify';
import { Modal } from 'react-bootstrap';
import { updateplatforminParticipant } from '../../../../../store/Participants/participant.action';
import { Platforms } from '../AddNewModals/Platforms';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import StylishNewSelect from 'components/DesignSystems/New/StylishNewSelect';
import StylishNewInput from 'components/DesignSystems/New/StylishNewInput';
import { inputStringLength, toastConfig } from 'assets/data/config';
import { endLoading, startLoading } from "../../../../../../reducers/loading/loading.action";

export const CreateParticipantPlatform = ({
  show,
  onClose,
  dialogType,
  updateDialogData,
  participantId,
}) => {
  const [allPlatforms, setAllPlatforms] = useState([]);
  const [showAddPlatformModal, setShowAddPlatformModal] = useState(false);
  const [platformAfterSave, setPlatformAfterSave] = useState();
  const [beddown, setBeddown] = useState('');

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    clearErrors,
  } = useForm();

  const dispatch = useDispatch();

  const { selectedWorkspace } = useSelector((state) => state.prepare.workspace);
  const reduxUser = useSelector((state) => state.prepare.user);

  const customFilter = (option, searchText) => {
    if (option?.value === 'new') return true;
    if (option?.data?.label?.toLowerCase().includes(searchText.toLowerCase())) {
      return true;
    } else {
      return false;
    }
  };

  const onSubmit = async (data) => {
    dispatch(startLoading());
    try {
      const newPlatform = await Network.post(
        dialogType === 'Add' ? API.addPlatform : API.updateplatform,
        {
          workspaceId: selectedWorkspace,
          platformId: data.platform.value,
          beddown: data.beddown,
          participantId: participantId,
          id: updateDialogData?.id,
        }
      );
      dispatch(
        updateplatforminParticipant(
          selectedWorkspace,
          data.platform.value,
          data.beddown,
          participantId,
          updateDialogData?.id
        )
      );
      if (dialogType === 'Add') {
        toast.success('Participant Platform added successfully', toastConfig);
      } else if (dialogType === 'Edit') {
        toast.success('Participant Platform edited successfully', toastConfig);
      }
      onClose(newPlatform.data.response.dataset[0]);
    } catch (error) {
      toast.error(error.response.data.response.status.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };

  const getAllPlatform = async () => {
    dispatch(startLoading());
    try {
      const platform = await Network.get(API.getAllPlatform, {
        workspaceId: selectedWorkspace,
      });
      setAllPlatforms([
        { value: 'new', label: 'Add New' },
        ...platform.data.response.dataset.map((e) => {
          return {
            value: e.id,
            label: e.title,
          };
        }),
      ]);
      if (updateDialogData?.id) {
        setPlatformAfterSave({
          value: updateDialogData?.ppId,
          label: updateDialogData?.title,
        });
        setValue('platform', {
          value: updateDialogData?.ppId,
          label: updateDialogData?.title,
        });
        setBeddown(updateDialogData?.beddown);
        setValue('beddown', updateDialogData?.beddown);
      }
    } catch (error) {
      toast.error(error?.response?.data?.response?.status?.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };

  useEffect(() => {
    getAllPlatform();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reduxUser]);

  const onCloseAfterSavePlatform = (afterSave) => {
    setShowAddPlatformModal(false);
    if (afterSave?.id) {
      // setAllPlatforms([
      //   { label: afterSave?.title, value: afterSave?.id },
      //   ...allPlatforms,
      // ]);
      setAllPlatforms([
        ...allPlatforms,
        {
          label: afterSave?.title,
          value: afterSave?.id,
        },
      ]);
      setPlatformAfterSave({
        label: afterSave?.title,
        value: afterSave?.id,
      });
      setValue('platform', {
        label: afterSave?.title,
        value: afterSave?.id,
      });
      clearErrors('platform');
    } else {
      setPlatformAfterSave('');
      setValue('platform', '');
    }
  };
  useEffect(() => {
    const listener = (event) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        event.preventDefault();
        handleSubmit(onSubmit)();
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, []);
  const checkKeyDown = (e) => e.code === 'Enter' && e.preventDefault();

  return (
    <>
      <Modal
        show={show}
        onHide={onClose}
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton closeVariant="white">
          <Modal.Title>{dialogType} Participant Platform</Modal.Title>
        </Modal.Header>
        <form
          onSubmit={handleSubmit(onSubmit)}
          onKeyDown={(e) => checkKeyDown(e)}
        >
          <Modal.Body>
            <div className="mb-3">
              <label className="form-label">Platform</label>
              <Controller
                control={control}
                name="platform"
                rules={{ required: true }}
                defaultValue={updateDialogData?.id}
                render={({ field: { onChange, value } }) => (
                  <StylishNewSelect
                    options={allPlatforms}
                    defaultValue={value}
                    value={platformAfterSave || value}
                    onChange={(e) => {
                      if (e?.value === 'new') {
                        clearErrors('platform');
                        setShowAddPlatformModal(true);
                        return onChange();
                      } else if (!e) {
                        setPlatformAfterSave('');
                      }
                      return onChange(e);
                    }}
                    filterOption={customFilter}
                    placeholder={'Select platform'}
                    isClearable={true}
                    isSearchable={true}
                    isMulti={false}
                    isDisabled={false}
                  />
                )}
              />
              {errors.platform && (
                <span className="form-text form-error">
                  This is a required field
                </span>
              )}
            </div>
            <div className="mb-0">
              <label className="form-label">Bed Down</label>
              <Controller
                control={control}
                name={'beddown'}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, value } }) => (
                  <StylishNewInput
                    onChange={(e) => {
                      onChange(e);
                    }}
                    value={value}
                    type="text"
                    name="beddown"
                    maxLength={inputStringLength}
                    className="form-control"
                    placeholder="BedDown"
                    // {...register('amount', { required: true })}
                  />
                )}
              />
              {errors?.beddown && (
                <span className="form-text form-error">
                  This is a required field
                </span>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="button-group">
              <StylishNewButton
                className="button button--secondary button--reverse"
                onClick={onClose}
              >
                Close
              </StylishNewButton>
              <StylishNewButton
                className="button button--primary"
                type="submit"
              >
                Save
              </StylishNewButton>
            </div>
          </Modal.Footer>
        </form>
        {showAddPlatformModal && (
          <Platforms
            show={showAddPlatformModal}
            onClose={(afterSave) => onCloseAfterSavePlatform(afterSave)}
          />
        )}
      </Modal>
    </>
  );
};
