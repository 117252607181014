import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { StylishNewSearchBar } from 'components/DesignSystems/New/StylishNewSearchBar';
import StylishNewTable from 'components/DesignSystems/New/StylishNewTable';
import Dropdown from 'react-bootstrap/Dropdown';
import { useDispatch, useSelector } from 'react-redux';
import { getAllWorkAssignments } from 'actions/IAPForm204Actions';
import { StylishConfirmDialog } from 'components/DesignSystems/New/StylishConfirmDialog';
import {
  deleteResourceToIncident,
  getAllResourcesToGroup,
  getAllResourcesToIncident,
  getRosterForIncident,
  updateIncidentResourceSelection,
} from 'actions/incidentActions';
import moment from 'moment';
import { endLoading, startLoading } from 'reducers/loading/loading.action';
import NewResourcesDialog from 'components/IncidentConfig/NewResourcesDialog';
import PageTitle from 'components/headers/PageTitle';
import { useNewUIFlag } from 'utils/featureFlags';
import { SharedIcon } from 'components/SharedIcon/SharedIcon';

export const ResourceList = () => {
  const navigate = useNavigate();
  const reduxDispatch = useDispatch();
  const [allWorkAssignments, setAllWorkAssignments] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [showNewBranchModal, setShowNewBranchModal] = useState(false);
  const [allResources, setAllResources] = useState([]);
  const [selectedIncidentResources, setSelectedIncidentResources] = useState(
    []
  );
  const [deleteRowData, setDeleteRowData] = useState(null);
  const [editResourceData, setEditResourceData] = useState();

  const [modalType, setModalType] = useState('New');
  const [prettyGroupResources, setPrettyGroupResources] = useState([]);
  const [isNewResourcesDialogOpen, setIsNewResourcesDialogOpen] = useState(
    false
  );
  const newUI = useNewUIFlag();

  const reduxCurrentIncident = useSelector((state) => {
    return state.app.currentIncident;
  });
  const reduxCurrentlySelectedGroup = useSelector((state) => {
    return state.app.currentlySelectedGroup;
  });
  const reduxValidateRBACPermissionForActionResult = useSelector((state) => {
    return state.app.validateRBACPermissionForActionResult;
  });
  const onEdit = (row) => {
    setModalType('Edit');
    setIsNewResourcesDialogOpen(true);
    setEditResourceData(row);
  };
  const onNewResource = () => {
    setModalType('New');
    setIsNewResourcesDialogOpen(true);
  };
  const onDelete = (row) => {
    setDeleteRowData(row);
    setShowConfirmDialog(true);
  };
  const onDeleteConfirmation = () => {
    reduxDispatch(startLoading());
    reduxDispatch(deleteResourceToIncident(deleteRowData.id));
    setShowConfirmDialog(false);
    setDeleteRowData(null);
  };

  const incidentResources = useSelector((state) => state.app.incidentResources);
  const groupResources = useSelector((state) => state.app.groupResources);
  useEffect(() => {
    setSelectedIncidentResources(
      prettyGroupResources
        ? prettyGroupResources?.filter((item) =>
            incidentResources?.find((i) => i.resource_id === item.id)
          )
        : []
    );
    reduxDispatch(endLoading());
  }, [incidentResources, prettyGroupResources]);

  useEffect(() => {
    setPrettyGroupResources(groupResources ? [...groupResources] : []);
  }, [groupResources]);

  const onChangeSearchTerm = (termSearched) => {
    setSearchTerm(termSearched);
    const filteredResources = groupResources.filter((resource) => {
      return (
        resource.name?.toLowerCase().includes(termSearched.toLowerCase()) ||
        resource.user_name
          ?.toLowerCase()
          .includes(termSearched.toLowerCase()) ||
        resource.special_instructions
          ?.toLowerCase()
          .includes(termSearched.toLowerCase()) ||
        resource.type?.toLowerCase().includes(termSearched.toLowerCase())
      );
    });
    setPrettyGroupResources(filteredResources);
  };
  useEffect(() => {
    if (!!reduxCurrentIncident) {
      if (!!reduxCurrentIncident.current_period) {
        reduxDispatch(getAllWorkAssignments());
      }
      reduxDispatch(getRosterForIncident());
      reduxDispatch(getAllResourcesToIncident());
    }
  }, [reduxCurrentIncident]);
  useEffect(() => {
    if (reduxCurrentlySelectedGroup) {
      reduxDispatch(getAllResourcesToGroup());
    }
  }, [reduxCurrentlySelectedGroup]);
  const columns = [
    {
      dataField: 'type',
      text: 'Type',
      sort: true,
      headerAttrs: { title: 'Sort By:' },
      attrs: { title: 'Type' },
    },
    {
      dataField: 'name',
      text: 'Name',
      sort: true,
      attrs: { title: 'Name' },
    },
  ];

  const expandRow = {
    parentClassName: 'parent-expand-tr',
    className: 'child-expand-tr',
    renderer: (row) => {
      return (
        <>
          <div className="row">
            <div className="col-md-6 mb-3">
              <h6 className="mb-2">Datetime Ordered</h6>
              {moment(row.datetime_ordered).format('MM/DD/YYYY @ hh:mm a')}
            </div>
            <div className="col-md-6 mb-3">
              <h6 className="mb-2">Location</h6>
              {row.location || row.geolocation?.name}
            </div>
            <div className="col-md-6 mb-3 mb-md-0">
              <h6 className="mb-2">ETA</h6>
              {moment(row.eta).format('MM/DD/YYYY @ hh:mm a')}
            </div>
            <div className="col-md-6">
              <h6 className="mb-2">Notes</h6>
              {row.description}
            </div>
          </div>
          {!!reduxValidateRBACPermissionForActionResult['Write Pod Data'] && (
            <div className="button-group mt-4">
              <StylishNewButton
                className="button--primary"
                onClick={() => onEdit(row)}
              >
                Edit
              </StylishNewButton>
              <StylishNewButton
                className="button--tertiary button--reverse"
                onClick={() => onDelete(row)}
              >
                Delete
              </StylishNewButton>
            </div>
          )}
        </>
      );
    },
    showExpandColumn: true,
    expandByColumnOnly: true,
    expandHeaderColumnRenderer: ({ isAnyExpands }) => {
      if (isAnyExpands) {
        return <i className="fa fa-chevron-down" aria-hidden="true"></i>;
      }
      return <i className="fa fa-chevron-right" aria-hidden="true"></i>;
    },
    expandColumnRenderer: ({ expanded }) => {
      if (expanded) {
        return (
          <>
            <div className="d-flex align-items-center justify-content-md-center">
              <span className="me-2">Details</span>
              <i className="fa fa-chevron-down" aria-hidden="true"></i>
            </div>
          </>
        );
      }
      return (
        <>
          <div className="d-flex align-items-center justify-content-md-center">
            <span className="me-2">Details</span>
            <i className="fa fa-chevron-right" aria-hidden="true"></i>
          </div>
        </>
      );
    },
  };

  const selectRow = {
    mode: 'checkbox',
    clickToSelect: true,
    hideSelectAll: false,
    selected: selectedIncidentResources.map((e) => e.id),
    selectionHeaderRenderer: ({ mode, ...rest }) => (
      <div className="form-checkbox align-items-center">
        <input type={mode} {...rest} />
        <span className="icon"></span>
      </div>
    ),
    selectionRenderer: ({ mode, ...rest }) => (
      <div className="form-checkbox align-items-center">
        <input type={mode} {...rest} />
        <span className="icon"></span>
      </div>
    ),
    onSelect: (row, isSelect, rowIndex, e) => {
      let selection = selectedIncidentResources.map((e) => e.id);
      if (selection.includes(row.id)) {
        selection = selection.filter((e) => e !== row.id);
      } else {
        selection.push(row.id);
      }
      reduxDispatch(startLoading());
      reduxDispatch(updateIncidentResourceSelection(selection));
    },
    onSelectAll: (isSelect, rows, e) => {
      if (isSelect) {
        reduxDispatch(startLoading());
        reduxDispatch(updateIncidentResourceSelection(rows.map((e) => e.id)));
      } else {
        reduxDispatch(startLoading());
        reduxDispatch(updateIncidentResourceSelection([]));
      }
    },
  };

  return (
    <>
      {newUI ? (
        <PageTitle title="Resource List" parents={['Incident Management']} />
      ) : (
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <button onClick={() => navigate(-1)}>
                <SharedIcon iconName="keyboard_arrow_left" classes="me-2" />
                Back
              </button>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Resource List
            </li>
          </ol>
        </nav>
      )}

      <div className="d-md-flex flex-wrap align-items-center mb-4 justify-content-end">
        {/* <div className="button-group toggle ms-auto mt-3 mt-md-0">
          <NavLink
            className="button--icon lrg flex-grow-1"
            to={'/incident_management/assignments_list'}
          >
            <i className="fa fa-bars" aria-hidden="true"></i>
          </NavLink>
          <NavLink
            className="button--icon lrg flex-grow-1"
            to={'/incident_management/assignments_board'}
          >
            <i className="fa fa-th-large" aria-hidden="true"></i>
          </NavLink>
          <NavLink
            className="button--icon lrg flex-grow-1"
            to={'/incident_management/assignments_calendar'}
          >
            <i className="fa fa-calendar" aria-hidden="true"></i>
          </NavLink>
        </div> */}
        <div className="d-flex align-items-center flex-grow-1 flex-lg-grow-0 mt-3 mt-lg-0">
          <StylishNewSearchBar
            className="ms-lg-3 me-3 me-md-0"
            value={searchTerm}
            onChangeSearchTerm={onChangeSearchTerm}
          />
          {/* <StylishNewButton
            customButton
            className="button--icon lrg ms-auto ms-lg-3 flex-shrink-0"
          >
            <img src={IconInfo} alt="" />
          </StylishNewButton> */}
          <Dropdown className="flex-shrink-0 ms-3">
            <Dropdown.Toggle className="button--icon">
              <SharedIcon iconName="more_vert" bold />
            </Dropdown.Toggle>
            <Dropdown.Menu align="end">
              <Dropdown.Item onClick={onNewResource}>
                <SharedIcon iconName="add" bold />
                New Resource
              </Dropdown.Item>
              <Dropdown.Item>
                <Link
                  to={'/incident_management/ICS_204-CG'}
                  className="nav-link d-flex align-items-center"
                >
                  <SharedIcon iconName="visibility" />
                  Printable Assignments List (204)
                </Link>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
      <div className="form-block">
        <StylishNewTable
          keyField={'id'}
          columns={columns}
          rows={prettyGroupResources}
          expandRow={expandRow}
          selectRow={selectRow}
        />
      </div>

      {isNewResourcesDialogOpen && (
        <NewResourcesDialog
          show={isNewResourcesDialogOpen}
          modalType={modalType}
          setModalType={setModalType}
          editResourceData={editResourceData}
          setEditResourceData={setEditResourceData}
          onClose={() => setIsNewResourcesDialogOpen(false)}
        />
      )}
      {showConfirmDialog && (
        <StylishConfirmDialog
          show={showConfirmDialog}
          dialogTitle={'Delete Confirmation'}
          dialogContent={'Are you sure you want to delete this Resource?'}
          onClose={() => setShowConfirmDialog(false)}
          onConfirm={onDeleteConfirmation}
        />
      )}
    </>
  );
};
