import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ProfilePage from '../components/ProfileRoute/ProfilePage';
import Activity from '../components/Activity/Activity';
import GroupsPage from '../components/GroupsRoute/GroupsPage';
import TosPage from '../components/TosRoute/TosPage';
import NotificationsPage from '../components/Notifications/NotificationsPage';
import IncidentConfigureTab from '../components/IncidentConfig/IncidentConfigureTab';
import OSINTPage from '../components/OSINT/OSINTPage';
import TaskManagement from '../components/TaskManagement/TaskManagement';
import TaskManagementDashboard from '../components/TaskManagement/TaskManagementDashboard';
import TaskManagementTimeline from '../components/TaskManagement/TaskManagementTimeline';
import TaskManagementMap from '../components/TaskManagement/TaskManagementMap';
import FeatureFlags from '../components/FeatureFlags/FeatureFlags';
import FormBuilderPage from '../components/FormBuilder/FormBuilderPage';
import { FormBuilderDashboard } from '../components/FormBuilder/FormBuilderDashboard';
import { AssignmentList } from '../components/FormBuilder/AssignmentsList';
import { ResourceList } from '../components/FormBuilder/ResourceList';
import { AssignmentBoard } from '../components/FormBuilder/AssignmentsBoard';
import { AssignmentCalendar } from '../components/FormBuilder/AssignmentsCalendar';
import ReportPage from '../components/Report/ReportPage';
import LogoutRoute from '../components/LogoutRoute/LogoutRoute';
import { DiceDashboard } from '../components/Dashboard/Dashboard';
import MapPage from '../components/MapPage/MapPage';
import { PreparePath } from '../utils/sharedUtils/sharedConst';
import PREPAREApp from '../PREPAREsrc/PREPAREapp';
import { SubscriptionManagement } from '../components/SubscriptionManagement/SubscriptionManagement';
import Loader from '../components/Loader/loader';
import { ToastContainer } from 'react-toastify';
import RouteError from '../components/Error/RouteError';
import KnowledgeBase from '../components/KnowledgeBase/KnowledgeBase';

const LegacyRoutes = ({ isDev }) => {
  return (
    <>
      <Routes>
        <Route errorElement={<RouteError />}>
          <Route path="/knowledge_base" element={<KnowledgeBase />} />
          <Route path="/profile/*" element={<ProfilePage />} />
          <Route path="/activity" element={<Activity />} />
          <Route path="/groups/*" element={<GroupsPage />} />
          <Route path="/tos" element={<TosPage />} />
          <Route path="/notifications" element={<NotificationsPage />} />
          <Route
            path="/incident_configuration"
            element={<IncidentConfigureTab />}
          />
          <Route path="/osint" element={<OSINTPage />} />
          <Route path="/task_management" element={<TaskManagement />}>
            <Route index element={<TaskManagementDashboard />} />
            <Route path="timeline" element={<TaskManagementTimeline />} />
            <Route path="map" element={<TaskManagementMap />} />
          </Route>
          {isDev && <Route path="/feature_flags" element={<FeatureFlags />} />}
          <Route path="/incident_management/*" element={<FormBuilderPage />}>
            <Route index element={<FormBuilderDashboard />} />
            <Route path="assignments_list" element={<AssignmentList />} />
            <Route path="resource_list" element={<ResourceList />} />
            <Route path="assignments_board" element={<AssignmentBoard />} />
            <Route
              path="assignments_calendar"
              element={<AssignmentCalendar />}
            />
          </Route>
          <Route path="/report/*" element={<ReportPage />} />
          <Route path="/logout" element={<LogoutRoute />} />
          <Route path="/dashboard" element={<DiceDashboard />} />
          <Route path="/map" element={<MapPage />} />
          <Route path="/" element={<MapPage />} />
          <Route path={`/${PreparePath}/*`} element={<PREPAREApp />}></Route>
          <Route
            path="/subscription_management"
            element={<SubscriptionManagement />}
          />
        </Route>
      </Routes>
      <Loader />
      <ToastContainer />
    </>
  );
};

export default LegacyRoutes;
