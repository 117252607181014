import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import IconBack from 'assets/images/socials/icon__back.svg';
import Logo from 'assets/images/socials/vk__logo.svg';
import IconLike from 'assets/images/socials/facebook__icon__like.svg';
import IconComment from 'assets/images/socials/facebook__icon__comment.svg';
import { Link, useNavigate, useParams } from 'react-router-dom';
import IconSearch from 'assets/images/socials/youtube__icon__search.svg';
import moment from 'moment';
import IconEmptyExercise from 'assets/images/icon__exercise-details-empty.svg';
import { useSelector } from 'react-redux';
import { PreparePath } from 'utils/sharedUtils/sharedConst';

export const PostVK = ({ data }) => {
  const navigate = useNavigate();

  const { workspaceName } = useSelector((state) => {
    return state?.prepare?.workspace;
  });

  const params = useParams();

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <>
      <div className="vk">
        <div className="vk__header">
          <StylishNewButton
            customButton
            className={'button--icon'}
            onClick={handleBack}
          >
            <img src={IconBack} alt="" />
          </StylishNewButton>
          <span className="logo">
            <img src={Logo} alt="" className="" />
          </span>
          <div className="search">
            <img className="icon" src={IconSearch} alt="" />
            <input type="search" placeholder="Search..." />
          </div>
          <Link>Sign Up</Link>
        </div>
        <div className="vk__main">
          {sessionStorage.getItem('mediaModuleSocialMediaType') === 'sites' ? (
            <>
              <div className="d-flex align-items-center mb-4">
                <div className="avatar flex-shrink-0">
                  {data?.profileAvatar ? (
                    <img src={data?.profileAvatar} alt="" />
                  ) : null}
                </div>
                <div className="ms-3">
                  {data?.profileName ? (
                    <h6 className="m-0">{data?.profileName}</h6>
                  ) : null}
                  {data?.postPublishTime ? (
                    <p className="m-0">
                      {moment(data?.postPublishTime).format('MMM Do YYYY')}
                    </p>
                  ) : null}
                </div>
              </div>
              {data?.post ? <p className="m-0">{data?.post}</p> : null}
              {data?.mediaFile ? (
                <div className="d-flex mt-3">
                  <img
                    src={data?.mediaFile}
                    alt=""
                    style={{ width: '200px', height: 'auto' }}
                  />
                </div>
              ) : null}
              <p className="mt-4 mt-md-5 mb-3 mb-md-2 d-flex weight-500 justify-content-center justify-content-md-end">
                <span>{data?.likes || '0'} Likes</span>
                <span className="ms-3">{data?.shares || '0'} Shares</span>
                <span className="ms-3">
                  {data?.comments?.length || '0'} Comments
                </span>
              </p>
              <hr className="m-0" />
              <div className="d-flex justify-content-center py-3 weight-500">
                <ul className="nav">
                  <li className="d-flex align-items-center cursor-pointer">
                    <img className="me-2" src={IconLike} alt="" />
                    Like
                  </li>
                  <li className="d-flex align-items-center ms-5 cursor-pointer">
                    <img className="me-2" src={IconComment} alt="" />
                    Comment
                  </li>
                </ul>
              </div>
              <hr className="m-0" />
              {data?.comments && !!data?.comments.length ? (
                <div className="py-4">
                  {data?.comments.map((i, idx) =>
                    i?.status === 'drafted' ? null : (
                      <div key={i?.id}>
                        {idx !== 0 ? <hr className="my-4" /> : null}
                        <div className="d-flex align-items-start">
                          <div className="avatar flex-shrink-0">
                            {i?.profileAvatar ? (
                              <img src={i?.profileAvatar} alt="" />
                            ) : null}
                          </div>
                          <div className="ms-3">
                            {i?.profileName ? (
                              <h6 className="mb-2">{i?.profileName}</h6>
                            ) : null}
                            {i?.comment ? (
                              <p className="m-0">{i?.comment}</p>
                            ) : null}
                            {i?.commentImg ? (
                              <div className="d-flex mt-3">
                                <img
                                  src={i?.commentImg}
                                  alt=""
                                  style={{ width: '100px', height: 'auto' }}
                                />
                              </div>
                            ) : null}
                            <p className="m-0 mt-3 weight-500 d-flex align-items-md-center flex-column flex-md-row txt--sml">
                              {i?.publishTime ? (
                                <>{moment(i?.publishTime).fromNow()}</>
                              ) : null}
                              <span className="mt-2 mt-md-0 ms-md-4">
                                {i?.likes || '0'} Likes
                              </span>
                              <span className="mt-2 mt-md-0 ms-md-4">
                                Reply
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    )
                  )}
                </div>
              ) : null}
              <hr className="m-0" />
              <div className="d-flex align-items-center py-3">
                <div className="avatar flex-shrink-0">
                  {data?.profileAvatar ? (
                    <img src={data?.profileAvatar} alt="" />
                  ) : null}
                </div>
                <input
                  type="text"
                  className="comment rounded-pill ms-4 flex-grow-1"
                  placeholder="Write a comment..."
                />
              </div>
              <hr className="m-0" />
            </>
          ) : null}
          {sessionStorage.getItem('mediaModuleSocialMediaType') ===
          'profiles' ? (
            <>
              {!params?.postId ? (
                <>
                  {data?.profiledata?.posts &&
                  !!data?.profiledata?.posts?.length ? (
                    <>
                      {data?.profiledata?.posts.map((i, idx) => (
                        <div key={i.id}>
                          {idx !== 0 ? <hr className="dashed my-5" /> : null}
                          <div className="position-relative">
                            <Link
                              className="stretched-link"
                              to={`${PreparePath}/dashboard/media/profiles/${
                                workspaceName &&
                                workspaceName.charAt(0).toUpperCase()
                              }/${data?.sitedata?.slug}/${
                                data?.profiledata?.username
                              }/${i?.id}`}
                            ></Link>
                            <div className="d-flex align-items-center mb-4">
                              <div className="avatar flex-shrink-0">
                                {data?.profiledata?.avatar ? (
                                  <img src={data?.profiledata?.avatar} alt="" />
                                ) : null}
                              </div>
                              <div className="ms-3">
                                {data?.profiledata?.profileName ? (
                                  <h6 className="m-0">
                                    {data?.profiledata?.profileName}
                                  </h6>
                                ) : null}
                                {i?.publishTime ? (
                                  <p className="m-0">
                                    {moment(i?.publishTime).format(
                                      'MMM Do YYYY'
                                    )}
                                  </p>
                                ) : null}
                              </div>
                            </div>
                            {i?.post ? <p className="m-0">{i?.post}</p> : null}
                            {i?.mediaFile ? (
                              <div className="d-flex mt-3">
                                <img
                                  src={i?.mediaFile}
                                  alt=""
                                  style={{ width: '200px', height: 'auto' }}
                                />
                              </div>
                            ) : null}
                            <p className="mt-4 mt-md-5 mb-3 mb-md-2 d-flex weight-500 justify-content-center justify-content-md-end">
                              <span>{i?.likes || '0'} Likes</span>
                              <span className="ms-3">
                                {i?.shares || '0'} Shares
                              </span>
                              <span className="ms-3">
                                {i?.comments?.length || '0'} Comments
                              </span>
                            </p>
                            <hr className="m-0" />
                            <div className="d-flex justify-content-center py-3 weight-500">
                              <ul className="nav">
                                <li className="d-flex align-items-center cursor-pointer">
                                  <img className="me-2" src={IconLike} alt="" />
                                  Like
                                </li>
                                <li className="d-flex align-items-center ms-5 cursor-pointer">
                                  <img
                                    className="me-2"
                                    src={IconComment}
                                    alt=""
                                  />
                                  Comment
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      ))}
                    </>
                  ) : (
                    <div className="text-center p-5">
                      <div className="d-flex justify-content-center mb-4">
                        <img
                          src={IconEmptyExercise}
                          alt=""
                          style={{ opacity: '0.5' }}
                        />
                      </div>
                      <p className="weight-600 mb-4">No Post Found!</p>
                    </div>
                  )}
                </>
              ) : (
                <>
                  <div className="d-flex align-items-center mb-4">
                    <div className="avatar flex-shrink-0">
                      {data?.profileAvatar ? (
                        <img src={data?.profileAvatar} alt="" />
                      ) : null}
                    </div>
                    <div className="ms-3">
                      {data?.profileName ? (
                        <h6 className="m-0">{data?.profileName}</h6>
                      ) : null}
                      {data?.postPublishTime ? (
                        <p className="m-0">
                          {moment(data?.postPublishTime).format('MMM Do YYYY')}
                        </p>
                      ) : null}
                    </div>
                  </div>
                  {data?.post ? <p className="m-0">{data?.post}</p> : null}
                  {data?.mediaFile ? (
                    <div className="d-flex mt-3">
                      <img
                        src={data?.mediaFile}
                        alt=""
                        style={{ width: '200px', height: 'auto' }}
                      />
                    </div>
                  ) : null}
                  <p className="mt-4 mt-md-5 mb-3 mb-md-2 d-flex weight-500 justify-content-center justify-content-md-end">
                    <span>{data?.likes || '0'} Likes</span>
                    <span className="ms-3">{data?.shares || '0'} Shares</span>
                    <span className="ms-3">
                      {data?.comments?.length || '0'} Comments
                    </span>
                  </p>
                  <hr className="m-0" />
                  <div className="d-flex justify-content-center py-3 weight-500">
                    <ul className="nav">
                      <li className="d-flex align-items-center cursor-pointer">
                        <img className="me-2" src={IconLike} alt="" />
                        Like
                      </li>
                      <li className="d-flex align-items-center ms-5 cursor-pointer">
                        <img className="me-2" src={IconComment} alt="" />
                        Comment
                      </li>
                    </ul>
                  </div>

                  {data?.comments && !!data?.comments.length ? (
                    <>
                      <hr className="m-0" />
                      <div className="py-4">
                        {data?.comments.map((i, idx) => (
                          <div key={i?.id}>
                            {idx !== 0 ? <hr className="my-4" /> : null}
                            <div className="d-flex align-items-start">
                              <div className="avatar flex-shrink-0">
                                {i?.profileAvatar ? (
                                  <img src={i?.profileAvatar} alt="" />
                                ) : null}
                              </div>
                              <div className="ms-3">
                                {i?.profileName ? (
                                  <h6 className="mb-2">{i?.profileName}</h6>
                                ) : null}
                                {i?.comment ? (
                                  <p className="m-0">{i?.comment}</p>
                                ) : null}
                                {i?.commentImg ? (
                                  <div className="d-flex mt-3">
                                    <img
                                      src={i?.commentImg}
                                      alt=""
                                      style={{ width: '100px', height: 'auto' }}
                                    />
                                  </div>
                                ) : null}

                                <p className="m-0 mt-3 weight-500 d-flex align-items-md-center flex-column flex-md-row txt--sml">
                                  {i?.publishTime ? (
                                    <>{moment(i?.publishTime).fromNow()}</>
                                  ) : null}
                                  <span className="mt-2 mt-md-0 ms-md-4">
                                    {i?.likes || '0'} Likes
                                  </span>
                                  <span className="mt-2 mt-md-0 ms-md-4">
                                    Reply
                                  </span>
                                </p>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </>
                  ) : null}
                  <hr className="m-0" />
                  <div className="d-flex align-items-center py-3">
                    <div className="avatar flex-shrink-0">
                      {data?.profileAvatar ? (
                        <img src={data?.profileAvatar} alt="" />
                      ) : null}
                    </div>
                    <input
                      type="text"
                      className="comment rounded-pill ms-4 flex-grow-1"
                      placeholder="Write a comment..."
                    />
                  </div>
                  <hr className="m-0" />
                </>
              )}
            </>
          ) : null}
        </div>
      </div>
    </>
  );
};
