import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import ConfirmModal from 'PREPAREsrc/component/ConfirmModal/ConfirmModal';
import { StylishConfirmDialog } from 'components/DesignSystems/New/StylishConfirmDialog';
import { Link, NavLink, useNavigate, useParams } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import API from '../../../service/api';
import Network from '../../../service/Network';
import { StylishNewSearchBar } from 'components/DesignSystems/New/StylishNewSearchBar';
import { MiscellaneousHelpLink, PersonasHelp, useSelfHostedKnowledgeBase } from 'assets/data/HelpContent';
import { toast } from 'react-toastify';
import IconEmptyExercise from 'assets/images/icon__exercise-details-empty.svg';
import ReactPaginate from 'react-paginate';
import Help from '../../../component/Help/Help';
import { PreparePath } from 'utils/sharedUtils/sharedConst';
import IconEdit from 'assets/images/icon__edit.svg';
import IconDelete from 'assets/images/icon__delete.svg';
import ProfileImage from 'assets/images/icon__profileimage.jpg';
import CreatePersonaDialog from './CreatePersonaDialog';
import QuickCreatePersonaDialog from './QuickCreatePersonaDialog';
import IconView from 'assets/images/icon__eye.svg';
import StylishNewSelect from 'components/DesignSystems/New/StylishNewSelect';
import UseBlankPersona from './UseBlankPersona';
import { useForm, Controller } from 'react-hook-form';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { toastConfig } from 'assets/data/config';
import StylishHelp from 'components/DesignSystems/New/StylishHelp';
import {
  endLoading,
  startLoading,
} from '../../../../reducers/loading/loading.action';
import isRoleReadOnly from 'PREPAREsrc/utils/isRoleReadOnly';
import PageTitle from 'components/headers/PageTitle';
import { useNewUIFlag } from 'utils/featureFlags';

export default function Personas() {
  const selfHostedKnowledgeBase = useSelfHostedKnowledgeBase()
  const { control } = useForm();

  const [showCreatePersonaDialog, setShowCreatePersonaDialog] = useState(false);
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [deletePersona, setDeletePersona] = useState(null);
  const [sortBy, setSortBy] = useState({ value: 'name_ASC', label: 'Name' });

  // const [
  //   showQuickCreatePersonaDialog,
  //   setShowQuickCreatePersonaDialog,
  // ] = useState(false);
  // const [showUseBlankPersonaDialog, setShowUseBlankPersonaDialog] = useState(
  //   false
  // );

  const [personaList, setPersonaList] = useState([]);
  const [personaListTotal, setPersonaListTotal] = useState(0);
  const perPage = 8;

  const workspaceId = useSelector(
    (state) => state.prepare.workspace.selectedWorkspace
  );
  const reduxUser = useSelector((state) => state.prepare.user);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const newUI = useNewUIFlag();

  const sortPersona = [
    { value: 'name_ASC', label: 'Name' },
    { value: 'createdAt_ASC', label: 'Oldest Created' },
    { value: 'createdAt_DESC', label: 'Last Created' },
    { value: 'updatedAt_ASC', label: 'Oldest Updated' },
    { value: 'updatedAt_DESC', label: 'Last Updated' },
  ];

  const onCreatePersonaDialog = () => {
    setShowCreatePersonaDialog(true);
  };
  const onCreatePersonaDialogClose = (data) => {
    if (data === true) {
      mediaPersonaList();
    }
    setShowCreatePersonaDialog(false);
  };
  const onChangeSearchTerm = (termSearched) => {
    setSearchTerm(termSearched);
    setPage(1);
  };

  const handlePageClick = (event) => {
    setPage(event.selected + 1);
  };
  const onDeletePersona = (row) => {
    setDeletePersona(row.id);
  };

  const mediaPersonaList = async () => {
    dispatch(startLoading());
    try {
      const response = await Network.get(API.mediaPersonaList, {
        workspaceId: workspaceId,
        limit: perPage,
        page: page,
        search: searchTerm,
        sort: sortBy?.value,
      });
      setPersonaList(response?.data?.response?.dataset);
      setPersonaListTotal(response.data.response.total);
    } catch (error) {
    } finally {
      dispatch(endLoading());
    }
  };

  useEffect(() => {
    mediaPersonaList();
  }, [reduxUser, page, perPage, searchTerm, sortBy, workspaceId]);

  const onDeleteConfirm = async () => {
    dispatch(startLoading());
    try {
      await Network.post(API.deleteMediaPersona, {
        personaId: deletePersona,
      });
      await mediaPersonaList();
      setDeletePersona(null);
      toast.success('Persona deleted successfully', toastConfig);
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(endLoading());
    }
  };

  return (
    <>
      {newUI ? (
        <PageTitle title="Personas" parents={['Media']} />
      ) : (
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <StylishNewButton onClick={() => navigate(-1)}>
                <i className="fa fa-angle-left me-2" aria-hidden="true"></i>
                Back
              </StylishNewButton>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Personas
            </li>
          </ol>
        </nav>
      )}

      <div className="d-flex flex-wrap align-items-center mb-4">
        {!isRoleReadOnly(reduxUser.roles) && (
          <StylishNewButton
            onClick={onCreatePersonaDialog}
            className="button--primary flex-shrink-0"
          >
            Create Persona
          </StylishNewButton>
        )}
        <p className="ms-auto m-0">{personaListTotal} Persona(s)</p>
        <div className="d-flex flex-wrap align-items-center ms-lg-3 w-100 w-lg-auto mt-3 mt-lg-0">
          <StylishNewSearchBar
            className="flex-shrink-0"
            value={searchTerm}
            onChangeSearchTerm={onChangeSearchTerm}
          />
          <div className="d-flex align-items-center ms-md-auto ms-lg-3 w-100 w-md-auto mt-3 mt-md-0">
            <Controller
              control={control}
              name="sortProfileBy"
              rules={{ required: false }}
              render={({ field: { value } }) => (
                <StylishNewSelect
                  classes={'flex-grow-1'}
                  style={{ minWidth: '160px' }}
                  options={sortPersona}
                  defaultValue={sortPersona[0] || sortBy}
                  value={sortBy}
                  onChange={(e) => {
                    setSortBy(e);
                  }}
                  isClearable={false}
                  isSearchable={true}
                  isMulti={false}
                  isDisabled={false}
                />
              )}
            />

            <StylishHelp
              classes={'flex-shrink-0 ms-3 sml'}
              title={PersonasHelp.title}
              content={PersonasHelp.content}
              link={selfHostedKnowledgeBase || PersonasHelp.link}
            />
          </div>
        </div>
      </div>

      {personaListTotal > 0 ? (
        <>
          <div className="row">
            {personaList?.map((persona) => (
              <div
                className="col-md-6 col-lg-4 col-xl-3 mb-4"
                key={persona?.id}
              >
                <div className="bg-gray-800 rounded h-100 d-flex flex-column overflow-hidden">
                  <div className="p-3">
                    <div className="d-flex align-items-start mb-4">
                      {persona?.avatar ? (
                        <div className="profile-img">
                          <img src={persona?.avatar} alt="" />
                        </div>
                      ) : (
                        <div className="profile-img"></div>
                      )}

                      <Dropdown className="flex-shrink-0 ms-auto">
                        <Dropdown.Toggle className="button--icon">
                          <i
                            className="fa fa-ellipsis-v"
                            aria-hidden="true"
                          ></i>
                        </Dropdown.Toggle>
                        <Dropdown.Menu align="end">
                          <Dropdown.Item
                            onClick={() => {
                              navigate(
                                `${PreparePath}/dashboard/media/view-persona/${persona.id}`
                              );
                            }}
                          >
                            <img src={IconView} alt="" />
                            View Persona
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => {
                              navigate(
                                `${PreparePath}/dashboard/media/edit-personas/useblankpersona/${persona.id}`
                              );
                            }}
                          >
                            <img src={IconEdit} alt="" />
                            Edit Persona
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => onDeletePersona(persona)}
                          >
                            <img src={IconDelete} alt="" />
                            Delete Persona
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>

                    <h4 className="mb-2 break-word">{persona?.name}</h4>
                    <p className="mb-2">{persona?.role}</p>

                    <span className="color-brand">
                      {persona?.count} Linked Profiles
                    </span>
                  </div>

                  <div className="bg-gray-700 p-3 mt-auto d-flex align-items-center justify-content-center">
                    <Link
                      to={`${PreparePath}/dashboard/media/edit-personas/useblankpersona/${persona.id}`}
                      className="text-white weight-500 d-flex align-items-center justify-content-center flex-grow-1"
                    >
                      <img src={IconEdit} className="me-2" alt="" />
                      Edit
                    </Link>
                    <div className="separator--y separator--800  flex-shrink-0"></div>

                    <Link
                      to={`${PreparePath}/dashboard/media/view-persona/${persona.id}`}
                      className="text-white weight-500 d-flex align-items-center justify-content-center flex-grow-1"
                    >
                      <img src={IconView} className="img-h-16 me-2" alt="" />
                      View
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="d-flex justify-content-center justify-content-md-end">
            <ReactPaginate
              className="pagination"
              breakLabel="..."
              nextLabel="Next"
              onPageChange={handlePageClick}
              pageCount={Math.ceil(personaListTotal / perPage)}
              previousLabel="Prev"
            />
          </div>
        </>
      ) : (
        <>
          <div className="bg-gray-800 text-center p-5">
            <div className="py-0 py-md-4">
              <div className="d-flex justify-content-center mb-4">
                <img src={IconEmptyExercise} alt="" />
              </div>
              <p className="weight-600 mb-4">
                A fresh start. Create the first entry.
                <a href={selfHostedKnowledgeBase || MiscellaneousHelpLink.link} target="_blank">
                  {' '}
                  Need more help?
                </a>
              </p>
              {!isRoleReadOnly(reduxUser.roles) && (
                <a
                  className="button button--primary cursor-pointer"
                  onClick={onCreatePersonaDialog}
                >
                  Create Persona
                </a>
              )}
            </div>
          </div>
        </>
      )}
      {deletePersona && (
        <StylishConfirmDialog
          show={deletePersona}
          onClose={() => setDeletePersona(null)}
          dialogTitle={'Delete Persona'}
          dialogContent={'Are you sure you want this?'}
          onConfirm={onDeleteConfirm}
        />
      )}

      {showCreatePersonaDialog && (
        <CreatePersonaDialog
          show={showCreatePersonaDialog}
          onClose={(e) => {
            onCreatePersonaDialogClose(e);
          }}
        />
      )}
    </>
  );
}
