import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { SharedIcon } from 'components/SharedIcon/SharedIcon';

/**
 * TODO: This component uses dangerouslySetInnerHTML instead of some other pattern of rendering HTML content.
 *        Technically it never renders user input, but still should be refactored to avoid future XSS attacks.
 * */
export default function StylishHelp({
  classes,
  title,
  content,
  link,
  hasChild,
  children,
}) {
  return (
    <>
      <Dropdown className={'help ' + classes}>
        <Dropdown.Toggle className="button--icon">
          <SharedIcon iconName="question_mark" />
        </Dropdown.Toggle>
        <Dropdown.Menu align="end">
          {!hasChild ? (
            <>
              <h4 className="mt-0 mb-1">{title}</h4>
              {/* <div className="help-title">{title}</div> */}
              <div dangerouslySetInnerHTML={{ __html: content }}></div>
              <a
                className="button button--primary mt-2"
                href={link}
                target="_blank"
                rel="noreferrer"
              >
                More Help
              </a>
            </>
          ) : (
            <>{children}</>
          )}
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
}
