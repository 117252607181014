import React, { useEffect, useState } from 'react';
import ReactECharts from 'echarts-for-react';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';


import './Chart.css';

import './ERIChart.css';

import StylishNewTable from 'components/DesignSystems/New/StylishNewTable';
import StylishNewSelect from 'components/DesignSystems/New/StylishNewSelect';

import { formatDataForChart, formatDataForVisualMap } from './ChartHelpers';
import Row from 'components/DesignSystems/layout/Row';
import { Col } from 'react-bootstrap';

dayjs.extend(utc)


const legendArray = [
  [0, '#FFFFFF'],
  [1, '#ffffb2'],
  [2, '#fecc5c'],
  [3, '#fd8d3c'],
  [4, '#f03b20'],
  [5, '#bd0026'],
];


function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function formatDataForERIMarklineConfig(layer, data) {
  if (!data) return;

  const result = {
    data: legendArray.map((item, index) => {
      return {
        yAxis: parseFloat(item[0]),
        lineStyle: {
          color: item[1],
          type: 'dotted',
          width: 1,
        },
      };
    }),
  };
  return result;
}

export function formatDataForERIVisualMap(layer, data, eriScenarios) {
  if (!data) return;

  const result = [
    {
      top: 50,
      right: 50,
      showLabel: true,
      pieces: legendArray.map((item, index) => {
        return {
          value: parseFloat(index),
          //lte: parseFloat(legendArray[index + 1][0]),
          color: item[1],
        };
      }),
      outOfRange: {
        color: '#000',
      },
    },
    // {
    //   // text: "Scenarios",
    //   top: 50,
    //   right: 100,
    //   type:"piecewise",
    //   categories: eriScenarios.map(s=>s.label),
    //   inRange: {
    //     color: eriScenarios.map(s=>s.color)
    //   },
    //   outOfRange: {
    //     color: '#000',
    //   },
    // },
  ];
  return result;
}

function formatDataForERIChart(
  layer,
  title,
  data,
  eriScenario,
  eriScenarios,
  index
) {
  if (!data) return;

  let modelDataArray = data.map((timestep) => {
    return [
      new Date(
        dayjs(timestep.timestamp).format('MM/DD/YYYY hh:mm A')
      ).toISOString(),
      timestep[eriScenario],
    ];
  });

  const foundScenario = eriScenarios.find((s) => s.value === eriScenario);

  const result = {
    name: `${title} - (${foundScenario.label})`,
    type: 'line',
    //stack: 'all',
    label: 'show',
    data: modelDataArray,
    symbol: 'none',
    lineStyle: {
      type: 'solid',
      color: foundScenario.color,
      width: 7,
    },
    tooltip: {
      borderColor: foundScenario.color,
      borderWidth: 3,
      textStyle: {
        textBorderColor: foundScenario.color,
        textBorderWidth: 3,
      },
    },
    extraCssText: `color: ${foundScenario.color} !important`,
  };

  if (index === eriScenarios.length - 1) {
    result.markLine = {
      ...formatDataForERIMarklineConfig(layer, title, data),
      name: title,
    };
  }

  return result;
}

export default function ERIChart({
  layer,
  feature,
  selectedDatetime,
  apiPrefix,
  eriScenario,
  eriScenarios,
  sidebarSelection,
  sidebarActiveItem,
  viewMode,
  authHeader,
}) {
  const [modelData, setModelData] = useState();
  const [modelDataFetched, setModelDataFetched] = useState(false)
  const [dataDatetime, setDataDatetime] = useState(selectedDatetime);
  const [title, setTitle] = useState('');
  const [currentFeatureId, setCurrentFeatureId] = useState(
    feature.properties.eri_feature_id
  );

  // ERI only shows within 5 days ahead of current date.  Any days beyond 5 days from the current day are invalid.
  
  const daysDifference = dayjs(selectedDatetime).diff(dayjs(), 'day');
  const dateIsTooFarAhead = daysDifference >= 5;
  const dateIsValid = !dateIsTooFarAhead

  useEffect(() => {
    if (
      (!modelDataFetched || 
      !modelData ||
      new Date(selectedDatetime) !== new Date(dataDatetime) ||
      currentFeatureId !== feature.properties.eri_feature_id)
      && dateIsValid
    ) {
      fetch(`${apiPrefix}/dice/map/models?model_code=${
        layer.metadata.model_details.model_code
      }&selected_datetime=${selectedDatetime
        .minute(0)
        .second(0)
        .format('YYYY-MM-DD HH:mm:ss')}&political_boundaries_type=${
        layer.metadata.model_details.political_boundaries_type
      }&eri_id=${feature.properties.eri_feature_id}&boundary_id=${
        feature.properties.boundary_id
      }
      `,{
        headers: {
          'Authorization': authHeader,
          'x-teamsapp': sessionStorage['isUsingTeams'] === 'true'
        }
      })
        .then((res) => res.json())
        .then((response) => {
          let newTitle = `${layer.metadata.model_details.model_code} - ${
            (feature.properties.political_boundaries_type === 'county' &&
              `${feature.properties.county_name} County, ${feature.properties.state_name}`) ||
            (feature.properties.political_boundaries_type === 'state' &&
              feature.properties.state_name)
          }`;
          setDataDatetime(selectedDatetime);
          setModelData(response);
          setModelDataFetched(true)
          setTitle(newTitle);
          setCurrentFeatureId(feature.properties.eri_feature_id);
        })
        .catch((error) => {
          console.error('owm ERROR', error);
        });
    }
  }, [layer, selectedDatetime, feature]);

  const chartConfig = {
    title: {
      text: title,
    },
    backgroundColor: '#222529',
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
        label: {
          backgroundColor: '#6a7985',
        },
      },
      backgroundColor: '#343a40',
      borderColor: '#495057',
      textStyle: {
        color: '#FFF',
      },
      // formatter: (params)=> {
      //   return `
      //     <div className="ERIChart-tooltip">
      //     ${params.map((p,i)=>{
      //       const itemColor = legendArray[p.data[1]][1]
      //       return `
      //         <div className="ERIChart-tooltip-item">
      //           <div className="ERIChart-tooltip-color"
      //             style="border-color:${eriScenarios[i].color};border-width:5px;color=${itemColor};"
      //           >
      //           </div>
      //           <div className="ERIChart-tooltip-item-name"
      //             style="color:${eriScenarios[i].color};"
      //           >
      //             ${p.seriesName}
      //           </div>
      //         </div>
      //       `
      //     })}
      //     </div>
      //   `;
      // }
    },
    // legend: {
    //   data: eriScenarios.map(s=>{
    //     return `${title} (${s.label})`
    //   }) //[title],
    // },
    legend: {
      data: eriScenarios.map((s) => {
        return {
          name: s.label,
          textStyle: {
            color: s.color,
          },
        };
      }),
    },
    grid: {
      left: '5%',
      right: '20%',
      bottom: '15%',
      containLabel: true,
    },
    toolbox: {
      feature: {
        saveAsImage: {},
        dataZoom: {
          yAxisIndex: 'none',
        },
        restore: {},
      },
    },
    dataZoom: [
      {
        id: 'dataZoomX',
        type: 'slider',
        xAxisIndex: [0][0],
        filterMode: 'filter',
      },
      {
        id: 'dataZoomY',
        type: 'slider',
        yAxisIndex: [0],
        filterMode: 'empty',
      },
    ],
    xAxis: [
      {
        name: 'Site Time',
        type: 'time',
        scale: true,
        data: [],
        boundaryGap: false,
      },
      {
        name: 'UTC',
        type: 'time',
        scale: true,
        data: [],
      },
    ],
    yAxis: [
      {
        type: 'value',
        scale: true,
        name: layer.metadata.model_details.model_code,
        min: 0,
        max: 5,
      },
    ],
    series: eriScenarios.map((s, i) => {
      return formatDataForERIChart(
        layer,
        title,
        modelData,
        s.value,
        eriScenarios,
        i
      );
    }),
    visualMap: formatDataForERIVisualMap(layer, modelData, eriScenarios),
  };

  function onChartReady() {}

  function onChartClick(e) {}

  const onEvents = {
    click: onChartClick,
    //'legendselectchanged': this.onChartLegendselectchanged
  };

  const eriRows =
    !!modelData &&
    modelData
      .filter((m, i) => {
        return i % 24 === 0 ? m : null;
      })
      .filter((m) => !!m)
      .map((m) => {
        return {
          ...m,
        };
      });

  const eriTableRows =
    (!!modelData &&
      eriScenarios.map((s) => {
        let row = {
          Scenario: s.label,
        };
        eriRows.forEach((r) => {
          row[dayjs(r.timestamp).format('MM/DD/YYYY hh:mm A')] = r[s.value];
        });
        return row;
      })) ||
    [];

  let eriCols =
    (!!modelData && [
      ...Object.keys(eriTableRows[0]).map((col) => {
        return {
          dataField: col,
          text: col,
          attrs: { title: col },
          formatter: (cell, row, rowIndex) => {
            return (
              <>
                {row[col] === 0 ? (
                  <span
                    className="color-black d-block weight-600"
                    style={{ background: '#FFFFFF', padding: '.75rem 1rem' }}
                  >
                    {row[col]}
                  </span>
                ) : row[col] === 1 ? (
                  <span
                    className="color-black d-block weight-600"
                    style={{ background: '#ffffb2', padding: '.75rem 1rem' }}
                  >
                    {row[col]}
                  </span>
                ) : row[col] === 2 ? (
                  <span
                    className="color-black d-block weight-600"
                    style={{ background: '#fecc5c', padding: '.75rem 1rem' }}
                  >
                    {row[col]}
                  </span>
                ) : row[col] === 3 ? (
                  <span
                    className="color-black d-block weight-600"
                    style={{ background: '#fd8d3c', padding: '.75rem 1rem' }}
                  >
                    {row[col]}
                  </span>
                ) : row[col] === 4 ? (
                  <span
                    className="color-black d-block weight-600"
                    style={{ background: '#f03b20', padding: '.75rem 1rem' }}
                  >
                    {row[col]}
                  </span>
                ) : row[col] === 5 ? (
                  <span
                    className="color-black d-block weight-600"
                    style={{ background: '#bd0026', padding: '.75rem 1rem' }}
                  >
                    {row[col]}
                  </span>
                ) : row.Scenario === '90% probability' ? (
                  <span
                    className="color-black d-block weight-600"
                    style={{ background: '#f0f9e8', padding: '.75rem 1rem' }}
                  >
                    {row[col]}
                  </span>
                ) : row.Scenario === '75% probability' ? (
                  <span
                    className="color-black d-block weight-600"
                    style={{ background: '#bae4bc', padding: '.75rem 1rem' }}
                  >
                    {row[col]}
                  </span>
                ) : row.Scenario === 'Mean scenario' ? (
                  <span
                    className="color-black d-block weight-600"
                    style={{ background: '#7bccc4', padding: '.75rem 1rem' }}
                  >
                    {row[col]}
                  </span>
                ) : row.Scenario === '25% probability' ? (
                  <span
                    className="color-black d-block weight-600"
                    style={{ background: '#43a2ca', padding: '.75rem 1rem' }}
                  >
                    {row[col]}
                  </span>
                ) : row.Scenario === '10% probability' ? (
                  <span
                    className="color-black d-block weight-600"
                    style={{ background: '#0868ac', padding: '.75rem 1rem' }}
                  >
                    {row[col]}
                  </span>
                ) : (
                  <>{row[col]}</>
                )}
              </>
            );
          },
        };
      }),
    ]) ||
    [];

  return (
    <div
      className={`map-chart ${
        !!sidebarActiveItem && 'map-chart-sidebar-expanded'
      }`}
    >
      {(!dateIsValid && (
        <div className="weight-600 p-3">Out of Range: ERI Forecast only available within 4 days ahead of current day.</div>
      )) || ((!!modelDataFetched && (!modelData||!modelData.length)) && (
        <div className="weight-600 p-3">There is no ERI data.</div>
      )) || (dateIsValid && !!modelData && modelData.length && (
        <>
          {(viewMode === 'Chart' && (
            <ReactECharts
              option={chartConfig}
              theme={'dark'}
              onChartReady={onChartReady}
              onEvents={onEvents}
              opts={{ renderer: 'svg' }}
              className="Map-Chart"
            />
          )) || (
            <div className="ERI-Table-Wrap">
              <span>{title}</span>
              <StylishNewTable
                classes={'tbody-p-0 nowrap'}
                keyField={`id`}
                rows={eriTableRows}
                columns={eriCols}
              />
            </div>
          )}
        </>        
      )) || <div className="weight-600 p-3">Loading data...</div>}
    </div>
  );
}
