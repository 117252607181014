import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import StylishNewInput from 'components/DesignSystems/New/StylishNewInput';
import StylishNewSelect from 'components/DesignSystems/New/StylishNewSelect';
import { StylishSwitcher } from 'components/DesignSystems/New/StylishSwitcher';
import { Controller, useForm, useFieldArray } from 'react-hook-form';
import IconAdd from 'assets/images/icon__add.svg';
import IconDelete from 'assets/images/icon__delete.svg';
import Network from 'PREPAREsrc/service/Network';
import API from 'PREPAREsrc/service/api';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { endLoading, startLoading } from 'reducers/loading/loading.action';
import { useNavigate, useParams } from 'react-router';
import { PreparePath } from 'utils/sharedUtils/sharedConst';
import { toast } from 'react-toastify';
import { toastConfig } from 'assets/data/config';

export const PointsOfContact = () => {
  const navigate = useNavigate();
  const params = useParams();
  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    getValues,
    trigger,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      point_of_contacts: [
        { name: null, email: '', phone: '', position: null, is_primary: false },
      ],
    },
  });

  const reduxDispatch = useDispatch();

  const { id: systemId } = params;

  const { selectedWorkspace } = useSelector((state) => state.prepare.workspace);

  const [contactList, setContactList] = useState([]);
  const [positionList, setPositionList] = useState([]);
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [primaryContactIndex, setPrimaryContactIndex] = useState(0);

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'point_of_contacts',
  });

  const onSubmit = (formData, type = '') => {
    // console.log('formData', formData);
    const submitData = formData?.point_of_contacts?.map((data, index) => ({
      user_id: data?.name?.value,
      position_id: data?.position?.value,
      is_primary: index === primaryContactIndex ? true : false,
      system_id: systemId,
      workspaceId: selectedWorkspace,
    }));

    // console.log({ submitData });
    try {
      reduxDispatch(startLoading());
      Network.post(API.createOrUpdatePointOfContacts, {
        point_of_contacts: submitData,
        system_id: systemId,
        workspaceId: selectedWorkspace,
      })
        .then(() => {
          if (type === 'exit') {
            navigate(`${PreparePath}/dashboard/plan/systems`);
          } else {
            navigate(
              `${PreparePath}/dashboard/plan/systems/${systemId}?page=3`
            );
          }
        })
        .catch(console.error)
        .finally(() => reduxDispatch(endLoading()));
    } catch (error) {
      console.error(error);
      reduxDispatch(endLoading());
    }
  };

  const fetchUserList = () => {
    try {
      reduxDispatch(startLoading());
      Network.get(
        `${API.fetchContacts}?workspaceId=${selectedWorkspace}`
      )
        .then((resp) => {
          setContactList(
            resp?.data?.response?.dataset?.map((contact) => ({
              ...contact,
              value: contact.id,
              label: contact.name?.trim() || contact.email?.trim(),
            })) || []
          );
        })
        .catch(console.error)
        .finally(() => reduxDispatch(endLoading()));
    } catch (error) {
      console.error(error);
      reduxDispatch(endLoading());
    }
  };

  const fetchPositionList = () => {
    try {
      reduxDispatch(startLoading());
      Network.get(
        `${API.positionList}?workspaceId=${selectedWorkspace}`
      )
        .then((resp) => {
          setPositionList(
            resp?.data?.response?.dataset?.map((position) => ({
              value: position.id,
              label: position.title?.trim() || contact.email?.trim(),
            })) || []
          );
        })
        .catch(console.error)
        .finally(() => reduxDispatch(endLoading()));
    } catch (error) {
      console.error(error);
      reduxDispatch(endLoading());
    }
  };

  const fetchPoinitOfContactList = () => {
    try {
      reduxDispatch(startLoading());
      Network.get(
        `${API.fetchPoinitOfContactList}?workspaceId=${selectedWorkspace}&systemId=${systemId}`
      )
        .then((resp) => {
          if (!resp.data.response.dataset?.length) {
            setValue('point_of_contacts', [
              {
                name: null,
                email: '',
                phone: '',
                position: null,
                is_primary: false,
              },
            ]);
          } else {
            const poc = resp.data.response.dataset.map((poc, index) => {
              const contact = contactList.find((cL) => cL.id === poc.user_id);
              const position = positionList.find(
                (pL) => pL.value === poc.position_id
              );

              if (poc.is_primary) {
                setPrimaryContactIndex(index);
              }

              return {
                name: contact,
                position: position,
                is_primary: poc.is_primary,
                email: contact?.email,
                phone: contact?.profile_settings?.mobile_phone_number,
              };
            });
            setValue('point_of_contacts', poc);
            setSelectedContacts(
              resp.data.response.dataset.map((p) => p.user_id)
            );
          }
        })
        .catch(console.error)
        .finally(() => reduxDispatch(endLoading()));
    } catch (error) {
      console.error(error);
      reduxDispatch(endLoading());
    }
  };

  useEffect(() => {
    fetchUserList();
    fetchPositionList();
  }, [selectedWorkspace]);

  useEffect(() => {
    systemId &&
      selectedWorkspace &&
      contactList?.length &&
      positionList?.length &&
      fetchPoinitOfContactList();
  }, [selectedWorkspace, systemId, contactList, positionList]);

  const onSaveAndExitClick = async () => {
    const valid = await trigger();
    if (valid) {
      const data = getValues();
      onSubmit(data, 'exit');
    }
  };

  const onPrevious = () => {
    if (params.id) {
      navigate(`${PreparePath}/dashboard/plan/systems/${params.id}`);
    } else {
      navigate(`${PreparePath}/dashboard/plan/systems`);
    }
  };

  const removePOC = (index) => {
    if (primaryContactIndex === index) {
      toast.error(
        'You can not delete Primary Point(s) of Contact',
        toastConfig
      );
      return;
    }
    if (index < primaryContactIndex) {
      setPrimaryContactIndex((prev) => (prev = prev - 1));
    }
    const deleteData = getValues(`point_of_contacts.${index}`);
    setSelectedContacts((prev) =>
      prev.filter((data) => data != deleteData?.name?.value)
    );
    remove(index);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <h4>Point(s) of Contact</h4>
        <div className="form-block">
          {fields?.map((poc, index) => (
            <div
              className={`row ${(index !== 0 && 'mt-3') || ''}`}
              key={poc?.id}
            >
              <div className="col-xl-9">
                <div className="row">
                  <div className="col-md-6 col-xl-3 mb-3 mb-xl-0">
                    <label className="form-label">
                      Select Name
                      <span aria-label="Required field" className="required">
                        *
                      </span>
                    </label>
                    <Controller
                      name={`point_of_contacts.${index}.name`}
                      control={control}
                      rules={{
                        required: true,
                      }}
                      render={({ field: { onChange, value } }) => (
                        <StylishNewSelect
                          // options={sampleName}
                          options={contactList.filter(
                            (contact) =>
                              !selectedContacts.includes(contact.value)
                          )}
                          onChange={(e) => {
                            console.log({ value });
                            setValue(
                              `point_of_contacts.${index}.email`,
                              e.email
                            );
                            setValue(
                              `point_of_contacts.${index}.phone`,
                              e.profile_settings?.mobile_phone_number
                            );
                            setSelectedContacts((prev) => [
                              ...prev?.filter((data) => data !== value?.value),
                              e?.value,
                            ]);
                            onChange(e);
                          }}
                          value={value}
                          isClearable={false}
                          isMulti={false}
                          isSearchable={false}
                        />
                      )}
                    />
                    {(errors?.point_of_contacts &&
                      !!errors?.point_of_contacts?.length &&
                      errors?.point_of_contacts?.[index]?.name?.type ===
                        'required' && (
                        <span className="form-text form-error">
                          This is a required field
                        </span>
                      )) ||
                      null}
                  </div>
                  <div className="col-md-6 col-xl-3 mb-3 mb-xl-0">
                    <label className="form-label">Email</label>
                    <Controller
                      name={`point_of_contacts.${index}.email`}
                      control={control}
                      rules={{
                        required: false,
                      }}
                      render={({ field: { onChange, value } }) => (
                        <StylishNewInput
                          type="text"
                          value={value || ''}
                          onChange={onChange}
                          readOnly
                          disabled
                        />
                      )}
                    />
                  </div>
                  <div className="col-md-6 col-xl-3 mb-3 mb-xl-0">
                    <label className="form-label">Phone</label>
                    <Controller
                      name={`point_of_contacts.${index}.phone`}
                      control={control}
                      rules={{
                        required: false,
                      }}
                      render={({ field: { onChange, value } }) => (
                        <StylishNewInput
                          type="text"
                          value={value || ''}
                          onChange={onChange}
                          readOnly
                          disabled
                        />
                      )}
                    />
                  </div>
                  <div className="col-md-6 col-xl-3 mb-3 mb-xl-0">
                    <label className="form-label">
                      Position
                      <span aria-label="Required field" className="required">
                        *
                      </span>
                    </label>
                    <Controller
                      name={`point_of_contacts.${index}.position`}
                      control={control}
                      rules={{
                        required: true,
                      }}
                      render={({ field: { onChange, value } }) => (
                        <StylishNewSelect
                          options={positionList}
                          value={value}
                          onChange={onChange}
                          isClearable={false}
                          isMulti={false}
                          isSearchable={false}
                        />
                      )}
                    />
                    {(errors?.point_of_contacts &&
                      !!errors?.point_of_contacts?.length &&
                      errors?.point_of_contacts?.[index]?.position?.type ===
                        'required' && (
                        <span className="form-text form-error">
                          This is a required field
                        </span>
                      )) ||
                      null}
                  </div>
                </div>
              </div>
              <div className="col-xl-3">
                <div className="d-flex align-items-end">
                  <div className="flex-shrink-0">
                    <label className="form-label d-block">Primary</label>
                    <div
                      className="d-flex align-items-center"
                      style={{ height: '3rem' }}
                    >
                      <Controller
                        name={`point_of_contacts.${index}.is_primary`}
                        control={control}
                        rules={{
                          required: false,
                        }}
                        render={({ field: { onChange, value } }) => (
                          <StylishSwitcher
                            value={value}
                            onChange={(e) => {
                              if (index !== primaryContactIndex) {
                                setPrimaryContactIndex(index);
                              }
                              onChange(e);
                            }}
                            checked={
                              index === primaryContactIndex ? true : false
                            }
                          />
                        )}
                      />
                    </div>
                  </div>
                  <div className="button-group flex-shrink-0 ms-auto">
                    {(index === fields?.length - 1 && (
                      <StylishNewButton
                        type="button"
                        customButton
                        className="button--icon primary lrg"
                        onClick={() => append({ name: null, position: null })}
                      >
                        <img src={IconAdd} alt="" />
                      </StylishNewButton>
                    )) ||
                      null}
                    {(fields?.length > 1 && (
                      <StylishNewButton
                        type="button"
                        customButton
                        className="button--icon primary lrg"
                        onClick={() => removePOC(index)}
                      >
                        <img src={IconDelete} alt="" />
                      </StylishNewButton>
                    )) ||
                      null}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="button-group d-block d-md-flex mt-4">
          <StylishNewButton
            type={'button'}
            className={'button--primary button--reverse w-100 w-md-auto'}
            onClick={onPrevious}
          >
            Previous
          </StylishNewButton>
          <StylishNewButton
            type="button"
            className={
              'button--tertiary button--reverse w-100 w-md-auto ms-0 ms-md-auto mt-2 mt-md-0'
            }
            onClick={onSaveAndExitClick}
          >
            Save & Exit
          </StylishNewButton>
          <StylishNewButton
            type="submit"
            className={
              'button--primary w-100 w-md-auto ms-0 ms-md-3 mt-2 mt-md-0'
            }
          >
            Next
          </StylishNewButton>
        </div>
      </form>
    </>
  );
};
