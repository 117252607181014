import {
  SET_EXERCISE_DETAILS_ID,
  SET_EXERCISE_DETAILS_NAME,
  SET_EXERCISE_DETAILS_STEP,
} from "./exercise.constants";

export const setExerciseDetailsId = (exerciseDetailsId) => {
  return (dispatch) => {
    dispatch({
      type: SET_EXERCISE_DETAILS_ID,
      payload: exerciseDetailsId,
    });
  };
};
export const setExerciseDetailName = (exerciseDetailsName) => {
  return (dispatch) => {
    dispatch({
      type: SET_EXERCISE_DETAILS_NAME,
      payload: exerciseDetailsName,
    });
  };
};
export const setExerciseDetailsStep = (exerciseDetailsStep) => {
  return (dispatch) => {
    dispatch({
      type: SET_EXERCISE_DETAILS_STEP,
      payload: exerciseDetailsStep,
    });
  };
};
