import React from 'react'

import './MainWrapper.css'

export default function MainWrapper({children}) {
  return (
    <main className="MainWrapper">
      {children}
    </main>
  )

}