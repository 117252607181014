import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Box from '@mui/material/Box';
import Dialog, {
  Header,
  Body,
  Footer,
} from 'components/DesignSystems/dialog/Dialog';
import Title from 'components/DesignSystems/text/Title';
import Info from 'components/DesignSystems/text/Info';
import Button from 'components/DesignSystems/buttons/StylishButton';

import { CloseButton } from 'components/DesignSystems/buttons/CloseButton';

import StylishNewTable from 'components/DesignSystems/New/StylishNewTable';
import StylishNewInput from 'components/DesignSystems/New/StylishNewInput';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';

import HVA from './HVA';

import { fetchHVAs, deleteHVA, cloneHVA } from 'actions/alertActions';

import './HVAs.css';

const columns = [
  {
    dataField: 'name',
    text: 'Name',
    sort: true,
    headerAttrs: { title: 'Sort By:' },
    attrs: { title: 'Name' },
  },
  {
    dataField: 'description',
    text: 'Description',
    sort: true,
    headerAttrs: { title: 'Sort By:' },
    attrs: { title: 'Description' },
  },
];

// Guide the user through the necessary minimum steps required to set up Alerts
export default function HVAs({ onClose }) {
  const reduxDispatch = useDispatch();

  const reduxCurrentlySelectedGroup = useSelector((state) => {
    return state.app.currentlySelectedGroup;
  });

  const reduxHVAs = useSelector((state) => {
    return state.app.HVAs;
  });

  const views = ['Risk Levels', 'Areas of Responsibility (AORs)'];

  const [selectedHVA, setSelectedHVA] = useState();
  const [manageHVADialogOpen, setManageHVADialogOpen] = useState(false);
  const [hvaToClone, setHVAToClone] = useState();
  const [hvaToCloneName, setHVAToCloneName] = useState('');

  function deleteHVAClicked(hva) {
    if (window.confirm('Delete this HVA?  This action cannot be undone.')) {
      reduxDispatch(deleteHVA(hva.id));
    }
  }

  useEffect(() => {
    if (
      !!reduxCurrentlySelectedGroup &&
      !!reduxCurrentlySelectedGroup.group_guid
    ) {
      reduxDispatch(fetchHVAs(reduxCurrentlySelectedGroup.group_guid));
    }
  }, [reduxCurrentlySelectedGroup]);

  const expandRow = {
    parentClassName: 'parent-expand-tr',
    className: 'child-expand-tr',
    renderer: (row) => {
      return (
        <div className="HVA-Row-wrap">
          <div className="HVA-Row">
            <div>
              <StylishNewButton
                className="HVA-action-button button--primary"
                onClick={() => {
                  setSelectedHVA(reduxHVAs.find((hva) => hva.id === row.id));
                  setManageHVADialogOpen(true);
                }}
              >
                Edit
              </StylishNewButton>
            </div>
            <div>
              <StylishNewButton
                className="HVA-action-button button--primary"
                onClick={() => deleteHVAClicked(row)}
              >
                Delete
              </StylishNewButton>
            </div>
            <div>
              {!hvaToClone && (
                <StylishNewButton
                  className="HVA-action-button button--primary"
                  onClick={() => setHVAToClone(row)}
                >
                  Clone
                </StylishNewButton>
              )}
            </div>
          </div>
          {!!hvaToClone && hvaToClone.id === row.id && (
            <div>
              <Info>Clone Name</Info>
              <StylishNewInput
                value={hvaToCloneName}
                onChange={(e) => setHVAToCloneName(e.target.value)}
              />
              <StylishNewButton
                className="HVA-action-button button--primary"
                onClick={() =>
                  reduxDispatch(cloneHVA({ ...row, name: hvaToCloneName }))
                }
              >
                Save Clone
              </StylishNewButton>
              <StylishNewButton
                className="HVA-action-button button--primary"
                onClick={() => setHVAToClone()}
              >
                Cancel
              </StylishNewButton>
            </div>
          )}
        </div>
      );
    },
    showExpandColumn: true,
    expandByColumnOnly: true,
    expandHeaderColumnRenderer: ({ isAnyExpands }) => {
      if (isAnyExpands) {
        return <i className="fa fa-chevron-down" aria-hidden="true"></i>;
      }
      return <i className="fa fa-chevron-right" aria-hidden="true"></i>;
    },
    expandColumnRenderer: ({ expanded }) => {
      if (expanded) {
        return (
          <>
            <div className="d-flex align-items-center justify-content-md-center">
              <span className="me-2">Details</span>
              <i className="fa fa-chevron-down" aria-hidden="true"></i>
            </div>
          </>
        );
      }
      return (
        <>
          <div className="d-flex align-items-center justify-content-md-center">
            <span className="me-2">Details</span>
            <i className="fa fa-chevron-right" aria-hidden="true"></i>
          </div>
        </>
      );
    },
  };

  return (
    <div className="HVAModal">
      <Title>Hazard Vulnerability Assessments</Title>
      <div>
        <StylishNewButton
          className={'button--primary HVA-new-button'}
          onClick={() => setManageHVADialogOpen(true)}
        >
          New HVA
        </StylishNewButton>
      </div>
      <StylishNewTable
        keyField={'id'}
        columns={columns}
        rows={reduxHVAs}
        expandRow={expandRow}
      />
      {!!manageHVADialogOpen && (
        <HVA
          onClose={() => {
            setManageHVADialogOpen(false);
            setSelectedHVA();
          }}
          hva={selectedHVA}
        />
      )}
    </div>
  );
}
