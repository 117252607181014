import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Network from '../../../../service/Network';
import API from '../../../../service/api';
import { toast } from 'react-toastify';
import { AllIcon } from '../../../Settings/InjectMethod/Icon';
import { HelpParticipantDetailsandTrainingObjectives, useSelfHostedKnowledgeBase } from 'assets/data/HelpContent';
import TrainingObjectiveDashboard from '../TrainingObjective/TrainingObjectiveDashboard';
import { PreparePath } from 'utils/sharedUtils/sharedConst';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import StylishNewTable from 'components/DesignSystems/New/StylishNewTable';
import StylishHelp from 'components/DesignSystems/New/StylishHelp';
import { toastConfig } from 'assets/data/config';
import {
  endLoading,
  startLoading,
} from '../../../../../reducers/loading/loading.action';
import { SharedIcon } from 'components/SharedIcon/SharedIcon';

export const ParticipantDetail = () => {
  const [showParticipantDetail, setShowParticipantDetail] = useState({});
  const [showTotalFunding, setShowTotalFunding] = useState(0);

  const seflHostedKnowledgeBase = useSelfHostedKnowledgeBase()

  const navigate = useNavigate();

  const { selectedWorkspace } = useSelector((state) => state.prepare.workspace);
  const reduxUser = useSelector((state) => state.prepare.user);

  const dispatch = useDispatch();

  const params = useParams();

  const fetchSingleParticipantsDetails = async () => {
    dispatch(startLoading());
    try {
      const Participant = await Network.get(
        API.fetchSingleParticipantsDetails,
        {
          workspaceId: selectedWorkspace,
          id: params.id,
        }
      );
      setShowParticipantDetail(Participant.data.response.dataset[0]);
      setShowTotalFunding(Participant.data.response.total_funding);
    } catch (error) {
      toast.error(error?.response?.data?.response?.status?.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };
  useEffect(() => {
    fetchSingleParticipantsDetails();
  }, [reduxUser]);

  const platforms_columns = [
    {
      dataField: 'platform_title',
      text: 'Platform Title',
      sort: false,
      headerAttrs: { title: 'Sort By:' },
      attrs: { title: 'Platform Title' },
    },
    {
      dataField: 'platform_type',
      text: 'Platform Type',
      sort: false,
      attrs: { title: 'Platform Type' },
    },
    {
      dataField: 'bed_down',
      text: 'Bed-down',
      sort: false,
      attrs: { title: 'Bed-down' },
    },
  ];

  const members_columns = [
    { dataField: 'name', text: 'Name', attrs: { title: 'Name' } },
    { dataField: 'email', text: 'Email', attrs: { title: 'Email' } },
    {
      dataField: 'phone_number',
      text: 'Phone Number',
      attrs: { title: 'Phone No.' },
    },
    {
      dataField: 'primary_contact',
      text: 'Primary Contact',
      attrs: { title: 'Primary Contact' },
    },
    {
      dataField: 'position',
      text: 'Position',
      attrs: { title: 'Position' },
    },
  ];

  const funding_columns = [
    {
      dataField: 'primary_source',
      text: 'Primary Source',
      sort: false,
      headerAttrs: { title: 'Sort By:' },

      attrs: { title: 'Primary Source' },
    },
    {
      dataField: 'sub_source',
      text: 'Sub-source',
      sort: false,
      attrs: { title: 'Sub-source' },
    },
    {
      dataField: 'fundingSourceAmount',
      text: 'Amount',
      sort: false,

      attrs: { title: 'Amount' },
    },
  ];

  const createTO = async (data) => {
    dispatch(startLoading());
    try {
      Network.post(API.addTrainingObjective, {
        participantId: params.id,
        workspaceId: selectedWorkspace,
      }).then((resp) => {
        toast.success(' Objective added successfully', toastConfig);
        sessionStorage.setItem(
          'participantDetails',
          JSON.stringify({
            participantId: resp.data.response.dataset[0].participantId,
            id: resp.data.response.dataset[0].id,
            participantType: showParticipantDetail.type,
            participantName: showParticipantDetail.name,
          })
        );
        navigate(
          `${PreparePath}/dashboard/plan/prepare/training-objective/all-details`,
          {
            state: {
              participantId: resp.data.response.dataset[0].participantId,
              id: resp.data.response.dataset[0].id,
              participantType: showParticipantDetail.type,
              participantName: showParticipantDetail.name,
            },
          }
        );
      });
    } catch (error) {
      toast.error(error.response.data.response.status.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };

  const totalFunding = (fundings) => {
    let fundTotal = 0;
    fundings.map((fund) => fund.amount && (fundTotal += Number(fund.amount)));

    return fundTotal;
  };
  return (
    <>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <StylishNewButton onClick={() => navigate(-1)}>
              <SharedIcon iconName="keyboard_arrow_left" classes="me-2" />
              Back
            </StylishNewButton>
          </li>
          <li className="breadcrumb-item">
            <Link to={`${PreparePath}/dashboard/plan/prepare/participants`}>
              Participants
            </Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            {showParticipantDetail.name}
          </li>
        </ol>
      </nav>
      <div className="d-block d-md-flex align-items-center mb-4">
        <h4 className="color-brand mb-3 mb-md-0 me-md-4 d-flex align-items-center">
          {showParticipantDetail.icon && (
            <span className="wrap-img-h-32 flex-shrink-0 me-3">
              {
                AllIcon.find((icon) => icon.name === showParticipantDetail.icon)
                  .icon
              }
            </span>
          )}
          {showParticipantDetail.name}
        </h4>
        <div className="d-flex ms-auto align-items-center">
          <StylishNewButton
            className="button button--primary px-4 flex-grow-1"
            onClick={() =>
              navigate(
                `${PreparePath}/dashboard/plan/prepare/participants/${showParticipantDetail.id}`
              )
            }
          >
            <SharedIcon iconName="stylus" classes="me-2" />
            Edit Participant
          </StylishNewButton>
          <StylishHelp
            classes={'ms-3 flex-shrink-0'}
            title={HelpParticipantDetailsandTrainingObjectives.title}
            content={HelpParticipantDetailsandTrainingObjectives.content}
            link={seflHostedKnowledgeBase || HelpParticipantDetailsandTrainingObjectives.link}
          />
        </div>
      </div>
      <div className="form-block">
        <div className="row">
          <div className="col-md-3 mb-4 mb-md-0">
            <div className="mb-3">
              {showParticipantDetail.type === 'DOD' ? (
                <label className="form-label d-block mb-0">
                  Organization Type
                </label>
              ) : (
                <label className="form-label d-block mb-0">
                  ESF / Organization Type
                </label>
              )}
              {showParticipantDetail.participantTypeTitle}
            </div>
            <div className="mb-3">
              {showParticipantDetail.type === 'DOD' ? (
                <label className="form-label d-block mb-0">
                  Service/Department Type
                </label>
              ) : (
                <label className="form-label d-block mb-0">
                  Jurisdiction/Service/Department Types
                </label>
              )}
              {showParticipantDetail.departmentTypeTitle}
            </div>
            <div className="mb-3">
              {showParticipantDetail.type === 'DOD' ? (
                <label className="form-label d-block mb-0">
                  Service/Department
                </label>
              ) : (
                <label className="form-label d-block mb-0">
                  City/Town/Service/Department
                </label>
              )}
              {showParticipantDetail.departmentTitle}
            </div>
            <div className="mb-3">
              {showParticipantDetail.type === 'DOD' ? (
                <label className="form-label d-block mb-0">
                  Command/Agency
                </label>
              ) : (
                <label className="form-label d-block mb-0">
                  State/County/Chapter/Command/Agency
                </label>
              )}
              {showParticipantDetail.commandAgencyTitle}
            </div>
            <div className="mb-3">
              {showParticipantDetail.type === 'DOD' ? (
                <label className="form-label d-block mb-0">Home Station</label>
              ) : (
                <label className="form-label d-block mb-0">
                  Office Location
                </label>
              )}
              {showParticipantDetail.homeStation}
            </div>
            <div className="mb-0">
              <label className="form-label d-block mb-0">Total Audience</label>
              {showParticipantDetail.totalAudience}
            </div>
          </div>
          <div className="col-md-9">
            <div className="mb-3">
              <label className="form-label d-block mb-0">
                Participant Platforms: {showParticipantDetail?.platform?.length}
              </label>
            </div>
            <div className="mb-4 mb-md-5">
              <StylishNewTable
                classes="no-action"
                keyField={'platformId'}
                columns={platforms_columns}
                rows={
                  showParticipantDetail?.platform?.map((pData) => ({
                    id: pData.platformId,
                    platform_title: pData.platformTitle,
                    platform_type: pData.platformType,
                    bed_down: pData.platformBeddown,
                  })) || []
                }
              />
            </div>
            <div className="mb-3">
              <label className="form-label d-block mb-0">
                Participant Members: {showParticipantDetail?.planner?.length}
              </label>
            </div>
            <div className="mb-4 mb-md-5">
              <StylishNewTable
                keyField={'id'}
                columns={members_columns}
                rows={
                  showParticipantDetail?.planner?.map((pData) => ({
                    id: pData.plannerId,
                    name: pData.plannerName,
                    email: pData.email,
                    phone_number: pData.phnNumber,
                    primary_contact: pData.isPrimary ? 'Yes' : 'No',
                    position: pData.position,
                  })) || []
                }
                classes="no-filter"
                headerClasses="empty"
              />
            </div>
            <div className="mb-3">
              <label className="form-label d-block mb-0">
                Participant Funding: $ {showTotalFunding}
              </label>
            </div>
            <div>
              <StylishNewTable
                classes="no-action"
                keyField={'ppfsId'}
                columns={funding_columns}
                rows={
                  showParticipantDetail?.funding?.map((fData) => ({
                    id: fData.ppfsId,
                    primary_source: fData.primarysource,
                    sub_source: fData.subsource,

                    fundingSourceAmount: `$ ${fData?.fundingSourceAmount?.toLocaleString()}`,

                    amount: `$ ${fData?.amount?.toLocaleString()}`,
                  })) || []
                }
              />
            </div>
          </div>
        </div>
      </div>

      {showParticipantDetail?.type ? (
        <TrainingObjectiveDashboard
          participantId={params.id}
          createTO={createTO}
          participantType={showParticipantDetail?.type}
        />
      ) : null}
    </>
  );
};
