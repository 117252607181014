import React from 'react';
import { Modal } from 'react-bootstrap';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { PreparePath } from 'utils/sharedUtils/sharedConst';
export default function ViewPlanMeetingDialog({
  onClose,
  show,
  selectedIncidentMember,
  start,
  end,
  // setSelectedIncidentMember,
  // existingEvent,
}) {
  const { timezone } = useSelector((state) => state.prepare.workspace);
  const navigate = useNavigate();

  const startDate = moment(selectedIncidentMember?.start).format('DD MMM YYYY');
  const endDate = moment(selectedIncidentMember?.end).format('DD MMM YYYY');

  return (
    <>
      <Modal
        show={show}
        onHide={onClose}
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton closeVariant="white">
          {show?._def?.title.includes('Exercise Details') ? (
            <Modal.Title>Exercise Details</Modal.Title>
          ) : (
            <Modal.Title>Planning Meeting</Modal.Title>
          )}
        </Modal.Header>
        <Modal.Body>
          <h5 className="mb-1">{selectedIncidentMember?.title}</h5>
          {show?._def?.title.includes('Exercise Details') ? (
            <p className="m-0">
              {start}
              {timezone.split('/')[0]} - {end}
              {timezone.split('/')[0]}
            </p>
          ) : (
            <p className="m-0">
              {startDate}
              {timezone.split('/')[0]} - {endDate}
              {timezone.split('/')[0]}
            </p>
          )}
        </Modal.Body>

        <Modal.Footer>
          <div className="button-group">
            <button
              className="button button--secondary button--reverse"
              onClick={onClose}
            >
              Close
            </button>
            <button
              className="button button--primary"
              type="button"
              onClick={() => {
                if (
                  typeof show?._def?.title === 'string' &&
                  show?._def?.title.includes('Exercise Details')
                ) {
                  navigate(
                    `${PreparePath}/dashboard/plan/prepare/all-details`,
                    {
                      state: { step: 1 },
                    }
                  );
                } else {
                  navigate(
                    `${PreparePath}/dashboard/plan/prepare/all-details`,
                    {
                      state: { step: 2 },
                    }
                  );
                }
              }}
            >
              Edit
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}
