import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { AppHeader } from '../headers/appHeader/AppHeader';
import { AppList } from '../Dashboard/DashboardConstant';
import { Link } from 'react-router-dom';
import Arrow from '../../assets/images/icon__arrow--right.svg';
import { useNewUIFlag } from '../../utils/featureFlags';

export const DiceDashboard = () => {
  const newUI = useNewUIFlag();
  const [appList, setAppList] = useState([]);
  const featureFlags = useSelector((state) => {
    return state.app.featureFlags;
  });

  const { selectedWorkspace } = useSelector((state) => {
    return state?.prepare?.workspace;
  });

  const userRoles = useSelector((state) => {
    return state.prepare.user.roles;
  });

  useEffect(() => {

    // now we'll handle restrictions
    let restrictedAppList = AppList.filter((app) => {
      return !!app.featureFlagRestricted ? app.featureFlagRestricted.filter(item => !featureFlags.includes(item)).length : true
    })

    setAppList(restrictedAppList);
  }, [featureFlags]);

  return (
    <>
      {!newUI ? <AppHeader context="Dashboard" /> : null}
      <div className="site-main">
        <div className="site-main__dashboard">
          <div className="container">
            <div className="row">
              {appList.map((item) => (
                <div className="col-md-4 col-xl-3" key={item.id}>
                  <div
                    className={`block logo ${item.className} ${
                      !!item.link.startsWith('/prepare') &&
                      (!selectedWorkspace || !userRoles?.length)
                        ? 'disabled'
                        : ''
                    }`}
                  >
                    <div className="icon mb-3">
                      <img src={item.logo} alt="" />
                    </div>
                    <h4 className="mb-2">{item.title}</h4>
                    <p className="m-0">{item.intro}</p>
                    <Link to={item.link} className="more">
                      <img src={Arrow} alt="" />
                    </Link>
                    <Link to={item.link} className="stretched-link" />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
