import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import Network from '../../../../service/Network';
import API from '../../../../service/api';
import { toast } from 'react-toastify';
import StylishNewSelect from 'components/DesignSystems/New/StylishNewSelect';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { PreparePath } from 'utils/sharedUtils/sharedConst';
import { toastConfig } from 'assets/data/config';
import { endLoading, startLoading } from "../../../../../reducers/loading/loading.action";

export default function TrainingObjectiveCreateDialog({ onClose, show }) {
  const navigate = useNavigate();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const [allParticipants, setAllParticipants] = useState([]);
  const [participantType, setParticipantType] = useState('');
  const [participantName, setParticipantName] = useState('');

  const dispatch = useDispatch();

  const reduxUser = useSelector((state) => state.prepare.user);
  const { selectedWorkspace } = useSelector((state) => state.prepare.workspace);

  const onSubmit = async (data) => {
    dispatch(startLoading());
    try {
      data.participants.value &&
        Network.post(API.addTrainingObjective, {
          participantId: data.participants.value,
          workspaceId: selectedWorkspace,
        }).then((resp) => {
          toast.success(' Objective added successfully', toastConfig);
          sessionStorage.setItem(
            'participantDetails',
            JSON.stringify({
              participantId: resp?.data?.response?.dataset[0]?.participantId,
              id: resp.data.response.dataset[0].id,
              participantType: participantType,
              participantName: participantName,
            })
          );
          navigate(
            `${PreparePath}/dashboard/plan/prepare/training-objective/all-details`,
            {
              state: {
                participantId: resp?.data?.response?.dataset[0]?.participantId,
                id: resp.data.response.dataset[0].id,
                participantType: participantType,
                participantName: participantName,
              },
            }
          );
        });
    } catch (error) {
      toast.error(error.response.data.response.status.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };

  const getParticipants = async () => {
    dispatch(startLoading());
    try {
      Network.get(API.fetchParticipantListForTo, {
        workspaceId: selectedWorkspace,
      }).then((resp) => {
        setAllParticipants(
          resp.data.response.dataset.map((e) => {
            return {
              value: e.id,
              label: e.name,
              type: e.type,
            };
          })
        );
      });
    } catch (error) {
      toast.error(error?.response?.data?.response?.status?.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };

  useEffect(() => {
    if (show) {
      getParticipants();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show, selectedWorkspace, reduxUser]);

  useEffect(() => {
    const listener = (event) => {
      if (
        (event.code === 'Enter' || event.code === 'NumpadEnter') &&
        !event.defaultPrevented
      ) {
        event.preventDefault();
        handleSubmit(onSubmit)();
      }
    };

    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, [handleSubmit, onSubmit]);

  return (
    <Modal
      show={show}
      onHide={onClose}
      centered
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title>Create Objective</Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <label className="form-label d-block">
            Choose Participant to add this Objective
          </label>
          <Controller
            control={control}
            name="participants"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <StylishNewSelect
                options={allParticipants}
                selected={value}
                onChange={(e) => {
                  const eventType = e?.type || '';
                  const eventLabel = e?.label || '';
                  setParticipantType(eventType);
                  setParticipantName(eventLabel);
                  return onChange(e);
                }}
                placeholder={'Select Participant'}
                isClearable={true}
                isSearchable={true}
              />
            )}
          />
          {errors.participants && (
            <span className="form-text form-error">
              This is a required field
            </span>
          )}
        </Modal.Body>
        <Modal.Footer>
          <div className="button-group">
            <StylishNewButton
              className="button button--secondary button--reverse"
              type="button"
              onClick={onClose}
            >
              Close
            </StylishNewButton>
            <StylishNewButton className="button button--primary" type="submit">
              Save &amp; Create
            </StylishNewButton>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
