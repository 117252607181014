import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { AllIcon } from '../../../Settings/InjectMethod/Icon';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import Network from '../../../../service/Network';
import API from '../../../../service/api';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import ConfirmModal from '../../../../component/ConfirmModal/ConfirmModal';
import { startInjectRefresh } from '../../../../store/injectReducer/inject.action';
import { toast } from 'react-toastify';
import { inputStringLength, toastConfig } from 'assets/data/config';
import { useForm } from 'react-hook-form';
import fileDownload from 'js-file-download';
import StylishNewSelect from 'components/DesignSystems/New/StylishNewSelect';
import StylishNewTable from 'components/DesignSystems/New/StylishNewTable';
import StylishNewInput from 'components/DesignSystems/New/StylishNewInput';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { PreparePath } from 'utils/sharedUtils/sharedConst';
import TinyMCEWithFormController from 'components/DesignSystems/New/TinyMCEWithFormController';
import { SharedIcon } from 'components/SharedIcon/SharedIcon';
import { sanitizeHtml } from '../../../../../utils/sanitizeHtml';
import {
  endLoading,
  startLoading,
} from '../../../../../reducers/loading/loading.action';

const getRoutePath = (location, params) => {
  const { pathname } = location;

  if (!Object.keys(params).length) {
    return pathname;
  }

  let path = pathname;
  Object.entries(params).forEach(([paramName, paramValue]) => {
    if (paramValue) {
      path = path.replace(paramValue, `:${paramName}`);
    }
  });
  return path;
};

const EditMSELTimeline = ({
  onClose,
  show,
  viewId,
  onStatusUpdate,
  onDeleteSuccess,
}) => {
  const [key, setKey] = useState('inject_details');
  const [nonExpandableRows, setNonExpandableRows] = useState([]);
  const [inject, setInject] = useState();
  const [selectedStatus, setSelectedStatus] = useState();
  const [allInjectObjective, setAllInjectObjective] = useState([]);
  const [totalInjectObjective, setTotalInjectObjective] = useState(0);
  const [activityLog, setActivityLog] = useState([]);
  const [injectStatus, setInjectStatus] = useState([]);
  const [injectObjectivePage, setInjectObjectivePage] = useState(1);
  const [deleteInject, setDeleteInject] = useState(null);

  const { selectedWorkspace } = useSelector((state) => state.prepare.workspace);
  const workspaceType = useSelector((state) => state.prepare.workspace.type);
  const reduxTimezone = useSelector(
    (state) => state.prepare.workspace.timezone
  );
  const reduxUser = useSelector((state) => state.prepare.user);

  const location = useLocation();
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const path = getRoutePath(location, params);

  const typeList = [
    { value: 'key', label: 'Key' },
    { value: 'enabling', label: 'Enabling' },
    { value: 'supporting', label: 'Supporting' },
  ];

  const {
    reset,
    handleSubmit,
    formState: { errors },
    setError,
    getValues,
    clearErrors,
    control,
  } = useForm();

  const currentPage =
    path === '/dashboard/plan/prepare/msel-all-details/' + viewId
      ? 'Inject Details'
      : null;

  useEffect(() => {
    getInjectDetails();
    getActivityLogList();
    getAllInjectStatus();
  }, [viewId]);

  useEffect(() => {
    getAllInjectObjective();
  }, [injectObjectivePage]);

  const getInjectDetails = async () => {
    dispatch(startLoading());
    const injectDetails = await Network.get(API.fetchSingleInject, {
      id: viewId,
    });
    setSelectedStatus({
      value: {
        name: injectDetails.data.response.dataset[0].statusId,
        color: injectDetails.data.response.dataset[0].statusColor,
      },
      label: (
        <>
          <span
            style={{
              backgroundColor:
                injectDetails.data.response.dataset[0].statusColor,
              height: '20px',
              width: '20px',
              borderRadius: '4px',
            }}
            className="me-2"
          ></span>
          {injectDetails.data.response.dataset[0].statusLabel}
        </>
      ),
    });
    let selectType = typeList.filter(
      (tl) => tl.value === injectDetails.data.response.dataset[0].type
    );
    console.log(selectType);
    const injects = {
      ...injectDetails.data.response.dataset[0],
      type: selectType[0]?.label,
    };
    setInject(injects);

    setAllInjectObjective(
      injectDetails.data.response.dataset[0].trainingobjective
    );
    setTotalInjectObjective(
      injectDetails.data.response.dataset[0].trainingobjective.length
    );

    dispatch(endLoading());
  };

  const getAllInjectObjective = () => {
    dispatch(startLoading());
    try {
      Network.get(API.fetchAllInjectObjective, {
        workspaceId: selectedWorkspace,
        injectId: viewId,
        page: injectObjectivePage,
        term: '',
        limit: 10,
        sort: false,
      })
        .then((resp) => {
          // setTotalInjectObjective(
          //   resp.data.response.trainingObjectiveAssociatedTotal
          // );
          // setAllInjectObjective(
          //   resp.data.response.trainingObjectiveAssociated
          // );
        })
        .catch(console.log)
        .finally(() => {
          dispatch(endLoading());
        });
    } catch (error) {
      console.log(error);
    }
  };

  const getActivityLogList = async () => {
    dispatch(startLoading());
    const activityLog = await Network.get(API.fetchActivityLog, {
      injectId: viewId,
    });
    const nonExpandable = [];
    const logs = activityLog.data.response.dataset.map((log) => {
      if (log.type === 'comment') {
        log.type = 'Comment';
      }
      if (log.type === 'updatePlanInject') {
        log.type = 'Update plan inject';
        nonExpandable.push(log.logid);
      }
      return {
        ...log,
        createdAt: moment(log.createdAt).fromNow(),
      };
    });
    setNonExpandableRows(nonExpandable);
    setActivityLog(logs);
    dispatch(endLoading());
  };
  const getAllInjectStatus = async () => {
    dispatch(startLoading());
    try {
      const injectStatus = await Network.get(API.planStatusLabelList, {
        workspaceId: selectedWorkspace,
      });
      const newInjectStatus = injectStatus.data.response.dataset.map(
        (status) => {
          return {
            value: { name: status.id, color: status.color },
            label: (
              <>
                <span
                  style={{
                    backgroundColor: status.color,
                    height: '20px',
                    width: '20px',
                    borderRadius: '4px',
                  }}
                  className="me-2"
                ></span>
                {status.title}
              </>
            ),
          };
        }
      );
      setInjectStatus(newInjectStatus);
    } catch (error) {
      toast.error(error?.response?.data?.response?.status?.msg, toastConfig);
    } finally {
      // dispatch(endLoading());
    }
  };

  const onUpdateStatus = async (status) => {
    try {
      dispatch(startLoading());
      await Network.post(API.inject, {
        id: viewId,
        statusId: status.value.name,
        workspaceId: selectedWorkspace,
      });
      setSelectedStatus(status);
      toast.success('Status updated successfully', toastConfig);
      dispatch(startInjectRefresh());
      getInjectDetails();
      onStatusUpdate && onStatusUpdate();
    } catch (error) {
      toast.error('Failed to updated successfully', toastConfig);
    } finally {
      !onStatusUpdate && dispatch(endLoading());
    }
  };

  const columns_training_objectives = [
    {
      dataField: 'participantName',
      text: 'Participant',
      sort: true,
      headerAttrs: { title: 'Sort By:' },
      attrs: { title: 'Participant' },
    },
    {
      dataField: 'platformType',
      text: 'Platform Type',
      sort: true,
      attrs: { title: 'Platform Type' },
    },
    {
      dataField: 'jmet',
      text: 'JMET/Objective',
      sort: true,
      attrs: { title: 'JMET/Objective' },
    },
  ];

  console.log('workspaceType', workspaceType);

  const expandRow_training_objectives = {
    parentClassName: 'parent-expand-tr',
    className: 'child-expand-tr',
    renderer: (row, rowIndex) => {
      if (row?.participantType === 'DOD') {
        return (
          <div key={rowIndex}>
            <div className="row mb-4">
              <div className="col-md-6 mb-4">
                <label className="form-label">Task</label>
                <StylishNewInput
                  type="text"
                  value={row?.task || ''}
                  maxLength={inputStringLength}
                  className="form-control"
                  readOnly
                />
              </div>
              <div className="col-md-6 mb-4">
                <label className="form-label">Condition</label>
                <StylishNewInput
                  type="text"
                  value={row?.condition || ''}
                  maxLength={inputStringLength}
                  className="form-control"
                  readOnly
                />
              </div>
              <div className="col-md-12">
                <label className="form-label">Standard</label>
                <StylishNewInput
                  type="text"
                  value={row?.standard || ''}
                  maxLength={inputStringLength}
                  className="form-control"
                  readOnly
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <label className="form-label">Measures</label>
                <p className="m-0">
                  {row?.measure && !!row?.measure?.length
                    ? row?.measure.map((measureData, index) => (
                        <span className="d-block" key={index}>
                          M{index + 1} - {measureData?.measureTitle}
                        </span>
                      ))
                    : '-'}
                </p>
              </div>
              <div className="col-md-6">
                <label className="form-label d-block">Associated Injects</label>
                <p className="m-0">
                  {row?.associatedInjects && !!row?.associatedInjects?.length
                    ? row?.associatedInjects?.map((associateData, index) => (
                        <span
                          key={index}
                          className={`d-block ${index !== 0 ? 'mt-2' : ''}`}
                        >
                          {associateData.number
                            ? `# ${associateData.number} - `
                            : null}
                          {associateData?.title} -{' '}
                          {moment(row?.responsedate).format('DD MMM YY - HHmm')}{' '}
                          ({reduxTimezone.split('/')[0]}){' '}
                          {associateData?.statusTitle ? '- ' : null}
                          {associateData?.statusTitle ? (
                            <span
                              className="status status--xsml"
                              style={{ backgroundColor: associateData?.color }}
                            >
                              {associateData?.statusTitle}
                            </span>
                          ) : null}
                        </span>
                      ))
                    : '-'}
                </p>
              </div>
            </div>
            <hr className="dashed my-4" />
            {workspaceType === 'DOD' ? (
              <div className="row">
                <div className="col-md-6 mb-4">
                  <label className="form-label">
                    Command Training Priorities
                  </label>
                  <p className="m-0">
                    {row?.commandPriorities && !!row?.commandPriorities?.length
                      ? row?.commandPriorities.map((commandData, index) => (
                          <span className="d-block" key={index}>
                            {commandData?.title}
                          </span>
                        ))
                      : '-'}
                  </p>
                </div>
                <div className="col-md-6 mb-4">
                  <label className="form-label">Exercise Objectives</label>
                  <p className="m-0">
                    {row?.exerciseObjectives &&
                    !!row?.exerciseObjectives?.length
                      ? row?.exerciseObjectives.map((objectiveData, index) => (
                          <span className="d-block" key={index}>
                            {objectiveData?.title}
                          </span>
                        ))
                      : '-'}
                  </p>
                </div>
                <div className="col-md-6 mb-4 mb-md-0">
                  <label className="form-label">Strategic Priority</label>
                  <p className="m-0">
                    {row?.trainingPriorities &&
                    !!row?.trainingPriorities?.length
                      ? row?.trainingPriorities.map((trainingData, index) => (
                          <span className="d-block" key={index}>
                            {trainingData?.title}
                          </span>
                        ))
                      : '-'}
                  </p>
                </div>
                <div className="col-md-6">
                  <label className="form-label">Accredited Tasks</label>
                  <p className="m-0">
                    {row?.accrediatedTasks && !!row?.accrediatedTasks?.length
                      ? row?.accrediatedTasks.map((accData, index) => (
                          <span className="d-block" key={index}>
                            {(accData?.levelWar || '') +
                              ' ' +
                              (accData?.taskNumber || '') +
                              ' ' +
                              (accData?.taskTitle || '')}
                          </span>
                        ))
                      : '-'}
                  </p>
                </div>
              </div>
            ) : (
              <div className="row">
                <div className="col-md-4 mb-4 mb-md-0">
                  <label className="form-label">
                    Core Capabilities / Mission Area(s)
                  </label>
                  <p className="m-0">
                    {row?.corecapabilities && !!row?.corecapabilities?.length
                      ? row?.corecapabilities.map((capabilityData, index) => (
                          <span
                            className="d-flex align-items-start"
                            key={index}
                          >
                            <span className="me-2">&#x25cf;</span>
                            {capabilityData?.title}
                          </span>
                        ))
                      : '-'}
                  </p>
                </div>
                <div className="col-md-4 mb-4 mb-md-0">
                  <label className="form-label">
                    Organizational Capability Targets
                  </label>
                  <p className="m-0">
                    {row?.organizationalcapabilitytarget &&
                    !!row?.organizationalcapabilitytarget?.length
                      ? row?.organizationalcapabilitytarget.map(
                          (targetData, index) => (
                            <span
                              className="d-flex align-items-start"
                              key={index}
                            >
                              <span className="me-2">&#x25cf;</span>
                              {targetData?.title}
                            </span>
                          )
                        )
                      : '-'}
                  </p>
                </div>
                <div className="col-md-4">
                  <label className="form-label">Strategic Priorities</label>
                  <p className="m-0">
                    {row?.trainingPriorities &&
                    !!row?.trainingPriorities?.length
                      ? row?.trainingPriorities.map((trainingData, index) => (
                          <span
                            className="d-flex align-items-start"
                            key={index}
                          >
                            <span className="me-2">&#x25cf;</span>
                            {trainingData?.title}
                          </span>
                        ))
                      : '-'}
                  </p>
                </div>
              </div>
            )}
          </div>
        );
      } else if (row?.participantType === 'HSEEP') {
        return (
          <div key={rowIndex}>
            <div className="row mb-md-4">
              <div className="col-md-6 mb-4">
                <label className="form-label">Source</label>
                <StylishNewInput
                  type="text"
                  value={row?.source_title || ''}
                  maxLength={inputStringLength}
                  className="form-control"
                  readOnly
                />
              </div>
              <div className="col-md-6 mb-4">
                <label className="form-label">Reference (Paragraph)</label>
                <StylishNewInput
                  type="text"
                  value={row?.referenceTitle || ''}
                  maxLength={inputStringLength}
                  className="form-control"
                  readOnly
                />
              </div>
              <div className="col-md-6 mb-4 mb-md-0">
                <label className="form-label">
                  Paragraph Title / Description
                </label>
                <StylishNewInput
                  type="text"
                  value={row?.para_title || ''}
                  maxLength={inputStringLength}
                  className="form-control"
                  readOnly
                />
              </div>
              <div className="col-md-6">
                <label className="form-label">Level / Competency</label>
                <StylishNewInput
                  type="text"
                  value={row?.competence_title || ''}
                  maxLength={inputStringLength}
                  className="form-control"
                  readOnly
                />
              </div>
            </div>
            {!!row?.tasks?.length ? <hr className="dashed my-4" /> : null}
            {row?.tasks &&
              row?.tasks.map((taskData, index) => (
                <>
                  <div
                    className={`row ${index > 0 ? 'mb-md-4' : 'mb-md-4'}`}
                    key={index}
                  >
                    <div className="col-md-4 mb-3 mb-md-0">
                      <label className="form-label">
                        Task / Behavior from PTB {index + 1}
                      </label>
                      <StylishNewInput
                        type="text"
                        value={taskData?.taskTitle || ''}
                        maxLength={inputStringLength}
                        className="form-control"
                        readOnly
                      />
                    </div>
                    <div className="col-md-4 mb-3 mb-md-0">
                      <label className="form-label">
                        Standard / Measurement {index + 1}
                      </label>
                      <StylishNewInput
                        type="text"
                        value={taskData?.measureTitle || ''}
                        maxLength={inputStringLength}
                        className="form-control"
                        readOnly
                      />
                    </div>
                    <div className="col-md-4 mb-3 mb-md-0">
                      <label className="form-label">
                        Code / Condition {index + 1}
                      </label>
                      <StylishNewInput
                        type="text"
                        value={taskData?.codeTitle || ''}
                        maxLength={inputStringLength}
                        className="form-control"
                        readOnly
                      />
                    </div>
                  </div>
                </>
              ))}
            <hr className="dashed my-4" />
            <div className="row">
              <div className="col-md-12">
                <label className="form-label">Associated Injects</label>
                <p className="m-0">
                  {row?.associatedInjects &&
                  !!row?.associatedInjects?.length ? (
                    <>
                      {row?.associatedInjects?.map((associateData, index) => (
                        <span
                          key={index}
                          className={`d-block ${index !== 0 ? 'mt-2' : ''}`}
                        >
                          {associateData.number
                            ? `# ${associateData.number} - `
                            : null}
                          {associateData?.title} -{' '}
                          {moment(row?.responsedate).format(
                            reduxUser?.timeFormat === 'hour12Mode'
                              ? 'YYYY-MM-DD - hh:mm a'
                              : 'YYYY-MM-DD - HH:mm'
                          )}{' '}
                          ({reduxTimezone.split('/')[0]}){' '}
                          {associateData?.statusTitle ? '- ' : null}
                          {associateData?.statusTitle ? (
                            <span
                              className="status status--xsml"
                              style={{ backgroundColor: associateData?.color }}
                            >
                              {associateData?.statusTitle}
                            </span>
                          ) : null}
                        </span>
                      ))}
                    </>
                  ) : (
                    '-'
                  )}
                </p>
              </div>
            </div>
            <hr className="dashed my-4" />
            {workspaceType === 'DOD' ? (
              <div className="row">
                <div className="col-md-6 mb-4">
                  <>
                    <label className="form-label d-block">
                      Command Training Priorities
                    </label>
                    <p className="m-0">
                      {row?.commandPriorities &&
                      !!row?.commandPriorities?.length ? (
                        <>
                          {row?.commandPriorities.map((commandData, index) => (
                            <span className="d-block" key={index}>
                              {commandData?.title}
                            </span>
                          ))}
                        </>
                      ) : (
                        '-'
                      )}
                    </p>
                  </>
                </div>
                <div className="col-md-6 mb-4">
                  <>
                    <label className="form-label d-block">
                      Exercise Objectives
                    </label>
                    <p className="m-0">
                      {row?.exerciseObjectives &&
                      !!row?.exerciseObjectives?.length ? (
                        <>
                          {row?.exerciseObjectives.map(
                            (objectiveData, index) => (
                              // console.log("objectiveData", objectiveData)
                              <span className="d-block" key={index}>
                                {objectiveData?.title}
                              </span>
                            )
                          )}
                        </>
                      ) : (
                        '-'
                      )}
                    </p>
                  </>
                </div>
                <div className="col-md-6 mb-4 mb-md-0">
                  <>
                    <label className="form-label d-block">
                      Strategic Priority
                    </label>
                    <p className="m-0">
                      {row?.trainingPriorities &&
                      !!row?.trainingPriorities?.length ? (
                        <>
                          {row?.trainingPriorities.map(
                            (trainingData, index) => (
                              <span className="d-block" key={index}>
                                {trainingData?.title}
                              </span>
                            )
                          )}
                        </>
                      ) : (
                        '-'
                      )}
                    </p>
                  </>
                </div>
                <div className="col-md-6">
                  <>
                    <label className="form-label d-block">
                      Accredited Tasks
                    </label>
                    <p className="m-0">
                      {row?.accrediatedTasks &&
                      !!row?.accrediatedTasks?.length ? (
                        <>
                          {row?.accrediatedTasks.map((accData, index) => (
                            <span className="d-block" key={index}>
                              {(accData?.levelWar || '') +
                                ' ' +
                                (accData?.taskNumber || '') +
                                ' ' +
                                (accData?.taskTitle || '')}
                            </span>
                          ))}
                        </>
                      ) : (
                        '-'
                      )}
                    </p>
                  </>
                </div>
              </div>
            ) : (
              <div className="row">
                <div className="col-md-4 mb-4 mb-md-0">
                  <label className="form-label">
                    Core Capabilities / Mission Area(s)
                  </label>
                  <p className="m-0">
                    {row?.corecapabilities &&
                    !!row?.corecapabilities?.length ? (
                      <>
                        {row?.corecapabilities.map((capabilityData, index) => (
                          <span
                            className="d-flex align-items-start"
                            key={index}
                          >
                            <span className="me-2">&#x25cf;</span>
                            {capabilityData?.title}
                          </span>
                        ))}
                      </>
                    ) : (
                      '-'
                    )}
                  </p>
                </div>

                <div className="col-md-4 mb-4 mb-md-0">
                  <label className="form-label">
                    Organizational Capability Targets
                  </label>
                  <p className="m-0">
                    {row?.organizationalcapabilitytarget &&
                    !!row?.organizationalcapabilitytarget?.length ? (
                      <>
                        {row?.organizationalcapabilitytarget.map(
                          (targetData, index) => (
                            <span
                              className="d-flex align-items-start"
                              key={index}
                            >
                              <span className="me-2">&#x25cf;</span>
                              {targetData?.title}
                            </span>
                          )
                        )}
                      </>
                    ) : (
                      '-'
                    )}
                  </p>
                </div>
                <div className="col-md-4">
                  <label className="form-label">Strategic Priorities</label>
                  <p className="m-0">
                    {row?.trainingPriorities &&
                    !!row?.trainingPriorities?.length ? (
                      <>
                        {row?.trainingPriorities.map((trainingData, index) => (
                          <span
                            className="d-flex align-items-start"
                            key={index}
                          >
                            <span className="me-2">&#x25cf;</span>
                            {trainingData?.title}
                          </span>
                        ))}
                      </>
                    ) : (
                      '-'
                    )}
                  </p>
                </div>
              </div>
            )}
          </div>
        );
      }
    },
    showExpandColumn: true,
    expandByColumnOnly: true,
    expandHeaderColumnRenderer: ({ isAnyExpands }) => {
      if (isAnyExpands) {
        return <i className="fa fa-chevron-down" aria-hidden="true"></i>;
      }
      return <i className="fa fa-chevron-right" aria-hidden="true"></i>;
    },
    expandColumnRenderer: ({ expanded }) => {
      if (expanded) {
        return (
          <>
            <div className="d-flex align-items-center justify-content-md-center">
              <span className="me-2">Details</span>
              <i className="fa fa-chevron-down" aria-hidden="true"></i>
            </div>
          </>
        );
      }
      return (
        <>
          <div className="d-flex align-items-center justify-content-md-center">
            <span className="me-2">Details</span>
            <i className="fa fa-chevron-right" aria-hidden="true"></i>
          </div>
        </>
      );
    },
  };

  const columns_activity_log = [
    {
      text: 'Participant',
      formatter: (cell, row, rowIndex) => {
        return (
          <>
            <div className="d-flex align-items-center">
              <StylishNewButton
                className="button--icon primary weight-700 rounded-circle p-0 me-3"
                style={{
                  pointerEvents: 'none',
                  userSelect: 'none',
                  fontSize: '12px',
                }}
              >
                {row.username &&
                  row.username
                    .match(/(\b\S)?/g)
                    .join('')
                    .match(/(^\S|\S$)?/g)
                    .join('')
                    .toUpperCase()}
              </StylishNewButton>
              {row.username}
            </div>
          </>
        );
      },
      sort: true,
      headerAttrs: { title: 'Sort By:' },
      attrs: { title: 'Participant' },
    },
    {
      dataField: 'type',
      text: 'Type',
      sort: true,
      attrs: { title: 'Type' },
    },
    {
      dataField: 'createdAt',
      text: 'Time',
      sort: true,
      attrs: { title: 'Time' },
    },
  ];

  const onCommentSubmit = async (e) => {
    const data = getValues();
    if (data.comment === undefined || !data.comment || data.comment === '') {
      setError('comment', {
        message: 'This field is required',
        type: 'required',
      });
      return;
    }
    try {
      dispatch(startLoading());
      await Network.post(API.addLogComment, {
        workspaceId: selectedWorkspace,
        injectId: viewId,
        comment: data.comment,
      });
      getActivityLogList();
      clearErrors('comment');
      reset();
    } catch (error) {
      toast.error('Failed to add comment', toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };

  const downloadFile = async (file) => {
    let fullFileName = file.split('/')[file.split('/').length - 1];
    try {
      dispatch(startLoading());
      const downloadFile = await Network.post(API.generateSignedUrl, {
        url: file,
      });
      const res = await fetch(downloadFile.data.response.singedUrl, {
        headers: {},
      });
      fileDownload(
        res.blob(),
        fullFileName.split('-')[fullFileName.split('-').length - 1]
      );
    } catch (error) {
      toast.error('Failed to download file', toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };

  const onDeleteInjects = (viewId) => {
    setDeleteInject(inject?.id);
  };

  const onDeleteConfirm = async () => {
    dispatch(startLoading());
    try {
      await Network.post(API.deleteInject, { id: deleteInject });
      setDeleteInject(null);
      // getAllPlanMeetings();
      toast.success('Inject deleted successfully', toastConfig);
      onStatusUpdate && onStatusUpdate();
      onDeleteSuccess && onDeleteSuccess();
      getAllInjectStatus();
      onClose();
    } catch (error) {
      toast.error(error.response.data.response.status.msg, toastConfig);
    } finally {
      !onStatusUpdate && dispatch(endLoading());
    }
  };

  const expandRow_activity_log = {
    parentClassName: 'parent-expand-tr',
    className: 'child-expand-tr',
    renderer: (row) => {
      const clean = sanitizeHtml(row.content);
      return (
        <>
          <label className="form-label d-block">Comment</label>
          <div
            className="m-0"
            dangerouslySetInnerHTML={{ __html: clean }}
          ></div>
        </>
      );
    },
    showExpandColumn: true,
    expandByColumnOnly: true,
    nonExpandable: nonExpandableRows,
    expandHeaderColumnRenderer: ({ isAnyExpands }) => {
      if (isAnyExpands) {
        return <i className="fa fa-chevron-down" aria-hidden="true"></i>;
      }
      return <i className="fa fa-chevron-right" aria-hidden="true"></i>;
    },
    expandColumnRenderer: ({ expanded, expandable }) => {
      if (expanded) {
        return (
          <>
            <div className="d-flex align-items-center justify-content-md-center">
              <span className="me-2">Details</span>
              <i className="fa fa-chevron-down" aria-hidden="true"></i>
            </div>
          </>
        );
      }
      if (expandable) {
        return (
          <>
            <div className="d-flex align-items-center justify-content-md-center">
              <span className="me-2">Details</span>
              <i className="fa fa-chevron-right" aria-hidden="true"></i>
            </div>
          </>
        );
      }
    },
  };
  useEffect(() => {
    const listener = (event) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        event.preventDefault();
        handleSubmit(onSubmit)();
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, []);

  console.log('allInjectObjective', allInjectObjective);

  return (
    <>
      <Modal
        show={show}
        onHide={onClose}
        centered
        backdrop="static"
        keyboard={false}
        size="xl"
        fullscreen="xl-down"
      >
        <Modal.Header closeButton closeVariant="white">
          <Modal.Title>
            {`#${inject?.number}`} {inject?.injectTitle}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="">
          <div className="d-flex align-items-md-start">
            <div className="flex-grow-1 pe-4 row">
              <div className="col-md-4 mb-4">
                <label className="form-label">Created At</label>
                <p className="m-0">
                  {inject?.startDate
                    ? moment(inject?.startDate).format('MMM Do YYYY')
                    : '-'}
                </p>
              </div>
              <div className="col-md-4 mb-4">
                <label className="form-label">Last Updated</label>
                <p className="m-0">
                  {inject?.updatedAt
                    ? moment(inject?.updatedAt).fromNow()
                    : '-'}
                </p>
              </div>
              <div className="col-md-4 mb-4">
                <label className="form-label">Type</label>
                <p className="m-0">{inject?.type ? inject?.type : '-'}</p>
              </div>
              <div className="col-md-4 mb-4 mb-md-0">
                <label className="form-label d-block">Status</label>
                {inject?.statusLabel ? (
                  <span
                    className="status status--sml"
                    style={{ background: inject?.statusColor }}
                  >
                    {inject?.statusLabel}
                  </span>
                ) : (
                  '-'
                )}
              </div>
              <div className="col-md-4">
                <label className="form-label d-block">Scenario</label>
                {inject?.planEvent ? (
                  <span
                    className="status status--sml"
                    style={{ background: inject?.planEventColor }}
                  >
                    {inject?.planEvent}
                  </span>
                ) : (
                  '-'
                )}
              </div>
            </div>
            <Dropdown className="flex-shrink-0 ms-auto">
              <Dropdown.Toggle className="button--icon">
                <SharedIcon iconName="more_vert" bold />
              </Dropdown.Toggle>
              <Dropdown.Menu align="end">
                <Link
                  className="dropdown-item"
                  to={
                    `${PreparePath}/dashboard/plan/prepare/msel-all-details/` +
                    viewId
                  }
                >
                  <SharedIcon iconName="stylus" />
                  Edit Inject
                </Link>
                <Link
                  className="dropdown-item"
                  to={
                    `${PreparePath}/dashboard/plan/prepare/msel-view/` + viewId
                  }
                >
                  <SharedIcon iconName="visibility" />
                  View Inject
                </Link>
                <Dropdown.Item onClick={onDeleteInjects}>
                  <SharedIcon iconName="delete" />
                  Delete Inject
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <hr className="dashed my-5" />
          <div className="tab-wrapper content-gap-none">
            <Tabs activeKey={key} onSelect={(k) => setKey(k)}>
              <Tab eventKey="inject_details" title="Inject Details">
                <div className="row">
                  <div className="col-md-6 col-xl-3 mb-4 mb-xl-0">
                    <label className="form-label">Owner</label>
                    <p className="m-0">
                      {inject?.injetOwnerTitle ? inject?.injetOwnerTitle : '-'}
                    </p>
                  </div>
                  <div className="col-md-6 col-xl-3 mb-4 mb-xl-0">
                    <label className="form-label">From</label>
                    <p className="m-0">
                      {inject?.from_title ? inject?.from_title : '-'}
                    </p>
                  </div>
                  <div className="col-md-6 col-xl-3 mb-4 mb-md-0">
                    <label className="form-label d-block">To</label>
                    <p className="m-0">
                      {inject?.to_title ? inject?.to_title : '-'}
                    </p>
                  </div>

                  <div className="col-md-6 col-xl-3">
                    <label className="form-label">Inject Method</label>
                    <div className="d-flex align-items-center">
                      {inject?.icon ? (
                        <>
                          <span
                            className="status status--icon sml me-2"
                            style={{ background: inject?.methodColor }}
                          >
                            {
                              AllIcon.find((icon) => icon.name === inject?.icon)
                                ?.icon
                            }
                          </span>
                          {inject?.methodName}
                        </>
                      ) : (
                        '-'
                      )}
                    </div>
                  </div>
                </div>
                <hr className="dashed" />

                <div className="row">
                  <div className="col-md-6 col-xl-4 mb-4">
                    <label className="form-label">Trigger</label>
                    <p className="m-0">
                      {inject?.trigger ? inject?.trigger : '-'}
                    </p>
                  </div>

                  <div className="col-md-6 col-xl-4 mb-4">
                    <label className="form-label">
                      Anticipated Response / Expected Player Action
                    </label>
                    <p className="m-0">
                      {inject?.response ? inject?.response : '-'}
                    </p>
                  </div>

                  <div className="col-md-6 col-xl-4 mb-4">
                    <label className="form-label d-block">Attachments</label>
                    {inject?.urls ? (
                      <>
                        {inject?.urls

                          ?.split(',')

                          .filter(
                            (data) =>
                              data != '' && data != 'null' && data != null
                          )

                          .map((urlData) => {
                            let fullFileName = urlData.split('/')[
                              urlData.split('/').length - 1
                            ];

                            return (
                              <span className="d-flex align-items-center mt-2 break-word">
                                {
                                  fullFileName.split('-')[
                                    fullFileName.split('-').length - 1
                                  ]
                                }

                                <span
                                  className="ms-3 cursor-pointer"
                                  onClick={() => downloadFile(urlData)}
                                >
                                  <SharedIcon iconName="download" />
                                </span>
                              </span>
                            );
                          })}
                      </>
                    ) : (
                      '-'
                    )}
                  </div>

                  <div className="col-md-6 col-xl-4 mb-4 mb-xl-0">
                    <label className="form-label">Admin Remarks</label>
                    <p className="m-0">
                      {inject?.remarks ? inject?.remarks : '-'}
                    </p>
                  </div>

                  <div className="col-md-6 col-xl-4">
                    <label className="form-label">Location</label>
                    <p className="m-0">
                      {inject?.loc && inject?.lat && inject?.lng ? (
                        <>
                          {inject?.loc} <br />
                          Lat: {inject?.lat} <br />
                          Lng: {inject?.lng}
                        </>
                      ) : (
                        '-'
                      )}
                    </p>
                  </div>
                </div>
                <hr className="dashed" />

                <div className="row">
                  <div className="col-md-6 mb-4 mb-md-0">
                    <label className="form-label">Response Time</label>
                    <p className="m-0">
                      {inject?.statusLabel === 'Complete'
                        ? inject?.responseDate &&
                          moment(inject?.responseDate).format('MMM Do YYYY')
                        : '-'}
                    </p>
                  </div>

                  <div className="col-md-6">
                    <label className="form-label">Actual Response</label>
                    <p className="m-0">
                      {inject?.statusLabel === 'Complete'
                        ? inject?.actualResponse
                        : '-'}
                    </p>
                  </div>
                </div>
              </Tab>
              <Tab
                eventKey="training_objectives"
                title={` Objectives (${totalInjectObjective})`}
              >
                <StylishNewTable
                  keyField={'trainingObjectiveId'}
                  columns={columns_training_objectives}
                  // rows={""}
                  rows={allInjectObjective.map((data) => ({
                    //Dod
                    participantName: data?.participantName,
                    participantType: data?.participantType,
                    platformType: `${data?.planplatformTitle || ''} ${
                      !!data?.platformType ? ':' : null
                    } ${data?.platformType || ''}`,
                    jmet:
                      data.participantType === 'DOD'
                        ? (data?.jmetLevelWar || '') +
                          ' ' +
                          (data?.jmetTaskNumber || '') +
                          ' ' +
                          (data?.jmetTaskTitle || '') +
                          ' ' +
                          (data?.planplatformTitle || '') +
                          ' ' +
                          (data?.platformType || '')
                        : (data?.exerciseObjectivesHSEEP || '') +
                          ' ' +
                          (data?.planplatformTitle || '') +
                          ' ' +
                          (data?.platformType || ''),

                    requestedMethod: data?.requestedMethod,
                    trainedMethodType: data?.trainedMethodType,
                    color: data?.color,
                    priority_title: data?.priority_title,
                    tasks: data?.tasks,
                    condition: data?.condition,
                    standard: data?.standard,
                    measure: data?.measure,
                    trainingObjectiveId: data?.trainingObjectiveId,
                    exerciseObjectives: data?.exerciseObjectives,
                    task: data?.task,

                    //Hseep
                    participantId: data?.participantId,
                    required_runs: data?.requiredRuns,
                    commandPriorities: data?.commandPriorities,
                    corecapabilities: data?.corecapabilities,
                    organizationalcapabilitytarget:
                      data?.organizationalcapabilitytarget,
                    referenceTitle: data?.reference_title,
                    competence_title: data?.competence_title,
                    source_title: data?.source_title,
                    exerciseObjectivesHSEEP: data?.exerciseObjectivesHSEEP,
                    trainingPriorities: data?.trainingPriorities,
                    accrediatedTasks: data?.accrediatedTasks,
                    associatedInjects: data?.associatedInjects,
                    para_title: data?.para_title,
                    inject_count: data?.injectCount,
                  }))}
                  expandRow={expandRow_training_objectives}
                />
              </Tab>
              <Tab eventKey="activity_log" title="Activity Log">
                <StylishNewTable
                  keyField={'logid'}
                  columns={columns_activity_log}
                  rows={activityLog}
                  expandRow={expandRow_activity_log}
                />
                <h4 className="my-4">Comment</h4>
                <form action="" onSubmit={handleSubmit(onCommentSubmit)}>
                  <div className="mb-3">
                    <TinyMCEWithFormController
                      name="comment"
                      control={control}
                      placeholder="Write a Comment"
                      readOnly={false}
                      rules={{ required: true }}
                    />
                    {errors.comment && (
                      <span className="form-text form-error">
                        This field is required
                      </span>
                    )}
                  </div>
                  <button className="button button--primary" type="submit">
                    Comment
                  </button>
                </form>
              </Tab>
            </Tabs>
          </div>
          {deleteInject && (
            <ConfirmModal
              show={deleteInject}
              onClose={() => setDeleteInject(null)}
              heading={'Remove Inject'}
              text={'Are you sure you want to do this?'}
              onConfirm={onDeleteConfirm}
            />
          )}
        </Modal.Body>
        <Modal.Footer className="d-block">
          <div className="d-md-flex align-items-center text-center">
            {selectedStatus && (
              <StylishNewSelect
                classes="form-selectbox-mselTimelineEdit"
                options={injectStatus}
                onChange={(e) => onUpdateStatus(e)}
                value={
                  !!inject?.statusId &&
                  !!inject?.statusLabel &&
                  !!inject?.statusColor
                    ? selectedStatus
                    : ''
                }
                placeholder={'Select a Status'}
                isClearable={false}
                isSearchable={false}
                isMulti={false}
                isDisabled={false}
                menuPlacement="top"
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    backgroundColor: selectedStatus
                      ? selectedStatus.value.color + '!important'
                      : '',
                    borderColor: selectedStatus
                      ? selectedStatus.value.color + ' !important'
                      : '',
                  }),
                  indicatorSeparator: (baseStyles, state) => ({
                    ...baseStyles,
                    backgroundColor: '#fff !important',
                  }),
                }}
              />
            )}
            <StylishNewButton
              className="button button--primary ms-auto mt-3 mt-md-0 w-100 w-md-auto"
              type="button"
              onClick={() =>
                navigate(
                  `${PreparePath}/dashboard/plan/prepare/msel-all-details/` +
                    viewId
                )
              }
            >
              Edit Inject
            </StylishNewButton>
            <StylishNewButton
              className="button button--primary ms-md-3 mt-3 mt-md-0 w-100 w-md-auto"
              type="button"
              onClick={() =>
                navigate(
                  `${PreparePath}/dashboard/plan/prepare/msel-view/` + viewId
                )
              }
            >
              View Inject
            </StylishNewButton>
            <StylishNewButton
              className="button button--secondary button--reverse ms-md-3 mt-3 mt-md-0 w-100 w-md-auto"
              type="button"
              onClick={() =>
                navigate(
                  `${PreparePath}/dashboard/plan/prepare/msel-all-details/` +
                    viewId,
                  {
                    state: { step: 1 },
                  }
                )
              }
            >
              Edit Response
            </StylishNewButton>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EditMSELTimeline;
