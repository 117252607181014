import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import TrainingObjectiveCreateDialog from './TrainingObjectiveCreateDialog';
import ConfirmModal from '../../../../component/ConfirmModal/ConfirmModal';
import Network from '../../../../service/Network';
import API from '../../../../service/api';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import IconEmpty from 'assets/images/icon__exercise-details-empty.svg';
import moment from 'moment';
import {
  HelpTrainingObjectives,
  MiscellaneousHelpLink,
  useSelfHostedKnowledgeBase,
} from 'assets/data/HelpContent';
import { inputStringLength, toastConfig } from 'assets/data/config';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import StylishNewTableAction from 'components/DesignSystems/New/StylishNewTableAction';
import { PreparePath } from 'utils/sharedUtils/sharedConst';
import StylishNewInput from 'components/DesignSystems/New/StylishNewInput';
import StylishNewSelect from 'components/DesignSystems/New/StylishNewSelect';
import { StylishNewSearchBar } from 'components/DesignSystems/New/StylishNewSearchBar';
import StylishNewTable from 'components/DesignSystems/New/StylishNewTable';
import StylishHelp from 'components/DesignSystems/New/StylishHelp';
import { StylishConfirmDialog } from 'components/DesignSystems/New/StylishConfirmDialog';
import { PaginationDropdown } from '../../../../../components/DesignSystems/PaginationDropdown';
import {
  endLoading,
  startLoading,
} from '../../../../../reducers/loading/loading.action';
import PageTitle from 'components/headers/PageTitle';
import { useNewUIFlag } from 'utils/featureFlags';
import { SharedIcon } from 'components/SharedIcon/SharedIcon';

export default function TrainingObjectiveDashboard(props) {
  const workspaceId = useSelector(
    (state) => state.prepare.workspace.selectedWorkspace
  );
  const workspaceType = useSelector((state) => state.prepare.workspace.type);
  const reduxTimezone = useSelector(
    (state) => state.prepare.workspace.timezone
  );

  const selfHostedKnowledgeBase = useSelfHostedKnowledgeBase()

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const newUI = useNewUIFlag();

  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [perPage, setPerPage] = useState(25);
  const [allTrainingObjectives, setAllTrainingObjectives] = useState([]);
  const [deleteTrainingObjective, setDeleteTrainingObjective] = useState(null);
  const [orderBy, setOrderBy] = useState('');

  const participantOptions = [
    { value: 'DOD', label: 'DOD' },
    { value: 'HSEEP', label: 'HSEEP' },
  ];
  const [participantOption, setParticipantOption] = useState(
    props?.participantType
      ? participantOptions.find((data) => data.value === props.participantType)
      : participantOptions[0]
  );

  const [
    showCreateTrainingObjectiveDialog,
    setShowCreateTrainingObjectiveDialog,
  ] = useState(false);

  const createHandler = () => {
    setShowCreateTrainingObjectiveDialog(true);
  };
  const handlePageClick = (selectedPage) => {
    setPage(selectedPage);
  };

  const dialogCloseHandler = () => {
    setShowCreateTrainingObjectiveDialog(false);
  };

  const columnsDod = [
    {
      dataField: 'priority',
      text: 'Priority',
      // eslint-disable-next-line
      formatter: (cell, row, rowIndex) => {
        return <>{row.priority}</>;
      },
      sort: true,
      headerAttrs: { title: 'Sort By:' },
      attrs: { title: 'Priority' },
      onSort: (field, order) => {
        if (order === 'asc') {
          setOrderBy('priority_title_ASC');
        } else if (order === 'desc') {
          setOrderBy('priority_title_DESC');
        } else {
          setOrderBy('');
        }
      },
    },
    {
      dataField: 'participantName',
      text: 'Participant',
      sort: true,
      attrs: { title: 'Participant' },
      onSort: (field, order) => {
        if (order === 'asc') {
          setOrderBy('participant_name_ASC');
        } else if (order === 'desc') {
          setOrderBy('participant_name_DESC');
        } else {
          setOrderBy('');
        }
      },
    },
    {
      dataField: 'platform_type',
      text: 'Platform Type',
      sort: true,
      attrs: { title: 'Platform Type' },
      onSort: (field, order) => {
        if (order === 'asc') {
          setOrderBy('platformType_ASC');
        } else if (order === 'desc') {
          setOrderBy('platformType_DESC');
        } else {
          setOrderBy('');
        }
      },
    },
    {
      dataField: 'jmet',
      text: 'JMET',
      sort: true,
      attrs: { title: 'JMET' },
      onSort: (field, order) => {
        if (order === 'asc') {
          setOrderBy('taskTitle_ASC');
        } else if (order === 'desc') {
          setOrderBy('taskTitle_DESC');
        } else {
          setOrderBy('');
        }
      },
    },
    {
      dataField: 'requested_method',
      text: 'Requested Method',
      sort: true,
      attrs: { title: 'Requested Method' },
      onSort: (field, order) => {
        if (order === 'asc') {
          setOrderBy('requestedMethod_ASC');
        } else if (order === 'desc') {
          setOrderBy('requestedMethod_DESC');
        } else {
          setOrderBy('');
        }
      },
    },
    {
      dataField: 'required_runs',
      text: 'Required Runs',
      sort: false,
      attrs: { title: 'Required Runs' },
    },
    {
      dataField: 'inject_count',
      text: 'Inject Count',
      sort: true,
      attrs: { title: 'Inject Count' },
    },
    {
      dataField: '',

      text: 'Action',
      isDummyField: true,
      formatter: (cell, row, rowIndex) => (
        <StylishNewTableAction
          name=" Objective"
          cell={cell}
          row={row}
          rowIndex={rowIndex}
          onDelete={onDeleteTrainingObjective}
          onEdit={onEditTrainingObjective}
        />
      ),
      attrs: { title: 'Action' },
      headerStyle: {
        width: '100px',
      },
      headerAlign: 'center',
      align: 'center',
    },
  ];

  const columnsHseep = [
    {
      dataField: 'priority',
      text: 'Priority',
      // eslint-disable-next-line
      formatter: (cell, row, rowIndex) => {
        return <>{row.priority}</>;
      },
      sort: true,
      headerAttrs: { title: 'Sort By:' },
      attrs: { title: 'Priority' },
      onSort: (field, order) => {
        if (order === 'asc') {
          setOrderBy('priority_title_ASC');
        } else if (order === 'desc') {
          setOrderBy('priority_title_DESC');
        } else {
          setOrderBy('');
        }
      },
    },
    {
      dataField: 'participantName',
      text: 'Participant',
      sort: true,
      attrs: { title: 'Participant' },
      onSort: (field, order) => {
        if (order === 'asc') {
          setOrderBy('participant_name_ASC');
        } else if (order === 'desc') {
          setOrderBy('participant_name_DESC');
        } else {
          setOrderBy('');
        }
      },
    },
    {
      dataField: 'platform_type',
      text: 'Platform Type',
      sort: true,
      attrs: { title: 'Platform Type' },
      onSort: (field, order) => {
        if (order === 'asc') {
          setOrderBy('platformType_ASC');
        } else if (order === 'desc') {
          setOrderBy('platformType_DESC');
        } else {
          setOrderBy('');
        }
      },
    },
    {
      dataField: 'exerciseObjectivesHSEEP',
      text: 'Exercise Objective',
      sort: false,
      attrs: { title: 'Exercise Objective' },
    },

    {
      dataField: 'requested_method',
      text: 'Requested Method',
      sort: true,
      attrs: { title: 'Requested Method' },
      onSort: (field, order) => {
        if (order === 'asc') {
          setOrderBy('requestedMethod_ASC');
        } else if (order === 'desc') {
          setOrderBy('requestedMethod_DESC');
        } else {
          setOrderBy('');
        }
      },
    },
    {
      dataField: 'required_runs',
      text: 'Required Runs',
      sort: false,
      attrs: { title: 'Required Runs' },
    },
    {
      dataField: 'inject_count',
      text: 'Inject Count',
      sort: true,
      attrs: { title: 'Inject Count' },
    },
    {
      dataField: '',

      text: 'Action',
      isDummyField: true,
      formatter: (cell, row, rowIndex) => (
        <StylishNewTableAction
          name=" Objective"
          cell={cell}
          row={row}
          rowIndex={rowIndex}
          onDelete={onDeleteTrainingObjective}
          onEdit={onEditTrainingObjective}
        />
      ),
      attrs: { title: 'Action' },
      headerStyle: {
        width: '100px',
      },
      headerAlign: 'center',
      align: 'center',
    },
  ];

  useEffect(() => {
    if (!!workspaceType && workspaceType === 'T&E') {
      navigate(`${PreparePath}/dashboard/plan`);
    }
  }, [workspaceType]);

  const onChangeSearchTerm = (term) => {
    setPage(1);
    setSearchTerm(term);
  };

  const onDeleteTrainingObjective = (row) => {
    setDeleteTrainingObjective(row.id);
  };

  const onDeleteConfirm = async () => {
    dispatch(startLoading());
    try {
      await Network.post(API.deleteTrainingObjective, {
        id: deleteTrainingObjective,
      });
      setDeleteTrainingObjective(null);
      getTrainingObjectives();
      toast.success(' Objective deleted successfully', toastConfig);
    } catch (error) {
      toast.error(error.response.data.response.status.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };

  const onEditTrainingObjective = (row) => {
    sessionStorage.setItem(
      'participantDetails',
      JSON.stringify({
        participantName: row?.participantName,
        participantType: row?.participantType,
        participantId: row?.participantId,
        id: row?.id,
      })
    );
    navigate(
      `${PreparePath}/dashboard/plan/prepare/training-objective/all-details`,
      {
        state: {
          participantName: row?.participantName,
          participantType: row?.participantType,
          participantId: row?.participantId,
          id: row?.id,
        },
      }
    );
  };

  const getTrainingObjectives = async () => {
    dispatch(startLoading());
    try {
      const participantQuery = props?.participantId
        ? { participantId: props?.participantId }
        : {};

      Network.get(API.getAllTrainingObjectives, {
        limit: perPage,
        participantType: props?.participantType
          ? props?.participantType
          : participantOption.value,
        page: page,
        term: searchTerm,
        workspaceId: workspaceId,
        order_by: orderBy,
        ...participantQuery,
      })
        .then((resp) => {
          setTotal(resp.data.response.total);
          if (participantOption.value === 'DOD') {
            setAllTrainingObjectives(
              resp.data.response.dataset[0].trainingObjective.map((e) => {
                return {
                  id: e?.id,
                  participantId: e?.participantId,
                  requested_method: e?.requestedMethod,
                  required_runs: e?.requiredRuns,
                  platform_type: e?.platformType,
                  participantName: e?.participantName,
                  participantType: e?.type,
                  task: e?.task,
                  corecapabilities: e?.corecapabilities,
                  organizationalcapabilitytarget:
                    e?.organizationalcapabilitytarget,
                  commandPriorities: e?.commandPriorities,
                  accrediatedTasks: e?.accrediatedTasks,
                  exerciseObjectivesDOD: e?.exerciseObjectivesDOD,
                  trainingPriorities: e?.trainingPriorities,
                  associatedInjects: e?.associatedInjects,
                  measure: e?.measure,
                  standard: e?.standard,
                  condition: e?.condition,
                  jmet:
                    (e?.levelWar || '') +
                    ' ' +
                    (e?.taskNumber || '') +
                    ' ' +
                    (e?.taskTitle || ''),
                  priority: (
                    <span
                      className="status w-min-auto"
                      style={{ backgroundColor: e?.color }}
                    >
                      {e?.priority_title}
                    </span>
                  ),
                  inject_count: e?.injectCount,
                };
              })
            );
          } else if (participantOption.value === 'HSEEP') {
            setAllTrainingObjectives(
              resp.data.response.dataset[0].trainingObjective.map((e) => {
                return {
                  id: e?.id,
                  participantId: e?.participantId,
                  requested_method: e?.requestedMethod,
                  required_runs: e?.requiredRuns,
                  platform_type: e?.platformType,
                  participantName: e?.participantName,
                  participantType: e?.type,
                  task: e?.task,
                  commandPriorities: e?.commandPriorities,
                  corecapabilities: e?.corecapabilities,
                  organizationalcapabilitytarget:
                    e?.organizationalcapabilitytarget,
                  referenceTitle: e?.reference_title,
                  competence_title: e?.competence_title,
                  source_title: e?.source_title,
                  exerciseObjectivesHSEEP: e?.exerciseObjectivesHSEEP,
                  trainingPriorities: e?.trainingPriorities,
                  accrediatedTasks: e?.accrediatedTasks,
                  exerciseObjectivesDOD: e?.exerciseObjectivesDOD,
                  associatedInjects: e?.associatedInjects,
                  para_title: e?.para_title,
                  tasks: e?.tasks,
                  standard: e?.standard,
                  condition: e?.condition,
                  priority: (
                    <span
                      className="status w-min-auto"
                      style={{ backgroundColor: e?.color }}
                    >
                      {e?.priority_title}
                    </span>
                  ),
                  inject_count: e?.injectCount,
                };
              })
            );
          }
        })
        .catch(console.log)
        .finally(() => {
          dispatch(endLoading());
        });
    } catch (error) {
      toast.error(error?.response?.data?.response?.status?.msg, toastConfig);
    }
  };

  useEffect(() => {
    getTrainingObjectives();
  }, [workspaceId, page, perPage, searchTerm, orderBy, participantOption]);

  useEffect(() => {
    setParticipantOption(
      participantOptions.find((data) => data.value === workspaceType)
    );
  }, [workspaceType]);

  const expandRowDOD = {
    parentClassName: 'parent-expand-tr',
    className: 'child-expand-tr',
    renderer: (row, rowIndex) => {
      return (
        <div key={rowIndex}>
          <div className="row mb-4">
            <div className="col-md-6 mb-3">
              <label className="form-label">Task</label>
              <StylishNewInput
                value={row?.task || ''}
                type="text"
                maxLength={inputStringLength}
                className="form-control"
                readOnly
              />
            </div>
            <div className="col-md-6 mb-3">
              <label className="form-label">Condition</label>
              <StylishNewInput
                value={row?.condition || ''}
                type="text"
                maxLength={inputStringLength}
                className="form-control"
                readOnly
              />
            </div>
            <div className="col-md-12">
              <label className="form-label">Standard</label>
              <StylishNewInput
                value={row?.standard || ''}
                type="text"
                maxLength={inputStringLength}
                className="form-control"
                readOnly
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <label className="form-label d-block">Measures</label>
              <p className="m-0">
                {row?.measure &&
                  row?.measure.map((measureData, index) => (
                    <span className="d-block" key={index}>
                      M{index + 1} - {measureData?.measureTitle}
                    </span>
                  ))}
              </p>
            </div>
            <div className="col-md-6">
              <label className="form-label d-block">Associated Injects</label>
              <p className="m-0">
                {row?.associatedInjects?.map((associateData, index) => (
                  <span key={index} className="d-block">
                    {associateData.number
                      ? `# ${associateData.number} - `
                      : null}
                    {associateData?.title} -{' '}
                    {associateData?.responseDate !== null
                      ? moment(associateData?.responseDate).format(
                          'DD MMM YY - HHmm'
                        )
                      : ''}
                    {associateData?.responseDate !== null
                      ? reduxTimezone.split('/')[0]
                      : ''}
                    {associateData?.statusTitle ? '- ' : null}
                    {associateData?.statusTitle ? (
                      <span
                        className="status status--xsml"
                        style={{ backgroundColor: associateData?.color }}
                      >
                        {associateData?.statusTitle}
                      </span>
                    ) : null}
                  </span>
                ))}
              </p>
            </div>
          </div>
          <hr className="dashed my-4" />
          {workspaceType === 'DOD' ? (
            <div className="row">
              <div className="col-md-6 col-xl-3 mb-3 mb-xl-0">
                <>
                  <label className="form-label d-block">
                    Command Training Priorities
                  </label>
                  <p className="m-0">
                    {row?.commandPriorities.map((commandData, index) => (
                      <span className="d-block" key={index}>
                        {commandData?.title}
                      </span>
                    ))}
                  </p>
                </>
              </div>
              <div className="col-md-6 col-xl-3 mb-3 mb-xl-0">
                <>
                  <label className="form-label d-block">
                    Exercise Objectives
                  </label>
                  <p className="m-0">
                    {row?.exerciseObjectivesDOD &&
                      row?.exerciseObjectivesDOD.map((objectiveData, index) => (
                        <span className="d-block" key={index}>
                          {objectiveData?.title}
                        </span>
                      ))}
                  </p>
                </>
              </div>
              <div className="col-md-6 col-xl-3 mb-3 mb-md-0">
                <>
                  <label className="form-label d-block">
                    Strategic Priority
                  </label>
                  <p className="m-0">
                    {row?.trainingPriorities.map((trainingData, index) => (
                      <span className="d-block" key={index}>
                        {trainingData?.title}
                      </span>
                    ))}
                  </p>
                </>
              </div>
              <div className="col-md-6 col-xl-3">
                <>
                  <label className="form-label d-block">Accredited Tasks</label>
                  <p className="m-0">
                    {row?.accrediatedTasks &&
                      row?.accrediatedTasks.map((accData, index) => (
                        <span className="d-block" key={index}>
                          {(accData?.levelWar || '') +
                            ' ' +
                            (accData?.taskNumber || '') +
                            ' ' +
                            (accData?.taskTitle || '')}
                        </span>
                      ))}
                  </p>
                </>
              </div>
            </div>
          ) : (
            <div className="row">
              <div className="col-md-4 mb-3 mb-md-0">
                <label className="form-label">
                  Core Capabilities / Mission Area(s)
                </label>
                <p className="m-0">
                  {row?.corecapabilities.map((capabilityData, index) => (
                    <span className="d-block" key={index}>
                      {capabilityData?.title}
                    </span>
                  ))}
                </p>
              </div>

              <div className="col-md-4 mb-3 mb-md-0">
                <label className="form-label">
                  Organizational Capability Targets
                </label>
                <p className="m-0">
                  {row?.organizationalcapabilitytarget.map(
                    (targetData, index) => (
                      <span className="d-block" key={index}>
                        {targetData?.title}
                      </span>
                    )
                  )}
                </p>
              </div>
              <div className="col-md-4">
                <label className="form-label">Strategic Priorities</label>
                <p className="m-0">
                  {row?.trainingPriorities.map((trainingData, index) => (
                    <span className="d-block" key={index}>
                      {trainingData?.title}
                    </span>
                  ))}
                </p>
              </div>
            </div>
          )}
          <div className="col-md-12 mt-4">
            <div className="button-group">
              <StylishNewButton
                type="button"
                className="button button--primary"
                onClick={() => onEditTrainingObjective(row)}
              >
                Edit
              </StylishNewButton>
              <StylishNewButton
                type="button"
                className="button button--tertiary button--reverse"
                onClick={() => onDeleteTrainingObjective(row)}
              >
                Delete
              </StylishNewButton>
            </div>
          </div>
          {deleteTrainingObjective && (
            <ConfirmModal
              show={deleteTrainingObjective}
              onClose={() => setDeleteTrainingObjective(null)}
              heading={'Delete  Objective'}
              text={'Are you sure you want this?'}
              onConfirm={onDeleteConfirm}
            />
          )}
        </div>
      );
    },
    showExpandColumn: true,
    expandByColumnOnly: true,
    expandHeaderColumnRenderer: ({ isAnyExpands }) => {
      if (isAnyExpands) {
        return <i className="fa fa-chevron-down" aria-hidden="true"></i>;
      }
      return <i className="fa fa-chevron-right" aria-hidden="true"></i>;
    },
    expandColumnRenderer: ({ expanded }) => {
      if (expanded) {
        return (
          <>
            <div className="d-flex align-items-center justify-content-md-center">
              <span className="me-2">Details</span>
              <i className="fa fa-chevron-down" aria-hidden="true"></i>
            </div>
          </>
        );
      }
      return (
        <>
          <div className="d-flex align-items-center justify-content-md-center">
            <span className="me-2">Details</span>
            <i className="fa fa-chevron-right" aria-hidden="true"></i>
          </div>
        </>
      );
    },
  };

  const expandRowHSEEP = {
    parentClassName: 'parent-expand-tr',
    className: 'child-expand-tr',
    renderer: (row, rowIndex) => {
      return (
        <div key={rowIndex}>
          <div className="row mb-md-4">
            <div className="col-md-6 col-lg-3 mb-3 mb-lg-0">
              <label className="form-label">Source</label>
              <StylishNewInput
                value={row?.source_title || ''}
                type="text"
                maxLength={inputStringLength}
                className="form-control"
                readOnly
              />
            </div>
            <div className="col-md-6 col-lg-3 mb-3 mb-lg-0">
              <label className="form-label">Reference (Paragraph)</label>
              <StylishNewInput
                value={row?.referenceTitle || ''}
                type="text"
                maxLength={inputStringLength}
                className="form-control"
                readOnly
              />
            </div>
            <div className="col-md-6 col-lg-3 mb-3 mb-md-0">
              <label className="form-label">
                Paragraph Title / Description
              </label>
              <StylishNewInput
                value={row?.para_title || ''}
                type="text"
                maxLength={inputStringLength}
                className="form-control"
                readOnly
              />
            </div>
            <div className="col-md-6 col-lg-3 mb-3 mb-md-0">
              <label className="form-label">Level / Competency</label>
              <StylishNewInput
                value={row?.competence_title || ''}
                type="text"
                maxLength={inputStringLength}
                className="form-control"
                readOnly
              />
            </div>
          </div>

          {row?.tasks &&
            row?.tasks.map((taskData, index) => (
              <>
                <div className={`row ${index > 0 ? 'mb-md-4' : 'mb-md-4'}`}>
                  <div className="col-md-4 mb-3 mb-md-0" key={index}>
                    <label className="form-label">
                      Task / Behavior from PTB {index + 1}
                    </label>
                    <StylishNewInput
                      value={taskData?.taskTitle || ''}
                      type="text"
                      maxLength={inputStringLength}
                      className="form-control"
                      readOnly
                    />
                  </div>
                  <div className="col-md-4 mb-3 mb-md-0" key={index}>
                    <label className="form-label">
                      Standard / Measurement {index + 1}
                    </label>
                    <StylishNewInput
                      value={taskData?.measureTitle || ''}
                      type="text"
                      maxLength={inputStringLength}
                      className="form-control"
                      readOnly
                    />
                  </div>
                  <div className="col-md-4 mb-3 mb-md-0" key={index}>
                    <label className="form-label">
                      Code / Condition {index + 1}
                    </label>
                    <StylishNewInput
                      value={taskData?.codeTitle || ''}
                      type="text"
                      maxLength={inputStringLength}
                      className="form-control"
                      readOnly
                    />
                  </div>
                </div>
              </>
            ))}
          <div className="row">
            <div className="col-md-12">
              <label className="form-label">Associated Injects</label>
              <p className="m-0">
                {row?.associatedInjects?.map((associateData, index) => (
                  <span key={index} className="d-block">
                    {associateData.number
                      ? `# ${associateData.number} - `
                      : null}
                    {associateData?.title} -{' '}
                    {associateData?.responseDate !== null
                      ? moment(associateData?.responseDate).format(
                          'DD MMM YY - HHmm'
                        )
                      : ''}
                    {associateData?.responseDate !== null
                      ? reduxTimezone.split('/')[0]
                      : ''}
                    {associateData?.statusTitle ? '- ' : null}
                    {associateData?.statusTitle ? (
                      <span
                        className="status status--xsml"
                        style={{ backgroundColor: associateData?.color }}
                      >
                        {associateData?.statusTitle}
                      </span>
                    ) : null}
                  </span>
                ))}
              </p>
            </div>
          </div>
          <hr className="dashed my-4" />
          {workspaceType === 'DOD' ? (
            <div className="row">
              <div className="col-md-6 col-xl-3 mb-3 mb-xl-0">
                <>
                  <label className="form-label d-block">
                    Command Training Priorities
                  </label>
                  <p className="m-0">
                    {row?.commandPriorities.map((commandData, index) => (
                      <span className="d-block" key={index}>
                        {commandData?.title}
                      </span>
                    ))}
                  </p>
                </>
              </div>
              <div className="col-md-6 col-xl-3 mb-3 mb-xl-0">
                <>
                  <label className="form-label d-block">
                    Exercise Objectives
                  </label>
                  <p className="m-0">
                    {row?.exerciseObjectivesDOD &&
                      row?.exerciseObjectivesDOD.map((objectiveData, index) => (
                        <span className="d-block" key={index}>
                          {objectiveData?.title}
                        </span>
                      ))}
                  </p>
                </>
              </div>
              <div className="col-md-6 col-xl-3 mb-3 mb-md-0">
                <>
                  <label className="form-label d-block">
                    Strategic Priority
                  </label>
                  <p className="m-0">
                    {row?.trainingPriorities.map((trainingData, index) => (
                      <span className="d-block" key={index}>
                        {trainingData?.title}
                      </span>
                    ))}
                  </p>
                </>
              </div>
              <div className="col-md-6 col-xl-3">
                <>
                  <label className="form-label d-block">Accredited Tasks</label>
                  <p className="m-0">
                    {row?.accrediatedTasks &&
                      row?.accrediatedTasks.map((accData, index) => (
                        <span className="d-block" key={index}>
                          {(accData?.levelWar || '') +
                            ' ' +
                            (accData?.taskNumber || '') +
                            ' ' +
                            (accData?.taskTitle || '')}
                        </span>
                      ))}
                  </p>
                </>
              </div>
            </div>
          ) : (
            <div className="row">
              <div className="col-md-4 mb-3 mb-md-0">
                <label className="form-label">
                  Core Capabilities / Mission Area(s)
                </label>
                <p className="m-0">
                  {row?.corecapabilities.map((capabilityData, index) => (
                    <span className="d-block" key={index}>
                      {capabilityData?.title}
                    </span>
                  ))}
                </p>
              </div>

              <div className="col-md-4 mb-3 mb-md-0">
                <label className="form-label">
                  Organizational Capability Targets
                </label>
                <p className="m-0">
                  {row?.organizationalcapabilitytarget.map(
                    (targetData, index) => (
                      <span className="d-block" key={index}>
                        {targetData?.title}
                      </span>
                    )
                  )}
                </p>
              </div>
              <div className="col-md-4">
                <label className="form-label">Strategic Priorities</label>
                <p className="m-0">
                  {row?.trainingPriorities.map((trainingData, index) => (
                    <span className="d-block" key={index}>
                      {trainingData?.title}
                    </span>
                  ))}
                </p>
              </div>
            </div>
          )}

          <div className="button-group mt-4">
            <StylishNewButton
              type="button"
              className="button button--primary"
              onClick={() => onEditTrainingObjective(row)}
            >
              Edit
            </StylishNewButton>
            <StylishNewButton
              type="button"
              className="button button--tertiary button--reverse"
              onClick={() => onDeleteTrainingObjective(row)}
            >
              Delete
            </StylishNewButton>
          </div>

          {deleteTrainingObjective && (
            <ConfirmModal
              show={deleteTrainingObjective}
              onClose={() => setDeleteTrainingObjective(null)}
              heading={'Delete  Objective'}
              text={'Are you sure you want this?'}
              onConfirm={onDeleteConfirm}
            />
          )}
        </div>
      );
    },
    showExpandColumn: true,
    expandByColumnOnly: true,
    expandHeaderColumnRenderer: ({ isAnyExpands }) => {
      if (isAnyExpands) {
        return <i className="fa fa-chevron-down" aria-hidden="true"></i>;
      }
      return <i className="fa fa-chevron-right" aria-hidden="true"></i>;
    },
    expandColumnRenderer: ({ expanded }) => {
      if (expanded) {
        return (
          <>
            <div className="d-flex align-items-center justify-content-md-center">
              <span className="me-2">Details</span>
              <i className="fa fa-chevron-down" aria-hidden="true"></i>
            </div>
          </>
        );
      }
      return (
        <>
          <div className="d-flex align-items-center justify-content-md-center">
            <span className="me-2">Details</span>
            <i className="fa fa-chevron-right" aria-hidden="true"></i>
          </div>
        </>
      );
    },
  };

  return (
    <>
      <>
        {newUI ? (
          <PageTitle
            title="Objectives"
            parents={['Exercise Management', 'Design']}
          >
            <StylishNewButton
              className="button button--primary w-100 w-md-auto mb-3"
              onClick={props?.participantId ? props?.createTO : createHandler}
            >
              {!props?.participantId
                ? 'Create  Objective'
                : props.participantType === 'DOD'
                ? 'Create Objective'
                : 'Create Objective'}
            </StylishNewButton>
          </PageTitle>
        ) : !props?.participantId ? (
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <StylishNewButton onClick={() => navigate(-1)}>
                  <SharedIcon iconName="keyboard_arrow_left" classes="me-2" />
                  Back
                </StylishNewButton>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Objectives
              </li>
            </ol>
          </nav>
        ) : null}

        {!newUI ? (
          <div className={props?.participantId ? 'mt-4' : ''}>
            <StylishNewButton
              className="button button--primary w-100 w-md-auto mb-3"
              onClick={props?.participantId ? props?.createTO : createHandler}
            >
              {!props?.participantId
                ? 'Create  Objective'
                : props.participantType === 'DOD'
                ? 'Create Objective'
                : 'Create Objective'}
            </StylishNewButton>
          </div>
        ) : null}

        <div className={props?.participantId ? 'form-block' : ''}>
          <div className="row">
            <div className="col-md-12">
              <div className="d-flex flex-column flex-xl-row align-items-xl-center mb-4">
                <div className="d-flex flex-column flex-sm-row align-items-sm-center justify-content-between justify-content-xl-start me-xl-3 mb-3 mb-xl-0 w-100 w-lg-auto">
                  <p className="m-0 me-sm-3 mb-3 mb-sm-0">
                    {total + ' Objectives'}
                  </p>
                  <div className="d-flex align-items-center ms-sm-4 justify-content-between justify-content-sm-start">
                    <p className="mb-0 me-3">Participant Type</p>
                    <StylishNewSelect
                      options={participantOptions}
                      onChange={(e) => {
                        setParticipantOption(e);
                        setPage(1);
                      }}
                      value={
                        props?.participantType
                          ? participantOptions.find(
                              (data) => data.value === props?.participantType
                            )
                          : participantOption
                      }
                      placeholder={'Sort By...'}
                      isClearable={false}
                      isSearchable={false}
                      isMulti={false}
                      isDisabled={!!props?.participantType}
                      // readOnly={!props?.participantType}
                    />
                  </div>
                </div>

                <div className="d-flex align-items-center justify-content-between ms-0 ms-xl-auto flex-column flex-sm-row">
                  <StylishNewSearchBar
                    onChangeSearchTerm={onChangeSearchTerm}
                  />
                  <div className="d-flex align-items-center ms-sm-3 flex-shrink-0 w-100 w-md-auto justify-content-between mt-3 mt-sm-0">
                    {props?.participantType ? (
                      <p className="mb-0 me-3 d-none d-md-block">Per Page</p>
                    ) : null}
                    <PaginationDropdown
                      setPage={setPage}
                      setPerPage={setPerPage}
                      options={[
                        { value: 25, label: '25 per page' },
                        { value: 50, label: '50 per page' },
                        { value: 75, label: '75 per page' },
                        { value: 100, label: '100 per page' },
                      ]}
                    />
                    {!props?.participantType ? (
                      <StylishHelp
                        classes={'ms-3 flex-shrink-0 sml'}
                        title={HelpTrainingObjectives.title}
                        content={HelpTrainingObjectives.content}
                        link={selfHostedKnowledgeBase || HelpTrainingObjectives.link}
                      />
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {allTrainingObjectives?.length ? (
            <StylishNewTable
              keyField={'id'}
              columns={
                participantOption?.value === 'DOD' ? columnsDod : columnsHseep
              }
              rows={allTrainingObjectives}
              expandRow={
                participantOption?.value === 'DOD'
                  ? expandRowDOD
                  : expandRowHSEEP
              }
              page={page}
              total={total}
              perPage={perPage}
              onPageChange={handlePageClick}
              pagination={true}
            />
          ) : (
            <>
              <div className="bg-gray-800 text-center p-5">
                <div className="py-0 py-md-4">
                  <div className="d-flex justify-content-center mb-4">
                    <img src={IconEmpty} alt="" />
                  </div>
                  <p className="weight-600 mb-4">
                    A fresh start. Create the first entry.
                    {/* eslint-disable-next-line */}
                    <a href={selfHostedKnowledgeBase || MiscellaneousHelpLink.link} target="_blank">
                      {' '}
                      Need more help?
                    </a>
                  </p>
                  <StylishNewButton
                    className="button button--primary w-100 w-md-auto mb-3 mb-md-0"
                    onClick={
                      props?.participantId ? props?.createTO : createHandler
                    }
                  >
                    {!props?.participantId
                      ? 'Create  Objective'
                      : props.participantType === 'DOD'
                      ? 'Create Objective'
                      : 'Create Objective'}
                  </StylishNewButton>
                </div>
              </div>
            </>
          )}
        </div>
      </>

      {showCreateTrainingObjectiveDialog && (
        <TrainingObjectiveCreateDialog
          show={showCreateTrainingObjectiveDialog}
          onClose={dialogCloseHandler}
        />
      )}
      {deleteTrainingObjective && (
        <StylishConfirmDialog
          show={deleteTrainingObjective}
          onClose={() => setDeleteTrainingObjective(null)}
          dialogType={'Delete'}
          dialogTitle={'Delete Objective'}
          dialogContent={'Are you sure you want this?'}
          updateDialogData={deleteTrainingObjective}
          onConfirm={onDeleteConfirm}
        />
      )}
    </>
  );
}
