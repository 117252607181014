import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import AddEditJSTPDialog from './AddEditJSTPDialog';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Network from '../../../service/Network';
import API from '../../../service/api';
import { useForm } from 'react-hook-form';
import StylishHelp from 'components/DesignSystems/New/StylishHelp';
import { HelpPlanJointStaffTrainingPriorities, useSelfHostedKnowledgeBase } from 'assets/data/HelpContent';
import StylishNewTable from 'components/DesignSystems/New/StylishNewTable';
import { StylishNewSearchBar } from 'components/DesignSystems/New/StylishNewSearchBar';
import StylishNewSelect from 'components/DesignSystems/New/StylishNewSelect';
import StylishNewTableAction from 'components/DesignSystems/New/StylishNewTableAction';
import { StylishConfirmDialog } from 'components/DesignSystems/New/StylishConfirmDialog';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { toastConfig } from 'assets/data/config';
import { PaginationDropdown } from '../../../../components/DesignSystems/PaginationDropdown';
import {
  endLoading,
  startLoading,
} from '../../../../reducers/loading/loading.action';

export default function JSTP() {
  const [allJSTP, setAllJSTP] = useState([]);
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [perPage, setPerPage] = useState(10);
  const [total, setTotal] = useState(0);
  const [showJSTPAddEditDialog, setShowJSTPAddEditDialog] = useState(false);
  const [dialogType, setDialogType] = useState('Add');
  const [updateDialogData, setUpdateDialogData] = useState(null);
  const [deleteJSTPId, setDeleteJSTPId] = useState(null);
  const [
    deleteStrategicPriorityTitle,
    setDeleteStrategicPriorityTitle,
  ] = useState('');

  const dispatch = useDispatch();

  const selfHostedKnowledgeBase = useSelfHostedKnowledgeBase()

  const workspaceId = useSelector(
    (state) => state.prepare.workspace.selectedWorkspace
  );

  const columns = [
    {
      dataField: 'title',
      text: 'Name',
      sort: true,
      headerAttrs: { title: 'Sort By:' },
      attrs: { title: 'Name' },
    },
    {
      dataField: '',
      text: 'Action',
      isDummyField: true,
      formatter: (cell, row, rowIndex) => (
        <StylishNewTableAction
          name="Strategic Prority"
          cell={cell}
          row={row}
          rowIndex={rowIndex}
          onDelete={onDeletegetAllJSTP}
          onEdit={onEditgetAllJSTP}
        />
      ),
      headerStyle: {
        width: '100px',
      },
      headerAlign: 'center',
      align: 'center',
      attrs: { title: 'Action' },
    },
  ];

  const expandRow = {
    parentClassName: 'parent-expand-tr',
    className: 'child-expand-tr',
    renderer: (row) => {
      return (
        <>
          <h6 className="mb-2">{row.title}</h6>
          <p className="mb-4">{row.description}</p>
          <div className="button-group">
            <StylishNewButton
              className="button button--primary"
              type="button"
              onClick={() => onEditgetAllJSTP(row)}
            >
              Edit
            </StylishNewButton>
            <StylishNewButton
              className="button button--secondary button--reverse"
              type="button"
              onClick={() => onDeletegetAllJSTP(row)}
            >
              Delete
            </StylishNewButton>
          </div>
        </>
      );
    },
    showExpandColumn: true,
    expandByColumnOnly: true,
    expandHeaderColumnRenderer: ({ isAnyExpands }) => {
      if (isAnyExpands) {
        return <i className="fa fa-chevron-down" aria-hidden="true"></i>;
      }
      return <i className="fa fa-chevron-right" aria-hidden="true"></i>;
    },
    expandColumnRenderer: ({ expanded }) => {
      if (expanded) {
        return (
          <>
            <div className="d-flex align-items-center justify-content-md-center">
              <span className="me-2">Details</span>
              <i className="fa fa-chevron-down" aria-hidden="true"></i>
            </div>
          </>
        );
      }
      return (
        <>
          <div className="d-flex align-items-center justify-content-md-center">
            <span className="me-2">Details</span>
            <i className="fa fa-chevron-right" aria-hidden="true"></i>
          </div>
        </>
      );
    },
  };

  const planJSTPlist = async () => {
    dispatch(startLoading());
    try {
      const JSTP = await Network.get(API.planJSTPlist, {
        limit: perPage,
        page: page,
        search: searchTerm,
        workspaceId: workspaceId,
      });

      setAllJSTP(JSTP.data.response.dataset);

      setTotal(JSTP.data.response.count);
    } catch (error) {
      toast.error(error.response.data.response.status.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };

  useEffect(() => {
    planJSTPlist();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, perPage, searchTerm, workspaceId]);

  const onAddgetAllJSTP = () => {
    setDialogType('Add');
    setShowJSTPAddEditDialog(true);
  };

  const onDeletegetAllJSTP = (row) => {
    setDeleteJSTPId(row.id);
    setDeleteStrategicPriorityTitle(row.title);
  };

  const onEditgetAllJSTP = (row) => {
    setUpdateDialogData(row);
    setDialogType('Edit');
    setShowJSTPAddEditDialog(true);
  };

  const OnAddOrEditgetAllJSTP = (afterSave) => {
    setUpdateDialogData(null);
    setDialogType('New');
    setShowJSTPAddEditDialog(false);
    if (afterSave?.id) {
      planJSTPlist();
    }
  };

  const onDeleteConfirm = async () => {
    dispatch(startLoading());
    try {
      await Network.post(API.deleteJSTP, {
        id: deleteJSTPId,
      });
      setDeleteJSTPId(null);
      planJSTPlist();
      toast.success('Deleted  successfully', toastConfig);
    } catch (error) {
      toast.error(error.response.data.response.status.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };

  const onChangeSearchTerm = (term) => {
    setPage(1);
    setSearchTerm(term);
  };

  return (
    <>
      <div className="settings__jstp">
        <StylishNewButton
          className="button button--primary mb-4"
          onClick={onAddgetAllJSTP}
        >
          Add Strategic Priority
        </StylishNewButton>
        <div className="form-block">
          <div className="row">
            <div className="col-md-12">
              <div className="d-flex flex-column flex-md-row align-items-md-center mb-4">
                <div className="d-flex align-items-center justify-content-between">
                  <p className="m-0 me-3 text-nowrap">
                    {total || 0} Strategic Priorities
                  </p>
                  <div className="">
                    <PaginationDropdown
                      setPage={setPage}
                      setPerPage={setPerPage}
                      options={[
                        { value: 10, label: '10 per page' },
                        { value: 30, label: '30 per page' },
                        { value: 60, label: '60 per page' },
                        { value: 120, label: '120 per page' },
                      ]}
                    />
                  </div>
                </div>
                <div className="ms-md-auto mt-3 mt-md-0 d-flex align-items-center">
                  <div className="flex-grow-1">
                    <StylishNewSearchBar
                      onChangeSearchTerm={onChangeSearchTerm}
                    />
                  </div>
                  <StylishHelp
                    classes={'ms-3 flex-shrink-0 sml'}
                    title={HelpPlanJointStaffTrainingPriorities.title}
                    content={HelpPlanJointStaffTrainingPriorities.content}
                    link={selfHostedKnowledgeBase || HelpPlanJointStaffTrainingPriorities.link}
                  />
                </div>
              </div>
            </div>
          </div>
          <StylishNewTable
            keyField={'id'}
            columns={columns}
            rows={allJSTP}
            onDelete={onDeletegetAllJSTP}
            onEdit={onEditgetAllJSTP}
            pagination
            page={page}
            perPage={perPage}
            total={total}
            expandRow={expandRow}
            onPageChange={(page) => setPage(page)}
          />
        </div>
        {showJSTPAddEditDialog && (
          <AddEditJSTPDialog
            show={showJSTPAddEditDialog}
            onClose={OnAddOrEditgetAllJSTP}
            dialogType={dialogType}
            updateDialogData={updateDialogData}
          />
        )}
        {deleteJSTPId && (
          <StylishConfirmDialog
            show={deleteJSTPId}
            onClose={() => setDeleteJSTPId(null)}
            dialogTitle={'Delete Strategic Prority'}
            dialogContentType={'html'}
            dialogContent={`
              Deleting <span class="weight-700 break-word">${deleteStrategicPriorityTitle}</span> will remove all its occurance from this workspace.<span class="d-block pt-3">Are you sure you want to delete this Strategic Prority ?</span>`}
            onConfirm={onDeleteConfirm}
          />
        )}
      </div>
    </>
  );
}
