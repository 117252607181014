import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Network from '../../../../../service/Network';
import API from '../../../../../service/api';
import { useNavigate } from 'react-router-dom';
import AddEditFundingSourceDialog from './AddEditFundingSourceDialog';
import { toast } from 'react-toastify';
import { HelpFundingSources, useSelfHostedKnowledgeBase } from 'assets/data/HelpContent';
import CurrencyFormat from 'react-currency-format';
import StylishNewTableAction from 'components/DesignSystems/New/StylishNewTableAction';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import StylishNewTable from 'components/DesignSystems/New/StylishNewTable';
import { PreparePath } from 'utils/sharedUtils/sharedConst';
import StylishHelp from 'components/DesignSystems/New/StylishHelp';
import { toastConfig } from 'assets/data/config';
import { StylishConfirmDialog } from 'components/DesignSystems/New/StylishConfirmDialog';
import { PaginationDropdown } from '../../../../../../components/DesignSystems/PaginationDropdown';
import { endLoading, startLoading } from "../../../../../../reducers/loading/loading.action";

const paginationOptions = [
  { value: 10, label: '10 per page' },
  { value: 30, label: '30 per page' },
  { value: 60, label: '60 per page' },
  { value: 120, label: '120 per page' },
];
export default function FundingSources(props) {
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [totalFunding, setTotalFunding] = useState(0);
  const searchTerm = '';
  const [pagination, setPagination] = useState(paginationOptions[0]);
  const [perPage, setPerPage] = useState(pagination.value);
  const [fundingSources, setFundingSources] = useState([]);
  const [dialogType, setDialogType] = useState('Add');
  const [updateDialogData, setUpdateDialogData] = useState(null);
  const [deleteFundingSources, setDeleteFundingSources] = useState(null);
  const [
    showFundingSourcesAddEditDialog,
    setShowFundingSourcesAddEditDialog,
  ] = useState(false);
  const [orderBy, setOrderBy] = useState('amount_ASC');

  const selfHostedKnowledgeBase = useSelfHostedKnowledgeBase()

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const reduxUser = useSelector((state) => state.prepare.user);
  const exerciseId = useSelector(
    (state) => state.prepare.exercise.exerciseDetailsId
  );
  const { selectedWorkspace, workspaceName } = useSelector((state) => {
    return state.prepare.workspace;
  });

  const columns = [
    {
      dataField: 'primarysource',
      text: 'Primary Source',
      sort: true,
      onSort: (field, order) => {
        if (order === 'asc') {
          setOrderBy('primarysource_ASC');
        } else if (order === 'desc') {
          setOrderBy('primarysource_DESC');
        } else {
          setOrderBy('');
        }
      },

      headerAttrs: { title: 'Sort By:' },
      attrs: { title: 'Primary Source' },
    },
    {
      dataField: 'subsource',
      text: 'Sub-source',
      sort: true,
      onSort: (field, order) => {
        if (order === 'asc') {
          setOrderBy('subsource_ASC');
        } else if (order === 'desc') {
          setOrderBy('subsource_DESC');
        } else {
          setOrderBy('');
        }
      },

      attrs: { title: 'Sub-source' },
    },
    {
      dataField: 'amountShown',
      text: 'Amount',
      sort: true,
      onSort: (field, order) => {
        if (order === 'asc') {
          setOrderBy('amount_ASC');
        } else if (order === 'desc') {
          setOrderBy('amount_DESC');
        } else {
          setOrderBy('');
        }
      },

      attrs: { title: 'Amount' },
    },
    {
      dataField: '',

      text: 'Action',
      isDummyField: true,
      formatter: (cell, row, rowIndex) => (
        <StylishNewTableAction
          name="Funding Source"
          cell={cell}
          row={row}
          rowIndex={rowIndex}
          onDelete={onDeleteFundingSources}
          onEdit={onEditgetFundingSources}
        />
      ),
      attrs: { title: 'Action' },
      headerStyle: {
        width: '100px',
      },
      headerAlign: 'center',
      align: 'center',
    },
  ];

  const onDeleteFundingSources = (row) => {
    setDeleteFundingSources(row.id);
  };

  const onEditgetFundingSources = (row) => {
    setUpdateDialogData(row);
    setDialogType('Edit');
    setShowFundingSourcesAddEditDialog(true);
  };

  const onAddFundingSources = () => {
    setDialogType('Add');
    setShowFundingSourcesAddEditDialog(true);
  };

  const OnAddOrEditFundingSources = (afterSave) => {
    setUpdateDialogData(null);
    setDialogType('New');
    setShowFundingSourcesAddEditDialog(false);
    if (afterSave?.id) {
      getAllFundingSources();
    }
  };

  const onDeleteConfirm = async () => {
    dispatch(startLoading());
    try {
      await Network.post(API.deleteFunding, { id: deleteFundingSources });
      setDeleteFundingSources(null);
      getAllFundingSources();
      toast.success('Funding Source deleted successfully', toastConfig);
    } catch (error) {
      toast.error(error.response.data.response.status.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };

  const getAllFundingSources = async () => {
    dispatch(startLoading());
    try {
      const funding = await Network.get(API.fetchAllFundingSources, {
        limit: perPage,
        page: page,
        search: searchTerm,
        workspaceId: selectedWorkspace,
        planEventId: exerciseId,
        sort: orderBy,
      });
      setFundingSources(
        funding.data.response.dataset.map((e) => {
          return {
            ...e,
            amountShown: (
              <CurrencyFormat
                value={e?.amount}
                displayType={'text'}
                thousandSeparator={true}
                prefix={'$ '}
              />
            ),
          };
        })
      );
      setTotal(funding.data.response.total || 0);
      setTotalFunding(funding.data.response.totalFund || 0);
    } catch (error) {
      toast.error(error.response.data.response.status.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };

  const saveAndExit = () => {
    navigate(`${PreparePath}/dashboard/plan/prepare/exercise-details`);
  };

  useEffect(() => {
    if (exerciseId) {
      getAllFundingSources();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, perPage, searchTerm, orderBy, reduxUser]);

  return (
    <>
      <div className="d-flex align-items-center mb-4">
        <h4 className="mb-0">
          {props?.exerciseDetailName?.eventName || workspaceName} Funding
          Sources
        </h4>
        <StylishHelp
          classes={'ms-auto sml flex-shrink-0'}
          title={HelpFundingSources.title}
          content={HelpFundingSources.content}
          link={selfHostedKnowledgeBase || HelpFundingSources.link}
        />
      </div>
      <div className="form-block">
        <StylishNewButton
          className="button button--primary mb-3 w-100 w-md-auto"
          onClick={onAddFundingSources}
        >
          Add Funding Source
        </StylishNewButton>
        <div className="row mb-2">
          <div className="col-md-12">
            <div className="d-flex align-items-center">
              <p className="m-0 me-3 text-nowrap weight-600">
                Total Funding: $ {totalFunding?.toLocaleString()}
              </p>
              <div className="ms-auto">
                <PaginationDropdown
                  setPage={setPage}
                  setPerPage={setPerPage}
                  options={[
                    { value: 5, label: '5 per page' },
                    { value: 15, label: '15 per page' },
                    { value: 30, label: '30 per page' },
                    { value: 60, label: '60 per page' },
                    { value: 120, label: '120 per page' },
                  ]}
                />
              </div>
            </div>
          </div>
        </div>
        <StylishNewTable
          keyField={'id'}
          columns={columns}
          rows={fundingSources}
          pagination
          page={page}
          perPage={perPage}
          total={total}
          onPageChange={(page) => setPage(page)}
        />
        {showFundingSourcesAddEditDialog && (
          <AddEditFundingSourceDialog
            show={showFundingSourcesAddEditDialog}
            onClose={OnAddOrEditFundingSources}
            dialogType={dialogType}
            updateDialogData={updateDialogData}
          />
        )}

        {deleteFundingSources && (
          <StylishConfirmDialog
            show={deleteFundingSources}
            onClose={() => setDeleteFundingSources(null)}
            dialogType={'Delete'}
            dialogTitle={'Delete Funding Source'}
            dialogContent={'Are you sure you want this?'}
            updateDialogData={deleteFundingSources}
            onConfirm={onDeleteConfirm}
          />
        )}
      </div>
      <div className="col-md-12 mt-4">
        <div className="button-group d-block d-md-flex">
          <StylishNewButton
            type="submit"
            className="button button--primary button--reverse w-100 w-md-auto"
            onClick={() => props.onPrevClick(1)}
          >
            Previous
          </StylishNewButton>
          <StylishNewButton
            type="button"
            className="button button--tertiary button--reverse ms-auto mt-3 mt-md-0 w-100 w-md-auto"
            onClick={saveAndExit}
          >
            Save &amp; Exit
          </StylishNewButton>
          <StylishNewButton
            type="submit"
            className="button button--primary  w-100 w-md-auto mt-3 mt-md-0 ms-0 ms-md-3"
            onClick={() => props.onNextClick(3)}
          >
            Next
          </StylishNewButton>
        </div>
      </div>
    </>
  );
}
