import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { StylishNewSearchBar } from 'components/DesignSystems/New/StylishNewSearchBar';
import StylishNewTable from 'components/DesignSystems/New/StylishNewTable';
import StylishNewTableAction from 'components/DesignSystems/New/StylishNewTableAction';
import { useEffect, useState } from 'react';
import { AddEditCellDialog } from './AddEditCellDialog';
import { useDispatch, useSelector } from 'react-redux';
import Network from 'PREPAREsrc/service/Network';
import API from 'PREPAREsrc/service/api';
import { toast } from 'react-toastify';
import { toastConfig } from 'assets/data/config';
import ConfirmModal from 'PREPAREsrc/component/ConfirmModal/ConfirmModal';
import { endLoading, startLoading } from "../../../../../reducers/loading/loading.action";

const perPageOptions = [
  { value: '5', label: '5 per page' },
  { value: '15', label: '15 per page' },
  { value: '30', label: '30 per page' },
  { value: '50', label: '50 per page' },
];

export const EventCells = (props) => {
  const { selectedTab } = props;
  const dispatch = useDispatch();
  const { selectedWorkspace } = useSelector(
    (state) => state?.prepare?.workspace
  );
  const eventId = useSelector((state) => state.prepare.planDashboard.eventId);
  const reduxUser = useSelector((state) => state.prepare.user);

  const [perPage, setPerPage] = useState(perPageOptions[1]);
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState();
  const [total, setTotal] = useState(0);
  const [showAddEditCellDialog, setShowAddEditCellDialog] = useState(false);
  const [eventCellList, SetEventCellList] = useState([]);
  const [editCell, setEditCell] = useState(null);
  const [deleteCell, setDeleteCell] = useState(null);
  const [showDeleteCellConfirmModal, setShowDeleteCellConfirmModal] = useState(
    false
  );
  const [selectedCell, setSelectedCell] = useState([]);
  useEffect(() => {
    if (selectedTab === 'event_cells' && eventId) {
      getEventCell();
    }
  }, [reduxUser, selectedWorkspace, selectedTab, searchTerm, eventId]);

  useEffect(() => {
    if (selectedTab !== 'event_cells') {
      setPage(1);
      setPerPage(perPageOptions[1]);
      setSearchTerm();
    }
  }, [selectedTab]);

  const getEventCell = () => {
    dispatch(startLoading());
    if (selectedWorkspace) {
      try {
        Network.get(API.getAllEventCell, {
          workspaceId: selectedWorkspace,
          planEventId: eventId,
          limit: perPage.value,
          page: page,
          search: searchTerm,
        })
          .then((resp) => {
            SetEventCellList(resp.data.response.dataset);
            if (!searchTerm || (!!searchTerm && searchTerm === '')) {
              setTotal(resp.data.response.total);
            }
            setSelectedCell([
              ...resp.data.response.dataset.map((cell) => cell.cell),
            ]);
          })
          .catch(console.log)
          .finally(() => {
            dispatch(endLoading());
          });
      } catch (error) {
        console.log(error);
        toast.error(error?.response?.data?.response?.status?.msg, toastConfig);
      }
    }
  };

  const onModalClose = () => {
    setShowAddEditCellDialog(false);
    getEventCell();
    if (editCell) {
      setEditCell(null);
    }
  };
  const onClose = (isFetchNeed) => {
    setShowAddEditCellDialog(false);
    if (isFetchNeed) {
      getEventCell();
    }
    if (editCell) {
      setEditCell(null);
    }
  };

  const onEditCell = (row, rowIndex) => {
    setEditCell(row);
    setShowAddEditCellDialog(true);
  };

  const onDeleteCellConfirm = async () => {
    dispatch(startLoading());
    try {
      await Network.post(API.deleteEventCell, { id: deleteCell.id });
      getEventCell();
      toast.success('Event Cell deleted successfully', toastConfig);
    } catch (error) {
      toast.error(error?.response?.data?.response?.status?.msg, toastConfig);
    } finally {
      dispatch(endLoading());
      setShowDeleteCellConfirmModal(false);
    }
  };
  const onDeleteCell = (row) => {
    setDeleteCell(row);
    setShowDeleteCellConfirmModal(true);
  };
  const onChangeSearchTerm = (termSearched) => {
    setSearchTerm(termSearched);
  };

  const columns = [
    {
      dataField: 'cell',
      text: 'Cell',
      sort: true,
      headerAttrs: { title: 'Sort By:' },
      attrs: { title: 'Cell' },
    },
    {
      dataField: 'responsibility',
      text: 'Responsibility',
      sort: true,
      attrs: { title: 'Responsibility' },
    },
    {
      dataField: 'id',
      text: 'Primary',
      sort: true,
      attrs: { title: 'Primary' },
      formatter: (cell, row) => {
        const primeUser = row.users.find((user) => user.isPrimary === true);
        return (
          <>
            {primeUser ? (
              <span className="d-block">{primeUser.name}</span>
            ) : null}
          </>
        );
      },
    },
    {
      dataField: 'members',
      text: 'Members',
      sort: true,
      attrs: { title: 'Members' },
      formatter: (cell, row) => {
        const members = row.users;
        return (
          <>
            {(members &&
              !!members?.length &&
              members?.map((i) => (
                <span key={i.id} className="d-block">
                  {i.name || i.email}
                </span>
              ))) ||
              null}
          </>
        );
      },
    },
    {
      text: 'Action',
      dataField: 'action',
      isDummyField: true,
      formatter: (cell, row, rowIndex) => (
        <StylishNewTableAction
          name="Cell"
          cell={cell}
          row={row}
          rowIndex={rowIndex}
          onDelete={() => onDeleteCell(row)}
          onEdit={() => onEditCell(row, rowIndex)}
        />
      ),
      attrs: { title: 'Action' },
      headerStyle: {
        width: '100px',
      },
      headerAlign: 'center',
      align: 'center',
    },
  ];

  return (
    <>
      {/* {(eventCellList && !!eventCellList?.length && ( */}
      <>
        <div className="d-md-flex align-items-center mb-4">
          <StylishNewButton
            type="button"
            className={'button--primary w-100 w-md-auto mb-3 mb-md-0'}
            onClick={() => setShowAddEditCellDialog(true)}
            disabled={total >= 6}
          >
            Add Cell
          </StylishNewButton>
          <div className="ms-auto d-md-flex align-items-center">
            <StylishNewSearchBar
              value={searchTerm}
              onChangeSearchTerm={onChangeSearchTerm}
            />
          </div>
        </div>
        <StylishNewTable
          keyField={'id'}
          columns={columns}
          rows={eventCellList && !!eventCellList?.length ? eventCellList : []}
          pagination
          page={page}
          perPage={perPage.value}
          total={total}
          onPageChange={(page) => setPage(page)}
        />
      </>
      {/* )) || (
        <div className="bg-gray-800 text-center px-4 py-5 p-md-5">
          <div className="py-0 py-md-4">
            <div className="d-flex justify-content-center mb-4">
              <img src={IconPlaceholder} alt="" />
            </div>
            <p className="weight-600 mb-3">
              A fresh start. Create the first entry.
            </p>
            <div className="d-flex justify-content-center">
              <StylishNewButton
                type="button"
                className={'button--primary'}
                onClick={() => setShowAddEditCellDialog(true)}
              >
                Add Cell
              </StylishNewButton>
            </div>
          </div>
        </div>
      )} */}
      {(showAddEditCellDialog && (
        <AddEditCellDialog
          editCell={editCell}
          show={showAddEditCellDialog}
          onClose={onClose}
          onSuccessClose={onModalClose}
          selectedCell={selectedCell}
        />
      )) ||
        null}

      {showDeleteCellConfirmModal && (
        <ConfirmModal
          heading={'Delete Event Cell'}
          text={'Are you sure you want to delete this Event Cell?'}
          onClose={() => {
            setDeleteCell(null);
            setShowDeleteCellConfirmModal(false);
          }}
          show={showDeleteCellConfirmModal}
          onConfirm={onDeleteCellConfirm}
        />
      )}
    </>
  );
};
