import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { Controller, useForm, useFieldArray } from 'react-hook-form';
import IconAdd from 'assets/images/icon__add.svg';
import IconDelete from 'assets/images/icon__delete.svg';
import { inputStringLength, textareaStringLength } from 'assets/data/config';
import StylishNewInput from 'components/DesignSystems/New/StylishNewInput';
import StylishNewTextArea from 'components/DesignSystems/New/StylishNewTextArea';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Network from 'PREPAREsrc/service/Network';
import API from 'PREPAREsrc/service/api';
import { endLoading, startLoading } from 'reducers/loading/loading.action';
import { useEffect, useState } from 'react';
import { PreparePath } from 'utils/sharedUtils/sharedConst';

export const LogisticsRequirements = () => {
  const navigate = useNavigate();

  const params = useParams();

  const reduxDispatch = useDispatch();

  const { id: systemId } = params;

  const { selectedWorkspace } = useSelector((state) => state.prepare.workspace);

  const [showAddButton, setShowAddButton] = useState(true);

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    getValues,
    trigger,
    watch,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      logistics_requirements: [
        {
          type: '',
          quantity: '',
          imc_spectrum: '',
          name: '',
          description: '',
          purpose: '',
          proposed_location: '',
          usage: '',
          owner: '',
          frequencies: '',
          power_output: '',
          additional_notes: '',
          contact_info: '',
        },
      ],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'logistics_requirements',
  });
  const onSaveAndExitClick = async () => {
    const valid = await trigger();
    if (valid) {
      const data = getValues();
      onSubmit(data, 'exit');
    }
  };
  const onSubmit = (formData, type = '') => {
    const submitData = {
      logistic_requirement: formData.logistics_requirements?.map((lr) => ({
        ...lr,
        system_id: systemId,
        workspaceId: selectedWorkspace,
      })),
      system_id: systemId,
      workspaceId: selectedWorkspace,
    };

    try {
      reduxDispatch(startLoading());
      Network.post(API.createOrUpdateLogisticsRequirements, submitData)
        .then(() => {
          if (type === 'exit') {
            navigate(`${PreparePath}/dashboard/plan/systems`);
          } else {
            navigate(
              `${PreparePath}/dashboard/plan/systems/${systemId}?page=4`
            );
          }
        })
        .catch(console.log)
        .finally(() => reduxDispatch(endLoading()));
    } catch (error) {
      console.error(error);
      reduxDispatch(endLoading());
    }
  };

  const onPrevious = () => {
    if (params.id) {
      navigate(`${PreparePath}/dashboard/plan/systems/${params.id}?page=2`);
    } else {
      navigate(`${PreparePath}/dashboard/plan/systems`);
    }
  };

  const fetchLogisticsRequirements = () => {
    try {
      reduxDispatch(startLoading());
      Network.get(API.fetchLogisticsRequirements, {
        workspaceId: selectedWorkspace,
        system_id: systemId,
      })
        .then((resp) => {
          if (resp.data.response.dataset?.length) {
            setShowAddButton(false);
            setValue('logistics_requirements', resp.data.response.dataset);
          } else {
            setShowAddButton(true);
          }
        })
        .catch(console.log)
        .finally(() => reduxDispatch(endLoading()));
    } catch (error) {
      console.error(error);
      reduxDispatch(endLoading());
    }
  };

  useEffect(() => {
    systemId && selectedWorkspace && fetchLogisticsRequirements();
  }, [systemId, selectedWorkspace]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <h4>Logistics Requirements</h4>
        {showAddButton ? (
          <StylishNewButton
            type="button"
            className={'button--primary mt-4 w-100 w-md-auto'}
            onClick={() => {
              setShowAddButton(false);
              setValue('logistics_requirements', [
                {
                  type: '',
                  quantity: '',
                  imc_spectrum: '',
                  name: '',
                  description: '',
                  purpose: '',
                  proposed_location: '',
                  usage: '',
                  owner: '',
                  frequencies: '',
                  power_output: '',
                  additional_notes: '',
                  contact_info: '',
                },
              ]);
            }}
            // disabled={showComponent}
          >
            Add Logistic Requirement
          </StylishNewButton>
        ) : null}
        {!showAddButton ? (
          <div className="form-block">
            {fields?.map((field, index) => (
              <div className="row" key={field.id}>
                {(index !== 0 && (
                  <div className="w-100">
                    <hr className="dashed" />
                  </div>
                )) ||
                  null}
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-4 mb-3">
                      <label className="form-label">Type</label>
                      <Controller
                        name={`logistics_requirements.${index}.type`}
                        control={control}
                        rules={{
                          required: true,
                          maxLength: inputStringLength,
                        }}
                        render={({ field: { onChange, value } }) => (
                          <StylishNewInput
                            type="text"
                            value={value || ''}
                            onChange={onChange}
                          />
                        )}
                      />
                      {(errors?.logistics_requirements?.[index]?.type?.type ===
                        'required' && (
                        <span className="form-text form-error">
                          This is a required field
                        </span>
                      )) ||
                        null}
                      {(errors?.logistics_requirements?.[index]?.type?.type ===
                        'maxLength' && (
                        <span className="form-text form-error">
                          Only {inputStringLength} characters allowed.
                        </span>
                      )) ||
                        null}
                    </div>
                    <div className="col-md-4 mb-3">
                      <label className="form-label">Quantity</label>
                      <Controller
                        name={`logistics_requirements.${index}.quantity`}
                        control={control}
                        rules={{
                          validate: (value) => {
                            if (!value) return true;
                            return value >= 0;
                          },
                        }}
                        render={({ field: { onChange, value } }) => (
                          <StylishNewInput
                            type="number"
                            value={value || ''}
                            onChange={onChange}
                          />
                        )}
                      />
                      {(errors?.logistics_requirements?.[index]?.quantity
                        ?.type === 'validate' && (
                        <span className="form-text form-error">
                          Only positive number allowed.
                        </span>
                      )) ||
                        null}
                    </div>
                    <div className="col-md-4 mb-3">
                      <label className="form-label">IMC Spectrum</label>
                      <Controller
                        name={`logistics_requirements.${index}.imc_spectrum`}
                        control={control}
                        rules={{
                          maxLength: inputStringLength,
                        }}
                        render={({ field: { onChange, value } }) => (
                          <StylishNewInput
                            type="text"
                            value={value || ''}
                            onChange={onChange}
                          />
                        )}
                      />
                      {(errors?.logistics_requirements?.[index]?.imc_spectrum
                        ?.type === 'maxLength' && (
                        <span className="form-text form-error">
                          Only {inputStringLength} characters allowed.
                        </span>
                      )) ||
                        null}
                    </div>
                  </div>
                </div>
                <div className="w-100"></div>
                <div className="col-md-4 mb-3">
                  <label className="form-label">Item Name</label>
                  <Controller
                    name={`logistics_requirements.${index}.name`}
                    control={control}
                    rules={{
                      maxLength: inputStringLength,
                    }}
                    render={({ field: { onChange, value } }) => (
                      <StylishNewInput
                        type="text"
                        value={value || ''}
                        onChange={onChange}
                      />
                    )}
                  />
                  {(errors?.logistics_requirements?.[index]?.name?.type ===
                    'maxLength' && (
                    <span className="form-text form-error">
                      Only {inputStringLength} characters allowed.
                    </span>
                  )) ||
                    null}
                </div>
                <div className="col-md-4 mb-3">
                  <label className="form-label">Description</label>
                  <Controller
                    name={`logistics_requirements.${index}.description`}
                    control={control}
                    rules={{
                      maxLength: inputStringLength,
                    }}
                    render={({ field: { onChange, value } }) => (
                      <StylishNewInput
                        type="text"
                        value={value || ''}
                        onChange={onChange}
                      />
                    )}
                  />
                  {(errors?.logistics_requirements?.[index]?.description
                    ?.type === 'maxLength' && (
                    <span className="form-text form-error">
                      Only {inputStringLength} characters allowed.
                    </span>
                  )) ||
                    null}
                </div>
                <div className="col-md-4 mb-3">
                  <label className="form-label">Purpose</label>
                  <Controller
                    name={`logistics_requirements.${index}.purpose`}
                    control={control}
                    rules={{
                      maxLength: inputStringLength,
                    }}
                    render={({ field: { onChange, value } }) => (
                      <StylishNewInput
                        type="text"
                        value={value || ''}
                        onChange={onChange}
                      />
                    )}
                  />
                  {(errors?.logistics_requirements?.[index]?.purpose?.type ===
                    'maxLength' && (
                    <span className="form-text form-error">
                      Only {inputStringLength} characters allowed.
                    </span>
                  )) ||
                    null}
                </div>
                <div className="col-md-4 mb-3">
                  <label className="form-label">Purpose Location</label>
                  <Controller
                    name={`logistics_requirements.${index}.proposed_location`}
                    control={control}
                    rules={{
                      maxLength: inputStringLength,
                    }}
                    render={({ field: { onChange, value } }) => (
                      <StylishNewInput
                        type="text"
                        value={value || ''}
                        onChange={onChange}
                      />
                    )}
                  />
                  {(errors?.logistics_requirements?.[index]?.proposed_location
                    ?.type === 'maxLength' && (
                    <span className="form-text form-error">
                      Only {inputStringLength} characters allowed.
                    </span>
                  )) ||
                    null}
                </div>
                <div className="col-md-4 mb-3">
                  <label className="form-label">Usage</label>
                  <Controller
                    name={`logistics_requirements.${index}.usage`}
                    control={control}
                    rules={{
                      maxLength: inputStringLength,
                    }}
                    render={({ field: { onChange, value } }) => (
                      <StylishNewInput
                        type="text"
                        value={value || ''}
                        onChange={onChange}
                      />
                    )}
                  />
                  {(errors?.logistics_requirements?.[index]?.usage?.type ===
                    'maxLength' && (
                    <span className="form-text form-error">
                      Only {inputStringLength} characters allowed.
                    </span>
                  )) ||
                    null}
                </div>
                <div className="col-md-4 mb-3">
                  <label className="form-label">Owner</label>
                  <Controller
                    name={`logistics_requirements.${index}.owner`}
                    control={control}
                    rules={{
                      maxLength: inputStringLength,
                    }}
                    render={({ field: { onChange, value } }) => (
                      <StylishNewInput
                        type="text"
                        value={value || ''}
                        onChange={onChange}
                      />
                    )}
                  />
                  {(errors?.logistics_requirements?.[index]?.owner?.type ===
                    'maxLength' && (
                    <span className="form-text form-error">
                      Only {inputStringLength} characters allowed.
                    </span>
                  )) ||
                    null}
                </div>
                <div className="col-md-12 mb-3">
                  <label className="form-label">Frequencies</label>
                  <Controller
                    name={`logistics_requirements.${index}.frequencies`}
                    control={control}
                    rules={{
                      maxLength: textareaStringLength,
                    }}
                    render={({ field: { onChange, value } }) => (
                      <StylishNewTextArea
                        value={value || ''}
                        onChange={onChange}
                      />
                    )}
                  />
                  {(errors?.logistics_requirements?.[index]?.frequencies
                    ?.type === 'maxLength' && (
                    <span className="form-text form-error">
                      Only {textareaStringLength} characters allowed.
                    </span>
                  )) ||
                    null}
                </div>
                <div className="col-md-6 mb-3">
                  <label className="form-label">Power Output</label>
                  <Controller
                    name={`logistics_requirements.${index}.power_output`}
                    control={control}
                    rules={{
                      maxLength: inputStringLength,
                    }}
                    render={({ field: { onChange, value } }) => (
                      <StylishNewInput
                        type="text"
                        value={value || ''}
                        onChange={onChange}
                      />
                    )}
                  />
                  {(errors?.logistics_requirements?.[index]?.power_output
                    ?.type === 'maxLength' && (
                    <span className="form-text form-error">
                      Only {inputStringLength} characters allowed.
                    </span>
                  )) ||
                    null}
                </div>
                <div className="col-md-6 mb-3">
                  <label className="form-label">Contact Info</label>
                  <Controller
                    name={`logistics_requirements.${index}.contact_info`}
                    control={control}
                    rules={{
                      maxLength: inputStringLength,
                    }}
                    render={({ field: { onChange, value } }) => (
                      <StylishNewInput
                        type="text"
                        value={value || ''}
                        onChange={onChange}
                      />
                    )}
                  />
                  {(errors?.logistics_requirements?.[index]?.contact_info
                    ?.type === 'maxLength' && (
                    <span className="form-text form-error">
                      Only {inputStringLength} characters allowed.
                    </span>
                  )) ||
                    null}
                </div>
                <div className="col-md-12">
                  <label className="form-label">Additional Notes</label>
                  <Controller
                    name={`logistics_requirements.${index}.additional_notes`}
                    control={control}
                    rules={{
                      maxLength: textareaStringLength,
                    }}
                    render={({ field: { onChange, value } }) => (
                      <StylishNewTextArea
                        value={value || ''}
                        onChange={onChange}
                      />
                    )}
                  />
                  {(errors?.logistics_requirements?.[index]?.additional_notes
                    ?.type === 'maxLength' && (
                    <span className="form-text form-error">
                      Only {textareaStringLength} characters allowed.
                    </span>
                  )) ||
                    null}
                </div>
                <div className="col-12 d-flex">
                  <div className="button-group ms-auto mt-4">
                    {(index === fields?.length - 1 && (
                      <StylishNewButton
                        type="button"
                        customButton
                        className="button--icon primary lrg"
                        onClick={() => append({ name: '', type: '' })}
                      >
                        <img src={IconAdd} alt="" />
                      </StylishNewButton>
                    )) ||
                      null}
                    {(fields?.length > 0 && (
                      <StylishNewButton
                        type="button"
                        customButton
                        className="button--icon primary lrg"
                        onClick={() => {
                          remove(index);
                          if (fields?.length === 1) {
                            setShowAddButton(true);
                          }
                        }}
                      >
                        <img src={IconDelete} alt="" />
                      </StylishNewButton>
                    )) ||
                      null}
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : null}
        <div className="button-group d-block d-md-flex mt-4">
          <StylishNewButton
            type="button"
            className={'button--primary button--reverse w-100 w-md-auto'}
            onClick={onPrevious}
          >
            Previous
          </StylishNewButton>
          <StylishNewButton
            type="button"
            className={
              'button--tertiary button--reverse w-100 w-md-auto ms-0 ms-md-auto mt-2 mt-md-0'
            }
            onClick={onSaveAndExitClick}
          >
            Save & Exit
          </StylishNewButton>
          <StylishNewButton
            className={
              'button--primary w-100 w-md-auto ms-0 ms-md-3 mt-2 mt-md-0'
            }
            type="submit"
          >
            Next
          </StylishNewButton>
        </div>
      </form>
    </>
  );
};
