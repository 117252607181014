// @ts-nocheck
import ReactDOM from 'react-dom/client';
import App from './App';
import EntraIdAuth from './EntraIdAuth'
import { getStore } from './store/store';
import { Provider } from 'react-redux';
import { productionEnv } from './constants/debug';
import { isFirefox, isSafari } from 'react-device-detect';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import * as Msal from '@azure/msal-browser';
import ThemeProvider from './ThemeProvider';

// Third party styles
import './vendors.scss';

import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';
import * as microsoftTeams from '@microsoft/teams-js';
import parseJWT from 'utils/parseJWT';
import { msalConfig, loginRequest } from 'components/Auth/MSALAuthObjects';
import { datadogRum } from '@datadog/browser-rum';
import { LoginPageButton } from 'LoginPageButton';

const datadogAppId = productionEnv && window.env.DATA_DOG_APP_ID;

const datadogClientToken = productionEnv
  && window.env.DATA_DOG_CLIENT_TOKEN;


if (!!datadogAppId && !!datadogClientToken) {
  
  console.log("initializing datadog")

  datadogRum.init({
    applicationId: datadogAppId,
    clientToken: datadogClientToken,
    site: 'datadoghq.com',
    service: 'pratus',
    env: 'dev',
    // Specify a version number to identify the deployed version of your application in Datadog 
    version: process.env.REACT_APP_PLATFORM_VERSION,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
  });

}

const msalInstance = new Msal.PublicClientApplication(msalConfig);
let currentAccounts = msalInstance.getAllAccounts();

const root = ReactDOM.createRoot(document.getElementById('root'));
const isBrave = async () => {
  return (navigator.brave && (await navigator.brave.isBrave())) || false;
};


// IMPORTANT: Auth notes
// Auth is very complex with Microsoft and our Teams integration.  Following the steps of action is essential to understanding what is going on
// 1: Check if app is running in browser or Teams.
// 1.1: If teams, render login page with function to use SSO to log in to Teams
// 1.2: If not teams...
// 1.2.1: Check to see if any accounts are known to the browser with msalInstance.getAllAccounts()
// 1.2.1.1: If valid accounts, add account as loginHint to ssoRequest, attempt acquireTokenSilent -> If no accounts or request fail, render login button
// 1.2.2: Login button click => redirect

let teamsInitialized = false;

microsoftTeams.initialize(() => {
  teamsInitialized = true;
});

function initTeamsAuth() {
  var authTokenRequest = {
    successCallback: function (result) {
      renderAppInTeams(result);
    },
    failureCallback: function (error) {
      console.warn('Failure: ' + error);
    },
  };
  microsoftTeams.authentication.getAuthToken(authTokenRequest);
}

const auth0Domain = productionEnv
  ? window.env.AUTH_0_DOMAIN
  : process.env.REACT_APP_AUTH_0_DOMAIN;

const auth0ClientId = productionEnv
  ? window.env.AUTH_0_CLIENT_ID
  : process.env.REACT_APP_AUTH_0_CLIENT_ID;

const auth0Audience = productionEnv
  ? window.env.AUTH_0_AUDIENCE
  : process.env.REACT_APP_AUTH_0_AUDIENCE;

const providerConfig = {
  domain: auth0Domain,
  clientId: auth0ClientId,
  authorizationParams: {
    redirect_uri: window.location.origin,
    audience: auth0Audience,
  },
  // useRefreshTokens: true,
  cacheLocation: 'memory',
};

const auth0Primary = productionEnv
  ? window.env.AUTH_0_PRIMARY
  : process.env.REACT_APP_AUTH_0_PRIMARY;

// The environment must explicitly set the value to false.
// Otherwise, we assume we have access to Auth0
const isAuth0Primary = !(auth0Primary === "false")

setTimeout(async () => {
  if (!isAuth0Primary && !teamsInitialized) {

      // TODO: We should try to await the conclusion of the "loginRedirect" 
      // rather than call this in parallel with the "handleRedirectPromise()".
      if(sessionStorage['hasAgreedToTerms']) {
        msalInstance
          .loginRedirect(loginRequest)
          .then(() => {})
          .catch((error:any) => {
            // handle error
            console.warn('REDIRECT ERROR: ', error);
          });
      }

      const redirectResponse = await msalInstance.handleRedirectPromise();

      if (redirectResponse !== null) {

        const {accessToken} = redirectResponse;

        if (!accessToken) {

          console.log("We don't have the token yet.")

        } else {
          currentAccounts = msalInstance.getAllAccounts();
          renderAppWithoutAuth0(redirectResponse, currentAccounts)
        }
      } else {
        root.render(
          <>
            <EntraIdAuth />
            <LoginPageButton 
              isUsingTeams={teamsInitialized} 
              isAuth0Primary={isAuth0Primary}
              msalInstance={msalInstance}
            />
          </>
        )
      }

  } else {
      const useRefreshTokens = (isSafari || isFirefox || await isBrave()) ? true : false;
      providerConfig['useRefreshTokens'] = useRefreshTokens;
      providerConfig['cacheLocation'] = useRefreshTokens ? 'localstorage' : 'memory';

      root.render(
        <Auth0Provider {...providerConfig}>
          <InitApp teamsInitialized={teamsInitialized} />
        </Auth0Provider>
      );
  }
}, 250);

function InitApp({ teamsInitialized, isAuth0Primary = true}) {
  let isLoading, isAuthenticatedWithAuth0, error, user = false;

  if (isAuth0Primary) {
    ({ isLoading, isAuthenticated: isAuthenticatedWithAuth0, error, user } = useAuth0());
  }

  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (error) {
    return <LoginPageButton isUsingTeams={teamsInitialized} error={error} />;
  }
  if (teamsInitialized) {
    initTeamsAuth();
    return <div>Loading...</div>;
  }


  if (isAuthenticatedWithAuth0) {
    const finalAuthInfo = {
      teamsInitialized: teamsInitialized || false,
      account: {
        userName: user.email,
      },
      type: 'AUTH0',
    };
    if (!user?.email_verified) {
      return <LoginPageButton isUsingTeams={teamsInitialized} user={user} />;
    }
    return (
      <DndProvider backend={HTML5Backend}>
        <Provider
          store={getStore({
            auth: finalAuthInfo,
          })}
        >
          <ThemeProvider>
            <App />
          </ThemeProvider>
        </Provider>
      </DndProvider>
    );
  } else {

    root.render(
      <>
        {!isAuth0Primary && (
          <EntraIdAuth />
        )}
        <Auth0Provider {...providerConfig}>
          <LoginPageButton 
            isUsingTeams={teamsInitialized} 
            isAuth0Primary={isAuth0Primary}
            msalInstance={msalInstance}
          />
        </Auth0Provider>
      </>
    )
  }
}

const renderAppWithoutAuth0 = (authInfo, accounts = {}) => {

  const email_address = accounts[0].username;
  const finalAuthInfo = authInfo;

  finalAuthInfo['teamsInitialized'] = teamsInitialized || false;
  finalAuthInfo.account.userName = email_address;
  finalAuthInfo['isB2C'] = false;
  finalAuthInfo['msalInstance'] = msalInstance;
  finalAuthInfo['type'] = 'MSAL';

  // TODO: What happens if we no longer set the session storage in this way? 
  // My "gut" tells me that our use of session storage here is not necessary or effective.
  sessionStorage.setItem('isUsingTeams', true)
  root.render(
    <DndProvider backend={HTML5Backend}>
      <Provider
        store={getStore({
          auth: finalAuthInfo,
        })}
      >
        <ThemeProvider>
          <App />
        </ThemeProvider>
      </Provider>
    </DndProvider>
  );
}

const renderAppInTeams = (authInfo) => {
  const parsedID = parseJWT(authInfo);
  const finalAuthInfo = {
    ...authInfo,
    account: {
      userName: parsedID['preferred_username'],
    },
  };
  finalAuthInfo['accessToken'] = authInfo;
  finalAuthInfo['teamsInitialized'] = true;
  finalAuthInfo['expiresOn'] = parsedID.expiresOn;
  finalAuthInfo['msalInstance'] = msalInstance;
  finalAuthInfo['type'] = 'MSAL';
  sessionStorage.setItem('accessToken', authInfo);
  sessionStorage.setItem('isUsingTeams', true);
  root.render(
    <DndProvider backend={HTML5Backend}>
      <Provider
        store={getStore({
          auth: finalAuthInfo,
        })}
      >
        <ThemeProvider>
          <App />
        </ThemeProvider>
      </Provider>
    </DndProvider>
  );
};
