import {
  Selector as ReduxSelector,
  TypedUseSelectorHook,
  useSelector,
} from 'react-redux';
import { RootState } from '../store/store';

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

type AppSelector<A> = ReduxSelector<RootState, A>;

export const selectGroup: AppSelector<Group> = (state) =>
  state.app.currentlySelectedGroup;

export const selectGroupGuid: AppSelector<UUID> = (state) =>
  state.app.currentlySelectedGroup.group_guid as UUID;

export const selectUserGuid: AppSelector<UUID> = (state) =>
  state.app.user.user_guid as UUID;

export const selectIncidentGuid: AppSelector<UUID> = (state) =>
  state.app.currentIncident?.id;

export const selectIncident: AppSelector<Incident> = (state) =>
  state.app.currentIncident;

export const selectIncidents: AppSelector<Incident[]> = (state) =>
  state.app.incidents;
