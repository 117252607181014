import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import StylishNewSelect from 'components/DesignSystems/New/StylishNewSelect';
import { Modal, Tab, Tabs } from 'react-bootstrap';
import { Details } from './Details/Details';
import { System } from './System/System';
import { TestObjectives } from './TestObjectives/TestObjectives';
import { ActivityLog } from './ActivityLog/ActivityLog';
import { useEffect, useState } from 'react';
import Network from 'PREPAREsrc/service/Network';
import API from 'PREPAREsrc/service/api';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { PreparePath } from 'utils/sharedUtils/sharedConst';
import { endLoading, startLoading } from 'reducers/loading/loading.action';

export const MissionTimelineDialog = ({
  show,
  onClose,
  missionTimelineId,
  missionId,
  statusLabel,
  updateMissionTimeList,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { selectedWorkspace } = useSelector(
    (state) => state?.prepare?.workspace
  );
  const [key, setKey] = useState('details');
  const [totalSystems, setTotalSystems] = useState(0);
  const [totalObjectives, setTotalObjectives] = useState(0);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [
    missionTimelinePopupDetails,
    setMissionTimelinePopupDetails,
  ] = useState(null);
  useEffect(() => {
    if (!!missionTimelineId && !!missionId) {
      // fetch total systems and total objectives
      getMissionTimelinePopupDetails(missionTimelineId, missionId);
    }
  }, [missionTimelineId, missionId]);

  const getMissionTimelinePopupDetails = async () => {
    try {
      dispatch(startLoading());
      const resp = await Network.get(API.missionTimelinePopupDetails, {
        workspaceId: selectedWorkspace,
        missionTimelineId,
        missionId,
      });
      const data = resp?.data.response.dataset[0];
      setMissionTimelinePopupDetails(data);
      setTotalSystems(data?.mission_system?.length || 0);
      setTotalObjectives(data?.test_objective?.length || 0);
      const status = statusLabel.find((sl) => sl.name === data.status);
      setSelectedStatus(status);
      dispatch(endLoading());
    } catch (e) {
      console.error(e);
      dispatch(endLoading());
    }
  };

  const tabs = [
    { label: 'Details', value: 'details' },
    { label: `System (${totalSystems || 0})`, value: 'system' },
    {
      label: `Test Objectives (${totalObjectives || 0})`,
      value: 'test_objectives',
    },
    { label: 'Activity Log', value: 'activity_log' },
  ];

  const onChangeStatus = async (e) => {
    if (e.value.id === selectedStatus?.value.id) return;
    try {
      dispatch(startLoading());
      await Network.post(API.updateMissionTimelineStatus, {
        workspaceId: selectedWorkspace,
        id: missionTimelineId,
        status: e.value.id,
        previous_data: {
          status: selectedStatus?.value.status,
        },
        current_data: {
          status: e.value.status,
        },
      });
      setSelectedStatus(e);
      updateMissionTimeList();
    } catch (e) {
      console.error(e);
      dispatch(endLoading());
    }
  };

  return (
    <>
      <Modal
        show={show}
        onHide={onClose}
        centered
        backdrop="static"
        keyboard={false}
        size="xl"
        fullscreen="xl-down"
      >
        <Modal.Header closeButton closeVariant="white">
          <Modal.Title>
            {missionTimelinePopupDetails?.mission_timeline_name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row d-block d-md-flex">
            <div className="col mb-3 mb-md-0">
              <p className="m-0">
                <span className="weight-600 d-block mb-1">Start Date/Time</span>
                {moment(
                  missionTimelinePopupDetails?.mission_timeline_start_date
                ).format('DD MMM YY - hh:mm a')}
              </p>
            </div>
            <div className="col mb-3 mb-md-0">
              <p className="m-0">
                <span className="weight-600 d-block mb-1">Mission</span>
                <span
                  className="status"
                  style={{
                    background: missionTimelinePopupDetails?.mission_color,
                  }}
                >
                  {missionTimelinePopupDetails?.mission_name}
                </span>
              </p>
            </div>
            <div className="col mb-3 mb-md-0">
              <p className="m-0">
                <span className="weight-600 d-block mb-1">Status</span>
                <span
                  className="status"
                  style={{
                    background: selectedStatus?.value?.statusColor,
                  }}
                >
                  {selectedStatus?.value?.status}
                </span>
              </p>
            </div>
            <div className="col mb-3 mb-md-0">
              <p className="m-0">
                <span className="weight-600 d-block mb-1">Mission Type</span>
                {missionTimelinePopupDetails?.mission_type_name}
              </p>
            </div>
            <div className="col">
              <p className="m-0">
                <span className="weight-600 d-block mb-1">Last Updated</span>
                {moment(
                  missionTimelinePopupDetails?.mission_timeline_updated_at
                ).fromNow()}
              </p>
            </div>
          </div>
          <hr className="dashed d-block d-md-none" />
          {(tabs && !!tabs?.length && (
            <div className="tab-wrapper content-gap-none responsive switch-lg mt-md-5">
              <StylishNewSelect
                options={tabs}
                value={tabs?.find((tab) => tab?.value === key)}
                onChange={(e) => setKey(e?.value)}
                isClearable={false}
                isMulti={false}
                isSearchable={false}
                placeholder={'Choose a Tab'}
                classes="m-0"
              />
              <Tabs activeKey={key} onSelect={(k) => setKey(k)}>
                {tabs?.map((tab) => (
                  <Tab
                    key={tab?.value}
                    eventKey={tab?.value}
                    title={tab?.label}
                  >
                    {tab?.value === 'details' ? (
                      <Details
                        missionTimelinePopupDetails={
                          missionTimelinePopupDetails
                        }
                        selectedTab={key}
                      />
                    ) : tab?.value === 'system' ? (
                      <System
                        selectedTab={key}
                        primary_poc={
                          missionTimelinePopupDetails?.primary_poc || []
                        }
                        mission_system={
                          missionTimelinePopupDetails?.mission_system || []
                        }
                      />
                    ) : tab?.value === 'test_objectives' ? (
                      <TestObjectives
                        test_objective={
                          missionTimelinePopupDetails?.test_objective || []
                        }
                        selectedTab={key}
                      />
                    ) : tab?.value === 'activity_log' ? (
                      <ActivityLog
                        selectedTab={key}
                        mission_timeline_id={missionTimelineId}
                        workspaceId={selectedWorkspace}
                      />
                    ) : null}
                  </Tab>
                ))}
              </Tabs>
            </div>
          )) ||
            null}
        </Modal.Body>
        <Modal.Footer className="justify-content-start">
          <StylishNewSelect
            classes="w-100 w-md-25 mb-3 mb-md-0"
            options={statusLabel}
            value={selectedStatus}
            placeholder="Select a Status"
            onChange={(e) => {
              if (!e) {
                setSelectedStatus(null);
              } else {
                onChangeStatus(e);
              }
            }}
            menuPlacement="top"
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                borderColor: !!selectedStatus?.value?.statusColor
                  ? `${selectedStatus?.value?.statusColor} !important`
                  : '',
                background: !!selectedStatus?.value?.statusColor
                  ? `${selectedStatus?.value?.statusColor} !important`
                  : '',
              }),
              indicatorSeparator: (baseStyles, state) => ({
                ...baseStyles,
                background: !!selectedStatus?.value?.statusColor
                  ? `#fff !important`
                  : '',
              }),
            }}
          />
          <div className="button-group ms-auto">
            <StylishNewButton
              className="button--primary"
              onClick={() =>
                navigate(
                  `${PreparePath}/dashboard/plan/edit-mission/${missionId}`
                )
              }
            >
              Edit Mission
            </StylishNewButton>

            <StylishNewButton
              className="button--primary"
              onClick={() =>
                navigate(
                  `${PreparePath}/dashboard/plan/missions/timeline/${missionTimelineId}?page=1`
                )
              }
            >
              Edit Timeline Event
            </StylishNewButton>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};
