import React, { useEffect, useState } from 'react';
import AddEditServiceDialog from './AddEditServiceDialog';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Network from '../../../service/Network';
import API from '../../../service/api';
import StylishHelp from 'components/DesignSystems/New/StylishHelp';
import { HelpServiceDepartment, useSelfHostedKnowledgeBase } from 'assets/data/HelpContent';
import StylishNewTable from 'components/DesignSystems/New/StylishNewTable';
import { StylishNewSearchBar } from 'components/DesignSystems/New/StylishNewSearchBar';
import StylishNewTableAction from 'components/DesignSystems/New/StylishNewTableAction';
import { StylishConfirmDialog } from 'components/DesignSystems/New/StylishConfirmDialog';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { toastConfig } from 'assets/data/config';
import { PaginationDropdown } from '../../../../components/DesignSystems/PaginationDropdown';
import {
  endLoading,
  startLoading,
} from '../../../../reducers/loading/loading.action';

export default function Service() {
  const [allDepartments, setAllDepartments] = useState([]);
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [perPage, setPerPage] = useState(10);
  const [total, setTotal] = useState(0);
  const [
    showDepartmentAddEditDialog,
    setShowDepartmentAddEditDialog,
  ] = useState(false);
  const [dialogType, setDialogType] = useState('Add');
  const [updateDialogData, setUpdateDialogData] = useState(null);
  const [deleteDepartmentId, setDeleteDepartmentId] = useState(null);
  const [deleteServiceTitle, setDeleteServiceTitle] = useState('');

  const selfHostedKnowledgeBase = useSelfHostedKnowledgeBase()

  const dispatch = useDispatch();

  const workspaceId = useSelector(
    (state) => state.prepare.workspace.selectedWorkspace
  );

  const columns = [
    {
      dataField: 'title',
      text: 'Name',
      sort: true,
      headerAttrs: { title: 'Sort By:' },
      attrs: { title: 'Name' },
    },
    {
      dataField: '',

      text: 'Action',
      isDummyField: true,
      formatter: (cell, row, rowIndex) => (
        <StylishNewTableAction
          name="City/Town/Service/Department"
          cell={cell}
          row={row}
          rowIndex={rowIndex}
          onDelete={onDeletegetAllDepartment}
          onEdit={onEditgetAllDepartment}
        />
      ),
      headerStyle: {
        width: '100px',
      },
      headerAlign: 'center',
      align: 'center',
      attrs: { title: 'Action' },
    },
  ];

  const planDepartmentList = async () => {
    dispatch(startLoading());
    try {
      const department = await Network.get(API.planDepartmentList, {
        limit: perPage,
        page: page,
        search: searchTerm,
        workspaceId: workspaceId,
      });

      setAllDepartments(department.data.response.dataset);

      setTotal(department.data.response.count);
    } catch (error) {
      toast.error(error.response.data.response.status.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };
  useEffect(() => {
    planDepartmentList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, perPage, searchTerm, workspaceId]);

  const onDeleteConfirm = async () => {
    dispatch(startLoading());
    try {
      await Network.post(API.deletePlanDepartment, {
        departmentId: deleteDepartmentId,
      });
      setDeleteDepartmentId(null);
      planDepartmentList();
      toast.success('Deleted  successfully', toastConfig);
    } catch (error) {
      toast.error(error.response.data.response.status.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };

  const onAddgetAllDepartment = () => {
    setDialogType('Add');
    setShowDepartmentAddEditDialog(true);
  };
  const onDeletegetAllDepartment = (row) => {
    setDeleteDepartmentId(row.id);
    setDeleteServiceTitle(row.title);
  };
  const onEditgetAllDepartment = (row) => {
    setUpdateDialogData(row);
    setDialogType('Edit');
    setShowDepartmentAddEditDialog(true);
  };
  const OnAddOrEditgetAllDepartment = (afterSave) => {
    setUpdateDialogData(null);
    setDialogType('New');
    setShowDepartmentAddEditDialog(false);
    if (afterSave?.id) {
      planDepartmentList();
    }
  };
  const onChangeSearchTerm = (term) => {
    setPage(1);
    setSearchTerm(term);
  };
  return (
    <>
      <div className="settings__service&department">
        <StylishNewButton
          className="button button--primary mb-4"
          onClick={onAddgetAllDepartment}
        >
          Add City/Town/Service/Department
        </StylishNewButton>
        <div className="form-block">
          <div className="row">
            <div className="col-md-12">
              <div className="d-flex flex-column flex-md-row align-items-md-center mb-4">
                <div className="d-flex align-items-center justify-content-between">
                  <p className="m-0 me-3 text-nowrap">
                    {total || 0} City/Town/Service/Department
                  </p>
                  <div className="">
                    <PaginationDropdown
                      setPage={setPage}
                      setPerPage={setPerPage}
                      options={[
                        { value: 10, label: '10 per page' },
                        { value: 30, label: '30 per page' },
                        { value: 60, label: '60 per page' },
                        { value: 120, label: '120 per page' },
                      ]}
                    />
                  </div>
                </div>
                <div className="ms-md-auto mt-3 mt-md-0 d-flex align-items-center">
                  <div className="flex-grow-1">
                    <StylishNewSearchBar
                      onChangeSearchTerm={onChangeSearchTerm}
                    />
                  </div>
                  <StylishHelp
                    classes={'ms-3 flex-shrink-0 sml'}
                    title={HelpServiceDepartment.title}
                    content={HelpServiceDepartment.content}
                    link={selfHostedKnowledgeBase || HelpServiceDepartment.link}
                  />
                </div>
              </div>
            </div>
          </div>
          <StylishNewTable
            keyField={'id'}
            columns={columns}
            rows={allDepartments}
            onDelete={onDeletegetAllDepartment}
            onEdit={onEditgetAllDepartment}
            pagination
            page={page}
            perPage={perPage}
            total={total}
            onPageChange={(page) => setPage(page)}
          />
        </div>
        {showDepartmentAddEditDialog && (
          <AddEditServiceDialog
            show={showDepartmentAddEditDialog}
            onClose={OnAddOrEditgetAllDepartment}
            dialogType={dialogType}
            updateDialogData={updateDialogData}
          />
        )}
        {deleteDepartmentId && (
          <StylishConfirmDialog
            show={deleteDepartmentId}
            onClose={() => setDeleteDepartmentId(null)}
            dialogTitle={'Delete City/Town/Service/Department'}
            dialogContentType={'html'}
            dialogContent={`
              Deleting <span class="weight-700 break-word">${deleteServiceTitle}</span> will remove all its occurance from this workspace.<span class="d-block pt-3">Are you sure you want to delete this City/Town/Service/Department ?</span>`}
            onConfirm={onDeleteConfirm}
          />
        )}
      </div>
    </>
  );
}
