import React, { useEffect, useState } from 'react';
import AddEditMetricDialog from './AddEditMetricDialog';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Network from '../../../service/Network';
import API from '../../../service/api';
import StylishNewTable from 'components/DesignSystems/New/StylishNewTable';
import { StylishNewSearchBar } from 'components/DesignSystems/New/StylishNewSearchBar';
import StylishNewSelect from 'components/DesignSystems/New/StylishNewSelect';
import StylishNewTableAction from 'components/DesignSystems/New/StylishNewTableAction';
import { StylishConfirmDialog } from 'components/DesignSystems/New/StylishConfirmDialog';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import StylishHelp from 'components/DesignSystems/New/StylishHelp';
import { HelpMetric, useSelfHostedKnowledgeBase } from 'assets/data/HelpContent';
import { toastConfig } from 'assets/data/config';
import { PaginationDropdown } from '../../../../components/DesignSystems/PaginationDropdown';
import {
  endLoading,
  startLoading,
} from '../../../../reducers/loading/loading.action';

export default function Metric() {
  const [allMetric, setAllMetric] = useState([]);
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [perPage, setPerPage] = useState(15);
  const [total, setTotal] = useState(0);
  const [showMetricAddEditDialog, setShowMetricAddEditDialog] = useState(false);
  const [dialogType, setDialogType] = useState('Add');
  const [updateDialogData, setUpdateDialogData] = useState(null);
  const [deleteMetricId, setDeleteMetricId] = useState(null);
  const [orderBy, setOrderBy] = useState('');
  const [deleteMetricTitle, setDeleteMetricTitle] = useState('');

  const selfHostedKnowledgeBase = useSelfHostedKnowledgeBase()

  const dispatch = useDispatch();
  const workspaceId = useSelector(
    (state) => state.prepare.workspace.selectedWorkspace
  );

  const participantOptions = [
    {
      value: 'DOD',
      label: 'DOD',
    },
    {
      value: 'HSEEP',
      label: 'HSEEP',
    },
  ];
  const [participantOption, setParticipantOption] = useState(
    participantOptions[0]
  );

  const columns = [
    {
      dataField: 'title',
      text: 'Name',
      sort: true,
      headerAttrs: { title: 'Sort By:' },
      attrs: { title: 'Name' },
      onSort: (field, order) => {
        if (order === 'asc') {
          setOrderBy(0);
        } else if (order === 'desc') {
          setOrderBy(1);
        } else {
          setOrderBy('');
        }
      },
    },
    {
      dataField: 'participantType',
      text: 'Participant Type',
      attrs: { title: 'Participant Type' },
    },

    {
      dataField: '',
      text: 'Action',
      isDummyField: true,
      formatter: (cell, row, rowIndex) => (
        <StylishNewTableAction
          name="Metric"
          cell={cell}
          row={row}
          rowIndex={rowIndex}
          onDelete={onDeletegetAllMetric}
          onEdit={onEditgetAllMetric}
        />
      ),
      headerStyle: {
        width: '100px',
      },
      headerAlign: 'center',
      align: 'center',
      attrs: { title: 'Action' },
    },
  ];

  const planMetricList = async () => {
    dispatch(startLoading());
    try {
      const newMetric = await Network.get(API.planMetricList, {
        limit: perPage,
        filter: participantOption.value,
        sort: orderBy,
        page: page,
        search: searchTerm,
        workspaceId: workspaceId,
      });

      setAllMetric(newMetric.data.response.dataset);
      setTotal(newMetric.data.response.count);
    } catch (error) {
      toast.error(error?.response?.data?.response?.status?.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };
  useEffect(() => {
    planMetricList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, perPage, searchTerm, workspaceId, participantOption, orderBy]);

  const onAddgetAllMetric = () => {
    setDialogType('Add');
    setShowMetricAddEditDialog(true);
  };

  const onDeletegetAllMetric = (row) => {
    setDeleteMetricId(row.id);
    setDeleteMetricTitle(row.title);
  };

  const onEditgetAllMetric = (row) => {
    setUpdateDialogData(row);
    setDialogType('Edit');
    setShowMetricAddEditDialog(true);
  };

  const OnAddOrEditgetAllMetric = (afterSave) => {
    setUpdateDialogData(null);
    setDialogType('New');
    setShowMetricAddEditDialog(false);
    if (afterSave?.id) {
      planMetricList();
    }
  };

  const onDeleteConfirm = async () => {
    dispatch(startLoading());
    try {
      await Network.post(API.deletePlanMetric, {
        metricId: deleteMetricId,
      });
      setDeleteMetricId(null);
      planMetricList();
      toast.success('Deleted  successfully', toastConfig);
    } catch (error) {
      toast.error(error.response.data.response.status.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };

  const onChangeSearchTerm = (term) => {
    setPage(1);
    setSearchTerm(term);
  };

  return (
    <>
      <div className="settings__exercise-type">
        <StylishNewButton
          className="button button--primary mb-4"
          onClick={onAddgetAllMetric}
        >
          Add Metric
        </StylishNewButton>

        <div className="form-block">
          <div className="d-lg-flex align-items-lg-center mb-4">
            <div className="d-md-flex align-items-md-center">
              <p className="m-0 me-md-3 mb-3 mb-md-0 text-nowrap ">
                {total || 0} Metrics
              </p>
              <div className="d-flex align-items-center ms-md-auto">
                <p className="mb-0 me-3 flex-shrink-0">Participant Type</p>
                <div className="flex-grow-1">
                  <StylishNewSelect
                    options={participantOptions}
                    onChange={(e) => {
                      setParticipantOption(e);
                      setPage(1);
                    }}
                    value={participantOption || participantOptions[0]}
                    placeholder={'Sort By...'}
                    isClearable={false}
                    isSearchable={false}
                    isMulti={false}
                  />
                </div>
              </div>
            </div>
            <div className="d-md-flex align-items-md-center ms-auto mt-3 mt-lg-0">
              <StylishNewSearchBar onChangeSearchTerm={onChangeSearchTerm} />
              <div className="ms-md-auto ms-lg-3 mt-3 mt-md-0 d-flex align-items-center">
                <div className="flex-grow-1">
                  <PaginationDropdown
                    setPage={setPage}
                    setPerPage={setPerPage}
                    options={[
                      { value: 15, label: '15 per page' },
                      { value: 30, label: '30 per page' },
                      { value: 60, label: '60 per page' },
                      { value: 100, label: '100 per page' },
                    ]}
                  />
                </div>
                <StylishHelp
                  classes={'ms-3 flex-shrink-0 sml'}
                  title={HelpMetric.title}
                  content={HelpMetric.content}
                  link={selfHostedKnowledgeBase || HelpMetric.link}
                />
              </div>
            </div>
          </div>

          <StylishNewTable
            keyField={'id'}
            columns={columns}
            rows={allMetric}
            onDelete={onDeletegetAllMetric}
            onEdit={onEditgetAllMetric}
            pagination
            page={page}
            perPage={perPage}
            total={total}
            onPageChange={(page) => setPage(page)}
          />
        </div>
        {showMetricAddEditDialog && (
          <AddEditMetricDialog
            show={showMetricAddEditDialog}
            onClose={OnAddOrEditgetAllMetric}
            dialogType={dialogType}
            updateDialogData={updateDialogData}
          />
        )}
        {deleteMetricId && (
          <StylishConfirmDialog
            show={deleteMetricId}
            onClose={() => setDeleteMetricId(null)}
            dialogTitle={'Delete Metric'}
            dialogContentType={'html'}
            dialogContent={`
              Deleting <span class="weight-700 break-word">${deleteMetricTitle}</span> will remove all its occurance from this workspace.<span class="d-block pt-3">Are you sure you want to delete this Metric ?</span>`}
            onConfirm={onDeleteConfirm}
          />
        )}
      </div>
    </>
  );
}
