import React, { useEffect, useRef, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import IconChooseMedia from 'assets/images/icon__choosemedia.svg';
import AddRelationshipsDialog from './AddRelationshipsDialog';
import IconLocation from 'assets/images/icon__location.svg';
import IconDelete from 'assets/images/icon__delete.svg';
import { ViewPersonaHelp, useSelfHostedKnowledgeBase } from 'assets/data/HelpContent';
import StylishNewInput from 'components/DesignSystems/New/StylishNewInput';
import AddProfilestoPersonaDialog from './AddProfilestoPersonaDialog';
import ReviewProfilesDialog from './ReviewProfilesDialog';
import ChooseMediaDialog from '../ChooseMediaModal/ChooseMediaDialog';
import IconEdit from 'assets/images/icon__edit.svg';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import IconView from 'assets/images/icon__eye.svg';
import IconTimes from 'assets/images/icon__times.svg';
import Network from 'PREPAREsrc/service/Network';
import API from 'PREPAREsrc/service/api';
import { endLoading, startLoading } from 'reducers/loading/loading.action';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { SelectLocation } from 'PREPAREsrc/pages/plan/Prepare/MSEL/Location/SelectLocation';
import { PreparePath } from 'utils/sharedUtils/sharedConst';
import { StylishSwitcher } from 'components/DesignSystems/New/StylishSwitcher';
import { Tree, TreeNode } from 'react-organizational-chart';
import { StylishConfirmDialog } from 'components/DesignSystems/New/StylishConfirmDialog';
import IconEmptyExercise from 'assets/images/icon__exercise-details-empty.svg';
import moment from 'moment';
import { cardStringLength, toastConfig } from 'assets/data/config';
import StylishHelp from 'components/DesignSystems/New/StylishHelp';
import TinyMCEWithFormController from 'components/DesignSystems/New/TinyMCEWithFormController';

export default function UseBlankPersona() {
  const {
    handleSubmit,
    setValue,
    formState: { errors },
    control,
  } = useForm();

  const selfHostedKnowledgeBase =useSelfHostedKnowledgeBase()

  const [nameOfPersona, setNameOfPersona] = useState(null);
  const [keyPressForPersonaName, setKeyPressForPersonaName] = useState('');
  const [message, setMessage] = useState('');
  const [noStrike, setNoStrike] = useState(false);
  const [connectedProfiles, setConnectedProfiles] = useState(null);
  const [isDefaultAttribute, setIsDefaultAttribute] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const [imageAvatar, setImageAvatar] = useState(null);
  const [filteredProfileArray, setFilteredProfileArray] = useState([]);
  const [filteredRelationshipArray, setFilteredRelaionshipArray] = useState([]);
  const [detailsPersonaByID, setDetailsPersonaByID] = useState([]);
  const [
    connectedProfilesForDropDown,
    setConnectedProfilesForDropDown,
  ] = useState([]);

  const [typeModalProfile, setTypeModalProfile] = useState(false);
  const [isVisibleAvatar, setIsVisibleAvatar] = useState(false);
  const [showChooseMediaDialog, setShowChooseMediaDialog] = useState(false);
  const [showAddRelationshipsDialog, setShowAddRelationshipsDialog] = useState(
    false
  );
  const [
    showAddProfilestoPersonaDialog,
    setShowAddProfilestoPersonaDialog,
  ] = useState(false);
  const [imageAvatarFrom, setImageAvatarFrom] = useState(false);
  const [showReviewProfilesDialog, setShowReviewProfilesDialog] = useState(
    false
  );
  const [selectLocation, setSelectLocation] = useState(false);
  const [lng, setLng] = useState('');
  const [lat, setLat] = useState('');
  const [place, setPlace] = useState('');
  const [deleteProfiles, setDeleteProfiles] = useState(null);

  const { fields, append, remove, insert } = useFieldArray({
    control,
    name: 'myFields',
  });

  const dispatch = useDispatch();

  const workspaceId = useSelector(
    (state) => state.prepare.workspace.selectedWorkspace
  );
  const isMounted = useRef(true);
  const params = useParams();

  const navigate = useNavigate();

  const onChooseMediaDialog = () => {
    setShowChooseMediaDialog(true);
  };
  const onChooseMediaDialogClose = () => {
    setShowChooseMediaDialog(false);
  };

  const onAddRelationshipsDialog = (e) => {
    e.preventDefault();
    setShowAddRelationshipsDialog(true);
  };
  const onAddRelationshipsDialogClose = () => {
    setShowAddRelationshipsDialog(false);
    mediaPersonaDetailsByPersonaId();
  };
  const onKeyPressForPersonaName = () => {
    let namePersona = document.getElementById('name');
    setKeyPressForPersonaName(namePersona.value);
  };

  const onAddProfilestoPersonaDialog = (e) => {
    e.preventDefault();
    setShowAddProfilestoPersonaDialog(true);
  };
  const onAddProfilestoPersonaDialogClose = () => {
    setShowAddProfilestoPersonaDialog(false);
  };
  const onIsCloseModal = (data) => {
    if (data === true) {
      setShowAddProfilestoPersonaDialog(false);
    }
  };
  const onReviewProfilesDialog = () => {
    setShowReviewProfilesDialog(true);
    setShowAddProfilestoPersonaDialog(false);
  };

  const onReviewProfilesDialogClose = () => {
    setShowReviewProfilesDialog(false);
  };
  const onSubmitImageAvatar = (data) => {
    setShowChooseMediaDialog(false);
    if (data !== undefined) {
      setImageAvatar(data);
      setIsVisibleAvatar(true);
    }
  };
  const onFinalRelationshipData = (data) => {
    setShowAddRelationshipsDialog(false);
    const filteredArray = data.map(function (mediaRelation) {
      return {
        linkedPersona: mediaRelation?.relationOfValue,
        relationLable: mediaRelation?.isThe,
        name: mediaRelation?.relationOfLabel,
        personaId: params?.id,
      };
    });
    setFilteredRelaionshipArray(filteredArray);
    dispatch(startLoading());
    try {
      Network.post(
        params?.id && params?.id?.length > 0
          ? API.updateMediaPersona
          : API.createMediaPersona,
        {
          personaId: params?.id,
          workspaceId: workspaceId,
          avatar: imageAvatar,
          profiles: filteredProfileArray,
          personas: filteredArray,
          lat: lat || null,
          lng: lng || null,
          locationName: place || null,
          noStrike: noStrike,
          updatedAt: moment().toISOString(),
        }
      )
        .then((resp) => {
          toast.success(
            params?.id || (params?.id !== undefined && params?.id?.length > 0)
              ? 'Persona updated successfully'
              : 'Persona created successfully',
            toastConfig
          );
          mediaPersonaDetailsByPersonaId();
        })
        .catch(console.log)
        .finally(() => {
          dispatch(endLoading());
        });
    } catch (error) {
      console.log('error', error);
      toast.error(error?.response?.data?.response?.status?.msg, toastConfig);
    }
  };

  const ondeleteProfiles = (row) => {
    setDeleteProfiles(row.id);
  };

  const onDeleteConfirmProfiles = async () => {
    dispatch(startLoading());

    try {
      await Network.post(API.removeMediaPersonaProfile, {
        personaId: params?.id,
        profileId: deleteProfiles,
      });
      setFilteredProfileArray(
        filteredProfileArray.filter(
          (obj) => obj.mediaProfileId !== deleteProfiles
        )
      );
      setConnectedProfilesForDropDown(
        connectedProfilesForDropDown.filter(
          (obj) => obj.value !== deleteProfiles
        )
      );
      setConnectedProfiles(
        connectedProfiles.filter((obj) => obj.id !== deleteProfiles)
      );
      setDeleteProfiles(null);

      toast.success('Profile removed successfully', toastConfig);
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(endLoading());
    }
  };

  const onGetProfileArray = (data) => {
    const filteredArray = data?.map(function (mediaProfile) {
      return { mediaProfileId: mediaProfile?.value };
    });

    setFilteredProfileArray(filteredArray);
    setConnectedProfilesForDropDown(data);

    let statusUpdateRequired = false;
    if (data?.length === connectedProfiles?.length) {
      for (let i = 0; i < data?.length; i++) {
        if (data[i]?.value !== connectedPersonas[i]?.id) {
          setMessage('* Pending updates');
          statusUpdateRequired = true;
          break;
        }
      }
    } else {
      setMessage('* Pending updates');
      statusUpdateRequired = true;
    }
    statusUpdateRequired === false ? setMessage('') : null;
  };

  useEffect(() => {
    if (
      params?.type === 'usedefaultpersona' &&
      !params?.id &&
      !(params?.id?.length > 0)
    ) {
      if (isDefaultAttribute === false) {
        append([
          {
            key: 'Aliases',
            value: '',
          },
          {
            key: 'Sex',
            value: '',
          },
          {
            key: 'Birth Date',
            value: '',
          },
          {
            key: 'Age',
            value: '',
          },
          {
            key: 'Hair Color',
            value: '',
          },
          {
            key: 'Eye Color',
            value: '',
          },
          {
            key: 'Height',
            value: '',
          },
          {
            key: 'Religion',
            value: '',
          },
          {
            key: 'Ethnicity',
            value: '',
          },
          {
            key: 'Residence',
            value: '',
          },
          {
            key: 'Hometown',
            value: '',
          },
        ]);
      }
      setIsDefaultAttribute(true);
    }
  }, []);

  const selectLocationHandler = () => {
    setSelectLocation(true);
  };

  const onCloseModal = () => {
    setSelectLocation(false);
  };
  const onSubmitImageAvatarFrom = (data) => {
    if (data !== undefined) {
      setImageAvatarFrom(data);
    }
  };

  const onSaveClicked = (changedlat, Changedlng, changedplace) => {
    setLat(changedlat);
    setLng(Changedlng);
    setPlace(changedplace);
  };

  const SeatBox = ({ className, seatName }) => {
    return (
      <div className={`chartBox${className ? ' ' + className : ''}`}>
        {seatName && <div className="chartBox__role">{seatName}</div>}
      </div>
    );
  };

  const mediaPersonaDetailsByPersonaId = () => {
    try {
      dispatch(startLoading());
      Network.get(API.mediaPersonaDetailsByPersonaId, {
        workspaceId: workspaceId,
        personaId: params?.id,
      })
        .then((resp) => {
          setDetailsPersonaByID(resp?.data?.response?.dataset[0]);
        })
        .catch(console.log)
        .finally(dispatch(endLoading()));
    } catch (error) {
      toast.error(error.response.data.response.status.msg, toastConfig);
    }
  };

  useEffect(() => {
    if (params?.id && params?.id?.length > 0) mediaPersonaDetailsByPersonaId();
  }, []);

  const onSubmit = async (data) => {
    const value = data?.description;

    dispatch(startLoading());
    try {
      Network.post(
        params?.id && params?.id?.length > 0
          ? API.updateMediaPersona
          : API.createMediaPersona,
        {
          personaId: params?.id,
          workspaceId: workspaceId,
          name: data?.name,
          role: data?.role,
          avatar: imageAvatar,
          attributes: data?.myFields,
          description: value || null,
          profiles: filteredProfileArray,
          personas: filteredRelationshipArray,
          lat: lat || null,
          lng: lng || null,
          locationName: place || null,
          noStrike: noStrike,
          updatedAt: moment().toISOString(),
        }
      )
        .then((resp) => {
          navigate(`${PreparePath}/dashboard/media/personas`);
          toast.success(
            params?.id || (params?.id !== undefined && params?.id?.length > 0)
              ? 'Persona updated successfully'
              : 'Persona created successfully',
            toastConfig
          );
        })
        .catch(console.log)
        .finally(() => {
          dispatch(endLoading());
        });
    } catch (error) {
      console.log('error', error);
      toast.error(error?.response?.data?.response?.status?.msg, toastConfig);
    }
  };

  useEffect(() => {
    if (isMounted.current) {
      isMounted.current = false;
      return;
    }
    if (!!workspaceId) {
      navigate(-1);
    }
  }, [workspaceId]);

  useEffect(() => {
    if (detailsPersonaByID !== []) {
      if (params?.id && params?.id?.length > 0) {
        setNoStrike(detailsPersonaByID?.noStrike);
        setNameOfPersona(detailsPersonaByID?.personaName);
        setValue('name', detailsPersonaByID?.personaName);
        setValue('role', detailsPersonaByID?.personaRole);
        setValue('description', detailsPersonaByID?.personaDescription);
        setLat(detailsPersonaByID?.lat);
        setLng(detailsPersonaByID?.lng);
        setPlace(detailsPersonaByID?.locationName);
        if (
          detailsPersonaByID?.personaattributes?.length > 0 &&
          isUpdated === false
        ) {
          let attributes = detailsPersonaByID?.personaattributes?.map(
            (personaattributes) => {
              return {
                key: personaattributes?.attributeKey,
                value: personaattributes?.attributeValue,
              };
            }
          );

          append(attributes);
          setIsUpdated(true);
        }
        setConnectedProfiles(detailsPersonaByID?.profiles);
        const filteredArray = detailsPersonaByID?.profiles?.map(function (
          mediaProfile
        ) {
          return { mediaProfileId: mediaProfile?.id };
        });
        setFilteredProfileArray(filteredArray);
        const filteredProfileArray = detailsPersonaByID?.profiles?.map(
          function (mediaProfile) {
            return {
              value: mediaProfile?.id,
              avatar: mediaProfile?.avatar,
              label: mediaProfile?.name,
            };
          }
        );
        setConnectedProfilesForDropDown(filteredProfileArray);
        const filteredRelation = detailsPersonaByID?.mediapersonarelatesto?.map(
          function (mediaRelation) {
            return {
              relatesToPersonaId: mediaRelation?.personaId,
              name: mediaRelation?.personaName,
              relationLable: mediaRelation?.relation,
              linkedPersona: mediaRelation?.linkedPersonaId,
              relationId: mediaRelation?.relationId,
            };
          }
        );
        setFilteredRelaionshipArray(filteredRelation);

        if (detailsPersonaByID?.avatar !== null) {
          setImageAvatar(detailsPersonaByID?.avatar);
          setIsVisibleAvatar(true);
        } else {
          setIsVisibleAvatar(false);
        }
      }
    }
  }, [detailsPersonaByID]);

  const onCreate = async (profile) => {
    dispatch(startLoading());
    try {
      const newProfile = await Network.post(API.createMediaPost, {
        status: 'drafted',
        mediaProfileId: profile?.id,
        workspaceId: workspaceId,
        type: 'post',
        mediaSiteId: profile?.siteId,
      });
      navigate(
        `${PreparePath}/dashboard/media/posts/edit-post/${newProfile?.data?.response?.MediaPost?.id}`
      );
    } catch (error) {
      console.log(error);
      toast.error('Something went wrong', toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };
  return (
    <>
      <div className="d-flex align-items-center mb-4">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb mb-0">
            <li className="breadcrumb-item">
              <StylishNewButton onClick={() => navigate(-1)}>
                <i className="fa fa-angle-left me-2" aria-hidden="true"></i>
                Back
              </StylishNewButton>
            </li>
            <li
              className="breadcrumb-item cursor-pointer"
              // aria-current="page"
            >
              <StylishNewButton
                onClick={(e) => {
                  e.preventDefault();
                  navigate(`${PreparePath}/dashboard/media/personas`);
                }}
              >
                Personas
              </StylishNewButton>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              {keyPressForPersonaName
                ? keyPressForPersonaName
                : nameOfPersona?.length > 0
                ? nameOfPersona
                : nameOfPersona?.target?.value}
            </li>
          </ol>
        </nav>
        <StylishHelp
          classes={'flex-shrink-0 ms-auto sml'}
          title={ViewPersonaHelp.title}
          content={ViewPersonaHelp.content}
          link={selfHostedKnowledgeBase || ViewPersonaHelp.link}
        />
      </div>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-block mb-4">
          <label className="status bg-success weight-600 px-4 mb-4">
            UNCLASSIFIED
          </label>
          <div className="row">
            <div className="col-md-6 mb-3">
              <label className="form-label">Name</label>
              <Controller
                control={control}
                name={'name'}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, value } }) => (
                  <StylishNewInput
                    onChange={(e) => {
                      setNameOfPersona(e);
                      onChange(e);
                    }}
                    value={value || ''}
                    type="text"
                    className="form-control"
                    placeholder="Name"
                    id="name"
                    onKeyUp={() => {
                      onKeyPressForPersonaName();
                    }}
                  />
                )}
              />
              {errors?.name && (
                <span className="form-text form-error">
                  This is a required field
                </span>
              )}
            </div>
            <div className="col-md-6 mb-3">
              <label className="form-label">Role</label>
              <Controller
                control={control}
                name={'role'}
                rules={{
                  required: false,
                }}
                render={({ field: { onChange, value } }) => (
                  <StylishNewInput
                    onChange={(e) => {
                      onChange(e);
                    }}
                    value={value || ''}
                    type="text"
                    className="form-control"
                    placeholder="Role"
                  />
                )}
              />
            </div>

            <div className="col-md-12 mb-4">
              <label className="form-label w-100"> Avatar</label>
              <span
                className="badge cursor-pointer"
                onClick={() => {
                  onChooseMediaDialog();
                  setTypeModalProfile('Avatar');
                }}
              >
                <img src={IconChooseMedia} alt="" />
                Choose Media
              </span>
              {isVisibleAvatar && (
                <>
                  <div className="d-flex align-items-start mt-3">
                    <img
                      src={imageAvatar}
                      className="me-3 rounded-5"
                      style={{ height: '200px', width: 'auto' }}
                      alt=""
                    />
                    <StylishNewButton
                      customButton
                      className={'button--icon'}
                      onClick={(e) => {
                        setIsVisibleAvatar(false);
                        setImageAvatar(null);
                      }}
                    >
                      <img src={IconTimes} alt="" />
                    </StylishNewButton>
                  </div>
                </>
              )}
            </div>
            <div className="col-md-12">
              <label className="form-label d-block">No Strike List?</label>
              <StylishSwitcher
                checked={noStrike}
                onChange={() => setNoStrike(!noStrike)}
              />
            </div>
          </div>
        </div>
        <div className="form-block mb-4">
          <h5>Description</h5>
          <TinyMCEWithFormController
            className="auto-height"
            name="description"
            control={control}
            placeholder="Write a Description"
            readOnly={false}
            defaultValue={detailsPersonaByID?.personaDescription}
          />
        </div>

        <div className="form-block mb-4">
          <h5>Location</h5>
          <span
            className="badge cursor-pointer mb-4"
            onClick={selectLocationHandler}
          >
            <img src={IconLocation} alt="" />
            Select Location
          </span>
          {lat && lng && place ? (
            <div className="d-flex">
              <p className="mb-0 me-2">
                {place}
                <br />
                Lat/Lon: {lat} {lng}
              </p>

              <StylishNewButton
                customButton
                type="button"
                className="button--icon primary ms-auto flex-shrink-0"
                onClick={() => {
                  setLng('');
                  setLat('');
                  setPlace('');
                  setSelectLocation('');
                }}
              >
                <img src={IconDelete} alt="" />
              </StylishNewButton>
            </div>
          ) : (
            <div className="bg-gray-800 text-center p-4">
              <div className="d-flex justify-content-center mb-4">
                <img src={IconEmptyExercise} alt="" />
              </div>
              <p className="weight-600 mb-0">No Location Found</p>
            </div>
          )}
        </div>
        <div className="form-block mb-4">
          <h5>Attribute</h5>
          <StylishNewButton
            className="button--primary mb-4"
            onClick={(e) => {
              e.preventDefault();
              insert(0, { key: '', value: '' });
            }}
          >
            Add
          </StylishNewButton>

          {fields?.length > 0 ? (
            <>
              {fields.map((field, index) => (
                <div className="posts-row">
                  <div className="row mt-3" key={field?.id}>
                    <div className="col-md-6 mb-3">
                      <label className="form-label">
                        Attribute Name {index + 1}
                      </label>
                      <Controller
                        control={control}
                        name={`myFields.${index}.key`}
                        rules={{ required: false }}
                        render={({ field: { onChange, value } }) => (
                          <StylishNewInput
                            type="text"
                            className="form-control"
                            onChange={onChange}
                            value={value}
                          />
                        )}
                      />

                      {/* <input
                    {...register(`name.${index}.name`)} // Register each field with a unique name
                    defaultValue={field.name} // Set the initial value
                  /> */}
                    </div>
                    <div className="col-md-6 mb-3">
                      <label className="form-label">Value</label>
                      <Controller
                        control={control}
                        name={`myFields.${index}.value`}
                        rules={{ required: false }}
                        render={({ field: { onChange, value } }) => (
                          <StylishNewInput
                            type="text"
                            className="form-control"
                            onChange={(e) => {
                              const inputValue = e.target.value.trim();
                              const newValue =
                                inputValue !== '' ? inputValue : null;
                              setValue(`myFields[${index}].value`, newValue);
                              onChange(e);
                            }}
                            // onChange={onChange}
                            value={value || ''}
                          />
                        )}
                      />
                    </div>
                  </div>
                  <StylishNewButton
                    customButton
                    type="button"
                    onClick={() => remove(index)}
                    className="button--icon lrg primary"
                  >
                    <img src={IconDelete} alt="" />
                  </StylishNewButton>
                  <hr className="dashed my-4" />
                </div>
              ))}
            </>
          ) : (
            <div className="bg-gray-800 text-center p-4">
              <div className="d-flex justify-content-center mb-4">
                <img src={IconEmptyExercise} alt="" />
              </div>
              <p className="weight-600 mb-0">No Attribute Found</p>
            </div>
          )}
        </div>
        <div className="form-block mb-4">
          <h5>Linked Relationships</h5>
          <StylishNewButton
            className="button--primary mb-4"
            onClick={(e) => {
              onAddRelationshipsDialog(e);
            }}
            disabled={
              (nameOfPersona && nameOfPersona?.target?.value?.length > 0) ||
              (params?.id?.length > 0 &&
                (nameOfPersona?.length > 0 ||
                  nameOfPersona?.target?.value?.length > 0))
                ? false
                : true
            }
          >
            Edit Relationships
          </StylishNewButton>
          {filteredRelationshipArray?.length > 0 ? (
            filteredRelationshipArray &&
            filteredRelationshipArray.length > 0 && (
              <div className="chart">
                <Tree
                  label={
                    <SeatBox
                      seatName={
                        params?.id?.length > 0
                          ? nameOfPersona?.length > 0
                            ? nameOfPersona
                            : nameOfPersona?.target?.value
                          : nameOfPersona?.target?.value
                      }
                    />
                  }
                >
                  {filteredRelationshipArray?.map((relation) => {
                    return (
                      <TreeNode
                        label={<SeatBox seatName={relation?.name} />}
                      ></TreeNode>
                    );
                  })}
                </Tree>
              </div>
            )
          ) : (
            <div className="bg-gray-800 text-center p-4">
              <div className="d-flex justify-content-center mb-4">
                <img src={IconEmptyExercise} alt="" />
              </div>
              <p className="weight-600 mb-0">No Linked Relationships Found</p>
            </div>
          )}
        </div>
        <div className="form-block mb-4">
          <div className="d-flex align-items-center mb-4">
            <StylishNewButton
              className="button--primary"
              onClick={(e) => {
                onAddProfilestoPersonaDialog(e);
              }}
            >
              Add Profiles
            </StylishNewButton>
            {!!connectedProfiles?.length ? (
              <span className="ms-3">
                {connectedProfiles?.length} Linked Profiles
              </span>
            ) : null}

            <span className="ms-3">{message}</span>
          </div>

          {connectedProfiles && connectedProfiles?.length > 0 ? (
            <>
              <div className="row">
                {connectedProfiles &&
                  connectedProfiles.length > 0 &&
                  connectedProfiles.map((profiles) => {
                    return (
                      <div
                        className="col-md-6 col-lg-4 col-xl-3 mb-4"
                        key={profiles?.id}
                      >
                        <div className="border-700 rounded h-100 d-flex flex-column overflow-hidden">
                          <div className="p-3">
                            <div className="d-flex align-items-start mb-4">
                              {profiles?.avatar ? (
                                <div className="profile-img">
                                  <img src={profiles?.avatar} alt="" />
                                </div>
                              ) : (
                                <div className="profile-img"></div>
                              )}

                              <Dropdown className="flex-shrink-0 ms-auto">
                                <Dropdown.Toggle className="button--icon">
                                  <i
                                    className="fa fa-ellipsis-v"
                                    aria-hidden="true"
                                  ></i>
                                </Dropdown.Toggle>
                                <Dropdown.Menu align="end">
                                  <Dropdown.Item
                                    onClick={() => {
                                      navigate(
                                        `${PreparePath}/dashboard/media/edit-profile/${profiles.id}`
                                      );
                                    }}
                                  >
                                    <img src={IconEdit} alt="" />
                                    Edit Profile
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    onClick={() => {
                                      ondeleteProfiles(profiles);
                                    }}
                                  >
                                    <img src={IconDelete} alt="" />
                                    Remove From Persona
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>

                            <h4 className="mb-2 break-word">
                              {profiles?.name}
                            </h4>
                            <p className="mb-2 break-word">
                              {'@'}
                              {profiles?.username}
                            </p>
                            <p className="mb-2 break-word">
                              {profiles?.siteName}
                            </p>
                            {profiles?.description?.length >
                            cardStringLength ? (
                              <p className="mb-0">
                                {profiles?.description?.slice(
                                  0,
                                  cardStringLength
                                )}
                                {'....'}
                              </p>
                            ) : (
                              <p className="mb-0">{profiles?.description}</p>
                            )}
                          </div>

                          <div className="bg-gray-700 p-3 mt-auto d-flex align-items-center justify-content-center">
                            <span
                              onClick={() => {
                                onCreate(profiles);
                              }}
                              className="text-white weight-500 d-flex align-items-center justify-content-center flex-grow-1 cursor-pointer"
                            >
                              <img src={IconEdit} className="me-2" alt="" />
                              Create Post
                            </span>
                            <div className="separator--y separator--800  flex-shrink-0"></div>

                            <Link
                              to={`${PreparePath}/dashboard/media/profiles/${profiles?.siteName?.toLowerCase()}/${profiles?.name?.toLowerCase()}/${profiles?.id?.toLowerCase()}`}
                              className="text-white weight-500 d-flex align-items-center justify-content-center flex-grow-1"
                            >
                              <img
                                src={IconView}
                                className="img-h-16 me-2"
                                alt=""
                              />
                              View Post
                            </Link>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </>
          ) : (
            <div className="bg-gray-800 text-center p-4">
              <div className="d-flex justify-content-center mb-4">
                <img src={IconEmptyExercise} alt="" />
              </div>
              <p className="weight-600 mb-0">No Profiles Found</p>
            </div>
          )}
        </div>

        <div className="button-group justify-content-end">
          <StylishNewButton
            className="button--secondary button--reverse"
            type="button"
            onClick={(e) => {
              e.preventDefault();
              navigate(`${PreparePath}/dashboard/media/personas`);
            }}
          >
            Cancel
          </StylishNewButton>
          <StylishNewButton className="button--primary" type="submit">
            Save
          </StylishNewButton>
        </div>
      </form>

      {showChooseMediaDialog && (
        <ChooseMediaDialog
          show={showChooseMediaDialog}
          onClose={onChooseMediaDialogClose}
          onSubmitImageAvatar={onSubmitImageAvatar}
          typeModalProfile={typeModalProfile}
          onSelectedImageAvatar={imageAvatar}
          imageAvatarFrom={imageAvatarFrom}
          onSubmitImageAvatarFrom={onSubmitImageAvatarFrom}
        />
      )}
      {showAddRelationshipsDialog && (
        <AddRelationshipsDialog
          show={showAddRelationshipsDialog}
          onClose={onAddRelationshipsDialogClose}
          nameOfPersona={nameOfPersona?.target?.value || nameOfPersona}
          finalRelationshipData={onFinalRelationshipData}
          linkedRelationship={filteredRelationshipArray}
          personaId={params?.id}
        />
      )}
      {showAddProfilestoPersonaDialog && (
        <AddProfilestoPersonaDialog
          show={showAddProfilestoPersonaDialog}
          onClose={onAddProfilestoPersonaDialogClose}
          onReviewProfilesDialog={onReviewProfilesDialog}
          isCloseModal={onIsCloseModal}
          profileSelectToCreatePersonaPage={onGetProfileArray}
          keyPressForPersonaName={keyPressForPersonaName || nameOfPersona}
          imageAvatar={imageAvatar}
          connectedProfilesForDropDown={connectedProfilesForDropDown}
        />
      )}

      {showReviewProfilesDialog && (
        <ReviewProfilesDialog
          show={showReviewProfilesDialog}
          onClose={onReviewProfilesDialogClose}
        />
      )}
      {selectLocation && (
        <SelectLocation
          show={selectLocation}
          onClose={onCloseModal}
          onSaveClicked={onSaveClicked}
          storedLat={lat}
          storedLng={lng}
          storedPlace={place}
        />
      )}
      {deleteProfiles && (
        <StylishConfirmDialog
          show={deleteProfiles}
          onClose={() => setDeleteProfiles(null)}
          dialogTitle={'Delete profile from persona'}
          dialogContent={'Are you sure you want this?'}
          onConfirm={onDeleteConfirmProfiles}
        />
      )}
    </>
  );
}
