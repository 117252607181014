import Network from 'PREPAREsrc/service/Network';
import API from 'PREPAREsrc/service/api';
import { AllIcon } from 'assets/Icon/Icon';
import { toastConfig } from 'assets/data/config';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { endLoading, startLoading } from 'reducers/loading/loading.action';
import { PreparePath } from 'utils/sharedUtils/sharedConst';
import IconDownload from 'assets/images/icon__download.svg';
import fileDownload from 'js-file-download';
export const Review = (props) => {
  const { setTimelineName } = props;

  const params = useParams();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const [missionTimelineReviewData, setMissionTimelineReviewData] = useState(
    null
  );

  const { id } = params;

  const { selectedWorkspace: workspaceId } = useSelector(
    (state) => state.prepare.workspace
  );

  const fetchMissionTimelineReview = () => {
    try {
      dispatch(startLoading());
      Network.get(API.fetchMissionTimelineReview, { id, workspaceId })
        .then((resp) => {
          setMissionTimelineReviewData(resp?.data?.response?.dataset?.[0]);
          setTimelineName(resp?.data?.response?.dataset?.[0]?.name);
        })
        .catch((error) => {
          console.error(error);
          toast.error(
            'Mission Timeline Review data fetch failed!',
            toastConfig
          );
        })
        .finally(() => dispatch(endLoading()));
    } catch (error) {
      console.error(error);
    }
  };

  const onPrevious = () => {
    if (params.id) {
      navigate(
        `${PreparePath}/dashboard/plan/missions/timeline/${params.id}?page=3`
      );
    } else {
      navigate(`${PreparePath}/dashboard/plan/missions/timeline`);
    }
  };

  useEffect(() => {
    fetchMissionTimelineReview();
  }, [workspaceId, id]);

  const completeReview = () => {
    navigate(`${PreparePath}/dashboard/plan/missions/timeline`);
  };
  const onDownload = async (file) => {
    let fullFileName = file.split('/')[file.split('/').length - 1];
    try {
      dispatch(startLoading());
      const downloadFile = await Network.post(API.generateSignedUrl, {
        url: file,
      });
      const res = await fetch(downloadFile.data.response.singedUrl, {
        headers: {},
      });

      fileDownload(
        await res.blob(),
        fullFileName.split('-')[fullFileName.split('-').length - 1]
      );
    } catch (error) {
      console.log('Error: ', error);
      toast.error('Failed to download file', toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };

  const handleVignetteDelete = async (id) => {
    try {
      dispatch(startLoading());
      const response = await Network.post(API.deleteVignette, {
        id,
        workspaceId,
      });
      if (response !== undefined && response?.status === 200) {
        fetchMissionTimelineReview();
        dispatch(endLoading());
      }
    } catch (error) {
      console.error(error);
      dispatch(endLoading());
    }
  };

  return (
    <>
      <h4>Review</h4>
      <div className="form-block">
        <h4 className="mb-4">Mission Timeline Event</h4>
        <div className="row">
          <div className="col-md-3">
            <p className="mb-4">
              <span className="weight-600 d-block mb-1">Event Name</span>
              {missionTimelineReviewData?.name}
            </p>
          </div>
          <div className="col-md-3">
            <p className="mb-4">
              <span className="weight-600 d-block mb-1">Start Date/Time</span>
              {moment(missionTimelineReviewData?.start_date).format(
                'DD MMM YY - HH:mm A'
              )}
              {/* 10 Jun 24 - 09:00 am */}
            </p>
          </div>
          <div className="col-md-3">
            <p className="mb-4">
              <span className="weight-600 d-block mb-1">Status</span>
              <span
                className="status status--sml"
                style={{ background: missionTimelineReviewData?.status_color }}
              >
                {missionTimelineReviewData?.status_title}
              </span>
            </p>
          </div>
          <div className="col-md-3">
            <p className="mb-4">
              <span className="weight-600 d-block mb-1">Profile(s)</span>
              {missionTimelineReviewData?.profiles
                ?.map((profile) => profile.name)
                ?.join(', ')}
            </p>
          </div>
          <div className="col-md-3">
            <p className="mb-4">
              <span className="weight-600 d-block mb-1">Event Cell(s)</span>
              {missionTimelineReviewData?.cells
                ?.map((cell) => cell.name)
                ?.join(', ')}
            </p>
          </div>
          <div className="col-md-3">
            <p className="mb-4">
              <span className="weight-600 d-block mb-1">Notification</span>
              <span className="d-flex align-items-center">
                <span
                  style={{
                    background:
                      missionTimelineReviewData?.notification_method_color,
                    width: '1.5rem',
                    height: '1.5rem',
                  }}
                  className="rounded-5 me-2 d-flex align-items-center justify-content-center has-img-h-16"
                >
                  {
                    AllIcon.find(
                      (icon) =>
                        icon.name ===
                        missionTimelineReviewData?.notification_method_icon
                    )?.icon
                  }
                </span>
                {missionTimelineReviewData?.notification_method_name}
              </span>
            </p>
          </div>
          <div className="col-md-3">
            <p className="mb-4">
              <span className="weight-600 d-block mb-1">Vignette</span>
              {missionTimelineReviewData?.vignette ? (
                <span className="d-flex align-items-center">
                  <span className="color-brand">
                    {missionTimelineReviewData?.vignette &&
                      missionTimelineReviewData?.vignette
                        ?.split('/')
                        .pop()
                        .replace(/%20/g, ' ')
                        .split('-')
                        .slice(1)
                        .join('-')}
                  </span>
                  <span
                    className="ms-3 cursor-pointer"
                    onClick={() =>
                      onDownload(missionTimelineReviewData?.vignette)
                    }
                  >
                    <img src={IconDownload} alt="" />
                  </span>
                  <span
                    className="ms-3 cursor-pointer has-img-h-16"
                    onClick={() =>
                      handleVignetteDelete(missionTimelineReviewData?.id)
                    }
                  >
                    <svg
                      width="11"
                      height="12"
                      viewBox="0 0 11 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.57305 6.84982C8.57305 5.85587 8.57091 4.86193 8.57391 3.86758C8.5752 3.44394 8.93507 3.16655 9.32365 3.31484C9.46889 3.3704 9.58285 3.52768 9.67924 3.66005C9.72937 3.72868 9.71994 3.84388 9.71994 3.93784C9.72208 5.89182 9.72808 7.8458 9.71866 9.79978C9.71437 10.6932 9.29966 11.38 8.4471 11.7672C8.14206 11.9057 7.77747 11.9801 7.43816 11.985C5.9104 12.0075 4.38221 11.9997 2.85446 11.9936C1.55762 11.9883 0.575671 11.0556 0.571816 9.82143C0.565818 7.85397 0.569245 5.8861 0.570959 3.91864C0.570959 3.45456 0.90213 3.18575 1.31899 3.30259C1.55548 3.36877 1.71057 3.5669 1.71742 3.82509C1.72342 4.04283 1.71871 4.26099 1.71871 4.47873C1.71871 6.24193 1.71871 8.00554 1.71871 9.76873C1.71871 10.4681 2.17155 10.8991 2.9063 10.8991C4.39849 10.8995 5.89069 10.8995 7.38289 10.8991C8.1185 10.8991 8.57134 10.4685 8.5722 9.76996C8.57262 8.79644 8.5722 7.82292 8.5722 6.849L8.57305 6.84982Z"
                        fill="#efa2a9"
                      />
                      <path
                        d="M2.86541 1.63502C2.86541 1.28246 2.86369 0.957278 2.86584 0.6325C2.86884 0.212127 3.08219 0.00296181 3.52047 0.00214476C4.60524 -0.00071492 5.69 -0.00071492 6.77477 0.00214476C7.21176 0.00337034 7.42298 0.213353 7.42555 0.636177C7.42769 0.960955 7.42555 1.28614 7.42555 1.63502C7.5258 1.63502 7.60206 1.63502 7.67832 1.63502C8.33466 1.63502 8.99144 1.63053 9.64778 1.63666C10.1092 1.64074 10.3894 1.98472 10.251 2.36383C10.173 2.5779 10.0072 2.69188 9.77759 2.72579C9.72147 2.73396 9.66363 2.73028 9.60665 2.73028C6.63082 2.73028 3.65499 2.72946 0.679163 2.73232C0.39726 2.73232 0.169768 2.65552 0.0510942 2.39856C-0.124131 2.01904 0.170196 1.64074 0.646174 1.63666C1.29566 1.63094 1.94515 1.63502 2.59422 1.63502C2.67219 1.63502 2.75016 1.63502 2.86455 1.63502H2.86541ZM4.0123 1.1068V1.61868H6.27566V1.1068H4.0123Z"
                        fill="#efa2a9"
                      />
                      <path
                        d="M3.43192 6.79466C3.43192 6.18881 3.43063 5.58297 3.43235 4.97712C3.43321 4.60986 3.65941 4.3676 3.99787 4.36434C4.33204 4.36107 4.57752 4.60496 4.57881 4.96691C4.58309 6.19903 4.58309 7.43114 4.57881 8.66325C4.57752 9.0248 4.33204 9.2691 3.99787 9.26583C3.65984 9.26256 3.43321 9.01949 3.43235 8.65263C3.43063 8.03331 3.43192 7.41357 3.43192 6.79425V6.79466Z"
                        fill="#efa2a9"
                      />
                      <path
                        d="M6.8602 6.81509C6.8602 7.44136 6.8602 8.06763 6.8602 8.6939C6.8602 8.93411 6.75181 9.11713 6.52218 9.21722C6.31782 9.30628 6.09375 9.29933 5.94466 9.14246C5.82985 9.02194 5.72445 8.83974 5.72231 8.68328C5.70432 7.43768 5.7086 6.19209 5.7146 4.94608C5.71631 4.59802 5.96694 4.36066 6.29426 4.36393C6.62628 4.3672 6.85806 4.60578 6.85978 4.9563C6.86277 5.57562 6.86063 6.19535 6.86063 6.81468L6.8602 6.81509Z"
                        fill="#efa2a9"
                      />
                    </svg>
                  </span>
                </span>
              ) : (
                'No Vignette Attached'
              )}
            </p>
          </div>
          <div className="col-md-12">
            <p className="m-0">
              <span className="weight-600 d-block mb-1">Remarks</span>
              {missionTimelineReviewData?.remarks}
            </p>
          </div>
        </div>
      </div>
      <div className="button-group d-block d-md-flex mt-4">
        <StylishNewButton
          type={'button'}
          className={'button--primary button--reverse w-100 w-md-auto'}
          onClick={onPrevious}
        >
          Previous
        </StylishNewButton>
        {/* <StylishNewButton
          type="button"
          className={
            'button--tertiary button--reverse w-100 w-md-auto ms-0 ms-md-auto mt-2 mt-md-0'
          }
        >
          Save & Exit
        </StylishNewButton> */}
        <StylishNewButton
          type="button"
          className={
            'button--primary w-100 w-md-auto ms-0 ms-md-auto mt-2 mt-md-0'
          }
          onClick={completeReview}
        >
          Complete
        </StylishNewButton>
      </div>
    </>
  );
};
