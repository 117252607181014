import { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router';
import StylishNewSelect from 'components/DesignSystems/New/StylishNewSelect';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import API from '../../../service/api';
import Network from '../../../service/Network';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { PreparePath } from 'utils/sharedUtils/sharedConst';
import { toast } from 'react-toastify';
import { toastConfig } from 'assets/data/config';
import { endLoading, startLoading } from "../../../../reducers/loading/loading.action";

export default function CreatePostDialog({
  onClose,
  show,
  selectedSiteId,
  selectedPostid,
  isFromProfile,
  site,
  name,
  id,
}) {
  const {
    handleSubmit,
    formState: { errors },
    control,
    getValues,
  } = useForm();

  const [profileList, setProfileList] = useState([]);
  const [selectedProfile, setSelectedProfile] = useState(null);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const workspaceId = useSelector(
    (state) => state.prepare.workspace.selectedWorkspace
  );

  const onSubmit = async () => {
    dispatch(startLoading());
    try {
      const newProfile = await Network.post(API.createMediaPost, {
        status: 'drafted',
        mediaProfileId: selectedProfile?.value,
        workspaceId: workspaceId,
        type: selectedSiteId ? 'comment' : 'post',
        postId: selectedPostid,
        mediaSiteId: selectedProfile?.siteId,
      });
      if (isFromProfile === 'yes') {
        navigate(
          `${PreparePath}/dashboard/media/posts/edit-post/${newProfile?.data?.response?.MediaPost?.id}?profile=true&site=${site}&profileName=${name}&id=${id}`
        );
      } else {
        navigate(
          `${PreparePath}/dashboard/media/posts/edit-post/${newProfile?.data?.response?.MediaPost?.id}`
        );
      }
      toast.success('Post created successfully', toastConfig);
    } catch (error) {
      console.log(error);
      toast.error('Something went wrong', toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };

  const mediaProfileList = async () => {
    dispatch(startLoading());
    try {
      const response = await Network.get(API.mediaProfileList, {
        workspaceId: workspaceId,
        siteId: selectedSiteId || null,
      });
      const newProfileList = response?.data?.response?.dataset.map(
        ({ name, id, siteId }) => ({
          value: id,
          label: name,
          siteId: siteId,
        })
      );
      setProfileList(newProfileList);
    } catch (error) {
      console.log('error', error);
    } finally {
      dispatch(endLoading());
    }
  };

  useEffect(() => {
    mediaProfileList();
  }, []);

  useEffect(() => {
    const listener = (event) => {
      if (
        (event.code === 'Enter' || event.code === 'NumpadEnter') &&
        !event.defaultPrevented
      ) {
        event.preventDefault();
        handleSubmit(onSubmit)();
      }
    };

    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, [handleSubmit, onSubmit]);

  return (
    <Modal
      show={show}
      onHide={onClose}
      centered
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title>Select a Profile</Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <div>
            <label className="form-label">Name</label>
            <Controller
              control={control}
              name="post"
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <StylishNewSelect
                  options={profileList}
                  placeholder={'Select Option'}
                  isClearable={false}
                  onChange={(e) => {
                    setSelectedProfile(e);
                    onChange(e);
                  }}
                  value={selectedProfile || value}
                  isSearchable={true}
                  isMulti={false}
                  isDisabled={false}
                />
              )}
            />
            {errors.post && (
              <span className="form-text form-error">
                This field is required
              </span>
            )}
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div className="button-group">
            <StylishNewButton
              className="button--secondary button--reverse"
              onClick={onClose}
            >
              Cancel
            </StylishNewButton>
            <StylishNewButton className="button--primary" type="submit">
              Create
            </StylishNewButton>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
