import Help from 'PREPAREsrc/component/Help/Help';
import { MediaAnalytics, useSelfHostedKnowledgeBase } from 'assets/data/HelpContent';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import StylishNewTable from 'components/DesignSystems/New/StylishNewTable';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { FaFacebookF, FaUser } from 'react-icons/fa';
import SampleProfilePic from 'assets/images/icon__profileimage.jpg';
import { endLoading, startLoading } from 'reducers/loading/loading.action';
import API from 'PREPAREsrc/service/api';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import Network from 'PREPAREsrc/service/Network';
import { AllIcon } from 'PREPAREsrc/pages/Settings/InjectMethod/Icon';
import Plot from 'react-plotly.js';
import {
  paperBgColor,
  plotBgColor,
  plotBrick,
  plotGreen,
  plotGrey,
} from 'assets/data/config';
import StylishHelp from 'components/DesignSystems/New/StylishHelp';
import { PreparePath } from 'utils/sharedUtils/sharedConst';
import { sanitizeHtml } from "../../../../utils/sanitizeHtml";

export default function PostAnalytics() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const selfHostedKnowledgeBase = useSelfHostedKnowledgeBase()

  const { selectedWorkspace } = useSelector((state) => {
    return state.prepare.workspace;
  });

  const [postAnalyticsData, setPostAnalyticsData] = useState([]);
  const [postAnalyticsGraphData, setPostAnalyticsGraphData] = useState([]);
  const [postAnalyticsPieGraphData, setPostAnalyticsPieGraphData] = useState(
    []
  );

  const postAnalyticsColumns = [
    {
      dataField: '',
      text: 'Profile',
      sort: false,
      headerAttrs: { title: 'Sort By:' },
      attrs: { title: 'Profile' },
      formatter: (cell, row) => {
        return (
          <>
            <div className="d-flex align-items-start">
              <div>
                {!!row.profileimage ? (
                  <>
                    <img
                      src={row.profileimage}
                      style={{
                        width: '4rem',
                        height: '4rem',
                        borderRadius: '5px',
                        display: 'block',
                      }}
                      alt=""
                    />
                  </>
                ) : (
                  <>
                    <span
                      className="d-flex align-items-center justify-content-center"
                      style={{
                        width: '4rem',
                        height: '4rem',
                        border: 'solid 2px #6c757d',
                        borderRadius: '5px',
                      }}
                    >
                      <FaUser
                        className="img-h-32"
                        style={{ color: '#6c757d' }}
                      />
                    </span>
                  </>
                )}
              </div>
              <div className="ms-3 weight-500">
                <h6 className="mb-1">{row.profilename}</h6>
                <p className="mb-1">@{row.profileusername}</p>
                <p className="d-flex align-items-center mb-1">
                  {!!row.siteicon ? (
                    <span
                      style={{ color: row.sitecolor }}
                      className="has-img-h-20 me-2"
                    >
                      {AllIcon.find((icon) => icon.name === row.siteicon)?.icon}
                    </span>
                  ) : null}
                  {row.siteName}
                </p>
                <p className="m-0">{`${moment(
                  row.publishTime
                ).fromNow()} at ${moment(row.publishTime).format(
                  'MMM Do YYYY'
                )}`}</p>
              </div>
            </div>
          </>
        );
      },
      headerStyle: {
        width: '400px',
      },
    },
    {
      dataField: 'postdescription',
      text: 'Post',
      sort: false,
      attrs: { title: 'Post' },
      formatter: (cell, row) => {
        const clean = sanitizeHtml(row.postdescription);
        return (
          <>
            {!!row.postdescription ? (
              <div
                dangerouslySetInnerHTML={{ __html: clean }}
              ></div>
            ) : (
              '-'
            )}
          </>
        );
      },
    },
    {
      dataField: 'postView',
      text: 'Total Views',
      sort: false,
      attrs: { title: 'Total Views' },
      headerStyle: {
        width: '150px',
      },
      headerAlign: 'center',
      align: 'center',
    },
    {
      dataField: 'uniqueviews',
      text: 'Total Unique Views',
      sort: false,
      attrs: { title: 'Total Unique Views' },
      headerStyle: {
        width: '200px',
      },
      headerAlign: 'center',
      align: 'center',
    },
  ];
  useEffect(() => {
    getPostAnalyticsData();
  }, [selectedWorkspace]);

  const getPostAnalyticsData = () => {
    dispatch(startLoading());
    Network.get(API.postAnalyticsData, {
      workspaceId: selectedWorkspace,
    })
      .then((resp) => {
        const data = resp.data.response.analyticsData;
        const graphData = {
          views: resp.data.response.viewsTotal,
          unviewed: resp.data.response.unviewedTotal,
          uniqueViews: resp.data.response.uniqueViewsTotal,
        };
        const pieData = {
          views: resp.data.response.viewedTotal,
          unviewed: resp.data.response.unviewedTotal,
          uniqueViews: resp.data.response.uniqueViewsTotal,
        };
        setPostAnalyticsData(data);
        setPostAnalyticsGraphData(graphData);
        setPostAnalyticsPieGraphData(pieData);
      })
      .catch(console.log)
      .finally(() => {
        dispatch(endLoading());
      });
  };

  return (
    <>
      <div className="d-flex align-items-center mb-4">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb mb-0">
            <li className="breadcrumb-item">
              <StylishNewButton onClick={() => navigate(-1)}>
                <i className="fa fa-angle-left me-2" aria-hidden="true"></i>
                Back
              </StylishNewButton>
            </li>
            <li className="breadcrumb-item">
              <StylishNewButton
                onClick={() => navigate(`${PreparePath}/dashboard/media/posts`)}
              >
                Posts
              </StylishNewButton>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Analytics
            </li>
          </ol>
        </nav>
        <StylishHelp
          classes={'flex-shrink-0 ms-auto sml'}
          title={MediaAnalytics.title}
          content={MediaAnalytics.content}
          link={selfHostedKnowledgeBase | MediaAnalytics.link}
        />
      </div>
      <div className="row mb-4">
        <div className="col-md-6 mb-4 mb-md-0">
          <div className="p-3 p-md-4 bg-gray-800">
            <Plot
              data={[
                {
                  labels: ['Views', 'Unviewed'],
                  values: [
                    postAnalyticsPieGraphData?.views || 0,
                    postAnalyticsPieGraphData?.unviewed || 0,
                  ],
                  type: 'pie',
                  marker: {
                    colors: [plotBrick, plotGreen],
                  },
                  rotation: 90,
                },
              ]}
              layout={{
                autosize: true,
                paper_bgcolor: paperBgColor,
                plot_bgcolor: plotBgColor,
                font: {
                  color: 'white',
                },

                title: {
                  text: `Inject Posts <br>Total: ${
                    postAnalyticsPieGraphData?.views +
                    postAnalyticsPieGraphData?.unviewed
                  }`,
                  x: 0,
                },
              }}
              useResizeHandler
              className="w-100"
              config={{
                displayModeBar: true,
                modeBarButtons: [['toImage']],
                displaylogo: false,
              }}
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="p-3 p-md-4 bg-gray-800">
            <Plot
              data={[
                {
                  x: ['Views', 'Unique Views'],
                  y: [
                    postAnalyticsGraphData?.views || 0,
                    postAnalyticsGraphData?.uniqueViews || 0,
                  ],
                  type: 'bar',
                  marker: {
                    color: [plotBrick, plotGreen],
                  },
                },
              ]}
              layout={{
                autosize: true,
                paper_bgcolor: paperBgColor,
                plot_bgcolor: plotBgColor,
                font: {
                  color: 'white',
                },
                title: {
                  text: 'Totals',
                  x: 0,
                },
              }}
              useResizeHandler
              className="w-100"
              config={{
                displayModeBar: true,
                modeBarButtons: [['toImage']],
                displaylogo: false,
              }}
            />
          </div>
        </div>
      </div>
      <div className="form-block">
        <StylishNewTable
          keyField={'postid'}
          classes="no-filter v-top"
          headerClasses="empty"
          columns={postAnalyticsColumns}
          rows={postAnalyticsData}
        />
      </div>
    </>
  );
}
