import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import GroupSelection from './GroupSelection';
import StylishNewInput from 'components/DesignSystems/New/StylishNewInput';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { createGroup } from 'actions/profileActions';
import { Controller, useForm } from 'react-hook-form';
import { endLoading, startLoading } from 'reducers/loading/loading.action';
import EditGroupModal from './EditGroupModal';

export const GroupSelectionDialog = ({
  show,
  onClose,
  onDelete,
  group,
  isEditDisabled,
  isDeleteDisabled,
}) => {
  const reduxDispatch = useDispatch();
  const {
    handleSubmit,
    control,
    formState: { errors, isValid, isSubmitted },
  } = useForm({ mode: 'onChange' });

  const [newGroupOption, setNewGroupOption] = useState(false);
  const [editGroupOption, setEditGroupOption] = useState(false);
  const [isLoaded, setIsLoaded] = useState(true);

  const iscreateGroupLoaded = useSelector((state) => {
    return state.app.iscreateGroupLoaded;
  });

  const areGroupsLoaded = useSelector((state) => {
    return state.app.areGroupsLoaded;
  });

  const isEditGroupLoaded = useSelector((state) => {
    return state.app.isEditGroupLoaded;
  });

  useEffect(() => {
    if (
      !isLoaded &&
      iscreateGroupLoaded &&
      isEditGroupLoaded &&
      areGroupsLoaded
    ) {
      reduxDispatch(endLoading());
      onClose();
    }
  }, [
    iscreateGroupLoaded,
    isEditGroupLoaded,
    areGroupsLoaded,
    isLoaded,
    onClose,
  ]);

  const onNewGroupSubmit = ({ createGroupName }) => {
    reduxDispatch(startLoading());
    reduxDispatch(createGroup({ groupName: createGroupName }));
    setIsLoaded(false);
  };

  return (
    <>
      <Modal
        show={show}
        onHide={onClose}
        centered
        backdrop="static"
        keyboard={false}
      >
        {!newGroupOption & !editGroupOption ? (
          <>
            <Modal.Header closeButton closeVariant="white">
              <Modal.Title>Select Organization</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <GroupSelection />
            </Modal.Body>
            <Modal.Footer>
              <div className="button-group">
                <StylishNewButton
                  className="button--primary"
                  onClick={() => setNewGroupOption(true)}
                >
                  New
                </StylishNewButton>
                <StylishNewButton
                  className="button--primary"
                  onClick={() => setEditGroupOption(true)}
                  disabled={isEditDisabled}
                >
                  Edit
                </StylishNewButton>
                <StylishNewButton
                  className="button--secondary button--reverse"
                  disabled={isDeleteDisabled}
                  onClick={onDelete}
                >
                  Delete
                </StylishNewButton>
              </div>
            </Modal.Footer>
          </>
        ) : null}

        {newGroupOption ? (
          <>
            <form onSubmit={handleSubmit(onNewGroupSubmit)}>
              <Modal.Header closeButton closeVariant="white">
                <Modal.Title>New Group</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <label className="form-label">Name</label>
                <Controller
                  control={control}
                  name="createGroupName"
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <StylishNewInput
                      type="text"
                      onChange={(e) => onChange(e.target.value)}
                      value={value}
                    />
                  )}
                />
                {errors.createGroupName && (
                  <span className="form-text form-error">
                    This field is required
                  </span>
                )}
              </Modal.Body>
              <Modal.Footer>
                <div className="button-group">
                  <StylishNewButton
                    className="button--secondary button--reverse"
                    onClick={() => setNewGroupOption(false)}
                  >
                    Cancel
                  </StylishNewButton>
                  <StylishNewButton
                    type="submit"
                    disabled={isSubmitted && !isValid}
                    className="button--primary"
                  >
                    Create Group
                  </StylishNewButton>
                </div>
              </Modal.Footer>
            </form>
          </>
        ) : null}

        {editGroupOption ? (
          <EditGroupModal group={group} onClose={onClose} />
        ) : null}
      </Modal>
    </>
  );
};
