import React from 'react';
import { useNavigate } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { useState, useEffect } from 'react';
import IconFile from 'assets/images/icon__file.svg';
import IconClose from 'assets/images/icon__times.svg';
import IconUpload from 'assets/images/icon__upload.svg';
import { useForm, Controller } from 'react-hook-form';
import Network from '../../../../../service/Network';
import API from '../../../../../service/api';
import { toast } from 'react-toastify';
import moment from 'moment';
import { HelpPlanInjectDescriptionMeasures, useSelfHostedKnowledgeBase } from 'assets/data/HelpContent';
import {
  inputStringLength,
  textareaStringLength,
  toastConfig,
} from 'assets/data/config';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { PreparePath } from 'utils/sharedUtils/sharedConst';
import StylishHelp from 'components/DesignSystems/New/StylishHelp';
import StylishDateTimePicker from 'components/DesignSystems/form/StylishDateTimePicker';
import StylishNewTextArea from 'components/DesignSystems/New/StylishNewTextArea';
import StylishNewInput from 'components/DesignSystems/New/StylishNewInput';
import {
  endLoading,
  startLoading,
} from '../../../../../../reducers/loading/loading.action';

export const MSELTriggerResponse = (props) => {
  const [fileLink, setFileLink] = useState('');
  const [separatedArray, setSeparatedArray] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [show, setShow] = useState(false);
  const seflHostedKnowledgeBase = useSelfHostedKnowledgeBase()
  const {
    control,
    getValues,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const reduxUser = useSelector((state) => state.prepare.user);
  const { selectedWorkspace } = useSelector((state) => state.prepare.workspace);
  const reduxTimezone = useSelector(
    (state) => state.prepare.workspace.timezone
  );

  const changeHandler = async (event) => {
    if (event.target.files.length <= 10) {
      dispatch(startLoading());
      try {
        const formData = new FormData();
        for (let i = 0; i < event.target.files.length; i++) {
          formData.append('file', event.target.files[i]);
        }
        const resp = await Network.post(API.multiplefileupload, formData);
        resp.data.response.dataset.map((e) => {
          setFileLink((preState) =>
            fileLink != '' ? preState + ',' + e.fileUrl : e.fileUrl
          );

          setSeparatedArray((current) =>
            current?.length ? [...current, e.fileUrl] : [e.fileUrl]
          );
        });
        toast.success('File upload successful', toastConfig);
      } catch (error) {
        toast.error(error?.response?.data?.response?.status?.msg, toastConfig);
      } finally {
        dispatch(endLoading());
      }
    } else {
      toast.info('Maximum upload limit is 10 files at a time', toastConfig);
    }
  };

  const fetchSingleInject = async () => {
    dispatch(startLoading());
    try {
      const selectedInject = await Network.get(API.fetchSingleInject, {
        id: props.injectId,
      });
      if (selectedInject.data.response.dataset[0].statusLabel !== 'Complete') {
        setShow(true);
        setValue('actualResponse', null);
        setValue('dateTime', null);
      } else if (
        selectedInject.data.response.dataset[0].statusLabel === 'Complete'
      ) {
        setShow(false);
        setValue(
          'actualResponse',
          selectedInject.data.response.dataset[0].actualResponse
        );
        moment(
          selectedInject.data.response.dataset[0].responseDate
        ).isValid() &&
          setValue(
            'dateTime',
            moment(
              selectedInject.data.response.dataset[0].responseDate
            ).toDate()
          );
      }
      setValue('trigger', selectedInject.data.response.dataset[0].trigger);
      setValue('remarks', selectedInject.data.response.dataset[0].remarks);
      setValue(
        'anticipatedResponse',
        selectedInject.data.response.dataset[0].response
      );
      setStartDate(
        moment(selectedInject.data.response.dataset[0].startDate).toDate()
      );
      setValue('title', selectedInject.data.response.dataset[0].injectTitle);
      setFileLink(selectedInject.data.response.dataset[0].urls);
      setSeparatedArray(
        selectedInject.data.response.dataset[0].urls
          ?.split(',')
          .filter((data) => data != '' && data != 'null' && data != null)
      );
    } catch (error) {
      toast.error(error?.response?.data?.response?.status.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };

  useEffect(() => {
    fetchSingleInject();
  }, [reduxUser]);

  const onSubmit = async (data) => {
    dispatch(startLoading());
    try {
      await Network.post(API.inject, {
        id: props.injectId,
        workspaceId: selectedWorkspace,
        trigger: data.trigger,
        actualResponse: data.actualResponse,
        anticipatedResponse: data.anticipatedResponse,
        responseDate: data.dateTime,
        remarks: data.remarks,
        urls: fileLink
          ?.split(',')
          .filter((data) => data != '' && data != 'null' && data != null)
          .join(','),
      }).then(() => {
        props.onNextClick(2);
      });
    } catch (error) {
      toast.error(error?.response?.data?.response?.status?.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };

  const submitAndExit = async () => {
    const data = getValues();
    dispatch(startLoading());
    try {
      await Network.post(API.inject, {
        id: props.injectId,
        workspaceId: selectedWorkspace,
        trigger: data.trigger,
        actualResponse: data.actualResponse,
        anticipatedResponse: data.anticipatedResponse,
        responseDate: data.dateTime,
        remarks: data.remarks,
        urls: fileLink
          ?.split(',')
          .filter((data) => data != '' && data != 'null' && data != null)
          .join(','),
      });
      navigate(-1, {
        replace: true,
      });
    } catch (error) {
      toast.error(error?.response?.data?.response?.status?.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };

  const deleteFile = (file) => {
    setSeparatedArray((prevState) => prevState.filter((pS) => pS != file));
    setFileLink(
      separatedArray
        .filter(
          (data) => data != '' && data != 'null' && data != null && data != file
        )
        .join(',')
    );
  };

  return (
    <>
      <div className="d-flex align-items-center mb-4">
        <h4 className="m-0">Trigger &amp; Response</h4>
        <StylishHelp
          classes={'ms-auto sml flex-shrink-0'}
          title={HelpPlanInjectDescriptionMeasures.title}
          content={HelpPlanInjectDescriptionMeasures.content}
          link={seflHostedKnowledgeBase || HelpPlanInjectDescriptionMeasures.link}
        />
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-block">
          <div className="row">
            <div className="col-md-12 mb-3">
              <label className="form-label">Trigger</label>
              <Controller
                control={control}
                name={'trigger'}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <StylishNewTextArea
                    onChange={(e) => {
                      onChange(e);
                    }}
                    value={value}
                    type="text"
                    name="trigger"
                    maxLength={textareaStringLength}
                    className="form-control"
                    placeholder="Describe the trigger to drive the anticipated response"
                  />
                )}
              />
              {errors.trigger && (
                <span className="form-text form-error">
                  This is a required field
                </span>
              )}
            </div>
            <div className="col-md-12 mb-3">
              <label className="form-label">
                Anticipated Response / Expected Player Action
              </label>
              <Controller
                control={control}
                name={'anticipatedResponse'}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <StylishNewTextArea
                    onChange={(e) => {
                      onChange(e);
                    }}
                    value={value}
                    type="text"
                    name="anticipatedResponse"
                    maxLength={textareaStringLength}
                    className="form-control"
                    placeholder="The expected action of the training audience"
                  />
                )}
              />
              {errors.anticipatedResponse && (
                <span className="form-text form-error">
                  This is a required field
                </span>
              )}
            </div>
            <div className="col-md-12 mb-3">
              <label className="form-label">Remarks</label>
              <Controller
                control={control}
                name={'remarks'}
                rules={{ required: false }}
                render={({ field: { onChange, value } }) => (
                  <StylishNewTextArea
                    onChange={(e) => {
                      onChange(e);
                    }}
                    value={value}
                    type="text"
                    name="remarks"
                    maxLength={textareaStringLength}
                    className="form-control"
                    placeholder="Additional information to support the inject"
                  />
                )}
              />
            </div>
            {separatedArray?.length ? (
              <div className="col-md-12 mb-2">
                <label className="form-label m-0">Attachments</label>
                <div className="row">
                  {separatedArray?.map((data, index) => {
                    let fullFileName = data.split('/')[
                      data.split('/').length - 1
                    ];
                    return (
                      // <div key={index}>
                      <div className="col-md-3 col-xl-2 my-2" key={index}>
                        <div className="cards cards__uploaded-file">
                          <span className="remove">
                            <img
                              src={IconClose}
                              alt=""
                              onClick={() => deleteFile(data)}
                            />
                          </span>
                          <span className="icon">
                            <img src={IconFile} alt="" />
                          </span>
                          <span className="title">
                            {
                              fullFileName.split('-')[
                                fullFileName.split('-').length - 1
                              ]
                            }
                          </span>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : null}
            <div className="col-md-12 mb-3">
              <label className="form-label">
                Upload Media
                <span className="notifyMessage ms-1">
                  *Maximum upload limit is 10 files at a time
                </span>
              </label>
              <div className="form-upload">
                <div>
                  <span className="d-flex justify-content-center mb-2">
                    <img src={IconUpload} alt="" />
                  </span>
                  <span className="d-block">
                    Drop Your Files Here or Click to Upload
                  </span>
                </div>
                <StylishNewInput
                  onChange={(e) => {
                    if (e.target.files.length > 0) {
                      changeHandler(e);
                    }
                  }}
                  multiple
                  accept=".docx,.doc,.pdf"
                  type="file"
                  name="file"
                  maxLength={inputStringLength}
                  className="form-control"
                />
              </div>
            </div>

            <div className="col-md-12 mb-3">
              <label className="form-label">
                Response Time ({reduxTimezone.split('/')[0]}){' '}
                {show && (
                  <span className="notifyMessage ms-1">
                    *Status must be marked “Complete” in order to select a
                    Response Date
                  </span>
                )}
              </label>

              <Controller
                control={control}
                name="dateTime"
                rules={{ required: !show }}
                render={({ field: { onChange, value } }) => (
                  <StylishDateTimePicker
                    disabled={show}
                    onChange={onChange}
                    value={value}
                    selected={show ? null : value}
                    isClearable={true}
                    minDate={startDate}
                    timeFormat="HH:mm"
                    timeIntervals={20}
                    timeCaption="time"
                    dateFormat={
                      reduxUser?.timeFormat === 'hour12Mode'
                        ? 'MMMM d, yyyy hh:mm a'
                        : 'MMMM d, yyyy HH:mm'
                    }
                    placeholderText={'Choose Start Date'}
                    disableDateTimeLabel={true}
                    type="datetime-local"
                  />
                )}
              />
              {errors.dateTime && (
                <span className="form-text form-error">
                  This is a required field
                </span>
              )}
            </div>

            <div className="col-md-12">
              <label className="form-label">
                Actual Response{' '}
                {show && (
                  <span className="notifyMessage ms-1">
                    *Status must be marked “Complete” in order to set Actual
                    Response
                  </span>
                )}
              </label>
              <Controller
                control={control}
                name={'actualResponse'}
                rules={{ required: false }}
                render={({ field: { onChange, value } }) => (
                  <StylishNewTextArea
                    onChange={(e) => {
                      onChange(e);
                    }}
                    value={value}
                    type="text"
                    name="actualResponse"
                    maxLength={textareaStringLength}
                    className="form-control"
                    placeholder="Describe the response of the training audience"
                    readOnly={show}
                  />
                )}
              />
            </div>
          </div>
        </div>
        <div className="col-md-12 mt-4">
          <div className="button-group d-block d-md-flex">
            <StylishNewButton
              className="button button--primary button--reverse w-100 w-md-auto"
              type="submit"
              onClick={() => props.onPrevClick(0)}
            >
              Previous
            </StylishNewButton>

            <StylishNewButton
              type="button"
              className="button button--tertiary button--reverse ms-auto mt-3 mt-md-0 w-100 w-md-auto"
              onClick={submitAndExit}
            >
              Save &amp; Exit
            </StylishNewButton>
            <StylishNewButton
              type="submit"
              className="button button--primary  w-100 w-md-auto mt-3 mt-md-0 ms-0 ms-md-3"
            >
              Next
            </StylishNewButton>
          </div>
        </div>
      </form>
    </>
  );
};
