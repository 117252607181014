import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import API from '../../../service/api';
import Network from '../../../service/Network';
import ConfirmModal from '../../../component/ConfirmModal/ConfirmModal';
import { Link, useNavigate } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import IconEmptyExercise from 'assets/images/icon__exercise-details-empty.svg';
import { toast } from 'react-toastify';
import SearchBar from '../../../component/SearchBar/SearchBar';
import ReactPaginate from 'react-paginate';
import AddSiteDialog from './AddSiteDialog';
import Help from '../../../component/Help/Help';
import { MiscellaneousHelpLink, Site, useSelfHostedKnowledgeBase} from 'assets/data/HelpContent';
import { AllIcon } from 'assets/Icon/Icon';
import IconEdit from 'assets/images/icon__edit.svg';
import IconVisit from 'assets/images/icon__visit.svg';
import IconDelete from 'assets/images/icon__delete.svg';
import StylishNewSelect from 'components/DesignSystems/New/StylishNewSelect';
import { PreparePath } from 'utils/sharedUtils/sharedConst';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { toastConfig } from 'assets/data/config';
import StylishHelp from 'components/DesignSystems/New/StylishHelp';
import { StylishNewSearchBar } from 'components/DesignSystems/New/StylishNewSearchBar';
import { StylishConfirmDialog } from 'components/DesignSystems/New/StylishConfirmDialog';
import StylishButton from 'components/DesignSystems/buttons/StylishButton';
import {
  endLoading,
  startLoading,
} from '../../../../reducers/loading/loading.action';
import isRoleReadOnly from 'PREPAREsrc/utils/isRoleReadOnly';
import PageTitle from 'components/headers/PageTitle';
import { useNewUIFlag } from 'utils/featureFlags';

export default function Sites() {
  const workspaceId = useSelector(
    (state) => state.prepare.workspace.selectedWorkspace
  );

  const newUI = useNewUIFlag();

  const selfHostedKnowledgeBase = useSelfHostedKnowledgeBase();

  const [showSiteAddDialog, setShowSiteAddDialog] = useState(false);
  const [viewSites, setViewSites] = useState([]);
  const [sitesTotal, setSitesTotal] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [dialogType, setDialogType] = useState('Add');
  const [updateDialogData, setUpdateDialogData] = useState(null);
  const [deleteSiteId, setDeleteSiteId] = useState(null);
  const [page, setPage] = useState(1);
  const [sortOption, setSortOption] = useState({
    value: 'displayName_ASC',
    label: 'Name',
  });
  const perPage = 16;

  const { selectedWorkspace, workspaceName } = useSelector((state) => {
    return state?.prepare?.workspace;
  });

  const reduxUser = useSelector((state) => state.prepare.user);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const sortOptions = [
    { value: 'displayName_ASC', label: 'Name' },
    { value: 'mediaType_ASC', label: 'Type' },
    { value: 'updatedAt_DESC', label: 'Last Updated' },
    { value: 'createdAt_DESC', label: 'Last Created' },
    { value: 'updatedAt_ASC', label: 'Oldest Updated' },
    { value: 'createdAt_ASC', label: 'Oldest Created' },
  ];

  const mediasiteList = async () => {
    dispatch(startLoading());
    try {
      const response = await Network.get(API.mediasiteList, {
        workspaceId: selectedWorkspace,
        sort: sortOption.value,
        term: searchTerm,
        limit: perPage,
        page: page,
      });
      const allMediaSites = response.data.response.dataset.map((e) => {
        if (e.mediaType === 'social_media') {
          return {
            ...e,
            modofiedMediaType: 'Social Media',
          };
        }
        if (e.mediaType === 'news') {
          return {
            ...e,
            modofiedMediaType: 'News',
          };
        }
        if (e.mediaType === 'blog') {
          return {
            ...e,
            modofiedMediaType: 'Blog',
          };
        }
        if (e.mediaType === 'video') {
          return {
            ...e,
            modofiedMediaType: 'Video',
          };
        }
        if (e.mediaType === 'forum') {
          return {
            ...e,
            modofiedMediaType: 'Forum',
          };
        }
        if (e.mediaType === 'chat') {
          return {
            ...e,
            modofiedMediaType: 'Chat',
          };
        }
        if (e.mediaType === 'other') {
          return {
            ...e,
            modofiedMediaType: 'Other',
          };
        }
      });
      setViewSites(allMediaSites);
      setSitesTotal(response.data.response.total);
    } catch (error) {
    } finally {
      dispatch(endLoading());
    }
  };

  useEffect(() => {
    mediasiteList();
  }, [reduxUser, searchTerm, sortOption, page, perPage, workspaceId]);

  const onAddSiteDialog = () => {
    setDialogType('Add');
    setShowSiteAddDialog(true);
  };
  const onEditSites = (row) => {
    setShowSiteAddDialog(true);
    setUpdateDialogData(row);
    setDialogType('Edit');
  };

  const onAddSiteDialogClose = (afterSave) => {
    setUpdateDialogData(null);
    setDialogType('New');
    setShowSiteAddDialog(false);
    if (!!afterSave && afterSave[0]?.id) {
      mediasiteList();
    }
  };
  const onChangeSearchTerm = (termSearched) => {
    setSearchTerm(termSearched);
    setPage(1);
  };

  const onDeleteSites = (row) => {
    setDeleteSiteId(row.id);
  };
  const onDeleteConfirm = async () => {
    dispatch(startLoading());
    try {
      await Network.post(API.deleteMediaSite, { siteId: deleteSiteId });
      setDeleteSiteId(null);
      mediasiteList();
      toast.success('Site deleted successfully', toastConfig);
    } catch (error) {
      toast.error(error?.response?.data?.response?.status?.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };

  const handlePageClick = (event) => {
    setPage(event.selected + 1);
  };

  return (
    <>
      {newUI ? (
        <PageTitle title="Sites" parents={['Media']} />
      ) : (
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <StylishNewButton onClick={() => navigate(-1)}>
                <i className="fa fa-angle-left me-2" aria-hidden="true"></i>
                Back
              </StylishNewButton>
            </li>
            <li
              className="breadcrumb-item active cursor-pointer"
              aria-current="page"
            >
              Sites
            </li>
          </ol>
        </nav>
      )}

      <div className="d-flex flex-wrap align-items-center">
        {!isRoleReadOnly(reduxUser.roles) && (
          <StylishNewButton
            onClick={onAddSiteDialog}
            className="button--primary flex-shrink-0"
          >
            Create Site
          </StylishNewButton>
        )}
        <p className="ms-auto m-0">{sitesTotal} Sites</p>
        <div className="d-flex flex-wrap align-items-center ms-lg-3 w-100 w-lg-auto mt-3 mt-lg-0">
          <StylishNewSearchBar
            className="flex-shrink-0"
            value={searchTerm}
            onChangeSearchTerm={onChangeSearchTerm}
          />
          <div className="d-flex align-items-center ms-md-auto ms-lg-3 w-100 w-md-auto mt-3 mt-md-0">
            <StylishNewSelect
              style={{ minWidth: '160px' }}
              classes={'flex-grow-1'}
              options={sortOptions}
              onChange={(e) => {
                setSortOption(e);
              }}
              value={sortOption}
              isClearable={false}
              isSearchable={false}
              isMulti={false}
              isDisabled={false}
              placeholder="Sort By"
            />
            <Help
              classes={'flex-shrink-0 ms-3 sml'}
              title={Site.title}
              content={Site.content}
              link={selfHostedKnowledgeBase || Site.link}
            />
          </div>
        </div>
      </div>

      {sitesTotal > 0 ? (
        <div className="row">
          {viewSites.map((item) => (
            <div className="col-md-6 col-lg-4 col-xl-3 mt-4" key={item.id}>
              <div className="bg-gray-800 rounded h-100 overflow-hidden d-flex flex-column">
                <div className="p-3">
                  <div className="d-flex align-items-center mb-4">
                    <div
                      className={`flex-shrink-0 bg-white rounded-5 d-flex align-items-center justify-content-center overflow-hidden ${
                        !!item?.icon ? 'has-img-h-24' : ''
                      }`}
                      style={{
                        color: item?.color,
                        height: '34px',
                        width: '34px',
                      }}
                    >
                      {!!item?.site_image ? (
                        <img
                          src={item?.site_image}
                          alt=""
                          style={{
                            display: 'block',
                            height: '34px',
                            width: '34px',
                          }}
                        />
                      ) : null}
                      {!!item?.icon ? (
                        <>
                          {
                            AllIcon.find((icon) => icon.name === item.icon)
                              ?.icon
                          }
                        </>
                      ) : null}
                    </div>
                    <Dropdown className="flex-shrink-0 ms-auto">
                      <Dropdown.Toggle className="button--icon">
                        <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                      </Dropdown.Toggle>
                      <Dropdown.Menu align="end">
                        <Link
                          to={`${PreparePath}/dashboard/media/sites/${
                            workspaceName &&
                            workspaceName.charAt(0).toUpperCase()
                          }/${item?.slug}`}
                          // state={{
                          //   siteId: item?.id,
                          //   workspaceId: item?.workspaceId,
                          //   workspaceName:
                          //     workspaceName &&
                          //     workspaceName.charAt(0).toUpperCase(),
                          //   slug: item?.slug,
                          // }}
                          className="dropdown-item"
                        >
                          <img src={IconVisit} alt="" />
                          Visit Site
                        </Link>
                        {!isRoleReadOnly(reduxUser.roles) && (
                          <>
                            <Dropdown.Item
                              // className="dropdown-item"
                              onClick={() => onEditSites(item)}
                            >
                              <img src={IconEdit} alt="" />
                              Edit Site
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => {
                                onDeleteSites(item);
                              }}
                            >
                              <img src={IconDelete} alt="" />
                              Delete Site
                            </Dropdown.Item>
                          </>
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>

                  <h5 className="mb-2">{item.displayName}</h5>
                  <p className="m-0">{item.modofiedMediaType}</p>
                </div>

                <div className="bg-gray-700 p-3 mt-auto d-flex justify-content-center">
                  <Link
                    to={`${PreparePath}/dashboard/media/sites/${
                      workspaceName && workspaceName.charAt(0).toUpperCase()
                    }/${item?.slug}`}
                    className="text-white weight-500 d-flex align-items-center justify-content-center flex-grow-1"
                  >
                    <img src={IconVisit} className="img-h-14 me-2" alt="" />
                    Visit
                  </Link>
                  <div className="separator--y separator--800 flex-shrink-0"></div>
                  <Link
                    className="text-white weight-500 d-flex align-items-center justify-content-center flex-grow-1"
                    onClick={() => {
                      if (!isRoleReadOnly(reduxUser.roles)) {
                        onEditSites(item);
                      }
                    }}
                  >
                    <img src={IconEdit} className="img-h-14 me-2" alt="" />
                    Edit
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <>
          <div className="bg-gray-800 text-center p-5 mt-4">
            <div className="py-0 py-md-4">
              <div className="d-flex justify-content-center mb-4">
                <img src={IconEmptyExercise} alt="" />
              </div>
              <p className="weight-600 mb-4">
                A fresh start. Create the first entry.
                <a href={selfHostedKnowledgeBase || MiscellaneousHelpLink.link} target="_blank">
                  {' '}
                  Need more help?
                </a>
              </p>
              {!isRoleReadOnly(reduxUser.roles) && (
                <StylishNewButton
                  className="button--primary"
                  onClick={onAddSiteDialog}
                >
                  Create Site
                </StylishNewButton>
              )}
            </div>
          </div>
        </>
      )}
      {sitesTotal > perPage && (
        <div className="d-flex justify-content-center justify-content-md-end mt-4">
          <ReactPaginate
            className="pagination"
            breakLabel="..."
            nextLabel="Next"
            onPageChange={handlePageClick}
            pageCount={Math.ceil(sitesTotal / perPage)}
            previousLabel="Prev"
          />
        </div>
      )}

      {deleteSiteId && (
        <StylishConfirmDialog
          show={deleteSiteId}
          onClose={() => setDeleteSiteId(null)}
          dialogTitle={'Delete Site'}
          dialogContent={'Are you sure you want to delete this site?'}
          onConfirm={onDeleteConfirm}
        />
      )}
      {showSiteAddDialog && (
        <AddSiteDialog
          show={showSiteAddDialog}
          onClose={onAddSiteDialogClose}
          dialogType={dialogType}
          updateDialogData={updateDialogData}
        />
      )}
    </>
  );
}
