import React, { useEffect, useState } from 'react';
import AddEditFromToDialog from './AddEditFromToDialog';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Network from '../../../service/Network';
import API from '../../../service/api';
import StylishNewTable from 'components/DesignSystems/New/StylishNewTable';
import { StylishNewSearchBar } from 'components/DesignSystems/New/StylishNewSearchBar';
import StylishNewTableAction from 'components/DesignSystems/New/StylishNewTableAction';
import { StylishConfirmDialog } from 'components/DesignSystems/New/StylishConfirmDialog';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import StylishHelp from 'components/DesignSystems/New/StylishHelp';
import { HelpFromTo, useSelfHostedKnowledgeBase } from 'assets/data/HelpContent';
import { toastConfig } from 'assets/data/config';
import { PaginationDropdown } from '../../../../components/DesignSystems/PaginationDropdown';
import {
  endLoading,
  startLoading,
} from '../../../../reducers/loading/loading.action';

export default function FromTo() {
  const [allFromTo, setAllFromTo] = useState([]);
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [perPage, setPerPage] = useState(10);
  const [total, setTotal] = useState(0);
  const [showFromToAddEditDialog, setShowFromToAddEditDialog] = useState(false);
  const [dialogType, setDialogType] = useState('Add');
  const [updateDialogData, setUpdateDialogData] = useState(null);
  const [deleteFromTo, setDeleteFromTo] = useState(null);
  const [deleteFromToTitle, setDeleteFromToTitle] = useState(null);

  const selfHostedKnowledgeBase = useSelfHostedKnowledgeBase()
  
  const dispatch = useDispatch();
  const reduxUser = useSelector((state) => state.prepare.user);
  const workspaceId = useSelector(
    (state) => state.prepare.workspace.selectedWorkspace
  );

  const columns = [
    {
      dataField: 'title',
      text: 'Name',
      sort: true,
      headerAttrs: { title: 'Sort By:' },
      attrs: { title: 'Name' },
    },
    {
      dataField: '',
      text: 'Action',
      isDummyField: true,
      formatter: (cell, row, rowIndex) => (
        <StylishNewTableAction
          name="From To"
          cell={cell}
          row={row}
          rowIndex={rowIndex}
          onDelete={onDeletegetAllExerciseType}
          onEdit={onEditgetAllExerciseType}
        />
      ),
      headerStyle: {
        width: '100px',
      },
      headerAlign: 'center',
      align: 'center',

      attrs: { title: 'Action' },
    },
  ];

  const fromToList = async () => {
    dispatch(startLoading());
    try {
      const newFromTo = await Network.get(API.fromToList, {
        limit: perPage,
        page: page,
        search: searchTerm,
        workspaceId: workspaceId,
      });

      setAllFromTo(newFromTo.data.response.dataset);

      setTotal(newFromTo.data.response.count);
    } catch (error) {
      toast.error(error.response.data.response.status.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };
  useEffect(() => {
    fromToList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, perPage, searchTerm, reduxUser]);

  const onAddgetAllExerciseType = () => {
    setDialogType('Add');
    setShowFromToAddEditDialog(true);
  };
  const onDeletegetAllExerciseType = (row) => {
    setDeleteFromTo(row.id);
    setDeleteFromToTitle(row.title);
  };
  const onEditgetAllExerciseType = (row) => {
    setUpdateDialogData(row);
    setDialogType('Edit');
    setShowFromToAddEditDialog(true);
  };
  const OnAddOrEditgetAllExerciseType = (afterSave) => {
    setUpdateDialogData(null);
    setDialogType('New');
    setShowFromToAddEditDialog(false);
    if (afterSave?.id) {
      fromToList();
    }
  };
  const onDeleteConfirm = async () => {
    dispatch(startLoading());
    try {
      await Network.post(API.deleteFromTo, {
        FromToId: deleteFromTo,
      });
      setDeleteFromTo(null);
      fromToList();
      toast.success('Deleted  successfully', toastConfig);
    } catch (error) {
      toast.error(error.response.data.response.status.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };
  const onChangeSearchTerm = (term) => {
    setPage(1);
    setSearchTerm(term);
  };
  return (
    <>
      <div className="settings__exercise-type">
        <StylishNewButton
          className="button button--primary mb-4"
          onClick={onAddgetAllExerciseType}
        >
          Add From To
        </StylishNewButton>
        <div className="form-block">
          <div className="row">
            <div className="col-md-12">
              <div className="d-flex flex-column flex-md-row align-items-md-center mb-4">
                <div className="d-flex align-items-center justify-content-between">
                  <p className="m-0 me-3 text-nowrap">{total || 0} From To</p>
                  <div className="">
                    <PaginationDropdown
                      setPage={setPage}
                      setPerPage={setPerPage}
                      options={[
                        { value: 10, label: '10 per page' },
                        { value: 30, label: '30 per page' },
                        { value: 60, label: '60 per page' },
                        { value: 100, label: '100 per page' },
                      ]}
                    />
                  </div>
                </div>
                <div className="ms-md-auto mt-3 mt-md-0 d-flex align-items-center">
                  <div className="flex-grow-1">
                    <StylishNewSearchBar
                      onChangeSearchTerm={onChangeSearchTerm}
                    />
                  </div>
                  <StylishHelp
                    classes={'ms-3 flex-shrink-0 sml'}
                    title={HelpFromTo.title}
                    content={HelpFromTo.content}
                    link={selfHostedKnowledgeBase || HelpFromTo.link}
                  />
                </div>
              </div>
            </div>
          </div>
          <StylishNewTable
            keyField={'id'}
            columns={columns}
            rows={allFromTo}
            onDelete={onDeletegetAllExerciseType}
            onEdit={onEditgetAllExerciseType}
            pagination
            page={page}
            perPage={perPage}
            total={total}
            onPageChange={(page) => setPage(page)}
          />
        </div>
        {showFromToAddEditDialog && (
          <AddEditFromToDialog
            show={showFromToAddEditDialog}
            onClose={OnAddOrEditgetAllExerciseType}
            dialogType={dialogType}
            updateDialogData={updateDialogData}
          />
        )}
        {deleteFromTo && (
          <StylishConfirmDialog
            show={deleteFromTo}
            onClose={() => setDeleteFromTo(null)}
            dialogTitle={'Delete From To'}
            dialogContentType={'html'}
            dialogContent={`
              Deleting <span class="weight-700 break-word">${deleteFromToTitle}</span> will remove all its occurance from this workspace.<span class="d-block pt-3">Are you sure you want to delete this From To ?</span>`}
            onConfirm={onDeleteConfirm}
          />
        )}
      </div>
    </>
  );
}
