import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Network from '../../../../../service/Network';
import API from '../../../../../service/api';
import { useLocation, useNavigate } from 'react-router-dom';
import { HelpTrainingObjectiveAssociations, useSelfHostedKnowledgeBase } from 'assets/data/HelpContent';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { PreparePath } from 'utils/sharedUtils/sharedConst';
import StylishNewTable from 'components/DesignSystems/New/StylishNewTable';
import StylishHelp from 'components/DesignSystems/New/StylishHelp';
import { toastConfig } from 'assets/data/config';
import { PaginationDropdown } from '../../../../../../components/DesignSystems/PaginationDropdown';
import {
  endLoading,
  startLoading,
} from '../../../../../../reducers/loading/loading.action';

const paginationOptions = [
  { value: 5, label: '5 per page' },
  { value: 15, label: '15 per page' },
  { value: 30, label: '30 per page' },
  { value: 60, label: '60 per page' },
];

export default function TrainingObjectiveAssociations(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const locationData = useLocation();
  const participant = JSON.parse(sessionStorage.getItem('participantDetails'));

  const selfHostedKnowledgeBase = useSelfHostedKnowledgeBase()

  const [objectives, setObjectives] = useState([]);
  const [totalExerciseObjectives, setTotalExerciseObjectives] = useState(0);
  const [pageExerciseObjectives, setPageExerciseObjectives] = useState(1);
  const [perPageExerciseObjectives, setPerPageExerciseObjectives] = useState(5);
  const [accreditedTasks, setAccreditedTasks] = useState([]);
  const [totalAccreditedTasks, setTotalAccreditedTasks] = useState(0);
  const [pageAccrediatedTasks, setPageAccrediatedTasks] = useState(1);
  const [perPageAccrediatedTasks, setPerPageAccrediatedTasks] = useState(5);
  const [commandPriority, setCommandPriority] = useState([]);
  const [totalCommandPriority, setTotalCommandPriority] = useState(0);
  const [pageCommandPriority, setPageCommandPriority] = useState(1);
  const [perPageCommandPriority, setPerPageCommandPriority] = useState(5);
  const [totalStrategicPriority, setTotalStrategicPriority] = useState(0);
  const [pageStrategicPriority, setPageStrategicPriority] = useState(1);
  const [pageCoreCapabilities, setPageCoreCapabilities] = useState(1);
  const [perPageCoreCapabilities, setPerPageCoreCapabilities] = useState(5);
  const [allCoreCapabilities, setAllCoreCapabilities] = useState([]);
  const [totalCoreCapabilities, setTotalCoreCapabilities] = useState(0);
  const [allPlatform, setAllPlatform] = useState([]);
  const [allPlatformAfterSave, setAllPlatformAfterSave] = useState([]);

  const [pageCapabilityTargets, setPageCapabilityTargets] = useState(1);
  const [perPageCapabilityTargets, setPerPageCapabilityTargets] = useState(5);
  const [allCapabilityTargets, setAllCapabilityTargets] = useState([]);
  const [totalCapabilityTargets, setTotalCapabilityTargets] = useState(0);

  const [perPageStrategicPriority, setPerPageStrategicPriority] = useState(5);
  const [allJSTP, setAllJSTP] = useState([]);
  const [selectedExerciseObjectives, setSelectedExerciseObjectives] = useState(
    []
  );
  const [selectedAccrediatedTasks, setSelectedAccrediatedTasks] = useState([]);
  const [selectedCommandPriority, setSelectedCommandPriority] = useState([]);
  const [selectedStrategicPriority, setSelectedStrategicPriority] = useState(
    []
  );
  const [selectedCapabilityTargets, setSelectedCapabilityTargets] = useState(
    []
  );
  const [selectedCoreCapabilities, setSelectedCoreCapabilities] = useState([]);

  const workspaceId = useSelector(
    (state) => state.prepare.workspace.selectedWorkspace
  );
  const workspaceType = useSelector((state) => state.prepare.workspace.type);
  const reduxUser = useSelector((state) => state.prepare.user);
  const exerciseId = useSelector(
    (state) => state.prepare.exercise.exerciseDetailsId
  );

  const eo_columns = [
    {
      dataField: 'title',
      text: 'Title',
      sort: true,
      headerAttrs: { title: 'Sort By:' },
      attrs: { title: 'Title' },
    },
  ];

  const expandRowEO = {
    parentClassName: 'parent-expand-tr',
    className: 'child-expand-tr',
    renderer: (row) => {
      return (
        <>
          <div className="mb-3">
            <label className="form-label d-block mb-0">Objective:</label>
            {row.exerciseobjective}
          </div>
          <div className="mb-3">
            <label className="form-label d-block mb-0">
              Measure of Performance:
            </label>
            {row.measureperformane}
          </div>
          <div>
            <label className="form-label d-block mb-0">
              Measure of Effectiveness:
            </label>
            {row.measureeffectiveness}
          </div>
        </>
      );
    },
    showExpandColumn: true,
    expandByColumnOnly: true,
    expandHeaderColumnRenderer: ({ isAnyExpands }) => {
      if (isAnyExpands) {
        return <i className="fa fa-chevron-down" aria-hidden="true"></i>;
      }
      return <i className="fa fa-chevron-right" aria-hidden="true"></i>;
    },
    expandColumnRenderer: ({ expanded }) => {
      if (expanded) {
        return (
          <>
            <div className="d-flex align-items-center justify-content-md-center">
              <span className="me-2">Details</span>
              <i className="fa fa-chevron-down" aria-hidden="true"></i>
            </div>
          </>
        );
      }
      return (
        <>
          <div className="d-flex align-items-center justify-content-md-center">
            <span className="me-2">Details</span>
            <i className="fa fa-chevron-right" aria-hidden="true"></i>
          </div>
        </>
      );
    },
  };
  const { setValue } = useForm();

  const at_columns = [
    {
      dataField: 'level_of_war',
      text: 'Level of War',
      sort: true,
      headerAttrs: { title: 'Sort By:' },
      attrs: { title: 'Level of War' },
    },
    {
      dataField: 'task_number',
      text: 'Task Number',
      sort: true,
      attrs: { title: 'Task Number' },
    },
    {
      dataField: 'task_title',
      text: 'Task Title',
      sort: true,
      attrs: { title: 'Task Title' },
    },
  ];

  const ctp_columns = [
    {
      dataField: 'title',
      text: 'Title',
      sort: true,
      headerAttrs: { title: 'Sort By:' },
      attrs: { title: 'Title' },
    },
  ];

  const expandRowCTP = {
    parentClassName: 'parent-expand-tr',
    className: 'child-expand-tr',
    renderer: (row) => {
      return (
        <>
          <div className="mb-0">
            <label className="form-label d-block mb-0">Description:</label>
            {row.description}
          </div>
        </>
      );
    },
    showExpandColumn: true,
    expandByColumnOnly: true,
    expandHeaderColumnRenderer: ({ isAnyExpands }) => {
      if (isAnyExpands) {
        return <i className="fa fa-chevron-down" aria-hidden="true"></i>;
      }
      return <i className="fa fa-chevron-right" aria-hidden="true"></i>;
    },
    expandColumnRenderer: ({ expanded }) => {
      if (expanded) {
        return (
          <>
            <div className="d-flex align-items-center justify-content-md-center">
              <span className="me-2">Details</span>
              <i className="fa fa-chevron-down" aria-hidden="true"></i>
            </div>
          </>
        );
      }
      return (
        <>
          <div className="d-flex align-items-center justify-content-md-center">
            <span className="me-2">Details</span>
            <i className="fa fa-chevron-right" aria-hidden="true"></i>
          </div>
        </>
      );
    },
  };

  const jstp_columns = [
    {
      dataField: 'title',
      text: 'Title',
      sort: true,
      headerAttrs: { title: 'Sort By:' },
      attrs: { title: 'Title' },
    },
  ];

  const expandRowJSTP = {
    parentClassName: 'parent-expand-tr',
    className: 'child-expand-tr',
    renderer: (row) => {
      return (
        <>
          <div className="mb-3">
            <label className="form-label d-block mb-0">Title:</label>
            {row.title}
          </div>
          <div className="mb-0">
            <label className="form-label d-block mb-0">Description:</label>
            {row.description}
          </div>
        </>
      );
    },
    showExpandColumn: true,
    expandByColumnOnly: true,
    expandHeaderColumnRenderer: ({ isAnyExpands }) => {
      if (isAnyExpands) {
        return <i className="fa fa-chevron-down" aria-hidden="true"></i>;
      }
      return <i className="fa fa-chevron-right" aria-hidden="true"></i>;
    },
    expandColumnRenderer: ({ expanded }) => {
      if (expanded) {
        return (
          <>
            <div className="d-flex align-items-center justify-content-md-center">
              <span className="me-2">Details</span>
              <i className="fa fa-chevron-down" aria-hidden="true"></i>
            </div>
          </>
        );
      }
      return (
        <>
          <div className="d-flex align-items-center justify-content-md-center">
            <span className="me-2">Details</span>
            <i className="fa fa-chevron-right" aria-hidden="true"></i>
          </div>
        </>
      );
    },
  };

  const selectRow = {
    mode: 'checkbox',
    clickToSelect: true,
    selected: [
      ...selectedAccrediatedTasks,
      ...selectedCommandPriority,
      ...selectedExerciseObjectives,
      ...selectedStrategicPriority,
      ...selectedCapabilityTargets,
      ...selectedCoreCapabilities,
    ],
    selectionHeaderRenderer: ({ mode, ...rest }) => (
      <div className="form-checkbox align-items-center">
        <input type={mode} {...rest} />
        <span className="icon"></span>
      </div>
    ),
    selectionRenderer: ({ mode, ...rest }) => (
      <div className="form-checkbox align-items-center">
        <input type={mode} {...rest} />
        <span className="icon"></span>
      </div>
    ),
    onSelect: (row, isSelect) => {
      if (isSelect) {
        if (row.planEventId) {
          setSelectedCapabilityTargets((prevState) => [...prevState, row.id]);
        }
        if (row.value) {
          setSelectedCoreCapabilities((prevState) => [...prevState, row.id]);
        }
        if (row.exerciseobjective) {
          setSelectedExerciseObjectives((prevState) => [...prevState, row.id]);
        }
        if (row.jmetId) {
          setSelectedAccrediatedTasks((prevState) => [...prevState, row.id]);
        }
        if (row.planEventId && !row.exerciseobjective) {
          setSelectedCommandPriority((prevState) => [...prevState, row.id]);
        }
        if (row.title && !row.planEventId && !row.value) {
          setSelectedStrategicPriority((prevState) => [...prevState, row.id]);
        }
      } else if (!isSelect) {
        if (row.planEventId) {
          setSelectedCapabilityTargets((prevState) =>
            prevState.filter((data) => data !== row.id)
          );
        }
        if (row.value) {
          setSelectedCoreCapabilities((prevState) =>
            prevState.filter((data) => data !== row.id)
          );
        }
        if (row.exerciseobjective) {
          setSelectedExerciseObjectives((prevState) =>
            prevState.filter((data) => data !== row.id)
          );
        }
        if (row.jmetId) {
          setSelectedAccrediatedTasks((prevState) =>
            prevState.filter((data) => data !== row.id)
          );
        }
        if (row.planEventId && !row.exerciseobjective) {
          setSelectedCommandPriority((prevState) =>
            prevState.filter((data) => data !== row.id)
          );
        }
        if (row.title && !row.planEventId && !row.value) {
          setSelectedStrategicPriority((prevState) =>
            prevState.filter((data) => data !== row.id)
          );
        }
      }
    },
    onSelectAll: (...e) => {
      if (e[0] === true) {
        e[1].map((e) => {
          if (e.planEventId) {
            setSelectedCapabilityTargets((prevState) => [...prevState, e.id]);
          }
        });
        e[1].map((e) => {
          if (e.value) {
            setSelectedCoreCapabilities((prevState) => [...prevState, e.id]);
          }
        });
        e[1].map((e) => {
          if (e.exerciseobjective) {
            setSelectedExerciseObjectives((prevState) => [...prevState, e.id]);
          }
        });
        e[1].map((e) => {
          if (e.jmetId) {
            setSelectedAccrediatedTasks((prevState) => [...prevState, e.id]);
          }
        });
        e[1].map((e) => {
          if (e.planEventId && !e.exerciseobjective) {
            setSelectedCommandPriority((prevState) => [...prevState, e.id]);
          }
        });
        e[1].map((e) => {
          if (e.title && !e.planEventId && !e.value) {
            setSelectedStrategicPriority((prevState) => [...prevState, e.id]);
          }
        });
      } else if (e[0] === false) {
        e[1].map((e) => {
          if (e.planEventId) {
            setSelectedCapabilityTargets([]);
          }
        });
        e[1].map((e) => {
          if (e.value) {
            setSelectedCoreCapabilities([]);
          }
        });
        e[1].map((e) => {
          if (e.exerciseobjective) {
            setSelectedExerciseObjectives([]);
          }
        });
        e[1].map((e) => {
          if (e.jmetId) {
            setSelectedAccrediatedTasks([]);
          }
        });
        e[1].map((e) => {
          if (e.planEventId && !e.exerciseobjective) {
            setSelectedCommandPriority([]);
          }
        });
        e[1].map((e) => {
          if (e.title && !e.planEventId && !e.value) {
            setSelectedStrategicPriority([]);
          }
        });
      }
    },
  };

  const getAllObjectives = async () => {
    dispatch(startLoading());
    try {
      const obj = await Network.get(API.fetchAllObjectives, {
        workspaceId: workspaceId,
        planEventId: exerciseId,
        limit: perPageExerciseObjectives,
        page: pageExerciseObjectives,
      });
      setObjectives(obj.data.response.dataset);
      if (obj.data.response.total) {
        setTotalExerciseObjectives(obj.data.response.total);
      }
    } catch (error) {
      toast.error(error.response.data.response.status.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };

  const getAllAccreditedTasks = async () => {
    dispatch(startLoading());
    try {
      Network.get(API.fetchAllAccreditedTasks, {
        workspaceId: workspaceId,
        planEventId: exerciseId,
        limit: perPageAccrediatedTasks,
        page: pageAccrediatedTasks,
      })
        .then((resp) => {
          setAccreditedTasks(resp.data.response.dataset);
          if (resp.data.response.total) {
            setTotalAccreditedTasks(resp.data.response.total);
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          dispatch(endLoading());
        });
    } catch (error) {
      toast.error(error.response.data.response.status.msg, toastConfig);
    }
    //  finally {
    //   dispatch(endLoading());
    // }
  };

  const getAllCommandPriority = async () => {
    dispatch(startLoading());
    try {
      const commandPrio = await Network.get(API.fetchAllCommandPriority, {
        workspaceId: workspaceId,
        limit: perPageCommandPriority,
        page: pageCommandPriority,
      });
      setCommandPriority(commandPrio.data.response.dataset);
      if (commandPrio.data.response.total) {
        setTotalCommandPriority(commandPrio.data.response.total);
      }
    } catch (error) {
      toast.error(error.response.data.response.status.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };

  const planJSTPlist = async () => {
    dispatch(startLoading());
    try {
      const JSTP = await Network.get(API.planJSTPlist, {
        workspaceId: workspaceId,
        limit: perPageStrategicPriority,
        page: pageStrategicPriority,
      });

      setAllJSTP(JSTP.data.response.dataset);

      setTotalStrategicPriority(JSTP.data.response.count);
    } catch (error) {
      toast.error(error.response.data.response.status.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };

  const fetchAllTrainingObjectives = async () => {
    dispatch(startLoading());
    try {
      await Network.get(API.fetchAllTrainingObjectivesAssociation, {
        workspaceId: workspaceId,
        trainingObjectiveId: props.id,
      }).then((resp) => {
        setSelectedExerciseObjectives(
          resp.data.response.dataset[0].exerciseobjective.map(
            (eO) => eO.exerciseObjectiveId
          )
        );
        setSelectedAccrediatedTasks(
          resp.data.response.dataset[1].accrediatedtask.map(
            (aT) => aT.accreditedTaskId
          )
        );
        setSelectedCommandPriority(
          resp.data.response.dataset[2].commandpriority.map(
            (cP) => cP.commandPriorityId
          )
        );
        setSelectedStrategicPriority(
          resp.data.response.dataset[3].trainingpriority.map(
            (tP) => tP.trainingPriorityId
          )
        );
      });
    } catch (error) {
      toast.error(error?.response?.data?.response?.status?.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };

  const fetchAllTrainingObjectivesHseep = async () => {
    dispatch(startLoading());
    try {
      await Network.get(API.fetchAllTrainingObjectivesAssociationHseep, {
        workspaceId: workspaceId,
        trainingObjectiveId: props.id,
      }).then((resp) => {
        setSelectedCapabilityTargets(
          resp.data.response.dataset[0].organizationCapabilities.map(
            (cT) => cT.capabilityTargetId
          )
        );
        setSelectedCoreCapabilities(
          resp.data.response.dataset[0].coreCapabilities
            .map((cC) => cC.capabilityTargetId)
            .filter((data) => data)
        );
        setSelectedStrategicPriority(
          resp.data.response.dataset[0].trainingpriority.map(
            (tP) => tP.trainingPriorityId
          )
        );
      });
    } catch (error) {
      toast.error(error?.response?.data?.response?.status?.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };

  const getAllCoreCapabilitiesList = async () => {
    dispatch(startLoading());
    try {
      Network.get(API.fetchAllCoreData, {
        limit: perPageCoreCapabilities,
        page: pageCoreCapabilities,
        workspaceId: workspaceId,
      })
        .then((resp) => {
          const coreDataList = resp.data.response.dataset.map((ele) => {
            return {
              ...ele,
              value: ele.id,
              title: `${ele.title} | ${ele?.areas
                .map((element) => element.area)
                .join(', ')}`,
            };
          });
          setAllCoreCapabilities(coreDataList);
          setTotalCoreCapabilities(resp.data.response.total);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          dispatch(endLoading());
        });
    } catch (error) {
      toast.error(error.response.data.response.status.msg, toastConfig);
    }
  };

  const getOganizationalCapabilityTarget = async () => {
    dispatch(startLoading());
    try {
      const obj = await Network.get(API.fetchAllOganizationalCapabilityTarget, {
        limit: perPageCapabilityTargets,
        page: pageCapabilityTargets,
        workspaceId: workspaceId,
      });
      setAllCapabilityTargets(obj.data.response.dataset);
      if (obj.data.response.total) {
        setTotalCapabilityTargets(obj.data.response.total);
      }
    } catch (error) {
      toast.error(error.response.data.response.status.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };

  const saveAndExit = () => {
    dispatch(startLoading());
    Network.post(API.addTrainingObjectiveAssociation, {
      workspaceId: workspaceId,
      trainingObjectiveId: props.id,
      exerciseobjective: selectedExerciseObjectives,
      accrediatedtask: selectedAccrediatedTasks,
      commandpriority: selectedCommandPriority,
      trainingpriority: selectedStrategicPriority,
    })
      .then(() => {
        navigate(`${PreparePath}/dashboard/plan/prepare/training-objective`);
        toast.success('Saving', toastConfig);
      })
      .catch((err) => {
        toast.error(err.response.data.response.status.msg, toastConfig);
      })
      .finally(() => {
        dispatch(endLoading());
      });
  };
  const saveAndExitHseep = () => {
    let capabilityData = selectedCapabilityTargets.map((e) => {
      return {
        workspaceId: workspaceId,
        trainingObjectiveId: props.id,
        capabilityTargetId: e,
      };
    });
    let coreData = selectedCoreCapabilities.map((e) => {
      return {
        workspaceId: workspaceId,
        trainingObjectiveId: props.id,
        capabilityTargetId: e,
      };
    });
    let trainingData = selectedStrategicPriority.map((e) => {
      return {
        workspaceId: workspaceId,
        trainingObjectiveId: props.id,
        trainingPriorityId: e,
      };
    });
    dispatch(startLoading());
    Network.post(API.addTrainingObjectiveAssociationHseep, {
      workspaceId: workspaceId,
      trainingObjectiveId: props.id,
      organizationCapabilities: capabilityData,
      coreCapabilities: coreData,
      trainingPriorities: trainingData,
    })
      .then(() => {
        navigate(`${PreparePath}/dashboard/plan/prepare/training-objective`);

        toast.success('Saving', toastConfig);
      })
      .catch((err) => {
        toast.error(err.response.data.response.status.msg, toastConfig);
      })
      .finally(() => {
        dispatch(endLoading());
      });
  };

  const onSubmit = () => {
    dispatch(startLoading());
    Network.post(API.addTrainingObjectiveAssociation, {
      workspaceId: workspaceId,
      trainingObjectiveId: props.id,
      exerciseobjective: selectedExerciseObjectives,
      accrediatedtask: selectedAccrediatedTasks,
      commandpriority: selectedCommandPriority,
      trainingpriority: selectedStrategicPriority,
    })
      .then(() => {
        if (props?.participantType === 'HSEEP') {
          props.onNextClick(2);
        } else if (props?.participantType === 'DOD') {
          props.onNextClick(3);
        }
        toast.success('Saving', toastConfig);
      })
      .catch((err) => {
        toast.error(err.response.data.response.status.msg, toastConfig);
      })
      .finally(() => {
        dispatch(endLoading());
      });
  };

  const onSubmitHSEEP = () => {
    let capabilityData = selectedCapabilityTargets.map((e) => {
      return {
        workspaceId: workspaceId,
        trainingObjectiveId: props.id,
        capabilityTargetId: e,
      };
    });
    let coreData = selectedCoreCapabilities.map((e) => {
      return {
        workspaceId: workspaceId,
        trainingObjectiveId: props.id,
        capabilityTargetId: e,
      };
    });
    let trainingData = selectedStrategicPriority.map((e) => {
      return {
        workspaceId: workspaceId,
        trainingObjectiveId: props.id,
        trainingPriorityId: e,
      };
    });
    dispatch(startLoading());
    Network.post(API.addTrainingObjectiveAssociationHseep, {
      workspaceId: workspaceId,
      trainingObjectiveId: props.id,
      organizationCapabilities: capabilityData,
      coreCapabilities: coreData,
      trainingPriorities: trainingData,
    })
      .then(() => {
        if (props?.participantType === 'HSEEP') {
          props.onNextClick(2);
        } else if (props?.participantType === 'DOD') {
          props.onNextClick(3);
        }
        toast.success('Saving', toastConfig);
      })
      .catch((err) => {
        toast.error(err.response.data.response.status.msg, toastConfig);
      })
      .finally(() => {
        dispatch(endLoading());
      });
  };

  useEffect(() => {
    props?.id &&
      workspaceType === 'HSEEP' &&
      getOganizationalCapabilityTarget();
  }, [reduxUser, perPageCapabilityTargets, pageCapabilityTargets]);

  useEffect(() => {
    getAllCoreCapabilitiesList();
  }, [reduxUser, pageCoreCapabilities, perPageCoreCapabilities]);

  useEffect(() => {
    props?.id && workspaceType === 'DOD' && getAllObjectives();
  }, [reduxUser, pageExerciseObjectives, perPageExerciseObjectives]);

  useEffect(() => {
    props?.id && workspaceType === 'DOD' && getAllAccreditedTasks();
  }, [reduxUser, pageAccrediatedTasks, perPageAccrediatedTasks]);

  useEffect(() => {
    props?.id && workspaceType === 'DOD' && getAllCommandPriority();
  }, [reduxUser, pageCommandPriority, perPageCommandPriority]);

  useEffect(() => {
    planJSTPlist();
  }, [reduxUser, pageStrategicPriority, perPageStrategicPriority]);

  useEffect(() => {
    props?.id && workspaceType === 'DOD' && fetchAllTrainingObjectives();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reduxUser]);

  useEffect(() => {
    props?.id && workspaceType === 'HSEEP' && fetchAllTrainingObjectivesHseep();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reduxUser]);

  useEffect(() => {
    fetchAllPlatform();
  }, [reduxUser]);

  useEffect(() => {
    if (workspaceType === 'HSEEP') {
      if (props.id && !!allPlatform && allPlatform.length) {
        getAllExerciseObjectives();
      }
    }
  }, [props.id, reduxUser, allPlatform]);

  const handleExerciseObjectivePageClick = (selectedPage) => {
    setPageExerciseObjectives(selectedPage);
  };

  const handleAccrediatedTaskPageClick = (selectedPage) => {
    setPageAccrediatedTasks(selectedPage);
  };

  const handleCommandPriorityPageClick = (selectedPage) => {
    setPageCommandPriority(selectedPage);
  };

  const handleStrategicPriorityPageClick = (selectedPage) => {
    setPageStrategicPriority(selectedPage);
  };

  const handleCoreCapabilitiesPageClick = (selectedPage) => {
    setPageCoreCapabilities(selectedPage);
  };

  const handleCapabilityTargetsPageClick = (selectedPage) => {
    setPageCapabilityTargets(selectedPage);
  };

  const fetchAllPlatform = async () => {
    dispatch(startLoading());
    try {
      Network.get(API.fetchAllPlatform, {
        workspaceId: workspaceId,
        participantId: props?.participantId,
      })
        .then((resp) => {
          setAllPlatform(
            resp?.data?.response.dataset.map((e) => {
              return {
                value: e.id,
                label: e.title,
              };
            })
          );
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          dispatch(endLoading());
        });
    } catch (error) {
      toast.error(error?.response?.data?.response.status.msg, toastConfig);
    }
  };

  const getAllExerciseObjectives = async () => {
    dispatch(startLoading());
    try {
      Network.get(API.getAllExerciseObjectives, {
        id: props.id,
      })
        .then((resp) => {
          let platformData = {};
          platformData =
            allPlatform.length &&
            allPlatform.find(
              (e) => e?.value === resp.data.response.dataset[0]?.platformId
            );
          platformData && setValue('platform', platformData);
          setAllPlatformAfterSave(platformData);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          dispatch(endLoading());
        });
    } catch (error) {
      toast.error(error.response.data.response.status.msg, toastConfig);
    }
  };

  return (
    <>
      {workspaceType && workspaceType === 'DOD' ? (
        <div>
          {/* DOD Version */}
          <div className="d-flex align-items-center mb-4">
            <h4 className="m-0 me-4">
              {props?.participantName} Objective Associations
            </h4>
            <StylishHelp
              classes={'ms-auto sml flex-shrink-0'}
              title={HelpTrainingObjectiveAssociations.title}
              content={HelpTrainingObjectiveAssociations.content}
              link={selfHostedKnowledgeBase || HelpTrainingObjectiveAssociations.link}
            />
          </div>
          <div className="form-block">
            <div className="mb-5">
              <div className="d-flex align-items-center mb-2">
                <p className="m-0 me-3 weight-600">
                  Exercise Objectives Selected:{' '}
                  {selectedExerciseObjectives.length}
                </p>
                <div className="ms-auto flex-shrink-0">
                  <PaginationDropdown
                    setPage={setPageExerciseObjectives}
                    setPerPage={setPerPageExerciseObjectives}
                    options={paginationOptions}
                  />
                </div>
              </div>
              <StylishNewTable
                keyField={'id'}
                columns={eo_columns}
                rows={objectives}
                expandRow={expandRowEO}
                selectRow={selectRow}
                pagination={true}
                total={totalExerciseObjectives}
                page={pageExerciseObjectives}
                perPage={perPageExerciseObjectives}
                onPageChange={handleExerciseObjectivePageClick}
              />
            </div>
            <div className="mb-5">
              <div className="d-flex align-items-center mb-2">
                <p className="m-0 me-3 weight-600">
                  Accredited Tasks Selected: {selectedAccrediatedTasks.length}
                </p>
                <div className="ms-auto flex-shrink-0">
                  <PaginationDropdown
                    setPage={setPageAccrediatedTasks}
                    setPerPage={setPerPageAccrediatedTasks}
                    options={paginationOptions}
                  />
                </div>
              </div>
              <StylishNewTable
                keyField={'id'}
                columns={at_columns}
                rows={accreditedTasks.map((data) => ({
                  id: data.id,
                  task_title: data.jmets.taskTitle,
                  level_of_war: data.jmets.levelWar,
                  task_number: data.jmets.taskNumber,
                  jmetId: data.jmets.id,
                }))}
                selectRow={selectRow}
                pagination={true}
                total={totalAccreditedTasks}
                page={pageAccrediatedTasks}
                perPage={perPageAccrediatedTasks}
                onPageChange={handleAccrediatedTaskPageClick}
              />
            </div>
            <div className="mb-5">
              <div className="d-flex align-items-center mb-2">
                <p className="m-0 me-3 weight-600">
                  Command Training Priorities Selected:{' '}
                  {selectedCommandPriority.length}
                </p>
                <div className="ms-auto flex-shrink-0">
                  <PaginationDropdown
                    setPage={setPageCommandPriority}
                    setPerPage={setPerPageCommandPriority}
                    options={paginationOptions}
                  />
                </div>
              </div>
              <StylishNewTable
                keyField={'id'}
                columns={ctp_columns}
                rows={commandPriority}
                expandRow={expandRowCTP}
                selectRow={selectRow}
                pagination={true}
                total={totalCommandPriority}
                page={pageCommandPriority}
                perPage={perPageCommandPriority}
                onPageChange={handleCommandPriorityPageClick}
              />
            </div>
            <div className="mb-0">
              <div className="d-flex align-items-center mb-2">
                <p className="m-0 me-3 weight-600">
                  Strategic Priorities Selected:{' '}
                  {selectedStrategicPriority.length}
                </p>
                <div className="ms-auto flex-shrink-0">
                  <PaginationDropdown
                    setPage={setPageStrategicPriority}
                    setPerPage={setPerPageStrategicPriority}
                    options={paginationOptions}
                  />
                </div>
              </div>
              <StylishNewTable
                keyField={'id'}
                columns={jstp_columns}
                rows={allJSTP}
                expandRow={expandRowJSTP}
                selectRow={selectRow}
                pagination={true}
                total={totalStrategicPriority}
                page={pageStrategicPriority}
                perPage={perPageStrategicPriority}
                onPageChange={handleStrategicPriorityPageClick}
              />
            </div>
          </div>
          <div className="col-md-12 mt-4">
            <div className="button-group d-block d-md-flex">
              <StylishNewButton
                type="submit"
                className="button button--primary button--reverse w-100 w-md-auto"
                onClick={() => {
                  props?.participantType === 'HSEEP'
                    ? props.onPrevClick(0)
                    : props.onPrevClick(1);
                }}
              >
                Previous
              </StylishNewButton>
              <StylishNewButton
                type="button"
                className="button button--tertiary button--reverse ms-auto mt-3 mt-md-0 w-100 w-md-auto"
                onClick={saveAndExit}
              >
                Save &amp; Exit
              </StylishNewButton>
              <StylishNewButton
                type="submit"
                className="button button--primary  w-100 w-md-auto mt-3 mt-md-0 ms-0 ms-md-3"
                onClick={onSubmit}
              >
                Next
              </StylishNewButton>
            </div>
          </div>
          {/* DOD Version */}
        </div>
      ) : workspaceType && workspaceType === 'HSEEP' ? (
        <div>
          {/* HSEEP Version */}
          <div className="d-flex align-items-center mb-4">
            <div>
              <h4 className="m-0">
                {props?.participantName} Exercise Objective Associations
              </h4>
              <span className="d-block">{allPlatformAfterSave?.label}</span>
            </div>
            <StylishHelp
              classes={'ms-auto sml flex-shrink-0'}
              title={HelpTrainingObjectiveAssociations.title}
              content={HelpTrainingObjectiveAssociations.content}
              link={selfHostedKnowledgeBase || HelpTrainingObjectiveAssociations.link}
            />
          </div>
          <div className="form-block">
            <div className="mb-5">
              <div className="d-flex align-items-center mb-2">
                <p className="m-0 me-3 weight-600">
                  Organizational Capability Targets Selected:{' '}
                  {selectedCapabilityTargets.length}
                </p>
                <div className="ms-auto flex-shrink-0">
                  <PaginationDropdown
                    setPage={setPageCapabilityTargets}
                    setPerPage={setPerPageCapabilityTargets}
                    options={paginationOptions}
                  />
                </div>
              </div>
              <StylishNewTable
                keyField={'id'}
                columns={eo_columns}
                rows={allCapabilityTargets}
                expandRow={expandRowJSTP}
                selectRow={selectRow}
                pagination={true}
                total={totalCapabilityTargets}
                page={pageCapabilityTargets}
                perPage={perPageCapabilityTargets}
                onPageChange={handleCapabilityTargetsPageClick}
              />
            </div>
            <div className="mb-5">
              <div className="d-flex align-items-center mb-2">
                <p className="m-0 me-3 weight-600">
                  Core Capabilities and Associated Mission Area(s):{' '}
                  {selectedCoreCapabilities.length}
                </p>
                <div className="ms-auto flex-shrink-0">
                  <PaginationDropdown
                    setPage={setPageCoreCapabilities}
                    setPerPage={setPerPageCoreCapabilities}
                    options={paginationOptions}
                  />
                </div>
              </div>
              <StylishNewTable
                keyField={'id'}
                columns={ctp_columns}
                rows={allCoreCapabilities}
                expandRow={expandRowJSTP}
                pagination={true}
                selectRow={selectRow}
                total={totalCoreCapabilities}
                page={pageCoreCapabilities}
                perPage={perPageCoreCapabilities}
                onPageChange={handleCoreCapabilitiesPageClick}
              />
            </div>
            <div className="mb-0">
              <div className="d-flex align-items-center mb-2">
                <p className="m-0 me-3 weight-600">
                  Strategic Priorities Selected:{' '}
                  {selectedStrategicPriority.length}
                </p>
                <div className="ms-auto flex-shrink-0">
                  <PaginationDropdown
                    setPage={setPageStrategicPriority}
                    setPerPage={setPerPageStrategicPriority}
                    options={paginationOptions}
                  />
                </div>
              </div>
              <StylishNewTable
                keyField={'id'}
                columns={jstp_columns}
                rows={allJSTP}
                expandRow={expandRowJSTP}
                selectRow={selectRow}
                pagination={true}
                total={totalStrategicPriority}
                page={pageStrategicPriority}
                perPage={perPageStrategicPriority}
                onPageChange={handleStrategicPriorityPageClick}
              />
            </div>
          </div>
          <div className="col-md-12 mt-4">
            <div className="button-group d-block d-md-flex">
              <StylishNewButton
                type="submit"
                className="button button--primary button--reverse w-100 w-md-auto"
                onClick={() => {
                  props?.participantType === 'HSEEP'
                    ? props.onPrevClick(0)
                    : props.onPrevClick(1);
                }}
              >
                Previous
              </StylishNewButton>
              <StylishNewButton
                type="button"
                className="button button--tertiary button--reverse ms-auto mt-3 mt-md-0 w-100 w-md-auto"
                onClick={saveAndExitHseep}
              >
                Save &amp; Exit
              </StylishNewButton>
              <StylishNewButton
                type="submit"
                className="button button--primary  w-100 w-md-auto mt-3 mt-md-0 ms-0 ms-md-3"
                onClick={onSubmitHSEEP}
              >
                Next
              </StylishNewButton>
            </div>
          </div>
          {/* HSEEP Version */}
        </div>
      ) : null}
    </>
  );
}
