import { Modal } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import StylishNewSelect from 'components/DesignSystems/New/StylishNewSelect';
import { inputStringLength, toastConfig } from 'assets/data/config';
import StylishNewInput from 'components/DesignSystems/New/StylishNewInput';
import StylishNewTable from 'components/DesignSystems/New/StylishNewTable';
import { StylishNewSearchBar } from 'components/DesignSystems/New/StylishNewSearchBar';
import { useEffect, useRef, useState } from 'react';
import { StylishSwitcher } from 'components/DesignSystems/New/StylishSwitcher';
import { useDispatch, useSelector } from 'react-redux';
import Network from 'PREPAREsrc/service/Network';
import API from 'PREPAREsrc/service/api';
import { toast } from 'react-toastify';

import { endLoading, startLoading } from "../../../../../reducers/loading/loading.action";

const selectOptions = [
  { label: 'Blue Cell', value: 'Friendly Forces' },
  { label: 'Red Cell', value: 'Adversarial Forces' },
  { label: 'White Cell', value: 'Event Management' },
  { label: 'Purple Cell', value: 'M&S/Engineering/Post Event Ops' },
  { label: 'Gold Cell', value: 'Leadership Decisions' },
  { label: 'EXCON', value: 'Experiment Control' },
];

export const AddEditCellDialog = ({
  editCell,
  show,
  onClose,
  onSuccessClose,
  selectedCell,
}) => {
  const dispatch = useDispatch();
  const { selectedWorkspace } = useSelector(
    (state) => state?.prepare?.workspace
  );
  const eventId = useSelector((state) => state.prepare.planDashboard.eventId);

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm();

  const [searchTerm, setSearchTerm] = useState();
  const [eventCell, setEventCell] = useState();
  const [users, setUsers] = useState([]);
  const [userToAdd, setUsersToAdd] = useState([]);
  const hasPrimaryUser = useRef(null);
  const isFetchNeed = useRef(false);

  useEffect(() => {
    if (editCell) {
      fetchEventCellById(editCell.id);
    }
  }, [editCell]);

  useEffect(() => {
    const isPrimary = users.find((u) => u.isPrimary);
    if (isPrimary) {
      hasPrimaryUser.current = isPrimary;
    } else {
      hasPrimaryUser.current = null;
    }
  }, [users]);

  const onChangeSearchTerm = (term) => {
    setSearchTerm(term);
    const filterUser = eventCell.userToAdd.filter((user) =>
      user.name.toLowerCase().includes(term.toLowerCase())
    );
    setUsersToAdd(filterUser);
  };

  const fetchEventCellById = async (id) => {
    dispatch(startLoading());
    try {
      const response = await Network.get(API.getEventCellById, {
        id,
      });
      const cell = response.data.response.dataset[0];
      const findCell = selectOptions.filter((c) => c.label === cell.cell);
      setValue('cell', findCell[0]);
      setValue('responsibility', cell.responsibility);

      setUsersToAdd([...cell.userToAdd]);
      setEventCell(cell);
      setUsers([...cell.users.toReversed()]);
    } catch (error) {
      toast.error(error.response.data.response.status.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };

  const addUserToCell = async (row) => {
    dispatch(startLoading());
    const cellObj = {
      id: eventCell.id,
      workspaceId: eventCell.workspaceId,
      userId: row.id,
    };
    try {
      const response = await Network.post(API.assignUserToEventCell, cellObj);
      const eventCell = response.data.response.dataset[0];
      setUsers([...eventCell.users].toReversed());
      setUsersToAdd(eventCell.userToAdd);
      setEventCell(eventCell);
    } catch (error) {
      console.error(error);
      toast.error(error.response.data.response.status.msg, toastConfig);
    } finally {
      dispatch(endLoading());
      isFetchNeed.current = true;
    }
  };

  const removeUserFromEventCell = async (row) => {
    dispatch(startLoading());
    const cellObj = {
      id: eventCell.id,
      workspaceId: eventCell.workspaceId,
      userId: row.id,
    };
    try {
      const response = await Network.post(API.removeUserFromEventCell, cellObj);
      const eventCell = response.data.response.dataset[0];
      setUsers([...eventCell.users].toReversed());
      setUsersToAdd(eventCell.userToAdd);
      setEventCell(eventCell);
    } catch (error) {
      console.error(error);
      toast.error(error.response.data.response.status.msg, toastConfig);
    } finally {
      dispatch(endLoading());
      isFetchNeed.current = true;
    }
  };

  const changeUserPrimary = async (row) => {
    if (row?.id === hasPrimaryUser?.current?.id) {
      toast.info('At least one primary user is required', toastConfig);
      return;
    }
    dispatch(startLoading());
    const cellObj = {
      id: eventCell.id,
      userId: row.id,
      isPrimary: true,
    };
    try {
      if (hasPrimaryUser.current) {
        await Network.post(API.primaryUserEventCell, {
          id: eventCell.id,
          userId: hasPrimaryUser.current.id,
          isPrimary: false,
        });
      }
      const response = await Network.post(API.primaryUserEventCell, cellObj);
      const newUsers = response.data.response.dataset;
      setUsers([...newUsers]);
      setEventCell((prev) => {
        return { ...prev, users: newUsers };
      });
    } catch (error) {
      console.error(error);
      toast.error(error.response.data.response.status.msg, toastConfig);
    } finally {
      dispatch(endLoading());
      isFetchNeed.current = true;
    }
  };

  const onSubmit = async (data) => {
    editCell ? onEditEventCell(data) : onAddNewEventCell(data);
  };

  const onEditEventCell = async (data) => {
    if (!!!hasPrimaryUser.current) {
      toast.error(
        `No primary member is selected. Please select a primary member.`,
        toastConfig
      );
      return;
    }
    if (
      data.cell.label === editCell.cell &&
      data.responsibility === editCell.responsibility
    ) {
      dispatch(endLoading());

      onClose(isFetchNeed.current);

      return;
    }
    dispatch(startLoading());
    try {
      let cellObj = {
        id: eventCell.id,
        cell: data.cell.label,
        responsibility: data.responsibility,
        workspaceId: selectedWorkspace,
      };
      await Network.post(API.updateEventCell, cellObj);
      toast.success('Cell updated successfully', toastConfig);
      onSuccessClose();
    } catch (error) {
      console.error(error);
      dispatch(endLoading());

      toast.error('Event Cell Already Exist', toastConfig);
    }
  };

  const onAddNewEventCell = async (data) => {
    dispatch(startLoading());
    const cellObj = {
      workspaceId: selectedWorkspace,
      planEventId: eventId,
      cell: data.cell.label,
      responsibility: data.responsibility,
    };
    try {
      await Network.post(API.createEventCell, cellObj);
      toast.success('Cell created successfully', toastConfig);
      onSuccessClose();
    } catch (error) {
      if (error.response.status == 409) {
        toast.error('Event Cell Already Exist', toastConfig);
        dispatch(endLoading());
        return;
      }
      console.error(error);
      toast.error(error.response.data.response.status.msg, toastConfig);
    }
  };

  const currentMembersColumns = [
    {
      dataField: 'name',
      text: 'Name',
      sort: true,
      headerAttrs: { title: 'Sort By:' },
      attrs: { title: 'Name' },
    },
    // {
    //   dataField: 'phone_mobile',
    //   text: 'Phone (Mobile)',
    //   sort: true,
    //   attrs: { title: 'Phone (Mobile)' },
    // },
    {
      dataField: 'email',
      text: 'Email',
      sort: false,
      attrs: { title: 'Email' },
    },
    {
      dataField: 'isPrimary',
      text: 'Primary',
      sort: false,
      attrs: { title: 'Primary' },

      formatter: (cell, row) => {
        return (
          <StylishSwitcher
            checked={row.isPrimary}
            onChange={() => changeUserPrimary(row)}
          />
        );
      },
      headerStyle: {
        width: '100px',
      },
      headerAlign: 'center',
      align: 'center',
    },
    {
      text: 'Action',
      dataField: 'action',
      isDummyField: true,
      formatter: (cell, row, rowIndex) =>
        !row.isPrimary ? (
          <StylishNewButton
            className="button--action"
            type="button"
            onClick={() => removeUserFromEventCell(row)}
          >
            <i className="fa fa-minus" aria-hidden="true"></i>
          </StylishNewButton>
        ) : null,
      attrs: { title: 'Action' },
      headerStyle: {
        width: '100px',
      },
      headerAlign: 'center',
      align: 'center',
    },
  ];

  const addMembersColumns = [
    {
      dataField: 'name',
      text: 'Name',
      sort: true,
      headerAttrs: { title: 'Sort By:' },
      attrs: { title: 'Name' },
    },
    // {
    //   dataField: 'phone_mobile',
    //   text: 'Phone (Mobile)',
    //   sort: true,
    //   attrs: { title: 'Phone (Mobile)' },
    // },
    {
      dataField: 'email',
      text: 'Email',
      sort: true,
      attrs: { title: 'Email' },
    },
    {
      text: 'Action',
      dataField: 'action',
      isDummyField: true,
      formatter: (cell, row, rowIndex) => (
        <StylishNewButton
          className="button--action"
          type="button"
          onClick={() => addUserToCell(row)}
        >
          <i className="fa fa-plus" aria-hidden="true"></i>
        </StylishNewButton>
      ),
      attrs: { title: 'Action' },
      headerStyle: {
        width: '100px',
      },
      headerAlign: 'center',
      align: 'center',
    },
  ];

  return (
    <Modal
      show={show}
      onHide={() => onClose(isFetchNeed.current)}
      centered
      backdrop="static"
      keyboard={false}
      size="lg"
    >
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title>
          {!!editCell ? 'Edit Event Cell/Members' : 'Add Event Cell'}
        </Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <div className="row position-relative" style={{ zIndex: '999' }}>
            <div className="col-md-4 mb-4 mb-md-0">
              <label className="form-label">Cell</label>
              <Controller
                control={control}
                name="cell"
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, value } }) => (
                  <StylishNewSelect
                    options={selectOptions.filter(
                      (op) =>
                        !selectedCell.includes(op.label) ||
                        op.label === eventCell?.cell
                    )}
                    value={value}
                    onChange={(e) => {
                      setValue('responsibility', e?.value, {
                        shouldValidate: true,
                      });
                      onChange(e);
                    }}
                    isSearchable={false}
                    isClearable={false}
                    isMulti={false}
                  />
                )}
              />
              {errors.cell?.type === 'required' && (
                <span className="form-text form-error">
                  This field is required
                </span>
              )}
            </div>
            <div className="col-md-8">
              <label className="form-label">Responsibility</label>
              <Controller
                control={control}
                name="responsibility"
                rules={{
                  required: true,
                  maxLength: inputStringLength,
                }}
                render={({ field: { onChange, value } }) => (
                  <StylishNewInput
                    readOnly
                    type="text"
                    onChange={onChange}
                    value={value || ''}
                  />
                )}
              />
              {errors.responsibility?.type === 'required' && (
                <span className="form-text form-error">
                  This field is required
                </span>
              )}
              {errors?.responsibility?.type === 'maxLength' && (
                <span className="form-text form-error">
                  Max {inputStringLength} character allowed
                </span>
              )}
            </div>
          </div>
          {!!editCell && (
            <>
              <div className="mt-4">
                <h5 className="mb-2">Current Members</h5>
                <div className="table-scroll">
                  <StylishNewTable
                    keyField={'id'}
                    columns={currentMembersColumns}
                    rows={users}
                  />
                </div>
              </div>
              <div className="mt-4">
                <div className="d-md-flex align-items-center mb-2">
                  <h5 className="mb-2 mb-md-0">Add Members</h5>
                  <div className="ms-auto">
                    <StylishNewSearchBar
                      value={searchTerm}
                      onChangeSearchTerm={onChangeSearchTerm}
                    />
                  </div>
                </div>
                <div className="table-scroll">
                  <StylishNewTable
                    keyField={'id'}
                    columns={addMembersColumns}
                    rows={userToAdd}
                  />
                </div>
              </div>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <div className="button-group">
            <StylishNewButton
              className="button--secondary button--reverse"
              onClick={() => onClose(isFetchNeed.current)}
            >
              Cancel
            </StylishNewButton>

            <StylishNewButton className="button--primary" type="submit">
              Save
            </StylishNewButton>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
};
