import React, { useState } from 'react';

import ParticipantDetails from './Details/ParticipantDetails';
import { ParticipantPlanner } from './Planner/ParticipantPlanner';
import { ParticipantPlatform } from './Platform/ParticipantPlatform';
import { FundingSources } from './FundingSource/FundingSources';
import { Review } from './Review/Review';
import { useParams, Link, useNavigate } from 'react-router-dom';
import IconTick from 'assets/images/icon__tick--blue.svg';
import { PreparePath } from 'utils/sharedUtils/sharedConst';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { SharedIcon } from 'components/SharedIcon/SharedIcon';

export default function ParticipantMain() {
  const navigate = useNavigate();
  const params = useParams();
  const [step, setStep] = useState(0);
  const [participantName, setParticipantName] = useState('');

  const nextPage = (stepCount) => {
    setStep(stepCount);
    setSteps(
      steps.map((step, index) => {
        if (index === stepCount) {
          return {
            ...step,
            isValid: true,
          };
        } else {
          return { ...step, isValid: false };
        }
      })
    );
  };

  const renderStepMarker = (index) => {
    return (
      <span className="step-marker">
        {step <= index ? index + 1 : <img src={IconTick} alt="" />}
      </span>
    );
  };

  const changeName = (name) => setParticipantName(name);
  const [steps, setSteps] = useState([
    {
      label: 'Participant Details',
      isValid: true,
      component: (
        <ParticipantDetails
          onNextClick={nextPage}
          participantId={params.id}
          onNameChange={changeName}
        />
      ),
      className: 'step-item col-4 col-md mb-3 mb-md-0',
    },
    {
      label: 'Participant Platform',
      isValid: false,
      component: (
        <ParticipantPlatform
          onNextClick={nextPage}
          onPrevClick={nextPage}
          participantId={params.id}
          participantName={participantName}
        />
      ),
      className: 'step-item col-4 col-md mb-3 mb-md-0',
    },
    {
      label: 'ParticipantPlanner',
      isValid: false,
      component: (
        <ParticipantPlanner
          onNextClick={nextPage}
          onPrevClick={nextPage}
          participantId={params.id}
          participantName={participantName}
        />
      ),
      className: 'step-item col-4 col-md mb-3 mb-md-0',
    },
    {
      label: 'Funding Allocations',
      isValid: false,
      component: (
        <FundingSources
          onNextClick={nextPage}
          onPrevClick={nextPage}
          participantId={params.id}
          participantName={participantName}
        />
      ),
      className: 'step-item col-4 col-md',
    },
    {
      label: 'Review',
      isValid: false,
      component: <Review onPrevClick={nextPage} participantId={params.id} />,
      className: 'step-item col-4 col-md',
    },
  ]);

  return (
    <>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <StylishNewButton onClick={() => navigate(-1)}>
              <SharedIcon iconName="keyboard_arrow_left" classes="me-2" />
              Back
            </StylishNewButton>
          </li>
          <li className="breadcrumb-item">
            <Link to={`${PreparePath}/dashboard/plan/prepare/participants`}>
              Participants
            </Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            {participantName}
          </li>
        </ol>
      </nav>
      <div className="steps my-4 my-md-5">
        <div className="row">
          <div
            className={
              steps[0].className +
              (step === 0 ? ' step-current' : ' step-completed')
            }
          >
            <span>
              {renderStepMarker(0)}
              <span className="step-title">Participant Details</span>
              <span className="step-divider"></span>
            </span>
          </div>
          <div
            className={
              steps[1].className +
              (step === 1 ? ' step-current' : step > 1 ? ' step-completed' : '')
            }
          >
            <span>
              {renderStepMarker(1)}
              <span className="step-title">Participant Platforms</span>
              <span className="step-divider"></span>
            </span>
          </div>
          <div
            className={
              steps[2].className +
              (step === 2 ? ' step-current' : step > 2 ? ' step-completed' : '')
            }
          >
            <span>
              {renderStepMarker(2)}
              <span className="step-title">Participant Members</span>
              <span className="step-divider"></span>
            </span>
          </div>
          <div
            className={
              steps[3].className +
              (step === 3 ? ' step-current' : step > 3 ? ' step-completed' : '')
            }
          >
            <span>
              {renderStepMarker(3)}
              <span className="step-title">Funding Allocations</span>
              <span className="step-divider"></span>
            </span>
          </div>
          <div
            className={
              steps[4].className +
              (step === 4 ? ' step-current' : step > 4 ? ' step-completed' : '')
            }
          >
            <span className="step-last-content">
              {renderStepMarker(4)}
              <span className="step-title">Review</span>
            </span>
          </div>
        </div>
      </div>
      {steps[step]?.component}
    </>
  );
}
