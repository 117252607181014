import { Outlet } from 'react-router-dom';
import React, { useEffect } from 'react';
import { fetchIcsIncidentInfo } from '../../slices/ics_incidentInfoSlice';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { useIncidentRoster } from './hooks/incidentRosterHooks';

const IAPWrapper = () => {
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();

  // trigger preloaded fetches
  useIncidentRoster();

  useEffect(() => {
    dispatch(fetchIcsIncidentInfo(id));
  }, []);

  return (
    <div className="h-100">
      <Outlet />
    </div>
  );
};

export default IAPWrapper;
