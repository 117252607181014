import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import config from 'constants/apiConfig';
import { toast } from 'react-toastify';
import { toastConfig } from 'assets/data/config';

import {
  getUserGuid,
  getGroupGuid
} from 'slices/sliceHelpers';

// Slice
const dchatSlice = createSlice({
  name: 'dchat',
  initialState: {
    current_sitrep_id: null,
    dchat: {},
    dchatId: null,
    status: 'idle',
    error: null,
    streamtext: '',  // New state variable for websocket stream text
  },
  reducers: {
    setCurrentSitrepId: (state, action) => {
      state.current_sitrep_id = action.payload;
    },
    pollDChatSuccess: (state, action) => {
      state.dchat = action.payload;
    },
    postDChatSuccess: (state, action) => {
      state.dchatId = action.payload;
      // Nothing since polling is used to fetch data
    },
    postCancelRunSuccess: (state, action) => {
      state.dchat = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setStatus: (state, action) => {
      state.status = action.payload;
    },
    setDchatStream: (state, action) => {
      state.streamtext = action.payload;  // Update stream text
    },
    setPostDchatInit: (state, action) => {
      state.streamtext = '';  // Update stream text
    },
    setSelectedDChat: (state, action) => {
      state.dchat = action.payload; // Set selected chat
    },
  },
});

export const {
  setCurrentSitrepId,
  pollDChatSuccess,
  postDChatSuccess,
  postCancelRunSuccess,
  setError,
  setStatus,
  setDchatStream,  // New action for setting stream text
  setPostDchatInit,
  setSelectedDChat
} = dchatSlice.actions;

export default dchatSlice.reducer;

export const pollDChat = (dchatId) => {
  let interval;
  return (dispatch, getState) => {
    dispatch(setStatus('loading'));
    const user_guid = getUserGuid(getState);
    const state = getState();
    const current_sitrep_id = state.dchat.current_sitrep_id;
    const group_guid = getGroupGuid(getState);

    const data = {
      id: dchatId,
      dchat_id: dchatId,
      user_guid: user_guid,
      current_sitrep_id: current_sitrep_id,
      group_guid: group_guid,
    };

    // Polling mechanism
    interval = setInterval(() => {
      axios.get(`${config.apiGateway.disasterchat}/poll`, { params: data })
        .then((response) => {
          const dchat = response.data;
          if (!dchat) {
            dispatch(setStatus('succeeded'));
            clearInterval(interval);
          } else if (dchat.status === 'Error') {
            toast.error("DChat Error.  Cancel run and try again.", toastConfig);
            dispatch(setError("DChat Error.  Cancel run and try again."));
            dispatch(cancelRun(dchat))
            clearInterval(interval);
          } else if (dchat.status === 'Complete') {
            dispatch(pollDChatSuccess(dchat));
            dispatch(setStatus('succeeded'));
            clearInterval(interval);
          } else {
            dispatch(setStatus('ongoing'));
            dispatch(pollDChatSuccess(dchat));
          }
        })
        .catch((error) => {
          console.error('Error checking DChat status:', error);
          dispatch(setError(error.message));
          dispatch(setStatus('failed'));
          clearInterval(interval);
        });
    }, 500); // Check every half second
  };
};

export const postDChat = (data) => async (dispatch, getState) => {
  dispatch(setStatus('loading'));
  dispatch(setPostDchatInit())
  try {
    const user_guid = getUserGuid(getState);
    const state = getState();
    const current_sitrep_id = state.dchat.current_sitrep_id;
    const group_guid = getGroupGuid(getState);
    const payload = {
      ...data,
      user_guid,
      current_sitrep_id,
      group_guid: group_guid,
    };
    let response
    if(!!data.dchat_id)
    {
      response = await axios.put(`${config.apiGateway.disasterchat}`, payload);
    }
    else
    {
      response = await axios.post(`${config.apiGateway.disasterchat}`, payload);
    }
    dispatch(postDChatSuccess(response.data));
    dispatch(setStatus('succeeded'));
    // Begin polling for dchat data
    dispatch(pollDChat(response.data));
  } catch (error) {
    toast.error(error.message, toastConfig);
    dispatch(setError(error.message));
    dispatch(setStatus('failed'));
  }
};

export const cancelRun = (data) => async (dispatch, getState) => {
  dispatch(setStatus('loading'));
  try {
    const response = await axios.post(`${config.apiGateway.disasterchat}/cancel`, data);
    dispatch(postCancelRunSuccess(response.data));
    dispatch(setStatus('succeeded'));
    // Begin polling for dchat data
    dispatch(pollDChat(response.data));
  } catch (error) {
    toast.error(error.message, toastConfig);
    dispatch(setError(error.message));
    dispatch(setStatus('failed'));
  }
};

