import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Network from '../../../../../service/Network';
import API from '../../../../../service/api';
import { Modal } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import { inputStringLength, toastConfig } from 'assets/data/config';
import { useEffect } from 'react';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import StylishNewInput from 'components/DesignSystems/New/StylishNewInput';
import { endLoading, startLoading } from "../../../../../../reducers/loading/loading.action";
export const AddEditFromTo = ({ show, onClose, nameOfModal }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const workspaceId = useSelector(
    (state) => state.prepare.workspace.selectedWorkspace
  );

  const dispatch = useDispatch();

  const onSubmit = async (data) => {
    dispatch(startLoading());
    try {
      const newFromTo = await Network.post(API.createFromTo, {
        ...data,
        workspaceId: workspaceId,
      });
      onClose(newFromTo.data.response.dataset);
      toast.success('Inject Owner created successfully', toastConfig);
    } catch (error) {
      toast.error(error.response.data.response.status.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };
  useEffect(() => {
    const listener = (event) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        event.preventDefault();
        handleSubmit(onSubmit)();
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, []);

  return (
    <Modal
      show={show}
      onHide={onClose}
      centered
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title>{nameOfModal}</Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <label className="form-label">Name</label>
          <Controller
            control={control}
            name={'title'}
            rules={{
              required: true,
            }}
            render={({ field: { onChange, value } }) => (
              <StylishNewInput
                onChange={(e) => {
                  onChange(e);
                }}
                value={value}
                type="text"
                name="title"
                maxLength={inputStringLength}
                className="form-control"
                placeholder="Name"
              />
            )}
          />
          {errors?.title && (
            <span className="form-text form-error">This field is required</span>
          )}
        </Modal.Body>

        <Modal.Footer>
          <div className="button-group">
            <StylishNewButton
              className="button button--secondary button--reverse"
              onClick={onClose}
            >
              Close
            </StylishNewButton>
            <StylishNewButton className="button button--primary" type="submit">
              Save
            </StylishNewButton>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
};
