import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import ICSIncidentInfo from 'components/ICS/ICSIncidentInfo';
import { getRosterForIncident } from 'actions/incidentActions';


import ICSObjectivesDialog from 'components/ICS/ICSObjectivesDialog';
import ICSStrategiesDialog from 'components/ICS/ICSStrategiesDialog';
import ICSTacticsDialog from 'components/ICS/ICSTacticsDialog';
import ICSActionsDialog from 'components/ICS/ICSActionsDialog';
import ICSMedicalAidStationsDialog from 'components/ICS/ICSMedicalAidStationsDialog';
import ICSMedicalTransportationDialog from 'components/ICS/ICSMedicalTransportationDialog';
import ICSHospitalsDialog from 'components/ICS/ICSHospitalsDialog';
import ICSWorkAssignmentsDialog from 'components/ICS/ICSWorkAssignmentsDialog';
import ICSComsDialog from 'components/ICS/ICSComsDialog';
import ICSResourcesDialog from 'components/ICS/ICSResourcesDialog';
import ICSResourceRequestsDialog from 'components/ICS/ICSResourceRequestsDialog';
import ICSResourceSitesDialog from 'components/ICS/ICSResourceSitesDialog';
import ICSResourceSetsDialog from 'components/ICS/ICSResourceSetsDialog';
import ICSStrikeTeamsDialog from 'components/ICS/ICSStrikeTeamsDialog';

import { exportAsForm } from 'actions/formActions';

import './ICS.css'

import { fetchIcsIncidentInfo } from 'slices/ics_incidentInfoSlice';
import {
  fetchIcsObjectives,
  updateIcsObjective,
} from 'slices/ics_objectivesSlice';
import {
  fetchIcsIncidentObjectives,
  updateIcsIncidentObjective,
} from 'slices/ics_incidentObjectivesSlice';
import {
  fetchIcsStrategies,
  updateIcsStrategy,
} from 'slices/ics_strategiesSlice';
import {
  fetchIcsIncidentStrategies,
  updateIcsIncidentStrategy,
} from 'slices/ics_incidentStrategiesSlice';
import { fetchIcsTactics, updateIcsTactic } from 'slices/ics_tacticsSlice';
import {
  fetchIcsIncidentTactics,
  updateIcsIncidentTactic,
} from 'slices/ics_incidentTacticsSlice';
import { fetchIcsActions, updateIcsAction } from 'slices/ics_actionsSlice';
import {
  fetchIcsIncidentActions,
  updateIcsIncidentAction,
} from 'slices/ics_incidentActionsSlice';
import {
  fetchIcsIncidentResources,
  updateIcsIncidentResource,
} from 'slices/ics_incidentResourcesSlice';
import {
  createIcsResourceRequest,
  fetchIcsResourceRequests,
  updateIcsResourceRequest,
} from 'slices/ics_resourceRequestsSlice';
import {
  createIcsResourceRequestItem,
  fetchIcsResourceRequestItems,
  updateIcsResourceRequestItem,
} from 'slices/ics_resourceRequestItemsSlice';
import {
  createResourceSite,
  fetchResourceSites,
  updateResourceSite,
} from 'slices/ics_resourceSitesSlice'
import {
  createResourceSet,
  fetchResourceSets,
  updateResourceSet,
} from 'slices/ics_resourceSetsSlice'
import {
  createStrikeTeam,
  fetchStrikeTeams,
  updateStrikeTeam,
} from 'slices/ics_strikeTeamsSlice'
import {
  fetchIcsMedicalAidStations,
  updateIcsMedicalAidStation,
} from 'slices/ics_medicalAidStationsSlice';
import {
  fetchIcsIncidentMedicalAidStations,
  updateIcsIncidentMedicalAidStation,
} from 'slices/ics_incidentMedicalAidStationsSlice';
import {
  fetchIcsMedicalTransportation,
  updateIcsMedicalTransportation,
} from 'slices/ics_medicalTransportationSlice';
import {
  fetchIcsIncidentMedicalTransportation,
  updateIcsIncidentMedicalTransportation,
} from 'slices/ics_incidentMedicalTransportationSlice';
import {
  fetchIcsHospitals,
  updateIcsHospital,
} from 'slices/ics_hospitalsSlice';
import {
  fetchIcsIncidentHospitals,
  updateIcsIncidentHospital,
} from 'slices/ics_incidentHospitalsSlice';
import {
  fetchIcsWorkAssignments,
  updateIcsWorkAssignment,
} from 'slices/ics_workAssignmentsSlice';
import {
  fetchIcsIncidentWorkAssignments,
  updateIcsIncidentWorkAssignment,
} from 'slices/ics_incidentWorkAssignmentsSlice';
import {
  fetchIcsComs,
  updateIcsComs,
} from 'slices/ics_comsSlice';
import {
  fetchIcsIncidentComs,
  updateIcsIncidentComs,
} from 'slices/ics_incidentComsSlice';

export default function ICS({ reduxCurrentIncident }) {
  const reduxDispatch = useDispatch();
  const reduxCurrentlySelectedGroup = useSelector((state) => state.app.currentlySelectedGroup);

  const [
    isManageObjectivesDialogOpen,
    setIsManageObjectivesDialogOpen,
  ] = useState(false);
  const [
    isManageStrategiesDialogOpen,
    setIsManageStrategiesDialogOpen,
  ] = useState(false);
  const [isManageTacticsDialogOpen, setIsManageTacticsDialogOpen] = useState(false);
  const [isManageActionsDialogOpen, setIsManageActionsDialogOpen] = useState(false);
  const [
    isManageResourcesDialogOpen,
    setIsManageResourcesDialogOpen,
  ] = useState(false);
  const [
    isManageResourceRequestsDialogOpen,
    setIsManageResourceRequestsDialogOpen,
  ] = useState(false);
  const [
    isManageResourceRequestItemsDialogOpen,
    setIsManageResourceRequestItemsDialogOpen,
  ] = useState(false);
  const [
    isManageMedicalAidStationsDialogOpen,
    setIsManageMedicalAidStationsDialogOpen,
  ] = useState(false);
  const [
    isManageMedicalTransportationDialogOpen,
    setIsManageMedicalTransportationDialogOpen,
  ] = useState(false);
  const [
    isManageHospitalsDialogOpen,
    setIsManageHospitalsDialogOpen,
  ] = useState(false);
  const [
    isManageWorkAssignmentsDialogOpen,
    setIsManageWorkAssignmentsDialogOpen,
  ] = useState(false);
  const [
    isManageComsDialogOpen,
    setIsManageComsDialogOpen,
  ] = useState(false);
  const [
    isManageResourceSitesDialogOpen,
    setIsManageResourceSitesDialogOpen,
  ] = useState(false);
  const [
    isManageResourceSetsDialogOpen,
    setIsManageResourceSetsDialogOpen,
  ] = useState(false);
  const [
    isManageStrikeTeamsDialogOpen,
    setIsManageStrikeTeamsDialogOpen,
  ] = useState(false);
  const [ 
    isManageIncidentResourceRequirementsDialogOpen,
    setIsManageIncidentResourceRequirementsDialogOpen
  ] = useState(false)

  const reduxRosterForIncident = useSelector((state) => state.app.rosterForIncident || []);
  const { ics_incident_info } = useSelector((state) => state.ics_incident_info);
  const { ics_objectives } = useSelector((state) => state.ics_objectives);
  const { ics_incident_objectives } = useSelector((state) => state.ics_incident_objectives);
  const { ics_strategies } = useSelector((state) => state.ics_strategies);
  const { ics_incident_strategies } = useSelector((state) => state.ics_incident_strategies);
  const { ics_tactics } = useSelector((state) => state.ics_tactics);
  const { ics_incident_tactics } = useSelector((state) => state.ics_incident_tactics);
  const { ics_actions } = useSelector((state) => state.ics_actions);
  const { ics_incident_actions } = useSelector((state) => state.ics_incident_actions);
  const { ics_incident_resources } = useSelector((state) => state.ics_incident_resources);
  const { ics_resource_requests } = useSelector((state) => state.ics_resource_requests || []);
  const { ics_resource_request_items } = useSelector((state) => state.ics_resource_request_items);
  const ics_incident_resource_requests = ics_resource_requests.filter(
    (rr) => rr.incident_id === reduxCurrentIncident?.id
  );
  const { ics_resource_sites } = useSelector((state) => state.ics_resource_sites || []);
  const { ics_resource_sets } = useSelector((state) => state.ics_resource_sets || []);
  const { ics_strike_teams } = useSelector((state) => state.ics_strike_teams || []);

  const { ics_medical_aid_stations } = useSelector((state) => state.ics_medical_aid_stations);
  const { ics_incident_medical_aid_stations } = useSelector((state) => state.ics_incident_medical_aid_stations);
  const { ics_medical_transportation } = useSelector((state) => state.ics_medical_transportation);
  const { ics_incident_medical_transportation } = useSelector(
    (state) => state.ics_incident_medical_transportation
  );
  const { ics_hospitals } = useSelector((state) => state.ics_hospitals);
  const { ics_incident_hospitals } = useSelector((state) => state.ics_incident_hospitals);
  const { ics_work_assignments } = useSelector((state) => state.ics_work_assignments);
  const { ics_incident_work_assignments } = useSelector((state) => state.ics_incident_work_assignments);
  const { ics_coms } = useSelector((state) => state.ics_coms);
  const { ics_incident_coms } = useSelector((state) => state.ics_incident_coms);

  useEffect(() => {
    if (!!reduxCurrentlySelectedGroup) {
      reduxDispatch(fetchIcsObjectives(reduxCurrentlySelectedGroup.group_guid));
      reduxDispatch(fetchIcsStrategies(reduxCurrentlySelectedGroup.group_guid));
      reduxDispatch(fetchIcsTactics(reduxCurrentlySelectedGroup.group_guid));
      reduxDispatch(fetchIcsActions(reduxCurrentlySelectedGroup.group_guid));
      reduxDispatch(fetchIcsMedicalAidStations(reduxCurrentlySelectedGroup.group_guid));
      reduxDispatch(fetchIcsMedicalTransportation(reduxCurrentlySelectedGroup.group_guid));
      reduxDispatch(fetchIcsHospitals(reduxCurrentlySelectedGroup.group_guid));
      reduxDispatch(fetchIcsWorkAssignments(reduxCurrentlySelectedGroup.group_guid));
      reduxDispatch(fetchIcsComs(reduxCurrentlySelectedGroup.group_guid));
      reduxDispatch(fetchIcsResourceRequests(reduxCurrentlySelectedGroup.group_guid));
      reduxDispatch(fetchIcsResourceRequestItems(reduxCurrentlySelectedGroup.group_guid));
      reduxDispatch(fetchResourceSites(reduxCurrentlySelectedGroup.group_guid))
      reduxDispatch(fetchResourceSets(reduxCurrentlySelectedGroup.group_guid))
    }
  }, [reduxCurrentlySelectedGroup, reduxDispatch]);

  useEffect(() => {
    if (!!reduxCurrentIncident) {
      reduxDispatch(getRosterForIncident());
      reduxDispatch(fetchIcsIncidentInfo(reduxCurrentIncident.id));
      reduxDispatch(fetchIcsIncidentObjectives(reduxCurrentIncident.id));
      reduxDispatch(fetchIcsIncidentStrategies(reduxCurrentIncident.id));
      reduxDispatch(fetchIcsIncidentTactics(reduxCurrentIncident.id));
      reduxDispatch(fetchIcsIncidentActions(reduxCurrentIncident.id));
      reduxDispatch(fetchIcsIncidentResources(reduxCurrentIncident.id));
      reduxDispatch(fetchIcsIncidentMedicalAidStations(reduxCurrentIncident.id));
      reduxDispatch(fetchIcsIncidentMedicalTransportation(reduxCurrentIncident.id));
      reduxDispatch(fetchIcsIncidentHospitals(reduxCurrentIncident.id));
      reduxDispatch(fetchIcsIncidentWorkAssignments(reduxCurrentIncident.id));
      reduxDispatch(fetchIcsIncidentComs(reduxCurrentIncident.id));
      reduxDispatch(fetchStrikeTeams(reduxCurrentIncident.id))
    }
  }, [reduxCurrentIncident, reduxDispatch]);

  return (
    <>
      <div className="">
        <div className="section">
          <h5>ICS</h5>
          <div className="ICS-button-wrap">
            <StylishNewButton onClick={() => setIsManageObjectivesDialogOpen(true)}>Objectives</StylishNewButton>
          </div>
          <div className="ICS-button-wrap">
            <StylishNewButton onClick={() => setIsManageStrategiesDialogOpen(true)}>Strategies</StylishNewButton>
          </div>
          <div className="ICS-button-wrap">
            <StylishNewButton onClick={() => setIsManageTacticsDialogOpen(true)}>Tactics</StylishNewButton>
          </div>
          <div className="ICS-button-wrap">
            <StylishNewButton onClick={() => setIsManageActionsDialogOpen(true)}>Actions</StylishNewButton>
          </div>
          
          {/*
          <div className="ICS-button-wrap">
            <StylishNewButton onClick={() => setIsManageResourceRequestsDialogOpen(true)}>Resource Requests</StylishNewButton>
          </div>
          */}
          <div className="ICS-button-wrap">
            <StylishNewButton onClick={() => setIsManageMedicalAidStationsDialogOpen(true)}>Medical Aid Stations</StylishNewButton>
          </div>
          <div className="ICS-button-wrap">
            <StylishNewButton onClick={() => setIsManageMedicalTransportationDialogOpen(true)}>
              Medical Transportation
            </StylishNewButton>
          </div>
          <div className="ICS-button-wrap">
            <StylishNewButton onClick={() => setIsManageHospitalsDialogOpen(true)}>Hospitals</StylishNewButton>
          </div>
          <div className="ICS-button-wrap">
            <StylishNewButton onClick={() => setIsManageComsDialogOpen(true)}>Coms</StylishNewButton>
          </div>
          <div className="ICS-button-wrap">
            <StylishNewButton onClick={() => setIsManageResourceSitesDialogOpen(true)}>Resource Sites</StylishNewButton>
          </div>
          <div className="ICS-button-wrap">
            <StylishNewButton onClick={() => setIsManageResourceSetsDialogOpen(true)}>Resource Sets</StylishNewButton>
          </div>
          <div className="ICS-button-wrap">
            <StylishNewButton onClick={() => setIsManageStrikeTeamsDialogOpen(true)}>Strike Teams</StylishNewButton>
          </div>
          {!!reduxCurrentIncident && (
            <>
              <div className="ICS-button-wrap">
                <StylishNewButton onClick={() => setIsManageIncidentResourceRequirementsDialogOpen(true)}>Incident Resource Requirements</StylishNewButton>
              </div>
              <div className="ICS-button-wrap">
                <StylishNewButton onClick={() => setIsManageWorkAssignmentsDialogOpen(true)}>Work Assignments</StylishNewButton>
              </div>
              <ICSIncidentInfo 
                reduxCurrentIncident={reduxCurrentIncident}
                ics_incident_info={ics_incident_info}
              />
            </>
          )}
          <div className="ICS-button-wrap">
            <StylishNewButton onClick={() => reduxDispatch(exportAsForm({ formname: 'ICS_201-CG' }))}>
              Export ICS 201-CG
            </StylishNewButton>
          </div>
          <div className="ICS-button-wrap">
            <StylishNewButton onClick={() => reduxDispatch(exportAsForm({ formname: 'ICS_202_CG' }))}>
              Export ICS 202-CG
            </StylishNewButton>
          </div>
          <div className="ICS-button-wrap">
            <StylishNewButton onClick={() => reduxDispatch(exportAsForm({ formname: 'ICS_203_CG' }))}>
              Export ICS 203-CG
            </StylishNewButton>
          </div>
          <div className="ICS-button-wrap">
            <StylishNewButton onClick={() => reduxDispatch(exportAsForm({ formname: 'ICS_205_CG' }))}>
              Export ICS 205-CG
            </StylishNewButton>
          </div>
          <div className="ICS-button-wrap">
            <StylishNewButton onClick={() => reduxDispatch(exportAsForm({ formname: 'ICS_205A_CG' }))}>
              Export ICS 205A-CG
            </StylishNewButton>
          </div>
          <div className="ICS-button-wrap">
            <StylishNewButton onClick={() => reduxDispatch(exportAsForm({ formname: 'ICS_206_CG' }))}>
              Export ICS 206-CG
            </StylishNewButton>
          </div>
          <div className="ICS-button-wrap">
            <StylishNewButton onClick={() => reduxDispatch(exportAsForm({ formname: 'ICS_207_CG' }))}>
              Export ICS 207-CG
            </StylishNewButton>
          </div>
        </div>
      </div>
      {isManageObjectivesDialogOpen && (
        <ICSObjectivesDialog
          show={isManageObjectivesDialogOpen}
          onClose={() => setIsManageObjectivesDialogOpen(false)}
          ics_objectives={ics_objectives}
          ics_incident_objectives={ics_incident_objectives}
          incident_id={reduxCurrentIncident?.id}
        />
      )}
      {isManageStrategiesDialogOpen && (
        <ICSStrategiesDialog
          show={isManageStrategiesDialogOpen}
          onClose={() => setIsManageStrategiesDialogOpen(false)}
          ics_strategies={ics_strategies}
          ics_incident_strategies={ics_incident_strategies}
          incident_id={reduxCurrentIncident?.id}
          ics_incident_objectives={ics_incident_objectives}
        />
      )}
      {isManageTacticsDialogOpen && (
        <ICSTacticsDialog
          show={isManageTacticsDialogOpen}
          onClose={() => setIsManageTacticsDialogOpen(false)}
          ics_tactics={ics_tactics}
          ics_incident_tactics={ics_incident_tactics}
          incident_id={reduxCurrentIncident?.id}
          ics_incident_strategies={ics_incident_strategies}
          ics_incident_resources={ics_incident_resources}
        />
      )}
      {isManageActionsDialogOpen && (
        <ICSActionsDialog
          show={isManageActionsDialogOpen}
          onClose={() => setIsManageActionsDialogOpen(false)}
          ics_actions={ics_actions}
          ics_incident_actions={ics_incident_actions}
          incident_id={reduxCurrentIncident?.id}
          incident_roster={reduxRosterForIncident}
        />
      )}
      {isManageIncidentResourceRequirementsDialogOpen && (
        <ICSResourcesDialog
          show={isManageIncidentResourceRequirementsDialogOpen}
          onClose={() => setIsManageIncidentResourceRequirementsDialogOpen(false)}
          ics_incident_resources={ics_incident_resources}
          incident_id={reduxCurrentIncident?.id}
          ics_resource_sets={ics_resource_sets}
          ics_incident_tactics={ics_incident_tactics}
        />
      )}
      {isManageResourceRequestsDialogOpen && (
        <ICSResourceRequestsDialog
          show={isManageResourceRequestsDialogOpen}
          onClose={() => setIsManageResourceRequestsDialogOpen(false)}
          ics_resource_requests={ics_resource_requests}
          ics_resource_request_items={ics_resource_request_items}
          ics_incident_resource_requests={ics_incident_resource_requests}
          incident_id={reduxCurrentIncident?.id}
          ics_resource_sites={ics_resource_sites}
        />
      )}
      {isManageMedicalAidStationsDialogOpen && (
        <ICSMedicalAidStationsDialog
          show={isManageMedicalAidStationsDialogOpen}
          onClose={() => setIsManageMedicalAidStationsDialogOpen(false)}
          ics_medical_aid_stations={ics_medical_aid_stations}
          ics_incident_medical_aid_stations={ics_incident_medical_aid_stations}
          incident_id={reduxCurrentIncident?.id}
        />
      )}
      {isManageMedicalTransportationDialogOpen && (
        <ICSMedicalTransportationDialog
          show={isManageMedicalTransportationDialogOpen}
          onClose={() => setIsManageMedicalTransportationDialogOpen(false)}
          ics_medical_transportation={ics_medical_transportation}
          ics_incident_medical_transportation={ics_incident_medical_transportation}
          incident_id={reduxCurrentIncident?.id}
        />
      )}
      {isManageHospitalsDialogOpen && (
        <ICSHospitalsDialog
          show={isManageHospitalsDialogOpen}
          onClose={() => setIsManageHospitalsDialogOpen(false)}
          ics_hospitals={ics_hospitals}
          ics_incident_hospitals={ics_incident_hospitals}
          incident_id={reduxCurrentIncident?.id}
        />
      )}
      {isManageWorkAssignmentsDialogOpen && (
        <ICSWorkAssignmentsDialog
          show={isManageWorkAssignmentsDialogOpen}
          onClose={() => setIsManageWorkAssignmentsDialogOpen(false)}
          ics_work_assignments={ics_work_assignments}
          ics_incident_work_assignments={ics_incident_work_assignments}
          incident_id={reduxCurrentIncident?.id}
        />
      )}
      {isManageComsDialogOpen && (
        <ICSComsDialog
          show={isManageComsDialogOpen}
          onClose={() => setIsManageComsDialogOpen(false)}
          ics_coms={ics_coms}
          ics_incident_coms={ics_incident_coms}
          incident_id={reduxCurrentIncident?.id}
        />
      )}
      {isManageResourceSitesDialogOpen && (
        <ICSResourceSitesDialog
          show={isManageResourceSitesDialogOpen}
          onClose={() => setIsManageResourceSitesDialogOpen(false)}
          ics_resource_sites={ics_resource_sites}
        />
      )}
      {isManageResourceSetsDialogOpen && (
        <ICSResourceSetsDialog
          show={isManageResourceSetsDialogOpen}
          onClose={() => setIsManageResourceSetsDialogOpen(false)}
          ics_resource_sets={ics_resource_sets}
          ics_resource_sites={ics_resource_sites}
        />
      )}
      {isManageStrikeTeamsDialogOpen && (
        <ICSStrikeTeamsDialog
          show={isManageStrikeTeamsDialogOpen}
          onClose={() => setIsManageStrikeTeamsDialogOpen(false)}
          ics_strike_teams={ics_strike_teams}
          incident_id={reduxCurrentIncident?.id}
        />
      )}
    </>
  );
}
