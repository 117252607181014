import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { PreparePath } from 'utils/sharedUtils/sharedConst';
import { Controller, useForm } from 'react-hook-form';
import { inputStringLength, textareaStringLength } from 'assets/data/config';
import StylishNewInput from 'components/DesignSystems/New/StylishNewInput';
import StylishDateTimePicker from 'components/DesignSystems/form/StylishDateTimePicker';
import { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { endLoading, startLoading } from 'reducers/loading/loading.action';
import Network from 'PREPAREsrc/service/Network';
import API from 'PREPAREsrc/service/api';
import StylishNewSelect from 'components/DesignSystems/New/StylishNewSelect';
import StylishNewTextArea from 'components/DesignSystems/New/StylishNewTextArea';
import TinyMCEWithFormController from 'components/DesignSystems/New/TinyMCEWithFormController';
import AddEditMissionTypeDialog from 'PREPAREsrc/pages/Settings/MissionTypes/AddEditMissionTypeDialog';
import { SharedIcon } from 'components/SharedIcon/SharedIcon';

export const CreateMission = () => {
  const {
    control,
    formState: { errors },
    setValue,
    getValues,
    watch,
    reset,
    trigger,
  } = useForm({ mode: 'onChange' });

  const tinyEditorRef = useRef(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { selectedWorkspace, type: workspaceType } = useSelector(
    (state) => state.prepare.workspace
  );
  const { id: editId } = useParams();

  const [primaryActionOfficers, setPrimaryActionOfficers] = useState();
  const [missionTypes, setMissionTypes] = useState();
  const [toBeEditedMission, setToBeEditedMission] = useState();
  const [
    showMissionTypeAddEditDialog,
    setShowMissionTypeAddEditDialog,
  ] = useState(false);

  useEffect(() => {
    if (workspaceType && workspaceType !== 'T&E')
      navigate(`${PreparePath}/dashboard/plan`);
  }, [workspaceType]);

  useEffect(() => {
    fetchUserList();
    fetchMissionTypes();
  }, [selectedWorkspace]);

  useEffect(() => {
    if (!!editId) fetchMissionById();
  }, [selectedWorkspace, editId]);

  const fetchUserList = async () => {
    try {
      dispatch(startLoading());
      const response = await Network.get(
        `${API.fetchContacts}?workspaceId=${selectedWorkspace}`
      );
      if (response?.status === 200 && response !== undefined) {
        const data =
          response?.data?.response?.dataset?.map((i) => ({
            value: i?.id,
            label: i?.name || i?.email,
          })) || [];
        setPrimaryActionOfficers(data);
        dispatch(endLoading());
      }
    } catch (error) {
      console.error(error);
      dispatch(endLoading());
    }
  };

  const fetchMissionTypes = async () => {
    try {
      dispatch(startLoading());
      const response = await Network.get(
        `${API.getAllMissionType}?workspaceId=${selectedWorkspace}`
      );
      if (response?.status === 200 && response !== undefined) {
        const data =
          response?.data?.response?.dataset?.map((i) => ({
            value: i?.id,
            label: i?.name,
          })) || [];
        setMissionTypes([
          { label: 'Add New', value: 'createMissionType' },
          ...data,
        ]);
        dispatch(endLoading());
      }
    } catch (error) {
      console.error(error);
      dispatch(endLoading());
    }
  };

  const fetchMissionById = async () => {
    try {
      dispatch(startLoading());
      const response = await Network.get(
        `${API.fetchMissionById}?id=${editId}&workspaceId=${selectedWorkspace}`
      );
      if (response?.status === 200 && response !== undefined) {
        setToBeEditedMission(response?.data?.response?.dataset[0]);
        dispatch(endLoading());
      }
    } catch (error) {
      console.error(error);
      dispatch(endLoading());
    }
  };

  const OnAddMissionType = (afterSave) => {
    setShowMissionTypeAddEditDialog(false);
    if (afterSave?.id) {
      fetchMissionTypes();
      setValue(
        'type',
        { label: afterSave?.name, value: afterSave?.id },
        { shouldValidate: true }
      );
    }
  };

  useEffect(() => {
    if (!!editId && !!toBeEditedMission) {
      setValue('name', toBeEditedMission?.name);
      setValue('start_date', toBeEditedMission?.start_date);
      setValue('end_date', toBeEditedMission?.end_date);
      setValue('objective', toBeEditedMission?.objective);
      setValue('admin_remarks', toBeEditedMission?.admin_remarks);
      setValue(
        'primary_action_officer',
        primaryActionOfficers?.find(
          (i) => i?.value === toBeEditedMission?.primary_action_officer_id
        )
      );
      setValue(
        'type',
        missionTypes?.find(
          (i) => i?.value === toBeEditedMission?.mission_type_id
        )
      );
    }
  }, [editId, toBeEditedMission]);

  const onSubmit = async (data) => {
    const refinedData = {
      ...data,
      workspaceId: selectedWorkspace,
      type: data?.type?.value,
      primary_action_officer: data?.primary_action_officer?.value,
    };

    if (!!editId && !!toBeEditedMission) {
      try {
        dispatch(startLoading());
        const response = await Network.post(API.updateMission, {
          ...refinedData,
          id: editId,
        });
        if (response?.status === 200 && response !== undefined) {
          navigate(
            `${PreparePath}/dashboard/plan/view-mission/${response.data.response.dataset[0].id}`
          );
          dispatch(endLoading());
        }
      } catch (error) {
        console.error(error);
        dispatch(endLoading());
      }
    } else {
      try {
        dispatch(startLoading());
        const response = await Network.post(API.createMission, refinedData);
        if (response?.status === 200 && response !== undefined) {
          navigate(
            `${PreparePath}/dashboard/plan/view-mission/${response.data.response.dataset[0].id}`
          );
          dispatch(endLoading());
        }
      } catch (error) {
        console.error(error);
        dispatch(endLoading());
      }
    }
  };
  const onSubmitTrigger = async () => {
    const valid = await trigger();
    if (valid) {
      onSubmit(getValues());
    }
  };

  return (
    <>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <StylishNewButton onClick={() => navigate(-1)}>
              <SharedIcon iconName="keyboard_arrow_left" classes="me-2" />
              Back
            </StylishNewButton>
          </li>
          <li className="breadcrumb-item">
            <Link to={`${PreparePath}/dashboard/plan/missions`}>Missions</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            {(editId && 'Edit') || 'Create'} Mission
          </li>
        </ol>
      </nav>
      <h4>{(editId && 'Edit') || 'Create'} Mission</h4>
      <form>
        <div className="form-block">
          <div className="row">
            <div className="col-md-4 mb-3">
              <label className="form-label">
                Mission Name
                <span aria-label="Required field" className="required">
                  *
                </span>
              </label>
              <Controller
                name="name"
                control={control}
                rules={{
                  required: true,
                  maxLength: inputStringLength,
                }}
                render={({ field: { onChange, value } }) => (
                  <StylishNewInput
                    type="text"
                    value={value || ''}
                    onChange={onChange}
                  />
                )}
              />
              {(errors?.name?.type === 'required' && (
                <span className="form-text form-error">
                  This is a required field
                </span>
              )) ||
                null}
              {(errors?.name?.type === 'maxLength' && (
                <span className="form-text form-error">
                  Only {inputStringLength} characters are allowed
                </span>
              )) ||
                null}
            </div>
            <div className="col-md-4 mb-3">
              <label className="form-label">
                Primary Action Officer
                <span aria-label="Required field" className="required">
                  *
                </span>
              </label>
              <Controller
                control={control}
                name="primary_action_officer"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <StylishNewSelect
                    value={value}
                    options={primaryActionOfficers}
                    onChange={onChange}
                    isClearable={true}
                  />
                )}
              />
              {(errors.primary_action_officer?.type === 'required' && (
                <span className="form-text form-error">
                  This is a required field
                </span>
              )) ||
                null}
            </div>
            <div className="col-md-4 mb-3">
              <label className="form-label">
                Mission Type
                <span aria-label="Required field" className="required">
                  *
                </span>
              </label>
              <Controller
                control={control}
                name="type"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <StylishNewSelect
                    value={value}
                    options={missionTypes}
                    onChange={(e) => {
                      if (e?.value === 'createMissionType') {
                        setValue('type', '');
                        setShowMissionTypeAddEditDialog(true);
                        return;
                      }
                      onChange(e);
                    }}
                    isClearable={true}
                  />
                )}
              />
              {(errors.type?.type === 'required' && (
                <span className="form-text form-error">
                  This is a required field
                </span>
              )) ||
                null}
            </div>
            <div
              className="col-md-6 mb-3 position-relative"
              style={{ zIndex: '3' }}
            >
              <Controller
                control={control}
                name="start_date"
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, value } }) => (
                  <StylishDateTimePicker
                    value={value}
                    onChange={(e) => onChange(e)}
                    type="datetime-local"
                    dayPlaceholder="DD"
                    monthPlaceholder="MM"
                    yearPlaceholder="YYYY"
                    dateLabel={'Start Date *'}
                    timeLabel={'Start Time *'}
                  />
                )}
              />
              {(errors.start_date?.type === 'required' && (
                <span className="form-text form-error">
                  This fields (Date &amp; Time) is required
                </span>
              )) ||
                null}
            </div>
            <div
              className="col-md-6 mb-3 position-relative"
              style={{ zIndex: '3' }}
            >
              <Controller
                control={control}
                name="end_date"
                rules={{
                  required: true,
                  validate: {
                    // End date should not be less than start date
                    endDateValidation: (value) =>
                      moment(value).isAfter(moment(getValues('start_date')))
                        ? true
                        : 'End Date & Time should be greater than Start Date & Time',
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <StylishDateTimePicker
                    value={value}
                    onChange={(e) => onChange(e)}
                    type="datetime-local"
                    dayPlaceholder="DD"
                    monthPlaceholder="MM"
                    yearPlaceholder="YYYY"
                    dateLabel={'End Date *'}
                    timeLabel={'End Time *'}
                    minDate={watch('start_date')}
                  />
                )}
              />
              {(errors.end_date?.type === 'required' && (
                <span className="form-text form-error">
                  This fields (Date &amp; Time) is required
                </span>
              )) ||
                null}
              {(errors.end_date?.type === 'endDateValidation' && (
                <span className="form-text form-error">
                  End (Date &amp; Time) should be greater than Start (Date &amp;
                  Time)
                </span>
              )) ||
                null}
            </div>
            <div className="col-md-12 mb-3">
              <label className="form-label">
                Mission Objective
                <span aria-label="Required field" className="required">
                  *
                </span>
              </label>
              <Controller
                name="objective"
                control={control}
                rules={{
                  required: true,
                  maxLength: textareaStringLength,
                }}
                render={({ field: { onChange, value } }) => (
                  <StylishNewTextArea value={value || ''} onChange={onChange} />
                )}
              />
              {(errors?.objective?.type === 'required' && (
                <span className="form-text form-error">
                  This is a required field
                </span>
              )) ||
                null}
              {(errors?.objective?.type === 'maxLength' && (
                <span className="form-text form-error">
                  Only {textareaStringLength} characters are allowed
                </span>
              )) ||
                null}
            </div>
            <div className="col-md-12">
              <label className="form-label">Admin Remarks</label>
              <TinyMCEWithFormController
                name="admin_remarks"
                control={control}
                readOnly={false}
                rules={{
                  required: false,
                  validate: () =>
                    tinyEditorRef?.current?.plugins?.wordcount?.body?.getCharacterCount() <=
                    textareaStringLength,
                }}
                defaultValue={
                  (!!editId &&
                    !!toBeEditedMission &&
                    toBeEditedMission?.admin_remarks) ||
                  ''
                }
                tinyEditorRef={tinyEditorRef}
              />
              {(errors?.admin_remarks?.type === 'validate' && (
                <span className="form-text form-error">
                  Only {textareaStringLength} characters are allowed
                </span>
              )) ||
                null}
            </div>
          </div>
        </div>
        <div className="d-md-flex align-items-center mt-4">
          <div className="button-group d-block d-md-flex ms-auto">
            <StylishNewButton
              type="button"
              className={
                'button--tertiary button--reverse w-100 w-md-auto ms-0 ms-md-auto '
              }
              onClick={() => {
                reset();
                navigate(-1);
              }}
            >
              Cancel
            </StylishNewButton>
            <StylishNewButton
              className={
                'button--primary w-100 w-md-auto ms-0 ms-md-3 mt-2 mt-md-0'
              }
              type="button"
              onClick={onSubmitTrigger}
            >
              Save
            </StylishNewButton>
          </div>
        </div>
      </form>
      {showMissionTypeAddEditDialog && (
        <AddEditMissionTypeDialog
          show={showMissionTypeAddEditDialog}
          onClose={OnAddMissionType}
          dialogType={'Add'}
        />
      )}
    </>
  );
};
