import React, { useEffect, useRef } from 'react';
import { Modal } from 'react-bootstrap';
import API from '../../../../../service/api';
import Network from '../../../../../service/Network';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { updateplannerinParticipant } from '../../../../../store/Participants/participant.action';
import { useState } from 'react';
import 'react-phone-number-input/style.css';
import { inputStringLength, toastConfig } from 'assets/data/config';
import { PositionModal } from '../AddNewModals/PositionModal';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import StylishNewInput from 'components/DesignSystems/New/StylishNewInput';
import StylishNewSelect from 'components/DesignSystems/New/StylishNewSelect';
import { StylishNewCheckbox } from 'components/DesignSystems/New/StylishNewCheckbox';
import { StylishInputPhone } from 'components/DesignSystems/New/StylishInputPhone';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { endLoading, startLoading } from "../../../../../../reducers/loading/loading.action";

export default function CreatePlanner({
  onClose,
  show,
  dialogType,
  updateDialogData,
  participantId,
}) {
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    clearErrors,
  } = useForm();

  const createPlannerSubmitButton = useRef();
  const createPlanner = useRef();

  const [showError, setShowError] = useState(false);
  const [phnError, setPhnError] = useState('');
  const [allPosition, setAllPosition] = useState([]);
  const [showAddPositionModal, setShowAddPositionModal] = useState(false);

  const { selectedWorkspace } = useSelector((state) => state.prepare.workspace);

  const dispatch = useDispatch();

  const customFilter = (option, searchText) => {
    if (option?.value === 'new') return true;
    if (option?.data?.label?.toLowerCase().includes(searchText.toLowerCase())) {
      return true;
    } else {
      return false;
    }
  };

  const onSubmit = async (data) => {
    if (showError) {
      return;
    }
    dispatch(startLoading());
    try {
      const newPlanner = await Network.post(
        dialogType === 'Add' ? API.addPlanner : API.updateplanner,
        {
          workspaceId: selectedWorkspace,
          name: data.name,
          email: data?.email?.toLowerCase(),
          phnNumber: data?.phnNumber || '',
          isPrimary: data.isPrimary,
          participantId: participantId,
          id: updateDialogData?.id,
          invite: data.invite,
          position: data.position.value,
        }
      );
      dispatch(
        updateplannerinParticipant(
          selectedWorkspace,
          data.name,
          data.email,
          data.phnNumber,
          data.isPrimary,
          participantId,
          updateDialogData?.id,
          data.invite
        )
      );
      onClose(newPlanner.data.response.dataset[0]);
    } catch (error) {
      toast.error(error.response.data.response.status.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };

  useEffect(() => {
    if (dialogType === 'Edit') {
      setValue('phnNumber', updateDialogData?.phnNumber);
    }
  }, []);

  const positionList = async () => {
    dispatch(startLoading());
    try {
      const position = await Network.get(API.positionList, {
        workspaceId: selectedWorkspace,
      });
      setAllPosition([
        { value: 'new', label: 'Add New' },
        ...position.data.response.dataset.map((e) => {
          return {
            value: e.id,
            label: e.title,
          };
        }),
      ]);
      if (dialogType === 'Edit') {
        let selectPosition = position.data.response.dataset.filter(
          (po) => po.title === updateDialogData.position
        );
        if (selectPosition[0] === undefined) {
          setValue('position', '');
        } else {
          setValue('position', {
            value: selectPosition[0]?.id,
            label: selectPosition[0]?.title,
          });
        }
      }
    } catch (error) {
      toast.error(error?.response?.data?.response?.status?.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };

  useEffect(() => {
    positionList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCloseAfterSavePosition = (afterSave) => {
    setShowAddPositionModal(false);
    if (afterSave?.id) {
      setAllPosition([
        ...allPosition,
        {
          label: afterSave?.title,
          value: afterSave?.id,
        },
      ]);

      setValue('position', {
        label: afterSave?.title,
        value: afterSave?.id,
      });
      clearErrors('position');
    } else {
      setValue('position', '');
    }
  };

  useEffect(() => {
    if (dialogType === 'Edit') {
      setValue(
        'isPrimary',
        updateDialogData?.isPrimary === 'No' ? false : true
      );
      setValue('invite', updateDialogData?.invite === undefined ? false : true);
      setValue('email', updateDialogData?.email || '');
      setValue('name', updateDialogData?.name || '');
    }
    const listener = (event) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        event.preventDefault();
        createPlannerSubmitButton.current?.click();
      }
    };
    if (showAddPositionModal) {
      document.removeEventListener('keydown', listener);
    } else {
      document.addEventListener('keydown', listener);
    }

    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, [showAddPositionModal]);

  return (
    <div>
      <Modal
        show={show}
        onHide={onClose}
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton closeVariant="white">
          <Modal.Title>{dialogType} Planner</Modal.Title>
        </Modal.Header>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body ref={createPlanner}>
            <div className="mb-3">
              <label className="form-label">Name</label>
              <Controller
                control={control}
                name={'name'}
                rules={{
                  required: true,
                  pattern: /^[A-Za-z]*[A-Za-z][A-Za-z0-9-. _]*$/,
                }}
                render={({ field: { onChange, value } }) => (
                  <StylishNewInput
                    onChange={(e) => {
                      onChange(e);
                    }}
                    value={value}
                    type="text"
                    name="name"
                    // defaultValue={
                    //   dialogType === 'Edit' ? updateDialogData?.name : ''
                    // }
                    maxLength={inputStringLength}
                    className="form-control"
                    placeholder="Name"
                  />
                )}
              />
              {errors?.name?.type === 'required' && (
                <span className="form-text form-error">
                  This field is required
                </span>
              )}
              {errors?.name?.type === 'pattern' && (
                <span className="form-text form-error">
                  Name cannot start with a number
                </span>
              )}
            </div>
            <div className="mb-3">
              <label className="form-label">Email</label>
              <Controller
                control={control}
                name={'email'}
                rules={{
                  required: true,
                  pattern: {
                    value: /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-z]+)$/,
                    message: 'Invalid email address',
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <StylishNewInput
                    onChange={(e) => {
                      onChange(e);
                    }}
                    value={value}
                    type="email"
                    name="email"
                    // defaultValue={
                    //   dialogType === 'Edit' ? updateDialogData?.email : ''
                    // }
                    maxLength={inputStringLength}
                    className="form-control"
                    placeholder="sample@sample.com"
                  />
                )}
              />
              {errors?.email?.type === 'required' && (
                <span className="form-text form-error">
                  This field is required
                </span>
              )}
              {errors?.email?.type === 'pattern' && (
                <span className="form-text form-error">
                  Please Enter a Valid Email
                </span>
              )}
            </div>
            <div className="mb-3">
              <label className="form-label">Phone number</label>
              <Controller
                control={control}
                name="phnNumber"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <StylishInputPhone
                    defaultCountry="US"
                    value={
                      dialogType === 'Edit'
                        ? updateDialogData?.phnNumber
                        : value
                    }
                    onChange={onChange}
                    isValid={(isValid) => {
                      if (isValid === true) {
                        setPhnError('');
                        setShowError(false);
                      } else {
                        setPhnError('Please input a valid phone number');
                        setShowError(true);
                      }
                    }}
                  />
                )}
              />
              {errors?.phnNumber?.type === 'required' && (
                <span className="form-text form-error">
                  This is a required field
                </span>
              )}
              {!!showError && (
                <span className="form-text form-error">{phnError}</span>
              )}
            </div>
            <div className="mb-3">
              <label className="form-label">Position</label>
              <Controller
                control={control}
                name="position"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <StylishNewSelect
                    options={allPosition}
                    filterOption={customFilter}
                    defaultValue={value}
                    onChange={(e) => {
                      if (e?.value === 'new') {
                        setShowAddPositionModal(true);
                        clearErrors('position');
                        return onChange();
                      } else if (!e) {
                        setValue('position', '');
                      }
                      return onChange(e);
                    }}
                    value={value}
                    placeholder={'Select position'}
                    isClearable={true}
                    isSearchable={true}
                    isMulti={false}
                    isDisabled={false}
                  />
                )}
              />
              {errors.position && (
                <span className="form-text form-error">
                  This is a required field
                </span>
              )}
            </div>
            <div className="row mt-4">
              <div className="col-6">
                <div className="form-checkbox">
                  <Controller
                    control={control}
                    name="isPrimary"
                    rules={{ required: false }}
                    render={({ field: { onChange, value } }) => (
                      <StylishNewCheckbox
                        onClick={onChange}
                        // defaultChecked={
                        //   dialogType == 'Edit'
                        //     ? updateDialogData?.isPrimary
                        //     : false
                        // }
                        checked={value}
                        label={'Primary Contact'}
                      />
                    )}
                  />
                </div>
              </div>

              <div className="col-6">
                <div className="form-checkbox">
                  <Controller
                    control={control}
                    name="invite"
                    rules={{ required: false }}
                    render={({ field: { onChange, value } }) => (
                      <StylishNewCheckbox
                        onClick={onChange}
                        // defaultValue={
                        //   dialogType === 'Edit'
                        //     ? updateDialogData?.invite
                        //     : false
                        // }
                        checked={value}
                        label={'Invite User'}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="button-group">
              <StylishNewButton
                className="button button--secondary button--reverse"
                onClick={onClose}
              >
                Close
              </StylishNewButton>
              <StylishNewButton
                ref={createPlannerSubmitButton}
                className="button button--primary"
                type="submit"
                // onClick={(event) => {
                //   if (phoneNumber == undefined || phoneNumber?.length == 0) {
                //     setShowError(true);
                //     setPhnError("This feild is required");
                //     // return;
                //     event.preventDefault();
                //   } else if (!isValidPhoneNumber(phoneNumber)) {
                //     setShowError(true);
                //     setPhnError("Please input a valid phone number");
                //     event.preventDefault();
                //     // return;
                //   } else if (isValidPhoneNumber(phoneNumber)) {
                //     setShowError(false);
                //     setPhnError("");
                //   }
                // }}
              >
                Save
              </StylishNewButton>
            </div>
          </Modal.Footer>
        </form>
      </Modal>
      {showAddPositionModal && (
        <PositionModal
          show={showAddPositionModal}
          onClose={(afterSave) => onCloseAfterSavePosition(afterSave)}
        />
      )}
    </div>
  );
}
