import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import { createIncident } from 'actions/diceActions';
import { IncidentTypes } from './IncidentTypes';
import { SeverityLevels } from './SeverityLevels';
import { useNavigate } from 'react-router-dom';
import { anomalyToIncident } from 'actions/ai2fpActions';
import StylishNewInput from 'components/DesignSystems/New/StylishNewInput';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import StylishNewSelect from 'components/DesignSystems/New/StylishNewSelect';
import { startLoading } from 'reducers/loading/loading.action';

export default function NewIncidentDialog({
  show,
  onClose,
  initName = '',
  initType = '',
  initSeverity = '',
  withAnomaly,
  withDashboard,
}) {
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors, isValid, isSubmitted },
  } = useForm({ mode: 'onChange' });

  const reduxDispatch = useDispatch();

  const navigate = useNavigate();

  const [isLoaded, setIsLoaded] = useState(true);

  const isCreateIncidentLoaded = useSelector((state) => {
    return state.app.isCreateIncidentLoaded;
  });

  const areIncidentsLoaded = useSelector((state) => {
    return state.app.areIncidentsLoaded;
  });

  const reduxGroups = useSelector((state) => {
    return state.app.groups;
  });

  const reduxCurrentlySelectedGroup = useSelector((state) => {
    return state.app.currentlySelectedGroup;
  });

  const isAnomalyToIncidentLoaded = useSelector((state) => {
    return state.app.isAnomalyToIncidentLoaded;
  });

  const anomalyForIncident = useSelector((state) => {
    return state.app.anomalyForIncident;
  });

  useEffect(() => {
    if (!!reduxCurrentlySelectedGroup) {
      setValue('sponsor_organization', {
        value: reduxCurrentlySelectedGroup.group_guid,
        label: reduxCurrentlySelectedGroup.group_name,
      });
    }
  }, [reduxCurrentlySelectedGroup]);

  useEffect(() => {
    if (!isLoaded && isCreateIncidentLoaded && areIncidentsLoaded) {
      if (
        !!withAnomaly &&
        withDashboard &&
        isAnomalyToIncidentLoaded &&
        anomalyForIncident
      ) {
        if (!!withAnomaly && !!withDashboard) {
          navigate('/situation_intelligence');
        }
        onClose();
      } else if (!withAnomaly && !withDashboard) {
        onClose();
      }
    }
  }, [isCreateIncidentLoaded, areIncidentsLoaded, isLoaded, onClose]);

  const onNewIncidentSubmit = (data) => {
    // If Incident comes from an Anomaly, then we use a slightly different process to manage the transition.
    // This is because a lot of "expectations" come in with creating the incident (lots of Redux variables changed)
    // This can be difficult to code a smooth UX using that existing function as-is, so we just adapt functionality here.
    if (!!withAnomaly) {
      const createdIncident = {
        name: data.incident_name,
        group_guid: data.sponsor_organization.value,
        severity: data.incident_severity.value,
        incidentType: null,
        // incidentType: data.incident_type.value,
        incident_types: data.incident_types.map((it) => it.value),
      };
      reduxDispatch(startLoading());
      reduxDispatch(
        anomalyToIncident(withAnomaly, createdIncident, withDashboard)
      );
      onClose();
    } else {
      reduxDispatch(startLoading());
      reduxDispatch(
        createIncident({
          name: data.incident_name,
          group_guid: data.sponsor_organization.value,
          severity: data.incident_severity.value,
          incidentType: null,
          // incidentType: data.incident_type.value,
          incident_types: data.incident_types.map((it) => it.value),
        })
      );
      onClose();
    }
  };

  return (
    <Modal
      show={show}
      onHide={onClose}
      centered
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title>New Incident</Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit(onNewIncidentSubmit)}>
        <Modal.Body>
          <div className="mb-3">
            <label className="form-label">Name</label>
            <Controller
              control={control}
              name="incident_name"
              rules={{ required: true }}
              render={({ field: { value, onChange } }) => (
                <StylishNewInput
                  type="text"
                  value={value}
                  onChange={(e) => onChange(e)}
                />
              )}
            />
            {errors.incident_name && (
              <span className="form-text form-error">
                This field is required
              </span>
            )}
          </div>
          <div className="mb-3">
            <label className="form-label">Incident Type</label>
            <Controller
              control={control}
              name="incident_types"
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <StylishNewSelect
                  options={IncidentTypes.map((type) => {
                    return { value: type, label: type };
                  })}
                  defaultValue={value}
                  value={value || ''}
                  onChange={(e) => onChange(e)}
                  placeholder={'Please Select a Type'}
                  isClearable={false}
                  isSearchable={true}
                  isMulti={true}
                  isDisabled={false}
                />
              )}
            />
            {errors.incident_types && (
              <span className="form-text form-error">
                This field is required
              </span>
            )}
          </div>
          <div className="mb-3">
            <label className="form-label">Severity</label>
            <Controller
              control={control}
              name="incident_severity"
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <StylishNewSelect
                  options={SeverityLevels.map((type) => {
                    return { value: type, label: type };
                  })}
                  defaultValue={value}
                  value={value || ''}
                  onChange={(e) => onChange(e)}
                  placeholder={'Please Select a Severity'}
                  isClearable={false}
                  isSearchable={true}
                  isMulti={false}
                  isDisabled={false}
                />
              )}
            />
            {errors.incident_severity && (
              <span className="form-text form-error">
                This field is required
              </span>
            )}
          </div>
          <div className="m-0">
            <label className="form-label">Sponsor Organization</label>
            <Controller
              control={control}
              name="sponsor_organization"
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <StylishNewSelect
                  options={reduxGroups.map((group) => {
                    return { value: group.group_guid, label: group.group_name };
                  })}
                  defaultValue={value}
                  value={value || ''}
                  onChange={(e) => onChange(e)}
                  placeholder={'Please Select a Organization'}
                  isClearable={false}
                  isSearchable={false}
                  isMulti={false}
                  isDisabled={false}
                />
              )}
            />
            {errors.sponsor_organization && (
              <span className="form-text form-error">
                This field is required
              </span>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="button-group">
            <StylishNewButton
              className="button--secondary button--reverse"
              type="button"
              onClick={onClose}
            >
              Close
            </StylishNewButton>
            <StylishNewButton type="submit" className="button--primary">
              Save
            </StylishNewButton>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
