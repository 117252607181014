import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import IconFile from 'assets/images/icon__file.svg';
import IconUpload from 'assets/images/icon__upload.svg';
import IconClose from 'assets/images/icon__times.svg';
import StylishNewTable from 'components/DesignSystems/New/StylishNewTable';
import { StylishNewTextEditor } from 'components/DesignSystems/New/StylishNewTextEditor';
import { SharedIcon } from 'components/SharedIcon/SharedIcon';

export const ReportDetails = () => {
  const navigate = useNavigate();
  const nonExpandableRows = [];
  const [texteditorContent, setTexteditorContent] = useState('');

  const msel_details_data_col = [
    {
      text: 'Participant',
      formatter: (cell, row, rowIndex) => {
        return (
          <>
            <div className="d-flex align-items-center">
              <button
                className="button--icon primary weight-700 rounded-circle p-0 me-3"
                style={{
                  pointerEvents: 'none',
                  userSelect: 'none',
                  fontSize: '12px',
                }}
              >
                {row.participant
                  .match(/(\b\S)?/g)
                  .join('')
                  .match(/(^\S|\S$)?/g)
                  .join('')
                  .toUpperCase()}
              </button>
              {row.participant}
            </div>
          </>
        );
      },
      sort: true,
      headerAttrs: { title: 'Sort By:' },
      attrs: { title: 'Participant' },
    },
    {
      dataField: 'type',
      text: 'Type',
      sort: true,
      attrs: { title: 'Type' },
    },
    {
      dataField: 'time',
      text: 'Time',
      sort: true,
      attrs: { title: 'Time' },
    },
  ];

  const msel_details_data_row = [
    {
      id: 1,
      participant: 'John Doe',
      type: 'Update Plan Inject',
      time: '2 months ago',
    },
    {
      id: 2,
      participant: 'John Doe',
      type: 'Comments',
      time: '1 months ago',
    },
    {
      id: 3,
      participant: 'John Doe',
      type: 'Update Plan Inject',
      time: '2 months ago',
    },
    {
      id: 4,
      participant: 'John Doe',
      type: 'Comments',
      time: '1 months ago',
    },
  ];

  const filterRows = msel_details_data_row.filter((item) => {
    if (item.type === 'Update Plan Inject') {
      return nonExpandableRows.push(item.id);
    }
  });

  const expandRow = {
    parentClassName: 'parent-expand-tr',
    className: 'child-expand-tr',
    renderer: (row) => {
      return (
        <>
          <label className="form-label d-block">Comment</label>
          <p className="m-0">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book.
          </p>
        </>
      );
    },
    showExpandColumn: true,
    expandByColumnOnly: true,
    nonExpandable: nonExpandableRows,
    expandHeaderColumnRenderer: ({ isAnyExpands }) => {
      if (isAnyExpands) {
        return <i className="fa fa-chevron-down" aria-hidden="true"></i>;
      }
      return <i className="fa fa-chevron-right" aria-hidden="true"></i>;
    },
    expandColumnRenderer: ({ expanded, expandable }) => {
      if (expanded) {
        return (
          <>
            <div className="d-flex align-items-center justify-content-md-center">
              <span className="me-2">Details</span>
              <i className="fa fa-chevron-down" aria-hidden="true"></i>
            </div>
          </>
        );
      }
      if (expandable) {
        return (
          <>
            <div className="d-flex align-items-center justify-content-md-center">
              <span className="me-2">Details</span>
              <i className="fa fa-chevron-right" aria-hidden="true"></i>
            </div>
          </>
        );
      }
    },
  };

  return (
    <>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <button onClick={() => navigate(-1)}>
              <SharedIcon iconName="keyboard_arrow_left" classes="me-2" />
              Back
            </button>
          </li>
          <li className="breadcrumb-item">
            <Link to={'/dashboard/report'}>Incident Report list</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Details
          </li>
        </ol>
      </nav>
      <div className="form-block mb-4">
        <div className="row">
          <div className="col-md-12 mb-4">
            <h4 className="m-0">Details</h4>
          </div>
          <div className="col-md-4 mb-3">
            <h6 className="mb-0">Incident name</h6>
            Manistique Spill
          </div>
          <div className="col-md-4 mb-3">
            <h6 className="mb-0">Incident Type</h6>
            Process Area – Major Liquid Hydrocarbon Release
          </div>
          <div className="w-100 d-none d-md-block"></div>
          <div className="col-md-4 mb-3">
            <h6 className="mb-0">Start Date</h6>
            06 Sep 22 -1100 (-12)
          </div>
          <div className="col-md-4 mb-3">
            <h6 className="mb-0">End Date</h6>
            12 Sep 22 -1100 (-12)
          </div>
          <div className="w-100 d-none d-md-block"></div>
          <div className="col-md-4 mb-3">
            <h6 className="mb-0">Location - Entity</h6>
            None
          </div>
          <div className="col-md-4 mb-3">
            <h6 className="mb-0">Location - Sub Entity</h6>
            None
          </div>
          <div className="col-md-4 mb-3">
            <h6 className="mb-0">Location - Enterprise</h6>
            None
          </div>
          <div className="col-md-4 mb-3">
            <h6 className="mb-0">Incident Location (asset)</h6>
            None
          </div>
          <div className="col-md-4 mb-3">
            <h6 className="mb-0">ISC Personnel Directly Involved</h6>
            None
          </div>
          <div className="col-md-12">
            <h6 className="mb-0">Brief Synopsis</h6>
            <p className="m-0">
              On April 14, 2017, the Duty Officer notified of an oil gas blowout
              on Well #3 in Prudhoe Bay, Alaska. The incident began at 0815
              local time. The well is venting gas and a mist of oil is blowing
              off the pad into snow covered tundra. The pad has been evacuated
              until the well can be shut in. There is no estimate of the amount
              of oil and gas being discharged. Notification only, no requests
              for additional resources at this time.
            </p>
          </div>
          <div className="col-md-12">
            <hr className="dashed" />
          </div>
          <div className="col-md-4 mb-3 mb-md-0">
            <h6 className="mb-0">Name of Person Calling</h6>
            John Doe
          </div>
          <div className="col-md-4 mb-3 mb-md-0">
            <h6 className="mb-0">Call Back Number</h6>
            None
          </div>
          <div className="col-md-4">
            <h6 className="mb-0">Type of Call</h6>
            None
          </div>
          <div className="col-md-12">
            <hr className="dashed" />
          </div>
          <div className="col-md-4 mb-3">
            <h6 className="mb-0">
              Is this a QI (Qualified Individual) Regulatory Drill?
            </h6>
            Yes
          </div>
          <div className="col-md-4 mb-3">
            <h6 className="mb-0">
              Did the Facility report to Abandon Stations?
            </h6>
            No
          </div>
          <div className="col-md-4 mb-3">
            <h6 className="mb-0">Did the Facility Muster?</h6>
            Yes
          </div>
          <div className="col-md-4 mb-3">
            <h6 className="mb-0">Teams Activated</h6>
            None
          </div>
          <div className="col-md-4 mb-3">
            <h6 className="mb-0">Resources Activated</h6>
            None
          </div>
          <div className="col-md-4 mb-3">
            <h6 className="mb-0">Site Checklist</h6>
            None
          </div>
          <div className="col-md-12">
            <h6 className="mb-2">IMT Checklist</h6>
            <ul className="list">
              <li>
                Get the key for the HMICP from Security if the room is locked
              </li>
              <li>
                Arrange for Security to control access to the HMICP throughout
                the incident and ensure that Security is aware to escort
                external visitors, Subject Matter Experts, etc. to the HMICP
                with no delays{' '}
              </li>
              <li>Establish communications with the affected site</li>
              <li>
                Log the initial information that you have on the status boards
                and confirm it with the Incident Commander once they arrive
              </li>
              <li>Find and print the relevant weather forecast</li>
            </ul>
          </div>
          <div className="col-md-12">
            <hr className="dashed" />
          </div>
          <h4>Attachments</h4>
          <div className="col-md-12 mb-2">
            <label className="form-label m-0">
              Incident After Action Report (File Upload)
            </label>
            <div className="row">
              <div className="col-md-3 col-xl-2 my-2">
                <div className="cards cards__uploaded-file">
                  <span className="remove">
                    <img src={IconClose} alt="" />
                  </span>
                  <span className="icon">
                    <img src={IconFile} alt="" />
                  </span>
                  <span className="title">Sample File.pdf</span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 mb-2">
            <label className="form-label m-0">
              Exercise After Action Report (File Upload)
            </label>
            <div className="row">
              <div className="col-md-3 col-xl-2 my-2">
                <div className="cards cards__uploaded-file">
                  <span className="remove">
                    <img src={IconClose} alt="" />
                  </span>
                  <span className="icon">
                    <img src={IconFile} alt="" />
                  </span>
                  <span className="title">Sample File.pdf</span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 mb-4">
            <label className="form-label">Images (File Upload)</label>
            <div className="row mb-3">
              <div className="col-md-3 col-xl-2 my-2">
                <div className="cards cards__uploaded-file">
                  <span className="remove">
                    <img src={IconClose} alt="" />
                  </span>
                  <span className="icon">
                    <img src={IconFile} alt="" />
                  </span>
                  <span className="title">Sample File.pdf</span>
                </div>
              </div>
              <div className="col-md-3 col-xl-2 my-2">
                <div className="cards cards__uploaded-file">
                  <span className="remove">
                    <img src={IconClose} alt="" />
                  </span>
                  <span className="icon">
                    <img src={IconFile} alt="" />
                  </span>
                  <span className="title">Sample File.pdf</span>
                </div>
              </div>
              <div className="col-md-3 col-xl-2 my-2">
                <div className="cards cards__uploaded-file">
                  <span className="remove">
                    <img src={IconClose} alt="" />
                  </span>
                  <span className="icon">
                    <img src={IconFile} alt="" />
                  </span>
                  <span className="title">Sample File.pdf</span>
                </div>
              </div>
            </div>
            <div className="form-upload">
              <div>
                <span className="d-flex justify-content-center mb-2">
                  <img src={IconUpload} alt="" />
                </span>
                <span className="d-block">
                  Drop Your Files Here or Click to Upload
                </span>
              </div>
              <input type="file" multiple />
            </div>
          </div>
          <div className="button-group">
            <button className="button button--primary w-100 w-md-auto">
              Generate ICS Forms
            </button>
          </div>
        </div>
      </div>
      <div className="form-block">
        <div className="mb-4 mb-md-5">
          <h4 className="mb-4">Activity Log</h4>
          <StylishNewTable
            keyField={'id'}
            columns={msel_details_data_col}
            rows={msel_details_data_row}
            expandRow={expandRow}
          />
        </div>
        <h4 className="mb-4">Comment</h4>
        <form action="">
          <StylishNewTextEditor
            className="mb-4"
            readOnly={false}
            placeholder="Write a Comment"
            initialValue={texteditorContent}
            onChange={setTexteditorContent}
          />
          <button
            className="button button--primary w-100 w-md-auto"
            type="submit"
            onClick={(e) => e.preventDefault()}
          >
            Comment
          </button>
        </form>
      </div>
    </>
  );
};
