import React, { useEffect, useState } from 'react';
import { Outlet, useParams, useLocation } from 'react-router-dom';

export default function Media() {
  const params = useParams();
  const { pathname } = useLocation();

  const [customUIForMedia, setCustomUIForMedia] = useState(false);

  useEffect(() => {
    !!pathname.split(`${params.slug}`)[1]
      ? setCustomUIForMedia(true)
      : setCustomUIForMedia(false);
  }, [pathname, params]);
  return (
    <>
      {!customUIForMedia ? (
        <div className="site-main__dashboard site-main__media">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <Outlet />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="site-main__social">
          <Outlet />
        </div>
      )}
    </>
  );
}
