import React from 'react';
import { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import API from '../../../../../service/api';
import Network from '../../../../../service/Network';
import { textareaStringLength, toastConfig } from 'assets/data/config';
import { inputStringLength } from 'assets/data/config';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import StylishNewInput from 'components/DesignSystems/New/StylishNewInput';
import StylishNewTextArea from 'components/DesignSystems/New/StylishNewTextArea';
import { endLoading, startLoading } from "../../../../../../reducers/loading/loading.action";

export default function AddEditCommandPriorityDialog({
  onClose,
  show,
  dialogType,
  updateDialogData,
}) {
  const {
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm();

  const { selectedWorkspace } = useSelector((state) => state.prepare.workspace);
  const exerciseId = useSelector(
    (state) => state.prepare.exercise.exerciseDetailsId
  );

  const dispatch = useDispatch();

  const onSubmit = async (data) => {
    const inputData = {
      title: data.title,
      description: data.description,
      workspaceId: selectedWorkspace,
      planEventId: exerciseId,
    };
    dispatch(startLoading());
    try {
      const newTraining = await Network.post(
        dialogType === 'Add'
          ? API.createCommandPriority
          : API.editCommandPriority,
        dialogType === 'Add'
          ? inputData
          : {
              ...inputData,
              id: updateDialogData.id,
            }
      );
      onClose(newTraining.data.response.dataset[0]);
    } catch (error) {
      toast.error(error.response.data.response.status.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };
  useEffect(() => {
    setValue('title', updateDialogData?.title || '');
    setValue('description', updateDialogData?.description || '');
    const listener = (event) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        event.preventDefault();
        handleSubmit(onSubmit)();
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, []);
  return (
    <Modal
      show={show}
      onHide={onClose}
      centered
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title>Command Training Priority</Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <div className="mb-3">
            <label className="form-label">Title</label>
            <Controller
              control={control}
              name={'title'}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <StylishNewInput
                  onChange={(e) => {
                    onChange(e);
                  }}
                  value={value}
                  type="text"
                  name="title"
                  maxLength={inputStringLength}
                  className="form-control"
                  // {...register('amount', { required: true })}
                />
              )}
            />
            {errors?.title && (
              <span className="form-text form-error">
                This is a required field
              </span>
            )}
          </div>
          <div className="m-0">
            <label className="form-label">Description</label>
            <Controller
              control={control}
              name={'description'}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <StylishNewTextArea
                  onChange={(e) => {
                    onChange(e);
                  }}
                  value={value}
                  type="text"
                  name="description"
                  maxLength={textareaStringLength}
                  className="form-control"
                  // {...register('amount', { required: true })}
                />
              )}
            />
            {errors?.description && (
              <span className="form-text form-error">
                This is a required field
              </span>
            )}
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div className="button-group">
            <StylishNewButton
              className="button button--secondary button--reverse"
              onClick={onClose}
            >
              Close
            </StylishNewButton>
            <StylishNewButton className="button button--primary" type="submit">
              Save
            </StylishNewButton>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
