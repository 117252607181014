import Network from 'PREPAREsrc/service/Network';
import API from 'PREPAREsrc/service/api';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { endLoading, startLoading } from 'reducers/loading/loading.action';
import { PostYouTube } from './PostYouTube';
import { PostInstagram } from './PostInstagram';
import { PostFacebook } from './PostFacebook';
import { PostTwitter } from './PostTwitter';
import { PostOthers } from './PostOthers';
import { PostUKRNews } from './PostUKRNews';
import { PostVK } from './PostVK';

export const SitesPostDetails = () => {
  const dispatch = useDispatch();

  const params = useParams();

  const { selectedWorkspace } = useSelector((state) => {
    return state?.prepare?.workspace;
  });

  const [postDetailsData, setPostDetailsData] = useState();

  useEffect(() => {
    if (
      sessionStorage.getItem('mediaModuleSocialMediaType') === 'sites' &&
      params.postId
    ) {
      fetchMediaSitePostItemDetails();
    }
    if (sessionStorage.getItem('mediaModuleSocialMediaType') === 'profiles') {
      if (!params?.postId) {
        fetchProfileSitePostList();
      } else {
        fetchMediaSitePostItemDetails();
      }
    }
  }, [params, selectedWorkspace]);

  const fetchMediaSitePostItemDetails = async () => {
    dispatch(startLoading());
    try {
      const data = await Network.get(API.fetchMediaSitePostDetails, {
        workspaceId: selectedWorkspace,
        postId: params.postId,
      });
      setPostDetailsData(data?.data?.response?.sitePostsData[0]);
    } catch (error) {
      toast.error(error?.response?.data?.response?.status?.msg);
    } finally {
      dispatch(endLoading());
    }
  };

  const fetchProfileSitePostList = async () => {
    dispatch(startLoading());
    try {
      const data = await Network.get(API.fetchProfileSitePostList, {
        workspaceId: selectedWorkspace,
        profileId: sessionStorage.getItem(
          'mediaModuleSocialMediaProfilesProfileId'
        ),
        siteId: sessionStorage.getItem('mediaModuleSocialMediaProfilesSiteId'),
      });
      setPostDetailsData(data?.data?.response);
    } catch (error) {
      toast.error(error?.response?.data?.response?.status?.msg);
    } finally {
      dispatch(endLoading());
    }
  };

  return (
    <>
      {params.slug === 'youtube' ? (
        <PostYouTube data={postDetailsData} />
      ) : params.slug === 'instagram' ? (
        <PostInstagram data={postDetailsData} />
      ) : params.slug === 'facebook' ? (
        <PostFacebook data={postDetailsData} />
      ) : params.slug === 'twitter' ? (
        <PostTwitter data={postDetailsData} />
      ) : params.slug === 'ukr-news' ? (
        <PostUKRNews data={postDetailsData} />
      ) : params.slug === 'vk' ? (
        <PostVK data={postDetailsData} />
      ) : (
        <PostOthers data={postDetailsData} />
      )}
    </>
  );
};
