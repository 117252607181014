import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import IconBack from 'assets/images/socials/icon__back.svg';
import Logo from 'assets/images/socials/others__logo.svg';
import { Link, useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import { AllIcon } from 'PREPAREsrc/pages/Settings/InjectMethod/Icon';
import { useSelector } from 'react-redux';
import IconEmptyExercise from 'assets/images/icon__exercise-details-empty.svg';
import { PreparePath } from 'utils/sharedUtils/sharedConst';
import { sanitizeHtml } from "../../../../utils/sanitizeHtml";

export const PostOthers = ({ data }) => {
  const navigate = useNavigate();

  const { workspaceName } = useSelector((state) => {
    return state?.prepare?.workspace;
  });

  const params = useParams();

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <>
      <div className="others">
        {sessionStorage.getItem('mediaModuleSocialMediaType') === 'sites' ? (
          <>
            <div className="others__header">
              <StylishNewButton
                customButton
                className={'button--icon'}
                onClick={handleBack}
              >
                <img src={IconBack} alt="" />
              </StylishNewButton>
              <span className="logo d-flex align-items-center">
                <span
                  className={`bg-white rounded-5 d-flex align-items-center justify-content-center overflow-hidden ${
                    !!data?.siteIcon ? 'has-img-h-24' : ''
                  }`}
                  style={{
                    color: data?.siteColor || '#3A3A3A',
                    width: '2.25rem',
                    height: '2.25rem',
                  }}
                >
                  {data?.siteIcon
                    ? /^[A-Z]*$/.test(data?.siteIcon.charAt(0))
                      ? AllIcon.find((icon) => icon.name === data?.siteIcon)
                          ?.icon
                      : AllIcon.find(
                          (icon) =>
                            icon.name ===
                            `${data?.siteIcon
                              .charAt(0)
                              .toUpperCase()}${data?.siteIcon.slice(1)}`
                        )?.icon
                    : null}
                  {!!data?.site_image ? (
                    <img
                      src={data?.site_image}
                      alt=""
                      style={{
                        display: 'block',
                        height: '100%',
                        width: '100%',
                      }}
                    />
                  ) : null}
                </span>
                <h4 className="m-0 ms-3 text-white">{data?.siteName}</h4>
              </span>
            </div>
            <div className="others__main">
              <div className="d-md-flex align-items-start">
                <div className="avatar flex-shrink-0">
                  {data?.profileAvatar ? (
                    <img src={data?.profileAvatar} alt="" />
                  ) : null}
                </div>
                <div className="ms-md-5 mt-4 mt-md-0 bg-white p-4 flex-grow-1">
                  {data?.profileName ? (
                    <h5 className="mb-1">{data?.profileName}</h5>
                  ) : null}
                  {data?.postPublishTime ? (
                    <p className="mb-4">
                      {moment(data?.postPublishTime).format('MMM Do YYYY')}
                    </p>
                  ) : null}
                  {data?.post ? (
                    <div
                      className="m-0"
                      dangerouslySetInnerHTML={{ __html: sanitizeHtml(data?.post) }}
                    ></div>
                  ) : null}
                  {data?.mediaFile ? (
                    <div className="d-flex mt-3">
                      <img
                        src={data?.mediaFile}
                        alt=""
                        style={{ width: '200px', height: 'auto' }}
                      />
                    </div>
                  ) : null}
                  {data?.comments && !!data?.comments.length ? (
                    <div className="mt-5">
                      {data?.comments.map((i, idx) =>
                        i?.status === 'drafted' ? null : (
                          <div key={i?.id}>
                            {idx !== 0 ? (
                              <hr className="dashed thin my-4" />
                            ) : null}
                            <div className="d-flex align-items-start">
                              <div className="avatar flex-shrink-0">
                                {i?.profileAvatar ? (
                                  <img src={i?.profileAvatar} alt="" />
                                ) : null}
                              </div>
                              <div className="ms-3">
                                {i?.profileName ? (
                                  <h6 className="mb-2">{i?.profileName}</h6>
                                ) : null}
                                {i?.comment ? (
                                  <div
                                    className="m-0"
                                    dangerouslySetInnerHTML={{
                                      __html: sanitizeHtml(i?.comment),
                                    }}
                                  ></div>
                                ) : // <p className="m-0">{i?.comment}</p>
                                null}
                                {i?.commentImg ? (
                                  <div className="d-flex mt-3">
                                    <img
                                      src={i?.commentImg}
                                      alt=""
                                      style={{ width: '100px', height: 'auto' }}
                                    />
                                  </div>
                                ) : null}
                                <p className="m-0 mt-3 weight-500 d-flex align-items-md-center flex-column flex-md-row txt--sml">
                                  {i?.publishTime ? (
                                    <>{moment(i?.publishTime).fromNow()}</>
                                  ) : null}
                                  <span className="mt-2 mt-md-0 ms-md-4">
                                    {i?.likes || '0'} Likes
                                  </span>
                                  <span className="mt-2 mt-md-0 ms-md-4">
                                    Reply
                                  </span>
                                </p>
                              </div>
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </>
        ) : null}
        {sessionStorage.getItem('mediaModuleSocialMediaType') === 'profiles' ? (
          <>
            {!params?.postId ? (
              <>
                {data?.profiledata?.posts &&
                !!data?.profiledata?.posts?.length ? (
                  <>
                    <div className="others__header">
                      <StylishNewButton
                        customButton
                        className={'button--icon'}
                        onClick={handleBack}
                      >
                        <img src={IconBack} alt="" />
                      </StylishNewButton>
                      <span className="logo d-flex align-items-center">
                        <span
                          className={`bg-white rounded-5 d-flex align-items-center justify-content-center overflow-hidden ${
                            !!data?.sitedata?.icon ? 'has-img-h-24' : ''
                          }`}
                          style={{
                            color: data?.sitedata?.color || '#3A3A3A',
                            width: '2.25rem',
                            height: '2.25rem',
                          }}
                        >
                          {data?.sitedata?.icon
                            ? /^[A-Z]*$/.test(data?.sitedata?.icon.charAt(0))
                              ? AllIcon.find(
                                  (icon) => icon.name === data?.sitedata?.icon
                                )?.icon
                              : AllIcon.find(
                                  (icon) =>
                                    icon.name ===
                                    `${data?.sitedata?.icon
                                      .charAt(0)
                                      .toUpperCase()}${data?.sitedata?.icon.slice(
                                      1
                                    )}`
                                )?.icon
                            : null}
                          {!!data?.sitedata?.site_image ? (
                            <img
                              src={data?.sitedata?.site_image}
                              alt=""
                              style={{
                                display: 'block',
                                height: '100%',
                                width: '100%',
                              }}
                            />
                          ) : null}
                        </span>
                        <h4 className="m-0 ms-3 text-white">
                          {data?.sitedata?.displayName}
                        </h4>
                      </span>
                    </div>
                    <div className="others__main">
                      {data?.profiledata?.posts.map((i, idx) => (
                        <div key={i.id}>
                          {idx !== 0 ? <hr className="dashed my-5" /> : null}
                          <div className="position-relative">
                            <Link
                              className="stretched-link"
                              to={`${PreparePath}/dashboard/media/profiles/${
                                workspaceName &&
                                workspaceName.charAt(0).toUpperCase()
                              }/${data?.sitedata?.slug}/${
                                data?.profiledata?.username
                              }/${i?.id}`}
                            ></Link>
                            <div className="d-md-flex align-items-start">
                              <div className="avatar flex-shrink-0">
                                {data?.profiledata?.avatar ? (
                                  <img src={data?.profiledata?.avatar} alt="" />
                                ) : null}
                              </div>
                              <div className="ms-md-5 mt-4 mt-md-0 bg-white p-4 flex-grow-1">
                                {data?.profiledata?.profileName ? (
                                  <h5 className="mb-1">
                                    {data?.profiledata?.profileName}
                                  </h5>
                                ) : null}
                                {i?.publishTime ? (
                                  <p className="mb-4">
                                    {moment(i?.publishTime).format(
                                      'MMM Do YYYY'
                                    )}
                                  </p>
                                ) : null}
                                {i?.post ? (
                                  <p
                                    className="m-0"
                                    dangerouslySetInnerHTML={{
                                      __html: sanitizeHtml(i?.post),
                                    }}
                                  >
                                    {/* {i?.post} */}
                                  </p>
                                ) : null}
                                {i?.mediaFile ? (
                                  <div className="d-flex mt-3">
                                    <img
                                      src={i?.mediaFile}
                                      alt=""
                                      style={{ width: '200px', height: 'auto' }}
                                    />
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </>
                ) : (
                  <>
                    <div className="others__header">
                      <StylishNewButton
                        customButton
                        className={'button--icon'}
                        onClick={handleBack}
                      >
                        <img src={IconBack} alt="" />
                      </StylishNewButton>
                      <span className="logo d-flex align-items-center">
                        <span
                          className={`bg-white rounded-5 d-flex align-items-center justify-content-center overflow-hidden ${
                            !!data?.sitedata?.icon ? 'has-img-h-24' : ''
                          }`}
                          style={{
                            color: data?.sitedata?.color || '#3A3A3A',
                            width: '2.25rem',
                            height: '2.25rem',
                          }}
                        >
                          {data?.sitedata?.icon
                            ? /^[A-Z]*$/.test(data?.sitedata?.icon.charAt(0))
                              ? AllIcon.find(
                                  (icon) => icon.name === data?.sitedata?.icon
                                )?.icon
                              : AllIcon.find(
                                  (icon) =>
                                    icon.name ===
                                    `${data?.sitedata?.icon
                                      .charAt(0)
                                      .toUpperCase()}${data?.sitedata?.icon.slice(
                                      1
                                    )}`
                                )?.icon
                            : null}
                          {!!data?.sitedata?.site_image ? (
                            <img
                              src={data?.sitedata?.site_image}
                              alt=""
                              style={{
                                display: 'block',
                                height: '100%',
                                width: '100%',
                              }}
                            />
                          ) : null}
                        </span>
                        <h4 className="m-0 ms-3 text-white">
                          {data?.sitedata?.displayName}
                        </h4>
                      </span>
                    </div>
                    <div className="others__main">
                      <div className="text-center p-5">
                        <div className="d-flex justify-content-center mb-4">
                          <img
                            src={IconEmptyExercise}
                            alt=""
                            style={{ opacity: '0.5' }}
                          />
                        </div>
                        <p className="weight-600 mb-4">No Post Found!</p>
                      </div>
                    </div>
                  </>
                )}
              </>
            ) : (
              <>
                <div className="others__header">
                  <StylishNewButton
                    customButton
                    className={'button--icon'}
                    onClick={handleBack}
                  >
                    <img src={IconBack} alt="" />
                  </StylishNewButton>
                  <span className="logo d-flex align-items-center">
                    <span
                      className={`bg-white rounded-5 d-flex align-items-center justify-content-center overflow-hidden ${
                        !!data?.siteIcon ? 'has-img-h-24' : ''
                      }`}
                      style={{
                        color: data?.siteColor || '#3A3A3A',
                        width: '2.25rem',
                        height: '2.25rem',
                      }}
                    >
                      {data?.siteIcon
                        ? /^[A-Z]*$/.test(data?.siteIcon.charAt(0))
                          ? AllIcon.find((icon) => icon.name === data?.siteIcon)
                              ?.icon
                          : AllIcon.find(
                              (icon) =>
                                icon.name ===
                                `${data?.siteIcon
                                  .charAt(0)
                                  .toUpperCase()}${data?.siteIcon.slice(1)}`
                            )?.icon
                        : null}
                      {!!data?.site_image ? (
                        <img
                          src={data?.site_image}
                          alt=""
                          style={{
                            display: 'block',
                            height: '100%',
                            width: '100%',
                          }}
                        />
                      ) : null}
                    </span>
                    <h4 className="m-0 ms-3 text-white">{data?.siteName}</h4>
                  </span>
                </div>
                <div className="others__main">
                  <div className="d-md-flex align-items-start">
                    <div className="avatar flex-shrink-0">
                      {data?.profileAvatar ? (
                        <img src={data?.profileAvatar} alt="" />
                      ) : null}
                    </div>
                    <div className="ms-md-5 mt-4 mt-md-0 bg-white p-4 flex-grow-1">
                      {data?.profileName ? (
                        <h5 className="mb-1">{data?.profileName}</h5>
                      ) : null}
                      {data?.postPublishTime ? (
                        <p className="mb-4">
                          {moment(data?.postPublishTime).format('MMM Do YYYY')}
                        </p>
                      ) : null}
                      {data?.post ? <p className="m-0">{data?.post}</p> : null}
                      {data?.mediaFile ? (
                        <div className="d-flex mt-3">
                          <img
                            src={data?.mediaFile}
                            alt=""
                            style={{ width: '200px', height: 'auto' }}
                          />
                        </div>
                      ) : null}
                      {data?.comments && !!data?.comments.length ? (
                        <div className="mt-5">
                          {data?.comments.map((i, idx) => (
                            <div key={i?.id}>
                              {idx !== 0 ? (
                                <hr className="dashed thin my-4" />
                              ) : null}
                              <div className="d-flex align-items-start">
                                <div className="avatar flex-shrink-0">
                                  {i?.profileAvatar ? (
                                    <img src={i?.profileAvatar} alt="" />
                                  ) : null}
                                </div>
                                <div className="ms-3">
                                  {i?.profileName ? (
                                    <h6 className="mb-2">{i?.profileName}</h6>
                                  ) : null}
                                  {i?.comment ? (
                                    <div
                                      className="m-0"
                                      dangerouslySetInnerHTML={{
                                        __html: sanitizeHtml(i?.comment),
                                      }}
                                    ></div>
                                  ) : // <p className="m-0">{i?.comment}</p>
                                  null}
                                  {i?.commentImg ? (
                                    <div className="d-flex mt-3">
                                      <img
                                        src={i?.commentImg}
                                        alt=""
                                        style={{
                                          width: '100px',
                                          height: 'auto',
                                        }}
                                      />
                                    </div>
                                  ) : null}
                                  <p className="m-0 mt-3 weight-500 d-flex align-items-md-center flex-column flex-md-row txt--sml">
                                    {i?.publishTime ? (
                                      <>{moment(i?.publishTime).fromNow()}</>
                                    ) : null}
                                    <span className="mt-2 mt-md-0 ms-md-4">
                                      {i?.likes || '0'} Likes
                                    </span>
                                    <span className="mt-2 mt-md-0 ms-md-4">
                                      Reply
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
        ) : null}
      </div>
    </>
  );
};
