// @ts-nocheck
import React from 'react';
import {
  Link
} from "react-router-dom";

import "./SimpleAppHeader.css";

import { PageDropdownMenu } from 'components/pageDropdownMenu/PageDropdownMenu';

import useWindowDimensions from '../../hooks/useWindowDimensions';

import { HeaderLogo } from 'components/headerLogo/HeaderLogo';

export const SimpleAppHeader = () => {  
  const { width } = useWindowDimensions();
  
  return (
    <div className="App-header">
      {width > 760 ? null : <div> </div>}
      <div style={{ display: 'flex', flexDirection: 'row', flex: 2 }}>
        <div>
          <Link to={`/dashboard`}>
          <HeaderLogo />
          </Link>
        </div>
      </div>
      <PageDropdownMenu />
    </div>
  );
};
