import React from 'react';

import { useSelector } from 'react-redux'
import styled from '@emotion/styled';

import './Logo.css'

export const Logo = styled(() => {

  const { logo, logo_small } = useSelector(state => {
    return state.app.user
  })

  return (
    <>
      <img
        className="logo logo--lg"
        src={`../${logo}`}
        alt="DisasterTech Logo"
      />
      <img
        className="logo logo--sm"
        src={`../${logo_small}`}
        alt="DisasterTech Logo"
      />
    </>
  )
})``;
