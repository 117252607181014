import React, { useEffect, useState } from 'react';
import Network from '../../../../service/Network';
import API from '../../../../service/api';
import { useDispatch, useSelector } from 'react-redux';
import { AddEditCustomReportModal } from '../Modal/AddEditCustomReportModal';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import moment from 'moment/moment';
import IconEmpty from 'assets/images/icon__exercise-details-empty.svg';
import StylishNewTable from 'components/DesignSystems/New/StylishNewTable';
import StylishNewTableAction from 'components/DesignSystems/New/StylishNewTableAction';
import { StylishConfirmDialog } from 'components/DesignSystems/New/StylishConfirmDialog';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { PreparePath } from 'utils/sharedUtils/sharedConst';
import { MiscellaneousHelpLink, useSelfHostedKnowledgeBase } from 'assets/data/HelpContent';
import { toastConfig } from 'assets/data/config';
import { PaginationDropdown } from '../../../../../components/DesignSystems/PaginationDropdown';
import {
  endLoading,
  startLoading,
} from '../../../../../reducers/loading/loading.action';
import PageTitle from 'components/headers/PageTitle';
import { useNewUIFlag } from '../../../../../utils/featureFlags';
import { SharedIcon } from '../../../../../components/SharedIcon/SharedIcon';

export const ReportList = () => {
  const newUI = useNewUIFlag();
  const [allReports, setAllReports] = useState([]);
  const [dialogType, setDialogType] = useState('Add');
  const [updateDialogData, setUpdateDialogData] = useState(null);
  const [showAddEditModal, setShowAddEditModal] = useState(false);
  const [reportTotal, setReportTotal] = useState(0);
  const [perPage, setPerPage] = useState(5);
  const [page, setPage] = useState(1);
  const [deleteCRId, setDeleteCRId] = useState(null);

  const selfHostedKnowledgeBase = useSelfHostedKnowledgeBase()

  const workspaceId = useSelector(
    (state) => state.prepare.workspace.selectedWorkspace
  );
  const reduxTimezone = useSelector(
    (state) => state.prepare.workspace.timezone
  );

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const pageDropDown = [
    { value: '5', label: '5 per page' },
    { value: '15', label: '15 per page' },
    { value: '30', label: '30 per page' },
    { value: '50', label: '50 per page' },
  ];

  const columns = [
    {
      dataField: 'title',
      text: 'Title',
      sort: true,
      headerAttrs: { title: 'Sort By:' },
      attrs: { title: 'Title' },
    },
    {
      dataField: 'reportDates',
      text: 'Analytics Dates',
      sort: true,
      attrs: { title: 'Analytics Dates' },
    },

    {
      dataField: '',
      text: 'Action',
      isDummyField: true,
      formatter: (cell, row, rowIndex) => (
        <StylishNewTableAction
          name="Custom Analytics"
          cell={cell}
          row={row}
          rowIndex={rowIndex}
          onDelete={onDeleteReportCustom}
          onEdit={onEditReportCustom}
          onView={onViewReportCustom}
        />
      ),
      headerStyle: {
        width: '100px',
      },
      headerAlign: 'center',
      align: 'center',
      attrs: { title: 'Action' },
    },
  ];

  const allCustomReport = async () => {
    dispatch(startLoading());
    try {
      const reports = await Network.get(API.allCustomReport, {
        workspaceId: workspaceId,
        limit: perPage,
        page: page,
      });
      setReportTotal(reports.data.response.total);

      const customReport = reports.data.response.dataset.map((element) => {
        {
          return {
            ...element,
            reportDates: (
              <span>
                {element.startDate ? (
                  <>
                    {moment(element?.startDate).format('YYYY-MM-DD HH:mm')}
                    {reduxTimezone.split('/')[0]} -{' '}
                  </>
                ) : (
                  ''
                )}
                {element?.endDate ? (
                  <>
                    {moment(element?.endDate).format('YYYY-MM-DD HH:mm')}
                    {reduxTimezone.split('/')[0]}
                  </>
                ) : (
                  ''
                )}
              </span>
            ),
          };
        }
      });
      setAllReports(customReport);
    } catch (error) {
      toast.error(error?.response?.data?.response?.status?.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };

  useEffect(() => {
    allCustomReport();
  }, [page, perPage, workspaceId]);

  const onDeleteConfirm = async () => {
    dispatch(startLoading());
    try {
      await Network.post(API.deleteCustomReport, {
        id: deleteCRId,
      });
      setDeleteCRId(null);
      allCustomReport();
      toast.success('Deleted  successfully', toastConfig);
    } catch (error) {
      toast.error(error.response.data.response.status.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };

  const onDeleteReportCustom = (row) => {
    setDeleteCRId(row.id);
  };

  const onAddReportCustom = () => {
    setDialogType('Add');
    setShowAddEditModal(true);
  };

  const onEditReportCustom = (row) => {
    setShowAddEditModal(true);
    setUpdateDialogData(row);
    setDialogType('Edit');
  };

  const onViewReportCustom = (row) => {
    navigate(`${PreparePath}/dashboard/plan/reports/${row.id}`);
  };

  const onCloseModal = (e) => {
    setUpdateDialogData(null);
    setDialogType('New');
    setShowAddEditModal(false);
    if (e?.status) {
      allCustomReport();
    }
  };

  const handlePageClick = (pages) => {
    setPage(pages);
  };

  return (
    <>
      {newUI ? (
        <PageTitle title="Custom Analytics" parents={['Exercise Management']} />
      ) : (
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <StylishNewButton onClick={() => navigate(-1)}>
                <SharedIcon iconName="keyboard_arrow_left" classes="me-2" />
                Back
              </StylishNewButton>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Custom Analytics
            </li>
          </ol>
        </nav>
      )}

      <div className="button-group d-block d-md-flex mb-4">
        <StylishNewButton
          className="button button--primary w-100 w-md-auto"
          onClick={onAddReportCustom}
        >
          Add Custom Analytics
        </StylishNewButton>
        <StylishNewButton
          className="button button--secondary w-100 w-md-auto ms-0 ms-md-3 mt-3 mt-md-0"
          onClick={() => {
            navigate(`${PreparePath}/dashboard/plan/reports/analytics`);
          }}
        >
          Show Full Analytics
        </StylishNewButton>
      </div>
      {!reportTotal ? (
        <div className="bg-gray-800 text-center p-5">
          <div className="py-0 py-md-4">
            <div className="d-flex justify-content-center mb-5">
              <img src={IconEmpty} alt="" />
            </div>
            <p className="weight-600 mb-4">
              A fresh start. Create the first entry.
              <a href={selfHostedKnowledgeBase || MiscellaneousHelpLink.link} target="_blank">
                {' '}
                Need more help?
              </a>
            </p>

            <div className="button-group justify-content-center d-block d-md-flex">
              <StylishNewButton
                className="button button--primary w-100 w-md-auto"
                onClick={onAddReportCustom}
              >
                Add Custom Analytics
              </StylishNewButton>
              <StylishNewButton
                className="button button--secondary w-100 w-md-auto ms-0 ms-md-3 mt-3 mt-md-0"
                onClick={() => {
                  navigate(`${PreparePath}/dashboard/plan/reports/analytics`);
                }}
              >
                Show Full Analytics
              </StylishNewButton>
            </div>
          </div>
        </div>
      ) : (
        <>
          {/* <div className="button-group d-block d-md-flex mb-4">
            <button
              className="button button--primary w-100 w-md-auto"
              onClick={onAddReportCustom}
            >
              Add Custom Analytics
            </button>
            <button
              className="button button--primary w-100 w-md-auto ms-0 ms-md-3 mt-3 mt-md-0"
              onClick={() => {
                navigate(`/dashboard/plan/reports/analytics`);
              }}
            >
              Show Full Analytics
            </button>
          </div> */}
          <div className="form-block">
            <div className="d-flex align-items-center mb-4">
              <p className="m-0 weight-600">{reportTotal} Analytics</p>
              <div className="form-select ms-auto">
                <PaginationDropdown
                  setPage={setPage}
                  setPerPage={setPerPage}
                  options={pageDropDown}
                />
              </div>
            </div>
            {reportTotal && (
              <div>
                <StylishNewTable
                  keyField={'id'}
                  columns={columns}
                  rows={allReports}
                  onDelete={onDeleteReportCustom}
                  onEdit={onEditReportCustom}
                  onView={onViewReportCustom}
                  page={page}
                  perPage={perPage}
                  pagination
                  total={reportTotal}
                  onPageChange={handlePageClick}
                />
              </div>
            )}
          </div>
        </>
      )}

      {showAddEditModal && (
        <AddEditCustomReportModal
          show={showAddEditModal}
          onClose={onCloseModal}
          dialogType={dialogType}
          updateDialogData={updateDialogData}
        ></AddEditCustomReportModal>
      )}
      {deleteCRId && (
        <StylishConfirmDialog
          show={deleteCRId}
          onClose={() => setDeleteCRId(null)}
          dialogTitle={'Delete Custom Analytics'}
          dialogContent={'Are you sure you want to delete this Analytics ?'}
          onConfirm={onDeleteConfirm}
        />
      )}
    </>
  );
};
