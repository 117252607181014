import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, NavLink, useNavigate, useParams } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import API from '../../../service/api';
import Network from '../../../service/Network';
import NetworkTitle from '../../../service/Network';
import { toast } from 'react-toastify';
import { StylishNewSearchBar } from 'components/DesignSystems/New/StylishNewSearchBar';
import SearchBar from '../../../component/SearchBar/SearchBar';
import ReactPaginate from 'react-paginate';
import Help from '../../../component/Help/Help';
import CreateNetworkDialog from './CreateNetworkDialog';
import IconEdit from 'assets/images/icon__edit.svg';
import IconDelete from 'assets/images/icon__delete.svg';
import ProfileImage from 'assets/images/icon__profileimage.jpg';
import IconView from 'assets/images/icon__eye.svg';
import StylishNewSelect from 'components/DesignSystems/New/StylishNewSelect';
import { PreparePath } from 'utils/sharedUtils/sharedConst';
import { StylishConfirmDialog } from 'components/DesignSystems/New/StylishConfirmDialog';
import IconEmptyExercise from 'assets/images/icon__exercise-details-empty.svg';
import { MiscellaneousHelpLink, NetworkHelp, useSelfHostedKnowledgeBase } from 'assets/data/HelpContent';
import { cardStringLength, toastConfig } from 'assets/data/config';
import StylishHelp from 'components/DesignSystems/New/StylishHelp';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import {
  endLoading,
  startLoading,
} from '../../../../reducers/loading/loading.action';
import isRoleReadOnly from 'PREPAREsrc/utils/isRoleReadOnly';
import PageTitle from 'components/headers/PageTitle';
import { useNewUIFlag } from 'utils/featureFlags';

export default function Networks() {
  const selfHostedKnowledgeBase = useSelfHostedKnowledgeBase();
  const [deleteNetwork, setDeleteNetwork] = useState(null);
  const [showCreateNetworkDialog, setShowCreateNetworkDialog] = useState(false);
  const [showAllNetworks, setShowAllNetworks] = useState([]);
  const [networksTotal, setNetworksTotal] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(1);
  const [sortOption, setSortOption] = useState({
    value: 'name_ASC',
    label: 'Name',
  });
  const perPage = 8;

  const workspaceId = useSelector(
    (state) => state.prepare.workspace.selectedWorkspace
  );

  const isMounted = useRef(true);

  const reduxUser = useSelector((state) => state.prepare.user);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const newUI = useNewUIFlag();

  const sortOptions = [
    { value: 'name_ASC', label: 'Name' },
    { value: 'createdAt_DESC', label: 'Last Created ' },
    { value: 'createdAt_ASC', label: 'Oldest Created' },
    { value: 'updatedAt_DESC', label: 'Last Updated' },
    { value: 'updatedAt_ASC', label: 'Oldest Updated' },
  ];

  const onChangeSearchTerm = (termSearched) => {
    setSearchTerm(termSearched);
    setPage(1);
  };

  const onCreateNetworkDialog = () => {
    setShowCreateNetworkDialog(true);
  };
  const onCreateNetworkDialogClose = () => {
    getAllNetworks();
    setShowCreateNetworkDialog(false);
  };
  const handlePageClick = (event) => {
    setPage(event.selected + 1);
  };
  const onDeleteNetwork = (row) => {
    setDeleteNetwork(row.id);
  };

  const getAllNetworks = async () => {
    dispatch(startLoading());
    try {
      await Network.get(API.getAllNetwork, {
        sort: sortOption.value,
        search: searchTerm,
        limit: perPage,
        page: page,
        workspaceId: workspaceId,
      })
        .then((resp) => {
          setShowAllNetworks(resp.data.response.dataset);
          setNetworksTotal(resp.data.response.total);
        })
        .catch(console.log)
        .finally(() => {
          dispatch(endLoading());
        });
    } catch (error) {
      toast.error(error, toastConfig);
      dispatch(endLoading());
    }
  };

  useEffect(() => {
    getAllNetworks();
  }, [reduxUser, searchTerm, perPage, page, sortOption, workspaceId]);

  const onDeleteConfirm = async () => {
    dispatch(startLoading());
    try {
      await Network.post(API.deleteMediaNetwork, {
        networkId: deleteNetwork,
      });
      await getAllNetworks();
      setDeleteNetwork(null);
      toast.success('Network deleted successfully', toastConfig);
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(endLoading());
    }
  };

  return (
    <>
      {newUI ? (
        <PageTitle title="Networks" parents={['Media']} />
      ) : (
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <StylishNewButton onClick={() => navigate(-1)}>
                <i className="fa fa-angle-left me-2" aria-hidden="true"></i>
                Back
              </StylishNewButton>
            </li>
            <li
              className="breadcrumb-item active cursor-pointer"
              aria-current="page"
            >
              Networks
            </li>
          </ol>
        </nav>
      )}

      <div className="d-flex flex-wrap align-items-center mb-4">
        {!isRoleReadOnly(reduxUser.roles) && (
          <StylishNewButton
            onClick={onCreateNetworkDialog}
            className="button--primary flex-shrink-0"
          >
            Create Network
          </StylishNewButton>
        )}

        <p className="ms-auto m-0">{networksTotal} Network(s)</p>

        <div className="d-flex flex-wrap align-items-center ms-lg-3 w-100 w-lg-auto mt-3 mt-lg-0">
          <StylishNewSearchBar
            className="flex-shrink-0"
            onChangeSearchTerm={onChangeSearchTerm}
          />

          <div className="d-flex align-items-center ms-md-auto ms-lg-3 w-100 w-md-auto mt-3 mt-md-0">
            <StylishNewSelect
              style={{ minWidth: '160px' }}
              options={sortOptions}
              classes={'flex-grow-1'}
              onChange={(e) => {
                setSortOption(e);
              }}
              value={sortOption}
              placeholder={'Sort By...'}
              isClearable={false}
              isSearchable={false}
              isMulti={false}
              isDisabled={false}
            />
            <StylishHelp
              classes={'flex-shrink-0 ms-3 sml'}
              title={NetworkHelp.title}
              content={NetworkHelp.content}
              link={useSelfHostedKnowledgeBase || NetworkHelp.link}
            />
          </div>
        </div>
      </div>

      {networksTotal > 0 ? (
        <>
          <div className="row">
            {showAllNetworks.map((aN) => {
              return (
                <div className="col-md-6 col-lg-4 col-xl-3 mb-4" key={aN?.id}>
                  <div className="bg-gray-800 rounded h-100 d-flex flex-column overflow-hidden">
                    <div className="p-3">
                      <div className="d-flex align-items-start mb-4">
                        <div className="profile-img">
                          {aN?.avatar ? <img src={aN?.avatar} alt="" /> : null}
                        </div>
                        <Dropdown className="flex-shrink-0 ms-auto">
                          <Dropdown.Toggle className="button--icon">
                            <i
                              className="fa fa-ellipsis-v"
                              aria-hidden="true"
                            ></i>
                          </Dropdown.Toggle>
                          <Dropdown.Menu align="end">
                            <Link
                              to={`${PreparePath}/dashboard/media/view-network/${aN?.id}`}
                              className="dropdown-item"
                            >
                              <img src={IconView} alt="" />
                              View Network
                            </Link>
                            <Link
                              className="dropdown-item"
                              to={`${PreparePath}/dashboard/media/edit-network/${aN?.id}`}
                            >
                              <img src={IconEdit} alt="" />
                              Edit Network
                            </Link>
                            <Dropdown.Item onClick={() => onDeleteNetwork(aN)}>
                              <img src={IconDelete} alt="" />
                              Delete Network
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>

                      <h4 className="mb-2 break-word">{aN?.name}</h4>
                      {/* {aN?.description && (
                        <p className="mb-2">{aN?.description}</p>
                      )} */}

                      {aN?.description?.length > cardStringLength ? (
                        <p className="mb-2 break-word">
                          {aN?.description?.slice(0, cardStringLength)}

                          {'....'}
                        </p>
                      ) : (
                        <p className="mb-2 break-word">{aN?.description}</p>
                      )}

                      <span className="color-brand">
                        {aN?.count} Linked Personas
                      </span>
                    </div>

                    <div className="bg-gray-700 p-3 mt-auto d-flex align-items-center justify-content-center">
                      <Link
                        to={`${PreparePath}/dashboard/media/edit-network/${aN?.id}`}
                        className="text-white weight-500 d-flex align-items-center justify-content-center flex-grow-1"
                      >
                        <img src={IconEdit} className="me-2" alt="" />
                        Edit
                      </Link>
                      <div className="separator--y separator--800  flex-shrink-0"></div>

                      <Link
                        to={`${PreparePath}/dashboard/media/view-network/${aN?.id}`}
                        className="text-white weight-500 d-flex align-items-center justify-content-center flex-grow-1"
                      >
                        <img src={IconView} className="img-h-16 me-2" alt="" />
                        View
                      </Link>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="d-flex justify-content-center justify-content-md-end">
            <ReactPaginate
              className="pagination"
              breakLabel="..."
              nextLabel="Next"
              onPageChange={handlePageClick}
              pageCount={Math.ceil(networksTotal / perPage)}
              previousLabel="Prev"
            />
          </div>
        </>
      ) : (
        <div className="bg-gray-800 text-center p-5">
          <div className="py-0 py-md-4">
            <div className="d-flex justify-content-center mb-4">
              <img src={IconEmptyExercise} alt="" />
            </div>
            <p className="weight-600 mb-4">
              A fresh start. Create the first entry.
              <a href={selfHostedKnowledgeBase || MiscellaneousHelpLink.link} target="_blank">
                {' '}
                Need more help?
              </a>
            </p>
            {!isRoleReadOnly(reduxUser.roles) && (
              <a
                className="button button--primary cursor-pointer"
                onClick={onCreateNetworkDialog}
              >
                Create Networks
              </a>
            )}
          </div>
        </div>
      )}

      {showCreateNetworkDialog && (
        <CreateNetworkDialog
          show={showCreateNetworkDialog}
          onClose={onCreateNetworkDialogClose}
          // updateDialogData={updateDialogData}
        />
      )}
      {deleteNetwork && (
        <StylishConfirmDialog
          show={deleteNetwork}
          onClose={() => setDeleteNetwork(null)}
          dialogTitle={'Delete Network'}
          dialogContent={'Are you sure you want this?'}
          onConfirm={onDeleteConfirm}
        />
      )}
    </>
  );
}
