import React from 'react';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import ReactPaginate from 'react-paginate';
import Dropdown from 'react-bootstrap/Dropdown';
import { Link, useNavigate } from 'react-router-dom';
import Network from '../../../../service/Network';
import API from '../../../../service/api';
import { AllIcon } from './Icon';
import CreateParticipant from './CreateParticipant';
import IconEmpty from 'assets/images/icon__exercise-details-empty.svg';
import { HelpParticipants, MiscellaneousHelpLink, useSelfHostedKnowledgeBase} from 'assets/data/HelpContent';
import ConfirmModal from '../../../../component/ConfirmModal/ConfirmModal';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { PreparePath } from 'utils/sharedUtils/sharedConst';
import { StylishNewSearchBar } from 'components/DesignSystems/New/StylishNewSearchBar';
import StylishHelp from 'components/DesignSystems/New/StylishHelp';
import { toastConfig } from 'assets/data/config';
import {
  endLoading,
  startLoading,
} from '../../../../../reducers/loading/loading.action';
import PageTitle from 'components/headers/PageTitle';
import { useNewUIFlag } from 'utils/featureFlags';
import { SharedIcon } from 'components/SharedIcon/SharedIcon';

export const Participant = () => {
  const navigate = useNavigate();
  const [showCreateParticipant, setShowCreateParticipant] = useState(false);
  const [allParticipants, setAllParticipants] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [deleteParticipant, setDeleteParticipant] = useState(null);

  const seflHostedKnowledgeBase = useSelfHostedKnowledgeBase()

  const { selectedWorkspace, type: workspaceType } = useSelector(
    (state) => state.prepare.workspace
  );

  const dispatch = useDispatch();

  const newUI = useNewUIFlag();

  const perPage = 15;

  const onAddParticipants = () => {
    setShowCreateParticipant(true);
  };

  const onCloseModal = (e) => {
    setShowCreateParticipant(false);
    if (e?.id) {
      getParticipants();
    }
  };

  const onChangeSearchTerm = (termSearched) => {
    setSearchTerm(termSearched);
    setPage(1);
  };

  const handlePageClick = (event) => {
    setPage(event.selected + 1);
  };

  const getParticipants = async () => {
    dispatch(startLoading());
    try {
      const participants = await Network.get(API.allParticipants, {
        term: searchTerm,
        workspaceId: selectedWorkspace,
        limit: perPage,
        page: page,
      });
      setTotal(participants.data.response.total);
      setAllParticipants(participants.data.response.dataset);
    } catch (error) {
      // toast.error(error?.response?.data?.response?.status?.msg);
    } finally {
      dispatch(endLoading());
    }
  };
  useEffect(() => {
    getParticipants();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, searchTerm, selectedWorkspace]);

  useEffect(() => {
    if (!!selectedWorkspace && !!workspaceType && workspaceType === 'T&E') {
      navigate(`${PreparePath}/dashboard/plan`);
    }
  }, [workspaceType]);

  const onDeleteParticipant = (row) => {
    setDeleteParticipant(row);
  };

  const onDeleteConfirm = async () => {
    dispatch(startLoading());
    try {
      await Network.post(API.deleteParticipants, { id: deleteParticipant });
      await getParticipants();
      setDeleteParticipant(null);
    } catch (error) {
      toast.error(error?.response?.data?.response?.status?.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };

  const totalFunding = (fundings) => {
    let fundTotal = 0;
    fundings.map(
      (fund) =>
        fund.fundingSourceAmount &&
        (fundTotal += Number(fund.fundingSourceAmount))
    );

    return fundTotal;
  };

  return (
    <>
      {newUI ? (
        <PageTitle
          title="Participants"
          parents={['Exercise Management', 'Design']}
        />
      ) : (
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <StylishNewButton onClick={() => navigate(-1)}>
                <SharedIcon iconName="keyboard_arrow_left" classes="me-2" />
                Back
              </StylishNewButton>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Participants
            </li>
          </ol>
        </nav>
      )}

      <div className="row">
        <div className="col-md-12">
          <div className="d-flex flex-column flex-md-row align-items-md-center mb-4">
            <StylishNewButton
              className="button button--primary w-100 w-md-auto mb-3 mb-md-0"
              onClick={onAddParticipants}
            >
              Create Participant
            </StylishNewButton>
            <div className="d-flex align-items-center justify-content-between ms-auto">
              <p className="m-0 me-3 text-nowrap">{total + ' Participants'}</p>
              <StylishNewSearchBar onChangeSearchTerm={onChangeSearchTerm} />
              <StylishHelp
                classes={'ms-3 flex-shrink-0 sml'}
                title={HelpParticipants.title}
                content={HelpParticipants.content}
                link={seflHostedKnowledgeBase || HelpParticipants.link}
              />
            </div>
          </div>
        </div>
      </div>

      {allParticipants.length ? (
        <>
          <div className="row">
            {allParticipants
              // .slice(itemOffset, itemOffset + perPage)
              .map((participant) => (
                <div
                  className="col-md-6 col-lg-4 col-xl-3 pb-3 mb-4"
                  key={participant.id}
                >
                  <div className="section__participant-item bg-gray-800 rounded p-4 cursor-pointer position-relative">
                    <span
                      className="stretched-link"
                      onClick={() =>
                        navigate(
                          `${PreparePath}/dashboard/plan/prepare/participants/details/${participant.id}`
                        )
                      }
                    ></span>
                    <div className="title d-flex align-items-start mb-4">
                      <div
                        className="d-flex align-items-center flex-grow-1 me-4"
                        style={{ width: 'calc(100% - (34px + 1.5rem))' }}
                      >
                        {participant.icon && (
                          <span className="icon flex-shrink-0 me-3">
                            {
                              AllIcon.find(
                                (icon) => icon.name === participant.icon
                              ).icon
                            }
                          </span>
                        )}
                        <div
                          className="flex-grow-1"
                          style={{ width: 'calc(100% - 3.25rem)' }}
                        >
                          {participant.name && (
                            <h4 className="m-0 text-truncate">
                              {participant.name}
                            </h4>
                          )}
                          {participant.commandAgencyTitle && (
                            <span className="d-block text-truncate">
                              {participant.commandAgencyTitle}
                            </span>
                          )}
                        </div>
                      </div>
                      <Dropdown className="flex-shrink-0 ms-auto position-relative zIndex--1">
                        <Dropdown.Toggle className="button--icon">
                          <i
                            className="fa fa-ellipsis-v"
                            aria-hidden="true"
                          ></i>
                        </Dropdown.Toggle>
                        <Dropdown.Menu align="end">
                          <Link
                            className="dropdown-item"
                            to={`${PreparePath}/dashboard/plan/prepare/participants/${participant.id}`}
                          >
                            <SharedIcon iconName="stylus" />
                            Edit Participant
                          </Link>
                          <Link
                            className="dropdown-item"
                            to={`${PreparePath}/dashboard/plan/prepare/participants/details/${participant.id}`}
                          >
                            <SharedIcon iconName="groups" />
                            View Participant
                          </Link>
                          <Dropdown.Item
                            onClick={() => onDeleteParticipant(participant.id)}
                          >
                            <SharedIcon iconName="delete" />
                            Delete Participant
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                    <ul className="list mb-3">
                      {/* {Number(participant.toCount) ? ( */}
                      <li>{participant.toCount} Total Objectives</li>
                      {/* ) : null} */}
                      {/* {Number(participant.totalAudience) ? ( */}
                      <li>
                        {participant.totalAudience} Total Training Audience
                      </li>
                      {/* ) : null} */}
                      {/* {participant.funding.length ? ( */}
                      <li>${totalFunding(participant.funding)} Funding</li>
                      {/* ) : null} */}
                      {participant.platform.map((platform, index) => {
                        if (index < 3) {
                          return (
                            <li key={index}>
                              {platform.platformTitle} - {platform.platformType}
                            </li>
                          );
                        }
                      })}
                    </ul>
                    <div
                      className="details"
                      onClick={() =>
                        navigate(
                          `${PreparePath}/dashboard/plan/prepare/participants/details/${participant.id}`
                        )
                      }
                    >
                      <SharedIcon iconName="keyboard_arrow_right" bold />
                    </div>
                  </div>
                </div>
              ))}
          </div>
          <div className="d-flex justify-content-center justify-content-md-end mt-4">
            <ReactPaginate
              className="pagination"
              breakLabel="..."
              nextLabel="Next"
              onPageChange={handlePageClick}
              pageCount={Math.ceil(total / perPage)}
              previousLabel="Prev"
            />
          </div>
        </>
      ) : (
        <div className="bg-gray-800 text-center p-5">
          <div className="py-0 py-md-4">
            <div className="d-flex justify-content-center mb-4">
              <img src={IconEmpty} alt="" />
            </div>
            <p className="weight-600 mb-4">
              A fresh start. Create the first entry.
              <a href={seflHostedKnowledgeBase || MiscellaneousHelpLink.link} target="_blank">
                {' '}
                Need more help?
              </a>
            </p>
            <StylishNewButton
              className="button button--primary w-100 w-md-auto mb-3 mb-md-0"
              onClick={onAddParticipants}
            >
              Create Participant
            </StylishNewButton>
          </div>
        </div>
      )}
      {showCreateParticipant && (
        <CreateParticipant
          show={showCreateParticipant}
          onClose={onCloseModal}
        />
      )}
      {deleteParticipant && (
        <ConfirmModal
          show={deleteParticipant}
          onClose={() => setDeleteParticipant(null)}
          heading={'Delete Participant'}
          text={'Are you sure you want this?'}
          onConfirm={onDeleteConfirm}
        />
      )}
    </>
  );
};
