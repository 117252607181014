import React from 'react';
import './StylishTextArea.css';
import Info from 'components/DesignSystems/text/Info'

export default function StylishTextArea({className, black, fullWidth, maxLength, value, ...props}) {
  let builtClassName = 'stylishTextArea';
  builtClassName = `${builtClassName}${!!black ? ' stylishTextArea--black' : ''}`
  builtClassName = `${builtClassName}${!!fullWidth ? ' stylishTextArea--fullWidth' : ''}`
  builtClassName = `${builtClassName}${!!className
    ? ` ${className}`
    : ''
  }`

  let lengthMessage;

  if (value && maxLength) {
    if (value.length == maxLength) {
      lengthMessage = `Maximum length has been reached.`
          } else {
      lengthMessage = `Remaining characters: ${maxLength - value.length}`
    }
  }

  return (
    <>
      <textarea
        rows="3"
        cols="23"
        className={builtClassName}
        maxLength={maxLength}
        value={value}
        {...props}
      />
      {lengthMessage && (
        <Info>
          {lengthMessage}
        </Info>
      )}
    </>
  )
}
