import { useEffect, useState } from 'react';
import { AddEditDetailsDialog } from './AddEditDetailsDialog';
import API from 'PREPAREsrc/service/api';
import Network from 'PREPAREsrc/service/Network';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { toastConfig } from 'assets/data/config';
import {
  endLoading,
  startLoading,
} from '../../../../../reducers/loading/loading.action';

export const Details = (props) => {
  const { detailsData, selectedTab, fetchSingleEvent } = props;
  const dispatch = useDispatch();
  const { selectedWorkspace } = useSelector(
    (state) => state?.prepare?.workspace
  );
  const { eventId } = useSelector((state) => state?.prepare?.planDashboard);
  const [showEEDDetailsDialog, setShowEEDDetailsDialog] = useState(false);

  const handleOnClose = (successFlag) => {
    if (successFlag) fetchSingleEvent(eventId);
    setShowEEDDetailsDialog(false);
  };

  useEffect(() => {
    if (selectedTab === 'details' && eventId) {
      fetchSingleEvent(eventId);
    }
  }, [selectedTab, eventId, selectedWorkspace]);

  return (
    <>
      <StylishNewButton
        type="button"
        className={'button--primary mb-4 w-100 w-md-auto'}
        onClick={() => setShowEEDDetailsDialog(true)}
        disabled={!eventId}
      >
        Edit Details
      </StylishNewButton>
      <p className="mb-4">
        <span className="d-block weight-700 mb-1">Purpose</span>
        {detailsData?.purpose || 'N/A'}
      </p>
      <p className="mb-4">
        <span className="d-block weight-700 mb-1">Background Statement</span>
        {detailsData?.background_statement || 'N/A'}
      </p>
      <p className="m-0">
        <span className="d-block weight-700 mb-1">Test Event Location</span>
        {detailsData?.event_location || 'N/A'}
      </p>
      {(showEEDDetailsDialog && (
        <AddEditDetailsDialog
          show={showEEDDetailsDialog}
          onClose={handleOnClose}
          data={detailsData}
        />
      )) ||
        null}
    </>
  );
};
