import React from 'react';
import { useDispatch } from 'react-redux';
import { useDisasterChats } from './hooks/useDisasterChats';
import DisasterChatItem from './DisasterChatItem';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { SharedIcon } from '../SharedIcon/SharedIcon';
import { setSelectedDChat, setCurrentSitrepId } from 'slices/dchatSlice';

const DisasterChatSidebar = ({ toggleDGPTOpen, DGPTOpen }) => {
    const reduxDispatch = useDispatch();
    const { data: chats = [], isLoading } = useDisasterChats();

    const handleChatClick = (chat) => {
        const startedOpen = DGPTOpen;
        reduxDispatch(setCurrentSitrepId());
        reduxDispatch(setSelectedDChat(chat));
        toggleDGPTOpen();
        if (startedOpen) {
            toggleDGPTOpen();
        }
    };

    return (
        <div className="sidebar-content">
            <div className="sidebar-title">
                <h4 className="m-0">DisasterChats</h4>
            </div>
            <div className="new-chat-icon">
                <StylishNewButton
                    className="button button--link"
                    onClick={() => handleChatClick({})}
                    style={{ margin: '8px' }}
                >
                    <SharedIcon iconName="add" />
                </StylishNewButton>
            </div>

            <hr className="dashed w-10 thin m-0" />

            {isLoading ? (
                <div style={{ textAlign: 'center', marginTop: '20px' }}>
                    <span>Loading...</span>
                </div>
            ) : (
                <ul className="chat-list" style={{ listStyleType: 'none', paddingLeft: '0', margin: '8px' }}>
                    {chats.length > 0 ? (
                        chats.map((chat, i) => (
                            <div key={chat.dchat_id} style={{ marginTop: '8px' }}>
                                <DisasterChatItem
                                    chat={chat}
                                    onClick={() => handleChatClick(chat)}
                                />
                                {(i < chats.length - 1) && (<hr className="dashed w-10 thin m-0" />)}
                            </div>
                        ))
                    ) : (
                        <li>No chats available</li>
                    )}
                </ul>
            )}
        </div>
    );
};

export default DisasterChatSidebar;
