import React, { useEffect, useState, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { Form } from 'react-bootstrap';
import CustomModal from 'components/DesignSystems/New/CustomModal';
import Network from '../../../../service/Network';
import API from '../../../../service/api';
import moment from 'moment';
import { toast } from 'react-toastify';
import { inputStringLength, toastConfig } from 'assets/data/config';
import StylishNewSelect from 'components/DesignSystems/New/StylishNewSelect';
import StylishNewInput from 'components/DesignSystems/New/StylishNewInput';
import StylishTextArea from 'components/DesignSystems/form/StylishTextArea';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { endLoading, startLoading } from "../../../../../reducers/loading/loading.action";
import { SharedIcon } from 'components/SharedIcon/SharedIcon';

const EditFeedbackHseep = ({ singleFeedback }) => {
  const [reason, setReason] = useState([
    {
      value: 'Add New',
      label: 'Add New',
    },
  ]);

  const [selectedReason, setSelectedReason] = useState({
    value: '',
    label: 'Select a reason',
  });
  const [modalFor, setModalFor] = useState('');
  const [complete, setComplete] = useState(true);
  const [rating, setRating] = useState('U');
  const [showModal, setShowModal] = useState(false);
  const [showModalMetric, setShowModalMetric] = useState(false);
  const [newReasonValue, setNewReasonValue] = useState('');
  const [feedbackComment, setFeedbackComment] = useState('');
  const [buttonDisable, setButtonDisable] = useState(false);
  const [taskRating, setTaskRating] = useState([]);
  const [selectedMissionTask, setSelectedMissionTask] = useState({
    value: '',
    label: 'Metric',
  });
  const [missionTask, setMissionTask] = useState([
    {
      value: 'Add New',
      label: 'Add new',
    },
  ]);
  const [feedbackData, setFeedbackData] = useState({
    lessonsLearned: 'No',
  });
  // const [feedback, setFeedback] = useState();
  const [newLessonsLearned, setNewlessonsLearned] = useState([]);
  const [reasonSearch, setReasonSearch] = useState('');
  const [metricSearch, setmetricSearch] = useState('');
  const [modalButtonDisabled, setModalButtonDisabled] = useState(false);
  const [modalInputReason, setModalInputReason] = useState('');
  const [newAllMission, setNewAllMission] = useState([]);
  const [missionTaskError, setMissionTaskError] = useState(false);
  const [missionCompleted, setMissionCompleted] = useState(0);
  const [showMissionTask, setShowMissionTask] = useState(false);
  const [showDeleteModal, setDeleteShowModal] = useState({
    id: '',
    index: null,
    show: false,
  });

  const { control, setValue, getValues } = useForm();

  const { fields, append, remove } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: 'lesson', // unique name for your Field Array
  });

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { selectedWorkspace } = useSelector((state) => state.prepare.workspace);

  useEffect(() => {
    fetchMissionTask();
  }, [selectedWorkspace, metricSearch]);

  useEffect(() => {
    fetchallAcademicReasons();
  }, [selectedWorkspace, reasonSearch]);

  useEffect(() => {
    if (singleFeedback?.lessionlearned.length > 0) {
      setValue(
        'lesson',
        singleFeedback?.lessionlearned.map((t) => ({
          classification: 'Unclassified',
          planfeedbacklessionlearnedId: t?.planfeedbacklessionlearnedId,
          comments: t?.comments || '',
          discussion: t?.discussion || '',
          eventDescription: t?.eventDescription || '',
          implications: t?.implications || '',
          observation: t?.observation || '',
          potentialOPR: t?.potentialOPR || '',
          recommendation: t?.recommendation || '',
          title: t?.title || '',
        }))
      );
      return;
    } else if (feedbackData.lessonsLearned === 'Yes') {
      append({
        classification: 'Unclassified',
        comments: '',
        discussion: '',
        eventDescription: '',
        implications: '',
        observation: '',
        potentialOPR: '',
        recommendation: '',
        title: '',
      });
    }
  }, [feedbackData]);

  const selectMissionFromSelectBox = (e) => {
    if (!e) {
      if (selectedMissionTask.value === '') return;
      setSelectedMissionTask({
        value: '',
        label: 'Metric',
      });
      return;
    }
    if (e?.value === 'Add New') {
      setShowModalMetric(true);
      setModalFor('Metric');
      return;
    }

    setSelectedMissionTask({ ...e });
  };

  const fetchMissionTask = async () => {
    if (selectedWorkspace === null) return;
    try {
      const task = await Network.get(API.planMetricList, {
        workspaceId: selectedWorkspace,
        search: metricSearch,
        filter: singleFeedback?.type,
      });
      if (task !== undefined && task.status === 200) {
        setMissionTask([
          { value: 'Add New', label: 'Add new' },
          ...task.data.response.dataset.map((e) => {
            return {
              value: e.id,
              label: e.title,
            };
          }),
        ]);
      }
    } catch (error) {
      toast.error('Something went wrong', toastConfig);
    }
  };

  const closeReasonModal = () => {
    setShowModalMetric(false);
    setModalButtonDisabled(false);
    setModalInputReason('');
  };

  const modalInputChange = (e) => {
    setModalInputReason(e.target.value);
  };

  const saveAllNewMission = () => {
    if (selectedMissionTask.value === '') {
      setMissionTaskError(true);
      return;
    }
    setMissionTaskError(false);
    let data = { ...selectedMissionTask, completed: missionCompleted };
    setNewAllMission((prevState) => [...prevState, { ...data }]);
    setShowMissionTask(false);
    setSelectedMissionTask({ value: '', label: 'Select a mission' });
    setMissionCompleted(0);
  };

  const deleteMissionTask = async () => {
    let data = { ...showDeleteModal };
    let mtData = [...newAllMission];
    mtData.splice(data.index, 1);
    setNewAllMission(mtData);
    data.id = '';
    data.index = 0;
    data.show = false;
    setDeleteShowModal(data);
  };

  // const deleteMissionTask = async () => {
  //   let data = { ...showDeleteModal };

  //   if (!("planfeedbackmissiontaskId" in data.id)) {
  //     // if mission task is not is server ..
  //     let mtData = [...newAllMission];
  //     mtData.splice(data.index, 1);
  //     setNewAllMission(mtData);
  //     data.id = "";
  //     data.index = 0;
  //     data.show = false;
  //     setDeleteShowModal(data);
  //   } else {
  //     // removing Mission task from server  then UI...
  //     setModalButtonDisabled(true);
  //     try {
  //       const deleteMissionTaskRes = await Network.post(
  //         API.deleteFeedbackMissionTask,
  //         {
  //           id: data.id.planfeedbackmissiontaskId,
  //         }
  //       );

  //       if (
  //         deleteMissionTaskRes !== undefined &&
  //         deleteMissionTaskRes.status === 200
  //       ) {
  //         let mtData = [...newAllMission];
  //         mtData.splice(data.index, 1);
  //         setNewAllMission(mtData);
  //         // toast.success("mission task deleted successfully");
  //       }
  //     } catch (error) {
  //       toast.error("Something went wrong");
  //     } finally {
  //       setModalButtonDisabled(false);
  //       data.id = "";
  //       data.index = null;
  //       data.show = false;
  //       setDeleteShowModal(data);
  //     }
  //   }
  // };

  const confirmDeleteModal = () => {
    if (modalFor === 'Delete Metic') {
      deleteMissionTask();
      return;
    }
    if (modalFor === 'Delete Feedback Lesson') {
      deleteLeasonLearned();
    }
  };

  const onModalConfirmMetric = async () => {
    if (modalFor === 'Metric') {
      onMissionModalConfirm();
    }
  };

  function notOnlySpace(str) {
    return /[A-Za-z]/.test(str);
  }

  const onMissionModalConfirm = async () => {
    if (modalInputReason.length === 0 || !notOnlySpace(modalInputReason)) {
      return;
    }
    setModalButtonDisabled(true);
    try {
      let postMission = await Network.post(API.createMetric, {
        title: modalInputReason,
        participantType: singleFeedback?.type,
        workspaceId: selectedWorkspace,
      });
      if (postMission !== undefined && postMission.status === 200) {
        let res = {
          value: postMission.data.response.dataset[0].id,
          label: postMission.data.response.dataset[0].title,
        };
        setMissionTask((prevState) => [...prevState, res]);
        setSelectedMissionTask(res);
        toast.success('Metric created successfully', toastConfig);
      }
    } catch (error) {
      toast.error('Something went wrong', toastConfig);
    } finally {
      setModalButtonDisabled(false);
      setShowModalMetric(false);
    }
  };

  const updateAllNewMission = (e, idx) => {
    let data = [...newAllMission];
    data[idx].completed = e.target.value;
    setNewAllMission([...data]);
  };

  const onKeyPressEvent = (event) => {
    var invalidChars = ['E', '.', 'e', '-', '+'];
    if (invalidChars.includes(event.key)) {
      event.preventDefault();
    }
  };

  const cancelSetShowMissionTask = () => {
    setMissionTaskError(false);
    setShowMissionTask(false);
    setSelectedMissionTask({
      value: '',
      label: 'Metric',
    });
    setMissionCompleted(0);
  };

  const removeAMissionTask = (mission, index) => {
    setModalFor('Delete Metic');
    let data = {
      id: mission,
      index: index,
      show: true,
    };
    setDeleteShowModal(data);
  };

  const deleteFeedbackLessionLearned = (index) => {
    setModalFor('Delete Feedback Lesson');
    let data = {
      id: '',
      index: index,
      show: true,
    };
    setDeleteShowModal(data);
  };

  useLayoutEffect(() => {
    // setFeedback({ ...singleFeedback });
    let fData = { ...feedbackData };

    if (singleFeedback?.rating?.length === 0) {
      if (singleFeedback?.task?.length > 0) {
        setTaskRating(
          singleFeedback?.task?.map((i) => {
            return {
              measure_rel_id: i?.measure_rel_id,
              rating: '',
            };
          })
        );
      }
    } else {
      // setTaskRating([...singleFeedback.rating]);
      setTaskRating(
        singleFeedback?.task?.map((i, idx) => {
          return {
            measure_rel_id: i?.measure_rel_id,
            rating: singleFeedback?.rating[idx]?.rating,
          };
        })
      );
    }
    // fData.lessonsLearned =
    //   singleFeedback?.lessonLearned === true ? "Yes" : "No";

    // setFeedbackData(fData);
    fData.lessonsLearned = singleFeedback?.lessonLearned ? 'Yes' : 'No';
    if (singleFeedback?.lessionlearned?.length > 0) {
      setFeedbackData({ ...fData });
    }
    // if (singleFeedback?.lessionlearned?.length > 0) {
    //   setNewlessonsLearned([...singleFeedback.lessionlearned]);
    // }
    if (singleFeedback?.metrics?.length > 0) {
      setNewAllMission(
        singleFeedback?.metrics.map((item) => {
          return {
            feedbackMetricId: item?.feedbackMetricId,
            value: item?.metricsId,
            label: item?.metricsTitle,
            completed: item?.feedbackData,
          };
        })
      );
    }
    setComplete(
      singleFeedback?.evalcomplete !== null ? singleFeedback.evalcomplete : true
    );

    setRating(
      singleFeedback?.evalstatus !== null ? singleFeedback.evalstatus : 'U'
    );

    setFeedbackComment(singleFeedback?.evalcomment);
  }, []);

  const changeAssessmentComplete = (com) => {
    setComplete(com);
  };

  const changeAssessmentStatus = (rat) => {
    setRating(rat);
  };

  const fetchallAcademicReasons = async () => {
    if (selectedWorkspace === null) return;
    try {
      const fetchReason = await Network.get(API.planAssessmentReasonList, {
        workspaceId: selectedWorkspace,
        search: reasonSearch,
        limit: 100,
      });
      if (fetchReason !== undefined && fetchReason.status === 200) {
        if (singleFeedback?.reasonId !== null) {
          fetchReason.data.response.dataset.map((r) => {
            if (r.id === singleFeedback?.reasonId) {
              setSelectedReason({
                value: r.id,
                label: r.title,
              });
              return;
            }
          });
        }
        setReason([
          { value: 'Add New', label: 'Add new' },
          ...fetchReason.data.response.dataset.map((e) => {
            return {
              value: e.id,
              label: e.title,
            };
          }),
        ]);
      }
    } catch (error) {
      toast.error('Something went wrong', toastConfig);
    }
  };

  const SelectReasonFromSelectBox = (e) => {
    if (!e) {
      setSelectedReason({
        value: '',
        label: 'Select a reason',
      });
      return;
    }
    if (e.value === 'Add New') {
      setShowModal(true);
      return;
    }
    setSelectedReason(e);
  };

  const onModalConfirm = async () => {
    if (newReasonValue.length === 0) return;
    setButtonDisable(true);
    try {
      let postReason = await Network.post(API.createPlanAssessmentReason, {
        title: newReasonValue,
        workspaceId: selectedWorkspace,
      });
      if (postReason !== undefined && postReason.status === 200) {
        let res = {
          value: postReason.data.response.dataset[0].id,
          label: postReason.data.response.dataset[0].title,
        };
        setReason((prevState) => [...prevState, res]);
        setSelectedReason(res);
        toast.success('Reason added successfully', toastConfig);
      }
    } catch (error) {
      toast.error('Something went wrong', toastConfig);
    } finally {
      setShowModal(false);
      setButtonDisable(false);
    }
  };

  const closeModal = () => {
    setNewReasonValue('');
    setShowModal(false);
  };

  const updateRating = (e, idx) => {
    let data = [...taskRating];
    data[idx].rating = e.target.value;
    setTaskRating(data);
  };

  const saveData = async (type) => {
    let lessonData = getValues();
    let reqObj = {
      status: type,
      trainingObjectiveId: singleFeedback?.trainingObjectiveId,
      feedbackId: singleFeedback?.feedbackId,
      workspaceId: selectedWorkspace,
      participantId: singleFeedback?.participantId,
      evaluation: {
        complete: complete,
        comment: feedbackComment,
      },
      rating: taskRating,
    };

    if (
      lessonData?.lesson?.length > 0 &&
      fields.length > 0 &&
      feedbackData?.lessonsLearned === 'Yes'
    ) {
      reqObj.lessonLearned = true;
      reqObj.lessionlearned = lessonData.lesson.map((e) => {
        return {
          potentialOPR: e?.potentialOPR,
          title: e?.title,
          observation: e?.observation,
          discussion: e?.discussion,
          recommendation: e?.recommendation,
          implications: e?.implications,
          eventDescription: e?.eventDescription,
          comments: e?.comments,
        };
      });
    } else {
      reqObj.lessonLearned = false;
      reqObj.lessionlearned = [];
    }

    // let ratingData = taskRating.map((e, index) => {
    //   return {
    //     rating: e.rating,
    //     measure_rel_id: singleFeedback.task[index].measure_rel_id,
    //   };
    // });

    // reqObj.rating = ratingData;

    // if (newLessonsLearned?.length > 0) {
    //   reqObj.lessionlearned = newLessonsLearned;
    // }

    if (complete === false) {
      reqObj.evaluation.status = 'U';
    } else {
      reqObj.evaluation.status = rating;
    }
    if (newAllMission.length > 0) {
      reqObj.metric = true;
      reqObj.metrics = newAllMission.map((item) => {
        return {
          metricsId: item.value,
          task: item.label,
          feedbackData: item.completed !== '' ? Number(item.completed) : 0,
        };
      });
    } else {
      reqObj.metric = false;
    }

    if (!(complete === true && rating === 'P')) {
      if (selectedReason.value !== '') {
        reqObj.evaluation.reasonId = selectedReason.value;
      }
    }

    setButtonDisable(true);
    dispatch(startLoading());
    try {
      let updateRes = await Network.post(API.updateFeedback, {
        ...reqObj,
      });
      if (updateRes !== undefined && updateRes.status === 200) {
        toast.success('Feedback updated successfully', toastConfig);
        navigate(-1);
      }
    } catch (error) {
      toast.error('Something went wrong', toastConfig);
    } finally {
      dispatch(endLoading());
      setButtonDisable(false);
    }
  };

  const changeFeedbackLessonsLearned = (lessonsLearned) => {
    if (feedbackData?.lessonsLearned === lessonsLearned) return;
    if (lessonsLearned === 'Yes' && newLessonsLearned.length === 0) {
      let newlesson = [
        {
          classification: 'Unclassified',
          comments: '',
          discussion: '',
          eventDescription: '',
          implications: '',
          observation: '',
          potentialOPR: '',
          recommendation: '',
          title: '',
        },
      ];
      setNewlessonsLearned([...newlesson]);
    }
    let data = { ...feedbackData };
    data.lessonsLearned = lessonsLearned;
    setFeedbackData({ ...data });
  };

  const deleteLeasonLearned = async () => {
    let data = { ...showDeleteModal };
    remove(data.index);
    fields.length === 1 && changeFeedbackLessonsLearned('No');
    data.id = '';
    data.index = 0;
    data.show = false;
    setDeleteShowModal(data);
  };

  // const deleteLeasonLearned = async () => {
  //   let data = { ...showDeleteModal };

  //   if (!("planfeedbacklessionlearnedId" in data.id)) {
  //     // if mission task is not is server ..
  //     let mtData = [...newLessonsLearned];
  //     mtData.splice(data.index, 1);
  //     setNewlessonsLearned(mtData);
  //     data.id = "";
  //     data.index = 0;
  //     data.show = false;
  //     setDeleteShowModal(data);
  //   } else {
  //     // removing Mission task from server  then UI...
  //     setModalButtonDisabled(true);
  //     try {
  //       const deleteLeasonLearnedRes = await Network.post(
  //         API.deleteFeedbackLessionLearned,
  //         {
  //           id: data.id.planfeedbacklessionlearnedId,
  //         }
  //       );

  //       if (
  //         deleteLeasonLearnedRes !== undefined &&
  //         deleteLeasonLearnedRes.status === 200
  //       ) {
  //         let mtData = [...newLessonsLearned];
  //         mtData.splice(data.index, 1);
  //         setNewlessonsLearned(mtData);
  //         // toast.success("mission task deleted successfully");
  //       }
  //     } catch (error) {
  //       toast.error("Something went wrong");
  //     } finally {
  //       setModalButtonDisabled(false);
  //       data.id = "";
  //       data.index = null;
  //       data.show = false;
  //       setDeleteShowModal(data);
  //     }
  //   }
  // };

  const customFilter = (option, searchText, type) => {
    if (option.value === 'Add New') return true;
    if (option.data.label.toLowerCase().includes(searchText.toLowerCase())) {
      if (type === 'reason') {
        setReasonSearch(searchText);
      } else if (type === 'metric') {
        setmetricSearch(searchText);
      }
      return true;
    } else {
      return false;
    }
  };

  console.log('singleFeedback', singleFeedback);

  return (
    <>
      <CustomModal
        show={showModalMetric}
        heading={modalFor}
        onClose={closeReasonModal}
        onConfirm={onModalConfirmMetric}
        saveButton="save"
        buttonDisable={modalButtonDisabled}
      >
        <div className="mb-3">
          <label className="form-label">Title</label>
          <StylishNewInput
            className="form-control"
            autoFocus={true}
            type="text"
            onChange={modalInputChange}
          />
        </div>
        <div className="mb-0">
          <label className="form-label">Type</label>
          <StylishNewInput
            className="form-control"
            autoFocus={false}
            type="text"
            defaultValue={singleFeedback?.type}
            readOnly
          />
        </div>
      </CustomModal>

      <CustomModal
        show={showDeleteModal?.show}
        heading={modalFor}
        onClose={() => setDeleteShowModal(false)}
        onConfirm={confirmDeleteModal}
        saveButton="Ok"
        buttonDisable={modalButtonDisabled}
      >
        <p>Are you sure you want to do this?</p>
      </CustomModal>

      {showModal ? (
        <CustomModal
          show={showModal}
          heading="Ineffective Reason"
          onClose={closeModal}
          onConfirm={onModalConfirm}
          saveButton="save"
          buttonDisable={buttonDisable}
        >
          <Form.Control
            autoFocus={true}
            type="text"
            onChange={(e) => setNewReasonValue(e.target.value)}
          />
        </CustomModal>
      ) : null}
      <div className="form-block mb-4">
        <div className="row">
          <div className="col-md-6 mb-3">
            <label className="form-label">Date</label>
            <p className="m-0">
              {moment(singleFeedback?.createdAt).format('Do MMM YY - hhmm')}
            </p>
          </div>
          <div className="col-md-6 mb-3">
            <label className="form-label">Participant Platform</label>
            <p className="m-0">{`${singleFeedback?.participantName}${
              singleFeedback?.platformTitle
                ? ` - ` + singleFeedback?.platformTitle
                : ``
            }${
              singleFeedback?.platformType
                ? ' : ' + singleFeedback?.platformType
                : ''
            }`}</p>
          </div>
          <div className="col-md-6 mb-3">
            <label className="form-label">Exercise Objective</label>
            <p className="m-0">{singleFeedback?.exerciseObjective}</p>
          </div>
          <div className="col-md-6 mb-3">
            <label className="form-label">Request Method</label>
            <p className="m-0">{singleFeedback?.requestedMethod}</p>
          </div>
          <div className="col-md-6 mb-3">
            <label className="form-label">Traning Method</label>
            <p className="m-0">{singleFeedback?.trainedMethodType}</p>
          </div>
          <div className="col-md-6 mb-3">
            <label className="form-label">Associated Injects</label>
            <p className="m-0">{singleFeedback?.associatedInjectCount}</p>
          </div>
          <div className="col-md-6 mb-3">
            <label className="form-label">Source</label>
            <p className="m-0">{singleFeedback?.sourcetitle}</p>
          </div>
          <div className="col-md-6 mb-3">
            <label className="form-label">Reference</label>
            <p className="m-0">{singleFeedback?.para}</p>
          </div>
          <div className="col-md-12 mb-3">
            <label className="form-label">Title or Description</label>
            <p className="m-0">{singleFeedback?.description}</p>
          </div>
          <div className="col-md-12 mb-3">
            <label className="form-label">Level or Competency</label>
            <p className="m-0">{singleFeedback?.competence}</p>
          </div>
          {singleFeedback?.injects?.length > 0 ? (
            <div className="col-md-12">
              <label className="form-label">Injects</label>
              {singleFeedback?.injects?.map((inject, idx) => (
                <p key={idx} className="m-0">
                  <span className="d-block">
                    #{inject?.number} - {inject?.title} -{' '}
                    {moment(inject?.startDate).format('DD MMM YY - hhmm')}
                  </span>
                </p>
              ))}
            </div>
          ) : (
            <></>
          )}
        </div>
        <hr className="dashed my-4" />
        <div className="col-md-12 mb-3">
          <label className="form-label">
            Was this exercise objective completed?
          </label>
          <div className="form-radio-group mt-1">
            <div className="form-radio">
              <StylishNewInput
                type="radio"
                id="check__yes"
                name="check__completed"
                checked={complete === true ? true : false}
                onChange={() => changeAssessmentComplete(true)}
              />
              <span className="icon"></span>
              <label className="form-label weight-400" htmlFor="check__yes">
                Yes
              </label>
            </div>
            <div className="form-radio">
              <StylishNewInput
                type="radio"
                id="check__no"
                name="check__completed"
                checked={complete === false ? true : false}
                onChange={() => changeAssessmentComplete(false)}
              />
              <span className="icon"></span>
              <label className="form-label weight-400" htmlFor="check__no">
                No
              </label>
            </div>
          </div>
        </div>
        <div className="row">
          {complete !== false ? (
            <div className="col-xl-5 col-lg-6 mb-3 mb-lg-0">
              <label className="form-label">What was the final rating?</label>
              <div className="form-radio-group mt-1">
                <div className="form-radio align-items-center">
                  <StylishNewInput
                    type="radio"
                    id="check__p"
                    name="check__rating"
                    checked={rating === 'P' ? true : false}
                    onChange={() => changeAssessmentStatus('P')}
                  />
                  <span className="icon"></span>
                  <label
                    className="status status--icon weight-700 ms-3"
                    htmlFor="check__p"
                    style={{ background: '#2ECC70' }}
                  >
                    P
                  </label>
                </div>
                <div className="form-radio align-items-center">
                  <StylishNewInput
                    type="radio"
                    id="check__s"
                    name="check__rating"
                    checked={rating === 'S' ? true : false}
                    onChange={() => changeAssessmentStatus('S')}
                  />
                  <span className="icon"></span>
                  <label
                    className="status status--icon weight-700 ms-3"
                    htmlFor="check__s"
                    style={{ background: '#CC9A06' }}
                  >
                    S
                  </label>
                </div>
                <div className="form-radio align-items-center">
                  <StylishNewInput
                    type="radio"
                    id="check__m"
                    name="check__rating"
                    checked={rating === 'M' ? true : false}
                    onChange={() => changeAssessmentStatus('M')}
                  />
                  <span className="icon"></span>
                  <label
                    className="status status--icon weight-700 ms-3"
                    htmlFor="check__m"
                    style={{ background: '#FD7E14' }}
                  >
                    M
                  </label>
                </div>
                <div className="form-radio align-items-center me-0">
                  <StylishNewInput
                    type="radio"
                    id="check__u"
                    name="check__rating"
                    checked={rating === 'U' ? true : false}
                    onChange={() => changeAssessmentStatus('U')}
                  />
                  <span className="icon"></span>
                  <label
                    className="status status--icon weight-700 ms-3"
                    htmlFor="check__u"
                    style={{ background: '#0AA2C0' }}
                  >
                    U
                  </label>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}

          {complete === true && rating === 'P' ? (
            <></>
          ) : (
            <div className="col-xl-4 col-lg-6">
              <label className="form-label">
                Why was the objective not effective (For S,M,U)?
              </label>
              <StylishNewSelect
                options={reason}
                placeholder={'Select a Reason'}
                isClearable={selectedReason?.value !== '' ? true : false}
                isSearchable={true}
                isMulti={false}
                filterOption={(option, searchText) =>
                  customFilter(option, searchText, 'reason')
                }
                isDisabled={false}
                onChange={(e) => SelectReasonFromSelectBox(e)}
                value={selectedReason}
              />
            </div>
          )}
        </div>

        <hr className="dashed my-4" />
        {singleFeedback?.task?.length > 0 ? (
          <>
            {singleFeedback?.task?.map((t, idx) => (
              <div className="row my-3" key={idx}>
                <div key={idx} className="col-md-6 col-lg-3 mb-3 mb-lg-0">
                  <label className="form-label">
                    Behavior or Critical Task
                  </label>
                  <StylishNewInput
                    maxLength={inputStringLength}
                    type="text"
                    className="form-control"
                    value={t?.task?.length > 0 ? t?.task : ''}
                    readOnly
                  />
                </div>
                <div className="col-md-6 col-lg-3 mb-3 mb-lg-0">
                  <label className="form-label">Standard</label>
                  <StylishNewInput
                    maxLength={inputStringLength}
                    type="text"
                    className="form-control"
                    value={t?.measure?.length > 0 ? t?.measure : ''}
                    readOnly
                  />
                </div>
                <div className="col-md-6 col-lg-3 mb-3 mb-md-0">
                  <label className="form-label">Condition</label>
                  <StylishNewInput
                    maxLength={inputStringLength}
                    type="text"
                    className="form-control"
                    value={t?.code?.length > 0 ? t?.code : ''}
                    readOnly
                  />
                </div>
                <div className="col-md-6 col-lg-3">
                  <label className="form-label">
                    Measurement/ Target Rating
                  </label>
                  <StylishNewInput
                    maxLength={inputStringLength}
                    type="text"
                    className="form-control"
                    value={
                      taskRating?.length > 0 ? taskRating[idx]?.rating : ''
                    }
                    onChange={(e) => updateRating(e, idx)}
                  />
                </div>
                {idx < singleFeedback?.task?.length - 1 ? (
                  <hr className="m-0 mt-4" />
                ) : (
                  <></>
                )}
              </div>
            ))}
          </>
        ) : (
          <></>
        )}

        <hr className="dashed my-4" />
        <div className="row">
          <div className="col-md-12">
            <label className="form-label">Comment</label>
            <StylishTextArea
              maxLength={1500}
              className="form-control form-textarea"
              value={feedbackComment !== null ? feedbackComment : ''}
              onChange={(e) => setFeedbackComment(e.target.value)}
            ></StylishTextArea>
          </div>
        </div>
      </div>

      <div className="form-block mb-4">
        <h4 className="mb-4">Metrics</h4>
        {newAllMission.map((mission, index) => (
          <div key={index} className="row">
            <div className="col-md-6 mb-3">
              <label className="form-label">Metric</label>
              <StylishNewInput
                type="text"
                className="form-control"
                readOnly
                value={mission?.label ? mission?.label : ''}
              />
            </div>
            <div className="col-md-6 mb-3">
              <label className="form-label">Number Completed</label>
              <StylishNewInput
                type="text"
                className="form-control"
                value={mission?.completed ? mission?.completed : 0}
                onKeyDown={onKeyPressEvent}
                onChange={(e) => updateAllNewMission(e, index)}
              />
            </div>
            <div className="col-md-12 button-group">
              <StylishNewButton
                type="button"
                className="button--icon primary"
                onClick={() => removeAMissionTask(mission, index)}
              >
                <SharedIcon iconName="delete" />
              </StylishNewButton>
            </div>
            <div className="col-md-12">
              <hr className="dashed my-4" />
            </div>
          </div>
        ))}

        {/* select and input for complete ... */}
        {showMissionTask ? (
          <div className="row">
            <div className="col-md-6 mb-3">
              <label className="form-label">Metric</label>
              <StylishNewSelect
                options={missionTask.filter((item) => {
                  return (
                    newAllMission.filter((item2) => {
                      return item2.value == item.value;
                    }).length == 0
                  );
                })}
                // options={missionTask}
                isClearable={selectedMissionTask.value === '' ? false : true}
                placeholder="Select a Metric"
                filterOption={(option, searchText) =>
                  customFilter(option, searchText, 'metric')
                }
                onChange={(e) => selectMissionFromSelectBox(e)}
                value={selectedMissionTask}
              />
              {missionTaskError === true && selectedMissionTask.value === '' ? (
                <span className="form-text form-error">
                  This is a required field
                </span>
              ) : null}
            </div>
            <div className="col-md-6 mb-3">
              <label className="form-label">Number Completed</label>
              <StylishNewInput
                type="number"
                className="form-control"
                placeholder="0"
                onKeyDown={onKeyPressEvent}
                onChange={(e) => setMissionCompleted(e.target.value)}
              />
            </div>
            <div className="col-md-12 button-group">
              <StylishNewButton
                className="button button--primary button--sml rounded-5"
                type="submit"
                onClick={saveAllNewMission}
              >
                Save
              </StylishNewButton>
              <StylishNewButton
                className="button button--secondary button--reverse button--sml rounded-5"
                onClick={cancelSetShowMissionTask}
              >
                Cancel
              </StylishNewButton>
            </div>
            <div className="col-md-12">
              <hr className="dashed my-4" />
            </div>
          </div>
        ) : (
          <></>
        )}

        <StylishNewButton
          className="button button--primary"
          onClick={() => {
            setShowMissionTask(true), fetchMissionTask();
          }}
        >
          Add
        </StylishNewButton>
      </div>
      <div className="form-block mb-4">
        <h4 className="mb-4">Lessons Learned</h4>
        <div className="mb-4">
          <label className="form-label">
            Do you have lessons learned to record for this day?
          </label>
          <div className="form-radio-group mt-1">
            <div className="form-radio">
              <StylishNewInput
                type="radio"
                id="check__lesson__yes"
                name="check__lesson__completed"
                readOnly
                checked={feedbackData.lessonsLearned === 'Yes' ? true : false}
                onChange={() => changeFeedbackLessonsLearned('Yes')}
              />
              <span className="icon"></span>
              <label
                className="form-label weight-400"
                htmlFor="check__lesson__yes"
              >
                Yes
              </label>
            </div>
            <div className="form-radio">
              <StylishNewInput
                type="radio"
                id="check__lesson__no"
                name="check__lesson__completed"
                readOnly
                checked={feedbackData.lessonsLearned === 'No' ? true : false}
                onChange={() => changeFeedbackLessonsLearned('No')}
              />
              <span className="icon"></span>
              <label
                className="form-label weight-400"
                htmlFor="check__lesson__no"
              >
                No
              </label>
            </div>
          </div>
        </div>
        {feedbackData.lessonsLearned === 'Yes' ? (
          <>
            <form>
              <hr className="dashed mt-0 mb-4" />
              {fields.map((lesson, idx) => (
                <div className="row" key={lesson.id}>
                  <div className="col-md-6 mb-3">
                    <label className="form-label">Potential OPR</label>
                    <Controller
                      control={control}
                      name={`lesson.${idx}.potentialOPR`}
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <StylishNewInput
                          type="text"
                          className="form-control"
                          onChange={onChange}
                          value={value}
                        />
                      )}
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <label className="form-label">Classification</label>
                    <StylishNewInput
                      type="text"
                      className="form-control"
                      value="Unclassified"
                      readOnly
                    />
                  </div>
                  <div className="col-md-12 mb-3">
                    <label className="form-label">Title</label>
                    <Controller
                      control={control}
                      name={`lesson.${idx}.title`}
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <StylishNewInput
                          type="text"
                          className="form-control"
                          onChange={onChange}
                          value={value}
                        />
                      )}
                    />
                  </div>
                  <div className="col-md-12 mb-3">
                    <label className="form-label">Observation</label>
                    <Controller
                      control={control}
                      name={`lesson.${idx}.observation`}
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <StylishTextArea
                          className="form-control form-textarea"
                          onChange={onChange}
                          value={value}
                        ></StylishTextArea>
                      )}
                    />
                  </div>
                  <div className="col-md-12 mb-3">
                    <label className="form-label">Discussion</label>
                    <Controller
                      control={control}
                      name={`lesson.${idx}.discussion`}
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <StylishTextArea
                          className="form-control form-textarea"
                          onChange={onChange}
                          value={value}
                        ></StylishTextArea>
                      )}
                    />
                  </div>
                  <div className="col-md-12 mb-3">
                    <label className="form-label">Recommendation</label>
                    <Controller
                      control={control}
                      name={`lesson.${idx}.recommendation`}
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <StylishTextArea
                          className="form-control form-textarea"
                          onChange={onChange}
                          value={value}
                        ></StylishTextArea>
                      )}
                    />
                  </div>
                  <div className="col-md-12 mb-3">
                    <label className="form-label">Implications</label>
                    <Controller
                      control={control}
                      name={`lesson.${idx}.implications`}
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <StylishTextArea
                          className="form-control form-textarea"
                          onChange={onChange}
                          value={value}
                        ></StylishTextArea>
                      )}
                    />
                  </div>
                  <div className="col-md-12 mb-3">
                    <label className="form-label">Event Description</label>
                    <Controller
                      control={control}
                      name={`lesson.${idx}.eventDescription`}
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <StylishTextArea
                          className="form-control form-textarea"
                          onChange={onChange}
                          value={value}
                        ></StylishTextArea>
                      )}
                    />
                  </div>
                  <div className="col-md-12 mb-3">
                    <label className="form-label">Comments</label>
                    <Controller
                      control={control}
                      name={`lesson.${idx}.comments`}
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <StylishTextArea
                          className="form-control form-textarea"
                          onChange={onChange}
                          value={value}
                        ></StylishTextArea>
                      )}
                    />
                  </div>
                  <div className="col-md-12 button-group">
                    <StylishNewButton
                      type="button"
                      className="button--icon primary"
                      onClick={() => {
                        deleteFeedbackLessionLearned(idx);
                        // remove(idx);
                        // fields.length === 1 &&
                        //   changeFeedbackLessonsLearned("No");
                      }}
                    >
                      <SharedIcon iconName="delete" />
                    </StylishNewButton>
                  </div>
                  <div className="col-md-12">
                    <hr className="dashed my-4" />
                  </div>
                </div>
              ))}
            </form>

            <StylishNewButton
              className="button button--primary"
              onClick={() =>
                append({
                  classification: 'Unclassified',
                  comments: '',
                  discussion: '',
                  eventDescription: '',
                  implications: '',
                  observation: '',
                  potentialOPR: '',
                  recommendation: '',
                  title: '',
                })
              }
            >
              Add
            </StylishNewButton>
          </>
        ) : null}
      </div>
      <div className="mt-4">
        <div className="button-group">
          <StylishNewButton
            className="button button--primary ms-auto"
            disabled={buttonDisable ? true : false}
            type="submit"
            onClick={() => saveData('Submitted')}
          >
            Submit
          </StylishNewButton>
          <StylishNewButton
            className="button button--secondary "
            disabled={buttonDisable ? true : false}
            type="submit"
            onClick={() => saveData('Draft')}
          >
            Save Draft
          </StylishNewButton>
          <StylishNewButton
            className="button button--secondary button--reverse"
            onClick={() => navigate(-1)}
          >
            Cancel
          </StylishNewButton>
        </div>
      </div>
    </>
  );
};

export default EditFeedbackHseep;
