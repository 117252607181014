import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useLocation, useParams } from 'react-router-dom';
import ExerciseDetails from './ExerciseDetails';
// import PlanMeetings from './PlanMeetings/PlanMeetings';
import FundingSources from './FundingSources/FundingSources';
import Objectives from './Objectives/Objectives';
import Tasks_Priorites from './Tasks&Priorites/Tasks&Priorites';
import Review from './Review/Review';
import IconTick from 'assets/images/icon__tick--blue.svg';
import { PreparePath } from 'utils/sharedUtils/sharedConst';
import { SharedIcon } from 'components/SharedIcon/SharedIcon';

//Old PlanMeeting BackupRoute Start
import PlanMeetings from './PlanMeetings/BkPlanMeetings';
import Network from 'PREPAREsrc/service/Network';
import API from 'PREPAREsrc/service/api';
import { endLoading, startLoading } from 'reducers/loading/loading.action';

//Old PlanMeeting BackupRoute End

const getRoutePath = (location, params) => {
  const { pathname } = location;

  if (!Object.keys(params).length) {
    return pathname;
  }

  let path = pathname;
  Object.entries(params).forEach(([paramName, paramValue]) => {
    if (paramValue) {
      path = path.replace(paramValue, `:${paramName}`);
    }
  });
  return path;
};

const stepPages = [
  ExerciseDetails,
  PlanMeetings,
  FundingSources,
  Objectives,
  Tasks_Priorites,
  Review,
];

export default function ExerciseDetailsStepForm() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const params = useParams();
  const path = getRoutePath(location, params);
  const [step, setStep] = useState((location.state?.step || 1) - 1);

  const [exerciseDetailsName, setExerciseDetailsName] = useState('');

  const reduxUser = useSelector((state) => state.prepare.user);

  const { selectedWorkspace, workspaceName } = useSelector(
    (state) => state.prepare.workspace
  );
  const exerciseName = useSelector(
    (state) => state.prepare.exercise.exerciseDetailName
  );
  const workspaceType = useSelector((state) => state.prepare.workspace.type);

  const getAllExerciseDetails = async () => {
    dispatch(startLoading());

    if (selectedWorkspace) {
      try {
        Network.get(API.planExerciseTypeList, {
          workspaceId: selectedWorkspace,
        })
          .then((resp) => {
            setExerciseDetailsName(resp.data.response.dataset[0]);
          })
          .catch(console.log)
          .finally(() => {
            dispatch(endLoading());
          });
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    getAllExerciseDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reduxUser]);

  const currentPage =
    path === '/dashboard/plan/prepare/all-details' ? 'Exercise' : null;
  const nextPage = (stepCount) => {
    setStep(stepCount);
    setSteps(
      steps.map((step, index) => {
        if (index === stepCount) {
          return {
            ...step,
            isValid: true,
          };
        } else {
          return { ...step, isValid: false };
        }
      })
    );
  };

  const [steps, setSteps] = useState([
    {
      label: 'Exercise Details',
      isValid: true,
      component: (
        <ExerciseDetails onNextClick={nextPage} onPrevClick={nextPage} />
      ),
      className: 'step-item col-4 col-md-2 mb-3 mb-md-0',
    },
    {
      label: 'Plan Meetings',
      isValid: false,
      component: (
        <PlanMeetings
          onNextClick={nextPage}
          onPrevClick={nextPage}
          exerciseDetailName={exerciseDetailsName}
        />
      ),
      className: 'step-item col-4 col-md-2 mb-3 mb-md-0',
    },
    {
      label: 'Funding Sources',
      isValid: false,
      component: (
        <FundingSources
          onNextClick={nextPage}
          onPrevClick={nextPage}
          exerciseDetailName={exerciseDetailsName}
        />
      ),
      className: 'step-item col-4 col-md-2 mb-3 mb-md-0',
    },
    {
      label: 'Objectives',
      isValid: false,
      component: (
        <Objectives
          onNextClick={nextPage}
          onPrevClick={nextPage}
          exerciseDetailName={exerciseDetailsName}
        />
      ),
      className: 'step-item col-4 col-md-2',
    },
    {
      label: 'Tasks&Priorites',
      isValid: false,
      component: (
        <Tasks_Priorites
          onNextClick={nextPage}
          onPrevClick={nextPage}
          exerciseDetailName={exerciseDetailsName}
        />
      ),
      className: 'step-item col-4 col-md-2',
    },
    {
      label: 'Review',
      isValid: false,
      component: <Review onNextClick={nextPage} onPrevClick={nextPage} />,
      className: 'step-item col-4 col-md-2',
    },
  ]);

  const lastStepIndex = steps.length - 1;
  const isLastStep = lastStepIndex === step;
  const isPreviousStepsValid =
    steps
      .slice(0, step)
      .findIndex((currentStep) => currentStep.isValid === false) === -1;

  const renderStepMarker = (index) => {
    return (
      <span className="step-marker">
        {step <= index ? index + 1 : <img src={IconTick} alt="" />}
      </span>
    );
  };

  return (
    <>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <button onClick={() => navigate(-1)}>
              <SharedIcon iconName="keyboard_arrow_left" classes="me-2" />
              Back
            </button>
          </li>
          <li className="breadcrumb-item">
            <Link to={`${PreparePath}/dashboard/plan/prepare/exercise-details`}>
              Exercise
            </Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            {workspaceName || exerciseDetailsName?.eventName || exerciseName}
          </li>
        </ol>
      </nav>
      {workspaceType === 'DOD' ? (
        <div className="steps my-4 my-md-5">
          <div className="row">
            <div
              className={
                steps[0].className +
                (step === 0 ? ' step-current' : ' step-completed')
              }
            >
              <span>
                {renderStepMarker(0)}
                <span className="step-title">Exercise Details</span>
                <span className="step-divider"></span>
              </span>
            </div>
            <div
              className={
                steps[1].className +
                (step === 1
                  ? ' step-current'
                  : step > 1
                  ? ' step-completed'
                  : '')
              }
            >
              <span>
                {renderStepMarker(1)}
                <span className="step-title">Planning Meetings</span>
                <span className="step-divider"></span>
              </span>
            </div>
            <div
              className={
                steps[2].className +
                (step === 2
                  ? ' step-current'
                  : step > 2
                  ? ' step-completed'
                  : '')
              }
            >
              <span>
                {renderStepMarker(2)}
                <span className="step-title">Funding Sources</span>
                <span className="step-divider"></span>
              </span>
            </div>
            <div
              className={
                steps[3].className +
                (step === 3
                  ? ' step-current'
                  : step > 3
                  ? ' step-completed'
                  : '')
              }
            >
              <span>
                {renderStepMarker(3)}
                <span className="step-title">Objectives</span>
                <span className="step-divider"></span>
              </span>
            </div>
            <div
              className={
                steps[4].className +
                (step === 4
                  ? ' step-current'
                  : step > 4
                  ? ' step-completed'
                  : '')
              }
            >
              <span>
                {renderStepMarker(4)}
                <span className="step-title">Tasks & Priorities</span>
                <span className="step-divider"></span>
              </span>
            </div>
            <div
              className={
                steps[5].className +
                (step === 5
                  ? ' step-current'
                  : step > 5
                  ? ' step-completed'
                  : '')
              }
            >
              <span className="step-last-content">
                {renderStepMarker(5)}
                <span className="step-title">Review</span>
              </span>
            </div>
          </div>
        </div>
      ) : (
        <div className="steps my-4 my-md-5">
          <div className="row">
            <div
              className={
                steps[0].className +
                (step === 0 ? ' step-current' : ' step-completed')
              }
            >
              <span>
                {renderStepMarker(0)}
                <span className="step-title">Exercise Details</span>
                <span className="step-divider"></span>
              </span>
            </div>
            <div
              className={
                steps[1].className +
                (step === 1
                  ? ' step-current'
                  : step > 1
                  ? ' step-completed'
                  : '')
              }
            >
              <span>
                {renderStepMarker(1)}
                <span className="step-title">Planning Meetings</span>
                <span className="step-divider"></span>
              </span>
            </div>
            <div
              className={
                steps[2].className +
                (step === 2
                  ? ' step-current'
                  : step > 2
                  ? ' step-completed'
                  : '')
              }
            >
              <span>
                {renderStepMarker(2)}
                <span className="step-title">Funding Sources</span>
                <span className="step-divider"></span>
              </span>
            </div>
            <div
              className={
                steps[3].className +
                (step === 3
                  ? ' step-current'
                  : step > 3
                  ? ' step-completed'
                  : '')
              }
            >
              <span>
                {renderStepMarker(3)}
                <span className="step-title">Targets</span>
                <span className="step-divider"></span>
              </span>
            </div>
            <div
              className={
                steps[4].className +
                (step === 4
                  ? ' step-current'
                  : step > 4
                  ? ' step-completed'
                  : '')
              }
            >
              <span>
                {renderStepMarker(4)}
                <span className="step-title">Core Capabilities</span>
                <span className="step-divider"></span>
              </span>
            </div>
            <div
              className={
                steps[5].className +
                (step === 5
                  ? ' step-current'
                  : step > 5
                  ? ' step-completed'
                  : '')
              }
            >
              <span className="step-last-content">
                {renderStepMarker(5)}
                <span className="step-title">Review</span>
              </span>
            </div>
          </div>
        </div>
      )}

      {steps[step].component}
    </>
  );
}
