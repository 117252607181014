import React from 'react';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import Network from '../../../../service/Network';
import API from '../../../../service/api';
import moment from 'moment';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import Dropdown from 'react-bootstrap/Dropdown';
import IconEmpty from 'assets/images/icon__exercise-details-empty.svg';
import { HelpEvents, MiscellaneousHelpLink, useSelfHostedKnowledgeBase } from 'assets/data/HelpContent';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { PreparePath } from 'utils/sharedUtils/sharedConst';
import StylishNewSelect from 'components/DesignSystems/New/StylishNewSelect';
import { StylishNewSearchBar } from 'components/DesignSystems/New/StylishNewSearchBar';
import StylishHelp from 'components/DesignSystems/New/StylishHelp';
import { toast } from 'react-toastify';
import { toastConfig } from 'assets/data/config';
import { StylishConfirmDialog } from 'components/DesignSystems/New/StylishConfirmDialog';
import {
  endLoading,
  startLoading,
} from '../../../../../reducers/loading/loading.action';
import PageTitle from 'components/headers/PageTitle';
import { useNewUIFlag } from 'utils/featureFlags';
import { SharedIcon } from 'components/SharedIcon/SharedIcon';

export const Event = () => {
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortBy, setSortBy] = useState('name');
  const [viewEvents, setViewEvent] = useState([]);
  const [totalEvent, setTotalEvent] = useState(0);
  const [deleteEvent, setDeleteEvent] = useState(null);

  const selfHostedKnowledgeBase = useSelfHostedKnowledgeBase()
  const perPage = 15;

  const { control } = useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    selectedWorkspace,
    timezone,
    timezoneOffset,
    type: workspaceType,
  } = useSelector((state) => state.prepare.workspace);
  const reduxUser = useSelector((state) => state.prepare.user);

  const newUI = useNewUIFlag();

  const sortEvent = [
    { value: 'name', label: 'Name' },
    { value: 'startDate_DESC', label: 'Latest Start Date' },
    { value: 'startDate_ASC', label: 'Earliest Start Date' },
  ];
  useEffect(() => {
    if (!!workspaceType && workspaceType === 'T&E') {
      navigate(`${PreparePath}/dashboard/plan`);
    }
  }, [workspaceType]);

  const onAddEvents = () => {
    navigate(`${PreparePath}/dashboard/plan/prepare/event/new`);
    navigate(`${PreparePath}/dashboard/plan/prepare/event/new`);
  };

  const onChangeSearchTerm = (termSearched) => {
    setPage(1);
    setSearchTerm(termSearched);
  };

  const onDeleteEvent = (row) => {
    setDeleteEvent(row.id);
  };

  const fetchEvents = () => {
    dispatch(startLoading());
    try {
      Network.get(API.fetchEvents, {
        limit: perPage,
        page: page,
        term: searchTerm,
        workspaceId: selectedWorkspace,
        sort: sortBy,
      })
        .then((resp) => {
          setTotalEvent(resp.data.response.total);
          setViewEvent(resp.data.response.dataset);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          dispatch(endLoading());
        });
    } catch (error) {}
  };

  const onDeleteConfirm = async () => {
    dispatch(startLoading());
    try {
      await Network.post(API.deleteEvent, { id: deleteEvent });
      toast.success('Scenario deleted successfully', toastConfig);

      await fetchEvents();
      setDeleteEvent(null);
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(endLoading());
    }
  };

  useEffect(() => {
    fetchEvents();
  }, [page, perPage, searchTerm, selectedWorkspace, sortBy]);

  const handlePageClick = (event) => {
    setPage(event.selected + 1);
  };

  return (
    <>
      {newUI ? (
        <PageTitle
          title="Scenarios"
          parents={['Exercise Management', 'Design']}
        />
      ) : (
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <StylishNewButton onClick={() => navigate(-1)}>
                <SharedIcon iconName="keyboard_arrow_left" classes="me-2" />
                Back
              </StylishNewButton>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Scenarios
            </li>
          </ol>
        </nav>
      )}

      <div className="plan-prepare__events">
        <div className="row">
          <div className="col-md-12">
            <div className="titlebar d-flex flex-wrap align-items-center mb-3">
              <StylishNewButton
                className="button button--primary"
                onClick={onAddEvents}
              >
                Create Scenario
              </StylishNewButton>
              <p className="m-0 me-3 ms-auto text-nowrap">
                {totalEvent > 0 && totalEvent + ' Scenarios'}
              </p>
              <div className="flex-grow-1 flex-md-grow-0 mt-3 mt-md-0 me-3">
                <Controller
                  control={control}
                  name="sortEventBy"
                  rules={{ required: false }}
                  render={({ field: { value } }) => (
                    <StylishNewSelect
                      options={sortEvent}
                      defaultValue={sortEvent[0] || value}
                      value={value}
                      onChange={(e) => {
                        setSortBy(e.value);
                      }}
                      isClearable={false}
                      isSearchable={true}
                      isMulti={false}
                      isDisabled={false}
                    />
                  )}
                />
              </div>
              <div className="button-group toggle me-xl-3 mt-3 mt-md-0">
                <NavLink
                  className="button--icon lrg"
                  to={`${PreparePath}/dashboard/plan/prepare/events-calendar`}
                >
                  <i className="fa fa-calendar" aria-hidden="true"></i>
                </NavLink>
                <NavLink
                  className="button--icon lrg"
                  to={`${PreparePath}/dashboard/plan/prepare/events`}
                >
                  <i className="fa fa-th-large" aria-hidden="true"></i>
                </NavLink>
              </div>
              <div className="w-100 d-block d-xl-none"></div>
              <StylishNewSearchBar
                onChangeSearchTerm={onChangeSearchTerm}
                // className="flex-grow-1"
              />
              <StylishHelp
                classes={'ms-3 sml'}
                title={HelpEvents.title}
                content={HelpEvents.content}
                link={selfHostedKnowledgeBase || HelpEvents.link}
              />
            </div>
          </div>
        </div>
        {viewEvents.length ? (
          <>
            <div className="row">
              {viewEvents.map((item) => (
                <div
                  className="col-md-6 col-lg-4 col-xl-3 pb-4 mt-2"
                  key={item.id}
                >
                  <div
                    className="section__event-item bg-gray-800 rounded p-3 cursor-pointer"
                    onClick={() =>
                      navigate(
                        `${PreparePath}/dashboard/plan/prepare/event/view/${item.id}`
                      )
                    }
                  >
                    <div className="d-flex align-item-center mb-4">
                      <div className="d-flex align-items-center">
                        {!!item?.color && (
                          <span
                            className="color me-2 flex-shrink-0"
                            style={{ background: item.color }}
                          ></span>
                        )}
                        <h4 className="m-0 text-break me-1">{item.name}</h4>
                      </div>
                      <Dropdown
                        className="flex-shrink-0 ms-auto"
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        <Dropdown.Toggle className="button--icon">
                          <i
                            className="fa fa-ellipsis-v"
                            aria-hidden="true"
                          ></i>
                        </Dropdown.Toggle>
                        <Dropdown.Menu align="end">
                          <Link
                            className="dropdown-item"
                            to={`${PreparePath}/dashboard/plan/prepare/event/edit/${item.id}`}
                          >
                            <SharedIcon iconName="stylus" />
                            Edit Scenario
                          </Link>
                          <Link
                            className="dropdown-item"
                            to={`${PreparePath}/dashboard/plan/prepare/event/view/${item.id}`}
                          >
                            <SharedIcon iconName="groups" />
                            View Scenario
                          </Link>
                          <Dropdown.Item onClick={() => onDeleteEvent(item)}>
                            <SharedIcon iconName="delete" />
                            Delete Scenario
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                    {(!!item?.startDate || !!item?.endDate) && (
                      <p className="mb-1">
                        {moment(item?.startDate)
                          .utcOffset(timezoneOffset)
                          .format(
                            reduxUser?.timeFormat === 'hour12Mode'
                              ? 'YYYY-MM-DD - hh:mm a'
                              : 'YYYY-MM-DD - HH:mm'
                          )}{' '}
                        ({timezone.split('/')[0]})<span> - </span>
                        {moment(item?.endDate)
                          .utcOffset(timezoneOffset)
                          .format(
                            reduxUser?.timeFormat === 'hour12Mode'
                              ? 'YYYY-MM-DD - hh:mm a'
                              : 'YYYY-MM-DD - HH:mm'
                          )}{' '}
                        ({timezone.split('/')[0]})
                      </p>
                    )}
                    {!!item?.overall_method && (
                      <p className="mb-1">
                        {item.overall_method.charAt(0).toUpperCase() +
                          item.overall_method.slice(1)}
                      </p>
                    )}
                    <p className="m-0 mt-3 weight-400">
                      {item.injectCount} MSEL Injects
                    </p>
                  </div>
                </div>
              ))}
            </div>
            {totalEvent > perPage && (
              <div className="d-flex justify-content-center justify-content-md-end mt-2 mt-xl-0">
                <ReactPaginate
                  className="pagination"
                  breakLabel="..."
                  nextLabel="Next"
                  onPageChange={handlePageClick}
                  pageCount={Math.ceil(totalEvent / perPage)}
                  previousLabel="Prev"
                />
              </div>
            )}
            {deleteEvent && (
              <StylishConfirmDialog
                show={deleteEvent}
                onClose={() => setDeleteEvent(null)}
                dialogType={'Delete'}
                dialogTitle={'Delete Scenario'}
                dialogContent={'Are you sure you want this?'}
                updateDialogData={deleteEvent}
                onConfirm={onDeleteConfirm}
              />
            )}
          </>
        ) : (
          <>
            <div className="bg-gray-800 text-center p-5 mt-2">
              <div className="py-0 py-md-4">
                <div className="d-flex justify-content-center mb-4">
                  <img src={IconEmpty} alt="" />
                </div>
                <p className="weight-600 mb-4">
                  A fresh start. Create the first entry.
                  <a href={selfHostedKnowledgeBase || MiscellaneousHelpLink.link} target="_blank">
                    {' '}
                    Need more help?
                  </a>
                </p>
                <StylishNewButton
                  className="button button--primary w-100 w-md-auto mb-3 mb-md-0"
                  onClick={onAddEvents}
                >
                  Create Scenario
                </StylishNewButton>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};
