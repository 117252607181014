import React, { useEffect, useState } from 'react';
import { AllIcon } from './Icon';
import IconNotAllowed from 'assets/images/sample-icon__not-allowed.svg';
import IconClose from 'assets/images/icon__times.svg';
import SearchBar from '../../../../PREPAREsrc/component/SearchBar/SearchBar';

export default function StylishIconSelected({ onIconChange, selectedIcon }) {
  const [showIcons, setShowIcons] = useState(false);
  const [allIcons, setAllIcons] = useState([]);
  const [term, setTerm] = useState('');
  // const [selectedIcon, setSelectedIcon] = useState();

  useEffect(() => {
    const newIcons = AllIcon.filter((icon) =>
      icon.name.toLocaleLowerCase().match(term.toLocaleLowerCase())
    );
    setAllIcons(newIcons);
  }, [term]);

  const onShowSearch = () => {
    setTerm('');
    setShowIcons(!showIcons);
  };

  return (
    <>
      <span className="badge badge--with-remove d-flex align-item-center weight-500">
        <span className="icon flex-grow-1" onClick={onShowSearch}>
          {!!selectedIcon ? (
            <>
              {selectedIcon.icon} {selectedIcon.name}
            </>
          ) : (
            <>
              <img src={IconNotAllowed} alt="" className="me-3" />
              Select an Icon
            </>
          )}
        </span>
        {!!selectedIcon ? (
          <button
            type="button"
            className={'flex-shrink-0'}
            onClick={() => onIconChange(null)}
          >
            <img src={IconClose} alt="" />
          </button>
        ) : null}
      </span>
      {showIcons && (
        <div className="my-3">
          <div className="mb-3">
            <SearchBar onChangeSearchTerm={setTerm} />
          </div>
          <ul className="nav nav__form-icons">
            {allIcons.map((icon) => {
              return (
                <li
                  onClick={() => {
                    onIconChange(icon);
                    // setSelectedIcon(icon);
                    setShowIcons(false);
                  }}
                  key={icon.name}
                >
                  {icon.icon}
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </>
  );
}
