import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import GroupMembers from 'components/Roles/GroupMembers';
import TeamRosters from './TeamRosters';
import GroupRoles from 'components/Roles/GroupRoles';
import GroupIncidents from './GroupIncidents';
import GroupWorkspaceUpdated from './GroupWorkspaceUpdated';
import HVAs from 'components/HVA/HVAs';
import RiskLevelsView from 'components/MapComponent/AlertPrefsViews/RiskLevelsView';
import { fetchAllDistributionList } from 'actions/notificationActions';
import { fetchRostersForGroups } from 'actions/roleActions';
import GroupAlerts from './GroupAlerts';
import ManageLocation from './ManageLocation';
import GroupAIUsage from './GroupAIUsage'
import ICS from 'components/ICS/ICS'

export default function GroupConfigureSection({ tableView }) {
  const reduxDispatch = useDispatch();

  const reduxRostersForGroups = useSelector((state) => {
    return state.app.rostersForGroups;
  });

  const isFetchRostersForGroupsLoaded = useSelector((state) => {
    return state.app.isFetchRostersForGroupsLoaded;
  });

  const { user_guid } = useSelector((state) => {
    return state.app.user;
  });

  const reduxCurrentlySelectedGroup = useSelector((state) => {
    return state.app.currentlySelectedGroup;
  });
  const reduxFeatureFlags = useSelector((state) => {
    return state.app.featureFlags;
  });
  useEffect(() => {
    if (
      typeof user_guid !== 'undefined' &&
      !reduxRostersForGroups &&
      isFetchRostersForGroupsLoaded &&
      reduxCurrentlySelectedGroup
    ) {
      reduxDispatch(
        fetchRostersForGroups([reduxCurrentlySelectedGroup.group_guid])
      );
    }

    if (reduxCurrentlySelectedGroup) {
      reduxDispatch(
        fetchAllDistributionList(reduxCurrentlySelectedGroup.group_guid)
      );
    }
  }, [user_guid, reduxCurrentlySelectedGroup]);

  return (
    <>
      {reduxCurrentlySelectedGroup && (
        <>
          {tableView === 'Members' && <GroupMembers />}
          {tableView === 'Team Rosters' && <TeamRosters />}
          {tableView === 'Roles' && <GroupRoles />}
          {tableView === 'Incidents' && <GroupIncidents />}
          {tableView === 'Events' ? (
              <GroupWorkspaceUpdated />
          ) : null}

          {tableView === 'HVAs' && <HVAs />}
          {tableView === 'Risk Levels' && <RiskLevelsView />}
          {tableView === 'Alerts' && <GroupAlerts />}
          {tableView === 'Areas of Responsibility' && <ManageLocation />}
          {tableView === 'AI Usage' && <GroupAIUsage />}
          {tableView === "ICS" && <ICS />}
        </>
      )}
    </>
  );
}
