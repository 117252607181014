import { useSelector } from 'react-redux';

export const useFeatureFlag = (flag) => {
  const flags = useSelector(s => s.app.featureFlags);
  return flags.includes(flag);
};

export const useNewUIFlag = () => {
  return useFeatureFlag('NEW_UI');
};

export const useFullIAPFlag = () => {
  return useFeatureFlag('FULL_INCIDENT_MANAGEMENT_LANDING_PAGE');
};
