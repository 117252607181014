import React from 'react';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { PreparePath } from 'utils/sharedUtils/sharedConst';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useEffect, useState } from 'react';
import { Details } from './Details/Details';
import { TestObjectives } from './TestObjectives/TestObjectives';
import { EventCells } from './EventCells/EventCells';
import { EventCalendar } from './EventCalendar/EventCalendar';
import { POAM } from './POA&M/POAM';
import { DataCollection } from './DataCollection/DataCollection';
import { Funding } from './Funding/Funding';
import moment from 'moment';
import StylishNewSelect from 'components/DesignSystems/New/StylishNewSelect';
import Network from 'PREPAREsrc/service/Network';
import API from 'PREPAREsrc/service/api';
import { toast } from 'react-toastify';
import { toastConfig } from 'assets/data/config';
import { endLoading, startLoading } from 'reducers/loading/loading.action';
import { selectedEventId } from 'PREPAREsrc/store/PlanDashboard/PlanDashboard.action';
import { SharedIcon } from 'components/SharedIcon/SharedIcon';

const tabs = [
  { label: 'Details', value: 'details' },
  { label: 'Test Objectives', value: 'test_objectives' },
  { label: 'Event Cells', value: 'event_cells' },
  { label: 'Event Calendar', value: 'event_calendar' },
  { label: 'POA&M', value: 'poam' },
  { label: 'Data Collection', value: 'data_collection' },
  { label: 'Funding', value: 'funding' },
];

export const ExperimentEventDetails = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { selectedWorkspace, type: workspaceType } = useSelector(
    (state) => state?.prepare?.workspace
  );
  const [detailsData, setDetailsData] = useState();
  const [key, setKey] = useState(tabs[0]?.value);
  const [eventData, setEventData] = useState({
    name: null,
    startDate: null,
    endDate: null,
    primaryHost: null,
    secondaryHost: null,
  });

  const planDashboard = async () => {
    dispatch(startLoading());
    if (selectedWorkspace) {
      try {
        const resp = await Network.get(API.planDashhboard, {
          workspaceId: selectedWorkspace,
          startDate: moment().clone().startOf('month').toISOString(),
          endDate: moment().toISOString(),
        });
        if (resp !== undefined && resp?.status === 200) {
          dispatch(selectedEventId(resp?.data?.response?.eventDetails?.id));
          fetchSingleEvent(resp?.data?.response?.eventDetails?.id);
        }
      } catch (error) {
        toast.error(error?.response?.data?.response?.status?.msg, toastConfig);
      } finally {
        dispatch(endLoading());
      }
    }
  };

  const fetchSingleEvent = async (eventId) => {
    const data = {
      id: eventId,
      workspaceId: selectedWorkspace,
    };
    dispatch(startLoading());
    try {
      const response = await Network.get(API.fetchSingleEvent, data);
      if (response !== undefined && response?.status === 200) {
        setDetailsData(response?.data?.response?.dataset[0]);
        setEventData({
          name: response?.data?.response?.dataset[0]?.name,
          startDate: response?.data?.response?.dataset[0]?.startDate,
          endDate: response?.data?.response?.dataset[0]?.endDate,
          primaryHost: response?.data?.response?.dataset[0]?.primary_host,
          secondaryHost: response?.data?.response?.dataset[0]?.secondary_host,
        });
      }
    } catch (error) {
      toast.error(error?.response?.data?.response?.status?.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };

  useEffect(() => {
    if (workspaceType && workspaceType !== 'T&E') {
      navigate(`${PreparePath}/dashboard/plan`);
    }
  }, [workspaceType]);

  useEffect(() => {
    planDashboard();
  }, [selectedWorkspace]);

  return (
    <>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <StylishNewButton
              onClick={() => navigate(`${PreparePath}/dashboard/plan`)}
            >
              <SharedIcon iconName="keyboard_arrow_left" classes="me-2" />
              Back
            </StylishNewButton>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Experimental Event Details
          </li>
        </ol>
      </nav>
      <div className="mb-4">
        <h4 className="mb-2">
          {eventData?.name || null} Experimental Event Details
        </h4>
        <p className="m-0">
          <span className="weight-700">Event Dates - </span>
          {(eventData?.startDate && eventData?.endDate && (
            <>
              {moment(eventData?.startDate).format('DD MMM YY') || ''}
              {` - ${moment(eventData?.endDate).format('DD MMM YY') || ''}`}
            </>
          )) ||
            'N/A'}
        </p>
        <p className="m-0">
          <span className="weight-700">Host - </span>
          {(eventData?.primaryHost && eventData?.secondaryHost && (
            <>
              {eventData?.primaryHost || ''}
              {` - ${eventData?.secondaryHost || ''}`}
            </>
          )) ||
            'N/A'}
        </p>
      </div>
      {(tabs && !!tabs?.length && (
        <div className="tab-wrapper responsive switch-lg">
          <StylishNewSelect
            options={tabs}
            value={tabs?.find((tab) => tab?.value === key)}
            onChange={(e) => setKey(e?.value)}
            isClearable={false}
            isMulti={false}
            isSearchable={false}
            placeholder={'Choose a Tab'}
          />
          <Tabs activeKey={key} onSelect={(k) => setKey(k)}>
            {tabs?.map((tab) => (
              <Tab key={tab?.value} eventKey={tab?.value} title={tab?.label}>
                {tab?.label === 'Details' ? (
                  <Details
                    detailsData={detailsData}
                    selectedTab={key}
                    fetchSingleEvent={fetchSingleEvent}
                  />
                ) : tab?.label === 'Test Objectives' ? (
                  <TestObjectives selectedTab={key} />
                ) : tab?.label === 'Event Cells' ? (
                  <EventCells selectedTab={key} />
                ) : tab?.label === 'Event Calendar' ? (
                  <EventCalendar selectedTab={key} />
                ) : tab?.label === 'POA&M' ? (
                  <POAM selectedTab={key} />
                ) : tab?.label === 'Data Collection' ? (
                  <DataCollection
                    changeTab={key === 'data_collection' ? key : null}
                  />
                ) : tab?.label === 'Funding' ? (
                  <Funding changeTab={key === 'funding' ? key : null} />
                ) : null}
              </Tab>
            ))}
          </Tabs>
        </div>
      )) ||
        null}
    </>
  );
};
