import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import API from '../../../service/api';
import Network from '../../../service/Network';
import { toast } from 'react-toastify';
import SectionDetails from './SectionDetails';
import EditPage from './EditPage';
import { Dropdown, Form } from 'react-bootstrap';
import CustomModal from 'components/DesignSystems/New/CustomModal';
import UpdateNotebook from './UpdateNotebook';
import { SharedIcon } from 'components/SharedIcon/SharedIcon';
import { inputStringLength, toastConfig } from 'assets/data/config';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { PreparePath } from 'utils/sharedUtils/sharedConst';
import { StylishConfirmDialog } from 'components/DesignSystems/New/StylishConfirmDialog';
import {
  endLoading,
  startLoading,
} from '../../../../reducers/loading/loading.action';
import isRoleReadOnly from 'PREPAREsrc/utils/isRoleReadOnly';

export default function NotebookDetails() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [createdPageId, setCreatedPageId] = useState();
  const [modalButtonDisabled, setModalButtonDisabled] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showUpdateNotebookModal, setShowUpdateNotebookModal] = useState(false);
  const [sections, setSections] = useState([
    {
      sectionId: 's1',
      title: 'Untitled Section',
      pages: [
        {
          pageId: 's1p1',
          pageTitle: 'Untitled Page',
          text: '',
        },
      ],
    },
  ]);

  const [noteBook, setNotebook] = useState({
    notebookId: 'xyx',
    notebookTitle: 'UNTITELED NOTEBOOK',
    notebookColor: '#FBC02D',
    notebookIcon: 'Accusoft',
  });

  const [selectedPage, setSelectedPage] = useState({
    pageId: '',
    pageTitle: '',
    text: null,
  });
  const [isEdit, setIsEdit] = useState(false);
  const [modalData, setModalData] = useState(false);
  const [modalValue, setModalValue] = useState('');

  const modalFor = useRef({
    modalFor: '',
    index: null,
    sectionId: null,
  });

  const { selectedWorkspace } = useSelector((state) => state.prepare.workspace);
  const reduxUser = useSelector((state) => state.prepare.user);

  const { id } = useParams();

  const navigate = useNavigate();

  const location = useLocation();

  const state = new URLSearchParams(location.search).get('state');

  const dispatch = useDispatch();

  const deleteSectionId = useRef('');

  const deleteModalMessage = useRef('');

  useEffect(() => {
    if (state === 'New') {
      setIsEdit(true);
    } else {
      setIsEdit(false);
    }
    fetchNotebook();
  }, [reduxUser]);

  const fetchNotebook = async (afterOperationSelectedPage = null) => {
    dispatch(startLoading());
    try {
      const book = await Network.get(API.notebookDetailsByNotebookId, {
        notebookId: id,
      });

      if (book !== undefined && book.status == 200) {
        const res = {
          notebookId: book?.data.response?.notebookId,
          notebookTitle: book?.data.response?.notebookTitle,
          notebookColor: book?.data.response?.notebookColor,
          notebookIcon: book?.data.response?.notebookIcon,
        };
        setNotebook(res);
        const sectionsData = [...book?.data.response?.dataset];
        setSections(sectionsData);
      }
      // if (!selectedPage.pageId) {
      //   setSelectedPage({
      //     pageId: book?.data?.response?.dataset[0]?.pages[0].pageId,
      //     pageTitle: book?.data?.response?.dataset[0]?.pages[0].pageTitle,
      //     text: book?.data?.response?.dataset[0]?.pages[0].text,
      //   });
      // }

      if (!!afterOperationSelectedPage) {
        return;
      }
      if (deleteSectionId.current !== '') {
        const sec = book?.data?.response.dataset.find(
          (data) => data.sectionId === deleteSectionId.current
        );
        deleteSectionId.current = '';
        if (sec && sec.pages.length) {
          setSelectedPage({
            pageId: sec?.pages[0].pageId,
            pageTitle: sec?.pages[0].pageTitle,
            text: sec?.pages[0].text,
          });
          return;
        }
      }
      const sec = book?.data?.response.dataset.find(
        (data) => data.pages.length
      );
      if (sec) {
        deleteSectionId.current = sec.sectionId;
        setSelectedPage({
          pageId: sec?.pages[0].pageId,
          pageTitle: sec?.pages[0].pageTitle,
          text: sec?.pages[0].text,
        });
      } else {
        setSelectedPage({
          pageId: '',
          pageTitle: '',
          text: null,
        });
      }
    } catch (error) {
      toast.error(error, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };

  const changeEdit = () => {
    setIsEdit((prevState) => !prevState);
  };

  const toggleClick = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const changePage = (sectionIdex, pageIndex) => {
    const data = [...sections];

    setSelectedPage({ ...data[sectionIdex].pages[pageIndex] });
    setIsSidebarOpen(false);
  };
  const changePageTitle = (e) => {
    setSelectedPage((prevData) => {
      return {
        ...prevData,
        pageTitle: e.target.value,
      };
    });
  };

  const onChangeText = (content) => {
    setSelectedPage((prevState) => {
      return {
        ...prevState,
        text: content,
      };
    });
  };

  const onAddPageClick = async (sectionId, idx) => {
    const newPage = {
      title: 'UNTITLED PAGE',
      text: '',
      sectionId: sectionId,
      workspaceId: selectedWorkspace,
    };
    dispatch(startLoading());

    try {
      const createNewPage = await Network.post(API.createNotepage, {
        ...newPage,
      });
      setCreatedPageId(createNewPage?.data?.response?.dataset[0]?.id);
      setSelectedPage({
        pageId: createNewPage?.data?.response?.dataset[0]?.id,
        pageTitle: createNewPage?.data?.response?.dataset[0]?.title,
        text: createNewPage?.data?.response?.dataset[0]?.text,
      });
      if (createNewPage !== undefined && createNewPage.status === 200) {
        toast.success('Page created successfully', toastConfig);
        deleteSectionId.current = sectionId;
        fetchNotebook({
          pageId: createNewPage?.data?.response?.dataset[0]?.id,
          pageTitle: createNewPage?.data?.response?.dataset[0]?.title,
          text: createNewPage?.data?.response?.dataset[0]?.text,
        });
      }
    } catch (error) {
      toast.error('Something went wrong', toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };

  const onAddSectionClick = async () => {
    if (modalValue?.length === 0) return;
    setModalButtonDisabled(true);
    dispatch(startLoading());

    try {
      const createSection = await Network.post(API.createNotesection, {
        title: modalValue,
        notebookId: noteBook.notebookId,
        workspaceId: selectedWorkspace,
      });

      if (createSection !== undefined && createSection.status === 200) {
        fetchNotebook();
      }
    } catch (error) {
      toast.error('Something went wrong', toastConfig);
    } finally {
      dispatch(endLoading());

      setModalButtonDisabled(false);
      setModalData(false);
      setModalValue('');
    }
  };

  const updateSectionTitle = async () => {
    if (modalValue.length === 0) {
      return;
    }
    dispatch(startLoading());

    try {
      const updateSection = await Network.post(API.updateNotesection, {
        title: modalValue,
        sectionId: modalFor.current.sectionId,
      });

      if (updateSection !== undefined) {
        let data = [...sections];
        data[modalFor.current.index].title = modalValue;

        setSections(data);
      }
    } catch (error) {
      toast.error('Something went wrong', toastConfig);
    } finally {
      modalFor.current = {
        modalFor: '',
        index: null,
        sectionId: null,
      };
      setModalData(false);
      setModalValue('');
      dispatch(endLoading());
    }
  };

  const openModal = (openFor, section, idx) => {
    if (section !== undefined) {
      modalFor.current = {
        modalFor: openFor,
        index: idx,
        sectionId: section.sectionId,
      };
    } else {
      modalFor.current = {
        modalFor: openFor,
        index: null,
        sectionId: null,
      };
    }
    setModalValue(section !== undefined ? section.title : 'Untitled Section');
    setModalData(true);
  };

  const onModalConfirm = () => {
    if (modalFor.current.modalFor === 'AddSection') {
      onAddSectionClick();
      return;
    }
    if (modalFor.current.modalFor === 'editSectionTitle') {
      updateSectionTitle();
    }
  };

  const onCloseModal = () => {
    setModalButtonDisabled(false);
    setModalData(false);
    setModalValue('');
    modalFor.current = {
      modalFor: '',
      index: null,
      sectionId: null,
    };
  };

  const modalInputChange = (e) => {
    setModalValue(e.target.value);
  };

  const openDeleteModal = (openModalFor, message) => {
    deleteModalMessage.current = message;
    setModalValue(openModalFor);
    setShowDeleteModal(true);
  };

  const onCloseDeleteModal = () => {
    deleteModalMessage.current = '';
    deleteSectionId.current = '';
    setShowDeleteModal(false);
    setModalValue('');
  };

  const onDeleteModalConform = () => {
    if (modalValue === 'Delete Section') {
      onDeleteSection();
    } else if (modalValue === 'Delete Page') {
      onDeletePage();
    } else {
      onDeleteNotebook();
    }
  };

  const onDeleteSection = async () => {
    dispatch(startLoading());

    try {
      const deleteSectionRes = await Network.post(API.deleteNotesection, {
        sectionId: deleteSectionId.current,
      });
      if (deleteSectionRes !== undefined) {
        setSelectedPage({
          pageId: '',
          pageTitle: '',
          text: null,
        });
        toast.success('Section deleted successfully', toastConfig);
        fetchNotebook();
      }
    } catch (error) {
      toast.error('Something went wrong', toastConfig);
    } finally {
      dispatch(endLoading());

      setShowDeleteModal(false);
      setModalValue('');
      deleteModalMessage.current = '';
    }
  };

  const onDeletePage = async () => {
    dispatch(startLoading());
    try {
      const deletePageRes = await Network.post(API.deleteNotepage, {
        pageId: selectedPage?.pageId,
      });

      // if (deletePageRes !== undefined) {
      //   if (sections?.length) {
      //     console.log('sections', sections);
      //     if (sections[0].pages?.length) {
      //       console.log('sections[0]', sections[0]);
      //       setSelectedPage({
      //         pageId: sections[0].pages[0].pageId,
      //         pageTitle: sections[0].pages[0].pageTitle,
      //         text: sections[0].pages[0].text,
      //       });
      //     }
      //   }
      //   toast.success('Page deleted successfully');
      //   fetchNotebook(selectedPage);
      // }
      toast.success('Page deleted successfully', toastConfig);
      fetchNotebook();
    } catch (error) {
      toast.error('Something went wrong', toastConfig);
    } finally {
      dispatch(endLoading());

      setShowDeleteModal(false);
      setModalValue('');
      deleteModalMessage.current = '';
    }
  };

  const onDeleteNotebook = async () => {
    dispatch(startLoading());

    try {
      const deleteNotebookRes = await Network.post(API.deleteNotebook, {
        notebookId: noteBook.notebookId,
      });
      if (deleteNotebookRes !== undefined) {
        toast.success('Notebook deleted successfully', toastConfig);
        navigate(`${PreparePath}/dashboard/notebook/notebooks`);
      }
    } catch (error) {
      toast.error('Something went wrong', toastConfig);
    } finally {
      setShowDeleteModal(false);
      setModalValue('');
      deleteModalMessage.current = '';
      dispatch(endLoading());
    }
  };

  const saveData = async (isClose) => {
    if (sections?.length == 0) {
      setIsEdit(false);
      return;
    }
    if (selectedPage?.pageTitle.length === 0) {
      return;
    }

    dispatch(startLoading());

    try {
      const updateNotePage = await Network.post(API.updateNotepage, {
        title: selectedPage?.pageTitle,
        text: selectedPage?.text,
        pageId: selectedPage?.pageId,
      });
      if (updateNotePage !== undefined) {
        fetchNotebook(selectedPage);
      }
    } catch (error) {
      console.error(error);
      toast.error('Something went wrong saving data', toastConfig);
    } finally {
      if (isClose) {
        setIsEdit(false);
      }
      dispatch(endLoading());
    }
  };

  const OnNotebookUpdate = () => {
    setShowUpdateNotebookModal(false);
    fetchNotebook();
  };

  return (
    <>
      {showUpdateNotebookModal ? (
        <UpdateNotebook
          show={showUpdateNotebookModal}
          onClose={() => setShowUpdateNotebookModal(false)}
          notebook={noteBook}
          onSuccess={OnNotebookUpdate}
        />
      ) : (
        <></>
      )}
      {showDeleteModal && (
        <StylishConfirmDialog
          show={showDeleteModal}
          onClose={onCloseDeleteModal}
          dialogTitle={modalValue}
          dialogContent={deleteModalMessage.current}
          dialogContentType="html"
          //buttonDisable={modalButtonDisabled}
          onConfirm={onDeleteModalConform}
        />
      )}
      <CustomModal
        show={modalData}
        heading="Section Title"
        onClose={onCloseModal}
        onConfirm={onModalConfirm}
        saveButton="save"
        buttonDisable={modalButtonDisabled}
      >
        <Form.Control
          autoFocus={true}
          type="text"
          value={modalValue}
          maxLength={inputStringLength}
          onChange={modalInputChange}
        />{' '}
        {modalValue.length === 0 && (
          <span className="form-text form-error">This field is required </span>
        )}
      </CustomModal>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <button
              onClick={() =>
                navigate(`${PreparePath}/dashboard/notebook/notebooks`)
              }
            >
              <SharedIcon iconName="keyboard_arrow_left" classes="me-2" />
              Back
            </button>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Notebook
          </li>
        </ol>
      </nav>
      <div className="d-flex align-items-center mb-4">
        <h4 className="mb-0 break-word me-4">{noteBook.notebookTitle}</h4>
        <div className="d-flex align-items-center ms-auto">
          {isEdit ? (
            <>
              <button
                type="button"
                className="button--icon primary flex-shrink-0"
                onClick={() => saveData(true)}
              >
                <i className="fa fa-floppy-o" aria-hidden="true"></i>
              </button>
              <Dropdown className="ms-3">
                <Dropdown.Toggle
                  variant="secondary"
                  id="dropdown-basic"
                  className="button--icon primary flex-shrink-0"
                >
                  <SharedIcon iconName="more_vert" bold />
                </Dropdown.Toggle>

                <Dropdown.Menu align="end">
                  <Dropdown.Item
                    onClick={() => setShowUpdateNotebookModal(true)}
                  >
                    <SharedIcon iconName="stylus" />
                    Edit Notebook
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => openModal('AddSection')}>
                    <SharedIcon iconName="add" bold />
                    Add Section
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() =>
                      openDeleteModal(
                        'Delete Notebook',
                        `This will delete all sections and pages out of <span class="break-word weight-600">${noteBook.notebookTitle}</span>.<span class="d-block mt-4 weight-600">Are you sure you want to do this?</span>`
                      )
                    }
                  >
                    <SharedIcon iconName="delete" />
                    Delete Notebook
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </>
          ) : (
            !isRoleReadOnly(reduxUser.roles) && (
              <button
                type="button"
                className="button--icon primary flex-shrink-0"
                onClick={changeEdit}
              >
                <SharedIcon iconName="stylus" />
              </button>
            )
          )}
        </div>
      </div>

      <div className="row">
        <div className="col-xl-3">
          <StylishNewButton
            type="button"
            className={
              'button--primary w-100 button-toggle button-toggle__sidebar mb-3 ' +
              (isSidebarOpen ? 'show' : 'hide')
            }
            onClick={toggleClick}
          >
            All Notes
            <i className="fa fa-chevron-down ms-auto" aria-hidden="true"></i>
          </StylishNewButton>
          <div
            className={
              'site-sidebar h-xl-100 ' + (isSidebarOpen ? 'show' : 'hide')
            }
          >
            {sections?.length > 0 ? (
              <>
                {sections.map((section, idx) => (
                  <SectionDetails
                    key={idx}
                    section={section}
                    selectedPage={selectedPage}
                    changePage={(pageIndex) => {
                      deleteSectionId.current = section.sectionId;
                      changePage(idx, pageIndex);
                    }}
                    isEdit={isEdit}
                    onAddPageClick={() =>
                      onAddPageClick(section.sectionId, idx)
                    }
                    onAddSectionClick={() => openModal('AddSection')}
                    onEdidSectionTitle={() =>
                      openModal('editSectionTitle', section, idx)
                    }
                    onDeleteSectionClick={() => {
                      deleteSectionId.current = section.sectionId;
                      openDeleteModal(
                        'Delete Section',
                        'Are you sure you want to do this?'
                      );
                    }}
                  />
                ))}
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
        {selectedPage?.pageId !== '' ? (
          <div className="col-xl-9 mt-4 mt-xl-0">
            <EditPage
              selectedPage={selectedPage}
              isEdit={isEdit}
              changePageTitle={changePageTitle}
              onChangeText={onChangeText}
              onDeletePage={() =>
                openDeleteModal(
                  'Delete Page',
                  'Are you sure you want to do this?'
                )
              }
              saveData={saveData}
            />
          </div>
        ) : (
          <div className="col-xl-9 mt-4 mt-xl-0">
            <div className="  bg-gray-800 rounded p-5">
              <div className="d-flex flex-column align-items-center">
                <i
                  className="fa fa-exclamation-triangle color-brand fa-3x"
                  aria-hidden="true"
                ></i>
                <p className="weight-500 mt-2 mb-0">
                  Please create a page to continue.
                </p>
              </div>
            </div>
          </div>
          // <div className="col-xl-9 mt-4 mt-xl-0">
          //   <div className="form-block">
          //     <span className="m-0 form-text form-error">
          //       Please create a page to continue.
          //     </span>
          //   </div>
          // </div>
        )}
      </div>
    </>
  );
}
