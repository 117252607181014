import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { AllIcon } from '../../../Settings/InjectMethod/Icon';
import IconDownload from 'assets/images/icon__download.svg';
import Network from '../../../../service/Network';
import API from '../../../../service/api';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import Dropdown from 'react-bootstrap/Dropdown';
import moment, { max } from 'moment';
import { HelpViewInject, useSelfHostedKnowledgeBase } from 'assets/data/HelpContent';
import fileDownload from 'js-file-download';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { PreparePath } from 'utils/sharedUtils/sharedConst';
import StylishHelp from 'components/DesignSystems/New/StylishHelp';
import StylishNewTable from 'components/DesignSystems/New/StylishNewTable';
import { inputStringLength, toastConfig } from 'assets/data/config';
import TinyMCEWithFormController from 'components/DesignSystems/New/TinyMCEWithFormController';
import { sanitizeHtml } from '../../../../../utils/sanitizeHtml';
import {
  endLoading,
  startLoading,
} from '../../../../../reducers/loading/loading.action';
import { SharedIcon } from 'components/SharedIcon/SharedIcon';

export const MSELView = () => {
  const [injects, setInjects] = useState({});
  const [activityLogs, setActivityLogs] = useState([]);
  const [refreshCommentList, setRefreshCommentList] = useState(true);
  const [nonExpandableRows, setNonExpandableRows] = useState([]);

  const selfHostedKnowledgeBase = useSelfHostedKnowledgeBase()

  const navigate = useNavigate();

  const params = useParams();

  const dispatch = useDispatch();

  const reduxUser = useSelector((state) => state.prepare.user);
  const { selectedWorkspace, type: workspaceType } = useSelector(
    (state) => state.prepare.workspace
  );
  const reduxTimezone = useSelector(
    (state) => state.prepare.workspace.timezone
  );

  const {
    handleSubmit,
    reset,
    clearErrors,
    setError,
    formState: { errors },
    control,
  } = useForm();

  const typeList = [
    { value: 'key', label: 'Key' },
    { value: 'enabling', label: 'Enabling' },
    { value: 'supporting', label: 'Supporting' },
  ];

  useEffect(() => {
    if (!!workspaceType && workspaceType === 'T&E') {
      navigate(`${PreparePath}/dashboard/plan`);
    }
  }, [workspaceType]);

  const fetchSingleInject = async () => {
    dispatch(startLoading());
    try {
      const inject = await Network.get(API.fetchSingleInject, {
        id: params.id,
      });
      let selectType = typeList.filter(
        (tl) => tl.value === inject.data.response.dataset[0].type
      );
      const injects = {
        ...inject.data.response.dataset[0],
        type: selectType[0]?.label,
      };

      setInjects(injects);
    } catch (error) {
      toast.error(error?.response?.data?.response?.status?.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };

  const activityLogList = async () => {
    dispatch(startLoading());
    try {
      Network.get(API.activityLogList, {
        injectId: params.id,
      })
        .then((resp) => {
          setActivityLogs(
            resp.data.response.dataset.map((element) => {
              return {
                ...element,
                createdAt: moment(element.createdAt).fromNow(),
                typeToShow:
                  element.type === 'updatePlanInject'
                    ? 'Update Plan Inject'
                    : 'Comment',
              };
            })
          );
          setRefreshCommentList(false);
          for (let i = 0; i < resp.data.response.dataset.length; i++) {
            if (resp.data.response.dataset[i].type === 'updatePlanInject') {
              setNonExpandableRows((prevState) => [
                ...prevState,
                resp.data.response.dataset[i].logid,
              ]);
            }
          }
        })
        .catch(console.log)
        .finally(() => {
          dispatch(endLoading());
        });
    } catch (error) {
      toast.error(error?.response?.data?.response?.status?.msg, toastConfig);
    }
  };

  const onSubmit = async (data) => {
    if (data.comment === '<p><br></p>') {
      setError('comment', {
        message: 'This field is required',
        type: 'required',
      });
      return;
    }
    dispatch(startLoading());
    try {
      const activityLog = await Network.post(API.addLogComment, {
        injectId: params.id,
        workspaceId: selectedWorkspace,
        comment: data.comment,
      });
      clearErrors('comment');
      activityLog && setRefreshCommentList(true);
      reset({ comment: '' });
    } catch (error) {
      toast.error(error?.response?.data?.response?.status?.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };

  useEffect(() => {
    refreshCommentList && fetchSingleInject();
    refreshCommentList && activityLogList();
    reset({ comment: '' });
  }, [reduxUser, refreshCommentList]);

  const training_method = [
    { value: 'live', label: 'Live' },
    { value: 'virtual', label: 'Virtual' },
    { value: 'constructive', label: 'Constructive' },
    { value: 'integrated_lvc', label: 'Integrated LVC' },
  ];

  const msel_details_data_col = [
    {
      dataField: 'username',
      text: 'Participant',
      sort: true,
      headerAttrs: { title: 'Sort By:' },
      attrs: { title: 'Participant' },
    },
    {
      dataField: 'typeToShow',
      text: 'Type',
      sort: true,
      attrs: { title: 'Type' },
    },
    {
      dataField: 'createdAt',
      text: 'Time',
      sort: true,
      attrs: { title: 'Time' },
    },
  ];

  const expandRow = {
    parentClassName: 'parent-expand-tr',
    className: 'child-expand-tr',
    renderer: (row) => {
      const clean = sanitizeHtml(row.content);
      return (
        <>
          <div className="row">
            <label className="form-label d-block">Comment</label>
            <div
              className="m-0"
              dangerouslySetInnerHTML={{ __html: clean }}
            ></div>
          </div>
        </>
      );
    },
    showExpandColumn: true,
    onlyOneExpanding: true,
    expandByColumnOnly: true,
    nonExpandable: nonExpandableRows,
    expandHeaderColumnRenderer: ({ isAnyExpands }) => {
      if (isAnyExpands) {
        return <i className="fa fa-chevron-down" aria-hidden="true"></i>;
      }
      return <i className="fa fa-chevron-right" aria-hidden="true"></i>;
    },
    expandColumnRenderer: ({ expanded, expandable }) => {
      if (expanded) {
        return (
          <>
            <div className="d-flex align-items-center justify-content-md-center">
              <span className="me-2">Details</span>
              <i className="fa fa-chevron-down" aria-hidden="true"></i>
            </div>
          </>
        );
      }
      if (expandable) {
        return (
          <>
            <div className="d-flex align-items-center justify-content-md-center">
              <span className="me-2">Details</span>
              <i className="fa fa-chevron-right" aria-hidden="true"></i>
            </div>
          </>
        );
      }
    },
  };

  const downloadFile = async (file) => {
    let fullFileName = file.split('/')[file.split('/').length - 1];
    try {
      dispatch(startLoading());
      const downloadFile = await Network.post(API.generateSignedUrl, {
        url: file,
      });
      const res = await fetch(downloadFile.data.response.singedUrl, {
        headers: {},
      });

      fileDownload(
        await res.blob(),
        fullFileName.split('-')[fullFileName.split('-').length - 1]
      );
    } catch (error) {
      console.log('Error: ', error);
      toast.error('Failed to download file', toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };

  return (
    <>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <StylishNewButton onClick={() => navigate(-1)}>
              <SharedIcon iconName="keyboard_arrow_left" classes="me-2" />
              Back
            </StylishNewButton>
          </li>
          <li className="breadcrumb-item">
            <Link to={`${PreparePath}/dashboard/plan/prepare/msel-list`}>
              Injects
            </Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            {injects.injectTitle}
          </li>
        </ol>
      </nav>
      <div className="form-block mb-3">
        <div className="row">
          <div className="col-md-12 mb-4 d-flex align-items-center">
            <h4 className="m-0"> {injects.injectTitle}</h4>
            <div className="d-flex align-items-center flex-shrink-0 ms-auto">
              <Dropdown className="me-3">
                <Dropdown.Toggle className="button--icon">
                  <SharedIcon iconName="more_vert" bold />
                </Dropdown.Toggle>
                <Dropdown.Menu align="end">
                  <Link
                    className="dropdown-item"
                    to={`${PreparePath}/dashboard/plan/prepare/msel-all-details/${injects.id}`}
                  >
                    <SharedIcon iconName="stylus" />
                    Edit Inject
                  </Link>
                </Dropdown.Menu>
              </Dropdown>
              <StylishHelp
                classes={'ms-auto sml flex-shrink-0'}
                title={HelpViewInject.title}
                content={HelpViewInject.content}
                link={selfHostedKnowledgeBase || HelpViewInject.link}
              />
            </div>
          </div>
          <div className="col-md-4 mb-3">
            <label className="form-label d-block mb-0">Trigger</label>
            {injects.trigger}
          </div>
          <div className="col-md-4 mb-3">
            <label className="form-label d-block mb-0">
              Anticipated Response / Expected Player Action
            </label>
            {injects.response}
          </div>
          <div className="col-md-4 mb-3">
            <label className="form-label d-block mb-0">Remarks</label>
            {injects.remarks}
          </div>
          <div className="col-md-4 mb-3 mb-md-0">
            <label className="form-label d-block mb-0">Response Time</label>
            {/* {injects.responseDate} */}
            {injects?.statusLabel === 'Complete' && injects.responseDate ? (
              <span>
                {moment(injects.responseDate).format(
                  reduxUser?.timeFormat === 'hour12Mode'
                    ? 'YYYY-MM-DD - hh:mm a'
                    : 'YYYY-MM-DD - HH:mm'
                )}{' '}
                ({reduxTimezone.split('/')[0]})
              </span>
            ) : null}
          </div>
          <div className="col-md-4 mb-3 mb-md-0">
            <label className="form-label d-block mb-0">Actual Response</label>
            {injects?.statusLabel === 'Complete'
              ? injects.actualResponse
              : null}
          </div>
          <div className="col-md-4 m-0">
            <label className="form-label d-block mb-0">Location</label>
            <p className="m-0">
              {injects?.loc} <br />
              {injects?.lat} <br />
              {injects?.lng}
            </p>
          </div>
          <div className="col-md-12">
            <hr className="dashed my-4" />
          </div>
          <div className="col-md-4 mb-3 mb-md-0">
            <label className="form-label d-block mb-0">
              Objectives &amp; Trained Method
            </label>
            <p className="m-0 pt-1">
              {injects.trainingobjective?.map((tO) => {
                const jmet =
                  (tO.jmetLevelWar || '') +
                  ' ' +
                  (tO.jmetTaskNumber || '') +
                  ' ' +
                  (tO.jmetTaskTitle || '') +
                  ' ' +
                  (tO.planplatformTitle || '') +
                  ' ' +
                  (tO.platformType || '');
                return (
                  <span className="d-block mt-2" key={tO.trainingObjectiveId}>
                    &bull; {tO.participantName || ''}{' '}
                    {jmet.trim() ? ` - ${jmet}` : ''}{' '}
                    {tO.trainedMethodType
                      ? `(${
                          training_method.find(
                            (data) => data.value === tO.trainedMethodType
                          )?.label
                        })`
                      : null}
                  </span>
                );
              })}
            </p>
          </div>
          <div className="col-md-4 mb-0">
            <label className="form-label d-block mb-0">Attachments</label>
            {injects.urls
              ?.split(',')
              .filter((data) => data != '' && data != 'null' && data != null)
              .map((urlData, index) => {
                let fullFileName = urlData?.split('/')[
                  urlData.split('/').length - 1
                ];
                return (
                  <p className="m-0 pt-1" key={index}>
                    <span>{fullFileName}</span>
                    <span
                      className="ms-3 cursor-pointer"
                      onClick={() => downloadFile(urlData)}
                    >
                      <img src={IconDownload} alt="" />
                    </span>
                  </p>
                );
              })}
          </div>
        </div>
      </div>
      <div className="form-block mb-3">
        <div className="row">
          <div className="col-md-12 mb-3">
            <h4 className="m-0">Status</h4>
          </div>
          <div className="col-md-12 mb-4">
            <span
              className="status"
              style={{ backgroundColor: injects.statusColor }}
            >
              {injects.statusLabel}
            </span>
          </div>
          <div className="row">
            <div className="col-md-4 mb-3 mb-md-0">
              <label className="form-label d-block mb-3">Type/Method</label>
              <span className="d-block mb-2">{injects.type}</span>
              <div className="d-flex align-items-center">
                <span
                  className="status status--icon me-2"
                  style={{ backgroundColor: injects.methodColor }}
                >
                  {AllIcon.find((icon) => icon.name === injects.icon)?.icon}
                </span>
                <span>{injects.methodName}</span>
              </div>
            </div>
            <div className="col-md-8">
              <div className="row">
                <div className="col-md-4 mb-3">
                  <label className="form-label d-block mb-0">Scenario</label>
                  {injects.planEvent}
                </div>
                <div className="col-md-4 mb-3">
                  <label className="form-label d-block mb-0">Owner</label>
                  {injects.injetOwnerTitle}
                </div>
                <div className="col-md-4 mb-3">
                  <label className="form-label d-block mb-0">
                    From &gt; To
                  </label>
                  {injects.from_title} {'>'} {injects.to_title}
                </div>
                <div className="col-md-4 mb-3 mb-md-0">
                  <label className="form-label d-block mb-0">Created At</label>
                  {injects.createdAt ? (
                    <span>
                      {moment(injects.createdAt).format(
                        reduxUser?.timeFormat === 'hour12Mode'
                          ? 'YYYY-MM-DD - hh:mm a'
                          : 'YYYY-MM-DD - HH:mm'
                      )}{' '}
                      ({reduxTimezone.split('/')[0]})
                    </span>
                  ) : null}
                </div>
                <div className="col-md-4 m-0">
                  <label className="form-label d-block mb-0">Created By</label>
                  {injects.createdBy}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="form-block">
        <div className="mb-4 mb-md-5">
          <h4 className="mb-4">Activity Log</h4>
          <StylishNewTable
            keyField={'logid'}
            columns={msel_details_data_col}
            rows={activityLogs}
            expandRow={expandRow}
          />
        </div>
        <h4 className="mb-4">Comment</h4>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-3">
            <TinyMCEWithFormController
              name="comment"
              control={control}
              placeholder="Write a Comment"
              readOnly={false}
              rules={{ required: true, maxLength: inputStringLength }}
            />
            {errors.comment?.type === 'required' && (
              <span className="form-text form-error">
                This field is required
              </span>
            )}
            {errors.comment?.type === 'maxLength' && (
              <span className="form-text form-error">
                Maximum {inputStringLength} characters allowed
              </span>
            )}
          </div>
          <StylishNewButton className="button button--primary" type="submit">
            Comment
          </StylishNewButton>
        </form>
      </div>
    </>
  );
};
