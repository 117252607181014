import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Network from '../../../../../service/Network';
import API from '../../../../../service/api';
import { Modal } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import { inputStringLength, toastConfig } from 'assets/data/config';
import { useEffect } from 'react';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import StylishNewInput from 'components/DesignSystems/New/StylishNewInput';
import { endLoading, startLoading } from "../../../../../../reducers/loading/loading.action";

export const PositionModal = ({ show, onClose }) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({ mode: 'onChange' });

  const workspaceId = useSelector(
    (state) => state.prepare.workspace.selectedWorkspace
  );

  const dispatch = useDispatch();
  const positionModalSubmitButton = useRef();

  const onSubmit = async (data) => {
    dispatch(startLoading());
    try {
      const newPosition = await Network.post(API.createPostion, {
        ...data,
        workspaceId: workspaceId,
      });
      onClose(newPosition.data.response.dataset[0]);
      toast.success('Position created successfully', toastConfig);
    } catch (error) {
      toast.error(error.response.data.response.status.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };

  useEffect(() => {
    const listener = (event) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        positionModalSubmitButton.current?.click();
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, []);

  return (
    <div>
      <div>
        <Modal
          show={show}
          onHide={onClose}
          centered
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton closeVariant="white">
            <Modal.Title>Position</Modal.Title>
          </Modal.Header>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Modal.Body>
              <div className="mb-3">
                <label className="form-label">Title</label>
                <Controller
                  control={control}
                  name={'title'}
                  rules={{
                    required: true,
                  }}
                  render={({ field: { onChange, value } }) => (
                    <StylishNewInput
                      onChange={(e) => {
                        onChange(e);
                      }}
                      value={value}
                      type="text"
                      name="title"
                      maxLength={inputStringLength}
                      className="form-control"
                      placeholder="Position"
                    />
                  )}
                />
                {errors?.title && (
                  <span className="form-text form-error">
                    This is a required field
                  </span>
                )}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div className="button-group">
                <StylishNewButton
                  type="button"
                  className="button button--secondary button--reverse"
                  onClick={onClose}
                >
                  Close
                </StylishNewButton>
                <StylishNewButton
                  ref={positionModalSubmitButton}
                  className="button button--primary"
                  type="submit"
                >
                  Save
                </StylishNewButton>
              </div>
            </Modal.Footer>
          </form>
        </Modal>
      </div>
    </div>
  );
};
