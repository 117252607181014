import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Modal } from 'react-bootstrap';

import generateUUID from 'utils/sharedUtils/generateUUID';

import StylishNewTable from 'components/DesignSystems/New/StylishNewTable';
import StylishNewInput from 'components/DesignSystems/New/StylishNewInput';

import { fetchRostersForGroups } from 'actions/roleActions';

import { upsertADL } from 'actions/alertActions';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { StylishNewCheckbox } from 'components/DesignSystems/New/StylishNewCheckbox';

const allMethods = [
  'Email',
  'SMS',
  'Text-To-Speech (Home)',
  'Text-To-Speech (Cell)',
];

const columns = [
  {
    dataField: 'email_address',
    text: 'Member Email',
    sort: true,
    headerAttrs: { title: 'Sort By:' },
    attrs: { title: 'Member Email' },
  },
  {
    dataField: 'role_assignments',
    text: 'Organization Roles',
    sort: true,
    attrs: { title: 'Organization Roles' },
    formatter: (cell, row, rowIndex) =>
      row?.role_assignments.map((role) => role.name).join(', '),
  },
];

// Guide the user through the necessary minimum steps required to set up Alerts
export default function ManageAlertListDialog({
  show,
  onClose,
  al = null,
  rl = null,
  aor = null,
}) {
  const reduxDispatch = useDispatch();

  const reduxCurrentlySelectedGroup = useSelector((state) => {
    return state.app.currentlySelectedGroup;
  });

  const reduxRostersForGroups = useSelector((state) => {
    return (
      (!!state.app.rostersForGroups &&
        state.app.rostersForGroups.filter(
          (r) => r.group_guid === reduxCurrentlySelectedGroup.group_guid
        )) ||
      []
    );
  });

  const isFetchRostersForGroupsLoaded = useSelector((state) => {
    return state.app.isFetchRostersForGroupsLoaded;
  });

  const isFetchADLsLoaded = useSelector((state) => {
    return state.app.isFetchADLsLoaded;
  });

  const isUpsertADLLoaded = useSelector((state) => {
    return state.app.isUpsertADLLoaded;
  });

  const [name, setName] = useState((!!al && al.name) || '');
  const [methods, setMethods] = useState((!!al && al.methods) || []);
  const [members, setMembers] = useState(
    (!!al && !!al.members && al.members.map((m) => m.user_id)) || []
  );

  const [isLoaded, setIsLoaded] = useState(true);

  useEffect(() => {
    if (!reduxRostersForGroups.length && !!isFetchRostersForGroupsLoaded) {
      reduxDispatch(
        fetchRostersForGroups([reduxCurrentlySelectedGroup.group_guid])
      );
    }
  }, [reduxRostersForGroups]);

  useEffect(() => {
    if (!isLoaded && isUpsertADLLoaded && isFetchADLsLoaded) {
      onClose();
    }
  }, [isUpsertADLLoaded, isFetchADLsLoaded, isLoaded, onClose]);

  const selectRow = {
    mode: 'checkBox',
    clickToSelect: true,
    hideSelectAll: true,
    selected: members,
    selectionHeaderRenderer: ({ mode, ...rest }) => (
      <div className="form-checkbox align-items-center">
        <input type={mode} {...rest} />
        <span className="icon"></span>
      </div>
    ),
    selectionRenderer: ({ mode, ...rest }) => (
      <div className="form-checkbox align-items-center">
        <input type={mode} {...rest} />
        <span className="icon"></span>
      </div>
    ),
    onSelect: (row, isSelect, rowIndex, e) => {
      if (members.find((m) => m === row.user_guid)) {
        setMembers(members.filter((m) => m !== row.user_guid));
      } else {
        setMembers([...members, row.user_guid]);
      }
    },
  };

  function saveClicked() {
    setIsLoaded(false);
    let newal = {
      id: (!!al && al.id) || generateUUID(),
      name: name,
      members: members,
      methods: methods,
      group_guid: reduxCurrentlySelectedGroup.group_guid,
      rl: rl,
      aor: aor,
    };
    reduxDispatch(upsertADL(newal));
  }

  const saveDisabled =
    !members.length || !methods.length || !name.length || !isLoaded;

  return (
    <Modal
      show={show}
      onHide={onClose}
      centered
      backdrop="static"
      keyboard={false}
      size="lg"
    >
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title>
          {(!!al && 'Manage Alert List: ' + al.name) || 'New Alert List'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="mb-3">
          <label className="form-label">Name</label>
          <StylishNewInput
            type="text"
            onChange={(e) => setName(e.target.value)}
            value={name}
          />
        </div>

        <div className="mb-3">
          <label className="form-label mb-2">Methods</label>
          {allMethods.map((m, idx) => {
            return (
              <div className={`d-flex ${idx !== 0 ? 'mt-2' : ''}`}>
                <StylishNewCheckbox
                  onClick={(e) => {
                    if (!methods.find((me) => me === m)) {
                      setMethods([...methods, m]);
                    } else {
                      setMethods(methods.filter((me) => me !== m));
                    }
                  }}
                  checked={methods.find((me) => me === m)}
                  label={m}
                />
              </div>
            );
          })}
        </div>

        <label className="form-label">Alert List Members</label>
        {(!!isFetchRostersForGroupsLoaded && !!reduxRostersForGroups && (
          <StylishNewTable
            keyField={'user_guid'}
            columns={columns}
            rows={reduxRostersForGroups}
            selectRow={selectRow}
          />
        )) || <div>Loading Organization Members...</div>}
      </Modal.Body>
      <Modal.Footer>
        <div className="button-group">
          <StylishNewButton
            type="button"
            className="button--secondary button--reverse"
            onClick={onClose}
          >
            Cancel
          </StylishNewButton>
          <StylishNewButton
            className={'button--primary'}
            disabled={saveDisabled}
            onClick={() => saveClicked()}
          >
            Save
          </StylishNewButton>
        </div>
        {!isLoaded && 'Loading...'}
      </Modal.Footer>
    </Modal>
  );
}
