import React from 'react';
export default function ComingSoon() {
  return (
    <>
      <div className="site site__no-route">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-md-12">
              <div className="d-flex justify-content-center mb-5">
                <span className="logo">EMW</span>
              </div>
              <p className="text__coming-soon m-0">
                Coming <br />
                Soon
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
