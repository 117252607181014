import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import API from '../../service/api';
import Network from '../../service/Network';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import StylishNewInput from 'components/DesignSystems/New/StylishNewInput';
import { toastConfig } from 'assets/data/config';
import { endLoading, startLoading } from "../../../reducers/loading/loading.action";

export default function ShareInviteLinkDialog({ show, onHide, userId }) {
  const [getInviteLink, setGetInviteLink] = useState(null);

  const dispatch = useDispatch();

  const inviteLink = async () => {
    dispatch(startLoading());
    try {
      const response = await Network.get(API.shareInviteLink, {
        userId: userId,
      });
      setGetInviteLink(response?.data?.response?.dataset[0]?.link);
    } catch (error) {
      toast.error(error, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };

  useEffect(() => {
    if (show) {
      inviteLink();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show, userId]);

  return getInviteLink !== null ? (
    <>
      <Modal
        show={show}
        onHide={onHide}
        centered
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton closeVariant="white">
          <Modal.Title>Share Invite Link</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <StylishNewInput
            type="text"
            className="form-control"
            defaultValue={getInviteLink}
            readOnly={true}
          />
        </Modal.Body>
        <Modal.Footer>
          <div className="button-group">
            <StylishNewButton
              className="button button--primary"
              type="button"
              onClick={() => {
                navigator.clipboard.writeText(getInviteLink);
                toast.success('Link Copied', toastConfig);
              }}
            >
              Copy to Clipboard
            </StylishNewButton>
            <StylishNewButton
              className="button button--secondary button--reverse"
              onClick={onHide}
            >
              Cancel
            </StylishNewButton>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  ) : (
    <></>
  );
}
