import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Network from '../../../../../service/Network';
import API from '../../../../../service/api';
import AddEditPlanMeetingsDialog from './BkAddEditPlanMeetingsDialog';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import moment from 'moment';
import { HelpPlanningMeetings, useSelfHostedKnowledgeBase } from 'assets/data/HelpContent';
import StylishNewTable from 'components/DesignSystems/New/StylishNewTable';
import StylishNewTableAction from 'components/DesignSystems/New/StylishNewTableAction';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { PreparePath } from 'utils/sharedUtils/sharedConst';
import StylishHelp from 'components/DesignSystems/New/StylishHelp';
import { toastConfig } from 'assets/data/config';
import { StylishConfirmDialog } from 'components/DesignSystems/New/StylishConfirmDialog';
import { PaginationDropdown } from '../../../../../../components/DesignSystems/PaginationDropdown';
import {
  endLoading,
  startLoading,
} from '../../../../../../reducers/loading/loading.action';

export default function PlanMeetings(props) {
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [orderBy, setOrderBy] = useState('');
  const searchTerm = '';
  const [perPage, setPerPage] = useState(5);
  const [planMeetings, setPlanMeetings] = useState([]);
  const [dialogType, setDialogType] = useState('Add');
  const [updateDialogData, setUpdateDialogData] = useState(null);
  const [deletePlanMeeting, setDeleteplanMeeting] = useState(null);
  const [
    showPlanMeetingsAddEditDialog,
    setShowPlanMeetingsAddEditDialog,
  ] = useState(false);

  const selfHostedKnowledgeBase = useSelfHostedKnowledgeBase()

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { selectedWorkspace, workspaceName } = useSelector((state) => {
    return state.prepare.workspace;
  });

  const reduxUser = useSelector((state) => state.prepare.user);
  const exerciseId = useSelector(
    (state) => state.prepare.exercise.exerciseDetailsId
  );

  const columns = [
    {
      dataField: 'name',
      text: 'Name',
      sort: true,
      onSort: (field, order) => {
        if (order === 'asc') {
          setOrderBy('name_ASC');
        } else if (order === 'desc') {
          setOrderBy('name_DESC');
        } else {
          setOrderBy('');
        }
      },

      headerAttrs: { title: 'Sort By:' },
      attrs: { title: 'Name' },
    },
    {
      dataField: 'location',
      text: 'Location',
      sort: true,
      onSort: (field, order) => {
        if (order === 'asc') {
          setOrderBy('location_ASC');
        } else if (order === 'desc') {
          setOrderBy('location_DESC');
        } else {
          setOrderBy('');
        }
      },

      attrs: { title: 'Location' },
    },
    {
      dataField: 'startDate',
      text: 'Start Date',
      sort: true,
      onSort: (field, order) => {
        if (order === 'asc') {
          setOrderBy('startDate_ASC');
        } else if (order === 'desc') {
          setOrderBy('startDate_DESC');
        } else {
          setOrderBy('');
        }
      },

      attrs: { title: 'Start Date' },
    },
    {
      dataField: 'endDate',
      text: 'End Date',
      sort: true,
      onSort: (field, order) => {
        if (order === 'asc') {
          setOrderBy('endDate_ASC');
        } else if (order === 'desc') {
          setOrderBy('endDate_DESC');
        } else {
          setOrderBy('');
        }
      },

      attrs: { title: 'End Date' },
    },
    {
      dataField: '',

      text: 'Action',
      isDummyField: true,
      formatter: (cell, row, rowIndex) => (
        <StylishNewTableAction
          name="Plan Meeting"
          cell={cell}
          row={row}
          rowIndex={rowIndex}
          onDelete={onDeletePlanMeetings}
          onEdit={onEditgetPlanMeetings}
        />
      ),
      attrs: { title: 'Action' },
      headerStyle: {
        width: '100px',
      },
      headerAlign: 'center',
      align: 'center',
    },
  ];

  useEffect(() => {
    if (exerciseId) {
      getAllPlanMeetings();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, perPage, searchTerm, orderBy, reduxUser]);

  const getAllPlanMeetings = async () => {
    dispatch(startLoading());
    try {
      Network.get(API.fetchAllMeeting, {
        limit: perPage,
        page: page,
        search: searchTerm,
        workspaceId: selectedWorkspace,
        planEventId: exerciseId,
        sort: orderBy,
      })
        .then((resp) => {
          let allPlanMeetings = {};
          allPlanMeetings =
            resp.data.response.dataset.length &&
            resp.data.response.dataset.map((e) => {
              return {
                ...e,
                startDate: moment(e?.startDate).format(
                  reduxUser?.timeFormat === 'hour12Mode'
                    ? 'YYYY-MM-DD hh:mm a'
                    : 'YYYY-MM-DD HH:mm'
                ),
                endDate: moment(e?.endDate).format(
                  reduxUser?.timeFormat === 'hour12Mode'
                    ? 'YYYY-MM-DD hh:mm a'
                    : 'YYYY-MM-DD HH:mm'
                ),
              };
            });
          setPlanMeetings(allPlanMeetings || []);
          if (resp.data.response.total) {
            setTotal(resp.data.response.total);
          } else {
            setTotal(0);
          }
        })
        .catch((err) => {
          toast.error(err.response.data.response.status.msg, toastConfig);
        })
        .finally(() => {
          dispatch(endLoading());
        });
    } catch (error) {
      toast.error(error.response.data.response.status.msg, toastConfig);
    } finally {
      // dispatch(endLoading());
    }
  };

  const onAddPlanMeetings = () => {
    setDialogType('Add');
    setShowPlanMeetingsAddEditDialog(true);
  };

  const OnAddOrEditTrainingPriorites = (afterSave) => {
    setUpdateDialogData(null);
    setDialogType('New');
    setShowPlanMeetingsAddEditDialog(false);
    if (afterSave?.id) {
      getAllPlanMeetings();
    }
  };

  const onEditgetPlanMeetings = (row) => {
    setUpdateDialogData(row);
    setDialogType('Edit');
    setShowPlanMeetingsAddEditDialog(true);
  };

  const onDeletePlanMeetings = (row) => {
    setDeleteplanMeeting(row.id);
  };

  const onDeleteConfirm = async () => {
    dispatch(startLoading());
    try {
      await Network.post(API.deleteMeetings, { id: deletePlanMeeting });
      setDeleteplanMeeting(null);
      getAllPlanMeetings();
      toast.success('Plan Meeting deleted successfully', toastConfig);
    } catch (error) {
      toast.error(error.response.data.response.status.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };

  const saveAndExit = () => {
    navigate(`${PreparePath}/dashboard/plan/prepare/exercise-details`);
  };

  return (
    <>
      <div className="d-flex align-items-center mb-4">
        <h4 className="mb-0">
          {props?.exerciseDetailName?.eventName || workspaceName} Planning
          Meetings
        </h4>
        <StylishHelp
          classes={'ms-auto sml flex-shrink-0'}
          title={HelpPlanningMeetings.title}
          content={HelpPlanningMeetings.content}
          link={selfHostedKnowledgeBase || HelpPlanningMeetings.link}
        />
      </div>
      <div className="form-block">
        <StylishNewButton
          className="button button--primary mb-3 w-100 w-md-auto"
          onClick={onAddPlanMeetings}
        >
          Add Planning Meeting
        </StylishNewButton>
        <div className="row mb-2">
          <div className="col-md-12">
            <div className="d-flex align-items-center">
              <p className="m-0 me-3 text-nowrap weight-600">
                Plan Meetings: {total}
              </p>
              <div className="ms-auto">
                <PaginationDropdown
                  setPage={setPage}
                  setPerPage={setPerPage}
                  options={[
                    { value: 5, label: '5 per page' },
                    { value: 15, label: '15 per page' },
                    { value: 30, label: '30 per page' },
                    { value: 60, label: '60 per page' },
                    { value: 120, label: '120 per page' },
                  ]}
                />
              </div>
            </div>
          </div>
        </div>
        <StylishNewTable
          keyField={'id'}
          columns={columns}
          rows={planMeetings}
          pagination
          page={page}
          perPage={perPage}
          total={total}
          onPageChange={(page) => setPage(page)}
        />
        {showPlanMeetingsAddEditDialog && (
          <AddEditPlanMeetingsDialog
            show={showPlanMeetingsAddEditDialog}
            onClose={OnAddOrEditTrainingPriorites}
            dialogType={dialogType}
            updateDialogData={updateDialogData}
          />
        )}
        {deletePlanMeeting && (
          <StylishConfirmDialog
            show={deletePlanMeeting}
            onClose={() => setDeleteplanMeeting(null)}
            dialogType={'Delete'}
            dialogTitle={'Delete Meeting'}
            dialogContent={'Are you sure you want this?'}
            updateDialogData={deletePlanMeeting}
            onConfirm={onDeleteConfirm}
          />
        )}
      </div>
      <div className="col-md-12 mt-4">
        <div className="button-group d-block d-md-flex">
          <StylishNewButton
            type="submit"
            className="button button--primary button--reverse w-100 w-md-auto"
            onClick={() => props.onPrevClick(0)}
          >
            Previous
          </StylishNewButton>
          <StylishNewButton
            type="button"
            className="button button--tertiary button--reverse ms-auto mt-3 mt-md-0 w-100 w-md-auto"
            onClick={saveAndExit}
          >
            Save &amp; Exit
          </StylishNewButton>
          <StylishNewButton
            type="submit"
            className="button button--primary  w-100 w-md-auto mt-3 mt-md-0 ms-0 ms-md-3"
            onClick={() => props.onNextClick(2)}
          >
            Next
          </StylishNewButton>
        </div>
      </div>
    </>
  );
}
