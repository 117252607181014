import {
  INJECT_REFRESH_REQUEST,
  INJECT_REFRESH_SUCCESS,
} from "./inject.constants";

export const startInjectRefresh = () => {
  return (dispatch) => {
    dispatch({ type: INJECT_REFRESH_REQUEST });
  };
};

export const endInjectRefresh = () => {
  return (dispatch) => {
    dispatch({ type: INJECT_REFRESH_SUCCESS });
  };
};
