import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { Legends, StyleLabel } from 'assets/data/config';

import IconArrowDown from 'assets/images/icon__angle--down.svg';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { SharedIcon } from 'components/SharedIcon/SharedIcon';

const TRRCard = ({
  trr,
  level,
  expandTrr,
  onDeleteTrr,
  onEditTrr,
  onAddTrr,
}) => {
  const hasChild =
    trr?.trrLevel === 3
      ? false
      : trr?.trrLevel === 1
      ? Number(trr.level_2_count) > 0
      : Object.keys(trr?.risk_character).reduce(
          (acc, color) => acc + trr?.risk_character[color],
          0
        ) > 0;
  return (
    <div
      className={`${
        trr.trrLevel === 1 ? 'bg-gray-700' : 'bg-gray-800 '
      } p-3 p-md-4 rounded d-flex flex-column flex-md-row position-relative`}
    >
      {trr.trrLevel !== 3 && (
        <span
          className={`trr__toggle ${hasChild ? 'enabled' : ''} ${
            trr?.active ? 'active' : ''
          }`}
          onClick={() => hasChild && expandTrr(trr?.trrItem)}
        >
          <img src={IconArrowDown} alt="" />
        </span>
      )}
      <div className="row flex-grow-1 order-1 order-md-0">
        <div className="col-md-4 col-xl-2 mb-3 mb-xl-0">
          <h6 className="weight-700 mb-2">Special Interest</h6>
          {trr?.special_interest?.label || 'N/A'}
        </div>
        <div className="col-md-4 col-xl-2 mb-3 mb-xl-0">
          <h6 className="weight-700 mb-2">Technical Discipline</h6>
          {trr?.technical_discipline_item || 'N/A'}
        </div>
        <div className="col-md-4 col-xl-2 mb-3 mb-xl-0">
          <h6 className="weight-700 mb-2">Description</h6>
          {(trr?.description && (
            <span className="break-word">{trr?.description || 'N/A'}</span>
          )) ||
            null}
        </div>

        <div className="col-md-4 col-xl-2 mb-3 mb-md-0">
          <h6 className="weight-700 mb-2">Risk Character</h6>
          <div className="d-flex align-items-center flex-shrink-0">
            {trr.trrLevel === 3 ? (
              <span
                key={trr.risk_character}
                style={{
                  ...StyleLabel,
                  background: Legends.find(l => l.value === trr.risk_character)?.color ?? '#fff'
                }}
                className={`color-black weight-700 d-flex justify-content-center align-items-center`}
              >
                1
              </span>
            ) : (
              Legends.map((i, idx) => (
                <span
                  key={idx}
                  style={{
                    ...StyleLabel,
                    background: i.color,
                  }}
                  className={`${
                    idx !== 0 ? 'ms-2' : ''
                  } color-black weight-700 d-flex justify-content-center align-items-center`}
                >
                  {trr?.risk_character[i.value]}
                </span>
              ))
            )}
          </div>
        </div>
        <div className="col-md-4 col-xl-2 mb-3 mb-md-0">
          <h6 className="weight-700 mb-2">Item</h6>
          {trr?.item_no}
        </div>
        <div className="col-md-4 col-xl-2">
          <h6 className="weight-700 mb-2">Comments/Mitigation</h6>
          {(trr?.comments && (
            <span className="break-word">{trr?.comments || 'N/A'}</span>
          )) ||
            null}
        </div>
      </div>
      <div className="flex-shrink-0 ms-auto ms-md-5 d-flex flex-md-column">
        <Dropdown className="order-1 order-md-0 ms-2 ms-md-0">
          <Dropdown.Toggle className="button--icon">
            <SharedIcon iconName="more_vert" bold />
          </Dropdown.Toggle>
          <Dropdown.Menu align="end">
            <Dropdown.Item as={'button'} onClick={onEditTrr}>
              <SharedIcon iconName="stylus" />
              Edit TRR
            </Dropdown.Item>
            <Dropdown.Item as={'button'} onClick={onDeleteTrr}>
              <SharedIcon iconName="delete" />
              Delete TRR
            </Dropdown.Item>
            {trr.trrLevel !== 3 && (
              <Dropdown.Item as={'button'} onClick={onAddTrr}>
                <SharedIcon iconName="add" bold />
                Add TRR Level {level + 1}
              </Dropdown.Item>
            )}
          </Dropdown.Menu>
        </Dropdown>
        {(trr?.trrLevel !== 3 && (
          <StylishNewButton
            customButton
            className={'button--icon primary mt-auto'}
            onClick={onAddTrr}
          >
            <SharedIcon iconName="add" bold />
          </StylishNewButton>
        )) ||
          null}
      </div>
    </div>
  );
};

export default TRRCard;
