import React, { useState, useEffect, useLayoutEffect } from 'react';
import API from '../../../../service/api';
import Network from '../../../../service/Network';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { Form } from 'react-bootstrap';
import moment from 'moment';
import CustomModal from 'components/DesignSystems/New/CustomModal';
import { inputStringLength, toastConfig } from 'assets/data/config';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import StylishNewSelect from 'components/DesignSystems/New/StylishNewSelect';
import StylishNewInput from 'components/DesignSystems/New/StylishNewInput';
import StylishNewTextArea from 'components/DesignSystems/New/StylishNewTextArea';
import StylishNewTable from 'components/DesignSystems/New/StylishNewTable';
import { toast } from 'react-toastify';
import { SharedIcon } from 'components/SharedIcon/SharedIcon';

import { endLoading, startLoading } from "../../../../../reducers/loading/loading.action";

const EditAssessmentDod = ({ assessId, ObjectiveId, assessmentData }) => {
  const [showModal, setShowModal] = useState(false);
  const [newReasonValue, setNewReasonValue] = useState('');
  const [buttonDisable, setButtonDisable] = useState(false);
  const [assessmentComment, setAssessmentComments] = useState('');
  const [reason, setReason] = useState(null);
  const [reasonSearch, setReasonSearch] = useState('');
  const [metricSearch, setmetricSearch] = useState('');
  const [assessment, setAssessment] = useState({});
  const [selectedReason, setSelectedReason] = useState({
    value: '',
    label: 'Select a reason',
  });
  const [complete, setComplete] = useState(true);
  const [status, setStatus] = useState('Untrained');
  const [modalFor, setModalFor] = useState('');
  const [selectedMetric, setSelectedMetric] = useState({
    value: '',
    label: 'Metric',
  });
  const [metric, setMetric] = useState([
    {
      value: 'Add New',
      label: 'Add new',
    },
  ]);
  const [newAllMetric, setNewAllMetric] = useState([]);
  const [metricCompleted, setMetricCompleted] = useState(0);
  const [showMetric, setShowMetric] = useState(false);
  const [metricError, setMetricError] = useState(false);
  const [modalButtonDisabled, setModalButtonDisabled] = useState(false);
  const [modalInputReason, setModalInputReason] = useState('');
  const [showDeleteModal, setDeleteShowModal] = useState({
    id: '',
    index: null,
    show: false,
  });
  const [showModalMetric, setShowModalMetric] = useState(false);
  const [assessData, setAssessData] = useState({
    lessonsLearned: 'No',
  });
  const [newLessonsLearned, setNewlessonsLearned] = useState([]);
  const [detailedRow, setDetailedRow] = useState([
    {
      id: '12345',
      date: 'data',
      participantPlatform: 'participantPlatform',
      traningObjective: '',
      requestedMethod: 'requestedMethod',
      trainingMethod: 'rainingMethod',
      associatedInject: 'associatedInject',
    },
  ]);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { selectedWorkspace } = useSelector((state) => state.prepare.workspace);

  const { control, setValue, getValues } = useForm();

  const { fields, append, remove } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: 'lesson', // unique name for your Field Array
  });

  useLayoutEffect(() => {
    let aData = { ...assessData };
    let detailedData = {
      id: '12345',
      date: moment(assessmentData.createdAt).format('Do MMM YY -  HHmm'),
      participantPlatform: `${assessmentData.participantName}${
        assessmentData.platformTitle ? ` - ` + assessmentData.platformTitle : ``
      }${
        assessmentData.platformType ? ' : ' + assessmentData.platformType : ''
      }`,
      traningObjective: `${
        assessmentData.levelWar != null ? assessmentData.levelWar : ''
      } ${assessmentData.taskNumber != null ? assessmentData.taskNumber : ''} ${
        assessmentData.taskTitle != null ? assessmentData.taskTitle : ''
      }`,
      requestedMethod: `${
        assessmentData?.requestedMethod != null
          ? assessmentData.requestedMethod
          : ``
      }`,
      trainingMethod: `${
        assessmentData?.trainedMethodType
          ? assessmentData.trainedMethodType
          : ''
      }`,
      associatedInject: `${
        assessmentData?.associatedInjectCount
          ? assessmentData.associatedInjectCount
          : ``
      }`,
    };
    aData.lessonsLearned = assessmentData?.lessonLearned ? 'Yes' : 'No';
    if (assessmentData?.lessionlearned?.length > 0) {
      setAssessData({ ...aData });
    }
    if (assessmentData?.metrics?.length > 0) {
      setNewAllMetric(
        assessmentData?.metrics.map((item) => {
          return {
            assessmentMetricId: item?.assessmentMetricId,
            value: item?.metricsId,
            label: item?.metricsTitle,
            completed: item?.assessmentData,
          };
        })
      );
    }

    setDetailedRow([detailedData]);
    setComplete(assessmentData.complete);
    setStatus(assessmentData.status);
    setAssessment({ ...assessmentData });
    setAssessmentComments(assessmentData.comment);
  }, []);

  useEffect(() => {
    fetchallAcademicReasons();
  }, [selectedWorkspace, reasonSearch]);

  useEffect(() => {
    fetchMetric();
  }, [selectedWorkspace, metricSearch]);

  useEffect(() => {
    if (assessmentData?.lessionlearned.length > 0) {
      setValue(
        'lesson',
        assessmentData?.lessionlearned.map((t) => ({
          classification: 'Unclassified',
          planassessmentlessionlearnedId: t?.planassessmentlessionlearnedId,
          comments: t?.comments || '',
          discussion: t?.discussion || '',
          eventDescription: t?.eventDescription || '',
          implications: t?.implications || '',
          observation: t?.observation || '',
          potentialOPR: t?.potentialOPR || '',
          recommendation: t?.recommendation || '',
          title: t?.title || '',
        }))
      );
      return;
    } else if (assessData.lessonsLearned === 'Yes') {
      append({
        classification: 'Unclassified',
        comments: '',
        discussion: '',
        eventDescription: '',
        implications: '',
        observation: '',
        potentialOPR: '',
        recommendation: '',
        title: '',
      });
    }
  }, [assessData]);

  const changeFeedbackLessonsLearned = (lessonsLearned) => {
    if (assessData?.lessonsLearned === lessonsLearned) return;
    if (lessonsLearned === 'Yes' && newLessonsLearned.length === 0) {
      let newlesson = [
        {
          classification: 'Unclassified',
          comments: '',
          discussion: '',
          eventDescription: '',
          implications: '',
          observation: '',
          potentialOPR: '',
          recommendation: '',
          title: '',
        },
      ];
      setNewlessonsLearned([...newlesson]);
    }

    let data = { ...assessData };
    data.lessonsLearned = lessonsLearned;
    setAssessData({ ...data });
  };

  function notOnlySpace(str) {
    return /[A-Za-z]/.test(str);
  }

  const fetchMetric = async () => {
    if (selectedWorkspace === null) return;
    try {
      const task = await Network.get(API.planMetricList, {
        workspaceId: selectedWorkspace,
        search: metricSearch,
        filter: assessmentData?.type,
      });
      if (task !== undefined && task.status === 200) {
        setMetric([
          { value: 'Add New', label: 'Add new' },
          ...task.data.response.dataset.map((e) => {
            return {
              value: e.id,
              label: e.title,
            };
          }),
        ]);
      }
    } catch (error) {
      toast.error('Something went wrong', toastConfig);
    }
  };

  const saveAllNewMetric = () => {
    if (selectedMetric.value === '') {
      setMetricError(true);
      return;
    }
    setMetricError(false);
    let data = { ...selectedMetric, completed: metricCompleted };
    setNewAllMetric((prevState) => [...prevState, { ...data }]);
    setShowMetric(false);
    setSelectedMetric({ value: '', label: 'Select a mission' });
    setMetricCompleted(0);
  };

  const deleteMetric = async () => {
    let data = { ...showDeleteModal };

    if (!('feedbackMetricId' in data.id)) {
      // if mission task is not is server ..
      let mtData = [...newAllMetric];
      mtData.splice(data.index, 1);
      setNewAllMetric(mtData);
      data.id = '';
      data.index = 0;
      data.show = false;
      setDeleteShowModal(data);
    } else {
      // removing Mission task from server  then UI...
      setModalButtonDisabled(true);
      try {
        const deleteMissionTaskRes = await Network.post(
          API.deleteFeedbackMissionTask,
          {
            id: data.id.feedbackMetricId,
          }
        );

        if (
          deleteMissionTaskRes !== undefined &&
          deleteMissionTaskRes.status === 200
        ) {
          let mtData = [...newAllMetric];
          mtData.splice(data.index, 1);
          setNewAllMetric(mtData);
          // toast.success("mission task deleted successfully");
        }
      } catch (error) {
        toast.error('Something went wrong', toastConfig);
      } finally {
        setModalButtonDisabled(false);
        data.id = '';
        data.index = null;
        data.show = false;
        setDeleteShowModal(data);
      }
    }
  };

  const deleteAssessmentLesson = (index) => {
    setModalFor('Delete Assessment Lesson');
    let data = {
      id: '',
      index: index,
      show: true,
    };
    setDeleteShowModal(data);
  };

  const deleteLeasonLearned = async () => {
    let data = { ...showDeleteModal };
    remove(data.index);
    fields.length === 1 && changeFeedbackLessonsLearned('No');
    data.id = '';
    data.index = 0;
    data.show = false;
    setDeleteShowModal(data);
  };

  const confirmDeleteModal = () => {
    if (modalFor === 'Delete Metric') {
      deleteMetric();
      return;
    }
    if (modalFor === 'Delete Assessment Lesson') {
      deleteLeasonLearned();
    }
  };

  const cancelSetShowMetric = () => {
    setMetricError(false);
    setShowMetric(false);
    setSelectedMetric({
      value: '',
      label: 'Metric',
    });
    setMetricCompleted(0);
  };

  const onMetricModalConfirm = async () => {
    if (modalInputReason.length === 0 || !notOnlySpace(modalInputReason)) {
      return;
    }
    setModalButtonDisabled(true);

    try {
      let postMission = await Network.post(API.createMetric, {
        title: modalInputReason,
        participantType: assessmentData?.type,
        workspaceId: selectedWorkspace,
      });
      if (postMission !== undefined && postMission.status === 200) {
        let res = {
          value: postMission.data.response.dataset[0].id,
          label: postMission.data.response.dataset[0].title,
        };
        setMetric((prevState) => [...prevState, res]);
        setSelectedMetric(res);
        toast.success('Metric created successfully', toastConfig);
      }
    } catch (error) {
      toast.error('Something went wrong', toastConfig);
    } finally {
      setModalButtonDisabled(false);
      setShowModalMetric(false);
    }
  };

  const onModalConfirmMetric = async () => {
    if (modalFor === 'Metric') {
      onMetricModalConfirm();
    }
  };

  const selectMetricFromSelectBox = (e) => {
    if (!e) {
      if (selectedMetric.value === '') return;
      setSelectedMetric({
        value: '',
        label: 'Metric',
      });
      return;
    }
    if (e?.value === 'Add New') {
      setShowModalMetric(true);

      setModalFor('Metric');
      return;
    }

    setSelectedMetric({ ...e });
  };

  const closeReasonModal = () => {
    setShowModalMetric(false);
    setModalButtonDisabled(false);
    setModalInputReason('');
  };

  const modalInputChange = (e) => {
    setModalInputReason(e.target.value);
  };

  const onKeyPressEvent = (event) => {
    var invalidChars = ['E', '.', 'e', '-', '+'];
    if (invalidChars.includes(event.key)) {
      event.preventDefault();
    }
  };

  const updateAllMetric = (e, idx) => {
    let data = [...newAllMetric];
    data[idx].completed = e.target.value;
    setNewAllMetric([...data]);
  };

  const removeMetric = (metric, index) => {
    setModalFor('Delete Metric');
    let data = {
      id: metric,
      index: index,
      show: true,
    };
    setDeleteShowModal(data);
  };

  // const deleteLeasonLearned = async () => {
  //   let data = { ...showDeleteModal };

  //   if (!data.id) {
  //     // if mission task is not is server ..
  //     let mtData = [...newLessonsLearned];
  //     mtData.splice(data.index, 1);
  //     setNewlessonsLearned(mtData);
  //     data.id = "";
  //     data.index = 0;
  //     data.show = false;
  //     setDeleteShowModal(data);
  //   } else {
  //     // removing Mission task from server  then UI...
  //     setModalButtonDisabled(true);
  //     try {
  //       const deleteLeasonLearnedRes = await Network.post(
  //         API.deleteFeedbackLessionLearned,
  //         {
  //           id: data.id,
  //         }
  //       );

  //       if (
  //         deleteLeasonLearnedRes !== undefined &&
  //         deleteLeasonLearnedRes.status === 200
  //       ) {
  //         let mtData = [...newLessonsLearned];
  //         mtData.splice(data.index, 1);
  //         setNewlessonsLearned(mtData);
  //         // fields(mtData);
  //         remove(data.index);
  //         // toast.success("mission task deleted successfully");
  //       }
  //     } catch (error) {
  //       toast.error("Something went wrong");
  //     } finally {
  //       setModalButtonDisabled(false);
  //       data.id = "";
  //       data.index = null;
  //       data.show = false;
  //       setDeleteShowModal(data);
  //     }
  //   }
  // };

  const fetchallAcademicReasons = async () => {
    try {
      const fetchReason = await Network.get(API.planAssessmentReasonList, {
        workspaceId: selectedWorkspace,
        search: reasonSearch,
        limit: 100,
      });
      if (fetchReason !== undefined && fetchReason.status === 200) {
        if (assessmentData?.reasonId !== null) {
          let x = fetchReason.data.response.dataset.filter(
            (e) => e.id === assessmentData?.reasonId
          );
          if (x?.length > 0) {
            setSelectedReason({
              value: x[0].id,
              label: x[0].title,
            });
          }
        }
        setReason([
          { value: 'Add New', label: 'Add new' },
          ...fetchReason.data.response.dataset.map((e) => {
            return {
              value: e.id,
              label: e.title,
            };
          }),
        ]);
      }
    } catch (error) {
      toast.error('Something went wrong', toastConfig);
    }
  };

  const changeAssessmentComplete = (complete) => {
    setComplete(complete);
  };

  const changeAssessmentStatus = (e) => {
    setStatus(e);
  };

  const SelectReasonFromSelectBox = (e) => {
    if (!e) {
      setSelectedReason({
        value: '',
        label: 'Select a reason',
      });
      return;
    }
    if (e.value === 'Add New') {
      setShowModal(true);
      return;
    }
    setSelectedReason(e);
  };

  const onModalConfirm = async () => {
    if (newReasonValue.length === 0) {
      return;
    }

    setButtonDisable(true);
    try {
      let postReason = await Network.post(API.createPlanAssessmentReason, {
        title: newReasonValue,
        workspaceId: selectedWorkspace,
      });
      if (postReason !== undefined && postReason.status === 200) {
        let res = {
          value: postReason.data.response.dataset[0].id,
          label: postReason.data.response.dataset[0].title,
        };
        setReason((prevState) => [...prevState, res]);
        setSelectedReason(res);
        toast.success('Reason added successfully', toastConfig);
      }
    } catch (error) {
      toast.error('Something went wrong', toastConfig);
    } finally {
      setShowModal(false);
      setButtonDisable(false);
    }
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const updatemeasure = (e, idx) => {
    let data = { ...assessment };
    data.measures[idx].title = e.target.value;
    setAssessment(data);
  };

  const saveData = async () => {
    let lessonData = getValues();
    let data = {
      comment: assessmentComment,
      id: assessId,
      trainingObjectiveId: ObjectiveId,
      workspaceId: selectedWorkspace,
      complete: complete,
      status: status,
    };

    if (complete === false) {
      data.status = 'Untrained';
    }

    if (!(complete === true && status === 'Trained')) {
      if (selectedReason.value !== '') {
        data.reasonId = selectedReason.value;
      }
    }
    if (assessment?.measures?.length > 0) {
      data.measures = assessment.measures.map((obj) => {
        return {
          measureId: obj.measureId,
          title: obj.title,
          assessmentId: assessId,
          trainingObjectiveId: ObjectiveId,
        };
      });
    }
    if (
      lessonData.lesson.length > 0 &&
      fields.length > 0 &&
      assessData?.lessonsLearned === 'Yes'
    ) {
      data.lessonLearned = true;
      data.lessionlearned = lessonData.lesson.map((e) => {
        return {
          potentialOPR: e?.potentialOPR,
          title: e?.title,
          observation: e?.observation,
          discussion: e?.discussion,
          recommendation: e?.recommendation,
          implications: e?.implications,
          eventDescription: e?.eventDescription,
          comments: e?.comments,
        };
      });
    } else {
      data.lessonLearned = false;
      data.lessionlearned = [];
    }
    if (newAllMetric.length > 0) {
      data.metric = true;
      data.metrics = newAllMetric.map((item) => {
        return {
          metricsId: item.value,
          task: item.label,
          assessmentData: item.completed !== '' ? Number(item.completed) : 0,
        };
      });
    } else {
      data.metric = false;
    }
    setButtonDisable(true);
    dispatch(startLoading());
    try {
      let updateAssessment = await Network.post(API.assess, {
        ...data,
      });
      if (updateAssessment !== undefined && updateAssessment.status === 200) {
        toast.success('Assessment updated successfully', toastConfig);
        cancelEdit();
      }
    } catch (error) {
      toast.error('Something went wrong', toastConfig);
    } finally {
      dispatch(endLoading());
      setButtonDisable(false);
    }
  };

  const cancelEdit = () => {
    navigate(-1);
  };

  const columns = [
    {
      dataField: 'date',
      text: 'Date',
      attrs: { title: 'Date' },
    },
    {
      dataField: 'participantPlatform',
      text: 'Participant Platform',
      attrs: { title: 'Participant Platform' },
    },
    {
      dataField: 'traningObjective',
      text: ' Objective',
      attrs: { title: 'Trainging Objective' },
    },
    {
      dataField: 'requestedMethod',
      text: 'Requested Method',
      attrs: { title: 'Requested Method' },
    },
    {
      dataField: 'trainingMethod',
      text: ' Method',
      attrs: { title: ' Method' },
    },
    {
      dataField: 'associatedInject',
      text: 'Associated Inject',
      attrs: { title: 'Associated Inject' },
    },
  ];

  //Add your search logic here.
  const customFilter = (option, searchText, type) => {
    if (option.value === 'Add New') return true;
    if (option.data.label.toLowerCase().includes(searchText.toLowerCase())) {
      if (type === 'reason') {
        setReasonSearch(searchText);
      } else if (type === 'metric') {
        setmetricSearch(searchText);
      }
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      <CustomModal
        show={showModalMetric}
        heading={modalFor}
        onClose={closeReasonModal}
        onConfirm={onModalConfirmMetric}
        saveButton="save"
        buttonDisable={modalButtonDisabled}
      >
        <div className="mb-3">
          <label className="form-label">Title</label>
          <StylishNewInput
            className="form-control"
            autoFocus={true}
            type="text"
            onChange={modalInputChange}
          />
        </div>
        <div className="mb-0">
          <label className="form-label">Type</label>
          <StylishNewInput
            className="form-control"
            autoFocus={false}
            type="text"
            defaultValue={assessmentData?.type}
            readOnly
          />
        </div>
      </CustomModal>

      <CustomModal
        show={showDeleteModal?.show}
        heading={modalFor}
        onClose={() => setDeleteShowModal(false)}
        onConfirm={confirmDeleteModal}
        saveButton="Ok"
        buttonDisable={modalButtonDisabled}
      >
        <p>Are you sure you want to do this?</p>
      </CustomModal>

      {showModal ? (
        <CustomModal
          show={showModal}
          heading="Ineffective Reason"
          onClose={closeModal}
          onConfirm={onModalConfirm}
          saveButton="save"
          buttonDisable={buttonDisable}
        >
          <Form.Control
            autoFocus={true}
            type="text"
            onChange={(e) => setNewReasonValue(e.target.value)}
          />
        </CustomModal>
      ) : null}
      <div className="form-block mb-4">
        <StylishNewTable
          keyField={'id'}
          columns={columns}
          rows={detailedRow}
          classes="no-filter"
          headerClasses="empty"
        />
        <div className="row mt-4">
          <div className="col-md-6 mb-3">
            <label className="form-label">Task</label>
            <StylishNewTextArea
              // maxLength={1500}
              className="form-control form-textarea"
              readOnly
              value={assessment?.task?.length > 0 ? assessment?.task : ''}
            ></StylishNewTextArea>
          </div>
          <div className="col-md-6 mb-3">
            <label className="form-label">Condition</label>
            <StylishNewTextArea
              // maxLength={1500}
              className="form-control form-textarea"
              readOnly
              value={
                assessment?.condition?.length > 0 ? assessment?.condition : ''
              }
            ></StylishNewTextArea>
          </div>
          <div className="col-md-12 mb-3">
            <label className="form-label">Standard</label>
            <StylishNewTextArea
              // maxLength={1500}
              className="form-control form-textarea"
              readOnly
              value={
                assessment?.standard?.length > 0 ? assessment?.standard : ''
              }
            ></StylishNewTextArea>
          </div>
          {assessment?.injects?.length > 0 ? (
            <div className="col-md-12">
              <label className="form-label">Injects</label>
              <p className="m-0">
                {assessment?.injects.map((inject, idx) => (
                  <span key={idx} className="d-block">
                    #{inject?.number} - {inject?.title} -{' '}
                    {moment(inject?.responseDate).format('DD MMM YY - hhmm')}
                  </span>
                ))}
              </p>
            </div>
          ) : (
            <></>
          )}
        </div>
        <hr className="dashed my-4" />
        <div className="row">
          <div className="col-md-12 mb-3">
            <label className="form-label">Was this objective completed?</label>
            <div className="form-radio-group mt-1">
              <div className="form-radio">
                <StylishNewInput
                  type="radio"
                  id="check__yes"
                  name="check__completed"
                  checked={complete === true ? true : false}
                  onChange={() => changeAssessmentComplete(true)}
                />
                <span className="icon"></span>
                <label className="form-label weight-400" htmlFor="check__yes">
                  Yes
                </label>
              </div>
              <div className="form-radio">
                <StylishNewInput
                  type="radio"
                  id="check__no"
                  name="check__completed"
                  checked={complete === false ? true : false}
                  onChange={() => changeAssessmentComplete(false)}
                />
                <span className="icon"></span>
                <label className="form-label weight-400" htmlFor="check__no">
                  No
                </label>
              </div>
            </div>
          </div>
          {complete === true ? (
            <div className="col-md-12 mb-3">
              <label className="form-label">
                To which level of effectiveness was this objective completed?
              </label>
              <div className="form-radio-group mt-1">
                <div className="form-radio">
                  <StylishNewInput
                    type="radio"
                    id="check__trained"
                    name="check__level"
                    checked={status === 'Trained' ? true : false}
                    onChange={() => changeAssessmentStatus('Trained')}
                  />
                  <span className="icon"></span>
                  <label
                    className="form-label weight-400"
                    htmlFor="check__trained"
                  >
                    Trained
                  </label>
                </div>
                <div className="form-radio">
                  <StylishNewInput
                    type="radio"
                    id="check__partially-trained"
                    name="check__level"
                    checked={status === 'Partially Trained' ? true : false}
                    onChange={() => changeAssessmentStatus('Partially Trained')}
                  />
                  <span className="icon"></span>
                  <label
                    className="form-label weight-400"
                    htmlFor="check__partially-trained"
                  >
                    Partially Trained
                  </label>
                </div>
                <div className="form-radio">
                  <StylishNewInput
                    type="radio"
                    id="check__no"
                    name="check__untrained"
                    checked={status === 'Untrained' ? true : false}
                    onChange={() => changeAssessmentStatus('Untrained')}
                  />
                  <span className="icon"></span>
                  <label
                    className="form-label weight-400"
                    htmlFor="check__untrained"
                  >
                    Untrained
                  </label>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
          {/* if  is completed then dont show  reason select ...... */}
          {complete === true && status === 'Trained' ? (
            <></>
          ) : (
            <div className="col-md-12 mb-3">
              <label className="form-label">
                Why was the objective not effective?
              </label>
              <StylishNewSelect
                options={reason}
                placeholder={'Select a Reason'}
                isClearable={selectedReason?.value !== '' ? true : false}
                isSearchable={true}
                isMulti={false}
                filterOption={(option, searchText) =>
                  customFilter(option, searchText, 'reason')
                }
                isDisabled={false}
                onChange={(e) => SelectReasonFromSelectBox(e)}
                value={selectedReason}
              />
            </div>
          )}

          <div className="col-md-12">
            <label className="form-label">Comment</label>
            <StylishNewTextArea
              maxLength={1500}
              className="form-control form-textarea"
              value={assessmentComment?.length > 0 ? assessmentComment : ''}
              onChange={(e) => setAssessmentComments(e.target.value)}
            ></StylishNewTextArea>
          </div>
        </div>
        {assessment.measures && assessment.measures.length > 0 ? (
          <div className="row mt-4">
            <div className="col-md-12">
              <h4 className="m-0">Measures</h4>
              <div className="row">
                {assessment?.measures.map((m, idx) => (
                  <div key={m?.measureId} className="col-md-12 mt-4">
                    <label className="form-label weight-500">
                      {m?.name != '' ? m?.name : `M${idx + 1}`}
                    </label>
                    <StylishNewInput
                      maxLength={inputStringLength}
                      type="text"
                      value={m?.title ? m?.title : ''}
                      className="form-control"
                      onChange={(e) => updatemeasure(e, idx)}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        ) : null}
      </div>
      <div className="form-block mb-4">
        <h4 className="mb-4">Metrics</h4>
        {newAllMetric.map((metric, index) => (
          <div key={index} className="row">
            <div className="col-md-6 mb-3">
              <label className="form-label">Metric</label>
              <StylishNewInput
                type="text"
                className="form-control"
                readOnly
                value={metric?.label ? metric?.label : ''}
              />
            </div>
            <div className="col-md-6 mb-3">
              <label className="form-label">Number Completed</label>
              <StylishNewInput
                type="text"
                className="form-control"
                value={metric?.completed ? metric?.completed : 0}
                onKeyDown={onKeyPressEvent}
                onChange={(e) => updateAllMetric(e, index)}
              />
            </div>
            <div className="col-md-12 button-group">
              <StylishNewButton
                type="button"
                className="button--icon primary"
                onClick={() => removeMetric(metric, index)}
              >
                <SharedIcon iconName="delete" />
              </StylishNewButton>
            </div>
            <div className="col-md-12">
              <hr className="dashed my-4" />
            </div>
          </div>
        ))}

        {/* select and input for complete ... */}
        {showMetric ? (
          <div className="row">
            <div className="col-md-6 mb-3">
              <label className="form-label">Metric</label>
              <StylishNewSelect
                options={metric.filter((item) => {
                  return (
                    newAllMetric.filter((item2) => {
                      return item2.value == item.value;
                    }).length == 0
                  );
                })}
                // options={metric}
                isClearable={selectedMetric.value === '' ? false : true}
                placeholder="Select a Metric"
                filterOption={(option, searchText) =>
                  customFilter(option, searchText, 'metric')
                }
                onChange={(e) => selectMetricFromSelectBox(e)}
                value={selectedMetric}
              />
              {metricError === true && selectedMetric.value === '' ? (
                <span className="form-text form-error">
                  This is a required field
                </span>
              ) : null}
            </div>
            <div className="col-md-6 mb-3">
              <label className="form-label">Number Completed</label>
              <StylishNewInput
                type="number"
                className="form-control"
                placeholder="0"
                onKeyDown={onKeyPressEvent}
                onChange={(e) => setMetricCompleted(e.target.value)}
              />
            </div>
            <div className="col-md-12 button-group">
              <StylishNewButton
                className="button button--primary button--sml rounded-5"
                type="submit"
                onClick={saveAllNewMetric}
              >
                Save
              </StylishNewButton>
              <StylishNewButton
                className="button button--secondary button--reverse button--sml rounded-5"
                onClick={cancelSetShowMetric}
              >
                Cancel
              </StylishNewButton>
            </div>
            <div className="col-md-12">
              <hr className="dashed my-4" />
            </div>
          </div>
        ) : (
          <></>
        )}

        <StylishNewButton
          className="button button--primary"
          onClick={() => {
            setShowMetric(true), fetchMetric();
          }}
        >
          Add
        </StylishNewButton>
      </div>
      <div className="form-block mb-4">
        <h4 className="mb-4">Lessons Learned</h4>
        <div className="mb-4">
          <label className="form-label">
            Do you have lessons learned to record for this day?
          </label>
          <div className="form-radio-group mt-1">
            <div className="form-radio">
              <StylishNewInput
                type="radio"
                id="check__lesson__yes"
                name="check__lesson__completed"
                readOnly
                checked={assessData.lessonsLearned === 'Yes' ? true : false}
                onChange={() => changeFeedbackLessonsLearned('Yes')}
              />
              <span className="icon"></span>
              <label
                className="form-label weight-400"
                htmlFor="check__lesson__yes"
              >
                Yes
              </label>
            </div>
            <div className="form-radio">
              <StylishNewInput
                type="radio"
                id="check__lesson__no"
                name="check__lesson__completed"
                readOnly
                checked={assessData.lessonsLearned === 'No' ? true : false}
                onChange={() => changeFeedbackLessonsLearned('No')}
              />
              <span className="icon"></span>
              <label
                className="form-label weight-400"
                htmlFor="check__lesson__no"
              >
                No
              </label>
            </div>
          </div>
        </div>
        {assessData.lessonsLearned === 'Yes' ? (
          <>
            <form>
              <hr className="dashed mt-0 mb-4" />
              {fields.map((lesson, idx) => (
                <div className="row" key={lesson.id}>
                  <div className="col-md-6 mb-3">
                    <label className="form-label">Potential OPR</label>
                    <Controller
                      control={control}
                      name={`lesson.${idx}.potentialOPR`}
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <StylishNewInput
                          type="text"
                          className="form-control"
                          onChange={onChange}
                          value={value}
                        />
                      )}
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <label className="form-label">Classification</label>
                    <StylishNewInput
                      type="text"
                      className="form-control"
                      value="Unclassified"
                      readOnly
                    />
                  </div>
                  <div className="col-md-12 mb-3">
                    <label className="form-label">Title</label>
                    <Controller
                      control={control}
                      name={`lesson.${idx}.title`}
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <StylishNewInput
                          type="text"
                          className="form-control"
                          onChange={onChange}
                          value={value}
                        />
                      )}
                    />
                  </div>
                  <div className="col-md-12 mb-3">
                    <label className="form-label">Observation</label>
                    <Controller
                      control={control}
                      name={`lesson.${idx}.observation`}
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <StylishNewTextArea
                          className="form-control form-textarea"
                          onChange={onChange}
                          value={value}
                        ></StylishNewTextArea>
                      )}
                    />
                  </div>
                  <div className="col-md-12 mb-3">
                    <label className="form-label">Discussion</label>
                    <Controller
                      control={control}
                      name={`lesson.${idx}.discussion`}
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <StylishNewTextArea
                          className="form-control form-textarea"
                          onChange={onChange}
                          value={value}
                        ></StylishNewTextArea>
                      )}
                    />
                  </div>
                  <div className="col-md-12 mb-3">
                    <label className="form-label">Recommendation</label>
                    <Controller
                      control={control}
                      name={`lesson.${idx}.recommendation`}
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <StylishNewTextArea
                          className="form-control form-textarea"
                          onChange={onChange}
                          value={value}
                        ></StylishNewTextArea>
                      )}
                    />
                  </div>
                  <div className="col-md-12 mb-3">
                    <label className="form-label">Implications</label>
                    <Controller
                      control={control}
                      name={`lesson.${idx}.implications`}
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <StylishNewTextArea
                          className="form-control form-textarea"
                          onChange={onChange}
                          value={value}
                        ></StylishNewTextArea>
                      )}
                    />
                  </div>
                  <div className="col-md-12 mb-3">
                    <label className="form-label">Event Description</label>
                    <Controller
                      control={control}
                      name={`lesson.${idx}.eventDescription`}
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <StylishNewTextArea
                          className="form-control form-textarea"
                          onChange={onChange}
                          value={value}
                        ></StylishNewTextArea>
                      )}
                    />
                  </div>
                  <div className="col-md-12 mb-3">
                    <label className="form-label">Comments</label>
                    <Controller
                      control={control}
                      name={`lesson.${idx}.comments`}
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <StylishNewTextArea
                          className="form-control form-textarea"
                          onChange={onChange}
                          value={value}
                        ></StylishNewTextArea>
                      )}
                    />
                  </div>
                  <div className="col-md-12 button-group">
                    <StylishNewButton
                      type="button"
                      className="button--icon primary"
                      onClick={() => {
                        deleteAssessmentLesson(idx);
                        // remove(idx);
                        // fields.length === 1 &&
                        //   changeFeedbackLessonsLearned("No");
                      }}
                    >
                      <SharedIcon iconName="delete" />
                    </StylishNewButton>
                  </div>
                  <div className="col-md-12">
                    <hr className="dashed my-4" />
                  </div>
                </div>
              ))}
            </form>
            <StylishNewButton
              className="button button--primary"
              onClick={() =>
                append({
                  classification: 'Unclassified',
                  comments: '',
                  discussion: '',
                  eventDescription: '',
                  implications: '',
                  observation: '',
                  potentialOPR: '',
                  recommendation: '',
                  title: '',
                })
              }
            >
              Add
            </StylishNewButton>
          </>
        ) : null}
      </div>
      <div className="mt-4">
        <div className="button-group">
          <StylishNewButton
            className="button button--primary ms-auto"
            disabled={buttonDisable ? true : false}
            onClick={saveData}
          >
            Save
          </StylishNewButton>
          <StylishNewButton
            className="button button--secondary button--reverse"
            onClick={cancelEdit}
          >
            Cancel
          </StylishNewButton>
        </div>
      </div>
    </>
  );
};

export default EditAssessmentDod;
