import React, { useState, useEffect } from 'react';
import moment from 'moment/moment';
import { toast } from 'react-toastify';
import Network from '../../../../service/Network';
import API from '../../../../service/api';
import { Modal } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { inputStringLength, toastConfig } from 'assets/data/config';
import { useNavigate } from 'react-router-dom';
import StylishNewInput from 'components/DesignSystems/New/StylishNewInput';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import StylishDateTimePicker from 'components/DesignSystems/form/StylishDateTimePicker';
import { PreparePath } from 'utils/sharedUtils/sharedConst';
import {
  endLoading,
  startLoading,
} from '../../../../../reducers/loading/loading.action';

export const AddEditCustomReportModal = ({
  show,
  onClose,
  dialogType,
  updateDialogData,
}) => {
  const {
    register,
    handleSubmit,
    control,
    setError,
    clearErrors,
    setValue,
    formState: { errors },
  } = useForm();

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const workspaceId = useSelector(
    (state) => state.prepare.workspace.selectedWorkspace
  );
  const reduxTimezone = useSelector(
    (state) => state.prepare.workspace.timezone
  );
  const reduxUser = useSelector((state) => state.prepare.user);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (dialogType === 'Edit') {
      updateDialogData?.endDate &&
        setValue('endingDate', moment(updateDialogData?.endDate).toDate());
      updateDialogData?.startDate &&
        setValue('startingDate', moment(updateDialogData?.startDate).toDate());
      updateDialogData?.startDate &&
        setStartDate(moment(updateDialogData?.startDate).toDate());
    }
  }, [dialogType]);

  const onSubmit = async (data) => {
    if (data.endingDate > data.startingDate) {
      dispatch(startLoading());
      try {
        const newCustomReport = await Network.post(
          dialogType === 'Add' ? API.addCustomReport : API.editCustomReport,
          {
            workspaceId: workspaceId,
            title: data.title,
            startDate: data.startingDate,
            endDate: data.endingDate,
            reportId: updateDialogData?.id,
          }
        );
        onClose(newCustomReport.data.response);
        let id = newCustomReport.data.response.dataset[0].id;
        if (dialogType === 'Add') {
          navigate(`${PreparePath}/dashboard/plan/reports/${id}`);
        }
      } catch (error) {
        toast.error(error?.response?.data?.response?.status.msg, toastConfig);
      } finally {
        dispatch(endLoading());
      }
    } else {
      setError('endingDate', {
        message: 'End date cannot be earlier than start date',
        type: 'required',
      });
      return;
    }
  };

  useEffect(() => {
    const listener = (event) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        event.preventDefault();
        handleSubmit(onSubmit)();
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, []);

  return (
    <Modal
      show={show}
      onHide={onClose}
      centered
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title>{dialogType} Custom Analytics</Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <div className="mb-3">
            <label className="form-label">Title</label>
            <Controller
              control={control}
              name="title"
              defaultValue={updateDialogData?.title}
              rules={{ required: true }}
              maxLength={inputStringLength}
              render={({ field: { onChange, value } }) => (
                <StylishNewInput
                  type="text"
                  className="form-control"
                  placeholder="Title"
                  onChange={onChange}
                  value={value}
                />
              )}
            />
            {errors?.title && (
              <span className="form-text form-error">
                This field is required
              </span>
            )}
          </div>

          <div className="mb-3">
            <label className="form-label">
              Start ({reduxTimezone.split('/')[0]})
            </label>
            <Controller
              control={control}
              name="startingDate"
              rules={{ required: true }}
              render={({ field: { value, onChange } }) => (
                <StylishDateTimePicker
                  type="datetime-local"
                  timeFormat="HH:mm"
                  timeIntervals={20}
                  timeCaption="time"
                  dateFormat={
                    reduxUser?.timeFormat === 'hour12Mode'
                      ? 'MMMM d, yyyy hh:mm a'
                      : 'MMMM d, yyyy HH:mm'
                  }
                  isClearable={false}
                  maxDate={moment().toDate()}
                  placeholderText={'Choose Start Date'}
                  onChange={(date) => {
                    onChange(date);
                    setStartDate(date);
                    setValue('startingDate', date);
                    if (date) {
                      clearErrors('startingDate');
                    }
                    if (
                      (date && moment(date).isAfter(moment(endDate))) ||
                      (date && !endDate)
                    ) {
                      clearErrors('startingDate');
                      clearErrors('endingDate');

                      setEndDate(new Date(moment(date).toISOString()));
                      setValue(
                        'endingDate',
                        new Date(moment(date).toISOString())
                      );
                    }

                    // Check for end time being less than or equal to start time
                    if (
                      endDate &&
                      moment(date).isSameOrAfter(moment(endDate))
                    ) {
                      setError('endingDate', {
                        type: 'manual',
                        message: 'End time cannot be same as start time',
                      });
                    } else {
                      clearErrors('endingDate');
                    }
                  }}
                  value={value || startDate}
                  startDate={value || startDate}
                  endDate={endDate}
                />
              )}
            />
            {errors.startingDate && (
              <span className="form-text form-error">
                This is a required field
              </span>
            )}
          </div>
          <div className="">
            <label className="form-label">
              End ({reduxTimezone.split('/')[0]})
            </label>
            <Controller
              control={control}
              name="endingDate"
              rules={{ required: true }}
              render={({ field: { value, onChange } }) => (
                <StylishDateTimePicker
                  type="datetime-local"
                  timeFormat="HH:mm"
                  timeIntervals={20}
                  timeCaption="time"
                  dateFormat={
                    reduxUser?.timeFormat === 'hour12Mode'
                      ? 'MMMM d, yyyy hh:mm a'
                      : 'MMMM d, yyyy HH:mm'
                  }
                  startDate={new Date(moment(startDate).toISOString())}
                  onChange={(date) => {
                    onChange(date);

                    // Check for start time being greater than or equal to end time
                    if (
                      startDate &&
                      moment(date).isSameOrBefore(moment(startDate))
                    ) {
                      setError('endingDate', {
                        type: 'manual',
                        message: 'End time cannot be same as start time',
                      });
                    } else {
                      clearErrors('endingDate');
                    }

                    if (!date) {
                      setEndDate(moment().toDate());
                      setValue('endingDate', null);
                    }

                    if (!!date && !!!startDate) {
                      clearErrors('endingDate');
                      setEndDate(date);
                      setValue('endingDate', date);
                    }
                  }}
                  value={value || endDate}
                  endDate={value || endDate}
                  minDate={
                    !!startDate
                      ? new Date(moment(startDate).toISOString())
                      : null
                  }
                  maxDate={moment().toDate()}
                  isClearable={false}
                  placeholderText={'Choose End Date'}
                />
              )}
            />
            {errors.endingDate && (
              <span className="form-text form-error">
                {errors.endingDate.message || 'This is a required field'}
              </span>
            )}
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div className="button-group">
            <StylishNewButton
              className="button button--secondary button--reverse"
              onClick={onClose}
            >
              Close
            </StylishNewButton>
            <StylishNewButton className="button button--primary" type="submit">
              Save
            </StylishNewButton>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
};
