import { Modal } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import Network from 'PREPAREsrc/service/Network';
import API from 'PREPAREsrc/service/api';
import {
  inputStringLength,
  textareaStringLength,
  toastConfig,
} from 'assets/data/config';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import StylishNewInput from 'components/DesignSystems/New/StylishNewInput';
import StylishNewTextArea from 'components/DesignSystems/New/StylishNewTextArea';
import StylishDateTimePicker from 'components/DesignSystems/form/StylishDateTimePicker';
import moment from 'moment';
import { toast } from 'react-toastify';
import {
  endLoading,
  startLoading,
} from '../../../../../reducers/loading/loading.action';

export const AddEditDetailsDialog = ({ show, onClose, data }) => {
  const {
    handleSubmit,
    control,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();

  const dispatch = useDispatch();

  const { selectedWorkspace } = useSelector(
    (state) => state?.prepare?.workspace
  );

  const { eventId } = useSelector((state) => state?.prepare?.planDashboard);

  const { timezone } = useSelector((state) => state?.prepare?.workspace);
  const { timeFormat } = useSelector((state) => state?.prepare?.user);

  useEffect(() => {
    if (data) {
      setValue('primary_host', data?.primary_host);
      setValue('secondary_host', data?.secondary_host);
      setValue('startDate', data?.startDate);
      setValue('endDate', data?.endDate);
      setValue('purpose', data?.purpose);
      setValue('background_statement', data?.background_statement);
      setValue('event_location', data?.event_location);
    } else {
      setValue('startDate', moment());
      setValue('endDate', moment());
    }
  }, []);

  useEffect(() => {
    if (moment(getValues('startDate')).isAfter(moment(getValues('endDate')))) {
      setValue('endDate', moment(getValues('startDate')));
    }
  }, [watch(['startDate', 'endDate'])]);

  const onSubmit = async (formData) => {
    const data = {
      ...formData,
      id: eventId,
      workspaceId: selectedWorkspace,
    };
    dispatch(startLoading());
    try {
      const response = await Network.post(API.updateEvent, data);
      if (response !== undefined && response?.status === 200) {
        onClose(true);
      }
    } catch (error) {
      toast.error(error?.response?.data?.response?.status?.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };

  return (
    <Modal
      show={show}
      onHide={onClose}
      centered
      backdrop="static"
      keyboard={false}
      size="lg"
    >
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title>
          Experimental Event <br className="d-inline d-md-none" />
          Details
        </Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <div className="row">
            <div className="col-md-6 mb-4">
              <label className="form-label">
                Host (Primary)
                <span aria-label="Required field" className="required">
                  *
                </span>
              </label>
              <Controller
                control={control}
                name="primary_host"
                rules={{
                  required: true,
                  maxLength: inputStringLength,
                }}
                render={({ field: { onChange, value } }) => (
                  <StylishNewInput
                    type="text"
                    onChange={onChange}
                    value={value || ''}
                  />
                )}
              />
              {errors.primary_host?.type === 'required' && (
                <span className="form-text form-error">
                  This field is required
                </span>
              )}
              {errors?.primary_host?.type === 'maxLength' && (
                <span className="form-text form-error">
                  Max {inputStringLength} character allowed
                </span>
              )}
            </div>
            <div className="col-md-6 mb-4">
              <label className="form-label">
                Host (Secondary)
                <span aria-label="Required field" className="required">
                  *
                </span>
              </label>
              <Controller
                control={control}
                name="secondary_host"
                rules={{
                  required: true,
                  maxLength: inputStringLength,
                }}
                render={({ field: { onChange, value } }) => (
                  <StylishNewInput
                    type="text"
                    onChange={onChange}
                    value={value || ''}
                  />
                )}
              />
              {errors.secondary_host?.type === 'required' && (
                <span className="form-text form-error">
                  This field is required
                </span>
              )}
              {errors?.secondary_host?.type === 'maxLength' && (
                <span className="form-text form-error">
                  Max {inputStringLength} character allowed
                </span>
              )}
            </div>
            <div className="col-md-6 mb-4">
              <label className="form-label">
                Start Date ({timezone.split('/')[0]})
              </label>
              <Controller
                control={control}
                name="startDate"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <StylishDateTimePicker
                    onChange={(e) => onChange(e)}
                    value={value}
                    dateFormat={
                      timeFormat === 'hour12Mode'
                        ? 'MMMM d, yyyy hh:mm a'
                        : 'MMMM d, yyyy HH:mm'
                    }
                    placeholderText={'Choose Start Date'}
                    type="datetime-local"
                    dayPlaceholder="DD"
                    monthPlaceholder="MM"
                    yearPlaceholder="YYYY"
                    disableDateTimeLabel
                  />
                )}
              />
              {errors.startDate?.type === 'required' && (
                <span className="form-text form-error">
                  This is a required field
                </span>
              )}
            </div>
            <div className="col-md-6 mb-4">
              <label className="form-label">
                End Date ({timezone.split('/')[0]})
              </label>
              <Controller
                control={control}
                name="endDate"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <StylishDateTimePicker
                    onChange={(e) => onChange(e)}
                    value={value}
                    dateFormat={
                      timeFormat === 'hour12Mode'
                        ? 'MMMM d, yyyy hh:mm a'
                        : 'MMMM d, yyyy HH:mm'
                    }
                    placeholderText={'Choose End Date'}
                    type="datetime-local"
                    dayPlaceholder="DD"
                    monthPlaceholder="MM"
                    yearPlaceholder="YYYY"
                    disableDateTimeLabel
                    minDate={watch('startDate')}
                  />
                )}
              />
              {errors.endDate?.type === 'required' && (
                <span className="form-text form-error">
                  This is a required field
                </span>
              )}
            </div>
            <div className="col-md-12 mb-4">
              <label className="form-label">
                Purpose
                <span aria-label="Required field" className="required">
                  *
                </span>
              </label>
              <Controller
                control={control}
                name="purpose"
                rules={{ required: true, maxLength: textareaStringLength }}
                render={({ field: { onChange, value } }) => (
                  <StylishNewTextArea value={value || ''} onChange={onChange} />
                )}
              />
              {errors?.purpose?.type === 'required' && (
                <span className="form-text form-error">
                  This field is required
                </span>
              )}
              {errors?.purpose?.type === 'maxLength' && (
                <span className="form-text form-error">
                  Max {textareaStringLength} character allowed
                </span>
              )}
            </div>
            <div className="col-md-12 mb-4">
              <label className="form-label">
                Background Statement
                <span aria-label="Required field" className="required">
                  *
                </span>
              </label>
              <Controller
                control={control}
                name="background_statement"
                rules={{ required: true, maxLength: textareaStringLength }}
                render={({ field: { onChange, value } }) => (
                  <StylishNewTextArea value={value || ''} onChange={onChange} />
                )}
              />
              {errors?.background_statement?.type === 'required' && (
                <span className="form-text form-error">
                  This field is required
                </span>
              )}
              {errors?.background_statement?.type === 'maxLength' && (
                <span className="form-text form-error">
                  Max {textareaStringLength} character allowed
                </span>
              )}
            </div>
            <div className="col-md-12">
              <label className="form-label">
                Test Event Location
                <span aria-label="Required field" className="required">
                  *
                </span>
              </label>
              <Controller
                control={control}
                name="event_location"
                rules={{ required: true, maxLength: textareaStringLength }}
                render={({ field: { onChange, value } }) => (
                  <StylishNewTextArea value={value || ''} onChange={onChange} />
                )}
              />
              {errors?.event_location?.type === 'required' && (
                <span className="form-text form-error">
                  This field is required
                </span>
              )}
              {errors?.event_location?.type === 'maxLength' && (
                <span className="form-text form-error">
                  Max {textareaStringLength} character allowed
                </span>
              )}
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div className="button-group">
            <StylishNewButton
              className="button--secondary button--reverse"
              onClick={() => onClose()}
            >
              Cancel
            </StylishNewButton>

            <StylishNewButton className="button--primary" type="submit">
              Save
            </StylishNewButton>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
};
