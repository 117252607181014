import { UPDATE_EVENT_ID } from "./PlanDashboard.constants";

export const selectedEventId = (eventId) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_EVENT_ID,
      payload: eventId,
    });
  };
};
