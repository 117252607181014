import React from 'react';
import { CollapsibleRow } from './formFields/RelationshipPickerField';

import { getComsTitle } from './hooks/incidentComsHooks';

export type CardType =
  | 'default'
  | 'name-description'
  | 'work-assignment'
  | 'coms';

type SelectableEntityCardSwitchProps = {
  cardType: CardType;
  entity: any;
  value: string[];
  toggleRelationship: (entity: any) => void;
};

const SelectableEntityCardSwitch: React.FC<SelectableEntityCardSwitchProps> = ({
  cardType,
  entity,
  value,
  toggleRelationship,
}) => {
  switch (cardType) {
    case 'name-description':
      return (
        <NameDescriptionCard
          entity={entity}
          value={value}
          toggleRelationship={toggleRelationship}
        />
      );
    case 'work-assignment':
      return (
        <WorkAssignmentCard
          entity={entity}
          value={value}
          toggleRelationship={toggleRelationship}
        />
      );
    case 'coms':
      return (
        <ComsCard
          entity={entity}
          value={value}
          toggleRelationship={toggleRelationship}
        />
      );
    default:
      return (
        <NameDescriptionCard
          entity={entity}
          value={value}
          toggleRelationship={toggleRelationship}
        />
      );
  }
};
export default SelectableEntityCardSwitch;

// @ts-ignore
const NameDescriptionCard = ({ entity, value, toggleRelationship }) => {
  return (
    <CollapsibleRow
      selected={value.includes(entity.id ?? '')}
      onClick={() => toggleRelationship(entity)}
      header={() => <span className="txt--semibold">{entity.name}</span>}
    >
      <div
        style={{
          marginTop: '1rem',
          borderTop: '1px solid var(--border-primary)',
          padding: '0.75rem 0 0.5rem 1.5rem',
        }}
      >
        <div className="txt--xsml text-muted">Description</div>
        <div className="ms-3 mt-1">{entity.description}</div>
      </div>
    </CollapsibleRow>
  );
};

const WorkAssignmentCard = ({
  // @ts-ignore
  entity,
  // @ts-ignore
  value,
  // @ts-ignore
  toggleRelationship,
}) => {
  return (
    <CollapsibleRow
      selected={value.includes(entity.work_assignment_id ?? '')}
      onClick={() => toggleRelationship(entity)}
      header={() => (
        <span className="txt--semibold">
          {entity.work_assignment?.substring(0, 40)}
        </span>
      )}
    >
      <div
        style={{
          marginTop: '1rem',
          borderTop: '1px solid var(--border-primary)',
          padding: '0.75rem 0 0.5rem 1.5rem',
        }}
      >
        {entity.branch && (
          <>
            <div className="txt--xsml text-muted">Branch</div>
            <div className="ms-3 mt-1">{entity.branch}</div>
          </>
        )}
        {entity.division && (
          <>
            <div className="txt--xsml text-muted">Division</div>
            <div className="ms-3 mt-1">{entity.division}</div>
          </>
        )}
      </div>
    </CollapsibleRow>
  );
};

// @ts-ignore
const ComsCard = ({ entity, value, toggleRelationship }) => {
  const formattedName = getComsTitle(entity);
  return (
    <CollapsibleRow
      selected={value.includes(entity.id ?? '')}
      onClick={() => toggleRelationship(entity)}
      header={() => <span className="txt--semibold">{formattedName}</span>}
    >
      <div
        style={{
          marginTop: '1rem',
          borderTop: '1px solid var(--border-primary)',
          padding: '0.75rem 0 0.5rem 1.5rem',
        }}
      >
        <div>{formattedName}</div>
      </div>
    </CollapsibleRow>
  );
};
