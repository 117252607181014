import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import NotificationLogScreen from './NotificationLogScreen';
import NotificationComposeScreen from './NotificationComposeScreen';
import { useNavigate } from 'react-router-dom';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { StylishNewToggleButtonGroup } from 'components/DesignSystems/New/StylishNewToggleButtonGroup';
import { SharedIcon } from 'components/SharedIcon/SharedIcon';
import { useNewUIFlag } from '../../utils/featureFlags';
import PageTitle from '../headers/PageTitle';

export default function NotificationsContent({ groupId }) {
  const newUI = useNewUIFlag();
  const navigate = useNavigate();
  const [viewType, setViewType] = useState('Notifications'); //state change b/t 1st and second statement w/i brackets
  const [selectedNotification, setSelectedNotification] = useState();
  const [currentLoggedInstance, setCurrentLoggedInstance] = useState();
  const [selectTemplateDialogOpen, setSelectTemplateDiaglogOpen] = useState(
    false
  );
  const [
    selectSendAttemptDialogOpen,
    setSelectSendAttemptDiaglogOpen,
  ] = useState(false);

  const reduxCurrentlySelectedGroup = useSelector((state) => {
    return state.app.currentlySelectedGroup;
  });

  const notificationsComposeRef = useRef();

  useEffect(() => {
    setSelectedNotification(false);
    setCurrentLoggedInstance(false);
    setViewType('Notifications');
  }, [groupId, reduxCurrentlySelectedGroup]);

  const toggleData = [
    { value: 'Notifications', label: 'Compose' },
    { value: 'Notifications Log', label: 'View Log' },
  ];

  const handleToggleButtonClick = (data) => {
    if (data === 'Notifications Log') {
      try {
        notificationsComposeRef.current.confirmSave(data);
      } catch (error) {
        return;
      }
    } else {
      setViewType(data);
    }
  };

  return (
    <>
      {newUI ? (
        <PageTitle title="Notifications" />
      ) : (
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <button onClick={() => navigate(-1)}>
                <SharedIcon iconName="keyboard_arrow_left" classes="me-2" />
                Back
              </button>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Notification
            </li>
          </ol>
        </nav>
      )}

      <div className="d-md-flex align-items-center mb-4">
        {viewType === 'Notifications' && (
          <>
            <div className="button-group d-block d-md-flex">
              <StylishNewButton
                className={'button--secondary w-100 w-md-auto'}
                onClick={() => setSelectTemplateDiaglogOpen(true)}
              >
                Select Template
              </StylishNewButton>
              <StylishNewButton
                className={
                  'button--secondary w-100 w-md-auto ms-0 ms-md-3 mt-2 mt-md-0 text-nowrap'
                }
                onClick={() =>
                  notificationsComposeRef.current.openManageDistributionListModal()
                }
              >
                Manage Relevant Team(s)
              </StylishNewButton>
            </div>
          </>
        )}

        {viewType === 'Notifications Log' && (
          <>
            <StylishNewButton
              className={'button--secondary button--reverse w-100 w-md-auto'}
              onClick={() => setSelectSendAttemptDiaglogOpen(true)}
            >
              Select Notification Log
            </StylishNewButton>
          </>
        )}
        <StylishNewToggleButtonGroup
          data={toggleData}
          selected={viewType}
          onChange={handleToggleButtonClick}
          className="ms-auto d-md-inline-flex mt-4 mt-md-0"
        />
      </div>

      {viewType === 'Notifications' && selectedNotification && (
        <div className="form-block d-flex align-items-center">
          <h4 className="m-0">{`Current template: ${selectedNotification.template_title} `}</h4>
          <StylishNewButton
            className={'button--primary ms-auto'}
            onClick={() => setSelectedNotification()}
          >
            Clear selection
          </StylishNewButton>
        </div>
      )}

      {(viewType === 'Notifications Log' && (
        <NotificationLogScreen
          selectSendAttemptDialogOpen={selectSendAttemptDialogOpen}
          currentLoggedInstance={currentLoggedInstance}
          setCurrentLoggedInstance={setCurrentLoggedInstance}
          selectedNotification={selectedNotification}
          setSelectSendAttemptDiaglogOpen={setSelectSendAttemptDiaglogOpen}
        />
      )) ||
        (viewType === 'Notifications' && (
          <NotificationComposeScreen
            ref={notificationsComposeRef}
            viewType={viewType}
            setViewType={setViewType}
            selectTemplateDialogOpen={selectTemplateDialogOpen}
            setSelectTemplateDiaglogOpen={setSelectTemplateDiaglogOpen}
            selectedNotification={selectedNotification}
            setSelectedNotification={setSelectedNotification}
            setCurrentLoggedInstance={setCurrentLoggedInstance}
          />
        ))}
    </>
  );
}
