import React from 'react'
import StylishIconButton from 'components/DesignSystems/buttons/StylishIconButton'
import './StylishButton.css'

export default function StylishButton({
  children, 
  red, 
  green, 
  white,
  black,
  type,
  disabled, 
  className,
  lessPadding,
  rounded,
  ...props
})  {
  let builtClassName = 'StylishButton';
  builtClassName = `${builtClassName}${!!lessPadding ? ' StylishButton--lessPadding' : ''}`;
  builtClassName = `${builtClassName}${!!red ? ' StylishButton--red' : ''}`;
  builtClassName = `${builtClassName}${!!green ? ' StylishButton--green' : ''}`;
  builtClassName = `${builtClassName}${!!white ? ' StylishButton--white' : ''}`;

  builtClassName = `${builtClassName}${!!black ? ' StylishButton--black' : ''}`;
  builtClassName = `${builtClassName}${(!!type && type==="black")? ' StylishButton--black' : ''}`;
  builtClassName = `${builtClassName}${!!disabled ? ' StylishButton--disabled' : ''}`
  builtClassName = `${builtClassName}${!!className
    ? ` ${className}`
    : ''
  }`

  return (
    <StylishIconButton
      {...props}
      disabled={disabled}
    >
      <button className={builtClassName} disabled={disabled} rounded={rounded ? 'true' : 'false'}{...props}>
        {children}
      </button>
    </StylishIconButton>
  ) 
}