import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import generateUUID from 'utils/sharedUtils/generateUUID';

import Info from 'components/DesignSystems/text/Info';
import Title from 'components/DesignSystems/text/Title';
import StylishNewSelect from 'components/DesignSystems/New/StylishNewSelect';
import StylishNewTable from 'components/DesignSystems/New/StylishNewTable';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';

import {
  fetchADLs,
  deleteADL,
  assignADL,
  unassignADL,
} from 'actions/alertActions';

import ManageAlertListDialog from './ManageAlertListDialog';

const columns = [
  {
    dataField: 'name',
    text: 'Name',
    sort: true,
    headerAttrs: { title: 'Sort By:' },
    attrs: { title: 'Name' },
  },
  {
    dataField: 'methods',
    text: 'Methods',
    sort: true,
    headerAttrs: { title: 'Sort By:' },
    attrs: { title: 'Methods' },
  },
];

export default function RiskLevels({
  riskLevels,
  setRiskLevels,
  aor = null,
  org,
}) {
  const reduxDispatch = useDispatch();

  const reduxCurrentlySelectedGroup = useSelector((state) => {
    return state.app.currentlySelectedGroup;
  });

  useEffect(() => {
    if (
      !!reduxCurrentlySelectedGroup &&
      !!reduxCurrentlySelectedGroup.group_guid &&
      !reduxADLs.length
    ) {
      reduxDispatch(fetchADLs());
    }
  }, [reduxCurrentlySelectedGroup]);

  const [selectedRiskLevel, setSelectedRiskLevel] = useState({
    label: riskLevels[0].name,
    value: riskLevels[0].id,
  });

  const [manageALOpen, setManageALOpen] = useState(false);
  const [selectedAL, setSelectedAL] = useState();

  // Filter Alert Lists by those that match per-risk-level
  const reduxADLs = useSelector((state) => {
    return state.app.ADLs.filter((adl) => {
      return !!adl.adls_per_rl.find((per) => {
        // If AOR then only show adls_per_rl for that AOR
        // Otherwise, only show adls_per_rl for NO AORs
        if (!!aor) {
          return per.adl_id === adl.id && per.aor_id === aor.id;
        } else {
          return per.adl_id === adl.id && !per.aor_id;
        }
      });
    });
  });

  function deleteALClicked(adl) {
    if (
      window.confirm('Delete this Alert List?  This action cannot be undone.')
    ) {
      reduxDispatch(deleteADL(adl.id));
    }
  }

  // Filter Alert Lists by per-risk-level (and aor if appropriate)
  // TODO filter out AOR stuff if no AOR
  const selectedable = reduxADLs.filter((adl) => {
    return !!adl.adls_per_rl.find(
      (per) =>
        per.adl_id === adl.id &&
        per.risk_level === selectedRiskLevel.value &&
        ((!!per.aor_id && !!aor && per.aor_id === aor.id) || !aor)
    );
  });

  const selectRow = {
    mode: 'checkBox',
    clickToSelect: true,
    selected: selectedable.map((s) => s.id),
    onSelect: (row, isSelect, rowIndex, e) => {
      const existingRow = selectedable.find((r) => r.id === row.id);
      if (!!existingRow) {
        const p = existingRow.adls_per_rl.find(
          (per) =>
            per.adl_id === existingRow.id &&
            per.risk_level === selectedRiskLevel.value
        );
        reduxDispatch(unassignADL(p.id));
      } else {
        reduxDispatch(
          assignADL(
            generateUUID(),
            reduxCurrentlySelectedGroup.group_guid,
            row.id,
            selectedRiskLevel.value,
            (!!aor && aor.id) || null
          )
        );
      }
    },
  };

  const expandRow = {
    parentClassName: 'parent-expand-tr',
    className: 'child-expand-tr',
    renderer: (row) => {
      return (
        <>
          <div>
            <StylishNewButton
              className="RL-action-button button--primary"
              onClick={() => {
                setSelectedAL(reduxADLs.find((adl) => adl.id === row.id));
                setManageALOpen(true);
              }}
            >
              Edit
            </StylishNewButton>
          </div>
          <div>
            <StylishNewButton
              className="RL-action-button button--primary"
              onClick={() => deleteALClicked(row)}
            >
              Delete
            </StylishNewButton>
          </div>
        </>
      );
    },
    showExpandColumn: true,
    expandByColumnOnly: true,
    expandHeaderColumnRenderer: ({ isAnyExpands }) => {
      if (isAnyExpands) {
        return <i className="fa fa-chevron-down" aria-hidden="true"></i>;
      }
      return <i className="fa fa-chevron-right" aria-hidden="true"></i>;
    },
    expandColumnRenderer: ({ expanded }) => {
      if (expanded) {
        return (
          <>
            <div className="d-flex align-items-center justify-content-md-center">
              <span className="me-2">Details</span>
              <i className="fa fa-chevron-down" aria-hidden="true"></i>
            </div>
          </>
        );
      }
      return (
        <>
          <div className="d-flex align-items-center justify-content-md-center">
            <span className="me-2">Details</span>
            <i className="fa fa-chevron-right" aria-hidden="true"></i>
          </div>
        </>
      );
    },
  };

  return (
    <>
      <h4>Risk Levels</h4>
      <div className="mb-3">
        <span className="d-block fst-italic">
          <span className="weight-700">Risk Levels</span> are standard across
          your Organization.
        </span>
        <span className="d-block fst-italic">
          <span className="weight-700">Alert Lists</span> notify Organization
          members when <span className="weight-700">Hazards</span> are
          identified at any <span className="weight-700">AOR</span> at the
          specified <span className="weight-700">Risk Level</span>.
        </span>
      </div>

      <label className="form-label">Risk Level</label>
      <StylishNewSelect
        classes={'mb-4'}
        value={selectedRiskLevel}
        options={riskLevels.map((r) => {
          return { label: r.name, value: r.id };
        })}
        onChange={(selection) => setSelectedRiskLevel(selection)}
      />

      {!!selectedRiskLevel && selectedRiskLevel.value !== 0 && (
        <>
          <hr className="dashed my-4" />
          <h4 className="form-label d-block">
            {selectedRiskLevel.label} Risk Alert Lists:
          </h4>
          <StylishNewButton
            className="button--primary mb-4"
            onClick={() => setManageALOpen(true)}
          >
            Add Alert List
          </StylishNewButton>
          {!!reduxADLs && (
            <div className="mb-4">
              <StylishNewTable
                keyField={'id'}
                columns={columns}
                rows={reduxADLs.map((adl) => {
                  return { ...adl, methods: adl.methods.join(', ') };
                })}
                selectRow={selectRow}
                expandRow={expandRow}
              />
            </div>
          )}
        </>
      )}

      {!!manageALOpen && (
        <ManageAlertListDialog
          show={manageALOpen}
          onClose={() => setManageALOpen(false)}
          al={selectedAL}
          rl={selectedRiskLevel}
          aor={aor}
        />
      )}
    </>
  );
}
