import React, { useState, useEffect, useRef } from 'react';

import { Link, NavLink, useNavigate, useParams } from 'react-router-dom';
import { Dropdown, Tab, Tabs } from 'react-bootstrap';
import IconDelete from 'assets/images/icon__delete.svg';
import ReactPaginate from 'react-paginate';
import Help from '../../../component/Help/Help';
import { ViewPersonaHelp, useSelfHostedKnowledgeBase } from 'assets/data/HelpContent';
import IconEdit from 'assets/images/icon__edit.svg';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { Tree, TreeNode } from 'react-organizational-chart';
import ProfileImage from 'assets/images/icon__profileimage.jpg';
import IconView from 'assets/images/icon__eye.svg';
import PrepareTree from '../../../component/PrepareTree/PrepareTree';
import Network from 'PREPAREsrc/service/Network';
import API from 'PREPAREsrc/service/api';
import { startLoading, endLoading } from 'reducers/loading/loading.action';
import { PreparePath } from 'utils/sharedUtils/sharedConst';
import { StylishConfirmDialog } from 'components/DesignSystems/New/StylishConfirmDialog';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { contentBlockLength, toastConfig } from 'assets/data/config';
import IconEmptyExercise from 'assets/images/icon__exercise-details-empty.svg';
import StylishNewInput from 'components/DesignSystems/New/StylishNewInput';
import StylishHelp from 'components/DesignSystems/New/StylishHelp';
import { sanitizeHtml } from '../../../../utils/sanitizeHtml';

export default function ViewPersona() {
  const [showRelationshipTreeView, setShowRelationshipTreeView] = useState(
    true
  );
  const [showRelationshipListView, setShowRelationshipListView] = useState(
    false
  );
  const [filteredRelationshipArray, setFilteredRelaionshipArray] = useState([]);
  const [detailsPersonaByID, setDetailsPersonaByID] = useState([]);
  const [nameOfPersona, setNameOfPersona] = useState('');
  const [deleteProfiles, setDeleteProfiles] = useState(null);
  const [connectedProfiles, setConnectedProfiles] = useState(null);
  const [filteredProfilesArray, setFilteredProfilesArray] = useState([]);
  const [deletePersona, setDeletePersona] = useState(null);

  const selfHostedKnowledgeBase = useSelfHostedKnowledgeBase();

  const params = useParams();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const workspaceId = useSelector(
    (state) => state.prepare.workspace.selectedWorkspace
  );
  const isMounted = useRef(true);

  const SeatBox = ({ className, seatName }) => {
    return (
      <div className={`chartBox${className ? ' ' + className : ''}`}>
        {seatName && <div className="chartBox__role">{seatName}</div>}
      </div>
    );
  };

  const mediaPersonaDetailsByPersonaId = () => {
    try {
      dispatch(startLoading());
      Network.get(API.mediaPersonaDetailsByPersonaId, {
        workspaceId: workspaceId,
        personaId: params?.id,
      })
        .then((resp) => {
          setDetailsPersonaByID(resp?.data?.response?.dataset[0]);
          setNameOfPersona(resp?.data?.response?.dataset[0]?.personaName);
          setFilteredRelaionshipArray(
            resp?.data?.response?.dataset[0]?.mediapersonarelatesto
          );
          setConnectedProfiles(resp?.data?.response?.dataset[0]?.profiles);
          const filteredProfilesArray = resp?.data?.response?.dataset[0]?.profiles?.map(
            function (mediaProfile) {
              return {
                value: mediaProfile?.id,
                avatar: mediaProfile?.avatar,
                label: mediaProfile?.name,
              };
            }
          );
          setFilteredProfilesArray(filteredProfilesArray);
        })
        .catch(console.log)
        .finally(dispatch(endLoading()));
    } catch (error) {
      toast.error(error.response.data.response.status.msg, toastConfig);
    }
  };

  useEffect(() => {
    mediaPersonaDetailsByPersonaId();
  }, []);

  useEffect(() => {
    if (isMounted.current) {
      isMounted.current = false;
      return;
    }
    if (!!workspaceId) {
      navigate(-1);
    }
  }, [workspaceId]);

  const ondeleteProfile = (row) => {
    setDeleteProfiles(row.id);
  };

  const onDeleteConfirmProfile = async () => {
    dispatch(startLoading());
    try {
      await Network.post(API.removeMediaPersonaProfile, {
        personaId: params?.id,
        profileId: deleteProfiles,
      });
      setFilteredProfilesArray(
        filteredProfilesArray.filter(
          (obj) => obj.mediaProfileId !== deleteProfiles
        )
      );
      setConnectedProfiles(
        connectedProfiles.filter((obj) => obj.id !== deleteProfiles)
      );
      setDeleteProfiles(null);

      toast.success('Persona removed successfully', toastConfig);
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(endLoading());
    }
  };

  const onDeletePersona = (id) => {
    setDeletePersona(id);
  };

  const onDeleteConfirm = async () => {
    dispatch(startLoading());
    try {
      await Network.post(API.deleteMediaPersona, {
        personaId: deletePersona,
      });
      setDeletePersona(null);
      toast.success('Persona deleted successfully', toastConfig);
      navigate(`${PreparePath}/dashboard/media/personas`);
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(endLoading());
    }
  };
  const onCreate = async (profile) => {
    dispatch(startLoading());
    try {
      const newProfile = await Network.post(API.createMediaPost, {
        status: 'drafted',
        mediaProfileId: profile?.id,
        workspaceId: workspaceId,
        type: 'post',
        mediaSiteId: profile?.siteId,
      });
      navigate(
        `${PreparePath}/dashboard/media/posts/edit-post/${newProfile?.data?.response?.MediaPost?.id}`
      );
    } catch (error) {
      console.log(error);
      toast.error('Something went wrong', toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };
  return (
    <>
      <div className="d-flex align-items-center mb-4">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb mb-0">
            <li className="breadcrumb-item">
              <StylishNewButton onClick={() => navigate(-1)}>
                <i className="fa fa-angle-left me-2" aria-hidden="true"></i>
                Back
              </StylishNewButton>
            </li>
            <li className="breadcrumb-item">
              <StylishNewButton
                onClick={() =>
                  navigate(`${PreparePath}/dashboard/media/personas`)
                }
              >
                Persona
              </StylishNewButton>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              {detailsPersonaByID?.personaName}
            </li>
          </ol>
        </nav>
        <StylishHelp
          classes={'flex-shrink-0 ms-auto sml'}
          title={ViewPersonaHelp.title}
          content={ViewPersonaHelp.content}
          link={selfHostedKnowledgeBase | ViewPersonaHelp.link}
        />
      </div>

      <div className="row mb-4">
        <div className="col-lg-6 col-md-6">
          <div className="bg-gray-800 p-4 h-md-100 mb-4 mb-lg-0">
            <div className="d-flex flex-column flex-md-row">
              {detailsPersonaByID?.avatar ? (
                <div className="profile-img profile-img__big flex-shrink-0">
                  <img src={detailsPersonaByID?.avatar} alt="" />
                </div>
              ) : (
                <div className="profile-img profile-img__big flex-shrink-0"></div>
              )}

              <div className="flex-grow-1 ps-md-4 pt-4 pt-md-0 d-flex">
                <div className="my-auto">
                  <h5 className="mb-1">{detailsPersonaByID?.personaName}</h5>
                  <p className="mb-4">{detailsPersonaByID?.personaRole}</p>
                  <label className="status bg-success px-3 py-1 weight-600">
                    UNCLASSIFIED
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-6">
          {detailsPersonaByID?.locationName ||
          detailsPersonaByID?.lat ||
          detailsPersonaByID?.lng ? (
            <div className="bg-gray-800 p-4 h-md-100">
              <h5 className="mb-1">Location</h5>
              <p className="mb-1">{detailsPersonaByID?.locationName}</p>
              <p className="mb-0">
                Lat/Lon: {detailsPersonaByID?.lat} {detailsPersonaByID?.lng}
              </p>
            </div>
          ) : (
            <div className="bg-gray-800 text-center h-100 d-flex flex-column">
              <div className="p-4 my-auto">
                <div className="d-flex justify-content-center mb-4">
                  <img src={IconEmptyExercise} alt="" />
                </div>
                <p className="weight-600 mb-0">No Locations Found</p>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="bg-gray-800 mb-4 p-4">
        <h5>Description</h5>
        {detailsPersonaByID?.personaDescription ? (
          <p
            className="mb-0"
            dangerouslySetInnerHTML={{
              __html: sanitizeHtml(`${detailsPersonaByID?.personaDescription}`),
            }}
          ></p>
        ) : (
          <div className="bg-gray-800 text-center p-4">
            <div className="d-flex justify-content-center mb-4">
              <img src={IconEmptyExercise} alt="" />
            </div>
            <p className="weight-600 mb-0">No Description Found</p>
          </div>
        )}
      </div>

      <div className="bg-gray-800 mb-4 p-4">
        <h5>Attribute</h5>
        {detailsPersonaByID?.personaattributes?.length > 0 ? (
          <div className="row">
            {detailsPersonaByID &&
              detailsPersonaByID?.personaattributes?.length > 0 &&
              detailsPersonaByID?.personaattributes?.map(
                (personaattributes, index) => {
                  return (
                    <div className="row mt-3" key={personaattributes?.id}>
                      <div className="col-md-6 mb-3">
                        <label className="form-label">
                          Attribute Name {index + 1}
                        </label>
                        <StylishNewInput
                          type="text"
                          className="form-control"
                          readOnly={true}
                          value={personaattributes?.attributeKey}
                        />
                      </div>
                      <div className="col-md-6 mb-3">
                        <label className="form-label">Value</label>

                        <StylishNewInput
                          type="text"
                          className="form-control"
                          readOnly={true}
                          value={personaattributes?.attributeValue}
                        />
                      </div>
                    </div>
                    // <div className="col-md-6 my-2">
                    //   <label className="form-label">
                    //     {personaattributes?.attributeKey}
                    //   </label>
                    //   <p className="mb-0">
                    //     {personaattributes?.attributeValue}
                    //   </p>
                    // </div>
                  );
                }
              )}
          </div>
        ) : (
          <div className="bg-gray-800 text-center p-4">
            <div className="d-flex justify-content-center mb-4">
              <img src={IconEmptyExercise} alt="" />
            </div>
            <p className="weight-600 mb-0">No Attribute Found</p>
          </div>
        )}
      </div>

      <div className="bg-gray-800 mb-4 p-4">
        <div className="d-flex align-items-center justify-content-between mb-4">
          <h5 className="mb-0">Linked Relationships</h5>

          <div className="button-group toggle ms-auto">
            <StylishNewButton
              className={`button--icon lrg flex-grow-1 ${
                showRelationshipTreeView ? 'active' : ''
              }`}
              onClick={() => {
                setShowRelationshipListView(false);
                setShowRelationshipTreeView(true);
              }}
            >
              <i className="fa fa-sitemap" aria-hidden="true"></i>
            </StylishNewButton>
            <StylishNewButton
              className={`button--icon lrg flex-grow-1 ms-0 ${
                showRelationshipListView ? 'active' : ''
              }`}
              onClick={() => {
                setShowRelationshipTreeView(false);
                setShowRelationshipListView(true);
              }}
            >
              <i className="fa fa-bars" aria-hidden="true"></i>
            </StylishNewButton>
          </div>
        </div>
        {filteredRelationshipArray?.length > 0 ? (
          (showRelationshipListView || showRelationshipTreeView) && (
            <>
              {showRelationshipTreeView ? (
                // <PrepareTree />
                <>
                  {filteredRelationshipArray &&
                    filteredRelationshipArray?.length > 0 && (
                      <div className="chart">
                        <Tree
                          label={
                            <SeatBox
                              seatName={detailsPersonaByID?.personaName}
                            />
                          }
                        >
                          {filteredRelationshipArray?.map((relation) => {
                            return (
                              <TreeNode
                                label={
                                  <SeatBox seatName={relation?.personaName} />
                                }
                              ></TreeNode>
                            );
                          })}
                        </Tree>
                      </div>
                    )}
                </>
              ) : showRelationshipListView ? (
                <div className="link-listview">
                  {detailsPersonaByID?.mediapersonarelatesto?.map((persona) => {
                    return (
                      <p
                        className="p-3 mb-0 weight-600"
                        key={persona?.personaId}
                      >
                        {persona?.personaName}
                        <span className="weight-400 mx-1">is</span>{' '}
                        {persona?.relation}
                        <span className="weight-400 mx-1">of</span>
                        {nameOfPersona}
                      </p>
                    );
                  })}
                </div>
              ) : null}
            </>
          )
        ) : (
          <div className="bg-gray-800 text-center p-4">
            <div className="d-flex justify-content-center mb-4">
              <img src={IconEmptyExercise} alt="" />
            </div>
            <p className="weight-600 mb-0">No Linked Relationships Found</p>
          </div>
        )}
      </div>

      <div className="bg-gray-800 p-4">
        {connectedProfiles?.length > 0 ? (
          <div className="row">
            <span className="mb-4 d-inline-block">
              {connectedProfiles?.length} Linked Profiles
            </span>
            {connectedProfiles &&
              connectedProfiles?.length > 0 &&
              connectedProfiles?.map((profile) => {
                return (
                  <div
                    className="col-md-6 col-lg-4 col-xl-3 mb-4"
                    key={connectedProfiles?.id}
                  >
                    <div className="border-700 rounded h-100 d-flex flex-column overflow-hidden">
                      <div className="p-3">
                        <div className="d-flex align-items-start mb-4">
                          {profile?.avatar ? (
                            <div className="profile-img">
                              <img src={profile?.avatar} alt="" />
                            </div>
                          ) : (
                            <div className="profile-img"></div>
                          )}

                          <Dropdown className="flex-shrink-0 ms-auto">
                            <Dropdown.Toggle className="button--icon">
                              <i
                                className="fa fa-ellipsis-v"
                                aria-hidden="true"
                              ></i>
                            </Dropdown.Toggle>
                            <Dropdown.Menu align="end">
                              <Dropdown.Item
                                onClick={(e) => {
                                  e.preventDefault();
                                  navigate(
                                    `${PreparePath}/dashboard/media/edit-profile/${profile?.id}`
                                  );
                                }}
                              >
                                <img src={IconEdit} alt="" />
                                Edit Profile
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() => {
                                  ondeleteProfile(profile);
                                }}
                              >
                                <img src={IconDelete} alt="" />
                                Remove From Persona
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>

                        <h4 className="mb-2 break-word">{profile?.name}</h4>

                        <p>{profile?.role}</p>
                      </div>

                      <div className="bg-gray-700 p-3 mt-auto d-flex align-items-center justify-content-center">
                        <span
                          onClick={() => {
                            onCreate(profile);
                          }}
                          className="text-white weight-500 d-flex align-items-center justify-content-center flex-grow-1 cursor-pointer"
                        >
                          <img src={IconEdit} className="me-2" alt="" />
                          Create Post
                        </span>
                        <div className="separator--y separator--800  flex-shrink-0"></div>

                        <Link
                          to={`${PreparePath}/dashboard/media/profiles/${profile?.siteName?.toLowerCase()}/${profile?.name?.toLowerCase()}/${profile?.id?.toLowerCase()}`}
                          className="text-white weight-500 d-flex align-items-center justify-content-center flex-grow-1"
                        >
                          <img
                            src={IconView}
                            className="img-h-16 me-2"
                            alt=""
                          />
                          View Posts
                        </Link>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        ) : (
          <div className="bg-gray-800 text-center p-4">
            <div className="d-flex justify-content-center mb-4">
              <img src={IconEmptyExercise} alt="" />
            </div>
            <p className="weight-600 mb-0">No Profiles Found</p>
          </div>
        )}
      </div>
      <div className="col-md-12 mt-4">
        <div className="button-group justify-content-end">
          <StylishNewButton
            className="button--tertiary button--reverse"
            onClick={(e) => {
              e.preventDefault();
              navigate(
                `${PreparePath}/dashboard/media/edit-personas/useblankpersons/${params?.id}`
              );
            }}
          >
            Edit
          </StylishNewButton>
          <StylishNewButton
            className="button--primary"
            onClick={(e) => {
              e.preventDefault();
              onDeletePersona(params?.id);
            }}
          >
            Delete Persona
          </StylishNewButton>
        </div>
      </div>
      {deleteProfiles && (
        <StylishConfirmDialog
          show={deleteProfiles}
          onClose={() => setDeleteProfiles(null)}
          dialogTitle={'Delete Profile from persona'}
          dialogContent={'Are you sure you want this?'}
          onConfirm={onDeleteConfirmProfile}
        />
      )}
      {deletePersona && (
        <StylishConfirmDialog
          show={deletePersona}
          onClose={() => setDeletePersona(null)}
          dialogTitle={'Delete Persona'}
          dialogContent={'Are you sure you want this?'}
          onConfirm={onDeleteConfirm}
        />
      )}
    </>
  );
}
