import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useToggle } from 'react-use';


import { BsArrowsFullscreen, BsFullscreenExit } from 'react-icons/bs';

import {
  MdCampaign,
  MdFlashOn,
  MdLocationOn,
  MdMap,
  MdOutlineTask,
  MdOutlineWarning,
  MdSettings,
  MdChat,
} from 'react-icons/md';

import { TiLightbulb, TiWeatherStormy } from 'react-icons/ti';
import { FaTimes } from 'react-icons/fa';
import { RiFileTextLine } from 'react-icons/ri';
import dayjs from 'dayjs';
import mapboxgl from '!mapbox-gl';

import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton.js';
import { StylishRangeSlider24hrs } from 'components/DesignSystems/New/StylishRangeSlider24hrs/StylishRangeSlider24hrs.js';
import StylishNewSelect from 'components/DesignSystems/New/StylishNewSelect';

import './MapComponent.css';
import { eriScenarios } from './constants/eri';

import { useAuth0 } from '@auth0/auth0-react';

import MapSettings from './mapMenu/MapSettings.js';
import MapAlerts from './mapMenu/MapAlerts.js';
import MapBase from './mapMenu/MapBase.js';
import MapGeolocations from './mapMenu/MapGeolocations.js';
import MapEvents from './mapMenu/MapEvents.js';
import MapWeather from './mapMenu/MapWeather.js';
import MapImpactModels from './mapMenu/MapImpactModels.js';
import MapIncidentReport from './mapMenu/MapIncidentReport.js';
import useMapInit from './mapHooks/useMapInit';
import MapLegend from './mapComponents/MapLegend.js';
import Chart from './mapComponents/Chart.js';
import useMapLayout from './mapHooks/useMapLayout';
import ERIChart from './mapComponents/ERIChart.js';
import MapTaskManagement from './mapMenu/MapTaskManagement';
import MapPowerOutage from './mapMenu/MapPowerOutage';
import PowerOutageChart from './mapComponents/PowerOutageChart';
import { powerOutageScenarios } from './constants/poweroutage';
import PageTitle from 'components/headers/PageTitle';
import { useNewUIFlag } from 'utils/featureFlags';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

import ChatDisasterGPT from 'components/DisasterGPT/ChatDisasterGPT';
import DisasterChatSidebar from 'components/DisasterGPT/DisasterChatSidebar'; // Add this import

const POPUP_LABELS = {
  WEATHER: 'WEATHER',
  ALERTS: 'ALERTS',
  GEOLOCATION: 'GEOLOCATION',
  EVENTS: 'EVENTS',
  ERI: 'ERI',
  INCIDENT_REPORT: 'INCIDENT_REPORT',
  TASK_MANAGEMENT: 'TASK_MANAGEMENT',
};

const SIDEBAR_ICONS = [
  { key: 'DisasterChats', icon: <MdChat /> }, // New entry
  { key: 'Settings', icon: <MdSettings /> },
  { key: 'AOR Reports', icon: <MdCampaign /> },
  { key: 'Basemap', icon: <MdMap /> },
  { key: 'Geolocations', icon: <MdLocationOn /> },
  { key: 'Events', icon: <MdOutlineWarning /> },
  { key: 'Weather', icon: <TiWeatherStormy /> },
  { key: 'Impact Models', icon: <TiLightbulb /> },
  { key: 'Incident Report', icon: <RiFileTextLine /> },
  { key: 'Task Management', icon: <MdOutlineTask /> },
  { key: 'Power Outage', icon: <MdFlashOn /> },
];



const VIEWMODES = [
  {
    label: 'Chart',
    value: 'Chart',
  },
  {
    label: 'Table',
    value: 'Table',
  },
];

function setupIcons(icons,featureFlags)
{
  let i = [...icons]
  if(!featureFlags.includes("STREAMLINE_PLATFORM"))
  {
    i = i.filter(ic=>ic.key!=="AOR Reports")
  }
  return i
}

export default function MapComponent({
  layers = [],
  setLayers,
  sources = [],
  setSources,
  lng,
  lat,
  zoom,
  apiPrefix,
}) {
  const mapboxToken =
    window.env.MAPBOX_ACCESS_TOKEN || process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
  mapboxgl.accessToken = mapboxToken;
  const owmToken =
    window.env.OPEN_WEATHER_MAP_API_KEY ||
    process.env.REACT_APP_OPEN_WEATHER_MAP_API_KEY;

  // constant variables
  const matches = window.matchMedia('(min-width: 768px)').matches;
  const authHeader = `Bearer ${sessionStorage['accessToken']}`;
  const { getAccessTokenSilently } = useAuth0();

  const reduxFeatureFlags = useSelector((state) => {
    return state.app.featureFlags;
  });

  const auth = useSelector((state) => {
    return state.auth;
  });

  // State Declarations
  const [sidebarIcons, setSidebarIcons] = useState(setupIcons(SIDEBAR_ICONS,reduxFeatureFlags));
  const [sidebarActiveItem, setSidebarActiveItem] = useState();
  const [sidebarSecondary, setSidebarSecondary] = useState({
    show: false,
    key: null,
  });
  const [fullScreenMode, setFullScreenMode] = useState(false);
  const [bottomPanelActive, setBottomPanelActive] = useState();
  const [mapHasLoaded, setMapHasLoaded] = useState(false);
  const [forecastData, setForecastData] = useState();
  const [viewMode, setViewMode] = useState('Chart');
  const [clickEventObject, setClickEventObject] = useState();
  const [clickedLayers, setClickedLayers] = useState([]);
  const [updatedLayer, setUpdatedLayer] = useState();

  const [selectedGeolocation, setSelectedGeolocation] = useState({
    type: null,
    geolocation: null,
  });

  const [ethelData, setEthelData] = useState();
  const [earthquakesData, setEarthquakesData] = useState();
  const [poweroutageData, setPoweroutageData] = useState();
  const [selectedEventTabNews, setSelectedEventTabNews] = useState();
  const [eventsVisibleToggle, setEventsVisibleToggle] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState();
  const [selectedReport, setSelectedReport] = useState();
  const [selectedTask, setSelectedTask] = useState();

  const [lngLatWeather, setLngLatWeather] = useState();

  const [DGPTOpen, toggleDGPTOpen] = useToggle(false);

  // settings variables.
  const [mapSettings, setMapSettings] = useState({
    trafficToggle: false,
    threeDBuildingsToggle: false,
    basemap: 'mapbox://styles/mapbox/satellite-streets-v11',
    weatherLayer: undefined,
    lng: lng,
    lat: lat,
    zoom: zoom,
    selectedDatetime: dayjs(),
    eriActive: false,
    eriScenario: 'eri_mean',
    powerOutageActive: false,
    powerOutageScenario: 'absolute',
  });

  const newUI = useNewUIFlag();

  // useRef variables
  const mapRef = useRef(React.createRef());
  const mapContainer = useRef(null);
  const map = useRef(null);
  const drawRef = useRef(React.createRef());

  // functions
  const sidebarSecondaryHandler = (show, key) => {
    setSidebarSecondary({
      show: show,
      key: key,
    });
  };

  const handleFullScreenMap = () => {
    if (mapRef.current) {
      if (document.fullscreenElement === mapRef.current) {
        setFullScreenMode(false);
        document.exitFullscreen();
      } else {
        setFullScreenMode(true);
        mapRef.current.requestFullscreen();
      }
    }
  };

  const sidebarActiveItemHandler = (key) => {
    if (key !== sidebarActiveItem) {
      setSidebarActiveItem(key);
    } else {
      setSidebarActiveItem(null);
    }
  };

  const handleCloseMenu = () => {
    setSidebarActiveItem(null);
  };

  const updateLayer = (oldLayer, layer, layerFeature, newProps) => {
    // If update to feature, make sure to update sources
    if (!!layerFeature && !!newProps) {
      let newSource = {
        ...sources.find((s) => s.id === layer.source),
      };
      let newData = (!!newSource.data && newSource.data) || newSource._data;
      newData.features = newData.features.map((f) => {
        return {
          ...f,
          properties: f.id === layerFeature.id ? newProps : f.properties,
        };
      });
      setSources([
        ...sources.filter((s) => s.id !== layer.source),
        { ...newSource },
      ]);
    }
    // ID has changed so make sure old layer no longer in list
    if (!!oldLayer) {
      map.current.removeLayer(oldLayer.id);
      setLayers([...layers.filter((l) => l.id !== oldLayer.id)]);
    }
    setUpdatedLayer(layer);
  };

  const eventSelected = (event) => {
    setSelectedEvent(event);
    setSelectedEventTabNews();
  };

  const reportSelected = (report) => {
    setSelectedReport(report);
  };

  const taskSelected = (task) => {
    setSelectedTask(task);
  };

  const handleLayersClicked = (key, data) => {
    if (key === POPUP_LABELS.WEATHER) {
      setClickEventObject((prev) => data?.e);
    }
    setClickedLayers((prev) => {
      const _layers = [...prev];
      const existingLayer = _layers.find((layer) => layer.key === key);
      if (existingLayer) {
        existingLayer.data = data;
      } else {
        _layers.push({ key, data });
      }
      return _layers;
    });
  };

  const ethelDataAndEarthquakesDataWrapper = (refreshTokenResponse) => {
    const formattedDatetime = mapSettings?.selectedDatetime
      .utc()
      .format('YYYY-MM-DD HH:mm:ss');

    fetch(
      `${apiPrefix}/dice/map/ethelData_alternate?selectedDatetime=${formattedDatetime}`,
      {
        headers: {
          Authorization: `Bearer ${refreshTokenResponse.accessToken}`,
          // Authorization: null,
          'x-teamsapp': sessionStorage['isUsingTeams'] === 'true',
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setEthelData(data);
      });

    const endDatetime = dayjs(formattedDatetime).format('YYYY-MM-DD HH:mm:ss');

    fetch(
      apiPrefix +
        '/dice/map/earthquakesData?selectedDatetime=' +
        formattedDatetime +
        '&endDatetime=' +
        endDatetime,
      {
        headers: {
          Authorization: `Bearer ${refreshTokenResponse.accessToken}`,
          // Authorization: null,
          'x-teamsapp': sessionStorage['isUsingTeams'] === 'true',
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setEarthquakesData(data);
      });

    const selectedDatetime = mapSettings?.selectedDatetime
      .minute(0)
      .second(0)
      .utc()
      .format('YYYY-MM-DD HH:mm:ss');
    fetch(
      apiPrefix +
        '/dice/map/poweroutageData?selectedDatetime=' +
        selectedDatetime,
      {
        headers: {
          Authorization: `Bearer ${refreshTokenResponse.accessToken}`,
          // Authorization: null,
          'x-teamsapp': sessionStorage['isUsingTeams'] === 'true',
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setPoweroutageData(data);
      });
  };

  // Use Effect for map container
  const { drawActive } = useMapInit({
    map,
    mapboxgl,
    mapContainer,
    layers,
    setLayers,
    drawRef,
    mapSettings,
    mapHasLoaded,
    clickEventObject,
    apiPrefix,
    setMapSettings,
    setMapHasLoaded,
    sources,
    setSources,
    updatedLayer,
    setUpdatedLayer,
  });

  const { sidebarSelection, selectedModelFeature } = useMapLayout({
    mapboxgl,
    map,
    mapSettings,
    owmToken,
    layers,
    clickedLayers,
    ethelData,
    sources,
    setLayers,
    updateLayer,
    setSelectedGeolocation,
    setBottomPanelActive,
    eventSelected,
    reportSelected,
    taskSelected,
    sidebarSecondaryHandler,
    setSidebarActiveItem,
    setSelectedEventTabNews,
    setClickedLayers,
    setLngLatWeather,
    setSources,
  });

  useEffect(() => {
    if (!!mapHasLoaded && !bottomPanelActive) {
      map.current.resize();
    }
  }, [mapHasLoaded, bottomPanelActive]);

  useEffect(() => {
    map.current.resize();
  }, [sidebarActiveItem, bottomPanelActive]);

  useEffect(() => {

    if (!!eventsVisibleToggle) {
      if (auth.type === 'AUTH0') {
        getAccessTokenSilently().then((auth0Token) => {
          let refreshTokenResponse;
          let now = dayjs();
          refreshTokenResponse = {
            accessToken: auth0Token,
            expiresOn: now.add(1, 'hour'),
          };

          ethelDataAndEarthquakesDataWrapper(refreshTokenResponse);
        });
      } else {
        let refreshTokenResponse;
        let now = dayjs();
        refreshTokenResponse = {
          accessToken: sessionStorage['accessToken'],
          expiresOn: now.add(1, 'hour'),
        };
        ethelDataAndEarthquakesDataWrapper(refreshTokenResponse);
      }
    }
  }, [eventsVisibleToggle, mapSettings?.selectedDatetime, reduxFeatureFlags]);

  useEffect(() => {
    let icons = [
      ...sidebarIcons
        .filter((i) => {
          if (
            i.key === 'Power Outage' &&
            !reduxFeatureFlags.find((f) => f === 'POWER_OUTAGES')
          ) {
            return false;
          } else {
            return true;
          }
        })
        .filter((i) => !!i),
    ];

    setSidebarIcons(icons);
  }, [reduxFeatureFlags]);

  useEffect(() => {
    if (sidebarActiveItem !== 'Events' && !!selectedEvent) {
      eventSelected();
      sidebarSecondaryHandler(false, null);
    }
  }, [sidebarActiveItem]);

  return (
    <div className="site-main__map" ref={mapRef}>
      <div className="map-sidebar">
        <div className="sidebar-icons">
          <ul>
            {sidebarIcons.map((i) => (
              <li key={i.key}>
                <OverlayTrigger
                  delay={{ hide: 450, show: 300 }}
                  overlay={(props) => <Tooltip {...props}>{i.key}</Tooltip>}
                  placement="right"
                >
                  <StylishNewButton
                    customButton
                    onClick={() => sidebarActiveItemHandler(i.key)}
                    className={sidebarActiveItem === i.key ? 'active' : ''}
                    aria-label={i.key}
                  >
                    {i.icon}
                  </StylishNewButton>
                </OverlayTrigger>
              </li>
            ))}
          </ul>
        </div>

        {sidebarActiveItem === 'DisasterChats' && (
          <DisasterChatSidebar
            toggleDGPTOpen={toggleDGPTOpen}
            DGPTOpen={DGPTOpen}
           />
        )}
        {sidebarActiveItem === 'Settings' && (
          <MapSettings
            mapSettings={mapSettings}
            handleMapSettings={(val) => setMapSettings(val)}
            onClose={() => handleCloseMenu()}
          />
        )}
        {sidebarActiveItem === 'AOR Reports' && (
          <MapAlerts
            map={map}
            mapSettings={mapSettings}
            mapboxToken={mapboxToken}
            mapHasLoaded={mapHasLoaded}
            apiPrefix={apiPrefix}
            handleMapSettings={(val) => setMapSettings(val)}
            setLayerClicked={(val) =>
              handleLayersClicked(POPUP_LABELS.ALERTS, val)
            }
            onClose={() => handleCloseMenu()}
            eventsVisibleToggle={eventsVisibleToggle}
            setEventsVisibleToggle={setEventsVisibleToggle}
            ethelData={ethelData}
            earthquakesData={earthquakesData}
            poweroutageData={poweroutageData}
            setEarthquakesData={setEarthquakesData}
            setSidebarActiveItem={setSidebarActiveItem}
            toggleDGPTOpen={toggleDGPTOpen}
          />
        )}
        {sidebarActiveItem === 'Basemap' && (
          <MapBase
            mapSettings={mapSettings}
            handleMapSettings={(val) => setMapSettings(val)}
            onClose={() => handleCloseMenu()}
          />
        )}
        {sidebarActiveItem === 'Geolocations' && (
          <MapGeolocations
            map={map}
            mapHasLoaded={mapHasLoaded}
            apiPrefix={apiPrefix}
            selectedGeolocation={selectedGeolocation}
            setLayerClicked={(val) =>
              handleLayersClicked(POPUP_LABELS.GEOLOCATION, val)
            }
            onClose={() => handleCloseMenu()}
          />
        )}
        {sidebarActiveItem === 'Events' && (
          <MapEvents
            map={map}
            mapSettings={mapSettings}
            mapHasLoaded={mapHasLoaded}
            apiPrefix={apiPrefix}
            mapboxToken={mapboxToken}
            sidebarSecondary={sidebarSecondary}
            sidebarActiveItem={sidebarActiveItem}
            selectedEvent={selectedEvent}
            setSelectedEvent={setSelectedEvent}
            selectedEventTabNews={selectedEventTabNews}
            setSelectedEventTabNews={setSelectedEventTabNews}
            eventsVisibleToggle={eventsVisibleToggle}
            setEventsVisibleToggle={setEventsVisibleToggle}
            eventSelected={eventSelected}
            setLayerClicked={(val) =>
              handleLayersClicked(POPUP_LABELS.EVENTS, val)
            }
            ethelData={ethelData}
            onClose={() => handleCloseMenu()}
            earthquakesData={earthquakesData}
            poweroutageData={poweroutageData}
            toggleDGPTOpen={toggleDGPTOpen}
          />
        )}
        {sidebarActiveItem === 'Weather' && (
          <MapWeather
            map={map}
            mapSettings={mapSettings}
            mapHasLoaded={mapHasLoaded}
            owmToken={owmToken}
            handleMapSettings={(val) => setMapSettings(val)}
            setLayerClicked={(val) =>
              handleLayersClicked(POPUP_LABELS.WEATHER, val)
            }
            onClose={() => handleCloseMenu()}
          />
        )}
        {sidebarActiveItem === 'Impact Models' && (
          <MapImpactModels
            map={map}
            mapHasLoaded={mapHasLoaded}
            apiPrefix={apiPrefix}
            mapSettings={mapSettings}
            handleMapSettings={(val) => setMapSettings(val)}
            setLayerClicked={(val) =>
              handleLayersClicked(POPUP_LABELS.ERI, val)
            }
            onClose={() => handleCloseMenu()}
          />
        )}
        {sidebarActiveItem === 'Incident Report' && (
          <MapIncidentReport
            map={map}
            mapHasLoaded={mapHasLoaded}
            apiPrefix={apiPrefix}
            selectedReport={selectedReport}
            setSelectedReport={setSelectedReport}
            setLayerClicked={(val) =>
              handleLayersClicked(POPUP_LABELS.INCIDENT_REPORT, val)
            }
            onClose={() => handleCloseMenu()}
          />
        )}
        {sidebarActiveItem === 'Task Management' && (
          <MapTaskManagement
            map={map}
            mapHasLoaded={mapHasLoaded}
            apiPrefix={apiPrefix}
            selectedTask={selectedTask}
            setSelectedTask={setSelectedTask}
            setLayerClicked={(val) =>
              handleLayersClicked(POPUP_LABELS.TASK_MANAGEMENT, val)
            }
            onClose={() => handleCloseMenu()}
          />
        )}
        {sidebarActiveItem === 'Power Outage' && (
          <MapPowerOutage
            map={map}
            mapHasLoaded={mapHasLoaded}
            apiPrefix={apiPrefix}
            mapSettings={mapSettings}
            handleMapSettings={(val) => setMapSettings(val)}
            setLayerClicked={(val) =>
              handleLayersClicked(POPUP_LABELS.ERI, val)
            }
            onClose={() => handleCloseMenu()}
          />
        )}
      </div>
      <div className="map-wrapper">
        <div className="d-inline-flex align-items-center map-full-screen">
          <StylishNewButton
            customButton
            className={`button--icon primary`}
            style={{ marginTop: '-18px', marginRight: '10px' }}
            onClick={() => handleFullScreenMap()}
          >
            {fullScreenMode ? <BsFullscreenExit /> : <BsArrowsFullscreen />}
          </StylishNewButton>
        </div>
        <div
          ref={mapContainer}
          className={`map-container ${
            bottomPanelActive ? 'with-bottom-panel' : ''
          }`}
        >
          {!!mapSettings?.weatherLayer && (
            <MapLegend
              matches={matches}
              weatherLayer={mapSettings?.weatherLayer}
            />
          )}
        </div>

        {(!!mapSettings?.weatherLayer ||
          mapSettings?.eriActive ||
          mapSettings?.powerOutageActive ||
          eventsVisibleToggle ||
          sidebarActiveItem === 'AOR Reports') && (
          <div
            className={`map-range ${
              !!sidebarActiveItem && 'map-range-sidebar-sitrepsize'
            }
            ${
              sidebarActiveItem === 'AOR Reports' &&
              `map-range-sidebar-sitrepsize`
            }
            `}
            style={{ bottom: !!bottomPanelActive ? '320px' : null }}
          >
            <StylishRangeSlider24hrs
              defaultValue={mapSettings?.selectedDatetime.hour()}
              minValue="0"
              maxValue="23"
              selectedDatetime={mapSettings?.selectedDatetime}
              setSelectedDatetime={(val) =>
                setMapSettings({
                  ...mapSettings,
                  selectedDatetime: val,
                })
              }
            />
          </div>
        )}

        {!!bottomPanelActive && (
          <div
            className={`map-bottom-panel ${
              !!sidebarActiveItem && `map-bottom-panel-sidebar-expanded`
            }
            ${
              sidebarActiveItem === 'AOR Reports' &&
              `map-range-sidebar-sitrepsize`
            }
            `}
          >
            <div className="map-bottom-panel-controls">
              <StylishNewButton
                customButton
                className={`button--icon primary cancel ${
                  !!sidebarActiveItem && `icon-button-sidebar-expanded`
                }
                ${
                  sidebarActiveItem === 'AOR Reports' &&
                  `map-range-sidebar-sitrepsize`
                }
                `}
                onClick={() => setBottomPanelActive()}
              >
                <FaTimes />
              </StylishNewButton>
              {bottomPanelActive?.isERI && selectedModelFeature && (
                <div className="map-bottom-panel-select">
                  <StylishNewSelect
                    options={VIEWMODES}
                    defaultValue={viewMode}
                    value={viewMode}
                    onChange={(e) => {
                      setViewMode(e.value);
                    }}
                    placeholder={viewMode}
                    isClearable={true}
                    isSearchable={true}
                    isMulti={false}
                    isDisabled={false}
                  />
                  <div className="map-bottom-panel-eri-legend-label">
                    Scenarios
                  </div>
                  <div className="map-bottom-panel-eri-legend">
                    {eriScenarios.map((s, idx) => (
                      <div
                        key={idx}
                        className="map-bottom-panel-eri-legend-scenario"
                      >
                        <div
                          className="map-bottom-panel-legend-scenario-color"
                          style={{ backgroundColor: s.color }}
                        ></div>
                        <div className="map-bottom-panel-legend-scenario-name">
                          {s.labelSmall}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}

              {bottomPanelActive?.isPowerOutage && selectedModelFeature && (
                <div className="map-bottom-panel-select">
                  <StylishNewSelect
                    options={VIEWMODES}
                    defaultValue={viewMode}
                    value={viewMode}
                    onChange={(e) => {
                      setViewMode(e.value);
                    }}
                    placeholder={viewMode}
                    isClearable={true}
                    isSearchable={true}
                    isMulti={false}
                    isDisabled={false}
                  />
                  <div className="map-bottom-panel-eri-legend-label">
                    Scenarios
                  </div>
                  <div className="map-bottom-panel-eri-legend">
                    {powerOutageScenarios.map((s, idx) => (
                      <div
                        key={idx}
                        className="map-bottom-panel-eri-legend-scenario"
                      >
                        <div
                          className="map-bottom-panel-legend-scenario-color"
                          style={{ backgroundColor: s.color }}
                        ></div>
                        <div className="map-bottom-panel-legend-scenario-name">
                          {s.label}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>

            {bottomPanelActive?.isWeather && (
              <Chart
                layer={bottomPanelActive.layer}
                owmToken={owmToken}
                clickEventObject={clickEventObject}
                selectedDatetime={mapSettings?.selectedDatetime}
                forecastData={forecastData}
                setForecastData={setForecastData}
              />
            )}
            {bottomPanelActive?.isERI && selectedModelFeature && (
              <ERIChart
                layer={bottomPanelActive.layer}
                feature={selectedModelFeature}
                selectedDatetime={mapSettings?.selectedDatetime}
                apiPrefix={apiPrefix}
                eriScenario={mapSettings?.eriScenario}
                eriScenarios={eriScenarios}
                sidebarSelection={sidebarSelection}
                sidebarActiveItem={sidebarActiveItem}
                viewMode={viewMode}
                authHeader={authHeader}
              />
            )}

            {bottomPanelActive?.isPowerOutage && selectedModelFeature && (
              <PowerOutageChart
                layer={bottomPanelActive.layer}
                feature={selectedModelFeature}
                selectedDatetime={mapSettings?.selectedDatetime}
                apiPrefix={apiPrefix}
                powerOutageScenarios={powerOutageScenarios}
                sidebarActiveItem={sidebarActiveItem}
                viewMode={viewMode}
                authHeader={authHeader}
              />
            )}
          </div>
        )}
      </div>
      {DGPTOpen ? <ChatDisasterGPT toggle={toggleDGPTOpen} /> : null}
    </div>
  );
}
