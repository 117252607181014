import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import IconDelete from 'assets/images/icon__delete.svg';
import IconData from 'assets/images/icon__data.svg';
import IconLocation from 'assets/images/icon__location.svg';
import IconChooseMedia from 'assets/images/icon__choosemedia.svg';
import ChooseMediaDialog from '../ChooseMediaModal/ChooseMediaDialog';
import IconEmptyExercise from 'assets/images/icon__exercise-details-empty.svg';
import API from '../../../service/api';
import Network from '../../../service/Network';
import { PreparePath } from 'utils/sharedUtils/sharedConst';
import { AllIcon } from 'PREPAREsrc/pages/Settings/InjectMethod/Icon';
import { contentBlockLength, toastConfig } from 'assets/data/config';
import { Controller, useForm } from 'react-hook-form';
import StylishNewInput from 'components/DesignSystems/New/StylishNewInput';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import IconTimes from 'assets/images/icon__times.svg';
import { SelectLocation } from 'PREPAREsrc/pages/plan/Prepare/MSEL/Location/SelectLocation';
import StylishDateTimePicker from 'components/DesignSystems/form/StylishDateTimePicker';
import IconRefresh from '../../../../assets/images/icon__refresh.svg';
import moment from 'moment';
import { toast } from 'react-toastify';
import { StylishConfirmDialog } from 'components/DesignSystems/New/StylishConfirmDialog';
import ViewPostDialog from '../ViewPostDialog/ViewPostDialog';
import IconEdit from 'assets/images/icon__edit.svg';
import IconCommentImage from 'assets/images/icon__image--post.svg';
import IconCommentLocation from 'assets/images/icon__location--post.svg';
import IconResponce from 'assets/images/icon__responce.svg';
import { StylishSwitcher } from 'components/DesignSystems/New/StylishSwitcher';
import TinyMCEWithFormController from 'components/DesignSystems/New/TinyMCEWithFormController';
import { sanitizeHtml } from '../../../../utils/sanitizeHtml';
import { endLoading, startLoading } from "../../../../reducers/loading/loading.action";

export default function CreatePostPage() {
  const {
    control,
    formState: { errors },
    setValue,
    handleSubmit,
    getValues,
  } = useForm({ mode: 'onChange' });

  const [showChooseMediaDialog, setShowChooseMediaDialog] = useState(false);
  const [imageAvatar, setImageAvatar] = useState(null);
  const [isVisibleAvatar, setIsVisibleAvatar] = useState(false);
  const [imageAvatarReceived, setImageAvatarReceived] = useState(null);
  const [
    isVisibleAvatarAlreadyReceived,
    setIsVisibleAvatarAlreadyReceived,
  ] = useState(false);
  const [postSubmitType, setPostSubmitType] = useState('');
  const [selectLocation, setSelectLocation] = useState(false);
  const [lng, setLng] = useState('');
  const [lat, setLat] = useState('');
  const [place, setPlace] = useState('');
  const [scheduledTime, setScheduledTime] = useState();
  const [startDateKey, setStartDateKey] = useState(false);
  const [postDetails, setPostDetails] = useState('');
  const [postDetailsForParent, setPostDetailsForParent] = useState([]);
  const [editPost, setEditPost] = useState(false);
  const [postType, setPostType] = useState('');
  const [commentsOnPost, setCommentsOnPost] = useState([]);
  const [showViewPostDialog, setShowViewPostDialog] = useState(false);
  const [commentId, setCommentId] = useState(false);
  const [deleteComment, setDeleteComment] = useState(false);
  const [deletePost, setDeletePost] = useState(false);
  const [imageAvatarFrom, setImageAvatarFrom] = useState(false);
  const [filter, setFilter] = useState(null);
  const [isReady, setIsReady] = useState(false);
  const [showLikesCount, setShowLikesCount] = useState(false);
  const [showTimeToPost, setShowTimeToPost] = useState(false);
  const [profile, setProfile] = useState('');
  const [site, setSite] = useState('');
  const [profileName, setProfileName] = useState('');
  const [id, setId] = useState('');
  const [title, setTitle] = useState('');

  const navigate = useNavigate();

  const params = useParams();

  const dispatch = useDispatch();

  const location = useLocation();

  const workspaceId = useSelector(
    (state) => state.prepare.workspace.selectedWorkspace
  );
  const reduxUser = useSelector((state) => state.prepare.user);

  const tilteModifier = function htmlToText(html) {
    var temp = document.createElement('div');
    temp.innerHTML = html;
    return temp.textContent; // Or return temp.innerText if you need to return only visible text. It's slower.
  };

  const handleResetButtonClick = () => {
    if (scheduledTime != null) {
      setScheduledTime(null);
      setValue('scheduledTime', null);
      setStartDateKey((k) => !k);
    }
  };

  const handleNowButtonClick = () => {
    setScheduledTime(moment().format('MM/DD/YYYY HH:mm'));
    setValue('scheduledTime', moment().format('MM/DD/YYYY HH:mm'));
    setStartDateKey((k) => !k);
  };

  const onChooseMediaDialog = () => {
    setShowChooseMediaDialog(true);
  };

  const onChooseMediaDialogClose = () => {
    setShowChooseMediaDialog(false);
  };

  const onSubmitImageAvatar = (data) => {
    setShowChooseMediaDialog(false);
    if (data !== undefined) {
      setImageAvatar(data);
      setIsVisibleAvatar(true);
    }
  };

  const onViewPostDialog = (data) => {
    setCommentId(data);
    setShowViewPostDialog(true);
  };

  const onViewPostDialogClose = () => {
    setShowViewPostDialog(false);
  };

  const selectLocationHandler = () => {
    setSelectLocation(true);
  };

  const onCloseModal = () => {
    setSelectLocation(false);
  };

  const onSaveClicked = (changedlat, Changedlng, changedplace) => {
    setLat(changedlat);
    setLng(Changedlng);
    setPlace(changedplace);
  };
  const mediaPostDetailsByPostId = async () => {
    dispatch(startLoading());
    try {
      const response = await Network.get(API.mediaPostDetailsByPostId, {
        workspaceId: workspaceId,
        postId: params?.id,
        filter: filter,
      });
      setPostDetails(response?.data?.response?.dataset[0]);
      setPostDetailsForParent(response?.data?.response?.parentData[0]);
      setPostType(response?.data?.response?.dataset[0]?.mainposttype);
      setValue('title', response?.data?.response?.dataset[0]?.mainposttitle);
      setValue('text', response?.data?.response?.dataset[0]?.mainpost);
      setValue('likes', response?.data?.response?.dataset[0]?.mainpostlikes);
      setValue(
        'disLikes',
        response?.data?.response?.dataset[0]?.mainpostdislikes
      );
      if (
        response?.data?.response?.dataset[0]?.mainpoststatus === 'scheduled'
      ) {
        setShowTimeToPost(true);
        setScheduledTime(
          response?.data?.response?.dataset[0]?.mainpostpublishtime
        );
      }

      setValue(
        'scheduledTime',
        response?.data?.response?.dataset[0]?.mainpostpublishtime
      );
      setValue('views', response?.data?.response?.dataset[0]?.mainpostviews);
      setValue('shares', response?.data?.response?.dataset[0]?.mainpostshares);
      setLat(response?.data?.response?.dataset[0]?.mainpostlat);
      setLng(response?.data?.response?.dataset[0]?.mainpostlng);
      setPlace(response?.data?.response?.dataset[0]?.mainpostlocationname);
      if (response?.data?.response?.dataset[0]?.mainpostmediafile != null) {
        setImageAvatar(response?.data?.response?.dataset[0]?.mainpostmediafile);
        setIsVisibleAvatar(true);
      }
      if (
        response?.data?.response?.parentData[0] !== undefined &&
        response?.data?.response?.parentData[0]?.mainpostmediafile != null
      ) {
        setImageAvatarReceived(
          response?.data?.response?.parentData[0]?.mainpostmediafile
        );
        setIsVisibleAvatarAlreadyReceived(true);
      }
      if (
        response?.data?.response?.parentData[0] === undefined &&
        response?.data?.response?.dataset[0]?.mainpostmediafile != null
      ) {
        setImageAvatarReceived(
          response?.data?.response?.dataset[0]?.mainpostmediafile
        );
        setIsVisibleAvatarAlreadyReceived(true);
      }
      if (
        response?.data?.response?.parentData[0] !== undefined &&
        response?.data?.response?.parentData[0]?.comments?.length > 0
      ) {
        setCommentsOnPost(response?.data?.response?.parentData[0]?.comments);
      } else if (
        response?.data?.response?.parentData[0] === undefined &&
        response?.data?.response?.dataset[0]?.comments?.length > 0
      ) {
        setCommentsOnPost(response?.data?.response?.dataset[0]?.comments);
      } else {
        setCommentsOnPost(null);
      }
    } catch (error) {
    } finally {
      dispatch(endLoading());
    }
  };

  useEffect(() => {
    setCommentsOnPost(null);
    mediaPostDetailsByPostId();
  }, [params?.id, filter]);

  useEffect(() => {
    const currentURL = window.location.href;

    const queryStringIndex = currentURL.indexOf('?');
    const baseURL = currentURL.substring(0, queryStringIndex);
    const queryString = currentURL.substring(queryStringIndex);

    const urlSearchParams = new URLSearchParams(queryString);
    setProfile(urlSearchParams.get('profile'));
    setSite(urlSearchParams.get('site'));
    setProfileName(urlSearchParams.get('profileName'));
    setId(urlSearchParams.get('id'));

    window.history.replaceState({}, document.title, baseURL);
  }, []);

  const onSubmit = () => {
    setPostSubmitType('');
    setIsReady(true);
    let textValue = tilteModifier(getValues('text'));
    if (errors?.text || textValue.length === 0) {
      return 'This field is required';
    } else if (showTimeToPost === true && scheduledTime === null) {
      return;
    } else {
      setEditPost(true);
    }
  };
  const onDraft = (e) => {
    let textValue = tilteModifier(getValues('text'));

    if (errors?.text || textValue.length === 0) {
      return 'This field is required';
    } else {
      e.preventDefault();
      setPostSubmitType('draft');
      setIsReady(true);
      setEditPost(true);
    }
  };
  const onSaveConfirm = async () => {
    const data = getValues();
    dispatch(startLoading());
    try {
      await Network.post(API.updateMediaPost, {
        title: data?.title,
        postId: params?.id,
        status:
          postSubmitType === 'draft'
            ? 'drafted'
            : moment().diff(
                moment(scheduledTime).format('MM/DD/YYYY HH:mm'),
                'minutes'
              ) >= 1 ||
              scheduledTime === null ||
              scheduledTime === undefined
            ? 'published'
            : 'scheduled',
        lat: lat,
        lng: lng,
        locationName: place,
        type: postType,
        mediaFile: imageAvatar,
        publishTime: scheduledTime || moment(),
        likes: data?.likes,
        views: data?.views,
        shares: data?.shares,
        dislikes: data?.disLikes,
        post: data?.text,
        workspaceId: workspaceId,
        updatedAt: moment().toISOString(),
        mediaSiteId: postDetails?.siteId,
      });
      setEditPost(false);
      if (profile === 'true') {
        navigate(
          `${PreparePath}/dashboard/media/profiles/${site}/${profileName}/${id}`
        );
      } else {
        navigate(`${PreparePath}/dashboard/media/posts`);
      }
      toast.success('Post updated successfully', toastConfig);
    } catch (error) {
      toast.error(error?.response?.data?.response?.status?.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };
  const handleKeyPress = (e) => {
    const keyCode = e.keyCode || e.which;
    const keyValue = String.fromCharCode(keyCode);
    const isValidKey = /[0-9]|Backspace/.test(keyValue);
    if (!isValidKey) {
      e.preventDefault();
    }
  };

  const onDeleteComment = (row) => {
    setDeleteComment(row?.id);
  };
  const onSubmitImageAvatarFrom = (data) => {
    if (data !== undefined) {
      setImageAvatarFrom(data);
    }
  };

  const onDeleteConfirmComment = async () => {
    dispatch(startLoading());
    try {
      await Network.post(API.deleteMediaPost, {
        postId: deleteComment,
      });
      await mediaPostDetailsByPostId();
      setDeleteComment(null);
      toast.success('Comment deleted successfully', toastConfig);
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(endLoading());
    }
  };

  const onDeletePost = (row) => {
    setDeletePost(row.mainpostid);
  };

  const onDeleteConfirm = async () => {
    dispatch(startLoading());
    try {
      await Network.post(API.deleteMediaPost, {
        postId: deletePost,
      });
      setDeletePost(null);
      toast.success('Post deleted successfully', toastConfig);
      navigate(`${PreparePath}/dashboard/media/posts`);
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(endLoading());
    }
  };

  return (
    <>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <StylishNewButton onClick={() => navigate(-1)}>
              <i className="fa fa-angle-left me-2" aria-hidden="true"></i>
              Back
            </StylishNewButton>
          </li>
          <li className="breadcrumb-item">
            <StylishNewButton
              onClick={() => navigate(`${PreparePath}/dashboard/media/posts`)}
            >
              Posts
            </StylishNewButton>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            {postDetails?.profilename}
          </li>
        </ol>
      </nav>
      <div className="row mb-4">
        <div className="col-xl-6 col-lg-12 mb-4 mb-xl-0">
          <div className="bg-gray-800 p-4">
            <div className="d-flex mb-3">
              <div className="flex-grow-1 d-flex flex-column flex-md-row">
                {postDetails?.profileavatar ? (
                  <div className="profile-img flex-shrink-0">
                    <img src={postDetails?.profileavatar} alt="" />
                  </div>
                ) : (
                  <div className="profile-img flex-shrink-0"></div>
                )}
                <div className="flex-grow-1 ps-0 ps-md-3 pt-3 pt-md-0">
                  <h4 className="mb-1 break-word">
                    {postDetails?.profilename}
                  </h4>

                  <p className="mb-1">
                    {'@'}
                    {postDetails?.profileusername}
                  </p>
                  <div className="d-flex align-items-center">
                    {postDetails?.siteIcon ? (
                      <div
                        className="me-2 has-img-h-20"
                        style={{
                          color: postDetails?.siteColor,
                        }}
                      >
                        {
                          AllIcon.find(
                            (icon) => icon.name === postDetails?.siteIcon
                          )?.icon
                        }
                      </div>
                    ) : null}
                    {postDetails?.site_image ? (
                      <div className="has-img-h-24 me-2">
                        <img
                          src={postDetails?.site_image}
                          alt=""
                          className="rounded-5 d-block"
                        />
                      </div>
                    ) : null}

                    {/* <img className="img-h-16 me-2" src={Fbicon} alt="" /> */}
                    <p className="mb-0">{postDetails?.siteName}</p>
                  </div>
                </div>
              </div>
              <div className="ms-2 flex-shrink-0">
                <Dropdown className="flex-shrink-0 ms-auto">
                  <Dropdown.Toggle className="button--icon">
                    <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                  </Dropdown.Toggle>
                  <Dropdown.Menu align="end">
                    {/* <Dropdown.Item>
                          <img src={IconLink} alt="" />
                          Permalink
                        </Dropdown.Item> */}
                    <Dropdown.Item
                      onClick={(e) => {
                        e.preventDefault();
                        onDeletePost(postDetails);
                      }}
                    >
                      <img src={IconDelete} alt="" />
                      Delete Post
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>

            <p>{postDetails?.profiledescription} </p>

            <form onSubmit={handleSubmit(onSubmit)}>
              {postDetails?.siteMediaType === 'video' && (
                <div className="mb-3">
                  <label className="form-label">Title</label>
                  <Controller
                    control={control}
                    name="title"
                    render={({ field: { onChange, value } }) => (
                      <StylishNewInput
                        // onChange={onChange}
                        onChange={(e) => {
                          setTitle(e.target.value);
                          onChange(e);
                        }}
                        value={title || value || ''}
                        className="form-control border-gray-600"
                      />
                    )}
                  />{' '}
                </div>
              )}
              <div className="mb-3">
                <label className="form-label">Text</label>
                {postDetails?.siteMediaType === 'video' ? (
                  <TinyMCEWithFormController
                    className="auto-height"
                    name="text"
                    control={control}
                    placeholder="Write Description"
                    rules={{ required: true }}
                    defaultValue={postDetails?.mainpost}
                  />
                ) : (
                  <TinyMCEWithFormController
                    className="mb-3"
                    name="text"
                    control={control}
                    rules={{ required: true }}
                    defaultValue={postDetails?.mainpost}
                  />
                )}
                {errors?.text && (
                  <span className="form-text form-error">
                    This is a required field
                  </span>
                )}
              </div>

              <div className="mb-3">
                <div className="d-flex">
                  <div
                    className="badge cursor-pointer"
                    onClick={() => {
                      setShowLikesCount(!showLikesCount);
                    }}
                  >
                    <img src={IconData} alt="" />
                    Data
                  </div>
                </div>
                {showLikesCount ? (
                  <div className="row">
                    <div className="col-md-6 mt-3">
                      <label className="form-label">Likes</label>
                      <Controller
                        control={control}
                        name="likes"
                        defaultValue={Math.floor(Math.random() * 90) + 100}
                        render={({ field: { onChange, value } }) => (
                          <StylishNewInput
                            type="number"
                            onKeyPress={handleKeyPress}
                            onChange={onChange}
                            value={value || ''}
                            className="form-control border-gray-600"
                          />
                        )}
                      />
                    </div>

                    <div className="col-md-6 mt-3">
                      <label className="form-label">Shares</label>
                      <Controller
                        control={control}
                        name="shares"
                        defaultValue={Math.floor(Math.random() * 90) + 10}
                        render={({ field: { onChange, value } }) => (
                          <StylishNewInput
                            type="number"
                            onKeyPress={handleKeyPress}
                            onChange={onChange}
                            value={value || ''}
                            className="form-control border-gray-600"
                          />
                        )}
                      />
                    </div>

                    {postDetails?.siteMediaType === 'video' && (
                      <div className="col-md-6 mt-3">
                        <label className="form-label">Dislikes</label>
                        <Controller
                          control={control}
                          name="disLikes"
                          defaultValue={Math.floor(Math.random() * 90) + 10}
                          // rules={{ required: true }}
                          render={({ field: { onChange, value } }) => (
                            <StylishNewInput
                              type="number"
                              onKeyPress={handleKeyPress}
                              onChange={onChange}
                              value={value || ''}
                              className="form-control border-gray-600"
                            />
                          )}
                        />
                      </div>
                    )}

                    {postDetails?.siteMediaType === 'video' && (
                      <div className="col-md-6 mt-3">
                        <label className="form-label d-block">Views</label>
                        <Controller
                          control={control}
                          name="views"
                          defaultValue={Math.floor(Math.random() * 90) + 10}
                          render={({ field: { onChange, value } }) => (
                            <StylishNewInput
                              type="number"
                              onKeyPress={handleKeyPress}
                              onChange={onChange}
                              value={value || ''}
                              className="form-control border-gray-600"
                            />
                          )}
                        />
                      </div>
                    )}
                  </div>
                ) : null}
              </div>

              <div className="mb-3">
                <label className="form-label d-block">Avatar</label>

                <div
                  className="badge cursor-pointer"
                  onClick={onChooseMediaDialog}
                >
                  <img src={IconChooseMedia} alt="" />
                  Choose Media
                </div>
                {isVisibleAvatar && (
                  <>
                    <div className="d-flex align-items-start mt-3">
                      <div className="flex-grow-1 me-3">
                        <img
                          src={imageAvatar}
                          className="rounded-5 w-100 h-auto d-block"
                          // style={{ height: '200px', width: 'auto' }}
                          alt=""
                        />
                      </div>
                      <StylishNewButton
                        customButton
                        className={'button--icon'}
                        onClick={(e) => {
                          setIsVisibleAvatar(false);
                          setImageAvatar(null);
                        }}
                      >
                        <img src={IconTimes} alt="" />
                      </StylishNewButton>
                    </div>
                  </>
                )}
              </div>

              <div className="mb-3">
                <label className="form-label d-block">Location</label>

                <div
                  className="badge cursor-pointer"
                  onClick={selectLocationHandler}
                >
                  <img src={IconLocation} className="me-3" alt="" />
                  Select Location
                </div>

                {lat && lng && place && (
                  <div className="col-md-12 mt-3">
                    <label className="form-label d-block">Training Area</label>
                    <div className="cards cards__location rounded bg-gray-700 p-3">
                      {/* <span className="remove" onClick={deleteLocation}>
                      <img src={IconClose} alt="" />
                    </span> */}
                      <p className="m-0">{place}</p>
                      <p className="m-0">
                        <span className="d-block">Lat/Lon:</span>
                        {lat} {lng}
                      </p>
                    </div>
                  </div>
                )}
              </div>
              {postDetails?.mainpoststatus === 'published' ? null : (
                <div className="col-md-6 mt-3">
                  <label className="form-label d-block">Scheduled Post</label>
                  <StylishSwitcher
                    checked={showTimeToPost}
                    onChange={() => setShowTimeToPost(!showTimeToPost)}
                  />
                </div>
              )}
              {showTimeToPost === true && (
                <div className="mb-0">
                  <label className="form-label">Scheduled Time</label>

                  <div className="d-flex flex-column flex-md-row">
                    <div className="flex-grow-1 posts-DateTimePicker">
                      <Controller
                        control={control}
                        name="scheduledTime"
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => (
                          <StylishDateTimePicker
                            onChange={(date) => {
                              setScheduledTime(date || null);
                              return onChange(date);
                            }}
                            selected={scheduledTime || ''}
                            key={startDateKey}
                            value={scheduledTime || ''}
                            isClearable={true}
                            timeFormat="HH:mm"
                            timeIntervals={20}
                            timeCaption="time"
                            dateFormat={
                              reduxUser?.timeFormat === 'hour12Mode'
                                ? 'MMMM d, yyyy hh:mm a'
                                : 'MMMM d, yyyy HH:mm'
                            }
                            placeholderText={'Choose a Date'}
                            disableDateTimeLabel={true}
                            type="datetime-local"
                            className="post-timepicker"
                          />
                        )}
                      />
                    </div>
                    <div className="button-group mt-3 mt-md-0 ms-md-3">
                      <StylishNewButton
                        type="button"
                        className="button--primary rounded flex-shrink-0"
                        onClick={handleNowButtonClick}
                      >
                        Now
                      </StylishNewButton>
                      <StylishNewButton
                        customButton
                        type="button"
                        className="button--icon primary lrg rounded flex-shrink-0"
                        onClick={handleResetButtonClick}
                      >
                        <img src={IconRefresh} alt="" />
                      </StylishNewButton>
                    </div>
                  </div>
                  {errors?.scheduledTime && (
                    <span className="form-text form-error">
                      This is a required field
                    </span>
                  )}
                </div>
              )}

              {/* <StylishNewButton
                customButton
                type="button"
                className="button--icon primary lrg rounded ms-xl-4 mt-3 mt-xl-0 flex-shrink-0"
                onClick={handleResetButtonClick}
              >
                <img src={IconRefresh} alt="" />
              </StylishNewButton> */}
              {/* </div>
            </div> */}
              <hr className="dashed" />

              <div className="button-group flex-column flex-md-row">
                {/* <button className="button button--primary button--reverse weight-500 w-100 w-md-auto mb-5 mb-md-0">
                Random
              </button> */}

                <div className="ms-0 ms-md-auto w-100 w-md-auto">
                  <StylishNewButton
                    className="button--secondary button--reverse w-100 w-md-auto mb-3 mb-md-0"
                    onClick={(e) => {
                      e.preventDefault();
                      navigate(`${PreparePath}/dashboard/media/posts`);
                    }}
                  >
                    Cancel
                  </StylishNewButton>

                  {showTimeToPost === false &&
                  (location?.state?.type === 'drafted' ||
                    location?.state?.type === undefined) ? (
                    <StylishNewButton
                      className="button--primary button--reverse weight-500 w-100 w-md-auto mb-3 mb-md-0 ms-0 ms-md-3"
                      onClick={(e) => {
                        onDraft(e);
                      }}
                    >
                      Save Draft
                    </StylishNewButton>
                  ) : null}
                  {postDetailsForParent?.mainpoststatus === 'scheduled' &&
                  showTimeToPost === false ? null : (
                    <StylishNewButton
                      className="button--primary weight-500 w-100 w-md-auto ms-0 ms-md-3"
                      type="submit"
                    >
                      {showTimeToPost === false
                        ? 'Publish Now'
                        : moment().diff(
                            moment(scheduledTime).format('MM/DD/YYYY HH:mm'),
                            'minutes'
                          ) >= 1
                        ? 'Back Date Post'
                        : 'Schedule Post'}
                    </StylishNewButton>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>

        <div className="col-xl-6 col-lg-12">
          <div className="bg-gray-800 p-4 ">
            <div className="mb-4">
              <div className="d-flex flex-column flex-md-row mb-4">
                {(
                  postDetails?.mainposttype === 'comment'
                    ? postDetailsForParent?.profileavatar
                    : postDetails?.profileavatar
                ) ? (
                  <div className="profile-img flex-shrink-0">
                    <img
                      src={
                        postDetails?.mainposttype === 'comment'
                          ? postDetailsForParent?.profileavatar
                          : postDetails?.profileavatar
                      }
                      alt=""
                    />
                  </div>
                ) : (
                  <div className="profile-img flex-shrink-0"></div>
                )}
                <div className="flex-grow-1 ps-0 ps-md-3 pt-3 pt-md-0">
                  <div className="d-flex align-items-start mb-2 flex-column flex-md-row align-items-md-center">
                    <h4 className="mb-0 flex-grow-1">
                      {postDetails?.mainposttype === 'comment'
                        ? postDetailsForParent?.profilename
                        : postDetails?.profilename}
                    </h4>
                    {(isVisibleAvatarAlreadyReceived ||
                      (postDetails?.mainposttype === 'comment'
                        ? postDetailsForParent?.mainpostlat
                        : postDetails?.mainpostlat)) && (
                      <div className="icon-group d-flex align-items-center mt-2 mt-md-0">
                        {postDetails?.mainposttype === 'comment'
                          ? postDetailsForParent?.mainpostlat && (
                              <>
                                <img src={IconCommentLocation} alt="" />
                              </>
                            )
                          : postDetails?.mainpostlat && (
                              <>
                                <img src={IconCommentLocation} alt="" />
                              </>
                            )}

                        {isVisibleAvatarAlreadyReceived && (
                          <>
                            <img src={IconCommentImage} alt="" />
                          </>
                        )}
                      </div>
                    )}
                  </div>
                  <div className="d-flex">
                    <p className="mb-0 ">
                      {'@'}
                      {postDetails?.mainposttype === 'comment'
                        ? postDetailsForParent?.profileusername
                        : postDetails?.profileusername}
                    </p>
                    <span className="mx-3">|</span>
                    <div className="d-flex align-items-center">
                      {postDetails?.mainposttype === 'comment' ? (
                        <>
                          {postDetailsForParent?.siteIcon ? (
                            <div
                              className="me-2 has-img-h-20"
                              style={{
                                color: postDetailsForParent?.siteColor,
                              }}
                            >
                              {
                                AllIcon.find(
                                  (icon) =>
                                    icon.name === postDetailsForParent?.siteIcon
                                )?.icon
                              }
                            </div>
                          ) : null}
                          {postDetailsForParent?.site_image ? (
                            <div className="has-img-h-24 me-2">
                              <img
                                src={postDetailsForParent?.site_image}
                                alt=""
                                className="rounded-5 d-block"
                              />
                            </div>
                          ) : null}
                        </>
                      ) : (
                        <>
                          {postDetails?.siteIcon ? (
                            <div
                              className="me-2 has-img-h-20"
                              style={{
                                color: postDetails?.siteColor,
                              }}
                            >
                              {
                                AllIcon.find(
                                  (icon) => icon.name === postDetails?.siteIcon
                                )?.icon
                              }
                            </div>
                          ) : null}
                          {postDetails?.site_image ? (
                            <div className="has-img-h-24 me-2">
                              <img
                                src={postDetails?.site_image}
                                alt=""
                                className="rounded-5 d-block"
                              />
                            </div>
                          ) : null}
                        </>
                      )}

                      {/* {comments?.site_image ? (
                        <div className="has-img-h-24 me-2">
                          <img
                            src={comments?.site_image}
                            alt=""
                            className="rounded-5 d-block"
                          />
                        </div>
                      ) : null} */}
                      <p className="mb-0 ">
                        {postDetails?.mainposttype === 'comment'
                          ? postDetailsForParent?.siteName
                          : postDetails?.siteName}
                      </p>
                    </div>
                  </div>

                  <div className="d-flex flex-column flex-md-row mt-2">
                    <p className="mb-0 ">
                      {moment(
                        postDetails?.mainposttype === 'comment'
                          ? postDetailsForParent?.mainpostcreatedat
                          : postDetails?.mainpostcreatedat
                      )?.fromNow()}{' '}
                      at{' '}
                      {moment(
                        postDetails?.mainposttype === 'comment'
                          ? postDetailsForParent?.mainpostcreatedat
                          : postDetails?.mainpostcreatedat
                      )?.format('MMM DD, YYYY  HH:mm')}
                    </p>
                    <p className="mb-0 ms-0 ms-md-auto">
                      {postDetails?.mainposttype === 'comment'
                        ? postDetailsForParent?.mainpoststatus
                            ?.charAt(0)
                            ?.toUpperCase() +
                          postDetailsForParent?.mainpoststatus?.slice(1)
                        : // postDetails?.mainpoststatus
                          postDetails?.mainpoststatus
                            ?.charAt(0)
                            ?.toUpperCase() +
                          postDetails?.mainpoststatus?.slice(1)}
                    </p>
                  </div>
                </div>
              </div>
              <p className="weight-600 mb-2">
                {postDetails?.mainposttype === 'comment'
                  ? postDetailsForParent?.mainposttitle
                  : postDetails?.mainposttitle}
              </p>
              <p
                className="mb-0"
                dangerouslySetInnerHTML={{
                  __html:
                    postDetails?.mainposttype === 'comment'
                      ? sanitizeHtml(postDetailsForParent?.mainpost)
                      : sanitizeHtml(postDetails?.mainpost),
                }}
              ></p>
              {isVisibleAvatarAlreadyReceived && (
                <>
                  <div className="d-flex align-items-start mt-3">
                    <img
                      src={imageAvatarReceived}
                      className="rounded-5"
                      style={{ height: 'auto', width: '100%' }}
                      alt=""
                    />
                  </div>
                </>
              )}
            </div>
            <div className="d-flex justify-content-center align-items-center flex-wrap mb-3">
              <span
                onClick={() => setFilter(null)}
                className={`cursor-pointer ${
                  filter === null ? 'color-brand' : ''
                }`}
              >
                All Comments
              </span>
              <span className="separator--y bg-white flex-shrink-0 h--16 mx-2"></span>
              <span
                onClick={() => setFilter('published')}
                className={`cursor-pointer ${
                  filter === 'published' ? 'color-brand' : ''
                }`}
              >
                Published
              </span>
              <span className="separator--y bg-white flex-shrink-0 h--16 mx-2"></span>
              <span
                onClick={() => setFilter('scheduled')}
                className={`cursor-pointer ${
                  filter === 'scheduled' ? 'color-brand' : ''
                }`}
              >
                Scheduled
              </span>
              <span className="separator--y bg-white flex-shrink-0 h--16 mx-2"></span>
              <span
                onClick={() => setFilter('drafted')}
                className={`cursor-pointer ${
                  filter === 'drafted' ? 'color-brand' : ''
                }`}
              >
                Drafted
              </span>
            </div>
            {commentsOnPost?.length > 0 ? (
              <>
                <hr className="dashed my-3" />

                {commentsOnPost.map((comments) => {
                  return (
                    <div
                      className="posts-row cursor-pointer"
                      key={comments?.id}
                      onClick={(e) => {
                        e.preventDefault();
                        onViewPostDialog(comments?.id);
                      }}
                    >
                      <p className="d-flex align-items-center">
                        <div className="has-img-h-16 me-2">
                          <img src={IconResponce} alt="" />
                        </div>
                        In response to @{postDetails?.profileusername}'s post
                      </p>

                      <div className="d-flex">
                        <div className="flex-grow-1 ">
                          <div className="d-flex flex-column flex-md-row">
                            {comments?.profileAvatar ? (
                              <div className="profile-img flex-shrink-0">
                                <img src={comments?.profileAvatar} alt="" />
                              </div>
                            ) : (
                              <div className="profile-img flex-shrink-0"></div>
                            )}

                            <div className="flex-grow-1 ps-0 ps-md-3 pt-3 pt-md-0">
                              <div className="d-flex align-items-start align-items-md-center mb-2 flex-column flex-md-row">
                                <h4 className="mb-0 break-word flex-grow-1">
                                  {comments?.profileName}
                                </h4>
                                {(comments?.lat || comments?.commentImg) && (
                                  <div className="icon-group d-flex align-items-center mt-2">
                                    {(comments?.lat ||
                                      comments?.lng ||
                                      comments?.locationName) && (
                                      <>
                                        <img src={IconCommentLocation} alt="" />
                                      </>
                                    )}

                                    {comments?.commentImg && (
                                      <>
                                        <img src={IconCommentImage} alt="" />
                                      </>
                                    )}
                                  </div>
                                )}
                              </div>

                              <div className="d-flex">
                                <p className="mb-0 ">
                                  {'@'} {comments?.profileUsername}
                                  {/* {post?.username} */}
                                </p>
                                <span className="mx-3">|</span>
                                <div className="d-flex align-items-center">
                                  {comments?.siteIcon ? (
                                    <div
                                      className="me-2 has-img-h-20"
                                      style={{
                                        color: comments?.siteColor,
                                      }}
                                    >
                                      {
                                        AllIcon.find(
                                          (icon) =>
                                            icon.name === comments?.siteIcon
                                        )?.icon
                                      }
                                    </div>
                                  ) : null}
                                  {comments?.site_image ? (
                                    <div className="has-img-h-24 me-2">
                                      <img
                                        src={comments?.site_image}
                                        alt=""
                                        className="rounded-5 d-block"
                                      />
                                    </div>
                                  ) : null}

                                  <p className="mb-0 ">
                                    {comments?.siteName}
                                    {/* {post?.siteName} */}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="ms-3 flex-shrink-0">
                          <Dropdown
                            className="flex-shrink-0 ms-auto"
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                          >
                            <Dropdown.Toggle className="button--icon">
                              <i
                                className="fa fa-ellipsis-v"
                                aria-hidden="true"
                              ></i>
                            </Dropdown.Toggle>
                            <Dropdown.Menu align="end">
                              <Dropdown.Item
                                onClick={(e) => {
                                  navigate(
                                    `${PreparePath}/dashboard/media/posts/edit-post/${comments?.id}`
                                  );
                                }}
                              >
                                <img src={IconEdit} alt="" />
                                Edit Comment
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() => onDeleteComment(comments)}
                              >
                                <img src={IconDelete} alt="" />
                                Delete Comment
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>

                      <div className="row--status d-flex flex-column flex-md-row mt-2 ms-auto">
                        <p className="mb-0">
                          {moment(comments?.createdAt)?.fromNow()} at{' '}
                          {moment(comments?.createdAt)?.format(
                            'MMM DD, YYYY  HH:mm'
                          )}
                        </p>

                        <p className="mb-0 ms-0 ms-md-auto">
                          {comments?.status?.charAt(0).toUpperCase() +
                            comments?.status?.slice(1)}
                        </p>
                      </div>

                      {comments?.title && (
                        <p className="mb-0 mt-3 weight-500">
                          {comments?.title}
                        </p>
                      )}

                      {comments?.comment && (
                        <p
                          className="mb-0 mt-3"
                          dangerouslySetInnerHTML={{
                            __html: sanitizeHtml(
                              `${comments?.comment?.slice(
                                0,
                                contentBlockLength
                              )}`
                            ),
                          }}
                        ></p>
                      )}
                      <hr className="dashed my-3" />
                    </div>
                  );
                })}
              </>
            ) : (
              <div className="bg-gray-800 text-center p-3 border-700 dashed wide-2px">
                <div className="py-0 py-md-4">
                  <div className="d-flex justify-content-center mb-4">
                    <img src={IconEmptyExercise} alt="" />
                  </div>
                  <p className="weight-400 mb-0">No Comments Found</p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {selectLocation && (
        <SelectLocation
          show={selectLocation}
          onClose={onCloseModal}
          onSaveClicked={onSaveClicked}
          storedLat={lat}
          storedLng={lng}
          storedPlace={place}
        />
      )}

      {showChooseMediaDialog && (
        <ChooseMediaDialog
          show={showChooseMediaDialog}
          onClose={onChooseMediaDialogClose}
          onSubmitImageAvatar={onSubmitImageAvatar}
          typeModalProfile={'Avatar'}
          onSelectedImageAvatar={imageAvatar}
          imageAvatarFrom={imageAvatarFrom}
          onSubmitImageAvatarFrom={onSubmitImageAvatarFrom}
        />
      )}
      {showViewPostDialog && (
        <ViewPostDialog
          show={showViewPostDialog}
          onClose={onViewPostDialogClose}
          postId={commentId}
        />
      )}

      {editPost && (
        <StylishConfirmDialog
          show={editPost}
          onClose={() => setEditPost(false)}
          dialogTitle={'Are you Sure?'}
          dialogContent={`This 
          ${
            postSubmitType !== 'draft'
              ? moment().diff(
                  moment(scheduledTime).format('MM/DD/YYYY HH:mm'),
                  'minutes'
                ) >= 1 || !scheduledTime
                ? 'will publish your post immediately and cannot be undone, are you sure'
                : 'will publish your post automatically at the time selected and cannot be undone, are you sure'
              : 'post will save as a draft and will not be published. Continue'
          }
          ?`}
          dialogButtonText={'OK'}
          onConfirm={onSaveConfirm}
        />
      )}
      {deleteComment && (
        <StylishConfirmDialog
          show={deleteComment}
          onClose={() => setDeleteComment(null)}
          dialogTitle={'Delete Comment'}
          dialogContent={'Are you sure you want this?'}
          onConfirm={onDeleteConfirmComment}
        />
      )}
      {deletePost && (
        <StylishConfirmDialog
          show={deletePost}
          onClose={() => setDeletePost(null)}
          dialogTitle={'Delete Post'}
          dialogContent={'Are you sure you want this?'}
          onConfirm={onDeleteConfirm}
        />
      )}
    </>
  );
}
