import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import API from '../../../service/api';
import Network from '../../../service/Network';

import IconChecked from 'assets/images/icon__tick.svg';
import { inputStringLength, toastConfig } from 'assets/data/config';
import StylishIconSelected from '../../../../components/DesignSystems/New/StylishIconSelected/StylishIconSelected';
import { AllIcon } from '../../plan/Prepare/Participant/Icon';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import StylishNewInput from 'components/DesignSystems/New/StylishNewInput';
export default function UpdateNotebook({ onClose, show, onSuccess, notebook }) {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    getValues,
    setError,
    clearErrors,
  } = useForm();

  const workspaceId = useSelector(
    (state) => state.prepare.workspace.selectedWorkspace
  );
  const [buttonDisable, setButtonDisable] = useState(false);

  const colors = [
    '#D32F2F',
    '#C2185B',
    '#7B1FA2',
    '#512DA8',
    '#303F9F',
    '#1976D2',
    '#0288D1',
    '#0097A7',
    '#00796B',
    '#388E3C',
    '#689F38',
    '#AFB42B',
    '#FBC02D',
    '#FFA000',
    '#F57C00',
    '#E64A19',
    '#5D4037',
    '#616161',
    '#455A64',
  ];

  useEffect(() => {
    setValue(
      'iconSelect',
      AllIcon.find((icon) => icon.name === notebook.notebookIcon)
    );

    setValue('title', notebook.notebookTitle);
    setValue('color', notebook.notebookColor);
    setValue('customColor', notebook.notebookColor);
  }, []);

  const predefinedColorHandler = () => {
    const checkedValue = getValues('color');

    setValue('customColor', checkedValue);

    if (!checkedValue) {
      setError('customColor');
    } else if (checkedValue) {
      clearErrors('customColor');
    }
  };

  const enteredColorHandler = () => {
    const enteredValue = getValues('customColor');

    const isPredefinedColor = colors.includes(enteredValue);

    isPredefinedColor ? setValue('color', enteredValue) : setValue('color', '');
  };

  const onSubmit = async (data) => {
    const formData = {
      title: data.title,

      color: data.color?.length ? data.color : data.customColor,

      icon: data.iconSelect.name,
    };

    setButtonDisable(true);
    try {
      const updateNotebook = await Network.post(API.updateNotebook, {
        title: formData.title,

        color: formData.color,

        icon: formData.icon,

        workspaceId: workspaceId,
        notebookId: notebook.notebookId,
      });

      if (updateNotebook !== undefined) {
        toast.success('Notebook updated successfully', toastConfig);
        onSuccess();
      }
    } catch (error) {
      toast.error(error.response.data.response.status.msg, toastConfig);
      onClose();
    } finally {
      setButtonDisable(false);
    }
  };
  useEffect(() => {
    const listener = (event) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        event.preventDefault();
        handleSubmit(onSubmit)();
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, []);
  return (
    <Modal
      show={show}
      onHide={onClose}
      centered
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title>Create Notebook</Modal.Title>
      </Modal.Header>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <div className="mb-3">
            <label className="form-label">Title</label>
            <Controller
              control={control}
              name="title"
              defaultValue={notebook?.notebookTitle}
              rules={{ required: true, maxLength: inputStringLength }}
              render={({ field: { onChange, value } }) => (
                <StylishNewInput
                  type="text"
                  onChange={onChange}
                  value={value}
                />
              )}
            />
            {errors?.title?.type === 'required' && (
              <span className="form-text form-error">
                This is a required field
              </span>
            )}
            {errors?.title?.type === 'maxLength' && (
              <span className="form-text form-error">
                Max length is {inputStringLength} characters
              </span>
            )}
          </div>

          <div className="col-12">
            <label className="form-label d-block">Icon</label>

            <Controller
              control={control}
              {...register('iconSelect', { required: true })}
              rules={{ required: false }}
              render={({ field: { onChange, value } }) => (
                <StylishIconSelected
                  onIconChange={onChange}
                  selectedIcon={value}
                />
              )}
            />

            {errors?.iconSelect && (
              <span className="form-text form-error">
                This is a required field
              </span>
            )}
          </div>

          <div className="mb-0">
            <label className="form-label">Color</label>

            <div className="mb-3">
              <ul className="nav nav__form-color">
                {colors.map((item, index) => (
                  <li key={index}>
                    <div className="form-color" style={{ background: item }}>
                      <Controller
                        control={control}
                        name="color"
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => (
                          <StylishNewInput
                            type="radio"
                            name="color"
                            value={item}
                            onChange={(e) => {
                              onChange(e);
                              predefinedColorHandler(e);
                            }}
                            checked={item === value}
                          />
                        )}
                      />

                      <span className="icon">
                        <img src={IconChecked} alt="" />
                      </span>
                    </div>
                  </li>
                ))}
              </ul>
            </div>

            <div className="m-0">
              <Controller
                control={control}
                name="customColor"
                rules={{
                  required: true,
                  pattern: /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/i,
                }}
                render={({ field: { onChange, value } }) => (
                  <StylishNewInput
                    type="text"
                    onChange={(e) => {
                      onChange(e);
                      enteredColorHandler(e);
                    }}
                    value={value}
                    placeholder="HEX Value"
                  />
                )}
              />
            </div>

            {errors?.customColor?.type === 'required' && (
              <span className="form-text form-error">
                This is a required field
              </span>
            )}

            {errors?.customColor?.type === 'pattern' && (
              <div className="alert alert-info mt-2" role="alert">
                <ul className="list-ordered">
                  <li>{"The HEX value must start with '#'"}</li>

                  <li>The HEX value must contain at least 3 characters</li>

                  <li>The HEX value can contain max 6 characters</li>
                </ul>
              </div>
            )}
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div className="button-group">
            <StylishNewButton
              className="button--secondary button--reverse"
              type="button"
              onClick={onClose}
            >
              Cancel
            </StylishNewButton>

            <StylishNewButton
              disabled={buttonDisable ? true : false}
              className="button--primary"
              type="submit"
            >
              Save
            </StylishNewButton>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
