export const columnsInUserRoleInfoTable = [
  { dataField: 'type', text: 'User Type' },
  { dataField: 'role', text: 'Role' },
];
export const rowsInUserRoleInfoTable = [
  {
    id: 1,
    type: 'User',
    role: 'Participant/Player',
  },
  {
    id: 2,
    type: 'Author',
    role: 'Evaluator/Observer',
  },
  {
    id: 3,
    type: 'Editor',
    role: 'Participant Planner (Trusted Agent)',
  },
  {
    id: 4,
    type: 'Admin',
    role: 'Primary Exercise Planner',
  },
];
