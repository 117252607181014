import { getLoaclTimeZoneAndOffset } from '../../utils/dashboardApplication';
import {
  CHANGE_SELECTED_WORKSPACE,
  CHANGE_SELECTED_WORKSPACE_NAME,
  CHANGE_WORKSPACE_APP_LIST,
  CHANGE_WORKSPACE_TIMEZONE,
  UPDATE_WORKSPACE_APPLICATION_BY_ID,
  UPDATE_ALL_WORKSPACE_LIST,
  ADD_WORKSPACE_TO_WROKSPACE_LIST,
} from './workspaces.constants';

const initialState = {
  selectedWorkspace: null,
  timezone: getLoaclTimeZoneAndOffset().timezone,
  timezoneOffset: getLoaclTimeZoneAndOffset().offset,
  appList: [],
  workspaceName: null,
  type: null,
  allWorkspace: [],
  allWorkspaceLoaded: false,
};

const workspaceReducer = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_SELECTED_WORKSPACE:
      return {
        ...state,
        selectedWorkspace: action.payload,
      };

    case CHANGE_SELECTED_WORKSPACE_NAME:
      return {
        ...state,
        workspaceName: action.payload.workspaceName,
        type: action.payload.type,
      };

    case CHANGE_WORKSPACE_APP_LIST:
      return {
        ...state,
        appList: action.payload,
      };
    case UPDATE_ALL_WORKSPACE_LIST:
      return {
        ...state,
        allWorkspace: action.payload,
        allWorkspaceLoaded: true,
      };
    case ADD_WORKSPACE_TO_WROKSPACE_LIST:
      const index = state.allWorkspace.findIndex(
        (w) => w.id === action.payload.id
      );
      if (index !== -1) {
        const allNewWorkspace = [...state.allWorkspace];
        allNewWorkspace[index] = action.payload;
        return {
          ...state,
          allWorkspace: allNewWorkspace,
        };
      } else {
        return {
          ...state,
          allWorkspace: [...state.allWorkspace, action.payload],
        };
      }

    case CHANGE_WORKSPACE_TIMEZONE:
      let returnState = {
        ...state,
      };
      if (action.payload.timezone) {
        returnState.timezone = action.payload.timezone;
      }
      if (action.payload.timezoneOffset) {
        returnState.timezoneOffset = action.payload.timezoneOffset;
      }
      return returnState;
    case UPDATE_WORKSPACE_APPLICATION_BY_ID:
      const appList = [...state.appList];
      const appIndex = state.appList.findIndex(
        (app) => app.id === action.payload
      );
      if (appIndex !== -1) {
        appList[appIndex] = {
          ...appList[appIndex],
          checked: !appList[appIndex].checked,
        };
        return {
          ...state,
          appList: appList,
        };
      }
      return state;
    default:
      return state;
  }
};

export default workspaceReducer;
