import React, { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import API from '../../../service/api';
import Network from '../../../service/Network';
import { useForm, Controller } from 'react-hook-form';
import { toast } from 'react-toastify';
// import ChangePasswordModalDialog from "./ChangePasswordModalDialog";
import {
  updateUserName,
  updateUserTimeFormat,
} from '../../../store/user/user.action';
import StylishHelp from 'components/DesignSystems/New/StylishHelp';
import { HelpUserSettings, useSelfHostedKnowledgeBase } from 'assets/data/HelpContent';
import { inputStringLength, toastConfig } from 'assets/data/config';
import { timeFormatOptions } from 'assets/data/config';
import StylishNewSelect from 'components/DesignSystems/New/StylishNewSelect';
import StylishNewInput from 'components/DesignSystems/New/StylishNewInput';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { endLoading, startLoading } from "../../../../reducers/loading/loading.action";

export default function UserSettings() {
  // const [showchangePasswordModal, setShowChangePasswordModal] = useState(false);
  const [user, setUser] = useState();
  const workspaceId = useSelector(
    (state) => state.prepare.workspace.selectedWorkspace
  );

  const selfHostedKnowledgeBase = useSelfHostedKnowledgeBase()

  const reduxUser = useSelector((state) => state.prepare.user);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    control,
  } = useForm();

  const onSubmit = async (data) => {
    dispatch(startLoading());
    try {
      await Network.post(API.updateUserDetails, {
        name: data.name,
        userId: reduxUser.id,
        timeFormat: data?.timeFormat?.value,
      });
      dispatch(updateUserName(data.name));
      dispatch(updateUserTimeFormat(data?.timeFormat?.value));
      getUserData();
      toast.success('User Data updated succesfully', toastConfig);
    } catch (error) {
      toast.error(error.response.data.response.status.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };

  const getUserData = async () => {
    dispatch(startLoading());
    try {
      const userData = await Network.get(API.userDetails, {
        userId: reduxUser.id,
      });
      setUser(userData.data.response.dataset[0]);
      const timeFormatValue = timeFormatOptions.find(
        (tF) => tF.value === userData.data.response.dataset[0].timeFormat
      );
      setValue('name', userData.data.response.dataset[0]?.name || '');
      setValue('email', userData.data.response.dataset[0]?.email || '');

      setValue('timeFormat', timeFormatValue || timeFormatOptions[0]);
    } catch (error) {
      console.log(error);
      // toast.error(error.response.data.response.status.msg);
    } finally {
      dispatch(endLoading());
    }
  };

  const dispatch = useDispatch();
  useEffect(() => {
    getUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reduxUser, workspaceId]);

  const changePasswordModalOpen = () => setShowChangePasswordModal(true);

  return (
    <>
      <div className="d-flex mb-4">
        <StylishHelp
          classes={'ms-auto'}
          title={HelpUserSettings.title}
          content={HelpUserSettings.content}
          link={selfHostedKnowledgeBase || HelpUserSettings.link}
        />
      </div>
      <div className="settings__user-settings">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row align-items-center">
            <div className="col-md-6 col-xl-5 mb-4 mb-md-0">
              <div className="d-flex align-items-center">
                <div className="initials me-3 me-md-4">
                  <h1>
                    {user?.name
                      ?.match(/(\b\S)?/g)
                      ?.join('')
                      ?.match(/(^\S|\S$)?/g)
                      ?.join('')
                      ?.toUpperCase()}
                  </h1>
                </div>
                <div>
                  <h4 className="m-0">{user?.name}</h4>
                  <p className="m-0">{user?.email}</p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-xl-7 separator--left ps-md-4 ps-xl-5 pb-5">
              <div className="mb-3">
                <label className="form-label">Name</label>
                <Controller
                  control={control}
                  name="name"
                  maxLength={inputStringLength}
                  defaultValue={user?.name}
                  rules={{
                    required: true,
                    pattern: /^[A-Za-z]*[A-Za-z][A-Za-z0-9-. _]*$/,
                  }}
                  render={({ field: { onChange, value } }) => (
                    <StylishNewInput
                      className="form-control"
                      onChange={onChange}
                      value={value}
                    />
                  )}
                />
                {errors?.name?.type === 'required' && (
                  <span className="form-text form-error">
                    This field is required
                  </span>
                )}
                {errors?.name?.type === 'pattern' && (
                  <span className="form-text form-error">
                    Name cannot start with a number
                  </span>
                )}
              </div>
              <div className="mb-3">
                <label className="form-label">Email</label>
                <Controller
                  control={control}
                  name="email"
                  maxLength={inputStringLength}
                  defaultValue={user?.email}
                  render={({ field: { onChange, value } }) => (
                    <StylishNewInput
                      className="form-control"
                      onChange={onChange}
                      value={value}
                      disabled
                    />
                  )}
                />
              </div>
              <div className="mb-0">
                <label className="form-label">Preferred Time Format</label>
                <Controller
                  control={control}
                  name="timeFormat"
                  render={({ field: { onChange, value } }) => (
                    <StylishNewSelect
                      options={timeFormatOptions}
                      defaultValue={value}
                      value={value}
                      onChange={onChange}
                      placeholder={'Select preferred time format'}
                      isClearable={false}
                      isSearchable={true}
                      isMulti={false}
                      isDisabled={false}
                    />
                  )}
                />
              </div>
              {/* <div className="mb-4 mb-md-5">
                <label className="form-label">Password</label>
                <div>
                  <button
                    className="button button--primary"
                    type="button"
                    onClick={changePasswordModalOpen}
                  >
                    Change Password
                  </button>
                </div>
              </div> */}
            </div>
            <div className="col-md-12">
              <div className="button-group justify-content-end pt-4">
                <StylishNewButton
                  className="button button--primary"
                  type="submit"
                >
                  Save
                </StylishNewButton>
              </div>
            </div>
          </div>
        </form>
      </div>
      {/* {showchangePasswordModal && (
        <ChangePasswordModalDialog
          show={showchangePasswordModal}
          onClose={() => setShowChangePasswordModal(false)}
        />
      )} */}
    </>
  );
}
