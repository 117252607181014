import React, { useState } from 'react';
import moment from 'moment';
import EditMSELTimeline from './EditMSELTimeline';
import { SharedIcon } from '../../../../../components/SharedIcon/SharedIcon';

export default function MSELTimelineItem({ item, onStatusUpdate }) {
  const [editMSELTimeline, setEditMSELTimeline] = useState(false);
  const [viewId, setViewId] = useState();
  const onViewInject = (id) => {
    setViewId(id);
    setEditMSELTimeline(true);
  };
  const onCloseViewInjectModal = () => {
    setViewId();
    setEditMSELTimeline(false);
  };
  return (
    <>
      {!!item.timelines &&
        item.timelines.length > 0 &&
        item.timelines.map((timeline, index) => {
          return (
            <>
              <div className="row-info mb-2 mb-md-0">
                <div className="info d-xl-flex align-items-start justify-content-end h-100 position-md-relative ps-4 ps-lg-5 ps-xl-0">
                  {index === 0 ? (
                    <span className="date">
                      {item.date && moment(item.date).format('MMM Do YYYY')}
                    </span>
                  ) : null}
                  <span
                    className="round"
                    style={{ borderColor: '#0099FF' }}
                  ></span>

                  <span
                    className="status"
                    style={{ background: timeline.statusColor }}
                  >
                    {timeline.statusLabel}
                  </span>
                </div>
              </div>

              <div
                className={`row-cards ${
                  index !== item.timelines.length - 1
                    ? 'ms-auto mb-3 mb-md-2'
                    : ''
                }`}
                key={timeline.id}
                onClick={() => onViewInject(timeline.id)}
              >
                <div className="row">
                  <div className="col-md-6 pe-md-4 mb-4 mb-md-0">
                    <div
                      className="card"
                      style={{ background: timeline.statusColor }}
                    >
                      <div>
                        <p className="mb-1">
                          {timeline.number && (
                            <span>#{timeline.number} - </span>
                          )}
                          <span>{timeline.injectTitle}</span>
                        </p>
                        <p className="mb-0">
                          {timeline.startDate &&
                            moment(timeline.startDate).format(
                              ' DD MMM YYYY @ HHMM'
                            )}
                        </p>
                        {timeline.startDate &&
                          moment(timeline.startDate).isBefore(moment()) &&
                          timeline.statusLabel === 'Scheduled' && (
                            <span
                              className="status status--xsml mt-1"
                              style={{ backgroundColor: '#dc3545' }}
                            >
                              Past Due
                            </span>
                          )}
                      </div>
                      <div>
                        <p className="mb-0">{timeline.injetOwnerTitle}</p>
                        <span
                          className="status status--xsml mt-1"
                          style={{ background: timeline.planEventColor }}
                        >
                          {timeline.planEvent}
                        </span>
                      </div>
                      <SharedIcon iconName="keyboard_arrow_right" bold />
                    </div>
                  </div>
                  <div className="col-md-6 ps-md-4">
                    <div
                      className="card"
                      style={{
                        background: !timeline.statusLabel
                          ? '#ECEFF0'
                          : timeline.statusLabel === 'Complete' ||
                            timeline.statusLabel === 'Incomplete'
                          ? timeline.statusColor
                          : '#95A5A6',
                      }}
                    >
                      <div className="flex-grow-1">
                        <p className="mb-1">
                          {timeline.number && (
                            <span>#{timeline.number} - </span>
                          )}
                          {timeline.anticipatedResponse && (
                            <span>{timeline.anticipatedResponse}</span>
                          )}
                        </p>
                        <p className="m-0">
                          {timeline.responsedate &&
                            moment(timeline.responsedate).format(
                              ' DD MMM YYYY @ HHMM'
                            )}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          );
        })}
      {editMSELTimeline && (
        <EditMSELTimeline
          show={editMSELTimeline}
          onClose={onCloseViewInjectModal}
          viewId={viewId}
          onStatusUpdate={onStatusUpdate}
        />
      )}
    </>
  );
}
