import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { PreparePath } from 'utils/sharedUtils/sharedConst';
import IconEmpty from 'assets/images/icon__exercise-details-empty.svg';
import Dropdown from 'react-bootstrap/Dropdown';
import moment from 'moment';
import ReactPaginate from 'react-paginate';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timegrid from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import { useDispatch, useSelector } from 'react-redux';
import { endLoading, startLoading } from 'reducers/loading/loading.action';
import Network from 'PREPAREsrc/service/Network';
import API from 'PREPAREsrc/service/api';
import { StylishNewSearchBar } from 'components/DesignSystems/New/StylishNewSearchBar';
import { StylishConfirmDialog } from 'components/DesignSystems/New/StylishConfirmDialog';
import { SharedIcon } from 'components/SharedIcon/SharedIcon';

export const MissionsDashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const reduxUser = useSelector((state) => state.prepare.user);
  const { selectedWorkspace, type: workspaceType } = useSelector(
    (state) => state?.prepare?.workspace
  );

  const [allMissions, setAllMissions] = useState();
  const [totalMissions, setTotalMissions] = useState(0);
  const [view, setView] = useState('list');
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState();
  const [calendarData, setCalendarData] = useState([]);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [toBeDeletedMission, setToBeDeletedMission] = useState();
  const perPage = 12;

  useEffect(() => {
    if (workspaceType && workspaceType !== 'T&E')
      navigate(`${PreparePath}/dashboard/plan`);
  }, [workspaceType]);

  useEffect(() => {
    if (selectedWorkspace) {
      fetchAllMissions();
    }
  }, [page, searchTerm, selectedWorkspace]);

  const fetchAllMissions = async () => {
    try {
      dispatch(startLoading());
      const response = await Network.get(API.fetchAllMissions, {
        page,
        search: searchTerm,
        workspaceId: selectedWorkspace,
        limit: perPage,
      });
      if (response?.status === 200 && response !== undefined) {
        setAllMissions(response?.data?.response?.dataset);
        setTotalMissions(response?.data?.response?.total);
        dispatch(endLoading());
      }
    } catch (error) {
      console.error(error);
      dispatch(endLoading());
    }
  };

  useEffect(() => {
    if (allMissions && !!allMissions?.length) {
      const refinedData = allMissions?.map((i) => {
        return {
          title: i?.name,
          start: i?.start_date,
          end: i?.end_date,
          id: i?.id,
        };
      });
      setCalendarData(refinedData);
    }
  }, [allMissions]);

  const handlePageClick = (event) => {
    setPage(event.selected + 1);
  };

  const onChangeSearchTerm = (termSearched) => {
    setSearchTerm(termSearched);
    setPage(1);
  };

  const handleDeleteMission = async () => {
    if (!toBeDeletedMission) return;
    try {
      dispatch(startLoading());
      const response = await Network.post(API?.deleteMission, {
        id: toBeDeletedMission,
      });
      if ((response?.status === 200) & (response !== undefined)) {
        setShowDeleteConfirmation(false);
        setToBeDeletedMission();
        dispatch(endLoading());
        fetchAllMissions();
      }
    } catch (error) {
      console.error(error);
      dispatch(endLoading());
    }
  };

  return (
    <>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <StylishNewButton
              onClick={() => navigate(`${PreparePath}/dashboard/plan`)}
            >
              <SharedIcon iconName="keyboard_arrow_left" classes="me-2" />
              Back
            </StylishNewButton>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Missions
          </li>
        </ol>
      </nav>
      <div className="d-md-flex align-items-center mb-4">
        <StylishNewButton
          className="button--primary w-100 w-md-auto mb-3 mb-md-0"
          onClick={() =>
            navigate(`${PreparePath}/dashboard/plan/create-mission`)
          }
        >
          Create Missions
        </StylishNewButton>
        <div className="d-flex flex-wrap align-items-center ms-auto">
          <>
            <p className="m-0 me-md-3 text-nowrap order-1 order-md-0">
              {totalMissions || 0} Mission(s)
            </p>
            <StylishNewSearchBar
              className={'w-100 w-md-auto mb-3 mb-md-0 me-md-3'}
              onChangeSearchTerm={onChangeSearchTerm}
              value={searchTerm}
            />
          </>
          <div className="button-group toggle ms-lg-auto me-md-3 mb-3 mb-md-0 w-100 w-md-auto">
            <StylishNewButton
              customButton
              className={`button--icon lrg flex-grow-1 ${
                view === 'list' ? 'active' : ''
              }`}
              onClick={() => setView('list')}
            >
              <i className="fa fa-th-large" aria-hidden="true"></i>
            </StylishNewButton>
            <StylishNewButton
              customButton
              className={`button--icon lrg flex-grow-1 m-0 ${
                view === 'calendar' ? 'active' : ''
              }`}
              onClick={() => setView('calendar')}
            >
              <i className="fa fa-calendar" aria-hidden="true"></i>
            </StylishNewButton>
          </div>
        </div>
      </div>
      {(allMissions && allMissions?.length && (
        <>
          {(view === 'list' && (
            <>
              <div className="row">
                {allMissions?.map((mission) => (
                  <div
                    className="col-md-6 col-lg-4 col-xl-3 pb-3 mb-4"
                    key={mission?.id}
                  >
                    <div className="section__mission-item bg-gray-800 rounded p-4 cursor-pointer position-relative">
                      <Link
                        className="stretched-link"
                        to={`${PreparePath}/dashboard/plan/view-mission/${mission?.id}`}
                      ></Link>
                      <div className="title d-flex align-items-center mb-4 h-min-auto">
                        <div
                          className="d-flex align-items-center flex-grow-1 me-4"
                          style={{ width: 'calc(100% - (34px + 1.5rem))' }}
                        >
                          <div
                            className="d-flex align-items-center flex-grow-1"
                            style={{ width: 'calc(100% - 3.25rem)' }}
                          >
                            {(mission?.name && (
                              <h4 className="m-0 text-truncate">
                                {mission?.name}
                              </h4>
                            )) ||
                              null}
                          </div>
                        </div>
                        <Dropdown className="flex-shrink-0 ms-auto position-relative zIndex--1">
                          <Dropdown.Toggle className="button--icon">
                            <SharedIcon iconName="more_vert" bold />
                          </Dropdown.Toggle>
                          <Dropdown.Menu align="end">
                            <Link
                              className="dropdown-item"
                              to={`${PreparePath}/dashboard/plan/edit-mission/${mission?.id}`}
                            >
                              <SharedIcon iconName="stylus" />
                              Edit Mission
                            </Link>
                            <Link
                              className="dropdown-item"
                              to={`${PreparePath}/dashboard/plan/view-mission/${mission?.id}`}
                            >
                              <SharedIcon iconName="visibility" />
                              View Mission
                            </Link>
                            <Dropdown.Item
                              onClick={() => {
                                setToBeDeletedMission(mission?.id);
                                setShowDeleteConfirmation(true);
                              }}
                            >
                              <SharedIcon iconName="delete" />
                              Delete Mission
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                      {(mission?.start_date && mission?.end_date && (
                        <p className="weight-600 mb-1">
                          {moment(mission?.start_date).format(
                            'DD MMM YY - hh:mma'
                          )}{' '}
                          -{' '}
                          {moment(mission?.end_date).format(
                            'DD MMM YY - hh:mma'
                          )}
                        </p>
                      )) ||
                        null}
                      {((mission?.primary_action_officer_name ||
                        mission?.primary_action_officer_email) && (
                        <p className="mb-1">
                          Primary Action Officer -{' '}
                          {mission?.primary_action_officer_name ||
                            mission?.primary_action_officer_email}
                        </p>
                      )) ||
                        null}
                      <p>{mission?.system_count || '0'} System(s)</p>
                      <div
                        className="details"
                        onClick={() =>
                          navigate(
                            `${PreparePath}/dashboard/plan/view-mission/${mission?.id}`
                          )
                        }
                      >
                        <SharedIcon iconName="keyboard_arrow_right" bold />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              {(totalMissions > perPage && (
                <div className="d-flex justify-content-center justify-content-md-end mt-4">
                  <ReactPaginate
                    className="pagination"
                    breakLabel="..."
                    nextLabel="Next"
                    onPageChange={handlePageClick}
                    pageCount={Math.ceil(totalMissions / perPage)}
                    previousLabel="Prev"
                  />
                </div>
              )) ||
                null}
            </>
          )) || (
            <div className="form-block">
              <div className="calendar">
                <FullCalendar
                  validRange={''}
                  plugins={[
                    dayGridPlugin,
                    timegrid,
                    listPlugin,
                    interactionPlugin,
                  ]}
                  headerToolbar={{
                    left: 'prev,next today',
                    center: 'title',
                    right: 'timeGridDay,timeGridWeek,dayGridMonth,listMonth',
                  }}
                  initialView="dayGridMonth"
                  events={calendarData}
                  eventClassNames={'cursor-default'}
                  eventClick={(e) => {
                    return false;
                  }}
                  eventTimeFormat={{
                    hour: '2-digit',
                    minute: '2-digit',
                    meridiem: 'short',
                    hour12:
                      reduxUser?.timeFormat === 'hour12Mode' ? true : false,
                  }}
                  displayEventTime={false}
                  slotLabelFormat={{
                    hour: '2-digit',
                    minute: '2-digit',
                    meridiem: 'short',
                    hour12:
                      reduxUser?.timeFormat === 'hour12Mode' ? true : false,
                  }}
                  slotLabelContent={(info) => {
                    if (info.text === '24:00') {
                      info.text = '00:00';
                    }
                  }}
                />
              </div>
            </div>
          )}
        </>
      )) || (
        <div className="bg-gray-800 text-center p-5">
          <div className="py-0 py-md-4">
            <div className="d-flex justify-content-center mb-4">
              <img src={IconEmpty} alt="" />
            </div>
            <p className="weight-600 mb-4">
              A fresh start. Create the first entry.
            </p>
            <StylishNewButton
              onClick={() =>
                navigate(`${PreparePath}/dashboard/plan/create-mission`)
              }
              className="button button--primary w-100 w-md-auto mb-3 mb-md-0"
            >
              Create Mission
            </StylishNewButton>
          </div>
        </div>
      )}
      {showDeleteConfirmation && (
        <StylishConfirmDialog
          show={showDeleteConfirmation}
          onClose={() => setShowDeleteConfirmation(false)}
          onConfirm={handleDeleteMission}
          dialogTitle={'Delete Confirmation'}
          dialogContent={'Are you sure you want to delete this mission?'}
        />
      )}
    </>
  );
};
