import React from 'react'
import './StylishIconButton.css'

export default function StylishIconButton({
  children,  
  disabled, 
  tooltip,
  tooltipOnDisable,
  bottomTooltip,
  leftTooltip,
  className,
})  { 

  let containerClassName = `StylishIconButton-tooltip${className ? ` ${className}` : ''}`

  let tooltipClassName = `StylishIconButton-tooltipText`
  tooltipClassName = `${tooltipClassName}${!!bottomTooltip ? ' StylishIconButton-tooltipText--bottom' : ''}`;
  tooltipClassName = `${tooltipClassName}${!!leftTooltip ? ' StylishIconButton-tooltipText--left' : ''}`;
  tooltipClassName = `${tooltipClassName}${(!bottomTooltip && !leftTooltip)? ' StylishIconButton-tooltipText--top' : ''}`;


  const tooltipText = tooltip || (disabled && tooltipOnDisable)

  return (
    <>
      {tooltipText ? (
         <div className={containerClassName}>
            {children}
          <span className={tooltipClassName}>{tooltipText}</span>
        </div>
      ) : (
      <>
        {children}
      </>
      )}
    </>
  ) 
}