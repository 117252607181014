import React, { useEffect, useState } from 'react';
import AddEditStatusBoardDialog from './AddEditStatusBoardDialog';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Network from '../../../service/Network';
import API from '../../../service/api';
import StylishHelp from 'components/DesignSystems/New/StylishHelp';
import { HelpPlanStatusBoards, useSelfHostedKnowledgeBase } from 'assets/data/HelpContent';
import StylishNewTable from 'components/DesignSystems/New/StylishNewTable';
import { StylishNewSearchBar } from 'components/DesignSystems/New/StylishNewSearchBar';
import StylishNewTableAction from 'components/DesignSystems/New/StylishNewTableAction';
import { toastConfig } from 'assets/data/config';
import { PaginationDropdown } from '../../../../components/DesignSystems/PaginationDropdown';
import { endLoading, startLoading } from "../../../../reducers/loading/loading.action";

export default function StatusBoard() {
  const [dialogType, setDialogType] = useState('Add');
  const [allStatusBoard, setAllStatusBoard] = useState([]);
  const [total, setTotal] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [updateDialogData, setUpdateDialogData] = useState(null);

  const selfHostedKnowledgeBase = useSelfHostedKnowledgeBase()
  const [
    showStatusBoardAddEditDialog,
    setShowStatusBoardAddEditDialog,
  ] = useState(false);

  const workspaceId = useSelector(
    (state) => state.prepare.workspace.selectedWorkspace
  );

  const dispatch = useDispatch();


  const allPlanStatusBoardList = async () => {
    dispatch(startLoading());
    try {
      const organisation = await Network.get(API.planStatusBoardList, {
        limit: perPage,
        page: page,
        search: searchTerm,
        workspaceId: workspaceId,
      });
      // setAllStatusBoard(organisation.data.response.dataset);
      setAllStatusBoard(organisation.data.response.dataset);
      setTotal(organisation.data.response.count);
    } catch (error) {
      toast.error(error.response.data.response.status.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };
  useEffect(() => {
    allPlanStatusBoardList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, perPage, searchTerm, workspaceId]);

  // const onDeleteConfirm = async () => {
  //   dispatch(startLoading());
  //   try {
  //     await Network.post(API.deletePlanStatusBoard, {
  //        boardId: deleteOrganisationId,
  //       workspaceId: workspaceId,
  //     });
  //     // setDeleteOrganiationId(null);
  //     toast.success("MSEL Status Board deleted successfully");
  //     allPlanStatusBoardList();
  //   } catch (error) {
  //     toast.error(error.response.data.response.status.msg);
  //   } finally {
  //     dispatch(endLoading());
  //   }
  // };

  const onAddPlanStatusBoardList = () => {
    setDialogType('Add');
    setShowStatusBoardAddEditDialog(true);
  };

  const OnAddOrEditStatusBoardList = (afterSave) => {
    setUpdateDialogData(null);
    setDialogType('New');
    setShowStatusBoardAddEditDialog(false);
    if (afterSave?.title) {
      allPlanStatusBoardList();
    }
  };

  // const onDeleteStatusBoardList = (row) => {
  //   setDeleteOrganiationId(row.boardId);
  // };

  const onEditStatusBoardList = (row) => {
    setUpdateDialogData(row);
    setDialogType('Edit');
    setShowStatusBoardAddEditDialog(true);
  };

  const onChangeSearchTerm = (term) => {
    setPage(1);
    setSearchTerm(term);
  };

  const columns = [
    {
      dataField: 'title',
      text: 'Title',
      sort: true,
      headerAttrs: { title: 'Sort By:' },
      attrs: { title: 'Title' },
    },
    // { dataField: "statusLabelColor", text: "Status List" },

    {
      dataField: 'labels',
      text: 'Status List',
      formatter: (cell, row) => {
        return (
          <ul className="nav nav__form-color">
            {row.labels.map((label) => {
              return (
                <li key={label.color}>
                  <span
                    className="status"
                    style={{ backgroundColor: label.color }}
                  >
                    {label.labelTitle}
                  </span>
                </li>
              );
            })}
          </ul>
        );
      },
      attrs: { title: 'Status List' },
    },
    {
      dataField: '',
      text: 'Action',
      isDummyField: true,
      formatter: (cell, row, rowIndex) => (
        <StylishNewTableAction
          name="Status Board"
          cell={cell}
          row={row}
          rowIndex={rowIndex}
          // onDelete={onDeleteStatusBoardList}
          onEdit={onEditStatusBoardList}
        />
      ),
      attrs: { title: 'Action' },
      headerStyle: {
        width: '100px',
      },
      headerAlign: 'center',
      align: 'center',
    },
  ];
  return (
    <>
      <div className="settings__status-board">
        {/* <button
          className="button button--primary mb-4"
          onClick={onAddPlanStatusBoardList}
        >
          Create MSEL Status Board
        </button> */}
        <div className="row">
          <div className="col-md-12">
            <div className="d-flex flex-column flex-md-row align-items-md-center mb-4">
              <div className="d-flex align-items-center justify-content-between">
                <p className="m-0 me-3 text-nowrap">
                  {total > 0 && total + ' Boards'}
                </p>
                <div className="">
                  <PaginationDropdown
                    setPage={setPage}
                    setPerPage={setPerPage}
                    options={[
                      { value: 10, label: '10 per page' },
                      { value: 30, label: '30 per page' },
                      { value: 60, label: '60 per page' },
                      { value: 120, label: '120 per page' },
                    ]}
                  />
                </div>
              </div>
              <div className="ms-md-auto mt-3 mt-md-0 d-flex align-items-center">
                <div className="flex-grow-1">
                  <StylishNewSearchBar
                    onChangeSearchTerm={onChangeSearchTerm}
                  />
                </div>
                <StylishHelp
                  classes={'ms-3 flex-shrink-0'}
                  title={HelpPlanStatusBoards.title}
                  content={HelpPlanStatusBoards.content}
                  link={selfHostedKnowledgeBase || HelpPlanStatusBoards.link}
                />
              </div>
            </div>
          </div>
        </div>
        <StylishNewTable
          keyField={'boardId'}
          columns={columns}
          rows={allStatusBoard}
          // onDelete={onDeleteStatusBoardList}
          onEdit={onEditStatusBoardList}
          pagination
          page={page}
          perPage={perPage}
          total={total}
          onPageChange={(page) => setPage(page)}
        />
        {showStatusBoardAddEditDialog && (
          <AddEditStatusBoardDialog
            show={showStatusBoardAddEditDialog}
            onClose={OnAddOrEditStatusBoardList}
            dialogType={dialogType}
            updateDialogData={updateDialogData}
          />
        )}
        {/* {deleteOrganisationId && (
          <ConfirmModal
            show={deleteOrganisationId}
            onClose={() => setDeleteOrganiationId(null)}
            heading={"Delete Board"}
            text={"Are you sure you want to do this?"}
            onConfirm={onDeleteConfirm}
          />
        )} */}
      </div>
    </>
  );
}
