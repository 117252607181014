import {
  deleteBranch,
  getAllBranch,
  upsertPlanningWorksheet,
} from 'actions/IAPForm204Actions';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import StylishNewTable from 'components/DesignSystems/New/StylishNewTable';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { endLoading, startLoading } from 'reducers/loading/loading.action';
import AddUpdateBranchModal from './AddUpdateBranchModal';
import { StylishConfirmDialog } from 'components/DesignSystems/New/StylishConfirmDialog';
import StylishNewTableAction from 'components/DesignSystems/New/StylishNewTableAction';
import { toast } from 'react-toastify';
import { toastConfig } from 'assets/data/config';

export default function BranchModal({
  show,
  onClose,
  selectedBranch,
  parent,
  rowSelectable,
  updateSelectedBranch,
}) {
  const reduxDispatch = useDispatch();
  useEffect(() => {
    reduxDispatch(getAllBranch());
  }, []);

  const reduxCurrentIncident = useSelector((state) => {
    return state.app.currentIncident;
  });
  const branchs = useSelector((state) => state.app.allBranches);

  const [branch, setSelectedBranch] = useState(selectedBranch);
  const [showNewBranchModal, setShowNewBranchModal] = useState(false);
  const [deleteConfirmationData, setDeleteConfirmationData] = useState(null);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [editBranchData, setEditBranchData] = useState(null);

  const columns = [
    {
      dataField: 'name',
      text: 'Branch',
      sort: true,
      headerAttrs: { title: 'Sort By:' },
      attrs: { title: 'Branch' },
    },
    {
      dataField: '',
      text: 'Action',
      isDummyField: true,
      formatter: (cell, row, rowIndex) => (
        <StylishNewTableAction
          name="Branch"
          cell={cell}
          row={row}
          rowIndex={rowIndex}
          onDelete={() => onDeleteAction(row)}
          onEdit={() => {
            setEditBranchData(row);
            setShowNewBranchModal(true);
          }}
        />
      ),
      headerStyle: {
        width: '100px',
      },
      headerAlign: 'center',
      align: 'center',
      attrs: { title: 'Action' },
    },
  ];

  const selectRow = {
    mode: 'radio',
    clickToSelect: true,
    hideSelectAll: true,
    selected: !!branch && [branch.id],
    selectionHeaderRenderer: ({ mode, ...rest }) => (
      <div className="form-checkbox align-items-center">
        <input type={mode} {...rest} />
        <span className="icon"></span>
      </div>
    ),
    selectionRenderer: ({ mode, ...rest }) => (
      <div className="form-checkbox align-items-center">
        <input type={mode} {...rest} />
        <span className="icon"></span>
      </div>
    ),
    onSelect: (row, isSelect, rowIndex, e) => {
      if (isSelect) {
        setSelectedBranch(row);
      }
    },
  };

  const onSave = async () => {
    if (!reduxCurrentIncident) {
      toast.error('Please select an Incident first', toastConfig);
      return;
    }
    // if (selectedBranch?.id === branch?.id) {
    //   onClose();
    //   return;
    // }
    try {
      reduxDispatch(startLoading());
      if (!!parent && parent === 'Work Assignment') {
        updateSelectedBranch(branchs.find((b) => b.id === branch.id));
      } else {
        reduxDispatch(upsertPlanningWorksheet('Branch', branch?.id));
      }
      onClose();
    } catch (error) {
      console.log(error);
    } finally {
      reduxDispatch(endLoading());
    }
  };

  const onDeleteAction = (row) => {
    setDeleteConfirmationData(row);
    setShowConfirmDialog(true);
  };

  const onConfirmedDeleteActionData = () => {
    reduxDispatch(startLoading());
    reduxDispatch(deleteBranch(deleteConfirmationData.id));
    if (selectedBranch?.id === deleteConfirmationData.id) {
      setSelectedBranch(!!branchs && branchs.length ? branchs[0] : null);
    } else {
      setSelectedBranch(selectedBranch);
    }
    setDeleteConfirmationData(null);
    setShowConfirmDialog(false);
  };

  return (
    <Modal
      show={show}
      onHide={onClose}
      centered
      backdrop="static"
      keyboard={false}
      size="lg"
    >
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title>Branch</Modal.Title>
      </Modal.Header>
      <Modal.Body className="pt-2">
        <StylishNewButton
          onClick={() => {
            setEditBranchData(null);
            setShowNewBranchModal(true);
          }}
          className={'button--primary mb-4'}
        >
          New Branch
        </StylishNewButton>
        <StylishNewTable
          keyField={'id'}
          columns={columns}
          rows={branchs}
          selectRow={rowSelectable ? selectRow : undefined}
        />
      </Modal.Body>
      <Modal.Footer>
        <div className="button-group">
          <StylishNewButton
            type="button"
            className="button--secondary button--reverse"
            onClick={onClose}
          >
            Cancel
          </StylishNewButton>
          {parent !== 'IncidentWorkspace' && (
            <StylishNewButton
              className={'button--primary'}
              onClick={onSave}
              disabled={
                !(!!branchs && !!branchs.length && !!branch && [branch.id])
              }
            >
              Save
            </StylishNewButton>
          )}
        </div>
      </Modal.Footer>

      {showNewBranchModal && (
        <AddUpdateBranchModal
          show={showNewBranchModal}
          onClose={() => setShowNewBranchModal(false)}
          selectedBranch={editBranchData}
        />
      )}

      {showConfirmDialog && (
        <StylishConfirmDialog
          show={showConfirmDialog}
          dialogTitle={'Delete Confirmation'}
          dialogContent={
            "Are you sure you want to delete this branch and it's divisions?"
          }
          onClose={() => setShowConfirmDialog(false)}
          onConfirm={() => onConfirmedDeleteActionData()}
        />
      )}
    </Modal>
  );
}
