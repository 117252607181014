import React from 'react';
import IconDownload from 'assets/images/icon__download.svg';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Network from '../../../../../service/Network';
import API from '../../../../../service/api';
import { toast } from 'react-toastify';
import moment from 'moment';
import { useNavigate } from 'react-router';
import { HelpMSELInjectReview, useSelfHostedKnowledgeBase } from 'assets/data/HelpContent';
import axios from 'axios';
import fileDownload from 'js-file-download';
import StylishHelp from 'components/DesignSystems/New/StylishHelp';
import StylishNewTable from 'components/DesignSystems/New/StylishNewTable';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { PreparePath } from 'utils/sharedUtils/sharedConst';
import { toastConfig } from 'assets/data/config';
import { endLoading, startLoading } from "../../../../../../reducers/loading/loading.action";

export const MSELReview = (props) => {
  const [review, setReview] = useState({});
  const [injectLocation, setInjectLocation] = useState({});
  const [selectedObjectives, setSelectedObjectives] = useState([]);

  const seflHostedKnowledgeBase = useSelfHostedKnowledgeBase()
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const reduxUser = useSelector((state) => state.prepare.user);
  const { selectedWorkspace } = useSelector((state) => state.prepare.workspace);
  const reduxTimezone = useSelector(
    (state) => state.prepare.workspace.timezone
  );
  const typeList = [
    { value: 'key', label: 'Key' },
    { value: 'enabling', label: 'Enabling' },
    { value: 'supporting', label: 'Supporting' },
  ];

  const fetchSingleInject = async () => {
    dispatch(startLoading());
    try {
      const selectedInject = await Network.get(API.fetchSingleInject, {
        id: props.injectId,
      });
      let selectType = typeList.filter(
        (tl) => tl.value === selectedInject.data.response.dataset[0].type
      );
      const injects = {
        ...selectedInject.data.response.dataset[0],
        type: selectType[0]?.label,
      };

      setReview(injects);
      // console.log(injects.trainingobjective);
      setSelectedObjectives(injects.trainingobjective);
    } catch (error) {
      // toast.error(error.response.data.response.status.msg);
    } finally {
      dispatch(endLoading());
    }
  };

  const fetchInjectLocation = async () => {
    dispatch(startLoading());
    try {
      const location = await Network.get(API.fetchInjectLocation, {
        injectId: props.injectId,
      });
      setInjectLocation(location.data.response.dataset[0]);
    } catch (error) {
      // toast.error(error.response.data.response.status.msg);
    } finally {
      dispatch(endLoading());
    }
  };

  // const fetchAllInjectObjective = async () => {
  //   dispatch(startLoading());
  //   try {
  //     const objectives =
  //
  //       (await Network.get(API.fetchAllInjectObjective, {
  //         workspaceId: selectedWorkspace,
  //         injectId: props.injectId,
  //       }));
  //     setSelectedObjectives(
  //       objectives?.data?.response?.trainingObjectiveAssociated || []
  //     );
  //   } catch (error) {
  //     toast.error(error?.response?.data?.response?.status?.msg);
  //   } finally {
  //     dispatch(endLoading());
  //   }
  // };

  useEffect(() => {
    fetchSingleInject();
    fetchInjectLocation();
    // fetchAllInjectObjective();
  }, [reduxUser, selectedWorkspace]);

  const columns_objective_methods = [
    {
      dataField: 'participant',
      text: 'Participant',
      sort: true,
      headerAttrs: { title: 'Sort By:' },
      attrs: { title: 'Participant' },
    },
    {
      dataField: 'platformType',
      text: 'Platform Type',
      sort: true,
      attrs: { title: 'Platform Type' },
    },
    {
      dataField: 'jmet',
      text: 'JMET/Objective',
      sort: true,
      attrs: { title: 'JMET/Objective' },
    },
  ];

  const downloadFile = async (file) => {
    let fullFileName = file.split('/')[file.split('/').length - 1];
    try {
      dispatch(startLoading());
      const downloadFile = await Network.post(API.generateSignedUrl, {
        url: file,
      });
      const res = await fetch(downloadFile.data.response.singedUrl, {
        headers: {},
      });

      fileDownload(
        await res.blob(),
        fullFileName.split('-')[fullFileName.split('-').length - 1]
      );
    } catch (error) {
      console.log('Error: ', error);
      toast.error('Failed to download file', toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };

  return (
    <>
      <div className="d-flex align-items-center mb-4">
        <h4 className="m-0">Review MSEL Inject</h4>
        <StylishHelp
          classes={'ms-auto sml flex-shrink-0'}
          title={HelpMSELInjectReview.title}
          content={HelpMSELInjectReview.content}
          link={seflHostedKnowledgeBase || HelpMSELInjectReview.link}
        />
      </div>
      <div className="form-block">
        <h4 className="mb-4">Inject Details</h4>
        <div className="row">
          <div className="col-md-3 mb-3">
            <label className="form-label d-block">Title</label>
            {review.injectTitle}
          </div>
          <div className="col-md-3 mb-3">
            <label className="form-label d-block">Status</label>
            {review.statusLabel}
          </div>
          <div className="col-md-3 mb-3">
            <label className="form-label d-block">Scenario</label>
            {review.planEvent}
          </div>
          <div className="col-md-3 mb-3">
            <label className="form-label d-block">Date and Time</label>
            {review.startDate ? (
              <span>
                {moment(review.createdAt).format(
                  reduxUser?.timeFormat === 'hour12Mode'
                    ? 'YYYY-MM-DD - hh:mm a'
                    : 'YYYY-MM-DD - HH:mm'
                )}{' '}
                ({reduxTimezone.split('/')[0]})
              </span>
            ) : null}
          </div>
          <div className="col-md-3 mb-3">
            <label className="form-label d-block">Type</label>
            {review.type}
          </div>
          <div className="col-md-3 mb-3">
            <label className="form-label d-block">Owner</label>
            {review.injetOwnerTitle}
          </div>
          <div className="col-md-3 mb-3">
            <label className="form-label d-block">From</label>
            {review.from_title}
          </div>
          <div className="col-md-3 mb-3">
            <label className="form-label d-block">To</label>
            {review.to_title}
          </div>
          <div className="col-md-3">
            <label className="form-label d-block">Inject Method</label>
            {review.methodName}
          </div>
        </div>
        <hr className="dashed" />
        <h4 className="mb-4">Description &amp; Measures</h4>
        <div className="row">
          <div className="col-md-3 mb-3">
            <label className="form-label d-block">Trigger</label>
            {review.trigger}
          </div>
          <div className="col-md-3 mb-3">
            <label className="form-label d-block">
              {' '}
              Anticipated Response / Expected Player Action
            </label>
            {review.response}{' '}
          </div>
          <div className="col-md-3 mb-3">
            <label className="form-label d-block">Remarks</label>
            {review.remarks}{' '}
          </div>
          <div className="col-md-3 mb-3">
            <label className="form-label d-block">Attachments</label>
            {review.urls
              ?.split(',')
              .filter((data) => data != '' && data != 'null' && data != null)
              .map((urlData, index) => {
                let fullFileName = urlData.split('/')[
                  urlData.split('/').length - 1
                ];
                return (
                  <span className="d-flex align-items-center mt-2" key={index}>
                    {
                      fullFileName.split('-')[
                        fullFileName.split('-').length - 1
                      ]
                    }
                    <span
                      className="ms-3 cursor-pointer"
                      onClick={() => downloadFile(urlData)}
                    >
                      <img src={IconDownload} alt="" />
                    </span>
                  </span>
                );
              })}
          </div>
          <div className="col-md-3 mb-3 mb-md-0">
            <label className="form-label d-block">Response Time</label>
            {review.responseDate ? (
              <span>
                {moment(review.responseDate).format(
                  reduxUser?.timeFormat === 'hour12Mode'
                    ? 'YYYY-MM-DD - hh:mm a'
                    : 'YYYY-MM-DD - HH:mm'
                )}{' '}
                ({reduxTimezone.split('/')[0]})
              </span>
            ) : null}
          </div>
          <div className="col-md-3 mb-0">
            <label className="form-label d-block">Actual Response</label>
            {review.actualResponse}
          </div>
        </div>
        <hr className="dashed" />
        <h4 className="mb-4">Location</h4>
        <div className="row">
          <div className="col-md-3">
            <label className="form-label d-block">Location</label>
            {/* Jefferson, <br />
            Louisiana, United States */}
            {injectLocation?.loc} <br />
            {injectLocation?.lat} <br />
            {injectLocation?.lng}
          </div>
        </div>
        <hr className="dashed" />
        <h4 className="mb-4">Objectives & Methods</h4>
        <StylishNewTable
          keyField={'id'}
          columns={columns_objective_methods}
          // rows={rows_objective_methods}
          rows={selectedObjectives.map((sO) => ({
            id: sO.trainingObjectiveId,
            participant: sO.participantName,
            platformType:
              (sO?.planplatformTitle || '') + ':' + sO?.platformType,
            jmet:
              sO.participantType === 'DOD'
                ? (sO?.jmetLevelWar || '') +
                  ' ' +
                  (sO?.jmetTaskNumber || '') +
                  ' ' +
                  (sO?.jmetTaskTitle || '') +
                  ' ' +
                  (sO?.planplatformTitle || '') +
                  ' ' +
                  (sO?.platformType || '')
                : sO?.exerciseObjectivesHSEEP +
                  ' ' +
                  (sO?.planplatformTitle || '') +
                  ' ' +
                  (sO?.platformType || ''),
          }))}
        />
      </div>
      <div className="col-md-12 mt-4">
        <div className="button-group">
          <StylishNewButton
            type="submit"
            className="button button--primary button--reverse"
            onClick={(e) => {
              e.preventDefault();
              props.onPrevClick(3);
            }}
          >
            Previous
          </StylishNewButton>
          <StylishNewButton
            type="submit"
            className="button button--primary ms-auto"
            onClick={(e) => {
              e.preventDefault();
              navigate(-1, {
                replace: true,
              });
            }}
          >
            Complete
          </StylishNewButton>
        </div>
      </div>
    </>
  );
};
