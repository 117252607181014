import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Outlet } from 'react-router-dom';

import { AppHeader } from 'components/headers/appHeader/AppHeader';

import './TaskManagement.css';
import { fetchRostersForGroups } from 'actions/roleActions';
import { fetchAllDistributionList } from 'actions/notificationActions';
import { useNewUIFlag } from '../../utils/featureFlags';

export default function TaskManagement() {
  const reduxDispatch = useDispatch();

  const reduxCurrentlySelectedGroup = useSelector((state) => {
    return state.app.currentlySelectedGroup;
  });

  useEffect(() => {
    if (reduxCurrentlySelectedGroup?.group_guid) {
      reduxDispatch(
        fetchRostersForGroups([reduxCurrentlySelectedGroup.group_guid])
      );
      reduxDispatch(
        fetchAllDistributionList(reduxCurrentlySelectedGroup.group_guid)
      );
    }
  }, [reduxCurrentlySelectedGroup]);

  const newUI = useNewUIFlag();

  return (
    <>
      {!newUI ? <AppHeader context={'Task Management'} /> : null}

      <Outlet />
    </>
  );
}
