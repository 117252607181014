import React from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import AddUpdateTimeZoneDialog from './AddUpdateTimeZoneDialog';
import StylishHelp from 'components/DesignSystems/New/StylishHelp';
import { HelpTimeZone, useSelfHostedKnowledgeBase } from 'assets/data/HelpContent';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';

const TimeZone = () => {
  const selfHostedKnowledgeBase = useSelfHostedKnowledgeBase()
  const [showSetTimeZoneDialog, setShowSetTimeZoneDialog] = useState(false);

  const { timezone, timezoneOffset } = useSelector(
    (state) => state.prepare.workspace
  );

  const onSetTimeZone = () => {
    setShowSetTimeZoneDialog(true);
  };
  const onCloseModal = () => {
    setShowSetTimeZoneDialog(false);
  };

  return (
    <>
      <div className="settings__timezone">
        <div className="d-flex align-items-center mb-4">
          <StylishNewButton
            onClick={onSetTimeZone}
            className="button button--primary "
          >
            Set Timezone
          </StylishNewButton>
          <StylishHelp
            classes={'ms-auto sml'}
            title={HelpTimeZone.title}
            content={HelpTimeZone.content}
            link={selfHostedKnowledgeBase || HelpTimeZone.link}
          />
        </div>
        <div className="form-block">
          <h4 className="mb-2">Workspace Timezone:</h4>
          <p className="m-0">{timezone}</p>
        </div>
      </div>

      {showSetTimeZoneDialog && (
        <AddUpdateTimeZoneDialog
          show={showSetTimeZoneDialog}
          onClose={onCloseModal}
          selectedTz={timezone}
          tzOffset={timezoneOffset}
        />
      )}
    </>
  );
};

export default TimeZone;
