import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import Network from '../../../../service/Network';
import API from '../../../../service/api';
import AddEditAllExerciseDialog from './AddEditAllExerciseDialog';
import AddEditAllOrganisationDialog from './AddEditAllOrganisationDialog';
import moment from 'moment';
import {
  setExerciseDetailsId,
  setExerciseDetailsStep,
  setExerciseDetailName,
} from '../../../../store/exercise/exercise.action';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { HelpExerciseDetails, useSelfHostedKnowledgeBase } from 'assets/data/HelpContent';
import { textareaStringLength, toastConfig } from 'assets/data/config';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import StylishDateTimePicker from 'components/DesignSystems/form/StylishDateTimePicker';
import StylishNewSelect from 'components/DesignSystems/New/StylishNewSelect';
import StylishNewTextArea from 'components/DesignSystems/New/StylishNewTextArea';
import { PreparePath } from 'utils/sharedUtils/sharedConst';
import StylishHelp from 'components/DesignSystems/New/StylishHelp';
import {
  endLoading,
  startLoading,
} from '../../../../../reducers/loading/loading.action';

export default function ExerciseDetails(props) {
  const selfHostedKnowledgebase = useSelfHostedKnowledgeBase()
  const [allExercises, setAllExercises] = useState([]);
  const [allOrganisations, setAllOrganisations] = useState([]);
  const [updateDialogData, setUpdateDialogData] = useState(null);
  const [dialogType, setDialogType] = useState('Add');
  const [exerciseAfterSave, setExerciseAfterSave] = useState('');
  const [exerciseSearch, setExerciseSearch] = useState('');
  const [organisationSearch, setOrganisationSearch] = useState('');
  const [organisationAfterSave, setOrganisationAfterSave] = useState('');
  const [exerciseDetailsName, setExerciseDetailsName] = useState('');
  const [
    showAllExerciseAddEditDialog,
    setShowAllExerciseAddEditDialog,
  ] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [
    showAllOrganisationAddEditDialog,
    setShowAllOrganisationAddEditDialog,
  ] = useState(false);
  const [allListLoaded, setAllListLoaded] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    getValues,
    trigger,
    setError,
    clearErrors,
  } = useForm();

  const reduxUser = useSelector((state) => state.prepare.user);
  const { selectedWorkspace, timezone, workspaceName } = useSelector(
    (state) => state.prepare.workspace
  );
  const { exerciseDetailsId: exerciseId } = useSelector(
    (state) => state.prepare.exercise
  );

  const workspaceType = useSelector((state) => state.prepare.workspace.type);

  const OnAddOrEditAllExercise = (afterSave) => {
    if (afterSave?.value) {
      // setAllExercises([
      //   ...allExercises,
      //   {
      //     value: afterSave?.id,
      //     label: afterSave?.title,
      //   },
      // ]);

      // let exerciseData = exerciseData.unshift({
      //   value: afterSave?.id,
      //   label: afterSave?.title,
      // });

      // setAllExercises(exerciseData);
      // console.log(allExercises);
      setExerciseAfterSave(afterSave);

      setAllExercises([
        ...allExercises,
        {
          label: afterSave?.label,
          value: afterSave?.value,
        },
      ]);

      setValue('exercises', afterSave);
      setValue('exercise', afterSave.value);
      setShowAllExerciseAddEditDialog(false);
    } else {
      setExerciseAfterSave('');
      setValue('exercises', '');
      setValue('exercise', '');
    }
    setUpdateDialogData(null);
    setDialogType('New');
    setShowAllExerciseAddEditDialog(false);
    setUpdateDialogData(null);
  };

  const OnAddOrEditAllOrganisation = (afterSave) => {
    if (afterSave?.value) {
      setAllOrganisations([
        ...allOrganisations,
        {
          label: afterSave?.label,
          value: afterSave?.value,
        },
      ]);

      setOrganisationAfterSave(afterSave);
      setValue('organisations', afterSave);
      setValue('organisation', afterSave.value);
    } else {
      setOrganisationAfterSave('');
      setValue('organisations', '');
      setValue('organisation', '');
    }
    setUpdateDialogData(null);
    setDialogType('New');
    setShowAllOrganisationAddEditDialog(false);
    // getAllOrganisations();
  };

  const getAllExercises = async () => {
    dispatch(startLoading());
    try {
      const exercise = await Network.get(API.planExerciseType, {
        workspaceId: selectedWorkspace,
        search: exerciseSearch,
      });
      if (exercise) {
        let exerciseData =
          exercise &&
          exercise.data.response.dataset.map((e) => {
            return {
              value: e.id,
              label: e.title,
            };
          });
        exerciseData.unshift({ value: 'new', label: 'Add New' });
        setAllExercises(exerciseData);
      }
    } catch (error) {
      toast.error(error.response.data.response.status.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };

  const getAllOrganisations = async () => {
    dispatch(startLoading());
    try {
      const organisation = await Network.get(API.getAllOrganisations, {
        workspaceId: selectedWorkspace,
        search: organisationSearch,
      });
      if (organisation) {
        let organisationData =
          organisation &&
          organisation.data.response.dataset.map((e) => {
            return {
              value: e.id,
              label: e.name + (e.group ? '-' + e.group : ''),
            };
          });
        organisationData.unshift({ value: 'new', label: 'Add New' });
        setAllOrganisations(organisationData);
      }
    } catch (error) {
      toast.error(error.response.data.response.status.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };

  const getAllExerciseDetails = async () => {
    dispatch(startLoading());
    try {
      Network.get(API.planExerciseTypeList, {
        workspaceId: selectedWorkspace,
      })
        .then((resp) => {
          let exerciseData = {};
          exerciseData =
            allExercises.length &&
            allExercises.find(
              (e) => e?.value === resp.data.response.dataset[0]?.exerciseTypeId
            );
          let organisationData = {};
          organisationData =
            allOrganisations.length &&
            allOrganisations.find(
              (e) => e?.value === resp.data.response.dataset[0]?.organizationId
            );
          organisationData &&
            setValue('organisations', organisationData) &&
            setValue('organisation', organisationData.value);
          exerciseData &&
            setValue('exercises', exerciseData) &&
            setValue('exercise', exerciseData.value);

          setValue(
            'exerciseGuidance',
            resp.data.response.dataset[0]?.exerciseGuidance
          );

          setOrganisationAfterSave(organisationData);
          setExerciseAfterSave(exerciseData);
          setExerciseDetailsName(resp.data.response.dataset[0]);
          dispatch(setExerciseDetailName(exerciseDetailsName?.name));
          resp.data.response.dataset[0].endDate &&
            setValue(
              'endDate',
              moment(resp.data.response.dataset[0].endDate).format(
                'MM/DD/YYYY HH:mm'
              )
            );
          resp.data.response.dataset[0].endDate &&
            setEndDate(
              moment(resp.data.response.dataset[0].endDate).format(
                'MM/DD/YYYY HH:mm'
              )
            );
          resp.data.response.dataset[0].startDate &&
            setValue(
              'startDate',
              moment(resp.data.response.dataset[0].startDate).format(
                'MM/DD/YYYY HH:mm'
              )
            );
          resp.data.response.dataset[0].startDate &&
            setStartDate(
              moment(resp.data.response.dataset[0].startDate).format(
                'MM/DD/YYYY HH:mm'
              )
            );
        })
        .catch(console.log)
        .finally(() => {
          dispatch(endLoading());
        });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllExercises();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reduxUser, exerciseSearch]);

  useEffect(() => {
    getAllOrganisations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reduxUser, organisationSearch]);

  useEffect(() => {
    const data = getValues();
    if (
      exerciseId &&
      !!allOrganisations &&
      allOrganisations.length > 0 &&
      !!allExercises &&
      allExercises.length > 0 &&
      !allListLoaded
    ) {
      setAllListLoaded(true);
      getAllExerciseDetails();
    }

    !data.startDate && setValue('startDate', new Date());
    !data.endDate && setValue('endDate', new Date());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reduxUser, allOrganisations, allExercises]);

  const onSubmit = (data) => {
    if (moment(data.startDate).isAfter(moment(data.endDate))) {
      setError('endDate', { type: 'custom' });

      return;
    } else {
      clearErrors('endDate');
    }

    const newData = {
      ...data,
      organisation: data.organisations.value,
      exercise: data.exercises.value,
    };
    if (exerciseId) {
      dispatch(startLoading());
      Network.post(API.updateEvent_Exercise, {
        workspaceId: selectedWorkspace,
        startDate: moment(newData.startDate).toISOString(),
        type: 'exercise',
        name: workspaceName,
        endDate: moment(newData.endDate).toISOString(),
        exerciseGuidance: newData.exerciseGuidance,
        exerciseTypeId: newData.exercise,
        organizationId: newData.organisation,
        id: exerciseId,
      })
        .then((resp) => {
          dispatch(setExerciseDetailsStep(props.onNextClick(0)));
          props.onNextClick(1);
          toast.success('Saving', toastConfig);
        })
        .catch((err) => {
          toast.error(err.response.data.response.status.msg, toastConfig);
        })
        .finally(() => {
          dispatch(endLoading());
        });
    } else {
      dispatch(startLoading());
      Network.post(API.createEvent_Exercise, {
        workspaceId: selectedWorkspace,
        startDate: newData.startDate,
        type: 'exercise',
        name: workspaceName,
        endDate: newData.endDate,
        exerciseGuidance: newData.exerciseGuidance,
        exerciseTypeId: newData.exercise,
        organizationId: newData.organisation,
      })
        .then((resp) => {
          dispatch(setExerciseDetailsStep(props.onNextClick(0)));
          let id = resp.data.response.dataset[0].id;
          dispatch(setExerciseDetailsId(id));
          props.onNextClick(1);
          toast.success('Saving', toastConfig);
        })
        .catch((err) => {
          toast.error(err.response.data.response.status.msg, toastConfig);
        })
        .finally(() => {
          dispatch(endLoading());
        });
    }
  };

  const saveAndExit = () => {
    const data = getValues();
    if (moment(startDate).isAfter(moment(data.endDate))) {
      setError('endDate', { type: 'custom' });

      return;
    } else {
      clearErrors('endDate');
    }

    const newData = {
      ...data,
      organisation: data.organisations.value,
      exercise: data.exercises.value,
    };
    if (exerciseId) {
      dispatch(startLoading());
      Network.post(API.updateEvent_Exercise, {
        workspaceId: selectedWorkspace,
        startDate: newData.startDate,
        type: 'exercise',
        name: workspaceName,
        endDate: newData.endDate,
        exerciseGuidance: newData.exerciseGuidance,
        exerciseTypeId: newData.exercise,
        organizationId: newData.organisation,
        id: exerciseId,
      })
        .then((resp) => {
          toast.success('Saving', toastConfig);
          navigate(`${PreparePath}/dashboard/plan/prepare/exercise-details`);
        })
        .catch((err) => {
          toast.error(err.response.data.response.status.msg, toastConfig);
        })
        .finally(() => {
          dispatch(endLoading());
        });
    } else {
      dispatch(startLoading());
      Network.post(API.createEvent_Exercise, {
        workspaceId: selectedWorkspace,
        startDate: newData.startDate,
        type: 'exercise',
        name: workspaceName,
        endDate: newData.endDate,
        exerciseGuidance: newData.exerciseGuidance,
        exerciseTypeId: newData.exercise,
        organizationId: newData.organisation,
      })
        .then((resp) => {
          let id = resp.data.response.dataset[0].id;
          dispatch(setExerciseDetailsId(id));
          toast.success('Saving', toastConfig);
          navigate(`${PreparePath}/dashboard/plan/prepare/exercise-details`);
        })
        .catch((err) => {
          toast.error(err.response.data.response.status.msg, toastConfig);
        })
        .finally(() => {
          dispatch(endLoading());
        });
    }
  };

  //Add your search logic here.
  const customFilter = (option, searchText, type) => {
    if (option.value === 'new') return true;
    if (option?.data?.label?.toLowerCase().includes(searchText.toLowerCase())) {
      if (type === 'Exercise') {
        setExerciseSearch(searchText);
      } else if (type === 'Organization') {
        setOrganisationSearch(searchText);
      }
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      <div className="d-flex align-items-center mb-4">
        <h4 className="mb-0">
          {workspaceName || exerciseDetailsName?.eventName} Exercise Details
        </h4>
        <StylishHelp
          classes={'ms-auto sml flex-shrink-0'}
          title={HelpExerciseDetails.title}
          content={HelpExerciseDetails.content}
          link={selfHostedKnowledgebase || HelpExerciseDetails.link}
        />
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-block">
          <div className="row">
            <div className="col-md-12 mb-3">
              <label className="form-label">
                Start Date {timezone.split('/')[0]}
              </label>
              <Controller
                control={control}
                name="startDate"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <StylishDateTimePicker
                    onChange={(date) => {
                      setStartDate(date || null);

                      if (date && moment(date).isAfter(moment(endDate))) {
                        setEndDate(new Date(moment(date).toISOString()));
                        setValue(
                          'endDate',
                          new Date(moment(date).toISOString())
                        );
                      }
                      if (date && !moment(date).isAfter(moment(endDate))) {
                        clearErrors('endDate');
                      }
                      return onChange(date);
                    }}
                    selected={value}
                    selectsStart
                    value={value}
                    endDate={endDate}
                    isClearable={true}
                    timeFormat="HH:mm"
                    timeIntervals={20}
                    timeCaption="time"
                    dateFormat={
                      reduxUser?.timeFormat === 'hour12Mode'
                        ? 'MMMM d, yyyy hh:mm a'
                        : 'MMMM d, yyyy HH:mm'
                    }
                    placeholderText={'Choose Start Date'}
                    disableDateTimeLabel={true}
                    type="datetime-local"
                  />
                )}
              />
              {errors.startDate && (
                <span className="form-text form-error">
                  This is a required field
                </span>
              )}
            </div>
            <div className="col-md-12 mb-3">
              <label className="form-label">
                End Date {timezone.split('/')[0]}
              </label>
              <Controller
                control={control}
                name="endDate"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <StylishDateTimePicker
                    onChange={(date) => {
                      if (!date) {
                        setEndDate(null);
                      } else {
                        if (moment(startDate).isAfter(moment(date))) {
                          setError('endDate', { type: 'custom' });
                          return onChange(date);
                        } else {
                          clearErrors('endDate');
                          return onChange(date);
                        }
                      }

                      // else {

                      // setEndDate(date);
                      // }
                    }}
                    value={value}
                    selected={value || endDate}
                    selectsEnd
                    minDate={
                      !!startDate
                        ? new Date(moment(startDate).toISOString())
                        : null
                    }
                    isClearable={true}
                    timeFormat="HH:mm"
                    timeIntervals={20}
                    timeCaption="time"
                    dateFormat={
                      reduxUser?.timeFormat === 'hour12Mode'
                        ? 'MMMM d, yyyy hh:mm a'
                        : 'MMMM d, yyyy HH:mm'
                    }
                    placeholderText={'Choose End Date'}
                    type="datetime-local"
                    disableDateTimeLabel={true}
                  />
                )}
              />
              {errors.endDate?.type === 'required' && (
                <span className="form-text form-error">
                  This is a required field
                </span>
              )}
              {errors.endDate?.type === 'custom' && (
                <span className="form-text form-error multiline">
                  End Time cannot be same or lesser than start Date
                </span>
              )}
            </div>
            <div className="col-md-6 mb-3">
              <label className="form-label">
                Primary Organization
                <span aria-label="Required field" className="required">
                  *
                </span>
              </label>
              <Controller
                control={control}
                name="organisations"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <StylishNewSelect
                    options={allOrganisations}
                    defaultValue={value}
                    value={value}
                    filterOption={(option, searchText) =>
                      customFilter(option, searchText, 'Organization')
                    }
                    onChange={(e) => {
                      if (e?.value === 'new') {
                        setShowAllOrganisationAddEditDialog(true);
                        clearErrors('organisations');
                      } else if (!e) {
                        setOrganisationAfterSave('');
                        setError('organisations');
                      } else if (e.value) {
                        clearErrors('organisations');
                      }
                      return onChange(e);
                    }}
                    placeholder={'Select Organization'}
                    isClearable={true}
                    isSearchable={true}
                    isMulti={false}
                    isDisabled={false}
                    openAddNew={() => ({})}
                  />
                )}
              />
              {errors.organisations && (
                <span className="form-text form-error">
                  This is a required field
                </span>
              )}
            </div>
            <div className="col-md-6 mb-3">
              <label className="form-label">
                Exercise Type
                <span aria-label="Required field" className="required">
                  *
                </span>
              </label>
              <Controller
                control={control}
                name="exercises"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <StylishNewSelect
                    options={allExercises}
                    defaultValue={value}
                    value={value}
                    onChange={(e) => {
                      if (e?.value === 'new') {
                        clearErrors('exercises');
                        setShowAllExerciseAddEditDialog(true);
                      } else if (!e) {
                        setExerciseAfterSave('');
                        setError('exercises');
                      } else if (e.value) {
                        clearErrors('exercises');
                      }
                      return onChange(e);
                    }}
                    placeholder={'Select Exercise Type'}
                    isClearable={true}
                    filterOption={(option, searchText) =>
                      customFilter(option, searchText, 'Exercise')
                    }
                    isSearchable={true}
                    isMulti={false}
                    isDisabled={false}
                  />
                )}
              />
              {errors.exercises && (
                <span className="form-text form-error">
                  This is a required field
                </span>
              )}
            </div>
            {workspaceType === 'DOD' ? (
              <div className="col-md-12">
                <label className="form-label">Commander's Guidance</label>
                <Controller
                  control={control}
                  name={'exerciseGuidance'}
                  rules={{ required: false }}
                  render={({ field: { onChange, value } }) => (
                    <StylishNewTextArea
                      onChange={(e) => {
                        onChange(e);
                      }}
                      value={value}
                      type="text"
                      name="exerciseGuidance"
                      maxLength={textareaStringLength}
                      className="form-control"
                      // {...register('amount', { required: true })}
                    />
                  )}
                />
              </div>
            ) : (
              <div className="col-md-12">
                <label className="form-label">Exercise Scope</label>
                <Controller
                  control={control}
                  name={'exerciseGuidance'}
                  rules={{ required: false }}
                  render={({ field: { onChange, value } }) => (
                    <StylishNewTextArea
                      onChange={(e) => {
                        onChange(e);
                      }}
                      value={value}
                      type="text"
                      name="exerciseGuidance"
                      maxLength={textareaStringLength}
                      className="form-control"
                      // {...register('amount', { required: true })}
                    />
                  )}
                />
              </div>
            )}
          </div>
        </div>
        <div className="col-md-12 mt-4">
          <div className="button-group justify-content-end">
            <StylishNewButton
              type="button"
              className="button button--tertiary button--reverse"
              onClick={async () => {
                const valid = await trigger();

                if (valid) {
                  saveAndExit();
                }
              }}
            >
              Save &amp; Exit
            </StylishNewButton>
            <StylishNewButton type="submit" className="button button--primary">
              Next
            </StylishNewButton>
          </div>
        </div>
      </form>
      {showAllExerciseAddEditDialog && (
        <AddEditAllExerciseDialog
          show={showAllExerciseAddEditDialog}
          onClose={OnAddOrEditAllExercise}
          dialogType={dialogType}
          updateDialogData={updateDialogData}
        />
      )}
      {showAllOrganisationAddEditDialog && (
        <AddEditAllOrganisationDialog
          show={showAllOrganisationAddEditDialog}
          onClose={OnAddOrEditAllOrganisation}
          dialogType={dialogType}
          updateDialogData={updateDialogData}
        />
      )}
    </>
  );
}
