import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { selectIncidentGuid, selectIncidents, useAppSelector } from '../../../slices/commonSelectors';

export const useActualIncidentId = () => {
  const { incidentId } = useParams<{ incidentId: string }>();
  const incident_guid = useSelector(selectIncidentGuid);
  return incidentId ?? incident_guid;
};


export const useCurrentIncident = () => {
  const incidentId = useActualIncidentId();
  const incidents = useSelector(selectIncidents);
  const areIncidentsLoaded = useAppSelector(state => state.app.areIncidentsLoaded);

  return {
    data: incidents.find(incident => incident.id === incidentId),
    isLoading: !areIncidentsLoaded,
    isError: false,
    error: undefined,
  }
}