import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Network from '../../../service/Network';
import API from '../../../service/api';
import StylishNewTable from 'components/DesignSystems/New/StylishNewTable';
import { StylishNewSearchBar } from 'components/DesignSystems/New/StylishNewSearchBar';
import StylishNewTableAction from 'components/DesignSystems/New/StylishNewTableAction';
import { StylishConfirmDialog } from 'components/DesignSystems/New/StylishConfirmDialog';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import StylishHelp from 'components/DesignSystems/New/StylishHelp';
import { HelpMissionProfile, useSelfHostedKnowledgeBase } from 'assets/data/HelpContent';
import { toastConfig } from 'assets/data/config';
import { useNavigate } from 'react-router-dom';
import { PreparePath } from 'utils/sharedUtils/sharedConst';
import { PaginationDropdown } from '../../../../components/DesignSystems/PaginationDropdown';
import {
  endLoading,
  startLoading,
} from '../../../../reducers/loading/loading.action';
import AddEditMissionProfileDialog from './AddEditMissionProfileDialog';

export default function MissionProfile() {
  const [allMissionProfile, setAllMissionProfile] = useState([]);
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [perPage, setPerPage] = useState(10);
  const [total, setTotal] = useState(0);
  const [
    showMissionProfileAddEditDialog,
    setShowMissionProfileAddEditDialog,
  ] = useState(false);
  const [dialogType, setDialogType] = useState('Add');
  const [updateDialogData, setUpdateDialogData] = useState(null);
  const [deleteMissionProfile, setDeleteMissionProfile] = useState(null);
  const [deleteMissionProfileTitle, setDeleteMissionProfileTitle] = useState(
    null
  );

  const selfHostedKnowledgeBase = useSelfHostedKnowledgeBase()

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const reduxUser = useSelector((state) => state.prepare.user);
  const workspaceId = useSelector(
    (state) => state.prepare.workspace.selectedWorkspace
  );
  const workspace = useSelector((state) => {
    return state?.prepare?.workspace;
  });

  const columns = [
    {
      dataField: 'name',
      text: 'Name',
      sort: true,
      headerAttrs: { title: 'Sort By:' },
      attrs: { title: 'Discipline Name' },
    },
    {
      dataField: '',
      text: 'Action',
      isDummyField: true,
      formatter: (cell, row, rowIndex) => (
        <StylishNewTableAction
          name="Mission Profile"
          cell={cell}
          row={row}
          rowIndex={rowIndex}
          onDelete={onDeleteMissionProfile}
          onEdit={onEditAllMissionProfile}
        />
      ),
      headerStyle: {
        width: '100px',
      },
      headerAlign: 'center',
      align: 'center',

      attrs: { title: 'Action' },
    },
  ];

  const getAllMissionProfileList = async () => {
    dispatch(startLoading());
    try {
      const newMissionProfile = await Network.get(API.getAllMissionProfile, {
        limit: perPage,
        page: page,
        search: searchTerm,
        workspaceId: workspaceId,
      });
      setAllMissionProfile(newMissionProfile.data.response.dataset || []);

      setTotal(newMissionProfile.data.response.total);
    } catch (error) {
      toast.error(error.response.data.response.status.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };
  useEffect(() => {
    if (!!workspace && !!workspace?.type && workspace?.type !== 'T&E') {
      navigate(`${PreparePath}/dashboard`);
    } else {
      setPage(1);
      setPerPage(10);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspace?.type, workspaceId]);

  useEffect(() => {
    getAllMissionProfileList();
  }, [page, perPage, searchTerm, reduxUser, workspaceId]);

  const onAddMissionProfile = () => {
    setDialogType('Add');
    setShowMissionProfileAddEditDialog(true);
  };
  const onDeleteMissionProfile = (row) => {
    setDeleteMissionProfile(row.id);
    setDeleteMissionProfileTitle(row.name);
  };
  const onEditAllMissionProfile = (row) => {
    setUpdateDialogData(row);
    setDialogType('Edit');
    setShowMissionProfileAddEditDialog(true);
  };
  const OnAddOrEditMissionProfile = (afterSave) => {
    setUpdateDialogData(null);
    setDialogType('New');
    setShowMissionProfileAddEditDialog(false);
    if (afterSave?.id) {
      getAllMissionProfileList();
    }
  };
  const onDeleteConfirm = async () => {
    dispatch(startLoading());
    try {
      await Network.post(API.deleteMissionProfile, {
        id: deleteMissionProfile,
        workspaceId,
      });
      setDeleteMissionProfile(null);
      getAllMissionProfileList();
      toast.success('Mission Profile deleted  successfully', toastConfig);
    } catch (error) {
      toast.error(error.response.data.response.status.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };
  const onChangeSearchTerm = (term) => {
    setPage(1);
    setSearchTerm(term);
  };
  return (
    <>
      <div className="settings__exercise-type">
        <StylishNewButton
          className="button button--primary mb-4"
          onClick={onAddMissionProfile}
        >
          Add Mission Profile
        </StylishNewButton>
        <div className="form-block">
          <div className="row">
            <div className="col-md-12">
              <div className="d-flex flex-column flex-md-row align-items-md-center mb-4">
                <div className="d-flex align-items-center justify-content-between">
                  <p className="m-0 me-3 text-nowrap">
                    {total || 0} Mission Profiles
                  </p>
                  <div className="">
                    <PaginationDropdown
                      setPage={setPage}
                      setPerPage={setPerPage}
                      options={[
                        { value: 10, label: '10 per page' },
                        { value: 30, label: '30 per page' },
                        { value: 60, label: '60 per page' },
                        { value: 100, label: '100 per page' },
                      ]}
                    />
                  </div>
                </div>
                <div className="ms-md-auto mt-3 mt-md-0 d-flex align-items-center">
                  <div className="flex-grow-1">
                    <StylishNewSearchBar
                      onChangeSearchTerm={onChangeSearchTerm}
                    />
                  </div>
                  <StylishHelp
                    classes={'ms-3 flex-shrink-0'}
                    title={HelpMissionProfile.title}
                    content={HelpMissionProfile.content}
                    link={selfHostedKnowledgeBase || HelpMissionProfile.link}
                  />
                </div>
              </div>
            </div>
          </div>
          <StylishNewTable
            keyField={'id'}
            columns={columns}
            rows={allMissionProfile}
            onDelete={onDeleteMissionProfile}
            onEdit={onEditAllMissionProfile}
            pagination
            page={page}
            perPage={perPage}
            total={total}
            onPageChange={(page) => setPage(page)}
          />
        </div>
        {showMissionProfileAddEditDialog && (
          <AddEditMissionProfileDialog
            show={showMissionProfileAddEditDialog}
            onClose={OnAddOrEditMissionProfile}
            dialogType={dialogType}
            updateDialogData={updateDialogData}
          />
        )}
        {deleteMissionProfile && (
          <StylishConfirmDialog
            show={deleteMissionProfile}
            onClose={() => setDeleteMissionProfile(null)}
            dialogTitle={'Delete Mission Profile'}
            dialogContentType={'html'}
            dialogContent={`
              Deleting <span class="weight-700 break-word">${deleteMissionProfileTitle}</span> will remove all its occurance from this workspace.<span class="d-block pt-3">Are you sure you want to delete this Mission Profile?</span>`}
            onConfirm={onDeleteConfirm}
          />
        )}
      </div>
    </>
  );
}
