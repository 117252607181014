import {
  ADD_PARTICIPANT_DATA,
  UPDATE_PLATFORM_PARTICIPANT,
  UPDATE_PLANNER_PARTICIPANT,
  ADD_PARTICIPANT_ID,
  ADD_PARTICIPANT_DATA_FUNDING_SOURCE,
} from "./participant.constants";

export const updateParticipantId = (selectParticipantId) => {
  return (dispatch) => {
    dispatch({
      type: ADD_PARTICIPANT_ID,
      payload: selectParticipantId,
    });
  };
};

export const updateFundingSourceinParticipant = (amount, fundingSourceId) => {
  return (dispatch) => {
    dispatch({
      type: ADD_PARTICIPANT_DATA_FUNDING_SOURCE,
      payload: {
        amount,
        fundingSourceId,
      },
    });
  };
};

export const updateParticipantData = (
  id,
  name,
  icon,
  command,
  homeStation,
  DepartmentType,
  department
) => {
  return (dispatch) => {
    dispatch({
      type: ADD_PARTICIPANT_DATA,
      payload: {
        id,
        name,
        icon,
        command,
        homeStation,
        DepartmentType,
        department,
      },
    });
  };
};

export const updateplatforminParticipant = (
  workspaceId,
  platformId,
  beddown,
  participantId,
  id
) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_PLATFORM_PARTICIPANT,
      payload: {
        workspaceId,
        platformId,
        beddown,
        participantId,
        id,
      },
    });
  };
};

export const updateplannerinParticipant = (
  workspaceId,
  name,
  email,
  phnNumber,
  isPrimary,
  participantId,
  id
) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_PLANNER_PARTICIPANT,
      payload: {
        workspaceId,
        name,
        email,
        phnNumber,
        isPrimary,
        participantId,
        id,
      },
    });
  };
};
