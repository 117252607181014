import { editGroup } from 'actions/roleActions';
import StylishNewSelect from 'components/DesignSystems/New/StylishNewSelect';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { startLoading } from 'reducers/loading/loading.action';

export default function GroupAlerts() {

  const [selectedAlert, setSelectedAlert] = useState(null);
  const [distributionLists, setDistributionLists] = useState([]);
  const reduxDispatch = useDispatch();

  // state variables
  const reduxCurrentlySelectedGroup = useSelector((state) => {
    return state.app.currentlySelectedGroup;
  });

  // functions
  const distributionListItems = useSelector((state) => {
    return state.app.distributionListItems;
  });

  const onChangeAlert = (selection) => {
    setSelectedAlert(selection);
    // update currently selected group.
    if (selection) {
      reduxDispatch(startLoading());
      const group = {
        daily_digest_team: selection.value,
        group_guid: reduxCurrentlySelectedGroup.group_guid,
        group_name: reduxCurrentlySelectedGroup.group_name,
        default_settings: reduxCurrentlySelectedGroup.default_settings
      }
      reduxDispatch(editGroup(group))
    }
  }

  // useEffect
  useEffect(() => {
    setDistributionLists(
      distributionListItems.map((item) => ({
        ...item,
        location_enterprise: item.teamLocation?.location_enterprise_data?.label,
      }))
    );
  }, [distributionListItems]);

  useEffect(() => {
    if (reduxCurrentlySelectedGroup.daily_digest_team) {
      const idx = distributionLists.findIndex(item => item.id === reduxCurrentlySelectedGroup.daily_digest_team);
      if (idx > -1) {
        setSelectedAlert({
          label: distributionLists[idx].name,
          value: distributionLists[idx].id
        });
      }
    }
  }, [distributionLists, reduxCurrentlySelectedGroup])

  return (
    <>
      <h4>Daily Hazard Digest</h4>
      <div className="mb-3">
        <span className="d-block">
        Select a Team to receive a daily report of Hazards affecting or forecast to affect AORs.  A Team must be selected for the reports to be sent.
        </span>
      </div>
      <label className="form-label">List of Team Rosters of the Group</label>
      <StylishNewSelect
        classes={'mb-4'}
        value={selectedAlert}
        options={distributionLists.map((r) => {
          return { label: r.name, value: r.id };
        })}
        onChange={(selection) => onChangeAlert(selection)}
      />
    </>
  )
}
