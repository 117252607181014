import React, {useState} from 'react'
import { SimpleAppHeader } from 'components/simpleAppHeader/SimpleAppHeader';
import MainWrapper from '../DesignSystems/layout/MainWrapper';

import './TosPage.css'

export default function TosPage() {

  const [height, setHeight] = useState('0px');

  const onLoad = e => {
      const height = e.currentTarget.contentWindow.document.body.scrollHeight + 100;
      setHeight(`${height}px`)
  }

  return (
    <>
     <SimpleAppHeader 
      />
      <MainWrapper>
      <iframe 
          className="tos" 
          title="tos" 
          src={process.env.PUBLIC_URL + '/tos_content.html'}
          style={{height}}
          onLoad={onLoad}
      />
      </MainWrapper>
    </>
  )
}