import { PratusTab } from '../DesignSystems/PratusTab/PratusTab';
import { Card } from 'react-bootstrap';
import ICS from '../ICS/ICS';
import React, { useState } from 'react';
import { selectIncident, useAppSelector } from '../../slices/commonSelectors';
import { StylishNewButton } from '../DesignSystems/New/StylishNewButton';
import { exportAsForm } from '../../actions/formActions';
import { useDispatch } from 'react-redux';

const DashboardSidebar: React.FC = () => {
  const currentIncident = useAppSelector(selectIncident);
  const [activeTab, setActiveTab] = useState(0);
  const dispatch = useDispatch();
  return (
    <>
      <PratusTab
        tabs={[
          { id: 0, label: 'ICS Forms' },
          { id: 1, label: 'ICS (alpha)' },
        ]}
        activeTabId={activeTab}
        onTabclick={(id: number) => setActiveTab(id)}
      />
      {activeTab === 0 && (
        <>
          <Card className="bg-bgdark m-3">
            <Card.Body>
              <div className="d-flex justify-content-between align-items-center">
                <div>ICS 201-CG</div>
                <StylishNewButton
                  className="button--secondary"
                  onClick={() =>
                    dispatch(exportAsForm({ formname: 'ICS_201-CG' }))
                  }
                >
                  Export
                </StylishNewButton>
              </div>
            </Card.Body>
          </Card>

          <Card className="bg-bgdark m-3">
            <Card.Body>
              <div className="d-flex justify-content-between align-items-center">
                <div>ICS 202-CG</div>
                <StylishNewButton
                  className="button--secondary"
                  onClick={() =>
                    dispatch(exportAsForm({ formname: 'ICS_202_CG' }))
                  }
                >
                  Export
                </StylishNewButton>
              </div>
            </Card.Body>
          </Card>

          <Card className="bg-bgdark m-3">
            <Card.Body>
              <div className="d-flex justify-content-between align-items-center">
                <div>ICS 203-CG</div>
                <StylishNewButton
                  className="button--secondary"
                  onClick={() =>
                    dispatch(exportAsForm({ formname: 'ICS_203_CG' }))
                  }
                >
                  Export
                </StylishNewButton>
              </div>
            </Card.Body>
          </Card>

          <Card className="bg-bgdark m-3">
            <Card.Body>
              <div className="d-flex justify-content-between align-items-center">
                <div>ICS 205-CG</div>
                <StylishNewButton
                  className="button--secondary"
                  onClick={() =>
                    dispatch(exportAsForm({ formname: 'ICS_205_CG' }))
                  }
                >
                  Export
                </StylishNewButton>
              </div>
            </Card.Body>
          </Card>

          <Card className="bg-bgdark m-3">
            <Card.Body>
              <div className="d-flex justify-content-between align-items-center">
                <div>ICS 205A-CG</div>
                <StylishNewButton
                  className="button--secondary"
                  onClick={() =>
                    dispatch(exportAsForm({ formname: 'ICS_205A_CG' }))
                  }
                >
                  Export
                </StylishNewButton>
              </div>
            </Card.Body>
          </Card>

          <Card className="bg-bgdark m-3">
            <Card.Body>
              <div className="d-flex justify-content-between align-items-center">
                <div>ICS 206-CG</div>
                <StylishNewButton
                  className="button--secondary"
                  onClick={() =>
                    dispatch(exportAsForm({ formname: 'ICS_206_CG' }))
                  }
                >
                  Export
                </StylishNewButton>
              </div>
            </Card.Body>
          </Card>

          <Card className="bg-bgdark m-3">
            <Card.Body>
              <div className="d-flex justify-content-between align-items-center">
                <div>ICS 207-CG</div>
                <StylishNewButton
                  className="button--secondary"
                  onClick={() =>
                    dispatch(exportAsForm({ formname: 'ICS_207_CG' }))
                  }
                >
                  Export
                </StylishNewButton>
              </div>
            </Card.Body>
          </Card>
        </>
      )}
      {activeTab === 1 && (
        <Card className="bg-bgdark m-3">
          <Card.Body>
            <ICS reduxCurrentIncident={currentIncident} />
          </Card.Body>
        </Card>
      )}
    </>
  );
};

export default DashboardSidebar;
