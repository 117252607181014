import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import API from '../../../service/api';
import Network from '../../../service/Network';
import { inputStringLength, toastConfig } from 'assets/data/config';
import StylishNewInput from 'components/DesignSystems/New/StylishNewInput';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { StylishNewCheckbox } from 'components/DesignSystems/New/StylishNewCheckbox';
import { endLoading, startLoading } from "../../../../reducers/loading/loading.action";
export default function AddEditStatusBoardDialog({
  onClose,
  show,
  dialogType,
  updateDialogData,
}) {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    getValues,
  } = useForm();

  const { selectedWorkspace } = useSelector((state) => {
    return state.prepare.workspace;
  });

  const [allPlanStatusLabel, setAllPlanStatusLabel] = useState([]);
  const [selectedLabels, setSelectedLabels] = useState([]);
  const dispatch = useDispatch();

  const onSubmit = async (data) => {
    const newData = {
      ...data,
      labels: selectedLabels.map((label) => {
        return {
          labelId: label,
        };
      }),
    };
    dispatch(startLoading());
    try {
      const newStatusBoard = await Network.post(
        dialogType === 'Add'
          ? API.createPlanStatusBoard
          : API.updatePlanStatusBoard,
        {
          ...newData,
          workspaceId: selectedWorkspace,
          boardId: updateDialogData?.boardId,
        }
      );
      toast.success(
        dialogType === 'Add'
          ? 'MSEL Status Board created successfully'
          : 'MSEL Status Board updated successfully',
        toastConfig
      );
      onClose(newStatusBoard.data.response.dataset[0]);
    } catch (error) {
      console.error(error);
      toast.error(error.response.data.response.status.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };

  const allPlanStatusLabelList = async () => {
    dispatch(startLoading());
    try {
      const statusLabelList = await Network.get(API.planStatusLabelList, {
        workspaceId: selectedWorkspace,
      });
      setAllPlanStatusLabel(statusLabelList.data.response.dataset);
    } catch (error) {
      toast.error(error.response.data.response.status.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };

  const allPlanStatusBoardDetails = async () => {
    dispatch(startLoading());
    try {
      const statusBoardDetails = await Network.get(API.planStatusBoardDetails, {
        workspaceId: selectedWorkspace,
        boardId: updateDialogData.boardId,
      });
      setValue(
        'labels[labelId]',
        statusBoardDetails.data.response.dataset[0].labels.map((slabel) => {
          return slabel.labelId;
        })
      );
      setSelectedLabels(
        statusBoardDetails.data.response.dataset[0].labels.map((slabel) => {
          return slabel.labelId;
        })
      );
      setValue('title', statusBoardDetails.data.response.dataset[0].title);
    } catch (error) {
      toast.error(error.response.data.response.status.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };

  useEffect(() => {
    if (show) {
      allPlanStatusLabelList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show, selectedWorkspace]);

  useEffect(() => {
    if (dialogType === 'Edit' && !!updateDialogData) {
      allPlanStatusBoardDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dialogType]);

  const predefinedColorHandler = () => {
    const checkedValue = getValues('Status List');
    setValue('customColor', checkedValue);
  };
  useEffect(() => {
    const listener = (event) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        event.preventDefault();
        handleSubmit(onSubmit)();
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, []);
  console.log('log', allPlanStatusLabel);

  return (
    <Modal
      show={show}
      onHide={onClose}
      centered
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title>{dialogType} Status Board</Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <div className="mb-3">
            <label className="form-label">Title</label>
            <Controller
              control={control}
              name="title"
              defaultValue={updateDialogData?.title}
              rules={{ required: true }}
              maxLength={inputStringLength}
              render={({ field: { onChange, value } }) => (
                <StylishNewInput
                  type="text"
                  className="form-control"
                  placeholder="Title"
                  onChange={onChange}
                  value={value}
                />
              )}
            />
            {errors?.title && (
              <span className="form-text form-error">
                *This is a required field{' '}
              </span>
            )}
          </div>
          <div className="mb-0">
            <label className="form-label">Status List</label>
            <ul className="nav nav__form-color">
              {allPlanStatusLabel.map((element, index) => {
                return (
                  <li key={element.id}>
                    <span
                      className="status d-flex align-items-center"
                      style={{
                        backgroundColor: element.color,
                      }}
                    >
                      {/* <div className="form-checkbox align-items-center me-2"> */}
                      <Controller
                        control={control}
                        name="labels[labelId]"
                        render={({ field: { onChange, value } }) => {
                          // console.log('value: ', value);
                          return (
                            <StylishNewCheckbox
                              type="checkbox"
                              // value={value}
                              onClick={(e) => {
                                console.log('data: ', element.id);
                                console.log('data: ', selectedLabels);
                                // onChange(e);
                                predefinedColorHandler(e);
                                if (selectedLabels.includes(element.id)) {
                                  setSelectedLabels(
                                    selectedLabels.filter(
                                      (data) => data !== element.id
                                    )
                                  );
                                } else {
                                  setSelectedLabels([
                                    ...selectedLabels,
                                    element.id,
                                  ]);
                                }
                                console.log('data: ', selectedLabels);
                              }}
                              checked={selectedLabels.includes(element.id)}
                              label={element.title}
                            />
                          );
                        }}
                      />
                      {/* <input
                          type="checkbox"
                          value={element.id}
                          {...register('labels[labelId]', {
                            onChange: predefinedColorHandler,
                          })}
                        /> */}
                      {/* <span className="icon"></span>
                        <label className="form-label">{element.title}</label>
                      </div> */}
                    </span>
                  </li>
                );
              })}
            </ul>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div className="button-group">
            <StylishNewButton
              className="button button--secondary button--reverse"
              onClick={onClose}
            >
              Close
            </StylishNewButton>
            <StylishNewButton className="button button--primary" type="submit">
              Save
            </StylishNewButton>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
