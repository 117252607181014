import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import MapComponent from 'components/MapComponent/MapComponent';
import { AppHeader } from 'components/headers/appHeader/AppHeader';

import config from 'constants/apiConfig';
import { useNewUIFlag } from '../../utils/featureFlags';

export default function MapPage() {
  const newUI = useNewUIFlag();
  const reduxCurrentlySelectedGroup = useSelector((state) => {
    return state.app.currentlySelectedGroup;
  });

  // All of these hooks represent persisted Map data that would be used in a dedicated application like DICE or EPIC
  const [layers, setLayers] = useState([]);
  const [sources, setSources] = useState([]);

  return !newUI ? (
    <>
      <AppHeader context={'Map'} />
      <div className="site-main overflow-hidden p-0">
        <MapComponent
          layers={layers}
          setLayers={setLayers}
          sources={sources}
          setSources={setSources}
          lng={-93.33}
          lat={45}
          zoom={3}
          apiPrefix={config.apiPrefix}
          group_guid={reduxCurrentlySelectedGroup.group_guid}
        />
      </div>
    </>
  ) : (
    <MapComponent
      layers={layers}
      setLayers={setLayers}
      sources={sources}
      setSources={setSources}
      lng={-93.33}
      lat={45}
      zoom={3}
      apiPrefix={config.apiPrefix}
      group_guid={reduxCurrentlySelectedGroup.group_guid}
    />
  );
}
