import Network from 'PREPAREsrc/service/Network';
import API from 'PREPAREsrc/service/api';
import {
  Legends,
  SpecialInterestOptions,
  textareaStringLength,
  toastConfig,
} from 'assets/data/config';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import StylishNewInput from 'components/DesignSystems/New/StylishNewInput';
import StylishNewSelect from 'components/DesignSystems/New/StylishNewSelect';
import StylishNewTextArea from 'components/DesignSystems/New/StylishNewTextArea';
import { useContext, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { CreateTechnicalDisciplinesDialog } from './CreateTechnicalDisciplinesDialog';
import { TechnicalDisciplineContext } from './TRRList';
import { endLoading, startLoading } from 'reducers/loading/loading.action';
import { toast } from 'react-toastify';

export const AddEditTRRLevelDialog = ({
  show,
  onClose,
  trrLevel,
  data,
  trrItem,
  trrParent,
  trrGrandParent,
  setLastTrrLevel1ItemNo,
}) => {
  const { technicalDisciplines } = useContext(TechnicalDisciplineContext);
  const reduxDispatch = useDispatch();
  const { selectedWorkspace } = useSelector((state) => {
    return state?.prepare?.workspace;
  });
  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();

  const [riskFactors, setRiskFactors] = useState();
  const [
    showCreateTechnicalDiscipline,
    setShowCreateTechnicalDiscipline,
  ] = useState(false);

  useEffect(() => {
    if (!!data && Object.keys(data).length > 0) {
      setValue('item_no', data.item_no);
      setValue('special_interest', data.special_interest);
      setValue(
        'technical_discipline',
        data.trrLevel === 3 &&
          data?.technical_discipline?.map((td) => {
            return {
              label: td.discipline_name,
              value: td.technical_discipline_id,
            };
          })
      );
      setValue('description', data?.description);
      setValue('comments', data?.comments);
      const risk_character = Legends.find(
        (i) => i.value === data?.risk_character
      );
      setValue(
        'risk_character',
        data.trrLevel === 3 ? RiskCharacterOptions(risk_character, 0) : null
      );
    } else {
      setValue('item_no', trrItem);
    }
  }, [data, trrItem]);

  useEffect(() => {
    const data = Legends.map((i, idx) => RiskCharacterOptions(i, idx));
    setRiskFactors(data);
  }, []);

  const handleSelectTechnicalDiscipline = (e, onChange) => {
    const addNew = e.map((i) => i.value).includes('createTechnicalDiscipline');
    if (addNew) {
      setShowCreateTechnicalDiscipline(true);
    } else {
      onChange(e);
    }
  };

  const handleCreateTechnicalDisciplinesDialogClose = (data) => {
    if (data && data.value) {
      const updatedTDs = getValues('technical_discipline')
        ? [...getValues('technical_discipline'), data]
        : [data];
      setValue('technical_discipline', updatedTDs);
    } else {
      const tds = getValues('technical_discipline');
      setValue(
        'technical_discipline',
        !!tds ? getValues('technical_discipline') : []
      );
    }
    setShowCreateTechnicalDiscipline(false);
    reduxDispatch(endLoading());
  };

  const onSubmit = async (formData) => {
    reduxDispatch(startLoading());
    const trrData = {
      id: data?.id,
      ...formData,
      active: false,
      special_interest: formData?.special_interest?.value || '',
      workspaceId: selectedWorkspace,
      risk_character: formData?.risk_character
        ? formData?.risk_character?.value
        : null,
      technical_discipline: formData?.technical_discipline
        ? formData?.technical_discipline?.map((td) => ({
            technical_discipline_id: td.value,
          }))
        : [],
      trr_level_1: data?.trr_level_1 || trrParent?.id,
      trr_level_2: data?.trr_level_2 || trrGrandParent?.id,
    };
    try {
      const resp = await Network.post(
        !!data && Object.keys(data).length > 0 ? API.updateTRR : API.createTRR,
        trrData
      );
      if (trrLevel === 1) {
        setLastTrrLevel1ItemNo((prev) => prev + 1);
      }
      const dataToSend = {
        ...trrData,
        technical_discipline:
          trrLevel === 3
            ? formData?.technical_discipline?.map((td) => ({
                discipline_name: td.label,
                technical_discipline_id: td.value,
              }))
            : data?.technical_discipline,
        technical_discipline_item:
          trrLevel === 3
            ? formData?.technical_discipline?.map((td) => td.label).join(', ')
            : data?.technical_discipline,
        risk_character:
          trrLevel === 3
            ? formData.risk_character.value
            : !!data && Object.keys(data).length > 0
            ? data.risk_character
            : { red: 0, yellow: 0, green: 0, unknown: 0, na: 0 },
        special_interest: formData?.special_interest,
        id: resp.data.response.dataset.id,
        trrLevel: trrLevel,
        child: [],
      };
      onClose(dataToSend);
    } catch (error) {
      toast.error('Failed to Add/Update TRR Level', toastConfig);
      reduxDispatch(endLoading());
    }
  };

  return (
    <Modal
      show={show}
      onHide={onClose}
      centered
      backdrop="static"
      keyboard={false}
      size="lg"
    >
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title>
          {!!data && Object.keys(data).length > 0 ? 'Edit' : 'Add'} Technical
          Readiness Review (TRR) Level {trrLevel}
        </Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <div className="mb-3">
            <label className="form-label">Item</label>
            <Controller
              control={control}
              name="item_no"
              rules={{
                required: false,
              }}
              render={({ field: { onChange, value } }) => (
                <StylishNewInput
                  type="text"
                  onChange={onChange}
                  value={value || ''}
                  readOnly
                  disabled
                />
              )}
            />
          </div>
          <div className="mb-3">
            <label className="form-label">Special Interest</label>
            <Controller
              control={control}
              name="special_interest"
              render={({ field: { onChange, value } }) => (
                <StylishNewSelect
                  options={SpecialInterestOptions}
                  value={value}
                  onChange={onChange}
                  isClearable={false}
                  isSearchable={false}
                  isMulti={false}
                  isDisabled={false}
                  placeholder="Select..."
                />
              )}
            />
          </div>
          {(trrLevel === 3 && (
            <div className="mb-3">
              <label className="form-label">
                Technical Discipline
                <span aria-label="Required field" className="required">
                  *
                </span>
              </label>
              <Controller
                control={control}
                name="technical_discipline"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <StylishNewSelect
                    options={[
                      { label: 'Add New', value: 'createTechnicalDiscipline' },
                      ...technicalDisciplines,
                    ]}
                    value={value}
                    onChange={(e) =>
                      handleSelectTechnicalDiscipline(e, onChange)
                    }
                    isClearable={false}
                    isSearchable={false}
                    isMulti={true}
                    isDisabled={false}
                    placeholder="Select..."
                  />
                )}
              />
              {errors.technical_discipline?.type === 'required' && (
                <span className="form-text form-error">
                  This field is required
                </span>
              )}
            </div>
          )) ||
            null}
          <div className="mb-3">
            <label className="form-label">
              Description
              <span aria-label="Required field" className="required">
                *
              </span>
            </label>
            <Controller
              control={control}
              name="description"
              rules={{ required: true, maxLength: textareaStringLength }}
              render={({ field: { onChange, value } }) => (
                <StylishNewTextArea value={value || ''} onChange={onChange} />
              )}
            />
            {errors?.description?.type === 'required' && (
              <span className="form-text form-error">
                This field is required
              </span>
            )}
            {errors?.description?.type === 'maxLength' && (
              <span className="form-text form-error">
                Max {textareaStringLength} character allowed
              </span>
            )}
          </div>
          {(trrLevel === 3 && (
            <div className="mb-3">
              <label className="form-label">
                Risk Character
                <span aria-label="Required field" className="required">
                  *
                </span>
              </label>
              <Controller
                control={control}
                name="risk_character"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <StylishNewSelect
                    options={riskFactors}
                    value={value}
                    onChange={onChange}
                    isClearable={false}
                    isSearchable={false}
                    isMulti={false}
                    isDisabled={false}
                    placeholder="Select..."
                  />
                )}
              />
              {errors.risk_character?.type === 'required' && (
                <span className="form-text form-error">
                  This field is required
                </span>
              )}
            </div>
          )) ||
            null}
          <div className="mb-0">
            <label className="form-label">Comments/Mitigation</label>
            <Controller
              control={control}
              name="comments"
              rules={{ maxLength: textareaStringLength }}
              render={({ field: { onChange, value } }) => (
                <StylishNewTextArea value={value || ''} onChange={onChange} />
              )}
            />
            {errors?.comments?.type === 'maxLength' && (
              <span className="form-text form-error">
                Max {textareaStringLength} character allowed
              </span>
            )}
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div className="button-group">
            <StylishNewButton
              className="button--secondary button--reverse"
              onClick={() => onClose()}
            >
              Cancel
            </StylishNewButton>

            <StylishNewButton className="button--primary" type="submit">
              Save
            </StylishNewButton>
          </div>
        </Modal.Footer>
      </form>
      {(showCreateTechnicalDiscipline && (
        <CreateTechnicalDisciplinesDialog
          show={showCreateTechnicalDiscipline}
          onClose={(data) => handleCreateTechnicalDisciplinesDialogClose(data)}
        />
      )) ||
        null}
    </Modal>
  );
};

const RiskCharacterOptions = (riskCharacter, idx) => {
  return {
    value: riskCharacter?.value,
    label: (
      <div className="d-flex align-items-center flex-grow-1" key={idx}>
        <span
          style={{
            backgroundColor: riskCharacter?.color,
            height: '20px',
            width: '20px',
            borderRadius: '4px',
          }}
          className="me-2 flex-shrink-0"
        ></span>
        {riskCharacter?.name}
      </div>
    ),
  };
};
