import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { StylishNewSearchBar } from 'components/DesignSystems/New/StylishNewSearchBar';
import StylishNewTable from 'components/DesignSystems/New/StylishNewTable';
import { Modal } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import API from '../../../service/api';
import Network from '../../../service/Network';
import { toastConfig } from 'assets/data/config';
import { endLoading, startLoading } from "../../../../reducers/loading/loading.action";

export default function AttachNotebookDialog({ show, onClose }) {
  const [attachedTotal, setAttachedTotal] = useState(0);

  const [searchTerm, setSearchTerm] = useState('');
  const [noteBookToAdd, setNoteBookToAdd] = useState([]);
  const [noteBookToRemove, setNoteBookToRemove] = useState([]);

  const workspaceId = useSelector(
    (state) => state.prepare.workspace.selectedWorkspace
  );

  const { id } = useParams();

  const dispatch = useDispatch();

  const onChangeSearchTerm = (term) => {
    setSearchTerm(term);
  };

  const columnsAddNoteBooks = [
    {
      dataField: 'title',
      text: 'Name',
      sort: true,
      headerAttrs: { title: 'Sort By:' },
      attrs: { title: 'Name' },
    },
    {
      text: 'Action',
      isDummyField: true,
      formatter: (cell, row, rowIndex) =>
        rowIndex + 1 === noteBookToAdd.length ? (
          <StylishNewButton
            className="button--action"
            type="button"
            onClick={(e) => attachNotebook(e, row)}
          >
            <i className="fa fa-plus" aria-hidden="true"></i>
          </StylishNewButton>
        ) : (
          <StylishNewButton
            className="button--action"
            type="button"
            onClick={(e) => attachNotebook(e, row)}
          >
            <i className="fa fa-plus" aria-hidden="true"></i>
          </StylishNewButton>
        ),
      headerStyle: {
        width: '100px',
      },
      headerAlign: 'center',
      align: 'center',
      attrs: { title: 'Action' },
    },
  ];

  const columnsCurrentNoteBooks = [
    {
      dataField: 'title',
      text: 'Name',
      sort: true,
      headerAttrs: { title: 'Sort By:' },
      attrs: { title: 'Title' },
    },
    {
      dataField: '',
      text: 'Action',
      isDummyField: true,
      formatter: (cell, row, rowIndex) => (
        <StylishNewButton
          className="button--action"
          type="button"
          onClick={(e) => detachNotebook(e, row)}
        >
          <i className="fa fa-minus" aria-hidden="true"></i>
        </StylishNewButton>
      ),
      headerStyle: {
        width: '100px',
      },
      headerAlign: 'center',
      align: 'center',
      attrs: { title: 'Action' },
    },
  ];

  const notefolderNotebookList = async () => {
    dispatch(startLoading());
    try {
      const response = await Network.get(API.notefolderNotebookList, {
        search: searchTerm || '',
        notefolderId: id,
        workspaceId: workspaceId,
      });
      setNoteBookToRemove(response.data.response.dataset[0].assignedNotebooks);
      setNoteBookToAdd([
        ...response.data.response.dataset[0].notAssignedNotebooks,
      ]);
      setAttachedTotal(
        response?.data?.response?.dataset[0]?.assignedNotebooks.length
      );
    } catch (error) {
      toast.error(error?.response?.data?.response?.status?.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };

  const attachNotebook = async (e, data) => {
    e.preventDefault();
    dispatch(startLoading());
    try {
      const response = await Network.post(API.attachNotebook, {
        notebookId: data?.id,
        notefolderId: id,
        workspaceId: workspaceId,
      });
      setNoteBookToAdd(
        response?.data?.response?.dataset[0]?.notAssignedNotebooks
      );
      setNoteBookToRemove(
        response?.data?.response?.dataset[0]?.assignedNotebooks
      );
      setAttachedTotal(attachedTotal + 1);

      toast.success('Notebook is attached', toastConfig);
    } catch (error) {
      toast.error(error.response.data.response.status.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };

  const detachNotebook = async (e, data) => {
    e.preventDefault();
    dispatch(startLoading());
    try {
      const response = await Network.post(API.detachNotebook, {
        id: data?.id,
        notefolderId: id,
        workspaceId: workspaceId,
      });

      setNoteBookToAdd(
        response?.data?.response?.dataset[0]?.notAssignedNotebooks
      );
      setNoteBookToRemove(
        response?.data?.response?.dataset[0]?.assignedNotebooks
      );

      setAttachedTotal(attachedTotal - 1);

      toast.success('Notebook is detached', toastConfig);
    } catch (error) {
      toast.error(error.response.data.response.status.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };

  useEffect(() => {
    notefolderNotebookList();
  }, [searchTerm]);

  return (
    <Modal
      show={show}
      onHide={onClose}
      centered
      backdrop="static"
      keyboard={false}
      size="lg"
    >
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title>Notebook List</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h6>{attachedTotal} Attached Notebook </h6>
        <div className="table-scroll">
          <StylishNewTable
            keyField={'id'}
            columns={columnsCurrentNoteBooks}
            rows={noteBookToRemove}
          />
        </div>
        <h6 className="mt-5">Select Notebook </h6>
        <StylishNewSearchBar
          onChangeSearchTerm={onChangeSearchTerm}
          className="mb-3"
        />
        <div className="table-scroll">
          <StylishNewTable
            keyField={'id'}
            columns={columnsAddNoteBooks}
            rows={noteBookToAdd}
          />
        </div>
      </Modal.Body>

      <Modal.Footer>
        <div className="button-group">
          {/* <StylishNewButton
            className="button--secondary button--reverse"
            onClick={onClose}
            type="button"
          >
            Cancel
          </StylishNewButton> */}
          <StylishNewButton
            className="button--primary"
            onClick={onClose}
            type="button"
          >
            Done
          </StylishNewButton>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
