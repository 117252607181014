import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap';
import API from '../../../service/api';
import Network from '../../../service/Network';
import MoveFolderChildDialog from './MoveFolderChildDialog';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { endLoading, startLoading } from "../../../../reducers/loading/loading.action";

export default function MoveFolderDialog({
  onClose,
  show,
  dialogType,
  updateDialogData,
  isRoot,
  parentId,
  parentInfo,
}) {
  const childRef = useRef();

  const [allDetails, setAllDetails] = useState([]);

  const { workspaceName } = useSelector((state) => state.prepare.workspace);
  const workspaceId = useSelector(
    (state) => state.prepare.workspace.selectedWorkspace
  );
  const reduxUser = useSelector((state) => state.prepare.user);

  const dispatch = useDispatch();

  const getAllNotefolderAndFile = async () => {
    dispatch(startLoading());
    if (workspaceId) {
      try {
        const noteFolder = await Network.get(API.notefolderAndList, {
          workspaceId: workspaceId,
        });
        const newNoteFolder = noteFolder?.data?.response?.dataset.filter(
          (data) => data?.id != updateDialogData?.id
        );
        setAllDetails(newNoteFolder.filter((data) => data?.type != 'file'));
      } catch (error) {
        console.log(error.response.data.response.status.msg);
      } finally {
        dispatch(endLoading());
      }
    }
  };

  useEffect(() => {
    getAllNotefolderAndFile();
  }, [reduxUser]);

  return (
    <Modal
      show={show}
      onHide={onClose}
      centered
      backdrop="static"
      keyboard={false}
      size="xl"
      fullscreen="xl-down"
    >
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title>
          {dialogType === 'folder' ? 'Move Folder - ' : 'Move File - '}
          {updateDialogData?.folder_name || updateDialogData?.file_name ? (
            <>
              <br />
              <span className="d-block break-word">
                {updateDialogData?.folder_name || updateDialogData?.file_name}
              </span>
            </>
          ) : null}
        </Modal.Title>
      </Modal.Header>
      <form>
        <Modal.Body>
          {isRoot ? (
            <h5 className="p-3 bg-gray-600 mb-0">{parentInfo} Files</h5>
          ) : (
            <h5 className="p-3 bg-gray-600 mb-0">{workspaceName} Files</h5>
          )}
          {allDetails && allDetails?.length ? (
            <MoveFolderChildDialog
              updateDialogData={allDetails}
              folder={updateDialogData}
              actualParentId={parentId}
              onClose={onClose}
              ref={childRef}
            />
          ) : null}
        </Modal.Body>

        <Modal.Footer>
          <div className="button-group">
            <StylishNewButton
              className="button--secondary button--reverse"
              onClick={onClose}
              type="button"
            >
              Cancel
            </StylishNewButton>
            <StylishNewButton
              className="button--primary"
              type="button"
              onClick={() => childRef.current.onSubmit()}
            >
              Move
            </StylishNewButton>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
