import React from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timegrid from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import Network from '../../../../service/Network';
import API from '../../../../service/api';
import { useNavigate, NavLink } from 'react-router-dom';
import moment from 'moment';
import { toast } from 'react-toastify';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { PreparePath } from 'utils/sharedUtils/sharedConst';
import { HelpEvents, useSelfHostedKnowledgeBase } from 'assets/data/HelpContent';
import StylishHelp from 'components/DesignSystems/New/StylishHelp';
import { toastConfig } from 'assets/data/config';
import { endLoading, startLoading } from "../../../../../reducers/loading/loading.action";
import { SharedIcon } from 'components/SharedIcon/SharedIcon';

export const Calendar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [eventMeetings, setEventMeetings] = useState([]);
  const [refinedEventMeetings, setRefinedEventMeetings] = useState([]);

  const selfHostedKnowledgeBase = useSelfHostedKnowledgeBase()

  const { selectedWorkspace } = useSelector((state) => state.prepare.workspace);
  const reduxUser = useSelector((state) => state.prepare.user);

  const onAddEvents = () => {
    navigate(`${PreparePath}/dashboard/plan/prepare/event/new`);
  };

  const fetchEvents = async () => {
    dispatch(startLoading());
    try {
      await Network.get(API.fetchEvents, {
        workspaceId: selectedWorkspace,
      }).then((resp) => {
        let allEventMeetings = {};
        allEventMeetings =
          resp.data.response.dataset.length &&
          resp.data.response.dataset.map((e) => {
            return {
              ...e,
              startDate: moment(e?.startDate).toISOString(),
              endDate: moment(e?.endDate).toISOString(),
            };
          });
        setEventMeetings(allEventMeetings);
      });
    } catch (error) {
      toast.error(error.response.data.response.status.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };

  useEffect(() => {
    fetchEvents();
  }, [selectedWorkspace]);

  useEffect(() => {
    if (eventMeetings) {
      const eventCalendar = eventMeetings.map((e) => {
        const refinedPlanCalendar = {
          title: e?.name,
          start: e?.startDate,
          end: e?.endDate,
          id: e?.id,
          color: e?.color,
        };
        return refinedPlanCalendar;
      });
      setRefinedEventMeetings(eventCalendar);
    } else {
      setRefinedEventMeetings([]);
    }
  }, [eventMeetings]);

  return (
    <>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <StylishNewButton onClick={() => navigate(-1)}>
              <SharedIcon iconName="keyboard_arrow_left" classes="me-2" />
              Back
            </StylishNewButton>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Scenarios
          </li>
        </ol>
      </nav>
      <div className="row">
        <div className="col-md-12">
          <div className="d-flex align-items-center mb-4">
            <StylishNewButton
              className="button button--primary"
              onClick={onAddEvents}
            >
              Create Scenario
            </StylishNewButton>
            <div className="d-flex align-items-center justify-content-between ms-auto">
              <div className="button-group toggle">
                <NavLink
                  className="button--icon lrg"
                  to={`${PreparePath}/dashboard/plan/prepare/events-calendar`}
                >
                  <i className="fa fa-calendar" aria-hidden="true"></i>
                </NavLink>
                <NavLink
                  className="button--icon lrg"
                  to={`${PreparePath}/dashboard/plan/prepare/events`}
                >
                  <i className="fa fa-th-large" aria-hidden="true"></i>
                </NavLink>
              </div>
              <StylishHelp
                classes={'ms-3'}
                title={HelpEvents.title}
                content={HelpEvents.content}
                link={selfHostedKnowledgeBase || HelpEvents.link}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="calendar">
        <FullCalendar
          validRange={''}
          plugins={[dayGridPlugin, timegrid, listPlugin, interactionPlugin]}
          headerToolbar={{
            left: 'prev,next today',
            center: 'title',
            right: 'timeGridDay,timeGridWeek,dayGridMonth,listMonth',
          }}
          initialView="dayGridMonth"
          events={refinedEventMeetings}
          eventClick={(e) => {
            navigate(
              `${PreparePath}/dashboard/plan/prepare/event/view/${e.event._def.publicId}`
            );
          }}
          eventDisplay="block"
          eventTimeFormat={{
            hour: '2-digit',
            minute: '2-digit',
            meridiem: 'short',
            hour12: reduxUser?.timeFormat === 'hour12Mode' ? true : false,
          }}
          displayEventTime={false}
          slotLabelFormat={{
            hour: '2-digit',
            minute: '2-digit',
            meridiem: 'short',
            hour12: reduxUser?.timeFormat === 'hour12Mode' ? true : false,
          }}
          slotLabelContent={(info) => {
            if (info.text === '24:00') {
              info.text = '00:00';
            }
          }}
        />
      </div>
    </>
  );
};
