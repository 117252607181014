import React, { useState, useEffect } from 'react';

import {useSelector} from 'react-redux';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import {
  useNavigate,
} from "react-router-dom";

import './PageDropdownMenu.css';
import { SharedIcon } from 'components/SharedIcon/SharedIcon';

export const PageDropdownMenu = () => {

  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState();
  
  const {msalInstance, B2CmsalInstance, teamsInitialized} = useSelector(state => {
    return state.auth
  })

  const { user } = useSelector(state => {
    return state.app
  }) || {}

  const groups = useSelector(state => {
    return state.app.groups;
  })

  const [isDev, setIsDev] = useState(false);

  useEffect(() => {
    if (groups && groups.length) {
      const devFound = !!groups.find(group => group.group_name === "DisasterTechDev")
      setIsDev(devFound)
    }
  }, [groups])

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (chosen) => {
    setAnchorEl();
    switch (chosen) {
      case "profile":
        navigate(`/profile/${user && user.user_guid}`)
        break;
      default:
        if (chosen) {
          navigate(`/${chosen}`)
        }
        break;
    }
  };

  const B2CAccounts = B2CmsalInstance.getAllAccounts();
  const handleLogout = () => {
    setAnchorEl()

    if (!!B2CAccounts.length) {
      const logoutRequest = {
        account: B2CAccounts[0]
      };
      B2CmsalInstance.logout(logoutRequest);
    } else {
      msalInstance.logout()
    }
  }

  return (
    <>
      <span className="PageDropdownMenu-gear" aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
        <SharedIcon iconName="more_vert" bold />
      </span>
      <Menu
        className="PageDropdown-menu"
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => handleClose()}
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {user && user.status !== 'Subscribed' && (
          <MenuItem onClick={() => {
            window.open("https://appsource.microsoft.com/en-us/product/office/WA200001909?tab=Overview", "_blank");
          }}>
            Sign up for Premium
          </MenuItem>
        )}
        <MenuItem onClick={() => {
          window.open(window.location.origin + "/privacy.html", "_blank");
        }}>
          Privacy policy
        </MenuItem>
        <MenuItem onClick={() => {
          window.open(window.location.origin + "/tos.html", "_blank");
        }}>
          Terms of service
        </MenuItem>
        {!teamsInitialized && (
          <MenuItem onClick={() => handleLogout()}>
            Logout
          </MenuItem>
        )}
        {isDev && (
          <MenuItem onClick={() => handleClose("feature_flags")}>
            Feature flags
          </MenuItem>
        )}
        {isDev && (
          <MenuItem onClick={() => alert("release/v2.18\n\nAsk the developers\n\n to finish this ;)")}>
            App Version
          </MenuItem>
        )}
      </Menu>
    </>
  );
};
