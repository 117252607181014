import React, { useEffect, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timegrid from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import Network from '../../../../../service/Network';
import API from '../../../../../service/api';
import { NavLink, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { MSELCreate } from '../MSELCreate';
import EditMSELTimeline from '../../../Train/MSELTimeline/EditMSELTimeline';
import Help from '../../../../../component/Help/Help';
import { HelpMselCalendar, useSelfHostedKnowledgeBase } from 'assets/data/HelpContent';
import { PreparePath } from 'utils/sharedUtils/sharedConst';
import {
  endLoading,
  startLoading,
} from '../../../../../../reducers/loading/loading.action';
import PageTitle from 'components/headers/PageTitle';
import { useNewUIFlag } from 'utils/featureFlags';
import { SharedIcon } from 'components/SharedIcon/SharedIcon';

export const CalendarMsel = () => {
  const [injectMeetings, setInjectMeetings] = useState([]);
  const [refinedInjectMeetings, setRefinedInjectMeetings] = useState([]);
  const [showCreateList, setShowCreateList] = useState(false);
  const [popupOnClick, showPopupOnClick] = useState(false);
  const [eventId, setEventId] = useState();

  const selfHostedKnowledgeBase = useSelfHostedKnowledgeBase()

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { selectedWorkspace, type: workspaceType } = useSelector(
    (state) => state.prepare.workspace
  );
  const reduxUser = useSelector((state) => state.prepare.user);
  const newUI = useNewUIFlag();

  useEffect(() => {
    if (!!workspaceType && workspaceType === 'T&E') {
      navigate(`${PreparePath}/dashboard/plan`);
    }
  }, [workspaceType]);

  const fetchAllInject = async () => {
    dispatch(startLoading());
    try {
      Network.get(API.fetchAllInject, {
        workspaceId: selectedWorkspace,
      })
        .then((resp) => {
          let allInjectMeetings = {};
          allInjectMeetings =
            resp.data.response.dataset.length &&
            resp.data.response.dataset.map((e) => {
              return {
                ...e,
                start: e?.startDate,
              };
            });
          setInjectMeetings(allInjectMeetings);
        })
        .catch(console.log)
        .finally(() => dispatch(endLoading()));
    } catch (error) {
      console.log(error);
      // toast.error(error.response.data.response.status.msg);
      // } finally {
      //   dispatch(endLoading());
    }
  };

  useEffect(() => {
    fetchAllInject();
  }, [selectedWorkspace]);

  useEffect(() => {
    if (injectMeetings) {
      const eventCalendar = injectMeetings.map((e) => {
        const refinedPlanCalendar = {
          title: e?.injectTitle,
          start: e?.startDate,
          end: e?.startDate,
          id: e?.id,
        };
        return refinedPlanCalendar;
      });
      setRefinedInjectMeetings(eventCalendar);
    } else {
      setRefinedInjectMeetings([]);
    }
  }, [injectMeetings, selectedWorkspace]);

  const onAddInject = () => {
    setShowCreateList(true);
  };

  const onCloseModal = (e) => {
    setShowCreateList(false);
    if (e) {
      fetchAllInject();
    }
  };

  const onCloseOfModal = (e) => {
    setEventId();
    showPopupOnClick(false);
  };

  return (
    <>
      {newUI ? (
        <PageTitle
          title="MSEL Calendar"
          parents={['Exercise Management', 'MSEL']}
        />
      ) : (
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <button onClick={() => navigate(-1)}>
                <SharedIcon iconName="keyboard_arrow_left" classes="me-2" />
                Back
              </button>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Injects
            </li>
          </ol>
        </nav>
      )}

      <div className="row">
        <div className="col-md-12">
          <div className="d-flex align-items-center mb-4">
            <button className="button button--primary" onClick={onAddInject}>
              Create Inject
            </button>
            <div className="d-flex align-items-center justify-content-between ms-auto">
              <div className="button-group toggle">
                {!newUI && (
                  <>
                    <NavLink
                      className="button--icon lrg flex-grow-1"
                      to={`${PreparePath}/dashboard/plan/prepare/msel-list`}
                    >
                      <i className="fa fa-bars" aria-hidden="true"></i>
                    </NavLink>
                    <NavLink
                      className="button--icon lrg flex-grow-1"
                      to={`${PreparePath}/dashboard/plan/prepare/msel-board`}
                    >
                      <i className="fa fa-th-large" aria-hidden="true"></i>
                    </NavLink>
                    <NavLink
                      className="button--icon lrg flex-grow-1"
                      to={`${PreparePath}/dashboard/plan/prepare/msel-calendar`}
                    >
                      <i className="fa fa-calendar" aria-hidden="true"></i>
                    </NavLink>
                  </>
                )}
              </div>
              <Help
                classes={'ms-3 flex-shrink-0 sml'}
                title={HelpMselCalendar.title}
                content={HelpMselCalendar.content}
                link={selfHostedKnowledgeBase || HelpMselCalendar.link}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="calendar">
        <FullCalendar
          validRange={''}
          plugins={[dayGridPlugin, timegrid, listPlugin, interactionPlugin]}
          headerToolbar={{
            left: 'prev,next today',
            center: 'title',
            right: 'timeGridDay,timeGridWeek,dayGridMonth,listMonth',
          }}
          initialView="dayGridMonth"
          events={refinedInjectMeetings}
          eventClick={(e) => {
            setEventId(e.event._def.publicId);
            showPopupOnClick(true);
            // <EditMSELTimeline
            //   show={true}
            //   onClose={false}
            //   viewId={e.event._def.publicId}
            // ></EditMSELTimeline>;
          }}
          eventTimeFormat={{
            hour: '2-digit',
            minute: '2-digit',
            meridiem: 'short',
            hour12: reduxUser?.timeFormat === 'hour12Mode' ? true : false,
          }}
          displayEventTime={false}
          slotLabelFormat={{
            hour: '2-digit',
            minute: '2-digit',
            meridiem: 'short',
            hour12: reduxUser?.timeFormat === 'hour12Mode' ? true : false,
          }}
          slotLabelContent={(info) => {
            if (info.text === '24:00') {
              info.text = '00:00';
            }
          }}
        />
      </div>
      {showCreateList && (
        <MSELCreate show={showCreateList} onClose={onCloseModal} />
      )}
      {popupOnClick && (
        <EditMSELTimeline
          show={popupOnClick}
          onClose={onCloseOfModal}
          viewId={eventId}
          onDeleteSuccess={fetchAllInject}
        ></EditMSELTimeline>
      )}
    </>
  );
};
