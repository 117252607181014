import { useToggle } from 'react-use';
import ExpandDownArrow from '../DesignSystems/buttons/ExpandDownArrow';
import ExpandRightArrow from '../DesignSystems/buttons/ExpandRightArrow';
import { useIncidentRoster } from './hooks/incidentRosterHooks';
import Collapse from '@mui/material/Collapse';
import { Card } from 'react-bootstrap';
import { getInitials } from '../../helpers/intials';
import React from 'react';

const CollapsibleRosterList = () => {
  const [open, toggle] = useToggle(false);
  const roster = useIncidentRoster();

  return (
    <div className="w-100">
      <div className="w-100 d-flex align-items-center mb-2">
        {roster.data?.length ? (
          <div>
            {open ? (
              <ExpandDownArrow onClick={toggle} />
            ) : (
              <ExpandRightArrow onClick={toggle} />
            )}
          </div>
        ) : null}
        <span>Incident Seat Roster</span>
      </div>
      <Collapse in={open}>
        <div
          style={{
            borderLeft: '1px solid var(--neutral-700)',
            paddingLeft: '1.375rem',
            marginLeft: '0.625rem',
          }}
        >
          {roster.data?.map((entity) => {
            let initials: string | "" = entity?.user_name||entity?.email_address
            try {
              initials = getInitials(initials)
            } catch (e) {
              console.warn("getInitials ERROR",e)
              initials = ""
            }

            return (
            <Card
              key={entity.user_guid}
              className="bg-neutral-800 my-1 p-2 rounded-1 align-items-center flex-row justify-content-between"
            >
              <div>{entity.incident_seat}</div>
              <div className="d-flex justify-content-start align-items-center">
                {!!initials && (
                <div className="avatar-initials sml me-3">
                  {initials}
                </div>
                )}
                <div>{entity?.user_name||entity?.email_address}</div>
              </div>
            </Card>
          )})}
        </div>
      </Collapse>
    </div>
  );
};
export default CollapsibleRosterList;
