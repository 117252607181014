import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import StylishNewSelect from 'components/DesignSystems/New/StylishNewSelect';
import { useForm, Controller } from 'react-hook-form';
import Network from 'PREPAREsrc/service/Network';
import API from 'PREPAREsrc/service/api';
import { startLoading, endLoading } from 'reducers/loading/loading.action';
import { useDispatch, useSelector } from 'react-redux';
import ReviewProfilesDialog from './ReviewPersonasDialog';
import CreatePersonaDialog from '../Personas/CreatePersonaDialog';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import UserIcon from 'assets/images/icon__developer--users.svg';

export default function AddProfilestoPersonaDialog({
  onClose,
  show,
  connectedPersonasForDropDown,
  profileSelect,
  isCloseModals,
  profileSelectToCreatePersonaPage,
  imageAvatar,
  keyPressForNetworkName,
}) {
  const {
    setValue,
    formState: { errors },
    control,
    getValues,
  } = useForm();

  const dispatch = useDispatch();

  const [personaList, setPersonaList] = useState([]);
  const [selectedPersona, setselectedPersona] = useState(null);
  const [showCreatePersonaDialog, setShowCreateProfileDialog] = useState(false);
  const [showAddRelationshipsDialog, setShowAddRelationshipsDialog] = useState(
    null
  );

  const workspaceId = useSelector(
    (state) => state.prepare.workspace.selectedWorkspace
  );

  const onReviewPersonaDialog = () => {
    setShowAddRelationshipsDialog(true);
  };
  const onReviewPersonaDialogClose = () => {
    setShowAddRelationshipsDialog(false);
  };

  const onCreatePersona = () => {
    setShowCreateProfileDialog(true);
  };
  const onCreatePersonaDialogClose = (aftersave) => {
    setShowCreateProfileDialog(false);
    if (aftersave) {
      mediaPersonaList();
    }
  };
  const onChooseMediaDialog = () => {
    // setShowChooseMediaDialog(true);
    setShowCreateProfileDialog(false);
  };

  const onprofileSelectToCreatePersonaPage = (data) => {
    profileSelectToCreatePersonaPage(data);
  };

  const mediaPersonaList = async () => {
    dispatch(startLoading());
    try {
      const response = await Network.get(API.mediaPersonaList, {
        workspaceId: workspaceId,
      });
      const newProfileList = response?.data?.response?.dataset.map(
        ({ name, id, avatar }) => ({
          value: id,
          label: name,
          avatar: avatar,
        })
      );
      setPersonaList(newProfileList);
      let selectedData = [];

      setValue(
        'persona',
        connectedPersonasForDropDown.map((data) => ({
          value: data?.value,
          label: data?.label,
          avatar: data?.avatar,
        }))
      );
      setselectedPersona(
        connectedPersonasForDropDown.map((data) => ({
          value: data?.value,
          label: data?.label,
          avatar: data?.avatar,
        }))
      );
    } catch (error) {
      console.log('error', error);
    } finally {
      dispatch(endLoading());
    }
  };

  useEffect(() => {
    mediaPersonaList();
  }, []);

  return (
    <Modal
      show={show}
      onHide={onClose}
      centered
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title>Add Personas to Network</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex align-items-center">
          {imageAvatar ? (
            <div className="profile-img me-3 flex-shrink-0">
              <img src={imageAvatar} alt="" />
            </div>
          ) : (
            <div className="profile-img me-3 flex-shrink-0"></div>
          )}

          <h4 className="mb-0 me-2 break-word">{keyPressForNetworkName}</h4>

          <div className="d-flex align-items-center ms-auto flex-shrink-0">
            <img className="img-h-16" src={UserIcon} alt="" />

            {getValues('persona')?.length ? (
              <>
                {getValues('persona')?.length > 0 && (
                  <p className="mb-0 ms-2">{getValues('persona')?.length}</p>
                )}
              </>
            ) : (
              <p className="mb-0 ms-2">0</p>
            )}
          </div>
        </div>
        <hr className="dashed" />

        <div className="d-flex justify-content-center mb-3">
          <StylishNewButton
            className="button--primary"
            onClick={onCreatePersona}
          >
            Create Persona
          </StylishNewButton>
        </div>

        <p className="line-heading d-flex align-items-center justify-content-center my-4">
          <hr className="m-0 p-0 position-absolute col-10" />
          <span className="break-word px-3 text-center position-relative">
            Or Search Existing Personas
          </span>
        </p>
        <Controller
          control={control}
          name="persona"
          render={({ field: { onChange, value } }) => (
            <StylishNewSelect
              options={personaList}
              isClearable={false}
              isSearchable={true}
              value={value || ''}
              onChange={(e) => {
                setselectedPersona(e);
                onChange(e);
              }}
              isMulti={true}
              isDisabled={false}
              placeholder="Search..."
            />
          )}
        />
      </Modal.Body>
      <Modal.Footer>
        <div className="button-group">
          <StylishNewButton
            className="button--secondary button--reverse"
            onClick={onClose}
          >
            Cancel
          </StylishNewButton>
          <StylishNewButton
            className="button--primary"
            onClick={onReviewPersonaDialog}
          >
            Review
          </StylishNewButton>
        </div>
      </Modal.Footer>
      {showAddRelationshipsDialog && (
        <ReviewProfilesDialog
          show={showAddRelationshipsDialog}
          onClose={onReviewPersonaDialogClose}
          profileSelectCount={selectedPersona?.length}
          profileSelect={selectedPersona}
          isCloseModals={onClose}
          profileSelectToCreatePersonaPage={onprofileSelectToCreatePersonaPage}
        />
      )}
      {showCreatePersonaDialog && (
        <CreatePersonaDialog
          show={showCreatePersonaDialog}
          onClose={onCreatePersonaDialogClose}
          onChooseMediaDialog={onChooseMediaDialog}
        />
      )}
    </Modal>
  );
}
