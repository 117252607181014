import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import SearchBar from '../../../../component/SearchBar/SearchBar';
import Network from '../../../../service/Network';
import API from '../../../../service/api';
import MSELBoardContainer from './MSELBoard/MSELBoardContainer';
import { MSELCreate } from './MSELCreate';
import EditMSELTimeline from '../../Train/MSELTimeline/EditMSELTimeline';
import { HelpPlanInjects, useSelfHostedKnowledgeBase } from 'assets/data/HelpContent';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { PreparePath } from 'utils/sharedUtils/sharedConst';
import StylishHelp from 'components/DesignSystems/New/StylishHelp';
import StylishNewSelect from 'components/DesignSystems/New/StylishNewSelect';
import { StylishCarousel } from 'components/DesignSystems/New/StylishCarousel';
import {
  endLoading,
  startLoading,
} from '../../../../../reducers/loading/loading.action';
import PageTitle from 'components/headers/PageTitle';
import { useNewUIFlag } from 'utils/featureFlags';
import { SharedIcon } from 'components/SharedIcon/SharedIcon';

export const MSELBoard = () => {
  const [boards, setBoards] = useState([]);
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState();
  const [selectedBoard, setSelectedBoard] = useState();
  const [searchTerm, setSearchTerm] = useState('');
  const [boardInjects, setBoardInjects] = useState([]);
  const [totalMselInject, setTotalMselInject] = useState(0);
  const [showCreateMSELInject, setShowCreateMSELInject] = useState(false);
  const [editMSELTimeline, setEditMSELTimeline] = useState(false);
  const [viewId, setViewId] = useState();

  const selfHostedKnowledgeBase = useSelfHostedKnowledgeBase()

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const newUI = useNewUIFlag();

  const { selectedWorkspace, type: workspaceType } = useSelector(
    (state) => state.prepare.workspace
  );

  useEffect(() => {
    if (!!workspaceType && workspaceType === 'T&E') {
      navigate(`${PreparePath}/dashboard/plan`);
    }
  }, [workspaceType]);
  const getAllStatusBoard = async () => {
    dispatch(startLoading());
    try {
      const statusBoards = await Network.get(API.planStatusBoardList, {
        workspaceId: selectedWorkspace,
      });

      const boardListData = statusBoards.data.response.dataset.map((e) => {
        return {
          value: e.boardId,
          label: e.title,
        };
      });
      setSelectedBoard(
        boardListData[Math.floor(Math.random() * boardListData.length)]
      );
      setBoards(boardListData);
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(endLoading());
    }
  };

  const getAllEvents = async () => {
    dispatch(startLoading());
    try {
      const events = await Network.get(API.fetchEvents, {
        workspaceId: selectedWorkspace,
      });
      setEvents([
        { value: 'all', label: 'All Scenarios' },
        ...events.data.response.dataset.map((e) => {
          return {
            value: e.id,
            label: e.name,
          };
        }),
      ]);
      setSelectedEvent({ value: 'all', label: 'All Scenarios' });
    } catch (error) {
      dispatch(endLoading());
    }
  };

  const onViewInject = (id) => {
    setViewId(id);
    setEditMSELTimeline(true);
  };

  const onCloseViewInjectModal = () => {
    setViewId();
    setEditMSELTimeline(false);
  };

  const getAllBoardInject = async () => {
    dispatch(startLoading());
    try {
      const allInjects = await Network.get(API.fetchAllBoardInject, {
        workspaceId: selectedWorkspace,
        boardId: selectedBoard.value,
        eventId: selectedEvent.value,
        search: searchTerm,
      });
      let total = 0;
      const boardData = allInjects.data.response.dataset[0].map((board) => {
        const item = allInjects.data.response.dataset[2][board.id];
        total += item.length;
        return {
          ...board,
          items: item,
        };
      });
      setTotalMselInject(total);
      setBoardInjects(boardData);
    } catch (error) {
      console.log(error);
    } finally {
      // dispatch(endInjectRefresh());
      dispatch(endLoading());
    }
  };
  const createHandler = () => {
    setShowCreateMSELInject(true);
  };

  const onCloseModal = () => {
    setShowCreateMSELInject(false);
  };
  useEffect(() => {
    if (selectedWorkspace) {
      dispatch(startLoading());
      setSelectedBoard();
      setSelectedEvent();
      getAllEvents();
      getAllStatusBoard();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedWorkspace]);

  useEffect(() => {
    if (!!selectedBoard && !!selectedEvent) {
      getAllBoardInject();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm, selectedBoard, selectedEvent]);

  return (
    <>
      {newUI ? (
        <PageTitle
          title="MSEL Board"
          parents={['Exercise Management', 'MSEL']}
        />
      ) : (
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <StylishNewButton onClick={() => navigate(-1)}>
                <SharedIcon iconName="keyboard_arrow_left" classes="me-2" />
                Back
              </StylishNewButton>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              MSEL Board
            </li>
          </ol>
        </nav>
      )}

      <div className="plan-msel">
        <div className="d-flex flex-column flex-md-row align-items-center mb-4">
          <StylishNewButton
            className="button button--primary w-100 w-md-auto mb-3 mb-md-0"
            onClick={createHandler}
          >
            Create MSEL Inject
          </StylishNewButton>
          <div className="button-group toggle ms-md-auto me-md-3 mb-3 mb-md-0 w-100 w-md-auto">
            {!newUI && (
              <>
                <NavLink
                  className="button--icon lrg flex-grow-1"
                  to={`${PreparePath}/dashboard/plan/prepare/msel-list`}
                >
                  <i className="fa fa-bars" aria-hidden="true"></i>
                </NavLink>
                <NavLink
                  className="button--icon lrg flex-grow-1"
                  to={`${PreparePath}/dashboard/plan/prepare/msel-board`}
                >
                  <i className="fa fa-th-large" aria-hidden="true"></i>
                </NavLink>
                <NavLink
                  className="button--icon lrg flex-grow-1"
                  to={`${PreparePath}/dashboard/plan/prepare/msel-calendar`}
                >
                  <i className="fa fa-calendar" aria-hidden="true"></i>
                </NavLink>
              </>
            )}
          </div>

          <SearchBar onChangeSearchTerm={(term) => setSearchTerm(term)} />
          <StylishHelp
            classes={'ms-3 flex-shrink-0 sml'}
            title={HelpPlanInjects.title}
            content={HelpPlanInjects.content}
            link={selfHostedKnowledgeBase || HelpPlanInjects.link}
          />
        </div>
        <div className="form-block">
          <div className="d-md-flex align-items-center mb-3">
            <p className="m-0 me-3 mb-3 mb-md-0 weight-600">
              {totalMselInject} MESL Injects
            </p>
            <div className="d-md-flex ms-auto">
              <div className="mb-3 mb-md-0">
                <StylishNewSelect
                  value={selectedEvent}
                  onChange={(e) => setSelectedEvent(e)}
                  options={events}
                  placeholder={'All Scenarios'}
                  isClearable={false}
                  isSearchable={false}
                  isMulti={false}
                  isDisabled={false}
                />
              </div>
            </div>
          </div>
          {boardInjects.length ? (
            <StylishCarousel
              className="carousel__msel-board"
              dots={false}
              arrows={true}
              infinite={false}
              slidesToShow="4"
              slidesToShowLG="3"
              slidesToShowMD="2"
              slidesToShowSM="1"
            >
              {boardInjects.map((item) => (
                <MSELBoardContainer
                  key={item.id}
                  item={item}
                  boardInjects={boardInjects}
                  setBoardInjects={setBoardInjects}
                  onViewInject={(id) => onViewInject(id)}
                />
              ))}
            </StylishCarousel>
          ) : null}
        </div>
      </div>
      {showCreateMSELInject && (
        <MSELCreate show={showCreateMSELInject} onClose={onCloseModal} />
      )}
      {editMSELTimeline && (
        <EditMSELTimeline
          show={editMSELTimeline}
          onClose={onCloseViewInjectModal}
          viewId={viewId}
          onStatusUpdate={getAllBoardInject}
        />
      )}
    </>
  );
};
