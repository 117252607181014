import { useNavigate } from 'react-router';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { PreparePath } from 'utils/sharedUtils/sharedConst';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import moment from 'moment';
import { useSelector } from 'react-redux';

export const ViewFeedbackHseep = ({ data }) => {
  const navigate = useNavigate();
  const reduxUser = useSelector((state) => state.prepare.user);

  const pdfHeaders = [
    {
      identifier: 'initials',
      data: [
        {
          date: 'Date',
          participant_plaform: 'Participant Plaform',
          exercise_objective: 'Exercise Objective',
          requested_method: 'Requested Method',
          traning_method: 'Traning Method',
          associated_inject: 'Associated Inject',
        },
      ],
    },
    {
      identifier: 'source',
      data: [
        {
          source: 'Source',
          reference: 'Reference',
          title_or_description: 'Title or Description',
          level_or_competency: 'Level or Competency',
          injects: 'Injects',
        },
      ],
    },
    {
      identifier: 'behavior_or_critical_task',
      data: [
        {
          behavior_or_critical_task: 'Behavior or Critical Task',
          standard: 'Standard',
          condition: 'Condition',
          measurement_or_target_rating: 'Measurement/ Target Rating',
        },
      ],
    },
    {
      identifier: 'trainingObjectives',
      data: [
        {
          objective_completed: 'Objective Completed?',
          final_rating: 'Final Rating',
          reason: 'Reason',
        },
      ],
    },
    {
      identifier: 'comment',
      data: [
        {
          comment: 'Comment',
        },
      ],
    },
    {
      identifier: 'metrics',
      data: [
        {
          metric: 'Metric',
          number_completed: 'Number Completed',
        },
      ],
    },
    {
      identifier: 'leasonLearned',
      data: [
        {
          potential_opr: 'Potential OPR',
          classification: 'Classification',
          title: 'Title',
          observation: 'Observation',
          discussion: 'Discussion',
          recommendation: 'Recommendation',
          implications: 'Implications',
          event_description: 'Event Description',
          comments: 'Comments',
        },
      ],
    },
  ];

  const generatePDF = () => {
    jsPDF.autoTableSetDefaults({
      headStyles: { fillColor: '#0099FF' },
    });

    const injectsData = data?.injects
      .map((i) => {
        return `#${i?.number} - ${i?.title} - ${moment(i?.responseDate).format(
          'DD MMM yy - hh:mm'
        )}`;
      })
      .join('\n\u2022 ');

    var doc = new jsPDF({ orientation: 'landscape' });

    doc.text('Feedback Details', 7, 15);

    doc.autoTable({
      startY: 25,
      head: pdfHeaders.find((i) => i.identifier === 'initials').data,
      body: [
        {
          date: moment(data?.createdAt).isValid()
            ? `${moment(data?.createdAt).format('Do MMM YYYY - HH:MM')} (
                ${moment(data?.createdAt).tz(moment.tz.guess()).format('Z')})`
            : 'N/A',
          participant_plaform:
            data?.participantName || data?.platformTitle || data?.platformType
              ? `${data?.participantName || ''} ${
                  data?.platformTitle ? `- ${data?.platformTitle}` : ''
                } ${data?.platformType ? `: ${data?.platformType}` : ''}`
              : 'N/A',
          exercise_objective: data?.exerciseObjective || 'N/A',
          requested_method: data?.requestedMethod || 'N/A',
          traning_method: data?.trainedMethodType || 'N/A',
          associated_inject: data?.associatedInjectCount || 'N/A',
        },
      ],
      showHead: 'firstPage',
      margin: { horizontal: 7 },
      pageBreak: 'avoid',
    });

    doc.autoTable({
      startY: doc.autoTable.previous.finalY + 10,
      head: pdfHeaders.find((i) => i.identifier === 'source').data,
      body: [
        {
          source: data?.sourcetitle || 'N/A',
          reference: data?.para || 'N/A',
          title_or_description: data?.description || 'N/A',
          level_or_competency: data?.competence || 'N/A',
          injects: injectsData ? `\u2022 ${injectsData}` : 'N/A',
        },
      ],
      showHead: 'firstPage',
      margin: { horizontal: 7 },
      pageBreak: 'avoid',
    });

    doc.autoTable({
      startY: doc.autoTable.previous.finalY + 10,
      head: pdfHeaders.find((i) => i.identifier === 'trainingObjectives').data,
      body: [
        {
          objective_completed: data?.complete ? 'Yes' : 'No',
          final_rating: data?.evalstatus || 'N/A',
          reason: data?.reason || 'N/A',
        },
      ],
      showHead: 'firstPage',
      margin: { horizontal: 7 },
      pageBreak: 'avoid',
    });

    doc.autoTable({
      startY: doc.autoTable.previous.finalY + 10,
      head: pdfHeaders.find((i) => i.identifier === 'behavior_or_critical_task')
        .data,
      body:
        (data?.task &&
          !!data?.task?.length &&
          data?.task.map((i) => ({
            behavior_or_critical_task: i?.task || 'N/A',
            standard: i?.measure || 'N/A',
            condition: i?.code || 'N/A',
            measurement_or_target_rating: i?.rating || 'N/A',
          }))) ||
        pdfHeaders
          .find((i) => i.identifier === 'behavior_or_critical_task')
          .data.map((i) => ({
            behavior_or_critical_task: 'N/A',
            standard: 'N/A',
            condition: 'N/A',
            measurement_or_target_rating: 'N/A',
          })),
      showHead: 'firstPage',
      margin: { horizontal: 7 },
      pageBreak: 'avoid',
    });

    doc.autoTable({
      startY: doc.autoTable.previous.finalY + 10,
      head: pdfHeaders.find((i) => i.identifier === 'comment').data,
      body: [
        {
          comment: data?.evalcomment || 'N/A',
        },
      ],
      showHead: 'firstPage',
      margin: { horizontal: 7 },
      pageBreak: 'avoid',
    });

    doc.autoTable({
      startY: doc.autoTable.previous.finalY + 10,
      head: pdfHeaders.find((i) => i.identifier === 'metrics').data,
      body:
        (data?.metrics &&
          !!data?.metrics?.length &&
          data?.metrics.map((i) => ({
            metric: i?.metricsTitle || 'N/A',
            number_completed: i?.feedbackData || 'N/A',
          }))) ||
        pdfHeaders
          .find((i) => i.identifier === 'metrics')
          .data.map((i) => ({
            metric: 'N/A',
            number_completed: 'N/A',
          })),
      showHead: 'firstPage',
      margin: { horizontal: 7 },
      pageBreak: 'avoid',
    });

    doc.autoTable({
      startY: doc.autoTable.previous.finalY + 10,
      head: pdfHeaders.find((i) => i.identifier === 'leasonLearned').data,
      body:
        (data?.lessionlearned &&
          !!data?.lessionlearned?.length &&
          data?.lessionlearned.map((i) => ({
            potential_opr: i?.potentialOPR || 'N/A',
            classification: i?.classification || 'N/A',
            title: i?.title || 'N/A',
            observation: i?.observation || 'N/A',
            discussion: i?.discussion || 'N/A',
            recommendation: i?.recommendation || 'N/A',
            implications: i?.implications || 'N/A',
            event_description: i?.eventDescription || 'N/A',
            comments: i?.comments || 'N/A',
          }))) ||
        pdfHeaders
          .find((i) => i.identifier === 'leasonLearned')
          .data.map((i) => ({
            potential_opr: 'N/A',
            classification: 'N/A',
            title: 'N/A',
            observation: 'N/A',
            discussion: 'N/A',
            recommendation: 'N/A',
            implications: 'N/A',
            event_description: 'N/A',
            comments: 'N/A',
          })),
      showHead: 'firstPage',
      margin: { horizontal: 7 },
      pageBreak: 'avoid',
    });

    doc.save(
      `Feedback-HSEEP-${moment().format(
        reduxUser?.timeFormat === 'hour12Mode'
          ? 'YYYY_MM_DD_hh_mm a'
          : 'YYYY_MM_DD_HH_mm'
      )}.pdf`
    );
  };

  return (
    <>
      <div className="form-block">
        <div className="row">
          <div className="col-md-6 mb-4">
            <label className="form-label">Date</label>
            <p className="m-0">
              {data?.createdAt ? (
                <>
                  {moment(data?.createdAt).format('Do MMM YYYY - HH:MM')} (
                  {moment(data?.createdAt).tz(moment.tz.guess()).format('Z')})
                </>
              ) : (
                '-'
              )}
            </p>
          </div>
          <div className="col-md-6 mb-4">
            <label className="form-label">Participant Plaform</label>
            <p className="m-0">
              {data?.participantName ||
              data?.platformTitle ||
              data?.platformType ? (
                <>
                  {data?.participantName ? (
                    <span>{data?.participantName} - </span>
                  ) : null}
                  {data?.platformTitle ? (
                    <span>{data?.platformTitle} : </span>
                  ) : null}
                  {data?.platformType ? (
                    <span>{data?.platformType}</span>
                  ) : null}
                </>
              ) : (
                '-'
              )}
            </p>
          </div>
          <div className="col-md-6 mb-4">
            <label className="form-label">Exercise Objective</label>
            <p className="m-0">
              {data?.exerciseObjective ? <>{data?.exerciseObjective}</> : '-'}
            </p>
          </div>
          <div className="col-md-6 mb-4">
            <label className="form-label">Request Method</label>
            <p className="m-0">
              {data?.requestedMethod ? <>{data?.requestedMethod}</> : '-'}
            </p>
          </div>
          <div className="col-md-6 mb-4 mb-md-0">
            <label className="form-label">Traning Method</label>
            <p className="m-0">
              {data?.trainedMethodType ? <>{data?.trainedMethodType}</> : '-'}
            </p>
          </div>
          <div className="col-md-6">
            <label className="form-label">Associated Injects</label>
            <p className="m-0">
              {data?.associatedInjectCount ? (
                <>{data?.associatedInjectCount}</>
              ) : (
                '-'
              )}
            </p>
          </div>
        </div>
        <hr className="dashed" />
        <div className="row">
          <div className="col-md-6 mb-4">
            <label className="form-label">Source</label>
            <p className="m-0">
              {data?.sourcetitle ? <>{data?.sourcetitle}</> : '-'}
            </p>
          </div>
          <div className="col-md-6 mb-4">
            <label className="form-label">Reference</label>
            <p className="m-0">{data?.para ? <>{data?.para}</> : '-'}</p>
          </div>
          <div className="col-md-6 mb-4">
            <label className="form-label">Title or Description</label>
            <p className="m-0">
              {data?.description ? <>{data?.description}</> : '-'}
            </p>
          </div>
          <div className="col-md-6 mb-4">
            <label className="form-label">Level or Competency</label>
            <p className="m-0">
              {data?.competence ? <>{data?.competence}</> : '-'}
            </p>
          </div>

          <div className="col-md-12">
            <label className="form-label">Injects</label>
            <p className="m-0">
              {data?.injects && !!data?.injects?.length ? (
                <>
                  {data?.injects?.map((inject, idx) => (
                    <span className="d-block" key={idx}>
                      #{inject?.number} - {inject?.title} -{' '}
                      {moment(inject?.startDate).format('DD MMM yy - hh:mm')}
                    </span>
                  ))}
                </>
              ) : (
                '-'
              )}
            </p>
          </div>
        </div>
        <hr className="dashed" />
        <div className="row">
          <div className="col-md-6 mb-4">
            <label className="form-label">
              Was this exercise objective completed?
            </label>
            <p className="m-0">{data?.complete ? 'Yes' : 'No'}</p>
          </div>
          <div className="col-md-6 mb-4">
            <label className="form-label d-block">
              What was the final rating?
            </label>
            <p className="m-0">
              {data?.evalstatus ? (
                <span
                  className="status status--icon weight-700 m-0"
                  style={{
                    background:
                      data?.evalstatus === 'P'
                        ? '#2ECC70'
                        : data?.evalstatus === 'S'
                        ? '#CC9A06'
                        : data?.evalstatus === 'M'
                        ? '#FD7E14'
                        : data?.evalstatus === 'U'
                        ? '#0AA2C0'
                        : null,
                  }}
                >
                  {data?.evalstatus}
                </span>
              ) : (
                '-'
              )}
            </p>
          </div>
          <div className="col-md-6">
            <label className="form-label">
              Why was the objective not effective (For S,M,U)?
            </label>
            <p className="m-0">{data?.reason ? <>{data?.reason}</> : '-'}</p>
          </div>
        </div>

        {data?.task && !!data?.task?.length ? (
          <>
            {data?.task.map((i, idx) => (
              <div key={idx}>
                <hr className="dashed" />
                <div className="row">
                  <div className="col-md-6 mb-4">
                    <label className="form-label">
                      Behavior or Critical Task
                    </label>
                    <p className="m-0">{i?.task ? <>{i?.task}</> : '-'}</p>
                  </div>
                  <div className="col-md-6 mb-4">
                    <label className="form-label">Standard</label>
                    <p className="m-0">
                      {i?.measure ? <>{i?.measure}</> : '-'}
                    </p>
                  </div>
                  <div className="col-md-6 mb-4 mb-md-0">
                    <label className="form-label">Condition</label>
                    <p className="m-0">{i?.code ? <>{i?.code}</> : '-'}</p>
                  </div>
                  <div className="col-md-6">
                    <label className="form-label">
                      Measurement/ Target Rating
                    </label>
                    <p className="m-0">{i?.rating ? <>{i?.rating}</> : '-'}</p>
                  </div>
                </div>
              </div>
            ))}
          </>
        ) : null}

        <hr className="dashed" />
        <div className="row">
          <div className="col-md-12">
            <label className="form-label">Comment</label>
            <p className="m-0">
              {data?.evalcomment ? <>{data?.evalcomment}</> : '-'}
            </p>
          </div>
        </div>
      </div>
      <div className="form-block mt-4">
        <h4>Metrics</h4>
        {data?.metrics && !!data?.metrics?.length ? (
          <>
            {data.metrics.map((i, idx) => (
              <div className="row" key={idx}>
                {idx !== 0 ? (
                  <div className="col-md-12">
                    <hr className="dashed" />
                  </div>
                ) : null}
                <div className="col-md-6">
                  <label className="form-label">Metric</label>
                  <p className="m-0">
                    {i?.metricsTitle ? <>{i?.metricsTitle}</> : '-'}
                  </p>
                </div>
                <div className="col-md-6">
                  <label className="form-label">Number Completed</label>
                  <p className="m-0">
                    {i?.feedbackData ? <>{i?.feedbackData}</> : '-'}
                  </p>
                </div>
              </div>
            ))}
          </>
        ) : (
          <p className="m-0">No Metrics Added</p>
        )}
      </div>
      <div className="form-block mt-4">
        <h4>Lessons Learned</h4>
        {data?.lessionlearned && !!data?.lessionlearned?.length ? (
          <>
            {data.lessionlearned.map((i, idx) => (
              <div className="row" key={idx}>
                {idx !== 0 ? (
                  <div className="col-md-12">
                    <hr className="dashed" />
                  </div>
                ) : null}
                <div className="col-md-6 mb-4">
                  <label className="form-label">Potential OPR</label>
                  <p className="m-0">
                    {i?.potentialOPR ? <>{i?.potentialOPR}</> : '-'}
                  </p>
                </div>
                <div className="col-md-6 mb-4">
                  <label className="form-label">Classification</label>
                  <p className="m-0">
                    {i?.classification ? <>{i?.classification}</> : '-'}
                  </p>
                </div>
                <div className="col-md-12 mb-4">
                  <label className="form-label">Title</label>
                  <p className="m-0">{i?.title ? <>{i?.title}</> : '-'}</p>
                </div>
                <div className="col-md-6 mb-4">
                  <label className="form-label">Observation</label>
                  <p className="m-0">
                    {i?.observation ? <>{i?.observation}</> : '-'}
                  </p>
                </div>
                <div className="col-md-6 mb-4">
                  <label className="form-label">Discussion</label>
                  <p className="m-0">
                    {i?.discussion ? <>{i?.discussion}</> : '-'}
                  </p>
                </div>
                <div className="col-md-6 mb-4">
                  <label className="form-label">Recommendation</label>
                  <p className="m-0">
                    {i?.recommendation ? <>{i?.recommendation}</> : '-'}
                  </p>
                </div>
                <div className="col-md-6 mb-4">
                  <label className="form-label">Implications</label>
                  <p className="m-0">
                    {i?.implications ? <>{i?.implications}</> : '-'}
                  </p>
                </div>
                <div className="col-md-6 mb-4 mb-md-0">
                  <label className="form-label">Event Description</label>
                  <p className="m-0">
                    {i?.eventDescription ? <>{i?.eventDescription}</> : '-'}
                  </p>
                </div>
                <div className="col-md-6">
                  <label className="form-label">Comments</label>
                  <p className="m-0">
                    {i?.comments ? <>{i?.comments}</> : '-'}
                  </p>
                </div>
              </div>
            ))}
          </>
        ) : (
          <p className="m-0">No Lessons Learned Added</p>
        )}
      </div>
      <div className="button-group mt-4">
        <StylishNewButton
          className={'button--secondary button--reverse ms-auto'}
          onClick={() => navigate(`${PreparePath}/dashboard/plan/feedback`)}
        >
          Back
        </StylishNewButton>
        <StylishNewButton className={'button--primary'} onClick={generatePDF}>
          Download PDF
        </StylishNewButton>
      </div>
    </>
  );
};
