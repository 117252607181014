import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { MdLocationOn, MdOutlineWarning } from 'react-icons/md';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import * as turf from '@turf/turf';
import moment from 'moment';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import Accordion from 'react-bootstrap/Accordion';
import config from 'constants/apiConfig';

import { StylishSwitcher } from 'components/DesignSystems/New/StylishSwitcher';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import StylishNewInput from 'components/DesignSystems/New/StylishNewInput.js';

import IconClose from '../../../assets/images/icon__times.svg';
import IconArrowLeft from '../../../assets/images/icon__arrow--left.svg';
import IconArrowRight from '../../../assets/images/icon__arrow--right.svg';

import {
  eventHazardsCategory,
  additionalWildFiresHazard,
} from '../constants/eventHazards.js';
import { productionEnv } from 'constants/debug';

import EventDetails from '../mapComponents/EventDetails.js';
import { HiOutlineExternalLink } from 'react-icons/hi';
import queryBingNewsEffect from '../mapEffects/queryBingNewsEffect';
import mapEventsEffect from '../mapEffects/mapEventsEffect';
import EventAIAnalysis from '../mapComponents/EventAIAnalysis';
import EventIntersectPowerOutage from '../mapComponents/EventIntersectPowerOutage';
import { setEventToggles } from 'slices/mapSlice';

dayjs.extend(utc);

const MapEvents = (props) => {
  const {
    map,
    mapSettings,
    mapHasLoaded,
    apiPrefix,
    mapboxToken,
    sidebarSecondary,
    sidebarActiveItem,
    selectedEvent,
    setSelectedEvent,
    selectedEventTabNews,
    setSelectedEventTabNews,
    eventsVisibleToggle,
    setEventsVisibleToggle,
    eventSelected,
    setLayerClicked,
    ethelData,
    onClose,
    earthquakesData,
    poweroutageData,
    toggleDGPTOpen,
  } = props;

  const dispatch = useDispatch();

  const reduxFeatureFlags = useSelector((state) => {
    return state.app.featureFlags;
  });

  const eventToggles = useSelector((state) => {
    return state.map.eventToggles;
  });

  // Constant variables
  const sortedEventHazardsCategory = eventHazardsCategory.sort((a, b) =>
    a.hazard_data_category.localeCompare(b.hazard_data_category)
  );
  const selectedDatetime = mapSettings?.selectedDatetime;
  const authHeader = `Bearer ${sessionStorage['accessToken']}`;
  const bingNewsKey =
    window.env.BING_NEWS_API_KEY || process.env.REACT_APP_BING_NEWS_API_KEY;
  const polygon_events_layer_id = 'polygon_events_layer_id';

  const hurricane_cones_layer_id = 'public.hurricane_cones_layer_id';
  const hurricane_points_layer_id = 'public.hurricane_points_layer_id';
  const hurricane_paths_layer_id = 'public.hurricane_paths_layer_id';

  const earthquakes_clusters_layer_id = 'earthquakes_clusters_layer_id';
  const earthquakes_cluster_count_layer_id =
    'earthquakes_cluster_count_layer_id';
  const earthquakes_unclustered_point_layer_id =
    'earthquakes_unclustered_point_layer_id';

  // State Declarations
  const [showSidebarSecondary, setShowSidebarSecondary] = useState(false);
  const [sidebarSecondaryActiveItem, setSidebarSecondaryActiveItem] = useState(
    null
  );

  const [eventSearchInput, setEventSearchInput] = useState('');
  const [eventLocationInfo, setEventLocationInfo] = useState();

  const [key, setKey] = useState('info');

  // functions
  const { filteredEthelData, groupedEthelDataByCategory } = useMemo(() => {
    const eventData = [...(ethelData || [])];
    let filteredEthelData = [];
    if (!!eventData && !!eventData.length) {
      filteredEthelData = eventData
        .filter(
          (d) =>
            !!sortedEventHazardsCategory.find(
              (s) => s.hazard_data_category === d.hazard_data_category
            )
        )
        .filter((d) =>
          d.hazard_name.toLowerCase().includes(eventSearchInput.toLowerCase())
        )
        .filter(
          (d) =>
            (!!selectedEvent &&
              selectedEvent.instance_id === d.instance_id &&
              true) ||
            (!selectedEvent && true) ||
            false
        );
    }

    // grouped events
    let groupedEthelData = filteredEthelData.reduce((groups, event) => {
      let category = event?.hazard_data_category;
      if (!groups[category]) {
        groups[category] = [];
      }

      groups[category].push(event);

      // add some events from NWS to Hurricane
      if (
        category !== 'Hurricane' &&
        event?.hazard_name.includes('Hurricane')
      ) {
        if (!groups['Hurricane']) groups['Hurricane'] = [];
        groups['Hurricane'].push(event);
      }
      // add some events from NWS to Wild Fires
      if (
        category === 'NWS alerts' &&
        additionalWildFiresHazard.filter(
          (w) => w.hazard_name === event?.hazard_name
        )?.length > 0
      ) {
        if (!groups['Wild Fires']) groups['Wild Fires'] = [];
        groups['Wild Fires'].push(event);
      }
      return groups;
    }, {});

    const groupedEthelDataByCategory = Object.keys(groupedEthelData)
      .sort()
      .reduce((acc, key) => {
        acc[key] = groupedEthelData[key].sort((a, b) =>
          a.hazard_name.localeCompare(b.hazard_name)
        );
        return acc;
      }, {});

    return { filteredEthelData, groupedEthelDataByCategory };
  }, [
    ethelData,
    poweroutageData,
    selectedEvent,
    eventSearchInput,
    eventToggles,
  ]);

  const handleClickClose = () => {
    onClose();
  };

  const sidebarSecondaryHandler = (show, key) => {
    setShowSidebarSecondary(show);
    setSidebarSecondaryActiveItem(key);
  };

  const eventTypeToggled = (eventType) => {
    if (
      eventToggles.some(
        (t) => t.hazard_data_category === eventType.hazard_data_category
      )
    ) {
      dispatch(
        setEventToggles(
          eventToggles.filter(
            (t) => t.hazard_data_category !== eventType.hazard_data_category
          )
        )
      );
    } else {
      dispatch(
        setEventToggles([
          ...eventToggles,
          { hazard_data_category: eventType.hazard_data_category },
        ])
      );
    }
  };

  const flyToEventFeature = (event) => {

    if (!event.bounds) return;

    var box = turf.bbox(JSON.parse(event.bounds));
    map.current.fitBounds(box, { padding: 50, maxZoom: 15 });
  };

  // useEffect for side effect
  useEffect(() => {
    setShowSidebarSecondary(sidebarSecondary.show);
    setSidebarSecondaryActiveItem(sidebarSecondary.key);
  }, [sidebarSecondary]);

  useEffect(() => {
    queryBingNewsEffect(
      selectedEvent,
      selectedEventTabNews,
      setSelectedEventTabNews,
      eventLocationInfo,
      bingNewsKey,
      key
    );
  }, [selectedEvent, eventLocationInfo, bingNewsKey, key]);

  useEffect(() => {
    if (mapHasLoaded && reduxFeatureFlags && !reduxFeatureFlags.includes("SUPPRESS_EVENT_TILES")) {
      mapEventsEffect(
        map,
        mapHasLoaded,
        ethelData,
        eventsVisibleToggle,
        selectedDatetime,
        setLayerClicked,
        apiPrefix,
        earthquakesData,
        poweroutageData,
        reduxFeatureFlags.includes('POWER_OUTAGES'),
        eventToggles,
        reduxFeatureFlags,
      );
    }
  }, [map, eventsVisibleToggle, mapHasLoaded, earthquakesData, eventToggles, reduxFeatureFlags]);

  useEffect(() => {
    let centroid;
    let placeUrl;

    if (!!selectedEvent && selectedEvent.hazard_data_category === 'Hurricane') {
      const boundsjson = fetch(
        config.apiPrefix +
          '/dice/map/hurricaneBounds?storm_id=' +
          selectedEvent.storm_id,
        {
          headers: {
            Authorization: authHeader,
            'x-teamsapp': sessionStorage['isUsingTeams'] === 'true',
          },
        }
      )
        .then(async (json) => await json.json())
        .then((bounds) => {
          centroid = turf.centroid(bounds).geometry;
          placeUrl = `https://api.mapbox.com/geocoding/v5/mapbox.places/${centroid[0]},${centroid[1]}.json?access_token=${mapboxToken}`;

          try {
            fetch(placeUrl)
              .then((res) => res.json())
              .then((response) => {
                const place = response.features.find((f) =>
                  f.id.includes('place')
                );
                const placeName =
                  (!!place && place.place_name) || 'Click to Zoom';
                const country = response.features.find((f) =>
                  f.id.includes('country')
                );
                const cc =
                  (!!country &&
                    !!country.properties &&
                    !!country.properties.short_code &&
                    country.properties.short_code.toUpperCase()) ||
                  'US';
                setEventLocationInfo({
                  place: place,
                  placeName: placeName,
                  country: country,
                  cc: cc,
                });
              });
          } catch (e) {
            setEventLocationInfo();
          }
        });
    } else if (!!selectedEvent?.bounds) {
      centroid = JSON.parse(selectedEvent?.bounds);
      placeUrl = `https://api.mapbox.com/geocoding/v5/mapbox.places/${centroid[0]},${centroid[1]}.json?access_token=${mapboxToken}`;
      try {
        if (selectedEvent?.bounds.type !== 'Point') {
          centroid = turf.centroid(centroid);
          placeUrl = `https://api.mapbox.com/geocoding/v5/mapbox.places/${centroid.geometry.coordinates[0]},${centroid.geometry.coordinates[1]}.json?access_token=${mapboxToken}`;
        }

        fetch(placeUrl)
          .then((res) => res.json())
          .then((response) => {
            const place = response.features.find((f) => f.id.includes('place'));
            const placeName = (!!place && place.place_name) || 'Click to Zoom';
            const country = response.features.find((f) =>
              f.id.includes('country')
            );
            const cc =
              (!!country &&
                !!country.properties &&
                !!country.properties.short_code &&
                country.properties.short_code.toUpperCase()) ||
              'US';

            const locationInfo = {
              place: place,
              placeName: placeName,
              country: country,
              cc: cc,
              centroid: centroid
            }
            setEventLocationInfo(locationInfo);
          });
      } catch (e) {
        console.warn("ERROR No event location found:",e,selectedEvent)
        setEventLocationInfo();
      }
    } else {
      console.warn("No event location found:",selectedEvent)
      setEventLocationInfo();
    }
  }, [selectedEvent, mapboxToken]);

  useEffect(() => {
    if (!mapHasLoaded) return;
    if (!!map.current.getLayer(polygon_events_layer_id)) {
      map.current.setFilter(polygon_events_layer_id, [
        'in',
        'hazard_data_category',
        ...eventToggles.map((t) => t.hazard_data_category),
      ]);
    }

    // Earthquakes
    const earthquakesEvent = eventToggles.find(
      (t) => t.hazard_data_category === 'Earthquakes'
    );
    const earthquakesClusterFilter = earthquakesEvent
      ? ['has', 'point_count']
      : ['has', 'point_count1'];
    const earthquakesUnclusterFilter = earthquakesEvent
      ? ['!', ['has', 'point_count']]
      : ['has', 'point_count1'];
    if (!!map.current.getLayer(earthquakes_clusters_layer_id)) {
      map.current.setFilter(
        earthquakes_clusters_layer_id,
        earthquakesClusterFilter
      );
    }
    if (!!map.current.getLayer(earthquakes_cluster_count_layer_id)) {
      map.current.setFilter(
        earthquakes_cluster_count_layer_id,
        earthquakesClusterFilter
      );
    }
    if (!!map.current.getLayer(earthquakes_unclustered_point_layer_id)) {
      map.current.setFilter(
        earthquakes_unclustered_point_layer_id,
        earthquakesUnclusterFilter
      );
    }

    // hurricane
    const hurricaneEvent = eventToggles.find(
      (t) => t.hazard_data_category === 'Hurricane'
    );
    const hurricaneFilter = hurricaneEvent
      ? ['!', ['has', 'hazard_data_category']]
      : ['has', 'hazard_data_category'];
    if (!!map.current.getLayer(hurricane_cones_layer_id)) {
      map.current.setFilter(hurricane_cones_layer_id, hurricaneFilter);
    }
    if (!!map.current.getLayer(hurricane_paths_layer_id)) {
      map.current.setFilter(hurricane_paths_layer_id, hurricaneFilter);
    }
    if (!!map.current.getLayer(hurricane_points_layer_id)) {
      map.current.setFilter(hurricane_points_layer_id, hurricaneFilter);
    }
  }, [mapHasLoaded, eventToggles]);

  const { placeName, lastObservedSelected } = useMemo(() => {
    let placeName;
    let lastObservedSelected;
    if (!!selectedEvent) {
      if (!!eventLocationInfo && !!eventLocationInfo.placeName) {
        placeName = eventLocationInfo.placeName;
      }

      if (selectedEvent.hazard_data_category === 'Hurricane') {
        lastObservedSelected = selectedEvent.hurricane_data_points.find(
          (p) => p.storm_data_category === 'Observed Points'
        );
        if (!lastObservedSelected)
          lastObservedSelected =
            selectedEvent.hurricane_data_points[
              selectedEvent.hurricane_data_points.length - 1
            ];
        placeName =
          lastObservedSelected.unified_storm_category +
          ' ' +
          lastObservedSelected.unified_storm_name;
      }
      if (selectedEvent.hazard_data_category === 'Earthquakes') {
        placeName = selectedEvent?.properties?.place;
      }
      if (selectedEvent.hazard_data_category === 'Power Outage') {
        placeName =
          (selectedEvent.political_boundaries_type === 'county' &&
            `${selectedEvent.county_name} County, ${selectedEvent.state_name}`) ||
          (selectedEvent.political_boundaries_type === 'state' &&
            selectedEvent.state_name);
      }
    }

    return { placeName, lastObservedSelected };
  }, [selectedEvent, eventLocationInfo]);

  return (
    <div className="sidebar-content-wide">
      <div className="sidebar-title">
        {showSidebarSecondary ? (
          <StylishNewButton
            customButton
            className={'button--icon'}
            onClick={() => {
              setShowSidebarSecondary(false);
              setSidebarSecondaryActiveItem(null);
              setSelectedEvent();
            }}
          >
            <img src={IconArrowLeft} alt="" />
          </StylishNewButton>
        ) : null}
        <h4 className="m-0">
          {(!!ethelData && !!ethelData.length && `${sidebarActiveItem}`) || (
            <>
              Events
              <span className="p--l ms-3">
                {!!eventsVisibleToggle && (
                  <i className="fa fa-spinner fa-pulse"></i>
                )}
              </span>
            </>
          )}
        </h4>
        <StylishNewButton
          customButton
          className={'button--icon'}
          onClick={() => handleClickClose()}
        >
          <img src={IconClose} alt="" />
        </StylishNewButton>
      </div>
      <div className="sidebar-inner">
        {!showSidebarSecondary ? (
          <>
            <div className="d-flex align-items-center mb-3">
              <span>Show Events</span>
              <StylishSwitcher
                className="ms-auto"
                checked={eventsVisibleToggle}
                onChange={() => setEventsVisibleToggle(!eventsVisibleToggle)}
              />
            </div>
            <div
              className={
                'd-flex align-items-center cursor-pointer bg-gray-900--light-5 p-3 rounded'
              }
              onClick={() =>
                sidebarSecondaryHandler(true, 'Active Event Types')
              }
            >
              {`Event Types`}
              <img src={IconArrowRight} alt="" className="ms-auto img-h-16" />
            </div>
            <hr className="dashed thin w-10 my-4" />
            <StylishNewInput
              placeholder="Search Events..."
              value={eventSearchInput}
              onChange={(e) => setEventSearchInput(e.target.value)}
              className={'mb-4'}
            />
            <div className="scroll scroll-events">
              {(!!ethelData &&
                !!ethelData.length &&
                (Object.keys(groupedEthelDataByCategory).length > 0 ? (
                  <Accordion
                    defaultActiveKey="0"
                    className="standard-accordion"
                  >
                    {Object.keys(groupedEthelDataByCategory).map(
                      (category, index) => {
                        if (
                          eventToggles.some(
                            (x) => x.hazard_data_category === category
                          )
                        ) {
                          let categoryName = category;
                          const categoryColor = eventHazardsCategory.find(
                            (eventCategory) =>
                              eventCategory?.hazard_data_category === category
                          )?.hazard_color;
                          if (categoryName === 'Wild Fires') {
                            categoryName = 'Wildfires';
                          }
                          if (categoryName === 'NWS alerts') {
                            categoryName = 'NWS Active Alerts';
                          }
                          return (
                            <Accordion.Item
                              eventKey={index}
                              key={'category-' + index}
                              className="mt-0"
                            >
                              <Accordion.Header className="border-b">
                                {categoryName} (
                                {groupedEthelDataByCategory[category].length})
                              </Accordion.Header>
                              <Accordion.Body>
                                {groupedEthelDataByCategory[category].map(
                                  (item, idx) => {
                                    let hazard_name = item.hazard_name;
                                    if (hazard_name === 'Hurricane') {
                                      let lastObservedItem = item.hurricane_data_points.find(
                                        (p) =>
                                          p.storm_data_category ===
                                          'Observed Points'
                                      );
                                      if (!lastObservedItem) {
                                        lastObservedItem =
                                          item.hurricane_data_points[
                                            item.hurricane_data_points.length -
                                              1
                                          ];
                                      }
                                      hazard_name =
                                        lastObservedItem.unified_storm_category +
                                        ' ' +
                                        lastObservedItem.unified_storm_name;
                                    } else if (hazard_name === 'Wild Fires') {
                                      hazard_name =
                                        '(Wildfires) ' +
                                        item.properties?.poly_IncidentName;
                                    } else if (hazard_name === 'Earthquakes') {
                                      hazard_name =
                                        '(Earthquakes) ' +
                                        item.properties?.place;
                                    }
                                    return (
                                      <div
                                        key={'filteredEth-' + idx}
                                        className={'events-' + idx}
                                      >
                                        {idx !== 0 ? (
                                          <hr
                                            key={idx}
                                            className="dashed thin w-10 m-0"
                                          />
                                        ) : null}
                                        <div
                                          className={`d-flex align-items-center cursor-pointer py-3`}
                                          key={item.instance_id}
                                          onClick={() => {
                                            eventSelected(item);
                                            sidebarSecondaryHandler(
                                              true,
                                              'Selected Event'
                                            );
                                          }}
                                        >
                                          <span
                                            style={{
                                              color: categoryColor || 'unset',
                                            }}
                                          >
                                            <MdOutlineWarning className="img-h-24 me-3" />
                                          </span>
                                          <span className="text-truncate">
                                            {hazard_name}
                                            {!!item?.properties?.senderName
                                              ? ` - ${item?.properties?.senderName}`
                                              : ''}
                                          </span>
                                          <img
                                            src={IconArrowRight}
                                            alt=""
                                            className="ms-auto img-h-16"
                                          />
                                        </div>
                                      </div>
                                    );
                                  }
                                )}
                              </Accordion.Body>
                            </Accordion.Item>
                          );
                        }
                      }
                    )}
                  </Accordion>
                ) : (
                  <p className="m-0">No Event Found</p>
                ))) ||
                (!eventsVisibleToggle && (
                  // eslint-disable-next-line react/no-unescaped-entities
                  <p className="m-0">Click "Show Events" to fetch Events.</p>
                )) || <p className="m-0">Loading Events...</p>}
            </div>
          </>
        ) : (
          <>
            {/* Show Active Event Types */}
            {sidebarSecondaryActiveItem === 'Active Event Types' &&
              sortedEventHazardsCategory
                .filter((item) =>
                  item.hazard_data_category === 'Power Outage'
                    ? reduxFeatureFlags.includes('POWER_OUTAGES')
                      ? item
                      : false
                    : item
                )
                .map((h, idx) => (
                  <div key={'event-type-' + idx}>
                    {idx !== 0 ? (
                      <hr key={idx} className="dashed w-10 thin m-0" />
                    ) : null}
                    <div
                      key={'hazard-' + h.id}
                      className={'d-flex align-items-center py-3'}
                    >
                      <span
                        style={{
                          color: h.hazard_color,
                        }}
                      >
                        <MdOutlineWarning className="img-h-24 me-3" />
                      </span>
                      <span className="text-truncate">
                        {h.hazard_data_category === 'Wild Fires'
                          ? 'Wildfires'
                          : h.hazard_data_category}
                      </span>
                      <StylishSwitcher
                        onChange={() => eventTypeToggled(h)}
                        checked={eventToggles.some(
                          (x) =>
                            x.hazard_data_category === h.hazard_data_category
                        )}
                        className={'ms-auto'}
                      />
                    </div>
                  </div>
                ))}
            {/* Selected Event */}
            {sidebarSecondaryActiveItem === 'Selected Event' ? (
              <div className="tab-wrapper map-tab-container">
                <Tabs activeKey={key} onSelect={(k) => setKey(k)}>
                  <Tab eventKey="info" title="Info">
                    <div
                      className="anchor anchor--white cursor-pointer d-flex align-items-center bg-gray-900 p-3 rounded mb-3"
                      onClick={() => flyToEventFeature(selectedEvent)}
                    >
                      <MdLocationOn className="img-h-20 me-3" />
                      {!!placeName && <>{placeName}</>}
                    </div>
                    <EventDetails event={selectedEvent} />
                  </Tab>
                  <Tab eventKey="news" title="News">
                    <div className="event-news-container">
                      {(!!selectedEventTabNews &&
                        selectedEventTabNews.map((n, idx) => {
                          return (
                            <>
                              {idx !== 0 ? (
                                <hr className="dashed my-3" />
                              ) : null}
                              <div key={idx + n.name}>
                                <h5 className="mb-1">{n.name} </h5>
                                <span className="d-block mb-1">
                                  <span className="weight-600">Category: </span>
                                  {n?.category}
                                </span>
                                {moment(n?.datePublished).isValid() && (
                                  <span className="d-block mb-1">
                                    <span className="weight-600">
                                      Published At:{' '}
                                    </span>
                                    {moment(n?.datePublished).format(
                                      'MM/DD/YYYY HH:mm'
                                    )}
                                  </span>
                                )}

                                <p className="mt-3">{n?.description}</p>
                                <div className="d-flex align-items-center mt-3">
                                  <span className="weight-600">
                                    {n?.provider[0]?.name}
                                  </span>
                                  <span
                                    className="cursor-pointer ms-auto"
                                    onClick={() => window.open(n.url, '_blank')}
                                  >
                                    <HiOutlineExternalLink className="img-h-24" />
                                  </span>
                                </div>
                              </div>
                            </>
                          );
                        })) || (
                        <div className="weight-600">Loading News...</div>
                      )}
                    </div>
                  </Tab>
                  {reduxFeatureFlags.includes('DISASTERGPT') &&
                    !!selectedEvent && (
                      <Tab eventKey="analysis" title="AutoSITREP">
                        <div
                          className="anchor anchor--white cursor-pointer d-flex align-items-center bg-gray-900 p-3 rounded mb-3"
                          onClick={() => flyToEventFeature(selectedEvent)}
                        ></div>
                        <EventAIAnalysis
                          event={selectedEvent}
                          locationInfo={eventLocationInfo||{}}
                          map={map}
                          toggleDGPTOpen={toggleDGPTOpen}
                        />
                      </Tab>
                    )}
                  {reduxFeatureFlags.includes('POWER_OUTAGES') &&
                    !!eventLocationInfo &&
                    !!selectedEvent && (
                      <Tab eventKey="analysis" title="Power Outage">
                        <EventIntersectPowerOutage
                          event={selectedEvent}
                          locationInfo={eventLocationInfo}
                          poweroutageData={poweroutageData}
                        />
                      </Tab>
                    )}
                </Tabs>
              </div>
            ) : null}
          </>
        )}
      </div>
    </div>
  );
};

export default MapEvents;
