import { StylishNewButton } from '../DesignSystems/New/StylishNewButton';
import { SharedIcon } from '../SharedIcon/SharedIcon';
import EntityList from './EntityList';
import { useToggle } from 'react-use';
import { Card } from 'react-bootstrap';
import { useState } from 'react';
import classNames from 'classnames';
import {
  IncidentHospital,
  useIncidentHospitals,
} from './hooks/incidentHospitalsHooks';
import HospitalAddDrawer from './HospitalAddDrawer';
import HospitalEditDrawer from './HospitalEditDrawer';

import {
  MdEdit,
} from 'react-icons/md';

const CollapsibleHospitalsList: React.FC = () => {
  const [expanded, toggleExpanded] = useToggle(false);
  const [editOpen, toggleEditDrawer] = useToggle(false);
  const [addOpen, toggleAddDrawer] = useToggle(false);
  const [
    selectedEntity,
    setSelectedEntity,
  ] = useState<IncidentHospital | null>(null);

  const { data: incidentHospitals } = useIncidentHospitals();

  const selectElement = (element: IncidentHospital) => {
    setSelectedEntity(element);
    toggleEditDrawer();
  };

  return (
    <>
      <EntityList
        entities={incidentHospitals}
        expanded={expanded}
        toggleExpanded={toggleExpanded}
        header={
          <div className="d-flex align-items-center justify-content-between flex-grow-1">
            <span>Hospitals</span>
            <StylishNewButton
              className={classNames('button', {
                'button--primary': expanded,
                'button--secondary': !expanded,
              })}
              onClick={toggleAddDrawer}
            >
              <SharedIcon iconName="add" classes="me-2" />
              Add
            </StylishNewButton>
          </div>
        }
        renderEntity={(element) => (
          <Card
            key={element.id}
            className="bg-neutral-800 my-1 rounded-1 flex-row align-items-center justify-content-between"
            style={{
              padding: '0.625rem 1rem 0.625rem 1rem',
            }}
          >
            <span>{element.hospital_name}</span>
            <StylishNewButton
              className="button button--link"
              onClick={() => selectElement(element)}
            >
              <MdEdit />
            </StylishNewButton>
          </Card>
        )}
      ></EntityList>
      {addOpen ? <HospitalAddDrawer toggle={toggleAddDrawer} /> : null}
      {editOpen && selectedEntity ? (
        <HospitalEditDrawer
          toggle={toggleEditDrawer}
          selectedEntity={selectedEntity}
        />
      ) : null}
    </>
  );
};

export default CollapsibleHospitalsList;
