import * as turf from '@turf/turf';
import { productionEnv } from 'constants/debug';
import config from 'constants/apiConfig';


export async function getEventCentroid(event) {
  const authHeader = `Bearer ${sessionStorage['accessToken']}`;

  if(event.hazard_name==="Hurricane") 
  {
    const boundsjson = await fetch(config.apiPrefix + '/dice/map/hurricaneBounds?storm_id='+event.storm_id,{
      headers: {
        'Authorization': authHeader,
        'x-teamsapp': sessionStorage['isUsingTeams'] === 'true'
    }})
    const hbounds = await boundsjson.json()
    const centroid = turf.centroid(hbounds).geometry
  	return centroid

  } else if (!event?.geojson && !event?.bounds) 
  {
    return;
  }
  else if (event?.geojson?.type === 'Point') 
  {
  	return event.geojson
  } else 
  {
    let bounds = event.bounds
    if(typeof bounds==='string')
    {
      bounds = JSON.parse(bounds)
    }
  	return turf.centroid(bounds).geometry
  }
}

export async function getForecastWeatherData(centroid)
{
  const owmToken = window.env.OPEN_WEATHER_MAP_API_KEY || process.env.REACT_APP_OPEN_WEATHER_MAP_API_KEY;

	const result = await fetch(
    `https://pro.openweathermap.org/data/2.5/forecast/hourly?lat=${centroid.coordinates[1]}&lon=${centroid.coordinates[0]}&units=imperial&appid=${owmToken}`
  ).then(async response=>await response.json())
  return result
}

export async function getCurrentWeatherData(centroid)
{
  const owmToken = window.env.OPEN_WEATHER_MAP_API_KEY || process.env.REACT_APP_OPEN_WEATHER_MAP_API_KEY;

	const result = await fetch(
    `https://api.openweathermap.org/data/2.5/weather?lat=${centroid.coordinates[1]}&lon=${centroid.coordinates[0]}&units=imperial&appid=${owmToken}`
  ).then(async response=>await response.json())
  return result
}

export async function getAORLocationInfo(aor,centroid)
{
  const mapboxToken = window.env.MAPBOX_ACCESS_TOKEN || process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

  let placeUrl = `https://api.mapbox.com/geocoding/v5/mapbox.places/${centroid.coordinates[0]},${centroid.coordinates[1]}.json?access_token=${mapboxToken}`;
  try {
    const response = await(fetch(placeUrl)).then((res)=>res.json())
    const place = response.features.find((f) => f.id.includes('place'));
    const placeName = (!!place && place.place_name) || 'Click to Zoom';
    const country = response.features.find((f) =>
      f.id.includes('country')
    );
    const cc =
      (!!country &&
        !!country.properties &&
        !!country.properties.short_code &&
        country.properties.short_code.toUpperCase()) ||
      'US';

    const returnObj = {
      place: place,
      placeName: placeName,
      country: country,
      cc: cc,
    };
    return returnObj
  } catch(e) {
    console.warn("getAORLocationInfo ERROR",e)
    return null;
  }
}

