import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import React from 'react';
import { Modal } from 'react-bootstrap';
export default function CustomModal({
  show,
  heading,
  onClose,
  onConfirm,
  children,
  saveButton,
  buttonDisable,
}) {
  return (
    <Modal
      show={show}
      onHide={onClose}
      centered
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title>{heading}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* <div className="form-label">Title</div> */}
        {children}
      </Modal.Body>
      <Modal.Footer>
        <div className="button-group">
          <StylishNewButton
            type="button"
            className="button--secondary button--reverse"
            onClick={onClose}
          >
            Close
          </StylishNewButton>
          <StylishNewButton
            type="button"
            className="button--primary"
            onClick={onConfirm}
            disabled={buttonDisable ? true : false}
          >
            {saveButton}
          </StylishNewButton>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
