import React, { useEffect, useState } from 'react';
import ReactECharts from 'echarts-for-react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import './Chart.css';
import './PowerOutageChart.css';
import StylishNewTable from 'components/DesignSystems/New/StylishNewTable';
dayjs.extend(utc)

const legendArray = [
  [0, '#FFFFFF'],
  [1, '#ffffcc'],
  [2, '#a1dab4'],
  [3, '#41b6c4'],
  [4, '#2c7fb8'],
  [5, '#253494'],
];

export default function PowerOutageChart({
  layer,
  feature,
  selectedDatetime,
  apiPrefix,
  powerOutageScenarios,
  sidebarActiveItem,
  viewMode,
  authHeader,
}) {
  const [powerOutageData, setPowerOutageData] = useState();
  const [powerOutageDataFetched, setPowerOutageDataFetched] = useState(false)
  const [dataDatetime, setDataDatetime] = useState(selectedDatetime);
  const [title, setTitle] = useState('');
  const [currentFeatureId, setCurrentFeatureId] = useState(feature.properties.poc_feature_id);

  // PowerOutage only shows within past 5 days. If the selected date time is ahead of current date time, then it is invalid.
  const dateIsValid = !((new Date(selectedDatetime.startOf('hour'))) > (new Date(dayjs().startOf('hour'))))

  useEffect(() => {
    if (
      (!powerOutageDataFetched || 
      !powerOutageData ||
      new Date(selectedDatetime) !== new Date(dataDatetime) ||
      currentFeatureId !== feature.properties.poc_feature_id)
      && dateIsValid
    ) {
      fetch(`
        ${apiPrefix}/dice/map/poweroutageRange?selected_datetime=
        ${selectedDatetime
          .minute(0)
          .second(0)
          .utc()
          .format('YYYY-MM-DD HH:mm:ss')}&political_boundaries_type=${
          layer.metadata.model_details.political_boundaries_type
        }&boundary_id=${
          feature.properties.boundary_id
        }
      `, {
        headers: {
          'Authorization': authHeader,
          'x-teamsapp': sessionStorage['isUsingTeams'] === 'true'
        }
      })
        .then((res) => res.json())
        .then((response) => {
          let newTitle = `${layer.metadata.model_details.model_code} - ${
            (feature.properties.political_boundaries_type === 'county' &&
              `${feature.properties.county_name} County, ${feature.properties.state_name}`) ||
            (feature.properties.political_boundaries_type === 'state' &&
              feature.properties.state_name)
          }`;
          setDataDatetime(selectedDatetime);
          setPowerOutageData(response);
          setPowerOutageDataFetched(true)
          setTitle(newTitle);
          setCurrentFeatureId(feature.properties.poc_feature_id);
        })
        .catch((error) => {
          console.error('Power Outage data fetch ERROR', error);
        });
    }
  }, [layer, selectedDatetime, feature]);

  // functions 
  function formatDataForPowerOutageMarklineConfig(data) {
    if (!data) return;
  
    const result = {
      data: legendArray.map((item) => {
        return {
          yAxis: parseFloat(item[0]),
          lineStyle: {
            color: item[1],
            type: 'dotted',
            width: 1,
          },
        };
      }),
    };
    return result;
  }
  
  function formatDataForPowerOutageVisualMap(data) {
    if (!data) return;
  
    const result = [
      {
        top: 50,
        right: 50,
        showLabel: true,
        pieces: legendArray.map((item, index) => {
          return {
            value: parseFloat(index),
            color: item[1],
          };
        }),
        outOfRange: {
          color: '#000',
        },
      },
    ];
    return result;
  }
  
  function calculateMatchValue(powerOutageScenario, val) {
    const filterScenario = powerOutageScenarios.find(p => p.value === powerOutageScenario);
    if (filterScenario.scenario) {
      const options = filterScenario.scenario;
      for (let i = 0; i < options.length; i++) {
        if (val <= options[i].limit) {
          return options[i].value;
        }
      }

      return options[options.length - 1].value;
    }
    return 0;
  }
  
  function formatDataForPowerOutageChart(
    layer,
    title,
    data,
    powerOutageScenario,
    powerOutageScenarios,
    index
  ) {
    if (!data) return;
  
    let powerOutageDataArray = data.map((elem) => {
      return [
        new Date(
          dayjs(elem.timestamp).format('MM/DD/YYYY hh:mm A')
        ).toISOString(),
        calculateMatchValue(powerOutageScenario, elem[powerOutageScenario].toFixed(2))
      ];
    });
  
    const foundScenario = powerOutageScenarios.find((s) => s.value === powerOutageScenario);
  
    const result = {
      name: `${title} - (${foundScenario.label})`,
      type: 'line',
      //stack: 'all',
      label: 'show',
      data: powerOutageDataArray,
      symbol: 'none',
      lineStyle: {
        type: 'solid',
        color: foundScenario.color,
        width: 7,
      },
      tooltip: {
        borderColor: foundScenario.color,
        borderWidth: 3,
        textStyle: {
          textBorderColor: foundScenario.color,
          textBorderWidth: 3,
        },
      },
      extraCssText: `color: ${foundScenario.color} !important`,
    };
  
    if (index === powerOutageScenarios.length - 1) {
      result.markLine = {
        ...formatDataForPowerOutageMarklineConfig(title),
        name: title,
      };
    }
  
    return result;
  }

  const chartConfig = {
    title: {
      text: title,
    },
    backgroundColor: '#222529',
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
        label: {
          backgroundColor: '#6a7985',
        },
      },
      backgroundColor: '#343a40',
      borderColor: '#495057',
      textStyle: {
        color: '#FFF',
      },
    },
    legend: {
      data: powerOutageScenarios.map((s) => {
        return {
          name: s.label,
          textStyle: {
            color: s.color,
          },
        };
      }),
    },
    grid: {
      left: '5%',
      right: '20%',
      bottom: '15%',
      containLabel: true,
    },
    toolbox: {
      feature: {
        saveAsImage: {},
        dataZoom: {
          yAxisIndex: 'none',
        },
        restore: {},
      },
    },
    dataZoom: [
      {
        id: 'dataZoomX',
        type: 'slider',
        xAxisIndex: [0][0],
        filterMode: 'filter',
      },
      {
        id: 'dataZoomY',
        type: 'slider',
        yAxisIndex: [0],
        filterMode: 'empty',
      },
    ],
    xAxis: [
      {
        name: 'Site Time',
        type: 'time',
        scale: true,
        data: [],
        boundaryGap: false,
      },
      {
        name: 'UTC',
        type: 'time',
        scale: true,
        data: [],
      },
    ],
    yAxis: [
      {
        type: 'value',
        scale: true,
        name: layer.metadata.model_details.model_code,
        min: 0,
        max: 5,
      },
    ],
    series: powerOutageScenarios.map((s, i) => {
      return formatDataForPowerOutageChart(
        layer,
        title,
        powerOutageData,
        s.value,
        powerOutageScenarios,
        i
      );
    }),
    visualMap: formatDataForPowerOutageVisualMap(powerOutageData),
  };

  function onChartReady() {}

  function onChartClick(e) {}

  const onEvents = {
    click: onChartClick,
  };

  const powerOutageRows =
    !!powerOutageData &&
    powerOutageData
      .filter((m, i) => {
        return i % 24 === 0 ? m : null;
      })
      .filter((m) => !!m)
      .map((m) => {
        return {
          ...m,
        };
      });

  const powerOutageTableRows =
    (!!powerOutageData &&
      powerOutageScenarios.map((s) => {
        let row = {
          Scenario: s.label,
        };
        powerOutageRows.forEach((r) => {
          row[dayjs(r.timestamp).format('MM/DD/YYYY hh A')] = calculateMatchValue(s.value, r[s.value]);
        });
        return row;
      })) ||
    [];

  let powerOutageCols =
    (!!powerOutageData && [
      ...Object.keys(powerOutageTableRows[0]).map((col) => {
        return {
          dataField: col,
          text: col,
          attrs: { title: col },
          formatter: (cell, row, rowIndex) => {
            return (
              <>
                {row[col] === 0 ? (
                  <span
                    className="color-black d-block weight-600"
                    style={{ background: '#FFFFFF', padding: '.75rem 1rem' }}
                  >
                    {row[col]}
                  </span>
                ) : row[col] === 1 ? (
                  <span
                    className="color-black d-block weight-600"
                    style={{ background: '#ffffcc', padding: '.75rem 1rem' }}
                  >
                    {row[col]}
                  </span>
                ) : row[col] === 2 ? (
                  <span
                    className="color-black d-block weight-600"
                    style={{ background: '#a1dab4', padding: '.75rem 1rem' }}
                  >
                    {row[col]}
                  </span>
                ) : row[col] === 3 ? (
                  <span
                    className="color-black d-block weight-600"
                    style={{ background: '#41b6c4', padding: '.75rem 1rem' }}
                  >
                    {row[col]}
                  </span>
                ) : row[col] === 4 ? (
                  <span
                    className="color-black d-block weight-600"
                    style={{ background: '#2c7fb8', padding: '.75rem 1rem' }}
                  >
                    {row[col]}
                  </span>
                ) : row[col] === 5 ? (
                  <span
                    className="color-black d-block weight-600"
                    style={{ background: '#253494', padding: '.75rem 1rem' }}
                  >
                    {row[col]}
                  </span>
                ) : row.Scenario === 'Absolute' ? (
                  <span
                    className="color-black d-block weight-600"
                    style={{ background: '#0868ac', padding: '.75rem 1rem' }}
                  >
                    {row[col]}
                  </span>
                ) : row.Scenario === 'Relative' ? (
                  <span
                    className="color-black d-block weight-600"
                    style={{ background: '#f0f9e8', padding: '.75rem 1rem' }}
                  >
                    {row[col]}
                  </span>
                ) : (
                  <>{row[col]}</>
                )}
              </>
            );
          },
        };
      }),
    ]) ||
    [];

  return (
    <div
      className={`map-chart ${
        !!sidebarActiveItem && 'map-chart-sidebar-expanded'
      }`}
    >
      {((!dateIsValid || !!powerOutageDataFetched && (!powerOutageData||!powerOutageData.length)) && (
        <div className="weight-600 p-3">Out of Range: PowerOutage History is only available within past 5 days.</div>
      )) || (dateIsValid && !!powerOutageData && powerOutageData.length && (
        <>
          {(viewMode === 'Chart' && (
            <ReactECharts
              option={chartConfig}
              theme={'dark'}
              onChartReady={onChartReady}
              onEvents={onEvents}
              opts={{ renderer: 'svg' }}
              className="Map-Chart"
            />
          )) || (
            <div className="PowerOutage-Table-Wrap">
              <span>{title}</span>
              <StylishNewTable
                classes={'tbody-p-0 nowrap'}
                keyField={`id`}
                rows={powerOutageTableRows}
                columns={powerOutageCols}
              />
            </div>
          )}
        </>        
      )) || <div className="weight-600 p-3">Loading data...</div>}
    </div>
  );
}
