import React, { useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Network from '../../../../service/Network';
import API from '../../../../service/api';
import { useNavigate, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import moment from 'moment';
import Dropdown from 'react-bootstrap/Dropdown';
import { HelpEventDetails, useSelfHostedKnowledgeBase } from 'assets/data/HelpContent';
import { MSELList } from '../MSEL/MSELList';
import { CSVLink } from 'react-csv';
import IconDownload from 'assets/images/icon__download--white.svg';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { PreparePath } from 'utils/sharedUtils/sharedConst';
import StylishHelp from 'components/DesignSystems/New/StylishHelp';
import ConfirmModal from 'PREPAREsrc/component/ConfirmModal/ConfirmModal';
import { toastConfig } from 'assets/data/config';
import { endLoading, startLoading } from "../../../../../reducers/loading/loading.action";
import { SharedIcon } from 'components/SharedIcon/SharedIcon';

export const ViewEvent = () => {
  const [showEvent, setShowEvent] = useState({});
  const [allInjectList, setAllInjectList] = useState([]);
  const [callInject, setCallInject] = useState(false);
  const [allInjectListForExport, setAllInjectListForExport] = useState([]);
  const [deleteEvent, setDeleteEvent] = useState(null);

  const selfHostedKnowledgeBase = useSelfHostedKnowledgeBase()

  const dispatch = useDispatch();

  const params = useParams();

  const navigate = useNavigate();
  const reduxTimezone = useSelector(
    (state) => state.prepare.workspace.timezone
  );

  const csvLink = useRef();

  const allInjectListHeader = [
    { label: 'Date/Time', key: 'date_time' },
    { label: '#', key: 'number' },
    { label: 'Title', key: 'title' },
    { label: 'Status', key: 'status' },
    { label: 'Owner', key: 'owner' },
    { label: 'Type', key: 'type' },
    { label: ' Inject Method', key: 'method' },
    { label: 'Scenario', key: 'event' },
    { label: 'From', key: 'from' },
    { label: 'To', key: 'to' },
    { label: 'Trigger', key: 'trigger' },
    { label: 'Anticipated Response / Expected Player Action', key: 'response' },
    { label: 'TrainingObjective', key: 'trainingobjective' },
    { label: 'Remarks', key: 'remarks' },
  ];

  const pdfHeaders = [
    'Date/Time',
    '# Title',
    'Status Owner',
    'Type ',
    'Inject Method',
    'Scenario',
    'From->To',
    'Trigger',
    'Anticipated Response / Expected Player Action',
    ' Objectives',
    'Remarks',
  ];

  const training_method = [
    { value: 'live', label: 'Live' },
    { value: 'virtual', label: 'Virtual' },
    { value: 'constructive', label: 'Constructive' },
    { value: 'integrated_lvc', label: 'Integrated LVC' },
  ];

  const {
    selectedWorkspace,
    timezone,
    timezoneOffset,
    workspaceName,
  } = useSelector((state) => state.prepare.workspace);
  const reduxUser = useSelector((state) => state.prepare.user);

  const onDeleteEvent = (row) => {
    setDeleteEvent(row.id);
  };

  const fetchAllInject = async (type) => {
    dispatch(startLoading());
    try {
      const inject = await Network.get(API.fetchAllInject, {
        workspaceId: selectedWorkspace,
        eventId: params.id,
      });

      const injectsForExport = inject.data.response.dataset.map((element) => {
        const toData = toDataString(element.trainingobjective);
        return {
          date_time: moment(element.responsedate).isValid()
            ? moment(element.responsedate).format(
                reduxUser?.timeFormat === 'hour12Mode'
                  ? 'YYYY-MM-DD - hh:mm a'
                  : 'YYYY-MM-DD - HH:mm'
              ) +
              ' (' +
              reduxTimezone.split('/')[0] +
              ')'
            : null,
          number: element.number,
          title: element.injectTitle,
          status: element.statusLabel,
          owner: element.injetOwnerTitle,
          type: element.type,
          method: element.methodName,
          event: element.planEvent,
          from: element?.from?.title,
          to: element?.to?.title,
          trigger: element?.trigger,
          response: element?.response,
          trainingobjective: toData ? '\u2022 ' + toData : '',
          remarks: element.remarks,
        };
      });
      if (!type) {
        setAllInjectList(injectsForExport);
      }
      if (type && type === 'PDF') {
        formatDataForDownload(injectsForExport, type);
      }
      if (type && type === 'CSV') {
        formatDataForDownload(injectsForExport, 'CSV');
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(endLoading());
    }
  };

  const formatDataForDownload = (inject, type) => {
    if (type === 'CSV') {
      csvLink.type = 'CSV';
      setAllInjectListForExport(inject);
    }
    if (type === 'PDF') {
      generatePDF(inject);
    }
  };

  const toDataString = (trainingobjective) => {
    return trainingobjective
      ?.map((tO) => {
        const jmet =
          (tO.jmetLevelWar || '') +
          ' ' +
          (tO.jmetTasknumber || '') +
          ' ' +
          (tO.jmetTaskTitle || '');

        const returnToData =
          (tO.participantName || '') +
          ' ' +
          (jmet.trim() ? ` - ${jmet}` : '') +
          ' ' +
          (tO.trainedMethodType
            ? `(${
                training_method.find(
                  (data) => data.value === tO.trainedMethodType
                )?.label
              })`
            : '');

        return returnToData;
      })
      .join('\n\u2022 ');
  };

  const generatePDF = (injects) => {
    const pdfData = [];
    injects.map((element) => {
      pdfData.push([
        element['date_time'],
        element.number ? element.number + ' - ' + element.title : element.title,
        (element.status || '') + ' ' + (element.owner || ' '),
        element.type || '',
        element.method || ' ',
        element.event || '',
        (element.from || '') + '->' + (element.to || ''),
        element.trigger || '',
        element.response || '',
        element.trainingobjective || '',
        element.remarks || '',
      ]);
    });
    const docObj = new jsPDF({
      orientation: 'landscape',
    });

    autoTable(docObj, {
      rowPageBreak: 'avoid',
      theme: 'grid',
      head: [pdfHeaders],
      body: pdfData,
    });

    docObj.save(
      `MSEL ${workspaceName} ${showEvent?.name} ` +
        moment().format(
          reduxUser?.timeFormat === 'hour12Mode'
            ? 'YYYY-MM-DD hh:mm a'
            : 'YYYY-MM-DD HH:mm'
        ) +
        '.pdf'
    );
  };

  const fetchSingleEvent = async (event) => {
    dispatch(startLoading());
    try {
      const event = await Network.get(API.fetchSingleEvent, {
        workspaceId: selectedWorkspace,
        id: params.id,
      });
      setShowEvent(event.data.response.dataset[0]);
    } catch (error) {
      toast.error(error.response.data.response.status.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };
  const onDeleteConfirm = async (event) => {
    event.preventDefault();
    dispatch(startLoading());
    try {
      await Network.post(API.deleteEvent, { id: deleteEvent });
      setDeleteEvent(null);
      navigate(`${PreparePath}/dashboard/plan/prepare/events`, {
        replace: true,
      });
      toast.success('Scenario deleted successfully', toastConfig);
    } catch (error) {
      toast.error(error.response.data.response.status.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };

  useEffect(() => {
    if (csvLink.type === 'CSV') {
      csvLink.current.link.click();
      delete csvLink?.type;
    }
  }, [allInjectListForExport]);

  useEffect(() => {
    callInject && fetchAllInject();
  }, [reduxUser, callInject]);

  useEffect(() => {
    fetchSingleEvent();
  }, [reduxUser]);

  return (
    <>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <StylishNewButton onClick={() => navigate(-1)}>
              <SharedIcon iconName="keyboard_arrow_left" classes="me-2" />
              Back
            </StylishNewButton>
          </li>
          <li className="breadcrumb-item">
            <Link to={`${PreparePath}/dashboard/plan/prepare/events`}>
              Scenario
            </Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            {showEvent.name}
          </li>
        </ol>
      </nav>
      <div className="plan-prepare__events">
        <div className="d-flex align-items-start align-items-md-center mb-4">
          <h4 className="color-brand mb-0 me-4 d-flex align-items-center">
            {!!showEvent?.color && (
              <span
                className="color me-3 me-md-2 flex-shrink-0"
                style={{ background: showEvent.color }}
              ></span>
            )}
            {showEvent.name}
          </h4>
          <Dropdown className="flex-shrink-0 ms-auto">
            <Dropdown.Toggle className="button--icon">
              <SharedIcon iconName="more_vert" bold />
            </Dropdown.Toggle>
            <Dropdown.Menu align="end">
              <Link
                className="dropdown-item"
                to={`${PreparePath}/dashboard/plan/prepare/event/edit/${showEvent.id}`}
              >
                <SharedIcon iconName="stylus" />
                Edit Scenario
              </Link>
              <Dropdown.Item
                onClick={() => {
                  onDeleteEvent(showEvent);
                }}
              >
                <SharedIcon iconName="delete" />
                Delete Scenario
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  fetchAllInject('CSV');
                }}
              >
                <img src={IconDownload} alt="" />
                Download Spreadsheet (CSV)
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  fetchAllInject('PDF');
                }}
              >
                <img src={IconDownload} alt="" />
                Download PDF
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <StylishHelp
            classes={'sml ms-3'}
            title={HelpEventDetails.title}
            content={HelpEventDetails.content}
            link={selfHostedKnowledgeBase || HelpEventDetails.link}
          />
        </div>
        <div className="form-block">
          <div className="row">
            <div className="col-md-6 mb-3">
              <label className="form-label d-block mb-0">Start</label>
              {!!showEvent?.startDate ? (
                <p className="m-0">
                  {moment(showEvent.startDate)
                    .utcOffset(timezoneOffset)
                    .format(
                      reduxUser?.timeFormat === 'hour12Mode'
                        ? 'YYYY-MM-DD - hh:mm a'
                        : 'YYYY-MM-DD - HH:mm'
                    )}{' '}
                  ({timezone.split('/')[0]})
                </p>
              ) : (
                <p className="m-0">-</p>
              )}
            </div>
            <div className="col-md-6 mb-3">
              <label className="form-label d-block mb-0">End</label>
              {!!showEvent?.endDate ? (
                <p className="m-0">
                  {moment(showEvent.endDate)
                    .utcOffset(timezoneOffset)
                    .format(
                      reduxUser?.timeFormat === 'hour12Mode'
                        ? 'YYYY-MM-DD - hh:mm a'
                        : 'YYYY-MM-DD - HH:mm'
                    )}{' '}
                  ({timezone.split('/')[0]})
                </p>
              ) : (
                <p className="m-0">-</p>
              )}
            </div>
            <div className="col-md-12 mb-3">
              <label className="form-label d-block mb-0">Overall Method</label>
              {!!showEvent?.overall_method ? (
                <p className="m-0">
                  {showEvent.overall_method.charAt(0).toUpperCase() +
                    showEvent.overall_method.slice(1)}
                </p>
              ) : (
                <p className="m-0">-</p>
              )}
            </div>
            <div className="col-md-6 mb-3 mb-md-0">
              <label className="form-label d-block mb-0">Scenario</label>
              {!!showEvent?.scenario ? (
                <p className="m-0">{showEvent.scenario}</p>
              ) : (
                <p className="m-0">-</p>
              )}
            </div>
            <div className="col-md-6">
              <label className="form-label d-block mb-0">Remarks</label>
              {!!showEvent?.remarks ? (
                <p className="m-0">{showEvent.remarks}</p>
              ) : (
                <p className="m-0">-</p>
              )}
            </div>
          </div>
        </div>
        <div className="mt-4">
          <MSELList eventId={params.id} />
        </div>

        <CSVLink
          filename={
            `MSEL ${workspaceName} ${showEvent?.name} ` +
            moment().format(
              reduxUser?.timeFormat === 'hour12Mode'
                ? 'YYYY-MM-DD hh:mm a'
                : 'YYYY-MM-DD HH:mm'
            ) +
            '.csv'
          }
          data={allInjectListForExport}
          headers={allInjectListHeader}
          ref={csvLink}
          className="hidden"
        ></CSVLink>
      </div>{' '}
      {deleteEvent && (
        <ConfirmModal
          show={deleteEvent}
          onClose={() => setDeleteEvent(null)}
          heading={'Delete Scenario'}
          text={'Are you sure you want this?'}
          onConfirm={onDeleteConfirm}
        />
      )}
    </>
  );
};
