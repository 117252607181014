import * as turf from '@turf/turf';

export default function queryBingNewsEffect(
  selectedEvent,
  selectedEventTabNews,
  setSelectedEventTabNews,
  eventLocationInfo,
  bingNewsKey,
  key,
) {

  

  if (!!selectedEvent && key === 'news' && ((!!eventLocationInfo && !!selectedEvent?.bounds) || selectedEvent.hazard_name==="Hurricane")) {

    let centroid = eventLocationInfo?.centroid
    let BING_ENDPOINT = 'https://api.bing.microsoft.com/v7.0/news/search';
    let headers = {
      'Ocp-Apim-Subscription-Key': bingNewsKey,
    }
    if(selectedEvent.hazard_name==="Hurricane")
    {
      BING_ENDPOINT += `?q=${selectedEvent.hurricane_data_points[0].storm_type} ${selectedEvent.hurricane_data_points[0].unified_storm_name}`
    }
    else
    {
      BING_ENDPOINT =
        BING_ENDPOINT +
        '?q=' +
        selectedEvent.hazard_name +
        ' ' +
        eventLocationInfo.placeName +
        '&cc=' +
        eventLocationInfo.cc;
      headers['X-Search-Location'] = `lat:${centroid.geometry.coordinates[1]};long:${centroid.geometry.coordinates[0]};re:2000m`
    }

    BING_ENDPOINT += "&freshness=Month&sortBy=Relevance&count=20"

    fetch(BING_ENDPOINT, {
      headers: headers
    })
      .then((res) => res.json())
      .then((response) => {
        setSelectedEventTabNews(response.value.sort((a,b)=> {
          const dateA = new Date(a.datePublished)
          const dateB = new Date(b.datePublished)
          if(dateA>dateB) return -1
          if(dateA<dateB) return 1
          if(dateA===dateB) return 0
        }));
      })
      .catch((error) => {
        console.error('owm ERROR', error);
      });
  }
}
