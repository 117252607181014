import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { toast } from 'react-toastify';
import Network from '../../../../../service/Network';
import API from '../../../../../service/api';
import { useState } from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AddSourceDialog from './AddSourceDialog';
import AddReferenceDialog from './AddReferenceDialog';
import AddParaDialog from './AddParaDialog';
import AddCompDialog from './addCompetencyDialog';
import AddTaskDialogFromArray from './addTaskDialogFromArray';
import AddStandardDialogFromArray from './addStandardDialogFromArray';
import AddCodeDialogFromArray from './AddCodeDialogFromArray';
import { HelpTrainingObjectiveDetails, useSelfHostedKnowledgeBase } from 'assets/data/HelpContent';
import { inputStringLength, toastConfig } from 'assets/data/config';
import { PreparePath } from 'utils/sharedUtils/sharedConst';
import StylishNewSelect from 'components/DesignSystems/New/StylishNewSelect';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import StylishNewInput from 'components/DesignSystems/New/StylishNewInput';
import StylishHelp from 'components/DesignSystems/New/StylishHelp';
import {
  endLoading,
  startLoading,
} from '../../../../../../reducers/loading/loading.action';
import { SharedIcon } from 'components/SharedIcon/SharedIcon';

export default function TrainingObjectiveDetails(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const selfHostedKnowledgeBase = useSelfHostedKnowledgeBase()

  const [allJMETs, setAllJMETs] = useState([]);
  const [allJMETsAfterSave, setAllJMETsAfterSave] = useState([]);
  const [updateDialogData, setUpdateDialogData] = useState(null);
  const [dialogType, setDialogType] = useState('Add');
  const [allPlatform, setAllPlatform] = useState([]);
  const [allPlatformAfterSave, setAllPlatformAfterSave] = useState([]);
  const [allPriority, setAllPriority] = useState('');
  const [allRequestedMethod, setAllRequestedMethod] = useState('');
  const [allTrainingPriority, setAllTrainingPriority] = useState([]);
  const [index, setIndex] = useState(-1);

  const [showAddSourceDialog, setShowAddSourceDialog] = useState(false);
  const [sourceAfterSave, setSourceAfterSave] = useState('');
  const [allSource, setAllSource] = useState([]);
  const [sourceSearch, setSourceSearch] = useState('');

  const [showAddReferenceDialog, setShowAddReferenceDialog] = useState(false);
  const [referenceAfterSave, setReferenceAfterSave] = useState('');
  const [allReference, setAllReference] = useState([]);
  const [referenceSearch, setReferenceSearch] = useState('');

  const [showAddParaDialog, setShowAddParaDialog] = useState(false);
  const [paraAfterSave, setParaAfterSave] = useState('');
  const [allPara, setAllPara] = useState([]);
  const [paraSearch, setParaSearch] = useState('');

  const [showAddCompDialog, setShowAddCompDialog] = useState(false);
  const [compAfterSave, setCompAfterSave] = useState('');
  const [allComp, setAllComp] = useState([]);
  const [compSearch, setCompSearch] = useState('');

  const [showAddTaskDialog, setShowAddTaskDialog] = useState(false);
  const [taskAfterSave, setTaskAfterSave] = useState('');
  const [allTask, setAllTask] = useState([]);
  const [taskSearch, setTaskSearch] = useState('');

  const [showAddStandardDialog, setShowAddStandardDialog] = useState(false);
  const [standardAfterSave, setStandardAfterSave] = useState('');
  const [allStandard, setAllStandard] = useState([]);
  const [standardSearch, setStandardSearch] = useState('');

  const [showAddCodeDialog, setShowAddCodeDialog] = useState(false);
  const [codeAfterSave, setCodeAfterSave] = useState('');
  const [allCode, setAllCode] = useState([]);
  const [codeSearch, setCodeSearch] = useState('');

  const [allListLoaded, setAllListLoaded] = useState(false);

  const workspaceId = useSelector(
    (state) => state.prepare.workspace.selectedWorkspace
  );
  const reduxUser = useSelector((state) => state.prepare.user);

  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    getValues,
    trigger,
    setError,
    clearErrors,
  } = useForm({
    mode: 'onChange',
    values: {
      task: [
        {
          taskid: '',
          measureid: '',
          codeid: '',
        },
      ],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: 'task', // unique name for your Field Array
  });

  const allMethods = [
    {
      value: 'none',
      label: 'None',
    },
    {
      value: 'live',
      label: 'Live',
    },
    {
      value: 'virtual',
      label: 'Virtual',
    },
    {
      value: 'constructive',
      label: 'Constructive',
    },
    {
      value: 'integrated_lvc',
      label: 'Integrated LVC',
    },
  ];

  //SOURCE TRAINING OBJECTIVE
  const getAllSource = async () => {
    dispatch(startLoading());
    try {
      const source = await Network.get(API.getSourceTrainingObjective, {
        workspaceId: workspaceId,
        search: sourceSearch,
      });
      if (source) {
        let sourceData =
          source &&
          source.data.response.dataset.map((e) => {
            return {
              value: e.id,
              label: e.title,
            };
          });
        sourceData.unshift({ value: 'new', label: 'Add New' });
        setAllSource(sourceData);
      }
    } catch (error) {
      toast.error(error.response.data.response.status.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };

  const OnAddSource = (afterSave) => {
    if (afterSave?.value) {
      setSourceAfterSave(afterSave);

      setAllSource([
        ...allSource,
        {
          label: afterSave?.label,
          value: afterSave?.value,
        },
      ]);

      setValue('source', afterSave);
      // getAllSource();
    } else {
      setSourceAfterSave('');
      setValue('source', '');
    }
    setUpdateDialogData(null);
    setDialogType('New');
    setShowAddSourceDialog(false);
  };
  //SOURCE TRAINING OBJECTIVE

  //REFERENCE TRAINING OBJECTIVE
  const getAllReference = async () => {
    dispatch(startLoading());
    try {
      const ref = await Network.get(API.getReferenceTrainingObjective, {
        workspaceId: workspaceId,
        search: referenceSearch,
      });
      if (ref) {
        let refData =
          ref &&
          ref.data.response.dataset.map((e) => {
            return {
              value: e.id,
              label: e.para,
            };
          });
        refData.unshift({ value: 'new', label: 'Add New' });
        setAllReference(refData);
      }
    } catch (error) {
      toast.error(error.response.data.response.status.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };

  const OnAddReference = (afterSave) => {
    if (afterSave?.value) {
      setReferenceAfterSave(afterSave);

      setAllReference([
        ...allReference,
        {
          label: afterSave?.label,
          value: afterSave?.value,
        },
      ]);

      setValue('reference', afterSave);
    } else {
      setReferenceAfterSave('');
      setValue('reference', '');
    }
    setUpdateDialogData(null);
    setDialogType('New');
    setShowAddReferenceDialog(false);
  };
  //REFERENCE TRAINING OBJECTIVE

  //PARAGRAPH TRAINING OBJECTIVE
  const getAllParagraph = async () => {
    dispatch(startLoading());
    try {
      const para = await Network.get(API.getParagraphTrainingObjective, {
        workspaceId: workspaceId,
        search: paraSearch,
      });
      if (para) {
        let paraData =
          para &&
          para.data.response.dataset.map((e) => {
            return {
              value: e.id,
              label: e.title,
            };
          });
        paraData.unshift({ value: 'new', label: 'Add New' });
        setAllPara(paraData);
      }
    } catch (error) {
      toast.error(error.response.data.response.status.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };

  const OnAddParagraph = (afterSave) => {
    if (afterSave?.value) {
      setParaAfterSave(afterSave);
      setAllPara([
        ...allPara,
        {
          label: afterSave?.label,
          value: afterSave?.value,
        },
      ]);

      setValue('paragraph', afterSave);
      // getAllParagraph();
    } else {
      setParaAfterSave('');
      setValue('paragraph', '');
    }
    setUpdateDialogData(null);
    setDialogType('New');
    setShowAddParaDialog(false);
  };
  //PARAGRAPH TRAINING OBJECTIVE

  //COMPETENCE TRAINING OBJECTIVE
  const getAllCompetence = async () => {
    dispatch(startLoading());
    try {
      const comp = await Network.get(API.getCompetenceTrainingObjective, {
        workspaceId: workspaceId,
        search: compSearch,
      });
      if (comp) {
        let compData =
          comp &&
          comp.data.response.dataset.map((e) => {
            return {
              value: e.id,
              label: e.title,
            };
          });
        compData.unshift({ value: 'new', label: 'Add New' });
        setAllComp(compData);
      }
    } catch (error) {
      toast.error(error.response.data.response.status.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };

  const OnAddCompetence = (afterSave) => {
    if (afterSave?.value) {
      setCompAfterSave(afterSave);
      setAllComp([
        ...allComp,
        {
          label: afterSave?.label,
          value: afterSave?.value,
        },
      ]);
      setValue('competence', afterSave);
      // getAllCompetence();
    } else {
      setCompAfterSave('');
      setValue('competence', '');
    }
    setUpdateDialogData(null);
    setDialogType('New');
    setShowAddCompDialog(false);
  };
  //COMPETENCE TRAINING OBJECTIVE

  //TASK TRAINING OBJECTIVE
  const getAllTask = async () => {
    dispatch(startLoading());
    try {
      const task = await Network.get(API.getTaskTrainingObjective, {
        workspaceId: workspaceId,
        search: taskSearch,
      });
      if (task) {
        let taskData =
          task &&
          task.data.response.dataset.map((e) => {
            return {
              value: e.id,
              label: e.title,
            };
          });
        taskData.unshift({ value: 'new', label: 'Add New' });
        setAllTask(taskData);
      }
    } catch (error) {
      toast.error(error.response.data.response.status.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };

  const OnAddTask = (afterSave) => {
    if (afterSave?.value) {
      setAllTask((prevState) => [...prevState, afterSave]);
      setValue(`task.${index}.taskid`, afterSave);
      setIndex(-1);
      setTaskAfterSave(afterSave);
    } else {
      setCompAfterSave('');
      setValue(`task.${index}.taskid`, null);
    }
    setUpdateDialogData(null);
    setDialogType('New');
    setShowAddTaskDialog(false);
  };
  //TASK TRAINING OBJECTIVE

  //STANDARD TRAINING OBJECTIVE
  const getAllStandard = async () => {
    dispatch(startLoading());
    try {
      const standard = await Network.get(API.getStandardTrainingObjective, {
        workspaceId: workspaceId,
        search: standardSearch,
      });
      if (standard) {
        let standardData =
          standard &&
          standard.data.response.dataset.map((e) => {
            return {
              value: e.id,
              label: e.title,
            };
          });
        standardData.unshift({ value: 'new', label: 'Add New' });
        setAllStandard(standardData);
      }
    } catch (error) {
      toast.error(error.response.data.response.status.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };

  const OnAddStandard = (afterSave) => {
    if (afterSave?.value) {
      setAllStandard((prevState) => [...prevState, afterSave]);
      setValue(`task.${index}.measureid`, afterSave);
      setIndex(-1);
      setStandardAfterSave(afterSave);
    } else {
      setStandardAfterSave('');
      setValue(`task.${index}.measureid`, null);
    }
    setUpdateDialogData(null);
    setDialogType('New');
    setShowAddStandardDialog(false);
  };
  //STANDARD TRAINING OBJECTIVE

  //CODE TRAINING OBJECTIVE
  const getAllCode = async () => {
    dispatch(startLoading());
    try {
      const code = await Network.get(API.getCodeTrainingObjective, {
        workspaceId: workspaceId,
        search: codeSearch,
      });
      if (code) {
        let codeData =
          code &&
          code.data.response.dataset.map((e) => {
            return {
              value: e.id,
              label: e.code,
            };
          });
        codeData.unshift({ value: 'new', label: 'Add New' });
        setAllCode(codeData);
      }
    } catch (error) {
      toast.error(error.response.data.response.status.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };

  const OnAddCode = (afterSave) => {
    if (afterSave?.value) {
      setAllCode((prevState) => [...prevState, afterSave]);
      setValue(`task.${index}.codeid`, afterSave);
      setIndex(-1);
      setCodeAfterSave(afterSave);
    } else {
      setCodeAfterSave('');
      setValue(`task.${index}.codeid`, null);
    }
    setUpdateDialogData(null);
    setDialogType('New');
    setShowAddCodeDialog(false);
  };
  //CODE TRAINING OBJECTIVE

  const getAllJMETs = async () => {
    dispatch(startLoading());
    try {
      const jmets = await Network.get(API.getAllJMETs, {
        workspaceId: workspaceId,
      });
      setAllJMETs(
        jmets?.data?.response.dataset.map((e) => {
          return {
            value: e.id,
            label: e.levelWar + ' ' + e.taskNumber + ' ' + e.taskTitle,
          };
        })
      );
    } catch (error) {
      toast.error(error?.response?.data?.response?.status?.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };

  const fetchAllPlatform = async () => {
    dispatch(startLoading());
    try {
      Network.get(API.fetchAllPlatform, {
        workspaceId: workspaceId,
        participantId: props?.participantId,
      })
        .then((resp) => {
          setAllPlatform(
            resp?.data?.response.dataset.map((e) => {
              return {
                value: e.id,
                label: e.title,
              };
            })
          );
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          dispatch(endLoading());
        });
    } catch (error) {
      toast.error(error?.response?.data?.response.status.msg, toastConfig);
    }
  };

  const fetchTrainingObjectiveDetail = () => {
    dispatch(startLoading());
    try {
      Network.get(API.updateTrainingObjective, {
        workspaceId: workspaceId,
        id: props.id,
      })
        .then((resp) => {
          let platformData = {};
          platformData =
            allPlatform.length &&
            allPlatform.find(
              (e) => e?.value === resp.data.response.dataset[0]?.platformId
            );
          platformData && setValue('platform', platformData);
          setAllPlatformAfterSave(platformData);

          let jmetData = {};
          jmetData =
            allJMETs.length &&
            allJMETs.find(
              (e) => e?.value === resp.data.response.dataset[0]?.jmetId
            );
          jmetData && setValue('jmet', jmetData);
          setAllJMETsAfterSave(jmetData);

          let priorityData = {};
          priorityData =
            allTrainingPriority.length &&
            allTrainingPriority.find(
              (e) => e?.id === resp.data.response.dataset[0]?.priorityId
            );
          priorityData &&
            setValue('trainingPriority', { ...priorityData, disabled: true });
          priorityData && setAllPriority({ ...priorityData, disabled: true });

          let requestData = {};
          requestData =
            allMethods.length &&
            allMethods.find(
              (e) => e?.label === resp.data.response.dataset[0]?.requestedMethod
            );
          requestData &&
            setValue('requestedMethod', { ...requestData, disabled: true });
          requestData &&
            setAllRequestedMethod({ ...requestData, disabled: true });
          if (resp.data.response.dataset[0].requiredRuns === 0) {
            setValue('requiredRuns', null);
          } else {
            setValue(
              'requiredRuns',
              resp.data.response.dataset[0].requiredRuns
            );
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          dispatch(endLoading());
        });
    } catch (error) {
      toast.error(error?.response?.data?.response.status.msg, toastConfig);
    }
  };

  const saveAndExit = () => {
    const data = getValues();
    dispatch(startLoading());
    Network.post(API.updateTrainingObjective, {
      workspaceId: workspaceId,
      jmetId: data.jmet.value,
      requiredRuns: data.requiredRuns,
      participantId: props?.participantId,
      id: props.id,
      priorityId: data.trainingPriority.id,
      platformId: data.platform.value,
      requestedMethod: data.requestedMethod.label,
    })
      .then(() => {
        toast.success('Saving', toastConfig);
        navigate(`${PreparePath}/dashboard/plan/prepare/training-objective`);
      })
      .catch((err) => {
        toast.error(err.response.data.response.status.msg, toastConfig);
      })
      .finally(() => {
        dispatch(endLoading());
      });
  };

  const saveAndExitHseep = () => {
    const data = getValues();
    let taskData = data.task.map((e) => ({
      taskId: e.taskid.value,
      measureId: e.measureid.value,
      codeId: e.codeid.value,
    }));

    dispatch(startLoading());
    Network.post(API.getAllExerciseObjectives, {
      workspaceId: workspaceId,
      participantId: props?.participantId,
      platformId: data.platform.value,
      objective: data.exerciseObjective,
      priorityId: data.trainingPriority.id,
      requestedMethod: data.requestedMethod.label,
      requiredRuns: data.requiredRuns,
      id: props?.id,
      sourceId: data.source.value,
      referenceId: data.reference.value,
      paraId: data.paragraph.value,
      competenceId: data.competence.value,
      task: taskData,
    })
      .then(() => {
        toast.success('Saving', toastConfig);
        navigate(`${PreparePath}/dashboard/plan/prepare/training-objective`);
      })
      .catch((err) => {
        toast.error(err.response.data.response.status.msg, toastConfig);
      })
      .finally(() => {
        dispatch(endLoading());
      });
  };

  useEffect(() => {
    if (props?.participantType === 'HSEEP') {
      getAllSource();
    }
  }, [reduxUser, sourceSearch]);

  useEffect(() => {
    if (props?.participantType === 'HSEEP') {
      getAllReference();
    }
  }, [reduxUser, referenceSearch]);

  useEffect(() => {
    if (props?.participantType === 'HSEEP') {
      getAllParagraph();
    }
  }, [reduxUser, paraSearch]);

  useEffect(() => {
    if (props?.participantType === 'HSEEP') {
      getAllCompetence();
    }
  }, [reduxUser, compSearch]);

  useEffect(() => {
    if (props?.participantType === 'HSEEP') {
      getAllTask();
    }
  }, [reduxUser, taskSearch]);

  useEffect(() => {
    if (props?.participantType === 'HSEEP') {
      getAllStandard();
    }
  }, [reduxUser, standardSearch]);

  useEffect(() => {
    if (props?.participantType === 'HSEEP') {
      getAllCode();
    }
  }, [reduxUser, codeSearch]);

  useEffect(() => {
    if (props?.participantType === 'DOD') {
      getAllJMETs();
    }
  }, [reduxUser]);

  useEffect(() => {
    allTrainingPriorities();
    fetchAllPlatform();
  }, [reduxUser]);

  useEffect(() => {
    if (props?.participantType === 'HSEEP') {
      if (
        props.id &&
        !!allSource &&
        allSource.length > 0 &&
        !!allReference &&
        allReference.length > 0 &&
        !!allPara &&
        allPara.length > 0 &&
        !!allComp &&
        allComp.length > 0 &&
        !!allTask &&
        allTask.length > 0 &&
        !!allStandard &&
        allStandard.length > 0 &&
        !!allCode &&
        allCode.length > 0 &&
        !!allPlatform &&
        allPlatform.length &&
        !allListLoaded
      ) {
        setAllListLoaded(true);
        getAllExerciseObjectives();
      }
      // else {
      //   setValue('task', [
      //     {
      //       taskid: '',
      //       measureid: '',
      //       codeid: '',
      //     },
      //   ]);
      // }
    }
  }, [
    props.id,
    reduxUser,
    allSource,
    allReference,
    allPara,
    allComp,
    allPlatform,
  ]);

  useEffect(() => {
    if (props?.participantType === 'DOD') {
      if (
        props.id &&
        !!allJMETs &&
        allJMETs.length > 0 &&
        !!allPlatform &&
        allPlatform.length > 0 &&
        !!allTrainingPriority &&
        allTrainingPriority.length > 0 &&
        !allListLoaded
      ) {
        setAllListLoaded(true);
        fetchTrainingObjectiveDetail();
      }
    }
  }, [props.id, reduxUser, allJMETs, allPlatform, allTrainingPriority]);

  const allTrainingPriorities = async () => {
    dispatch(startLoading());
    try {
      await Network.get(API.allTrainingPriorities, {
        workspaceId: workspaceId,
      })
        .then((resp) => {
          setAllTrainingPriority(
            resp.data.response.dataset.map((data, index) => ({
              ...data,
              label: (
                <span
                  className="status status--xsml"
                  style={{ backgroundColor: data.color }}
                  key={index}
                >
                  {data.title}
                </span>
              ),
              disabled: false,
              value: data.id,
            }))
          );
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          dispatch(endLoading());
        });
    } catch (error) {
      toast.error(error?.response?.data?.response?.status?.msg, toastConfig);
    }
  };

  const onSubmit = (data) => {
    dispatch(startLoading());
    Network.post(API.updateTrainingObjective, {
      workspaceId: workspaceId,
      jmetId: data.jmet.value,
      requiredRuns: data.requiredRuns,
      participantId: props?.participantId,
      id: props?.id,
      priorityId: data.trainingPriority.id,
      platformId: data.platform.value,
      requestedMethod: data.requestedMethod.label,
    })
      .then(() => {
        props.onNextClick(1);
        toast.success('Saving', toastConfig);
      })
      .catch((err) => {
        toast.error(err.response.data.response.status.msg, toastConfig);
      })
      .finally(() => {
        dispatch(endLoading());
      });
  };

  const getAllExerciseObjectives = async () => {
    dispatch(startLoading());
    try {
      Network.get(API.getAllExerciseObjectives, {
        id: props.id,
      })
        .then((resp) => {
          setValue(
            'exerciseObjective',
            resp.data.response.dataset[0].exerciseObjective
          );
          let platformData = {};
          platformData =
            allPlatform.length &&
            allPlatform.find(
              (e) => e?.value === resp.data.response.dataset[0]?.platformId
            );
          platformData && setValue('platform', platformData);
          setAllPlatformAfterSave(platformData);

          if (resp.data.response.dataset[0].requiredRuns === 0) {
            setValue('requiredRuns', null);
          } else {
            setValue(
              'requiredRuns',
              resp.data.response.dataset[0].requiredRuns
            );
          }

          let requestData = {};
          requestData =
            allMethods.length &&
            allMethods.find(
              (e) => e?.label === resp.data.response.dataset[0]?.requestedMethod
            );
          requestData &&
            setValue('requestedMethod', { ...requestData, disabled: true });
          requestData &&
            setAllRequestedMethod({ ...requestData, disabled: true });

          let priorityData = {};
          priorityData =
            allTrainingPriority.length &&
            allTrainingPriority.find(
              (e) => e?.id === resp.data.response.dataset[0]?.priorityId
            );
          priorityData &&
            setValue('trainingPriority', { ...priorityData, disabled: true });
          priorityData && setAllPriority({ ...priorityData, disabled: true });

          let sourceData = {};
          sourceData =
            allSource.length &&
            allSource.find(
              (e) => e?.value === resp.data.response.dataset[0]?.sourceId
            );
          sourceData &&
            setValue('source', sourceData) &&
            setSourceAfterSave(sourceData);

          let referData = {};
          referData =
            allReference.length &&
            allReference.find(
              (e) => e?.value === resp.data.response.dataset[0]?.referenceId
            );
          referData &&
            setValue('reference', referData) &&
            setReferenceAfterSave(referData);

          let paraData = {};
          paraData =
            allPara.length &&
            allPara.find(
              (e) => e?.value === resp.data.response.dataset[0]?.paraId
            );
          paraData &&
            setValue('paragraph', paraData) &&
            setParaAfterSave(paraData);

          let compData = {};
          compData =
            allComp.length &&
            allComp.find(
              (e) => e?.value === resp.data.response.dataset[0]?.competenceId
            );
          compData &&
            setValue('competence', compData) &&
            setCompAfterSave(compData);
          setValue(
            'task',
            resp.data.response.dataset[0].task.length
              ? resp.data.response.dataset[0].task.map((t) => ({
                  taskid: allTask.find((aT) => aT.value === t.taskId),
                  measureid: allStandard.find((aS) => aS.value === t.measureId),
                  codeid: allCode.find((aC) => aC.value === t.codeId),
                }))
              : [
                  {
                    taskid: '',
                    measureid: '',
                    codeid: '',
                  },
                ]
          );
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          dispatch(endLoading());
        });
    } catch (error) {
      toast.error(error.response.data.response.status.msg, toastConfig);
    }
  };

  const onSubmitHSEEP = (data) => {
    let taskData = data.task.map((e) => ({
      taskId: e.taskid.value,
      measureId: e.measureid.value,
      codeId: e.codeid.value,
    }));

    dispatch(startLoading());
    Network.post(API.getAllExerciseObjectives, {
      workspaceId: workspaceId,
      participantId: props?.participantId,
      platformId: data.platform.value,
      objective: data.exerciseObjective,
      priorityId: data.trainingPriority.id,
      requestedMethod: data.requestedMethod.label,
      requiredRuns: data.requiredRuns,
      id: props?.id,
      sourceId: data.source.value,
      referenceId: data.reference.value,
      paraId: data.paragraph.value,
      competenceId: data.competence.value,
      task: taskData,
    })
      .then(() => {
        props.onNextClick(1);
        toast.success('Saving', toastConfig);
      })
      .catch((err) => {
        toast.error(err.response.data.response.status.msg, toastConfig);
      })
      .finally(() => {
        dispatch(endLoading());
      });
  };

  //Add your search logic here.
  const customFilter = (option, searchText, type) => {
    if (option.value === 'new') return true;
    if (option.data.label.toLowerCase().includes(searchText.toLowerCase())) {
      if (type === 'source') {
        setSourceSearch(searchText);
      } else if (type === 'reference') {
        setReferenceSearch(searchText);
      } else if (type === 'para') {
        setParaSearch(searchText);
      } else if (type === 'comp') {
        setCompSearch(searchText);
      } else if (type === 'task') {
        setTaskSearch(searchText);
      } else if (type === 'standard') {
        setStandardSearch(searchText);
      } else if (type === 'code') {
        setCodeSearch(searchText);
      }
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      {props.participantType === 'DOD' ? (
        <div>
          {/* DOD Version */}
          <div className="d-flex align-items-center mb-4">
            <h4 className="m-0 me-4">{props?.participantName} Objective</h4>
            <StylishHelp
              classes={'ms-auto sml flex-shrink-0'}
              title={HelpTrainingObjectiveDetails.title}
              content={HelpTrainingObjectiveDetails.content}
              link={selfHostedKnowledgeBase || HelpTrainingObjectiveDetails.link}
            />
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-block">
              <div className="row">
                <div className="col-md-12 mb-3">
                  <label className="form-label">Platform</label>
                  <div className="">
                    <Controller
                      control={control}
                      name="platform"
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <StylishNewSelect
                          options={allPlatform}
                          defaultValue={value}
                          value={value || allPlatformAfterSave}
                          onChange={(e) => {
                            if (!e) {
                              setAllPlatformAfterSave('');
                              setError('platform');
                            } else if (e.value) {
                              clearErrors('platform');
                            }
                            return onChange(e);
                          }}
                          placeholder={'Select Platform'}
                          isClearable={true}
                          isSearchable={true}
                          isMulti={false}
                          isDisabled={false}
                        />
                      )}
                    />
                    {errors.platform && (
                      <span className="form-text form-error">
                        This is a required field
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-12 mb-3">
                  <label className="form-label">JMET/Description</label>
                  <div className="">
                    <Controller
                      control={control}
                      name="jmet"
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <StylishNewSelect
                          options={allJMETs}
                          defaultValue={value}
                          value={value || allJMETsAfterSave}
                          onChange={(e) => {
                            if (!e) {
                              setAllJMETsAfterSave('');
                              setError('jmet');
                            } else if (e.value) {
                              clearErrors('jmet');
                            }
                            return onChange(e);
                          }}
                          placeholder={'Select JMET'}
                          isClearable={true}
                          isSearchable={true}
                          isMulti={false}
                          isDisabled={false}
                        />
                      )}
                    />
                    {errors.jmet && (
                      <span className="form-text form-error">
                        This is a required field
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-12 col-lg-4 mb-3">
                      <label className="form-label">Requested Method</label>
                      <div className="">
                        <Controller
                          control={control}
                          name="requestedMethod"
                          rules={{ required: true }}
                          render={({ field: { onChange, value } }) => (
                            <StylishNewSelect
                              options={allMethods}
                              defaultValue={value}
                              value={value || allRequestedMethod}
                              onChange={(e) => {
                                if (!e) {
                                  setAllRequestedMethod('');
                                  setError('requestedMethod');
                                } else if (e.value) {
                                  clearErrors('requestedMethod');
                                }
                                return onChange(e);
                              }}
                              placeholder={'Select Method'}
                              isClearable={true}
                              isSearchable={true}
                              isMulti={false}
                              isDisabled={false}
                            />
                          )}
                        />
                        {errors.requestedMethod && (
                          <span className="form-text form-error">
                            This is a required field
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-12 col-lg-4 mb-3">
                      <label className="form-label">Objective Priority</label>
                      <div className="">
                        <Controller
                          control={control}
                          name="trainingPriority"
                          rules={{ required: true }}
                          render={({ field: { onChange, value } }) => (
                            <StylishNewSelect
                              options={allTrainingPriority}
                              defaultValue={value}
                              value={value || allPriority}
                              onChange={(e) => {
                                if (!e) {
                                  setAllPriority('');
                                  setError('trainingPriority');
                                } else if (e.value) {
                                  clearErrors('trainingPriority');
                                }
                                return onChange(e);
                              }}
                              placeholder={'Select Priority'}
                              isClearable={true}
                              isSearchable={true}
                              isMulti={false}
                              isDisabled={false}
                              isOptionDisabled={(trainingPriority) =>
                                trainingPriority.disabled
                              }
                            />
                          )}
                        />
                        {errors.trainingPriority && (
                          <span className="form-text form-error">
                            This is a required field
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-12 col-lg-4 mb-3">
                      <label className="form-label">Required Runs</label>
                      <Controller
                        control={control}
                        name={'requiredRuns'}
                        rules={{
                          required: true,
                          max: 1000000000,
                          min: 0,
                        }}
                        render={({ field: { onChange, value } }) => (
                          <StylishNewInput
                            onChange={(e) => {
                              const inputValue = e.target.value;
                              const sanitizedValue = inputValue.replace(
                                /[^0-9]/g,
                                ''
                              );
                              onChange(sanitizedValue);
                            }}
                            value={value}
                            type="number"
                            name="requiredRuns"
                            className="form-control"
                            placeholder="Required runs"
                          />
                        )}
                      />

                      {errors?.requiredRuns?.type === 'required' && (
                        <span className="form-text form-error">
                          This is a required field
                        </span>
                      )}
                      {errors?.requiredRuns?.type === 'max' && (
                        <span className="form-text form-error">
                          Max 1000000000 allowed.
                        </span>
                      )}
                      {errors?.requiredRuns?.type === 'min' && (
                        <span className="form-text form-error">
                          Value should be positive
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                {/* <div className="col-md-12 col-lg-4">
                  <label className="form-label">Classification</label>
                  <input
                    type="text"
                    maxLength={inputStringLength}
                    className="form-control"
                    value="Unclassified"
                    readOnly
                  />
                </div> */}
              </div>
            </div>
            <div className="col-md-12 mt-4">
              <div className="button-group d-block d-md-flex">
                <StylishNewButton
                  type="button"
                  className="button button--tertiary button--reverse ms-auto w-100 w-md-auto"
                  onClick={async () => {
                    const valid = await trigger();

                    if (valid) {
                      saveAndExit();
                    }
                  }}
                >
                  Save &amp; Exit
                </StylishNewButton>
                <StylishNewButton
                  type="submit"
                  className="button button--primary w-100 w-md-auto mt-3 mt-md-0 ms-0 ms-md-3"
                  // onClick={() => props.onNextClick(1)}
                >
                  Next
                </StylishNewButton>
              </div>
            </div>
          </form>
          {/* DOD Version */}
        </div>
      ) : (
        <div>
          {/* HSEEP Version */}
          <div className="d-flex align-items-center mb-4">
            <div>
              <h4 className="m-0">
                {props?.participantName} Exercise Objective
              </h4>
              <span className="d-block">{allPlatformAfterSave?.label}</span>
            </div>
            <StylishHelp
              classes={'ms-auto sml flex-shrink-0'}
              title={HelpTrainingObjectiveDetails.title}
              content={HelpTrainingObjectiveDetails.content}
              link={selfHostedKnowledgeBase || HelpTrainingObjectiveDetails.link}
            />
          </div>
          <form onSubmit={handleSubmit(onSubmitHSEEP)}>
            <div className="form-block">
              <div className="row">
                <div className="col-md-12 mb-3">
                  <label className="form-label">Platform</label>
                  <div className="">
                    <Controller
                      control={control}
                      name="platform"
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <StylishNewSelect
                          options={allPlatform}
                          defaultValue={value}
                          value={value || allPlatformAfterSave}
                          onChange={(e) => {
                            if (!e) {
                              setAllPlatformAfterSave('');
                              setError('platform');
                            } else if (e.value) {
                              clearErrors('platform');
                            }
                            return onChange(e);
                          }}
                          placeholder={'Select Platform'}
                          isClearable={true}
                          isSearchable={true}
                          isMulti={false}
                          isDisabled={false}
                        />
                      )}
                    />
                    {errors.platform && (
                      <span className="form-text form-error">
                        This is a required field
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-12 mt-2">
                  <h4>Objective Focus</h4>
                </div>
                <div className="col-md-12 mb-3">
                  <label className="form-label">Exercise Objective</label>
                  <Controller
                    control={control}
                    name={'exerciseObjective'}
                    rules={{
                      required: true,
                    }}
                    render={({ field: { onChange, value } }) => (
                      <StylishNewInput
                        onChange={(e) => {
                          onChange(e);
                        }}
                        value={value}
                        type="text"
                        name="exerciseObjective"
                        maxLength={inputStringLength}
                        className="form-control"
                        placeholder="Short Title of Objective"
                      />
                    )}
                  />
                  {errors?.exerciseObjective && (
                    <span className="form-text form-error">
                      This is a required field
                    </span>
                  )}
                </div>
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-12 col-lg-4 mb-3">
                      <label className="form-label">Priority</label>
                      <div className="">
                        <Controller
                          control={control}
                          name="trainingPriority"
                          rules={{ required: true }}
                          render={({ field: { onChange, value } }) => (
                            <StylishNewSelect
                              options={allTrainingPriority}
                              defaultValue={value}
                              value={value || allPriority}
                              onChange={(e) => {
                                if (!e) {
                                  setAllPriority('');
                                  setError('trainingPriority');
                                } else if (e.value) {
                                  clearErrors('trainingPriority');
                                }
                                return onChange(e);
                              }}
                              placeholder={'Select Priority'}
                              isClearable={true}
                              isSearchable={true}
                              isMulti={false}
                              isDisabled={false}
                              isOptionDisabled={(trainingPriority) =>
                                trainingPriority.disabled
                              }
                            />
                          )}
                        />
                        {errors.trainingPriority && (
                          <span className="form-text form-error">
                            This is a required field
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-12 col-lg-4 mb-3">
                      <label className="form-label">Required Runs</label>
                      <Controller
                        control={control}
                        name={'requiredRuns'}
                        rules={{
                          required: true,
                          max: 1000000000,
                          min: 0,
                        }}
                        render={({ field: { onChange, value } }) => (
                          <StylishNewInput
                            onChange={(e) => {
                              const inputValue = e.target.value;
                              const sanitizedValue = inputValue.replace(
                                /[^0-9]/g,
                                ''
                              );
                              onChange(sanitizedValue);
                            }}
                            value={value}
                            type="number"
                            name="requiredRuns"
                            className="form-control"
                            placeholder="Required runs"
                          />
                        )}
                      />

                      {errors?.requiredRuns?.type === 'required' && (
                        <span className="form-text form-error">
                          This is a required field
                        </span>
                      )}
                      {errors?.requiredRuns?.type === 'max' && (
                        <span className="form-text form-error">
                          Max 1000000000 allowed.
                        </span>
                      )}
                      {errors?.requiredRuns?.type === 'min' && (
                        <span className="form-text form-error">
                          Value should be positive
                        </span>
                      )}
                    </div>
                    <div className="col-md-12 col-lg-4 mb-3">
                      <label className="form-label">Requested Method</label>
                      <div className="">
                        <Controller
                          control={control}
                          name="requestedMethod"
                          rules={{ required: true }}
                          render={({ field: { onChange, value } }) => (
                            <StylishNewSelect
                              options={allMethods}
                              defaultValue={value}
                              value={value || allRequestedMethod}
                              onChange={(e) => {
                                if (!e) {
                                  setAllRequestedMethod('');
                                  setError('requestedMethod');
                                } else if (e.value) {
                                  clearErrors('requestedMethod');
                                }
                                return onChange(e);
                              }}
                              placeholder={'Select Method'}
                              isClearable={true}
                              isSearchable={true}
                              isMulti={false}
                              isDisabled={false}
                              isOptionDisabled={(requestedMethod) =>
                                requestedMethod.disabled
                              }
                            />
                          )}
                        />
                        {errors.requestedMethod && (
                          <span className="form-text form-error">
                            This is a required field
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-12 col-lg-4 mb-3">
                      <label className="form-label">
                        Source (Plan. Framework. SOP. ctc.)
                      </label>
                      <div className="">
                        <Controller
                          control={control}
                          name="source"
                          rules={{ required: true }}
                          render={({ field: { onChange, value } }) => (
                            <StylishNewSelect
                              options={allSource}
                              defaultValue={value}
                              value={value || sourceAfterSave}
                              onChange={(e) => {
                                if (e?.value === 'new') {
                                  clearErrors('source');
                                  setShowAddSourceDialog(true);
                                } else if (!e) {
                                  setSourceAfterSave('');
                                  setError('source');
                                } else if (e.value) {
                                  clearErrors('source');
                                }
                                return onChange(e);
                              }}
                              placeholder={'Add new or Select'}
                              isClearable={true}
                              filterOption={(option, searchText) =>
                                customFilter(option, searchText, 'source')
                              }
                              isSearchable={true}
                              isMulti={false}
                              isDisabled={false}
                              openAddNew={() => ({})}
                            />
                          )}
                        />
                        {errors.source && (
                          <span className="form-text form-error">
                            This is a required field
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-12 col-lg-4 mb-3">
                      <label className="form-label">
                        Reference (Paragraph)
                      </label>
                      <div className="">
                        <Controller
                          control={control}
                          name="reference"
                          rules={{ required: true }}
                          render={({ field: { onChange, value } }) => (
                            <StylishNewSelect
                              options={allReference}
                              defaultValue={value}
                              value={value || referenceAfterSave}
                              onChange={(e) => {
                                if (e?.value === 'new') {
                                  clearErrors('reference');
                                  setShowAddReferenceDialog(true);
                                } else if (!e) {
                                  setReferenceAfterSave('');
                                  setError('reference');
                                } else if (e.value) {
                                  clearErrors('reference');
                                }
                                return onChange(e);
                              }}
                              placeholder={'Add new or Select'}
                              isClearable={true}
                              isSearchable={true}
                              filterOption={(option, searchText) =>
                                customFilter(option, searchText, 'reference')
                              }
                              isMulti={false}
                              isDisabled={false}
                              openAddNew={() => ({})}
                            />
                          )}
                        />
                        {errors.reference && (
                          <span className="form-text form-error">
                            This is a required field
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-12 col-lg-4 mb-3">
                      <label className="form-label">
                        Paragraph Title/ Description
                      </label>
                      <div className="">
                        <Controller
                          control={control}
                          name="paragraph"
                          rules={{ required: true }}
                          render={({ field: { onChange, value } }) => (
                            <StylishNewSelect
                              options={allPara}
                              defaultValue={value}
                              value={value || paraAfterSave}
                              onChange={(e) => {
                                if (e?.value === 'new') {
                                  clearErrors('paragraph');
                                  setShowAddParaDialog(true);
                                } else if (!e) {
                                  setParaAfterSave('');
                                  setError('paragraph');
                                } else if (e.value) {
                                  clearErrors('paragraph');
                                }
                                return onChange(e);
                              }}
                              placeholder={'Add new or Select'}
                              isClearable={true}
                              isSearchable={true}
                              filterOption={(option, searchText) =>
                                customFilter(option, searchText, 'para')
                              }
                              isMulti={false}
                              isDisabled={false}
                              openAddNew={() => ({})}
                            />
                          )}
                        />
                        {errors.paragraph && (
                          <span className="form-text form-error">
                            This is a required field
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 mb-3">
                  <label className="form-label">Level / Competency</label>
                  <div className="">
                    <Controller
                      control={control}
                      name="competence"
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <StylishNewSelect
                          options={allComp}
                          defaultValue={value}
                          value={value || compAfterSave}
                          onChange={(e) => {
                            if (e?.value === 'new') {
                              clearErrors('competence');
                              setShowAddCompDialog(true);
                            } else if (!e) {
                              setCompAfterSave('');
                              setError('competence');
                            } else if (e.value) {
                              clearErrors('competence');
                            }
                            return onChange(e);
                          }}
                          placeholder={'Add new or Select'}
                          isClearable={true}
                          isSearchable={true}
                          filterOption={(option, searchText) =>
                            customFilter(option, searchText, 'comp')
                          }
                          isMulti={false}
                          isDisabled={false}
                          openAddNew={() => ({})}
                        />
                      )}
                    />
                    {errors.competence && (
                      <span className="form-text form-error">
                        This is a required field
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-12 mt-3">
                  {/* This row will be a repeater */}
                  {fields.map((field, index) => (
                    <div key={field.id}>
                      <hr className="m-0" />
                      <div className="row py-4">
                        <div className="col-md-12 col-xl-4 mb-3">
                          <label className="form-label">
                            Task / Behavior from PTB
                          </label>
                          <div className="">
                            <Controller
                              control={control}
                              name={`task.${index}.taskid`}
                              rules={{ required: true }}
                              render={({ field: { onChange, value } }) => (
                                <StylishNewSelect
                                  options={allTask}
                                  defaultValue={value}
                                  value={value}
                                  onChange={(e) => {
                                    if (e?.value === 'new') {
                                      setShowAddTaskDialog(true);
                                      clearErrors(`task.${index}.taskid`);
                                      setIndex(index);
                                    } else if (!e) {
                                      setTaskAfterSave('');
                                      setError(`task.${index}.taskid`);
                                    } else if (e.value) {
                                      clearErrors(`task.${index}.taskid`);
                                    }
                                    return onChange(e);
                                  }}
                                  placeholder={'Add new or Select'}
                                  isClearable={true}
                                  isSearchable={true}
                                  filterOption={(option, searchText) =>
                                    customFilter(option, searchText, 'task')
                                  }
                                  isMulti={false}
                                  isDisabled={false}
                                  openAddNew={() => ({})}
                                />
                              )}
                            />
                            {errors?.task?.length &&
                              errors?.task[index]?.taskid && (
                                <span className="form-text form-error">
                                  This is a required field
                                </span>
                              )}
                          </div>
                        </div>
                        <div className="col-md-12 col-xl-4 mb-3">
                          <label className="form-label">
                            Standard / Measurement
                          </label>
                          <div className="">
                            <Controller
                              control={control}
                              name={`task.${index}.measureid`}
                              rules={{ required: true }}
                              render={({ field: { onChange, value } }) => (
                                <StylishNewSelect
                                  options={allStandard}
                                  defaultValue={value}
                                  value={value}
                                  onChange={(e) => {
                                    if (e?.value === 'new') {
                                      setShowAddStandardDialog(true);
                                      clearErrors(`task.${index}.measureid`);
                                      setIndex(index);
                                    } else if (!e) {
                                      setStandardAfterSave('');
                                      setError(`task.${index}.measureid`);
                                    } else if (e.value) {
                                      clearErrors(`task.${index}.measureid`);
                                    }
                                    return onChange(e);
                                  }}
                                  placeholder={'Add new or Select'}
                                  isClearable={true}
                                  isSearchable={true}
                                  isMulti={false}
                                  filterOption={(option, searchText) =>
                                    customFilter(option, searchText, 'standard')
                                  }
                                  isDisabled={false}
                                  openAddNew={() => ({})}
                                />
                              )}
                            />
                            {errors?.task?.length &&
                              errors?.task[index]?.measureid && (
                                <span className="form-text form-error">
                                  This is a required field
                                </span>
                              )}
                          </div>
                        </div>
                        <div className="col-md-12 col-xl-4 mb-3">
                          <label className="form-label">Code / Condition</label>
                          <div className="">
                            <Controller
                              control={control}
                              name={`task.${index}.codeid`}
                              rules={{ required: true }}
                              render={({ field: { onChange, value } }) => (
                                <StylishNewSelect
                                  options={allCode}
                                  defaultValue={value}
                                  value={value}
                                  onChange={(e) => {
                                    if (e?.value === 'new') {
                                      setShowAddCodeDialog(true);
                                      clearErrors(`task.${index}.codeid`);
                                      setIndex(index);
                                    } else if (!e) {
                                      setCodeAfterSave('');
                                      setError(`task.${index}.codeid`);
                                    } else if (e.value) {
                                      clearErrors(`task.${index}.codeid`);
                                    }
                                    return onChange(e);
                                  }}
                                  placeholder={'Add new or Select'}
                                  isClearable={true}
                                  isSearchable={true}
                                  filterOption={(option, searchText) =>
                                    customFilter(option, searchText, 'code')
                                  }
                                  isMulti={false}
                                  isDisabled={false}
                                  openAddNew={() => ({})}
                                />
                              )}
                            />
                            {errors?.task?.length &&
                              errors?.task[index]?.codeid && (
                                <span className="form-text form-error">
                                  This is a required field
                                </span>
                              )}
                          </div>
                        </div>
                        <div className="col-md-12 button-group">
                          <StylishNewButton
                            type="button"
                            className="button--icon primary"
                            onClick={() =>
                              append({
                                taskid: '',
                                measureid: '',
                                codeid: '',
                                // workspaceId: workspaceId,
                                // trainingObjectiveId: props.id,
                              })
                            }
                          >
                            <SharedIcon iconName="add" bold />
                          </StylishNewButton>
                          {fields.length > 1 ? (
                            <StylishNewButton
                              type="button"
                              className="button--icon primary ms-2"
                              onClick={() => remove(index)}
                            >
                              <SharedIcon iconName="delete" />
                            </StylishNewButton>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="col-md-12 mt-4">
              <div className="button-group d-block d-md-flex">
                <StylishNewButton
                  type="button"
                  className="button button--tertiary button--reverse ms-auto w-100 w-md-auto"
                  onClick={async () => {
                    const valid = await trigger();

                    if (valid) {
                      saveAndExitHseep();
                    }
                  }}
                >
                  Save &amp; Exit
                </StylishNewButton>
                <StylishNewButton
                  type="submit"
                  className="button button--primary w-100 w-md-auto mt-3 mt-md-0 ms-0 ms-md-3"
                >
                  Next
                </StylishNewButton>
              </div>
            </div>
          </form>
          {/* HSEEP Version */}
          {showAddSourceDialog && (
            <AddSourceDialog
              show={showAddSourceDialog}
              onClose={OnAddSource}
              dialogType={dialogType}
              updateDialogData={updateDialogData}
            />
          )}
          {showAddReferenceDialog && (
            <AddReferenceDialog
              show={showAddReferenceDialog}
              onClose={OnAddReference}
              dialogType={dialogType}
              updateDialogData={updateDialogData}
            />
          )}
          {showAddParaDialog && (
            <AddParaDialog
              show={showAddParaDialog}
              onClose={OnAddParagraph}
              dialogType={dialogType}
              updateDialogData={updateDialogData}
            />
          )}
          {showAddCompDialog && (
            <AddCompDialog
              show={showAddCompDialog}
              onClose={OnAddCompetence}
              dialogType={dialogType}
              updateDialogData={updateDialogData}
            />
          )}
          {showAddTaskDialog && (
            <AddTaskDialogFromArray
              show={showAddTaskDialog}
              onClose={OnAddTask}
              dialogType={dialogType}
              updateDialogData={updateDialogData}
            />
          )}
          {showAddStandardDialog && (
            <AddStandardDialogFromArray
              show={showAddStandardDialog}
              onClose={OnAddStandard}
              dialogType={dialogType}
              updateDialogData={updateDialogData}
            />
          )}
          {showAddCodeDialog && (
            <AddCodeDialogFromArray
              show={showAddCodeDialog}
              onClose={OnAddCode}
              dialogType={dialogType}
              updateDialogData={updateDialogData}
            />
          )}
        </div>
      )}
    </>
  );
}
