import React, { useState, useEffect, useRef } from 'react';
import { Link, NavLink, useNavigate, useParams } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import AddProfilestoPersonaDialog from '../Personas/AddProfilestoPersonaDialog';
import ReactPaginate from 'react-paginate';
import Help from '../../../component/Help/Help';
import { EditNetworkHelp, useSelfHostedKnowledgeBase } from 'assets/data/HelpContent';
import { useSelector, useDispatch } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import IconTimes from 'assets/images/icon__times.svg';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import IconEdit from 'assets/images/icon__edit.svg';
import IconDelete from 'assets/images/icon__delete.svg';
import ProfileImage from 'assets/images/icon__profileimage.jpg';
import IconView from 'assets/images/icon__eye.svg';
import IconChooseMedia from 'assets/images/icon__choosemedia.svg';
import ChooseMediaDialog from '../ChooseMediaModal/ChooseMediaDialog';
import AddPersonasDialog from './AddPersonasDialog';
import ReviewPersonasDialog from './ReviewPersonasDialog';
import { toast } from 'react-toastify';
import API from '../../../service/api';
import Network from '../../../service/Network';
import { PreparePath } from 'utils/sharedUtils/sharedConst';
import StylishNewInput from 'components/DesignSystems/New/StylishNewInput';
import StylishTextArea from 'components/DesignSystems/form/StylishTextArea';
import { StylishConfirmDialog } from 'components/DesignSystems/New/StylishConfirmDialog';
import moment from 'moment';
import { toastConfig } from 'assets/data/config';
import StylishHelp from 'components/DesignSystems/New/StylishHelp';
import IconEmptyExercise from 'assets/images/icon__exercise-details-empty.svg';
import StylishNewTextArea from 'components/DesignSystems/New/StylishNewTextArea';
import { endLoading, startLoading } from "../../../../reducers/loading/loading.action";

export default function EditNetwork() {
  const {
    handleSubmit,
    formState: { errors },
    setValue,
    control,
  } = useForm();

  const selfHostedKnowledgeBase = useSelfHostedKnowledgeBase();

  const [
    showAddProfilestoPersonaDialog,
    setShowAddProfilestoPersonaDialog,
  ] = useState(false);
  const [deletePersona, setDeletePersona] = useState(null);
  const [connectedPersonas, setConnectedPersonas] = useState([]);
  const [
    connectedPersonasForDropDown,
    setConnectedPersonasForDropDown,
  ] = useState([]);

  const [allNetworks, setAllNetworks] = useState([]);
  const [networksTotal, setNetworksTotal] = useState(0);
  const [imageAvatar, setImageAvatar] = useState(null);
  const [isVisibleAvatar, setIsVisibleAvatar] = useState(false);
  const [filteredPersonaArray, setFilteredPersonaArray] = useState([]);
  const [showReviewProfilesDialog, setShowReviewProfilesDialog] = useState(
    false
  );
  const [message, setMessage] = useState('');
  const [showChooseMediaDialog, setShowChooseMediaDialog] = useState(false);
  const [showReviewPersonasDialog, setShowReviewPersonasDialog] = useState(
    false
  );
  const [keyPressForNetworkName, setKeyPressForNetworkName] = useState('');
  const [imageAvatarFrom, setImageAvatarFrom] = useState(false);

  const reduxUser = useSelector((state) => state.prepare.user);

  const workspaceId = useSelector(
    (state) => state.prepare.workspace.selectedWorkspace
  );

  const isMounted = useRef(true);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const id = useParams();

  const onChooseMediaDialog = () => {
    setShowChooseMediaDialog(true);
  };
  const onChooseMediaDialogClose = () => {
    setShowChooseMediaDialog(false);
  };

  const onAddPersonatoNetworkDialog = (e) => {
    e.preventDefault();
    setShowAddProfilestoPersonaDialog(true);
  };
  const onAddPersonatoNetworkDialogClose = () => {
    setShowAddProfilestoPersonaDialog(false);
  };
  const onIsCloseModal = (data) => {
    if (data === true) {
      setShowAddProfilestoPersonaDialog(false);
    }
  };
  const onReviewPersonaDialog = () => {
    setShowReviewProfilesDialog(true);
    setShowAddProfilestoPersonaDialog(false);
  };

  const onGetPersonaArray = (data) => {
    const filteredArray = data?.map(function (mediaPersona) {
      return { mediaPersonaId: mediaPersona?.value };
    });
    setFilteredPersonaArray(filteredArray);
    setConnectedPersonasForDropDown(data);
    let statusUpdateRequired = false;
    if (data?.length === connectedPersonas?.length) {
      for (let i = 0; i < data?.length; i++) {
        if (data[i]?.value !== connectedPersonas[i]?.mediaPersonaId) {
          setMessage('* Pending updates');
          statusUpdateRequired = true;
          break;
        }
      }
    } else {
      setMessage('* Pending updates');
      statusUpdateRequired = true;
    }
    statusUpdateRequired === false ? setMessage('') : null;
  };

  useEffect(() => {
    if (isMounted.current) {
      isMounted.current = false;
      return;
    }
    if (!!workspaceId) {
      navigate(-1);
    }
  }, [workspaceId]);

  const onReviewPersonasDialogClose = () => {
    setShowReviewPersonasDialog(false);
  };

  const ondeletePersona = (row) => {
    setDeletePersona(row.mediaPersonaId);
  };

  const onSubmitImageAvatar = (data) => {
    setShowChooseMediaDialog(false);
    if (data !== undefined) {
      setImageAvatar(data);
      setIsVisibleAvatar(true);
    }
  };
  const onKeyPressForNetworkName = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // Prevent the form from submitting
      let networkName = document.getElementById('name');
      setKeyPressForNetworkName(networkName.value);
    }
  };

  const onSubmit = async (data) => {
    dispatch(startLoading());

    try {
      await Network.post(API.editNetworkById, {
        networkId: id?.id,
        name: data?.networkName,
        description: data?.networkDescription,
        personas: filteredPersonaArray,
        workspaceId: workspaceId,
        avatar: imageAvatar,
        updatedAt: moment().toISOString(),
      });
      toast.success('Network updated successfully', toastConfig);
      navigate(`${PreparePath}/dashboard/media/networks`);
    } catch (error) {
      toast.error(error.response.data.response.status.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };

  const getNetworkDetails = async () => {
    dispatch(startLoading());
    try {
      await Network.get(API.getNetworkById, {
        workspaceId: workspaceId,
        networkId: id?.id,
      })
        .then((resp) => {
          setAllNetworks(resp.data.response.dataset);
          resp.data.response.dataset[0]?.mediapersonas &&
            setNetworksTotal(
              resp.data.response.dataset[0]?.mediapersonas?.length
            );

          setConnectedPersonas(resp.data.response.dataset[0]?.mediapersonas);
          const filteredPersonaArrayForDropDown = resp.data.response.dataset[0]?.mediapersonas?.map(
            function (mediaPersona) {
              return {
                value: mediaPersona?.mediaPersonaId,
                label: mediaPersona?.name,
                avatar: mediaPersona?.avatar,
              };
            }
          );

          setConnectedPersonasForDropDown(filteredPersonaArrayForDropDown);

          const filteredArray = resp.data.response.dataset[0]?.mediapersonas?.map(
            function (mediaPersona) {
              return { mediaPersonaId: mediaPersona?.mediaPersonaId };
            }
          );
          setFilteredPersonaArray(filteredArray);
        })
        .catch(console.log)
        .finally(() => {
          dispatch(endLoading());
        });
    } catch (error) {
      toast.error(error, toastConfig);
      dispatch(endLoading());
    }
  };

  const onSubmitImageAvatarFrom = (data) => {
    if (data !== undefined) {
      setImageAvatarFrom(data);
    }
  };

  useEffect(() => {
    getNetworkDetails();
  }, [reduxUser]);

  useEffect(() => {
    setValue('networkName', allNetworks[0]?.networkName);
    setValue('networkDescription', allNetworks[0]?.networkDescription);
    setImageAvatar(allNetworks[0]?.avatar);
    allNetworks[0]?.avatar?.length > 0
      ? setIsVisibleAvatar(true)
      : setIsVisibleAvatar(false);
  }, [allNetworks]);

  const onDeleteConfirmPersona = async () => {
    dispatch(startLoading());

    try {
      await Network.post(API.deleteMediaNetworkPersona, {
        personaId: deletePersona,
        networkId: id?.id,
      });
      setFilteredPersonaArray(
        filteredPersonaArray?.filter(
          (obj) => obj.mediaPersonaId !== deletePersona
        )
      );
      setConnectedPersonas(
        connectedPersonas?.filter((obj) => obj.mediaPersonaId !== deletePersona)
      );
      setConnectedPersonasForDropDown(
        connectedPersonas?.filter((obj) => obj.mediaPersonaId !== deletePersona)
      );
      setDeletePersona(null);

      toast.success('Persona removed successfully', toastConfig);
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(endLoading());
    }
  };

  return (
    <>
      <div className="d-flex align-items-center mb-3">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb mb-0">
            <li className="breadcrumb-item">
              <StylishNewButton onClick={() => navigate(-1)}>
                <i className="fa fa-angle-left me-2" aria-hidden="true"></i>
                Back
              </StylishNewButton>
            </li>
            <li className="breadcrumb-item">
              <StylishNewButton
                onClick={() =>
                  navigate(`${PreparePath}/dashboard/media/networks`)
                }
              >
                Networks
              </StylishNewButton>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              {keyPressForNetworkName || allNetworks[0]?.networkName}
            </li>
          </ol>
        </nav>
        <StylishHelp
          classes={'ms-auto sml flex-shrink-0'}
          title={EditNetworkHelp.title}
          content={EditNetworkHelp.content}
          link={useSelfHostedKnowledgeBase || EditNetworkHelp.link}
        />
      </div>

      <form
        onSubmit={handleSubmit(onSubmit)}
        onKeyPress={(e) => e.key === 'Enter' && e.preventDefault()}
      >
        <div className="form-block mb-4">
          <div className="mb-3">
            <label className="form-label">Name</label>
            <Controller
              control={control}
              name="networkName"
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <StylishNewInput
                  type="text"
                  className="form-control"
                  placeholder="Name"
                  name="networkName"
                  onChange={onChange}
                  value={value || ''}
                  id="name"
                  onKeyUp={onKeyPressForNetworkName}
                />
              )}
            />
            {errors?.networkName && (
              <span className="form-text form-error">
                This is a required field
              </span>
            )}
          </div>
          <div className="mb-3">
            <label className="form-label">Description</label>
            <Controller
              control={control}
              name="networkDescription"
              rules={{ required: false }}
              render={({ field: { onChange, value } }) => (
                <StylishNewTextArea
                  type="text"
                  className="form-control form-textarea"
                  onChange={onChange}
                  value={value || ''}
                />
              )}
            />
          </div>
          <div>
            <label className="form-label d-block"> Avatar</label>
            <span
              className="badge cursor-pointer"
              onClick={onChooseMediaDialog}
            >
              <span className="icon">
                <img src={IconChooseMedia} alt="" />
                Choose Media
              </span>
            </span>
            {isVisibleAvatar && (
              <>
                <div className="d-flex align-items-start mt-3">
                  <img
                    src={imageAvatar}
                    className="me-3 rounded-5"
                    style={{ height: '200px', width: 'auto' }}
                    alt=""
                  />
                  <StylishNewButton
                    customButton
                    className={'button--icon'}
                    onClick={(e) => {
                      setIsVisibleAvatar(false);
                      setImageAvatar(null);
                    }}
                  >
                    <img src={IconTimes} alt="" />
                  </StylishNewButton>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="form-block mb-4">
          <div className="d-flex align-items-center mb-4">
            <StylishNewButton
              className="button--primary"
              // onClick={onAddPersonasDialog}

              onClick={(e) => {
                onAddPersonatoNetworkDialog(e);
              }}
            >
              Add Personas
            </StylishNewButton>
            <span className="ms-3">{message}</span>
          </div>

          {connectedPersonas.length > 0 ? (
            <div className="row">
              {connectedPersonas &&
                connectedPersonas.length > 0 &&
                connectedPersonas?.map((persona) => {
                  return (
                    <div
                      className="col-md-6 col-lg-4 col-xl-3 mb-4"
                      key={persona?.id}
                    >
                      <div className="border-700 rounded h-100 d-flex flex-column overflow-hidden">
                        <div className="p-3">
                          <div className="d-flex align-items-start mb-4">
                            {persona?.avatar ? (
                              <div className="profile-img">
                                <img src={persona?.avatar} alt="" />
                              </div>
                            ) : (
                              <div className="profile-img"></div>
                            )}

                            <Dropdown className="flex-shrink-0 ms-auto">
                              <Dropdown.Toggle className="button--icon">
                                <i
                                  className="fa fa-ellipsis-v"
                                  aria-hidden="true"
                                ></i>
                              </Dropdown.Toggle>
                              <Dropdown.Menu align="end">
                                <Dropdown.Item
                                  onClick={() => {
                                    navigate(
                                      `${PreparePath}/dashboard/media/edit-personas/useblankpersona/${persona.mediaPersonaId}`
                                    );
                                  }}
                                >
                                  <img src={IconEdit} alt="" />
                                  Edit Persona
                                </Dropdown.Item>
                                <Dropdown.Item
                                  onClick={() => {
                                    ondeletePersona(persona);
                                  }}
                                >
                                  <img src={IconDelete} alt="" />
                                  Remove From Network
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>

                          <h4 className="mb-2 break-word">{persona?.name}</h4>
                          <span className="color-brand">
                            {persona?.count} Linked Profiles
                          </span>
                        </div>

                        <div className="bg-gray-700 p-3 mt-auto d-flex align-items-center justify-content-center">
                          <Link
                            to={`${PreparePath}/dashboard/media/edit-personas/useblankpersona/${persona.mediaPersonaId}`}
                            className="text-white weight-500 d-flex align-items-center justify-content-center flex-grow-1"
                          >
                            <img src={IconEdit} className="me-2" alt="" />
                            Edit
                          </Link>
                          <div className="separator--y separator--800  flex-shrink-0"></div>

                          <Link
                            to={`${PreparePath}/dashboard/media/view-persona/${persona.mediaPersonaId}`}
                            className="text-white weight-500 d-flex align-items-center justify-content-center flex-grow-1"
                          >
                            <img
                              src={IconView}
                              className="img-h-16 me-2"
                              alt=""
                            />
                            View
                          </Link>
                        </div>
                      </div>
                    </div>
                    // </div>
                  );
                })}
            </div>
          ) : (
            <div className="bg-gray-800 text-center p-4">
              <div className="d-flex justify-content-center mb-4">
                <img src={IconEmptyExercise} alt="" />
              </div>
              <p className="weight-600 mb-0">No Personas Found</p>
            </div>
          )}
        </div>

        <div className="button-group justify-content-end">
          <StylishNewButton
            className="button--secondary button--reverse"
            type="button"
            onClick={(e) => {
              e.preventDefault();
              navigate(`${PreparePath}/dashboard/media/networks`);
            }}
          >
            Cancel
          </StylishNewButton>
          <StylishNewButton
            // onClick={onSubmit}
            className="button--primary"
            type="submit"
          >
            Save
          </StylishNewButton>
        </div>
      </form>
      {showChooseMediaDialog && (
        <ChooseMediaDialog
          show={showChooseMediaDialog}
          onClose={onChooseMediaDialogClose}
          onSubmitImageAvatar={onSubmitImageAvatar}
          typeModalProfile={'Avatar'}
          onSelectedImageAvatar={imageAvatar}
          imageAvatarFrom={imageAvatarFrom}
          onSubmitImageAvatarFrom={onSubmitImageAvatarFrom}

          // updateDialogData={updateDialogData}
        />
      )}

      {showAddProfilestoPersonaDialog && (
        <AddPersonasDialog
          show={showAddProfilestoPersonaDialog}
          onClose={onAddPersonatoNetworkDialogClose}
          onReviewPersonaDialog={onReviewPersonaDialog}
          isCloseModal={onIsCloseModal}
          profileSelectToCreatePersonaPage={onGetPersonaArray}
          imageAvatar={imageAvatar}
          connectedPersonasForDropDown={connectedPersonasForDropDown}
          keyPressForNetworkName={
            keyPressForNetworkName || allNetworks[0]?.networkName
          }
        />
      )}

      {showReviewPersonasDialog && (
        <ReviewPersonasDialog
          show={showReviewPersonasDialog}
          onClose={onReviewPersonasDialogClose}
        />
      )}
      {deletePersona && (
        <StylishConfirmDialog
          show={deletePersona}
          onClose={() => setDeletePersona(null)}
          dialogTitle={'Delete Persona from network'}
          dialogContent={'Are you sure you want this?'}
          onConfirm={onDeleteConfirmPersona}
        />
      )}
    </>
  );
}
