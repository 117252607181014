import Network from 'PREPAREsrc/service/Network';
import API from 'PREPAREsrc/service/api';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { PreparePath } from 'utils/sharedUtils/sharedConst';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timegrid from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import ViewPostDialog from '../ViewPostDialog/ViewPostDialog';
import { calendarStringLength, toastConfig } from 'assets/data/config';
import {
  endLoading,
  startLoading,
} from '../../../../reducers/loading/loading.action';
import PageTitle from 'components/headers/PageTitle';
import { useNewUIFlag } from 'utils/featureFlags';

export const PostsCalendar = () => {
  const navigate = useNavigate();

  const reduxDispatch = useDispatch();

  const { selectedWorkspace } = useSelector((state) => state.prepare.workspace);

  const newUI = useNewUIFlag();

  const [postCalendarData, setPostCalendarData] = useState();
  const [postCalendarRefinedData, setPostCalendarRefinedData] = useState();
  const [postCalendarSelectedData, setPostCalendarSelectedData] = useState(
    null
  );
  const [showSelectedEventDialog, setShowSelectedEventDialog] = useState(false);
  const [selectedEventId, setSelectedEventId] = useState(false);

  const tilteModifier = function htmlToText(html) {
    var temp = document.createElement('div');
    temp.innerHTML = html;
    return temp.textContent; // Or return temp.innerText if you need to return only visible text. It's slower.
  };

  const fetchPostCalendarData = async () => {
    reduxDispatch(startLoading());
    try {
      const data = await Network.get(API.fetchAllPostCalendarData, {
        workspaceId: selectedWorkspace,
      });
      setPostCalendarData(data.data.response.calendarData);
    } catch (error) {
      toast.error(error?.response?.data?.response?.status?.msg, toastConfig);
    } finally {
      reduxDispatch(endLoading());
    }
  };

  useEffect(() => {
    fetchPostCalendarData();
  }, [selectedWorkspace]);

  useEffect(() => {
    if (postCalendarData && postCalendarData.length) {
      const data = postCalendarData.map((i) => {
        const titletext = tilteModifier(i?.post);
        const refinedData = {
          title:
            titletext?.length < calendarStringLength
              ? titletext
              : `${titletext?.slice(0, calendarStringLength)}...`,

          start: i?.publishTime,
          end: i?.publishTime,
          id: i?.id,
          textEscape: false,
        };
        return refinedData;
      });
      setPostCalendarRefinedData(data);
    } else {
      setPostCalendarRefinedData([]);
    }
  }, [selectedWorkspace, postCalendarData]);

  // const fetchSelectedPostCalendarData = async (eventId) => {
  //   reduxDispatch(startLoading());
  //   try {
  //     const data = await Network.get(API.fetchSelectedPostCalendarData, {
  //       postId: eventId,
  //     });
  //     setPostCalendarSelectedData(data.data.response.dataset[0]);
  //     setShowSelectedEventDialog(true);
  //   } catch (error) {
  //     toast.error(error?.response?.data?.response?.status?.msg, toastConfig);
  //   } finally {
  //     reduxDispatch(endLoading());
  //   }
  // };
  return (
    <>
      {newUI ? (
        <PageTitle title="Calendar" parents={['Media', 'Posts']} />
      ) : (
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <button onClick={() => navigate(-1)}>
                <i className="fa fa-angle-left me-2" aria-hidden="true"></i>
                Back
              </button>
            </li>
            <li className="breadcrumb-item">
              <Link to={`${PreparePath}/dashboard/media/posts`}>Posts</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Calendar
            </li>
          </ol>
        </nav>
      )}

      <div className="form-block">
        <div className="calendar">
          <FullCalendar
            validRange={''}
            plugins={[dayGridPlugin, timegrid, listPlugin, interactionPlugin]}
            headerToolbar={{
              left: 'prev,next today',
              center: 'title',
              right: 'timeGridDay,timeGridWeek,dayGridMonth,listMonth',
            }}
            initialView="dayGridMonth"
            events={postCalendarRefinedData}
            dayMaxEvents={true}
            eventClick={(e) => {
              setSelectedEventId(e.event._def.publicId);
              setShowSelectedEventDialog(true);
            }}
            displayEventTime={false}
          />
        </div>
      </div>
      {showSelectedEventDialog && (
        <ViewPostDialog
          show={showSelectedEventDialog}
          onClose={() => {
            setShowSelectedEventDialog(false);
            // setPostCalendarSelectedData();
          }}
          postId={selectedEventId}
        />
      )}
    </>
  );
};
