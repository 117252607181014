import React, { useEffect, useRef, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { AllIcon } from '../../../Settings/InjectMethod/Icon';
import { MSELCreate } from './MSELCreate';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import API from '../../../../service/api';
import Network from '../../../../service/Network';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment/moment';
import IconEmpty from 'assets/images/icon__exercise-details-empty.svg';
import {
  HelpPlanInjects,
  MiscellaneousHelpLink,
  useSelfHostedKnowledgeBase,
} from 'assets/data/HelpContent';
import { CSVLink } from 'react-csv';
import Dropdown from 'react-bootstrap/Dropdown';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import fileDownload from 'js-file-download';
import StylishNewTableAction from 'components/DesignSystems/New/StylishNewTableAction';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { PreparePath } from 'utils/sharedUtils/sharedConst';
import { StylishNewSearchBar } from 'components/DesignSystems/New/StylishNewSearchBar';
import StylishHelp from 'components/DesignSystems/New/StylishHelp';
import StylishNewSelect from 'components/DesignSystems/New/StylishNewSelect';
import StylishNewTable from 'components/DesignSystems/New/StylishNewTable';
import { toastConfig } from 'assets/data/config';
import { StylishConfirmDialog } from 'components/DesignSystems/New/StylishConfirmDialog';
import { PaginationDropdown } from '../../../../../components/DesignSystems/PaginationDropdown';
import {
  endLoading,
  startLoading,
} from '../../../../../reducers/loading/loading.action';
import PageTitle from 'components/headers/PageTitle';
import { useNewUIFlag } from 'utils/featureFlags';
import { SharedIcon } from 'components/SharedIcon/SharedIcon';

export const MSELList = (props) => {
  const [showCreateMSELInject, setShowCreateMSELInject] = useState(false);
  const [allInjectList, setAllInjectList] = useState([]);
  const [totalInject, setTotalInject] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [perPage, setPerPage] = useState(5);
  const [pages, setPage] = useState(1);
  const [eventList, setEventList] = useState([]);
  const [events, setEvents] = useState({ value: '', label: 'All Scenarios' });
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState(false);
  const [orderBy, setOrderBy] = useState('');
  const [allInjectListForExport, setAllInjectListForExport] = useState([]);
  const reduxUser = useSelector((state) => state.prepare.user);
  const { selectedWorkspace, workspaceName, type: workspaceType } = useSelector(
    (state) => state.prepare.workspace
  );
  const reduxTimezone = useSelector(
    (state) => state.prepare.workspace.timezone
  );

  const selfHostedKnowledgeBase = useSelfHostedKnowledgeBase()

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const csvLink = useRef();

  const newUI = useNewUIFlag();

  const { control, setValue } = useForm();

  const allInjectListHeader = [
    { label: 'Date/Time', key: 'date_time' },
    { label: '#', key: 'number' },
    { label: 'Title', key: 'title' },
    { label: 'Status', key: 'status' },
    { label: 'Owner', key: 'owner' },
    { label: 'Type', key: 'type' },
    { label: 'Inject Method', key: 'method' },
    { label: 'Scenario', key: 'event' },
    { label: 'From', key: 'from' },
    { label: 'To', key: 'to' },
    { label: 'Trigger', key: 'trigger' },
    { label: 'Anticipated Response / Expected Player Action', key: 'response' },
    { label: 'TrainingObjective', key: 'trainingobjective' },
    { label: 'Remarks', key: 'remarks' },
  ];

  const pdfHeaders = [
    'Date/Time',
    '# Title',
    'Status Owner',
    'Type',
    ' Inject Method',
    // "Type Method",
    'Scenario',
    'From->To',
    'Trigger',
    'Anticipated Response / Expected Player Action',
    ' Objectives',
    'Remarks',
  ];

  const training_method = [
    { value: 'live', label: 'Live' },
    { value: 'virtual', label: 'Virtual' },
    { value: 'constructive', label: 'Constructive' },
    { value: 'integrated_lvc', label: 'Integrated LVC' },
  ];

  const pageDropDown = [
    { value: '5', label: '5 per page' },
    { value: '15', label: '15 per page' },
    { value: '30', label: '30 per page' },
    { value: '50', label: '50 per page' },
  ];

  const typeList = [
    { value: 'key', label: 'Key' },
    { value: 'enabling', label: 'Enabling' },
    { value: 'supporting', label: 'Supporting' },
  ];

  useEffect(() => {
    if (!!workspaceType && workspaceType === 'T&E') {
      navigate(`${PreparePath}/dashboard/plan`);
    }
  }, [workspaceType]);

  const fetchAllInject = async (type) => {
    dispatch(startLoading());
    try {
      const inject = await Network.get(API.fetchAllInject, {
        workspaceId: selectedWorkspace,
        limit: !type && perPage,
        page: !type && pages,
        search: searchTerm,
        eventId: props.eventId ? props.eventId : events?.value,
        sort: orderBy,
      });
      const injects = inject.data.response.dataset.map((element) => {
        if (
          element.statusLabel === 'Scheduled' &&
          moment().isAfter(moment(element.responseDate))
        ) {
          let selectType = typeList.filter((tl) => tl.value === element.type);

          return {
            ...element,
            title:
              (element.number ? `#${element.number} - ` : '') +
              (element.injectTitle || ''),
            statusOwner: element.statusLabel + ' ' + element.injetOwnerTitle,
            typeMethod: selectType[0]?.label + element.methodName,
            updatedat: moment(element.updatedAt).fromNow(),
            type: selectType[0]?.label,
            is_past_due: true,
            from: element?.from?.title,
            to: element?.to?.title,
          };
        } else {
          let selectType = typeList.filter((tl) => tl.value === element.type);
          return {
            ...element,
            title:
              (element.number ? `#${element.number} - ` : '') +
              (element.injectTitle || ''),
            statusOwner: element.statusLabel + ' ' + element.injetOwnerTitle,
            typeMethod: selectType[0]?.label + element.methodName,
            updatedat: moment(element.updatedAt).fromNow(),
            type: selectType[0]?.label,
            from: element?.from?.title,
            to: element?.to?.title,

            is_past_due: false,
          };
        }
      });

      if (!type) {
        setTotalInject(inject.data.response.total);
        setValue(
          'perPage',
          pageDropDown.find((data) => data.value == perPage)
        );
        setAllInjectList(injects);
      }
      if (type && type === 'PDF') {
        formatDataForDownload(inject, type);
      }
      if (type && type === 'CSV') {
        formatDataForDownload(inject, type);
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(endLoading());
    }
  };

  const formatDataForDownload = (inject, type) => {
    const injectsForExport = inject.data.response.dataset.map((element) => {
      const toData = toDataString(element.trainingobjective);
      return {
        date_time: moment(element.startDate).isValid()
          ? moment(element.startDate).format(
              reduxUser?.timeFormat === 'hour12Mode'
                ? 'YYYY-MM-DD - hh:mm a'
                : 'YYYY-MM-DD - HH:mm'
            ) +
            ' (' +
            reduxTimezone.split('/')[0] +
            ')'
          : null,
        number: element.number,
        title: element.injectTitle,
        status: element.statusLabel,
        owner: element.injetOwnerTitle,
        type: element.type,
        method: element.methodName,
        event: element.planEvent,
        from: element?.from?.title,
        to: element?.to?.title,
        trigger: element.trigger,
        response: element.response,
        trainingobjective: toData ? '\u2022 ' + toData : '',
        remarks: element.remarks,
      };
    });

    if (type === 'CSV') {
      csvLink.type = 'CSV';
      setAllInjectListForExport(injectsForExport);
    }
    if (type === 'PDF') {
      generatePDF(injectsForExport);
    }
  };

  const toDataString = (trainingobjective) => {
    return trainingobjective
      ?.map((tO) => {
        const jmet =
          (tO.jmetLevelWar || '') +
          ' ' +
          (tO.jmetTasknumber || '') +
          ' ' +
          (tO.jmetTaskTitle || '');

        const returnToData =
          (tO.participantName || '') +
          ' ' +
          (jmet.trim() ? ` - ${jmet}` : '') +
          ' ' +
          (tO.trainedMethodType
            ? `(${
                training_method.find(
                  (data) => data.value === tO.trainedMethodType
                )?.label
              })`
            : '');

        return returnToData;
      })
      .join('\n\u2022 ');
  };

  const generatePDF = (injects) => {
    const pdfData = [];
    injects.map((element) => {
      pdfData.push([
        element['date_time'],
        element.number ? element.number + ' - ' + element.title : element.title,
        (element.status || '') + ' ' + (element.owner || ' '),
        element.type || '',
        element.method || ' ',
        element.event || '',
        (element?.from || '') + '->' + (element?.to || ''),
        element.trigger || '',
        element.response || '',
        element.trainingobjective || '',
        element.remarks || '',
      ]);
    });
    const docObj = new jsPDF({
      orientation: 'landscape',
    });

    autoTable(docObj, {
      rowPageBreak: 'avoid',
      theme: 'grid',
      head: [pdfHeaders],
      body: pdfData,
    });

    docObj.save(
      `MSEL ${workspaceName} ` +
        moment().format(
          reduxUser?.timeFormat === 'hour12Mode'
            ? 'YYYY-MM-DD hh:mm a'
            : 'YYYY-MM-DD HH:mm'
        ) +
        '.pdf'
    );
  };

  const fetchEvents = async () => {
    dispatch(startLoading());
    try {
      const events = await Network.get(API.fetchEvents, {
        workspaceId: selectedWorkspace,
      });
      setEventList([
        { value: '', label: 'All Scenarios' },
        ...events.data.response.dataset.map((e) => {
          return {
            value: e.id,
            label: e.name,
          };
        }),
      ]);
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(endLoading());
    }
  };
  const onDeleteConfirm = async () => {
    dispatch(startLoading());
    try {
      await Network.post(API.deleteInject, { id: deleteId });
      if (allInjectList.length >= 1) {
        if (pages > 2 || (pages === 2 && allInjectList.length > 1)) {
          setPage(pages - 1);
        } else {
          setPage(1);
        }
        fetchAllInject();
      } else {
        setPage(1);
        fetchAllInject();
      }
      setShowDeleteModal(false);
      toast.success('Inject deleted successfully', toastConfig);
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(endLoading());
    }
  };

  const onCloseDeleteModal = (e) => {
    setShowDeleteModal(false);
    if (e) {
      fetchAllInject();
    }
  };

  useEffect(() => {
    fetchAllInject();
  }, [
    pages,
    perPage,
    searchTerm,
    events,
    selectedWorkspace,
    orderBy,
    reduxUser,
  ]);

  useEffect(() => {
    fetchEvents();
  }, [selectedWorkspace]);

  useEffect(() => {
    if (csvLink.type === 'CSV') {
      csvLink.current.link.click();
      delete csvLink?.type;
    }
  }, [allInjectListForExport]);

  const onCloseModal = (e) => {
    setShowCreateMSELInject(false);
    if (e === true) {
      fetchAllInject();
    }
  };

  const msel_list_data_col = [
    {
      text: 'Date/Time',
      // eslint-disable-next-line
      formatter: (cell, row, rowIndex) => {
        return (
          <div className="d-block" key={`startDateTime-${rowIndex}`}>
            <span className="d-block">
              {row.startDate ? (
                <span>
                  {moment(row.startDate).format(
                    reduxUser?.timeFormat === 'hour12Mode'
                      ? 'YYYY-MM-DD - hh:mm a'
                      : 'YYYY-MM-DD - HH:mm'
                  )}{' '}
                  ({reduxTimezone.split('/')[0]})
                </span>
              ) : null}
            </span>
            {row.is_past_due && (
              <span
                className="status mt-2"
                style={{ backgroundColor: '#dc3545' }}
              >
                Past Due
              </span>
            )}
          </div>
        );
      },
      sort: true,
      headerAttrs: { title: 'Sort By:' },
      attrs: { title: 'Date/Time' },
      onSort: (field, order) => {
        if (order === 'asc') {
          setOrderBy('injectStartDate_ASC');
        } else if (order === 'desc') {
          setOrderBy('injectStartDate_DESC');
        } else {
          setOrderBy('');
        }
      },
    },
    {
      dataField: 'title',
      text: '# Title',
      sort: true,
      attrs: { title: 'Title' },
      onSort: (field, order) => {
        if (order === 'asc') {
          setOrderBy('number_ASC');
        } else if (order === 'desc') {
          setOrderBy('number_DESC');
        } else {
          setOrderBy('');
        }
      },
    },
    {
      dataField: 'statusOwner',
      // eslint-disable-next-line
      formatter: (cell, row, rowIndex) => {
        return (
          <div className="d-block" key={`statusColor-${rowIndex}`}>
            <span
              className="status mb-2"
              style={{ backgroundColor: row.statusColor }}
            >
              {row.statusLabel}
            </span>
            <span className="d-block">{row.injetOwnerTitle}</span>
          </div>
        );
      },
      text: 'Status Owner',
      attrs: { title: 'Status Owner' },
    },
    {
      dataField: 'type',
      text: 'Type',
      attrs: { title: 'Type' },
    },
    {
      dataField: 'methodName',
      text: 'Inject Method',
      sort: true,
      attrs: { title: 'Inject Method' },
      formatter: (cell, row, rowIndex) => {
        return (
          <div className="d-block" key={`methodColor-${rowIndex}`}>
            <div className="d-flex align-items-center">
              <span
                className="status status--icon me-2"
                style={{ backgroundColor: row.methodColor }}
              >
                {AllIcon.find((icon) => icon.name === row.icon)?.icon}
              </span>
              <span>{row.methodName}</span>
            </div>
          </div>
        );
      },
    },

    {
      text: 'Scenario',
      // eslint-disable-next-line
      formatter: (cell, row, rowIndex) => {
        return (
          <span
            className="status"
            style={{ backgroundColor: row.planEventColor }}
            key={`event-${rowIndex}`}
          >
            {row.planEvent}
          </span>
        );
      },
      attrs: { title: 'Scenario' },
    },
    {
      dataField: 'updatedAt',
      text: 'Last Updated',
      attrs: { title: 'Last Updated' },
      sort: true,
      formatter: (cell, row, rowIndex) => {
        return (
          <span key={`updatedAt-${rowIndex}`}>
            {moment(row.updatedAt).fromNow()}
          </span>
        );
      },

      onSort: (field, order) => {
        if (order === 'asc') {
          setOrderBy('updatedAt_ASC');
        } else if (order === 'desc') {
          setOrderBy('updatedAt_DESC');
        } else {
          setOrderBy('');
        }
        return;
      },
    },
    {
      text: 'Action',
      isDummyField: true,
      formatter: (cell, row, rowIndex) => (
        <StylishNewTableAction
          name="Inject"
          cell={cell}
          row={row}
          rowIndex={rowIndex}
          onDelete={onDeleteMSELList}
          onEdit={onEditMSELList}
          onView={onViewMSELList}
        />
      ),
      attrs: { title: 'Action' },
      headerStyle: {
        width: '100px',
      },
      headerAlign: 'center',
      align: 'center',
    },
  ];

  const expandRow = {
    parentClassName: 'parent-expand-tr',
    className: 'child-expand-tr',
    renderer: (row, rowIndex) => {
      return (
        <div key={`expandRow${rowIndex}`}>
          <div className="row">
            <div className="col-md-4 mb-3">
              <label className="form-label d-block mb-0">From</label>
              {row.from}
            </div>
            <div className="col-md-4 mb-3">
              <label className="form-label d-block mb-0">To</label>
              {row.to}
            </div>
            <div className="w-100 d-none d-md-block"></div>
            <div className="col-md-4 mb-3">
              <label className="form-label d-block mb-0">Trigger</label>
              {row.trigger}
            </div>
            <div className="col-md-4 mb-3">
              <label className="form-label d-block mb-0">
                Anticipated Response / Expected Player Action
              </label>
              {row.response}
            </div>
            <div className="col-md-4 mb-3">
              <label className="form-label d-block mb-0">Remarks</label>
              {row.remarks}
            </div>
            <div className="col-md-4 mb-3 mb-md-0">
              <label className="form-label d-block mb-0">Response Time</label>
              {row?.statusLabel === 'Complete' && row.responseDate ? (
                <span>
                  {moment(row.responseDate).format(
                    reduxUser?.timeFormat === 'hour12Mode'
                      ? 'YYYY-MM-DD - hh:mm a'
                      : 'YYYY-MM-DD - HH:mm'
                  )}{' '}
                  ({reduxTimezone.split('/')[0]})
                </span>
              ) : null}
            </div>
            <div className="col-md-4 mb-3 mb-md-0">
              <label className="form-label d-block mb-0">Actual Response</label>
              {row?.statusLabel === 'Complete' ? row.actualResponse : null}
            </div>
            <div className="col-md-4 m-0">
              <label className="form-label d-block mb-0">Location</label>
              {row?.loc} <br />
              {row?.lat} <br />
              {row?.lng}
            </div>
          </div>

          <hr className="dashed my-4" />
          <div className="row">
            <div className="col-md-4 mb-3 mb-md-0">
              <label className="form-label d-block mb-0">
                Objectives &amp; Trained Method
              </label>
              <p className="m-0 pt-1">
                {row.trainingobjective?.map((tO) => {
                  const jmet =
                    (tO.jmetLevelWar || '') +
                    ' ' +
                    (tO.jmetTaskNumber || '') +
                    ' ' +
                    (tO.jmetTaskTitle || '') +
                    ' ' +
                    (tO.planplatformTitle || '') +
                    ' ' +
                    (tO.platformType || '');
                  return (
                    <span className="d-block mt-2" key={tO.trainingObjectiveId}>
                      &bull; {tO.participantName || ''}{' '}
                      {jmet.trim() ? ` - ${jmet}` : ''}{' '}
                      {tO.trainedMethodType
                        ? `(${
                            training_method.find(
                              (data) => data.value === tO.trainedMethodType
                            )?.label
                          })`
                        : null}
                    </span>
                  );
                })}
                {/* <span className="d-block mt-2">&bull; Demo 1</span> */}
              </p>
            </div>
            <div className="col-md-4 mb-0">
              <label className="form-label d-block mb-0">Attachments</label>
              <p className="m-0 pt-1">
                {row.urls
                  ?.split(',')
                  .filter(
                    (data) => data != '' && data != 'null' && data != null
                  )
                  .map((urlData, index) => {
                    let fullFileName = urlData.split('/')[
                      urlData.split('/').length - 1
                    ];
                    return (
                      <span
                        className="d-flex align-items-center mt-2"
                        key={`url-${index}`}
                      >
                        {
                          fullFileName.split('-')[
                            fullFileName.split('-').length - 1
                          ]
                        }
                        <span
                          className="ms-3 cursor-pointer"
                          onClick={() => downloadFile(urlData)}
                        >
                          <SharedIcon iconName="download" />
                        </span>
                      </span>
                    );
                  })}
              </p>
            </div>
          </div>
          <div className="button-group mt-4">
            <StylishNewButton
              type="button"
              className="button--icon primary"
              onClick={() => onViewMSELList(row)}
            >
              <SharedIcon iconName="visibility" />
            </StylishNewButton>
            <StylishNewButton
              type="button"
              onClick={() => onEditMSELList(row)}
              className="button--icon primary ms-2"
            >
              <SharedIcon iconName="stylus" />
            </StylishNewButton>
            <StylishNewButton
              type="button"
              className="button--icon primary ms-2"
              onClick={() => {
                onDeleteMSELList(row);
              }}
            >
              <SharedIcon iconName="delete" />
            </StylishNewButton>
          </div>
        </div>
      );
    },
    showExpandColumn: true,
    expandByColumnOnly: true,
    expandHeaderColumnRenderer: ({ isAnyExpands }) => {
      if (isAnyExpands) {
        return <i className="fa fa-chevron-down" aria-hidden="true"></i>;
      }
      return <i className="fa fa-chevron-right" aria-hidden="true"></i>;
    },
    expandColumnRenderer: ({ expanded }) => {
      if (expanded) {
        return (
          <div className="d-flex align-items-center justify-content-md-center">
            <span className="me-2">Details</span>
            <i className="fa fa-chevron-down" aria-hidden="true"></i>
          </div>
        );
      }
      return (
        <div className="d-flex align-items-center justify-content-md-center">
          <span className="me-2">Details</span>
          <i className="fa fa-chevron-right" aria-hidden="true"></i>
        </div>
      );
    },
  };

  const onDeleteMSELList = (row) => {
    setShowDeleteModal(true);
    setDeleteId(row.id);
  };

  const onEditMSELList = (row) => {
    navigate(
      `${PreparePath}/dashboard/plan/prepare/msel-all-details/${row.id}`
    );
  };

  const onViewMSELList = (row) => {
    navigate(`${PreparePath}/dashboard/plan/prepare/msel-view/${row.id}`);
  };

  const createHandler = () => {
    setShowCreateMSELInject(true);
  };

  const handlePageClick = (page) => {
    setPage(page);
  };

  const onChangeSearchTerm = (termSearched) => {
    setPage(1);
    setSearchTerm(termSearched);
  };

  const downloadFile = async (file) => {
    let fullFileName = file.split('/')[file.split('/').length - 1];
    try {
      dispatch(startLoading());
      const downloadFile = await Network.post(API.generateSignedUrl, {
        url: file,
      });
      const res = await fetch(downloadFile.data.response.singedUrl, {
        headers: {},
      });

      fileDownload(
        await res.blob(),
        fullFileName.split('-')[fullFileName.split('-').length - 1]
      );
    } catch (error) {
      console.log('Error: ', error);
      toast.error('Failed to download file', toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };

  return (
    <>
      {newUI ? (
        <PageTitle
          title="MSEL List"
          parents={['Exercise Management', 'MSEL']}
        />
      ) : !props.eventId ? (
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <StylishNewButton onClick={() => navigate(-1)}>
                <SharedIcon iconName="keyboard_arrow_left" classes="me-2" />
                Back
              </StylishNewButton>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              MSEL List
            </li>
          </ol>
        </nav>
      ) : null}

      <div className="plan-msel">
        <div
          className={
            'align-items-start align-items-lg-center mb-4 ' +
            (!props.eventId ? 'd-flex flex-column flex-lg-row' : 'd-md-flex')
          }
        >
          <StylishNewButton
            className={
              'button button--primary ' +
              (!props.eventId ? 'mb-4 mb-lg-0' : 'w-100 w-md-auto mb-3 mb-md-0')
            }
            onClick={createHandler}
          >
            {!props.eventId ? 'Create' : 'Add'} MSEL Inject
          </StylishNewButton>
          <div
            className={
              'ms-lg-auto d-md-flex flex-row align-items-center ' +
              (!props.eventId ? 'w-100 w-lg-auto' : 'ms-auto')
            }
          >
            {!props.eventId ? (
              <div className="button-group toggle ms-lg-auto me-md-3 mb-3 mb-md-0 w-100 w-md-auto">
                {!newUI && (
                  <>
                    <NavLink
                      className="button--icon lrg flex-grow-1"
                      to={`${PreparePath}/dashboard/plan/prepare/msel-list`}
                    >
                      <i className="fa fa-bars" aria-hidden="true"></i>
                    </NavLink>
                    <NavLink
                      className="button--icon lrg flex-grow-1"
                      to={`${PreparePath}/dashboard/plan/prepare/msel-board`}
                    >
                      <i className="fa fa-th-large" aria-hidden="true"></i>
                    </NavLink>
                    <NavLink
                      className="button--icon lrg flex-grow-1"
                      to={`${PreparePath}/dashboard/plan/prepare/msel-calendar`}
                    >
                      <i className="fa fa-calendar" aria-hidden="true"></i>
                    </NavLink>
                  </>
                )}
              </div>
            ) : null}
            <div
              className={
                props.eventId
                  ? 'ms-auto'
                  : 'ms-auto d-flex align-items-center form-search-fluid'
              }
            >
              <StylishNewSearchBar onChangeSearchTerm={onChangeSearchTerm} />
              {!props.eventId && (
                <>
                  <StylishHelp
                    classes={'ms-3 flex-shrink-0 sml'}
                    title={HelpPlanInjects.title}
                    content={HelpPlanInjects.content}
                    link={selfHostedKnowledgeBase || HelpPlanInjects.link}
                  />
                  <Dropdown className="ms-3 flex-shrink-0">
                    <Dropdown.Toggle className="button--icon">
                      <SharedIcon iconName="more_vert" bold />
                    </Dropdown.Toggle>
                    <Dropdown.Menu align="end">
                      <Dropdown.Item
                        onClick={() => {
                          navigate(
                            `${PreparePath}/dashboard/settings/status-label`
                          );
                        }}
                      >
                        <SharedIcon iconName="stylus" />
                        Edit MSEL Status Labels
                      </Dropdown.Item>

                      <Dropdown.Item
                        onClick={() => {
                          fetchAllInject('CSV');
                        }}
                      >
                        <SharedIcon iconName="download" />
                        Download Spreadsheet (CSV)
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          fetchAllInject('PDF');
                        }}
                      >
                        <SharedIcon iconName="download" />
                        Download PDF
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </>
              )}
            </div>
          </div>
        </div>
        {allInjectList.length ? (
          <div className="form-block">
            <div className="d-md-flex align-items-center mb-3">
              <p className="m-0 me-3 mb-3 mb-md-0 weight-600">
                {totalInject > 0 && totalInject + ' MSEL Injects'}
              </p>
              <div className="d-md-flex ms-auto">
                <div className="mb-3 mb-md-0">
                  {!props.eventId ? (
                    <Controller
                      control={control}
                      name="eventList"
                      rules={{ required: true }}
                      render={({ field: { value } }) => (
                        <StylishNewSelect
                          options={eventList}
                          value={value || events}
                          onChange={(e) => {
                            setEvents(e);
                          }}
                          isClearable={false}
                          isSearchable={true}
                          isMulti={false}
                          isDisabled={false}
                        />
                      )}
                    />
                  ) : null}
                </div>
                <div className="ms-md-3">
                  <PaginationDropdown
                    setPage={setPage}
                    setPerPage={setPerPage}
                    options={pageDropDown}
                  />
                </div>
              </div>
            </div>
            <StylishNewTable
              keyField={'id'}
              columns={msel_list_data_col}
              rows={allInjectList}
              onDelete={onDeleteMSELList}
              onEdit={onEditMSELList}
              expandRow={expandRow}
              pagination={true}
              page={pages}
              perPage={perPage}
              total={totalInject}
              onPageChange={(page) => handlePageClick(page)}
            />
          </div>
        ) : (
          <div className="form-block">
            <div className="d-md-flex align-items-center mb-3">
              <p className="m-0 me-3 mb-3 mb-md-0 weight-600">
                {totalInject > 0 && totalInject + ' MSEL Injects'}
              </p>
              <div className="d-md-flex ms-auto">
                <div className="mb-3 mb-md-0">
                  {!props.eventId ? (
                    <Controller
                      control={control}
                      name="eventList"
                      rules={{ required: true }}
                      render={({ field: { value } }) => (
                        <StylishNewSelect
                          options={eventList}
                          value={value || events}
                          onChange={(e) => {
                            setEvents(e);
                          }}
                          isClearable={false}
                          isSearchable={true}
                          isMulti={false}
                          isDisabled={false}
                        />
                      )}
                    />
                  ) : null}
                </div>
                <div className="ms-md-3">
                  <PaginationDropdown
                    setPage={setPage}
                    setPerPage={setPerPage}
                    options={pageDropDown}
                  />
                </div>
              </div>
            </div>
            <div className="bg-gray-800 text-center p-5">
              <div className="py-0 py-md-4">
                <div className="d-flex justify-content-center mb-4">
                  <img src={IconEmpty} alt="" />
                </div>
                <p className="weight-600 mb-4">
                  A fresh start. Create the first entry.
                  <a href={selfHostedKnowledgeBase || MiscellaneousHelpLink.link} target="_blank">
                    {' '}
                    Need more help?
                  </a>
                </p>
                <StylishNewButton
                  className={
                    'button button--primary ' +
                    (!props.eventId
                      ? 'mb-4 mb-lg-0'
                      : 'w-100 w-md-auto mb-3 mb-md-0')
                  }
                  onClick={createHandler}
                >
                  {!props.eventId ? 'Create' : 'Add'} MSEL Inject
                </StylishNewButton>
              </div>
            </div>
          </div>
        )}
      </div>
      <CSVLink
        filename={
          `MSEL ${workspaceName} ` +
          moment().format(
            reduxUser?.timeFormat === 'hour12Mode'
              ? 'YYYY-MM-DD hh:mm a'
              : 'YYYY-MM-DD HH:mm'
          ) +
          '.csv'
        }
        data={allInjectListForExport}
        headers={allInjectListHeader}
        ref={csvLink}
        className="hidden"
      ></CSVLink>

      {showCreateMSELInject && (
        <MSELCreate
          show={showCreateMSELInject}
          onClose={onCloseModal}
          eventId={props.eventId}
        />
      )}

      {showDeleteModal && (
        <StylishConfirmDialog
          show={showDeleteModal}
          onClose={onCloseDeleteModal}
          dialogTitle={'Delete MSEL Inject'}
          dialogContent={'Are you sure you want to delete this?'}
          onConfirm={onDeleteConfirm}
        />
      )}
    </>
  );
};
