import Network from "PREPAREsrc/service/Network";
import API from "PREPAREsrc/service/api";
import { SpecialInterestOptions } from "assets/data/config";

export const getTrrByLevelOneId = (selectedWorkspace, trr_level_1, trrLevels, selectedTechnicalDiscipline) => {
  return new Promise(async (resolve, reject) => {
    const resp = await Network.get(API.fetchTRRById, {
      workspaceId: selectedWorkspace,
      trr_id: trr_level_1,
      td_id: selectedTechnicalDiscipline || '',
    });
    const updatedTrrLevels = [...trrLevels];
    const trrLevel1Index = updatedTrrLevels.findIndex(trr => trr?.id === trr_level_1);
    const updateTrrLevel1 = {
      ...resp.data.response.dataset,
      trrLevel: 1,
      special_interest: SpecialInterestOptions.find(i => i.value === resp.data.response.dataset?.special_interest.trim()),
      technical_discipline_item: resp.data.response.dataset.technical_discipline,
      child: resp.data.response.dataset?.trr_level_2s?.map(trr => {
        return {
          ...trr,
          trrLevel: 2,
          active: false,
          item_no: trr?.item_no.trim(),
          special_interest: SpecialInterestOptions.find(i => i.value === trr?.special_interest.trim()),
          description: trr?.description?.trim(),
          comments: trr?.comments?.trim(),
          risk_character: trr?.risk_character,
          technical_discipline_item: trr.technical_discipline,
          child: trr.trr_level_3s?.map(trr => {
            return {
              ...trr,
              trrLevel: 3,
              item_no: trr?.item_no.trim(),
              special_interest: SpecialInterestOptions.find(i => i.value === trr?.special_interest.trim()),
              description: trr?.description?.trim(),
              comments: trr?.comments?.trim(),
              technical_discipline_item: trr?.technical_discipline.map(td => td.discipline_name).join(', '),
              risk_character: trr?.risk_character,
              child: [],
            }
          }),
        }
      })
    };

    updatedTrrLevels[trrLevel1Index] = updateTrrLevel1;
    resolve(updatedTrrLevels);

  });

};
