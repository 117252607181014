import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Network from '../../service/Network';
import API from '../../service/api';
import { setExerciseDetailsId } from '../../store/exercise/exercise.action';
import { toast } from 'react-toastify';
import { toastConfig } from 'assets/data/config';
import {
  endLoading,
  startLoading,
} from '../../../reducers/loading/loading.action';

export default function Plan() {
  const dispatch = useDispatch();

  const { selectedWorkspace, type: workspaceType } = useSelector(
    (state) => state.prepare.workspace
  );
  const exerciseId = useSelector(
    (state) => state.prepare.exercise.exerciseDetailsId
  );

  const getExerciseDetails = async () => {
    try {
      Network.get(API.planExerciseTypeList, {
        workspaceId: selectedWorkspace,
      }).then((resp) => {
        if (resp.data.response.dataset) {
          let id = resp.data.response.dataset[0]?.id;
          if (workspaceType !== 'T&E') {
            dispatch(setExerciseDetailsId(id));
          } else {
            dispatch(setExerciseDetailsId(null));
          }
        }
      });
    } catch (error) {
      toast.error(error.response.data.response.status.msg, toastConfig);
    }
  };

  useEffect(() => {
    getExerciseDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exerciseId]);

  return (
    <>
      <div className="site-main__dashboard site-main__plan">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
