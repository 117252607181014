import { Modal } from 'react-bootstrap';
import { useEffect } from 'react';

import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import API from '../../../service/api';
import Network from '../../../service/Network';
import { inputStringLength, toastConfig } from 'assets/data/config';
import StylishNewInput from 'components/DesignSystems/New/StylishNewInput';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import {
  endLoading,
  startLoading,
} from '../../../../reducers/loading/loading.action';

export default function AddEditMissionProfileDialog({
  onClose,
  show,
  dialogType,
  updateDialogData,
}) {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const workspaceId = useSelector(
    (state) => state.prepare.workspace.selectedWorkspace
  );

  const dispatch = useDispatch();

  const onSubmit = async (data) => {
    dispatch(startLoading());
    try {
      const newMissionProfile = await Network.post(
        dialogType === 'Add'
          ? API.createMissionProfile
          : API.updateMissionProfile,
        {
          ...data,
          workspaceId: workspaceId,
          name: data?.name?.trim(),
          id: updateDialogData?.id,
        }
      );
      onClose(newMissionProfile.data.response.dataset[0]);
      toast.success(
        dialogType === 'Add'
          ? 'Mission Profile created successfully'
          : 'Mission Profile updated successfully',
        toastConfig
      );
    } catch (error) {
      toast.error(error.response.data.response.status.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };
  useEffect(() => {
    const listener = (event) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        event.preventDefault();
        handleSubmit(onSubmit)();
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, []);

  return (
    <Modal
      show={show}
      onHide={onClose}
      centered
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title>{dialogType} Mission Profile</Modal.Title>
      </Modal.Header>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <label className="form-label">Name</label>
          <Controller
            control={control}
            name="name"
            defaultValue={updateDialogData?.name?.trim()}
            rules={{ required: true, maxLength: inputStringLength }}
            // maxLength={inputStringLength}
            render={({ field: { onChange, value } }) => (
              <StylishNewInput
                type="text"
                className="form-control"
                placeholder="Name"
                onChange={onChange}
                value={value}
              />
            )}
          />
          {errors?.name?.type === 'required' && (
            <span className="form-text form-error">
              Please provide valid input{' '}
            </span>
          )}
          {errors?.name?.type === 'maxLength' && (
            <span className="form-text form-error">
              Maximum {inputStringLength} characters allowed
            </span>
          )}
        </Modal.Body>

        <Modal.Footer>
          <div className="button-group">
            <StylishNewButton
              className="button button--secondary button--reverse"
              onClick={onClose}
            >
              Close
            </StylishNewButton>
            <StylishNewButton className="button button--primary" type="submit">
              Save
            </StylishNewButton>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
