import React from 'react';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import customParseFormat from 'dayjs/plugin/customParseFormat'
import timezone from 'dayjs/plugin/timezone'

import StylishNewTable from 'components/DesignSystems/New/StylishNewTable';

import {TSCategoryToColor} from '../constants/TSCategoryToColor'

import './EventDetails.css';

dayjs.extend(utc)
dayjs.extend(customParseFormat)
dayjs.extend(timezone)


export const getStormCategory = (speed) => {
  let category = '';
  let tooltip = '';

  if (typeof speed === 'number') {
    if (speed <= 33) {
      category = 'TD';
      tooltip = 'Tropical Depression';
    } else if (speed <= 63) {
      category = 'TS';
      tooltip = 'Tropical Storm';
    } else if (speed <= 82) {
      category = 'H1';
      tooltip = 'Hurricane 1';
    } else if (speed <= 95) {
      category = 'H2';
      tooltip = 'Hurricane 2';
    } else if (speed <= 112) {
      category = 'H3';
      tooltip = 'Hurricane 3';
    } else if (speed <= 136) {
      category = 'H4';
      tooltip = 'Hurricane 4';
    } else {
      category = 'H5';
      tooltip = 'Hurricane 5';
    }
  }

  return {category, tooltip};
};

export default function EventDetails({ event }) {
  function EarthquakesRender(event) {
    return (
      <>
        <div className="mt-3">
          <span className="weight-600 d-block">Magnitude:&nbsp;</span>
          {event?.event?.properties?.mag}
        </div>
        <div className="mt-3">
          <span className="weight-600 d-block">Reported Time: </span>
          {event?.event?.properties?.time ? dayjs(event?.event?.properties?.time).format('YYYY-MM-DD hh:mm A') : ''}
        </div>
        <div className="mt-3">
          <span className="weight-600 d-block">Updated Time: </span>
          {event?.event?.properties?.updated ? dayjs(event?.event?.properties?.updated).format('YYYY-MM-DD hh:mm A') : ''}
        </div>
      </>
    );
  }

  function NWSRender(event) {
    return (
      <>
        <div className="mt-3">
          <span className="weight-600 d-block">Headline: </span>
          {event?.event?.properties?.headline}
        </div>
        {!!event?.event?.properties?.effective && (
          <div className="mt-3">
            <span className="weight-600 d-block">Effective: </span>
            {dayjs(event?.event?.properties?.effective).utc().format("YYYY-MM-DD HHmm")} UTC
          </div>
        )}
        {!!event?.event?.properties?.expires && (
          <div className="mt-3">
            <span className="weight-600 d-block">Expires: </span>
            {dayjs(event?.event?.properties?.expires).utc().format("YYYY-MM-DD HHmm")} UTC
          </div>
        )}
        <div className="mt-3">
          <span className="weight-600 d-block">Status: </span>
          {event?.event?.properties?.status}
        </div>
        <div className="mt-3">
          <span className="weight-600 d-block">Severity: </span>
          {event?.event?.properties?.severity}
        </div>
        <div className="mt-3">
          <span className="weight-600 d-block">Urgency: </span>
          {event?.event?.properties?.urgency}
        </div>
        {!!event?.event?.properties?.areadesc && (
          <div className="mt-3">
            <span className="weight-600 d-block">Area: </span>
            {event?.event?.properties?.areadesc}
          </div>
        )}        
        <div className="mt-3">
          <span className="weight-600 d-block">Description: </span>
          {event?.event?.properties?.description}
        </div>
        <div className="mt-3">
          <span className="weight-600 d-block">Instruction: </span>
          {event?.event?.properties?.instruction}
        </div>
      </>
    );
  }

  function PowerOutageRender(event) {
    return (
      <>
        <div className="mt-3">
          <span className="weight-600 d-block">
            County: 
          </span>
          {event?.event?.county_name}
        </div>

        <div className="mt-3">
          <span className="weight-600 d-block">
            State: 
          </span>
          {event?.event?.state_name}
        </div>

        <div className="mt-3">
          <span className="weight-600 d-block">
            {event?.event?.CustomersOut || 0} customers out
          </span>
        </div>

        <div className="mt-3">
          <span className="weight-600 d-block">
            {(event?.event?.power_outage_percent || 0).toFixed(2)} of customers out
          </span>
        </div>

        <div className="mt-3">
          <span className="weight-600 d-block">Last Fetched Time:</span>
          {dayjs(event?.event?.LastUpdatedDateTime).format('MM/DD/YYYY hh:mm A')}
        </div>
      </>
    );
  }

  function WildfiresRender({event})
  {
    const acres = event?.properties?.attr_CalculatedAcres?.toFixed(0) || event?.properties?.attr_DiscoveryAcres?.toFixed(0)
    const contained = event?.properties?.attr_PercentContained
    const shortDesc = event?.properties?.attr_IncidentShortDescription
    const totalPersonnel = event?.properties?.attr_TotalIncidentPersonnel
    return (
      <>
        <div className="mt-3">
          <span className="weight-600 d-block">Fire Name: </span>
          {event?.properties?.poly_IncidentName}
        </div>

        <div className="mt-3">
            <span className="weight-600 d-block">Short Description: </span>
            {!!shortDesc && shortDesc || "(Not available)"}
          </div>
        
        <div className="mt-3">
          <span className="weight-600 d-block">Size: </span>
          {!!acres && `${acres} acres` || "(Not available)"}
        </div>

        <div className="mt-3">
          <span className="weight-600 d-block">Percent Contained: </span>
          {!!contained && `${contained}%` || "(Not available)"}
        </div>

        <div className="mt-3">
          <span className="weight-600 d-block">Total Personnel: </span>
          {!!totalPersonnel && totalPersonnel || "(Not available)"}
        </div>

        <div className="mt-3">
          <span className="weight-600 d-block">First Update: </span>
          {dayjs(event?.properties?.poly_CreateDate).format("YYYY-MM-DD HHmm")} UTC
        </div>

        <div className="mt-3">
          <span className="weight-600 d-block">Last Updated: </span>
          {dayjs(event?.properties?.poly_DateCurrent).format("YYYY-MM-DD HHmm")} UTC
        </div>
      </>
    )
  }

  function HurricanesRender({event})
  {
    const mainData = event.hurricane_data_points[0].properties
    const tableRows = event.hurricane_data_points
      .filter(data=>data.storm_data_category.includes("Points"));

    let highlightedRowIndex = tableRows.findIndex(p=>p.storm_data_category==="Observed Points")
    if(highlightedRowIndex===-1) highlightedRowIndex = tableRows.length -1

    const column = [
      {
        dataField: 'unified_datetime_utc_formatted',
        text: 'Date (UTC)',
        attrs: { title: 'Date (UTC)' },
      },
      {
        dataField: 'unified_storm_maxwind',
        text: 'Wind Speed (KT)',
        attrs: { title: 'Wind Speed (KT)' },
        formatter: (cell, row) => {
          const color = TSCategoryToColor[row.unified_storm_category]
          return (
            <div className="EventDetailsHurricaneWindSpeedCell" style={{color:color}}>
              {row.unified_storm_maxwind}
            </div>
          )
        }
      },
      // Pressure is included in the NOAA Historical Hurricanes site, but our data sources have been inconsistent with reporting pressure
      // {
      //   dataField: 'unified_storm_pressure',
      //   text: 'Pressure (MB)',
      //   attrs: { title: 'Pressure (MB)' },
      // },
      {
        dataField: 'unified_storm_category',
        text: 'Category',
        attrs: (cell, row) => { 
          const category = getStormCategory(row?.unified_storm_maxwind);
          return { title: category.tooltip }
        },
        formatter: (cell, row) => {
          const color = TSCategoryToColor[row.unified_storm_category]
          const category = getStormCategory(row?.unified_storm_maxwind);
          return (
            <div className="EventDetailsHurricaneWindSpeedCell" style={{color:color}}>
              {category.category}
            </div>
          )
        }
      },
      {
        dataField: 'unified_data_time_type',
        text: 'Forecast/ Observed',
        attrs: { title: 'Data' },
      },
    ];

    const rowClasses = (row, rowIndex) => {
      if (rowIndex === highlightedRowIndex) {
        return 'nonSelectableRow'; // Apply custom class to the non-selectable row
      }
      return '';
    };

    return (
      <>
        <div>
          <StylishNewTable
            keyField={'id'}
            rows={tableRows}
            columns={column}
            classes="mb-4 table-hurricane"
            rowClasses={rowClasses}
          />
        </div>
      </>
    )
  }

  return (
    <div className="event-detail-container">
      <h5 className="mb-1">{event?.hazard_name === "Hurricane" && "Hurricane/Tropical Storm" || event?.hazard_name}</h5>
      <div className="mb-3">
        <span className="weight-600 d-block">{event?.last_modified}</span>
      </div>
      <hr className="dashed m-0" />
      <div className="EventDetails-Specifics">
        {(!!event && event.hazard_name === 'Earthquakes' && (
          <EarthquakesRender event={event} />
        )) || (!!event && event.hazard_name === 'Wild Fires' && (
          <WildfiresRender event={event} />
        )) || (!!event && event.hazard_name === 'Hurricane' && (
          <HurricanesRender event={event} />
        )) || (!!event && event.hazard_data_category === 'Power Outage' && (
          <PowerOutageRender event={event} />
        )) || (!!event && event?.properties?.['@id'] && (
          <NWSRender event={event} />
        )) || (
          <div>ERROR: Unknown event type {!!event && event.hazard_name}</div>
        )}
      </div>
    </div>
  );
}
