import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Link, NavLink, useNavigate, useParams } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import API from '../../../service/api';
import Network from '../../../service/Network';
import { StylishNewSearchBar } from 'components/DesignSystems/New/StylishNewSearchBar';
import { useForm, Controller } from 'react-hook-form';
import IconEmptyExercise from 'assets/images/icon__exercise-details-empty.svg';
import ReactPaginate from 'react-paginate';
import Help from '../../../component/Help/Help';
import { ProfilesHelp, useSelfHostedKnowledgeBase } from 'assets/data/HelpContent';
import { AllIcon } from 'assets/Icon/Icon';
import ConfirmModal from 'PREPAREsrc/component/ConfirmModal/ConfirmModal';
import IconEdit from 'assets/images/icon__edit.svg';
import IconDelete from 'assets/images/icon__delete.svg';
import ProfileImage from 'assets/images/icon__profileimage.jpg';
import CreateProfileDialog from './CreateProfileDialog';
import ChooseMediaDialog from '../ChooseMediaModal/ChooseMediaDialog';
import { PreparePath } from 'utils/sharedUtils/sharedConst';
import IconView from 'assets/images/icon__eye.svg';
import ProfilesBanner from 'assets/images/profile__bannerimg.jpg';
import Fbicon from 'assets/images/icon__facebook.svg';
import IconLink from 'assets/images/icon__visit.svg';
import StylishNewSelect from 'components/DesignSystems/New/StylishNewSelect';
import { MiscellaneousHelpLink, Site } from 'assets/data/HelpContent';
import { toast } from 'react-toastify';
import { StylishConfirmDialog } from 'components/DesignSystems/New/StylishConfirmDialog';
import { cardStringLength, toastConfig } from 'assets/data/config';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import StylishHelp from 'components/DesignSystems/New/StylishHelp';
import {
  endLoading,
  startLoading,
} from '../../../../reducers/loading/loading.action';
import isRoleReadOnly from 'PREPAREsrc/utils/isRoleReadOnly';
import PageTitle from 'components/headers/PageTitle';
import { useNewUIFlag } from 'utils/featureFlags';

export default function Profiles() {
  const { control } = useForm();

  const [showCreateProfileDialog, setShowCreateProfileDialog] = useState(false);
  const [showChooseMediaDialog, setShowChooseMediaDialog] = useState(false);
  const [profileList, setProfileList] = useState(null);
  const [profileListTotal, setProfileListTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  // const [perPage, setPerPage] = useState(5);
  const [sortBy, setSortBy] = useState({ value: 'name_ASC', label: 'Name' });
  const [deleteProfile, setDeleteProfile] = useState(null);

  const selfHostedKnowledgeBase = useSelfHostedKnowledgeBase()

  const perPage = 8;

  const workspaceId = useSelector(
    (state) => state.prepare.workspace.selectedWorkspace
  );
  const { workspaceName } = useSelector((state) => state.prepare.workspace);

  const reduxUser = useSelector((state) => state.prepare.user);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const newUI = useNewUIFlag();

  const sortProfile = [
    { value: 'name_ASC', label: 'Name' },
    { value: 'createdAt_ASC', label: 'Oldest Created ' },
    { value: 'createdAt_DESC', label: 'Last Created' },
    { value: 'updatedAt_ASC', label: 'Oldest Updated' },
    { value: 'updatedAt_DESC', label: 'Last Updated' },
  ];

  const onCreateProfileDialog = () => {
    setShowCreateProfileDialog(true);
  };
  const onCreateProfileDialogClose = (aftersave) => {
    setShowCreateProfileDialog(false);
    if (aftersave?.id) {
      mediaProfileList();
    }
  };
  const onChangeSearchTerm = (termSearched) => {
    setSearchTerm(termSearched);
    setPage(1);
  };

  // const onChooseMediaDialog = () => {
  //   setShowChooseMediaDialog(true);
  //   setShowCreateProfileDialog(false);
  // };
  // const onChooseMediaDialogClose = () => {
  //   setShowChooseMediaDialog(false);
  // };

  const mediaProfileList = async () => {
    dispatch(startLoading());
    try {
      const response = await Network.get(API.mediaProfileList, {
        workspaceId: workspaceId,
        limit: perPage,
        page: page,
        search: searchTerm,
        sort: sortBy?.value,
      });
      setProfileList(response.data.response.dataset);
      setProfileListTotal(response.data.response.total);
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(endLoading());
    }
  };

  useEffect(() => {
    setPage(1);
  }, [workspaceId]);

  useEffect(() => {
    mediaProfileList();
  }, [reduxUser, page, perPage, searchTerm, sortBy, workspaceId]);

  const onDeleteConfirm = async () => {
    dispatch(startLoading());
    try {
      await Network.post(API.deleteMediaProfile, { profileId: deleteProfile });
      await mediaProfileList();
      setDeleteProfile(null);
      toast.success('Profile deleted successfully', toastConfig);
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(endLoading());
    }
  };
  const onDeleteProfile = (row) => {
    setDeleteProfile(row.id);
  };

  const handlePageClick = (event) => {
    setPage(event.selected + 1);
  };
  const onCreate = async (profile) => {
    dispatch(startLoading());
    try {
      const newProfile = await Network.post(API.createMediaPost, {
        status: 'drafted',
        mediaProfileId: profile?.id,
        workspaceId: workspaceId,
        type: 'post',
        mediaSiteId: profile?.siteId,
      });
      navigate(
        `${PreparePath}/dashboard/media/posts/edit-post/${newProfile?.data?.response?.MediaPost?.id}`
      );
    } catch (error) {
      console.log(error);
      toast.error('Something went wrong', toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };

  const handleRedirect = (siteId, profileId, slug, username) => {
    if (!siteId && !profileId && !slug && !username) return;
    sessionStorage.setItem('mediaModuleSocialMediaType', 'profiles');
    sessionStorage.setItem('mediaModuleSocialMediaProfilesSiteId', siteId);
    sessionStorage.setItem(
      'mediaModuleSocialMediaProfilesProfileId',
      profileId
    );
    navigate(
      `${PreparePath}/dashboard/media/profiles/${
        workspaceName && workspaceName.charAt(0).toUpperCase()
      }/${slug}/${username}`
    );
  };

  return (
    <>
      {newUI ? (
        <PageTitle title="Profile" parents={['Media']} />
      ) : (
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <StylishNewButton onClick={() => navigate(-1)}>
                <i className="fa fa-angle-left me-2" aria-hidden="true"></i>
                Back
              </StylishNewButton>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Profile
            </li>
          </ol>
        </nav>
      )}

      <div className="d-flex flex-wrap align-items-center mb-4">
        {!isRoleReadOnly(reduxUser.roles) && (
          <StylishNewButton
            onClick={onCreateProfileDialog}
            className="button--primary flex-shrink-0"
          >
            Create Profile
          </StylishNewButton>
        )}
        <p className="ms-auto m-0">{profileListTotal} Profile(s)</p>
        <div className="d-flex flex-wrap align-items-center ms-lg-3 w-100 w-lg-auto mt-3 mt-lg-0">
          <StylishNewSearchBar
            className="flex-shrink-0"
            value={searchTerm}
            // onChangeSearchTerm={(e) => updateSearchTerm(e)}
            onChangeSearchTerm={onChangeSearchTerm}
          />
          <div className="d-flex align-items-center ms-md-auto ms-lg-3 w-100 w-md-auto mt-3 mt-md-0">
            <Controller
              control={control}
              name="sortProfileBy"
              rules={{ required: false }}
              render={({ field: { value } }) => (
                <StylishNewSelect
                  style={{ minWidth: '160px' }}
                  classes={'flex-grow-1'}
                  options={sortProfile}
                  defaultValue={sortProfile[0] || sortBy}
                  value={sortBy}
                  onChange={(e) => {
                    setSortBy(e);
                  }}
                  isClearable={false}
                  isSearchable={true}
                  isMulti={false}
                  isDisabled={false}
                />
              )}
            />
            <StylishHelp
              classes={'flex-shrink-0 ms-3 sml'}
              title={ProfilesHelp.title}
              content={ProfilesHelp.content}
              link={selfHostedKnowledgeBase || ProfilesHelp.link}
            />
          </div>
        </div>
      </div>

      {profileListTotal > 0 ? (
        <>
          <div className="row">
            {profileList?.map((profile) => (
              <div
                className="col-md-6 col-lg-4 col-xl-3 mb-4"
                key={profile?.id}
              >
                <div className="bg-gray-800 rounded h-100 d-flex flex-column overflow-hidden">
                  <div className="position-relative">
                    {profile?.banner ? (
                      <div
                        className="card-banner position-relative"
                        // style={{
                        //   background: `url(${profile?.banner})`,
                        // }}
                      >
                        <img
                          className="position-absolute top-0 start-0"
                          src={profile?.banner}
                          alt=""
                        />
                      </div>
                    ) : (
                      <div
                        className="card-banner"
                        // style={{
                        //   background: `url(${profile?.banner})`,
                        // }}
                      ></div>
                    )}
                    <Dropdown className="position-absolute top-0 end-0 mt-3 me-3">
                      <Dropdown.Toggle className="button--icon bg-gray-600 ">
                        <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                      </Dropdown.Toggle>
                      <Dropdown.Menu align="end">
                        {!isRoleReadOnly(reduxUser.roles) && (
                          <Link
                            to={`${PreparePath}/dashboard/media/edit-profile/${profile.id}`}
                            className="dropdown-item"
                          >
                            <img src={IconEdit} alt="" />
                            Edit Profile
                          </Link>
                        )}
                        <Dropdown.Item
                          onClick={() =>
                            handleRedirect(
                              profile?.siteId,
                              profile?.id,
                              profile?.slug,
                              profile?.username
                            )
                          }
                          className={`${
                            !profile?.siteId &&
                            !profile?.profileId &&
                            !profile?.slug &&
                            !profile?.username
                              ? 'disabled'
                              : ''
                          }`}
                        >
                          <img src={IconLink} alt="" />
                          Permalink
                        </Dropdown.Item>
                        {!isRoleReadOnly(reduxUser.roles) && (
                          <Dropdown.Item
                            onClick={() => onDeleteProfile(profile)}
                          >
                            <img src={IconDelete} alt="" />
                            Delete Profile
                          </Dropdown.Item>
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>

                  <div className="p-3 pt-5 card-content position-relative ">
                    {profile?.avatar ? (
                      <div className="profile-img position-absolute">
                        <img src={profile?.avatar} alt="" />
                      </div>
                    ) : (
                      <div className="profile-img position-absolute"></div>
                    )}
                    <h4 className="mb-0 break-word">{profile?.name}</h4>
                    <p className="mb-2 ">
                      {'@'}
                      {profile?.username}
                    </p>
                    <div className="d-flex align-items-center mb-2">
                      {profile?.icon ? (
                        <div style={{ color: profile?.siteColor }}>
                          {
                            AllIcon.find((icon) => icon.name === profile?.icon)
                              ?.icon
                          }
                        </div>
                      ) : null}
                      {profile?.site_image ? (
                        <div className="has-img-h-24 me-2">
                          <img
                            src={profile?.site_image}
                            alt=""
                            className="rounded-5 d-block"
                          />
                        </div>
                      ) : null}
                      <p className="mb-0">{profile?.siteName}</p>
                    </div>

                    {profile?.description?.length > cardStringLength ? (
                      <p className="mb-0">
                        {profile?.description?.slice(0, cardStringLength)}
                        {'....'}
                      </p>
                    ) : (
                      <p className="mb-0">{profile?.description}</p>
                    )}
                  </div>
                  <div className="bg-gray-700 p-3 mt-auto d-flex align-items-center justify-content-center">
                    <span
                      onClick={() => {
                        if (!isRoleReadOnly(reduxUser.roles)) {
                          onCreate(profile);
                        }
                      }}
                      className="text-white weight-500 d-flex align-items-center justify-content-center flex-grow-1 cursor-pointer"
                    >
                      <img src={IconEdit} className="me-2" alt="" />
                      Create Post
                    </span>
                    <div className="separator--y separator--800  flex-shrink-0"></div>

                    <Link
                      onClick={() => {
                        sessionStorage.removeItem('mediaModuleSocialMediaType');
                        sessionStorage.removeItem(
                          'mediaModuleSocialMediaProfilesSiteId'
                        );
                        sessionStorage.removeItem(
                          'mediaModuleSocialMediaProfilesProfileId'
                        );
                      }}
                      to={`${PreparePath}/dashboard/media/profiles/${profile?.slug?.toLowerCase()}/${profile?.name?.toLowerCase()}/${profile?.id?.toLowerCase()}`}
                      className="text-white weight-500 d-flex align-items-center justify-content-center flex-grow-1"
                    >
                      <img src={IconView} className="img-h-16 me-2" alt="" />
                      View Posts
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="d-flex justify-content-center justify-content-md-end">
            <ReactPaginate
              className="pagination"
              breakLabel="..."
              nextLabel="Next"
              onPageChange={handlePageClick}
              pageCount={Math.ceil(profileListTotal / perPage)}
              previousLabel="Prev"
              forcePage={page - 1}
            />
          </div>
        </>
      ) : (
        <div>
          <div className="bg-gray-800 text-center p-5">
            <div className="py-0 py-md-4">
              <div className="d-flex justify-content-center mb-4">
                <img src={IconEmptyExercise} alt="" />
              </div>
              <p className="weight-600 mb-4">
                A fresh start. Create the first entry.
                <a
                  href={MiscellaneousHelpLink.link}
                  target="_blank"
                  rel="noreferrer"
                >
                  {' '}
                  Need more help?
                </a>
              </p>
              <a
                className="button button--primary cursor-pointer"
                onClick={onCreateProfileDialog}
              >
                Create Profile
              </a>
            </div>
          </div>
        </div>
      )}
      {showCreateProfileDialog && (
        <CreateProfileDialog
          show={showCreateProfileDialog}
          onClose={onCreateProfileDialogClose}
          // onChooseMediaDialog={onChooseMediaDialog}
        />
      )}
      {/* {showChooseMediaDialog && (
        <ChooseMediaDialog
          show={showChooseMediaDialog}
          onClose={onChooseMediaDialogClose}
          // updateDialogData={updateDialogData}
        />
      )} */}
      {deleteProfile && (
        <StylishConfirmDialog
          show={deleteProfile}
          onClose={() => setDeleteProfile(null)}
          dialogTitle={'Delete Profile'}
          dialogContent={
            'Deleting the profile, will delete the posts and comments created with this profile - Are you sure?'
          }
          onConfirm={onDeleteConfirm}
        />
      )}
    </>
  );
}
