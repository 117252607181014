import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { SharedIcon } from 'components/SharedIcon/SharedIcon';

const SectionDetails = ({
  section,
  selectedPage,
  isEdit,
  changePage,
  onAddPageClick,
  onAddSectionClick,
  onEdidSectionTitle,
  onDeleteSectionClick,
}) => {
  return (
    <>
      <div className="section">
        <div className="d-flex align-items-center mb-2">
          <h6 className="mb-0">{section.title}</h6>
          {isEdit ? (
            <Dropdown className="ms-auto" align="end">
              <Dropdown.Toggle
                variant="secondary"
                id="dropdown-basic"
                className="button--icon flex-shrink-0"
              >
                <SharedIcon iconName="more_vert" bold />
              </Dropdown.Toggle>

              <Dropdown.Menu direction="end">
                <Dropdown.Item onClick={onEdidSectionTitle}>
                  <SharedIcon iconName="stylus" />
                  Edit Section Title
                </Dropdown.Item>
                <Dropdown.Item onClick={onAddSectionClick}>
                  <SharedIcon iconName="add" />
                  Add Section
                </Dropdown.Item>
                <Dropdown.Item onClick={onAddPageClick}>
                  <SharedIcon iconName="new_window" />
                  Add Page
                </Dropdown.Item>
                <Dropdown.Item onClick={onDeleteSectionClick}>
                  <SharedIcon iconName="delete" />
                  Delete Section and Pages
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          ) : null}
        </div>
        {section?.pages
          ?.sort(
            (formerPage, nextPage) =>
              new Date(formerPage.pageCreatedAt) -
              new Date(nextPage.pageCreatedAt)
          )
          .map((page, pageIndex) => (
            <ul>
              <li>
                <a
                  className={
                    selectedPage?.pageId === page?.pageId ? 'active' : ''
                  }
                  onClick={() => changePage(pageIndex)}
                  key={section.sectionId + page?.pageId + pageIndex}
                >
                  {page?.pageTitle}
                </a>
              </li>
            </ul>
          ))}
      </div>
    </>
  );
};

export default SectionDetails;
