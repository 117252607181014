import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import config from '../../../constants/apiConfig';
import { useSelector } from 'react-redux';
import { selectUserGuid } from '../../../slices/commonSelectors';
import { toast } from 'react-toastify';
import { toastConfig } from '../../../assets/data/config';
import { useActualIncidentId } from './commonHooks';

export type WorkAssignment = {
  id: string;
  work_assignment_id: string;
  incident_id: string;
  user_guid: string;
  version: number;
  branch?: string;
  division?: string;
  work_assignment: string;
  resources?: string;
  overhead_positions?: string;
  special_equipment_supplies?: string;
  reporting_location?: string;
  requested_arrival_time?: Date;
  special_instructions?: string;
  timestamp: Date;
  archived: boolean;
  geolocation: any;
  communications: UUID[];
  strike_teams: UUID[];
  resource_sets: UUID[];
};

export const keys = {
  base: 'incident_workAssignments',
  get all() { return [keys.base] },
  list: (incidentId: string, userId: string) => [keys.base, incidentId, userId],
} as const;

export type CreateWorkAssignmentReqData = {
  work_assignment_id: string;
  incident_id: string;
  branch?: string;
  division?: string;
  work_assignment: string;
  resources?: string;
  overhead_positions?: string;
  special_equipment_supplies?: string;
  reporting_location?: string;
  requested_arrival_time?: Date;
  special_instructions?: string;
  archived?: boolean;
  geolocation: any;
};

export const useWorkAssignments = () => {
  const incidentId = useActualIncidentId();
  const user_guid = useSelector(selectUserGuid);
  return useQuery({
    queryKey: keys.list(incidentId, user_guid),
    queryFn: async () => {
      const resp = await axios.get<WorkAssignment[]>(
        `${config.apiGateway.ics_work_assignments}/${incidentId}`,
        {
          headers: { user_guid: user_guid },
        }
      );
      return resp.data;
    },
  });
};

export const useCreateWorkAssignment = () => {
  const incidentId = useActualIncidentId();
  const user_guid = useSelector(selectUserGuid);
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async (data: CreateWorkAssignmentReqData) => {
      return await axios.post(
        `${config.apiGateway.ics_work_assignments}/${incidentId}`,
        { ...data, user_guid }
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: keys.list(incidentId, user_guid) });
    },
    onError: (error: any) => {
      toast.error(error.message, toastConfig);
    },
  });
  return mutation;
};

interface EditWorkAssignmentReqData {
  id: string;
  branch?: string;
  division?: string;
  work_assignment: string;
  resources?: string;
  overhead_positions?: string;
  special_equipment_supplies?: string;
  reporting_location?: string;
  requested_arrival_time?: Date;
  special_instructions?: string;
  archived?: boolean;
  geolocation: any;
  communications: UUID[];
  strike_teams: UUID[];
  resource_sets: UUID[];
}

export const useEditWorkAssignment = (workAssignmentId: string) => {
  const user_guid = useSelector(selectUserGuid);
  const incidentId = useActualIncidentId();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: EditWorkAssignmentReqData) => {
      return await axios.put(
        `${config.apiGateway.ics_work_assignments}/${incidentId}/${workAssignmentId}`,
        { ...data, user_guid }
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: keys.list(incidentId, user_guid) });
    },
    onError: (error: any) => {
      toast.error(error.message, toastConfig);
    },
  });
};
