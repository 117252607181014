import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import StylishNewSelect from 'components/DesignSystems/New/StylishNewSelect';
import { updateCurrentlySelectedGroup } from 'actions/diceActions';
import IconError from '../../assets/images/icon__error.svg';

export default function GroupSelection() {
  const reduxDispatch = useDispatch();
  const [groups, setGroups] = useState([]);
  const [currentGroup, setCurrentGroup] = useState();

  const reduxGroups = useSelector((state) => {
    return state.app.groups;
  });

  const reduxCurrentlySelectedGroup = useSelector((state) => {
    return state.app.currentlySelectedGroup;
  });

  function groupSelected(currentlySelectedGroupGuid) {
    reduxDispatch(
      updateCurrentlySelectedGroup(
        reduxGroups.find(
          (group) => group.group_guid === currentlySelectedGroupGuid
        )
      )
    );
  }

  useEffect(() => {
    setGroups(
      reduxGroups
        .filter((group) => !group.is_closed)
        .map((group) => ({
          value: group.group_guid,
          label: group.group_name,
        }))
    );
  }, [reduxGroups]);

  useEffect(() => {
    if (!!reduxCurrentlySelectedGroup) {
      setCurrentGroup({
        value: reduxCurrentlySelectedGroup.group_guid,
        label: reduxCurrentlySelectedGroup.group_name,
      });
    }
  }, [reduxCurrentlySelectedGroup]);

  return (
    <>
      {(JSON.stringify(reduxGroups) !== JSON.stringify([]) && (
        <>
          <label className="form-label">Organization</label>
          <StylishNewSelect
            options={groups}
            value={currentGroup}
            placeholder="Select a Organization"
            onChange={(e) => groupSelected(e.value)}
            isClearable={false}
            isSearchable={false}
            isMulti={false}
            isDisabled={false}
          />
        </>
      )) ||
        (reduxGroups === [] && (
          <div
            className="alert alert-warning d-flex align-items-center"
            role="alert"
          >
            <i
              className="fa fa-exclamation-triangle fa-2x me-3"
              aria-hidden="true"
            ></i>
            <p className="weight-500 m-0">
              You do not belong to any group. Please join or create a group.
            </p>
          </div>
        ))}
    </>
  );
}
