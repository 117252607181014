import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import API from '../../../service/api';
import Network from '../../../service/Network';
import { toast } from 'react-toastify';
import RenameFileDialog from './RenameFileDialog';
import MoveFolderDialog from './MoveFolderDialog';
import Dropdown from 'react-bootstrap/Dropdown';
import AddNotebookDialog from './AddNotebookDialog';
import AddFolderDialog from './AddFolderDialog';
import IconMove from 'assets/images/icon__move.svg';

import IconFile from 'assets/images/icon__file.svg';
import IconEmptyExercise from 'assets/images/icon__exercise-details-empty.svg';
import IconDownload from 'assets/images/icon__download--white.svg';
import { AllIcon } from 'assets/Icon/Icon';
import Help from '../../../component/Help/Help';
import {
  FoldersandFileUploads,
  MiscellaneousHelpLink,
  Notebook,
  useSelfHostedKnowledgeBase,
} from 'assets/data/HelpContent';
import fileDownload from 'js-file-download';
import { PreparePath } from 'utils/sharedUtils/sharedConst';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { StylishNewSearchBar } from 'components/DesignSystems/New/StylishNewSearchBar';
import StylishViewFileDialog from 'components/DesignSystems/New/StylishViewFileDialog';
import AttachNotebookDialog from './AttachNotebookDialog';
import { Tab, Tabs } from 'react-bootstrap';
import { StylishConfirmDialog } from 'components/DesignSystems/New/StylishConfirmDialog';
import { toastConfig } from 'assets/data/config';
import {
  endLoading,
  startLoading,
} from '../../../../reducers/loading/loading.action';
import isRoleReadOnly from 'PREPAREsrc/utils/isRoleReadOnly';
import PageTitle from 'components/headers/PageTitle';
import { useNewUIFlag } from 'utils/featureFlags';
import { SharedIcon } from 'components/SharedIcon/SharedIcon';

export default function NotebookDashboard() {
  const [showNotebookAddDialog, setShowNotebookAddDialog] = useState(false);
  const [showNotefolderAddDialog, setShowNotefolderAddDialog] = useState(false);
  const [showRenameFileDialog, setShowRenameFileDialog] = useState(false);
  const [showFileViewDialog, setShowFileViewDialog] = useState(false);
  const [showMoveFolderDialog, setShowMoveFolderDialog] = useState(false);
  const [showFileViewUrl, setShowFileViewUrl] = useState(null);
  const [updateDialogData, setUpdateDialogData] = useState(null);
  const [allNotebook, setAllNotebook] = useState([]);
  const [dialogType, setDialogType] = useState('Create');
  const [dialogTypeNotebook, setDialogTypeNotebook] = useState('Add');
  const [isFileClicked, setIsFileClicked] = useState(false);
  const [folderFileDialogType, setFolderFileDialogType] = useState('Folder');
  const [allNotefolder, setAllNotefolder] = useState([]);
  const [allNotefile, setAllNotefile] = useState([]);
  const [deleteFolderId, setDeleteFolderId] = useState(null);
  const [deleteFileId, setDeleteFileId] = useState(null);
  const [separatedArray, setSeparatedArray] = useState([]);
  const [tableView, setTableView] = useState('notebooks');
  const [showAttachNotebookDialog, setShowAttachNotebookDialog] = useState(
    false
  );
  const [deleteNotebook, setDeleteNotebook] = useState('');
  const [notefolderSearchTerm, setNotefolderSearchTerm] = useState('');
  const [notefileSearchTerm, setNotefileSearchTerm] = useState('');
  const [notebookSearchTerm, setNotebookSearchTerm] = useState('');

  const selfHostedKnowledgeBase = useSelfHostedKnowledgeBase()

  const workspaceId = useSelector(
    (state) => state.prepare.workspace.selectedWorkspace
  );
  const reduxUser = useSelector((state) => state.prepare.user);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const newUI = useNewUIFlag();

  const { page } = useParams();

  const hiddenFileInput = React.useRef(null);

  const getAllNotebook = async () => {
    dispatch(startLoading());
    if (workspaceId) {
      try {
        Network.get(API.notebookList, {
          workspaceId: workspaceId,
          search: notebookSearchTerm,
        })
          .then((resp) => {
            setAllNotebook(resp.data.response.dataset);
          })
          .catch(console.log)
          .finally(() => {
            dispatch(endLoading());
          });
      } catch (error) {
        toast.error(error.response.data.response.status.msg, toastConfig);
      }
    }
  };

  const getAllNotefolder = () => {
    dispatch(startLoading());

    if (workspaceId) {
      try {
        Network.get(API.notefolderAndList, {
          workspaceId: workspaceId,
          search: notefolderSearchTerm,
        })
          .then((resp) => {
            setAllNotefolder(resp?.data?.response?.dataset);
          })
          .catch(console.log)
          .finally(() => {
            dispatch(endLoading());
          });
      } catch (error) {
        console.log(error);
      }
    }
  };

  const getAllNotefile = async () => {
    dispatch(startLoading());

    if (workspaceId) {
      try {
        Network.get(API.noteFile, {
          workspaceId: workspaceId,
          search: notefileSearchTerm,
        })
          .then(async (resp) => {
            let noteFileData = resp?.data?.response?.dataset;
            for (let i = 0; i < noteFileData.length; i++) {
              noteFileData[i] = {
                ...noteFileData[i],
                iconUrl:
                  noteFileData[i].fileUrl &&
                  (await fileSignedUrl(noteFileData[i].fileUrl)),
              };
            }
            setAllNotefile(noteFileData);
          })
          .catch(console.log)
          .finally(() => {
            dispatch(endLoading());
          });
      } catch (error) {
        console.log(error);
      }
    }
  };

  const fileUpload = async () => {
    dispatch(startLoading());
    setSeparatedArray((separatedArray) => {
      try {
        let files = {};
        files = separatedArray.map((fN) => {
          return {
            fileUrl: fN?.fileUrl,
            file_name: fN?.originalname,
            size: fN?.size,
            mimetype: fN?.mimetype,
            workspaceId: workspaceId,
          };
        });
        Network.post(API.createNotefile, {
          files: files,
        })
          .then((resp) => {
            getAllNotefile();
            toast.success('Upload successful', toastConfig);
          })
          .catch((error) => {
            console.log(error);
            dispatch(endLoading());
          });
      } catch (error) {
        console.log(error?.response?.data?.response?.status?.msg);
      }
      return separatedArray;
    });
  };

  const changeHandler = (event) => {
    if (event.target.files.length > 10) {
      toast.info('Maximum upload limit is 10 files at a time', toastConfig);
      event.stopPropagation();
      return;
    }
    if (event.target.files.length <= 0) {
      return;
    }
    try {
      dispatch(startLoading());

      const formData = new FormData();

      for (let i = 0; i < event.target.files.length; i++) {
        formData.append('file', event.target.files[i]);
      }
      Network.post(API.multiplefileupload, formData)
        .then((resp) => {
          setSeparatedArray(resp.data.response.dataset);
          fileUpload();
          hiddenFileInput.current.value = '';
        })
        .catch(console.log)
        .finally(() => {
          dispatch(endLoading());
        });
    } catch (error) {
      console.log(error?.response?.data?.response?.status?.msg);
    }
  };
  useEffect(() => {
    !!isFileClicked && getAllNotefile();
  }, [isFileClicked, notefileSearchTerm]);

  useEffect(() => {
    if (page === 'notebooks') {
      setTableView('notebooks');
    } else if (page === 'folders') {
      setTableView('folders');
    } else {
      setTableView('files');
    }
  }, [page]);

  useEffect(() => {
    if (tableView === 'notebooks') {
      setNotefileSearchTerm('');
      setNotefolderSearchTerm('');
    } else if (tableView === 'folders') {
      setNotebookSearchTerm('');
      setNotefileSearchTerm('');
    } else if (tableView === 'files') {
      setNotebookSearchTerm('');
      setNotefolderSearchTerm('');
    }
  }, [tableView]);

  useEffect(() => {
    getAllNotefolder();
  }, [reduxUser, notefolderSearchTerm, workspaceId]);

  useEffect(() => {
    getAllNotebook();
  }, [reduxUser, notebookSearchTerm, workspaceId]);

  useEffect(() => {
    getAllNotefile();
  }, [reduxUser, notefileSearchTerm, workspaceId]);

  const onAddNotebook = (data) => {
    data && setUpdateDialogData(data);
    data && setDialogTypeNotebook('Edit');
    setShowNotebookAddDialog(true);
  };

  const OnAddOrEditNotebook = (afterSave) => {
    setUpdateDialogData(null);
    setShowNotebookAddDialog(false);
    if (afterSave?.id) {
      getAllNotebook();
    }
  };

  const onAddNoteFolder = () => {
    setDialogType('Create');
    setShowNotefolderAddDialog(true);
  };

  const onMoveFolderDialog = (folderDetails) => {
    setFolderFileDialogType(folderDetails?.type);
    setUpdateDialogData(folderDetails);
    setShowMoveFolderDialog(true);
  };

  const onMoveFolderDialogClose = (afterSave) => {
    setShowMoveFolderDialog(false);
    if (afterSave?.id) {
      getAllNotefolder();
      getAllNotefile();
    }
  };

  const fileSignedUrl = async (fileUrl) => {
    try {
      dispatch(startLoading());
      const viewFile = await Network.post(API.generateSignedUrl, {
        url: fileUrl,
      });
      return viewFile.data.response.singedUrl;
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(endLoading());
    }
  };

  const FileOpen = async (file) => {
    setShowFileViewDialog(true);
    try {
      dispatch(startLoading());
      setShowFileViewUrl(await fileSignedUrl(file?.fileUrl));
    } catch (error) {
      toast.error('Failed to view file', toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };

  const fileClose = () => {
    setShowFileViewDialog(false);
    setShowFileViewUrl(null);
  };

  const downloadFile = async (file) => {
    try {
      dispatch(startLoading());
      const downloadFile = await Network.post(API.generateSignedUrl, {
        url: file?.fileUrl,
      });
      const res = await fetch(downloadFile.data.response.singedUrl, {
        headers: {},
      });

      fileDownload(await res.blob(), file.file_name);
    } catch (error) {
      console.log('Error: ', error);
      toast.error('Failed to download file', toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };

  const onRenameFileDialog = (data) => {
    setUpdateDialogData(data);
    setShowRenameFileDialog(true);
  };

  const onRenameFileDialogClose = (afterSave) => {
    setShowRenameFileDialog(false);
    if (afterSave?.id) {
      getAllNotefolder();
      getAllNotefile();
    }
  };

  const onEditFolderLabelList = (name, color, id) => {
    const folderDetails = {
      name: name,
      color: color,
      id: id,
    };
    setUpdateDialogData(folderDetails);
    setDialogType('Edit');
    setShowNotefolderAddDialog(true);
  };

  const onDeleteFolderLabelList = (id) => {
    setDeleteFolderId(id);
  };

  const onDeleteFileLabelList = (id) => {
    setDeleteFileId(id);
  };
  const ondeleteNotebook = (row) => {
    setDeleteNotebook(row.id);
  };
  const onDeleteConfirmNotebook = async () => {
    dispatch(startLoading());
    try {
      await Network.post(API.deleteNotebook, {
        notebookId: deleteNotebook,
      });
      setDeleteNotebook(null);
      toast.success('Notebook deleted successfully', toastConfig);
      await getAllNotebook();
    } catch (error) {
      toast.error(error.response.data.response.status.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };

  const onDeleteConfirm = async () => {
    dispatch(startLoading());
    try {
      await Network.post(API.deleteNoteFolder, {
        folderId: deleteFolderId,
      });
      setDeleteFolderId(null);
      toast.success('Folder deleted successfully', toastConfig);
      await getAllNotefolder();
    } catch (error) {
      toast.error(error.response.data.response.status.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };

  const onDeleteConfirmFile = async () => {
    dispatch(startLoading());
    try {
      await Network.post(API.deleteNoteFile, {
        fileId: deleteFileId,
      });
      setDeleteFileId(null);
      toast.success('File deleted successfully', toastConfig);
      await getAllNotefile();
    } catch (error) {
      toast.error(error.response.data.response.status.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };

  const OnAddOrEditNotefolder = (afterSave) => {
    setUpdateDialogData(null);
    setDialogType('Edit');
    setShowNotefolderAddDialog(false);
    if (afterSave?.id) {
      getAllNotefolder();
    }
  };

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const handleNotebookClick = (data) => {
    navigate(`${PreparePath}/dashboard/notebook/notebooks/details/${data}`);
  };

  const handleFolderClick = (data) => {
    navigate(`${PreparePath}/dashboard/notebook/folders/folder/edit/${data}`);
  };

  const onChangeNotefolderSearchTerm = (term) => {
    // setNotefolderPage(1);
    setNotefolderSearchTerm(term);
  };

  const onChangeNotefileSearchTerm = (term) => {
    // setNotefolderPage(1);
    setNotefileSearchTerm(term);
  };

  const onChangeNotebookSearchTerm = (term) => {
    // setNotefolderPage(1);
    setNotebookSearchTerm(term);
  };

  return (
    <>
      {newUI ? (
        <PageTitle title="Notebook" parents={['Exercise Management']} />
      ) : null}
      <div className="tab-wrapper content-gap-none">
        <Tabs
          activeKey={tableView}
          onSelect={(k) => {
            setTableView(k);
            if (k === 'files') {
              setIsFileClicked(true);
            }
          }}
        >
          <Tab eventKey="notebooks" title="Notebook" className="p-4">
            {tableView === 'notebooks' && (
              <>
                <div className="col-12">
                  <div className="d-flex flex-column flex-md-row align-items-md-center mb-4">
                    {!isRoleReadOnly(reduxUser.roles) && (
                      <StylishNewButton
                        className="button button--primary w-100 w-md-auto mb-3 mb-md-0"
                        onClick={() => {
                          onAddNotebook(null);
                        }}
                      >
                        Create Notebook
                      </StylishNewButton>
                    )}
                    <div className="d-flex align-items-center justify-content-between ms-0 ms-md-auto">
                      <StylishNewSearchBar
                        value={notebookSearchTerm}
                        onChangeSearchTerm={onChangeNotebookSearchTerm}
                      />

                      <Help
                        classes={'ms-3 flex-shrink-0 sml'}
                        title={Notebook.title}
                        content={Notebook.content}
                        link={selfHostedKnowledgeBase || Notebook.link}
                      />
                    </div>
                  </div>
                </div>
                <div className="form-block--notebook ">
                  {allNotebook.length ? (
                    <>
                      <h4>Notebooks</h4>
                      <div className="d-flex flex-wrap">
                        {allNotebook.map((nB) => {
                          return (
                            <div className="my-3 item" key={nB?.id}>
                              <div
                                className="flex-grow-1 me-3 cursor-pointer"
                                onClick={() => handleNotebookClick(nB.id)}
                              >
                                <div className="item__top mb-3">
                                  <svg
                                    width="120"
                                    height="132"
                                    viewBox="0 0 120 132"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="item__pic"
                                  >
                                    <path
                                      onClick={() => handleNotebookClick(nB.id)}
                                      d="M113.867 124.203H63.7886V7.04193H113.867C116.965 7.04193 119.474 9.55123 119.474 12.6493V118.596C119.474 121.694 116.965 124.203 113.867 124.203Z"
                                      fill={nB?.color}
                                      className="item__opacity"
                                    />
                                    <path
                                      onClick={() => handleNotebookClick(nB.id)}
                                      d="M112.792 11.5792H102.292V119.671H112.792V11.5792Z"
                                      fill="#F4F4F4"
                                    />
                                    <path
                                      onClick={() => handleNotebookClick(nB.id)}
                                      d="M103.554 131.311H8.60737C5.5093 131.311 3 128.801 3 125.703V5.60737C3 2.5093 5.5093 0 8.60737 0H103.554C106.652 0 109.162 2.5093 109.162 5.60737V125.703C109.162 128.801 106.652 131.311 103.554 131.311Z"
                                      fill={nB?.color}
                                    />
                                    <path
                                      onClick={() => handleNotebookClick(nB.id)}
                                      d="M19.5604 0V131.311H8.60737C5.51397 131.311 3 128.801 3 125.703V5.60737C3 2.5093 5.51397 0 8.60737 0H19.5604Z"
                                      fill="white"
                                      fill-opacity="0.3"
                                    />
                                    <path
                                      onClick={() => handleNotebookClick(nB.id)}
                                      d="M11.453 19.252C13.5615 19.252 15.2707 17.5427 15.2707 15.4343C15.2707 13.3258 13.5615 11.6166 11.453 11.6166C9.34455 11.6166 7.63531 13.3258 7.63531 15.4343C7.63531 17.5427 9.34455 19.252 11.453 19.252Z"
                                      fill="#2F3339"
                                    />
                                    <path
                                      onClick={() => handleNotebookClick(nB.id)}
                                      d="M11.453 33.5648C13.5615 33.5648 15.2707 31.8555 15.2707 29.7471C15.2707 27.6386 13.5615 25.9294 11.453 25.9294C9.34455 25.9294 7.63531 27.6386 7.63531 29.7471C7.63531 31.8555 9.34455 33.5648 11.453 33.5648Z"
                                      fill="#2F3339"
                                    />
                                    <path
                                      onClick={() => handleNotebookClick(nB.id)}
                                      d="M11.453 47.915C13.5615 47.915 15.2707 46.2057 15.2707 44.0973C15.2707 41.9888 13.5615 40.2796 11.453 40.2796C9.34455 40.2796 7.63531 41.9888 7.63531 44.0973C7.63531 46.2057 9.34455 47.915 11.453 47.915Z"
                                      fill="#2F3339"
                                    />
                                    <path
                                      onClick={() => handleNotebookClick(nB.id)}
                                      d="M11.453 62.2652C13.5615 62.2652 15.2707 60.556 15.2707 58.4475C15.2707 56.3391 13.5615 54.6298 11.453 54.6298C9.34455 54.6298 7.63531 56.3391 7.63531 58.4475C7.63531 60.556 9.34455 62.2652 11.453 62.2652Z"
                                      fill="#2F3339"
                                    />
                                    <path
                                      onClick={() => handleNotebookClick(nB.id)}
                                      d="M11.453 76.6154C13.5615 76.6154 15.2707 74.9061 15.2707 72.7977C15.2707 70.6892 13.5615 68.98 11.453 68.98C9.34455 68.98 7.63531 70.6892 7.63531 72.7977C7.63531 74.9061 9.34455 76.6154 11.453 76.6154Z"
                                      fill="#2F3339"
                                    />
                                    <path
                                      onClick={() => handleNotebookClick(nB.id)}
                                      d="M11.453 90.9656C13.5615 90.9656 15.2707 89.2563 15.2707 87.1479C15.2707 85.0394 13.5615 83.3302 11.453 83.3302C9.34455 83.3302 7.63531 85.0394 7.63531 87.1479C7.63531 89.2563 9.34455 90.9656 11.453 90.9656Z"
                                      fill="#2F3339"
                                    />
                                    <path
                                      onClick={() => handleNotebookClick(nB.id)}
                                      d="M11.453 105.316C13.5615 105.316 15.2707 103.607 15.2707 101.498C15.2707 99.3897 13.5615 97.6804 11.453 97.6804C9.34455 97.6804 7.63531 99.3897 7.63531 101.498C7.63531 103.607 9.34455 105.316 11.453 105.316Z"
                                      fill="#2F3339"
                                    />
                                    <path
                                      onClick={() => handleNotebookClick(nB.id)}
                                      d="M11.453 119.671C13.5615 119.671 15.2707 117.961 15.2707 115.853C15.2707 113.745 13.5615 112.035 11.453 112.035C9.34455 112.035 7.63531 113.745 7.63531 115.853C7.63531 117.961 9.34455 119.671 11.453 119.671Z"
                                      fill="#2F3339"
                                    />
                                    <path
                                      onClick={() => handleNotebookClick(nB.id)}
                                      d="M9.8643 17.0277H1.58876C0.710267 17.0277 0 16.3175 0 15.439C0 14.5605 0.710267 13.8502 1.58876 13.8502H9.85963C10.7381 13.8502 11.4484 14.5605 11.4484 15.439C11.4531 16.3128 10.7428 17.0277 9.8643 17.0277Z"
                                      fill="#FBFFFB"
                                    />
                                    <path
                                      onClick={() => handleNotebookClick(nB.id)}
                                      d="M9.8643 31.3733H1.58876C0.710267 31.3733 0 30.663 0 29.7845C0 28.906 0.710267 28.1957 1.58876 28.1957H9.85963C10.7381 28.1957 11.4484 28.906 11.4484 29.7845C11.4531 30.6583 10.7428 31.3733 9.8643 31.3733Z"
                                      fill="#FBFFFB"
                                    />
                                    <path
                                      onClick={() => handleNotebookClick(nB.id)}
                                      d="M9.8643 45.7141H1.58876C0.710267 45.7141 0 45.0038 0 44.1253C0 43.2469 0.710267 42.5366 1.58876 42.5366H9.85963C10.7381 42.5366 11.4484 43.2469 11.4484 44.1253C11.4531 45.0038 10.7428 45.7141 9.8643 45.7141Z"
                                      fill="#FBFFFB"
                                    />
                                    <path
                                      onClick={() => handleNotebookClick(nB.id)}
                                      d="M9.8643 60.0596H1.58876C0.710267 60.0596 0 59.3494 0 58.4709C0 57.5924 0.710267 56.8821 1.58876 56.8821H9.85963C10.7381 56.8821 11.4484 57.5924 11.4484 58.4709C11.4531 59.3494 10.7428 60.0596 9.8643 60.0596Z"
                                      fill="#FBFFFB"
                                    />
                                    <path
                                      onClick={() => handleNotebookClick(nB.id)}
                                      d="M9.8643 74.4051H1.58876C0.710267 74.4051 0 73.6949 0 72.8164C0 71.9379 0.710267 71.2276 1.58876 71.2276H9.85963C10.7381 71.2276 11.4484 71.9379 11.4484 72.8164C11.4531 73.6949 10.7428 74.4051 9.8643 74.4051Z"
                                      fill="#FBFFFB"
                                    />
                                    <path
                                      onClick={() => handleNotebookClick(nB.id)}
                                      d="M9.8643 88.7507H1.58876C0.710267 88.7507 0 88.0404 0 87.1619C0 86.2834 0.710267 85.5732 1.58876 85.5732H9.85963C10.7381 85.5732 11.4484 86.2834 11.4484 87.1619C11.4531 88.0404 10.7428 88.7507 9.8643 88.7507Z"
                                      fill="#FBFFFB"
                                    />
                                    <path
                                      onClick={() => handleNotebookClick(nB.id)}
                                      d="M9.8643 103.096H1.58876C0.710267 103.096 0 102.386 0 101.507C0 100.629 0.710267 99.9187 1.58876 99.9187H9.85963C10.7381 99.9187 11.4484 100.629 11.4484 101.507C11.4531 102.386 10.7428 103.096 9.8643 103.096Z"
                                      fill="#FBFFFB"
                                    />
                                    <path
                                      onClick={() => handleNotebookClick(nB.id)}
                                      d="M9.8643 117.442H1.58876C0.710267 117.442 0 116.731 0 115.853C0 114.974 0.710267 114.264 1.58876 114.264H9.85963C10.7381 114.264 11.4484 114.974 11.4484 115.853C11.4531 116.731 10.7428 117.442 9.8643 117.442Z"
                                      fill="#FBFFFB"
                                    />
                                  </svg>
                                  {nB.icon && (
                                    <span
                                      className="item__icon"
                                      onClick={() => handleNotebookClick(nB.id)}
                                    >
                                      {
                                        AllIcon.find(
                                          (icon) => icon.name === nB.icon
                                        ).icon
                                      }
                                    </span>
                                  )}
                                </div>
                                <h6 className="weight-500 text-center mb-0 break-word">
                                  {nB?.title}
                                </h6>
                              </div>
                              <Dropdown className="flex-shrink-0 ms-auto">
                                <Dropdown.Toggle className="button--icon">
                                  <SharedIcon iconName="more_vert" bold />
                                </Dropdown.Toggle>
                                <Dropdown.Menu align="end">
                                  <Dropdown.Item
                                    onClick={() =>
                                      navigate(
                                        `${PreparePath}/dashboard/notebook/notebooks/details/${nB.id}`
                                      )
                                    }
                                  >
                                    <SharedIcon iconName="visibility" />
                                    View
                                  </Dropdown.Item>
                                  {!isRoleReadOnly(reduxUser.roles) && (
                                    <>
                                      <Dropdown.Item
                                        onClick={() => {
                                          onAddNotebook(nB);
                                        }}
                                      >
                                        <SharedIcon iconName="stylus" />
                                        Edit Notebook
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        onClick={() => ondeleteNotebook(nB)}
                                      >
                                        <SharedIcon iconName="delete" />
                                        Delete Notebook
                                      </Dropdown.Item>
                                    </>
                                  )}
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          );
                        })}
                      </div>
                    </>
                  ) : (
                    <div className="bg-gray-800 text-center p-5">
                      <div className="py-0 py-md-4">
                        <div className="d-flex justify-content-center mb-4">
                          <img src={IconEmptyExercise} alt="" />
                        </div>
                        <p className="weight-600 mb-4">
                          A fresh start. Create the first entry.
                          <a href={selfHostedKnowledgeBase || MiscellaneousHelpLink.link} target="_blank">
                            {' '}
                            Need more help?
                          </a>
                        </p>
                        <StylishNewButton
                          className="button--primary"
                          onClick={() => {
                            onAddNotebook(null);
                          }}
                          type="button"
                        >
                          Create Notebook
                        </StylishNewButton>
                      </div>
                    </div>
                  )}
                </div>
              </>
            )}
          </Tab>
          <Tab eventKey="folders" title="Folders" className="p-4">
            {tableView === 'folders' && (
              <>
                <div className="col-12">
                  <div className="d-flex flex-column flex-md-row align-items-md-center mb-4">
                    {!isRoleReadOnly(reduxUser.roles) && (
                      <StylishNewButton
                        className="button button--primary w-100 w-md-auto mb-3 mb-md-0"
                        onClick={onAddNoteFolder}
                        type="button"
                      >
                        Create Folder
                      </StylishNewButton>
                    )}
                    <div className="d-flex align-items-center justify-content-between ms-0 ms-md-auto">
                      <StylishNewSearchBar
                        value={notefolderSearchTerm}
                        onChangeSearchTerm={onChangeNotefolderSearchTerm}
                      />

                      <Help
                        classes={'ms-3 flex-shrink-0 sml'}
                        title={FoldersandFileUploads.title}
                        content={FoldersandFileUploads.content}
                        link={selfHostedKnowledgeBase || FoldersandFileUploads.link}
                      />
                    </div>
                  </div>
                </div>

                {allNotefolder.length ? (
                  <>
                    <h4>Folder</h4>
                    <div className="form-block--folder">
                      <div className="d-flex flex-wrap">
                        {allNotefolder.map((nF) => {
                          return (
                            <div className="my-3 item" key={nF.id}>
                              <div
                                className="flex-grow-1 me-3 cursor-pointer"
                                onClick={() => handleFolderClick(nF.id)}
                              >
                                <div className="mb-2">
                                  <svg
                                    width="148"
                                    height="105"
                                    viewBox="0 0 148 105"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="item__pic"
                                  >
                                    <path
                                      d="M134.4 63.2253H13.6985V4.50243C13.6985 2.01484 15.7133 0 18.2009 0H53.7419C55.2812 0 56.7164 0.787925 57.5409 2.088L64.0694 12.3592H129.9C132.388 12.3592 134.403 14.374 134.403 16.8616V63.2253H134.4Z"
                                      onClick={() => handleFolderClick(nF.id)}
                                      fill={nF.color}
                                      className="item__opacity"
                                    />
                                    <path
                                      d="M128.077 74.7206H19.1577V23.4689C19.1577 20.9813 21.1726 18.9664 23.6601 18.9664H123.575C126.062 18.9664 128.077 20.9813 128.077 23.4689V74.7206Z"
                                      onClick={() => handleFolderClick(nF.id)}
                                      fill="white"
                                    />
                                    <path
                                      onClick={() => handleFolderClick(nF.id)}
                                      d="M147.916 34.9642L135.011 101.254C134.6 103.37 132.745 104.895 130.59 104.895H17.4073C15.2518 104.895 13.4002 103.367 12.9865 101.254L0.0842294 34.9642C-0.456062 32.1839 1.67133 29.6007 4.50505 29.6007H143.498C146.329 29.6007 148.456 32.1839 147.916 34.9642Z"
                                      fill={nF.color}
                                    />
                                  </svg>
                                </div>
                                <h6 className="weight-500 text-center mb-0 break-word">
                                  {nF.folder_name}
                                </h6>
                              </div>
                              <Dropdown className="flex-shrink-0">
                                <Dropdown.Toggle className="button--icon">
                                  <i
                                    className="fa fa-ellipsis-v"
                                    aria-hidden="true"
                                  ></i>
                                </Dropdown.Toggle>
                                <Dropdown.Menu align="end">
                                  {!isRoleReadOnly(reduxUser.roles) && (
                                    <>
                                      <Dropdown.Item
                                        onClick={() =>
                                          onEditFolderLabelList(
                                            nF.folder_name,
                                            nF.color,
                                            nF.id
                                          )
                                        }
                                      >
                                        <SharedIcon iconName="stylus" />
                                        Edit Folder
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        onClick={() => onMoveFolderDialog(nF)}
                                      >
                                        <img src={IconMove} alt="" />
                                        Move Folder
                                      </Dropdown.Item>
                                    </>
                                  )}
                                  <Dropdown.Item
                                    onClick={() => handleFolderClick(nF.id)}
                                  >
                                    <SharedIcon iconName="visibility" />
                                    View Folder
                                  </Dropdown.Item>

                                  {!isRoleReadOnly(reduxUser.roles) && (
                                    <Dropdown.Item
                                      onClick={() =>
                                        onDeleteFolderLabelList(nF.id)
                                      }
                                    >
                                      <SharedIcon iconName="delete" />
                                      Delete Folder
                                    </Dropdown.Item>
                                  )}
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="bg-gray-800 text-center p-5">
                      <div className="py-0 py-md-4">
                        <div className="d-flex justify-content-center mb-4">
                          <img src={IconEmptyExercise} alt="" />
                        </div>
                        <p className="weight-600 mb-4">
                          A fresh start. Create the first entry.
                          <a href={selfHostedKnowledgeBase || MiscellaneousHelpLink.link} target="_blank">
                            {' '}
                            Need more help?
                          </a>
                        </p>
                        <StylishNewButton
                          className="button--primary"
                          onClick={onAddNoteFolder}
                          type="button"
                        >
                          Create Folder
                        </StylishNewButton>
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
          </Tab>
          <Tab eventKey="files" title="Files" className="p-4">
            {tableView === 'files' && (
              <>
                <div className="col-12 mb-4">
                  <div className="d-flex flex-column flex-lg-row align-items-lg-center">
                    <div className="d-flex align-items-center flex-column flex-md-row mb-3 mb-lg-0">
                      {!isRoleReadOnly(reduxUser.roles) && (
                        <StylishNewButton
                          className="button--primary w-100 w-md-auto mb-1 mb-md-0"
                          onClick={handleClick}
                          type="button"
                        >
                          Upload Files
                        </StylishNewButton>
                      )}
                      <span className="notifyMessage weight-500 ms-sm-0 ms-md-auto ms-lg-4">
                        *Maximum upload limit is 10 files at a time
                      </span>
                    </div>

                    <input
                      ref={hiddenFileInput}
                      type="file"
                      name="file"
                      multiple
                      accept=".jpg,.pdf,.png"
                      style={{ display: 'none' }}
                      onInput={(e) => changeHandler(e)}
                    />
                    <div className="d-flex align-items-center justify-content-between ms-0 ms-lg-auto w-100 w-lg-auto">
                      <StylishNewSearchBar
                        className={'w-100'}
                        value={notefileSearchTerm}
                        onChangeSearchTerm={onChangeNotefileSearchTerm}
                      />
                    </div>
                  </div>
                </div>
                <div className="form-block--files">
                  {allNotefile.length ? (
                    <>
                      <h4>Files </h4>
                      <div className="d-flex flex-wrap">
                        {allNotefile.map((nFF) => {
                          return nFF?.fileUrl ? (
                            <div className="my-3 item" key={nFF?.id}>
                              <div
                                className="cards cards__uploaded-file item__uploaded--file flex-grow-1 me-2 cursor-pointer"
                                onClick={() => FileOpen(nFF)}
                              >
                                {nFF?.mimetype === 'application/pdf' ? (
                                  <span className="icon item__icon--file">
                                    <img src={IconFile} alt="" />
                                  </span>
                                ) : (
                                  <>
                                    <span
                                      className="icon item__icon--file"
                                      style={{
                                        backgroundImage: `url('${nFF?.iconUrl}')`,
                                      }}
                                    ></span>
                                  </>
                                )}
                                <span className="title item__tittle">
                                  {nFF?.file_name}
                                </span>
                              </div>
                              <Dropdown className="flex-shrink-0">
                                <Dropdown.Toggle className="button--icon">
                                  <i
                                    className="fa fa-ellipsis-v"
                                    aria-hidden="true"
                                  ></i>
                                </Dropdown.Toggle>
                                <Dropdown.Menu align="end">
                                  <Dropdown.Item
                                    onClick={() => onMoveFolderDialog(nFF)}
                                  >
                                    <img src={IconMove} alt="" />
                                    Move File
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    onClick={() => onRenameFileDialog(nFF)}
                                  >
                                    <SharedIcon iconName="stylus" />
                                    Rename File
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    onClick={() => {
                                      downloadFile(nFF);
                                    }}
                                  >
                                    <img src={IconDownload} alt="" />
                                    Download File
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    onClick={() =>
                                      onDeleteFileLabelList(nFF.id)
                                    }
                                  >
                                    <SharedIcon iconName="delete" />
                                    Delete File
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          ) : null;
                        })}
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="bg-gray-800 text-center p-5">
                        <div className="py-0 py-md-4">
                          <div className="d-flex justify-content-center mb-4">
                            <img src={IconEmptyExercise} alt="" />
                          </div>
                          <p className="weight-600 mb-4">
                            A fresh start. Create the first entry.
                            <a
                              href={selfHostedKnowledgeBase || MiscellaneousHelpLink.link}
                              target="_blank"
                            >
                              {' '}
                              Need more help?
                            </a>
                          </p>
                          {!isRoleReadOnly(reduxUser.roles) && (
                            <StylishNewButton
                              className="button--primary"
                              onClick={handleClick}
                              type="button"
                            >
                              Upload Files
                            </StylishNewButton>
                          )}
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </>
            )}
          </Tab>
        </Tabs>
      </div>

      {showNotebookAddDialog && (
        <AddNotebookDialog
          show={showNotebookAddDialog}
          onClose={OnAddOrEditNotebook}
          updateDialogData={updateDialogData}
          dialogType={dialogTypeNotebook}
        />
      )}
      {showNotefolderAddDialog && (
        <AddFolderDialog
          show={showNotefolderAddDialog}
          onClose={OnAddOrEditNotefolder}
          dialogType={dialogType}
          updateDialogData={updateDialogData}
        />
      )}

      {showMoveFolderDialog && (
        <MoveFolderDialog
          show={showMoveFolderDialog}
          onClose={onMoveFolderDialogClose}
          dialogType={folderFileDialogType}
          updateDialogData={updateDialogData}
        />
      )}
      {showRenameFileDialog && (
        <RenameFileDialog
          show={showRenameFileDialog}
          onClose={onRenameFileDialogClose}
          updateDialogData={updateDialogData}
        />
      )}
      {showFileViewDialog && showFileViewUrl && (
        <StylishViewFileDialog
          show={showFileViewDialog}
          onClose={fileClose}
          fileUrl={showFileViewUrl}
        />
      )}
      {deleteFolderId && (
        <StylishConfirmDialog
          show={deleteFolderId}
          onClose={() => setDeleteFolderId(null)}
          dialogTitle={'Delete Folder'}
          dialogContent={'Are you sure you want to do this?'}
          onConfirm={onDeleteConfirm}
        />
      )}
      {deleteFileId && (
        <StylishConfirmDialog
          show={deleteFileId}
          onClose={() => setDeleteFileId(null)}
          dialogTitle={'Delete File'}
          dialogContent={'Are you sure you want to do this?'}
          onConfirm={onDeleteConfirmFile}
        />
      )}
      {deleteNotebook && (
        <StylishConfirmDialog
          show={deleteNotebook}
          onClose={() => setDeleteNotebook(null)}
          dialogTitle={'Delete Notebook'}
          dialogContent={'Are you sure you want this?'}
          onConfirm={onDeleteConfirmNotebook}
        />
      )}

      {showAttachNotebookDialog && (
        <AttachNotebookDialog
          show={showAttachNotebookDialog}
          onClose={() => setShowAttachNotebookDialog(false)}
        />
      )}
    </>
  );
}
