import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import API from '../../../service/api';
import Network from '../../../service/Network';
import IconChecked from 'assets/images/icon__tick.svg';
import { inputStringLength, toastConfig } from 'assets/data/config';
import { PreparePath } from 'utils/sharedUtils/sharedConst';
import StylishNewInput from 'components/DesignSystems/New/StylishNewInput';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { endLoading, startLoading } from "../../../../reducers/loading/loading.action";

export default function AddFolderDialog({
  onClose,
  show,
  dialogType,
  updateDialogData,
  parentId,
}) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    setError,
    watch,
    control,
    clearErrors,
  } = useForm({ mode: 'onChange' });

  const workspaceId = useSelector(
    (state) => state.prepare.workspace.selectedWorkspace
  );

  const dispatch = useDispatch();

  const colors = [
    '#D32F2F',
    '#C2185B',
    '#7B1FA2',
    '#512DA8',
    '#303F9F',
    '#1976D2',
    '#0288D1',
    '#0097A7',
    '#00796B',
    '#388E3C',
    '#689F38',
    '#AFB42B',
    '#FBC02D',
    '#FFA000',
    '#F57C00',
    '#E64A19',
    '#5D4037',
    '#616161',
    '#455A64',
  ];

  useEffect(() => {
    if (dialogType === 'Edit' && !!updateDialogData) {
      setValue('color', updateDialogData.color);
      setValue('customColor', updateDialogData.color);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dialogType]);

  const predefinedColorHandler = () => {
    const checkedValue = getValues('color');
    setValue('customColor', checkedValue);
    if (!checkedValue) {
      setError('customColor');
    } else if (checkedValue) {
      clearErrors('customColor');
    }
  };

  useEffect(() => {
    const enteredValue = getValues('customColor');
    const isPredefinedColor = colors.includes(enteredValue);
    isPredefinedColor ? setValue('color', enteredValue) : setValue('color', '');
  }, [watch('customColor')]);

  const onSubmit = async (data) => {
    const formData = {
      folder_name: data.title,
      color: data?.customColor,
    };
    dispatch(startLoading());
    try {
      const newNoteFolder = await Network.post(
        dialogType === 'Create' ? API.createNoteFolder : API.updateNotefolder,
        updateDialogData?.id
          ? {
              folder_name: formData.folder_name,
              color: formData.color,
              workspaceId: workspaceId,
              folderId: updateDialogData?.id || null,
              parentId: parentId || null,
            }
          : {
              folder_name: formData.folder_name,
              color: formData.color,
              workspaceId: workspaceId,
              parentId: parentId || null,
            }
      );
      onClose(newNoteFolder.data.response.dataset[0]);
      toast.success(
        dialogType === 'Create'
          ? 'Folder created successfully'
          : 'Folder updated successfully',
        toastConfig
      );
    } catch (error) {
      toast.error(error.response.data.response.status.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };
  useEffect(() => {
    const listener = (event) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        event.preventDefault();
        handleSubmit(onSubmit)();
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, []);
  return (
    <Modal
      show={show}
      onHide={onClose}
      centered
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title>{dialogType} Folder</Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <div className="mb-3">
            <Controller
              control={control}
              defaultValue={updateDialogData?.name}
              name="title"
              rules={{ required: true, maxLength: inputStringLength }}
              render={({ field: { onChange, value } }) => (
                <StylishNewInput
                  placeholder="Name"
                  type="text"
                  value={value}
                  onChange={onChange}
                />
              )}
            />

            {errors?.title?.type === 'required' && (
              <span className="form-text form-error">
                This is a required field
              </span>
            )}
            {errors?.title?.type === 'maxLength' && (
              <span className="form-text form-error">
                Maximum {inputStringLength} characters allowed
              </span>
            )}
          </div>
          <div className="mb-0">
            <label className="form-label">Color</label>
            <div className="mb-3">
              <ul className="nav nav__form-color">
                {colors.map((item, index) => (
                  <li key={index}>
                    <div className="form-color" style={{ background: item }}>
                      <Controller
                        control={control}
                        name="color"
                        rules={{ required: false }}
                        render={({ field: { onChange, value } }) => (
                          <StylishNewInput
                            type="radio"
                            name="colors"
                            value={item}
                            onChange={(e) => {
                              onChange(e);
                              predefinedColorHandler(e);
                            }}
                            checked={item === value}
                          />
                        )}
                      />
                      <span className="icon">
                        <img src={IconChecked} alt="" />
                      </span>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
            <div className="m-0">
              <Controller
                name="customColor"
                control={control}
                rules={{
                  required: true,
                  pattern: /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/i,
                }}
                render={({ field: { onChange, value } }) => (
                  <StylishNewInput
                    type="text"
                    className="form-control flex-grow-1"
                    placeholder="HEX Value"
                    onChange={(e) => {
                      onChange(e);
                      // enteredColorHandler;
                    }}
                    value={value}
                  />
                )}
              />
            </div>
            {errors?.customColor?.type === 'required' && (
              <span className="form-text form-error">
                This is a required field
              </span>
            )}
            {errors?.customColor?.type === 'pattern' && (
              <div className="alert alert-info mt-2" role="alert">
                <ul className="list-ordered">
                  <li>{"The HEX value must start with '#'"}</li>
                  <li>The HEX value must contain at least 3 characters</li>
                  <li>The HEX value can contain max 6 characters</li>
                </ul>
              </div>
            )}
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div className="button-group">
            <StylishNewButton
              className="button--secondary button--reverse"
              onClick={onClose}
              type="button"
            >
              Cancel
            </StylishNewButton>
            <StylishNewButton className="button--primary" type="submit">
              Save
            </StylishNewButton>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
