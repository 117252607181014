import React, { useState, useEffect, useRef } from 'react';
import { Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router';
import IconDelete from 'assets/images/icon__delete--blue.svg';
import StylishNewSelect from 'components/DesignSystems/New/StylishNewSelect';
import { useSelector, useDispatch } from 'react-redux';
import Network from 'PREPAREsrc/service/Network';
import API from 'PREPAREsrc/service/api';
import { startLoading, endLoading } from 'reducers/loading/loading.action';
import { toast } from 'react-toastify';
import { useForm, Controller } from 'react-hook-form';
import StylishNewInput from 'components/DesignSystems/New/StylishNewInput';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { StylishConfirmDialog } from 'components/DesignSystems/New/StylishConfirmDialog';

export default function AddRelationshipsDialog({
  onClose,
  show,
  nameOfPersona,
  finalRelationshipData,
  linkedRelationship,
  personaId,
}) {
  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors },
    getValues,
    clearErrors,
  } = useForm();
  const [personaList, setPersonaList] = useState(null);
  const [relationOf, setRelationOf] = useState(null);
  const [relationData, setRelationData] = useState([]);
  const [relationIsThe, setRelationIsThe] = useState(null);
  const [error, setError] = useState(null);
  const [relationPersonaData, setRelationPersonaData] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [modifiedLinkedRelationship, setModifiedLinkedRelationship] = useState(
    null
  );

  const workspaceId = useSelector(
    (state) => state.prepare.workspace.selectedWorkspace
  );
  const dispatch = useDispatch();
  useEffect(() => {
    setModifiedLinkedRelationship(
      linkedRelationship.map((relation) => {
        return {
          isThe: relation?.relationLable,
          relationOfLabel: relation?.name,
          relationOfValue: relation?.linkedPersona,
          relationId: relation?.relationId,
        };
      })
    );
  }, []);

  const onSubmit = (data) => {
    finalRelationshipData([
      ...relationData,
      ...modifiedLinkedRelationship,
      {
        // data: {
        isThe: data?.isThe,
        relationOfLabel: data?.of?.label,
        relationOfValue: data?.of?.value,
        relationId: data?.relationId,

        // },
      },
    ]);
  };
  const SaveAndAddAnother = (e) => {
    e.preventDefault();
    if (relationIsThe && relationOf?.value) {
      setRelationData([
        ...relationData,
        {
          isThe: relationIsThe,
          relationOfLabel: relationOf?.label,
          relationOfValue: relationOf?.value,
        },
      ]);
      setRelationOf(null);
      setValue('of', null);
      setValue('isThe', '');
      setError(null);
    } else {
      setError('This is a required field');
    }
  };
  const mediaPersonaList = async () => {
    dispatch(startLoading());
    try {
      const response = await Network.get(API.mediaPersonaList, {
        workspaceId: workspaceId,
      });
      const newPersonaList = response?.data?.response?.dataset.map(
        ({ name, id }) => ({
          value: id,
          label: name,
        })
      );
      setPersonaList(newPersonaList);
    } catch (error) {
    } finally {
      dispatch(endLoading());
    }
  };
  useEffect(() => {
    mediaPersonaList();
  }, []);

  const DeleteRelation = (data) => {
    dispatch(startLoading());
    try {
      Network.post(API.removePersonaRelatedProfile, {
        // mainPersonaId: personaId,
        // linkedPersonaId: data?.relationOfValue,
        id: data?.relationId,
      })
        .then((resp) => {
          onClose();
          setShowModal(false);
        })
        .catch(console.log);
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(endLoading());
    }
  };

  return (
    <>
      <Modal
        show={show}
        onHide={onClose}
        centered
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton closeVariant="white">
          <Modal.Title>Add Relationships</Modal.Title>
        </Modal.Header>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body>
            <h5>{nameOfPersona}</h5>
            <div className="mb-3">
              <label className="form-label">Is the</label>
              <Controller
                control={control}
                name="isThe"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <StylishNewInput
                    type="text"
                    className="form-control"
                    placeholder="e.g. mother, father,son,daughter"
                    onChange={(e) => {
                      setRelationIsThe(e?.target?.value);
                      onChange(e);
                    }}
                    value={value || ''}
                  />
                )}
              />
              {(errors?.isThe || (error && relationIsThe === null)) && (
                <span className="form-text form-error">
                  This is a required field
                </span>
              )}
            </div>
            <div>
              <label className="form-label">Of</label>
              <Controller
                control={control}
                name="of"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <StylishNewSelect
                    options={personaList}
                    isClearable={false}
                    isSearchable={true}
                    isMulti={false}
                    isDisabled={false}
                    value={relationOf}
                    onChange={(e) => {
                      setRelationOf(e);
                      onChange(e);
                    }}
                  />
                )}
              />

              {(errors?.of || (error && relationOf === null)) && (
                <span className="form-text form-error">
                  This is a required field
                </span>
              )}
            </div>
            <hr className="dashed" />
            {/* {relationData?.length > 0 ? (
            <div className="mb-4">
              <h5 className="mb-0">Pending Changes</h5>
              {relationData?.map((relationshipData, index) => {
                return (
                  <div
                    className="d-flex align-items-center border-700 border-start-0 border-end-0 border-top-0 p-3"
                    key={index}
                  >
                    <p className="mb-0 me-1">
                      <span className="weight-600">{nameOfPersona}</span>
                      <span className="mx-1">is</span>
                      <span className="weight-600">
                        {relationshipData?.isThe}
                      </span>
                      <span className="mx-1">of</span>
                      <span className="weight-600">
                        {relationshipData?.relationOfLabel}
                      </span>
                    </p>

                    <div className="cursor-pointer ms-auto">
                      <img
                        className="img-h-16"
                        src={IconDelete}
                        alt=""
                        onClick={() => {
                          setRelationData(
                            relationData.filter(
                              (obj) =>
                                obj?.relationOfValue !==
                                relationshipData?.relationOfValue
                            )
                          );
                        }}
                      />
                    </div>
                  </div>
                );
              })}
            </div>

) : null} */}

            <h5 className="mb-0">Linked Relationships</h5>
            {modifiedLinkedRelationship?.map((relationshipData, index) => {
              return (
                <div
                  className="d-flex align-items-center border-700 border-start-0 border-end-0 border-top-0 p-3"
                  key={index}
                >
                  <p className="mb-0 me-1">
                    <span className="weight-600">{nameOfPersona}</span>
                    <span className="mx-1">is</span>
                    <span className="weight-600">
                      {relationshipData?.isThe}
                    </span>
                    <span className="mx-1">of</span>
                    <span className="weight-600">
                      {' '}
                      {relationshipData?.relationOfLabel}
                    </span>
                  </p>

                  <div className="cursor-pointer ms-auto">
                    <img
                      className="img-h-16"
                      src={IconDelete}
                      alt=""
                      // onClick={() => {
                      //   setRelationData([
                      //     ...relationData,
                      //     {
                      //       relationOfLabel: relationshipData?.isThe,
                      //       isThe: relationshipData?.relationOfLabel,
                      //       relationOfValue: relationshipData?.relationOfValue,
                      //     },
                      //   ]);
                      //   setModifiedLinkedRelationship(
                      //     modifiedLinkedRelationship.filter(
                      //       (obj) =>
                      //         obj?.relationOfValue !==
                      //         relationshipData?.relationOfValue
                      //     )
                      //   );
                      onClick={() => {
                        setRelationPersonaData(relationshipData),
                          setShowModal(true);
                      }}

                      // {
                      //   linkedRelationship.filter(
                      //     (obj) =>
                      //       obj?.relatesToPersonaId !==
                      //       relationshipData?.relatesToPersonaId
                      //   );
                      // }
                      // }}
                    />
                  </div>
                </div>
              );
            })}
          </Modal.Body>

          <Modal.Footer>
            <div className="button-group">
              <StylishNewButton
                className="button--secondary button--reverse"
                onClick={onClose}
              >
                Cancel
              </StylishNewButton>
              {/* <StylishNewButton
              className="button--primary button--reverse"
              onClick={(e) => {
                SaveAndAddAnother(e);
              }}
            >
              Save & Add Another
            </StylishNewButton> */}
              <StylishNewButton className="button--primary" type="submit">
                Save
              </StylishNewButton>
            </div>
          </Modal.Footer>
        </form>
      </Modal>
      {showModal && (
        <StylishConfirmDialog
          show={showModal}
          onClose={() => setShowModal(false)}
          dialogTitle={'Delete persona relation'}
          dialogContent={'Are you sure you want this?'}
          onConfirm={() => DeleteRelation(relationPersonaData)}
        />
      )}
    </>
  );
}
