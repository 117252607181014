import React, { useEffect, useState } from 'react';
import {
  deleteInitialIncidentReport,
  getAllInitialIncidentReport,
} from 'actions/ReportActions';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import StylishNewTable from 'components/DesignSystems/New/StylishNewTable';
import StylishNewTableAction from 'components/DesignSystems/New/StylishNewTableAction';
import { StylishConfirmDialog } from 'components/DesignSystems/New/StylishConfirmDialog';
import {
  fetchNestedCategories,
  fetchNestedCategoryItems,
} from 'actions/locationAction';
import ManageCustomFieldsModal from './ManageCustomFieldsModal';
import RecipientListModal from './RecipientListModal';
import PageTitle from 'components/headers/PageTitle';
import { useNewUIFlag } from 'utils/featureFlags';
import { SharedIcon } from 'components/SharedIcon/SharedIcon';
import { Modal } from 'react-bootstrap';
import ManageChecklistModal from './ManageChecklistModal';

export default function ReportList({ onChangeTab }) {
  const reduxNestedCategoryItems = useSelector((state) => {
    return state.app.nestedCategoryItems;
  });

  const reduxNestedCategories = useSelector((state) => {
    return state.app.nestedCategories;
  });

  const reduxFeatureFlags = useSelector((state) => {
    return state.app.featureFlags;
  });

  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const reduxCurrentIncident = useSelector((state) => {
    return state.app.currentIncident;
  });
  const { user_guid } = useSelector((state) => {
    return state.app.user;
  });
  const reduxCurrentlySelectedGroup = useSelector((state) => {
    return state.app.currentlySelectedGroup;
  });
  const allInitialIncidentReport = useSelector(
    (state) =>
      state.app.allInitialIncidentReport &&
      state.app.allInitialIncidentReport.map((item) => {
        return {
          ...item,
          incident_type: item.incident_types
            ? item.incident_types.join(', ')
            : item.incidentType,
          start_date: moment(item.start_date).format('MM/DD/YYYY'),
          alreadyEscalated: item.current_status.includes('Escalated'),
        };
      })
  );
  const reduxDispatch = useDispatch();
  const newUI = useNewUIFlag();

  const incident_id =
    (reduxCurrentIncident && reduxCurrentIncident.id) || undefined;

  const [showCustomFieldsModal, setShowCustomFieldsModal] = useState(false);
  const [showChecklistModal, setShowChecklistModal] = useState(false);

  useEffect(() => {
    if (!!incident_id && !!reduxCurrentlySelectedGroup) {
      reduxDispatch(getAllInitialIncidentReport());
    }
  }, [reduxCurrentlySelectedGroup, incident_id]);

  useEffect(() => {
    if (
      !!reduxCurrentlySelectedGroup &&
      !!reduxCurrentlySelectedGroup.group_guid
    ) {
      reduxDispatch(fetchNestedCategories());
      reduxDispatch(fetchNestedCategoryItems());
    }
  }, [reduxCurrentlySelectedGroup]);

  const onDeleteInitialIncidentReport = (id) => {
    setShowConfirmDialog(id);
  };

  const onCloseConfirmDialog = () => {
    setShowConfirmDialog(false);
  };

  const onView = (row) => {
    onChangeTab(row, 'View Single');
  };
  const onEdit = (row) => {
    onChangeTab(row, 'Create');
  };
  const onCreate = () => {
    onChangeTab(undefined, 'Create');
  };
  const onManageChecklist = () => {
    setShowChecklistModal(true);
  };
  const onManageCustomFields = () => {
    setShowCustomFieldsModal(true);
  };
  const onCloseManageCustomFieldsModal = () => {
    setShowCustomFieldsModal(false);
  };
  const onCloseChecklistModal = () => {
    setShowChecklistModal(false);
  };
  const columns_report_data = [
    {
      dataField: 'incident_name',
      text: 'Incident Name',
      sort: true,
      headerAttrs: { title: 'Sort By:' },
      attrs: { title: 'Incident Name' },
    },
    {
      dataField: 'incident_type',
      text: 'Incident Type',
      sort: true,
      attrs: { title: 'Incident Type' },
    },
    {
      dataField: 'start_date',
      text: 'Start Date',
      sort: true,
      sortFunc: (a, b, dir) => {
        const dateA = moment(a);
        const dateB = moment(b);
        if (dir === 'asc') {
          if (dateA.isBefore(dateB)) {
            return -1;
          }
          if (dateA.isAfter(dateB)) {
            return 1;
          }
          return 0;
        } else {
          if (dateA.isAfter(dateB)) {
            return -1;
          }
          if (dateA.isBefore(dateB)) {
            return 1;
          }
          return 0;
        }
      },
      attrs: { title: 'Start Date' },
    },
    {
      dataField: 'current_status',
      text: 'Status',
      sort: true,
      attrs: { title: 'Status' },
    },
    {
      text: 'Action',
      isDummyField: true,
      formatter: (cell, row, rowIndex) => (
        <StylishNewTableAction
          name="Report"
          cell={cell}
          row={row}
          rowIndex={rowIndex}
          onDelete={() => onDeleteInitialIncidentReport(row.id)}
          onEdit={() => onEdit(row)}
          onView={() => onView(row)}
        />
      ),
      headerStyle: {
        width: '100px',
      },
      headerAlign: 'center',
      align: 'center',
      attrs: { title: 'Action' },
    },
  ];

  const [categoryName, setCategoryName] = useState();
  const [itemName, setItemName] = useState();
  const [showRecipientListModal, setShowRecipientListModal] = useState(false);

  const onManageRecipientList = () => {
    setShowRecipientListModal(true);
  };
  const onCloseManageRecipientList = () => {
    setShowRecipientListModal(false);
  };

  const expandRow = {
    parentClassName: 'parent-expand-tr',
    className: 'child-expand-tr',
    renderer: (row) => {
      let itemName, categoryName;

      if (!!reduxNestedCategories && !!reduxNestedCategoryItems) {
        const item = reduxNestedCategoryItems.find(
          (catItem) => catItem.id === row.location_id
        );

        const category = reduxNestedCategories.find(
          (listedCat) => listedCat.id === item?.category_id
        );

        categoryName = category?.category_name;
        itemName = item?.category_item_name;
      }

      return (
        <>
          <div className="row">
            {categoryName && itemName && (
              <div className="col-md-4 mb-3">
                <h6 className="mb-0 apply-weight">{categoryName}</h6>
                {itemName}
              </div>
            )}
            <div className="col-md-4 mb-3">
              <label className="form-label d-block mb-0">
                Is this a QI (Qualified Individual) Regulatory Drill?
              </label>
              {row.is_qi ? 'Yes' : 'No'}
            </div>
            <div className="col-md-4 mb-3 mb-md-0">
              <label className="form-label d-block mb-0">
                Relevant Teams Identified
              </label>
              {row.teams_activated_list &&
                row.teams_activated_list.length > 0 &&
                row.teams_activated_list.join(', ')}
            </div>
            <div className="col-md-12">
              <label className="form-label d-block mb-0">Brief Synopsis</label>
              <p className="m-0">{row.incident_description}</p>
            </div>
          </div>
          <div className="button-group mt-4">
            <button
              type="button"
              className="button--icon primary"
              onClick={() => onView(row)}
            >
              <SharedIcon iconName="visibility" />
            </button>
            <button
              type="button"
              className="button--icon primary ms-2"
              onClick={() => onEdit(row)}
            >
              <SharedIcon iconName="stylus" classes="dropdown-icon" />
            </button>
            <button
              type="button"
              className="button--icon primary ms-2"
              onClick={() => onDeleteInitialIncidentReport(row.id)}
            >
              <SharedIcon iconName="delete" classes="dropdown-icon" />
            </button>
          </div>
        </>
      );
    },
    showExpandColumn: true,
    expandByColumnOnly: true,
    expandHeaderColumnRenderer: ({ isAnyExpands }) => {
      if (isAnyExpands) {
        return <i className="fa fa-chevron-down" aria-hidden="true"></i>;
      }
      return <i className="fa fa-chevron-right" aria-hidden="true"></i>;
    },
    expandColumnRenderer: ({ expanded }) => {
      if (expanded) {
        return (
          <>
            <div className="d-flex align-items-center justify-content-md-center">
              <span className="me-2">Details</span>
              <i className="fa fa-chevron-down" aria-hidden="true"></i>
            </div>
          </>
        );
      }
      return (
        <>
          <div className="d-flex align-items-center justify-content-md-center">
            <span className="me-2">Details</span>
            <i className="fa fa-chevron-right" aria-hidden="true"></i>
          </div>
        </>
      );
    },
  };

  return (
    <>
      {newUI && <PageTitle title="Report List" />}
      <div className="d-flex align-items-center justify-content-between">
        <div className="button-group">
          <StylishNewButton
            className="button--primary mb-4 w-100 w-md-auto"
            onClick={onCreate}
          >
            Create Incident Report
          </StylishNewButton>
        </div>
        <div className="button-group">
          <StylishNewButton
            className="button--secondary-fill ml-4 mb-4 w-100 w-md-auto"
            onClick={onManageChecklist}
          >
            Manage Checklists
          </StylishNewButton>
          <StylishNewButton
            className="button--secondary-fill ml-4 mb-4 w-100 w-md-auto"
            onClick={onManageRecipientList}
          >
            Manage Recipient List
          </StylishNewButton>
          <StylishNewButton
            className="button--secondary-fill ml-4 mb-4 w-100 w-md-auto"
            onClick={onManageCustomFields}
          >
            Manage Custom Fields
          </StylishNewButton>
        </div>
      </div>

      <div className="form-block">
        <StylishNewTable
          keyField={'id'}
          columns={columns_report_data}
          rows={allInitialIncidentReport}
          expandRow={expandRow}
        />
      </div>
      {showRecipientListModal && (
        <RecipientListModal
          show={showRecipientListModal}
          onClose={onCloseManageRecipientList}
        />
      )}
      {!!showConfirmDialog && (
        <StylishConfirmDialog
          show={!!showConfirmDialog}
          dialogTitle={'Delete Report'}
          dialogContent={
            'Are you sure you want to delete this initial incident report?'
          }
          onClose={onCloseConfirmDialog}
          onConfirm={() => {
            reduxDispatch(deleteInitialIncidentReport(showConfirmDialog));
            onCloseConfirmDialog();
          }}
        />
      )}
      {showCustomFieldsModal && (
        <ManageCustomFieldsModal
          show={showCustomFieldsModal}
          onClose={onCloseManageCustomFieldsModal}
        />
      )}
      {showChecklistModal && (
        <ManageChecklistModal 
          show={showChecklistModal}
          onClose={onCloseChecklistModal} />
      )}
    </>
  );
}
