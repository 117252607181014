import React from 'react';
import Network from '../../../../service/Network';
import API from '../../../../service/api';
import Plot from 'react-plotly.js';
import { useSelector, useDispatch } from 'react-redux';
import { useState, useEffect } from 'react';
import moment from 'moment/moment';
import { useParams } from 'react-router-dom';
import { AddEditCustomReportModal } from '../Modal/AddEditCustomReportModal';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  plotBgColor,
  plotGreen,
  plotBlue,
  plotOrange,
  plotYellow,
  plotBrick,
  toastConfig,
} from 'assets/data/config';
import { paperBgColor } from 'assets/data/config';
import { useForm, Controller } from 'react-hook-form';
import IconRefresh from '../../../../../assets/images/icon__refresh.svg';
import html2canvas from 'html2canvas';
import { saveAs } from 'file-saver';
import StylishNewSelect from 'components/DesignSystems/New/StylishNewSelect';
import StylishDateTimePicker from 'components/DesignSystems/form/StylishDateTimePicker';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import {
  endLoading,
  startLoading,
} from '../../../../../reducers/loading/loading.action';
import { SharedIcon } from 'components/SharedIcon/SharedIcon';

export const Analytics = () => {
  const [
    dodEffectivenessTrainingObjectiveTotal,
    setDodEffectivenessTrainingObjectiveTotal,
  ] = useState(0);
  const [fSPerParticipantsTotal, setFSPerParticipantsTotal] = useState(0);
  const [participantsDataTotal, setParticipantsDataTotal] = useState(0);
  const [trainDataTotal, setTrainDataTotal] = useState(0);
  const [excerciseDataTotal, setExcerciseDataTotal] = useState(0);
  const [
    excerciseParticipantsDataTotal,
    setExcerciseParticipantsDataTotal,
  ] = useState(0);
  const [participantsPerTotal, setParticipantsPerTotal] = useState(0);
  const [dialogType, setDialogType] = useState('Add');
  const [showAddEditModal, setShowAddEditModal] = useState(false);
  const [
    excerciseObjectiveCompletioin,
    setExcerciseObjectiveCompletioin,
  ] = useState({});
  const [hseepPlannedVsAssessed, setHseepPlannedVsAssessed] = useState({});
  const [
    hseepPlannedVsAssessedTotal,
    setHseepPlannedVsAssessedTotal,
  ] = useState(0);
  const [dodPlannedVsAssessed, setDodPlannedVsAssessed] = useState({});
  const [dodPlannedVsAssessedTotal, setDodPlannedVsAssessedTotal] = useState(0);
  const [dodRequestedVsPlan, setDodRequestedVsPlan] = useState({});
  const [dodRequestedVsPlanTotal, setDodRequestedVsPlanTotal] = useState(0);
  const [hseepRequestedVsPlan, setHseepRequestedVsPlan] = useState({});
  const [trainObjectiveCompletioin, setTrainObjectiveCompletioin] = useState(
    {}
  );
  const [participantTitle, setparticipantTitle] = useState([]);
  const [participantTotalCount, setParticipantTotalCount] = useState([]);
  const [trainParticipantTotalFalse, setTrainParticipantTotalFalse] = useState([
    null,
  ]);
  const [trainParticipantTotalTrue, setTrainParticipantTotalTrue] = useState([
    null,
  ]);
  const [trainParticipantTitle, setTrainParticipantTitle] = useState([]);
  const [trainParticipantDataSum, setTrainParticipantDataSum] = useState([]);
  const [
    excerciseParticipantDataTitle,
    setExcerciseParticipantDataTitle,
  ] = useState([]);
  const [excerciseDataSum, setExcerciseDataSum] = useState([]);
  const [
    excerciseParticipantDataTotalTrue,
    setExcerciseParticipantDataTotalTrue,
  ] = useState([null]);
  const [
    exerciseParticipantDataTotalFalse,
    setExerciseParticipantDataTotalFalse,
  ] = useState([null]);
  const [
    dODEffectivenessTrainingObjectives,
    setdODEffectivenessTrainingObjectives,
  ] = useState({});
  const [task, setTask] = useState({ value: 'all', label: 'All' });
  const [excerciseDataTitle, setExcerciseDataTitle] = useState([]);
  const [excerciseDataTotalTrue, setExcerciseDataTotalTrue] = useState([null]);
  const [exerciseDataTotalFalse, setExerciseDataTotalFalse] = useState([null]);
  const [allocatedFund, setAllocatedFund] = useState({});
  const [taskParticipantTotal, setTaskParticipantTotal] = useState(0);
  const [showDateError, setShowDateError] = useState('');
  const [apiCall, setApiCall] = useState(false);

  const [allocatedFundTotal, setAllocatedFundTotal] = useState(0);
  const [trainDataTotalFalse, setTrainDataTotalFalse] = useState([null]);
  const [trainDataTotalTrue, setTrainDataTotalTrue] = useState([null]);
  const [trainDataTitle, setTrainDataTitle] = useState([]);
  const [trainDataSum, setTrainDataSum] = useState([]);
  const [dodReasonsDeptTitle, setDodReasonsDeptTitle] = useState([]);
  const [dodReasonsTotalAudience, setDodReasonsTotalAudience] = useState([]);
  const [allParticipants, setAllParticipants] = useState([]);
  const [participantFundAmount, setParticipantFundAmount] = useState([]);
  const [participantNamme, setParticipantName] = useState([]);
  const [hseepReasonsCount, setHseepReasonsCount] = useState([]);
  const [hseepReasonsTitle, setHseepReasonsTitle] = useState([]);
  const [hseepReasonsTotal, setHseepReasonsTotal] = useState([]);
  const [dodReasonsCount, setDodReasonsCount] = useState([]);
  const [dodReasonsTitle, setDodReasonsTitle] = useState([]);
  const [dodReasonsTotal, setDodReasonsTotal] = useState([]);
  const [hseepEffectivenessStatus, setHseepEffectivenessStatus] = useState([]);
  const [hseepEffectivenessCount, setHseepEffectivenessCount] = useState([]);
  const [totalHseepEffectiveness, settotalHseepEffectiveness] = useState(0);
  const [
    totalExcerciseObjectiveCompletioin,
    setTotalExcerciseObjectiveCompletioin,
  ] = useState(0);
  const [startDateKey, setStartDateKey] = useState(false);
  const [endDateKey, setEndDateKey] = useState(false);
  const [hseepRequestedVsPlanTotal, setHseepRequestedVsPlanTotal] = useState(0);
  const [
    totalTrainObjectiveCompletioin,
    setTotalTrainObjectiveCompletioin,
  ] = useState(0);
  const [taskparticipantTitle, setTaskparticipantTitle] = useState([]);
  const [taskParticipantTotalCount, setTaskParticipantTotalCount] = useState(
    []
  );

  const [fundingData, setFundingData] = useState(false);
  const [organisationTypeData, setOrganisationTypeData] = useState(false);
  const [startDate, setStartDate] = useState();
  const [titleOfReport, setTitleOfReport] = useState();
  const [endDate, setEndDate] = useState();
  const [allFunding, setAllFundings] = useState([]);
  const [audienceTotal, setAudienceTotal] = useState(0);
  const [
    hseepExcerciseCompletionAllignedSum,
    setHseepExcerciseCompletionAllignedSum,
  ] = useState([]);

  const { control, setValue } = useForm();

  const [funds, setFunds] = useState({});
  const [option, setOption] = useState({
    value: 'assessment',
    label: 'Assessment',
  });

  const [typeOption, setTypeOption] = useState({
    value: 'DOD',
    label: 'DOD',
  });

  const [filterAudience, setFilterAudience] = useState({
    value: 'department',
    label: 'Department',
  });
  const [filterParticipants, setFilterParticipants] = useState({
    value: 'department',
    label: 'Department',
  });

  const [filterTrainParticipants, setFilterTrainParticipants] = useState({
    value: 'department',
    label: 'Department',
  });
  const [filterTrainData, setFilterTrainData] = useState({
    value: 'command_priority',
    label: 'Command Priority',
  });
  const [
    filterHSEEPExcerciseObjectiveinData,
    setFilterHSEEPExcerciseObjective,
  ] = useState({
    value: 'core_capability',
    label: 'Core Capability',
  });
  const [
    filterExcerciseObjectiveData,
    setFilterExcerciseObjectiveData,
  ] = useState({
    value: 'department',
    label: 'Department',
  });

  const { selectedWorkspace } = useSelector((state) => state.prepare.workspace);
  const exerciseId = useSelector(
    (state) => state.prepare.exercise.exerciseDetailsId
  );

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const params = useParams();
  const reduxUser = useSelector((state) => state.prepare.user);

  const dropDownOptions = [
    { value: 'assessment', label: 'Assessment' },
    { value: 'feedback', label: 'Feedback' },
  ];
  const typeDropDownOptions = [
    { value: 'DOD', label: 'DOD' },
    { value: 'HSEEP', label: 'HSEEP' },
  ];
  const dropDownExcerciseObjecvtive = [
    {
      value: 'organizational_capability_target ',
      label: 'Organizational Capability Target',
    },
    { value: 'core_capability', label: 'Core Capability' },
    { value: 'strategic_priority', label: 'Strategic Priority' },
  ];

  const dropDownFilters = [
    { value: 'department', label: 'Department' },
    { value: 'plandepartmenttype', label: 'Plan Department Type' },
    { value: 'participanttype', label: 'Participant Type' },
    { value: 'participantcommandagency', label: 'Participant Command Agency' },
  ];

  const dropDownTrainFilters = [
    { value: 'command_priority', label: 'Command Priority' },
    { value: 'accredied_task', label: 'Accredied Task' },
    {
      value: 'joint_staff_training_priority',
      label: 'Joint Staff Training Priority',
    },
    {
      value: 'exercise_objective',
      label: 'Exercise Objective',
    },
  ];
  const getOrganizationType = async () => {
    dispatch(startLoading());
    try {
      const organizationType = await Network.get(API.getOrganizationType, {
        workspaceId: selectedWorkspace,
      });
      if (organizationType !== undefined && organizationType.status === 200) {
        setOrganisationTypeData(true);
      }

      setAllParticipants([
        { value: 'all', label: 'all' },
        ...organizationType.data.response.dataset.map((e) => {
          return {
            value: e.id,
            label: e.title,
          };
        }),
      ]);
    } catch (error) {
      toast.error(error.response.data.response.status.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };

  const getAllFundingSources = async () => {
    dispatch(startLoading());
    try {
      const funding = await Network.get(API.fetchAllFundingSources, {
        workspaceId: selectedWorkspace,
        planEventId: exerciseId,
      });

      if (funding !== undefined && funding.status === 200) {
        setFundingData(true);
      }
      setAllFundings([
        ...funding.data.response.dataset.map((e) => {
          return {
            value: e.id,
            label: e.subsource,
          };
        }),
      ]);

      setFunds({
        value: funding?.data?.response?.dataset[0]?.id,
        label: funding?.data?.response?.dataset[0]?.subsource,
      });
    } catch (error) {
      toast.error(error.response.data.response.status.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };

  const getSingleCustomReport = async () => {
    dispatch(startLoading());
    try {
      const resp = await Network.get(API.getSingleCustomReport, {
        id: params.id,
      });
      resp?.data?.response?.dataset[0]?.endDate &&
        setEndDate(moment(resp?.data?.response?.dataset[0]?.endDate).toDate());

      resp?.data?.response?.dataset[0]?.endDate &&
        setValue(
          'endDate',
          moment(resp?.data?.response?.dataset[0]?.endDate).toDate()
        );

      resp?.data?.response?.dataset[0]?.startDate &&
        setStartDate(
          moment(resp?.data?.response?.dataset[0]?.startDate).toDate()
        );

      resp?.data?.response?.dataset[0]?.startDate &&
        setValue(
          'startDate',
          moment(resp?.data?.response?.dataset[0]?.startDate).toDate()
        );

      setTitleOfReport(resp?.data?.response?.dataset[0]?.title);
    } catch (error) {
      toast.error(error?.response?.data?.response?.status?.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };
  // useEffect(() => {
  //   startDate !== null &&
  //     endDate !== null &&
  //     endDate < startDate &&
  //     setEndDate(startDate);
  // }, [startDate, endDate]);

  useEffect(() => {
    !!params.id && getSingleCustomReport();
  }, [params.id, selectedWorkspace]);

  useEffect(() => {
    setFundingData(false);
    setOrganisationTypeData(false);
  }, [selectedWorkspace]);

  useEffect(() => {
    if (exerciseId != undefined) {
      getAllFundingSources();
      getOrganizationType();
    }
  }, [exerciseId]);

  const getReportsAnalytics = (signal) => {
    dispatch(startLoading());

    try {
      Network.http
        .get(API.getReportsAnalytics, {
          params: {
            workspaceId: selectedWorkspace,
            filter: option.value,
            startDate:
              !!startDate && startDate !== ''
                ? moment(startDate).toISOString()
                : '',
            endDate:
              !!endDate && endDate !== '' ? moment(endDate).toISOString() : '',
            exerciseParticipantDataFilter: filterExcerciseObjectiveData.value,
            exerciseDataFilter: filterHSEEPExcerciseObjectiveinData.value,
            fundingSourcePerParticipantFilter: funds.value,
            audiencePerParticipantFilter: filterAudience.value,
            taskPerParticipantFilter: task.value,
            participantsPerParticipantFilter: filterParticipants.value,
            trainParticipantDataFilter: filterTrainParticipants.value,
            trainDataFilter: filterTrainData.value,
            eventId: exerciseId,
          },
          signal: signal,
        })
        .then((resp) => {
          if (
            resp.data.response.fundingSourcePerParticipantData &&
            resp.data.response.fundingSourcePerParticipantData.length === 0
          ) {
            setParticipantName([]);
            setParticipantFundAmount([]);
            setFSPerParticipantsTotal(0);
          }
          if (
            resp.data.response.fundingSourcePerParticipantData &&
            resp.data.response.fundingSourcePerParticipantData.length > 0
          ) {
            const tempParticipantNameArr = [];
            const tempParticipantFundAmoountArr = [];

            resp.data.response.fundingSourcePerParticipantData.map((e) => {
              tempParticipantNameArr.push(e.participantname);
              tempParticipantFundAmoountArr.push(e.participant_fund_amount);
            });
            setParticipantName(tempParticipantNameArr);
            setParticipantFundAmount(tempParticipantFundAmoountArr);
            setFSPerParticipantsTotal(
              resp.data.response.fundingSourcePerParticipantData[0].total_amount
            );
          }

          if (
            resp.data.response.allocatedFundingSourceData &&
            resp.data.response.allocatedFundingSourceData.length === 0
          ) {
            setAllocatedFund({});
            setAllocatedFundTotal(0);
          }
          if (
            resp.data.response.allocatedFundingSourceData &&
            resp.data.response.allocatedFundingSourceData.length > 0
          ) {
            setAllocatedFund({
              allocated: Number(
                resp.data.response.allocatedFundingSourceData[0].total_allocated
              ),
              non_allocated:
                Number(
                  resp.data.response.allocatedFundingSourceData[0].total_budget
                ) -
                Number(
                  resp.data.response.allocatedFundingSourceData[0]
                    .total_allocated
                ),
            });

            setAllocatedFundTotal(
              Number(
                resp.data.response.allocatedFundingSourceData[0].total_budget
              )
            );
          }
          if (
            resp.data.response.reasonDodData &&
            resp.data.response.reasonDodData.length === 0
          ) {
            setDodReasonsTitle([]);
            setDodReasonsCount([]);
            setDodReasonsTotal(0);
          }

          if (
            resp.data.response.reasonDodData &&
            resp.data.response.reasonDodData.length > 0
          ) {
            const tempDodReasonsTitleArr = [];
            const tempDodReasonsCountArr = [];

            resp.data.response.reasonDodData.map((e) => {
              if (e.title === null) {
                tempDodReasonsTitleArr.push('No Reason');
              } else {
                tempDodReasonsTitleArr.push(e.title);
              }
              tempDodReasonsCountArr.push(e.count);
            });
            setDodReasonsTitle(tempDodReasonsTitleArr);
            setDodReasonsCount(tempDodReasonsCountArr);
            setDodReasonsTotal(resp.data.response.reasonDodDataTotal);
          }
          if (
            resp.data.response.reasonHseepData &&
            resp.data.response.reasonHseepData.length === 0
          ) {
            setHseepReasonsTitle([]);
            setHseepReasonsCount([]);
            setHseepReasonsTotal(0);
          }

          if (
            resp.data.response.reasonHseepData &&
            resp.data.response.reasonHseepData.length > 0
          ) {
            const tempHseepReasonsTitleArr = [];
            const tempHseepReasonsCountArr = [];
            resp.data.response.reasonHseepData.map((e) => {
              if (e.title === null) {
                tempHseepReasonsTitleArr.push('No Reason');
              } else {
                tempHseepReasonsTitleArr.push(e.title);
              }
              tempHseepReasonsCountArr.push(e.count);
            });
            setHseepReasonsTitle(tempHseepReasonsTitleArr);
            setHseepReasonsCount(tempHseepReasonsCountArr);
            setHseepReasonsTotal(resp.data.response.reasonHseepDataTotal);
          }
          if (
            resp.data.response.exerciseData &&
            resp.data.response.exerciseData.length === 0
          ) {
            setExcerciseDataTitle([]);
            setExcerciseDataTotalTrue([null]);

            setExerciseDataTotalFalse([null]);

            setExcerciseDataTotal(0);
          }

          if (
            resp.data.response.exerciseData &&
            resp.data.response.exerciseData.length > 0
          ) {
            const tempExcerciseDataTitleArr = [];
            const tempExcerciseDataTotalTrueArr = [];
            const tempExerciseDataTotalFalseArr = [];
            resp.data.response.exerciseData.map((e) => {
              tempExcerciseDataTitleArr.push(e.title);
              if (e.complete == true) {
                tempExcerciseDataTotalTrueArr.push(e.total);
              }
              if (e.complete == false) {
                tempExerciseDataTotalFalseArr.push(e.total);
              }
            });
            setExcerciseDataTitle(tempExcerciseDataTitleArr);
            if (tempExcerciseDataTotalTrueArr.length > 0) {
              setExcerciseDataTotalTrue(tempExcerciseDataTotalTrueArr);
            }
            if (tempExerciseDataTotalFalseArr.length > 0) {
              setExerciseDataTotalFalse(tempExerciseDataTotalFalseArr);
            }
            setExcerciseDataTotal(resp.data.response.exerciseDataTotal);
          }
          if (
            option.value == 'feedback' &&
            resp.data.response.taskPerParticipantData &&
            resp.data.response.taskPerParticipantData.length === 0
          ) {
            setTaskParticipantTotalCount([]);
            setTaskparticipantTitle([]);
            setTaskParticipantTotal(0);
          }

          if (
            option.value == 'feedback' &&
            resp.data.response.taskPerParticipantData &&
            resp.data.response.taskPerParticipantData.length > 0
          ) {
            const tempTaskparticipantTitleArr = [];
            const tempHseepExcerciseCompletionAllignedSumArr = [];
            resp.data.response.taskPerParticipantData.map((e) => {
              tempTaskparticipantTitleArr.push(e.missiontasktitle);
              tempHseepExcerciseCompletionAllignedSumArr.push(e.count);
            });
            setTaskParticipantTotalCount(
              tempHseepExcerciseCompletionAllignedSumArr
            );
            setTaskparticipantTitle(tempTaskparticipantTitleArr);
            setTaskParticipantTotal(
              resp.data.response.taskPerParticipantDataTotal
            );
          }
          if (
            resp.data.response.trainData &&
            resp.data.response.trainData.length === 0
          ) {
            setTrainDataTitle([]);
            setTrainDataTotalTrue([null]);

            setTrainDataTotalFalse([null]);

            setTrainDataTotal(0);
          }

          if (
            resp.data.response.trainData &&
            resp.data.response.trainData.length > 0
          ) {
            const tempTitle = [];
            const tempTrue = [];
            const tempFalse = [];
            resp.data.response.trainData.map((e) => {
              e.title &&
                tempTitle.indexOf(e.title) < 0 &&
                tempTitle.push(e.title);
              if (e.complete == true && e.title) {
                tempTrue.push(e.total);
              }
              if (e.complete == false && e.title) {
                tempFalse.push(e.total);
              }
            });
            setTrainDataTitle(tempTitle);
            if (tempTrue.length > 0) {
              setTrainDataTotalTrue(tempTrue);
            }
            if (tempFalse.length > 0) {
              setTrainDataTotalFalse(tempFalse);
            }
            setTrainDataTotal(resp.data.response.trainDataTotal);
          }

          if (
            resp.data.response.exerciseDataSum &&
            resp.data.response.exerciseDataSum.length === 0
          ) {
            setHseepExcerciseCompletionAllignedSum([]);
          }
          if (
            resp.data.response.exerciseDataSum &&
            resp.data.response.exerciseDataSum.length > 0
          ) {
            const tempHseepExcerciseCompletionAllignedSumArr = [];
            resp.data.response.exerciseDataSum.map((e) => {
              tempHseepExcerciseCompletionAllignedSumArr.push(e.total);
            });
            setHseepExcerciseCompletionAllignedSum(
              tempHseepExcerciseCompletionAllignedSumArr
            );
          }
          if (
            resp.data.response.exerciseParticipantData &&
            resp.data.response.exerciseParticipantData.length === 0
          ) {
            setExcerciseParticipantDataTitle([]);
            setExcerciseParticipantDataTotalTrue([null]);

            setExerciseParticipantDataTotalFalse([null]);

            setExcerciseDataSum([]);
            setExcerciseParticipantsDataTotal(0);
          }

          if (
            resp.data.response.exerciseParticipantData &&
            resp.data.response.exerciseParticipantData.length > 0
          ) {
            const tempExcerciseParticipantDataTitleArr = [];
            const tempExcerciseParticipantDataTotalTrueArr = [];
            const tempExcerciseParticipantDataTotalFalseArr = [];
            resp.data.response.exerciseParticipantData.map((e) => {
              tempExcerciseParticipantDataTitleArr.push(e.title);
              if (e.complete == true) {
                tempExcerciseParticipantDataTotalTrueArr.push(e.total);
              }
              if (e.complete == false) {
                tempExcerciseParticipantDataTotalFalseArr(e.total);
              }
            });
            setExcerciseParticipantDataTitle(
              tempExcerciseParticipantDataTitleArr
            );
            if (tempExcerciseParticipantDataTotalTrueArr.length > 0) {
              setExcerciseParticipantDataTotalTrue(
                tempExcerciseParticipantDataTotalTrueArr
              );
            }
            if (tempExcerciseParticipantDataTotalFalseArr.length > 0) {
              setExerciseParticipantDataTotalFalse(
                tempExcerciseParticipantDataTotalFalseArr
              );
            }

            const tempExcerciseDataSum = [];
            resp.data.response.exerciseParticipantDataSum.map((e) => {
              tempExcerciseDataSum.push(e.total);
            });
            setExcerciseDataSum(tempExcerciseDataSum);
            setExcerciseParticipantsDataTotal(
              resp.data.response.exerciseParticipantDataTotal
            );
          }
          if (
            resp.data.response.trainDataSum &&
            resp.data.response.trainDataSum.length === 0
          ) {
            setTrainDataSum([]);
          }

          if (
            resp.data.response.trainDataSum &&
            resp.data.response.trainDataSum.length > 0
          ) {
            const tempTrainDataSumArr = [];
            resp.data.response.trainDataSum.map((e) => {
              tempTrainDataSumArr.push(e.total);
            });
            setTrainDataSum(tempTrainDataSumArr);
          }
          if (
            resp.data.response.trainParticipantDataSum &&
            resp.data.response.trainParticipantDataSum.length === 0
          ) {
            setTrainParticipantDataSum([]);
          }

          if (
            resp.data.response.trainParticipantDataSum &&
            resp.data.response.trainParticipantDataSum.length > 0
          ) {
            const tempTrainParticipantDataSumArr = [];
            resp.data.response.trainParticipantDataSum.map((e) => {
              tempTrainParticipantDataSumArr.push(e.total);
            });
            setTrainParticipantDataSum(tempTrainParticipantDataSumArr);
          }

          if (
            resp.data.response.trainParticipantData &&
            resp.data.response.trainParticipantData.length === 0
          ) {
            setTrainParticipantTitle([]);
            setTrainParticipantTotalTrue([null]);

            setTrainParticipantTotalFalse([null]);

            setParticipantsDataTotal(0);
          }

          if (
            resp.data.response.trainParticipantData &&
            resp.data.response.trainParticipantData.length > 0
          ) {
            const tempTrainParticipantTitleArr = [];
            const tempTrainParticipantTotalTrueArr = [];
            const tempTrainParticipantTotalFalseArr = [];

            resp.data.response.trainParticipantData.map((e) => {
              e.title &&
                tempTrainParticipantTitleArr.indexOf(e.title) < 0 &&
                tempTrainParticipantTitleArr.push(e.title);

              if (e.complete == true && e.title) {
                tempTrainParticipantTotalTrueArr.push(e.total);
              }
              if (e.complete == false && e.title) {
                tempTrainParticipantTotalFalseArr.push(e.total);
              }
            });
            setTrainParticipantTitle(tempTrainParticipantTitleArr);
            if (tempTrainParticipantTotalTrueArr.length > 0) {
              setTrainParticipantTotalTrue(tempTrainParticipantTotalTrueArr);
            }
            if (tempTrainParticipantTotalFalseArr.length > 0) {
              setTrainParticipantTotalFalse(tempTrainParticipantTotalFalseArr);
            }
            setParticipantsDataTotal(
              resp.data.response.trainParticipantDataTotal
            );
          }
          if (
            resp.data.response.jmetDodData[0] &&
            resp.data.response.jmetDodData.length === 0
          ) {
            setdODEffectivenessTrainingObjectives({});

            setDodEffectivenessTrainingObjectiveTotal(0);
          }

          if (
            resp.data.response.jmetDodData[0] &&
            resp.data.response.jmetDodData.length > 0
          ) {
            setdODEffectivenessTrainingObjectives({
              partially_trainted: Number(
                resp.data.response.jmetDodData[0].partially_trainted
              ),
              trainted: Number(resp.data.response.jmetDodData[0].trainted),
              untrainted: Number(resp.data.response.jmetDodData[0].untrainted),
            });

            setDodEffectivenessTrainingObjectiveTotal(
              resp.data.response.jmetDodData[0].total
            );
          }

          if (
            resp.data.response.jmetHseepData &&
            resp.data.response.jmetHseepData.length === 0
          ) {
            setHseepEffectivenessStatus([]);
            setHseepEffectivenessCount([]);
            settotalHseepEffectiveness(0);
          }

          if (
            resp.data.response.jmetHseepData &&
            resp.data.response.jmetHseepData.length > 0
          ) {
            const temphseepEffectivenessStatusArr = [];
            const temphseepEffectivenessCountArr = [];

            resp.data.response.jmetHseepData.map((e) => {
              temphseepEffectivenessStatusArr.push(e.status);
              temphseepEffectivenessCountArr.push(e.count);
            });
            setHseepEffectivenessStatus(temphseepEffectivenessStatusArr);
            setHseepEffectivenessCount(temphseepEffectivenessCountArr);
            settotalHseepEffectiveness(resp.data.response.jmetHseepDataTotal);
          }

          if (
            resp.data.response.assessDodData &&
            resp.data.response.assessDodData.length === 0
          ) {
            setTrainObjectiveCompletioin({});
            setTotalTrainObjectiveCompletioin(0);
          }

          if (
            resp.data.response.assessDodData[0] &&
            resp.data.response.assessDodData.length > 0
          ) {
            setTrainObjectiveCompletioin({
              not_completed:
                Number(resp.data.response.assessDodData[0].complete) -
                Number(resp.data.response.assessDodData[0].completed_count),
              completed: Number(
                resp.data.response.assessDodData[0].completed_count
              ),
            });
            setTotalTrainObjectiveCompletioin(
              Number(resp.data.response.assessDodData[0].complete)
            );
          }

          if (
            resp.data.response.plannedAssessedHseepData &&
            resp.data.response.plannedAssessedHseepData.length === 0
          ) {
            setHseepPlannedVsAssessedTotal(0);
            setHseepPlannedVsAssessed({});
          }

          if (
            resp.data.response.plannedAssessedHseepData[0] &&
            resp.data.response.plannedAssessedHseepData.length > 0
          ) {
            setHseepPlannedVsAssessedTotal(
              Number(
                resp.data.response.plannedAssessedHseepData[0]
                  .total_training_objectives_planned
              )
            );
            setHseepPlannedVsAssessed({
              planned:
                Number(
                  resp.data.response.plannedAssessedHseepData[0]
                    .total_training_objectives_planned
                ) -
                Number(
                  resp.data.response.plannedAssessedHseepData[0]
                    .total_training_objectives_assessed
                ),
              assessed: Number(
                resp.data.response.plannedAssessedHseepData[0]
                  .total_training_objectives_assessed
              ),
            });
          }

          if (
            resp.data.response.plannedAssessedDodData &&
            resp.data.response.plannedAssessedDodData.length === 0
          ) {
            setDodPlannedVsAssessedTotal(0);
            setDodPlannedVsAssessed({});
          }

          if (
            resp.data.response.plannedAssessedDodData[0] &&
            resp.data.response.plannedAssessedDodData.length > 0
          ) {
            setDodPlannedVsAssessedTotal(
              Number(
                resp.data.response.plannedAssessedDodData[0]
                  .total_training_objectives_planned
              )
            );
            setDodPlannedVsAssessed({
              planned:
                Number(
                  resp.data.response.plannedAssessedDodData[0]
                    .total_training_objectives_planned
                ) -
                Number(
                  resp.data.response.plannedAssessedDodData[0]
                    .total_training_objectives_assessed
                ),
              assessed: Number(
                resp.data.response.plannedAssessedDodData[0]
                  .total_training_objectives_assessed
              ),
            });
          }
          if (
            resp.data.response.requestedPlannedDodData &&
            resp.data.response.requestedPlannedDodData.length === 0
          ) {
            setDodRequestedVsPlan({});
            setDodRequestedVsPlanTotal(0);
          }

          if (
            resp.data.response.requestedPlannedDodData[0] &&
            resp.data.response.requestedPlannedDodData.length > 0
          ) {
            setDodRequestedVsPlan({
              planned: Number(
                resp.data.response.requestedPlannedDodData[0]
                  .total_training_objectives_planned
              ),
              requested_planned: Number(
                resp.data.response.requestedPlannedDodData[0]
                  .total_training_objectives_requested
              ),
            });
            setDodRequestedVsPlanTotal(
              Number(
                resp.data.response.requestedPlannedDodData[0]
                  .total_training_objectives_planned
              ) +
                Number(
                  resp.data.response.requestedPlannedDodData[0]
                    .total_training_objectives_requested
                )
            );
          }

          if (
            resp.data.response.requestedPlannedHseepData &&
            resp.data.response.requestedPlannedHseepData.length === 0
          ) {
            setHseepRequestedVsPlan({});
            setHseepRequestedVsPlanTotal(0);
          }

          if (
            resp.data.response.requestedPlannedHseepData[0] &&
            resp.data.response.requestedPlannedHseepData.length > 0
          ) {
            setHseepRequestedVsPlan({
              planned: Number(
                resp.data.response.requestedPlannedHseepData[0]
                  .total_training_objectives_planned
              ),
              requested_planned: Number(
                resp.data.response.requestedPlannedHseepData[0]
                  .total_training_objectives_requested
              ),
            });
            setHseepRequestedVsPlanTotal(
              Number(
                resp.data.response.requestedPlannedHseepData[0]
                  .total_training_objectives_planned
              ) +
                Number(
                  resp.data.response.requestedPlannedHseepData[0]
                    .total_training_objectives_requested
                )
            );
          }
          if (
            resp.data.response.assessHseepData &&
            resp.data.response.assessHseepData.length === 0
          ) {
            setExcerciseObjectiveCompletioin({});
            setTotalExcerciseObjectiveCompletioin(0);
          }

          if (
            resp.data.response.assessHseepData[0] &&
            resp.data.response.assessHseepData.length > 0
          ) {
            setExcerciseObjectiveCompletioin({
              not_completed:
                Number(resp.data.response.assessHseepData[0].complete) -
                Number(resp.data.response.assessHseepData[0].completed_count),
              completed: Number(
                resp.data.response.assessHseepData[0].completed_count
              ),
            });
            setTotalExcerciseObjectiveCompletioin(
              Number(resp.data.response.assessHseepData[0].complete)
            );
          }
          if (
            resp.data.response.audienceParticipantData &&
            resp.data.response.audienceParticipantData.length === 0
          ) {
            setAudienceTotal(0);

            setDodReasonsDeptTitle([]);
            setDodReasonsTotalAudience([]);

            setParticipantsPerTotal(0);
          }

          if (
            resp.data.response.audienceParticipantData &&
            resp.data.response.audienceParticipantData.length > 0
          ) {
            const tempDodReasonsDeptTitleArr = [];
            const tempDodReasonsTotalAudienceArr = [];
            resp.data.response.audienceParticipantData.map((e) => {
              tempDodReasonsDeptTitleArr.push(e.title);
              tempDodReasonsTotalAudienceArr.push(e.totalaudience);
              setAudienceTotal(e.sum);
            });
            setDodReasonsDeptTitle(tempDodReasonsDeptTitleArr);
            setDodReasonsTotalAudience(tempDodReasonsTotalAudienceArr);

            setParticipantsPerTotal(
              resp.data.response.participantsPerParticipantReportTotal
            );
          }

          if (
            resp.data.response.participantsPerParticipantReportData &&
            resp.data.response.participantsPerParticipantReportData.length === 0
          ) {
            setparticipantTitle([]);
            setParticipantTotalCount([]);
          }

          if (
            resp.data.response.participantsPerParticipantReportData &&
            resp.data.response.participantsPerParticipantReportData.length > 0
          ) {
            const tempParticipantTitleArr = [];
            const tempParticipantTotalCount = [];

            resp.data.response.participantsPerParticipantReportData.map((e) => {
              tempParticipantTitleArr.push(e.title);
              tempParticipantTotalCount.push(e.count);
            });
            setparticipantTitle(tempParticipantTitleArr);
            setParticipantTotalCount(tempParticipantTotalCount);
          }
          setShowDateError('');
        })
        .catch(console.log)
        .finally(() => {
          dispatch(endLoading());
        });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    // option?.value &&
    //   task?.value &&
    //   funds?.value &&
    //   filterAudience?.value &&
    //   filterParticipants?.value &&
    //   filterTrainParticipants?.value &&
    //   filterTrainData?.value &&
    //   filterHSEEPExcerciseObjectiveinData?.value &&
    //   filterExcerciseObjectiveData?.value &&
    //   exerciseId &&
    //   ((startDate !== null && endDate !== null && endDate > startDate) ||
    //     endDate === startDate ||
    //     (startDate !== null && endDate == null) ||
    //     (endDate !== null && startDate == null)) &&

    const controller = new AbortController();
    const signal = controller.signal;

    if (exerciseId) {
      fundingData && organisationTypeData && getReportsAnalytics(signal);
    } else {
      getReportsAnalytics(signal);
    }

    // if (apiCall) {
    //   if (exerciseId) {
    //     fundingData && organisationTypeData && getReportsAnalytics();
    //   }
    //   if (!exerciseId) {
    //     getReportsAnalytics();
    //   }
    //   setApiCall(false);
    // }
    return () => controller.abort();
  }, [
    option,
    funds,
    task,
    filterAudience,
    filterParticipants,
    filterTrainParticipants,
    filterTrainData,
    filterHSEEPExcerciseObjectiveinData,
    filterExcerciseObjectiveData,
    exerciseId,
    startDate,
    endDate,
    selectedWorkspace,
    // apiCall,
  ]);

  const onAddReportCustom = () => {
    setDialogType('Add');
    setShowAddEditModal(true);
  };
  const onCloseModal = () => {
    setDialogType('New');
    setShowAddEditModal(false);
  };
  const exportAllGraph = async () => {
    const names = [
      'HSEEP Exercise Objective Requested Vs Planned',
      'HSEEP Exercise Objective Planned Vs Assessed',
      'DOD Training Objective Requested Vs Planned',
      'DOD Training Objective Planned Vs Assessed',
      'HSEEP Exercise Objective Completion',
      'HSEEP Effectiveness of Objective',
      'HSEEP Reasons for Partial or Ineffectiveness of Exercise Objectives',
      'DOD  Objective Completion',
      'DOD Effectiveness of  Objectives',
      'DOD Reasons for Partial or Ineffectiveness of  Objectives',
      `HSEEP Exercise Objective Completion Aligned to ${filterHSEEPExcerciseObjectiveinData.label}`,
      `HSEEP Exercise Objective Completion Aligned to ${filterHSEEPExcerciseObjectiveinData.label}-bar`,
      `DOD Train Objective Completion Aligned to ${filterTrainData.label}`,
      `DOD Train Objective Completion Aligned to ${filterTrainData.label}-bar`,
      `HSEEP Exercise Objective Completion Per ${filterExcerciseObjectiveData.label}`,
      `HSEEP Exercise Objective Completion Per ${filterExcerciseObjectiveData.label}-bar`,
      `DOD Train Objective Completion Per ${filterTrainParticipants.label}`,
      `DOD Train Objective Completion Per ${filterTrainParticipants.label}-bar`,
      `Training Audience per ${filterAudience.label}`,
      `Participants per ${filterParticipants.label}`,
      `Participants Funding Allocation ${funds.label}`,
      `Allocated Funding Source ${funds.label}`,
    ];

    if (option.value === 'feedback') {
      names.splice(20, 0, 'Task per');
    }

    if (option.value === 'assessment') {
      let index = names.indexOf('Task per');
      if (index !== -1) {
        names.splice(20, 1);
      }
    }

    dispatch(startLoading());
    try {
      const zip = require('jszip')();
      let allCharts = document.querySelectorAll('.svg-container');
      let index = 0;
      for (let file of allCharts) {
        const canvas = await html2canvas(file, { allowTaint: true });

        let imgurl = canvas
          .toDataURL('image/png')
          .replace('data:', '')
          .replace(/^.+,/, '');
        zip.file(`${names[index]}.png`, imgurl, { base64: true });
        index++;
      }
      try {
        const content = await zip.generateAsync({ type: 'blob' });
        saveAs(content, 'Plan Report.zip');
      } catch (error) {
        console.log(error);
      } finally {
        dispatch(endLoading());
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleResetButtonClick = () => {
    if (startDate != null || endDate != null) {
      setStartDate(null);
      setValue('startDate', null);
      setEndDate(null);
      setStartDateKey((k) => !k);
      setEndDateKey((k) => !k);
      setValue('endDate', null);
    }
  };

  return (
    <>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <StylishNewButton onClick={() => navigate(-1)}>
              <SharedIcon iconName="keyboard_arrow_left" classes="me-2" />
              Back
            </StylishNewButton>
          </li>
          {!!params.id ? (
            <li className="breadcrumb-item active" aria-current="page">
              {titleOfReport}
            </li>
          ) : null}
        </ol>
      </nav>
      {!!params.id ? <h4>{titleOfReport}</h4> : null}

      <div className="button-group mb-4 d-block d-md-flex">
        {!params.id && (
          <StylishNewButton
            className="button button--primary w-100 w-md-auto"
            onClick={onAddReportCustom}
          >
            Add Custom Analytics
          </StylishNewButton>
        )}
        <StylishNewButton
          className={`button button--primary w-100 w-md-auto mt-3 mt-md-0 ${
            !params.id ? 'ms-0 ms-md-3' : ''
          }`}
          onClick={exportAllGraph}
        >
          Export All Graphs
        </StylishNewButton>
      </div>
      <div className="row d-lg-flex align-items-end mb-4">
        {!!params.id ? (
          <>
            <div className="col-xl-8 d-md-flex align-items-center mb-3 mb-xl-0">
              <div className="flex-grow-1">
                <label className="form-label">Start Date &amp; Time</label>

                <Controller
                  control={control}
                  name="startDate"
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <StylishDateTimePicker
                      disabled={true}
                      key={startDateKey}
                      onChange={(date) => {
                        setStartDate(date || null);
                        if (date && moment(date).isAfter(moment(endDate))) {
                          setValue('endDate', date);
                          setEndDate(date);
                        }
                        if (date && !endDate) {
                          setValue('endDate', date);
                          setEndDate(date);
                        }
                        return onChange(date);
                      }}
                      selected={value}
                      selectsStart
                      value={value}
                      endDate={endDate}
                      isClearable={true}
                      timeFormat="HH:mm"
                      timeIntervals={20}
                      timeCaption="time"
                      dateFormat={
                        reduxUser?.timeFormat === 'hour12Mode'
                          ? 'MMMM d, yyyy hh:mm a'
                          : 'MMMM d, yyyy HH:mm'
                      }
                      placeholderText={'Choose Start Date'}
                      disableDateTimeLabel={true}
                      type="datetime-local"
                    />
                  )}
                />
              </div>
              <div className="flex-grow-1 ms-md-4 ps-md-2 ms-md-3 mt-3 mt-md-0">
                <label className="form-label">End Date &amp; Time</label>

                <Controller
                  control={control}
                  name="endDate"
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <StylishDateTimePicker
                      disabled={true}
                      key={endDateKey}
                      onChange={(date) => {
                        return onChange(date);
                      }}
                      value={value}
                      selected={value || endDate}
                      selectsEnd
                      minDate={
                        !!startDate
                          ? new Date(moment(startDate).toISOString())
                          : null
                      }
                      isClearable={true}
                      timeFormat="HH:mm"
                      timeIntervals={20}
                      timeCaption="time"
                      dateFormat={
                        reduxUser?.timeFormat === 'hour12Mode'
                          ? 'MMMM d, yyyy hh:mm a'
                          : 'MMMM d, yyyy HH:mm'
                      }
                      placeholderText={'Choose End Date'}
                      type="datetime-local"
                      disableDateTimeLabel={true}
                    />
                  )}
                />
              </div>
              {/* <StylishNewButton
                customButton
                type="button"
                className="button--icon primary lrg rounded ms-xl-4 mt-3 mt-xl-0 flex-shrink-0"
                onClick={handleResetButtonClick}
              >
                <img src={IconRefresh} alt="" />
              </StylishNewButton> */}
            </div>
          </>
        ) : (
          <>
            <div className="col-xl-8 d-md-flex align-items-end mb-3 mb-xl-0">
              <div className="flex-grow-1">
                <label className="form-label">Start Date &amp; Time</label>

                <Controller
                  control={control}
                  name="startDate"
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <StylishDateTimePicker
                      key={startDateKey}
                      onChange={(date) => {
                        setStartDate(date || null);
                        if (date && moment(date).isAfter(moment(endDate))) {
                          setValue('endDate', date);
                          setEndDate(date);
                        }
                        if (date && !endDate) {
                          setValue('endDate', date);
                          setEndDate(date);
                        }
                        return onChange(date);
                      }}
                      selected={value}
                      selectsStart
                      value={value}
                      endDate={endDate}
                      isClearable={true}
                      timeFormat="HH:mm"
                      timeIntervals={20}
                      timeCaption="time"
                      dateFormat={
                        reduxUser?.timeFormat === 'hour12Mode'
                          ? 'MMMM d, yyyy hh:mm a'
                          : 'MMMM d, yyyy HH:mm'
                      }
                      placeholderText={'Choose Start Date'}
                      disableDateTimeLabel={true}
                      type="datetime-local"
                    />
                  )}
                />
              </div>
              <div className="flex-grow-1 ms-md-4 ps-md-2 ms-md-3 mt-3 mt-md-0">
                <label className="form-label">End Date &amp; Time</label>

                <Controller
                  control={control}
                  name="endDate"
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <StylishDateTimePicker
                      key={endDateKey}
                      onChange={(date) => {
                        if (!date) {
                          setEndDate(null);
                        } else {
                          setEndDate(date);
                        }

                        return onChange(date);
                      }}
                      value={value}
                      selected={value || endDate}
                      selectsEnd
                      minDate={
                        !!startDate
                          ? new Date(moment(startDate).toISOString())
                          : null
                      }
                      isClearable={true}
                      timeFormat="HH:mm"
                      timeIntervals={20}
                      timeCaption="time"
                      dateFormat={
                        reduxUser?.timeFormat === 'hour12Mode'
                          ? 'MMMM d, yyyy hh:mm a'
                          : 'MMMM d, yyyy HH:mm'
                      }
                      placeholderText={'Choose End Date'}
                      type="datetime-local"
                      disableDateTimeLabel={true}
                    />
                  )}
                />
              </div>
              <StylishNewButton
                customButton
                type="button"
                className="button--icon primary lrg rounded ms-md-4 mt-3 mt-xl-0 flex-shrink-0"
                onClick={handleResetButtonClick}
              >
                <img src={IconRefresh} alt="" />
              </StylishNewButton>
            </div>
          </>
        )}

        <div className="col-md-6 col-xl-2 d-md-flex">
          <div className="w-100">
            <StylishNewSelect
              options={typeDropDownOptions}
              value={typeOption}
              isClearable={false}
              isSearchable={true}
              isMulti={false}
              isDisabled={false}
              onChange={(e) => {
                setTypeOption(e);
              }}
            />
          </div>
        </div>

        <div className="col-md-6 col-xl-2 d-md-flex mt-3 mt-md-0">
          <div className="w-100">
            <StylishNewSelect
              options={dropDownOptions}
              value={option}
              isClearable={false}
              isSearchable={true}
              isMulti={false}
              isDisabled={false}
              onChange={(e) => {
                setOption(e);
              }}
            />
          </div>
        </div>
        {showDateError}
      </div>
      <div className="row">
        {typeOption.value === 'HSEEP' && (
          <div className="col-xl-6 mb-3 mb-xl-4">
            <div className="p-3 p-md-4 bg-gray-800 h-100">
              <h4 className="mb-4 chartTitle">
                HSEEP Exercise Objective Requested Vs Planned
              </h4>
              <Plot
                data={[
                  {
                    values:
                      !!hseepRequestedVsPlan &&
                      Object.values(hseepRequestedVsPlan),
                    labels: ['Planned', 'Requested Planning'],
                    type: 'pie',
                    marker: {
                      colors: [plotOrange, plotGreen],
                    },
                    rotation: 90,
                    text:
                      !!hseepRequestedVsPlan &&
                      Object.values(hseepRequestedVsPlan).map(String),
                  },
                ]}
                layout={{
                  autosize: true,
                  title: `HSEEP  Exercise Objective <br>Requested Vs Planned<br>Total Requested : ${hseepRequestedVsPlanTotal}`,
                  paper_bgcolor: paperBgColor,
                  plot_bgcolor: plotBgColor,
                  showlegend: true,
                  plotly_legenddoubleclick: false,

                  // plotly_legendclick', () => false,
                  // dict(
                  //     legend=dict(
                  //         uirevision=False
                  //     )
                  // ),
                  font: {
                    color: 'white',
                  },
                }}
                onLegendClick={() => false}
                useResizeHandler
                className="w-100"
                config={{
                  displayModeBar: true,
                  modeBarButtons: [['toImage']],
                  displaylogo: false,
                  responsive: true,
                }}
              />
            </div>
          </div>
        )}
        {typeOption.value === 'HSEEP' && (
          <div className="col-xl-6 mb-3 mb-xl-4">
            <div className="p-3 p-md-4 bg-gray-800 h-100">
              <h4 className="mb-4 chartTitle">
                HSEEP Exercise Objective Planned Vs Assessed
              </h4>

              <Plot
                data={[
                  {
                    values:
                      !!hseepPlannedVsAssessed &&
                      Object.values(hseepPlannedVsAssessed),
                    labels: ['Planned Remaing', 'Assessed'],
                    type: 'pie',
                    rotation: 90,

                    marker: {
                      colors: [plotGreen, plotBlue],
                    },

                    text:
                      !!hseepPlannedVsAssessed &&
                      Object.values(hseepPlannedVsAssessed).map(String),
                  },
                ]}
                layout={{
                  autosize: true,
                  title: `HSEEP  Exercise Objective<br>  Planned Vs Assessed<br>Total Planned : ${hseepPlannedVsAssessedTotal}`,
                  paper_bgcolor: paperBgColor,
                  plot_bgcolor: plotBgColor,
                  font: {
                    color: 'white',
                  },
                }}
                onLegendClick={() => false}
                useResizeHandler
                className="w-100"
                config={{
                  displayModeBar: true,
                  modeBarButtons: [['toImage']],
                  displaylogo: false,
                }}
              />
            </div>
          </div>
        )}
        {typeOption.value === 'DOD' && (
          <div className="col-xl-6 mb-3 mb-xl-4">
            <div className="p-3 p-md-4 bg-gray-800 h-100">
              <h4 className="mb-4 chartTitle">
                DOD Training Objective Requested Vs Planned
              </h4>

              <Plot
                data={[
                  {
                    values:
                      !!dodRequestedVsPlan && Object.values(dodRequestedVsPlan),
                    labels: ['Planned', 'Requested Planning'],
                    type: 'pie',
                    rotation: 90,

                    marker: {
                      colors: [plotOrange, plotGreen],
                    },

                    text:
                      !!dodRequestedVsPlan &&
                      Object.values(dodRequestedVsPlan).map(String),
                  },
                ]}
                layout={{
                  autosize: true,
                  title: `DOD Training Objective<br> Requested Vs Planned <br>Total Requested : ${dodRequestedVsPlanTotal}`,
                  paper_bgcolor: paperBgColor,
                  plot_bgcolor: plotBgColor,
                  font: {
                    color: 'white',
                  },
                }}
                onLegendClick={() => false}
                useResizeHandler
                className="w-100"
                config={{
                  displayModeBar: true,
                  modeBarButtons: [['toImage']],
                  displaylogo: false,
                }}
              />
            </div>
          </div>
        )}
        {typeOption.value === 'DOD' && (
          <div className="col-xl-6 mb-3 mb-xl-4">
            <div className="p-3 p-md-4 bg-gray-800 h-100">
              <h4 className="mb-4 chartTitle">
                DOD Training Objective Planned Vs Assessed
              </h4>

              <Plot
                data={[
                  {
                    values:
                      !!dodPlannedVsAssessed &&
                      Object.values(dodPlannedVsAssessed),
                    labels: ['Plan Remaining', 'Assessed'],
                    type: 'pie',
                    rotation: 90,

                    marker: {
                      colors: [plotGreen, plotBlue],
                    },

                    text:
                      !!dodPlannedVsAssessed &&
                      Object.values(dodPlannedVsAssessed).map(String),
                  },
                ]}
                layout={{
                  autosize: true,
                  title: `DOD Training Objective<br> Planned Vs Assessed <br>Total Planned : ${dodPlannedVsAssessedTotal}`,
                  paper_bgcolor: paperBgColor,
                  plot_bgcolor: plotBgColor,

                  font: {
                    color: 'white',
                  },
                }}
                onLegendClick={() => false}
                useResizeHandler
                className="w-100"
                config={{
                  displayModeBar: true,
                  modeBarButtons: [['toImage']],
                  displaylogo: false,
                }}
              />
            </div>
          </div>
        )}
        {typeOption.value === 'HSEEP' && (
          <div className="col-xl-4 mb-3 mb-xl-4">
            <div className="p-3 p-md-4 bg-gray-800 h-100">
              <h4 className="mb-4 chartTitle">
                HSEEP Exercise Objective Completion
              </h4>

              <Plot
                data={[
                  {
                    values:
                      !!excerciseObjectiveCompletioin &&
                      Object.values(excerciseObjectiveCompletioin),
                    labels: ['Incomplete', 'Complete'],
                    type: 'pie',
                    rotation: 90,

                    marker: {
                      colors: [plotBrick, plotGreen],
                    },

                    text:
                      !!excerciseObjectiveCompletioin &&
                      Object.values(excerciseObjectiveCompletioin).map(String),
                  },
                ]}
                layout={{
                  autosize: true,
                  title: `HSEEP Exercise Objective Completion<br>Total: ${totalExcerciseObjectiveCompletioin}`,
                  paper_bgcolor: paperBgColor,
                  plot_bgcolor: plotBgColor,
                  font: {
                    color: 'white',
                  },
                }}
                onLegendClick={() => false}
                useResizeHandler
                className="w-100"
                config={{
                  displayModeBar: true,
                  modeBarButtons: [['toImage']],
                  displaylogo: false,
                }}
              />
            </div>
          </div>
        )}
        {typeOption.value === 'HSEEP' && (
          <div className="col-xl-4 mb-3 mb-xl-4">
            <div className="p-3 p-md-4 bg-gray-800 h-100">
              <h4 className="mb-4 chartTitle">
                HSEEP Effectiveness of Objective
              </h4>

              <Plot
                data={[
                  {
                    x: hseepEffectivenessCount,
                    y: hseepEffectivenessStatus,
                    type: 'bar',
                    orientation: 'h',
                    marker: {
                      color: [plotGreen, plotYellow, plotOrange, plotBrick],
                    },

                    text: hseepEffectivenessCount.map(String),
                  },
                ]}
                layout={{
                  autosize: true,
                  title: `HSEEP <br> Effectiveness of  Objective<br> Total:${totalHseepEffectiveness}`,
                  paper_bgcolor: paperBgColor,
                  plot_bgcolor: plotBgColor,
                  yaxis: {
                    automargin: true,
                  },

                  font: {
                    color: 'white',
                  },
                }}
                useResizeHandler
                className="w-100"
                config={{
                  displayModeBar: true,
                  modeBarButtons: [['toImage']],
                  displaylogo: false,
                }}
              />
            </div>
          </div>
        )}
        {typeOption.value === 'HSEEP' && (
          <div className="col-xl-4 mb-3 mb-xl-4">
            <div className="p-3 p-md-4 bg-gray-800 h-100">
              <h4 className="mb-4 chartTitle">
                HSEEP Reasons for Partial or Ineffectiveness of Exercise
                Objectives
              </h4>

              <Plot
                data={[
                  {
                    x: hseepReasonsCount,
                    y: hseepReasonsTitle,
                    type: 'bar',

                    orientation: 'h',
                    text: hseepReasonsCount.map(String),
                  },
                ]}
                layout={{
                  autosize: true,
                  title: `HSEEP Reasons for Partial <br>or Ineffectiveness of Exercise Objectives<br> Total:${hseepReasonsTotal}`,
                  paper_bgcolor: paperBgColor,
                  plot_bgcolor: plotBgColor,
                  yaxis: {
                    automargin: true,
                  },

                  font: {
                    color: 'white',
                  },
                }}
                useResizeHandler
                className="w-100"
                config={{
                  displayModeBar: true,
                  modeBarButtons: [['toImage']],
                  displaylogo: false,
                }}
              />
            </div>
          </div>
        )}
        {typeOption.value === 'DOD' && (
          <div className="col-xl-4 mb-3 mb-xl-4">
            <div className="p-3 p-md-4 bg-gray-800 h-100">
              <h4 className="mb-4 chartTitle">DOD Objective Completion</h4>

              <Plot
                data={[
                  {
                    values:
                      !!trainObjectiveCompletioin &&
                      Object.values(trainObjectiveCompletioin),
                    labels: ['Incomplete', 'Complete'],
                    type: 'pie',
                    rotation: 90,

                    marker: {
                      colors: [plotBrick, plotGreen],
                    },

                    text:
                      !!trainObjectiveCompletioin &&
                      Object.values(trainObjectiveCompletioin).map(String),
                  },
                ]}
                layout={{
                  autosize: true,
                  title: `DOD  Objective Completion<br>Total:${totalTrainObjectiveCompletioin}`,
                  paper_bgcolor: paperBgColor,
                  plot_bgcolor: plotBgColor,

                  font: {
                    color: 'white',
                  },
                }}
                onLegendClick={() => false}
                useResizeHandler
                className="w-100"
                config={{
                  displayModeBar: true,
                  modeBarButtons: [['toImage']],
                  displaylogo: false,
                }}
              />
            </div>
          </div>
        )}
        {typeOption.value === 'DOD' && (
          <div className="col-xl-4 mb-3 mb-xl-4">
            <div className="p-3 p-md-4 bg-gray-800 h-100">
              <h4 className="mb-4 chartTitle">
                DOD Effectiveness of Objectives
              </h4>

              <Plot
                data={[
                  {
                    x:
                      !!dODEffectivenessTrainingObjectives &&
                      Object.values(dODEffectivenessTrainingObjectives),

                    y: ['Partially Trained', 'Trained', 'Untrained'],
                    type: 'bar',
                    orientation: 'h',
                    marker: {
                      color: [plotGreen, plotYellow, plotOrange, plotBrick],
                    },

                    text:
                      !!dODEffectivenessTrainingObjectives &&
                      Object.values(dODEffectivenessTrainingObjectives).map(
                        String
                      ),
                  },
                ]}
                layout={{
                  autosize: true,
                  title: `DOD <br> Effectiveness of  Objectives<br> Total: ${dodEffectivenessTrainingObjectiveTotal}`,
                  paper_bgcolor: paperBgColor,
                  plot_bgcolor: plotBgColor,
                  yaxis: {
                    automargin: true,
                  },

                  font: {
                    color: 'white',
                  },
                }}
                useResizeHandler
                className="w-100"
                config={{
                  displayModeBar: true,
                  modeBarButtons: [['toImage']],
                  displaylogo: false,
                }}
              />
            </div>
          </div>
        )}
        {typeOption.value === 'DOD' && (
          <div className="col-xl-4 mb-3 mb-xl-4">
            <div className="p-3 p-md-4 bg-gray-800 h-100">
              <h4 className="mb-4 chartTitle">
                DOD Reasons for Partial or Ineffectiveness of Objectives
              </h4>

              <Plot
                data={[
                  {
                    x: dodReasonsCount,
                    y: dodReasonsTitle,
                    type: 'bar',

                    orientation: 'h',
                    text: dodReasonsCount.map(String),
                  },
                ]}
                layout={{
                  autosize: true,
                  title: `DOD Reasons for Partial <br>or Ineffectiveness of  Objectives<br> Total:${dodReasonsTotal}`,
                  paper_bgcolor: paperBgColor,
                  plot_bgcolor: plotBgColor,
                  yaxis: {
                    automargin: true,
                  },

                  font: {
                    color: 'white',
                  },
                }}
                useResizeHandler
                className="w-100"
                config={{
                  responsive: true,
                  displayModeBar: true,
                  modeBarButtons: [['toImage']],
                  displaylogo: false,
                }}
              />
            </div>
          </div>
        )}
        {typeOption.value === 'HSEEP' && (
          <div className="col-xl-12 mb-3 mb-xl-4">
            <div className="p-3 p-md-4 bg-gray-800 h-100">
              <div className="d-xl-flex align-items-center mb-3 mb-md-4">
                <h4 className="m-0 mb-2 chartTitle">
                  HSEEP Exercise Objective Completion Aligned to
                </h4>
                <div className="ms-auto">
                  <StylishNewSelect
                    style={{ minWidth: '300px' }}
                    options={dropDownExcerciseObjecvtive}
                    value={filterHSEEPExcerciseObjectiveinData}
                    isClearable={false}
                    isSearchable={true}
                    isMulti={false}
                    isDisabled={false}
                    onChange={(e) => {
                      setFilterHSEEPExcerciseObjective(e);
                    }}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-xl-6">
                  <div>
                    <Plot
                      data={[
                        {
                          y: excerciseDataTitle,
                          x: excerciseDataTotalTrue,
                          type: 'bar',
                          name: 'Complete',
                          marker: {
                            color: plotBrick,
                          },

                          orientation: 'h',
                          text: excerciseDataTotalTrue.map(String),
                        },
                        {
                          y: excerciseDataTitle,
                          x: exerciseDataTotalFalse,
                          type: 'bar',
                          name: 'Incomplete',
                          marker: {
                            color: plotGreen,
                          },

                          orientation: 'h',
                          text: exerciseDataTotalFalse.map(String),
                        },
                      ]}
                      layout={{
                        autosize: true,
                        title: `HSEEP Exercise Objective<br> Completion Aligned to ${filterHSEEPExcerciseObjectiveinData.label} <br> Total: ${excerciseDataTotal}`,
                        barmode: 'stack',
                        paper_bgcolor: paperBgColor,
                        plot_bgcolor: plotBgColor,
                        yaxis: {
                          automargin: true,
                        },

                        showlegend: true,

                        font: {
                          color: 'white',
                        },
                      }}
                      onLegendClick={() => false}
                      useResizeHandler
                      className="w-100"
                      config={{
                        displayModeBar: true,
                        modeBarButtons: [['toImage']],
                        displaylogo: false,
                      }}
                    />
                  </div>
                </div>
                <div className="col-xl-6">
                  <div>
                    <Plot
                      data={[
                        {
                          x: hseepExcerciseCompletionAllignedSum,
                          y: excerciseDataTitle,
                          type: 'bar',
                          orientation: 'h',
                          text: hseepExcerciseCompletionAllignedSum.map(String),
                        },
                      ]}
                      layout={{
                        title: `HSEEP Exercise Objective<br> Completion Aligned to ${filterHSEEPExcerciseObjectiveinData.label} <br> Total: ${excerciseDataTotal}`,
                        paper_bgcolor: paperBgColor,
                        plot_bgcolor: plotBgColor,
                        yaxis: {
                          automargin: true,
                        },

                        font: {
                          color: 'white',
                        },

                        autosize: true,
                      }}
                      useResizeHandler
                      className="w-100"
                      config={{
                        displayModeBar: true,
                        modeBarButtons: [['toImage']],
                        displaylogo: false,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {typeOption.value === 'DOD' && (
          <div className="col-xl-12 mb-3 mb-xl-4">
            <div className="p-3 p-md-4 bg-gray-800 h-100">
              <div className="d-xl-flex align-items-center mb-3 mb-md-4">
                <h4 className="m-0 mb-2 chartTitle">
                  DOD Train Objective Completion Aligned to
                </h4>
                <div className="ms-auto">
                  <StylishNewSelect
                    style={{ minWidth: '300px' }}
                    options={dropDownTrainFilters}
                    value={filterTrainData}
                    isClearable={false}
                    isSearchable={true}
                    isMulti={false}
                    isDisabled={false}
                    onChange={(e) => {
                      setFilterTrainData(e);
                    }}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-xl-6">
                  <div>
                    <Plot
                      data={[
                        {
                          y: trainDataTitle,
                          x: trainDataTotalTrue,
                          type: 'bar',
                          marker: {
                            color: plotBrick,
                          },

                          orientation: 'h',
                          name: 'Complete',
                          text: trainDataTotalTrue.map(String),
                        },
                        {
                          y: trainDataTitle,
                          x: trainDataTotalFalse,
                          type: 'bar',
                          marker: {
                            color: plotGreen,
                          },

                          orientation: 'h',
                          name: 'Incomplete',
                          text: trainDataTotalFalse.map(String),
                        },
                      ]}
                      layout={{
                        autosize: true,
                        title: `DOD Train Objective<br> Completion Aligned to ${filterTrainData.label} <br> Total:${trainDataTotal}`,
                        barmode: 'stack',
                        paper_bgcolor: paperBgColor,
                        plot_bgcolor: plotBgColor,
                        yaxis: {
                          automargin: true,
                        },

                        showlegend: true,

                        font: {
                          color: 'white',
                        },
                      }}
                      onLegendClick={() => false}
                      useResizeHandler
                      className="w-100"
                      config={{
                        displayModeBar: true,
                        modeBarButtons: [['toImage']],
                        displaylogo: false,
                      }}
                    />
                  </div>
                </div>
                <div className="col-xl-6">
                  <div>
                    <Plot
                      data={[
                        {
                          x: trainDataSum,
                          y: trainDataTitle,
                          type: 'bar',
                          orientation: 'h',
                          text: trainDataSum.map(String),
                        },
                      ]}
                      layout={{
                        title: `DOD Train Objective<br> Completion Aligned to ${filterTrainData.label} <br> Total:${trainDataTotal}`,
                        yaxis: {
                          automargin: true,
                        },

                        autosize: true,
                        paper_bgcolor: paperBgColor,
                        plot_bgcolor: plotBgColor,
                        font: {
                          color: 'white',
                        },
                      }}
                      useResizeHandler
                      className="w-100"
                      config={{
                        displayModeBar: true,
                        modeBarButtons: [['toImage']],
                        displaylogo: false,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {typeOption.value === 'HSEEP' && (
          <div className="col-xl-12 mb-3 mb-xl-4">
            <div className="p-3 p-md-4 bg-gray-800 h-100">
              <div className="d-xl-flex align-items-center mb-3 mb-xl-4">
                <h4 className="m-0 mb-2 chartTitle">
                  HSEEP Exercise Objective Completion Per
                </h4>
                <div className="ms-auto">
                  <StylishNewSelect
                    style={{ minWidth: '300px' }}
                    options={dropDownFilters}
                    value={filterExcerciseObjectiveData}
                    isClearable={false}
                    isSearchable={true}
                    isMulti={false}
                    isDisabled={false}
                    onChange={(e) => {
                      setFilterExcerciseObjectiveData(e);
                    }}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-xl-6">
                  <div>
                    <Plot
                      data={[
                        {
                          y: excerciseParticipantDataTitle,
                          x: excerciseParticipantDataTotalTrue,
                          type: 'bar',
                          marker: {
                            color: plotBrick,
                          },

                          orientation: 'h',
                          name: 'Complete',
                          text: excerciseParticipantDataTotalTrue.map(String),
                        },
                        {
                          y: excerciseParticipantDataTitle,
                          x: exerciseParticipantDataTotalFalse,
                          type: 'bar',
                          marker: {
                            color: plotGreen,
                          },

                          orientation: 'h',
                          name: 'Incomplete',
                          text: exerciseParticipantDataTotalFalse.map(String),
                        },
                      ]}
                      layout={{
                        autosize: true,
                        title: `HSEEP Exercise Objective<br> Completion Per ${filterExcerciseObjectiveData.label} <br> Total: ${excerciseParticipantsDataTotal}`,
                        barmode: 'stack',
                        paper_bgcolor: paperBgColor,
                        yaxis: {
                          automargin: true,
                        },

                        showlegend: true,

                        plot_bgcolor: plotBgColor,
                        font: {
                          color: 'white',
                        },
                      }}
                      onLegendClick={() => false}
                      useResizeHandler
                      className="w-100"
                      config={{
                        displayModeBar: true,
                        modeBarButtons: [['toImage']],
                        displaylogo: false,
                      }}
                    />
                  </div>
                </div>
                <div className="col-xl-6">
                  <div>
                    <Plot
                      data={[
                        {
                          x: excerciseDataSum,
                          y: excerciseParticipantDataTitle,
                          type: 'bar',
                          orientation: 'h',
                          text: excerciseDataSum.map(String),
                        },
                      ]}
                      layout={{
                        title: `HSEEP Exercise Objective<br> Completion Per ${filterExcerciseObjectiveData.label} <br> Total: ${excerciseParticipantsDataTotal}`,
                        paper_bgcolor: paperBgColor,
                        plot_bgcolor: plotBgColor,
                        font: {
                          color: 'white',
                        },
                        yaxis: {
                          automargin: true,
                        },

                        autosize: true,
                      }}
                      useResizeHandler
                      className="w-100"
                      config={{
                        displayModeBar: true,
                        modeBarButtons: [['toImage']],
                        displaylogo: false,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {typeOption.value === 'DOD' && (
          <div className="col-xl-12 mb-3 mb-xl-4">
            <div className="p-3 p-md-4 bg-gray-800 h-100">
              <div className="d-xl-flex align-items-center mb-3 mb-xl-4">
                <h4 className="m-0 mb-2 chartTitle">
                  DOD Train Objective Completion Per
                </h4>
                <div className="ms-auto">
                  <StylishNewSelect
                    style={{ minWidth: '300px' }}
                    options={dropDownFilters}
                    value={filterTrainParticipants}
                    isClearable={false}
                    isSearchable={true}
                    isMulti={false}
                    isDisabled={false}
                    onChange={(e) => {
                      setFilterTrainParticipants(e);
                    }}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-xl-6">
                  <div>
                    <Plot
                      data={[
                        {
                          y: trainParticipantTitle,
                          x: trainParticipantTotalTrue,
                          type: 'bar',
                          orientation: 'h',
                          marker: {
                            color: plotBrick,
                          },

                          name: 'Complete',
                          text: trainParticipantTotalTrue.map(String),
                        },
                        {
                          y: trainParticipantTitle,
                          x: trainParticipantTotalFalse,
                          type: 'bar',
                          orientation: 'h',
                          marker: {
                            color: plotGreen,
                          },

                          name: 'Incomplete',
                          text: trainParticipantTotalFalse.map(String),
                        },
                      ]}
                      layout={{
                        autosize: true,
                        title: `DOD Train Objective<br> Completion Per ${filterTrainParticipants.label} <br> Total:${participantsDataTotal}`,
                        barmode: 'stack',
                        paper_bgcolor: paperBgColor,
                        plot_bgcolor: plotBgColor,
                        yaxis: {
                          automargin: true,
                        },

                        showlegend: true,

                        font: {
                          color: 'white',
                        },
                      }}
                      onLegendClick={() => false}
                      useResizeHandler
                      className="w-100"
                      config={{
                        displayModeBar: true,
                        modeBarButtons: [['toImage']],
                        displaylogo: false,
                      }}
                    />
                  </div>
                </div>
                <div className="col-xl-6">
                  <div>
                    <Plot
                      data={[
                        {
                          x: trainParticipantDataSum,
                          y: trainParticipantTitle,
                          type: 'bar',
                          orientation: 'h',
                          text: trainParticipantDataSum.map(String),
                        },
                      ]}
                      layout={{
                        autosize: true,
                        title: `DOD Train Objective<br> Completion Per ${filterTrainParticipants.label} <br> Total:${participantsDataTotal}`,
                        paper_bgcolor: paperBgColor,
                        plot_bgcolor: plotBgColor,
                        yaxis: {
                          automargin: true,
                        },

                        font: {
                          color: 'white',
                        },
                      }}
                      useResizeHandler
                      className="w-100"
                      config={{
                        displayModeBar: true,
                        modeBarButtons: [['toImage']],
                        displaylogo: false,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="col-xl-6 mb-3 mb-xl-4">
          <div className="p-3 p-md-4 bg-gray-800 h-100">
            <div className="d-xl-flex align-items-center mb-3 mb-xl-4">
              <h4 className="m-0 mb-2 chartTitle">Training Audience per</h4>
              <div className="ms-auto">
                <StylishNewSelect
                  style={{ minWidth: '300px' }}
                  options={dropDownFilters}
                  value={filterAudience}
                  isClearable={false}
                  isSearchable={true}
                  isMulti={false}
                  isDisabled={false}
                  onChange={(e) => {
                    setFilterAudience(e);
                  }}
                />
              </div>
            </div>
            <div>
              <Plot
                data={[
                  {
                    x: dodReasonsTotalAudience,
                    y: dodReasonsDeptTitle,
                    type: 'bar',
                    orientation: 'h',
                    text: dodReasonsTotalAudience.map(String),
                  },
                ]}
                layout={{
                  autosize: true,
                  title: `Exercise Audience per ${filterAudience.label} <br>Total:${audienceTotal}`,
                  paper_bgcolor: paperBgColor,
                  plot_bgcolor: plotBgColor,
                  yaxis: {
                    automargin: true,
                  },

                  font: {
                    color: 'white',
                  },
                }}
                useResizeHandler
                className="w-100"
                config={{
                  displayModeBar: true,
                  modeBarButtons: [['toImage']],
                  displaylogo: false,
                }}
              />
            </div>
          </div>
        </div>
        <div className="col-xl-6 mb-3 mb-xl-4">
          <div className="p-3 p-md-4 bg-gray-800 h-100">
            <div className="d-xl-flex align-items-center mb-3 mb-xl-4">
              <h4 className="m-0 mb-2 chartTitle">Participants per</h4>
              <div className="ms-auto">
                <StylishNewSelect
                  style={{ minWidth: '300px' }}
                  options={dropDownFilters}
                  value={filterParticipants}
                  isClearable={false}
                  isSearchable={true}
                  isMulti={false}
                  isDisabled={false}
                  onChange={(e) => {
                    setFilterParticipants(e);
                  }}
                />
              </div>
            </div>
            <div>
              <Plot
                data={[
                  {
                    x: participantTotalCount,
                    y: participantTitle,
                    type: 'bar',
                    orientation: 'h',
                    text: participantTotalCount.map(String),
                  },
                ]}
                layout={{
                  autosize: true,
                  title: `Participants per ${filterParticipants.label} <br>Total: ${participantsPerTotal}`,
                  paper_bgcolor: paperBgColor,
                  plot_bgcolor: plotBgColor,
                  yaxis: {
                    automargin: true,
                  },

                  font: {
                    color: 'white',
                  },
                }}
                useResizeHandler
                className="w-100"
                config={{
                  displayModeBar: true,
                  modeBarButtons: [['toImage']],
                  displaylogo: false,
                }}
              />
            </div>
          </div>
        </div>
        {option.value == 'feedback' ? (
          <div className="col-xl-6 mb-3 mb-xl-4">
            <div className="p-3 p-md-4 bg-gray-800">
              <div className="d-xl-flex align-items-center mb-3 mb-xl-4">
                <h4 className="m-0 mb-2 chartTitle">Task per</h4>
                <div className="ms-auto">
                  <StylishNewSelect
                    style={{ minWidth: '300px' }}
                    options={allParticipants}
                    value={task}
                    isClearable={false}
                    isSearchable={true}
                    isMulti={false}
                    isDisabled={false}
                    onChange={(e) => {
                      setTask(e);
                    }}
                  />
                </div>
              </div>
              <div>
                <Plot
                  data={[
                    {
                      x: taskParticipantTotalCount,
                      y: taskparticipantTitle,
                      type: 'bar',
                      orientation: 'h',
                      text: taskParticipantTotalCount.map(String),
                    },
                  ]}
                  layout={{
                    autosize: true,
                    title: `Task per ${task.label} <br>Total:${taskParticipantTotal}`,
                    paper_bgcolor: paperBgColor,
                    plot_bgcolor: plotBgColor,
                    font: {
                      color: 'white',
                    },
                    yaxis: {
                      automargin: true,
                    },
                  }}
                  useResizeHandler
                  className="w-100"
                  config={{
                    displayModeBar: true,
                    modeBarButtons: [['toImage']],
                    displaylogo: false,
                  }}
                />
              </div>
            </div>
          </div>
        ) : null}
        <div className="col-xl-6 mb-3 mb-xl-4">
          <div className="p-3 p-md-4 bg-gray-800 h-100">
            <div className="d-xl-flex align-items-center mb-3 mb-xl-4">
              <h4 className="m-0 mb-2 chartTitle">
                Participants Funding Allocation
              </h4>
              <div className="ms-auto">
                <StylishNewSelect
                  style={{ minWidth: '300px' }}
                  options={allFunding}
                  value={funds}
                  isClearable={false}
                  isSearchable={true}
                  isMulti={false}
                  isDisabled={false}
                  onChange={(e) => {
                    setFunds(e);
                  }}
                />
              </div>
            </div>
            <div>
              <Plot
                data={[
                  {
                    x: participantFundAmount,
                    y: participantNamme,
                    type: 'bar',
                    orientation: 'h',
                    text: participantFundAmount.map(String),
                  },
                ]}
                layout={{
                  autosize: true,
                  title: `Participants Funding Allocation <br> Total: ${fSPerParticipantsTotal}`,
                  paper_bgcolor: paperBgColor,
                  plot_bgcolor: plotBgColor,
                  yaxis: {
                    automargin: true,
                  },

                  font: {
                    color: 'white',
                  },
                }}
                useResizeHandler
                className="w-100"
                config={{
                  displayModeBar: true,
                  modeBarButtons: [['toImage']],
                  displaylogo: false,
                }}
              />
            </div>
          </div>
        </div>
        <div className="col-xl-6">
          <div className="p-3 p-md-4 bg-gray-800 h-100">
            <div className="d-xl-flex align-items-center mb-3 mb-xl-4">
              <div className="mb-2 mb-xl-0">
                <h4 className="mb-0 chartTitle">Allocated Funding Source</h4>
                <p className="weight-600 m-0">Per ESF/Organization Type</p>
              </div>
              <div className="ms-auto">
                <StylishNewSelect
                  style={{ minWidth: '300px' }}
                  options={allFunding}
                  value={funds}
                  isClearable={false}
                  isSearchable={true}
                  isMulti={false}
                  isDisabled={false}
                  onChange={(e) => {
                    setFunds(e);
                  }}
                />
              </div>
            </div>
            <Plot
              data={[
                {
                  values: !!allocatedFund && Object.values(allocatedFund),
                  labels: ['Allocated', 'Non Allocated'],

                  type: 'pie',
                  rotation: 90,

                  marker: {
                    colors: [plotGreen, plotOrange],
                  },

                  text:
                    !!allocatedFund && Object.values(allocatedFund).map(String),
                },
              ]}
              layout={{
                autosize: true,
                title: `JEP Funding Source <br>Total  : $ ${allocatedFundTotal?.toLocaleString()}`,
                paper_bgcolor: paperBgColor,
                plot_bgcolor: plotBgColor,
                font: {
                  color: 'white',
                },
              }}
              onLegendClick={() => false}
              useResizeHandler
              className="w-100"
              config={{
                displayModeBar: true,
                modeBarButtons: [['toImage']],
                displaylogo: false,
              }}
            />
          </div>
        </div>
      </div>

      {option.value == 'feedback' && <></>}

      {showAddEditModal && (
        <AddEditCustomReportModal
          show={showAddEditModal}
          onClose={onCloseModal}
          dialogType={dialogType}
        ></AddEditCustomReportModal>
      )}
    </>
  );
};
