import React from 'react';
import { useNavigate } from 'react-router';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Network from '../../../../../service/Network';
import API from '../../../../../service/api';
import { toast } from 'react-toastify';
import { AllIcon } from '../Icon';
import { HelpParticipantReview, useSelfHostedKnowledgeBase } from 'assets/data/HelpContent';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { PreparePath } from 'utils/sharedUtils/sharedConst';
import StylishNewTable from 'components/DesignSystems/New/StylishNewTable';
import StylishHelp from 'components/DesignSystems/New/StylishHelp';
import { toastConfig } from 'assets/data/config';
import { endLoading, startLoading } from "../../../../../../reducers/loading/loading.action";

export const Review = (props) => {
  const [participantDetails, setParticipantDetails] = useState({});
  const [showTotalFunding, setShowTotalFunding] = useState(0);

  const { selectedWorkspace } = useSelector((state) => state.prepare.workspace);
  const reduxUser = useSelector((state) => state.prepare.user);

  const selfHostedKnowledgeBase = useSelfHostedKnowledgeBase();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const getParticipants = async () => {
    dispatch(startLoading());
    try {
      const participants = await Network.get(
        API.fetchSingleParticipantsDetails,
        {
          id: props.participantId,
          workspaceId: selectedWorkspace,
        }
      );
      setShowTotalFunding(participants.data.response.total_funding);

      setParticipantDetails({
        ...participants.data.response.dataset[0],
        total_funding: participants.data.response.total_funding,
      });
    } catch (error) {
      toast.error(error?.response?.data?.response?.status?.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };

  useEffect(() => {
    getParticipants();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reduxUser]);

  const submitAndExit = () => {
    try {
      navigate(`${PreparePath}/dashboard/plan/prepare/participants`, {
        replace: true,
      });
    } catch (error) {
      toast.error(error.response.data.response.status.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };

  const saveAndCreateTo = () => {
    try {
      Network.post(API.addTrainingObjective, {
        participantId: props.participantId,
        workspaceId: selectedWorkspace,
      }).then((resp) => {
        toast.success(' Objective added successfully', toastConfig);
        sessionStorage.setItem(
          'participantDetails',
          JSON.stringify({
            participantId: props.participantId,
            id: resp.data.response.dataset[0].id,
            participantType: participantDetails.type,
            participantName: participantDetails.name,
          })
        );
        navigate(
          `${PreparePath}/dashboard/plan/prepare/training-objective/all-details`,
          {
            state: {
              participantId: props.participantId,
              id: resp.data.response.dataset[0].id,
              participantType: participantDetails.type,
              participantName: participantDetails.name,
            },
            replace: true,
          }
        );
      });
    } catch (error) {
      toast.error(error.response.data.response.status.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };

  const columns_participant_platform = [
    {
      dataField: 'platform_title',
      text: 'Platform Title',
      attrs: { title: 'Platform Title' },
    },
    {
      dataField: 'platform_type',
      text: 'Platform Type',
      attrs: { title: 'Platform Type' },
    },
    {
      dataField: 'bed_down',
      text: 'Bed-down',
      attrs: { title: 'Bed-down' },
    },
  ];

  const columns_participant_planner = [
    {
      dataField: 'name',
      text: 'Name',
      attrs: { title: 'Name' },
    },
    {
      dataField: 'email',
      text: 'Email',
      attrs: { title: 'Email' },
    },
    {
      dataField: 'phone_number',
      text: 'Phone Number',
      attrs: { title: 'Phone Number' },
    },
    {
      dataField: 'primary_contact',
      text: 'Primary Contact',
      attrs: { title: 'Phone Number' },
    },
    {
      dataField: 'position',
      text: 'Position',
      attrs: { title: 'Position' },
    },
  ];

  const columns_funding_source = [
    {
      dataField: 'primary_source',
      text: 'Primary Source',
      attrs: { title: 'Primary Source' },
    },
    {
      dataField: 'sub_source',
      text: 'Sub-source',
      attrs: { title: 'Sub-source' },
    },
    {
      dataField: 'amount',
      text: 'Amount',
      attrs: { title: 'Amount' },
    },
  ];

  return (
    <>
      <div className="d-flex align-items-center mb-4">
        <h4 className="mb-0">Review Participant</h4>
        <StylishHelp
          classes={'ms-auto sml flex-shrink-0'}
          title={HelpParticipantReview.title}
          content={HelpParticipantReview.content}
          link={selfHostedKnowledgeBase || HelpParticipantReview.link}
        />
      </div>
      <div className="form-block">
        <div className="pt-3">
          <h4 className="mb-4">Details</h4>
          <div className="row">
            <div className="col-md-4 mb-3">
              <label className="form-label d-block mb-0">Participant</label>
              {participantDetails.name}
            </div>
            <div className="col-md-4 mb-3">
              <label className="form-label d-block mb-0">
                Participant Type
              </label>
              {participantDetails.type}
            </div>
            <div className="col-md-4 mb-3">
              {participantDetails.type === 'DOD' ? (
                <label className="form-label d-block mb-0">
                  Command or Agency
                </label>
              ) : (
                <label className="form-label d-block mb-0">
                  State/County/Chapter/Command/Agency
                </label>
              )}
              {participantDetails.commandAgencyTitle}
            </div>
            <div className="col-md-4 mb-3">
              {participantDetails.type === 'DOD' ? (
                <label className="form-label d-block mb-0">Service Type</label>
              ) : (
                <label className="form-label d-block mb-0">
                  Jurisdiction/Service/Department Types
                </label>
              )}
              {participantDetails.departmentTypeTitle}
            </div>
            <div className="col-md-4 mb-3">
              {participantDetails.type === 'DOD' ? (
                <label className="form-label d-block mb-0">
                  Service or Department
                </label>
              ) : (
                <label className="form-label d-block mb-0">
                  City/Town/Service/Department
                </label>
              )}
              {participantDetails.departmentTitle}
            </div>
            <div className="col-md-4 mb-3">
              {participantDetails.type === 'DOD' ? (
                <label className="form-label d-block mb-0">Home Station</label>
              ) : (
                <label className="form-label d-block mb-0">
                  Office Location
                </label>
              )}
              {participantDetails.homeStation}
            </div>
            <div className="col-md-4 mb-0 mb-md-3">
              {participantDetails.type === 'DOD' ? (
                <label className="form-label d-block mb-0">
                  Organization Type
                </label>
              ) : (
                <label className="form-label d-block mb-0">
                  ESF / Organization Type
                </label>
              )}
              {participantDetails.participantTypeTitle}
            </div>
            <div className="col-md-4 mb-3">
              <label className="form-label d-block mb-0">Total Audience</label>
              {participantDetails.totalAudience}
            </div>
            <div className="col-md-4 mb-0 mb-md-3">
              <label className="form-label d-block mb-0">Icon</label>
              {
                AllIcon.find((icons) => icons.name === participantDetails.icon)
                  ?.icon
              }
            </div>
          </div>
        </div>
        <hr className="dashed" />
        <div className="pb-3">
          <h4 className="mb-4">Participant Platforms</h4>
          <StylishNewTable
            classes="no-filter"
            headerClasses="empty"
            keyField={'id'}
            columns={columns_participant_platform}
            rows={
              participantDetails?.platform?.length
                ? participantDetails?.platform.map((pData) => ({
                    id: pData.platformId,
                    platform_title: pData.platformTitle,
                    platform_type: pData.platformType,
                    bed_down: pData.platformBeddown,
                  }))
                : []
            }
          />
        </div>
        <hr className="dashed" />
        <div className="pb-3">
          <h4 className="mb-4">Participant Members</h4>
          <StylishNewTable
            classes="no-filter"
            headerClasses="empty"
            keyField={'id'}
            columns={columns_participant_planner}
            rows={
              participantDetails?.planner?.length
                ? participantDetails?.planner.map((pData) => ({
                    id: pData.plannerId,
                    name: pData.plannerName,
                    email: pData.email,
                    phone_number: pData.phnNumber,
                    primary_contact: pData.isPrimary ? 'Yes' : 'No',
                    position: pData.position,
                  }))
                : []
            }
          />
        </div>
        <hr className="dashed" />
        <div className="pb-3">
          <h4 className="mb-4">
            Funding Allocations: $ {showTotalFunding?.toLocaleString()}
          </h4>
          <StylishNewTable
            classes="no-filter"
            headerClasses="empty"
            keyField={'id'}
            columns={columns_funding_source}
            rows={
              participantDetails?.funding?.length
                ? participantDetails?.funding.map((pData) => ({
                    id: pData.fundingSourceId,
                    primary_source: pData.primarysource,
                    sub_source: pData.subsource,
                    amount: `$ ${pData?.fundingSourceAmount?.toLocaleString()}`,
                  }))
                : []
            }
          />
        </div>
      </div>
      <div className="col-md-12 mt-4">
        <div className="button-group d-block d-md-flex">
          <StylishNewButton
            className="button button--primary button--reverse w-100 w-md-auto"
            onClick={() => props.onPrevClick(3)}
            type="submit"
          >
            Previous
          </StylishNewButton>
          <StylishNewButton
            className="button button--tertiary button--reverse ms-auto mt-3 mt-md-0 w-100 w-md-auto"
            onClick={saveAndCreateTo}
          >
            Save &amp; Create Objective
          </StylishNewButton>
          <StylishNewButton
            className="button button--primary  w-100 w-md-auto mt-3 mt-md-0 ms-0 ms-md-3"
            onClick={submitAndExit}
          >
            Complete
          </StylishNewButton>
        </div>
      </div>
    </>
  );
};
