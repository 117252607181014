import React from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useDrag } from 'react-dnd';
import Network from '../../../../../service/Network';
import API from '../../../../../service/api';

export default function MSELBoardItem({
  item,
  boardInjects,
  setBoardInjects,
  onViewInject,
}) {
  const reduxTimezone = useSelector(
    (state) => state.prepare.workspace.timezone
  );
  const { selectedWorkspace } = useSelector((state) => state.prepare.workspace);

  const [{ isDragging }, drag] = useDrag({
    item: item,
    type: 'Not Relevent For Now',
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();
      if (dropResult) {
        if (item.statusLabelId !== dropResult.id) {
          updateMSELInject(dropResult.id, item, dropResult.title);
        }
      }
    },
  });
  const updateMSELInject = async (statusId, oldItem, statusTitle) => {
    const fullOldData = [...boardInjects];
    try {
      let currentBoardInject = [...boardInjects];
      currentBoardInject = currentBoardInject.map((board) => {
        const newItems = board.items.filter((it) => it.id !== oldItem.id);
        if (board.id === statusId) {
          newItems.push({
            ...oldItem,
            statusLabelId: statusId,
            statusLabel: statusTitle,
          });
        }
        return {
          ...board,
          items: newItems,
        };
      });
      setBoardInjects(currentBoardInject);
      await Network.post(API.inject, {
        id: item.id,
        statusId: statusId,
        workspaceId: selectedWorkspace,
      });
    } catch (error) {
      setBoardInjects(fullOldData);
    }
  };
  const opacity = isDragging ? 0.4 : 1;

  return (
    <div
      ref={drag}
      style={{ opacity }}
      className="bg-gray-600 rounded m-2 p-2"
      onClick={() => onViewInject()}
    >
      <div className="d-xl-flex align-items-center weight-500 mb-2 mb-xl-1">
        {item.number && <span className="me-3">#{item.number}</span>}
        {item.startDate && (
          <span>
            {moment(item.startDate).format('DD MMM YY - HHmm')} (
            {reduxTimezone.split('/')[0]})
          </span>
        )}
        <div className="w-100 d-block d-xl-none"></div>
        {item.startDate &&
          moment(item.startDate).isBefore(moment()) &&
          item.statusLabel === 'Scheduled' && (
            <span
              className="status status--sml ms-xl-3 mt-2 mt-xl-0"
              style={{ backgroundColor: '#dc3545' }}
            >
              Past Due
            </span>
          )}
      </div>
      <h5 className="weight-500 mb-2 text-truncate">{item.injectTitle}</h5>
      <span
        className="status status--sml"
        style={{ background: item.planEventColor }}
      >
        {item.planEvent}
      </span>
    </div>
  );
}
