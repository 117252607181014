import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import API from '../../../service/api';
import Network from '../../../service/Network';
import { useNavigate } from 'react-router-dom';
import IconChecked from 'assets/images/icon__tick.svg';
import { inputStringLength, toastConfig } from 'assets/data/config';
import StylishIconSelected from '../../../../components/DesignSystems/New/StylishIconSelected/StylishIconSelected';
import { PreparePath } from 'utils/sharedUtils/sharedConst';
import StylishNewInput from 'components/DesignSystems/New/StylishNewInput';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { AllIcon } from 'assets/Icon/Icon';
import { endLoading, startLoading } from "../../../../reducers/loading/loading.action";

export default function AddNotebookDialog({
  onClose,
  show,
  dialogType,
  updateDialogData,
}) {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    getValues,
    watch,
    setError,
    clearErrors,
  } = useForm();

  const workspaceId = useSelector(
    (state) => state.prepare.workspace.selectedWorkspace
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const colors = [
    '#D32F2F',
    '#C2185B',
    '#7B1FA2',
    '#512DA8',
    '#303F9F',
    '#1976D2',
    '#0288D1',
    '#0097A7',
    '#00796B',
    '#388E3C',
    '#689F38',
    '#AFB42B',
    '#FBC02D',
    '#FFA000',
    '#F57C00',
    '#E64A19',
    '#5D4037',
    '#616161',
    '#455A64',
  ];
  useEffect(() => {
    if (dialogType === 'Edit' && !!updateDialogData) {
      setValue('name', updateDialogData.name);
      setValue(
        'iconSelect',
        AllIcon.find((icon) => icon.name === updateDialogData.icon)
      );
      setValue('color', updateDialogData.color);
      setValue('customColor', updateDialogData.color);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const predefinedColorHandler = () => {
    const checkedValue = getValues('color');
    setValue('customColor', checkedValue);
    if (!checkedValue) {
      setError('customColor');
    } else if (checkedValue) {
      clearErrors('customColor');
    }
  };

  useEffect(() => {
    const enteredValue = getValues('customColor');
    const isPredefinedColor = colors.includes(enteredValue);
    isPredefinedColor ? setValue('color', enteredValue) : setValue('color', '');
  }, [watch('customColor')]);

  const onSubmit = async (data) => {
    const formData = {
      title: data.title,
      color: data.color.length ? data.color : data.customColor,
      icon: data.iconSelect.name,
    };
    dispatch(startLoading());
    try {
      const newNotebook = await Network.post(
        dialogType === 'Add' ? API.createNotebook : API.updateNotebook,
        {
          title: formData.title,
          color: formData.color,
          icon: formData.icon,
          workspaceId: workspaceId,
          notebookId: updateDialogData?.id,
        }
      );
      onClose(newNotebook.data.response.dataset[0]);
      navigate(
        `${PreparePath}/dashboard/notebook/notebooks/details/${newNotebook.data.response.dataset[0].id}?state=New`
      );
      dialogType === 'Add'
        ? toast.success('Notebook created successfully', toastConfig)
        : toast.success('Notebook updated successfully', toastConfig);
    } catch (error) {
      toast.error(
        error.response.data.response.status.msg.charAt(0).toUpperCase() +
          error.response.data.response.status.msg.slice(1),
        toastConfig
      );
    } finally {
      dispatch(endLoading());
    }
  };
  useEffect(() => {
    const listener = (event) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        event.preventDefault();
        handleSubmit(onSubmit)();
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, []);
  return (
    <Modal
      show={show}
      onHide={onClose}
      centered
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title>{dialogType} Notebook</Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <div className="mb-3">
            <label className="form-label">Title</label>
            <Controller
              control={control}
              defaultValue={updateDialogData?.title}
              name="title"
              rules={{ required: true, maxLength: inputStringLength }}
              render={({ field: { onChange, value } }) => (
                <StylishNewInput
                  type="text"
                  onChange={onChange}
                  value={value}
                />
              )}
            />
            {errors?.title?.type === 'required' && (
              <span className="form-text form-error">
                This is a required field
              </span>
            )}
            {errors?.title?.type === 'maxLength' && (
              <span className="form-text form-error">
                Max length is {inputStringLength} characters
              </span>
            )}
          </div>
          <div className="col-12">
            <label className="form-label d-block">Icon</label>
            <Controller
              control={control}
              name="iconSelect"
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <StylishIconSelected
                  onIconChange={onChange}
                  selectedIcon={value}
                />
              )}
            />
            {errors?.iconSelect && (
              <span className="form-text form-error">
                This is a required field
              </span>
            )}
          </div>
          <div className="mb-0">
            <label className="form-label">Color</label>
            <div className="mb-3">
              <ul className="nav nav__form-color">
                {colors.map((item, index) => (
                  <li key={index}>
                    <div className="form-color" style={{ background: item }}>
                      <Controller
                        control={control}
                        name="color"
                        rules={{ required: false }}
                        render={({ field: { onChange, value } }) => (
                          <StylishNewInput
                            type="radio"
                            name="colors"
                            value={item}
                            onChange={(e) => {
                              onChange(e);
                              predefinedColorHandler(e);
                            }}
                            checked={item === value}
                          />
                        )}
                      />
                      <span className="icon">
                        <img src={IconChecked} alt="" />
                      </span>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
            <div className="m-0">
              <Controller
                name="customColor"
                control={control}
                rules={{
                  required: true,
                  pattern: /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/i,
                }}
                render={({ field: { onChange, value } }) => (
                  <StylishNewInput
                    type="text"
                    className="form-control flex-grow-1"
                    placeholder="HEX Value"
                    onChange={(e) => {
                      onChange(e);
                      // enteredColorHandler;
                    }}
                    value={value}
                  />
                )}
              />
            </div>
            {errors?.customColor?.type === 'required' && (
              <span className="form-text form-error">
                This is a required field
              </span>
            )}
            {errors?.customColor?.type === 'pattern' && (
              <div className="alert alert-info mt-2" role="alert">
                <ul className="list-ordered">
                  <li>{"The HEX value must start with '#'"}</li>
                  <li>The HEX value must contain at least 3 characters</li>
                  <li>The HEX value can contain max 6 characters</li>
                </ul>
              </div>
            )}
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div className="button-group">
            <StylishNewButton
              type="button"
              className="button button--secondary button--reverse"
              onClick={onClose}
            >
              Cancel
            </StylishNewButton>
            <StylishNewButton className="button button--primary" type="submit">
              Save
            </StylishNewButton>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
