import { Offcanvas, Row } from 'react-bootstrap';
import React, { useState } from 'react';
import { StylishNewButton } from '../DesignSystems/New/StylishNewButton';
import { FormProvider, useForm } from 'react-hook-form';
import { EditEntityDrawerProps } from './EntityListProps';
import {
  useEditWorkAssignment,
  WorkAssignment,
} from './hooks/workAssignmentHooks';
import DrawerWrapper, { DrawerFooter } from './DrawerWrapper';
import FormTextField from './formFields/FormTextField';
import FormTextAreaField from './formFields/FormTextAreaField';
import FormDateTimeField from './formFields/FormDateTimeField';
import { useActualIncidentId } from './hooks/commonHooks';
import GeoLocationComponent from 'components/GeoLocationComponent/GeoLocationComponent';
import { ParentRelationships } from './ParentRelationships';
import { useIncidentTactics } from './hooks/incidentTacticHooks';
import RelationshipPickerField from './formFields/RelationshipPickerField';
import { Coms, useComs } from './hooks/comsHooks';
import { StrikeTeam, useStrikeTeams } from './hooks/strikeTeamHooks';
import { ResourceSet, useResourceSets } from './hooks/resourceSetHooks';

type FormFields = {
  id: string;
  work_assignment_id: string;
  branch?: string;
  division?: string;
  work_assignment: string;
  resources?: string;
  overhead_positions?: string;
  special_equipment_supplies?: string;
  reporting_location?: string;
  requested_arrival_time?: Date;
  special_instructions?: string;
  archived?: boolean;
  geolocation: any;
  communications: UUID[];
  strike_teams: UUID[];
  resource_sets: UUID[];
};

const WorkAssignmentEditDrawer: React.FC<
  EditEntityDrawerProps<WorkAssignment>
> = ({ toggle, selectedEntity: editedEntity }) => {
  const form = useForm<FormFields>({
    mode: 'onChange',
    defaultValues: {
      ...editedEntity,
      communications: editedEntity.communications ?? [],
      strike_teams: editedEntity.strike_teams ?? [],
      resource_sets: editedEntity.resource_sets ?? [],
    },
  });
  const { handleSubmit } = form;

  const incidentId = useActualIncidentId();
  const mutation = useEditWorkAssignment(editedEntity.id);
  const [geolocation, setGeolocation] = useState(editedEntity.geolocation);

  const incidentTactics = useIncidentTactics();
  const communicationOptions = useComs();
  const strikeTeamOptions = useStrikeTeams();
  const resourceSetOptions = useResourceSets();

  const submitWorkAssignmentEdit = async (formData: FormFields) => {
    const payload = {
      ...formData,
      archived: formData.archived ?? false,
      incident_id: incidentId,
      geolocation: geolocation,
    };
    await mutation.mutateAsync(payload);
    toggle();
  };

  return (
    <DrawerWrapper toggle={toggle} title="Edit Work Assignment">
      <FormProvider {...form}>
        <form
          onSubmit={handleSubmit(submitWorkAssignmentEdit)}
          className="d-flex flex-column justify-content-between flex-grow-1"
        >
          <Offcanvas.Body>
            <Row>
              <FormTextAreaField
                title="Work Assignment"
                fieldName="work_assignment"
                rules={{
                  required: { value: true, message: 'This field is required' },
                }}
                variant="full"
              />
            </Row>
            <Row>
              <FormTextField
                title="Branch"
                fieldName="branch"
                rules={{
                  maxLength: {
                    value: 255,
                    message: 'This maximum length is 255 characters',
                  },
                }}
                variant="half"
              />
            </Row>
            <Row>
              <FormTextField
                title="Division"
                fieldName="division"
                rules={{
                  maxLength: {
                    value: 255,
                    message: 'This maximum length is 255 characters',
                  },
                }}
                variant="half"
              />
            </Row>
            <Row>
              <FormTextAreaField
                title="Resources"
                fieldName="resources"
                variant="full"
              />
            </Row>
            <Row>
              <FormTextAreaField
                title="Overhead Positions"
                fieldName="overhead_positions"
                variant="full"
              />
            </Row>
            <Row>
              <FormTextAreaField
                title="Special Equipment Supplies"
                fieldName="special_equipment_supplies"
                variant="full"
              />
            </Row>
            <Row>
              <div className="m-0">
                <label className="form-label">Location:</label>
                <GeoLocationComponent
                  location={geolocation}
                  setLocation={setGeolocation}
                />
              </div>
            </Row>
            <Row>
              <FormDateTimeField
                title="Requested Arrival Time"
                fieldName="requested_arrival_time"
                variant="half"
                type="datetime-local"
              />
            </Row>
            <Row>
              <FormTextAreaField
                title="Special Instructions"
                fieldName="special_instructions"
                variant="full"
              />
            </Row>
            <Row>
              <ParentRelationships
                label="Tactics"
                parents={incidentTactics.data}
              />
            </Row>
            <Row>
              <RelationshipPickerField<Coms>
                label={'Communications'}
                fieldName={'communications'}
                defaultValue={editedEntity.communications}
                options={communicationOptions.data ?? []}
                title={(entity) => entity.channel_name ?? entity.branch}
                cardType={'coms'}
              />
            </Row>
            <Row>
              <RelationshipPickerField<StrikeTeam>
                label={'Strike Teams'}
                fieldName={'strike_teams'}
                defaultValue={editedEntity.strike_teams}
                options={strikeTeamOptions.data}
              />
            </Row>
            <Row>
              <RelationshipPickerField<ResourceSet>
                label={'Resource Sets'}
                fieldName={'resource_sets'}
                defaultValue={editedEntity.resource_sets}
                options={resourceSetOptions.data}
              />
            </Row>
          </Offcanvas.Body>
          <DrawerFooter>
            <div className="button-group">
              <StylishNewButton
                className="button--secondary button--reverse"
                type="button"
                onClick={toggle}
              >
                Close
              </StylishNewButton>
              <StylishNewButton
                className="button--primary"
                type="submit"
                disabled={mutation.isPending}
              >
                Save
              </StylishNewButton>
            </div>
          </DrawerFooter>
        </form>
      </FormProvider>
    </DrawerWrapper>
  );
};

export default WorkAssignmentEditDrawer;
