import React, { useEffect, useState } from 'react';
import AddEditJMETDialog from './AddEditJMETDialog';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Network from '../../../service/Network';
import API from '../../../service/api';
import StylishHelp from 'components/DesignSystems/New/StylishHelp';
import { HelpPlanJmets, useSelfHostedKnowledgeBase } from 'assets/data/HelpContent';
import StylishNewTable from 'components/DesignSystems/New/StylishNewTable';
import { StylishNewSearchBar } from 'components/DesignSystems/New/StylishNewSearchBar';
import StylishNewTableAction from 'components/DesignSystems/New/StylishNewTableAction';
import { StylishConfirmDialog } from 'components/DesignSystems/New/StylishConfirmDialog';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { toastConfig } from 'assets/data/config';
import { PaginationDropdown } from '../../../../components/DesignSystems/PaginationDropdown';
import {
  endLoading,
  startLoading,
} from '../../../../reducers/loading/loading.action';

export default function Jmets() {
  const [allJMETs, setAllJMETs] = useState([]);
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [perPage, setPerPage] = useState(25);
  const [total, setTotal] = useState(0);
  const [showJMETAddEditDialog, setShowJMETAddEditDialog] = useState(false);
  const [dialogType, setDialogType] = useState('Add');
  const [updateDialogData, setUpdateDialogData] = useState(null);
  const [deleteJMETId, setDeleteJMETId] = useState(null);
  const [deleteJMETLevelWar, setDeleteJMETLevelWar] = useState('');
  const [deleteJMETTaskNumber, setDeleteJMETTaskNumber] = useState('');

  const selfHostedKnowledgeBase = useSelfHostedKnowledgeBase()

  const dispatch = useDispatch();

  const workspaceId = useSelector(
    (state) => state.prepare.workspace.selectedWorkspace
  );

  const columns = [
    {
      dataField: 'levelWar',
      text: 'Level of War',
      sort: true,
      headerAttrs: { title: 'Sort By:' },
      attrs: { title: 'Level of War' },
    },
    {
      dataField: 'taskNumber',
      text: 'Task Number',
      sort: true,
      attrs: { title: 'Task Number' },
    },
    {
      dataField: 'taskTitle',
      text: 'Task Title',
      sort: true,
      attrs: { title: 'Task Title' },
    },
    {
      dataField: '',

      text: 'Action',
      isDummyField: true,
      formatter: (cell, row, rowIndex) => (
        <StylishNewTableAction
          name="JMETs"
          cell={cell}
          row={row}
          rowIndex={rowIndex}
          onDelete={onDeleteJMET}
          onEdit={onEditJMET}
        />
      ),
      headerStyle: {
        width: '100px',
      },
      headerAlign: 'center',
      align: 'center',
      attrs: { title: 'Action' },
    },
  ];
  const getAllJMETs = async () => {
    dispatch(startLoading());
    try {
      const jmets = await Network.get(API.getAllJMETs, {
        limit: perPage,
        page: page,
        search: searchTerm,
        workspaceId: workspaceId,
      });

      setAllJMETs(jmets.data.response.dataset);
      setTotal(jmets.data.response.count);
    } catch (error) {
      toast.error(error.response.data.response.status.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };

  useEffect(() => {
    getAllJMETs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, perPage, searchTerm, workspaceId]);

  const onAddJMET = () => {
    setDialogType('Add');
    setShowJMETAddEditDialog(true);
  };
  const onDeleteJMET = (row) => {
    setDeleteJMETId(row.id);
    setDeleteJMETLevelWar(row.levelWar);
    setDeleteJMETTaskNumber(row.taskNumber);
  };
  const onEditJMET = (row) => {
    setUpdateDialogData(row);
    setDialogType('Edit');
    setShowJMETAddEditDialog(true);
  };
  const OnAddOrEditJMET = (afterSave) => {
    setUpdateDialogData(null);
    setDialogType('New');
    setShowJMETAddEditDialog(false);
    if (afterSave?.id) {
      getAllJMETs();
    }
  };
  const onDeleteConfirm = async () => {
    dispatch(startLoading());
    try {
      await Network.post(API.deleteJMETs, { jmetId: deleteJMETId });
      setDeleteJMETId(null);
      getAllJMETs();
      toast.success('JMET deleted successfully', toastConfig);
    } catch (error) {
      toast.error(error.response.data.response.status.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };
  const onChangeSearchTerm = (term) => {
    setPage(1);
    setSearchTerm(term);
  };
  return (
    <>
      <div className="settings__jmets">
        <StylishNewButton
          className="button button--primary mb-4"
          onClick={onAddJMET}
        >
          Add JMET
        </StylishNewButton>
        <div className="form-block">
          <div className="row">
            <div className="col-md-12">
              <div className="d-flex flex-column flex-md-row align-items-md-center mb-4">
                <div className="d-flex align-items-center justify-content-between">
                  <p className="m-0 me-3 text-nowrap">{total || 0} JMETs</p>
                  <div className="">
                    <PaginationDropdown
                      setPage={setPage}
                      setPerPage={setPerPage}
                      options={[
                        { value: 25, label: '25 per page' },
                        { value: 50, label: '50 per page' },
                        { value: 75, label: '75 per page' },
                        { value: 100, label: '100 per page' },
                      ]}
                    />
                  </div>
                </div>
                <div className="ms-md-auto mt-3 mt-md-0 d-flex align-items-center">
                  <div className="flex-grow-1">
                    <StylishNewSearchBar
                      onChangeSearchTerm={onChangeSearchTerm}
                    />
                  </div>
                  <StylishHelp
                    classes={'ms-3 flex-shrink-0 sml'}
                    title={HelpPlanJmets.title}
                    content={HelpPlanJmets.content}
                    link={selfHostedKnowledgeBase || HelpPlanJmets.link}
                  />
                </div>
              </div>
            </div>
          </div>
          <StylishNewTable
            keyField={'id'}
            columns={columns}
            rows={allJMETs}
            onDelete={onDeleteJMET}
            onEdit={onEditJMET}
            pagination
            page={page}
            perPage={perPage}
            bordered={false}
            striped={true}
            total={total}
            onPageChange={(page) => setPage(page)}
          />
        </div>
        {showJMETAddEditDialog && (
          <AddEditJMETDialog
            show={showJMETAddEditDialog}
            onClose={OnAddOrEditJMET}
            dialogType={dialogType}
            updateDialogData={updateDialogData}
          />
        )}
        {deleteJMETId && (
          <StylishConfirmDialog
            show={deleteJMETId}
            onClose={() => setDeleteJMETId(null)}
            dialogTitle={'Delete JMET'}
            dialogContentType={'html'}
            dialogContent={`
              Deleting <span class="weight-700 break-word">${deleteJMETLevelWar}-${deleteJMETTaskNumber}</span> will remove all its occurance from this workspace.<span class="d-block pt-3">Are you sure you want to delete this JMET ?</span>`}
            onConfirm={onDeleteConfirm}
          />
        )}
      </div>
    </>
  );
}
