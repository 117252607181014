import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import React, { useEffect, useRef, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin!
import timegrid from '@fullcalendar/timegrid'; // a plugin!
import listPlugin from '@fullcalendar/list'; // a plugin!
import interactionPlugin from '@fullcalendar/interaction'; // must go before plugins
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import Network from 'PREPAREsrc/service/Network';
import API from 'PREPAREsrc/service/api';
import { toast } from 'react-toastify';
import { endLoading, startLoading } from 'reducers/loading/loading.action';
import AddEditPlanMeetingsDialog from '../../Prepare/ExerciseDetails/PlanMeetings/BkAddEditPlanMeetingsDialog';
import ViewEventCalendarDialog from '../viewEventCalendarDialog';
import { toastConfig } from 'assets/data/config';
import IconPlaceholder from 'assets/images/icon__exercise-details-empty.svg';

export const EventCalendar = (props) => {
  const { selectedTab } = props;
  const calendarId = 'calendar';
  const calendarRef = useRef(null);
  const dispatch = useDispatch();

  const { eventId } = useSelector((state) => state?.prepare?.planDashboard);
  const reduxUser = useSelector((state) => state.prepare.user);
  const { selectedWorkspace } = useSelector((state) => state.prepare.workspace);
  // const exerciseId = useSelector(
  //   (state) => state.prepare.exercise.exerciseDetailsId
  // );

  var date = new Date();
  var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  const [currentMonth, setCurrentMonth] = useState(lastDay);
  const [listMeetings, setListMeetings] = useState('');
  const [refinedEventMeetings, setRefinedEventMeetings] = useState([]);
  const [selectedIncidentMember, setSelectedIncidentMember] = useState();
  const [existingEvent, setExistingEvent] = useState('');
  const [eventMeetings, setEventMeetings] = useState([]);
  const [
    addScheduleActivationDialogOpen,
    setAddScheduleActivationDialogOpen,
  ] = useState(false);
  const [
    showEventMeetingsAddEditDialog,
    setShowEventMeetingsAddEditDialog,
  ] = useState(false);
  const [dialogType, setDialogType] = useState('Add');
  const [updateDialogData, setUpdateDialogData] = useState(null);

  const fetchListMeetingTestRoute = () => {
    dispatch(startLoading());
    try {
      Network.get(API.fetchListMeetingTestRoute, {
        workspaceId: selectedWorkspace,
        calendarDate: currentMonth.toISOString(),
      }).then((resp) => {
        let allPlanMeetings = {};
        allPlanMeetings =
          resp.data.response?.length &&
          resp.data.response.map((e) => {
            return {
              ...e,
              startDate: moment(e?.startDate).toISOString(),
              endDate: moment(e?.endDate).toISOString(),
            };
          });
        setEventMeetings(allPlanMeetings);
        dispatch(endLoading());
      });
    } catch (error) {
      toast.error(error.response.data.response.status.msg, toastConfig);
      dispatch(endLoading());
    }
  };

  const editExistingEvent = (eventInfo) => {
    const { event } = eventInfo;

    const incidentMember = {
      title: event.title,
      id: event.id,
      name: event?.title,
      start: event.start,
      end: event.end,
      agenda: event.extendedProps.agenda,
      location: event.extendedProps.location,
    };
    setSelectedIncidentMember(incidentMember);
    setExistingEvent(event);
    setAddScheduleActivationDialogOpen(true);
  };

  const OnAddOrEditTrainingPriorites = (afterSave) => {
    setUpdateDialogData(null);
    setDialogType('New');
    setShowEventMeetingsAddEditDialog(false);
    setAddScheduleActivationDialogOpen(false);
    if (afterSave?.id) {
      fetchListMeetingTestRoute();
    }
    if (afterSave === 'Deleted') {
      fetchListMeetingTestRoute();
    }
  };

  const onAddEventMeetings = () => {
    setDialogType('Add');
    setShowEventMeetingsAddEditDialog(true);
  };

  useEffect(() => {
    if (eventMeetings) {
      const planCalendar = eventMeetings.map((e) => {
        const refinedPlanCalendar = {
          title: e?.name,
          id: e?.id,
          agenda: e?.agenda,
          name: e?.name,
          location: e?.location,
          start: e?.startDate,
          end: e?.endDate,
        };
        return refinedPlanCalendar;
      });
      setRefinedEventMeetings(planCalendar);
    } else {
      setRefinedEventMeetings([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventMeetings]);

  useEffect(() => {
    if (selectedTab === 'event_calendar' && eventId) {
      fetchListMeetingTestRoute();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    reduxUser,
    // exerciseId,
    currentMonth,
    listMeetings,
    selectedTab,
    selectedWorkspace,
    eventId,
  ]);

  return (
    <>
      {(refinedEventMeetings && !!refinedEventMeetings?.length && (
        <>
          <div className="d-flex align-items-center mb-4">
            <StylishNewButton
              className="button--primary w-100 w-md-auto"
              onClick={onAddEventMeetings}
            >
              Add Event Meeting
            </StylishNewButton>
          </div>
          {selectedTab === 'event_calendar' && eventId && (
            <div className="calendar">
              <FullCalendar
                id={calendarId}
                validRange={''}
                plugins={[
                  dayGridPlugin,
                  timegrid,
                  listPlugin,
                  interactionPlugin,
                ]}
                headerToolbar={{
                  left: 'prev,next today',
                  center: 'title',
                  right: 'timeGridDay,timeGridWeek,dayGridMonth,listMonth',
                }}
                initialView="dayGridMonth"
                events={refinedEventMeetings}
                eventClick={editExistingEvent}
                eventTimeFormat={{
                  hour: '2-digit',
                  minute: '2-digit',
                  meridiem: 'short',
                  hour12: reduxUser?.timeFormat === 'hour12Mode' ? true : false,
                }}
                displayEventTime={false}
                eventBorderColor={'#000000'}
                slotLabelFormat={{
                  hour: '2-digit',
                  minute: '2-digit',
                  meridiem: 'short',
                  hour12: reduxUser?.timeFormat === 'hour12Mode' ? true : false,
                }}
                slotLabelContent={(info) => {
                  if (info.text === '24:00') {
                    info.text = '00:00';
                  }
                }}
              />
            </div>
          )}
        </>
      )) || (
        <div className="bg-gray-800 text-center px-4 py-5 p-md-5">
          <div className="py-0 py-md-4">
            <div className="d-flex justify-content-center mb-4">
              <img src={IconPlaceholder} alt="" />
            </div>
            <p className="weight-600 mb-3">
              A fresh start. Create the first entry.
            </p>
            <div className="d-flex justify-content-center">
              <StylishNewButton
                type="button"
                className={'button--primary'}
                onClick={onAddEventMeetings}
              >
                Add Event Meeting
              </StylishNewButton>
            </div>
          </div>
        </div>
      )}
      {addScheduleActivationDialogOpen && (
        <ViewEventCalendarDialog
          show={existingEvent}
          selectedIncidentMember={selectedIncidentMember}
          onClose={OnAddOrEditTrainingPriorites}
        ></ViewEventCalendarDialog>
      )}
      {showEventMeetingsAddEditDialog && (
        <AddEditPlanMeetingsDialog
          show={showEventMeetingsAddEditDialog}
          onClose={OnAddOrEditTrainingPriorites}
          dialogType={dialogType}
          updateDialogData={updateDialogData}
          title="Event Meeting"
        />
      )}
    </>
  );
};
