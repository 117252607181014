import React from 'react';
import { NavLink } from 'react-router-dom';
import Error404 from 'assets/images/typography__404.svg';
import Graphic404 from 'assets/images/graphics__404.svg';

export default function PageNotFound() {
  return (
    <>
      <div className="site site__no-route">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-md-6 col-lg-5 col-xl-4">
              <div className="d-flex justify-content-center mb-5">
                <span className="logo">EMW</span>
              </div>
              <h3 className="mb-3">Page not found</h3>
              <div className="d-flex justify-content-center mb-4">
                <img className="graphics__404" src={Error404} alt="" />
              </div>
              <div className="d-flex justify-content-center">
                <img className="w-100 d-block" src={Graphic404} alt="" />
              </div>
              <div className="button-group justify-content-center mt-5">
                <NavLink to={'/login'} className="button button--primary">
                  Back to Login
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
