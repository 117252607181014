import React, { useState, useEffect, useRef } from 'react';
import { Modal } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import API from '../../../service/api';
import Network from '../../../service/Network';
import IconChecked from 'assets/images/icon__tick.svg';
import { inputStringLength, toastConfig } from 'assets/data/config';
import IconSelected from '../../../../components/DesignSystems/New/StylishIconSelected/StylishIconSelected';
import { AllIcon } from '../../../../components/DesignSystems/New/StylishIconSelected/Icon';
import StylishNewSelect from 'components/DesignSystems/New/StylishNewSelect';
import moment from 'moment';
import StylishButton from 'components/DesignSystems/buttons/StylishButton';
import { StylishNewSearchBar } from 'components/DesignSystems/New/StylishNewSearchBar';
import { productionEnv } from 'constants/debug';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import IconTimes from 'assets/images/icon__times.svg';
import IconUpload from 'assets/images/icon__upload.svg';
import StylishNewInput from 'components/DesignSystems/New/StylishNewInput';
import {
  endLoading,
  startLoading,
} from '../../../../reducers/loading/loading.action';

export default function AddSiteDialog({
  onClose,
  show,
  dialogType,
  updateDialogData,
}) {
  const unsplashClientId = window.env.UNSPLASH_CLIENT_ID;

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    getValues,
    setError,
    clearErrors,
  } = useForm();

  const [sites, setSites] = useState({});
  const [avatarTypeSelectedOption, setAvatarTypeSelectedOption] = useState();

  const [searchTerm, setSearchTerm] = useState('');
  const [images, setImages] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [selectedAvatar, setSelectedAvatar] = useState(null);
  const [resetAvatar, setResetAvatar] = useState(false);
  const [uploadAvatarError, setUploadAvatarError] = useState(false);
  const [uploadAvailable, setuploadAvailable] = useState(false);
  const [fileUploaded, setFileUploaded] = useState(false);

  const workspaceId = useSelector(
    (state) => state.prepare.workspace.selectedWorkspace
  );

  const dispatch = useDispatch();

  const unsplashContainerRef = useRef();

  const avatarTypeOptions = [
    { label: 'Icon', value: 'icon' },
    { label: 'Image', value: 'image' },
  ];

  const colors = [
    '#D32F2F',
    '#C2185B',
    '#7B1FA2',
    '#512DA8',
    '#303F9F',
    '#1976D2',
    '#0288D1',
    '#0097A7',
    '#00796B',
    '#388E3C',
    '#689F38',
    '#AFB42B',
    '#FBC02D',
    '#FFA000',
    '#F57C00',
    '#E64A19',
    '#5D4037',
    '#616161',
    '#455A64',
  ];

  const mediaOptions = [
    { value: 'video', label: 'Video' },
    { value: 'social_media', label: 'Social Media' },
    { value: 'news', label: 'News' },
    { value: 'blog', label: 'Blogs' },
    { value: 'forum', label: 'Forum' },
    { value: 'chat', label: 'Chat' },
    { value: 'other', label: 'Other' },
  ];

  const onSubmit = async (data) => {
    if (avatarTypeSelectedOption?.value === 'image' && !fileUploaded) {
      setuploadAvailable(true);
      return;
    }
    dispatch(startLoading());
    try {
      const newSite = await Network.post(
        dialogType === 'Add' ? API.createMediaSite : API.updateMediaSite,
        {
          displayName: data?.title,
          color:
            !selectedAvatar && data?.iconSelect?.name
              ? data?.customColor
              : null,
          icon:
            avatarTypeSelectedOption?.value === 'icon'
              ? data?.iconSelect?.name
              : null,
          site_image:
            avatarTypeSelectedOption?.value === 'image' ? selectedAvatar : null,
          workspaceId: workspaceId,
          mediaType: sites?.value,
          siteId: updateDialogData?.id,
          updatedAt: moment().toISOString(),
        }
      );
      onClose(newSite.data.response.dataset);
      toast.success(
        dialogType === 'Add'
          ? 'Site created successfully'
          : 'Site updated successfully',
        toastConfig
      );
    } catch (error) {
      toast.error(error.response.data.response.status.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };

  useEffect(() => {
    if (dialogType === 'Edit' && !!updateDialogData) {
      setValue('title', updateDialogData.displayName);
      setValue(
        'iconSelect',
        AllIcon.find((icon) => icon.name === updateDialogData.icon)
      );
      setValue(
        'mediaTypeDropdown',
        mediaOptions.find((mO) => mO.value === updateDialogData.mediaType)
      );

      setValue('color', updateDialogData.color);
      setValue('customColor', updateDialogData.color);

      !!updateDialogData?.site_image
        ? setAvatarTypeSelectedOption({ label: 'Image', value: 'image' })
        : !!updateDialogData?.icon
        ? setAvatarTypeSelectedOption({ label: 'Icon', value: 'icon' })
        : setAvatarTypeSelectedOption();

      setValue(
        'avatarType',
        !!updateDialogData?.site_image
          ? { label: 'Image', value: 'image' }
          : !!updateDialogData?.icon
          ? { label: 'Icon', value: 'icon' }
          : null
      );
    }
  }, []);

  const predefinedColorHandler = () => {
    const checkedValue = getValues('color');
    setValue('customColor', checkedValue);
  };

  const enteredColorHandler = () => {
    const enteredValue = getValues('customColor');
    const isPredefinedColor = colors.includes(enteredValue);
    isPredefinedColor ? setValue('color', enteredValue) : setValue('color', '');
  };

  useEffect(() => {
    const listener = (event) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        event.preventDefault();
        handleSubmit(onSubmit)();
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, []);

  const onChangeSearchTerm = (term) => {
    setPage(1);
    setSearchTerm(term);
  };

  useEffect(() => {
    setImages([]);
  }, [searchTerm]);

  useEffect(() => {
    if (avatarTypeSelectedOption?.value === 'image') {
      fetchImages();
    }
  }, [page, searchTerm, avatarTypeSelectedOption]);

  const fetchImages = async () => {
    try {
      fetch(
        `https://api.unsplash.com/search/photos?query=${
          searchTerm || 'nature'
        }&page=${page}&orientation=squarish&per_page=15 &client_id=${unsplashClientId}`
      )
        .then((res) => res.json())
        .catch(console.log)
        .then((res) => {
          const newImages = res?.results;
          if (res?.results) {
            setImages((prevImages) => [...prevImages, ...newImages]);
          }

          if (newImages && newImages.length === 0) {
            setHasMore(false);
          }
        })
        .catch(console.log);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (
      !!avatarTypeSelectedOption &&
      avatarTypeSelectedOption?.value === 'image'
    ) {
      if (
        (!updateDialogData || !updateDialogData?.color) &&
        !getValues('iconSelect')
      ) {
        setValue('color', null);
        setValue('customColor', null);
      }
    }
  }, [avatarTypeSelectedOption]);

  const handleUploadSiteImage = async (event) => {
    const file = event.target.files[0];
    if (file) {
      setuploadAvailable(false);
      setFileUploaded(true);
    } else {
      setuploadAvailable(true);
      setFileUploaded(false);
    }
    if (file && Math.ceil(file?.size / 1000) > 100) {
      setUploadAvatarError(true);
      return;
    } else {
      setUploadAvatarError(false);
      uploadSiteImage(file);
    }
  };

  const uploadSiteImage = async (imageFile) => {
    dispatch(startLoading());
    try {
      const formData = new FormData();
      formData.append('file', imageFile);
      const response = await Network.post(API.publicSingleFileUpload, formData);
      setSelectedAvatar(response?.data?.response?.dataset[0]?.fileUrl);
      toast.success('Image uploaded successfully', toastConfig);
    } catch (error) {
      toast.error(error?.response?.data?.response?.status?.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };

  useEffect(() => {
    if (!!selectedAvatar) setResetAvatar(false);
  }, [selectedAvatar]);

  return (
    <Modal
      show={show}
      onHide={onClose}
      centered
      backdrop="static"
      keyboard={false}
      size="lg"
    >
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title>{dialogType} Site</Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Modal.Body>
          <div className="mb-3">
            <label className="form-label">Site Name</label>
            <input
              type="text"
              maxLength={inputStringLength}
              name="title"
              className="form-control"
              {...register('title', { required: true })}
              placeholder="Name"
            />
            {errors?.title && (
              <span className="form-text form-error">
                This is a required field
              </span>
            )}
          </div>
          <div className="mb-3">
            <label className="form-label">Site Type</label>
            <Controller
              control={control}
              name="mediaTypeDropdown"
              rules={{ required: true }}
              render={({ field: { value } }) => (
                <StylishNewSelect
                  options={mediaOptions}
                  value={value}
                  isClearable={false}
                  isSearchable={false}
                  isMulti={false}
                  isDisabled={false}
                  onChange={(e) => {
                    if (!e) {
                      setError('mediaTypeDropdown');
                    } else {
                      clearErrors('mediaTypeDropdown');

                      setSites(e);
                      setValue('mediaTypeDropdown', e);
                    }
                  }}
                />
              )}
            />
            {errors?.mediaTypeDropdown && (
              <span className="form-text form-error">
                This is a required field
              </span>
            )}
          </div>
          <div className={`${!!avatarTypeSelectedOption ? 'mb-3' : ''}`}>
            <label className="form-label">Avatar Type</label>
            <Controller
              control={control}
              name="avatarType"
              rules={{ required: false }}
              render={({ field: { onChange, value } }) => (
                <StylishNewSelect
                  options={avatarTypeOptions}
                  value={avatarTypeSelectedOption || value}
                  placeholder="Select a type"
                  onChange={(e) => {
                    setAvatarTypeSelectedOption(e);
                    e.value === 'image' &&
                      !selectedAvatar &&
                      !updateDialogData?.site_image &&
                      setResetAvatar(true);
                    onChange(e);
                  }}
                  isClearable={false}
                  isSearchable={false}
                  isMulti={false}
                  isDisabled={false}
                />
              )}
            />
            {errors?.avatarType && (
              <span className="form-text form-error">
                This is a required field
              </span>
            )}
          </div>
          {avatarTypeSelectedOption?.value === 'icon' ? (
            <>
              <div className="mb-3">
                <label className="form-label d-block">Icon</label>
                <Controller
                  control={control}
                  name="iconSelect"
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <IconSelected
                      onIconChange={onChange}
                      selectedIcon={value}
                    />
                  )}
                />
                {errors?.iconSelect && (
                  <span className="form-text form-error">
                    This is a required field
                  </span>
                )}
              </div>
              <div className="mb-0">
                <label className="form-label">Color</label>
                <div className="mb-3">
                  <ul className="nav nav__form-color">
                    {colors.map((item, index) => (
                      <li key={index}>
                        <div
                          className="form-color"
                          style={{ background: item }}
                        >
                          <input
                            type="radio"
                            name="colors"
                            value={item}
                            {...register('color', {
                              onChange: predefinedColorHandler,
                            })}
                          />
                          <span className="icon">
                            <img src={IconChecked} alt="" />
                          </span>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="m-0">
                  <input
                    type="text"
                    className="form-control flex-grow-1"
                    placeholder="HEX Value"
                    {...register('customColor', {
                      pattern: /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/i,
                      onChange: enteredColorHandler,
                    })}
                  />
                </div>
                {errors?.customColor?.type === 'pattern' && (
                  <div className="alert alert-info mt-2" role="alert">
                    <ul className="list-ordered">
                      <li>{"The HEX value must start with '#'"}</li>
                      <li>The HEX value must contain at least 3 characters</li>
                      <li>The HEX value can contain max 6 characters</li>
                    </ul>
                  </div>
                )}
              </div>
            </>
          ) : null}
          {avatarTypeSelectedOption?.value === 'image' ? (
            <>
              {resetAvatar ? (
                <>
                  <div className="m-0">
                    <label className="form-label">Upload Image</label>
                    <div className="form-upload">
                      <div>
                        <span className="d-flex justify-content-center mb-2">
                          <img src={IconUpload} alt="" />
                        </span>
                        <span className="d-block text-center">
                          Drop Your File Here or Click to Upload <br />
                          Max upload size 100KB
                        </span>
                      </div>
                      <StylishNewInput
                        type="file"
                        name="file"
                        accept=".png, .jpg, jpeg"
                        onChange={handleUploadSiteImage}
                        required
                      />
                    </div>
                    {uploadAvailable ? (
                      <span className="form-text form-error">
                        This field is required
                      </span>
                    ) : null}
                    {uploadAvatarError ? (
                      <span className="form-text form-error">
                        Max upload size 100KB
                      </span>
                    ) : null}
                  </div>
                </>
              ) : (
                <div className="d-flex align-items-start mt-3">
                  <div className="avatar xlarge me-3">
                    <img
                      src={
                        !!updateDialogData && !!updateDialogData?.site_image
                          ? updateDialogData?.site_image
                          : selectedAvatar
                      }
                      alt=""
                    />
                  </div>
                  <StylishNewButton
                    customButton
                    className={'button--icon'}
                    onClick={() => {
                      setResetAvatar(true);
                    }}
                  >
                    <img src={IconTimes} alt="" />
                  </StylishNewButton>
                </div>
              )}
            </>
          ) : null}
        </Modal.Body>

        <Modal.Footer>
          <div className="button-group">
            <StylishButton
              className="button button--secondary button--reverse"
              onClick={onClose}
            >
              Cancel
            </StylishButton>
            <StylishButton className="button button--primary" type="submit">
              Save
            </StylishButton>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
