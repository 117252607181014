import { Link, useNavigate, useParams } from 'react-router-dom';
import IconBack from 'assets/images/socials/icon__back.svg';
import Logo from 'assets/images/socials/instagram__logo.svg';
import IconSearch from 'assets/images/socials/youtube__icon__search.svg';
import IconTag from 'assets/images/socials/instagram__icon__tag.svg';
import IconLike from 'assets/images/socials/instagram__icon__likes.svg';
import IconComment from 'assets/images/socials/instagram__icon__comments.svg';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import moment from 'moment';
import { useSelector } from 'react-redux';
import IconEmptyExercise from 'assets/images/icon__exercise-details-empty.svg';
import { PreparePath } from 'utils/sharedUtils/sharedConst';

export const PostInstagram = ({ data }) => {
  const navigate = useNavigate();

  const { workspaceName } = useSelector((state) => {
    return state?.prepare?.workspace;
  });

  const params = useParams();

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <>
      <div className="instagram">
        <div className="instagram__header">
          <div className="d-flex align-items-center">
            <StylishNewButton
              customButton
              className={'button--icon'}
              onClick={handleBack}
            >
              <img src={IconBack} alt="" />
            </StylishNewButton>
            <span className="logo">
              <img src={Logo} alt="" className="" />
            </span>
          </div>
          <div className="search">
            <img className="icon" src={IconSearch} alt="" />
            <input type="search" placeholder="Search..." />
          </div>
          <div className="link-group">
            <Link className="link-button">Login</Link>
            <Link>Sign Up</Link>
          </div>
        </div>
        <div className="instagram__main">
          {sessionStorage.getItem('mediaModuleSocialMediaType') === 'sites' ? (
            <div className="row">
              {data?.mediaFile ? (
                <div className="col-lg-6 col-xl-7 mb-4 mb-md-5 mb-lg-0">
                  <div className="d-flex">
                    <img
                      src={data?.mediaFile}
                      alt=""
                      className="d-block w-100"
                    />
                  </div>
                </div>
              ) : null}
              <div
                className={`${
                  data?.mediaFile ? 'col-lg-6 col-xl-5' : 'col-md-12'
                }`}
              >
                <div className="d-flex align-items-center mb-4">
                  <div className="d-flex align-items-center">
                    <div className="avatar flex-shrink-0">
                      {data?.profileAvatar ? (
                        <img src={data?.profileAvatar} alt="" />
                      ) : null}
                    </div>
                    {data?.profileName ? (
                      <h6 className="m-0 ms-3">{data?.profileName}</h6>
                    ) : null}
                  </div>
                  <span className="color-blue weight-700 ms-auto flex-shrink-0">
                    Follow
                  </span>
                </div>
                {data?.post ? <p className="m-0">{data?.post}</p> : null}
                <div className="d-flex align-items-center mt-4 mt-md-5">
                  <div className="d-flex align-items-center">
                    <img src={IconLike} alt="" className="cursor-pointer" />
                    <img
                      src={IconComment}
                      alt=""
                      className="ms-3 cursor-pointer"
                    />
                  </div>
                  <img
                    src={IconTag}
                    alt=""
                    className="ms-auto cursor-pointer"
                  />
                </div>
                <div className="mt-4">
                  <p className="weight-600 m-0">{data?.likes || 0} Likes</p>
                  {data?.postPublishTime ? (
                    <p className="m-0">
                      {moment(data?.postPublishTime).format('MMM Do YYYY')}
                    </p>
                  ) : null}
                </div>
                {data?.comments && !!data?.comments.length ? (
                  <div className="mt-5">
                    {data?.comments.map((i, idx) =>
                      i?.status === 'drafted' ? null : (
                        <div key={i?.id}>
                          {idx !== 0 ? (
                            <hr className="dashed thin my-4" />
                          ) : null}
                          <div className="d-flex align-items-start">
                            <div className="avatar flex-shrink-0">
                              {i?.profileAvatar ? (
                                <img src={i?.profileAvatar} alt="" />
                              ) : null}
                            </div>
                            <div className="ms-3">
                              {i?.profileName ? (
                                <h6 className="mb-2">{i?.profileName}</h6>
                              ) : null}
                              {i?.comment ? (
                                <p className="m-0">{i?.comment}</p>
                              ) : null}
                              {i?.commentImg ? (
                                <div className="d-flex mt-3">
                                  <img
                                    src={i?.commentImg}
                                    alt=""
                                    style={{ width: '100px', height: 'auto' }}
                                  />
                                </div>
                              ) : null}
                              <p className="m-0 mt-3 weight-500 d-flex align-items-md-center flex-column flex-md-row txt--sml">
                                {i?.publishTime ? (
                                  <>{moment(i?.publishTime).fromNow()}</>
                                ) : null}
                                <span className="mt-2 mt-md-0 ms-md-4">
                                  {i?.likes || '0'} Likes
                                </span>
                                <span className="mt-2 mt-md-0 ms-md-4">
                                  Reply
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                      )
                    )}
                  </div>
                ) : null}
              </div>
            </div>
          ) : null}
          {sessionStorage.getItem('mediaModuleSocialMediaType') ===
          'profiles' ? (
            <>
              {!params?.postId ? (
                <>
                  {data?.profiledata?.posts &&
                  !!data?.profiledata?.posts?.length ? (
                    <>
                      {data?.profiledata?.posts.map((i, idx) => (
                        <div key={i.id}>
                          {idx !== 0 ? <hr className="dashed my-5" /> : null}
                          <div className="row position-relative">
                            <Link
                              className="stretched-link"
                              to={`${PreparePath}/dashboard/media/profiles/${
                                workspaceName &&
                                workspaceName.charAt(0).toUpperCase()
                              }/${data?.sitedata?.slug}/${
                                data?.profiledata?.username
                              }/${i?.id}`}
                            ></Link>
                            {i?.mediaFile ? (
                              <div className="col-lg-6 col-xl-7 mb-4 mb-md-5 mb-lg-0">
                                <div className="d-flex">
                                  <img
                                    src={i?.mediaFile}
                                    alt=""
                                    className="d-block w-100"
                                  />
                                </div>
                              </div>
                            ) : null}
                            <div
                              className={`${
                                i?.mediaFile ? 'col-lg-6 col-xl-5' : 'col-md-12'
                              }`}
                            >
                              <div className="d-flex align-items-center mb-4">
                                <div className="d-flex align-items-center">
                                  <div className="avatar flex-shrink-0">
                                    {data?.profiledata?.avatar ? (
                                      <img
                                        src={data?.profiledata?.avatar}
                                        alt=""
                                      />
                                    ) : null}
                                  </div>
                                  {data?.profiledata?.profileName ? (
                                    <h6 className="m-0 ms-3">
                                      {data?.profiledata?.profileName}
                                    </h6>
                                  ) : null}
                                </div>
                                <span className="color-blue weight-700 ms-auto flex-shrink-0">
                                  Follow
                                </span>
                              </div>
                              {i?.post ? (
                                <p className="m-0">{i?.post}</p>
                              ) : null}
                              <div className="d-flex align-items-center mt-4 mt-md-5">
                                <div className="d-flex align-items-center">
                                  <img
                                    src={IconLike}
                                    alt=""
                                    className="cursor-pointer"
                                  />
                                  <img
                                    src={IconComment}
                                    alt=""
                                    className="ms-3 cursor-pointer"
                                  />
                                </div>
                                <img
                                  src={IconTag}
                                  alt=""
                                  className="ms-auto cursor-pointer"
                                />
                              </div>
                              <div className="mt-4">
                                <p className="weight-600 m-0">
                                  {i?.likes || 0} Likes
                                </p>
                                {i?.publishTime ? (
                                  <p className="m-0">
                                    {moment(i?.publishTime).format(
                                      'MMM Do YYYY'
                                    )}
                                  </p>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </>
                  ) : (
                    <div className="text-center p-5">
                      <div className="d-flex justify-content-center mb-4">
                        <img
                          src={IconEmptyExercise}
                          alt=""
                          style={{ opacity: '0.5' }}
                        />
                      </div>
                      <p className="weight-600 mb-4">No Post Found!</p>
                    </div>
                  )}
                </>
              ) : (
                <div className="row">
                  {data?.mediaFile ? (
                    <div className="col-lg-6 col-xl-7 mb-4 mb-md-5 mb-lg-0">
                      <div className="d-flex">
                        <img
                          src={data?.mediaFile}
                          alt=""
                          className="d-block w-100"
                        />
                      </div>
                    </div>
                  ) : null}
                  <div
                    className={`${
                      data?.mediaFile ? 'col-lg-6 col-xl-5' : 'col-md-12'
                    }`}
                  >
                    <div className="d-flex align-items-center mb-4">
                      <div className="d-flex align-items-center">
                        <div className="avatar flex-shrink-0">
                          {data?.profileAvatar ? (
                            <img src={data?.profileAvatar} alt="" />
                          ) : null}
                        </div>
                        {data?.profileName ? (
                          <h6 className="m-0 ms-3">{data?.profileName}</h6>
                        ) : null}
                      </div>
                      <span className="color-blue weight-700 ms-auto flex-shrink-0">
                        Follow
                      </span>
                    </div>
                    {data?.post ? <p className="m-0">{data?.post}</p> : null}
                    <div className="d-flex align-items-center mt-4 mt-md-5">
                      <div className="d-flex align-items-center">
                        <img src={IconLike} alt="" className="cursor-pointer" />
                        <img
                          src={IconComment}
                          alt=""
                          className="ms-3 cursor-pointer"
                        />
                      </div>
                      <img
                        src={IconTag}
                        alt=""
                        className="ms-auto cursor-pointer"
                      />
                    </div>
                    <div className="mt-4">
                      <p className="weight-600 m-0">{data?.likes || 0} Likes</p>
                      {data?.postPublishTime ? (
                        <p className="m-0">
                          {moment(data?.postPublishTime).format('MMM Do YYYY')}
                        </p>
                      ) : null}
                    </div>
                    {data?.comments && !!data?.comments.length ? (
                      <div className="mt-5">
                        {data?.comments.map((i, idx) => (
                          <div key={i?.id}>
                            {idx !== 0 ? (
                              <hr className="dashed thin my-4" />
                            ) : null}
                            <div className="d-flex align-items-start">
                              <div className="avatar flex-shrink-0">
                                {i?.profileAvatar ? (
                                  <img src={i?.profileAvatar} alt="" />
                                ) : null}
                              </div>
                              <div className="ms-3">
                                {i?.profileName ? (
                                  <h6 className="mb-2">{i?.profileName}</h6>
                                ) : null}
                                {i?.comment ? (
                                  <p className="m-0">{i?.comment}</p>
                                ) : null}
                                {i?.commentImg ? (
                                  <div className="d-flex mt-3">
                                    <img
                                      src={i?.commentImg}
                                      alt=""
                                      style={{ width: '100px', height: 'auto' }}
                                    />
                                  </div>
                                ) : null}
                                <p className="m-0 mt-3 weight-500 d-flex align-items-md-center flex-column flex-md-row txt--sml">
                                  {i?.publishTime ? (
                                    <>{moment(i?.publishTime).fromNow()}</>
                                  ) : null}
                                  <span className="mt-2 mt-md-0 ms-md-4">
                                    {i?.likes || '0'} Likes
                                  </span>
                                  <span className="mt-2 mt-md-0 ms-md-4">
                                    Reply
                                  </span>
                                </p>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : null}
                  </div>
                </div>
              )}
            </>
          ) : null}
        </div>
      </div>
    </>
  );
};
