import { Offcanvas, Row } from 'react-bootstrap';
import React, {useState,useEffect} from 'react';
import { StylishNewButton } from '../DesignSystems/New/StylishNewButton';
import { FormProvider, useForm } from 'react-hook-form';
import { AddEntityDrawerProps } from './EntityListProps';
import { useCreateIncidentComs } from './hooks/incidentComsHooks';
import DrawerWrapper, { DrawerFooter } from './DrawerWrapper';
import FormTextField from './formFields/FormTextField';
import FormTextAreaField from './formFields/FormTextAreaField';
import FormCheckboxField from './formFields/FormCheckboxField';
import { useActualIncidentId } from './hooks/commonHooks';
import { useSelector, useDispatch } from 'react-redux';
import { selectUserGuid } from '../../slices/commonSelectors';
import { selectGroupGuid, useAppSelector } from '../../slices/commonSelectors';
import FormSelectField from './formFields/FormSelectField';
import {ComsTypes, ComsFunctions} from '../ICS/ICSConstants'

type FormFields = {
  coms_id: string;
  type: string;
  branch?: string;
  division?: string;
  method_of_contact?: string;
  zone_group?: string;
  channel_number?: string;
  function?: string;
  channel_name?: string;
  assignment?: string;
  rx_frequency?: string;
  rx_tone_nac?: string;
  tx_frequency?: string;
  tx_tone_nac?: string;
  mode?: string;
  remarks?: string;
  special_instructions?: string;
  archived?: boolean;
  name? :string;
};

const ComsAddDrawer: React.FC<AddEntityDrawerProps> = ({ toggle }) => {
  const reduxDispatch = useDispatch()
  const form = useForm<FormFields>({ mode: 'onChange', defaultValues: {type:'Radio'} });
  const { handleSubmit, watch } = form;
  const watchedFields = watch(["type"]);

  const mutation = useCreateIncidentComs();

  const incidentId = useActualIncidentId();
  const user_guid = useSelector(selectUserGuid);
  const group_guid = useAppSelector(selectGroupGuid);

  const onNewComsDataSubmit = async (formData: FormFields) => {
    const payload = {
      ...formData,
      archived: formData.archived ?? false,
      incident_id: incidentId,
      user_guid: user_guid,
      group_guid,
    };
    await mutation.mutateAsync(payload);
    toggle();
  };

  return (
    <DrawerWrapper toggle={toggle} title="Add Communication">
      <FormProvider {...form}>
        <form
          onSubmit={handleSubmit(onNewComsDataSubmit)}
          className="d-flex flex-column justify-content-between flex-grow-1"
        >
          <Offcanvas.Body>

            <Row>
              <FormSelectField
                title="Type"
                fieldName="type"
                rules={{
                  required: { value: true, message: 'This field is required' },
                }}
                options={ComsTypes}
                variant="half"
              />
            </Row>

            <Row>
              <FormTextField
                title="Branch"
                fieldName="branch"
                rules={{ maxLength: { value: 255, message: 'This maximum length is 255 characters' } }}
                variant="half"
              />
            </Row>
            <Row>
              <FormTextField
                title="Division"
                fieldName="division"
                rules={{ maxLength: { value: 255, message: 'This maximum length is 255 characters' } }}
                variant="half"
              />
            </Row>

            {watchedFields.find(f=>f==="Radio") && (
              <>
                <Row>
                  <FormTextField
                    title="Zone Group"
                    fieldName="zone_group"
                    rules={{ maxLength: { value: 255, message: 'This maximum length is 255 characters' } }}
                    variant="half"
                  />
                </Row>
                <Row>
                  <FormTextField
                    title="Channel Number"
                    fieldName="channel_number"
                    rules={{ maxLength: { value: 255, message: 'This maximum length is 255 characters' } }}
                    variant="half"
                  />
                </Row>
                <Row>
                  <FormSelectField
                    title="Function"
                    fieldName="function"
                    options={ComsFunctions}
                    variant="half"
                  />
                </Row>
                 
                <Row>
                  <FormTextField
                    title="Channel Name"
                    fieldName="channel_name"
                    rules={{ maxLength: { value: 255, message: 'This maximum length is 255 characters' } }}
                    variant="half"
                  />
                </Row>
                <Row>
                  <FormTextField
                    title="RX Frequency"
                    fieldName="rx_frequency"
                    rules={{ maxLength: { value: 255, message: 'This maximum length is 255 characters' } }}
                    variant="half"
                  />
                </Row>
                <Row>
                  <FormTextField
                    title="RX Tone NAC"
                    fieldName="rx_tone_nac"
                    rules={{ maxLength: { value: 255, message: 'This maximum length is 255 characters' } }}
                    variant="half"
                  />
                </Row>
                <Row>
                  <FormTextField
                    title="TX Frequency"
                    fieldName="tx_frequency"
                    rules={{ maxLength: { value: 255, message: 'This maximum length is 255 characters' } }}
                    variant="half"
                  />
                </Row>
                <Row>
                  <FormTextField
                    title="TX Tone NAC"
                    fieldName="tx_tone_nac"
                    rules={{ maxLength: { value: 255, message: 'This maximum length is 255 characters' } }}
                    variant="half"
                  />
                </Row>
                <Row>
                  <FormTextField
                    title="Mode (A, D, or M)"
                    fieldName="mode"
                    rules={{ maxLength: { value: 50, message: 'This maximum length is 50 characters' } }}
                    variant="half"
                  />
                </Row>
                <Row>
                  <FormTextAreaField
                    title="Remarks"
                    fieldName="remarks"
                    rules={{ maxLength: { value: 300, message: 'This maximum length is 300 characters' } }}
                    variant="full"
                  />
                </Row>
              </>
            ) || (
              <>
                <Row>
                  <FormTextField
                    title="Name of Contact"
                    fieldName="name"
                    rules={{ maxLength: { value: 255, message: 'This maximum length is 255 characters' } }}
                    variant="half"
                  />
                </Row>
                <Row>
                  <FormTextField
                    title="Method of Contact"
                    fieldName="method_of_contact"
                    rules={{ maxLength: { value: 255, message: 'This maximum length is 255 characters' } }}
                    variant="half"
                  />
                </Row>
              </>
            )}

            <Row>
              <FormCheckboxField
                title="Archived"
                fieldName="archived"
              />
            </Row>
          </Offcanvas.Body>
          <DrawerFooter>
            <div className="button-group">
              <StylishNewButton
                className="button--secondary button--reverse"
                type="button"
                onClick={toggle}
              >
                Close
              </StylishNewButton>
              <StylishNewButton
                className="button--primary"
                type="submit"
                disabled={mutation.isPending}
              >
                Save
              </StylishNewButton>
            </div>
          </DrawerFooter>
        </form>
      </FormProvider>
    </DrawerWrapper>
  );
};

export default ComsAddDrawer;
