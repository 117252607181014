import React, { useState, useEffect, useRef } from 'react';

import { Link, NavLink, useNavigate, useParams } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import ReactPaginate from 'react-paginate';
import Help from '../../../component/Help/Help';
import { NetworkView, useSelfHostedKnowledgeBase } from 'assets/data/HelpContent';
import API from '../../../service/api';
import Network from '../../../service/Network';
import { toast } from 'react-toastify';
import IconEdit from 'assets/images/icon__edit.svg';
import IconDelete from 'assets/images/icon__delete.svg';
import ProfileImage from 'assets/images/icon__profileimage.jpg';
import IconView from 'assets/images/icon__eye.svg';
import { PreparePath } from 'utils/sharedUtils/sharedConst';
import { StylishConfirmDialog } from 'components/DesignSystems/New/StylishConfirmDialog';
import { toastConfig } from 'assets/data/config';
import StylishHelp from 'components/DesignSystems/New/StylishHelp';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import IconEmptyExercise from 'assets/images/icon__exercise-details-empty.svg';
import { endLoading, startLoading } from "../../../../reducers/loading/loading.action";

export default function ViewNetwork() {
  const [deleteNetwork, setDeleteNetwork] = useState(null);
  const [filteredPersonaArray, setFilteredPersonaArray] = useState([]);
  const [deletePersona, setDeletePersona] = useState(null);
  const reduxUser = useSelector((state) => state.prepare.user);
  const [allNetworks, setAllNetworks] = useState([]);
  const [networksTotal, setNetworksTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [connectedPersonas, setConnectedPersonas] = useState(null);
  const perPage = 10;

  const selfHostedKnowledgeBase = useSelfHostedKnowledgeBase();

  const dispatch = useDispatch();

  const navigate = useNavigate();
  const workspaceId = useSelector(
    (state) => state.prepare.workspace.selectedWorkspace
  );

  const isMounted = useRef(true);
  const id = useParams();

  const onDeleteNetwork = (row) => {
    setDeleteNetwork(row.networkId);
  };

  const getNetworkById = () => {
    dispatch(startLoading());
    try {
      Network.get(API.getNetworkById, {
        networkId: id?.id,
      })
        .then((resp) => {
          setAllNetworks(resp.data.response.dataset);
          resp.data.response.dataset[0]?.mediapersonas &&
            setNetworksTotal(
              resp.data.response.dataset[0]?.mediapersonas?.length
            );
          setConnectedPersonas(resp.data.response.dataset[0]?.mediapersonas);
          const filteredArray = resp.data.response.dataset[0]?.mediapersonas.map(
            function (mediaPersona) {
              return { mediaPersonaId: mediaPersona?.mediaPersonaId };
            }
          );
          setFilteredPersonaArray(filteredArray);
        })

        .catch(console.log)
        .finally(() => {
          dispatch(endLoading());
        });
    } catch (error) {
      toast.error(error, toastConfig);
      dispatch(endLoading());
    }
  };

  useEffect(() => {
    getNetworkById();
  }, [reduxUser]);

  useEffect(() => {
    if (isMounted.current) {
      isMounted.current = false;
      return;
    }
    if (!!workspaceId) {
      navigate(-1);
    }
  }, [workspaceId]);

  const onDeleteConfirmPersona = async () => {
    dispatch(startLoading());

    try {
      await Network.post(API.deleteMediaNetworkPersona, {
        personaId: deletePersona,
        networkId: id?.id,
      });
      setFilteredPersonaArray(
        filteredPersonaArray.filter(
          (obj) => obj.mediaPersonaId !== deletePersona
        )
      );
      setConnectedPersonas(
        connectedPersonas.filter((obj) => obj.mediaPersonaId !== deletePersona)
      );
      setDeletePersona(null);

      toast.success('Persona removed successfully', toastConfig);
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(endLoading());
    }
  };

  const ondeletePersona = (row) => {
    setDeletePersona(row.mediaPersonaId);
  };

  const onDeleteConfirm = async () => {
    dispatch(startLoading());
    try {
      await Network.post(API.deleteMediaNetwork, {
        networkId: deleteNetwork,
      });
      setDeleteNetwork(null);
      toast.success('Network deleted successfully', toastConfig);
      navigate(`${PreparePath}/dashboard/media/networks`);
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(endLoading());
    }
  };

  return (
    <>
      <div className="d-flex align-items-center mb-4">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb mb-0">
            <li className="breadcrumb-item">
              <StylishNewButton onClick={() => navigate(-1)}>
                <i className="fa fa-angle-left me-2" aria-hidden="true"></i>
                Back
              </StylishNewButton>
            </li>
            <li className="breadcrumb-item">
              <StylishNewButton
                onClick={() =>
                  navigate(`${PreparePath}/dashboard/media/networks`)
                }
              >
                Networks
              </StylishNewButton>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              {allNetworks[0]?.networkName}
            </li>
          </ol>
        </nav>
        <StylishHelp
          classes={'ms-auto sml flex-shrink-0'}
          title={NetworkView.title}
          content={NetworkView.content}
          link={selfHostedKnowledgeBase, NetworkView.link}
        />
      </div>

      <div className="bg-gray-800 p-4 mb-4">
        <div className="d-flex">
          {allNetworks.map((aN) => {
            return (
              <>
                <div className="flex-grow-1">
                  <div className="d-flex flex-md-row flex-column">
                    {allNetworks[0]?.avatar ? (
                      <div
                        className="profile-img profile-img__big flex-shrink-0"
                        key={aN?.id}
                      >
                        <img src={allNetworks[0]?.avatar} alt="" />
                      </div>
                    ) : (
                      <div className="profile-img profile-img__big flex-shrink-0"></div>
                    )}

                    <div
                      className="flex-grow-1 ps-md-4 pt-4 pt-md-0"
                      key={aN?.networkId}
                    >
                      <h5 className="break-word">{aN?.networkName}</h5>
                      <p className="mb-0">{aN?.networkDescription}</p>
                    </div>
                  </div>
                </div>

                <Dropdown className="ms-3 flex-shrink-0">
                  <Dropdown.Toggle className="button--icon">
                    <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                  </Dropdown.Toggle>
                  <Dropdown.Menu align="end">
                    <Link
                      className="dropdown-item"
                      to={`${PreparePath}/dashboard/media/edit-network/${aN?.networkId}`}
                    >
                      <img src={IconEdit} alt="" />
                      Edit Network
                    </Link>
                    <Dropdown.Item onClick={() => onDeleteNetwork(aN)}>
                      <img src={IconDelete} alt="" />
                      Delete Network
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </>
            );
          })}
        </div>
      </div>

      <h4>Personas</h4>
      {connectedPersonas?.length > 0 ? (
        <div className="row">
          {connectedPersonas &&
            connectedPersonas?.length > 0 &&
            connectedPersonas.map((persona) => {
              return (
                <div
                  className="col-md-6 col-lg-4 col-xl-3 mb-4"
                  key={persona?.id}
                >
                  <div className="bg-gray-800 rounded h-100 d-flex flex-column overflow-hidden">
                    <div className="p-3">
                      <div className="d-flex align-items-start mb-4">
                        {persona?.avatar ? (
                          <div className="profile-img">
                            <img src={persona?.avatar} alt="" />
                          </div>
                        ) : (
                          <div className="profile-img"></div>
                        )}

                        <Dropdown className="flex-shrink-0 ms-auto">
                          <Dropdown.Toggle className="button--icon">
                            <i
                              className="fa fa-ellipsis-v"
                              aria-hidden="true"
                            ></i>
                          </Dropdown.Toggle>
                          <Dropdown.Menu align="end">
                            <Dropdown.Item
                              onClick={() => {
                                navigate(
                                  `${PreparePath}/dashboard/media/edit-personas/useblankpersona/${persona.mediaPersonaId}`
                                );
                              }}
                            >
                              <img src={IconEdit} alt="" />
                              Edit Persona
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => {
                                ondeletePersona(persona);
                              }}
                            >
                              <img src={IconDelete} alt="" />
                              Remove From Network
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>

                      <h4 className="mb-2 break-word">{persona?.name}</h4>
                      <span className="color-brand">
                        {persona?.count} Linked Profiles
                      </span>
                    </div>

                    <div className="bg-gray-700 p-3 mt-auto d-flex align-items-center justify-content-center">
                      <Link
                        to={`${PreparePath}/dashboard/media/edit-personas/useblankpersona/${persona.mediaPersonaId}`}
                        className="text-white weight-500 d-flex align-items-center justify-content-center flex-grow-1"
                      >
                        <img src={IconEdit} className="me-2" alt="" />
                        Edit{' '}
                      </Link>
                      <div className="separator--y separator--800  flex-shrink-0"></div>

                      <Link
                        to={`${PreparePath}/dashboard/media/view-persona/${persona.mediaPersonaId}`}
                        className="text-white weight-500 d-flex align-items-center justify-content-center flex-grow-1"
                      >
                        <img src={IconView} className="img-h-16 me-2" alt="" />
                        View
                      </Link>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      ) : (
        <div className="bg-gray-800 text-center p-4">
          <div className="py-0 py-md-4">
            <div className="d-flex justify-content-center mb-4">
              <img src={IconEmptyExercise} alt="" />
            </div>
            <p className="weight-600 mb-0">No Personas Found</p>
          </div>
        </div>
      )}

      {deleteNetwork && (
        <StylishConfirmDialog
          show={deleteNetwork}
          onClose={() => setDeleteNetwork(null)}
          dialogTitle={'Delete Network'}
          dialogContent={'Are you sure you want this?'}
          onConfirm={onDeleteConfirm}
        />
      )}
      {deletePersona && (
        <StylishConfirmDialog
          show={deletePersona}
          onClose={() => setDeletePersona(null)}
          dialogTitle={'Delete Persona from network'}
          dialogContent={'Are you sure you want this?'}
          onConfirm={onDeleteConfirmPersona}
        />
      )}
    </>
  );
}
