import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Network from '../../../../../service/Network';
import API from '../../../../../service/api';
import AddEditObjectivesDialog from './AddEditObjectivesDialog';
import { toast } from 'react-toastify';
import { HelpExerciseObjectives, useSelfHostedKnowledgeBase } from 'assets/data/HelpContent';
import StylishNewTableAction from 'components/DesignSystems/New/StylishNewTableAction';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import StylishNewTable from 'components/DesignSystems/New/StylishNewTable';
import { PreparePath } from 'utils/sharedUtils/sharedConst';
import StylishHelp from 'components/DesignSystems/New/StylishHelp';
import { toastConfig } from 'assets/data/config';
import { StylishConfirmDialog } from 'components/DesignSystems/New/StylishConfirmDialog';
import { PaginationDropdown } from '../../../../../../components/DesignSystems/PaginationDropdown';
import { endLoading, startLoading } from "../../../../../../reducers/loading/loading.action";

export default function Objectives(props) {
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const searchTerm = '';
  const [perPage, setPerPage] = useState(5);
  const [objectives, setObjectives] = useState([]);
  const [oganizationalCapability, setOganizationalCapability] = useState([]);
  const [dialogType, setDialogType] = useState('Add');
  const [updateDialogData, setUpdateDialogData] = useState(null);
  const [deleteObjectives, setDeleteObjectives] = useState(null);
  const [
    showObjectivesAddEditDialog,
    setShowObjectivesAddEditDialog,
  ] = useState(false);
  const [orderBy, setOrderBy] = useState('');

  const selfHostedKnowledgeBase = useSelfHostedKnowledgeBase()

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { selectedWorkspace, workspaceName } = useSelector((state) => {
    return state.prepare.workspace;
  });
  const reduxUser = useSelector((state) => state.prepare.user);
  const workspaceType = useSelector((state) => state.prepare.workspace.type);
  const exerciseId = useSelector(
    (state) => state.prepare.exercise.exerciseDetailsId
  );

  const objectiveColumns = [
    {
      dataField: 'title',
      text: 'Objective Title',
      sort: true,
      onSort: (field, order) => {
        if (order === 'asc') {
          setOrderBy('title_ASC');
        } else if (order === 'desc') {
          setOrderBy('title_DESC');
        } else {
          setOrderBy('');
        }
      },

      headerAttrs: { title: 'Sort By:' },
      attrs: { title: 'Objective Title' },
    },
    {
      dataField: '',

      text: 'Action',
      isDummyField: true,
      formatter: (cell, row, rowIndex) => (
        <StylishNewTableAction
          name="Objective"
          cell={cell}
          row={row}
          rowIndex={rowIndex}
          onDelete={onDeleteObjectives}
          onEdit={onEditgetObjectives}
        />
      ),
      attrs: { title: 'Action' },
      headerStyle: {
        width: '100px',
      },
      headerAlign: 'center',
      align: 'center',
    },
  ];

  const targetColumns = [
    {
      dataField: 'title',
      text: 'Title',
      sort: true,
      onSort: (field, order) => {
        if (order === 'asc') {
          setOrderBy('title_ASC');
        } else if (order === 'desc') {
          setOrderBy('title_DESC');
        } else {
          setOrderBy('');
        }
      },
    },
    {
      dataField: '',

      text: 'Action',
      isDummyField: true,
      formatter: (cell, row, rowIndex) => (
        <StylishNewTableAction
          name="Target"
          cell={cell}
          row={row}
          rowIndex={rowIndex}
          onDelete={onDeleteObjectives}
          onEdit={onEditgetObjectives}
        />
      ),
      attrs: { title: 'Action' },
      headerStyle: {
        width: '100px',
      },
      headerAlign: 'center',
      align: 'center',
    },
  ];

  const onDeleteObjectives = (row) => {
    setDeleteObjectives(row.id);
  };

  const onEditgetObjectives = (row) => {
    setUpdateDialogData(row);
    setDialogType('Edit');
    setShowObjectivesAddEditDialog(true);
  };

  const onAddObjectives = () => {
    setDialogType('Add');
    setShowObjectivesAddEditDialog(true);
  };

  const OnAddOrEditObjectives = (afterSave) => {
    setUpdateDialogData(null);
    setDialogType('New');
    setShowObjectivesAddEditDialog(false);
    if (afterSave?.id) {
      if (workspaceType === 'DOD') {
        getAllObjectives();
      } else {
        getOganizationalCapabilityTarget();
      }
    }
  };

  const onDeleteConfirm = async () => {
    dispatch(startLoading());
    try {
      await Network.post(API.deleteObjective, { id: deleteObjectives });
      setDeleteObjectives(null);
      getAllObjectives();
      toast.success('Objective deleted successfully', toastConfig);
    } catch (error) {
      toast.error(error.response.data.response.status.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };

  const onDeleteOganizationalCapabilityTarget = async () => {
    dispatch(startLoading());
    try {
      await Network.post(API.deleteOrganizationalCapabilityTarget, {
        id: deleteObjectives,
      });
      setDeleteObjectives(null);
      getOganizationalCapabilityTarget();
      toast.success('Target deleted successfully', toastConfig);
    } catch (error) {
      toast.error(error.response.data.response.status.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };

  const getOganizationalCapabilityTarget = async () => {
    dispatch(startLoading());
    try {
      const obj = await Network.get(API.fetchAllOganizationalCapabilityTarget, {
        limit: perPage,
        page: page,
        search: searchTerm,
        workspaceId: selectedWorkspace,
        sort: orderBy,
      });
      setOganizationalCapability(obj.data.response.dataset);
      if (obj.data.response.total) {
        setTotal(obj.data.response.total);
      }
    } catch (error) {
      toast.error(error.response.data.response.status.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };

  const getAllObjectives = async () => {
    dispatch(startLoading());
    try {
      const obj = await Network.get(API.fetchAllObjectives, {
        limit: perPage,
        page: page,
        search: searchTerm,
        workspaceId: selectedWorkspace,
        planEventId: exerciseId,
        sort: orderBy,
      });
      setObjectives(obj.data.response.dataset);
      if (obj.data.response.total) {
        setTotal(obj.data.response.total);
      }
    } catch (error) {
      toast.error(error.response.data.response.status.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };
  const saveAndExit = () => {
    navigate(`${PreparePath}/dashboard/plan/prepare/exercise-details`);
  };

  useEffect(() => {
    if (exerciseId) {
      if (workspaceType === 'DOD') {
        getAllObjectives();
      } else {
        getOganizationalCapabilityTarget();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, perPage, searchTerm, orderBy, reduxUser]);

  return (
    <>
      {workspaceType === 'DOD' ? (
        <div>
          <div className="d-flex align-items-center mb-4">
            <h4 className="mb-0">
              {props?.exerciseDetailName?.eventName || workspaceName} Exercise
              Objectives
            </h4>
            <StylishHelp
              classes={'ms-auto sml flex-shrink-0'}
              title={HelpExerciseObjectives.title}
              content={HelpExerciseObjectives.content}
              link={selfHostedKnowledgeBase || HelpExerciseObjectives.link}
            />
          </div>
          <div className="form-block">
            <StylishNewButton
              className="button button--primary mb-3 w-100 w-md-auto"
              onClick={onAddObjectives}
            >
              Add Objective
            </StylishNewButton>
            <div className="row mb-2">
              <div className="col-md-12">
                <div className="d-flex align-items-center">
                  <p className="m-0 me-3 text-nowrap weight-600">
                    Exercise Objectives: {total > 0 && total}
                  </p>
                  <div className="ms-auto">
                    <PaginationDropdown
                      setPage={setPage}
                      setPerPage={setPerPage}
                      options={[
                        { value: 5, label: '5 per page' },
                        { value: 15, label: '15 per page' },
                        { value: 30, label: '30 per page' },
                        { value: 60, label: '60 per page' },
                        { value: 120, label: '120 per page' },
                      ]}
                    />
                  </div>
                </div>
              </div>
            </div>
            <StylishNewTable
              keyField={'id'}
              columns={objectiveColumns}
              rows={objectives}
              pagination
              page={page}
              perPage={perPage}
              total={total}
              onPageChange={(page) => setPage(page)}
            />
            {showObjectivesAddEditDialog && (
              <AddEditObjectivesDialog
                show={showObjectivesAddEditDialog}
                onClose={OnAddOrEditObjectives}
                dialogType={dialogType}
                updateDialogData={updateDialogData}
              />
            )}
            {deleteObjectives && (
              <StylishConfirmDialog
                show={deleteObjectives}
                onClose={() => setDeleteObjectives(null)}
                dialogType={'Delete'}
                dialogTitle={'Delete Exercise Objective'}
                dialogContent={'Are you sure you want this?'}
                updateDialogData={deleteObjectives}
                onConfirm={onDeleteConfirm}
              />
            )}
          </div>
          <div className="col-md-12 mt-4">
            <div className="button-group d-block d-md-flex">
              <StylishNewButton
                type="submit"
                className="button button--primary button--reverse w-100 w-md-auto"
                onClick={() => props.onPrevClick(2)}
              >
                Previous
              </StylishNewButton>
              <StylishNewButton
                type="button"
                className="button button--tertiary button--reverse ms-auto mt-3 mt-md-0 w-100 w-md-auto"
                onClick={saveAndExit}
              >
                Save &amp; Exit
              </StylishNewButton>
              <StylishNewButton
                type="submit"
                className="button button--primary  w-100 w-md-auto mt-3 mt-md-0 ms-0 ms-md-3"
                onClick={() => props.onNextClick(4)}
              >
                Next
              </StylishNewButton>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <h4>{workspaceName} Organizational Capability Targets</h4>
          <div className="form-block">
            <StylishNewButton
              className="button button--primary mb-3 w-100 w-md-auto"
              onClick={onAddObjectives}
            >
              Add Target
            </StylishNewButton>
            <div className="row mb-2">
              <div className="col-md-12">
                <div className="d-flex align-items-center">
                  <p className="m-0 me-3 text-nowrap weight-600">
                    Organizational Capability Targets: {total || 0}
                  </p>
                  <div className="ms-auto">
                    <PaginationDropdown
                      setPage={setPage}
                      setPerPage={setPerPage}
                      options={[
                        { value: 5, label: '5 per page' },
                        { value: 15, label: '15 per page' },
                        { value: 30, label: '30 per page' },
                        { value: 60, label: '60 per page' },
                        { value: 120, label: '120 per page' },
                      ]}
                    />
                  </div>
                </div>
              </div>
            </div>
            <StylishNewTable
              keyField={'id'}
              columns={targetColumns}
              rows={oganizationalCapability}
              pagination
              page={page}
              perPage={perPage}
              total={total}
              onPageChange={(page) => setPage(page)}
            />
            {showObjectivesAddEditDialog && (
              <AddEditObjectivesDialog
                show={showObjectivesAddEditDialog}
                onClose={OnAddOrEditObjectives}
                dialogType={dialogType}
                updateDialogData={updateDialogData}
              />
            )}
            {deleteObjectives && (
              <StylishConfirmDialog
                show={deleteObjectives}
                onClose={() => setDeleteObjectives(null)}
                dialogType={'Delete'}
                dialogTitle={'Delete Exercise Target'}
                dialogContent={'Are you sure you want this?'}
                updateDialogData={deleteObjectives}
                onConfirm={onDeleteOganizationalCapabilityTarget}
              />
            )}
          </div>
          <div className="col-md-12 mt-4">
            <div className="button-group d-block d-md-flex">
              <StylishNewButton
                type="submit"
                className="button button--primary button--reverse w-100 w-md-auto"
                onClick={() => props.onPrevClick(2)}
              >
                Previous
              </StylishNewButton>
              <StylishNewButton
                type="button"
                className="button button--tertiary button--reverse ms-auto mt-3 mt-md-0 w-100 w-md-auto"
                onClick={saveAndExit}
              >
                Save &amp; Exit
              </StylishNewButton>
              <StylishNewButton
                type="submit"
                className="button button--primary  w-100 w-md-auto mt-3 mt-md-0 ms-0 ms-md-3"
                onClick={() => props.onNextClick(4)}
              >
                Next
              </StylishNewButton>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
