import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Dropdown } from 'react-bootstrap';
import { toast } from 'react-toastify';
import API from '../../../service/api';
import Network from '../../../service/Network';
import { toastConfig } from 'assets/data/config';
import { endLoading, startLoading } from "../../../../reducers/loading/loading.action";

const MoveFolderChildDialog = forwardRef(
  (
    {
      onClose,
      show,
      dialogType,
      updateDialogData,
      key,
      folder,
      actualParentId,
    },
    ref
  ) => {
    const dispatch = useDispatch();

    const [allNotefolderDetails, setAllNotefolderDetails] = useState([]);
    const [showSubFolders, setShowSubFolders] = useState({});
    const [selectedFolder, setSelectedFolder] = useState('');

    const workspaceId = useSelector(
      (state) => state.prepare.workspace.selectedWorkspace
    );

    useImperativeHandle(ref, () => ({
      onSubmit() {
        let formData = {};
        if (folder?.type === 'folder') {
          formData = {
            folder_name: folder.folder_name,
            color: folder?.color ? folder.color : folder.customColor,
          };
        } else if (folder?.type === 'file') {
          formData = {
            file_name: folder.file_name,
          };
        }
        if (selectedFolder != '' && selectedFolder != 'root') {
          dispatch(startLoading());
          try {
            Network.post(
              folder?.type === 'folder'
                ? API.updateNotefolder
                : API.moveNotefile,
              folder?.type === 'folder'
                ? {
                    ...formData,
                    workspaceId: workspaceId,
                    folderId: folder?.id || null,
                    parentId: selectedFolder,
                  }
                : {
                    ...formData,
                    workspaceId: workspaceId,
                    fileId: folder?.id || null,
                    parentId: selectedFolder,
                  }
            )
              .then((resp) => {
                onClose(resp.data.response.dataset[0]);
                toast.success('Move Successful', toastConfig);
              })
              .catch(console.log)
              .finally(() => {
                dispatch(endLoading());
              });
          } catch (error) {
            console.log(error);
          }
        } else if (selectedFolder === '') {
          toast.error('Please select a folder to move to', toastConfig);
        } else if (selectedFolder === 'root') {
          toast.error('Folder already exists in this location', toastConfig);
        }
      },
    }));

    useEffect(() => {
      setAllNotefolderDetails(updateDialogData);
      const showFolders = showSubFolders;
      updateDialogData.map((uD) => {
        showFolders[uD.id] = false;
      });
      setShowSubFolders(showFolders);
    }, []);

    const updateChildren = (allData, parentId, children) => {
      return allData.map((aD) => {
        if (aD.id === parentId) {
          const showFolders = showSubFolders;
          children
            .filter((data) => data.type !== 'file')
            .map((child) => {
              showFolders[child.id] = false;
            });
          setShowSubFolders(showFolders);
          return {
            ...aD,
            children: children.filter((data) => data.type !== 'file'),
          };
        } else if (aD?.children) {
          return {
            ...aD,
            children: updateChildren(aD?.children, parentId, children),
          };
        } else {
          return aD;
        }
      });
    };

    const handleFolderClick = (event, data) => {
      if (actualParentId === data) {
        setSelectedFolder('root');
      } else {
        setSelectedFolder(data);
      }
      event.stopPropagation();
      const showFolders = showSubFolders;
      if (showFolders[data]) {
        showFolders[data] = false;
      } else {
        showFolders[data] = true;
      }
      setShowSubFolders(showFolders);
      dispatch(startLoading());
      try {
        if (!showFolders[data]) {
          dispatch(endLoading());
        }

        showFolders[data] &&
          Network.get(API.notebookFolderDetails, {
            folderId: data,
          })
            .then((resp) => {
              let newNoteFolder = resp?.data?.response?.dataset[1].child.filter(
                (data) => data?.id != folder?.id
              );
              let noteFolderDetails = updateChildren(
                allNotefolderDetails,
                resp?.data?.response?.dataset[0].parent[0]?.id,
                // resp?.data?.response?.dataset[1].child
                newNoteFolder
              );
              setAllNotefolderDetails(noteFolderDetails);
            })
            .catch(console.log)
            .finally(() => {
              dispatch(endLoading());
            });
      } catch (error) {
        console.log(error);
      }
    };

    const dropdownComponent = (nFD) => {
      return (
        <Dropdown.Menu align="start" key={nFD?.id}>
          {nFD?.children === undefined || nFD?.children.length > 0 ? (
            <Dropdown
              autoClose={false}
              onClick={(e) => handleFolderClick(e, nFD?.id)}
              key={nFD?.id}
              className={'dropdown__movefolder'}
            >
              <Dropdown.Toggle
                className={
                  selectedFolder === nFD?.id
                    ? 'px-3 py-2 bg-gray-600 mb-0 rounded-0'
                    : ''
                }
              >
                <svg
                  width="148"
                  height="105"
                  viewBox="0 0 148 105"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M134.4 63.2253H13.6985V4.50243C13.6985 2.01484 15.7133 0 18.2009 0H53.7419C55.2812 0 56.7164 0.787925 57.5409 2.088L64.0694 12.3592H129.9C132.388 12.3592 134.403 14.374 134.403 16.8616V63.2253H134.4Z"
                    fill={nFD?.color}
                    className="opacity"
                  />
                  <path
                    d="M128.077 74.7206H19.1577V23.4689C19.1577 20.9813 21.1726 18.9664 23.6601 18.9664H123.575C126.062 18.9664 128.077 20.9813 128.077 23.4689V74.7206Z"
                    fill="white"
                  />
                  <path
                    d="M147.916 34.9642L135.011 101.254C134.6 103.37 132.745 104.895 130.59 104.895H17.4073C15.2518 104.895 13.4002 103.367 12.9865 101.254L0.0842294 34.9642C-0.456062 32.1839 1.67133 29.6007 4.50505 29.6007H143.498C146.329 29.6007 148.456 32.1839 147.916 34.9642Z"
                    fill={nFD?.color}
                  />
                </svg>

                <h6 className="weight-700 mb-0">{nFD?.folder_name}</h6>
              </Dropdown.Toggle>
              {nFD?.children?.length && showSubFolders[nFD?.id] ? (
                <>
                  {nFD?.children?.map((nFDC) => {
                    return dropdownComponent(nFDC);
                  })}
                </>
              ) : null}
            </Dropdown>
          ) : (
            <Dropdown
              autoClose={false}
              onClick={(e) => handleFolderClick(e, nFD?.id)}
              key={nFD?.id}
              className={
                selectedFolder === nFD?.id
                  ? 'dropdown__movefolder last bg-gray-600'
                  : 'last-parent'
              }
            >
              <Dropdown.Toggle
                className={
                  selectedFolder === nFD?.id
                    ? 'py-2 bg-gray-600 mb-0 rounded-0'
                    : ''
                }
              >
                <svg
                  width="148"
                  height="105"
                  viewBox="0 0 148 105"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M134.4 63.2253H13.6985V4.50243C13.6985 2.01484 15.7133 0 18.2009 0H53.7419C55.2812 0 56.7164 0.787925 57.5409 2.088L64.0694 12.3592H129.9C132.388 12.3592 134.403 14.374 134.403 16.8616V63.2253H134.4Z"
                    fill={nFD?.color}
                    className="opacity"
                  />
                  <path
                    d="M128.077 74.7206H19.1577V23.4689C19.1577 20.9813 21.1726 18.9664 23.6601 18.9664H123.575C126.062 18.9664 128.077 20.9813 128.077 23.4689V74.7206Z"
                    fill="white"
                  />
                  <path
                    d="M147.916 34.9642L135.011 101.254C134.6 103.37 132.745 104.895 130.59 104.895H17.4073C15.2518 104.895 13.4002 103.367 12.9865 101.254L0.0842294 34.9642C-0.456062 32.1839 1.67133 29.6007 4.50505 29.6007H143.498C146.329 29.6007 148.456 32.1839 147.916 34.9642Z"
                    fill={nFD?.color}
                  />
                </svg>

                <h6 className="weight-700 mb-0">{nFD?.folder_name}</h6>
              </Dropdown.Toggle>
            </Dropdown>
          )}
        </Dropdown.Menu>
      );
    };

    return (
      <>
        {allNotefolderDetails.map((nF) => {
          return (
            <Dropdown
              autoClose={false}
              key={key || nF?.id}
              onClick={(e) => handleFolderClick(e, nF?.id)}
              className={'dropdown__movefolder'}
            >
              <Dropdown.Toggle
                className={
                  selectedFolder === nF?.id
                    ? 'px-3 py-2 bg-gray-600 mb-0 rounded-0'
                    : ''
                }
              >
                <svg
                  width="148"
                  height="105"
                  viewBox="0 0 148 105"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M134.4 63.2253H13.6985V4.50243C13.6985 2.01484 15.7133 0 18.2009 0H53.7419C55.2812 0 56.7164 0.787925 57.5409 2.088L64.0694 12.3592H129.9C132.388 12.3592 134.403 14.374 134.403 16.8616V63.2253H134.4Z"
                    fill={nF?.color}
                    className="opacity"
                  />
                  <path
                    d="M128.077 74.7206H19.1577V23.4689C19.1577 20.9813 21.1726 18.9664 23.6601 18.9664H123.575C126.062 18.9664 128.077 20.9813 128.077 23.4689V74.7206Z"
                    fill="white"
                  />
                  <path
                    d="M147.916 34.9642L135.011 101.254C134.6 103.37 132.745 104.895 130.59 104.895H17.4073C15.2518 104.895 13.4002 103.367 12.9865 101.254L0.0842294 34.9642C-0.456062 32.1839 1.67133 29.6007 4.50505 29.6007H143.498C146.329 29.6007 148.456 32.1839 147.916 34.9642Z"
                    fill={nF?.color}
                  />
                </svg>

                <h6 className="weight-700 mb-0">{nF.folder_name}</h6>
              </Dropdown.Toggle>
              {nF && showSubFolders[nF?.id] ? (
                <>
                  {nF?.children?.map((nFD) => {
                    return dropdownComponent(nFD);
                  })}
                </>
              ) : null}
            </Dropdown>
          );
        })}
      </>
    );
  }
);

MoveFolderChildDialog.displayName = 'MyApp';

export default MoveFolderChildDialog;
