import React from 'react';
import { Modal } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import API from '../../../service/api';
import Network from '../../../service/Network';
import { inputStringLength, toastConfig } from 'assets/data/config';
import { useEffect } from 'react';
import StylishNewInput from 'components/DesignSystems/New/StylishNewInput';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { endLoading, startLoading } from "../../../../reducers/loading/loading.action";

export default function AddEditDepartmentTypeDialog({
  onClose,
  show,
  dialogType,
  updateDialogData,
}) {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const workspaceId = useSelector(
    (state) => state.prepare.workspace.selectedWorkspace
  );
  const dispatch = useDispatch();

  const onSubmit = async (data) => {
    dispatch(startLoading());
    try {
      const newDepartment = await Network.post(
        dialogType === 'Add'
          ? API.createPlanDepartmentType
          : API.updatePlanDepartmentType,
        {
          ...data,
          workspaceId: workspaceId,
          typeId: updateDialogData?.id,
        }
      );
      onClose(newDepartment.data.response.dataset[0]);
      toast.success(
        dialogType === 'Add'
          ? 'Jurisdiction/Service/Department type created successfully'
          : 'Jurisdiction/Service/Department type updated successfully',
        toastConfig
      );
    } catch (error) {
      toast.error(error.response.data.response.status.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };
  useEffect(() => {
    const listener = (event) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        event.preventDefault();
        handleSubmit(onSubmit)();
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, []);

  return (
    <Modal
      show={show}
      onHide={onClose}
      centered
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title>
          {dialogType} Jurisdiction/Service/Department Type
        </Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <div>
            <label className="form-label">Name</label>
            <Controller
              control={control}
              name="title"
              defaultValue={updateDialogData?.title}
              rules={{ required: true }}
              maxLength={inputStringLength}
              render={({ field: { onChange, value } }) => (
                <StylishNewInput
                  type="text"
                  className="form-control"
                  placeholder="Name"
                  onChange={onChange}
                  value={value}
                />
              )}
            />
            {errors?.title && (
              <span className="form-text form-error">
                *This is a required field{' '}
              </span>
            )}
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div className="button-group">
            <StylishNewButton
              className="button button--secondary button--reverse"
              onClick={onClose}
            >
              Close
            </StylishNewButton>
            <StylishNewButton className="button button--primary" type="submit">
              Save
            </StylishNewButton>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
