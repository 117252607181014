import React from 'react';
import GroupsContent from './GroupsContent';
import './AdminGroups.css';

import { AppHeader } from 'components/headers/appHeader/AppHeader';
import { useNewUIFlag } from '../../utils/featureFlags';
import OrganizationSettings from './OrganizationSettings';

export default function GroupsPage() {
  const uiToggle = useNewUIFlag();
  return uiToggle ? (
      <OrganizationSettings />
  ) : (
    <>
      <AppHeader context="Organization" />
      <div className="site-main">
        <div className="site-main__organization-configuration">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <GroupsContent />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
