import React from 'react'
import { useDrop } from 'react-dnd';
import MSELBoardItem from "./MSELBoardItem";

export default function MSELBoardContainer({ item, boardInjects, setBoardInjects, onViewInject }) {
    const [{}, drop] = useDrop({
        accept: 'Not Relevent For Now',
        drop: ()=> ({id: item.id, title: item.title}),
    });
  return (
        <div className="board-item" key={item.id} ref={drop}>
          <div className="board-item__title" style={{ background: item.color }}>
              <h5>{item.title}</h5>
              <span>{item.items.length}</span>
          </div>
          <div className="board-item__content">
              {
                  item.items.map(item => (
                      <MSELBoardItem key={item.id} item={item} boardInjects={boardInjects} setBoardInjects={setBoardInjects} onViewInject={() => onViewInject(item.id)}/>
                  ))
              }
          </div>
            
        </div>
  )
}
