import {
  inputStringLength,
  textareaStringLength,
  toastConfig,
} from 'assets/data/config';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import StylishNewInput from 'components/DesignSystems/New/StylishNewInput';
import StylishNewSelect from 'components/DesignSystems/New/StylishNewSelect';
import StylishDateTimePicker from 'components/DesignSystems/form/StylishDateTimePicker';
import { Controller, useForm } from 'react-hook-form';
import IconUpload from 'assets/images/icon__upload.svg';
import StylishNewTextArea from 'components/DesignSystems/New/StylishNewTextArea';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Network from 'PREPAREsrc/service/Network';
import { endLoading, startLoading } from 'reducers/loading/loading.action';
import API from 'PREPAREsrc/service/api';
import { toast } from 'react-toastify';
import { AllIcon } from 'assets/Icon/Icon';
import AddEditMissionProfileDialog from 'PREPAREsrc/pages/Settings/MissionProfile/AddEditMissionProfileDialog';
import { useNavigate, useParams } from 'react-router';
import { PreparePath } from 'utils/sharedUtils/sharedConst';
import { useSearchParams } from 'react-router-dom';
import moment from 'moment';

const status = ['Draft', 'Scheduled', 'In-progress', 'Complete', 'Incomplete'];

export const TimelineEventDetails = (props) => {
  const { setTimelineName } = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const [searchParams] = useSearchParams();
  const vignetteRef = useRef();
  const { selectedWorkspace, type: workspaceType } = useSelector(
    (state) => state?.prepare?.workspace
  );
  const eventId = useSelector((state) => state.prepare.planDashboard.eventId);
  const [showAddEditProfileModal, setShowAddEditProfileModal] = useState(false);
  const [allMissionProfiles, setAllMissionProfiles] = useState([]);
  const [allStatusLabel, setAllStatusLabel] = useState([]);
  const [allCellList, setAllCellList] = useState([]);
  const [allMethod, setAllMethod] = useState([]);
  const [allMissions, setAllMissions] = useState([]);
  const [previousData, setPreviousData] = useState();

  useEffect(() => {
    if (params.id) {
      getMissionTimelineEvent();
    }
  }, [params.id]);

  useEffect(() => {
    const missionId = searchParams.get('missionId');
    if (!params.id && !!missionId && !!allMissions.length) {
      const mission = allMissions.find((item) => item.value === missionId);
      if (mission) {
        setValue('mission_id', mission);
      }
    }
  }, [searchParams, allMissions]);

  const {
    control,
    formState: { errors },
    handleSubmit,
    watch,
    setValue,
    getValues,
    trigger,
  } = useForm({
    mode: 'onChange',
  });

  useEffect(() => {
    if (!!selectedWorkspace) {
      if (!!eventId) {
        getAllEventCell();
      }
      getAllProfile();
      allPlanStatusLabelList();
      getAllInjectMethod();
      fetchAllMissions();
    }
  }, [selectedWorkspace, eventId]);
  const getAllEventCell = async () => {
    dispatch(startLoading());
    try {
      const resp = await Network.get(API.getAllEventCell, {
        workspaceId: selectedWorkspace,
        planEventId: eventId,
      });

      setAllCellList(
        resp.data.response.dataset.map((item) => ({
          label: item.cell,
          value: item.id,
        }))
      );
      dispatch(endLoading());
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.response?.status?.msg, toastConfig);
      dispatch(endLoading());
    }
  };
  const getAllProfile = async () => {
    dispatch(startLoading());
    try {
      const resp = await Network.get(API.getAllMissionProfile, {
        workspaceId: selectedWorkspace,
      });

      const allProfiles = resp.data.response.dataset.map((item) => ({
        label: item.name,
        value: item.id,
      }));
      setAllMissionProfiles([
        { label: 'Add New', value: 'Add New' },
        ...allProfiles,
      ]);
      dispatch(endLoading());
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.response?.status?.msg, toastConfig);
    }
  };
  const allPlanStatusLabelList = async () => {
    dispatch(startLoading());
    try {
      const statusLabel = await Network.get(API.planStatusLabelList, {
        workspaceId: selectedWorkspace,
      });
      setAllStatusLabel(
        statusLabel.data.response.dataset
          .map((item) => ({
            label: item.title,
            value: item.id,
          }))
          .filter((item) => status.includes(item.label))
      );
    } catch (error) {
      toast.error(error.response.data.response.status.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };

  const getAllInjectMethod = async () => {
    dispatch(startLoading());
    try {
      const injectMethods = await Network.get(API.getAllInjectMethods, {
        workspaceId: selectedWorkspace,
      });
      setAllMethod([
        ...injectMethods.data.response.dataset.map((e) => ({
          value: e.id,
          title: e.name,
          label: (
            <div className="d-flex align-items-center" key={e.id}>
              <span
                className="status status--icon sml me-2"
                style={{ backgroundColor: e.color }}
              >
                {AllIcon.find((icon) => icon.name === e.icon)?.icon}
              </span>
              <span className="txt--sml">{e.name}</span>
            </div>
          ),
        })),
      ]);
    } catch (error) {
      toast.error(error?.response?.data?.response?.status?.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };

  const fetchAllMissions = async () => {
    try {
      dispatch(startLoading());
      const response = await Network.get(API.fetchAllMissions, {
        workspaceId: selectedWorkspace,
      });
      if (response?.status === 200 && response !== undefined) {
        const allMissions = response.data.response.dataset.map((item) => ({
          label: item.name,
          value: item.id,
        }));
        setAllMissions(allMissions);
        dispatch(endLoading());
      }
    } catch (error) {
      console.error(error);
      dispatch(endLoading());
    }
  };

  const getMissionTimelineEvent = async () => {
    try {
      dispatch(startLoading());
      const resp = await Network.get(API.getMissionTimelineById, {
        id: params.id,
        workspaceId: selectedWorkspace,
      });
      const data = resp.data.response.dataset[0];
      setValue('name', data.name);
      setTimelineName(data.name);
      setValue(
        'start_date',
        data.start_date
          ? moment(data?.start_date).isBefore(moment())
            ? moment()
            : data.start_date
          : moment()
      );
      setValue('mission_id', {
        label: data.mission_name,
        value: data.mission_id,
      });
      setValue('status', {
        label: data.status_title,
        value: data.status_id,
      });
      setValue('notification_method', {
        value: data.notification_method_id,
        title: data.notification_method_name,
        label: (
          <div className="d-flex align-items-center">
            <span
              className="status status--icon sml me-2"
              style={{ backgroundColor: data.notification_method_color }}
            >
              {
                AllIcon.find(
                  (icon) => icon.name === data.notification_method_icon
                )?.icon
              }
            </span>
            <span className="txt--sml">{data.notification_method_name}</span>
          </div>
        ),
      });
      setValue(
        'event_cell',
        data.cells.map((item) => ({ label: item.name, value: item.cell_id }))
      );
      setValue(
        'profile',
        data.profiles?.map((item) => ({
          label: item.name,
          value: item.profile_id,
        })) || []
      );
      setValue('remarks', data.remarks);
      if (!!data?.vignette) {
        setValue('vignette', {
          url: data.vignette,
          name: data.vignette.split('/').pop().split('-').pop(),
        });
      } else {
        setValue('vignette', '');
      }
      setPreviousData(data);
    } catch (error) {
      console.error(error);
    }
  };

  const dataCompare = () => {
    let previous_data = {};
    let current_data = {};
    const curr_data = getValues();
    Object.keys(previousData).forEach((key) => {
      switch (key) {
        case 'name':
          previous_data[key] = previousData[key];
          current_data[key] = curr_data[key];
          break;
        case 'start_date':
          previous_data[key] = moment(previousData[key]).format(
            'MM-DD-YYYY HH:mm a'
          );
          current_data[key] = moment(curr_data[key]).format(
            'MM-DD-YYYY HH:mm a'
          );
          break;
        case 'mission_name':
          previous_data[key] = previousData[key];
          current_data[key] = curr_data.mission_id.label;
          break;
        case 'status_title':
          previous_data[key] = previousData[key];
          current_data[key] = curr_data.status.label;
          break;
        case 'cells':
          previous_data[key] = previousData[key].map((item) => item.name);
          current_data[key] = curr_data.event_cell.map((item) => item.label);
          break;
        case 'profiles':
          previous_data[key] = previousData[key].map((item) => item.name);
          current_data[key] =
            curr_data.profile?.map((item) => item.label) || [];
          break;
        case 'remarks':
          previous_data[key] = previousData[key];
          current_data[key] = curr_data[key];
          break;
        case 'vignette':
          const file_name =
            (previousData[key] &&
              previousData[key].split('/').pop().split('-').pop()) ||
            '';
          previous_data[key] = file_name;
          current_data[key] = (curr_data[key] && curr_data[key].name) || null;
          break;
      }
    });
    Object.keys(previous_data).forEach((key) => {
      if (
        JSON.stringify(previous_data[key]) === JSON.stringify(current_data[key])
      ) {
        delete previous_data[key];
        delete current_data[key];
      }
    });
    return { previous_data, current_data };
  };

  const onFileChange = async (file) => {
    if (!file) return;
    const fileData = new FormData();
    fileData.append('file', file);
    try {
      dispatch(startLoading());
      const resp = await Network.post(API.singleFileUpload, fileData);
      setValue(
        'vignette',
        {
          url: resp.data.response.dataset[0].fileUrl,
          name: file.name,
        },
        { shouldValidate: true }
      );
      dispatch(endLoading());
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.response?.status?.msg, toastConfig);
      dispatch(endLoading());
    }
  };

  const onSubmit = async (formData, type) => {
    dispatch(startLoading());
    const data = {
      ...formData,
      mission_id: formData.mission_id.value,
      status: formData.status.value,
      notification_method: formData.notification_method.value,
      cell_id: formData.event_cell.map((item) => item.value),
      profile_id: formData.profile?.map((item) => item.value) || [],
      vignette: (formData?.vignette && formData?.vignette?.url) || null,
      workspaceId: selectedWorkspace,
    };
    try {
      let resp;
      if (!!previousData && !!previousData.id) {
        resp = await Network.post(API.updateMissionTimeline, {
          ...data,
          id: previousData.id,
          type: 'Update',
          ...dataCompare(),
        });
      } else {
        resp = await Network.post(API.addMissionTimeline, {
          ...data,
          type: 'Create',
        });
      }
      dispatch(endLoading());
      if (type === 'exit') {
        navigate(`${PreparePath}/dashboard/plan/missions/timeline`);
      } else {
        navigate(
          `${PreparePath}/dashboard/plan/missions/timeline/${resp.data.response.dataset[0]?.id}?page=2`
        );
      }
    } catch (error) {
      console.error(error);
      toast.error(error?.response?.data?.response?.status?.msg, toastConfig);
      dispatch(endLoading());
    }
  };

  const onSaveAndExitClick = async () => {
    const valid = await trigger();
    if (valid) {
      const data = getValues();
      onSubmit(data, 'exit');
    }
  };

  const onCloseAddEditProfileModal = (data) => {
    if (data && data.id) {
      const newProfile = {
        label: data.name,
        value: data.id,
      };
      setAllMissionProfiles([...allMissionProfiles, newProfile]);
      const allProfiles = getValues('profile');
      setValue(
        'profile',
        !!allProfiles && allProfiles?.length
          ? [...allProfiles, newProfile]
          : [newProfile],
        {
          shouldValidate: true,
        }
      );

      setShowAddEditProfileModal(false);
    } else {
      const allProfiles = getValues('profile');

      setValue(
        'profile',
        !!allProfiles && allProfiles?.length ? allProfiles : [],
        {
          shouldValidate: true,
        }
      );

      setShowAddEditProfileModal(false);
    }
  };

  const resetVignette = () => {
    if (!!watch('vignette') || !!getValues('vignette')) {
      vignetteRef.current.value = '';
      setValue('vignette', '');
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <h4>Timeline Event Details</h4>
        <div className="form-block">
          <div className="row">
            <div className="col-md-4 mb-3">
              <label className="form-label">
                Event Name
                <span aria-label="Required field" className="required">
                  *
                </span>
              </label>
              <Controller
                name="name"
                control={control}
                rules={{
                  required: true,
                  maxLength: inputStringLength,
                }}
                render={({ field: { onChange, value } }) => (
                  <StylishNewInput
                    type="text"
                    value={value}
                    onChange={(e) => {
                      setTimelineName(e?.target?.value);
                      onChange(e);
                    }}
                    placeholder="Event Name"
                  />
                )}
              />
              {(errors?.name?.type === 'required' && (
                <span className="form-text form-error">
                  This is a required field
                </span>
              )) ||
                null}
              {(errors?.name?.type === 'maxLength' && (
                <span className="form-text form-error">
                  Only {inputStringLength} characters allowed.
                </span>
              )) ||
                null}
            </div>
            <div className="col-md-8 mb-3">
              <Controller
                control={control}
                name="start_date"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <StylishDateTimePicker
                    value={value}
                    onChange={(e) => onChange(e)}
                    type="datetime-local"
                    dayPlaceholder="DD"
                    monthPlaceholder="MM"
                    yearPlaceholder="YYYY"
                    dateLabel={'Start Date *'}
                    timeLabel={'Start Time *'}
                  />
                )}
              />
              {(errors.start_date?.type === 'required' && (
                <span className="form-text form-error">
                  This fields (Date &amp; Time) is required
                </span>
              )) ||
                null}
            </div>
            <div className="col-md-4 mb-3">
              <label className="form-label">
                Mission Assigned to
                <span aria-label="Required field" className="required">
                  *
                </span>
              </label>
              <Controller
                name="mission_id"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, value } }) => (
                  <StylishNewSelect
                    options={allMissions}
                    value={value}
                    onChange={onChange}
                  />
                )}
              />
              {(errors?.mission_id?.type === 'required' && (
                <span className="form-text form-error">
                  This is a required field
                </span>
              )) ||
                null}
            </div>
            <div className="col-md-4 mb-3">
              <label className="form-label">
                Status
                <span aria-label="Required field" className="required">
                  *
                </span>
              </label>
              <Controller
                name="status"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, value } }) => (
                  <StylishNewSelect
                    options={allStatusLabel}
                    value={value}
                    onChange={onChange}
                    isSearchable={false}
                  />
                )}
              />
              {(errors?.status?.type === 'required' && (
                <span className="form-text form-error">
                  This is a required field
                </span>
              )) ||
                null}
            </div>
            <div className="col-md-4 mb-3">
              <label className="form-label">
                Event Cell(s)
                <span aria-label="Required field" className="required">
                  *
                </span>
              </label>
              <Controller
                name="event_cell"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, value } }) => (
                  <StylishNewSelect
                    options={allCellList}
                    value={value}
                    onChange={onChange}
                    isMulti
                    isSearchable={false}
                  />
                )}
              />
              {(errors?.event_cell?.type === 'required' && (
                <span className="form-text form-error">
                  This is a required field
                </span>
              )) ||
                null}
            </div>
            <div className="col-md-4 mb-3">
              <label className="form-label">Profile(s)</label>
              <Controller
                name="profile"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <StylishNewSelect
                    options={allMissionProfiles}
                    value={value}
                    onChange={(e) => {
                      const hasNew = e?.find(
                        (item) => item.value === 'Add New'
                      );

                      if (hasNew) {
                        setShowAddEditProfileModal(true);
                        return;
                      }
                      onChange(e);
                    }}
                    isMulti
                  />
                )}
              />
            </div>
            <div className="col-md-4 mb-3">
              <label className="form-label">
                Notification Method
                <span aria-label="Required field" className="required">
                  *
                </span>
              </label>
              <Controller
                name="notification_method"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, value } }) => (
                  <StylishNewSelect
                    options={allMethod}
                    value={value}
                    onChange={onChange}
                  />
                )}
              />
              {(errors?.notification_method?.type === 'required' && (
                <span className="form-text form-error">
                  This is a required field
                </span>
              )) ||
                null}
            </div>
            <div className="col-md-12 mb-3">
              <div className="mb-2 d-flex align-items-center">
                <label className="form-label m-0">Vignette (File Upload)</label>
                {(!!watch('vignette') && (
                  <StylishNewButton
                    onClick={resetVignette}
                    type="button"
                    className="button--link ms-3"
                  >
                    Clear
                  </StylishNewButton>
                )) ||
                  null}
              </div>
              <Controller
                name="vignette"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <div className="form-upload">
                    <div>
                      <span className="d-flex justify-content-center mb-2">
                        <img src={IconUpload} alt="" />
                      </span>
                      <span className="d-block">
                        {watch('vignette')?.name ||
                          'Drop Your Files Here or Click to Upload'}
                      </span>
                    </div>
                    <input
                      ref={vignetteRef}
                      type="file"
                      accept=".doc,.docx,.pdf"
                      onChange={(e) => onFileChange(e.target.files[0])}
                    />
                  </div>
                )}
              />
              {(errors?.vignette?.type === 'required' && (
                <span className="form-text form-error">
                  This is a required field
                </span>
              )) ||
                null}
            </div>
            <div className="col-md-12">
              <label className="form-label">
                Remarks and Support Requirements
              </label>
              <Controller
                name="remarks"
                control={control}
                rules={{
                  maxLength: textareaStringLength,
                }}
                render={({ field: { onChange, value } }) => (
                  <StylishNewTextArea value={value || ''} onChange={onChange} />
                )}
              />
              {(errors?.remarks?.type === 'required' && (
                <span className="form-text form-error">
                  This is a required field
                </span>
              )) ||
                null}
              {(errors?.remarks?.type === 'maxLength' && (
                <span className="form-text form-error">
                  Only {textareaStringLength} characters allowed.
                </span>
              )) ||
                null}
            </div>
          </div>
        </div>
        <div className="button-group d-block d-md-flex mt-4">
          <StylishNewButton
            onClick={() => {
              navigate(`${PreparePath}/dashboard/plan/missions/timeline`);
            }}
            type="button"
            className={
              'button--tertiary button--reverse ms-auto w-100 w-md-auto'
            }
          >
            Cancel
          </StylishNewButton>
          <StylishNewButton
            onClick={onSaveAndExitClick}
            type="button"
            className={
              'button--secondary button--reverse w-100 w-md-auto ms-0 ms-md-3 mt-2 mt-md-0'
            }
          >
            Save & Exit
          </StylishNewButton>
          <StylishNewButton
            type="submit"
            className={
              'button--primary w-100 w-md-auto ms-0 ms-md-3 mt-2 mt-md-0'
            }
          >
            Next
          </StylishNewButton>
        </div>
      </form>
      {showAddEditProfileModal && (
        <AddEditMissionProfileDialog
          dialogType={'Add'}
          show={showAddEditProfileModal}
          onClose={(data) => onCloseAddEditProfileModal(data)}
        />
      )}
    </>
  );
};
