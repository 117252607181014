import React, { useEffect, useState } from 'react';
import { Dropdown, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import IconEmptyExercise from 'assets/images/icon__exercise-details-empty.svg';
import API from '../../../service/api';
import Network from '../../../service/Network';
import moment from 'moment';
import { AllIcon } from 'PREPAREsrc/pages/Settings/InjectMethod/Icon';
import { PreparePath } from 'utils/sharedUtils/sharedConst';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import IconEdit from 'assets/images/icon__edit.svg';
import IconDelete from 'assets/images/icon__delete.svg';
import { StylishConfirmDialog } from 'components/DesignSystems/New/StylishConfirmDialog';
import CreatePostDialog from '../Posts/CreatePostDialog';
import { toast } from 'react-toastify';
import { toastConfig } from 'assets/data/config';

import IconCommentImage from 'assets/images/icon__image--post.svg';
import IconCommentLocation from 'assets/images/icon__location--post.svg';
import IconResponce from 'assets/images/icon__responce.svg';
import IconChat from 'assets/images/icon__chat--post.svg';
import { sanitizeHtml } from '../../../../utils/sanitizeHtml';
import { endLoading, startLoading } from "../../../../reducers/loading/loading.action";
import isRoleReadOnly from 'PREPAREsrc/utils/isRoleReadOnly'

export default function ViewPostDialog({
  onClose,
  show,
  postId,
  isNoiseGeneratedPost,
}) {
  const [postDetails, setPostDetails] = useState('');
  const [imageAvatar, setImageAvatar] = useState(null);
  const [isVisibleAvatar, setIsVisibleAvatar] = useState(false);
  const [filter, setFilter] = useState(null);
  const [commentsOnPost, setCommentsOnPost] = useState([]);
  const [deleteComment, setDeleteComment] = useState(false);
  const [selectedSiteId, setSelectedSiteId] = useState('');
  const [selectedPostid, setSelectedPostid] = useState('');
  const [isDeleted, setIsDeleted] = useState(false);
  const [showCreatePostDialog, setShowCreatePostDialog] = useState(false);
  // const [view, setView] = useState(false);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const reduxUser = useSelector((state) => state.prepare.user);
  const workspaceId = useSelector(
    (state) => state.prepare.workspace.selectedWorkspace
  );

  const onCreatePostDialog = (data) => {
    setShowCreatePostDialog(true);
    setSelectedSiteId(data?.siteId);
    setSelectedPostid(data?.mainpostid);
  };

  const onCreatePostDialogClose = () => {
    setShowCreatePostDialog(false);
  };
  const mediaPostDetailsByPostId = async () => {
    dispatch(startLoading());
    try {
      const response = await Network.get(API.mediaPostDetailsByPostId, {
        workspaceId: workspaceId,
        postId: postId,
        filter: filter,
      });
      setPostDetails(response?.data?.response?.dataset[0]);
      if (response?.data?.response?.dataset[0]?.mainpostmediafile != null) {
        setIsVisibleAvatar(true);
        setImageAvatar(response?.data?.response?.dataset[0]?.mainpostmediafile);
      }
      if (
        response?.data?.response?.parentData[0] !== undefined &&
        response?.data?.response?.parentData[0]?.comments?.length > 0
      ) {
        setCommentsOnPost(response?.data?.response?.parentData[0]?.comments);
      } else if (
        response?.data?.response?.parentData[0] === undefined &&
        response?.data?.response?.dataset[0]?.comments?.length > 0
      ) {
        setCommentsOnPost(response?.data?.response?.dataset[0]?.comments);
      } else {
        setCommentsOnPost(null);
      }
    } catch (error) {
      console.log('error', error);
    } finally {
      dispatch(endLoading());
    }
  };

  // const viewMediaPost = async () => {
  //   dispatch(startLoading());
  //   try {
  //     const response = await Network.post(API.viewMediaPost, {
  //       workspaceId: workspaceId,
  //       postId: postId,
  //     });
  //   } catch (error) {
  //     console.log('error', error);
  //   } finally {
  //     dispatch(endLoading());
  //   }
  // };
  // useEffect(() => {
  //   viewMediaPost();
  // }, [view]);

  useEffect(() => {
    setCommentsOnPost(null);
    mediaPostDetailsByPostId();
  }, [filter]);

  const onDeleteComment = (row) => {
    setDeleteComment(row?.id);
  };
  const onDeleteConfirmComment = async () => {
    dispatch(startLoading());
    try {
      await Network.post(API.deleteMediaPost, {
        postId: deleteComment,
      });
      setDeleteComment(null);
      setIsDeleted(true);
      await mediaPostDetailsByPostId();
      toast.success('Comment deleted successfully', toastConfig);
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(endLoading());
    }
  };

  const { selectedWorkspace, workspaceName } = useSelector((state) => {
    return state?.prepare?.workspace;
  });

  const viewPostHandler = async () => {
    dispatch(startLoading());
    try {
      await Network.post(API.viewMediaPost, {
        workspaceId: postDetails?.mainpostworkspaceid,
        postId: postDetails?.mainpostid,
      });
      sessionStorage.setItem('mediaModuleSocialMediaType', 'sites');
    } catch (error) {
      toast.error(error?.response?.data?.response?.status?.msg);
    } finally {
      dispatch(endLoading());
      onClose();
      navigate(
        `${PreparePath}/dashboard/media/sites/${
          workspaceName && workspaceName.charAt(0).toUpperCase()
        }/${postDetails?.siteSlug}/${postDetails?.profileusername}/${
          postDetails?.mainpostid
        }`
      );
    }
  };
  return (
    <Modal
      show={show}
      onHide={onClose}
      centered
      backdrop="static"
      keyboard={false}
      size="lg"
    >
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title>{postDetails?.siteName}</Modal.Title>
      </Modal.Header>
      <form>
        <Modal.Body>
          <div className="mb-4">
            <div className="d-flex flex-column flex-md-row mb-4">
              {postDetails?.profileavatar ? (
                <div className="profile-img flex-shrink-0">
                  <img src={postDetails?.profileavatar} alt="" />
                </div>
              ) : (
                <div className="profile-img flex-shrink-0"></div>
              )}

              <div className="flex-grow-1 ps-0 ps-md-3 pt-3 pt-md-0">
                <div className="d-flex align-items-start mb-2 flex-column flex-md-row align-items-md-center">
                  <h4 className="mb-0 flex-grow-1">
                    {postDetails?.profilename}
                  </h4>
                  {(postDetails?.comments?.length > 0 ||
                    postDetails?.mainpostlat ||
                    postDetails?.mainpostmediafile) && (
                    <div className="icon-group d-flex align-items-center mt-2 mt-md-0">
                      {postDetails?.comments?.length > 0 && (
                        <>
                          <div className="d-flex position-relative">
                            <img src={IconChat} alt="" />
                            <span className="status--bubble">
                              {postDetails?.comments?.length}
                            </span>
                          </div>
                        </>
                      )}
                      {postDetails?.mainpostlat && (
                        <>
                          <img src={IconCommentLocation} alt="" />
                        </>
                      )}

                      {postDetails?.mainpostmediafile && (
                        <>
                          <img src={IconCommentImage} alt="" />
                        </>
                      )}
                    </div>
                  )}
                </div>

                <div className="d-flex">
                  <p className="mb-0">
                    {'@'}
                    {postDetails?.profileusername}
                  </p>
                  <span className="mx-3">|</span>

                  <div className="d-flex align-items-center">
                    {postDetails?.siteIcon ? (
                      <div
                        className="me-2 has-img-h-20"
                        style={{
                          color: postDetails?.siteColor,
                        }}
                      >
                        {
                          AllIcon.find(
                            (icon) => icon.name === postDetails?.siteIcon
                          )?.icon
                        }
                      </div>
                    ) : null}
                    {postDetails?.site_image ? (
                      <div className="has-img-h-24 me-2">
                        <img
                          src={postDetails?.site_image}
                          alt=""
                          className="rounded-5 d-block"
                        />
                      </div>
                    ) : null}

                    {/* <img className="img-h-16 me-2" src={Fbicon} alt="" /> */}
                    <p className="mb-0 ">{postDetails?.siteName}</p>
                  </div>
                </div>

                <div className="d-flex flex-column flex-md-row mt-2">
                  {postDetails?.mainpoststatus === 'published' ? (
                    <p className="mb-0">
                      {moment(postDetails?.mainpostpublishtime)?.fromNow()} at{' '}
                      {moment(postDetails?.mainpostpublishtime)?.format(
                        'MMM DD, YYYY  HH:mm'
                      )}
                    </p>
                  ) : (
                    <p className="mb-0">
                      {moment(postDetails?.mainpostcreatedat)?.fromNow()} at{' '}
                      {moment(postDetails?.mainpostcreatedat)?.format(
                        'MMM DD, YYYY  HH:mm'
                      )}
                    </p>
                  )}

                  <p className="mb-0 ms-0 ms-md-auto">
                    {postDetails?.mainpoststatus?.charAt(0)?.toUpperCase() +
                      postDetails?.mainpoststatus?.slice(1)}
                  </p>
                </div>
              </div>
            </div>
            <p>{postDetails?.mainposttitle}</p>
            <p
              dangerouslySetInnerHTML={{
                __html: sanitizeHtml(postDetails?.mainpost),
              }}
            ></p>
            {isVisibleAvatar && (
              <>
                <div className="d-flex align-items-start mt-3">
                  <img
                    src={imageAvatar}
                    className="rounded-5"
                    style={{ height: 'auto', width: '100%' }}
                    alt=""
                  />
                </div>
              </>
            )}
          </div>

          <div className="d-flex justify-content-center align-items-center flex-wrap mb-3">
            <span
              onClick={() => setFilter(null)}
              className={`cursor-pointer ${
                filter === null ? 'color-brand' : ''
              }`}
              // className={`cursor-pointer ${'weight-700 text-decoration-underline'}`}
            >
              All Comments
            </span>
            <span className="separator--y bg-white flex-shrink-0 h--16 mx-2"></span>
            <span
              onClick={() => setFilter('published')}
              className={`cursor-pointer ${
                filter === 'published' ? 'color-brand' : ''
              }`}
            >
              Published
            </span>
            <span className="separator--y bg-white flex-shrink-0 h--16 mx-2"></span>
            <span
              onClick={() => setFilter('scheduled')}
              className={`cursor-pointer ${
                filter === 'scheduled' ? 'color-brand' : ''
              }`}
            >
              Scheduled
            </span>
            <span className="separator--y bg-white flex-shrink-0 h--16 mx-2"></span>
            <span
              onClick={() => setFilter('drafted')}
              className={`cursor-pointer ${
                filter === 'drafted' ? 'color-brand' : ''
              }`}
            >
              Drafted
            </span>{' '}
            {/* <span className="separator--y bg-white flex-shrink-0 h--16 mx-2"></span>
            <span
              onClick={() => setFilter('noise')}
              className={`cursor-pointer ${
                filter === 'noise' ? 'color-brand' : ''
              }`}
            >
              Noise
            </span>{' '} */}
          </div>
          {postDetails?.mainposttype === 'post' &&
          commentsOnPost?.length > 0 ? (
            <>
              {commentsOnPost?.length > 0 ? (
                <>
                  <hr className="dashed my-3" />
                  {commentsOnPost.map((comments) => {
                    const cleanComment = sanitizeHtml(comments?.comment);
                    return (
                      <div className="posts-row" key={comments?.id}>
                        <p className="d-flex align-items-center">
                          <div className="has-img-h-16 me-2">
                            <img src={IconResponce} alt="" />
                          </div>
                          In response to @{postDetails?.profileusername}'s post
                        </p>
                        <div className="d-flex">
                          <div
                            className="flex-grow-1"
                            onClick={(e) => {
                              e.preventDefault();
                              // onViewPostDialog(comments?.id);
                            }}
                          >
                            <div className="d-flex flex-column flex-md-row">
                              {comments?.profileAvatar ? (
                                <div className="profile-img flex-shrink-0">
                                  <img src={comments?.profileAvatar} alt="" />
                                </div>
                              ) : (
                                <div className="profile-img flex-shrink-0"></div>
                              )}

                              <div className="flex-grow-1 ps-0 ps-md-3 pt-3 pt-md-0">
                                <div className="d-flex align-items-start align-items-md-center mb-2 flex-column flex-md-row">
                                  <h4 className="mb-0 break-word flex-grow-1">
                                    {comments?.profileName}
                                  </h4>
                                  {(comments?.lat || comments?.commentImg) && (
                                    <div className="icon-group d-flex align-items-center mt-2">
                                      {(comments?.lat ||
                                        comments?.lng ||
                                        comments?.locationName) && (
                                        <>
                                          <img
                                            src={IconCommentLocation}
                                            alt=""
                                          />
                                        </>
                                      )}
                                      {comments?.commentImg && (
                                        <>
                                          <img src={IconCommentImage} alt="" />
                                        </>
                                      )}
                                    </div>
                                  )}
                                </div>

                                <div className="d-flex">
                                  <p className="mb-0 ">
                                    {'@'}
                                    {comments?.profileUsername}
                                    {/* {post?.username} */}
                                  </p>
                                  <span className="mx-3">|</span>
                                  <div className="d-flex align-items-center">
                                    {comments?.siteIcon ? (
                                      <div
                                        className="me-2 has-img-h-20"
                                        style={{
                                          color: comments?.siteColor,
                                        }}
                                      >
                                        {
                                          AllIcon.find(
                                            (icon) =>
                                              icon.name === comments?.siteIcon
                                          )?.icon
                                        }
                                      </div>
                                    ) : null}
                                    {comments?.site_image ? (
                                      <div className="has-img-h-24 me-2">
                                        <img
                                          src={comments?.site_image}
                                          alt=""
                                          className="rounded-5 d-block"
                                        />
                                      </div>
                                    ) : null}

                                    <p className="mb-0 ">
                                      {comments?.siteName}
                                      {/* {post?.siteName} */}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="ms-3 flex-shrink-0">
                            <Dropdown className="flex-shrink-0 ms-auto">
                              <Dropdown.Toggle className="button--icon">
                                <i
                                  className="fa fa-ellipsis-v"
                                  aria-hidden="true"
                                ></i>
                              </Dropdown.Toggle>
                              <Dropdown.Menu align="end">
                                <Dropdown.Item
                                  onClick={(e) => {
                                    e.preventDefault();
                                    navigate(
                                      `${PreparePath}/dashboard/media/posts/edit-post/${comments?.id}`
                                    );
                                  }}
                                >
                                  <img src={IconEdit} alt="" />
                                  Edit Comment
                                </Dropdown.Item>
                                <Dropdown.Item
                                  onClick={() => onDeleteComment(comments)}
                                >
                                  <img src={IconDelete} alt="" />
                                  Delete Comment
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </div>

                        <div className="row--status d-flex flex-column flex-md-row mt-2 ms-auto">
                          <p className="mb-0">
                            {moment(comments?.createdAt)?.fromNow()} at{' '}
                            {moment(comments?.createdAt)?.format(
                              'MM/DD/YYYY  HH:mm'
                            )}
                          </p>
                          <p className="mb-0 ms-0 ms-md-auto">
                            {comments?.status?.charAt(0).toUpperCase() +
                              comments?.status?.slice(1)}
                          </p>
                        </div>
                        {comments?.title && (
                          <p className="mb-0 mt-3 weight-500">
                            {comments?.title}
                          </p>
                        )}

                        {comments?.comment && (
                          <p
                            className="mb-0 mt-3"
                            dangerouslySetInnerHTML={{
                              __html: cleanComment,
                            }}
                          >
                            {/* {comments?.comment} */}
                          </p>
                        )}
                        <hr className="dashed my-3" />
                      </div>
                    );
                  })}
                </>
              ) : (
                <div className="bg-gray-800 text-center p-3 border-700 dashed wide-2px">
                  <div className="py-0 py-md-4">
                    <div className="d-flex justify-content-center mb-4">
                      <img src={IconEmptyExercise} alt="" />
                    </div>
                    <p className="weight-400 mb-0">No Comments Found</p>
                  </div>
                </div>
              )}
            </>
          ) : (
            <div className="bg-gray-800 text-center p-3 border-700 dashed wide-2px">
              <div className="py-0 py-md-4">
                <div className="d-flex justify-content-center mb-4">
                  <img src={IconEmptyExercise} alt="" />
                </div>
                <p className="weight-400 mb-0">No Comments Found</p>
              </div>
            </div>
          )}
        </Modal.Body>

        <Modal.Footer>
          <div className="button-group">
            <StylishNewButton
              className="button--secondary button--reverse"
              onClick={(e) => {
                e.preventDefault();
                onClose(isDeleted);

                // onClose
              }}
            >
              Cancel
            </StylishNewButton>
            {(isNoiseGeneratedPost === true || isRoleReadOnly(reduxUser.roles) ) ? null : (
              <StylishNewButton
                className="button--primary button--reverse"
                onClick={(e) => {
                  e.preventDefault();
                  navigate(
                    `${PreparePath}/dashboard/media/posts/edit-post/${postDetails?.mainpostid}`
                  );
                }}
              >
                Edit Post
              </StylishNewButton>
            )}
            {isNoiseGeneratedPost ===
            true ? null : postDetails?.mainpoststatus === 'published' ? (
              <StylishNewButton
                className="button--primary button--reverse"
                onClick={viewPostHandler}
                type="button"
              >
                View Post
              </StylishNewButton>
            ) : null}

            {isNoiseGeneratedPost === true ? null : postDetails?.mainposttype ==
                'post' &&
              postDetails?.mainpoststatus !== 'drafted' &&
              postDetails?.mainpoststatus !== 'scheduled' ? (
              <StylishNewButton
                className="button--primary button--reverse"
                onClick={(e) => {
                  e?.preventDefault();
                  onCreatePostDialog(postDetails);
                }}
              >
                Create Comment
              </StylishNewButton>
            ) : null}
          </div>
        </Modal.Footer>
      </form>
      {deleteComment && (
        <StylishConfirmDialog
          show={deleteComment}
          onClose={() => setDeleteComment(null)}
          dialogTitle={'Delete Comment'}
          dialogContent={'Are you sure you want this?'}
          onConfirm={onDeleteConfirmComment}
        />
      )}
      {showCreatePostDialog && (
        <CreatePostDialog
          show={showCreatePostDialog}
          onClose={onCreatePostDialogClose}
          selectedSiteId={selectedSiteId}
          selectedPostid={selectedPostid}
        />
      )}
    </Modal>
  );
}
