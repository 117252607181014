import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import React from 'react';
import { Modal } from 'react-bootstrap';
export default function ConfirmModal({
  show,
  heading,
  text,
  onClose,
  onConfirm,
  buttonDisable,
}) {
  return (
    <Modal
      show={show}
      onHide={onClose}
      centered
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title>{heading}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="m-0">{text}</p>
      </Modal.Body>
      <Modal.Footer>
        <div className="button-group">
          <StylishNewButton
            className="button--secondary button--reverse"
            onClick={onClose}
          >
            Cancel
          </StylishNewButton>
          <StylishNewButton
            disabled={buttonDisable ? true : false}
            className="button--primary"
            onClick={onConfirm}
          >
            Yes
          </StylishNewButton>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
