import { useEffect, useState } from 'react';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { AllIcon } from 'assets/Icon/Icon';
import StylishNewTable from 'components/DesignSystems/New/StylishNewTable';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { endLoading, startLoading } from 'reducers/loading/loading.action';
import Network from 'PREPAREsrc/service/Network';
import API from 'PREPAREsrc/service/api';
import { toast } from 'react-toastify';
import { PreparePath } from 'utils/sharedUtils/sharedConst';

export const Review = () => {
  const dispatch = useDispatch();

  const { id: system_id } = useParams();
  const navigate = useNavigate();

  const { selectedWorkspace: workspaceId } = useSelector(
    (state) => state?.prepare?.workspace
  );

  const [reviewData, setReviewData] = useState();

  const logisticsRequirementsColumns = [
    {
      dataField: 'type',
      text: 'Type',
      sort: false,
      attrs: { title: 'Type' },
    },
    {
      dataField: 'imc_spectrum',
      text: 'IMC Spectrum',
      sort: false,
      attrs: { title: 'IMC Spectrum' },
    },
    {
      dataField: 'name',
      text: 'Item Name',
      sort: false,
      attrs: { title: 'Item Name' },
    },
    {
      dataField: 'description',
      text: 'Description',
      sort: false,
      attrs: { title: 'Description' },
    },
    {
      dataField: 'purpose',
      text: 'Purpose',
      sort: false,
      attrs: { title: 'Purpose' },
    },
    {
      dataField: 'quantity',
      text: 'Quantity',
      sort: false,
      attrs: { title: 'Quantity' },
    },
  ];

  const logisticsRequirementsExpandRow = {
    parentClassName: 'parent-expand-tr',
    className: 'child-expand-tr',
    renderer: (row) => {
      return (
        <>
          <div className="row d-block d-md-flex">
            <div className="col">
              {(row?.power_output && (
                <p>
                  <strong className="weight-600 d-block">Power Output</strong>
                  {row?.power_output}
                </p>
              )) ||
                null}
            </div>
            <div className="col">
              {(row?.usage && (
                <p>
                  <strong className="weight-600 d-block">Usage</strong>
                  {row?.usage}
                </p>
              )) ||
                null}
            </div>
            <div className="col">
              {(row?.owner && (
                <p>
                  <strong className="weight-600 d-block">Owner</strong>
                  {row?.owner}
                </p>
              )) ||
                null}
            </div>
            <div className="col">
              {(row?.contact_info && (
                <p>
                  <strong className="weight-600 d-block">Contact Info</strong>
                  {row?.contact_info}
                </p>
              )) ||
                null}
            </div>
            <div className="col">
              {(row?.proposed_location && (
                <p>
                  <strong className="weight-600 d-block">
                    Proposed Location
                  </strong>
                  {row?.proposed_location}
                </p>
              )) ||
                null}
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              {(row?.frequencies && (
                <p className="mb-3 mb-md-0">
                  <strong className="weight-600 d-block">Frequencies</strong>
                  {row?.frequencies}
                </p>
              )) ||
                null}
            </div>
            <div className="col-md-6">
              {(row?.additional_notes && (
                <p className="m-0">
                  <strong className="weight-600 d-block">
                    Additional Notes
                  </strong>
                  {row?.additional_notes}
                </p>
              )) ||
                null}
            </div>
          </div>
        </>
      );
    },
    showExpandColumn: true,
    expandByColumnOnly: true,
    expandHeaderColumnRenderer: ({ isAnyExpands }) => {
      if (isAnyExpands) {
        return <i className="fa fa-chevron-down" aria-hidden="true"></i>;
      }
      return <i className="fa fa-chevron-right" aria-hidden="true"></i>;
    },
    expandColumnRenderer: ({ expanded }) => {
      if (expanded) {
        return (
          <>
            <div className="d-flex align-items-center justify-content-md-center">
              <span className="me-2">Details</span>
              <i className="fa fa-chevron-down" aria-hidden="true"></i>
            </div>
          </>
        );
      }
      return (
        <>
          <div className="d-flex align-items-center justify-content-md-center">
            <span className="me-2">Details</span>
            <i className="fa fa-chevron-right" aria-hidden="true"></i>
          </div>
        </>
      );
    },
  };

  const componentsColumns = [
    {
      dataField: 'name',
      text: 'Component Name',
      sort: false,
      attrs: { title: 'Component Name' },
    },
    {
      dataField: 'type',
      text: 'Component Type',
      sort: false,
      attrs: { title: 'Component Type' },
    },
  ];

  useEffect(() => {
    if (workspaceId && system_id) fetchSystemData();
  }, [workspaceId, system_id]);

  const fetchSystemData = async () => {
    dispatch(startLoading());
    try {
      const response = await Network.get(API.fetchReviewData, {
        workspaceId,
        system_id,
      });
      if (response?.status === 200 && response !== undefined) {
        setReviewData(response?.data?.response?.dataset[0]);
      }
    } catch (error) {
      toast.error(error?.response?.data?.response?.status?.msg);
      dispatch(endLoading());
    } finally {
      dispatch(endLoading());
    }
  };

  const onPrevious = () => {
    if (system_id) {
      navigate(`${PreparePath}/dashboard/plan/systems/${system_id}?page=3`);
    } else {
      navigate(`${PreparePath}/dashboard/plan/systems`);
    }
  };

  return (
    <>
      <div className="d-flex align-items-center mb-4">
        <span className="icon has-img-h-48">
          {AllIcon &&
            AllIcon?.find((icon) => icon?.name === reviewData?.icon)?.icon}
        </span>
        <div className="ms-3">
          <h4 className="m-0">{reviewData?.name || ''}</h4>
          <p className="m-0 weight-500">
            {reviewData?.event_cell_type === 'red_cell'
              ? 'Red Cell'
              : reviewData?.event_cell_type === 'blue_cell'
              ? 'Blue Cell'
              : null}
          </p>
        </div>
      </div>
      <div className="form-block">
        <div className="row">
          <div className="col-md-3">
            {(reviewData?.category && (
              <p>
                <strong className="weight-600 d-block">Category</strong>
                {reviewData?.category}
              </p>
            )) ||
              null}
            {(reviewData?.quantity && (
              <p>
                <strong className="weight-600 d-block">Quantity</strong>
                {reviewData?.quantity}
              </p>
            )) ||
              null}
            {(reviewData?.provider && (
              <p>
                <strong className="weight-600 d-block">Provider</strong>
                {reviewData?.provider}
              </p>
            )) ||
              null}
            {(reviewData?.command && (
              <p>
                <strong className="weight-600 d-block">Command</strong>
                {reviewData?.command}
              </p>
            )) ||
              null}
            {(reviewData?.service_branch && (
              <p>
                <strong className="weight-600 d-block">Service Branch</strong>
                {reviewData?.service_branch}
              </p>
            )) ||
              null}
            {(reviewData?.point_of_contacts &&
              !!reviewData?.point_of_contacts?.length && (
                <p>
                  <strong className="weight-600 d-block">Primary POC</strong>
                  {(reviewData?.point_of_contacts?.filter(
                    (poc) => poc?.is_primary
                  )?.length && (
                    <>
                      {reviewData?.point_of_contacts?.map((poc, idx) => (
                        <span key={idx} className="d-block">
                          {poc?.is_primary && poc?.name}
                        </span>
                      ))}
                    </>
                  )) || (
                    <span className="d-block">No Primary POC available</span>
                  )}
                </p>
              )) ||
              null}
            {(reviewData?.point_of_contacts &&
              !!reviewData?.point_of_contacts?.length && (
                <p>
                  <strong className="weight-600 d-block">Member(s)</strong>
                  {(reviewData?.point_of_contacts?.length && (
                    <>
                      {reviewData?.point_of_contacts?.map((poc, idx) => (
                        <span key={idx} className="d-block">
                          {poc?.name || poc?.email || ''}
                        </span>
                      ))}
                    </>
                  )) || <span className="d-block">No Member available</span>}
                </p>
              )) ||
              null}
          </div>
          <div className="col-md-9">
            {(reviewData?.capabilities_limitations && (
              <div className="mb-4">
                <h5 className="mb-2">Capabilities and Limitations</h5>
                <p>{reviewData?.capabilities_limitations}</p>
              </div>
            )) ||
              null}
            {(reviewData?.data_link_connections && (
              <div className="mb-4">
                <h5 className="mb-2">Data Link Connection(s)</h5>
                <p>{reviewData?.data_link_connections}</p>
              </div>
            )) ||
              null}
            <h5>Components</h5>
            <StylishNewTable
              keyField={'id'}
              columns={componentsColumns}
              rows={reviewData?.components || []}
              classes="no-filter"
              headerClasses="empty"
            />
          </div>
        </div>
        <h5 className="mt-4">Logistics Requirements</h5>
        <StylishNewTable
          keyField={'id'}
          columns={logisticsRequirementsColumns}
          rows={reviewData?.logistics_requirements || []}
          expandRow={logisticsRequirementsExpandRow}
          classes="no-filter"
          headerClasses="empty"
        />
      </div>
      <div className="button-group d-block d-md-flex mt-4">
        <StylishNewButton
          className="button--primary button--reverse w-100 w-md-auto"
          onClick={onPrevious}
        >
          Previous
        </StylishNewButton>
        <StylishNewButton
          className="button--primary w-100 w-md-auto ms-0 ms-md-auto mt-2 mt-md-0"
          onClick={() => navigate(`${PreparePath}/dashboard/plan/systems`)}
        >
          Complete
        </StylishNewButton>
      </div>
    </>
  );
};
