import StylishNewTable from 'components/DesignSystems/New/StylishNewTable';
export const TestObjectives = ({ test_objective }) => {
  const columns = [
    {
      dataField: 'coic',
      text: 'COIC',
      sort: false,
      attrs: { title: 'COIC' },
      formatter: (_, row) => `COIC ${row?.coic}`,
    },
    {
      dataField: 'coic_description',
      text: 'Description',
      sort: false,
      attrs: { title: 'Description' },
    },
  ];

  const expandRow = {
    parentClassName: 'parent-expand-tr',
    className: 'child-expand-tr',
    renderer: (row) => {
      return (
        <>
          {(row?.objective_association &&
            !!row?.objective_association?.length &&
            row?.objective_association?.map((obj, idx) => (
              <p
                className={`${
                  (idx === row?.objective_association?.length - 1 && 'm-0') ||
                  ''
                }`}
                key={obj?.item}
              >
                <strong className="weight-600 d-block mb-1">
                  {obj?.type} {obj?.item}
                </strong>
                {obj?.description || '-'}
              </p>
            ))) ||
            null}
        </>
      );
    },
    showExpandColumn: true,
    expandByColumnOnly: true,
    expandHeaderColumnRenderer: ({ isAnyExpands }) => {
      if (isAnyExpands) {
        return <i className="fa fa-chevron-down" aria-hidden="true"></i>;
      }
      return <i className="fa fa-chevron-right" aria-hidden="true"></i>;
    },
    expandColumnRenderer: ({ expanded }) => {
      if (expanded) {
        return (
          <>
            <div className="d-flex align-items-center justify-content-md-center">
              <span className="me-2">Details</span>
              <i className="fa fa-chevron-down" aria-hidden="true"></i>
            </div>
          </>
        );
      }
      return (
        <>
          <div className="d-flex align-items-center justify-content-md-center">
            <span className="me-2">Details</span>
            <i className="fa fa-chevron-right" aria-hidden="true"></i>
          </div>
        </>
      );
    },
  };

  return (
    <>
      <StylishNewTable
        keyField="coic_id"
        columns={columns}
        rows={test_objective || []}
        expandRow={expandRow}
        classes="no-filter"
        headerClasses="empty"
      />
    </>
  );
};
