import { useState } from 'react';
import { FullScreenModal } from 'components/DesignSystems/FullScreenModal/FullScreenModal';
import { PratusStepper } from 'components/DesignSystems/PratusStepper/PratusStepper';
import { SharedIcon } from 'components/SharedIcon/SharedIcon';
import { IconContainerWithButton } from 'components/DesignSystems/IconContainerWithButton/IconContainerWithButton';
import { ExistingTemplates } from './ExistingTemplates';
import { PratusBreadcrumb } from 'components/DesignSystems/PatusBreadcrumb/PratusBreadcrumb';
import { TemplateStep } from './TemplateStep';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { Breadcrumb, Step } from 'types/wizard';

interface Props {
  steps: Step[];
  breadcrumbs: Breadcrumb[];
  onModalClose: () => null;
  onSubmit: (template: any) => null;
}

export const TemplateWizard = ({
  onModalClose,
  onSubmit,
  steps,
  breadcrumbs,
}: Props) => {
  const [activeStep, setActiveStep] = useState(steps[0]);
  const [showHelperText, setShowHelperText] = useState(true);
  const [selectedTemplate, setSelectedTemplate] = useState<any>(null);

  const handleTemplateSelect = (template: any) => {
    if (template) setSelectedTemplate(template);
    const currentIndex = steps.findIndex(step => step.id === activeStep.id);
    setActiveStep(steps[currentIndex + 1]);
  };

  const handleSubmit = () => {
    onSubmit(selectedTemplate);
    onModalClose();
  };

  return (
    <FullScreenModal
      title="Provisions Teams Site"
      show
      onClose={() => onModalClose()}
    >
      <div className="d-flex flex-row teams-template-wizard-container">
        <div className="d-flex flex-column justify-content-between align-items-start left-content">
          <PratusStepper
            vertical
            activeStep={activeStep.id}
            steps={steps}
          />
          {showHelperText && (
            <div className="helper-text-container d-flex flex-column align-items-start">
              <span className="title d-flex align-items-center">
                <SharedIcon iconName="info" />
                <span className="title ms-1">Helper Text</span>
              </span>
              <span className="description">
                Create new or enhance existing Teams templates through PRATUS
                for improved insights and productivity.
              </span>
              <button
                onClick={() => setShowHelperText(false)}
                className="dismiss-btn"
              >
                Dismiss
              </button>
            </div>
          )}
        </div>

        <div className="right-content d-flex flex-column align-items-start justify-content-between">
          <div className="w-100">
            <div className="d-flex align-items-center justify-content-between">
              {steps.indexOf(activeStep) === 1 ? (
                <PratusBreadcrumb
                  steps={breadcrumbs}
                  onBack={() =>
                    setActiveStep(steps[steps.indexOf(activeStep) - 1])
                  }
                />
              ) : (
                <div className="text-uppercase step">{`Step ${
                  activeStep.id
                }/${steps.length}`}</div>
              )}

              <button className="button button--primary button--reverse border-0">
                <SharedIcon iconName="help" classes="me-2" />
                Need Help?
              </button>
            </div>

            <TemplateStep
              pageTitle={activeStep.pageTitle}
              pageSubTitle={activeStep.pageSubTitle}
            >
              <activeStep.component
                handleTemplateSelect={handleTemplateSelect}
                selectedTemplate={selectedTemplate}
              />
            </TemplateStep>
          </div>

          <div className="w-100 d-flex flex-row justify-content-between">
            <StylishNewButton
              className="button--secondary border-0"
              onClick={() => onModalClose()}
            >
              Cancel
            </StylishNewButton>
            {steps.indexOf(activeStep) === steps.length - 1 && (
              <StylishNewButton
                className="button--primary"
                onClick={handleSubmit}
              >
                Submit
              </StylishNewButton>
            )}
          </div>
        </div>
      </div>
    </FullScreenModal>
  );
};
