import React, { useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import Accordion from 'react-bootstrap/Accordion';
import TrainingObjectiveDetails from './Details/TrainingObjectiveDetails';
import IconTick from 'assets/images/icon__tick--blue.svg';
import TrainingObjectivePerformance from './Performance/TrainingObjectivePerformance';
import TrainingObjectiveAssociations from './Associations/TrainingObjectiveAssociations';
import TrainingObjectiveReview from './Review/TrainingObjectiveReview';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import API from '../../../../service/api';
import Network from '../../../../service/Network';
import { PreparePath } from 'utils/sharedUtils/sharedConst';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { toastConfig } from 'assets/data/config';
import { SharedIcon } from 'components/SharedIcon/SharedIcon';
import {
  endLoading,
  startLoading,
} from '../../../../../reducers/loading/loading.action';

export default function TrainingObjectiveStepForm() {
  const [step, setStep] = useState(0);
  const [objectives, setObjectives] = useState([]);
  const [allCapabilityTargets, setAllCapabilityTargets] = useState([]);

  const workspaceId = useSelector(
    (state) => state.prepare.workspace.selectedWorkspace
  );
  const workspaceType = useSelector((state) => state.prepare.workspace.type);
  const reduxUser = useSelector((state) => state.prepare.user);
  const exerciseId = useSelector(
    (state) => state.prepare.exercise.exerciseDetailsId
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const participant = JSON.parse(sessionStorage.getItem('participantDetails'));

  const locationData = useLocation();

  const nextPage = (stepCount) => {
    setStep(stepCount);
    setSteps(
      steps.map((step, index) => {
        if (index === stepCount) {
          return {
            ...step,
            isValid: true,
          };
        } else {
          return { ...step, isValid: false };
        }
      })
    );
  };
  const [steps, setSteps] = useState([
    {
      label: 'Details',
      isValid: true,
      component: (
        <TrainingObjectiveDetails
          onNextClick={nextPage}
          participantId={
            locationData?.state?.participantId || participant?.participantId
          }
          id={locationData?.state?.id || participant?.id}
          participantType={
            locationData?.state?.participantType || participant?.participantType
          }
          participantName={
            locationData?.state?.participantName || participant?.participantName
          }
        />
      ),
      className: 'step-item col-6 col-md-3 mb-3 mb-md-0',
    },
    {
      label: 'Performance',
      isValid: false,
      component: (
        <TrainingObjectivePerformance
          onNextClick={nextPage}
          onPrevClick={nextPage}
          participantId={
            locationData?.state?.participantId || participant?.participantId
          }
          id={locationData?.state?.id || participant?.id}
          participantType={
            locationData?.state?.participantType || participant?.participantType
          }
          participantName={
            locationData?.state?.participantName || participant?.participantName
          }
        />
      ),
      className: 'step-item col-6 col-md-3 mb-3 mb-md-0',
    },
    {
      label: 'Assocoations',
      isValid: false,
      component: (
        <TrainingObjectiveAssociations
          onNextClick={nextPage}
          onPrevClick={nextPage}
          participantId={
            locationData?.state?.participantId || participant?.participantId
          }
          id={locationData?.state?.id || participant?.id}
          participantType={
            locationData?.state?.participantType || participant?.participantType
          }
          participantName={
            locationData?.state?.participantName || participant?.participantName
          }
        />
      ),
      className: 'step-item col-6 col-md-3',
    },
    {
      label: 'Review',
      isValid: false,
      component: (
        <TrainingObjectiveReview
          onPrevClick={nextPage}
          participantId={
            locationData?.state?.participantId || participant?.participantId
          }
          id={locationData?.state?.id || participant?.id}
          workspaceType={workspaceType}
          participantType={
            locationData?.state?.participantType || participant?.participantType
          }
          participantName={
            locationData?.state?.participantName || participant?.participantName
          }
        />
      ),
      className: 'step-item col-6 col-md-3',
    },
  ]);

  const hseepSteps = steps.filter((step) => step.label !== 'Performance');

  const getAllObjectives = () => {
    dispatch(startLoading());
    try {
      Network.get(API.fetchAllObjectives, {
        workspaceId: workspaceId,
        planEventId: exerciseId,
      })
        .then((resp) => {
          setObjectives(resp.data.response.dataset);
        })
        .catch();
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(endLoading());
    }
  };

  const getOganizationalCapabilityTarget = async () => {
    dispatch(startLoading());
    try {
      const obj = await Network.get(API.fetchAllOganizationalCapabilityTarget, {
        workspaceId: workspaceId,
      });
      setAllCapabilityTargets(obj.data.response.dataset);
    } catch (error) {
      toast.error(error.response.data.response.status.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };

  const renderStepMarker = (index) => {
    return (
      <span className="step-marker">
        {step <= index ? index + 1 : <img src={IconTick} alt="" />}
      </span>
    );
  };

  useEffect(() => {
    getAllObjectives();
    getOganizationalCapabilityTarget();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reduxUser, step, exerciseId]);

  return (
    <>
      {locationData?.state?.participantType === 'DOD' ||
      participant?.participantType === 'DOD' ? (
        <div>
          {/* DOD Version */}
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <StylishNewButton onClick={() => navigate(-1)}>
                  <SharedIcon iconName="keyboard_arrow_left" classes="me-2" />
                  Back
                </StylishNewButton>
              </li>
              <li className="breadcrumb-item">
                <Link
                  to={`${PreparePath}/dashboard/plan/prepare/training-objective`}
                >
                  Objectives
                </Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                {locationData?.state?.participantName ||
                  participant?.participantName}
              </li>
            </ol>
          </nav>
          <div className="row">
            <div className="col-md-4 col-lg-3 mb-5 mb-md-0">
              {workspaceType === 'DOD' ? (
                <div className="bg-gray-800 rounded p-4">
                  <h6 className="text-uppercase">Exercise Details</h6>
                  <hr className="dashed my-3" />
                  <Accordion defaultActiveKey="0" flush>
                    {objectives.length > 0 &&
                      objectives.map((e, index) => (
                        <Accordion.Item eventKey={index} key={index}>
                          <Accordion.Header>
                            <span className="text-truncate">{e?.title}</span>
                          </Accordion.Header>
                          <Accordion.Body>
                            <div className="mb-3">
                              <strong className="mb-1 d-block weight-600">
                                Objective:
                              </strong>
                              {e?.exerciseobjective}
                            </div>
                            <div className="mb-3">
                              <strong className="mb-1 d-block weight-600">
                                Measure of Performance:
                              </strong>
                              {e?.measureperformane}
                            </div>
                            <div className="m-0">
                              <strong className="mb-1 d-block weight-600">
                                Measure of Effectiveness:
                              </strong>
                              {e?.measureeffectiveness}
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                      ))}
                  </Accordion>
                </div>
              ) : (
                <div className="bg-gray-800 rounded p-4">
                  <h6 className="text-uppercase">
                    Organizational Capability Targets
                  </h6>
                  <hr className="dashed my-3" />
                  <Accordion defaultActiveKey="0" flush>
                    {allCapabilityTargets.map((e, index) => (
                      <Accordion.Item eventKey={index} key={index}>
                        <Accordion.Header>
                          <span className="text-truncate">{e?.title}</span>
                        </Accordion.Header>
                        <Accordion.Body>
                          <div className="mb-3">
                            <strong className="mb-1 d-block weight-600">
                              Title:
                            </strong>
                            {e?.title}
                          </div>
                          <div className="mb-0">
                            <strong className="mb-1 d-block weight-600">
                              Description:
                            </strong>
                            {e?.description}
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    ))}
                  </Accordion>
                </div>
              )}
            </div>
            <div className="col-md-8 col-lg-9">
              <div className="steps mb-5">
                <div className="row">
                  <div
                    className={
                      steps[0].className +
                      (step === 0 ? ' step-current' : ' step-completed')
                    }
                  >
                    <span>
                      {renderStepMarker(0)}
                      <span className="step-title">Details</span>
                      <span className="step-divider"></span>
                    </span>
                  </div>
                  <div
                    className={
                      steps[1].className +
                      (step === 1
                        ? ' step-current'
                        : step > 1
                        ? ' step-completed'
                        : '')
                    }
                  >
                    <span>
                      {renderStepMarker(1)}
                      <span className="step-title">Performance</span>
                      <span className="step-divider"></span>
                    </span>
                  </div>
                  <div
                    className={
                      steps[2].className +
                      (step === 2
                        ? ' step-current'
                        : step > 2
                        ? ' step-completed'
                        : '')
                    }
                  >
                    <span>
                      {renderStepMarker(2)}
                      <span className="step-title">Associations</span>
                      <span className="step-divider"></span>
                    </span>
                  </div>
                  <div
                    className={
                      steps[3].className +
                      (step === 3
                        ? ' step-current'
                        : step > 3
                        ? ' step-completed'
                        : '')
                    }
                  >
                    <span className="step-last-content">
                      {renderStepMarker(3)}
                      <span className="step-title">Review</span>
                    </span>
                  </div>
                </div>
              </div>
              {steps[step].component}
            </div>
          </div>
          {/* DOD Version */}
        </div>
      ) : (
        <div>
          {/* HSEEP Version */}
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <StylishNewButton onClick={() => navigate(-1)}>
                  <SharedIcon iconName="keyboard_arrow_left" classes="me-2" />
                  Back
                </StylishNewButton>
              </li>
              <li className="breadcrumb-item">
                <Link
                  to={`${PreparePath}/dashboard/plan/prepare/training-objective`}
                >
                  Exercise Objectives
                </Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                {locationData?.state?.participantName ||
                  participant?.participantName}
              </li>
            </ol>
          </nav>
          <div className="row">
            <div className="col-md-4 col-lg-3 mb-5 mb-md-0">
              {workspaceType === 'DOD' ? (
                <div className="bg-gray-800 rounded p-4">
                  <h6 className="text-uppercase">Exercise Details</h6>
                  <hr className="dashed my-3" />
                  <Accordion defaultActiveKey="0" flush>
                    {objectives.length > 0 &&
                      objectives.map((e, index) => (
                        <Accordion.Item eventKey={index} key={index}>
                          <Accordion.Header>
                            <span className="text-truncate">{e?.title}</span>
                          </Accordion.Header>
                          <Accordion.Body>
                            <div className="mb-3">
                              <strong className="mb-1 d-block weight-600">
                                Objective:
                              </strong>
                              {e?.exerciseobjective}
                            </div>
                            <div className="mb-3">
                              <strong className="mb-1 d-block weight-600">
                                Measure of Performance:
                              </strong>
                              {e?.measureperformane}
                            </div>
                            <div className="m-0">
                              <strong className="mb-1 d-block weight-600">
                                Measure of Effectiveness:
                              </strong>
                              {e?.measureeffectiveness}
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                      ))}
                  </Accordion>
                </div>
              ) : (
                <div className="bg-gray-800 rounded p-4">
                  <h6 className="text-uppercase">
                    Organizational Capability Targets
                  </h6>
                  <hr className="dashed my-3" />
                  <Accordion defaultActiveKey="0" flush>
                    {allCapabilityTargets.map((e, index) => (
                      <Accordion.Item eventKey={index} key={index}>
                        <Accordion.Header>
                          <span className="text-truncate">{e?.title}</span>
                        </Accordion.Header>
                        <Accordion.Body>
                          <div className="mb-3">
                            <strong className="mb-1 d-block weight-600">
                              Title:
                            </strong>
                            {e?.title}
                          </div>
                          <div className="mb-0">
                            <strong className="mb-1 d-block weight-600">
                              Description:
                            </strong>
                            {e?.description}
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    ))}
                  </Accordion>
                </div>
              )}
            </div>
            <div className="col-md-8 col-lg-9">
              <div className="steps mb-5">
                <div className="row">
                  <div
                    className={
                      hseepSteps[0].className +
                      (step === 0
                        ? ' step-current'
                        : step > 0
                        ? ' step-completed'
                        : '')
                    }
                  >
                    <span>
                      {renderStepMarker(0)}
                      <span className="step-title">Details</span>
                      <span className="step-divider"></span>
                    </span>
                  </div>
                  <div
                    className={
                      hseepSteps[1].className +
                      (step === 1
                        ? ' step-current'
                        : step > 1
                        ? ' step-completed'
                        : '')
                    }
                  >
                    <span>
                      {renderStepMarker(1)}
                      <span className="step-title">Associations</span>
                      <span className="step-divider"></span>
                    </span>
                  </div>
                  <div
                    className={
                      hseepSteps[2].className +
                      (step === 2
                        ? ' step-current'
                        : step > 2
                        ? ' step-completed'
                        : '')
                    }
                  >
                    <span className="step-last-content">
                      {renderStepMarker(2)}
                      <span className="step-title">Review</span>
                    </span>
                  </div>
                </div>
              </div>
              {hseepSteps[step].component}
            </div>
          </div>
          {/* HSEEP Version */}
        </div>
      )}
    </>
  );
}
