import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import IconEmpty from 'assets/images/icon__exercise-details-empty.svg';
import { useDispatch, useSelector } from 'react-redux';
import Network from '../../../../service/Network';
import API from '../../../../service/api';
import moment from 'moment';
import MSELTimelineItem from './MSELTimelineItem';
import {
  HelpMSELTimeline,
  MiscellaneousHelpLink,
  useSelfHostedKnowledgeBase,
} from 'assets/data/HelpContent';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import StylishHelp from 'components/DesignSystems/New/StylishHelp';
import IconRefresh from '../../../../../assets/images/icon__refresh.svg';
import StylishNewSelect from 'components/DesignSystems/New/StylishNewSelect';
import StylishDateTimePicker from 'components/DesignSystems/form/StylishDateTimePicker';
import {
  endLoading,
  startLoading,
} from '../../../../../reducers/loading/loading.action';
import PageTitle from 'components/headers/PageTitle';
import { useNewUIFlag } from 'utils/featureFlags';
import { PreparePath } from 'utils/sharedUtils/sharedConst';
import { SharedIcon } from 'components/SharedIcon/SharedIcon';

export const MSELTimeline = () => {
  const [startDateKey, setStartDateKey] = useState(false);
  const [endDateKey, setEndDateKey] = useState(false);
  const [boards, setBoards] = useState([]);
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState();
  const [selectedBoard, setSelectedBoard] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [injectCount, setInjectCount] = useState(0);
  const [page, setPage] = useState(1);
  const [timelines, setTimelines] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [totalMselInjects, setTotalMselInjects] = useState(false);

  const selfHostedKnowledgeBase = useSelfHostedKnowledgeBase()

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const newUI = useNewUIFlag();

  const observer = useRef();

  const { control, setValue } = useForm();

  const reduxUser = useSelector((state) => state.prepare.user);

  const lastRowRef = useCallback(
    (node) => {
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPage((prevPageNumber) => prevPageNumber + 1);
        }
      });

      if (node) observer.current.observe(node);
    },
    [hasMore]
  );

  const { selectedWorkspace } = useSelector((state) => state.prepare.workspace);
  const workspaceType = useSelector((state) => state.prepare.workspace.type);

  useEffect(() => {
    if (!!workspaceType && workspaceType === 'T&E') {
      navigate(`${PreparePath}/dashboard/plan`);
    }
  }, [workspaceType]);

  const getAllStatusBoard = async () => {
    try {
      const statusBoards = await Network.get(API.planStatusBoardList, {
        workspaceId: selectedWorkspace,
      });

      const boardListData = statusBoards.data.response.dataset.map((e) => {
        return {
          value: e.boardId,
          label: e.title,
        };
      });
      setSelectedBoard(
        boardListData[Math.floor(Math.random() * boardListData.length)]
      );
      setBoards(boardListData);
    } catch (error) {
      dispatch(endLoading());
    }
  };

  const getAllEvents = async () => {
    dispatch(startLoading());
    try {
      const events = await Network.get(API.fetchEvents, {
        workspaceId: selectedWorkspace,
        sort: 'name',
      });
      setEvents([
        { value: 'all', label: 'All Scenarios' },
        ...events.data.response.dataset.map((e) => {
          return {
            value: e.id,
            label: e.name,
          };
        }),
      ]);
      setSelectedEvent({ value: 'all', label: 'All Scenarios' });
    } catch (error) {
      dispatch(endLoading());
    }
  };

  const getAllTimeLine = async () => {
    dispatch(startLoading());

    try {
      const allTimeLine = await Network.get(API.getAllMSELTimeLine, {
        workspaceId: selectedWorkspace,
        boardId: selectedBoard.value,
        eventId: selectedEvent.value,
        startDate:
          !!startDate && startDate !== ''
            ? startDate
            : // moment(startDate)
              // .format(
              //     reduxUser?.timeFormat === "hour12Mode"
              //       ? "YYYY-MM-DD - hh:mm a"
              //       : "YYYY-MM-DD - HH:mm"
              //   ) //.toISOString()
              '',
        endDate:
          !!endDate && endDate !== ''
            ? endDate
            : //  moment(endDate).format(
              //     reduxUser?.timeFormat === "hour12Mode"
              //       ? "YYYY-MM-DD - hh:mm a"
              //       : "YYYY-MM-DD - HH:mm"
              //   ) //.toISOString()
              '',
        page,
      });
      setTotalMselInjects(allTimeLine.data.response.total);
      // const data = allTimeLine.data.response.dataset;
      // const groups = data.map((group) => {
      //   return {
      //     date: group.startDate.split('T')[0],
      //     timelines: [group],
      //   };
      // });
      const data = allTimeLine.data.response.dataset;
      const groups = data.reduce((groups, timeline) => {
        const date = timeline.startDate.split('T')[0];
        if (!groups[date]) {
          groups[date] = [];
        }
        groups[date].push(timeline);
        return groups;
      }, {});
      const groupArrays = Object.keys(groups).map((date) => {
        return {
          date,
          timelines: groups[date],
        };
      });
      allTimeLine.data.response.dataset?.length && setTimelines(groupArrays);
      setInjectCount(Number(allTimeLine.data.response.total));
      setHasMore(10 * page < Number(allTimeLine.data.response.total));
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(endLoading());
    }
  };

  useEffect(() => {
    if (selectedWorkspace) {
      setPage(1);
      setSelectedBoard();
      setSelectedEvent();
      dispatch(startLoading());
      getAllStatusBoard();
      getAllEvents();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedWorkspace]);

  const handleResetButtonClick = () => {
    if (startDate != null || endDate != null) {
      setStartDate(null);
      setValue('startDate', null);
      setEndDate(null);
      setStartDateKey((k) => !k);
      setEndDateKey((k) => !k);
      setValue('endDate', null);
    }
    // setStartDate(null);
    // setValue('startDate', null);
    // setEndDate(null);
    // setValue('endDate', null);
  };

  useEffect(() => {
    if (!!selectedBoard && !!selectedEvent) {
      getAllTimeLine();
    }
  }, [selectedBoard, selectedEvent, startDate, endDate, page]);

  return (
    <>
      {newUI ? (
        <PageTitle
          title="MSEL Timeline"
          parents={['Exercise Management', 'Conduct']}
        >
          <StylishHelp
            classes={'ms-auto flex-shrink-0 sml'}
            title={HelpMSELTimeline.title}
            content={HelpMSELTimeline.content}
            link={selfHostedKnowledgeBase || HelpMSELTimeline.link}
          />
        </PageTitle>
      ) : null}
      <div className="d-flex align-items-start flex-column mb-4">
        {!newUI && (
          <>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb m-0">
                <li className="breadcrumb-item">
                  <StylishNewButton onClick={() => navigate(-1)}>
                    <SharedIcon iconName="keyboard_arrow_left" classes="me-2" />
                    Back
                  </StylishNewButton>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  MSEL Timeline
                </li>
              </ol>
            </nav>
            <StylishHelp
              classes={'ms-auto flex-shrink-0 sml'}
              title={HelpMSELTimeline.title}
              content={HelpMSELTimeline.content}
              link={selfHostedKnowledgeBase || HelpMSELTimeline.link}
            />
          </>
        )}
      </div>
      <div className="row d-lg-flex align-items-end mb-5">
        <div className="col-xl-7 d-md-flex align-items-end mb-0 mb-md-3 mb-xl-0 order-1 order-md-0">
          <div className="flex-grow-1">
            <label className="form-label">Start Date &amp; Time</label>

            <Controller
              control={control}
              name="startDate"
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <StylishDateTimePicker
                  // selected={startDate}
                  key={startDateKey}
                  timeFormat="HH:mm"
                  timeIntervals={20}
                  timeCaption="time"
                  selectsStart
                  selected={value}
                  value={value}
                  // dateLabel="Start Date"
                  // timeLabel="Start Time"
                  dateFormat={
                    reduxUser?.timeFormat === 'hour12Mode'
                      ? 'MMMM d, yyyy hh:mm a'
                      : 'MMMM d, yyyy HH:mm'
                  }
                  isClearable={true}
                  placeholderText={'Start Date'}
                  onChange={(date) => {
                    const newDate = moment(date).format();

                    setStartDate(newDate || null);
                    if (moment(newDate).isAfter(moment(endDate))) {
                      // setEndDate(new Date(moment(date).toISOString()));
                      setValue('endDate', newDate);
                      setEndDate(newDate);
                    }
                    if (!endDate) {
                      // setEndDate(new Date(moment(date).toISOString()));
                      setValue('endDate', newDate);
                      setEndDate(newDate);
                    }
                    return onChange(date);
                  }}
                  disableDateTimeLabel={true}
                  type="datetime-local"
                />
              )}
            />
          </div>
          <div className="flex-grow-1 ms-md-5 ps-md-5 mt-3 mt-md-0">
            <label className="form-label">End Date &amp; Time</label>

            <Controller
              control={control}
              name="endDate"
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <StylishDateTimePicker
                  // selected={endDate}
                  key={endDateKey}
                  selected={value || endDate}
                  selectsEnd
                  timeFormat="HH:mm"
                  timeIntervals={20}
                  timeCaption="time"
                  // dateLabel="End Date"
                  // timeLabel="End Time"
                  value={value}
                  minDate={
                    !!startDate
                      ? new Date(moment(startDate).toISOString())
                      : null
                  }
                  dateFormat={
                    reduxUser?.timeFormat === 'hour12Mode'
                      ? 'MMMM d, yyyy hh:mm a'
                      : 'MMMM d, yyyy HH:mm'
                  }
                  isClearable={true}
                  placeholderText={'End Date'}
                  disableDateTimeLabel={true}
                  type="datetime-local"
                  onChange={(date) => {
                    if (date) {
                      const newDate = moment(date).format();

                      setEndDate(newDate);
                    } else {
                      setEndDate(null);
                    }
                    return onChange(date);
                  }}
                />
              )}
            />
          </div>
          <StylishNewButton
            customButton
            type="button"
            className="button--icon primary lrg rounded ms-md-4 mt-3 mt-md-0 flex-shrink-0"
            onClick={handleResetButtonClick}
          >
            <img src={IconRefresh} alt="" />
          </StylishNewButton>
        </div>

        <div className="col-xl-4 d-md-flex align-items-center ms-auto mb-3 mb-md-0">
          <p className="m-0 me-3 mb-3 mb-md-0 flex-shrink-0">
            {totalMselInjects} MSEL Injects
          </p>
          <div className="flex-grow-1">
            <StylishNewSelect
              value={selectedEvent}
              onChange={(e) => setSelectedEvent(e)}
              options={events}
              isClearable={false}
              isSearchable={false}
              isMulti={false}
              isDisabled={false}
              placeholder="All Scenarios"
            />
          </div>
        </div>
      </div>
      {injectCount === 0 ? (
        <div className="bg-gray-800 text-center p-5">
          <div className="py-0 py-md-4">
            <div className="d-flex justify-content-center mb-4">
              <img src={IconEmpty} alt="" />
            </div>
            <p className="weight-600 m-0">
              A fresh start. Create the first entry.
              {/* eslint-disable-next-line */}
              <a href={selfHostedKnowledgeBase || MiscellaneousHelpLink.link} target="_blank">
                {' '}
                Need more help?
              </a>
            </p>
          </div>
        </div>
      ) : (
        <>
          <div className="train__msel-timeline container px-0 px-md-3">
            {!!timelines &&
              timelines.length > 0 &&
              timelines.map((item, index) => {
                return index + 1 == timelines.length ? (
                  <div key={item.date} ref={lastRowRef} className="row">
                    <MSELTimelineItem
                      item={item}
                      onStatusUpdate={getAllTimeLine}
                    />
                  </div>
                ) : (
                  <div key={item.date} className="row position-relative">
                    <MSELTimelineItem
                      item={item}
                      onStatusUpdate={getAllTimeLine}
                    />
                  </div>
                );
              })}
          </div>
        </>
      )}
    </>
  );
};
