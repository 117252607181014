import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppHeader } from '../headers/appHeader/AppHeader';
import { useLocation, Outlet, useNavigate } from 'react-router-dom';
import { fetchFormRecords } from 'actions/formActions';
import { FormBuilderDashboard } from './FormBuilderDashboard';
import FormCollectionInterface from './FormCollectionInterface';
import generateUUID from 'utils/sharedUtils/generateUUID';
import { useNewUIFlag } from '../../utils/featureFlags';

export default function FormBuilderPage() {
  const newUI = useNewUIFlag();
  const location = useLocation();
  const navigation = useNavigate();
  const match = location.pathname.split('/').slice(-1).join();
  const reduxDispatch = useDispatch();

  const [formCollection, setFormCollection] = useState(null);

  const reduxCurrentIncident = useSelector((state) => {
    return state.app.currentIncident;
  });

  const reduxCurrentlySelectedGroup = useSelector((state) => {
    return state.app.currentlySelectedGroup;
  });

  const reduxFeatureFlags = useSelector((state) => {
    return state.app.featureFlags;
  });

  useEffect(() => {
    // if we actually have a location state setup,
    // we can fetch if we don't have the records.
    // otherwise, we'll just fetch anyway.

    if (
      !!reduxCurrentIncident &&
      !!reduxCurrentlySelectedGroup &&
      !!reduxCurrentlySelectedGroup.group_guid
    ) {
      reduxDispatch(
        fetchFormRecords(
          reduxCurrentIncident.id,
          reduxCurrentlySelectedGroup.group_guid
        )
      );
    }
  }, [reduxCurrentIncident, reduxCurrentlySelectedGroup]);

  
  useEffect(() => {
    if (match === 'ICS_201-CG' && !!reduxCurrentIncident) {
      setFormCollection(initializeFormCollection('ICS 201'));
      navigation('/incident_management/ICS_201-CG', { replace: true });
    } else if (match === 'ICS_204-CG' && !!reduxCurrentIncident) {
      setFormCollection(initializeFormCollection('ICS 204'));
      navigation('/incident_management/ICS_204-CG', { replace: true });
    } else if (match === 'ICS_206-CG' && !!reduxCurrentIncident) {
      setFormCollection(initializeFormCollection('ICS 206'));
      navigation('/incident_management/ICS_206-CG', { replace: true });
    } else if (match === 'ICS_202-CG' && !!reduxCurrentIncident) {
      setFormCollection(initializeFormCollection('ICS 202'));
      navigation('/incident_management/ICS_202-CG', { replace: true });
    } else if (match === 'ICS_213-RR' && !!reduxCurrentIncident) {
      setFormCollection(initializeFormCollection('ICS 213 RR'));
      navigation('/incident_management/ICS_213-RR', { replace: true });
    } else if (match === 'ICS_205-CG' && !!reduxCurrentIncident) {
      setFormCollection(initializeFormCollection('ICS 205'));
      navigation('/incident_management/ICS_205-CG', { replace: true });
    } else if (match === 'ICS_205A-CG' && !!reduxCurrentIncident) {
      setFormCollection(initializeFormCollection('ICS 205A'));
      navigation('/incident_management/ICS_205A-CG', { replace: true });
    } else if (match === 'ICS_208-CG' && !!reduxCurrentIncident) {
      setFormCollection(initializeFormCollection('ICS 208'));
      navigation('/incident_management/ICS_208-CG', { replace: true });
    } else if (match === 'IAP_COVER_SHEET' && !!reduxCurrentIncident) {
      setFormCollection(initializeFormCollection('IAP COVER SHEET'));
      navigation('/incident_management/IAP_COVER_SHEET', { replace: true });
    } else {
      setFormCollection(null);
    }
  }, [reduxCurrentIncident, match]);

  const initializeFormCollection = (formname) => {
    if (!formCollection || formCollection?.formname !== formname) {
      const newCollectionId = generateUUID();
      return {
        collection_id: newCollectionId,
        formname: formname,
        incident_id: reduxCurrentIncident?.id,
        collection_form_records: [
          {
            collection_id: newCollectionId,
            formname: formname,
            incident_id: reduxCurrentIncident?.id,
            id: generateUUID(),
            record_data: {},
          },
        ],
      };
    } else {
      return formCollection;
    }
  };
  

  return (
    <>
      {!newUI ? <AppHeader context="Incident Management" /> : null}
      <div className="site-main">
        <div className="site-main__incident-management">
          <div className="container-fluid">
            <div className="row">
              {reduxCurrentIncident && !!reduxCurrentIncident.id ? (
                <div className="col-md-12">
                  {!formCollection ? (
                    <Outlet />
                  ) : (
                    <FormCollectionInterface formCollection={formCollection} />
                  )}
                </div>
              ) : (
                <div>You must select an incident to continue.</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
