import React, { Suspense, useEffect } from 'react';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import ExerciseLayout from '../routes/ExerciseLayout';
import Settings from './pages/Settings/settings';
import UserSettings from './pages/Settings/UserSettings/UserSettings';
import Members from './pages/Members/members';
import Jmets from './pages/Settings/JMETs/Jmets';
import Command from './pages/command/command';
import TimeZone from './pages/Settings/timezone/TimeZone';
import Application from './pages/Settings/Application/Application';
import Organization from './pages/Settings/Organization/Organization';
import Platform from './pages/Settings/Platform/Platform';
import TrainingPriority from './pages/Settings/TrainingPriority/trainingPriority';
import Service from './pages/Settings/Service/Service';
import DepartmentType from './pages/Settings/DepartmentType/DepartmentType';
import CommandAgency from './pages/Settings/CommandAgency/commandAgency';
import JSTP from './pages/Settings/JSTP/JSTP';
import ExerciseType from './pages/Settings/ExerciseType/ExerciseType';
import OrganizationType from './pages/Settings/OrganizationType/OrganizationType';
import AssessmentReason from './pages/Settings/AssessmentReasons/assessmentReason';
import InjectOwner from './pages/Settings/InjectOwner/InjectOwner';
import StatusLabel from './pages/Settings/StatusLabel/statusLabel';
import RegisterAccount from './pages/register-account/register-account';
import StatusBoard from './pages/Settings/StatusBoard/statusBoard';
import PageNotFound from './pages/page-not-found/pageNotFound';
import ComingSoon from './pages/coming-soon/comingSoon';
import InjectMethod from './pages/Settings/InjectMethod/InjectMethod';
import 'react-toastify/dist/ReactToastify.css';
import Plan from './pages/plan/plan';
import ExerciseDetails from './pages/plan/Prepare/ExerciseDetails/ExerciseDetails';
import { Participant } from './pages/plan/Prepare/Participant/Participant';
import { ParticipantDetail } from './pages/plan/Prepare/Participant/ParticipantDetail';
import ParticipantMain from './pages/plan/Prepare/Participant/ParticipantMain';
import PlanDashboard from './pages/plan/Dashboard/Dashboard';
import PrepareDashboard from './pages/plan/Prepare/Dashboard/Dashboard';
import Objectives from './pages/plan/Prepare/ExerciseDetails/Objectives/Objectives';
import FundingSources from './pages/plan/Prepare/ExerciseDetails/FundingSources/FundingSources';
import Tasks_Priorities from './pages/plan/Prepare/ExerciseDetails/Tasks&Priorites/Tasks&Priorites';
import ExerciseDetailsStepForm from './pages/plan/Prepare/ExerciseDetails/ExerciseDetailsStepForm';
import Review from './pages/plan/Prepare/ExerciseDetails/Review/Review';
import { ViewEvent } from './pages/plan/Prepare/Events/ViewEvent';
import { Calendar } from './pages/plan/Prepare/Events/Calendar';
import TrainingObjectiveDashboard from './pages/plan/Prepare/TrainingObjective/TrainingObjectiveDashboard';
import TrainingObjectiveStepForm from './pages/plan/Prepare/TrainingObjective/TrainingObjectiveStepForm';
import { Event } from './pages/plan/Prepare/Events/Event';
import { Timeline } from './pages/plan/Train/Timeline';
import { EditOrCreateEvent } from './pages/plan/Prepare/Events/EditOrCreateEvent';
import { CalendarMsel } from './pages/plan/Prepare/MSEL/CalendarMsel/CalendarMsel';
import { Dashboard as TrainDashboard } from './pages/plan/Train/Dashboard/Dashboard';
import { MSELList } from './pages/plan/Prepare/MSEL/MSELList';
import { MSELBoard } from './pages/plan/Prepare/MSEL/MSELBoard';
import { MSELView } from './pages/plan/Prepare/MSEL/MSELView';
import { MSELStepForm } from './pages/plan/Prepare/MSEL/MSELStepForm';
import { MSELTimeline } from './pages/plan/Train/MSELTimeline/MSELTimeline';
import { Report } from './pages/report/Report';
import { ReportDashboard } from './pages/report/ReportDashboard';
import { ReportDetails } from './pages/report/ReportDetails';

import EditFeedback from './pages/plan/Feedback/EditFeedback/EditFeedback';
import EditAssessment from './pages/plan/Assessment/EditAssessment/EditAssessment';
import { Analytics } from './pages/plan/Reports/Anaytics/Analytics';
import { ReportList } from './pages/plan/Reports/ReportList/ReportList';
import Notebook from './pages/notebook/notebook';
import NotebookDashboard from './pages/notebook/Dashboard/Dashboard';
import NotebookDetails from './pages/notebook/NotebookDetails/NotebookDetails';
import NotebookFolder from './pages/notebook/NotebookFolder/NotebookFolder';

import Media from './pages/Media/media';
import MediaDashboard from './pages/Media/Dashboard/Dashboard';

import Sites from './pages/Media/Sites/Sites';

import Networks from './pages/Media/Networks/Networks';
import ViewNetwork from './pages/Media/Networks/ViewNetwork';
import EditNetwork from './pages/Media/Networks/EditNetwork';

import Personas from './pages/Media/Personas/Personas';
import UseBlankPersona from './pages/Media/Personas/UseBlankPersona';
import ViewPersona from './pages/Media/Personas/ViewPersona';

import Posts from './pages/Media/Posts/Posts';
import CreatePost from './pages/Media/Posts/CreatePostPage';

import Profiles from './pages/Media/Profiles/Profiles';
import ViewPosts from './pages/Media/Profiles/ViewPosts';
import EditProfile from './pages/Media/Profiles/EditProfile';
import FromTo from './pages/Settings/FromTo/FromTo';
import Position from './pages/Settings/Position/Position';
import Metric from './pages/Settings/Metric/Metric';

import Noise from './pages/Media/Noise/Noise';
import PrimarySource from './pages/Settings/PrimarySource/PrimarySource';
import Subsource from './pages/Settings/SubSource/SubSource';

// Old PlanMeeting BackupRoute Start
import PlanMeetings from './pages/plan/Prepare/ExerciseDetails/PlanMeetings/BkPlanMeetings';

//Old PlanMeeting BackupRoute End
//Old Excercise Details Backup Route Start
import ExerciseMain from './pages/plan/Prepare/ExerciseDetails/ExcerciseMain';
//Old Excercise Details Backup Route End
import { PostsCalendar } from './pages/Media/Calendar/PostsCalendar';
import PostAnalytics from './pages/Media/PostAnalytics/PostAnalytics';
import SitesDetails from './pages/Media/Sites/SitesDetails';
import { SitesPostDetails } from './pages/Media/Sites/SitesPostDetails';
import { ViewAssessment } from './pages/plan/Assessment/View/ViewAssessment';
import { ViewFeedback } from './pages/plan/Feedback/ViewFeedback/ViewFeedback';
import { PreparePath } from 'utils/sharedUtils/sharedConst';
import Support from './pages/Settings/Support/Support';
import { TRR } from './pages/plan/TRR/TRRList';
import TechnicalDiscipline from './pages/Settings/TechnicalDiscipline/TechnicalDiscipline';
import { ExperimentEventDetails } from './pages/plan/ExperimentEventDetails/ExperimentEventDetails';
import { SystemsDashboard } from './pages/plan/Systems/Dashboard/Dashboard';
import { SystemsStepFormDashboard } from './pages/plan/Systems/StepForm/Dashboard';
import { SystemView } from './pages/plan/Systems/View/View';
import MissionLocation from './pages/Settings/MissionLocation/MissionLocation';
import MissionTypes from './pages/Settings/MissionTypes/MissionTypes';
import { MissionsDashboard } from './pages/plan/Missions/Dashboard/Dashboard';
import { CreateMission } from './pages/plan/Missions/Create/CreateMission';
import MissionProfile from './pages/Settings/MissionProfile/MissionProfile';
import { TimeLineStepFormDashboard } from './pages/plan/Missions/TimelineStepForm/Dashboard';
import { TimelineDashboard } from './pages/plan/Missions/TimelineDashboard/TimelineDashboard';
import { MissionTimeline } from './pages/plan/Missions/MissionTimeline/MissionTimeline';
import RouteError from '../components/Error/RouteError';
import { MissionTimelineView } from './pages/plan/Missions/MissionTimeline/MissionTimelineView';
import { MissionView } from './pages/plan/Missions/View/MissionView';

const FeatureFlag = React.lazy(() => import('./pages/FeatureFlag/FeatureFlag'));
const Overview = React.lazy(() =>
  import('./pages/plan/Assess/Overview/Overview')
);
const AssessmentList = React.lazy(() =>
  import('./pages/plan/Assessment/AssessmentList')
);
const Feedback = React.lazy(() => import('./pages/plan/Feedback/Feedback'));
// import { MSELCalendar } from "./pages/plan/Prepare/MSEL/MSELCalendar";
export default function PREPAREapp() {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname === `${PreparePath}/dashboard`) {
      navigate('/dashboard');
    }
  }, [location]);

  return (
    <>
      <Suspense>
        <Routes>
          <Route index element={<Navigate to="dashboard" />} />
          <Route path="register/:email/:id" element={<RegisterAccount />} />
          <Route path="page-not-found" element={<PageNotFound />} />
          <Route path="coming-soon" element={<ComingSoon />} />

          <Route
            path="dashboard"
            element={<ExerciseLayout />}
            errorElement={<RouteError />}
          >
            <Route path="plan" element={<Plan />}>
              <Route index element={<PlanDashboard />} />
              <Route
                path="prepare/exercise-details"
                element={<ExerciseMain />}
              />
              <Route
                path="prepare/all-details"
                element={<ExerciseDetailsStepForm />}
              />
              <Route path="prepare/exercise" element={<ExerciseDetails />} />
              <Route
                path="prepare/exercise-objectives"
                element={<Objectives />}
              />
              <Route
                path="prepare/planning-meeting"
                element={<PlanMeetings />}
              />
              <Route
                path="prepare/funding-sources"
                element={<FundingSources />}
              />
              <Route
                path="prepare/tasks-priorites"
                element={<Tasks_Priorities />}
              />
              <Route path="prepare/review" element={<Review />} />
              <Route
                path="prepare/participants"
                element={<Participant />}
              ></Route>
              <Route
                path="prepare/participants/:id"
                element={<ParticipantMain />}
              />
              <Route
                path="prepare/participants/details/:id"
                element={<ParticipantDetail />}
              />
              <Route path="prepare/dashboard" element={<PrepareDashboard />} />
              <Route path="prepare/events" element={<Event />}></Route>
              <Route
                path="prepare/events-calendar"
                element={<Calendar />}
              ></Route>
              <Route
                path="prepare/event/view/:id"
                element={<ViewEvent />}
              ></Route>
              <Route
                path="prepare/event/new"
                element={<EditOrCreateEvent />}
              ></Route>
              <Route
                path="prepare/event/edit/:id"
                element={<EditOrCreateEvent />}
              ></Route>
              <Route
                path="prepare/training-objective"
                element={<TrainingObjectiveDashboard />}
              ></Route>
              <Route
                path="prepare/training-objective/all-details"
                element={<TrainingObjectiveStepForm />}
              ></Route>
              <Route path="feedback" element={<Feedback />}></Route>
              <Route
                path="assessment/assess-list"
                element={<AssessmentList />}
              ></Route>
              <Route path="assessment/overview" element={<Overview />}></Route>
              <Route
                path="feedback/edit/:id"
                element={<EditFeedback />}
              ></Route>
              <Route
                path="assessment/edit/:id"
                element={<EditAssessment />}
              ></Route>
              <Route
                path="assessment/view/:assessmentId/:trainingObjectiveId"
                element={<ViewAssessment />}
              ></Route>
              <Route
                path="feedback/view/:feedbackId/:trainingObjectiveId"
                element={<ViewFeedback />}
              ></Route>
              <Route
                path="train/msel-timeline"
                element={<MSELTimeline />}
              ></Route>
              <Route
                path="prepare/train/dashboard"
                element={<TrainDashboard />}
              />
              <Route path="train/timeline" element={<Timeline />}></Route>
              <Route path="prepare/msel-list" element={<MSELList />} />
              <Route path="prepare/msel-board" element={<MSELBoard />} />
              <Route path="prepare/msel-calendar" element={<CalendarMsel />} />
              <Route path="prepare/msel-view/:id" element={<MSELView />} />
              <Route
                path="prepare/msel-all-details/:id"
                element={<MSELStepForm />}
              />
              <Route path="reports/:id" element={<Analytics />} />
              <Route path="reports/analytics" element={<Analytics />} />
              <Route path="reports/list" element={<ReportList />} />
              <Route path="trr" element={<TRR />} />
              <Route
                path="experimental-event-details"
                element={<ExperimentEventDetails />}
              />
              <Route path="systems" element={<SystemsDashboard />} />
              <Route
                path="systems/create"
                element={<SystemsStepFormDashboard />}
              />
              <Route
                path="systems/:id"
                element={<SystemsStepFormDashboard />}
              />
              <Route path="systems/view/:id" element={<SystemView />} />
              <Route path="missions" element={<MissionsDashboard />} />
              <Route path="create-mission" element={<CreateMission />} />
              <Route path="edit-mission/:id" element={<CreateMission />} />
              <Route path="missions/timeline" element={<TimelineDashboard />} />
              <Route
                path="missions/timeline/:id"
                element={<TimeLineStepFormDashboard />}
              />
              <Route
                path="missions/timeline/create-timeline"
                element={<TimeLineStepFormDashboard />}
              />
              <Route
                path="missions/mission-timeline"
                element={<MissionTimeline />}
              />
              <Route
                path="missions/timeline/view/:id"
                element={<MissionTimelineView />}
              />
              <Route path="view-mission/:id" element={<MissionView />} />
            </Route>
            <Route path="media" element={<Media />}>
              <Route index element={<MediaDashboard />} />
              <Route path="sites" element={<Sites />} />
              <Route path="sites/:workspace/:slug" element={<SitesDetails />} />
              <Route
                path="sites/:workspace/:slug/:profileUsername/:postId"
                element={<SitesPostDetails />}
              />
              <Route path="networks" element={<Networks />} />
              <Route path="view-network/:id" element={<ViewNetwork />} />
              <Route path="edit-network/:id" element={<EditNetwork />} />
              <Route path="personas" element={<Personas />} />
              <Route path="personas/:type" element={<UseBlankPersona />} />
              <Route
                path="edit-personas/:type/:id"
                element={<UseBlankPersona />}
              />

              <Route path="view-persona/:id" element={<ViewPersona />} />
              <Route path="posts" element={<Posts />} />
              <Route path="posts/edit-post/:id" element={<CreatePost />} />
              <Route path="profiles" element={<Profiles />} />

              {sessionStorage.getItem('mediaModuleSocialMediaType') ===
              'profiles' ? (
                <Route
                  path="profiles/:workspace/:slug/:profileUsername"
                  element={<SitesPostDetails />}
                />
              ) : (
                <Route
                  path="profiles/:site/:name/:id"
                  element={<ViewPosts />}
                />
              )}

              <Route
                path="profiles/:workspace/:slug/:profileUsername/:postId"
                element={<SitesPostDetails />}
              />

              <Route path="edit-profile/:id" element={<EditProfile />} />
              <Route path="noise" element={<Noise />} />
              <Route path="calendar" element={<PostsCalendar />} />
              <Route path="analytics" element={<PostAnalytics />} />
            </Route>
            <Route path="report" element={<Report />}>
              <Route index element={<ReportDashboard />} />
              <Route path="details/:id" element={<ReportDetails />} />
            </Route>
            <Route path="notebook/:page" element={<Notebook />}>
              <Route index element={<NotebookDashboard />} />
              <Route path="details/:id" element={<NotebookDetails />} />
              <Route path="folder/edit/:id" element={<NotebookFolder />} />
            </Route>
            <Route
              path="web"
              element={<Navigate to="/coming-soon" replace={true} />}
            />
            <Route
              path="observe"
              element={<Navigate to="/coming-soon" replace={true} />}
            />
            <Route
              path="map"
              element={<Navigate to="/coming-soon" replace={true} />}
            />
            <Route
              path="chat"
              element={<Navigate to="/coming-soon" replace={true} />}
            />
            <Route
              path="resources"
              element={<Navigate to="/coming-soon" replace={true} />}
            />
            <Route
              path="learn"
              element={<Navigate to="/coming-soon" replace={true} />}
            />
            <Route
              path="email"
              element={<Navigate to="/coming-soon" replace={true} />}
            />
            <Route path="prepare/msel-all-details" element={<MSELStepForm />} />
            <Route path="command" element={<Command />} />

            <Route path="settings" element={<Settings />}>
              <Route path="user-settings" element={<UserSettings />} />
              <Route path="members" element={<Members />} />
              <Route path="jmets" element={<Jmets />} />
              <Route path="time-zone" element={<TimeZone />} />
              <Route path="application" element={<Application />} />
              <Route path="organizations" element={<Organization />} />
              <Route path="platform" element={<Platform />} />
              <Route path="fromto" element={<FromTo />} />
              <Route path="position" element={<Position />} />
              <Route path="primary-source" element={<PrimarySource />} />
              <Route path="sub-source" element={<Subsource />} />
              <Route path="support" element={<Support />} />
              <Route
                path="technical-discipline"
                element={<TechnicalDiscipline />}
              />
              <Route path="mission-type" element={<MissionTypes />} />
              <Route path="mission-profile" element={<MissionProfile />} />
              <Route path="mission-location" element={<MissionLocation />} />

              <Route path="training-priority" element={<TrainingPriority />} />
              <Route path="service" element={<Service />} />
              <Route path="department-type" element={<DepartmentType />} />
              <Route path="command-agency" element={<CommandAgency />} />
              <Route path="strategic-priority" element={<JSTP />} />
              <Route path="metric" element={<Metric />} />

              <Route path="inject-method" element={<InjectMethod />} />
              <Route path="exercise-type" element={<ExerciseType />} />
              <Route path="status-label" element={<StatusLabel />} />
              <Route path="status-board" element={<StatusBoard />} />
              <Route path="organization-type" element={<OrganizationType />} />
              <Route path="assessment-reasons" element={<AssessmentReason />} />
              <Route path="inject-owner" element={<InjectOwner />} />
            </Route>
            <Route path="feature-flag" element={<FeatureFlag />} />
          </Route>
        </Routes>
      </Suspense>
    </>
  );
}
