import React from 'react';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import Network from '../../../service/Network';
import API from '../../../service/api';
import StylishNewSelect from 'components/DesignSystems/New/StylishNewSelect';
import { StylishNewSearchBar } from 'components/DesignSystems/New/StylishNewSearchBar';
import StylishDateTimePicker from 'components/DesignSystems/form/StylishDateTimePicker';
import { endLoading, startLoading } from "../../../../reducers/loading/loading.action";

export const Timeline = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [events, setEvents] = useState({ value: '', label: 'All Events' });
  const [eventList, setEventList] = useState([]);
  const [board, setBoard] = useState({ value: '', label: 'All Board' });
  const [boardsList, setBoardsList] = useState([]);

  const dispatch = useDispatch();

  const { selectedWorkspace } = useSelector((state) => state.prepare.workspace);
  const reduxUser = useSelector((state) => state.prepare.user);

  const {
    control,
    formState: { errors },
  } = useForm();

  const pageDropDown = [
    { value: 3, title: '3' },
    { value: 50, title: '50' },
    { value: 75, title: '75' },
    { value: 100, title: '100' },
  ];

  const fetchAllBoardInject = async () => {
    dispatch(startLoading());
    try {
      await Network.get(API.fetchAllBoardInject, {
        workspaceId: selectedWorkspace,
        boardId: board.value,
        eventId: events.value,
      });
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(endLoading());
    }
  };
  useEffect(() => {
    fetchAllBoardInject();
  }, [searchTerm, reduxUser]);

  const planStatusBoardList = async () => {
    dispatch(startLoading());
    try {
      const newStatusBoard = await Network.get(API.planStatusBoardList, {
        workspaceId: selectedWorkspace,
      });
      setBoardsList(
        { value: '', label: 'All Board' },

        ...newStatusBoard.data.response.dataset.map((e) => {
          return {
            value: e.boardId,
            label: e.title,
          };
        })
      );
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(endLoading());
    }
  };

  const fetchEvents = async () => {
    dispatch(startLoading());
    try {
      const events = await Network.get(API.fetchEvents, {
        workspaceId: selectedWorkspace,
      });
      setEventList([
        { value: '', label: 'All Events' },
        ...events.data.response.dataset.map((e) => {
          return {
            value: e.id,
            label: e.name,
          };
        }),
      ]);
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(endLoading());
    }
  };

  useEffect(() => {
    fetchEvents();
    planStatusBoardList();
  }, [searchTerm, reduxUser]);

  const onChangeSearchTerm = (termSearched) => {
    setSearchTerm(termSearched);
  };
  return (
    <div>
      <StylishNewSearchBar onChangeSearchTerm={onChangeSearchTerm} />

      <div className="form-select">
        <Controller
          control={control}
          name="eventList"
          rules={{ required: true }}
          render={({ field: { value } }) => (
            <StylishNewSelect
              options={eventList}
              value={value || events}
              onChange={(e) => {
                setEvents(e);
              }}
              isClearable={true}
              isSearchable={true}
              isMulti={false}
              isDisabled={false}
            />
          )}
        />
      </div>
      <div className="form-select">
        <Controller
          control={control}
          name="boardList"
          rules={{ required: true }}
          render={({ field: { value } }) => (
            <StylishNewSelect
              options={boardsList}
              value={value || board}
              isClearable={true}
              onChange={(e) => {
                setBoard(e);
              }}
              placeholder={'boardList'}
              isSearchable={true}
              isMulti={false}
              isDisabled={false}
            />
          )}
        />
      </div>
      <div className="form-select">
        <Controller
          control={control}
          name="pageDropDown"
          rules={{ required: true }}
          render={({ field: { value } }) => (
            <StylishNewSelect
              options={pageDropDown}
              value={value}
              // onChange={(e) => setPerPage(e.value)}
              isClearable={true}
              isSearchable={true}
              isMulti={false}
              isDisabled={false}
            />
          )}
        />
      </div>

      <div></div>

      <div className="col-md-6">
        <label className="form-label">Start Date</label>
        <Controller
          control={control}
          name="startexDate"
          rules={{ required: true }}
          render={({ field: { onChange, value } }) => (
            <StylishDateTimePicker
              onChange={onChange}
              selected={value}
              isClearable={true}
              // showTimeSelect
              type="datetime-local"
              timeFormat="HH:mm"
              timeIntervals={20}
              timeCaption="time"
              dateFormat={
                reduxUser?.timeFormat === 'hour12Mode'
                  ? 'MMMM d, yyyy hh:mm a'
                  : 'MMMM d, yyyy HH:mm'
              }
              placeholderText={'Choose Start Date'}
            />
          )}
        />
        {errors.startexDate && (
          <span className="form-text form-error">This is a required field</span>
        )}
      </div>
      <div className="col-md-6">
        <label className="form-label">End Date</label>
        <Controller
          control={control}
          name="EndexDate"
          rules={{ required: true }}
          render={({ field: { onChange, value } }) => (
            <StylishDateTimePicker
              onChange={onChange}
              selected={value}
              isClearable={true}
              // showTimeSelect
              type="datetime-local"
              timeFormat="HH:mm"
              timeIntervals={20}
              timeCaption="time"
              dateFormat={
                reduxUser?.timeFormat === 'hour12Mode'
                  ? 'MMMM d, yyyy hh:mm a'
                  : 'MMMM d, yyyy HH:mm'
              }
              placeholderText={'Choose End Date'}
            />
          )}
        />
        {errors.EndexDate && (
          <span className="form-text form-error">This is a required field</span>
        )}
      </div>
    </div>
  );
};
