import React from "react";
import { Outlet } from "react-router-dom";

export default function Notebook() {
  return (
    <div className="site-main__dashboard site-main__notebook">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
}
