import React from 'react';
import { Outlet } from 'react-router-dom'

export const Report = () => {
    return (
        <>
            <div className="site-main__dashboard">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <Outlet />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
