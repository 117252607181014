import config from 'constants/apiConfig';
import axios from 'axios';
import downloadFile from 'utils/downloadFile';
import { saveAs } from 'file-saver';
import { toast } from 'react-toastify';
import { noAutoClose } from 'assets/data/config';

import { endLoading, startLoading } from 'reducers/loading/loading.action';

import {
  FETCH_CHAT_DGPT_REQUEST,
  FETCH_CHAT_DGPT_SUCCESS,
  FETCH_CHAT_DGPT_ERROR,
  FETCH_CHAT_DGPT_ONGOING,
  SET_DGPT_SITREP_TO_INITIAL_INCIDENT_REPORT_SUMMARY,
  EXPORT_SITREP_TO_PDF_REQUEST,
  EXPORT_SITREP_TO_PDF_SUCCESS,
  EXPORT_SITREP_TO_PDF_ERROR,
  INIT_SITREP_REQUEST,
  INIT_SITREP_SUCCESS,
  INIT_SITREP_ERROR,
  FETCH_SITREPS_REQUEST,
  FETCH_SITREPS_ONGOING,
  FETCH_SITREPS_SUCCESS,
  FETCH_SITREPS_ERROR,
  REFRESH_SITREP_REQUEST,
  REFRESH_SITREP_SUCCESS,
  REFRESH_SITREP_ERROR,
  UPDATE_SITREP_SECTION_REQUEST,
  UPDATE_SITREP_SECTION_SUCCESS,
  UPDATE_SITREP_SECTION_ERROR,
  ADD_SITREP_SECTION_REQUEST,
  ADD_SITREP_SECTION_SUCCESS,
  ADD_SITREP_SECTION_ERROR,
  REMOVE_SITREP_SECTION_REQUEST,
  REMOVE_SITREP_SECTION_SUCCESS,
  REMOVE_SITREP_SECTION_ERROR,
  FETCH_GROUP_AI_USAGE_REQUEST,
  FETCH_GROUP_AI_USAGE_SUCCESS,
  FETCH_GROUP_AI_USAGE_ERROR,
  FETCH_ALL_GROUPS_AI_USAGE_REQUEST,
  FETCH_ALL_GROUPS_AI_USAGE_SUCCESS,
  FETCH_ALL_GROUPS_AI_USAGE_ERROR,
  FETCH_SOPS_REQUEST,
  FETCH_SOPS_SUCCESS,
  FETCH_SOPS_ERROR,
  UPLOAD_SOP_REQUEST,
  UPLOAD_SOP_SUCCESS,
  UPLOAD_SOP_ERROR,
  DELETE_SOP_REQUEST,
  DELETE_SOP_SUCCESS,
  DELETE_SOP_ERROR,
  INIT_PLANAI_SUGGESTION_REQUEST,
  INIT_PLANAI_SUGGESTION_SUCCESS,
  INIT_PLANAI_SUGGESTION_ERROR,
  FETCH_PLANAI_SUGGESTION_REQUEST,
  FETCH_PLANAI_SUGGESTION_SUCCESS,
  FETCH_PLANAI_SUGGESTION_ERROR,
} from 'constants/dgpt-action-types';

function fetchChatDGPTRequest() {
  return {
    type: FETCH_CHAT_DGPT_REQUEST,
  };
}

function fetchChatDGPTSuccess(session) {
  return {
    type: FETCH_CHAT_DGPT_SUCCESS,
    payload: session,
  };
}

function fetchChatDGPTOngoing(session) {
  return {
    type: FETCH_CHAT_DGPT_ONGOING,
    payload: session,
  };
}

function fetchChatDGPTError(error, sitrepId) {
  const errorMessage =
    'fetchChatDGPTError\n\n' +
    ((!!error &&
      !!error.message &&
      typeof error.message === 'string' &&
      error.message) ||
      '') +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: FETCH_CHAT_DGPT_ERROR,
    payload: sitrepId,
  };
}

export const fetchChatDGPT = ({ chatDGPTSession, userMessage = '' }) => {
  let interval;
  return (dispatch, getState) => {
    dispatch(fetchChatDGPTRequest());
    axios(config.apiGateway.chatDGPT, {
      method: 'POST',
      data: JSON.stringify({
        chatDGPTSession,
        userMessage,
      }),
    })
      .then((response) => {
        const sitrepId = response.data || {};
        let chatDGPTSessionOngoing = chatDGPTSession;
        chatDGPTSessionOngoing.id = sitrepId;
        // Now poll for results
        interval = setInterval(() => {
          axios(config.apiGateway.chatDGPTCheckStatus, {
            method: 'POST',
            data: JSON.stringify({
              sitrepId,
              chatDGPTSessionOngoing,
            }),
          })
            .then((pollResponse) => {
              if (pollResponse.data.status === 'Summarized') {
                dispatch(fetchChatDGPTSuccess(pollResponse.data.session));
                clearInterval(interval);
              } else if (pollResponse.data.status === 'Error') {
                dispatch(
                  fetchChatDGPTError(
                    'DisasterGPT processing error.  Please try again.',
                    sitrepId
                  )
                );
                clearInterval(interval);
              } else {
                dispatch(fetchChatDGPTOngoing(pollResponse.data.session));
              }
            })
            .catch((error) => {
              console.error('Error checking DGPT task status:', error);
              dispatch(fetchChatDGPTError(error, sitrepId));
              clearInterval(interval);
            });
        }, 1000); // Check every 1 second
      })
      .catch((error) => {
        console.error('Error with DGPT task:', error);
        dispatch(fetchChatDGPTError(error, sitrepId));
        clearInterval(interval);
      });
  };
};

/**
 * @param {sitrep,sitrep_id,sitrepMarkdown,reference,reference_id,mapScreenshot} payload
 * */
export function dgptSitrepToInitialIncidentReportSummary(payload) {
  return {
    type: SET_DGPT_SITREP_TO_INITIAL_INCIDENT_REPORT_SUMMARY,
    payload,
  };
}

function exportSitrepToPdfRequest() {
  return {
    type: EXPORT_SITREP_TO_PDF_REQUEST,
  };
}

function exportSitrepToPdfSuccess(data) {
  return {
    type: EXPORT_SITREP_TO_PDF_SUCCESS,
    payload: data,
  };
}

function exportSitrepToPdfError(error) {
  const errorMessage =
    'exportSitrepToPdfError\n\n' +
    ((!!error &&
      !!error.message &&
      typeof error.message === 'string' &&
      error.message) ||
      '') +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: EXPORT_SITREP_TO_PDF_ERROR,
  };
}

export const exportSitrepToPdf = ({ sitrepMarkdown, sitrepId, output }) => {
  return (dispatch, getState) => {
    dispatch(startLoading());
    dispatch(exportSitrepToPdfRequest());
    axios(config.apiGateway.exportSitrepToPdf, {
      method: 'POST',
      data: JSON.stringify({
        sitrepMarkdown,
        output,
      }),
    })
      .then((response) => {
        const fileName = 'Sitrep-' + sitrepId;
        const data = response.data || {};
        console.log('data', data);
        if (output === 'PDF') {
          downloadFile(fileName, output, data);
        } else if (output === 'DOCX') {
          // const statementAsDocumentFile = response.data || {};
          // let statementBlob;
          // // Convert the data to be blob-able
          // let binary = atob(statementAsDocumentFile.replace(/\s/g, ''));
          // let len = binary.length;
          // let buffer = new ArrayBuffer(len);
          // let view = new Uint8Array(buffer);
          // for (let i = 0; i < len; i++) {
          //   view[i] = binary.charCodeAt(i);
          // }
          // statementBlob = new Blob([view], { type: 'application/docx' });
          saveAs(response.data, fileName + '.' + output);
        }

        dispatch(endLoading());
        dispatch(exportSitrepToPdfSuccess(data));
      })
      .catch((error) => {
        console.error('Error with DGPT task:', error);
        dispatch(endLoading());
        dispatch(exportSitrepToPdfError(error));
      });
  };
};

// Newer approach

function initSitrepRequest() {
  return {
    type: INIT_SITREP_REQUEST,
  };
}

function initSitrepSuccess(sitrepId) {
  return {
    type: INIT_SITREP_SUCCESS,
    payload: sitrepId,
  };
}

function initSitrepError(error, sitrepId) {
  const errorMessage =
    'initSitrepError\n\n' +
    ((!!error &&
      !!error.message &&
      typeof error.message === 'string' &&
      error.message) ||
      '') +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: INIT_SITREP_ERROR,
    payload: sitrepId,
  };
}

export const initSitrep = ({ chatDGPTSession, source, source_id, name }) => {
  return (dispatch, getState) => {
    dispatch(initSitrepRequest());
    const { user_guid } = getState().app.user;
    const { group_guid } = getState().app.currentlySelectedGroup;
    axios(config.apiGateway.initSitrep, {
      method: 'POST',
      data: JSON.stringify({
        chatDGPTSession,
        source,
        source_id,
        group_guid,
        user_guid,
        name,
      }),
    })
      .then((response) => {
        const sitrepId = response.data || {};
        dispatch(initSitrepSuccess(sitrepId));
        dispatch(fetchSitreps({ sitrepId: sitrepId }));
      })
      .catch((error) => {
        console.error('Error with DGPT task:', error);
        dispatch(initSitrepError(error, sitrepId));
      });
  };
};

function fetchSitrepsRequest() {
  return {
    type: FETCH_SITREPS_REQUEST,
  };
}

function fetchSitrepsOngoing(sitrep) {
  return {
    type: FETCH_SITREPS_ONGOING,
    payload: sitrep,
  };
}

function fetchSitrepsSuccess(sitrep) {
  return {
    type: FETCH_SITREPS_SUCCESS,
    payload: sitrep,
  };
}

function fetchSitrepsError(error, sitrepId) {
  const errorMessage =
    'fetchSitrepsError\n\n' +
    ((!!error &&
      !!error.message &&
      typeof error.message === 'string' &&
      error.message) ||
      '') +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: FETCH_SITREPS_ERROR,
    payload: sitrepId,
  };
}

export const fetchSitreps = ({ sitrepId, source_id }) => {
  let interval;
  return (dispatch, getState) => {
    dispatch(fetchSitrepsRequest());
    const { user_guid } = getState().app.user;
    const { group_guid } = getState().app.currentlySelectedGroup;
    // Now poll for results
    interval = setInterval(() => {
      axios(config.apiGateway.fetchSitreps, {
        method: 'POST',
        data: JSON.stringify({
          sitrepId,
          source_id,
          user_guid,
          group_guid
        }),
      })
        .then((pollResponse) => {
          const sitrep = pollResponse.data;
          if (!sitrep || sitrep.status === 'Error') {
            dispatch(
              fetchSitrepsError(
                'DisasterGPT processing error.  Please try again.'
              )
            );
            clearInterval(interval);
          } else if (sitrep.status === 'Summarized') {
            dispatch(fetchSitrepsSuccess(sitrep));
            clearInterval(interval);
          } else {
            dispatch(fetchSitrepsOngoing(sitrep));
          }
        })
        .catch((error) => {
          console.error('Error checking DGPT task status:', error);
          dispatch(fetchSitrepsError(error, source_id));
          clearInterval(interval);
        });
    }, 1000); // Check every 1 second
  };
};

function refreshSitrepRequest() {
  return {
    type: REFRESH_SITREP_REQUEST,
  };
}

function refreshSitrepSuccess(sitrepId) {
  return {
    type: REFRESH_SITREP_SUCCESS,
    payload: sitrepId,
  };
}

function refreshSitrepError(error, sitrepId) {
  const errorMessage =
    'refreshSitrepError\n\n' +
    ((!!error &&
      !!error.message &&
      typeof error.message === 'string' &&
      error.message) ||
      '') +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: REFRESH_SITREP_ERROR,
    payload: sitrepId,
  };
}

export const refreshSitrep = ({ id, name }) => {
  return (dispatch, getState) => {
    dispatch(refreshSitrepRequest());
    const { user_guid } = getState().app.user;
    const { group_guid } = getState().app.currentlySelectedGroup;
    axios(config.apiGateway.refreshSitrep, {
      method: 'POST',
      data: JSON.stringify({
        id,
        user_guid,
        group_guid,
        name,
      }),
    })
      .then((response) => {
        const sitrepId = response.data || {};
        dispatch(refreshSitrepSuccess(sitrepId));
        dispatch(fetchSitreps({ sitrepId: sitrepId }));
      })
      .catch((error) => {
        console.error('Error with DGPT task:', error);
        dispatch(refreshSitrepError(error, id));
      });
  };
};

function updateSitrepSectionRequest() {
  return {
    type: UPDATE_SITREP_SECTION_REQUEST,
  };
}

function updateSitrepSectionSuccess(sitrepId) {
  return {
    type: UPDATE_SITREP_SECTION_REQUEST,
    payload: sitrepId,
  };
}

function updateSitrepSectionError(error, sitrepId) {
  const errorMessage =
    'updateSitrepSectionError\n\n' +
    ((!!error &&
      !!error.message &&
      typeof error.message === 'string' &&
      error.message) ||
      '') +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: UPDATE_SITREP_SECTION_ERROR,
    payload: sitrepId,
  };
}

export const updateSitrepSection = ({
  sitrepId,
  sectionId,
  sectionText,
  sectionTitle,
}) => {
  return (dispatch, getState) => {
    dispatch(updateSitrepSectionRequest());
    axios(config.apiGateway.updateSitrepSection, {
      method: 'POST',
      data: JSON.stringify({
        sitrepId,
        sectionId,
        sectionText,
        sectionTitle,
      }),
    })
      .then((response) => {
        dispatch(updateSitrepSectionSuccess(sitrepId));
        dispatch(fetchSitreps({ sitrepId: sitrepId }));
      })
      .catch((error) => {
        console.error('Error with DGPT task:', error);
        dispatch(updateSitrepSectionError(error, sitrepId));
      });
  };
};

function addSitrepSectionRequest() {
  return {
    type: ADD_SITREP_SECTION_REQUEST,
  };
}

function addSitrepSectionSuccess(sitrepId) {
  return {
    type: ADD_SITREP_SECTION_REQUEST,
    payload: sitrepId,
  };
}

function addSitrepSectionError(error, sitrepId) {
  const errorMessage =
    'addSitrepSectionError\n\n' +
    ((!!error &&
      !!error.message &&
      typeof error.message === 'string' &&
      error.message) ||
      '') +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: ADD_SITREP_SECTION_ERROR,
    payload: sitrepId,
  };
}

export const addSitrepSection = ({ sitrepId, sectionId, sectionTitle }) => {
  return (dispatch, getState) => {
    dispatch(addSitrepSectionRequest());
    const { user_guid } = getState().app.user;
    const { group_guid } = getState().app.currentlySelectedGroup;
    axios(config.apiGateway.addSitrepSection, {
      method: 'POST',
      data: JSON.stringify({
        sitrepId,
        sectionId,
        sectionTitle,
        user_guid,
        group_guid,
      }),
    })
      .then((response) => {
        dispatch(addSitrepSectionSuccess(sitrepId));
        dispatch(fetchSitreps({ sitrepId: sitrepId }));
      })
      .catch((error) => {
        console.error('Error with DGPT task:', error);
        dispatch(addSitrepSectionError(error, sitrepId));
      });
  };
};

function removeSitrepSectionRequest() {
  return {
    type: REMOVE_SITREP_SECTION_REQUEST,
  };
}

function removeSitrepSectionSuccess(sitrepId) {
  return {
    type: REMOVE_SITREP_SECTION_REQUEST,
    payload: sitrepId,
  };
}

function removeSitrepSectionError(error, sitrepId) {
  const errorMessage =
    'removeSitrepSectionError\n\n' +
    ((!!error &&
      !!error.message &&
      typeof error.message === 'string' &&
      error.message) ||
      '') +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: REMOVE_SITREP_SECTION_ERROR,
    payload: sitrepId,
  };
}

export const removeSitrepSection = ({ sitrepId, sectionId }) => {
  return (dispatch, getState) => {
    dispatch(removeSitrepSectionRequest());
    axios(config.apiGateway.removeSitrepSection, {
      method: 'POST',
      data: JSON.stringify({
        sitrepId,
        sectionId,
      }),
    })
      .then((response) => {
        dispatch(removeSitrepSectionSuccess(sitrepId));
        dispatch(fetchSitreps({ sitrepId: sitrepId }));
      })
      .catch((error) => {
        console.error('Error with DGPT task:', error);
        dispatch(removeSitrepSectionError(error, sitrepId));
      });
  };
};

function fetchGroupAIUsageRequest() {
  return {
    type: FETCH_GROUP_AI_USAGE_REQUEST,
  };
}

function fetchGroupAIUsageSuccess({ group_guid, usage }) {
  return {
    type: FETCH_GROUP_AI_USAGE_SUCCESS,
    payload: { group_guid, usage },
  };
}

function fetchGroupAIUsageError(error, group_guid) {
  const errorMessage =
    'fetchGroupAIUsageError\n\n' +
    ((!!error &&
      !!error.message &&
      typeof error.message === 'string' &&
      error.message) ||
      '') +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: FETCH_GROUP_AI_USAGE_ERROR,
    payload: group_guid,
  };
}

export const fetchGroupAIUsage = ({group_guid}) => {
  return (dispatch, getState) => {
    dispatch(fetchGroupAIUsageRequest());
    axios(config.apiGateway.fetchGroupAIUsage, {
      method: 'POST',
      data: JSON.stringify({
        group_guid,
      }),
    })
      .then((response) => {
        dispatch(
          fetchGroupAIUsageSuccess({ group_guid, usage: response.data })
        );
      })
      .catch((error) => {
        console.error('Error with DGPT task:', error);
        dispatch(fetchGroupAIUsageError(error, group_guid));
      });
  };
};

function fetchAllGroupsAIUsageRequest() {
  return {
    type: FETCH_ALL_GROUPS_AI_USAGE_REQUEST,
  };
}

function fetchAllGroupsAIUsageSuccess( usage ) {
  return {
    type: FETCH_ALL_GROUPS_AI_USAGE_SUCCESS,
    payload: usage,
  };
}

function fetchAllGroupsAIUsageError(error) {
  const errorMessage =
    'fetchGroupAIUsageError\n\n' +
    ((!!error &&
      !!error.message &&
      typeof error.message === 'string' &&
      error.message) ||
      '') +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: FETCH_ALL_GROUPS_AI_USAGE_ERROR,
  };
}

export const fetchAllGroupsAIUsage = () => {
  return (dispatch, getState) => {
    dispatch(fetchAllGroupsAIUsageRequest());
    axios(config.apiGateway.fetchAllGroupsAIUsage, {
      method: 'POST',
    })
      .then((response) => {
        dispatch(
          fetchAllGroupsAIUsageSuccess(response.data)
        );
      })
      .catch((error) => {
        console.error('Error with DGPT task:', error);
        dispatch(fetchAllGroupsAIUsageError(error));
      });
  };
};

function uploadSOPRequest() {
  return {
    type: UPLOAD_SOP_REQUEST,
  };
}

function uploadSOPSuccess({ group_guid, SOPs }) {
  return {
    type: UPLOAD_SOP_SUCCESS,
    payload: { group_guid },
  };
}

function uploadSOPError(error, group_guid) {
  const errorMessage =
    'uploadSOPError\n\n' +
    ((!!error &&
      !!error.message &&
      typeof error.message === 'string' &&
      error.message) ||
      '') +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: UPLOAD_SOP_ERROR,
    payload: group_guid,
  };
}

export const uploadSOP = (file) => {
  return (dispatch, getState) => {
    dispatch(uploadSOPRequest());
    const { group_guid } = getState().app.currentlySelectedGroup;
    const formData = new FormData();
    formData.append('file', file);
    formData.append('group_guid', group_guid);

    // Create a separate upload instance due to content-type override
    const fileUploadInstance = axios.create({
      ...axios.defaults,
      headers: {
        ...axios.headers,
        'Content-Type': undefined,
      },
    });

    fileUploadInstance.interceptors.request.use((config) => {
      // This is a little dangerous, as it leaves open an edge case where the token could expire before use
      const token = sessionStorage['accessToken'];
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    });

    fileUploadInstance
      .post(config.apiGateway.uploadSOP, formData, {
        method: 'POST',
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        dispatch(uploadSOPSuccess({ group_guid }));
        dispatch(fetchSOPs());
      })
      .catch((error) => {
        console.error('Error with DGPT task:', error);
        dispatch(uploadSOPError(error, group_guid));
      });
  };
};

function fetchSOPsRequest() {
  return {
    type: FETCH_SOPS_REQUEST,
  };
}

function fetchSOPsSuccess({ group_guid, SOPs }) {
  return {
    type: FETCH_SOPS_SUCCESS,
    payload: { group_guid, SOPs },
  };
}

function fetchSOPsError(error, group_guid) {
  const errorMessage =
    'fetchSOPsError\n\n' +
    ((!!error &&
      !!error.message &&
      typeof error.message === 'string' &&
      error.message) ||
      '') +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: FETCH_SOPS_ERROR,
    payload: group_guid,
  };
}

export const fetchSOPs = () => {
  return (dispatch, getState) => {
    dispatch(fetchSOPsRequest());
    const { group_guid } = getState().app.currentlySelectedGroup;
    axios(config.apiGateway.fetchSOPs, {
      method: 'POST',
      data: JSON.stringify({
        group_guid,
      }),
    })
      .then((response) => {
        dispatch(fetchSOPsSuccess({ group_guid, SOPs: response.data }));
      })
      .catch((error) => {
        console.error('Error with DGPT task:', error);
        dispatch(fetchSOPsError(error, group_guid));
      });
  };
};

function deleteSOPRequest() {
  return {
    type: DELETE_SOP_REQUEST,
  };
}

function deleteSOPSuccess({ group_guid }) {
  return {
    type: DELETE_SOP_SUCCESS,
    payload: { group_guid },
  };
}

function deleteSOPError(error, group_guid) {
  const errorMessage =
    'deleteSOPError\n\n' +
    ((!!error &&
      !!error.message &&
      typeof error.message === 'string' &&
      error.message) ||
      '') +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: DELETE_SOP_ERROR,
    payload: group_guid,
  };
}

export const deleteSOP = (id) => {
  return (dispatch, getState) => {
    dispatch(deleteSOPRequest());
    const { group_guid } = getState().app.currentlySelectedGroup;
    axios(config.apiGateway.deleteSOP, {
      method: 'POST',
      data: JSON.stringify({
        id,
      }),
    })
      .then((response) => {
        dispatch(deleteSOPSuccess({ group_guid }));
        dispatch(fetchSOPs());
      })
      .catch((error) => {
        console.error('Error with DGPT task:', error);
        dispatch(deleteSOPError(error, group_guid));
      });
  };
};

// /SOPs

// PlanAI

function initPlanAISuggestionRequest() {
  return {
    type: INIT_PLANAI_SUGGESTION_REQUEST,
  };
}

function initPlanAISuggestionSuccess({ group_guid }) {
  return {
    type: INIT_PLANAI_SUGGESTION_SUCCESS,
    payload: { group_guid },
  };
}

function initPlanAISuggestionError(error, group_guid) {
  const errorMessage =
    'initPlanAISuggestionError\n\n' +
    ((!!error &&
      !!error.message &&
      typeof error.message === 'string' &&
      error.message) ||
      '') +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: INIT_PLANAI_SUGGESTION_REQUEST,
    payload: group_guid,
  };
}

export const initPlanAISuggestion = ({
  reference,
  reference_id,
  input,
  sitrep_id,
  mei_type,
}) => {
  return (dispatch, getState) => {
    dispatch(initPlanAISuggestionRequest());
    const { group_guid } = getState().app.currentlySelectedGroup;
    const { user_guid } = getState().app.user;
    const { id } = getState().app.currentIncident;
    axios(config.apiGateway.initPlanAISuggestion, {
      method: 'POST',
      data: JSON.stringify({
        user_guid,
        group_guid,
        incident_id: id,
        reference,
        reference_id,
        input,
        sitrep_id,
        mei_type,
      }),
    })
      .then((response) => {
        const plan_id = response.data;
        dispatch(initPlanAISuggestionSuccess({ group_guid }));
        dispatch(
          fetchPlanAISuggestion({
            plan_id,
            reference,
            reference_id,
            mei_type,
          })
        );
      })
      .catch((error) => {
        console.error('Error with DGPT task:', error);
        dispatch(initPlanAISuggestionError(error, group_guid));
      });
  };
};

function fetchPlanAISuggestionRequest() {
  return {
    type: FETCH_PLANAI_SUGGESTION_REQUEST,
  };
}

function fetchPlanAISuggestionSuccess({
  reference,
  reference_id,
  suggestions,
  mei_type,
}) {
  return {
    type: FETCH_PLANAI_SUGGESTION_SUCCESS,
    payload: { reference, reference_id, suggestions, mei_type },
  };
}

function fetchPlanAISuggestionError(error) {
  const errorMessage =
    'fetchPlanAISuggestionError\n\n' +
    ((!!error &&
      !!error.message &&
      typeof error.message === 'string' &&
      error.message) ||
      '') +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: FETCH_PLANAI_SUGGESTION_ERROR,
  };
}

export const fetchPlanAISuggestion = ({
  plan_id,
  reference,
  reference_id,
  mei_type,
}) => {
  let interval;
  return (dispatch, getState) => {
    dispatch(fetchPlanAISuggestionRequest());
    // Now poll for results
    interval = setInterval(() => {
      axios(config.apiGateway.fetchPlanAISuggestion, {
        method: 'POST',
        data: JSON.stringify({
          plan_id,
        }),
      })
        .then((pollResponse) => {
          const suggestionResponse = pollResponse.data;
          if (!suggestionResponse || suggestionResponse.status === 'Error') {
            dispatch(
              fetchPlanAISuggestionError(
                'DisasterGPT processing error.  Please try again.'
              )
            );
            clearInterval(interval);
          } else if (suggestionResponse.status === 'Complete') {
            dispatch(
              fetchPlanAISuggestionSuccess({
                reference,
                reference_id,
                suggestions: suggestionResponse.output,
                mei_type,
              })
            );
            clearInterval(interval);
          }
        })
        .catch((error) => {
          console.error('Error checking DGPT task status:', error);
          dispatch(fetchPlanAISuggestionError(error));
          clearInterval(interval);
        });
    }, 1000); // Check every 1 second
  };
};

export function updateSuggestionsAfterSave({ suggestions, mei_type }) {
  return {
    type: FETCH_PLANAI_SUGGESTION_SUCCESS,
    payload: { suggestions, mei_type },
  };
}
