import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Network from '../../../../service/Network';
import API from '../../../../service/api';
import { useSelector, useDispatch } from 'react-redux';
import EditAssessmentDod from './EditAssessmentDod';
import EditAssessmentHseep from './EditAssessmentHseep';
import { toast } from 'react-toastify';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { toastConfig } from 'assets/data/config';
import { endLoading, startLoading } from "../../../../../reducers/loading/loading.action";
import { SharedIcon } from 'components/SharedIcon/SharedIcon';

const EditAssessment = () => {
  const [assessment, setAssessment] = useState(null);

  let { id } = useParams();

  let assessmentId = useRef(null);
  let trainingObjectiveId = useRef(null);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { selectedWorkspace } = useSelector((state) => state.prepare.workspace);

  useEffect(() => {
    fetchSingleAssessment();
  }, []);

  const fetchSingleAssessment = async () => {
    let urlData = id.split('-TOI-');

    if (urlData[0] === null || urlData[1] === null) {
      toast.error('Somthing went wrong', toastConfig);
      navigate(-1);
      return;
    }
    dispatch(startLoading());
    try {
      const singleAssessment = await Network.get(API.assess, {
        workspaceId: selectedWorkspace,
        id: urlData[0],
        trainingObjectiveId: urlData[1],
      });

      if (singleAssessment !== undefined && singleAssessment.status === 200) {
        if (singleAssessment.data.response.dataset[0].type === 'HSEEP') {
          try {
            const hseepAss = await Network.get(API.getAssessemetHseep, {
              workspaceId: selectedWorkspace,
              id: urlData[0],
              trainingObjectiveId: urlData[1],
            });

            if (hseepAss !== undefined && hseepAss.status === 200) {
              setAssessment(hseepAss.data.response.dataset[0]);
              assessmentId.current = urlData[0];
              trainingObjectiveId.current = urlData[1];
            }
          } catch (error) {
            toast.error('Something went wrong', toastConfig);
          }
        } else {
          setAssessment(singleAssessment.data.response.dataset[0]);
          assessmentId.current = urlData[0];
          trainingObjectiveId.current = urlData[1];
        }
      }
    } catch (error) {
      toast.error('Something went wrong', toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };

  const navigateBack = () => {
    navigate(-1);
  };

  console.log('assessment details', assessment);

  return (
    <>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <StylishNewButton onClick={navigateBack}>
              <SharedIcon iconName="keyboard_arrow_left" classes="me-2" />
              Back
            </StylishNewButton>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Assessment
          </li>
        </ol>
      </nav>
      {assessment !== null ? (
        <>
          {assessment.type === 'DOD' ? (
            <EditAssessmentDod
              assessmentData={assessment}
              assessId={assessmentId.current}
              ObjectiveId={trainingObjectiveId.current}
            />
          ) : (
            <EditAssessmentHseep
              assessmentData={assessment}
              assessId={assessmentId.current}
              ObjectiveId={trainingObjectiveId.current}
            />
          )}
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default EditAssessment;
