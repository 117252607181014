import PageTitle from 'components/headers/PageTitle';
import React, { useState } from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useNewUIFlag } from 'utils/featureFlags';
import { PreparePath } from 'utils/sharedUtils/sharedConst';
import { SharedIcon } from 'components/SharedIcon/SharedIcon';

const getRoutePath = (location, params) => {
  const { pathname } = location;

  if (!Object.keys(params).length) {
    return pathname; // we don't need to replace anything
  }

  let path = pathname;
  // Object.entries(params).forEach(([paramName, paramValue]) => {
  //   if (paramValue) {
  //     path = path.replace(paramValue, `:${paramName}`);
  //   }
  // });
  return path;
};

export default function Settings() {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const path = getRoutePath(location, params);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const newUI = useNewUIFlag();

  const toggleClick = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const currentPage =
    path === `${PreparePath}/dashboard/settings/user-settings`
      ? 'User Settings'
      : path === `${PreparePath}/dashboard/settings/members`
      ? 'Members'
      : path === `${PreparePath}/dashboard/settings/time-zone`
      ? 'Time zone'
      : path === `${PreparePath}/dashboard/settings/application`
      ? 'Application'
      : path === `${PreparePath}/dashboard/settings/jmets`
      ? 'JMETs'
      : path === `${PreparePath}/dashboard/settings/organizations`
      ? 'Organizations'
      : path === `${PreparePath}/dashboard/settings/platform`
      ? 'Platforms'
      : path === `${PreparePath}/dashboard/settings/organization-type`
      ? 'ESF/Organization Type'
      : path === `${PreparePath}/dashboard/settings/service`
      ? 'City/Town/Service/Department'
      : path === `${PreparePath}/dashboard/settings/department-type`
      ? 'Jurisdiction/Service/Department Types'
      : path === `${PreparePath}/dashboard/settings/command-agency`
      ? 'State/County/Chapter/Command/Agency'
      : path === `${PreparePath}/dashboard/settings/strategic-priority`
      ? 'Strategic Priorities'
      : path === `${PreparePath}/dashboard/settings/inject-method`
      ? 'Inject Methods'
      : path === `${PreparePath}/dashboard/settings/inject-owner`
      ? 'Inject Owner'
      : path === `${PreparePath}/dashboard/settings/training-priority`
      ? 'Objective Priority'
      : // : path === `${PreparePath}/dashboard/settings/status-board`
      // ? 'Status Board'
      path === `${PreparePath}/dashboard/settings/status-label`
      ? 'Status Label'
      : // : path === `${PreparePath}/dashboard/settings/status-board`
      // ? 'Status Board'
      path === `${PreparePath}/dashboard/settings/assessment-reasons`
      ? 'Assessment Reasons'
      : path === `${PreparePath}/dashboard/settings/exercise-type`
      ? 'Exercise Type'
      : path === `${PreparePath}/dashboard/settings/position`
      ? 'Position'
      : path === `${PreparePath}/dashboard/settings/fromto`
      ? 'From To'
      : path === `${PreparePath}/dashboard/settings/metric`
      ? 'Metric'
      : path === `${PreparePath}/dashboard/settings/primary-source`
      ? 'Primary Source'
      : path === `${PreparePath}/dashboard/settings/sub-source`
      ? 'Sub Source'
      : path === `${PreparePath}/dashboard/settings/support`
      ? 'Support'
      : path === `${PreparePath}/dashboard/settings/technical-discipline`
      ? 'Technical Discipline'
      : path === `${PreparePath}/dashboard/settings/mission-location`
      ? 'Mission Location'
      : path === `${PreparePath}/dashboard/settings/mission-type`
      ? 'Mission Type'
      : path === `${PreparePath}/dashboard/settings/mission-profile`
      ? 'Mission Profile'
      : null;

  return (
    <>
      <div className="site-main__dashboard site-main__settings">
        <div className="container-fluid">
          {newUI ? (
            <PageTitle title={currentPage} parents={['Exercise Management', 'Settings']} />
          ) : (
            <div className="row">
              <div className="col-md-12">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <button onClick={() => navigate(-1)}>
                      <SharedIcon iconName="keyboard_arrow_left" classes="me-2" />
                        Back
                      </button>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      {currentPage}
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          )}
          <div className="site-content">
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
}
