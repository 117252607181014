import React from "react";
import { Modal } from "react-bootstrap";
import DocViewer from "@cyntler/react-doc-viewer";

export default function StylishViewFileDialog({ onClose, show, fileUrl }) {
  return (
    <Modal
      show={show}
      onHide={onClose}
      centered
      backdrop="static"
      keyboard={false}
      fullscreen={true}
    >
      <Modal.Header closeButton closeVariant="white"></Modal.Header>
      <Modal.Body>
        <DocViewer documents={[{ uri: fileUrl }]} />
      </Modal.Body>
    </Modal>
  );
}
