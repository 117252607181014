import React, { useState, useEffect } from 'react';
import NewResourcesDialog from './NewResourcesDialog';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAllResourcesToIncident,
  deleteResourceToIncident,
  getAllResourcesToGroup,
  updateIncidentResourceSelection,
} from 'actions/incidentActions';
import moment from 'moment';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { endLoading, startLoading } from 'reducers/loading/loading.action';
import StylishNewTable from 'components/DesignSystems/New/StylishNewTable';
import { StylishConfirmDialog } from 'components/DesignSystems/New/StylishConfirmDialog';

export default function ManageIncidentResources() {
  const [isNewResourcesDialogOpen, setIsNewResourcesDialogOpen] = useState(
    false
  );
  const [modalType, setModalType] = useState('New');
  const [editResourceData, setEditResourceData] = useState();
  const [selectedIncidentResources, setSelectedIncidentResources] = useState(
    []
  );
  const [prettyGroupResources, setPrettyGroupResources] = useState([]);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [deleteRowData, setDeleteRowData] = useState(null);

  const incidentResources = useSelector((state) => state.app.incidentResources);

  const isIncidentResourcesLoaded = useSelector((state) => {
    return state.app.isIncidentResourcesLoaded;
  });

  const groupResources = useSelector((state) => state.app.groupResources);

  const isGroupResourcesLoaded = useSelector((state) => {
    return state.app.isGroupResourcesLoaded;
  });

  const reduxCurrentIncident = useSelector((state) => {
    return state.app.currentIncident;
  });
  const { user_guid } = useSelector((state) => {
    return state.app.user;
  });
  const reduxCurrentlySelectedGroup = useSelector((state) => {
    return state.app.currentlySelectedGroup;
  });

  const incident_id =
    (reduxCurrentIncident && reduxCurrentIncident.id) || undefined;

  const reduxValidateRBACPermissionForActionResult = useSelector((state) => {
    return state.app.validateRBACPermissionForActionResult;
  });

  const reduxDispatch = useDispatch();

  useEffect(() => {
    setSelectedIncidentResources(
      prettyGroupResources
        ? prettyGroupResources?.filter((item) =>
            incidentResources?.find((i) => i.resource_id === item.id)
          )
        : []
    );
  }, [incidentResources, prettyGroupResources]);
  useEffect(() => {
    setPrettyGroupResources(groupResources ? [...groupResources] : []);
  }, [groupResources]);

  useEffect(() => {
    if (
      typeof user_guid !== 'undefined' &&
      !!reduxCurrentIncident &&
      !!reduxCurrentIncident.id
    ) {
      reduxDispatch(getAllResourcesToIncident());
    }
  }, [incident_id]);

  useEffect(() => {
    if (reduxCurrentlySelectedGroup) {
      reduxDispatch(getAllResourcesToGroup());
    }
  }, [reduxCurrentlySelectedGroup]);

  const onDelete = (row) => {
    setDeleteRowData(row);
    setShowConfirmDialog(true);
  };
  const onDeleteConfirmation = () => {
    reduxDispatch(startLoading());
    reduxDispatch(deleteResourceToIncident(deleteRowData.id));
    setShowConfirmDialog(false);
    setDeleteRowData(null);
  };

  const onEdit = (row) => {
    setModalType('Edit');
    setIsNewResourcesDialogOpen(true);
    setEditResourceData(row);
  };
  const onNewResource = () => {
    setModalType('New');
    setIsNewResourcesDialogOpen(true);
  };

  const columns = [
    {
      dataField: 'type',
      text: 'Type',
      sort: true,
      headerAttrs: { title: 'Sort By:' },
      attrs: { title: 'Type' },
    },
    {
      dataField: 'name',
      text: 'Name',
      sort: true,
      attrs: { title: 'Name' },
    },
  ];

  const expandRow = {
    parentClassName: 'parent-expand-tr',
    className: 'child-expand-tr',
    renderer: (row) => {
      return (
        <>
          <div className="row">
            <div className="col-md-6 mb-3">
              <h6 className="mb-2">Datetime Ordered</h6>
              {moment(row.datetime_ordered).format('MM/DD/YYYY @ hh:mm a')}
            </div>
            <div className="col-md-6 mb-3">
              <h6 className="mb-2">Location</h6>
              {row.location || row.geolocation?.name}
            </div>
            <div className="col-md-6 mb-3 mb-md-0">
              <h6 className="mb-2">ETA</h6>
              {moment(row.eta).format('MM/DD/YYYY @ hh:mm a')}
            </div>
            <div className="col-md-6">
              <h6 className="mb-2">Notes</h6>
              {row.description}
            </div>
          </div>
          {!!reduxValidateRBACPermissionForActionResult['Write Pod Data'] && (
            <div className="button-group mt-4">
              <StylishNewButton
                className="button--secondary"
                onClick={() => onEdit(row)}
              >
                Edit
              </StylishNewButton>
              <StylishNewButton
                className="button--tertiary button--reverse"
                onClick={() => onDelete(row)}
              >
                Delete
              </StylishNewButton>
            </div>
          )}
        </>
      );
    },
    showExpandColumn: true,
    expandByColumnOnly: true,
    expandHeaderColumnRenderer: ({ isAnyExpands }) => {
      if (isAnyExpands) {
        return <i className="fa fa-chevron-down" aria-hidden="true"></i>;
      }
      return <i className="fa fa-chevron-right" aria-hidden="true"></i>;
    },
    expandColumnRenderer: ({ expanded }) => {
      if (expanded) {
        return (
          <>
            <div className="d-flex align-items-center justify-content-md-center">
              <span className="me-2">Details</span>
              <i className="fa fa-chevron-down" aria-hidden="true"></i>
            </div>
          </>
        );
      }
      return (
        <>
          <div className="d-flex align-items-center justify-content-md-center">
            <span className="me-2">Details</span>
            <i className="fa fa-chevron-right" aria-hidden="true"></i>
          </div>
        </>
      );
    },
  };

  const selectRow = {
    mode: 'checkbox',
    clickToSelect: true,
    hideSelectAll: false,
    selected: selectedIncidentResources.map((e) => e.id),
    selectionHeaderRenderer: ({ mode, ...rest }) => (
      <div className="form-checkbox align-items-center">
        <input type={mode} {...rest} readOnly />
        <span className="icon"></span>
      </div>
    ),
    selectionRenderer: ({ mode, ...rest }) => (
      <div className="form-checkbox align-items-center">
        <input type={mode} {...rest} readOnly />
        <span className="icon"></span>
      </div>
    ),
    onSelect: (row, isSelect, rowIndex, e) => {
      let selection = selectedIncidentResources.map((e) => e.id);
      if (selection.includes(row.id)) {
        selection = selection.filter((e) => e !== row.id);
      } else {
        selection.push(row.id);
      }
      reduxDispatch(startLoading());
      reduxDispatch(updateIncidentResourceSelection(selection));
    },
    onSelectAll: (isSelect, rows, e) => {
      if (isSelect) {
        reduxDispatch(startLoading());
        reduxDispatch(updateIncidentResourceSelection(rows.map((e) => e.id)));
      } else {
        reduxDispatch(startLoading());
        reduxDispatch(updateIncidentResourceSelection([]));
      }
    },
  };

  useEffect(() => {
    if (!isIncidentResourcesLoaded || !isGroupResourcesLoaded) {
      reduxDispatch(startLoading());
    } else {
      reduxDispatch(endLoading());
    }
  }, [isIncidentResourcesLoaded, isGroupResourcesLoaded]);

  return (
    <>
      <div className="d-md-flex align-items-center mb-3">
        <h4 className="m-0">Resource Library</h4>
        {!!reduxValidateRBACPermissionForActionResult['Write Pod Data'] && (
          <>
            <div className="ms-auto text-md-end">
              <StylishNewButton
                className="button--secondary mt-2 mt-md-0 w-100 w-md-auto"
                onClick={() => onNewResource(true)}
              >
                New Resource
              </StylishNewButton>
            </div>
          </>
        )}
      </div>

      <div className="form-block">
        <StylishNewTable
          keyField={'id'}
          columns={columns}
          rows={prettyGroupResources}
          expandRow={expandRow}
          selectRow={selectRow}
        />
      </div>

      {isNewResourcesDialogOpen && (
        <NewResourcesDialog
          show={isNewResourcesDialogOpen}
          modalType={modalType}
          setModalType={setModalType}
          editResourceData={editResourceData}
          setEditResourceData={setEditResourceData}
          onClose={() => setIsNewResourcesDialogOpen(false)}
        />
      )}
      {showConfirmDialog && (
        <StylishConfirmDialog
          show={showConfirmDialog}
          dialogTitle={'Delete Confirmation'}
          dialogContent={'Are you sure you want to delete this Resource?'}
          onClose={() => setShowConfirmDialog(false)}
          onConfirm={onDeleteConfirmation}
        />
      )}
    </>
  );
}
