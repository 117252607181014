import { Offcanvas, Row } from 'react-bootstrap';
import React from 'react';
import { StylishNewButton } from '../DesignSystems/New/StylishNewButton';
import { FormProvider, useForm } from 'react-hook-form';
import { EditEntityDrawerProps } from './EntityListProps';
import { StrikeTeam, useEditStrikeTeam } from './hooks/strikeTeamHooks';
import DrawerWrapper, { DrawerFooter } from './DrawerWrapper';
import FormTextField from './formFields/FormTextField';
import FormTextAreaField from './formFields/FormTextAreaField';
import FormSelectField from './formFields/FormSelectField';
import { useActualIncidentId } from './hooks/commonHooks';
import {strike_team_statuses_object} from '../ICS/ICSConstants'
import {
  selectGroupGuid,
  useAppSelector,
} from '../../slices/commonSelectors';
import RelationshipPickerField from './formFields/RelationshipPickerField';
import { ResourceSet, useResourceSets } from './hooks/resourceSetHooks';

type FormFields = {
  id: string;
  strike_team_id: string;
  name: string;
  description?: string;
  group_guid: string;
  work_assignment_id?: string;
  status: string;
  archived?: boolean;
  resource_sets: UUID[];
};

const StrikeTeamEditDrawer: React.FC<EditEntityDrawerProps<StrikeTeam>> = ({
  toggle,
  selectedEntity: editedEntity,
}) => {
  const form = useForm<FormFields>({
    mode: 'onChange',
    defaultValues: { ...editedEntity,
      resource_sets: editedEntity.resource_sets ?? [],
    },
  });
  const { handleSubmit } = form;

  const incidentId = useActualIncidentId();
  const group_guid = useAppSelector(selectGroupGuid);

  const resourceSetOptions = useResourceSets();

  const mutation = useEditStrikeTeam(editedEntity.id);

  const submitStrikeTeamEdit = async (formData: FormFields) => {
    const payload = {
      ...formData,
      archived: formData.archived ?? false,
      incident_id: incidentId,
      group_guid: group_guid,
      strike_team_id: editedEntity.strike_team_id
    };
    await mutation.mutateAsync(payload);
    toggle();
  };

  return (
    <DrawerWrapper toggle={toggle} title="Edit Strike Team">
      <FormProvider {...form}>
        <form
          onSubmit={handleSubmit(submitStrikeTeamEdit)}
          className="d-flex flex-column justify-content-between flex-grow-1"
        >
          <Offcanvas.Body>
            <Row>
              <FormTextField
                title="Name"
                fieldName="name"
                rules={{
                  required: { value: true, message: 'This field is required' },
                  maxLength: { value: 255, message: 'The maximum length is 255 characters' },
                }}
                variant="half"
              />
            </Row>
            <Row>
              <FormTextAreaField
                title="Description"
                fieldName="description"
                rules={{ maxLength: { value: 300, message: 'The maximum length is 300 characters' } }}
                variant="full"
              />
            </Row>
            <Row>
              <FormSelectField
                title="Status"
                fieldName="status"
                rules={{ required: { value: true, message: 'This field is required' } }}
                options={strike_team_statuses_object}
                variant="half"
              />
            </Row>
            <Row>
              <RelationshipPickerField<ResourceSet>
                label={'Resource Sets'}
                fieldName={'resource_sets'}
                defaultValue={editedEntity.resource_sets}
                options={resourceSetOptions.data}
              />
            </Row>
          </Offcanvas.Body>
          <DrawerFooter>
            <div className="button-group">
              <StylishNewButton
                className="button--secondary button--reverse"
                type="button"
                onClick={toggle}
              >
                Close
              </StylishNewButton>
              <StylishNewButton
                className="button--primary"
                type="submit"
                disabled={mutation.isPending}
              >
                Save
              </StylishNewButton>
            </div>
          </DrawerFooter>
        </form>
      </FormProvider>
    </DrawerWrapper>
  );
};

export default StrikeTeamEditDrawer;
