import React, { useEffect, useState } from 'react';
import { SystemDetails } from './SystemDetails/SystemDetails';
import { LogisticsRequirements } from './LogisticsRequirements/LogisticsRequirements';
import { Review } from './Review/Review';
import { PreparePath } from 'utils/sharedUtils/sharedConst';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { PointsOfContact } from './PointsOfContact/PointsOfContact';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import IconTick from 'assets/images/icon__tick-plain.svg';

import { useSelector } from 'react-redux';

export const SystemsStepFormDashboard = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState(0);
  const [searchParams, _] = useSearchParams();
  const params = useParams();

  const { selectedWorkspace, type: workspaceType } = useSelector(
    (state) => state?.prepare?.workspace
  );

  useEffect(() => {
    if (workspaceType && workspaceType !== 'T&E')
      navigate(`${PreparePath}/dashboard/plan`);
  }, [workspaceType]);

  useEffect(() => {
    const page = searchParams.get('page');
    if (!!params.id) {
      if (page && page > 0 && page < 5) {
        setStep(Number(page) - 1);
      } else {
        setStep(0);
      }
    }
  }, [params?.id, searchParams]);

  const steps = [
    {
      label: 'System Details',
      isValid: true,
      component: <SystemDetails />,
      className: 'step-item col-6 col-md-3 mb-3 mb-md-0',
    },
    {
      label: 'Point(s) of Contact',
      isValid: false,
      component: <PointsOfContact />,
      className: 'step-item col-6 col-md-3 mb-3 mb-md-0',
    },
    {
      label: 'Logistics Requirements',
      isValid: false,
      component: <LogisticsRequirements />,
      className: 'step-item col-6 col-md-3',
    },
    {
      label: 'Review',
      isValid: false,
      component: <Review />,
      className: 'step-item col-6 col-md-3',
    },
  ];

  // useEffect(() => {
  //   navigate(`${PreparePath}/dashboard/plan/systems`);
  // }, [selectedWorkspace]);

  return (
    <>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <StylishNewButton
              onClick={() => navigate(`${PreparePath}/dashboard/plan/systems`)}
            >
              <i className="fa fa-angle-left me-2" aria-hidden="true"></i>
              Back
            </StylishNewButton>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Systems
          </li>
        </ol>
      </nav>
      <div className="steps my-4 my-md-5">
        <div className="row">
          <div
            className={
              steps[0].className +
              (step === 0 ? ' step-current' : ' step-completed')
            }
          >
            <span>
              <span className="step-marker">1</span>
              <span className="step-title">System Details</span>
            </span>
          </div>
          <div
            className={
              steps[1].className +
              (step === 1 ? ' step-current' : step > 1 ? ' step-completed' : '')
            }
          >
            <span>
              <span className="step-marker">2</span>
              <span className="step-title">Point(s) of Contact</span>
            </span>
          </div>
          <div
            className={
              steps[2].className +
              (step === 2 ? ' step-current' : step > 2 ? ' step-completed' : '')
            }
          >
            <span>
              <span className="step-marker">3</span>
              <span className="step-title">Logistics Requirements</span>
            </span>
          </div>
          <div
            className={
              steps[3].className +
              (step === 3 ? ' step-current' : step > 5 ? ' step-completed' : '')
            }
          >
            <span>
              <span className="step-marker">
                <img src={IconTick} alt="" />
              </span>
              <span className="step-title">Review</span>
            </span>
          </div>
        </div>
      </div>
      {steps[step].component}
    </>
  );
};
