// import React, { useEffect, useState } from 'react';
// import { useNavigate, useParams } from 'react-router-dom';
// import Network from '../../../../service/Network';
// import API from '../../../../service/api';
// import { useSelector, useDispatch } from 'react-redux';
// import {
//   endLoading,
//   startLoading,
// } from '../../../../store/loading/loading.action';
// import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
// import { toast } from 'react-toastify';
// import LogoEpic from 'assets/images/logo__epicready.svg';
// import ViewFeedbackDod from './ViewFeedbackDod';
// import ViewFeedbackHseep from './ViewFeedbackHseep';
// import { toastConfig } from 'assets/data/config';

// const ViewFeedback = () => {
//   const [feedback, setFeedback] = useState(null);

//   const { selectedWorkspace } = useSelector((state) => state.prepare.workspace);

//   let { id } = useParams();

//   const navigate = useNavigate();

//   const dispatch = useDispatch();

//   useEffect(() => {
//     fetchFeedback();
//   }, [selectedWorkspace]);

//   const fetchFeedback = async () => {
//     dispatch(startLoading());
//     try {
//       const getFeedback = await Network.get(API.getFeedback, {
//         id: id,
//       });
//       if (getFeedback != undefined && getFeedback.status === 200) {
//         if (getFeedback.data.response.dataset[0].type === 'DOD') {
//           setFeedback(getFeedback.data.response.dataset[0]);
//         } else {
//           try {
//             const fetchFeed = await Network.get(API.getFeedbackHseep, {
//               workspaceId: selectedWorkspace,
//               id: id,
//               trainingObjectiveId:
//                 getFeedback.data.response.dataset[0].trainingObjectiveId,
//             });
//             if (fetchFeed !== undefined && fetchFeed.status === 200) {
//               setFeedback({
//                 ...fetchFeed.data.response.dataset[0],
//                 feedbackId: id,
//                 participantId:
//                   getFeedback.data.response.dataset[0].participantId,
//                 workspaceId: selectedWorkspace,
//               });
//             }
//           } catch (error) {
//             toast.error('Something went wrong', toastConfig);
//           }
//         }
//       }
//     } catch (error) {
//       toast.error('Something went wrong', toastConfig);
//     } finally {
//       dispatch(endLoading());
//     }
//   };

//   return (
//     <>
//       <nav aria-label="breadcrumb">
//         <ol className="breadcrumb">
//           <li className="breadcrumb-item">
//             <StylishNewButton onClick={() => navigate(-1)}>
//               <i className="fa fa-angle-left me-2" aria-hidden="true"></i>
//               Back
//             </StylishNewButton>
//           </li>
//         </ol>
//       </nav>
//       <div className="text-center mb-4">
//         <h3 className="m-0">Feedback</h3>
//       </div>
//       {feedback !== null ? (
//         <>
//           {feedback?.type === 'DOD' ? (
//             <ViewFeedbackDod singleFeedback={feedback} />
//           ) : (
//             <ViewFeedbackHseep singleFeedback={feedback} />
//           )}
//         </>
//       ) : (
//         <></>
//       )}
//     </>
//   );
// };

// export default ViewFeedback;

import Network from 'PREPAREsrc/service/Network';
import API from 'PREPAREsrc/service/api';
import { toastConfig } from 'assets/data/config';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ViewFeedbackHseep } from './ViewFeedbackHseep';
import { ViewFeedbackDod } from './ViewFeedbackDod';
import { endLoading, startLoading } from "../../../../../reducers/loading/loading.action";
import { SharedIcon } from 'components/SharedIcon/SharedIcon';

export const ViewFeedback = () => {
  const reduxDispatch = useDispatch();
  const navigate = useNavigate();
  const { feedbackId, trainingObjectiveId } = useParams();
  const { selectedWorkspace } = useSelector(
    (state) => state?.prepare?.workspace
  );

  const [viewData, setViewData] = useState();

  const fetchFeedbackData = async () => {
    reduxDispatch(startLoading());
    try {
      const resp = await Network.get(
        `${
          sessionStorage.getItem('viewFeedbackType') === 'HSEEP'
            ? API.fetchFeedbackHseep
            : API.fetchFeedbackDod
        }`,
        {
          workspaceId: selectedWorkspace,
          id: feedbackId,
          trainingObjectiveId: trainingObjectiveId,
        }
      );
      if (resp !== undefined && resp.status === 200) {
        setViewData(resp?.data?.response?.dataset[0]);
      }
    } catch (error) {
      toast.error(error?.response?.data?.response?.status?.msg, toastConfig);
    } finally {
      reduxDispatch(endLoading());
    }
  };

  useEffect(() => {
    if (!!sessionStorage.getItem('viewFeedbackType')) fetchFeedbackData();
  }, [selectedWorkspace, feedbackId, trainingObjectiveId]);

  return (
    <>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <StylishNewButton onClick={() => navigate(-1)}>
              <SharedIcon iconName="keyboard_arrow_left" classes="me-2" />
              Back
            </StylishNewButton>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            View Feedback
          </li>
        </ol>
      </nav>
      <h3 className="mb-4 pb-2 text-center">View Feedback</h3>
      {viewData && viewData?.type === 'HSEEP' ? (
        <ViewFeedbackHseep data={viewData} />
      ) : null}
      {viewData && viewData?.type === 'DOD' ? (
        <ViewFeedbackDod data={viewData} />
      ) : null}
    </>
  );
};
