import React from 'react';
import { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import API from '../../../../service/api';
import Network from '../../../../service/Network';
import { inputStringLength, toastConfig } from 'assets/data/config';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import StylishNewInput from 'components/DesignSystems/New/StylishNewInput';
import { endLoading, startLoading } from "../../../../../reducers/loading/loading.action";

export default function AddEditAllExerciseDialog({
  onClose,
  show,
  dialogType,
  updateDialogData,
}) {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();
  const workspaceId = useSelector(
    (state) => state.prepare.workspace.selectedWorkspace
  );

  const dispatch = useDispatch();

  const onSubmit = async (data) => {
    dispatch(startLoading());
    try {
      const newData = await Network.post(API.createPlanExerciseType, {
        ...data,
        workspaceId: workspaceId,
      });
      toast.success('Exercise Type added successfully', toastConfig);
      onClose({
        value: newData.data.response.dataset[0].id,
        label: newData.data.response.dataset[0].title,
      });
    } catch (error) {
      toast.error(error.response.data.response.status.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };
  useEffect(() => {
    const listener = (event) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        event.preventDefault();
        handleSubmit(onSubmit)();
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, []);
  return (
    <>
      <Modal
        show={show}
        onHide={onClose}
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton closeVariant="white">
          <Modal.Title>Exercise Type</Modal.Title>
        </Modal.Header>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body>
            <div className="mb-3">
              <label className="form-label">Name</label>
              <Controller
                control={control}
                name={'title'}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <StylishNewInput
                    onChange={(e) => {
                      onChange(e);
                    }}
                    value={value}
                    type="text"
                    name="title"
                    maxLength={inputStringLength}
                    defaultValue={updateDialogData?.title}
                    className="form-control"
                    // {...register('amount', { required: true })}
                  />
                )}
              />
              {errors?.title && (
                <span className="form-text form-error">
                  This is a required field
                </span>
              )}
            </div>
          </Modal.Body>

          <Modal.Footer>
            <div className="button-group">
              <StylishNewButton
                className="button button--secondary button--reverse"
                onClick={() => onClose({})}
              >
                Close
              </StylishNewButton>
              <StylishNewButton
                className="button button--primary"
                type="submit"
              >
                Save
              </StylishNewButton>
            </div>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
}
