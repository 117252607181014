import Network from 'PREPAREsrc/service/Network';
import API from 'PREPAREsrc/service/api';
import { toastConfig } from 'assets/data/config';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { ViewAssessmentHSEEP } from './ViewAssessmentHSEEP';
import { ViewAssessmentDOD } from './ViewAssessmentDOD';
import { toast } from 'react-toastify';
import { endLoading, startLoading } from "../../../../../reducers/loading/loading.action";
import { SharedIcon } from 'components/SharedIcon/SharedIcon';

export const ViewAssessment = () => {
  const reduxDispatch = useDispatch();
  const navigate = useNavigate();
  const { assessmentId, trainingObjectiveId } = useParams();
  const { selectedWorkspace } = useSelector(
    (state) => state?.prepare?.workspace
  );

  const [viewData, setViewData] = useState();

  const fetchAssessmentData = async () => {
    reduxDispatch(startLoading());
    try {
      const resp = await Network.get(
        `${
          sessionStorage.getItem('viewAssessmentType') === 'HSEEP'
            ? API.fetchAssessmentHseep
            : API.fetchAssessmentDod
        }`,
        {
          workspaceId: selectedWorkspace,
          id: assessmentId,
          trainingObjectiveId: trainingObjectiveId,
        }
      );
      if (resp !== undefined && resp.status === 200) {
        setViewData(resp?.data?.response?.dataset[0]);
      }
    } catch (error) {
      toast.error(error?.response?.data?.response?.status?.msg, toastConfig);
    } finally {
      reduxDispatch(endLoading());
    }
  };

  useEffect(() => {
    if (!!sessionStorage.getItem('viewAssessmentType')) fetchAssessmentData();
  }, [selectedWorkspace, assessmentId, trainingObjectiveId]);

  return (
    <>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <StylishNewButton onClick={() => navigate(-1)}>
              <SharedIcon iconName="keyboard_arrow_left" classes="me-2" />
              Back
            </StylishNewButton>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            View Assessment
          </li>
        </ol>
      </nav>
      <h3 className="mb-4 pb-2 text-center">View Assessment</h3>
      {viewData && viewData?.type === 'HSEEP' ? (
        <ViewAssessmentHSEEP data={viewData} />
      ) : null}
      {viewData && viewData?.type === 'DOD' ? (
        <ViewAssessmentDOD data={viewData} />
      ) : null}
    </>
  );
};
