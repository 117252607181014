import React, { Fragment } from 'react';
import API from '../../../service/api';
import Network from '../../../service/Network';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { updateWorkSpaceApplicationById } from '../../../store/workspaces/workspaces.action';
import StylishHelp from 'components/DesignSystems/New/StylishHelp';
import { HelpApplicationSettings, useSelfHostedKnowledgeBase } from 'assets/data/HelpContent';
import { StylishSwitcher } from 'components/DesignSystems/New/StylishSwitcher';
import { toastConfig } from 'assets/data/config';
import {
  endLoading,
  startLoading,
} from '../../../../reducers/loading/loading.action';

export default function Application() {
  const dispatch = useDispatch();
  const applist = useSelector((state) => state.prepare.workspace.appList);
  const workspaceId = useSelector(
    (state) => state.prepare.workspace.selectedWorkspace
  );

  const selfHostedKnowledgeBase = useSelfHostedKnowledgeBase()

  const postData = async (data) => {
    dispatch(startLoading());
    try {
      await Network.post(API.updateAppList, {
        id: data,
        workspaceId: workspaceId,
      });
      toast.success('Applist updated', toastConfig);
      dispatch(updateWorkSpaceApplicationById(data));
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.response.status.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };

  const handleChange = (event, id) => {
    console.log(event.target.checked, id);
    if (event.target.checked) {
      postData(id);
    } else {
      postData(id);
    }
  };
  return (
    <>
      <div className="d-flex mb-4">
        <StylishHelp
          classes={'ms-auto'}
          title={HelpApplicationSettings.title}
          content={HelpApplicationSettings.content}
          link={selfHostedKnowledgeBase || HelpApplicationSettings.link}
        />
      </div>
      <div className="settings__application">
        <p className="p--xl weight-300 mb-0 mb-md-3">
          Customize the active applications for this workspace. Deactivating an
          application will not remove data.
        </p>
        <div className="row">
          {applist.map((data) => (
            <div className="col-md-6 mt-4" key={data.id}>
              <div className={`block h-100 m-0 logo d-flex ${data.className}`}>
                <div className="d-flex align-items-start me-3 flex-grow-1">
                  <div className="icon flex-shrink-0 me-3">
                    <img src={data.logo} alt="" />
                  </div>
                  <div>
                    <h4 className="mb-1">{data.title}</h4>
                    <p className="mb-1">{data.intro}</p>
                    {/* <p className="m-0">
                      <span className="weight-600">Accessible by:</span> Admin,
                      Editor
                    </p> */}
                  </div>
                </div>
                <div className="text-end flex-shrink-0">
                  <StylishSwitcher
                    checked={data.checked}
                    onChange={(e) => handleChange(e, data.id)}
                  />
                  <p className="m-0">{data.checked ? 'Active' : 'Inactive'}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
