import React, { useEffect, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import Network from '../../../../service/Network';
import API from '../../../../service/api';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import Plot from 'react-plotly.js';
import { HelpTrainDashboard, useSelfHostedKnowledgeBase } from 'assets/data/HelpContent';
import EditMSELTimeline from '../MSELTimeline/EditMSELTimeline';
import {
  paperBgColor,
  plotBgColor,
  plotBlue,
  plotBrick,
  plotGreen,
  plotOrange,
  toastConfig,
} from 'assets/data/config';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import StylishHelp from 'components/DesignSystems/New/StylishHelp';
import StylishNewSelect from 'components/DesignSystems/New/StylishNewSelect';
import {
  endLoading,
  startLoading,
} from '../../../../../reducers/loading/loading.action';
import PageTitle from 'components/headers/PageTitle';
import { useNewUIFlag } from 'utils/featureFlags';
import { SharedIcon } from 'components/SharedIcon/SharedIcon';
import { PreparePath } from 'utils/sharedUtils/sharedConst';

export const Dashboard = () => {
  const [titleOfGraph, setTitleOfGraph] = useState('');
  const [injectMeetings, setInjectMeetings] = useState([]);
  const [refinedInjectMeetings, setRefinedInjectMeetings] = useState([]);
  const [board, setBoard] = useState({});
  const [injectSelected, setInjectSelected] = useState({
    value: 'all',
    label: 'all',
  });
  // const [boardsList, setBoardsList] = useState([]);
  const [allOwner, setAllOwner] = useState([]);
  const [mselInject, setMselInject] = useState([]);
  const [
    trainningObjectiveCompletion,
    setTrainingobjectivecompletion,
  ] = useState({});
  const [totalCompleted, setTotalCompleted] = useState(0);
  const [totalPlanned, setTotalPlanned] = useState(0);
  const [totalAssessed, setTotalAssessed] = useState(0);
  const [showPopupOnClick, setShowPopupOnClick] = useState(false);
  const [eventId, setEventId] = useState();
  const [
    trainningObjectiveRequestedvsAssessed,
    setTrainingobjectiveRequestedvsAssessed,
  ] = useState({});
  const [
    trainningObjectiveRequestedvsPlanned,
    setTrainingobjectiveRequestedvsPlanned,
  ] = useState({});
  const [mselInjectLength, setMselInjectLength] = useState(0);

  const selfHostedKnowledgeBase = useSelfHostedKnowledgeBase()

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const newUI = useNewUIFlag();

  const { control } = useForm();
  const reduxUser = useSelector((state) => state.prepare.user);

  const { selectedWorkspace } = useSelector((state) => state.prepare.workspace);
  const workspaceType = useSelector((state) => state.prepare.workspace.type);
  useEffect(() => {
    if (!!workspaceType && workspaceType === 'T&E') {
      navigate(`${PreparePath}/dashboard/plan`);
    }
  }, [workspaceType]);

  const fetchAllInject = () => {
    dispatch(startLoading());
    try {
      Network.get(API.fetchAllInject, {
        workspaceId: selectedWorkspace,
      })
        .then((resp) => {
          let allInjectMeetings = {};
          allInjectMeetings =
            resp.data.response.dataset.length &&
            resp.data.response.dataset.map((e) => {
              return {
                ...e,
                start: e?.startDate,
              };
            });
          setInjectMeetings(allInjectMeetings);
        })
        // }
        .catch(console.log)
        .finally(() => {
          dispatch(endLoading());
        });
    } catch (error) {
      toast.error(error?.response?.data?.response?.status?.msg, toastConfig);
    }
  };

  const planStatusBoardList = async () => {
    dispatch(startLoading());
    try {
      const newStatusBoard = await Network.get(API.planStatusBoardList, {
        workspaceId: selectedWorkspace,
      });
      const boardListData = newStatusBoard.data.response.dataset.map((e) => {
        return {
          value: e.boardId,
          label: e.title,
        };
      });
      // setBoardsList(boardListData);
      boardListData?.length &&
        setBoard(
          boardListData[Math.floor(Math.random() * boardListData.length)]
        );
    } catch (error) {
      toast.error(error?.response?.data?.response?.status?.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };

  const fetchAllBoardInject = async () => {
    dispatch(startLoading());
    try {
      await Network.get(API.fetchAllBoardInject, {
        workspaceId: selectedWorkspace,
        boardId: board.value,
      });
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(endLoading());
    }
  };

  const trainDashboard = async () => {
    dispatch(startLoading());
    try {
      // const train =

      Network.get(API.trainDashboard, {
        workspaceId: selectedWorkspace,
        boardId: board.value,
        filter: injectSelected.value,
      })
        .then((train) => {
          if (Number(train?.data?.response?.assessmentData[0]?.total) === 0) {
            setTotalCompleted(0);
            setTrainingobjectivecompletion({});
          }

          if (Number(train?.data?.response?.assessmentData[0]?.total) > 0) {
            setTotalCompleted(
              Number(train?.data?.response?.assessmentData[0]?.total)
            );
            setTrainingobjectivecompletion({
              not_completed: Number(
                train?.data?.response?.assessmentData[0]?.not_completed
              ),
              completed: Number(
                train?.data?.response?.assessmentData[0]?.completed
              ),
            });
          }
          if (train?.data?.response.assessedPlannedDashboardData.length > 0) {
            const totalAssessed =
              Number(
                train?.data?.response?.assessedPlannedDashboardData[0]
                  ?.total_training_objectives_assessed_planassessment
              ) +
              Number(
                train?.data?.response?.assessedPlannedDashboardData[0]
                  ?.total_training_objectives_assessed_planfeedback
              );
            const totalPlanned = Number(
              train?.data?.response?.assessedPlannedDashboardData[0]
                ?.total_training_objectives_planned
            );
            setTrainingobjectiveRequestedvsAssessed({
              planned: totalPlanned,
              assessed: totalAssessed,
            });
            setTotalAssessed(
              Number(
                train?.data?.response?.assessedPlannedDashboardData[0]
                  ?.total_training_objectives_planned
              ) + totalAssessed
            );
          }

          if (
            train?.data?.response.requestedPlannedDashboardData.length === 0
          ) {
            setTotalAssessed(0);
            setTrainingobjectiveRequestedvsAssessed([]);
          }
          if (train?.data?.response?.requestedPlannedDashboardData.length > 0) {
            setTotalPlanned(
              Number(
                train?.data?.response?.requestedPlannedDashboardData[0]
                  .total_training_objectives_requested
              ) +
                Number(
                  train?.data?.response?.requestedPlannedDashboardData[0]
                    ?.total_training_objectives_planned
                )
            );

            setTrainingobjectiveRequestedvsPlanned({
              planned: Number(
                train?.data?.response?.requestedPlannedDashboardData[0]
                  .total_training_objectives_requested
              ),
              requested_planned: Number(
                train?.data?.response?.requestedPlannedDashboardData[0]
                  ?.total_training_objectives_planned
              ),
            });
          }
          if (
            train?.data?.response?.requestedPlannedDashboardData.length === 0
          ) {
            setTotalPlanned(0);
            setTrainingobjectiveRequestedvsPlanned([]);
          }

          if (train?.data?.response?.mselData.length > 0) {
            setMselInject(train?.data?.response?.mselData);
            setMselInjectLength(
              train?.data?.response?.mselData.reduce(
                (len, data) => len + Number(data.count || 0),
                0
              )
            );
          }
          if (train?.data?.response?.mselData.length === 0) {
            setMselInject([]);
            setMselInjectLength(0);
          }
        })

        .catch(console.log)
        .finally(() => {
          dispatch(endLoading());
        });
    } catch (error) {}
  };

  const getInjectOwnerList = async () => {
    dispatch(startLoading());
    try {
      const injectOwner = await Network.get(API.getPlanInjectOwnerList, {
        workspaceId: selectedWorkspace,
      });
      setAllOwner([
        { value: 'all', label: 'all' },
        ...injectOwner.data.response.dataset.map((e) => {
          return {
            value: e.id,
            label: e.title,
          };
        }),
      ]);
    } catch (error) {
      toast.error(error?.response?.data?.response?.status?.msg, toastConfig);
    } finally {
      allOwner && dispatch(endLoading());
    }
  };

  useEffect(() => {
    board?.value && fetchAllBoardInject();
    board?.value && trainDashboard();
    fetchAllInject();
  }, [board, injectSelected, selectedWorkspace]);

  useEffect(() => {
    getInjectOwnerList();
    planStatusBoardList();
    fetchAllInject();
    if (workspaceType === 'DOD') {
      setTitleOfGraph(' Objectives');
    }
    if (workspaceType === 'HSEEP') {
      setTitleOfGraph('Excercise Details');
    }
  }, []);

  useEffect(() => {
    if (injectMeetings) {
      const eventCalendar = injectMeetings.map((e) => {
        const refinedPlanCalendar = {
          title: e?.injectTitle,
          start: e?.startDate,
          end: e?.startDate,
          id: e?.id,
          color: e?.planEventColor,
        };
        return refinedPlanCalendar;
      });
      setRefinedInjectMeetings(eventCalendar);
    } else {
      setRefinedInjectMeetings([]);
    }
  }, [injectMeetings]);

  const onCloseOfModal = () => {
    setEventId();
    setShowPopupOnClick(false);
  };
  return (
    <>
      {newUI ? (
        <PageTitle
          title="Conduct Dashboard"
          parents={['Exercise Management', 'Conduct']}
        >
          <StylishHelp
            classes={'ms-auto sml'}
            title={HelpTrainDashboard.title}
            content={HelpTrainDashboard.content}
            link={selfHostedKnowledgeBase || HelpTrainDashboard.link}
          />
        </PageTitle>
      ) : null}
      <div className="d-flex align-items-start flex-column mb-4">
        {!newUI && (
          <>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb m-0">
                <li className="breadcrumb-item">
                  <StylishNewButton onClick={() => navigate(-1)}>
                    <SharedIcon iconName="keyboard_arrow_left" classes="me-2" />
                    Back
                  </StylishNewButton>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Dashboard
                </li>
              </ol>
            </nav>
            <StylishHelp
              classes={'ms-auto sml'}
              title={HelpTrainDashboard.title}
              content={HelpTrainDashboard.content}
              link={selfHostedKnowledgeBase || HelpTrainDashboard.link}
            />
          </>
        )}
      </div>
      <div className="row">
        <div className="col-xl-4 mb-4 mb-xl-0">
          <div className="section section__graph">
            <h4 className="mb-4">MSEL Injects</h4>
            <div className="row mb-4">
              <div className="col-md-6">
                <Controller
                  control={control}
                  name="Owner"
                  rules={{ required: true }}
                  render={({ field: { value } }) => (
                    <StylishNewSelect
                      options={allOwner}
                      defaultValue={value}
                      value={value || injectSelected}
                      onChange={(e) => {
                        setInjectSelected(e);
                      }}
                      isClearable={false}
                      isSearchable={true}
                      isMulti={false}
                      isDisabled={false}
                    />
                  )}
                />
              </div>
            </div>
            <div className="section section__graph">
              <div className="p-3 p-md-4 bg-gray-800 mb-4">
                <h4 className="mb-1">MSEL Injects</h4>
                <p className="weight-600 mb-3">Total: {mselInjectLength}</p>
                <Plot
                  data={[
                    {
                      x: [...mselInject.map((e) => e.title)],
                      y: [...mselInject.map((e) => e.count)],
                      type: 'bar',
                      marker: {
                        color: mselInject.map((e) => e?.color),
                        // plotBrick,
                        // plotGreen,
                        // plotBrick,
                        // plotGreen,
                        // plotBrick,
                        // plotGreen,
                      },
                      text: [...mselInject.map((e) => e.count)].map(String),
                    },
                  ]}
                  layout={{
                    autosize: true,
                    paper_bgcolor: paperBgColor,
                    plot_bgcolor: plotBgColor,
                    font: {
                      color: 'white',
                    },

                    // title: `Msel Injects <br> Total: ${mselInjectLength}`,
                  }}
                  onLegendClick={() => false}
                  useResizeHandler
                  className="w-100"
                  config={{
                    displayModeBar: true,
                    modeBarButtons: [['toImage']],
                    displaylogo: false,
                  }}
                />
              </div>
              <div className="p-3 p-md-4 bg-gray-800">
                <h4 className="mb-1">{titleOfGraph} Completion </h4>

                <p className="weight-600 mb-3">Total: {totalCompleted}</p>

                <Plot
                  data={[
                    {
                      values:
                        !!trainningObjectiveCompletion &&
                        Object.values(trainningObjectiveCompletion),
                      labels: ['Not Completed', 'Completed'],
                      type: 'pie',
                      marker: {
                        colors: [plotBrick, plotGreen],
                      },
                      text:
                        !!trainningObjectiveCompletion &&
                        Object.values(trainningObjectiveCompletion).map(String),
                    },
                  ]}
                  layout={{
                    autosize: true,
                    paper_bgcolor: paperBgColor,
                    plot_bgcolor: plotBgColor,
                    font: {
                      color: 'white',
                    },

                    // title: `${titleOfGraph} Completion<br>Total: ${totalCompleted}`,
                  }}
                  onLegendClick={() => false}
                  useResizeHandler
                  className="w-100"
                  config={{
                    displayModeBar: true,
                    modeBarButtons: [['toImage']],
                    displaylogo: false,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-4 mb-4 mb-xl-0">
          <div className="section section__graph">
            <div className="p-3 p-md-4 bg-gray-800  mb-4">
              <h4 className="mb-1">{titleOfGraph} (Requested vs Planned) </h4>
              <p className="weight-600 mb-3">Total: {totalPlanned}</p>
              <Plot
                data={[
                  {
                    values:
                      !!trainningObjectiveRequestedvsPlanned &&
                      Object.values(trainningObjectiveRequestedvsPlanned),
                    labels: ['Requested', 'Planned '],
                    type: 'pie',
                    marker: {
                      colors: [plotGreen, plotOrange],
                    },
                    text:
                      !!trainningObjectiveRequestedvsPlanned &&
                      Object.values(trainningObjectiveRequestedvsPlanned).map(
                        String
                      ),
                  },
                ]}
                layout={{
                  autosize: true,
                  paper_bgcolor: paperBgColor,
                  plot_bgcolor: plotBgColor,
                  font: {
                    color: 'white',
                  },

                  // title: `${titleOfGraph} <br> Requested vs Planned <br> Total:${totalPlanned}`,
                }}
                onLegendClick={() => false}
                useResizeHandler
                className="w-100"
                config={{
                  displayModeBar: true,
                  modeBarButtons: [['toImage']],
                  displaylogo: false,
                }}
              />
            </div>
            <div className="p-3 p-md-4 bg-gray-800">
              <h4 className="mb-1">{titleOfGraph} (Planned vs Assessed) </h4>
              <p className="weight-600 mb-3">Total: {totalAssessed}</p>
              <Plot
                data={[
                  {
                    values:
                      !!trainningObjectiveRequestedvsAssessed &&
                      Object.values(trainningObjectiveRequestedvsAssessed),
                    labels: ['Planned', 'Assessed'],
                    type: 'pie',
                    marker: {
                      colors: [plotGreen, plotBlue],
                    },
                    text:
                      !!trainningObjectiveRequestedvsAssessed &&
                      Object.values(trainningObjectiveRequestedvsAssessed).map(
                        String
                      ),
                  },
                ]}
                layout={{
                  autosize: true,
                  paper_bgcolor: paperBgColor,
                  plot_bgcolor: plotBgColor,
                  font: {
                    color: 'white',
                  },

                  // title: `${titleOfGraph} <br> Planned vs Assessed <br> Total :${totalAssessed}`,
                }}
                onLegendClick={() => false}
                useResizeHandler
                className="w-100"
                config={{
                  displayModeBar: true,
                  modeBarButtons: [['toImage']],
                  displaylogo: false,
                }}
              />
            </div>
          </div>
        </div>
        <div className="col-xl-4">
          <div className="section section__calendar">
            <div className="d-flex align-items-center mb-4">
              <h4 className="m-0">Calendar</h4>
            </div>
            <div className="calendar calendar-dashboard">
              <FullCalendar
                validRange={''}
                plugins={[listPlugin, interactionPlugin]}
                headerToolbar={{
                  left: 'title',
                  center: '',
                  right: 'prev,next today',
                }}
                initialView="listMonth"
                events={refinedInjectMeetings}
                eventTimeFormat={{
                  hour: '2-digit',
                  minute: '2-digit',
                  meridiem: 'short',
                  hour12: reduxUser?.timeFormat === 'hour12Mode' ? true : false,
                }}
                displayEventTime={true}
                eventClick={(clickEvent) => {
                  setEventId(clickEvent.event._def.publicId);
                  setShowPopupOnClick(true);
                }}
              />
            </div>
          </div>
        </div>
      </div>
      {showPopupOnClick && (
        <EditMSELTimeline
          show={showPopupOnClick}
          onClose={onCloseOfModal}
          viewId={eventId}
          onDeleteSuccess={fetchAllInject}
          onStatusUpdate={fetchAllInject}
        ></EditMSELTimeline>
      )}
    </>
  );
};
