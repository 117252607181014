import React, { useEffect, useState } from 'react';
import { Modal, Dropdown } from 'react-bootstrap';
import ChooseMediaDialog from '../ChooseMediaModal/ChooseMediaDialog';
import { useSelector, useDispatch } from 'react-redux';
import IconData from 'assets/images/icon__data.svg';
import IconChooseMedia from 'assets/images/icon__choosemedia.svg';
import StylishNewSelect from 'components/DesignSystems/New/StylishNewSelect';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import IconTimes from 'assets/images/icon__times.svg';
import { inputStringLength, toastConfig } from 'assets/data/config';
import API from '../../../service/api';
import Network from '../../../service/Network';
import { useForm, Controller } from 'react-hook-form';
import StylishNewInput from 'components/DesignSystems/New/StylishNewInput';
import StylishNewTextArea from 'components/DesignSystems/New/StylishNewTextArea';
import { toast } from 'react-toastify';
import { endLoading, startLoading } from "../../../../reducers/loading/loading.action";

export default function CreateProfileDialog({
  onClose,
  show,
  dialogType,
  updateDialogData,
}) {
  const {
    register,
    handleSubmit,
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();

  const [showChooseMediaDialog, setShowChooseMediaDialog] = useState(false);
  const [typeModalProfile, setTypeModalProfile] = useState(false);
  const [imageAvatar, setImageAvatar] = useState(null);
  const [imageBanner, setImageBanner] = useState(null);
  const [isVisibleAvatar, setIsVisibleAvatar] = useState(false);
  const [isVisibleBanner, setIsVisibleBanner] = useState(false);
  const [imageAvatarFrom, setImageAvatarFrom] = useState(false);
  const [imageBannerFrom, setImageBannerFrom] = useState(false);
  const [showFollowerCount, setShowFollowerCount] = useState(false);

  const [viewSites, setViewSites] = useState([]);

  const workspaceId = useSelector(
    (state) => state.prepare.workspace.selectedWorkspace
  );
  const reduxUser = useSelector((state) => state.prepare.user);
  const dispatch = useDispatch();

  const mediaSiteList = async () => {
    dispatch(startLoading());
    try {
      const response = await Network.get(API.mediasiteList, {
        workspaceId: workspaceId,
      });
      const mediaList = response.data.response.dataset.map(
        (updatedMediaList) => {
          return {
            label: updatedMediaList.displayName,
            value: updatedMediaList.id,
          };
        }
      );
      setViewSites(mediaList);
    } catch (error) {
    } finally {
      dispatch(endLoading());
    }
  };
  useEffect(() => {
    mediaSiteList();
  }, [reduxUser]);

  const onChooseMediaDialog = () => {
    setShowChooseMediaDialog(true);
  };
  const onChooseMediaDialogClose = () => {
    setShowChooseMediaDialog(false);
  };
  const onSubmitImageAvatar = (data) => {
    setShowChooseMediaDialog(false);
    if (data !== undefined) {
      setImageAvatar(data);
      setIsVisibleAvatar(true);
    }
  };
  const onSubmitImageAvatarFrom = (data) => {
    if (data !== undefined) {
      setImageAvatarFrom(data);
    }
  };
  const onSubmitImageBannerFrom = (data) => {
    if (data !== undefined) {
      setImageBannerFrom(data);
    }
  };

  const onSubmitImageBanner = (data) => {
    setShowChooseMediaDialog(false);
    if (data !== undefined) {
      setImageBanner(data);
      setIsVisibleBanner(true);
    }
  };

  useEffect(() => {
    let nameField = document.getElementById('userName');
    if (nameField) {
      nameField.addEventListener('input', function () {
        const value = nameField.value.trim();

        if (!value.startsWith('@')) {
          nameField.value = '@' + value;
        }
      });
    }
  }, []);

  useEffect(() => {
    const listener = (event) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        event.preventDefault();
        handleSubmit(onSubmit)();
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, []);

  /////////////////////////

  const [personas, setPersonas] = useState([]);

  const fetchAllPersonas = async () => {
    dispatch(startLoading());
    try {
      const response = await Network.get(API.mediaPersonaList, {
        workspaceId: workspaceId,
      });
      if (response !== undefined && response?.status === 200) {
        const data = response?.data?.response?.dataset?.map((i) => ({
          label: i?.name,
          value: i?.id,
        }));
        setPersonas(data);
      }
    } catch (error) {
      toast.error('Something went wrong', toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };

  useEffect(() => {
    fetchAllPersonas();
  }, [reduxUser, workspaceId]);

  const onSubmit = async (data) => {
    const personas =
      data?.personas?.map((i) => ({ mediaPersonaId: i?.value })) || [];
    dispatch(startLoading());
    try {
      const newProfile = await Network.post(API.createMediaProfile, {
        name: data?.name,
        username: data?.userName.slice(1),
        description: data?.description,
        mediaSiteId: data?.siteDropDown?.value,
        followerCount: data?.followers || 900,
        followingCount: data?.following || 400,
        avatar: imageAvatar,
        banner: imageBanner,
        workspaceId: workspaceId,
        isUserGenerated: true,
        personas,
      });
      onClose(newProfile?.data?.response?.MediaProfile);
      toast.success('Profile created successfully', toastConfig);
    } catch (error) {
      toast.error('Something went wrong', toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };

  return (
    <div>
      <Modal
        show={show}
        onHide={onClose}
        centered
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton closeVariant="white">
          <Modal.Title>Create Profile</Modal.Title>
        </Modal.Header>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body>
            <div className="mb-3">
              <label className="form-label">Site</label>
              <Controller
                control={control}
                name="siteDropDown"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <StylishNewSelect
                    options={viewSites}
                    value={value}
                    onChange={onChange}
                    isClearable={false}
                    isSearchable={false}
                    isMulti={false}
                    isDisabled={false}
                    placeholder="Select a Site"
                  />
                )}
              />
              {errors.siteDropDown && (
                <span className="form-text form-error">
                  This field is required
                </span>
              )}
            </div>
            <div className="mb-3">
              <label className="form-label">Name</label>
              <Controller
                control={control}
                name="name"
                // defaultValue={updateDialogData?.name}
                rules={{
                  required: true,
                  pattern: /^[A-Za-z]*[A-Za-z][A-Za-z0-9-. _]*$/,
                }}
                render={({ field: { onChange, value } }) => (
                  <StylishNewInput
                    type="text"
                    // name=""
                    className="form-control"
                    onChange={onChange}
                    value={value || ''}
                  />
                )}
              />
              {errors.name?.type === 'required' && (
                <span className="form-text form-error">
                  This field is required
                </span>
              )}
              {errors?.name?.type === 'pattern' && (
                <span className="form-text form-error">
                  Please provide a valid name
                </span>
              )}
            </div>

            <div className="mb-3">
              <label className="form-label">User Name</label>
              <Controller
                control={control}
                name="userName"
                id="userName"
                defaultValue="@"
                rules={{
                  required: true,
                  pattern: /^@?[A-Za-z]*[A-Za-z][A-Za-z0-9-. _]*$/,
                }}
                render={({ field: { onChange, value } }) => (
                  <StylishNewInput
                    id="userName"
                    type="text"
                    onChange={onChange}
                    value={value || ''}
                    className="form-control"
                  />
                )}
              />
              {errors.userName?.type === 'required' && (
                <span className="form-text form-error">
                  This field is required
                </span>
              )}
              {errors?.userName?.type === 'pattern' && (
                <span className="form-text form-error">
                  Please provide a valid user name
                </span>
              )}
            </div>

            <div className="mb-3">
              <label className="form-label">Description</label>
              <Controller
                control={control}
                name="description"
                // defaultValue={updateDialogData?.description}
                maxLength={inputStringLength}
                render={({ field: { onChange, value } }) => (
                  <StylishNewTextArea
                    className="form-control form-textarea"
                    onChange={onChange}
                    value={value || ''}
                  ></StylishNewTextArea>
                )}
              />{' '}
            </div>

            <div className="mb-3">
              <label className="form-label d-block">Follower / Following</label>

              <span
                className="badge cursor-pointer"
                onClick={() => {
                  setShowFollowerCount(!showFollowerCount);
                }}
              >
                <img src={IconData} alt="" />
                Data
              </span>

              {showFollowerCount ? (
                <div className="row mt-3">
                  <div className="col-md-6 mb-3 mb-md-0">
                    <label className="form-label">Followers</label>
                    <Controller
                      control={control}
                      name="followers"
                      defaultValue={Math.floor(Math.random() * 90) + 10}
                      // rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <StylishNewInput
                          type="number"
                          onChange={onChange}
                          value={value || ''}
                          className="form-control"
                        />
                      )}
                    />
                  </div>
                  <div className="col-md-6">
                    <label className="form-label">Following</label>
                    <Controller
                      control={control}
                      name="following"
                      defaultValue={Math.floor(Math.random() * 90) + 10}
                      render={({ field: { onChange, value } }) => (
                        <StylishNewInput
                          type="number"
                          name=""
                          onChange={onChange}
                          value={value || ''}
                          className="form-control"
                        />
                      )}
                    />
                  </div>
                </div>
              ) : null}
            </div>
            <div className="mb-3">
              <label className="form-label w-100">Avatar</label>

              <div
                className="badge cursor-pointer"
                onClick={() => {
                  onChooseMediaDialog();
                  setTypeModalProfile('Avatar');
                }}
              >
                <img src={IconChooseMedia} alt="" />
                Choose Media
              </div>

              {isVisibleAvatar && (
                <>
                  <div className="d-flex align-items-start mt-3">
                    <div className="me-3">
                      <img
                        src={imageAvatar}
                        className="rounded-5 w-100 h-auto d-block"
                        alt=""
                      />
                    </div>
                    <StylishNewButton
                      customButton
                      className={'button--icon flex-shrink-0'}
                      onClick={(e) => {
                        setIsVisibleAvatar(false);
                        setImageAvatar(null);
                      }}
                    >
                      <img src={IconTimes} alt="" />
                    </StylishNewButton>
                  </div>
                </>
              )}
              {/* {isVisibleAvatar && (
                    <img src={imageAvatar} className="me-3" alt="" />
                  )} */}
            </div>
            <div className="mb-3">
              <label className="form-label w-100">Banner</label>

              <div
                className="badge cursor-pointer"
                onClick={() => {
                  onChooseMediaDialog();
                  setTypeModalProfile('Banner');
                }}
              >
                <img src={IconChooseMedia} alt="" />
                Choose Media
              </div>

              {isVisibleBanner && (
                <>
                  <div className="d-flex align-items-start mt-3">
                    <div className="flex-grow-1 me-3">
                      <img
                        src={imageBanner}
                        className="rounded-5 w-100 h-auto d-block"
                        alt=""
                      />
                    </div>
                    <StylishNewButton
                      customButton
                      className={'button--icon flex-shrink-0'}
                      onClick={(e) => {
                        setIsVisibleBanner(false);
                        setImageBanner(null);
                      }}
                    >
                      <img src={IconTimes} alt="" />
                    </StylishNewButton>
                  </div>
                </>
              )}
            </div>
            <div className="mb-0">
              <label className="form-label">Link Existing Persona</label>
              <Controller
                control={control}
                name="personas"
                rules={{ required: false }}
                render={({ field: { onChange, value } }) => (
                  <StylishNewSelect
                    options={personas}
                    value={value}
                    onChange={onChange}
                    isClearable={false}
                    isSearchable={false}
                    isMulti={true}
                    isDisabled={false}
                    placeholder="Select Personas"
                  />
                )}
              />
            </div>
          </Modal.Body>

          <Modal.Footer>
            <div className="button-group w-100">
              {/* <button className="button button--primary button--reverse">
                  Random
                </button> */}
              <div className="ms-md-auto w-100 w-md-auto mt-3 mt-md-0">
                <StylishNewButton
                  className="button--secondary button--reverse"
                  onClick={onClose}
                >
                  Cancel
                </StylishNewButton>

                <StylishNewButton className="button--primary" type="submit">
                  Save
                </StylishNewButton>
              </div>
            </div>
          </Modal.Footer>
        </form>
      </Modal>
      {showChooseMediaDialog && (
        <ChooseMediaDialog
          show={showChooseMediaDialog}
          onClose={onChooseMediaDialogClose}
          onSelectedImageAvatar={imageAvatar}
          onSelectedImageBanner={imageBanner}
          imageAvatarFrom={imageAvatarFrom}
          onSubmitImageAvatar={onSubmitImageAvatar}
          onSubmitImageAvatarFrom={onSubmitImageAvatarFrom}
          onSubmitImageBanner={onSubmitImageBanner}
          onSubmitImageBannerFrom={onSubmitImageBannerFrom}
          imageBannerFrom={imageBannerFrom}
          typeModalProfile={typeModalProfile}
        />
      )}
    </div>
  );
}
