import Network from 'PREPAREsrc/service/Network';
import API from 'PREPAREsrc/service/api';
import StylishNewTable from 'components/DesignSystems/New/StylishNewTable';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { endLoading, startLoading } from 'reducers/loading/loading.action';

export const ActivityLog = ({
  mission_timeline_id,
  workspaceId,
  selectedTab,
}) => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [perpage] = useState(10);
  const [total, setTotal] = useState(0);
  const [activityLogData, setActivityLogData] = useState([]);
  useEffect(() => {
    if (mission_timeline_id && selectedTab === 'activity_log') {
      fetchActivityLogData();
    }
  }, [mission_timeline_id, page, selectedTab]);
  const fetchActivityLogData = async () => {
    try {
      dispatch(startLoading());
      const resp = await Network.get(API.getTimelineActivityLog, {
        mission_timeline_id,
        page,
        limit: perpage,
        workspaceId,
      });

      // you may want to use `useMemo` (for React) or `computed` (for Vue)
      // to avoid redundant computations
      const updated_log = resp.data.response.dataset;

      setActivityLogData(updated_log);
      setTotal(resp.data.response.total);
      dispatch(endLoading());
    } catch (error) {
      console.error('error', error);
      dispatch(endLoading());
    }
  };
  const columns = [
    {
      dataField: 'member',
      text: 'Member',
      sort: false,
      attrs: { title: 'Member' },
      formatter: (_, row) => row.name || row.email || '',
    },
    {
      dataField: 'createdAt',
      text: 'Time',
      sort: false,
      attrs: { title: 'Time' },
      formatter: (_, row) => moment(row?.createdAt).format('MM-DD-YYYY HH:mm'),
    },
    {
      dataField: 'update_section',
      text: 'Section',
      sort: false,
      attrs: { title: 'Type' },
      formatter: (_, row) => (
        <span>
          {row?.update_section?.charAt(0).toUpperCase() +
            row?.update_section?.slice(1)}
        </span>
      ),
    },
    {
      dataField: 'log_type',
      text: 'Type',
      sort: false,
      attrs: { title: 'Type' },
      formatter: (_, row) => (
        <span>
          {row?.log_type?.charAt(0).toUpperCase() + row?.log_type?.slice(1)}
        </span>
      ),
    },
  ];
  return (
    <StylishNewTable
      keyField="id"
      columns={columns}
      rows={activityLogData}
      classes="no-filter"
      headerClasses="empty"
      pagination
      total={total}
      page={page}
      perPage={perpage}
      onPageChange={(page) => setPage(page)}
    />
  );
};
