import React, { useState, useEffect } from 'react';
import IconLocation from 'assets/images/icon__location.svg';
import IconClose from 'assets/images/icon__times.svg';
import { SelectLocation } from './SelectLocation';
import { useNavigate } from 'react-router';
import Network from '../../../../../service/Network';
import API from '../../../../../service/api';
import { useSelector, useDispatch } from 'react-redux';
import Help from '../../../../../component/Help/Help';
import { HelpMSELInjectLocation, useSelfHostedKnowledgeBase } from 'assets/data/HelpContent';
import StylishHelp from 'components/DesignSystems/New/StylishHelp';
import { PreparePath } from 'utils/sharedUtils/sharedConst';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { endLoading, startLoading } from "../../../../../../reducers/loading/loading.action";

export const MSELLocation = (props) => {
  const [selectLocation, setSelectLocation] = useState(false);
  const [lng, setLng] = useState('');
  const [lat, setLat] = useState('');
  const [place, setPlace] = useState('');
  const [locId, setLocId] = useState();

  const reduxUser = useSelector((state) => state.prepare.user);
  const { selectedWorkspace } = useSelector((state) => state.prepare.workspace);

  const selfHostedKnowledgeBase = useSelfHostedKnowledgeBase()

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const fetchInjectLocation = async () => {
    dispatch(startLoading());
    try {
      const injectLocation = await Network.get(API.fetchInjectLocation, {
        injectId: props.injectId,
      });
      setLat(injectLocation.data.response.dataset[0].lat || '');
      setLng(injectLocation.data.response.dataset[0].lng || '');
      setPlace(injectLocation.data.response.dataset[0].loc || '');
      setLocId(injectLocation.data.response.dataset[0].id || '');
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(endLoading());
    }
  };

  useEffect(() => {
    fetchInjectLocation();
  }, [reduxUser]);

  const selectLocationHandler = () => {
    setSelectLocation(true);
  };

  const onCloseModal = () => {
    setSelectLocation(false);
  };

  const onSaveClicked = (changedlat, Changedlng, changedplace) => {
    dispatch(startLoading());
    try {
      Network.post(!locId ? API.addInjectLocation : API.updateInjectLocation, {
        injectId: props.injectId,
        workspaceId: selectedWorkspace,
        lat: changedlat,
        lng: Changedlng,
        loc: changedplace,
        id: locId,
      })
        .then((resp) => {
          setLat(resp.data.response.dataset[0].lat);
          setLng(resp.data.response.dataset[0].lng);
          setPlace(resp.data.response.dataset[0].loc);
          setLocId(resp.data.response.dataset[0].id);
        })
        .catch(console.log)
        .finally(() => dispatch(endLoading()));
    } catch (error) {
      console.log(error);
      // } finally {
      //   dispatch(endLoading());
    }
  };

  const deleteLocation = async () => {
    dispatch(startLoading());
    try {
      Network.post(API.deleteInjectLocation, {
        id: locId,
      })
        .then(() => {
          setLat('');
          setLng('');
          setPlace('');
          setLocId(null);
          fetchInjectLocation();
        })
        .catch(console.log)
        .finally(() => dispatch(endLoading()));
    } catch (error) {
      console.log(error);
      // } finally {
      //   dispatch(endLoading());
    }
  };

  return (
    <>
      <div className="d-flex align-items-center mb-4">
        <h4 className="m-0">Location</h4>
        <StylishHelp
          classes={'ms-auto sml flex-shrink-0'}
          title={HelpMSELInjectLocation.title}
          content={HelpMSELInjectLocation.content}
          link={selfHostedKnowledgeBase || HelpMSELInjectLocation.link}
        />
      </div>
      <form action="">
        <div className="form-block">
          <div className="row">
            <div className="col-md-4 col-lg-3 col-xl-2 mb-4">
              <label className="form-label d-block">Training Area</label>
              <span
                className="button--location cursor-pointer"
                onClick={selectLocationHandler}
              >
                <img src={IconLocation} className="me-3" alt="" />
                Select Location
              </span>
            </div>
            {lat && lng && place && (
              <div className="col-md-12">
                <label className="form-label d-block">Training Area</label>
                <div className="cards cards__location rounded bg-gray-700 p-4">
                  <span className="remove" onClick={deleteLocation}>
                    <img src={IconClose} alt="" />
                  </span>
                  <p className="m-0">
                    {place}
                    <br />
                    Lat/Lon: <br />
                    {lat} {lng}
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="col-md-12 mt-4">
          <div className="button-group d-block d-md-flex">
            <StylishNewButton
              type="button"
              className="button button--primary button--reverse w-100 w-md-auto"
              onClick={(e) => {
                e.preventDefault();
                props.onPrevClick(1);
              }}
            >
              Previous
            </StylishNewButton>
            <StylishNewButton
              type="button"
              className="button button--tertiary button--reverse ms-auto mt-3 mt-md-0 w-100 w-md-auto"
              onClick={(e) => {
                e.preventDefault();
                navigate(-1, {
                  replace: true,
                });
              }}
            >
              Save &amp; Exit
            </StylishNewButton>
            <StylishNewButton
              type="submit"
              className="button button--primary  w-100 w-md-auto mt-3 mt-md-0 ms-0 ms-md-3"
              onClick={(e) => {
                e.preventDefault();
                props.onNextClick(3);
              }}
            >
              Next
            </StylishNewButton>
          </div>
        </div>
      </form>
      {selectLocation && (
        <SelectLocation
          show={selectLocation}
          onClose={onCloseModal}
          onSaveClicked={onSaveClicked}
          storedLat={lat}
          storedLng={lng}
          storedPlace={place}
        />
      )}
    </>
  );
};
