import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import StylishNewTable from 'components/DesignSystems/New/StylishNewTable';
import NewRoleDialog from './NewRoleDialog';
import EditRoleDialog from './EditRoleDialog';
import { useNavigate } from 'react-router-dom';
import {
  removeUserFromGroup,
  unassignRole,
  fetchAllRolesInGroup,
} from 'actions/roleActions';
import isUserOnlyAdminInGroup from 'components/Roles/RolesUtils/isUserOnlyAdminInGroup';
import isUserAllowedToBeRemovedFromGroup from 'components/Roles/RolesUtils/isUserAllowedToBeRemovedFromGroup';
import NewRoleAssignmentDialog from './NewRoleAssignmentDialog';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { StylishConfirmDialog } from 'components/DesignSystems/New/StylishConfirmDialog';
import { startLoading, endLoading } from 'reducers/loading/loading.action';

const allColumn = [
  {
    dataField: 'email_address',
    text: 'Member Email',
    sort: true,
    headerAttrs: { title: 'Sort By:' },
    attrs: { title: 'Member Email' },
  },
  {
    dataField: 'role_assignments_string',
    text: 'Organization Roles',
    sort: true,
    attrs: { title: 'Organization Roles' },
  },
];

export default function GroupMembers() {
  const reduxDispatch = useDispatch();
  const navigate = useNavigate();

  const [roleSelectedForEditing] = useState();
  const [isNewRoleDialogOpen, setIsNewRoleDialogOpen] = useState(false);
  const [isEditRoleDialogOpen, setIsEditRoleDialogOpen] = useState(false);
  const [newRoleAssignee, setNewRoleAssignee] = useState(false);
  const [rostersForGroups, setRostersForGroups] = useState([]);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [confirmDialogType, setConfirmDialogType] = useState('');
  const [unassignUserRoleData, setUnassignUserRoleData] = useState([]);
  const [removeGroupUserData, setRemoveGroupUserData] = useState();

  const reduxCurrentlySelectedGroup = useSelector((state) => {
    return state.app.currentlySelectedGroup;
  });

  useEffect(() => {
    reduxDispatch(fetchAllRolesInGroup(reduxCurrentlySelectedGroup.group_guid));
  }, [reduxCurrentlySelectedGroup]);

  const reduxRostersForGroups = useSelector((state) => {
    return state.app.rostersForGroups || [];
  });

  const { user_guid } = useSelector((state) => {
    return state.app.user;
  });

  const reduxValidateRBACPermissionForActionResult = useSelector((state) => {
    return state.app.validateRBACPermissionForActionResult;
  });

  const allGroupQuestions = useSelector((state) => state.app.allGroupQuestions);
  const allGroupQuestionAnswers = useSelector(
    (state) => state.app.allGroupQuestionAnswers
  );

  useEffect(() => {
    reduxDispatch(endLoading());
    if (reduxRostersForGroups.length && reduxCurrentlySelectedGroup) {
      const collection = reduxRostersForGroups
        .filter(
          (member) =>
            member.group_guid === reduxCurrentlySelectedGroup.group_guid
        )
        .map((member) => {
          const allAnswer = [];
          if (allGroupQuestionAnswers && allGroupQuestionAnswers.length > 0) {
            const answers = allGroupQuestionAnswers.filter(
              (ele) => ele.user_guid === member.user_guid
            );
            //  .map((e) => e.answer.trim());
            answers.forEach((answer, index) => {
              allAnswer.push({ [answer.question_id]: answer.answer });
            });
          }
          const prevReturnValue = {
            ...member,
            role_assignments_string: member.role_assignments
              .map((ra) => {
                return ra.name;
              })
              .join(', '),
            ...(member.seats
              ? {
                  seat_assignments_string: member.seats
                    .map((seat) => seat.seat_name)
                    .join(','),
                }
              : {}),
            tags_string: member.role_assignments.map((ra) => {
              return ra.tags
                .map((tag) => {
                  return tag.name;
                })
                .join(', ');
            }),
          };
          return Object.assign({}, prevReturnValue, ...allAnswer);
        });
      setRostersForGroups(collection);
    }
  }, [
    reduxRostersForGroups,
    reduxCurrentlySelectedGroup,
    allGroupQuestionAnswers,
  ]);

  const [columns, setColumns] = useState(allColumn);

  useEffect(() => {
    if (allGroupQuestions && allGroupQuestions.length > 0) {
      const newAllColumn = [
        ...allColumn,
        ...allGroupQuestions.map((ele, index) => {
          return {
            dataField: ele.question_id,
            text: ele.question.trim(),
            sort: true,
            attrs: { title: ele.question.trim() },
          };
        }),
      ];
      setColumns(newAllColumn);
    } else {
      const newAllColumn = [...allColumn];
      setColumns(newAllColumn);
    }
  }, [allGroupQuestions]);
  if (typeof rostersForGroups === 'undefined') {
    return <div>Loading Organization Members...</div>;
  }

  function removeGroupMemberButtonClicked(user) {
    setRemoveGroupUserData(user);
    setConfirmDialogType('delete_member');
    setShowConfirmDialog(true);
  }

  const removeGroupMemberConfirmed = () => {
    reduxDispatch(startLoading());
    reduxDispatch(
      removeUserFromGroup(
        removeGroupUserData.user_guid,
        reduxCurrentlySelectedGroup.group_guid
      )
    );
    setShowConfirmDialog(false);
    setConfirmDialogType('');
  };

  function unassignUserRoleButtonClicked(user, ra) {
    setUnassignUserRoleData([user, ra]);
    setConfirmDialogType('delete_role');
    setShowConfirmDialog(true);
  }

  const unassignUserRoleconfirmed = () => {
    reduxDispatch(startLoading());
    reduxDispatch(
      unassignRole({
        ...unassignUserRoleData[1],
        user_guid: unassignUserRoleData[0].user_guid,
      })
    );
    setShowConfirmDialog(false);
    setConfirmDialogType('');
  };

  const userRow =
    !!reduxRostersForGroups &&
    !!reduxCurrentlySelectedGroup &&
    !!reduxRostersForGroups.length &&
    reduxRostersForGroups.find(
      (r) =>
        r.user_guid === user_guid &&
        r.group_guid === reduxCurrentlySelectedGroup.group_guid
    );
  const userIsAdminForGroup =
    (!!userRow &&
      !!userRow.role_assignments &&
      !!userRow.role_assignments.length &&
      userRow.role_assignments.find((ra) => ra.name === 'Group Admin')) ||
    false;

  const expandRow = {
    parentClassName: 'parent-expand-tr',
    className: 'child-expand-tr',
    renderer: (row) => {
      return (
        <>
          <div className="row">
            <div className="col-md-6">
              <h6>Profile</h6>
              <span className="d-block">Email: {row.email_address}</span>
              {!!row.profile_settings && (
                <>
                  <span className="d-block">
                    Phone (Mobile): {row.profile_settings.mobile_phone_number}
                  </span>
                  <span className="d-block">
                    Phone (Home): {row.profile_settings.home_phone_number}
                  </span>
                  <span className="d-block">
                    Pager: {row.profile_settings.pager_address}
                  </span>
                </>
              )}
            </div>
            <div className="col-md-6">
              <h6>Organization Roles</h6>
              {!!row.role_assignments &&
                !!row.role_assignments.length &&
                row.role_assignments.map((ra) => {
                  return (
                    <span className="d-block" key={'GroupMembers-' + ra.name}>
                      {ra.name}
                      {((ra.name === 'Group Admin' &&
                        !isUserOnlyAdminInGroup(
                          reduxCurrentlySelectedGroup.group_guid,
                          reduxRostersForGroups
                        )) ||
                        ra.name !== 'Group Admin') &&
                        !!reduxValidateRBACPermissionForActionResult[
                          'Unassign Member from Role'
                        ] && (
                          <span
                            className="anchor ms-2"
                            onClick={() =>
                              unassignUserRoleButtonClicked(row, ra)
                            }
                          >
                            Remove
                          </span>
                        )}
                    </span>
                  );
                })}
            </div>
          </div>
          <div className="button-group mt-4">
            {row.allow_admin_profile_edits && userIsAdminForGroup && (
              <StylishNewButton
                onClick={() => navigate(`/profile/${row.user_guid}`)}
                className="button--primary"
              >
                Edit Profile
              </StylishNewButton>
            )}
            {!!reduxValidateRBACPermissionForActionResult[
              'Assign Member to Role'
            ] && (
              <StylishNewButton
                className="button--primary"
                onClick={() => setNewRoleAssignee(row.user_guid)}
              >
                Edit Roles
              </StylishNewButton>
            )}
            {isUserAllowedToBeRemovedFromGroup(
              row,
              user_guid,
              reduxValidateRBACPermissionForActionResult,
              reduxCurrentlySelectedGroup,
              reduxRostersForGroups
            ) && (
              <StylishNewButton
                className="button--tertiary button--reverse"
                onClick={() => removeGroupMemberButtonClicked(row)}
              >
                Remove from Group
              </StylishNewButton>
            )}
          </div>
        </>
      );
    },
    showExpandColumn: true,
    expandByColumnOnly: true,
    expandHeaderColumnRenderer: ({ isAnyExpands }) => {
      if (isAnyExpands) {
        return <i className="fa fa-chevron-down" aria-hidden="true"></i>;
      }
      return <i className="fa fa-chevron-right" aria-hidden="true"></i>;
    },
    expandColumnRenderer: ({ expanded }) => {
      if (expanded) {
        return (
          <>
            <div className="d-flex align-items-center justify-content-md-center">
              <span className="me-2">Details</span>
              <i className="fa fa-chevron-down" aria-hidden="true"></i>
            </div>
          </>
        );
      }
      return (
        <>
          <div className="d-flex align-items-center justify-content-md-center">
            <span className="me-2">Details</span>
            <i className="fa fa-chevron-right" aria-hidden="true"></i>
          </div>
        </>
      );
    },
  };

  return (
    <>
      <h4>Organization Members</h4>
      <div className="form-block">
        <StylishNewTable
          keyField={'user_guid'}
          columns={columns}
          rows={rostersForGroups}
          expandRow={expandRow}
        />
      </div>

      {isNewRoleDialogOpen && (
        <NewRoleDialog
          onClose={() => setIsNewRoleDialogOpen(false)}
        ></NewRoleDialog>
      )}
      {isEditRoleDialogOpen && (
        <EditRoleDialog
          onClose={() => setIsEditRoleDialogOpen(false)}
          roleSelectedForEditing={roleSelectedForEditing}
        ></EditRoleDialog>
      )}
      {newRoleAssignee && (
        <NewRoleAssignmentDialog
          show={newRoleAssignee}
          onClose={() => setNewRoleAssignee(false)}
          group_guid={reduxCurrentlySelectedGroup.group_guid}
          assignee_guid={newRoleAssignee}
        />
      )}
      {showConfirmDialog && (
        <StylishConfirmDialog
          show={showConfirmDialog}
          dialogTitle={'Delete Confirmation'}
          dialogContent={
            confirmDialogType === 'delete_role'
              ? `Remove '${unassignUserRoleData[0].email_address}' from the role '${unassignUserRoleData[1].name}'?`
              : confirmDialogType === 'delete_member'
              ? `Remove '${removeGroupUserData.email_address}' from this group?`
              : ''
          }
          onClose={() => setShowConfirmDialog(false)}
          onConfirm={
            confirmDialogType === 'delete_role'
              ? unassignUserRoleconfirmed
              : confirmDialogType === 'delete_member'
              ? removeGroupMemberConfirmed
              : null
          }
        />
      )}
    </>
  );
}
