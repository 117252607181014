import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import StylishNewSelect from 'components/DesignSystems/New/StylishNewSelect';
import StylishDateTimePicker from 'components/DesignSystems/form/StylishDateTimePicker';
import moment from 'moment';
import StylishNewTable from 'components/DesignSystems/New/StylishNewTable';
import StylishNewTableAction from 'components/DesignSystems/New/StylishNewTableAction';
import { useEffect, useState } from 'react';
import { AddEditPoamDialog } from './AddEditPoamDialog';
import Network from 'PREPAREsrc/service/Network';
import API from 'PREPAREsrc/service/api';
import { endLoading, startLoading } from 'reducers/loading/loading.action';
import { useDispatch, useSelector } from 'react-redux';
import { StylishConfirmDialog } from 'components/DesignSystems/New/StylishConfirmDialog';
import { toast } from 'react-toastify';
import { toastConfig } from 'assets/data/config';
import { Controller, useForm } from 'react-hook-form';
import { PaginationDropdown } from 'components/DesignSystems/PaginationDropdown';

const styleLabel = {
  width: '2rem',
  height: '2rem',
  borderRadius: '5px',
  display: 'block',
};

const legends = [
  {
    name: 'Overdue',
    color: '#FF0000',
  },
  {
    name: 'In-Progress',
    color: '#FBC02D',
  },
  {
    name: 'Completed',
    color: '#20BA38',
  },
  {
    name: 'Not Started',
    color: '#FFFFFF',
  },
];

export const POAM = (props) => {
  const { selectedTab } = props;

  const { control, setValue } = useForm();

  const [showAddEditPoamDialog, setShowAddEditPoamDialog] = useState(false);
  const [addEditPoamDialogType, setAddEditPoamDialogType] = useState('Add');
  const [addEditPoamData, setAddEditPoamData] = useState([]);
  const [poamList, setPOAMList] = useState([]);
  const [poamCount, setPOAMCount] = useState(0);
  const [supportList, setSupportList] = useState([]);
  const [selectedSupport, setSelectedSupport] = useState(null);
  const [selectedCell, setSelectedCell] = useState(null);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [toBeDeleteData, setToBeDeleteData] = useState();
  const [eventCells, setEventCells] = useState([]);
  const [eventCellList, setEventCellList] = useState([]);
  const [filterDate, setFilterDate] = useState(null);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  const dispatch = useDispatch();

  const { eventId } = useSelector((state) => state.prepare.planDashboard);
  const { selectedWorkspace } = useSelector((state) => state.prepare.workspace);

  const getEventCells = () => {
    dispatch(startLoading());
    if (selectedWorkspace) {
      try {
        Network.get(API.getAllEventCell, {
          workspaceId: selectedWorkspace,
          planEventId: eventId,
          limit: perPage.value,
          page: page,
          search: '',
        })
          .then((resp) => {
            setEventCellList(resp.data.response.dataset);
          })
          .catch(console.log)
          .finally(() => {
            dispatch(endLoading());
          });
      } catch (error) {
        console.log(error);
        toast.error(error?.response?.data?.response?.status?.msg, toastConfig);
      }
    }
  };

  const columns = [
    {
      dataField: 'status',
      text: 'Status',
      sort: true,
      attrs: { title: 'Status' },
      headerStyle: {
        width: '100px',
      },
      formatter: (cell, row) => {
        let color =
          row?.status === 'Overdue' || row?.status === 'overdue'
            ? '#FF0000'
            : row?.status === 'in-progress'
            ? '#FBC02D'
            : row?.status === 'completed'
            ? '#20BA38'
            : row?.status === 'not started'
            ? '#FFFFFF'
            : null;
        return (
          <span
            style={{ ...styleLabel, background: color }}
            className="me-2"
          ></span>
        );
      },
    },
    {
      dataField: 'taskNo',
      text: 'Task ID',
      headerAttrs: { title: 'Sort By:' },
      sort: true,
      attrs: { title: 'Task ID' },
    },
    {
      dataField: 'task',
      text: 'Task',
      sort: true,
      attrs: { title: 'Task' },
    },
    {
      dataField: 'cell',
      text: 'Cell',
      sort: true,
      attrs: { title: 'Cell' },
    },
    {
      dataField: 'support',
      text: 'Supports',
      sort: true,
      attrs: { title: 'Supports' },
    },
    {
      dataField: 'dueDate',
      text: 'Due Date',
      sort: true,
      attrs: { title: 'Due Date' },
      formatter: (cell, row) => <>{moment(row?.dueDate).format('DD MMM YY')}</>,
    },
    {
      dataField: 'days_due',
      text: 'Days Due',
      sort: false,
      attrs: { title: 'Days Due' },
      formatter: (cell, row) => (
        <>
          {row?.status === 'completed'
            ? 0
            : moment(row?.dueDate).diff(moment(), 'days') >= 0 &&
              !moment(row?.dueDate)
                .startOf('day')
                .isSame(moment().startOf('day'))
            ? moment(row?.dueDate).diff(moment(), 'days') + 1
            : moment(row?.dueDate).diff(moment(), 'days')}
        </>
      ),
    },
    {
      dataField: 'assigned',
      text: 'Assigned to',
      sort: true,
      attrs: { title: 'Assigned' },
    },
    {
      dataField: 'completed_on',
      text: 'Completed On',
      sort: false,
      attrs: { title: 'Completed On' },
      formatter: (cell, row) => (
        <>
          {row?.completed_on
            ? moment(row?.completed_on).format('DD MMM YYYY HH:mm')
            : '-'}
        </>
      ),
    },
    {
      dataField: 'linked_artifact',
      text: 'Linked Artifact',
      sort: false,
      attrs: { title: 'Linked Artifact' },
    },
    {
      text: 'Action',
      dataField: 'action',
      isDummyField: true,
      formatter: (cell, row, rowIndex) => (
        <StylishNewTableAction
          name="POA&M"
          cell={cell}
          row={row}
          rowIndex={rowIndex}
          onDelete={(row) => onDeletePOAM(row)}
          onEdit={(row) => {
            setShowAddEditPoamDialog(true);
            setAddEditPoamDialogType('Edit');
            setAddEditPoamData(row);
          }}
        />
      ),
      attrs: { title: 'Action' },
      headerStyle: {
        width: '100px',
      },
      headerAlign: 'center',
      align: 'center',
    },
  ];

  const onDeletePOAM = (poam) => {
    setShowConfirmModal(true);
    setToBeDeleteData(poam);
  };

  const onDeleteConfirm = async () => {
    try {
      dispatch(startLoading());
      await Network.post(API.deletePOAM, { id: toBeDeleteData?.id });
      setToBeDeleteData();
      setShowConfirmModal(false);
      resetFilters();
      fetchPOAMs();
      toast.success('POAM Deleted Successfully', toastConfig);
    } catch (error) {
      toast.error('Failed to delete POAM', toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };

  const handlePageClick = (page) => {
    setPage(page);
  };

  const fetchPOAMs = () => {
    try {
      dispatch(startLoading());
      Network.get(API.fetchPOAMs, {
        workspaceId: selectedWorkspace,
        planEventId: eventId,
        limit: perPage,
        page: page,
        support_search: selectedSupport,
        cell_search: selectedCell,
        date_search: filterDate,
        sort: 'dueDate_ASC',
      })
        .then((resp) => {
          setPOAMList(
            resp?.data?.response?.dataset?.map((data) => ({
              ...data,
              linked_artifact: (
                <a
                  href={data.linkedArtifact}
                  target={'_blank'}
                  className="anchor anchor--white weight-400"
                >
                  {data.linkedArtifact}
                </a>
              ),
            }))
          );
          setPOAMCount(resp?.data?.response?.total);
          dispatch(endLoading());
        })
        .catch((error) => {
          console.log(error);
          dispatch(endLoading());
        });
    } catch (error) {
      console.log(error);
      dispatch(endLoading());
    }
  };

  const fetchSupportList = () => {
    try {
      dispatch(startLoading());
      Network.get(API.getSupportList, {
        workspaceId: selectedWorkspace,
      })
        .then((resp) => {
          setSupportList(
            resp?.data?.response?.dataset?.map((data) => ({
              value: data?.id,
              label: data?.title,
            }))
          );
          dispatch(endLoading());
        })
        .catch((error) => {
          console.log(error);
          dispatch(endLoading());
        });
    } catch (error) {
      console.log(error);
      dispatch(endLoading());
    }
  };

  const fetchCell = () => {
    try {
      dispatch(startLoading());
      Network.get(API.fetchAllCell, {
        workspaceId: selectedWorkspace,
        planEventId: eventId,
      })
        .then((resp) => {
          setEventCells(
            resp?.data?.response?.dataset?.map((data) => ({
              value: data?.id,
              label: data?.cell,
            }))
          );
          dispatch(endLoading());
        })
        .catch((error) => {
          console.log(error);
          dispatch(endLoading());
        });
    } catch (error) {
      console.log(error);
      dispatch(endLoading());
    }
  };

  useEffect(() => {
    if (selectedTab === 'poam') {
      fetchPOAMs();
      fetchSupportList();
      fetchCell();
      getEventCells();
    } else {
      resetFilters();
    }
  }, [
    selectedWorkspace,
    page,
    selectedSupport,
    filterDate,
    selectedTab,
    selectedCell,
    eventId,
    perPage,
  ]);

  useEffect(() => {
    setPage(1);
  }, [filterDate, selectedSupport, selectedCell, selectedWorkspace, eventId]);

  const resetFilters = () => {
    setPage(1);
    setSelectedCell(null);
    setSelectedSupport(null);
    setFilterDate(null);
    setValue('support', null);
    setValue('cell', null);
  };

  return (
    <>
      <div className="d-flex flex-column flex-lg-row align-items-md-start align-items-lg-center mb-4">
        <StylishNewButton
          type="button"
          className={
            'button--primary order-1 order-md-0 mb-md-3 mb-lg-0 mt-3 mt-md-0'
          }
          onClick={() => {
            setShowAddEditPoamDialog(true);
            setAddEditPoamDialogType('Add');
          }}
        >
          Create Task
        </StylishNewButton>
        <div className="d-flex flex-wrap align-items-center flex-shrink-0 ms-lg-auto">
          {legends.map((i, idx) => (
            <div
              key={idx}
              className={`d-flex align-items-center w-50 w-md-auto ${
                idx !== 0 ? 'ms-md-4' : ''
              } ${idx > 1 ? 'mt-2 mt-md-0' : ''}`}
            >
              <span
                style={{ ...styleLabel, background: i?.color }}
                className="me-2"
              ></span>
              {i?.name}
            </div>
          ))}
        </div>
      </div>
      <div className="d-xl-flex align-items-center mb-4">
        <h5 className="mb-3 mb-xl-0">Plan of Action and Milestones (POA&M)</h5>
        <div className="d-md-flex align-items-center flex-shrink-0 ms-auto">
          <label htmlFor="pageSize">Page Size </label>
          <PaginationDropdown
            setPage={setPage}
            perPage={perPage}
            setPerPage={setPerPage}
            options={[
              { value: 10, label: 10 },
              { value: 25, label: 25 },
              { value: 50, label: 50 },
              { value: 75, label: 75 },
              { value: 100, label: 100 },
            ]}
            className={'ms-md-3 me-md-3 mt-3 mt-md-0'}
          />
          <div className="d-flex align-items-center">
            <div className="flex-grow-1">
              <StylishDateTimePicker
                value={filterDate}
                onChange={setFilterDate}
                disableDateTimeLabel
              />
            </div>
          </div>
          <Controller
            control={control}
            name="support"
            rules={{ required: false }}
            render={({ field: { onChange, value } }) => (
              <StylishNewSelect
                value={value}
                options={supportList}
                onChange={(e) => {
                  setSelectedSupport(e?.value);
                  setPage(1);
                  onChange(e);
                }}
                isSearchable={false}
                isClearable={true}
                isMulti={false}
                placeholder={'Select Support'}
                classes={'ms-md-3 mt-3 mt-md-0'}
              />
            )}
          />
          <Controller
            control={control}
            name="cell"
            rules={{ required: false }}
            render={({ field: { onChange, value } }) => (
              <StylishNewSelect
                value={value}
                options={eventCells}
                onChange={(e) => {
                  setSelectedCell(e?.value);
                  setPage(1);
                  onChange(e);
                }}
                isSearchable={false}
                isClearable={true}
                isMulti={false}
                classes={'ms-md-3 mt-3 mt-md-0'}
                placeholder={'Select Cell'}
              />
            )}
          />
          <StylishNewButton
            onClick={resetFilters}
            type="button"
            className="button--link ms-3"
            disabled={!filterDate && !selectedSupport && !selectedCell}
          >
            Clear
          </StylishNewButton>
        </div>
      </div>
      <StylishNewTable
        keyField={'id'}
        columns={columns}
        rows={poamList}
        pagination={true}
        total={poamCount}
        page={page}
        perPage={perPage}
        onPageChange={(page) => handlePageClick(page)}
      />

      {showAddEditPoamDialog && (
        <AddEditPoamDialog
          show={showAddEditPoamDialog}
          onClose={(isPOAMAdded = false, isSupportAdded = false) => {
            if (isPOAMAdded) {
              fetchPOAMs();
            }
            if (isSupportAdded) {
              fetchSupportList();
            }
            setShowAddEditPoamDialog(false);
            setAddEditPoamData(null);
          }}
          dialogType={addEditPoamDialogType}
          editPoamData={addEditPoamData}
          eventCellList={eventCellList}
        />
      )}

      {showConfirmModal && (
        <StylishConfirmDialog
          show={showConfirmModal}
          dialogTitle={'Delete POAM?'}
          dialogContent={`Are you sure you want to delete POAM? By deleting this POAM all of its data will be deleted.`}
          onClose={() => setShowConfirmModal(false)}
          onConfirm={onDeleteConfirm}
        />
      )}
    </>
  );
};
