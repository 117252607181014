import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import AdminUser from './AdminUser';
import ProfileScreen from './ProfileScreen';
import {
  fetchUserSubscriptions,
  getAllUserWorkspace,
  getUserById,
} from 'actions/profileActions';
// import NewUserProfileDialog from './NewUserProfileDialog';
import { useLocation } from 'react-router-dom';
import ManageUserSchedule from './ManageUserSchedule';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { StylishNewToggleButtonGroup } from 'components/DesignSystems/New/StylishNewToggleButtonGroup';
import { endLoading, startLoading } from 'reducers/loading/loading.action';
import moment from 'moment';
import StripeCheckout from 'components/SubscriptionManagement/StripeCheckout'

export default function ProfileContent() {
  const reduxDispatch = useDispatch();
  const location = useLocation();
  const [focusUser, setFocusUser] = useState(false);

  // const [isNewUserProfileDialogOpen, setIsNewUserProfileDialogOpen] = useState(
  //   (!!location && !!location.state && !!location.state.is_new_user) || false
  // );
  const urlUserGuid = location.pathname.split('/').slice(-1).join();

  const { user_guid } =
    useSelector((state) => {
      return state.app.user;
    }) || {};

  const reduxUser = useSelector((state) => {
    return state.app.user;
  });

  let reduxFocusUser = useSelector((state) => {
    return state.app.focusUser;
  });

  const featureFlags = useSelector(state=>state.app.featureFlags)

  const [user, setUser] = useState({});
  const [isFromLink, setIsFromLink] = useState(false);
  const [selectedTab, setSelectedTab] = useState('Manage Organizations');
  const [userSortName, setUserSortName] = useState('');

  useEffect(() => {
    if (reduxUser && reduxUser.epic_user_id) {
      reduxDispatch(getAllUserWorkspace(reduxUser.epic_user_id));
    }
  }, [user_guid]);

  useEffect(() => {
    if (!urlUserGuid) return;

    if (reduxFocusUser && urlUserGuid !== user_guid) {
      if (urlUserGuid === 'profile') {
        setFocusUser(false);
      } else {
        setFocusUser(true);
      }

      setUser(reduxFocusUser);
    } else if (user_guid) {
      reduxDispatch(fetchUserSubscriptions());
      setUser(reduxUser);
      if (
        reduxUser.profile_settings &&
        reduxUser.profile_settings.is_new_user === 'true'
      ) {
        setIsFromLink(false);
        // setIsNewUserProfileDialogOpen(true);
      }
      setFocusUser(false);
    }
  }, [user_guid, reduxUser, reduxFocusUser, urlUserGuid]);

  useEffect(() => {
    if (urlUserGuid && urlUserGuid !== user_guid) {
      reduxDispatch(startLoading());
      setFocusUser(true);
      reduxDispatch(
        getUserById(urlUserGuid !== 'profile' ? urlUserGuid : user_guid)
      );
      if (urlUserGuid === 'profile') {
        setFocusUser(false);
      }
      // reduxDispatch(getUserById(user_guid));
    } else {
      setUser(reduxUser);
      setFocusUser(false);
    }
  }, [urlUserGuid]);

  const toggleData = [
    { label: 'Manage Organizations', value: 'Manage Organizations' },
    { label: 'Manage Schedule', value: 'Manage Schedule' },
  ];

  const handleToggleButtonClick = (value) => {
    setSelectedTab(value);
  };

  useEffect(() => {
    const sortName =
      user &&
      user?.user_name &&
      user?.user_name
        .match(/(\b\S)?/g)
        .join('')
        .match(/(^\S|\S$)?/g)
        .join('')
        .toUpperCase();
    setUserSortName(sortName);
  }, [user]);

  return (
    <>
      <div className="d-md-flex align-items-center mb-4 mb-md-5">
        {/* <div>
          <h6 className="mb-0 mb-md-1">User Profile:</h6>
          {user.user_name}
        </div>
        <div className="ms-md-5 mt-2 mt-md-0">
          <h6 className="mb-0 mb-md-1">User Email:</h6>
          {user.email_address}
        </div> */}
        <div className="d-md-flex align-items-center flex-grow-1 me-md-4">
          <div className="initials flex-shrink-0">
            <h1>{userSortName}</h1>
          </div>
          <div className="mt-3 mt-md-0 ms-md-4 flex-grow-1 overflow-hidden">
            <h5 className="mb-1 text-truncate">{user?.user_name}</h5>
            <p className="m-0 text-truncate">{user?.email_address}</p>
            <div className="d-lg-flex align-items-center mt-3">
              {/* Warning Alert */}
              {featureFlags.includes("SUBSCRIPTION_MANAGEMENT") && user.subscription && !user.subscription.is_expired ? (
                <div
                  className="alert alert-warning d-flex align-items-center rounded m-0"
                  role="alert"
                  style={{ paddingTop: '.625rem', paddingBottom: '.625rem' }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="bi bi-exclamation-triangle-fill flex-shrink-0 me-2 img-h-24"
                    viewBox="0 0 16 16"
                    role="img"
                    aria-label="Warning:"
                    style={{ fill: 'currentcolor' }}
                  >
                    <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                  </svg>
                  <div className="weight-500">
                    {`${
                      !user.subscription.is_enterprise_client
                        ? user.subscription.current_status === 'Trial'
                          ? 'Trial'
                          : 'Plan'
                        : 'Plan'
                    }`}{' '}
                    {`Expires in ${moment(
                      user.subscription.subscription_end_on
                    ).diff(moment(), 'days')} Days`}
                  </div>
                </div>
              ) : (

                featureFlags.includes("SUBSCRIPTION_MANAGEMENT") && (
                <div
                  className="alert alert-danger d-flex align-items-center rounded m-0"
                  role="alert"
                  style={{ paddingTop: '.625rem', paddingBottom: '.625rem' }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="bi bi-exclamation-triangle-fill flex-shrink-0 me-2 img-h-24"
                    viewBox="0 0 16 16"
                    role="img"
                    aria-label="Warning:"
                    style={{ fill: 'currentcolor' }}
                  >
                    <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                  </svg>
                  <div className="weight-500">Your 30 Day Trial Has Ended</div>
                </div>
                )
              )}
             
              {/* Success Alert */}
              <StylishNewButton
                className={
                  'button--primary ms-lg-3 mt-3 mt-lg-0 w-100 w-md-auto'
                }
                onClick={() => {
                  window.open(
                    'https://www.disastertech.com/contact-us',
                    '_blank'
                  );
                }}
              >
                Contact Sales
              </StylishNewButton>

            </div>

          </div>
        </div>
       
      </div>

      {!!featureFlags.includes("STRIPE") && (
        <StripeCheckout />
      )}
      
      <div className="form-block mb-5">
        <ProfileScreen
          userId={user.user_guid}
          userEmail={user.email_address}
          userName={user.user_name}
          planId={user.plan_id}
          subscriptionId={user.subscription_id}
          subscriptionName={user.subscription_name}
          status={user.status}
          profileSettings={user.profile_settings}
        />
      </div>
      <div className="form-block">
        <div className="d-md-flex align-items-start mb-4">
          {/* {!focusUser && selectedTab === 'Manage Organizations' ? (
            <h4 className="m-0">User Groups and Incidents</h4>
          ) : selectedTab === 'Manage Schedule' ? (
            <h4 className="m-0">User Schedule</h4>
          ) : null} */}
          <div className="flex-grow-1 me-4">
            <h4 className="mb-1">User Sponsor Organization</h4>
            <p className="m-0">
              Note: Sponsor Organizations, Exercises, and Incidents that you are
              a part of will appear below
            </p>
          </div>
          <StylishNewToggleButtonGroup
            data={toggleData}
            selected={selectedTab}
            onChange={handleToggleButtonClick}
            size={'sml'}
            className="d-md-inline-flex ms-auto mt-3 mt-md-0 flex-shrink-0 flex-column flex-md-row"
          />
        </div>
        {!focusUser && selectedTab === 'Manage Organizations' && (
          <>
            <AdminUser user={user} />
          </>
        )}
        {selectedTab === 'Manage Schedule' && (
          <ManageUserSchedule user={user} />
        )}
      </div>
      {/* {!!isNewUserProfileDialogOpen && (
        <NewUserProfileDialog
          show={!!isNewUserProfileDialogOpen}
          onClose={() => setIsNewUserProfileDialogOpen(false)}
          isFromLink={isFromLink}
        />
      )} */}
    </>
  );
}
