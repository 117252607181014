import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Help from '../../../component/Help/Help';
import { NoiseHelp, useSelfHostedKnowledgeBase } from 'assets/data/HelpContent';
import HelpIcon from 'assets/images/icon__help.svg';
import PostIcon from 'assets/images/icon__posted--blue.svg';
import Ticon from 'assets/images/icon__twitter.svg';
import IconLocation from 'assets/images/icon__location.svg';
import IconHour from 'assets/images/icon__hour.svg';
import IconDay from 'assets/images/icon__day.svg';
import IconWeek from 'assets/images/icon__week.svg';
import IconMonth from 'assets/images/icon__month.svg';
import IconEver from 'assets/images/icon__ever.svg';
import SampleGraph from 'assets/images/sample__graph--noise.svg';
import { StylishNewChip } from 'components/DesignSystems/New/StylishNewChip';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { PreparePath } from 'utils/sharedUtils/sharedConst';
import { BsFillSquareFill } from 'react-icons/bs';
import { SelectLocation } from 'PREPAREsrc/pages/plan/Prepare/MSEL/Location/SelectLocation';
import StylishNewInput from 'components/DesignSystems/New/StylishNewInput';
import Network from 'PREPAREsrc/service/Network';
import API from 'PREPAREsrc/service/api';
import { useSelector, useDispatch } from 'react-redux';
import {
  endLoading,
  startLoading,
} from '../../../../reducers/loading/loading.action';
import PageTitle from 'components/headers/PageTitle';
import { useNewUIFlag } from 'utils/featureFlags';

export default function Noise() {
  const selfHostedKnowledgeBase = useSelfHostedKnowledgeBase();

  const workspaceId = useSelector(
    (state) => state.prepare.workspace.selectedWorkspace
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const newUI = useNewUIFlag();

  const [valueNoise, setValueNoise] = useState(0);
  const [inputValue, setInputValue] = useState('');
  const [chips, setChips] = useState([]);
  const [savedChips, setSavedChips] = useState([]);
  const [noiseData, setNoiseData] = useState({});
  const [recentPosts, setRecentPosts] = useState([]);
  const [valueSource, setvalueSource] = useState(25);
  const [selectLocation, setSelectLocation] = useState(false);

  const handleMouseUp = (e) => {
    let joinedChips = '';
    if (e.target.value !== 0) {
      setValueNoise(parseInt(e.target.value));
      if (chips && chips.length > 0) {
        joinedChips = chips.join(',');
      } else {
        joinedChips = '';
      }

      dispatch(startLoading());
      try {
        Network.post(API.updateMediaNoise, {
          postRate: parseInt(e.target.value),
          filterTags: joinedChips,
          workspaceId: workspaceId,
        })
          .then(() => {})
          .catch(console.log)
          .finally(() => {
            dispatch(endLoading());
          });
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleAddClick = () => {
    if (inputValue.trim() !== '') {
      setChips(chips ? [...chips, inputValue] : [inputValue]);
      setInputValue('');
    }
  };

  const handleSaveClick = () => {
    const joinedChips = chips.join(',');
    setSavedChips([...savedChips, ...chips]);
    dispatch(startLoading());
    try {
      Network.post(API.updateMediaNoise, {
        postRate:
          parseInt(valueNoise) !== 0
            ? parseInt(valueNoise)
            : noiseData?.postRate,
        filterTags: joinedChips,
        workspaceId: workspaceId,
      })
        .then(() => {})
        .catch(console.log)
        .finally(() => {
          dispatch(endLoading());
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleChipDelete = (index) => {
    const updatedChips = [...chips];
    updatedChips.splice(index, 1);
    setChips(updatedChips);
  };

  const noiseDetails = () => {
    dispatch(startLoading());
    try {
      Network.get(API.mediaNoiseDetails, {
        workspaceId: workspaceId,
      })
        .then((resp) => {
          setNoiseData(resp.data.response.noiseData);
          setRecentPosts(resp.data.response.recentPostedData);
          if (resp.data.response.noiseData?.filterTags !== '') {
            setChips(resp.data.response.noiseData?.filterTags.split(','));
          }
          if (resp.data.response.noiseData !== null) {
            setValueNoise(resp.data.response.noiseData?.postRate);
          } else {
            setValueNoise(0);
          }
        })
        .catch(console.log)
        .finally(() => {
          dispatch(endLoading());
        });
    } catch (error) {
      console.log(error);
    }
  };

  const formatTimestamp = (timestamp) => {
    const now = new Date();
    const date = new Date(Date.parse(timestamp));
    if (isNaN(date.getTime())) {
      return 'Invalid Date';
    }

    const diffInMilliseconds = now - date;
    const diffInSeconds = Math.floor(diffInMilliseconds / 1000);
    const diffInMinutes = Math.floor(diffInSeconds / 60);
    const diffInHours = Math.floor(diffInMinutes / 60);
    const diffInDays = Math.floor(diffInHours / 24);

    if (diffInDays === 1) {
      return (
        'A day ago at ' +
        date.toLocaleDateString('en-US', { month: 'short', day: 'numeric' }) +
        ' ' +
        date.getFullYear()
      );
    } else {
      return (
        date.toLocaleDateString('en-US', { month: 'short', day: 'numeric' }) +
        ' ' +
        date.getFullYear()
      );
    }
  };

  useEffect(() => {
    noiseDetails();
  }, [workspaceId]);

  return (
    <>
      <div className="d-flex align-items-start mb-4">
        <div className="d-flex align-items-start flex-column mb-4">
          {newUI ? (
            <PageTitle title="Noise" parents={['Media', 'Posts']} />
          ) : (
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb mb-0">
                <li className="breadcrumb-item">
                  <StylishNewButton onClick={() => navigate(-1)}>
                    <i className="fa fa-angle-left me-2" aria-hidden="true"></i>
                    Back
                  </StylishNewButton>
                </li>
                <li className="breadcrumb-item">
                  <StylishNewButton
                    onClick={() =>
                      navigate(`${PreparePath}/dashboard/media/posts`)
                    }
                  >
                    Posts
                  </StylishNewButton>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Noise
                </li>
              </ol>
            </nav>
          )}
        </div>

        <Help
          classes={'flex-shrink-0 ms-auto sml mt-4'}
          title={NoiseHelp.title}
          content={NoiseHelp.content}
          link={selfHostedKnowledgeBase || NoiseHelp.link}
        />
      </div>

      <div className="row">
        <div className="col-lg-6">
          <div className="bg-gray-800 p-4 mb-4">
            <div className="d-flex align-items-center mb-2">
              <h4 className="m-0">Post Rate</h4>

              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip>
                    <p className="text-start m-0">
                      Drag the slider right to increase noise. To stop or
                      decrease noise, slide to 0.
                    </p>
                  </Tooltip>
                }
              >
                <span className="button--icon sml flex-shrink-0 rounded-circle ms-3">
                  <img src={HelpIcon} alt="" />
                </span>
              </OverlayTrigger>
            </div>

            <div className="d-flex align-items-center mb-4 weight-500">
              <span className="me-3">Noise Post Per Hour</span>
              <span className="ms-auto">
                {valueNoise || noiseData?.postRate || 0}
              </span>
            </div>
            <input
              type="range"
              className="form-range d-block"
              id="customRange1"
              value={valueNoise || noiseData?.postRate || 0}
              onChange={(e) => setValueNoise(e.target.value)}
              onMouseUp={handleMouseUp}
            />
          </div>

          <div className="bg-gray-800 p-4 mb-4 d-none">
            <h4>Location</h4>
            <div className="d-flex">
              <span
                className="bg-gray-700 rounded p-3 cursor-pointer"
                onClick={() => setSelectLocation(true)}
              >
                <img src={IconLocation} className="me-3 img-h-20" alt="" />
                Select Location
              </span>
            </div>
          </div>

          <div className="bg-gray-800 p-4 mb-4 d-none">
            <div className="d-flex align-items-center mb-3">
              <h4 className="mb-0">Source Type</h4>

              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip>
                    <p className="text-start mb-0">
                      Control source types with a Live feed that pulls from the
                      selected location and uploaded sources.
                    </p>
                  </Tooltip>
                }
              >
                <span className="button--icon sml flex-shrink-0 rounded-circle ms-3">
                  <img src={HelpIcon} alt="" />
                </span>
              </OverlayTrigger>
            </div>

            <div className="row align-items-center">
              <div className="col-xl-6">
                <div className="mb-4">
                  <div className="d-flex align-items-center mb-2">
                    <BsFillSquareFill
                      style={{
                        color: '#E69F00',
                      }}
                    />
                    <span className="ms-2 weight-500">Bot</span>
                  </div>
                  <div className="d-flex align-items-center">
                    <input
                      type="range"
                      className="form-range d-block"
                      id="bot"
                      value={valueSource}
                      onChange={(e) => setvalueSource(e.target.value)}
                    />

                    <span className="weight-500 ms-3">{valueSource}%</span>
                  </div>
                </div>
                <div className="mb-0">
                  <div className="d-flex align-items-center mb-2">
                    <BsFillSquareFill
                      style={{
                        color: '#0099FF',
                      }}
                    />
                    <span className="ms-2 weight-500">Live</span>
                  </div>

                  <div className="d-flex align-items-center">
                    <input
                      type="range"
                      className="form-range d-block"
                      id="live"
                      value={100 - valueSource}
                      onChange={(e) => setvalueSource(e.target.value)}
                    />
                    <span className="weight-500 ms-3">
                      {100 - valueSource}%
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-xl-6 mt-4 mt-xl-0">
                <img className="w-100 d-block" src={SampleGraph} />
              </div>
            </div>
          </div>

          <div className="bg-gray-800 p-4 mb-4">
            <div className="d-flex align-items-center mb-3">
              <h4 className="mb-0">Live Feed Keywords</h4>

              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip>
                    <p className="text-start mb-0">
                      Add keywords to pull in noise from anywhere.
                    </p>
                  </Tooltip>
                }
              >
                <span className="button--icon sml flex-shrink-0 rounded-circle ms-3">
                  <img src={HelpIcon} alt="" />
                </span>
              </OverlayTrigger>
            </div>
            {chips && !!chips?.length && chips[0] !== '' ? (
              <>
                <div className="form-chip-group mb-4">
                  {chips.map((chip, index) => (
                    <StylishNewChip
                      key={index}
                      label={chip}
                      handleDelete={() => handleChipDelete(index)}
                    />
                  ))}
                </div>
              </>
            ) : null}
            <div className="mb-4 d-flex align-items-center">
              <StylishNewInput
                className={'flex-grow-1'}
                placeholder={'Enter keywords...'}
                onChange={handleInputChange}
                value={inputValue}
              />
              <StylishNewButton
                className="button--primary rounded flex-shrink-0 ms-3"
                onClick={handleAddClick}
                type="button"
              >
                Add
              </StylishNewButton>
            </div>
            <hr className="dashed my-4" />
            <StylishNewButton
              className="button--primary"
              type="button"
              onClick={handleSaveClick}
            >
              Save
            </StylishNewButton>
          </div>
        </div>

        <div className="col-lg-6">
          <div className="bg-gray-800 p-4 mb-4 d-none">
            <div className="d-flex align-items-center mb-3">
              <img src={PostIcon} alt="" />

              <Dropdown className="flex-shrink-0 ms-auto">
                <Dropdown.Toggle className="button--icon">
                  <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                </Dropdown.Toggle>
                <Dropdown.Menu align="end">
                  <Dropdown.Item>
                    <img src={IconHour} className="img-h-16" alt="" />
                    Hour
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <img src={IconDay} className="img-h-16" alt="" />
                    Day
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <img src={IconWeek} className="img-h-16" alt="" />
                    Week
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <img src={IconMonth} className="img-h-16" alt="" />
                    Month
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <img src={IconEver} alt="" />
                    Ever
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="d-flex align-items-center ">
              <div className="flex-shrink-0">
                <p className="mb-0 weight-500">Posted</p>
                <h4 className="mb-0">500</h4>
              </div>

              <p className="mb-0 weight-500 ms-auto">Last Hour</p>
            </div>
          </div>
          <div className="bg-gray-800 p-4 mb-4 d-none">
            <div className="d-flex align-items-center mb-3">
              <h5 className="mb-0">Sites</h5>

              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip>
                    <p className="text-start mb-0">
                      Control the ratio of sites for noise posts.
                    </p>
                  </Tooltip>
                }
              >
                <span className="button--icon sml flex-shrink-0 rounded-circle ms-3">
                  <img src={HelpIcon} alt="" />
                </span>
              </OverlayTrigger>
            </div>

            <div className="row align-items-center">
              <div className="col-xl-6">
                <div className="mb-4">
                  <div className="d-flex align-items-center mb-2">
                    <BsFillSquareFill
                      style={{
                        color: '#E69F00',
                      }}
                    />
                    <span className="ms-2 weight-500">Facebook</span>
                  </div>
                  <div className="d-flex align-items-center">
                    <input
                      type="range"
                      className="form-range d-block"
                      id="bot"
                      value={valueSource}
                      onChange={(e) => setvalueSource(e.target.value)}
                    />

                    <span className="weight-500 ms-3">{valueSource}%</span>
                  </div>
                </div>
                <div className="mb-0">
                  <div className="d-flex align-items-center mb-2">
                    <BsFillSquareFill
                      style={{
                        color: '#0099FF',
                      }}
                    />
                    <span className="ms-2 weight-500">Twitter</span>
                  </div>
                  <div className="d-flex align-items-center">
                    <input
                      type="range"
                      className="form-range d-block"
                      id="bot"
                      value={100 - valueSource}
                      onChange={(e) => setvalueSource(e.target.value)}
                    />

                    <span className="weight-500 ms-3">
                      {100 - valueSource}%
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-xl-6 mt-4 mt-xl-0">
                <img className="w-100 d-block" src={SampleGraph} />
              </div>
            </div>
          </div>

          <div className="bg-gray-800 p-4 mb-4">
            <div className="d-flex align-items-center mb-4">
              <h4 className="mb-1">Recent Posts</h4>

              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip>
                    <p className="text-start mb-0">
                      Shows the 5 most recent posts added by the noise
                      generation
                    </p>
                  </Tooltip>
                }
              >
                <span className="button--icon sml flex-shrink-0 rounded-circle ms-3">
                  <img src={HelpIcon} alt="" />
                </span>
              </OverlayTrigger>
            </div>
            {recentPosts && !!recentPosts?.length ? (
              <>
                {recentPosts.map((postData, index) => (
                  <div key={index}>
                    {index !== 0 ? <hr className="dashed" /> : null}
                    <div className="d-flex">
                      <div className="flex-grow-1">
                        <div className="d-flex mb-3 flex-column flex-md-row">
                          <div className="profile-img flex-shrink-0">
                            <img src={postData.profileimage} alt="" />
                          </div>
                          <div className="flex-grow-1 ps-0 ps-md-3 pt-3 pt-md-0">
                            <div className="d-flex align-items-start flex-column flex-md-row align-items-md-center">
                              <h5 className="mb-0">{postData.profilename}</h5>

                              <p className="mb-0 ms-0 ms-md-auto">Published</p>
                            </div>
                            <p className="mb-1 ">
                              {formatTimestamp(postData?.publishTime)}
                            </p>

                            <div className="d-flex flex-column flex-md-row">
                              <div className="d-flex">
                                <p className="mb-0 ">
                                  @{postData.profileusername}
                                </p>
                                <span className="mx-3">|</span>
                                <div className="d-flex align-items-center">
                                  <img
                                    className="img-h-16 me-2"
                                    src={Ticon}
                                    alt=""
                                  />
                                  <p className="mb-0 ">{postData.siteName}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <p className="mb-0">{postData.postdescription}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </>
            ) : (
              <p className="weight-500">No post found.</p>
            )}
          </div>
        </div>
      </div>
      {selectLocation && (
        <SelectLocation
          show={selectLocation}
          onClose={() => setSelectLocation(false)}
          hasMoveDrawFeature={true}
        />
      )}
    </>
  );
}
