import React from 'react';
import { Link } from 'react-router-dom';
import PratusLogo from 'assets/images/pratus__logo.svg';
import IconEmail from 'assets/images/icon__email.svg';
import IconPassword from 'assets/images/icon__password.svg';
import IconUser from 'assets/images/icon__user.svg';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import Network from '../../service/Network';
import API from '../../service/api';
import { inputStringLength, toastConfig } from 'assets/data/config';
import { endLoading, startLoading } from "../../../reducers/loading/loading.action";

export default function RegisterAccount() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const params = useParams();

  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#&])(?=.{8,})/;

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    dispatch(startLoading());
    try {
      const resp = await Network.post(API.registerUser, {
        userId: params.id,
        name: data.username,
        email: params.email,
        password: 'abc', //data.password,
      });
      toast.success(resp.data.response.status.msg, toastConfig);
      navigate('/dashboard');
    } catch (error) {
      toast.error(error.response.data.response.status.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };

  return (
    <>
      <div className="site site-authentication">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-lg-9 col-xl-6">
              <div className="d-flex justify-content-center mb-5">
                <img className="img-h-32" src={PratusLogo} alt="" />
              </div>
              <h4 className="text-center mb-4">Register your account</h4>
              <div className="row justify-content-center">
                <div className="col-md-8">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="mb-3">
                      <div className="form-icon">
                        <input
                          className="form-control"
                          type="text"
                          maxLength={inputStringLength}
                          placeholder="User Name"
                          {...register('username', { required: true })}
                        />
                        <span className="icon">
                          <img src={IconUser} alt="" />
                        </span>
                      </div>
                      {errors?.username?.type === 'required' && (
                        <span className="form-text form-error">
                          This field is required
                        </span>
                      )}
                    </div>
                    <div className="mb-3">
                      <div className="form-icon">
                        <input
                          className="form-control"
                          type="email"
                          placeholder={params.email || 'User Email'}
                          disabled
                        />
                        <span className="icon icon-email">
                          <img src={IconEmail} alt="" />
                        </span>
                      </div>
                    </div>
                    {/* <div className="mb-3">
                      <div className="form-icon">
                        <input
                          className="form-control"
                          type="password"
                          placeholder="Password"
                          {...register("password", {
                            required: true,
                            pattern: passwordRegex,
                          })}
                        />
                        <span className="icon">
                          <img src={IconPassword} alt="" />
                        </span>
                      </div>
                      {errors?.password?.type === "required" && (
                        <span className="form-text form-error">
                          This field is required
                        </span>
                      )}
                      {errors?.password?.type === "pattern" && (
                        <div className="alert alert-info mt-2" role="alert">
                          <ul className="list-ordered">
                            <li>
                              The password must contain at least 1 lowercase
                              alphabetical character
                            </li>
                            <li>
                              The password must contain at least 1 uppercase
                              alphabetical character
                            </li>
                            <li>
                              The password must contain at least 1 numeric
                              character
                            </li>
                            <li>
                              The password must contain at least one special
                              character, but we are escaping reserved RegEx
                              characters to avoid conflict
                            </li>
                            <li>
                              The password must be eight characters or longer
                            </li>
                          </ul>
                        </div>
                      )}
                    </div>
                    <div className="mb-3">
                      <div className="form-icon">
                        <input
                          className="form-control"
                          type="password"
                          placeholder="Confirm Password"
                          {...register("confirmpassword", {
                            required: true,
                            validate: (val) => {
                              if (watch("password") !== val) {
                                return "Password and Confirm Password do not match";
                              }
                            },
                          })}
                        />
                        <span className="icon">
                          <img src={IconPassword} alt="" />
                        </span>
                      </div>
                      {errors?.confirmpassword?.type === "required" && (
                        <span className="form-text form-error">
                          This field is required
                        </span>
                      )}
                      {errors?.confirmpassword?.type === "validate" && (
                        <span className="form-text form-error">
                          {errors?.confirmpassword?.message}
                        </span>
                      )}
                    </div> */}
                    <div className="button-group mt-4 justify-content-center">
                      <button type="submit" className="button button--primary">
                        Register
                      </button>
                    </div>
                    {/* <p className="text-center mt-4 mb-0">
                      Already have an account? <Link to="/login">Login</Link>
                    </p> */}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
