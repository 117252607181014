import React, { Fragment, useEffect, useState } from 'react';
import NewObjectiveDialog from './NewObjectiveDialog';
import { useSelector, useDispatch } from 'react-redux';
import {
  getAllObjectiveToIncident,
  deleteObjectiveToIncident,
  getAllObjectivesToGroup,
  updateIncidentObjectiveSelection,
} from 'actions/incidentActions';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { endLoading, startLoading } from 'reducers/loading/loading.action';
import StylishNewTable from 'components/DesignSystems/New/StylishNewTable';
import { StylishConfirmDialog } from 'components/DesignSystems/New/StylishConfirmDialog';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';

export default function ManageIncidentObjectives() {
  const [isNewObjectiveDialogOpen, setIsNewObjectiveDialogOpen] = useState(
    false
  );
  const [modalType, setModalType] = useState('New');
  const [editObjectiveData, setEditObjectiveData] = useState();
  const [selectedIncidentObjectives, setSelectedIncidentObjectives] = useState(
    []
  );
  const [prettyGroupObjectives, setPrettyGroupObjectives] = useState([]);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [deleteRowData, setDeleteRowData] = useState(null);

  const incidentObjectives = useSelector(
    (state) => state.app.incidentObjectives
  );

  const isIncidentObjectivesLoaded = useSelector((state) => {
    return state.app.isIncidentObjectivesLoaded;
  });

  const groupObjectives = useSelector((state) => state.app.groupObjectives);

  const isGroupObjectivesLoaded = useSelector((state) => {
    return state.app.isGroupObjectivesLoaded;
  });
  const reduxCurrentIncident = useSelector((state) => {
    return state.app.currentIncident;
  });
  const { user_guid } = useSelector((state) => {
    return state.app.user;
  });
  const reduxCurrentlySelectedGroup = useSelector((state) => {
    return state.app.currentlySelectedGroup;
  });

  const incident_id =
    (reduxCurrentIncident && reduxCurrentIncident.id) || undefined;

  const reduxValidateRBACPermissionForActionResult = useSelector((state) => {
    return state.app.validateRBACPermissionForActionResult;
  });

  const reduxDispatch = useDispatch();

  useEffect(() => {
    setSelectedIncidentObjectives(
      prettyGroupObjectives
        ? prettyGroupObjectives?.filter((item) =>
            incidentObjectives?.find((i) => i.objective_id === item.id)
          )
        : []
    );
  }, [incidentObjectives, prettyGroupObjectives]);

  useEffect(() => {
    setPrettyGroupObjectives(
      groupObjectives
        ? groupObjectives.map(({ id, ...obj }) => {
            return {
              ...obj,
              id,
              objective_id: id.substring(0, 4),
            };
          })
        : []
    );
  }, [groupObjectives]);

  useEffect(() => {
    if (
      typeof user_guid !== 'undefined' &&
      !!reduxCurrentIncident &&
      !!reduxCurrentIncident.id
    ) {
      reduxDispatch(getAllObjectiveToIncident());
    }
  }, [incident_id]);

  useEffect(() => {
    if (reduxCurrentlySelectedGroup) {
      reduxDispatch(getAllObjectivesToGroup());
    }
  }, [reduxCurrentlySelectedGroup]);

  const onEdit = (row) => {
    setModalType('Edit');
    setIsNewObjectiveDialogOpen(true);
    setEditObjectiveData(row);
  };
  const onDelete = (row) => {
    setDeleteRowData(row);
    setShowConfirmDialog(true);
  };
  const onDeleteConfirmation = () => {
    reduxDispatch(startLoading());
    reduxDispatch(deleteObjectiveToIncident(deleteRowData.id));
    setShowConfirmDialog(false);
    setDeleteRowData(null);
  };
  const onNewObjective = () => {
    setModalType('New');
    setIsNewObjectiveDialogOpen(true);
  };

  const columns = [
    {
      dataField: 'objective_id',
      text: 'Number',
      sort: true,
      headerAttrs: { title: 'Sort By:' },
      attrs: { title: 'Number' },
      filter: textFilter(),
    },
    {
      dataField: 'description',
      text: 'Description',
      sort: true,
      attrs: { title: 'Description' },
      filter: textFilter(),
    },
  ];

  const expandRow = {
    parentClassName: 'parent-expand-tr',
    className: 'child-expand-tr',
    renderer: (row) => {
      return (
        <>
          <h6>Description</h6>
          {row.description}
          {!!reduxValidateRBACPermissionForActionResult['Write Pod Data'] && (
            <div className="button-group mt-4">
              <StylishNewButton
                className="button--secondary"
                onClick={() => onEdit(row)}
              >
                Edit Description
              </StylishNewButton>
              <StylishNewButton
                className="button--tertiary button--reverse"
                onClick={() => onDelete(row)}
              >
                Delete
              </StylishNewButton>
            </div>
          )}
        </>
      );
    },
    showExpandColumn: true,
    expandByColumnOnly: true,
    expandHeaderColumnRenderer: ({ isAnyExpands }) => {
      if (isAnyExpands) {
        return <i className="fa fa-chevron-down" aria-hidden="true"></i>;
      }
      return <i className="fa fa-chevron-right" aria-hidden="true"></i>;
    },
    expandColumnRenderer: ({ expanded }) => {
      if (expanded) {
        return (
          <>
            <div className="d-flex align-items-center justify-content-md-center">
              <span className="me-2">Details</span>
              <i className="fa fa-chevron-down" aria-hidden="true"></i>
            </div>
          </>
        );
      }
      return (
        <>
          <div className="d-flex align-items-center justify-content-md-center">
            <span className="me-2">Details</span>
            <i className="fa fa-chevron-right" aria-hidden="true"></i>
          </div>
        </>
      );
    },
  };

  const selectRow = {
    mode: 'checkbox',
    clickToSelect: true,
    hideSelectAll: false,
    selected: selectedIncidentObjectives.map((e) => e.id),
    selectionHeaderRenderer: ({ mode, ...rest }) => (
      <div className="form-checkbox align-items-center">
        <input type={mode} {...rest} />
        <span className="icon"></span>
      </div>
    ),
    selectionRenderer: ({ mode, ...rest }) => (
      <div className="form-checkbox align-items-center">
        <input type={mode} {...rest} />
        <span className="icon"></span>
      </div>
    ),
    onSelect: (row, isSelect, rowIndex, e) => {
      let selection = selectedIncidentObjectives.map((e) => e.id);
      if (selection.includes(row.id)) {
        selection = selection.filter((e) => e !== row.id);
      } else {
        selection.push(row.id);
      }
      reduxDispatch(startLoading());
      reduxDispatch(updateIncidentObjectiveSelection(selection));
    },
    onSelectAll: (isSelect, rows, e) => {
      if (isSelect) {
        reduxDispatch(startLoading());
        reduxDispatch(updateIncidentObjectiveSelection(rows.map((e) => e.id)));
      } else {
        reduxDispatch(startLoading());
        reduxDispatch(updateIncidentObjectiveSelection([]));
      }
    },
  };

  useEffect(() => {
    if (!isIncidentObjectivesLoaded || !isGroupObjectivesLoaded) {
      reduxDispatch(startLoading());
    } else {
      reduxDispatch(endLoading());
    }
  }, [isIncidentObjectivesLoaded, isGroupObjectivesLoaded]);

  return (
    <>
      <div className="d-md-flex align-items-center mb-3">
        <h4 className="m-0">Objective Library</h4>
        {!!reduxValidateRBACPermissionForActionResult['Write Pod Data'] && (
          <>
            <div className="ms-auto text-md-end">
              <StylishNewButton
                className="button--secondary mt-2 mt-md-0 w-100 w-md-auto"
                onClick={onNewObjective}
              >
                New Objective
              </StylishNewButton>
            </div>
          </>
        )}
      </div>

      <div className="form-block">
        <StylishNewTable
          keyField={'id'}
          columns={columns}
          rows={prettyGroupObjectives}
          expandRow={expandRow}
          selectRow={selectRow}
          filter={filterFactory()}
        />
      </div>

      {isNewObjectiveDialogOpen && (
        <NewObjectiveDialog
          show={isNewObjectiveDialogOpen}
          setEditObjectiveData={setEditObjectiveData}
          setModalType={setModalType}
          editObjectiveData={editObjectiveData}
          modalType={modalType}
          onClose={() => setIsNewObjectiveDialogOpen(false)}
        />
      )}

      {showConfirmDialog && (
        <StylishConfirmDialog
          show={showConfirmDialog}
          dialogTitle={'Delete Confirmation'}
          dialogContent={'Are you sure you want to delete this Objective?'}
          onClose={() => setShowConfirmDialog(false)}
          onConfirm={onDeleteConfirmation}
        />
      )}
    </>
  );
}
