import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import mapboxgl from 'mapbox-gl/dist/mapbox-gl';
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;
import ReactMapGL, { Marker } from 'react-map-gl';
import Network from '../../../../../service/Network';
import { MdLocationOn } from 'react-icons/md';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import IconMovement from 'assets/images/icon__movement.svg';
import IconDrawBox from 'assets/images/icon__draw-box.svg';

export const SelectLocation = ({
  onClose,
  show,
  storedLat,
  storedLng,
  storedPlace,
  onSaveClicked,
  hasMoveDrawFeature = false,
}) => {
  const [satelliteView, setSatelliteView] = useState(false);
  const [moveMap, setMoveMap] = useState(true);
  const [drawBoundingBox, setDrawBoundingBox] = useState(false);

  const AccessToken =window.env.MAPBOX_ACCESS_TOKEN;

  const [lng, setLng] = useState();
  const [lat, setLat] = useState();
  const [place, setPlace] = useState();

  useEffect(() => {
    if (!!storedLat && !!storedLng) {
      setLat(storedLat);
      setLng(storedLng);
    }
    if (!!storedPlace) {
      setPlace(storedPlace);
    }
  }, [storedLat, storedLng, storedPlace]);

  const mapViewHandler = (e) => {
    e.preventDefault();
    setSatelliteView(!satelliteView);
  };
  const onMapClicked = async (e) => {
    setLat(e.lngLat.lat);
    setLng(e.lngLat.lng);
    const place = await Network.get(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${e.lngLat.lng},${e.lngLat.lat}.json?access_token=${AccessToken}`
    );
    setPlace(place.data.features[2].place_name);
  };
  const onSave = () => {
    onSaveClicked(lat, lng, place);
    onClose();
  };

  const mapFeaturesHandler = (e) => {
    console.log(e);
    e.preventDefault();
    setMoveMap(!moveMap);
    setDrawBoundingBox(!drawBoundingBox);
  };

  return (
    <>
      <Modal
        size="lg"
        show={show}
        onHide={onClose}
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton closeVariant="white">
          <Modal.Title>Choose Location</Modal.Title>
        </Modal.Header>
        <form>
          <Modal.Body>
            <div className="button-group switcher mb-4">
              <StylishNewButton
                className={'' + (satelliteView ? '' : 'active')}
                onClick={(e) => mapViewHandler(e)}
              >
                Map
              </StylishNewButton>
              <StylishNewButton
                className={'' + (satelliteView ? 'active' : '')}
                onClick={(e) => mapViewHandler(e)}
              >
                Satellite
              </StylishNewButton>
            </div>
            <div className="map rounded overflow-hidden">
              <ReactMapGL
                initialViewState={{
                  latitude: 40.7128,
                  longitude: -98.5556,
                  zoom: 3,
                }}
                mapboxAccessToken={AccessToken}
                style={{ width: 'auto', height: 400 }}
                mapStyle={
                  satelliteView
                    ? 'mapbox://styles/mapbox/satellite-v9'
                    : 'mapbox://styles/mapbox/streets-v12'
                }
                onClick={(e) => onMapClicked(e)}
              >
                {lat && lng && (
                  <Marker
                    longitude={lng}
                    latitude={lat}
                    offsetTop={-20}
                    offsetLeft={-10}
                    followUserMode="course"
                  >
                    <MdLocationOn size={36} color={'#78919C'} />
                  </Marker>
                )}
              </ReactMapGL>
            </div>
            {hasMoveDrawFeature ? (
              <div className="button-group flex-column flex-md-row align-items-start align-items-md-center tab mt-4">
                <StylishNewButton
                  className={
                    'button w-100 w-md-auto rounded-5-mobile-only ' +
                    (moveMap ? 'active' : '')
                  }
                  onClick={(e) => mapFeaturesHandler(e)}
                >
                  <img src={IconMovement} alt="" className="me-2" />
                  Move Map
                </StylishNewButton>
                <StylishNewButton
                  className={
                    'button w-100 w-md-auto rounded-5-mobile-only mt-2 mt-md-0 ' +
                    (drawBoundingBox ? 'active' : '')
                  }
                  onClick={(e) => mapFeaturesHandler(e)}
                >
                  <img src={IconDrawBox} alt="" className="me-2" />
                  Draw Bounding Box
                </StylishNewButton>
              </div>
            ) : null}
            {place && <p className="mt-4 m-0">{place}</p>}
            {lat && lng && (
              <p className="mt-2 m-0">
                Lat/Lon: {lat} {lng}
              </p>
            )}
          </Modal.Body>
          <Modal.Footer>
            <div className="button-group">
              <StylishNewButton
                className="button--secondary button--reverse"
                onClick={onClose}
              >
                Close
              </StylishNewButton>

              <StylishNewButton
                className="button--primary"
                type="submit"
                onClick={onSave}
                disabled={!lat && !lng}
              >
                Save
              </StylishNewButton>
            </div>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};
