import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Network from '../../../../service/Network';
import API from '../../../../service/api';
import { useSelector, useDispatch } from 'react-redux';
import EditFeedbackDod from './EditFeedbackDod';
import EditFeedbackHseep from './EditFeedbackHseep';
import { toast } from 'react-toastify';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { toastConfig } from 'assets/data/config';
import { PreparePath } from 'utils/sharedUtils/sharedConst';
import { endLoading, startLoading } from "../../../../../reducers/loading/loading.action";
import { SharedIcon } from 'components/SharedIcon/SharedIcon';

const EditFeedback = () => {
  const [feedback, setFeedback] = useState(null);

  let { id } = useParams();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { selectedWorkspace } = useSelector((state) => state.prepare.workspace);

  useEffect(() => {
    if (id !== undefined) {
      fetchFeedback();
    }
  }, [id, selectedWorkspace]);

  const fetchFeedback = async () => {
    dispatch(startLoading());
    try {
      const fetch_feedback = await Network.get(API.getFeedback, {
        id: id,
      });
      if (fetch_feedback !== undefined && fetch_feedback.status === 200) {
        let feedbackData = fetch_feedback.data.response.dataset[0];
        console.log('feedbackData', feedbackData);
        if (selectedWorkspace !== feedbackData?.workspaceId) {
          navigate(`${PreparePath}/dashboard/plan/feedback`);
        }
        if (feedbackData.type === 'DOD') {
          setFeedback({ ...fetch_feedback.data.response.dataset[0] });
        } else {
          try {
            const fetchFeed = await Network.get(API.getFeedbackHseep, {
              workspaceId: selectedWorkspace,
              id: id,
              trainingObjectiveId: feedbackData.trainingObjectiveId,
            });
            if (fetchFeed !== undefined && fetchFeed.status === 200) {
              setFeedback({
                ...fetchFeed.data.response.dataset[0],
                feedbackId: id,
                participantId: feedbackData.participantId,
              });
            }
          } catch (error) {
            toast.error('Something went wrong', toastConfig);
          }
        }
      }
    } catch (error) {
      toast.error('Something went wrong', toastConfig);
      navigate(-1);
    } finally {
      dispatch(endLoading());
    }
  };

  return (
    <>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <StylishNewButton onClick={() => navigate(-1)}>
              <SharedIcon iconName="keyboard_arrow_left" classes="me-2" />
              Back
            </StylishNewButton>
          </li>
        </ol>
      </nav>
      <div className="text-center mb-4">
        {/* <div className="d-flex justify-content-center mb-3">
          <img className="img-h-128" src={LogoEpic} alt="" />
        </div> */}
        <h3 className="m-0">Feedback</h3>
      </div>
      {feedback !== null ? (
        <>
          {feedback.type === 'DOD' ? (
            <EditFeedbackDod singleFeedback={feedback}></EditFeedbackDod>
          ) : (
            <EditFeedbackHseep singleFeedback={feedback}></EditFeedbackHseep>
          )}
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default EditFeedback;
