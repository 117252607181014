import Network from 'PREPAREsrc/service/Network';
import API from 'PREPAREsrc/service/api';
import { AllIcon } from 'assets/Icon/Icon';
import IconDownload from 'assets/images/icon__download.svg';
import fileDownload from 'js-file-download';
import { useDispatch } from 'react-redux';
import { endLoading, startLoading } from 'reducers/loading/loading.action';
import { sanitizeHtml } from 'utils/sanitizeHtml';

export const Details = ({ missionTimelinePopupDetails }) => {
  const dispatch = useDispatch();
  const onDownload = async (file) => {
    let fullFileName = file.split('/')[file.split('/').length - 1];
    try {
      dispatch(startLoading());
      const downloadFile = await Network.post(API.generateSignedUrl, {
        url: file,
      });
      const res = await fetch(downloadFile.data.response.singedUrl, {
        headers: {},
      });

      fileDownload(
        await res.blob(),
        fullFileName.split('-')[fullFileName.split('-').length - 1]
      );
    } catch (error) {
      console.log('Error: ', error);
      toast.error('Failed to download file', toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };
  return (
    <>
      <div className="row">
        <div className="mb-3 col-md-3">
          <p className="m-0">
            <span className="weight-600 d-block mb-1">Profile(s)</span>
            {missionTimelinePopupDetails?.profiles
              ?.map((profile) => profile.name)
              .join(', ')}
          </p>
        </div>
        <div className="mb-3 col-md-3">
          <p className="m-0">
            <span className="weight-600 d-block mb-1">Cells</span>
            {missionTimelinePopupDetails?.cells
              .map((cell) => cell.cell)
              .join(', ')}
          </p>
        </div>
        <div className="mb-3 col-md-3">
          <p className="m-0">
            <span className="weight-600 d-block mb-1">Notification</span>
            <span className="d-flex align-items-center">
              <span
                style={{
                  background:
                    missionTimelinePopupDetails?.notification_method_color,
                  width: '1.5rem',
                  height: '1.5rem',
                }}
                className="rounded-5 me-2 d-flex align-items-center justify-content-center has-img-h-16"
              >
                {
                  AllIcon.find(
                    (icon) =>
                      icon.name ===
                      missionTimelinePopupDetails?.notification_method_icon
                  )?.icon
                }
              </span>
              {missionTimelinePopupDetails?.notification_method_name}
            </span>
          </p>
        </div>
        <div className="mb-3 col-md-3">
          <p className="m-0">
            <span className="weight-600 d-block mb-1">
              Primary Action Officer
            </span>
            {missionTimelinePopupDetails?.primary_action_officer_name ||
              missionTimelinePopupDetails?.primary_action_officer_email ||
              ''}
          </p>
        </div>
        <div className="mb-3 col-md-12">
          <p className="m-0">
            <span className="weight-600 d-block mb-1">Mission Objective</span>
            {missionTimelinePopupDetails?.mission_objective}
          </p>
        </div>
        <div className="mb-3 col-md-12">
          <p className="m-0">
            <span className="weight-600 d-block mb-1">Admin Remarks</span>
            <div
              dangerouslySetInnerHTML={{
                __html: sanitizeHtml(
                  missionTimelinePopupDetails?.mission_admin_remarks
                ),
              }}
            ></div>
          </p>
        </div>
        <div className="col-md-12">
          <p className="m-0">
            <span className="weight-600 d-block mb-1">Vignette</span>
            {missionTimelinePopupDetails?.mission_timeline_vignette ? (
              <span
                className="d-flex align-items-center"
                onClick={() =>
                  onDownload(
                    missionTimelinePopupDetails?.mission_timeline_vignette
                  )
                }
              >
                <span className="anchor anchor--white weight-400">
                  {missionTimelinePopupDetails?.mission_timeline_vignette
                    ?.split('/')
                    .pop()
                    .replace(/%20/g, ' ')
                    .split('-')
                    .slice(1)
                    .join('-')}
                </span>
                <span className="ms-3 cursor-pointer">
                  <img src={IconDownload} alt="" />
                </span>
              </span>
            ) : (
              'No Vignette Attached'
            )}
          </p>
        </div>
      </div>
    </>
  );
};
