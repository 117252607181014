import React, { useEffect, useState, useLayoutEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import Network from '../../../../service/Network';
import API from '../../../../service/api';
import CustomModal from 'components/DesignSystems/New/CustomModal';
import { toast } from 'react-toastify';
import moment from 'moment';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import StylishNewSelect from 'components/DesignSystems/New/StylishNewSelect';
import StylishNewInput from 'components/DesignSystems/New/StylishNewInput';
import StylishNewTextArea from 'components/DesignSystems/New/StylishNewTextArea';
import { toastConfig } from 'assets/data/config';
import { endLoading, startLoading } from "../../../../../reducers/loading/loading.action";
import { SharedIcon } from 'components/SharedIcon/SharedIcon';

const EditFeedbackDod = ({ singleFeedback }) => {
  const [feedback, setFeedback] = useState();
  const [reason, setReason] = useState([
    {
      value: 'Add New',
      label: 'Add new',
    },
  ]);
  const [missionTask, setMissionTask] = useState([
    {
      value: 'Add New',
      label: 'Add new',
    },
  ]);
  const [qualification, setQualification] = useState([
    {
      value: 'Add New',
      label: 'Add new',
    },
  ]);
  const [selectedReason, setSelectedReason] = useState({
    value: '',
    label: 'Select a reason',
  });
  const [selectedMissionTask, setSelectedMissionTask] = useState({
    value: '',
    label: 'Metric',
  });
  const [selectedQualification, setSelectedQualification] = useState({
    value: '',
    label: 'Qualification',
  });
  const [qualificationError, setQualificationError] = useState(false);
  const [missionTaskError, setMissionTaskError] = useState(false);
  const [missionCompleted, setMissionCompleted] = useState(0);
  const [qualificationCompleted, setQualificationCompleted] = useState(0);
  const [measure, setMeasure] = useState([]);
  const [showMissionTask, setShowMissionTask] = useState(false);
  const [showQualification, setShowQualification] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setDeleteShowModal] = useState({
    id: '',
    index: null,
    show: false,
  });
  const [modalInputReason, setModalInputReason] = useState('');
  const [modalFor, setModalFor] = useState('');
  const [reasonSearch, setReasonSearch] = useState('');
  const [metricSearch, setmetricSearch] = useState('');
  const [feedbackData, setFeedbackData] = useState({
    lessonsLearned: 'No',
  });
  const [newLessonsLearned, setNewlessonsLearned] = useState([]);
  const [feedbackComments, setFeedbackComments] = useState('');
  const [feedbackRemarks, setFeedbackRemarks] = useState('');
  const [feedbackAirMatrics, setFeedbackAirMatrics] = useState({
    flight_Hours: 0,
    Sorties: 0,
    Passengers: 0,
    cargo: 0,
    fuel: 0,
  });
  const [newAllMission, setNewAllMission] = useState([]);
  const [newAllQualification, setNewAllQualification] = useState([]);
  const [modalButtonDisabled, setModalButtonDisabled] = useState(false);
  const [saveButtonDisable, setSaveButtonDisable] = useState(false);

  const { control, setValue, getValues } = useForm();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { selectedWorkspace } = useSelector((state) => state.prepare.workspace);

  const { fields, append, remove } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: 'lesson', // unique name for your Field Array
  });

  useEffect(() => {
    fetchQualification();
  }, [selectedWorkspace]);

  useEffect(() => {
    fetchMissionTask();
  }, [selectedWorkspace, metricSearch]);

  useEffect(() => {
    fetchallAcademicReasons();
  }, [selectedWorkspace, reasonSearch]);

  useEffect(() => {
    if (singleFeedback?.lessionlearned.length > 0) {
      setValue(
        'lesson',
        singleFeedback?.lessionlearned.map((t) => ({
          classification: 'Unclassified',
          planfeedbacklessionlearnedId: t?.planfeedbacklessionlearnedId,
          comments: t?.comments || '',
          discussion: t?.discussion || '',
          eventDescription: t?.eventDescription || '',
          implications: t?.implications || '',
          observation: t?.observation || '',
          potentialOPR: t?.potentialOPR || '',
          recommendation: t?.recommendation || '',
          title: t?.title || '',
        }))
      );
      return;
    } else if (feedbackData.lessonsLearned === 'Yes') {
      append({
        classification: 'Unclassified',
        comments: '',
        discussion: '',
        eventDescription: '',
        implications: '',
        observation: '',
        potentialOPR: '',
        recommendation: '',
        title: '',
      });
    }
  }, [feedbackData]);

  useLayoutEffect(() => {
    setFeedback({ ...singleFeedback });
    let fData = { ...feedbackData };
    fData.complete =
      singleFeedback?.evalcomplete === null
        ? true
        : singleFeedback?.evalcomplete;
    fData.levelOfEffectiveness =
      singleFeedback?.evalstatus === null
        ? 'Untrained'
        : singleFeedback?.evalstatus;
    fData.airMetrics = singleFeedback?.metric === true ? 'Yes' : 'No';
    let airMat = { ...feedbackAirMatrics };
    airMat.flight_Hours =
      singleFeedback?.metricflighthours != null
        ? singleFeedback?.metricflighthours
        : '';
    airMat.Sorties =
      singleFeedback?.metricnumofstories != null
        ? singleFeedback?.metricnumofstories
        : '';
    airMat.Passengers =
      singleFeedback?.metricnumofpassesngers != null
        ? singleFeedback?.metricnumofpassesngers
        : '';
    airMat.cargo =
      singleFeedback?.metriccargoweight != null
        ? singleFeedback?.metriccargoweight
        : '';
    airMat.fuel =
      singleFeedback?.metricfuelpassed != null
        ? singleFeedback?.metricfuelpassed
        : '';
    setFeedbackAirMatrics({ ...airMat });
    fData.lessonsLearned =
      singleFeedback?.lessonLearned === true ? 'Yes' : 'No';

    if (singleFeedback?.evalcomment?.length > 0) {
      setFeedbackComments(singleFeedback?.evalcomment);
    }
    if (singleFeedback?.evalremark?.length > 0) {
      setFeedbackRemarks(singleFeedback?.evalremark);
    }

    setFeedbackData(fData);
    if (singleFeedback?.metrics?.length > 0) {
      setNewAllMission(
        singleFeedback?.metrics.map((item) => {
          return {
            feedbackMetricId: item?.feedbackMetricId,
            value: item?.metricsId,
            label: item?.metricsTitle,
            completed: item?.feedbackData,
          };
        })
      );
    }
    if (singleFeedback?.qualification?.length > 0) {
      setNewAllQualification(
        singleFeedback?.qualification.map((item) => {
          return {
            planfeedbackqualificationId: item?.planfeedbackqualificationId,
            value: item?.qualificationTaskId,
            label: item?.qualificationTask,
            completed: item?.numberCompleted,
          };
        })
      );
    }
    fData.lessonsLearned = singleFeedback?.lessonLearned ? 'Yes' : 'No';
    if (singleFeedback?.lessionlearned?.length > 0) {
      setFeedbackData({ ...fData });
    }

    if (singleFeedback?.measure && singleFeedback?.measure?.length > 0) {
      setMeasure([...singleFeedback.measure]);
    }
  }, []);

  const fetchallAcademicReasons = async () => {
    if (selectedWorkspace === null) return;
    try {
      const fetchReason = await Network.get(API.planAssessmentReasonList, {
        workspaceId: selectedWorkspace,
        search: reasonSearch,
      });
      if (fetchReason !== undefined && fetchReason.status === 200) {
        if (singleFeedback?.reasonId !== null) {
          let x = fetchReason.data.response.dataset.filter(
            (e) => e.id === singleFeedback?.reasonId
          );
          if (x?.length > 0) {
            setSelectedReason({
              value: x[0].id,
              label: x[0].title,
            });
          }
        }
        setReason([
          { value: 'Add New', label: 'Add new' },
          ...fetchReason.data.response.dataset.map((e) => {
            return {
              value: e.id,
              label: e.title,
            };
          }),
        ]);
      }
    } catch (error) {
      toast.error('Something went wrong', toastConfig);
    }
  };

  const fetchMissionTask = async () => {
    if (selectedWorkspace === null) return;
    try {
      const task = await Network.get(API.planMetricList, {
        workspaceId: selectedWorkspace,
        search: metricSearch,
        filter: singleFeedback?.type,
      });
      if (task !== undefined && task.status === 200) {
        setMissionTask([
          { value: 'Add New', label: 'Add new' },
          ...task.data.response.dataset.map((e) => {
            return {
              value: e.id,
              label: e.title,
            };
          }),
        ]);
      }
    } catch (error) {
      toast.error('Something went wrong', toastConfig);
    }
  };

  const fetchQualification = async () => {
    if (selectedWorkspace === null) return;
    try {
      const task = await Network.get(API.allQualification, {
        workspaceId: selectedWorkspace,
      });

      if (task !== undefined && task.status === 200) {
        setQualification([
          { value: 'Add New', label: 'Add new' },
          ...task.data.response.dataset.map((e) => {
            return {
              value: e.id,
              label: e.title,
            };
          }),
        ]);
      }
    } catch (error) {
      toast.error('Something went wrong', toastConfig);
    }
  };

  const SelectReasonFromSelectBox = (e) => {
    if (!e) {
      setSelectedReason({
        value: '',
        label: 'Select a reason',
      });
      return;
    }
    if (e?.value === 'Add New') {
      setShowModal(true);
      setModalFor('Ineffective Reason');
      return;
    }
    setSelectedReason({
      value: e.value,
      label: e.label,
    });
  };

  const selectMissionFromSelectBox = (e) => {
    if (!e) {
      if (selectedMissionTask.value === '') return;
      setSelectedMissionTask({
        value: '',
        label: 'Metric',
      });
      return;
    }
    if (e?.value === 'Add New') {
      setShowModal(true);
      setModalFor('Metric');
      return;
    }

    setSelectedMissionTask({ ...e });
  };

  const selectQualificationFromSelectBox = (e) => {
    if (!e) {
      if (selectedQualification.value === '') return;
      setSelectedQualification({
        value: '',
        label: 'Qualification',
      });
      return;
    }
    if (e?.value === 'Add New') {
      setShowModal(true);
      setModalFor('Qualification');
      return;
    }

    setSelectedQualification({ ...e });
  };

  const onModalConfirm = async () => {
    if (modalFor === 'Ineffective Reason') {
      onReasonModalConfirm();
    } else if (modalFor === 'Metric') {
      onMissionModalConfirm();
    } else {
      onQualityModalConfirm();
    }
  };

  function notOnlySpace(str) {
    return /[A-Za-z]/.test(str);
  }

  const onReasonModalConfirm = async () => {
    if (modalInputReason.length === 0 || !notOnlySpace(modalInputReason)) {
      return;
    }
    setModalButtonDisabled(true);
    try {
      let postReason = await Network.post(API.createPlanAssessmentReason, {
        title: modalInputReason,
        workspaceId: selectedWorkspace,
      });
      if (postReason !== undefined && postReason.status === 200) {
        let res = {
          value: postReason.data.response.dataset[0].id,
          label: postReason.data.response.dataset[0].title,
        };
        setReason((prevState) => [...prevState, res]);
        setSelectedReason(res);
        toast.success('Reason created successfully', toastConfig);
      }
    } catch (error) {
      toast.error('Something went wrong', toastConfig);
    } finally {
      setModalButtonDisabled(false);
      setShowModal(false);
    }
  };

  const onMissionModalConfirm = async () => {
    if (modalInputReason.length === 0 || !notOnlySpace(modalInputReason)) {
      return;
    }
    setModalButtonDisabled(true);
    try {
      let postMission = await Network.post(API.createMetric, {
        title: modalInputReason,
        participantType: singleFeedback?.type,
        workspaceId: selectedWorkspace,
      });
      if (postMission !== undefined && postMission.status === 200) {
        let res = {
          value: postMission.data.response.dataset[0].id,
          label: postMission.data.response.dataset[0].title,
        };
        setMissionTask((prevState) => [...prevState, res]);
        setSelectedMissionTask(res);
        fetchMissionTask();
        toast.success('Metric created successfully', toastConfig);
      }
    } catch (error) {
      toast.error('Something went wrong', toastConfig);
    } finally {
      setModalButtonDisabled(false);
      setShowModal(false);
    }
  };

  const onQualityModalConfirm = async () => {
    if (modalInputReason.length === 0 || !notOnlySpace(modalInputReason)) {
      return;
    }
    setModalButtonDisabled(true);
    try {
      let postQualification = await Network.post(API.addQualification, {
        title: modalInputReason,
        workspaceId: selectedWorkspace,
      });
      if (postQualification !== undefined && postQualification.status === 200) {
        let res = {
          value: postQualification.data.response.dataset.id,
          label: postQualification.data.response.dataset.title,
        };
        setQualification((prevState) => [...prevState, res]);
        setSelectedQualification(res);
        toast.success('Qualification created successfully', toastConfig);
      }
    } catch (error) {
      toast.error('Something went wrong', toastConfig);
    } finally {
      setModalButtonDisabled(false);
      setShowModal(false);
    }
  };

  const modalInputChange = (e) => {
    setModalInputReason(e.target.value);
  };

  const changefeedbackStatus = (complete) => {
    let data = { ...feedbackData };
    data.complete = complete;
    setFeedbackData({ ...data });
  };

  const changeFeedbackLevelOfEffectivness = (levelOfEffectiveness) => {
    if (feedbackData?.levelOfEffectiveness === levelOfEffectiveness) return;
    let data = { ...feedbackData };
    data.levelOfEffectiveness = levelOfEffectiveness;
    setFeedbackData({ ...data });
  };

  const changeFeedbackLessonsLearned = (lessonsLearned) => {
    if (feedbackData?.lessonsLearned === lessonsLearned) return;
    if (lessonsLearned === 'Yes' && newLessonsLearned.length === 0) {
      let newlesson = [
        {
          classification: 'Unclassified',
          comments: '',
          discussion: '',
          eventDescription: '',
          implications: '',
          observation: '',
          potentialOPR: '',
          recommendation: '',
          title: '',
        },
      ];
      setNewlessonsLearned([...newlesson]);
    }
    let data = { ...feedbackData };
    data.lessonsLearned = lessonsLearned;
    setFeedbackData({ ...data });
  };

  const saveAllNewMission = () => {
    if (selectedMissionTask.value === '') {
      setMissionTaskError(true);
      return;
    }
    setMissionTaskError(false);
    let data = { ...selectedMissionTask, completed: missionCompleted };
    setNewAllMission((prevState) => [...prevState, { ...data }]);
    setShowMissionTask(false);
    setSelectedMissionTask({ value: '', label: 'Select a mission' });
    setMissionCompleted(0);
  };

  const saveNewAllQualification = () => {
    if (selectedQualification.value === '') {
      setQualificationError(true);
      return;
    }
    setQualificationError(false);
    let data = { ...selectedQualification, completed: qualificationCompleted };
    setNewAllQualification((prevState) => [...prevState, { ...data }]);
    setShowQualification(false);
    setSelectedQualification({ value: '', label: 'Qualification' });
    setQualificationCompleted(0);
  };

  // const deleteMissionTask = async () => {
  //   let data = { ...showDeleteModal };

  //   if (!("planfeedbackmissiontaskId" in data.id)) {
  //     // if mission task is not is server ..
  //     let mtData = [...newAllMission];
  //     mtData.splice(data.index, 1);
  //     setNewAllMission(mtData);
  //     data.id = "";
  //     data.index = 0;
  //     data.show = false;
  //     setDeleteShowModal(data);
  //   } else {
  //     // removing Mission task from server  then UI...
  //     setModalButtonDisabled(true);
  //     try {
  //       const deleteMissionTaskRes = await Network.post(
  //         API.deleteFeedbackMissionTask,
  //         {
  //           id: data.id.planfeedbackmissiontaskId,
  //         }
  //       );

  //       if (
  //         deleteMissionTaskRes !== undefined &&
  //         deleteMissionTaskRes.status === 200
  //       ) {
  //         let mtData = [...newAllMission];
  //         mtData.splice(data.index, 1);
  //         setNewAllMission(mtData);
  //         // toast.success("mission task deleted successfully");
  //       }
  //     } catch (error) {
  //       toast.error("Something went wrong");
  //     } finally {
  //       setModalButtonDisabled(false);
  //       data.id = "";
  //       data.index = null;
  //       data.show = false;
  //       setDeleteShowModal(data);
  //     }
  //   }
  // };

  const deleteMissionTask = async () => {
    let data = { ...showDeleteModal };
    let mtData = [...newAllMission];
    mtData.splice(data.index, 1);
    setNewAllMission(mtData);
    data.id = '';
    data.index = 0;
    data.show = false;
    setDeleteShowModal(data);
  };

  const deleteQualification = async () => {
    let data = { ...showDeleteModal };
    if (!('planfeedbackqualificationId' in data.id)) {
      // if mission task is not is server ..
      let mtData = [...newAllQualification];
      mtData.splice(data.index, 1);
      setNewAllQualification(mtData);
      data.id = '';
      data.index = 0;
      data.show = false;
      setDeleteShowModal(data);
    } else {
      // removing Mission task from server  then UI...
      setModalButtonDisabled(true);
      try {
        const deleteQualificationRes = await Network.post(
          API.deleteFeedbackQualification,
          {
            id: data.id.planfeedbackqualificationId,
          }
        );
        if (
          deleteQualificationRes !== undefined &&
          deleteQualificationRes.status === 200
        ) {
          let mtData = [...newAllQualification];
          mtData.splice(data.index, 1);
          setNewAllQualification(mtData);
          // toast.success("mission task deleted successfully");
        }
      } catch (error) {
        toast.error('Something went wrong', toastConfig);
      } finally {
        setModalButtonDisabled(false);
        data.id = '';
        data.index = null;
        data.show = false;
        setDeleteShowModal(data);
      }
    }
  };

  // const deleteLeasonLearned = async () => {
  //   let data = { ...showDeleteModal };

  //   if (!("planfeedbacklessionlearnedId" in data.id)) {
  //     // if mission task is not is server ..
  //     let mtData = [...newLessonsLearned];
  //     mtData.splice(data.index, 1);
  //     setNewlessonsLearned(mtData);
  //     data.id = "";
  //     data.index = 0;
  //     data.show = false;
  //     setDeleteShowModal(data);
  //   } else {
  //     // removing Mission task from server  then UI...
  //     setModalButtonDisabled(true);
  //     try {
  //       const deleteLeasonLearnedRes = await Network.post(
  //         API.deleteFeedbackLessionLearned,
  //         {
  //           id: data.id.planfeedbacklessionlearnedId,
  //         }
  //       );

  //       if (
  //         deleteLeasonLearnedRes !== undefined &&
  //         deleteLeasonLearnedRes.status === 200
  //       ) {
  //         let mtData = [...newLessonsLearned];
  //         mtData.splice(data.index, 1);
  //         setNewlessonsLearned(mtData);
  //         // toast.success("mission task deleted successfully");
  //       }
  //     } catch (error) {
  //       toast.error("Something went wrong");
  //     } finally {
  //       setModalButtonDisabled(false);
  //       data.id = "";
  //       data.index = null;
  //       data.show = false;
  //       setDeleteShowModal(data);
  //     }
  //   }
  // };

  const confirmDeleteModal = () => {
    if (modalFor === 'Delete Metric') {
      deleteMissionTask();
      return;
    }
    if (modalFor === 'Delete Feedback Qualification') {
      deleteQualification();
      return;
    }
    if (modalFor === 'Delete Feedback Lesson') {
      deleteLeasonLearned();
    }
  };

  const onKeyPressEvent = (event) => {
    var invalidChars = ['E', '.', 'e', '-', '+'];
    if (invalidChars.includes(event.key)) {
      event.preventDefault();
    }
  };

  const removeAMissionTask = (mission, index) => {
    setModalFor('Delete Metric');
    let data = {
      id: mission,
      index: index,
      show: true,
    };
    setDeleteShowModal(data);
  };

  const removeQualification = (qualification, index) => {
    setModalFor('Delete Feedback Qualification');
    let data = {
      id: qualification,
      inde: index,
      show: true,
    };
    setDeleteShowModal(data);
  };

  const deleteFeedbackLessionLearned = (index) => {
    setModalFor('Delete Feedback Lesson');
    let data = {
      id: '',
      index: index,
      show: true,
    };
    setDeleteShowModal(data);
  };

  const deleteLeasonLearned = async () => {
    let data = { ...showDeleteModal };
    remove(data.index);
    fields.length === 1 && changeFeedbackLessonsLearned('No');
    data.id = '';
    data.index = 0;
    data.show = false;
    setDeleteShowModal(data);
  };

  const updateAllNewMission = (e, idx) => {
    let data = [...newAllMission];
    data[idx].completed = e.target.value;
    setNewAllMission([...data]);
  };

  const updateAllNewQualification = (e, idx) => {
    let data = [...newAllQualification];
    data[idx].completed = e.target.value;
    setNewAllQualification([...data]);
  };

  const submitEditedFeedback = async (action) => {
    let lessonData = getValues();
    let req = {
      feedbackId: feedback.id,
      comment: feedbackComments,
      participantId: feedback.participantId,
      trainingObjectiveId: feedback.trainingObjectiveId,
      workspaceId: feedback.workspaceId,
      status: action,
      evaluation: {
        complete: feedbackData.complete,
        comment: feedbackComments,
        remark: feedbackRemarks,
      },
    };
    if (feedbackData.complete === false) {
      req.evaluation.status = 'Untrained';
    } else {
      req.evaluation.status = feedbackData.levelOfEffectiveness;
    }
    if (
      !(
        feedbackData.complete === true &&
        feedbackData.levelOfEffectiveness === 'Trained'
      )
    ) {
      if (selectedReason.value !== '') {
        req.evaluation.reasonId = selectedReason.value;
      }
    }

    // if (feedbackData.airMetrics === "Yes") {
    //   req.metric = true;
    //   req.airmetric = {
    //     flightHours:
    //       feedbackAirMatrics.flight_Hours !== ""
    //         ? Number(feedbackAirMatrics.flight_Hours)
    //         : 0,
    //     numOfStories:
    //       feedbackAirMatrics.Sorties !== ""
    //         ? Number(feedbackAirMatrics.Sorties)
    //         : 0,
    //     numOfPassesngers:
    //       feedbackAirMatrics.Passengers !== ""
    //         ? Number(feedbackAirMatrics.Passengers)
    //         : 0,
    //     cargoWeight:
    //       feedbackAirMatrics.cargo !== ""
    //         ? Number(feedbackAirMatrics.cargo)
    //         : 0,
    //     fuelPassed:
    //       feedbackAirMatrics.fuel !== "" ? Number(feedbackAirMatrics.fuel) : 0,
    //   };
    // } else {
    //   req.metric = false;
    // }
    if (
      lessonData.lesson.length > 0 &&
      fields.length > 0 &&
      feedbackData?.lessonsLearned === 'Yes'
    ) {
      req.lessonLearned = true;
      req.lessionlearned = lessonData.lesson.map((e) => {
        return {
          potentialOPR: e?.potentialOPR,
          title: e?.title,
          observation: e?.observation,
          discussion: e?.discussion,
          recommendation: e?.recommendation,
          implications: e?.implications,
          eventDescription: e?.eventDescription,
          comments: e?.comments,
        };
      });
    } else {
      req.lessonLearned = false;
      req.lessionlearned = [];
    }

    if (newAllMission.length > 0) {
      req.metric = true;
      req.metrics = newAllMission.map((item) => {
        return {
          metricsId: item.value,
          task: item.label,
          feedbackData: item.completed !== '' ? Number(item.completed) : 0,
        };
      });
    } else {
      req.metric = false;
    }

    if (newAllQualification?.length > 0) {
      req.qualification = newAllQualification.map((item) => {
        return {
          id: item.value,
          task: item.label,
          numberCompleted: item.completed !== '' ? Number(item.completed) : 0,
        };
      });
    }

    if (feedbackData.lessonsLearned === 'Yes') {
      req.lessonLearned = true;
    } else {
      req.lessonLearned = false;
    }

    // if (newLessonsLearned?.length > 0) {
    //   req.lessionlearned = newLessonsLearned;
    // }

    if (measure?.length !== 0) {
      req.measure = measure;
    }
    setSaveButtonDisable(true);
    dispatch(startLoading());
    try {
      let updateRes = await Network.post(API.updateFeedback, {
        ...req,
      });
      if (updateRes !== undefined && updateRes.status === 200) {
        toast.success('Feedback updated successfully', toastConfig);
        navigate(-1);
      }
    } catch (error) {
      toast.error('Something went wrong', toastConfig);
    } finally {
      setSaveButtonDisable(false);
      dispatch(endLoading());
    }
  };

  const closeReasonModal = () => {
    setShowModal(false);
    setModalButtonDisabled(false);
    setModalInputReason('');
  };

  const updateMeasureTitle = (e, idx) => {
    let data = [...measure];
    data[idx].title = e.target.value;
    setMeasure(data);
  };

  const cancelSetShowMissionTask = () => {
    setMissionTaskError(false);
    setShowMissionTask(false);
    setSelectedMissionTask({
      value: '',
      label: 'Metric',
    });
    setMissionCompleted(0);
  };

  const cancelSetShowQualification = () => {
    setQualificationError(false);
    setShowQualification(false);
    setSelectedQualification({
      value: '',
      label: 'Select a Qualification',
    });
    setQualificationCompleted(0);
  };

  //Add your search logic here.
  const customFilter = (option, searchText, type) => {
    if (option.value === 'Add New') return true;
    if (option.data.label.toLowerCase().includes(searchText.toLowerCase())) {
      if (type === 'reason') {
        setReasonSearch(searchText);
      } else if (type === 'metric') {
        setmetricSearch(searchText);
      }
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      <CustomModal
        show={showModal}
        heading={modalFor}
        onClose={closeReasonModal}
        onConfirm={onModalConfirm}
        saveButton="save"
        buttonDisable={modalButtonDisabled}
      >
        <div className="mb-3">
          <label className="form-label">Title</label>
          <StylishNewInput
            className="form-control"
            autoFocus={true}
            type="text"
            onChange={modalInputChange}
          />
        </div>
        <div className="mb-0">
          <label className="form-label">Type</label>
          <StylishNewInput
            className="form-control"
            autoFocus={false}
            type="text"
            defaultValue={singleFeedback?.type}
            readOnly
          />
        </div>
      </CustomModal>

      <CustomModal
        show={showDeleteModal?.show}
        heading={modalFor}
        onClose={() => setDeleteShowModal(false)}
        onConfirm={confirmDeleteModal}
        saveButton="Ok"
        buttonDisable={modalButtonDisabled}
      >
        <p>Are you sure you want to do this?</p>
      </CustomModal>

      <div className="form-block mb-4">
        <div className="row">
          <div className="col-md-4 mb-3">
            <label className="form-label">Participant Platform</label>
            <p className="m-0">{`${feedback?.participantname}${
              feedback?.platformtitle !== null
                ? `- ${feedback?.platformtitle}`
                : ``
            }`}</p>
          </div>
          <div className="col-md-4 mb-3">
            <label className="form-label">Date</label>
            <p className="m-0">
              {moment(feedback?.createdAt).format('Do MMM YY - hhmm')}
            </p>
          </div>
          <div className="col-md-4 mb-3">
            <label className="form-label"> Objective</label>
            <p className="m-0">{`${feedback?.jmet ? feedback?.jmet : ``}`}</p>
          </div>
          <div className="col-md-6 mb-3">
            <label className="form-label">Task</label>
            <StylishNewTextArea
              readOnly
              className="form-control form-textarea"
              value={feedback?.task !== null ? feedback?.task : ''}
            ></StylishNewTextArea>
          </div>
          <div className="col-md-6 mb-3">
            <label className="form-label">Condition</label>
            <StylishNewTextArea
              readOnly
              className="form-control form-textarea"
              value={feedback?.condition !== null ? feedback?.condition : ''}
            ></StylishNewTextArea>
          </div>
          <div className="col-md-12">
            <label className="form-label">Standard</label>
            <StylishNewTextArea
              readOnly
              className="form-control form-textarea"
              value={feedback?.standard !== null ? feedback?.standard : ''}
            ></StylishNewTextArea>
          </div>
        </div>
      </div>
      <div className="form-block mb-4">
        <h4 className="mb-4">Self Assessment</h4>
        <div className="row mb-4">
          <div className="col-md-12 mb-3">
            <label className="form-label">Was this objective completed?</label>

            <div className="form-radio-group mt-1">
              <div className="form-radio">
                <StylishNewInput
                  type="radio"
                  id="check__Yes"
                  name="check__complete"
                  onChange={() => changefeedbackStatus(true)}
                  checked={feedbackData.complete === true ? true : false}
                />
                <span className="icon"></span>
                <label className="form-label weight-400" htmlFor="check__Yes">
                  Complete
                </label>
              </div>
              <div className="form-radio">
                <StylishNewInput
                  type="radio"
                  id="check__No"
                  readOnly
                  name="check__complete"
                  onChange={() => changefeedbackStatus(false)}
                  checked={feedbackData.complete === false ? true : false}
                />
                <span className="icon"></span>
                <label className="form-label weight-400" htmlFor="check__No">
                  Incomplete
                </label>
              </div>
            </div>
          </div>
          {/* level of effectiveness ratio option ..  */}
          {feedbackData.complete === true ? (
            <div className="col-md-12 mb-3">
              <label className="form-label">
                To which level of effectiveness was this objective completed?
              </label>
              <div className="form-radio-group mt-1">
                <div className="form-radio">
                  <StylishNewInput
                    type="radio"
                    id="check__trained"
                    readOnly
                    name="check__effectiveness"
                    onChange={() => {
                      changeFeedbackLevelOfEffectivness('Trained');
                    }}
                    checked={
                      feedbackData.levelOfEffectiveness === 'Trained'
                        ? true
                        : false
                    }
                  />
                  <span className="icon"></span>
                  <label
                    className="form-label weight-400"
                    htmlFor="check__trained"
                  >
                    Trained
                  </label>
                </div>
                <div className="form-radio">
                  <StylishNewInput
                    type="radio"
                    id="check__partially-trained"
                    name="check__effectiveness"
                    onChange={() =>
                      changeFeedbackLevelOfEffectivness('Partially Trained')
                    }
                    checked={
                      feedbackData.levelOfEffectiveness === 'Partially Trained'
                        ? true
                        : false
                    }
                  />
                  <span className="icon"></span>
                  <label
                    className="form-label weight-400"
                    htmlFor="check__partially-trained"
                  >
                    Partially Trained
                  </label>
                </div>
                <div className="form-radio">
                  <StylishNewInput
                    type="radio"
                    id="check__untrained"
                    name="check__effectiveness"
                    onChange={() =>
                      changeFeedbackLevelOfEffectivness('Untrained')
                    }
                    checked={
                      feedbackData.levelOfEffectiveness === 'Untrained'
                        ? true
                        : false
                    }
                  />
                  <span className="icon"></span>
                  <label
                    className="form-label weight-400"
                    htmlFor="check__untrained"
                  >
                    Untrained
                  </label>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}

          {feedbackData.complete === true &&
          feedbackData.levelOfEffectiveness === 'Trained' ? (
            <></>
          ) : (
            <div className="col-md-12 mb-3">
              <label className="form-label">
                Why was the objective not effective?
              </label>
              <StylishNewSelect
                options={reason}
                placeholder={'Select a Reason'}
                isClearable={selectedReason.value !== '' ? true : false}
                isSearchable={true}
                filterOption={(option, searchText) =>
                  customFilter(option, searchText, 'reason')
                }
                isMulti={false}
                isDisabled={false}
                onChange={(e) => SelectReasonFromSelectBox(e)}
                value={selectedReason}
              />
            </div>
          )}

          <div className="col-md-12">
            <label className="form-label">Comment</label>
            <StylishNewTextArea
              className="form-control form-textarea"
              value={feedbackComments.length !== 0 ? feedbackComments : ''}
              onChange={(e) => setFeedbackComments(e.target.value)}
            ></StylishNewTextArea>
          </div>
        </div>
        {measure !== null && measure?.length > 0 ? (
          <>
            <h4 className="mb-0">Measures</h4>
            <div className="row">
              {measure.map((m, idx) => (
                <div className="col-md-12 mt-4" key={idx}>
                  <label className="form-label weight-500">
                    {m?.name != '' ? m?.name : `M${idx + 1}`}
                  </label>
                  <StylishNewInput
                    type="text"
                    className="form-control"
                    value={m?.title?.length > 0 ? m?.title : ''}
                    onChange={(e) => updateMeasureTitle(e, idx)}
                  />
                </div>
              ))}
            </div>
          </>
        ) : (
          <></>
        )}

        <hr className="dashed my-4" />
        {/* <div>
          <label className="form-label">Overall Remarks</label>
          <textarea
            className="form-control form-textarea"
            value={feedbackRemarks.length > 0 ? feedbackRemarks : ""}
            maxLength={textareaStringLength}
            onChange={(e) => setFeedbackRemarks(e.target.value)}
          ></textarea>
        </div> */}
      </div>
      {/* <div className="form-block mb-4">
        <h4 className="mb-4">Air Metrics</h4>
        <div className="row">
          <div className="col-md-12 mb-3">
            <label className="form-label">
              Do you have metrics to record for this day?
            </label>
            <div className="form-radio-group mt-1">
              <div className="form-radio">
                <input
                  type="radio"
                  id="check__am__yes"
                  name="check__airMatrics__completed"
                  readOnly
                  checked={feedbackData.airMetrics === "Yes" ? true : false}
                  onChange={() => changeFeedbackAirMatrics("Yes")}
                />
                <span className="icon"></span>
                <label
                  className="form-label weight-400"
                  htmlFor="check__am__yes"
                >
                  Yes
                </label>
              </div>
              <div className="form-radio">
                <input
                  type="radio"
                  id="check__am__no"
                  name="check__airMatrics__completed"
                  readOnly
                  checked={feedbackData.airMetrics === "No" ? true : false}
                  onChange={() => changeFeedbackAirMatrics("No")}
                />
                <span className="icon"></span>
                <label
                  className="form-label weight-400"
                  htmlFor="check__am__no"
                >
                  No
                </label>
              </div>
            </div>
          </div>
          {feedbackData.airMetrics === "Yes" ? (
            <>
              <div className="col-md-6 mb-3">
                <label className="form-label">Flight Hours</label>
                <input
                  className="form-control"
                  type="number"
                  name="flight_Hours"
                  onKeyDown={onKeyPressEvent}
                  value={feedbackAirMatrics.flight_Hours}
                  placeholder="0"
                  onChange={(e) => updatefeedbackAirMatrics(e, "flight_Hours")}
                />
              </div>
              <div className="col-md-6 mb-3">
                <label className="form-label">Sorties (Number)</label>
                <input
                  className="form-control"
                  type="number"
                  placeholder="0"
                  name="Sorties"
                  value={feedbackAirMatrics.Sorties}
                  onKeyDown={onKeyPressEvent}
                  onChange={(e) => updatefeedbackAirMatrics(e, "Sorties")}
                />
              </div>
              <div className="col-md-6 mb-3">
                <label className="form-label">
                  Passengers Carried (Number)
                </label>
                <input
                  className="form-control"
                  type="number"
                  placeholder="0"
                  name="Passengers"
                  value={feedbackAirMatrics.Passengers}
                  onKeyDown={onKeyPressEvent}
                  onChange={(e) => updatefeedbackAirMatrics(e, "Passengers")}
                />
              </div>
              <div className="col-md-6 mb-3">
                <label className="form-label">Cargo Weight (lbs)</label>
                <input
                  className="form-control"
                  type="number"
                  placeholder="0"
                  name="cargo"
                  value={feedbackAirMatrics.cargo}
                  onKeyDown={onKeyPressEvent}
                  onChange={(e) => updatefeedbackAirMatrics(e, "cargo")}
                />
              </div>
              <div className="col-md-6">
                <label className="form-label">Fuel Passed (lbs)</label>
                <input
                  className="form-control"
                  type="number"
                  placeholder="0"
                  name="fuel"
                  value={feedbackAirMatrics.fuel}
                  onKeyDown={onKeyPressEvent}
                  onChange={(e) => updatefeedbackAirMatrics(e, "fuel")}
                />
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      </div> */}

      <div className="form-block mb-4">
        <h4 className="mb-4">Metrics</h4>
        {newAllMission.map((mission, index) => (
          <div key={index} className="row">
            <div className="col-md-6 mb-3">
              <label className="form-label">Metric</label>
              <StylishNewInput
                type="text"
                className="form-control"
                readOnly
                value={mission?.label ? mission?.label : ''}
              />
            </div>
            <div className="col-md-6 mb-3">
              <label className="form-label">Number Completed</label>
              <StylishNewInput
                type="text"
                className="form-control"
                value={mission?.completed ? mission?.completed : 0}
                onKeyDown={onKeyPressEvent}
                onChange={(e) => updateAllNewMission(e, index)}
              />
            </div>
            <div className="col-md-12 button-group">
              <StylishNewButton
                type="button"
                className="button--icon primary"
                onClick={() => removeAMissionTask(mission, index)}
              >
                <SharedIcon iconName="delete" />
              </StylishNewButton>
            </div>
            <div className="col-md-12">
              <hr className="dashed my-4" />
            </div>
          </div>
        ))}

        {/* select and input for complete ... */}
        {showMissionTask ? (
          <div className="row">
            <div className="col-md-6 mb-3">
              <label className="form-label">Metric</label>
              <StylishNewSelect
                options={missionTask.filter((item) => {
                  return (
                    newAllMission.filter((item2) => {
                      return item2.value == item.value;
                    }).length == 0
                  );
                })}
                // options={missionTask}
                isClearable={selectedMissionTask.value === '' ? false : true}
                placeholder="Select a Metric"
                filterOption={(option, searchText) =>
                  customFilter(option, searchText, 'metric')
                }
                onChange={(e) => selectMissionFromSelectBox(e)}
                value={selectedMissionTask}
              />
              {missionTaskError === true && selectedMissionTask.value === '' ? (
                <span className="form-text form-error">
                  This is a required field
                </span>
              ) : null}
            </div>
            <div className="col-md-6 mb-3">
              <label className="form-label">Number Completed</label>
              <StylishNewInput
                type="number"
                className="form-control"
                placeholder="0"
                onKeyDown={onKeyPressEvent}
                onChange={(e) => setMissionCompleted(e.target.value)}
              />
            </div>
            <div className="col-md-12 button-group">
              <StylishNewButton
                className="button button--primary button--sml rounded-5"
                type="submit"
                onClick={saveAllNewMission}
              >
                Save
              </StylishNewButton>
              <StylishNewButton
                className="button button--secondary button--reverse button--sml rounded-5"
                onClick={cancelSetShowMissionTask}
              >
                Cancel
              </StylishNewButton>
            </div>
            <div className="col-md-12">
              <hr className="dashed my-4" />
            </div>
          </div>
        ) : (
          <></>
        )}

        <StylishNewButton
          className="button button--primary"
          onClick={() => {
            setShowMissionTask(true), fetchMissionTask();
          }}
        >
          Add
        </StylishNewButton>
      </div>

      {/* qualification start from here  */}
      <div className="form-block mb-4">
        <h4 className="mb-4">Qualifications</h4>
        {newAllQualification.map((qualification, index) => (
          <div className="row" key={index}>
            <div className="col-md-6 mb-3">
              <label className="form-label">Qualification</label>
              <StylishNewInput
                type="text"
                className="form-control"
                value={qualification?.label ? qualification?.label : ''}
                readOnly
              />
            </div>
            <div className="col-md-6 mb-3">
              <label className="form-label">Number Completed</label>
              <StylishNewInput
                type="text"
                className="form-control"
                onKeyDown={onKeyPressEvent}
                value={qualification.completed}
                onChange={(e) => updateAllNewQualification(e, index)}
              />
            </div>
            <div className="col-md-12 button-group">
              <StylishNewButton
                type="button"
                className="button--icon primary"
                onClick={() => removeQualification(qualification, index)}
              >
                <SharedIcon iconName="delete" />
              </StylishNewButton>
            </div>
            <div className="col-md-12">
              <hr className="dashed my-4" />
            </div>
          </div>
        ))}

        {showQualification ? (
          <div className="row">
            <div className="col-md-6 mb-3">
              <label className="form-label">Qualification</label>
              <StylishNewSelect
                options={qualification.filter((item) => {
                  return (
                    newAllQualification.filter((item2) => {
                      return item2.value == item.value;
                    }).length == 0
                  );
                })}
                isClearable={selectedQualification.value === '' ? false : true}
                placeholder="Select a Qualification"
                onChange={(e) => selectQualificationFromSelectBox(e)}
                value={selectedQualification}
              />
              {qualificationError === true &&
              selectedQualification.value === '' ? (
                <span className="form-text form-error">
                  This is a required field
                </span>
              ) : null}
            </div>
            <div className="col-md-6 mb-3">
              <label className="form-label">Number Completed</label>
              <StylishNewInput
                type="number"
                placeholder="0"
                className="form-control"
                onKeyDown={onKeyPressEvent}
                onChange={(e) => setQualificationCompleted(e.target.value)}
              />
            </div>
            <div className="col-md-12 button-group">
              <StylishNewButton
                className="button button--primary button--sml rounded-5"
                type="submit"
                onClick={saveNewAllQualification}
              >
                Save
              </StylishNewButton>
              <StylishNewButton
                onClick={cancelSetShowQualification}
                className="button button--secondary button--reverse button--sml rounded-5"
              >
                Cancel
              </StylishNewButton>
            </div>
            <div className="col-md-12">
              <hr className="dashed my-4" />
            </div>
          </div>
        ) : (
          <></>
        )}

        <StylishNewButton
          className="button button--primary"
          onClick={() => setShowQualification(true)}
        >
          Add
        </StylishNewButton>

        {/* lesson Learned start from here.... */}
      </div>
      <div className="form-block mb-4">
        <h4 className="mb-4">Lessons Learned</h4>
        <div className="mb-4">
          <label className="form-label">
            Do you have lessons learned to record for this day?
          </label>
          <div className="form-radio-group mt-1">
            <div className="form-radio">
              <StylishNewInput
                type="radio"
                id="check__lesson__yes"
                name="check__lesson__completed"
                readOnly
                checked={feedbackData.lessonsLearned === 'Yes' ? true : false}
                onChange={() => changeFeedbackLessonsLearned('Yes')}
              />
              <span className="icon"></span>
              <label
                className="form-label weight-400"
                htmlFor="check__lesson__yes"
              >
                Yes
              </label>
            </div>
            <div className="form-radio">
              <StylishNewInput
                type="radio"
                id="check__lesson__no"
                name="check__lesson__completed"
                readOnly
                checked={feedbackData.lessonsLearned === 'No' ? true : false}
                onChange={() => changeFeedbackLessonsLearned('No')}
              />
              <span className="icon"></span>
              <label
                className="form-label weight-400"
                htmlFor="check__lesson__no"
              >
                No
              </label>
            </div>
          </div>
        </div>
        {feedbackData.lessonsLearned === 'Yes' ? (
          <>
            <form>
              <hr className="dashed mt-0 mb-4" />
              {fields.map((lesson, idx) => (
                <div className="row" key={lesson.id}>
                  <div className="col-md-6 mb-3">
                    <label className="form-label">Potential OPR</label>
                    <Controller
                      control={control}
                      name={`lesson.${idx}.potentialOPR`}
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <StylishNewInput
                          type="text"
                          className="form-control"
                          onChange={onChange}
                          value={value}
                        />
                      )}
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <label className="form-label">Classification</label>
                    <StylishNewInput
                      type="text"
                      className="form-control"
                      value="Unclassified"
                      readOnly
                    />
                  </div>
                  <div className="col-md-12 mb-3">
                    <label className="form-label">Title</label>
                    <Controller
                      control={control}
                      name={`lesson.${idx}.title`}
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <StylishNewInput
                          type="text"
                          className="form-control"
                          onChange={onChange}
                          value={value}
                        />
                      )}
                    />
                  </div>
                  <div className="col-md-12 mb-3">
                    <label className="form-label">Observation</label>
                    <Controller
                      control={control}
                      name={`lesson.${idx}.observation`}
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <StylishNewTextArea
                          className="form-control form-textarea"
                          onChange={onChange}
                          value={value}
                        ></StylishNewTextArea>
                      )}
                    />
                  </div>
                  <div className="col-md-12 mb-3">
                    <label className="form-label">Discussion</label>
                    <Controller
                      control={control}
                      name={`lesson.${idx}.discussion`}
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <StylishNewTextArea
                          className="form-control form-textarea"
                          onChange={onChange}
                          value={value}
                        ></StylishNewTextArea>
                      )}
                    />
                  </div>
                  <div className="col-md-12 mb-3">
                    <label className="form-label">Recommendation</label>
                    <Controller
                      control={control}
                      name={`lesson.${idx}.recommendation`}
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <StylishNewTextArea
                          className="form-control form-textarea"
                          onChange={onChange}
                          value={value}
                        ></StylishNewTextArea>
                      )}
                    />
                  </div>
                  <div className="col-md-12 mb-3">
                    <label className="form-label">Implications</label>
                    <Controller
                      control={control}
                      name={`lesson.${idx}.implications`}
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <StylishNewTextArea
                          className="form-control form-textarea"
                          onChange={onChange}
                          value={value}
                        ></StylishNewTextArea>
                      )}
                    />
                  </div>
                  <div className="col-md-12 mb-3">
                    <label className="form-label">Event Description</label>
                    <Controller
                      control={control}
                      name={`lesson.${idx}.eventDescription`}
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <StylishNewTextArea
                          className="form-control form-textarea"
                          onChange={onChange}
                          value={value}
                        ></StylishNewTextArea>
                      )}
                    />
                  </div>
                  <div className="col-md-12 mb-3">
                    <label className="form-label">Comments</label>
                    <Controller
                      control={control}
                      name={`lesson.${idx}.comments`}
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <StylishNewTextArea
                          className="form-control form-textarea"
                          onChange={onChange}
                          value={value}
                        ></StylishNewTextArea>
                      )}
                    />
                  </div>
                  <div className="col-md-12 button-group">
                    <StylishNewButton
                      type="button"
                      className="button--icon primary"
                      onClick={() => {
                        deleteFeedbackLessionLearned(idx);
                        // remove(idx);
                        // fields.length === 1 &&
                        //   changeFeedbackLessonsLearned("No");
                      }}
                    >
                      <SharedIcon iconName="delete" />
                    </StylishNewButton>
                  </div>
                  <div className="col-md-12">
                    <hr className="dashed my-4" />
                  </div>
                </div>
              ))}
            </form>

            <StylishNewButton
              className="button button--primary"
              onClick={() =>
                append({
                  classification: 'Unclassified',
                  comments: '',
                  discussion: '',
                  eventDescription: '',
                  implications: '',
                  observation: '',
                  potentialOPR: '',
                  recommendation: '',
                  title: '',
                })
              }
            >
              Add
            </StylishNewButton>
          </>
        ) : null}
      </div>
      <div className="col-md-12 button-group">
        <StylishNewButton
          className="button button--primary ms-auto"
          disabled={saveButtonDisable ? true : false}
          type="submit"
          onClick={() => submitEditedFeedback('Submitted')}
        >
          Submit
        </StylishNewButton>
        <StylishNewButton
          className="button button--secondary "
          disabled={saveButtonDisable ? true : false}
          type="submit"
          onClick={() => submitEditedFeedback('Draft')}
        >
          Save Draft
        </StylishNewButton>
        <StylishNewButton
          className="button button--secondary button--reverse"
          onClick={() => navigate(-1)}
        >
          Cancel
        </StylishNewButton>
      </div>
    </>
  );
};

export default EditFeedbackDod;
