export const CREATE_OR_GET_USER_REQUEST = 'CREATE_OR_GET_USER_REQUEST'; 
export const CREATE_OR_GET_USER_SUCCESS = 'CREATE_OR_GET_USER_SUCCESS';
export const CREATE_OR_GET_USER_ERROR = 'CREATE_OR_GET_USER_ERROR';
export const FETCH_GROUPS_REQUEST = 'FETCH_GROUPS_REQUEST';
export const FETCH_GROUPS_SUCCESS = 'FETCH_GROUPS_SUCCESS';
export const FETCH_GROUPS_ERROR = 'FETCH_GROUPS_ERROR';
export const FETCH_GROUPS_FOR_USER_REQUEST = 'FETCH_GROUPS_FOR_USER_REQUEST'; 
export const FETCH_GROUPS_FOR_USER_SUCCESS = 'FETCH_GROUPS_FOR_USER_SUCCESS';
export const FETCH_GROUPS_FOR_USER_ERROR = 'FETCH_GROUPS_FOR_USER_ERROR';
export const FETCH_AVAILABLE_GROUPS_REQUEST = 'FETCH_AVAILABLE_GROUPS_REQUEST';
export const FETCH_AVAILABLE_GROUPS_SUCCESS = 'FETCH_AVAILABLE_GROUPS_SUCCESS';
export const FETCH_AVAILABLE_GROUPS_ERROR = 'FETCH_AVAILABLE_GROUPS_ERROR';
export const CREATE_GROUP_REQUEST = 'CREATE_GROUP_REQUEST';
export const CREATE_GROUP_SUCCESS = 'CREATE_GROUP_SUCCESS';
export const CREATE_GROUP_ERROR = 'CREATE_GROUP_ERROR';
export const CLOSE_GROUP_REQUEST = 'CLOSE_GROUP_REQUEST';
export const CLOSE_GROUP_SUCCESS = 'CLOSE_GROUP_SUCCESS';
export const CLOSE_GROUP_ERROR = 'CLOSE_GROUP_ERROR';
export const JOIN_GROUP_REQUEST = 'JOIN_GROUP_REQUEST';
export const JOIN_GROUP_SUCCESS = 'JOIN_GROUP_SUCCESS';
export const JOIN_GROUP_ERROR = 'JOIN_GROUP_ERROR';
export const FETCH_INCIDENTS_REQUEST = 'FETCH_INCIDENTS_REQUEST';
export const UPDATE_INCIDENT_STATUS = 'UPDATE_INCIDENT_STATUS';
export const FETCH_INCIDENTS_SUCCESS = 'FETCH_INCIDENTS_SUCCESS';
export const FETCH_INCIDENTS_ERROR = 'FETCH_INCIDENTS_ERROR';
export const UPDATE_ROLE_REQUEST = 'UPDATE_ROLE_REQUEST';
export const UPDATE_ROLE_SUCCESS = 'UPDATE_ROLE_SUCCESS';
export const UPDATE_ROLE_ERROR = 'UPDATE_ROLE_ERROR';
export const FETCH_GROUP_SOLICITATIONS_REQUEST = 'FETCH_GROUP_SOLICITATIONS_REQUEST';
export const FETCH_GROUP_SOLICITATIONS_SUCCESS = 'FETCH_GROUP_SOLICITATIONS_SUCCESS';
export const FETCH_GROUP_SOLICITATIONS_ERROR = 'FETCH_GROUP_SOLICITATIONS_ERROR';
export const FETCH_USER_SUBSCRIPTIONS_REQUEST = 'FETCH_USER_SUBSCRIPTIONS_REQUEST';
export const FETCH_USER_SUBSCRIPTIONS_SUCCESS = 'FETCH_USER_SUBSCRIPTIONS_SUCCESS';
export const FETCH_USER_SUBSCRIPTIONS_ERROR = 'FETCH_USER_SUBSCRIPTIONS_ERROR';
export const FETCH_SEAT_ASSIGNMENTS_REQUEST = 'FETCH_SEAT_ASSIGNMENTS_REQUEST';
export const FETCH_SEAT_ASSIGNMENTS_SUCCESS = 'FETCH_SEAT_ASSIGNMENTS_SUCCESS';
export const FETCH_SEAT_ASSIGNMENTS_ERROR = 'FETCH_SEAT_ASSIGNMENTS_ERROR';
export const ASSIGN_SEAT_REQUEST = 'ASSIGN_SEAT_REQUEST';
export const ASSIGN_SEAT_SUCCESS = 'ASSIGN_SEAT_SUCCESS';
export const ASSIGN_SEAT_ERROR = 'ASSIGN_SEAT_ERROR';
export const UNASSIGN_SEAT_REQUEST = 'UNASSIGN_SEAT_REQUEST';
export const UNASSIGN_SEAT_SUCCESS = 'UNASSIGN_SEAT_SUCCESS';
export const UNASSIGN_SEAT_ERROR = 'UNASSIGN_SEAT_ERROR';
export const FETCH_SUBSCRIPTION_PLANS_REQUEST = 'FETCH_SUBSCRIPTION_PLANS_REQUEST';
export const FETCH_SUBSCRIPTION_PLANS_SUCCESS = 'FETCH_SUBSCRIPTION_PLANS_SUCCESS';
export const FETCH_SUBSCRIPTION_PLANS_ERROR = 'FETCH_SUBSCRIPTION_PLANS_ERROR';
export const FETCH_ICON_REQUEST = 'FETCH_ICON_REQUEST';
export const FETCH_ICON_SUCCESS = 'FETCH_ICON_SUCCESS';
export const FETCH_ICON_ERROR = 'FETCH_ICON_ERROR';
export const INVITE_GROUP_MEMBER_REQUEST = 'INVITE_GROUP_MEMBER_REQUEST'; 
export const INVITE_GROUP_MEMBER_SUCCESS = 'INVITE_GROUP_MEMBER_SUCCESS'; 
export const INVITE_GROUP_MEMBER_ERROR = 'INVITE_GROUP_MEMBER_ERROR'; 
export const UPDATE_CURRENTLY_SELECTED_REQUEST = 'UPDATE_CURRENTLY_SELECTED_REQUEST';
export const UPDATE_CURRENTLY_SELECTED_SUCCESS = 'UPDATE_CURRENTLY_SELECTED_SUCCESS';
export const UPDATE_CURRENTLY_SELECTED_ERROR = 'UPDATE_CURRENTLY_SELECTED_ERROR';
export const UPDATE_USER_PROFILE_SETTINGS_REQUEST = 'UPDATE_USER_PROFILE_SETTINGS_REQUEST';
export const UPDATE_USER_PROFILE_SETTINGS_SUCCESS = 'UPDATE_USER_PROFILE_SETTINGS_SUCCESS';
export const UPDATE_USER_PROFILE_SETTINGS_ERROR = 'UPDATE_USER_PROFILE_SETTINGS_ERROR';
export const BULK_USER_IMPORT_REQUEST = 'BULK_USER_IMPORT_REQUEST'
export const BULK_USER_IMPORT_SUCCESS = 'BULK_USER_IMPORT_SUCCESS'
export const BULK_USER_IMPORT_ERROR = 'BULK_USER_IMPORT_ERROR'
export const GET_USER_BY_ID_REQUEST = 'GET_USER_BY_ID_REQUEST' 
export const GET_USER_BY_ID_SUCCESS = 'GET_USER_BY_ID_SUCCESS'
export const GET_USER_BY_ID_ERROR = 'GET_USER_BY_ID_ERROR'
export const UPDATE_FOCUS_USER_PROFILE_SETTINGS_SUCCESS = 'UPDATE_FOCUS_USER_PROFILE_SETTINGS_SUCCESS'
export const CONFIRM_USER_NOT_NEW_REQUEST = "CONFIRM_USER_NOT_NEW_REQUEST"
export const CONFIRM_USER_NOT_NEW_SUCCESS = "CONFIRM_USER_NOT_NEW_SUCCESS"
export const CONFIRM_USER_NOT_NEW_ERROR = "CONFIRM_USER_NOT_NEW_ERROR"
export const FETCH_USER_GROUPS_ROLES_INCIDENTS_REQUEST = "FETCH_USER_GROUPS_ROLES_INCIDENTS_REQUEST"
export const FETCH_USER_GROUPS_ROLES_INCIDENTS_SUCCESS = "FETCH_USER_GROUPS_ROLES_INCIDENTS_SUCCESS"
export const FETCH_USER_GROUPS_ROLES_INCIDENTS_ERROR = "FETCH_USER_GROUPS_ROLES_INCIDENTS_ERROR"
export const GET_ALL_GROUP_QUESTIONS = "GET_ALL_GROUP_QUESTIONS"
export const GET_ALL_GROUP_QUESTIONS_REQUEST = "GET_ALL_GROUP_QUESTIONS_REQUEST"
export const GET_ALL_GROUP_QUESTIONS_SUCCESS = "GET_ALL_GROUP_QUESTIONS_SUCCESS"
export const ADD_GROUP_QUESTIONS_REQUEST = "ADD_GROUP_QUESTIONS_REQUEST"
export const ADD_GROUP_QUESTIONS_SUCCESS = "ADD_GROUP_QUESTIONS_SUCCESS"
export const DELETE_GROUP_QUESTIONS_REQUEST = "DELETE_GROUP_QUESTIONS_REQUEST"
export const DELETE_GROUP_QUESTIONS_SUCCESS = "DELETE_GROUP_QUESTIONS_SUCCESS"
export const GET_ALL_GROUP_ANSWER= "GET_ALL_GROUP_ANSWER"
export const ADD_GROUP_ANSWER= "ADD_GROUP_ANSWER"
export const GROUP_ANSWER_REQUEST= "GROUP_ANSWER_REQUEST"
export const GROUP_ANSWER_SUCCESS= "GROUP_ANSWER_SUCCESS"
export const GET_ALL_GROUP_ANSWER_FOR_ALL_MEMBERS = 'GET_ALL_GROUP_ANSWER_FOR_ALL_MEMBERS';