import React, { useEffect, useState } from 'react';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { StylishNewSearchBar } from 'components/DesignSystems/New/StylishNewSearchBar';
import StylishHelp from 'components/DesignSystems/New/StylishHelp';
import StylishNewTable from 'components/DesignSystems/New/StylishNewTable';
import { StylishConfirmDialog } from 'components/DesignSystems/New/StylishConfirmDialog';
import { HelpSupprt, useSelfHostedKnowledgeBase } from 'assets/data/HelpContent';
import StylishNewTableAction from 'components/DesignSystems/New/StylishNewTableAction';
import AddEditSupportDialog from './AddEditSupportDialog';
import { useDispatch, useSelector } from 'react-redux';
import API from '../../../service/api';
import Network from '../../../service/Network';
import { endLoading, startLoading } from 'reducers/loading/loading.action';
import { toast } from 'react-toastify';
import { toastConfig } from 'assets/data/config';
import { useNavigate } from 'react-router-dom';
import { PreparePath } from '../../../../utils/sharedUtils/sharedConst';
import { PaginationDropdown } from '../../../../components/DesignSystems/PaginationDropdown';

const Support = () => {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(15);
  const [orderBy, setOrderBy] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [allSupportData, setAllSupportData] = useState([]);
  const [updateDialogData, setUpdateDialogData] = useState(null);
  const [showSupportAddEditDialog, setShowSupportAddEditDialog] = useState(
    false
  );
  const [deleteSupportTitle, setDeleteSupportTitle] = useState('');
  const [deleteSupportDataId, setDeleteSupportDataId] = useState(null);
  const [dialogType, setDialogType] = useState('Add');
  const [total, setTotal] = useState(0);

  const selfHostedKnowledgeBase = useSelfHostedKnowledgeBase()

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const workspaceId = useSelector(
    (state) => state.prepare.workspace.selectedWorkspace
  );

  const workspace = useSelector((state) => {
    return state?.prepare?.workspace;
  });


  const columns = [
    {
      dataField: 'title',
      text: 'Title',
      sort: true,
      headerAttrs: { title: 'Sort By:' },
      attrs: { title: 'Title' },
      onSort: (field, order) => {
        if (order === 'asc') {
          setOrderBy(0);
        } else if (order === 'desc') {
          setOrderBy(1);
        } else {
          setOrderBy(0);
        }
      },
    },
    {
      dataField: '',
      text: 'Action',
      isDummyField: true,
      formatter: (cell, row, rowIndex) => (
        <StylishNewTableAction
          name="Support"
          cell={cell}
          row={row}
          rowIndex={rowIndex}
          onDelete={onDeleteSupport}
          onEdit={onEditSupport}
        />
      ),
      headerStyle: {
        width: '100px',
      },
      headerAlign: 'center',
      align: 'center',
      attrs: { title: 'Action' },
    },
  ];

  const supportList = async () => {
    dispatch(startLoading());
    try {
      const supportListData = await Network.get(API.getSupportList, {
        workspaceId: workspaceId,
        search: searchTerm,
        limit: perPage,
        page: page,
        sort: orderBy,
      });
      setAllSupportData(supportListData.data.response.dataset);
      setTotal(supportListData?.data?.response?.count);
    } catch (error) {
      toast.error(error.response.data.response.status.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };

  useEffect(() => {
    if (!!workspace && !!workspace?.type && workspace?.type !== 'T&E') {
      navigate(`${PreparePath}/dashboard`);
    } else {
      setPage(1);
      setPerPage(15);
      //   setRerender(!reRender);
    }
  }, [workspace.type, workspaceId]);

  useEffect(() => {
    // if (reRender) setRerender(!reRender);
    supportList();
  }, [
    page,
    perPage,
    searchTerm,
    orderBy,
    workspaceId, // workspaceId added for an scenario
    // reRender
  ]);

  const onAddSupport = () => {
    setDialogType('Add');
    setShowSupportAddEditDialog(true);
  };

  const onDeleteSupport = (row) => {
    setDeleteSupportDataId(row?.id);
    setDeleteSupportTitle(row?.title);
  };

  const onEditSupport = (row) => {
    setUpdateDialogData(row);
    setDialogType('Edit');
    setShowSupportAddEditDialog(true);
  };

  const onAddOrEditGetAllSupports = (afterSave) => {
    setUpdateDialogData(null);
    setDialogType('New');
    setShowSupportAddEditDialog(false);
    if (afterSave?.id) {
      supportList();
    }
  };

  const onDeleteConfirm = async () => {
    dispatch(startLoading());
    try {
      await Network.post(API.deleteSupport, {
        id: deleteSupportDataId,
      });
      setDeleteSupportTitle(null);
      supportList();
      toast.success('Deleted  successfully', toastConfig);
    } catch (error) {
      toast.error(error.response.data.response.status.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };

  const onChangeSearchTerm = (term) => {
    setPage(1);
    setSearchTerm(term);
  };

  return (
    <>
      <div className="settings__exercise-type">
        <StylishNewButton
          className="button button--primary mb-4"
          onClick={onAddSupport}
        >
          Add Support
        </StylishNewButton>
        <div className="form-block">
          <div className="row">
            <div className="col-md-12">
              <div className="d-flex flex-column flex-md-row align-items-md-center mb-4">
                <div className="d-flex align-items-center justify-content-between">
                  <p className="m-0 me-3 text-nowrap">{total || 0} Support</p>
                  <div className="">
                    <PaginationDropdown
                      setPage={setPage}
                      setPerPage={setPerPage}
                      options={[
                        { value: 15, label: '15 per page' },
                        { value: 30, label: '30 per page' },
                        { value: 60, label: '60 per page' },
                        { value: 100, label: '100 per page' },
                      ]}
                    />
                  </div>
                </div>
                <div className="ms-md-auto mt-3 mt-md-0 d-flex align-items-center">
                  <div className="flex-grow-1">
                    <StylishNewSearchBar
                      onChangeSearchTerm={onChangeSearchTerm}
                    />
                  </div>
                  <StylishHelp
                    classes={'ms-3 flex-shrink-0'}
                    title={HelpSupprt.title}
                    content={HelpSupprt.content}
                    link={selfHostedKnowledgeBase || HelpSupprt.link}
                  />
                </div>
              </div>
            </div>
          </div>
          <StylishNewTable
            keyField={'id'}
            columns={columns}
            rows={allSupportData}
            onDelete={onDeleteSupport}
            onEdit={onEditSupport}
            pagination
            page={page}
            perPage={perPage}
            total={total}
            onPageChange={(page) => setPage(page)}
          />
        </div>
        {showSupportAddEditDialog && (
          <AddEditSupportDialog
            show={showSupportAddEditDialog}
            onClose={onAddOrEditGetAllSupports}
            dialogType={dialogType}
            updateDialogData={updateDialogData}
          />
        )}
        {deleteSupportDataId && (
          <StylishConfirmDialog
            show={deleteSupportTitle}
            onClose={() => setDeleteSupportDataId(null)}
            dialogTitle={'Delete Support'}
            dialogContentType={'html'}
            dialogContent={`Deleting 
            <span class="weight-700 break-word">${deleteSupportTitle}</span>
             will remove all its occurance from this workspace. 
             <span class="d-block pt-3">Are you sure you want to delete this Support?</span>`}
            onConfirm={onDeleteConfirm}
          />
        )}
      </div>
    </>
  );
};

export default Support;
