import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import API from '../../../../service/api';
import Network from '../../../../service/Network';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { inputStringLength, toastConfig } from 'assets/data/config';
import { PreparePath } from 'utils/sharedUtils/sharedConst';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import StylishNewInput from 'components/DesignSystems/New/StylishNewInput';
import StylishDateTimePicker from 'components/DesignSystems/form/StylishDateTimePicker';
import { endLoading, startLoading } from "../../../../../reducers/loading/loading.action";

export const MSELCreate = ({ onClose, show, eventId }) => {
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const dispatch = useDispatch();

  const { selectedWorkspace } = useSelector((state) => state.prepare.workspace);
  const reduxUser = useSelector((state) => state.prepare.user);
  const reduxTimezone = useSelector(
    (state) => state.prepare.workspace.timezone
  );
  const navigate = useNavigate();

  useEffect(() => {
    // if (dialogType === "Edit") {
    //   updateDialogData &&
    //     setValue("startexDate", moment(updateDialogData?.startDate).toDate());
    // } else if (dialogType === "Add") {
    // }
    setValue('dateTime', new Date());
  }, [reduxUser]);

  const onSubmit = async (data) => {
    dispatch(startLoading());
    try {
      const eventData = eventId
        ? {
            eventId: eventId,
          }
        : {};
      const newInject = await Network.post(API.addInject, {
        workspaceId: selectedWorkspace,
        title: data.title,
        startDate: data.dateTime,
        ...eventData,
      });
      const newId = newInject.data.response.dataset.map((e) => e.id);

      onClose(true);
      navigate(
        `${PreparePath}/dashboard/plan/prepare/msel-all-details/${newId}`
      );
    } catch (error) {
      toast.error(error.response.data.response.status.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };
  useEffect(() => {
    const listener = (event) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        event.preventDefault();
        handleSubmit(onSubmit)();
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, []);
  return (
    <>
      <Modal
        show={show}
        onHide={onClose}
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton closeVariant="white">
          <Modal.Title>Create MSEL Inject</Modal.Title>
        </Modal.Header>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body>
            <div className="mb-3">
              <label className="form-label">Title</label>
              <Controller
                control={control}
                name={'title'}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, value } }) => (
                  <StylishNewInput
                    onChange={(e) => {
                      onChange(e);
                    }}
                    value={value}
                    type="text"
                    name="title"
                    maxLength={inputStringLength}
                    className="form-control"
                    placeholder="Title"
                  />
                )}
              />
              {errors?.title && (
                <span className="form-text form-error">
                  This is a required field
                </span>
              )}
            </div>
            <div className="mb-0">
              <label className="form-label">
                Date &amp; Time ({reduxTimezone.split('/')[0]})
              </label>
              <Controller
                control={control}
                name="dateTime"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <StylishDateTimePicker
                    onChange={onChange}
                    selected={value}
                    selectsStart
                    value={value}
                    isClearable={true}
                    timeFormat="HH:mm"
                    timeIntervals={20}
                    timeCaption="time"
                    dateFormat={
                      reduxUser?.timeFormat === 'hour12Mode'
                        ? 'MMMM d, yyyy hh:mm a'
                        : 'MMMM d, yyyy HH:mm'
                    }
                    placeholderText={'Choose Start Date'}
                    disableDateTimeLabel={true}
                    type="datetime-local"
                  />
                )}
              />
              {errors.dateTime && (
                <span className="form-text form-error">
                  This is a required field
                </span>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="button-group">
              <StylishNewButton
                className="button button--secondary button--reverse"
                onClick={onClose}
              >
                Close
              </StylishNewButton>

              {/* <button
                className="button button--primary"
                type="submit"
                onClick={(e) => onSubmitHandler(e)}
              > */}

              <StylishNewButton
                className="button button--primary"
                type="submit"
              >
                Save
              </StylishNewButton>
            </div>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};
