import React, { useEffect, useState } from 'react';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';

import StylishNewTable from 'components/DesignSystems/New/StylishNewTable';
import StylishNewTableAction from 'components/DesignSystems/New/StylishNewTableAction';
import Network from 'PREPAREsrc/service/Network';
import API from 'PREPAREsrc/service/api';
import CurrencyFormat from 'react-currency-format';
import { endLoading, startLoading } from 'reducers/loading/loading.action';
import { toastConfig } from 'assets/data/config';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import AddEditFundingSourceDialog from '../../Prepare/ExerciseDetails/FundingSources/AddEditFundingSourceDialog';
import { StylishConfirmDialog } from 'components/DesignSystems/New/StylishConfirmDialog';
import IconPlaceholder from 'assets/images/icon__exercise-details-empty.svg';
import { PaginationDropdown } from '../../../../../components/DesignSystems/PaginationDropdown';

export const Funding = ({ changeTab }) => {
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [totalFunding, setTotalFunding] = useState(0);
  const [fundingSources, setFundingSources] = useState([]);
  const searchTerm = '';
  const [perPage, setPerPage] = useState(5);
  const [orderBy, setOrderBy] = useState('amount_ASC');
  const [dialogType, setDialogType] = useState('Add');
  const [
    showFundingSourcesAddEditDialog,
    setShowFundingSourcesAddEditDialog,
  ] = useState(false);
  const [updateDialogData, setUpdateDialogData] = useState(null);
  const [deleteFundings, setDeleteFundings] = useState(null);

  const dispatch = useDispatch();

  const eventId = useSelector((state) => state.prepare.planDashboard.eventId);

  const {
    selectedWorkspace,
    // workspaceName
  } = useSelector((state) => {
    return state.prepare.workspace;
  });

  const columns = [
    {
      dataField: 'primarysource',
      text: 'Primary Source',
      sort: true,
      onSort: (field, order) => {
        if (order === 'asc') {
          setOrderBy('primarysource_ASC');
        } else if (order === 'desc') {
          setOrderBy('primarysource_DESC');
        } else {
          setOrderBy('');
        }
      },

      headerAttrs: { title: 'Sort By:' },
      attrs: { title: 'Primary Source' },
    },
    {
      dataField: 'subsource',
      text: 'Sub-source',
      sort: true,
      onSort: (field, order) => {
        if (order === 'asc') {
          setOrderBy('subsource_ASC');
        } else if (order === 'desc') {
          setOrderBy('subsource_DESC');
        } else {
          setOrderBy('');
        }
      },

      attrs: { title: 'Sub-source' },
    },
    {
      dataField: 'amountShown',
      text: 'Amount',
      sort: true,
      onSort: (field, order) => {
        if (order === 'asc') {
          setOrderBy('amount_ASC');
        } else if (order === 'desc') {
          setOrderBy('amount_DESC');
        } else {
          setOrderBy('');
        }
      },

      attrs: { title: 'Amount' },
    },
    {
      dataField: '',

      text: 'Action',
      isDummyField: true,
      formatter: (cell, row, rowIndex) => (
        <StylishNewTableAction
          name="Funding Source"
          cell={cell}
          row={row}
          rowIndex={rowIndex}
          onDelete={onDeleteFunding}
          onEdit={onEditFunding}
        />
      ),
      attrs: { title: 'Action' },
      headerStyle: {
        width: '100px',
      },
      headerAlign: 'center',
      align: 'center',
    },
  ];

  const getAllFundings = async () => {
    dispatch(startLoading());
    try {
      const funding = await Network.get(API.fetchAllFundingSources, {
        limit: perPage,
        page: page,
        search: searchTerm,
        workspaceId: selectedWorkspace,
        planEventId: eventId,
        sort: orderBy,
      });
      setFundingSources(
        funding.data.response.dataset.map((e) => {
          return {
            ...e,
            amountShown: (
              <CurrencyFormat
                value={e?.amount}
                displayType={'text'}
                thousandSeparator={true}
                prefix={'$ '}
              />
            ),
          };
        })
      );
      setTotal(funding.data.response.total || 0);
      setTotalFunding(funding.data.response.totalFund || 0);
    } catch (error) {
      toast.error(error.response.data.response.status.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };

  const onAddFundings = () => {
    setDialogType('Add');
    setShowFundingSourcesAddEditDialog(true);
  };

  const onDeleteFunding = (row) => {
    setDeleteFundings(row.id);
  };

  const onEditFunding = (row) => {
    setUpdateDialogData(row);
    setDialogType('Edit');
    setShowFundingSourcesAddEditDialog(true);
  };

  const OnAddOrEditFundings = (afterSave) => {
    setUpdateDialogData(null);
    setDialogType('New');
    setShowFundingSourcesAddEditDialog(false);
    if (afterSave?.id) {
      getAllFundings();
    }
  };

  const onDeleteConfirm = async () => {
    dispatch(startLoading());
    try {
      await Network.post(API.deleteFunding, { id: deleteFundings });
      setDeleteFundings(null);
      getAllFundings();
      toast.success('Funding deleted successfully', toastConfig);
    } catch (error) {
      toast.error(error.response.data.response.status.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };

  useEffect(() => {
    if (changeTab === 'funding') {
      setPage(1);
      setPerPage(5);
    }
  }, [changeTab, selectedWorkspace, eventId]);

  useEffect(() => {
    if (changeTab === 'funding') {
      getAllFundings();
    }
  }, [changeTab, page, perPage, orderBy, selectedWorkspace, eventId]);

  return (
    <>
      {(fundingSources && !!fundingSources?.length && (
        <>
          <div className="d-md-flex align-items-center mb-4">
            <StylishNewButton
              type="button"
              className={'button--primary w-100 w-md-auto mb-3 mb-md-0'}
              onClick={onAddFundings}
            >
              Add Funding
            </StylishNewButton>
            <div className="ms-auto">
              <PaginationDropdown
                setPage={setPage}
                setPerPage={setPerPage}
                options={[
                  { value: 5, label: '5 per page' },
                  { value: 15, label: '15 per page' },
                  { value: 30, label: '30 per page' },
                  { value: 60, label: '60 per page' },
                  { value: 120, label: '120 per page' },
                ]}
              />
            </div>
          </div>
          <h5 className="mb-4">
            Total Fundings: $ {totalFunding?.toLocaleString()}
          </h5>
          <StylishNewTable
            keyField={'id'}
            columns={columns}
            rows={fundingSources}
            pagination
            page={page}
            perPage={perPage}
            total={total}
            onPageChange={(page) => setPage(page)}
          />
        </>
      )) || (
        <div className="bg-gray-800 text-center px-4 py-5 p-md-5">
          <div className="py-0 py-md-4">
            <div className="d-flex justify-content-center mb-4">
              <img src={IconPlaceholder} alt="" />
            </div>
            <p className="weight-600 mb-3">
              A fresh start. Create the first entry.
            </p>
            <div className="d-flex justify-content-center">
              <StylishNewButton
                type="button"
                className={'button--primary'}
                onClick={onAddFundings}
              >
                Add Funding
              </StylishNewButton>
            </div>
          </div>
        </div>
      )}
      {showFundingSourcesAddEditDialog && (
        <AddEditFundingSourceDialog
          show={showFundingSourcesAddEditDialog}
          onClose={OnAddOrEditFundings}
          dialogType={dialogType}
          updateDialogData={updateDialogData}
        />
      )}
      {deleteFundings && (
        <StylishConfirmDialog
          show={deleteFundings}
          onClose={() => setDeleteFundings(null)}
          dialogType={'Delete'}
          dialogTitle={'Delete Funding'}
          dialogContent={'Are you sure you want this?'}
          updateDialogData={deleteFundings}
          onConfirm={onDeleteConfirm}
        />
      )}
    </>
  );
};
