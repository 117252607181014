import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { HelpExerciseDetailsReview, useSelfHostedKnowledgeBase } from 'assets/data/HelpContent';
import { PreparePath } from 'utils/sharedUtils/sharedConst';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import StylishHelp from 'components/DesignSystems/New/StylishHelp';

export default function Review(props) {
  const navigate = useNavigate();

  const selfHostedKnowledgeBase = useSelfHostedKnowledgeBase()

  const workspaceType = useSelector((state) => state.prepare.workspace.type);

  const nav = () => {
    navigate(`${PreparePath}/dashboard/plan/prepare/exercise-details`);
  };

  return (
    <>
      <div className="d-flex align-items-center mb-4">
        <h4 className="mb-0 me-4">Review Initial Exercise Design</h4>
        <StylishHelp
          classes={'ms-auto sml flex-shrink-0'}
          title={HelpExerciseDetailsReview.title}
          content={HelpExerciseDetailsReview.content}
          link={selfHostedKnowledgeBase || HelpExerciseDetailsReview.link}
        />
      </div>
      {workspaceType === 'DOD' ? (
        <>
          <div className="form-block">
            <div className="row mb-4 align-items-center">
              <div className="col-md-5 col-lg-3 mb-3 mb-md-0">
                <h4 className="mb-0 text-center text-md-start">
                  Exercise Details
                </h4>
              </div>
              <div className="col-md-7 col-lg-9">
                <a
                  className="button button--primary w-100 w-md-25 cursor-pointer"
                  onClick={() => props.onPrevClick(0)}
                >
                  Review
                </a>
              </div>
            </div>

            <div className="row mb-4 align-items-center">
              <div className="col-md-5 col-lg-3 mb-3 mb-md-0">
                <h4 className="mb-0 text-center text-md-start">
                  Planning Meetings
                </h4>
              </div>
              <div className="col-md-7 col-lg-9">
                <a
                  className="button button--primary w-100 w-md-25 cursor-pointer"
                  onClick={() => props.onPrevClick(1)}
                >
                  Review
                </a>
              </div>
            </div>

            <div className="row mb-4 align-items-center">
              <div className="col-md-5 col-lg-3 mb-3 mb-md-0">
                <h4 className="mb-0 text-center text-md-start">
                  Funding Sources
                </h4>
              </div>
              <div className="col-md-7 col-lg-9">
                <a
                  className="button button--primary w-100 w-md-25 cursor-pointer"
                  onClick={() => props.onPrevClick(2)}
                >
                  Review
                </a>
              </div>
            </div>

            <div className="row mb-4 align-items-center">
              <div className="col-md-5 col-lg-3 mb-3 mb-md-0">
                <h4 className="mb-0 text-center text-md-start">Objectives</h4>
              </div>
              <div className="col-md-7 col-lg-9">
                <a
                  className="button button--primary w-100 w-md-25 cursor-pointer"
                  onClick={() => props.onPrevClick(3)}
                >
                  Review
                </a>
              </div>
            </div>

            <div className="row align-items-center">
              <div className="col-md-5 col-lg-3 mb-3 mb-md-0">
                <h4 className="mb-0 text-center text-md-start">
                  Tasks & Priorites
                </h4>
              </div>
              <div className="col-md-7 col-lg-9">
                <a
                  className="button button--primary w-100 w-md-25 cursor-pointer"
                  onClick={() => props.onPrevClick(4)}
                >
                  Review
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-12 mt-4">
            <div className="button-group">
              <StylishNewButton
                type="submit"
                className="button button--primary button--reverse"
                onClick={() => props.onPrevClick(4)}
              >
                Previous
              </StylishNewButton>
              <StylishNewButton
                type="submit"
                className="button button--primary ms-auto"
                onClick={nav}
              >
                Complete
              </StylishNewButton>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="form-block">
            <div className="row mb-4 align-items-center">
              <div className="col-md-5 col-lg-3 mb-3 mb-md-0">
                <h4 className="mb-0 text-center text-md-start">
                  Exercise Details
                </h4>
              </div>
              <div className="col-md-7 col-lg-9">
                <a
                  className="button button--primary w-100 w-md-25 cursor-pointer"
                  onClick={() => props.onPrevClick(0)}
                >
                  Review
                </a>
              </div>
            </div>

            <div className="row mb-4 align-items-center">
              <div className="col-md-5 col-lg-3 mb-3 mb-md-0">
                <h4 className="mb-0 text-center text-md-start">
                  Planning Meetings
                </h4>
              </div>
              <div className="col-md-7 col-lg-9">
                <a
                  className="button button--primary w-100 w-md-25 cursor-pointer"
                  onClick={() => props.onPrevClick(1)}
                >
                  Review
                </a>
              </div>
            </div>

            <div className="row mb-4 align-items-center">
              <div className="col-md-5 col-lg-3 mb-3 mb-md-0">
                <h4 className="mb-0 text-center text-md-start">
                  Funding Sources
                </h4>
              </div>
              <div className="col-md-7 col-lg-9">
                <a
                  className="button button--primary w-100 w-md-25 cursor-pointer"
                  onClick={() => props.onPrevClick(2)}
                >
                  Review
                </a>
              </div>
            </div>

            <div className="row mb-4 align-items-center">
              <div className="col-md-5 col-lg-3 mb-3 mb-md-0">
                <h4 className="mb-0 text-center text-md-start">
                  Core Capabilities & Associated Mission Area(s)
                </h4>
              </div>
              <div className="col-md-7 col-lg-9">
                <a
                  className="button button--primary w-100 w-md-25 cursor-pointer"
                  onClick={() => props.onPrevClick(4)}
                >
                  Review
                </a>
              </div>
            </div>

            <div className="row align-items-center">
              <div className="col-md-5 col-lg-3 mb-3 mb-md-0">
                <h4 className="mb-0 text-center text-md-start">
                  Organizational Capability Targets
                </h4>
              </div>
              <div className="col-md-7 col-lg-9">
                <a
                  className="button button--primary w-100 w-md-25 cursor-pointer"
                  onClick={() => props.onPrevClick(3)}
                >
                  Review
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-12 mt-4">
            <div className="button-group">
              <StylishNewButton
                type="submit"
                className="button button--primary button--reverse"
                onClick={() => props.onPrevClick(4)}
              >
                Previous
              </StylishNewButton>
              <StylishNewButton
                type="submit"
                className="button button--primary ms-auto"
                onClick={nav}
              >
                Complete
              </StylishNewButton>
            </div>
          </div>
        </>
      )}
    </>
  );
}
