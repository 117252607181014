import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import Network from '../../service/Network';
import api from '../../service/api';
import { toast } from 'react-toastify';
import ShareInviteLinkDialog from './ShareInviteLinkDialog';
import {
  columnsInUserRoleInfoTable,
  rowsInUserRoleInfoTable,
} from 'assets/data/UserRoleInfo';
import StylishHelp from 'components/DesignSystems/New/StylishHelp';
import { HelpMembers, HelpWorkspaceMembers, useSelfHostedKnowledgeBase } from 'assets/data/HelpContent';
import StylishNewTable from 'components/DesignSystems/New/StylishNewTable';
import StylishNewSelect from 'components/DesignSystems/New/StylishNewSelect';
import { StylishNewSearchBar } from 'components/DesignSystems/New/StylishNewSearchBar';
import StylishNewInput from 'components/DesignSystems/New/StylishNewInput';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { toastConfig } from 'assets/data/config';
import API from '../../service/api';
import { PaginationDropdown } from '../../../components/DesignSystems/PaginationDropdown';
import {
  endLoading,
  startLoading,
} from '../../../reducers/loading/loading.action';
import isRoleReadOnly from 'PREPAREsrc/utils/isRoleReadOnly';

export default function Members() {
  const selfHostedKnowledgeBase = useSelfHostedKnowledgeBase()
  const dispatch = useDispatch();
  const [userList, setUserList] = useState([]);
  const [fullUserList, setFullUserList] = useState([]);
  const [exerciseDetailsName, setExerciseDetailsName] = useState('');
  const [roles, setRoles] = useState([]);
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [roleId, setRoleId] = useState('');
  const [userId, setUserId] = useState('');
  const [perPage, setPerPage] = useState(25);
  const [total, setTotal] = useState(0);
  const [showShareInviteLink, setShowShareInviteLink] = useState(false);
  const [showRoleError, setShowRoleError] = useState(false);
  const [selectedRoleIds, setSelectedRoleIds] = useState([]);
  const reduxUser = useSelector((state) => state.prepare.user);
  const { selectedWorkspace, workspaceName } = useSelector((state) => {
    return state.prepare.workspace;
  });
  const workspaceId = useSelector(
    (state) => state.prepare.workspace.selectedWorkspace
  );
  const user = useSelector((state) => state.prepare.user);

  const columns = [
    {
      dataField: 'name',
      text: 'Name',
      sort: true,
      headerAttrs: { title: 'Sort By:' },
      attrs: { title: 'Title' },
    },
    {
      dataField: 'email',
      text: 'Email',
      attrs: { title: 'Email' },
    },
    { dataField: 'role', text: 'Role(s)', attrs: { title: 'Role(s)' } },
    // {
    //   text: 'Last Login',
    //   formatter: (cell, row, rowIndex) => {
    //     return row.lastLogin && moment(row.lastLogin).isValid()
    //       ? moment(row.lastLogin).fromNow()
    //       : '';
    //   },
    //   attrs: { title: 'Last Login' },
    // },
  ];
  const onShareInviteLink = (userid) => {
    setUserId(userid);
    setShowShareInviteLink(true);
  };
  const onShareInviteLinkDialogClose = () => {
    setUserId(null);
    setShowShareInviteLink(false);
  };
  const getAllExerciseDetails = async () => {
    dispatch(startLoading());

    if (selectedWorkspace) {
      try {
        Network.get(API.planExerciseTypeList, {
          workspaceId: selectedWorkspace,
        })
          .then((resp) => {
            setExerciseDetailsName(resp.data.response.dataset[0]);
          })
          .catch(console.log)
          .finally(() => {
            dispatch(endLoading());
          });
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    getAllExerciseDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reduxUser, selectedWorkspace]);

  const expandRow = {
    parentClassName: 'parent-expand-tr',
    className: 'child-expand-tr',
    renderer: (row, rowIndex) => {
      return (
        <>
          <div className="row">
            <div className="col-md-6">
              <div className="mb-3">
                <label className="form-label d-block mb-0">Name</label>
                {row.name}
              </div>
              <div className="mb-3">
                <label className="form-label d-block mb-0">Email</label>{' '}
                {row.email}
              </div>
              {/* <div className="mb-3">
                <label className="form-label d-block mb-0">
                  Invite Accepted
                </label>{" "}
                {row.inviteAccepted === true ? "Yes" : "No"}
              </div> */}
              <div className="mb-3">
                <label className="form-label d-block mb-0">
                  Email Confirmed
                </label>{' '}
                {row.emailConfirmed === true ? 'Yes' : 'No'}
              </div>
              {/* <div className="mb-3">
                <label className="form-label d-block mb-0">Joined At</label>
                {row.joinedAt && moment(row.joinedAt).isValid()
                  ? moment(row.joinedAt).format('MM/DD/YYYY HH:mm:ss')
                  : ''}
              </div> */}
            </div>
            <div className="col-md-6">
              {/* <div className="mb-3">
                <label className="form-label d-block mb-0">Last Login At</label>{' '}
                {row.lastLogin && moment(row.lastLogin).isValid()
                  ? moment(row.lastLogin).format('MM/DD/YYYY HH:mm:ss')
                  : ''}
              </div> */}
              {user.id !== row.userid ? (
                <div className="mb-3">
                  <label className="form-label d-block">Available Roles</label>
                  <Controller
                    control={control}
                    name="role"
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <StylishNewSelect
                        options={roles}
                        defaultValue={value}
                        value={selectedRoleIds[rowIndex]}
                        onChange={(e) => {
                          if (!e) {
                            setError('role');
                            setSelectedRoleIds([]);
                          } else if (e.value) {
                            const roleIds = structuredClone(selectedRoleIds);
                            roleIds[rowIndex] = e;
                            setSelectedRoleIds(roleIds);
                            clearErrors('role');
                            setRoleId(e.value);
                            setUserId(row.userid);
                          }
                          return onChange(e);
                        }}
                        placeholder={'Select Role'}
                        isClearable={true}
                        isSearchable={true}
                        isMulti={false}
                        isDisabled={false}
                      />
                    )}
                  />
                  {showRoleError && (
                    <span className="form-text form-error">
                      This field is required
                    </span>
                  )}
                </div>
              ) : (
                <></>
              )}
              <div className="mb-3">
                <label className="form-label d-block">Assigned roles</label>
                <ul className="nav">
                  {row.role.split(',').map((data) => {
                    if (data) {
                      return (
                        <li key={data}>
                          <span className="badge">{data}</span>
                        </li>
                      );
                    }
                  })}
                </ul>
              </div>
            </div>
            <div className="col-md-12">
              {user.id !== row.userid && !isRoleReadOnly(user.roles) ? (
                <div className="button-group mt-2">
                  <StylishNewButton
                    // type="button"
                    className="button button--primary"
                    onClick={userRole}
                  >
                    Save
                  </StylishNewButton>
                  <StylishNewButton
                    // type="button"
                    className="button button--tertiary"
                    onClick={() => revokeUserPermission(row.userid)}
                  >
                    Revoke Permissions
                  </StylishNewButton>
                  <StylishNewButton
                    // type="button"
                    className="button button--tertiary button--reverse"
                    onClick={() => removeUser(row.userid)}
                  >
                    Remove from Workspace
                  </StylishNewButton>
                  {row &&
                    row.workspace &&
                    row.inviteAccepted === false &&
                    row.workspace.length > 0 && (
                      <StylishNewButton
                        // type="button"
                        className="button button--primary button--reverse"
                        onClick={() => resendInvite(row.userid)}
                      >
                        Resend Invite
                      </StylishNewButton>
                    )}
                  {row.inviteAccepted === false && (
                    <StylishNewButton
                      // type="button"
                      className="button button--primary"
                      onClick={() => onShareInviteLink(row.userid)}
                    >
                      Share Invite Link
                    </StylishNewButton>
                  )}
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>

          {showShareInviteLink && (
            <ShareInviteLinkDialog
              show={showShareInviteLink}
              onHide={onShareInviteLinkDialogClose}
              userId={userId}
            />
          )}
        </>
      );
    },
    showExpandColumn: true,
    expandByColumnOnly: false,
    expandHeaderColumnRenderer: ({ isAnyExpands }) => {
      if (isAnyExpands) {
        return <i className="fa fa-chevron-down" aria-hidden="true"></i>;
      }
      return <i className="fa fa-chevron-right" aria-hidden="true"></i>;
    },
    expandColumnRenderer: ({ expanded }) => {
      if (expanded) {
        return (
          <>
            <div className="d-flex align-items-center justify-content-md-center">
              <span className="me-2">Details</span>
              <i className="fa fa-chevron-down" aria-hidden="true"></i>
            </div>
          </>
        );
      }
      return (
        <>
          <div className="d-flex align-items-center justify-content-md-center">
            <span className="me-2">Details</span>
            <i className="fa fa-chevron-right" aria-hidden="true"></i>
          </div>
        </>
      );
    },
  };

  const {
    setValue,
    getValues,
    reset,
    control,
    setError,
    clearErrors,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onChangeSearchTerm = (term) => {
    setPage(1);
    setSearchTerm(term);
  };

  const removeUser = async (userId) => {
    dispatch(startLoading());
    await Network.post(api.removeUserFromWorkspace, {
      userId: userId,
      workspaceId: selectedWorkspace,
    })
      .then((resp) => {
        toast.success(resp.data.response.status.msg, toastConfig);
        workSpaceUserList();
      })
      .catch((err) => {
        toast.error(err.response.data.response.status.msg, toastConfig);
      })
      .finally(() => {
        dispatch(endLoading());
      });
  };

  const resendInvite = async (userId) => {
    dispatch(startLoading());
    await Network.post(api.resendInvite, {
      userId: userId,
      workspace: workspaceId,
    })
      .then((resp) => {
        toast.success(resp.data.response.status.msg, toastConfig);
        workSpaceUserList();
      })
      .catch((err) => {
        toast.error(err.response.data.response.status.msg, toastConfig);
      })
      .finally(() => {
        dispatch(endLoading());
      });
  };

  const revokeUserPermission = async (userId) => {
    dispatch(startLoading());
    await Network.post(api.revokeUserPermission, {
      userId: userId,
    })
      .then((resp) => {
        toast.success(resp.data.response.status.msg, toastConfig);
        workSpaceUserList();
      })
      .catch((err) => {
        toast.error(err.response.data.response.status.msg, toastConfig);
      })
      .finally(() => {
        dispatch(endLoading());
      });
  };

  const userRole = () => {
    setShowRoleError(!roleId);
    roleId &&
      Network.post(api.assignUserRole, {
        role: roleId,
        userId: userId,
      })
        .then((resp) => {
          toast.success(resp.data.response.status.msg, toastConfig);
          setValue('role', '');
          setRoleId('');
          workSpaceUserList();
        })
        .catch((err) => {
          toast.error(err.response.data.response.status.msg, toastConfig);
        });
  };

  useEffect(() => {
    getUserRole();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    const from = (page - 1) * perPage;
    const to = (page - 1) * perPage + perPage;

    setUserList(fullUserList.slice(from, to));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, perPage, fullUserList]);
  useEffect(() => {
    workSpaceUserList();
    // getUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm, selectedWorkspace, reduxUser, page, perPage]);

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()

      .match(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const inviteUser = (e) => {
    const data = getValues();
    const email = data?.email?.toLowerCase();
    if (
      email === '' ||
      !validateEmail(email) ||
      data.roleInvite === undefined
    ) {
      return;
    }

    const workspace = selectedWorkspace;
    const role = data.roleInvite?.value;

    dispatch(startLoading());
    Network.post(api.inviteNewUser, { email, workspace, role })
      .then((resp) => {
        toast.success('User Invited', toastConfig);
        reset();
        setValue('email', '');

        setValue('roleInvite', '');
        workSpaceUserList();
      })
      .catch((err) => {
        toast.error(err.response.data.response.status.msg, toastConfig);
      })
      .finally(() => {
        dispatch(endLoading());
      });
  };

  const getUserRole = () => {
    dispatch(startLoading());
    Network.get(api.roleList)
      .then((resp) => {
        setRoles(
          resp.data.response.dataset.map((data) => {
            return {
              value: data.roleId,
              label: data.name,
            };
          })
        );
      })
      .catch((err) => {
        toast.error(err.response.data.response.status.msg, toastConfig);
      })
      .finally(() => {
        dispatch(endLoading());
      });
  };

  const workSpaceUserList = async () => {
    dispatch(startLoading());

    if (selectedWorkspace) {
      await Network.get(api.workSpaceUserList, {
        limit: perPage,
        page: page,
        search: searchTerm,
        workspaceId: selectedWorkspace,
      })
        .then((resp) => {
          const users = resp.data.response.dataset.map((ele) => {
            return {
              ...ele,
              role: ele.role.map((element) => element.rolename).join(','),
            };
          });
          setFullUserList(users);
          setTotal(resp.data.response.count);
          setSelectedRoleIds(
            Array.from(Array(resp.data.response.count), () => null)
          );
        })
        .catch((err) => {})
        .finally(() => {
          dispatch(endLoading());
        });
    }
  };

  return (
    <>
      <div className="settings__member">
        {!isRoleReadOnly(user.roles) && (
          <form onSubmit={handleSubmit(inviteUser)}>
            <div className="row">
              <div className="col-md-6 mb-3">
                <label className="form-label">
                  Invite User By Email to {workspaceName} Workspace
                  <span aria-label="Required field" className="required">
                    *
                  </span>
                </label>
                {/* <input
                className="form-control"
                type="email"
                placeholder="Email"
                {...register('email', {
                  required: true,
                  pattern: {
                    value: /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-z]+)$/,
                    message: 'Invalid email address',
                  },
                })}
              /> */}
                <Controller
                  control={control}
                  name="email"
                  rules={{
                    required: true,
                    pattern: {
                      value: /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-z]+)$/,
                      message: 'Invalid Email Address',
                    },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <StylishNewInput
                      type="email"
                      className="form-control"
                      onChange={onChange}
                      value={value}
                      placeholder="Email"
                      // {...register('email', {
                      //   required: true,
                      //   pattern: {
                      //     value: /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-z]+)$/,
                      //     message: 'Invalid Email Address',
                      //   },
                      // })}
                    />
                  )}
                />

                {errors?.email?.type === 'required' && (
                  <span className="form-text form-error">
                    This field is required
                  </span>
                )}
                {errors?.email?.type === 'pattern' && (
                  <span className="form-text form-error">
                    Please Enter a Valid Email
                  </span>
                )}
              </div>
              <div className="col-md-6 mb-4 mb-md-3">
                <label className="form-label">
                  Select Role for User
                  <span aria-label="Required field" className="required">
                    *
                  </span>
                </label>
                <div className="d-flex align-items-center">
                  <div className="flex-grow-1">
                    <div className="">
                      <Controller
                        control={control}
                        name="roleInvite"
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => (
                          <StylishNewSelect
                            options={roles}
                            defaultValue={value}
                            value={value}
                            // onChange={(e) => handleChange(e, row.userid)}
                            onChange={(e) => {
                              if (!e) {
                                setError('roleInvite');
                              } else if (e.value) {
                                clearErrors('roleInvite');
                              }
                              return onChange(e);
                            }}
                            placeholder={'Select Role'}
                            isClearable={true}
                            isSearchable={true}
                            isMulti={false}
                            isDisabled={false}
                          />
                        )}
                      />
                    </div>
                  </div>
                  <StylishHelp
                    classes={'ms-3 flex-shrink-0 sml'}
                    hasChild={true}
                  >
                    <h4>{HelpMembers.title}</h4>
                    <StylishNewTable
                      classes="table--raw"
                      keyField={'userid'}
                      columns={columnsInUserRoleInfoTable}
                      rows={rowsInUserRoleInfoTable}
                      bordered={false}
                      striped={true}
                    />
                    <a
                      className="button button--primary mt-4"
                      href={selfHostedKnowledgeBase || HelpMembers.link}
                      target="_blank"
                    >
                      More Help
                    </a>
                  </StylishHelp>
                </div>
                {errors?.roleInvite?.type === 'required' && (
                  <span className="form-text form-error">
                    This field is required
                  </span>
                )}
              </div>
              <div className="col-md-12">
                <StylishNewButton
                  // onClick={inviteUser}
                  type="submit"
                  className="button button button--primary"
                >
                  Invite New User
                </StylishNewButton>
              </div>
            </div>
          </form>
        )}
        <hr />
        <div className="row">
          <div className="col-md-12">
            <h4>
              Members of {workspaceName || exerciseDetailsName?.eventName}
            </h4>
            <div className="form-block">
              <div className="d-flex flex-column flex-md-row align-items-md-center mb-4">
                <div className="d-flex align-items-center justify-content-between">
                  <p className="m-0 me-3 text-nowrap">
                    {total || 0} Workspace Users
                  </p>
                  <div className="">
                    <PaginationDropdown
                      setPage={setPage}
                      setPerPage={setPerPage}
                      options={[
                        { value: 25, label: '25 per page' },
                        { value: 50, label: '50 per page' },
                        { value: 75, label: '75 per page' },
                        { value: 100, label: '100 per page' },
                      ]}
                    />
                  </div>
                </div>
                <div className="ms-md-auto mt-3 mt-md-0 d-flex align-items-center">
                  <div className="flex-grow-1">
                    <StylishNewSearchBar
                      onChangeSearchTerm={onChangeSearchTerm}
                    />
                  </div>
                  <StylishHelp
                    classes={'ms-3 flex-shrink-0 sml'}
                    title={HelpWorkspaceMembers.title}
                    content={HelpWorkspaceMembers.content}
                    link={selfHostedKnowledgeBase || HelpWorkspaceMembers.link}
                  />
                </div>
              </div>
              <StylishNewTable
                keyField={'userid'}
                columns={columns}
                rows={userList}
                pagination
                page={page}
                perPage={perPage}
                total={total}
                expandRow={expandRow}
                onPageChange={(page) => setPage(page)}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
