import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import { StylishNewSearchBar } from 'components/DesignSystems/New/StylishNewSearchBar';
import ReactPaginate from 'react-paginate';
import {
  MiscellaneousHelpLink,
  PostsHelp,
  useSelfHostedKnowledgeBase,
} from 'assets/data/HelpContent';
import IconEdit from 'assets/images/icon__edit.svg';
import IconComment from 'assets/images/icon__comment.svg';
import IconProfile from 'assets/images/icon__profile--line.svg';
import IconDelete from 'assets/images/icon__delete.svg';
import ViewPostDialog from '../ViewPostDialog/ViewPostDialog';
import CreatePostDialog from './CreatePostDialog';
import API from '../../../service/api';
import Network from '../../../service/Network';
import StylishNewSelect from 'components/DesignSystems/New/StylishNewSelect';
import { AllIcon } from 'PREPAREsrc/pages/Settings/InjectMethod/Icon';
import moment from 'moment';
import { PreparePath } from 'utils/sharedUtils/sharedConst';
import IconEmptyExercise from 'assets/images/icon__exercise-details-empty.svg';
import { StylishConfirmDialog } from 'components/DesignSystems/New/StylishConfirmDialog';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { toast } from 'react-toastify';
import { contentBlockLength, toastConfig } from 'assets/data/config';
import StylishHelp from 'components/DesignSystems/New/StylishHelp';
import IconCommentImage from 'assets/images/icon__image--post.svg';
import IconCommentLocation from 'assets/images/icon__location--post.svg';
import IconResponce from 'assets/images/icon__responce.svg';
import IconChat from 'assets/images/icon__chat--post.svg';
import { sanitizeHtml } from '../../../../utils/sanitizeHtml';
import {
  endLoading,
  startLoading,
} from '../../../../reducers/loading/loading.action';
import isRoleReadOnly from 'PREPAREsrc/utils/isRoleReadOnly';
import PageTitle from 'components/headers/PageTitle';
import { useNewUIFlag } from 'utils/featureFlags';

export default function Posts() {
  const [showViewPostDialog, setShowViewPostDialog] = useState(false);
  const [showCreatePostDialog, setShowCreatePostDialog] = useState(false);
  const [postDetails, setPostDetails] = useState([]);
  const [postTotal, setPostTotal] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(1);
  const [postId, setPostId] = useState('');
  const [selectedSiteId, setSelectedSiteId] = useState('');
  const [selectedPostid, setSelectedPostid] = useState('');
  const [filter, setFilter] = useState(null);
  const [deletePost, setDeletePost] = useState(null);
  const [isNoiseGeneratedPost, setIsNoiseGeneratedPost] = useState(false);
  const [countAll, setCountAll] = useState(null);
  const [countPublish, setCountPublish] = useState(null);
  const [countScheduled, setCountScheduled] = useState(null);
  const [countDrafted, setCountDrafted] = useState(null);
  const [countNoise, setCountNoise] = useState(null);
  const [deleteType, setDeleteType] = useState(null);

  const [sortOption, setSortOption] = useState({
    value: 'last_updated',
    label: 'Last Updated',
  });

  const selfHostedKnowledgeBase = useSelfHostedKnowledgeBase();

  const featureFlags = useSelector((state) => {
    return state.app.featureFlags;
  });

  const perPage = 8;

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const location = useLocation();

  const workspaceId = useSelector(
    (state) => state.prepare.workspace.selectedWorkspace
  );

  const newUI = useNewUIFlag();

  const sortOptions = [
    { value: 'last_updated', label: 'Last Updated' },
    { value: 'oldest_updated', label: 'Oldest Updated' },
    { value: 'last_created', label: 'Last Created' },
    { value: 'oldest_created', label: 'Oldest Created' },
    { value: 'last_published', label: 'Last Published' },
    { value: 'oldest_published', label: 'Oldest Published' },
  ];

  const onViewPostDialog = (data) => {
    setIsNoiseGeneratedPost(data?.isNoiseGeneratedPost);
    setPostId(data?.id);
    setShowViewPostDialog(true);
  };
  const onViewPostDialogClose = (data) => {
    data === true && mediaPostList();
    setShowViewPostDialog(false);
  };

  const onCreatePostDialog = (data) => {
    setShowCreatePostDialog(true);
    setSelectedSiteId(data?.siteId);
    setSelectedPostid(data?.id);
  };
  useEffect(() => {
    if (location?.state?.filter === 'published') {
      setFilter('published');
    } else if (location?.state?.filter === 'drafted') {
      setFilter('drafted');
    } else if (location?.state?.filter === 'scheduled') {
      setFilter('scheduled');
    } else {
      setFilter('all');
    }
  }, [location?.state?.filter]);

  const onCreatePostDialogClose = () => {
    setShowCreatePostDialog(false);
  };

  const handlePageClick = (event) => {
    setPage(event.selected + 1);
  };

  const onChangeSearchTerm = (termSearched) => {
    setSearchTerm(termSearched);
    setPage(1);
  };

  const onDeletePost = (row) => {
    setDeletePost(row.id);
    setDeleteType(row.type);
  };

  const onDeleteConfirm = async () => {
    dispatch(startLoading());
    try {
      await Network.post(API.deleteMediaPost, {
        postId: deletePost,
      });
      await mediaPostList();
      setDeletePost(null);
      toast.success('Post deleted successfully', toastConfig);
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(endLoading());
    }
  };

  const user = useSelector((state) => state.prepare.user);

  const mediaPostList = async () => {
    dispatch(startLoading());
    if (!filter) return;
    try {
      const response = await Network.get(API.mediaPostList, {
        workspaceId: workspaceId,
        sort: sortOption.value,
        search: searchTerm,
        limit: perPage,
        page: page,
        filter: filter === 'all' ? null : filter,
      });
      setCountAll(response?.data?.response?.totalAllData);
      setCountPublish(response?.data?.response?.totalPublished);
      setCountScheduled(response?.data?.response?.totalScheduled);
      setCountDrafted(response?.data?.response?.totalDrafted);
      setCountNoise(response?.data?.response?.totalNoise);
      setPostDetails(response?.data?.response?.dataset);
      setPostTotal(response?.data?.response?.total);
    } catch (error) {
    } finally {
      dispatch(endLoading());
    }
  };
  useEffect(() => {
    mediaPostList();
  }, [searchTerm, perPage, page, sortOption, filter, workspaceId]);

  useEffect(() => {
    const event = {
      selected: 0,
    };
    handlePageClick(event);
  }, [filter]);

  const formatCount = (count) => {
    if (count >= 1000000) {
      return (count / 1000000).toFixed(1) + 'M';
    } else if (count >= 1000) {
      return (count / 1000).toFixed(1) + 'k';
    } else {
      return count;
    }
  };

  return (
    <>
      {newUI ? (
        <PageTitle title="Posts" parents={['Media', 'Posts']} />
      ) : (
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <StylishNewButton onClick={() => navigate(-1)}>
                <i className="fa fa-angle-left me-2" aria-hidden="true"></i>
                Back
              </StylishNewButton>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Posts
            </li>
          </ol>
        </nav>
      )}

      <div className="d-flex flex-wrap align-items-center mb-4">
        {!isRoleReadOnly(user.roles) && (
          <StylishNewButton
            className={`button--primary flex-shrink-0
              ${isRoleReadOnly(user.roles) ? ' disabled' : ''}`}
            onClick={() => {
              onCreatePostDialog(null);
            }}
          >
            Create Post
          </StylishNewButton>
        )}
        <p className="ms-auto m-0">{formatCount(postTotal)} Post(s)</p>

        <div className="d-flex flex-wrap align-items-center ms-lg-3 w-100 w-lg-auto mt-3 mt-lg-0">
          <StylishNewSearchBar
            className="flex-shrink-md-0"
            onChangeSearchTerm={onChangeSearchTerm}
          />
          <div className="d-flex align-items-center ms-md-auto ms-lg-3 w-100 w-md-auto mt-3 mt-md-0">
            <StylishNewSelect
              classes={'flex-grow-1'}
              style={{ minWidth: '200px' }}
              options={sortOptions}
              onChange={(e) => {
                if (sortOption.value !== e.value) {
                  setSortOption(e);
                }
              }}
              value={sortOption}
              placeholder={'Sort By...'}
              isClearable={false}
              isSearchable={true}
              isMulti={false}
              isDisabled={false}
            />

            <StylishHelp
              classes={'flex-shrink-0 ms-3 sml'}
              title={PostsHelp.title}
              content={PostsHelp.content}
              link={selfHostedKnowledgeBase || PostsHelp.link}
            />
          </div>
        </div>
      </div>

      <div className="mb-4 posts-tab">
        <div className="d-flex align-items-center justify-content-start justify-content-md-end flex-wrap">
          <StylishNewButton
            onClick={() => setFilter('all')}
            className={`cursor-pointer ${filter === null ? 'color-brand' : ''}`}
          >
            All{' '}
            <div className="badge badge--posts">{formatCount(countAll)}</div>
          </StylishNewButton>
          <StylishNewButton
            onClick={() => setFilter('published')}
            className={`cursor-pointer ${
              filter === 'published' ? 'color-brand' : ''
            }`}
          >
            Published{' '}
            <div className="badge badge--posts">
              {formatCount(countPublish)}
            </div>
          </StylishNewButton>
          <StylishNewButton
            onClick={() => setFilter('scheduled')}
            className={`cursor-pointer ${
              filter === 'scheduled' ? 'color-brand' : ''
            }`}
          >
            Scheduled{' '}
            <div className="badge badge--posts">
              {formatCount(countScheduled)}
            </div>
          </StylishNewButton>
          <StylishNewButton
            onClick={() => setFilter('drafted')}
            className={`cursor-pointer ${
              filter === 'drafted' ? 'color-brand' : ''
            }`}
          >
            Drafted{' '}
            <div className="badge badge--posts">
              {formatCount(countDrafted)}
            </div>
          </StylishNewButton>
          <StylishNewButton
            onClick={() => setFilter('noise')}
            className={`cursor-pointer ${
              filter === 'noise' ? 'color-brand' : ''
            }`}
          >
            Noise
            <div className="badge badge--posts">{formatCount(countNoise)}</div>
          </StylishNewButton>
        </div>
      </div>
      {postTotal > 0 ? (
        <>
          <div className="bg-gray-800 mb-4 p-4">
            {postDetails.map((post, idx) => {
              const truncatedPost =
                post?.post?.length > contentBlockLength
                  ? `${post?.post?.slice(0, contentBlockLength)}....`
                  : post?.post;
              const cleanPost = sanitizeHtml(truncatedPost);
              return (
                <div
                  className="posts-row cursor-pointer position-relative"
                  key={post?.id}
                >
                  <div
                    className="stretched-link "
                    onClick={(e) => {
                      e.preventDefault();
                      onViewPostDialog(post);
                    }}
                  ></div>
                  {post?.type === 'comment' && (
                    <p className="d-flex align-items-center">
                      <div className="has-img-h-16 me-2">
                        <img src={IconResponce} alt="" />
                      </div>
                      In response to {post?.response_to}'s post
                    </p>
                  )}

                  <div className="d-flex">
                    <div className="flex-grow-1 ">
                      <div className="d-flex flex-column flex-md-row">
                        {post?.avatar ? (
                          <div className="profile-img flex-shrink-0">
                            <img src={post?.avatar} alt="" />
                          </div>
                        ) : (
                          <div className="profile-img flex-shrink-0"></div>
                        )}

                        <div className="flex-grow-1 ps-0 ps-md-3 pt-3 pt-md-0">
                          <div className="d-flex align-items-start align-items-md-center mb-2 flex-column flex-md-row">
                            <h4 className="mb-0 break-word flex-grow-1">
                              {post?.name}
                            </h4>
                            {(post?.commentcount > 0 ||
                              post?.lat !== null ||
                              post?.mediaFile !== null) && (
                              <div className="icon-group d-flex align-items-center mt-2">
                                {post?.commentcount > 0 && (
                                  <>
                                    <div className="d-flex position-relative">
                                      <img src={IconChat} alt="" />
                                      <span className="status--bubble">
                                        {post?.commentcount}
                                      </span>
                                    </div>
                                  </>
                                )}

                                {post?.lat !== null && (
                                  <>
                                    <img src={IconCommentLocation} alt="" />
                                  </>
                                )}

                                {post?.mediaFile?.length > 0 && (
                                  <>
                                    <img src={IconCommentImage} alt="" />
                                  </>
                                )}
                              </div>
                            )}
                          </div>

                          <div className="d-flex">
                            <p className="mb-0 ">
                              {'@'}
                              {post?.username}
                            </p>
                            <span className="mx-3">|</span>
                            <div className="d-flex align-items-center">
                              {post?.siteicon ? (
                                <div
                                  className="has-img-h-20 me-2"
                                  style={{ color: post?.siteColor }}
                                >
                                  {
                                    AllIcon.find(
                                      (icon) => icon.name === post?.siteicon
                                    )?.icon
                                  }
                                </div>
                              ) : null}
                              {post?.site_image ? (
                                <div className="has-img-h-24 me-2">
                                  <img
                                    src={post?.site_image}
                                    alt=""
                                    className="rounded-5 d-block"
                                  />
                                </div>
                              ) : null}
                              <p className="mb-0 ">{post?.siteName}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {filter === 'noise' ? null : (
                      <div className="ms-3 flex-shrink-0 ">
                        <Dropdown
                          className="position-relative"
                          style={{
                            zIndex: `${
                              postDetails && postDetails.length - idx
                            }`,
                          }}
                          onClick={(e) => {}}
                        >
                          <Dropdown.Toggle className="button--icon">
                            <i
                              className="fa fa-ellipsis-v"
                              aria-hidden="true"
                            ></i>
                          </Dropdown.Toggle>
                          <Dropdown.Menu align="end">
                            {!isRoleReadOnly(user.roles) && (
                              <Dropdown.Item
                                onClick={(e) => {
                                  e.preventDefault();
                                  navigate(
                                    `${PreparePath}/dashboard/media/posts/edit-post/${post?.id}`,

                                    {
                                      state: {
                                        type: post?.status,
                                      },
                                    }
                                  );
                                }}
                              >
                                <img src={IconEdit} alt="" />
                                {post?.type === 'post'
                                  ? 'Edit Post'
                                  : 'Edit Comment'}
                              </Dropdown.Item>
                            )}
                            {post?.type === 'post' &&
                              post?.status !== 'drafted' &&
                              post?.status !== 'scheduled' && (
                                <Dropdown.Item
                                  onClick={() => {
                                    onCreatePostDialog(post);
                                  }}
                                >
                                  <img src={IconComment} alt="" />
                                  Create Comment
                                </Dropdown.Item>
                              )}
                            <Dropdown.Item
                              onClick={(e) => {
                                e.preventDefault();
                                navigate(
                                  `${PreparePath}/dashboard/media/profiles/${post?.siteName?.toLowerCase()}/${post?.name?.toLowerCase()}/${
                                    post?.profileId
                                  }`
                                );
                              }}
                            >
                              <img src={IconProfile} alt="" />
                              View Profile
                            </Dropdown.Item>
                            {!isRoleReadOnly(user.roles) && (
                              <Dropdown.Item onClick={() => onDeletePost(post)}>
                                <img src={IconDelete} alt="" />
                                {post?.type === 'post'
                                  ? 'Delete Post'
                                  : 'Delete Comment'}
                              </Dropdown.Item>
                            )}
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    )}
                  </div>

                  <div className="row--status d-flex flex-column flex-md-row mt-2 ms-auto">
                    <p className="mb-0">
                      {moment(post?.publishTime).isValid()
                        ? `${moment(post?.publishTime)?.fromNow()} at ${moment(
                            post?.publishTime
                          )?.format('MMM DD, YYYY  HH:mm')}`
                        : `${moment(post?.createdAt)?.fromNow()} at ${moment(
                            post?.createdAt
                          )?.format('MMM DD, YYYY  HH:mm')}`}
                    </p>

                    <p className="mb-0 ms-0 ms-md-auto">
                      {post?.status?.charAt(0).toUpperCase() +
                        post?.status?.slice(1)}
                    </p>
                  </div>

                  {post?.title && (
                    <p className="mb-0 mt-3 weight-500">{post?.title}</p>
                  )}

                  <p
                    className="mb-0 mt-3"
                    dangerouslySetInnerHTML={{ __html: cleanPost }}
                  ></p>

                  <hr className="dashed my-3" />
                </div>
              );
            })}
          </div>
          <div className="d-flex justify-content-center justify-content-md-end mt-4">
            <ReactPaginate
              className="pagination"
              breakLabel="..."
              nextLabel="Next"
              onPageChange={handlePageClick}
              pageCount={Math.ceil(postTotal / perPage)}
              previousLabel="Prev"
              forcePage={page - 1}
            />
          </div>
        </>
      ) : (
        <>
          <div>
            <div className="bg-gray-800 text-center p-5">
              <div className="py-0 py-md-4">
                <div className="d-flex justify-content-center mb-4">
                  <img src={IconEmptyExercise} alt="" />
                </div>
                <p className="weight-600 mb-4">
                  A fresh start. Create the first entry.
                  <a
                    href={selfHostedKnowledgeBase || MiscellaneousHelpLink.link}
                    target="_blank"
                  >
                    {' '}
                    Need more help?
                  </a>
                </p>
                <StylishNewButton
                  className={`button--primary
                    ${isRoleReadOnly(user.roles) ? ' disabled' : ''}`}
                  onClick={() => {
                    onCreatePostDialog(null);
                  }}
                >
                  Create Post
                </StylishNewButton>
              </div>
            </div>
          </div>
        </>
      )}
      {showViewPostDialog && (
        <ViewPostDialog
          show={showViewPostDialog}
          onClose={(data) => onViewPostDialogClose(data)}
          postId={postId}
          isNoiseGeneratedPost={isNoiseGeneratedPost}
        />
      )}
      {showCreatePostDialog && (
        <CreatePostDialog
          show={showCreatePostDialog}
          onClose={onCreatePostDialogClose}
          selectedSiteId={selectedSiteId}
          selectedPostid={selectedPostid}
        />
      )}
      {deletePost && (
        <StylishConfirmDialog
          show={deletePost}
          onClose={() => setDeletePost(null)}
          dialogTitle={deleteType === 'post' ? 'Delete Post' : 'Delete Comment'}
          dialogContent={'Are you sure you want this?'}
          onConfirm={onDeleteConfirm}
        />
      )}
    </>
  );
}
