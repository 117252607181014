import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import API from '../../../../../service/api';
import Network from '../../../../../service/Network';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import StylishNewSelect from 'components/DesignSystems/New/StylishNewSelect';
import { createFilter } from 'react-select';
import { toastConfig } from 'assets/data/config';
import { endLoading, startLoading } from "../../../../../../reducers/loading/loading.action";
export default function AddEditAccreditedTaskDialog({
  onClose,
  show,
  dialogType,
  updateDialogData,
  postData,
}) {
  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    getValues,
  } = useForm();

  const { selectedWorkspace } = useSelector((state) => state.prepare.workspace);
  const exerciseId = useSelector(
    (state) => state.prepare.exercise.exerciseDetailsId
  );
  const workspaceType = useSelector((state) => state.prepare.workspace.type);

  const [allAccreditedTasks, setAllAccreditedTasks] = useState('');
  const [coreCapabilities, setCoreCapabilities] = useState('');
  const [selectedOptions, setSelectedOptions] = useState(
    workspaceType === 'DOD' ? '' : []
  );
  // const [dataPresent, setDataPresent] = useState(false);

  const dispatch = useDispatch();

  const getAllAccreditedTasksfromJMET = async () => {
    dispatch(startLoading());
    try {
      await Network.get(API.getAllJMETs, {
        workspaceId: selectedWorkspace,
      }).then((resp) => {
        let selectedData = '';
        const coreData = resp?.data?.response?.dataset.map((ele) => {
          if (updateDialogData?.jmetId || updateDialogData.length > 0) {
            if (updateDialogData.jmetId === ele.id) {
              selectedData = {
                value: ele.id,
                label:
                  ele.levelWar + ' ' + ele.taskNumber + ' ' + ele.taskTitle,
                disabled: true,
              };
            }
          }
          return {
            value: ele.id,
            label: ele.levelWar + ' ' + ele.taskNumber + ' ' + ele.taskTitle,
          };
        });
        setSelectedOptions(selectedData);
        setAllAccreditedTasks(coreData);
        setValue('accreditedTask', selectedData);
      });
    } catch (error) {
      toast.error(error.response.data.response.status.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };

  const getAllCoreCapabilities = async () => {
    dispatch(startLoading());
    try {
      Network.get(API.fetchAllCoreData, {
        workspaceId: selectedWorkspace,
      })
        .then((resp) => {
          let selectedData = [];
          const coreData = resp.data.response.dataset.map((ele) => {
            const existData = postData.find(
              (pD) => pD.coreCapabilitiesId === ele.id
            );
            if (existData?.coreCapabilitiesId) {
              selectedData.push({ value: ele.id, label: ele.title });
            }
            return {
              value: ele.id,
              label: ele.title,
            };
          });
          setSelectedOptions(selectedData);
          setCoreCapabilities(coreData);
          setValue('coreCapabilities', selectedData);
        })
        .catch(console.log)
        .finally(() => {
          dispatch(endLoading());
        });
    } catch (error) {
      toast.error(error.response.data.response.status.msg, toastConfig);
      // } finally {
      //   dispatch(endLoading());
    }
  };

  useEffect(() => {
    if (show) {
      if (workspaceType === 'DOD') {
        getAllAccreditedTasksfromJMET();
      } else {
        getAllCoreCapabilities();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show, selectedWorkspace]);

  const onSubmit = async (e) => {
    // e.preventDefault();
    const data = getValues();
    dispatch(startLoading());
    try {
      // if (dialogType === "Add") {
      //   updateDialogData.map((uD) => {
      //     if (data?.accreditedTask?.value === uD?.jmets?.id) {
      //       setDataPresent(true);
      //     }
      //   });
      // }
      // if (dataPresent === true && dialogType === "Add") {
      //   toast.info("Accredited task already present");
      // } else if (dialogType === "Edit") {
      const newTraining = await Network.post(
        dialogType === 'Add'
          ? API.createAccreditedTasks
          : API.editAccreditedTasks,
        {
          id: updateDialogData?.id,
          jmetId: data?.accreditedTask?.value,
          workspaceId: selectedWorkspace,
          planEventId: exerciseId,
        }
      );
      onClose(newTraining.data.response.dataset[0]);
      // }
    } catch (error) {
      toast.error(error.response.data.response.status.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };

  const onSubmitCoreCapabilities = async (data) => {
    let coreCapabilities = data.coreCapabilities.map((e) => {
      return {
        coreCapabilitiesId: e.value,
        workspaceId: selectedWorkspace,
        planEventId: exerciseId,
      };
    });
    dispatch(startLoading());
    try {
      Network.post(
        dialogType === 'Add'
          ? API.updateExerciseCoreCapabilities
          : API.updateExerciseCoreCapabilities,
        {
          coreCapabilities,
        }
      )
        .then((resp) => {
          dispatch(endLoading());
          onClose(resp.data.response.dataset[0]);
        })
        .catch((error) => {
          console.log(error);
          dispatch(endLoading());
        });
    } catch (error) {
      toast.error(error.response.data.response.status.msg, toastConfig);
    }
  };

  useEffect(() => {
    const listener = (event) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        event.preventDefault();
        workspaceType === 'DOD'
          ? handleSubmit(onSubmit)()
          : handleSubmit(onSubmitCoreCapabilities)();
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, []);

  return (
    <>
      {workspaceType === 'DOD' ? (
        <Modal
          show={show}
          onHide={onClose}
          centered
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton closeVariant="white">
            <Modal.Title>Accredited Task</Modal.Title>
          </Modal.Header>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Modal.Body>
              <label className="form-label">Select Accredited Task</label>
              <Controller
                control={control}
                name="accreditedTask"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <StylishNewSelect
                    options={allAccreditedTasks}
                    defaultValue={value}
                    value={value || selectedOptions}
                    onChange={onChange}
                    placeholder={'Select Accredited Task'}
                    filterOption={{ matchFrom: 'start' }}
                    isSearchable={true}
                    isClearable={false}
                    isMulti={false}
                    isDisabled={false}
                    isOptionDisabled={(accreditedTask) =>
                      accreditedTask.disabled
                    }
                  />
                )}
              />
              {errors.accreditedTask && (
                <span className="form-text form-error">
                  This is a required field
                </span>
              )}
            </Modal.Body>

            <Modal.Footer>
              <div className="button-group">
                <StylishNewButton
                  className="button button--secondary button--reverse"
                  onClick={onClose}
                >
                  Close
                </StylishNewButton>
                <StylishNewButton
                  className="button button--primary"
                  type="submit"
                >
                  Save
                </StylishNewButton>
              </div>
            </Modal.Footer>
          </form>
        </Modal>
      ) : (
        <Modal
          show={show}
          onHide={onClose}
          centered
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton closeVariant="white">
            <Modal.Title>Add/Update Capabilities</Modal.Title>
          </Modal.Header>
          <form onSubmit={handleSubmit(onSubmitCoreCapabilities)}>
            <Modal.Body>
              <label className="form-label">Core Capabilities</label>
              <Controller
                control={control}
                name="coreCapabilities"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <StylishNewSelect
                    options={coreCapabilities}
                    defaultValue={value}
                    value={value || selectedOptions}
                    onChange={onChange}
                    placeholder={'Select Core Capabilities'}
                    isClearable={true}
                    isSearchable={true}
                    isMulti={true}
                    isDisabled={false}
                    isOptionDisabled={(coreCapabilities) =>
                      coreCapabilities.disabled
                    }
                  />
                )}
              />
              {errors.coreCapabilities && (
                <span className="form-text form-error">
                  This is a required field
                </span>
              )}
            </Modal.Body>

            <Modal.Footer>
              <div className="button-group">
                <StylishNewButton
                  className="button button--primary"
                  type="submit"
                >
                  Save
                </StylishNewButton>
              </div>
            </Modal.Footer>
          </form>
        </Modal>
      )}
    </>
  );
}
