import React, {useState,useEffect} from 'react'

import Info from 'components/DesignSystems/text/Info';
import Title from 'components/DesignSystems/text/Title';

import Button from 'components/DesignSystems/buttons/StylishButton';

import RiskLevels from './RiskLevels'

import "./RiskLevelsView.css"

import {defaultRiskLevels} from '../constants/defaultRiskLevels'

export default function RiskLevelsView()
{

	const [riskLevels, setRiskLevels] = useState(defaultRiskLevels)
	const [selectedRiskLevel, setSelectedRiskLevel] = useState(0)

	return (
		<div className="RiskLevelsView">

			<RiskLevels
				riskLevels={riskLevels}
				setRiskLevels={setRiskLevels}
				aor={null}
			/>

		</div>
	)
}