import { saveAs } from 'file-saver';

import Blob from 'blob';
import json2csv from 'json2csv';

import cfRows2BoundingBox from 'utils/cfRows2BoundingBox';
import tabularizeData from 'utils/sharedUtils/tabularizeData';
import parseErrorMessage from 'utils/parseErrorMessage';

import { setSelectedFeatures } from 'slices/dataTableSlice';

import config from 'constants/apiConfig';
import axios from 'axios';
import {
  UPDATE_CURRENT_INCIDENT_REQUEST,
  UPDATE_CURRENT_INCIDENT_SUCCESS,
  UPDATE_CURRENT_INCIDENT_ERROR,
  CREATE_WIDGET_REQUEST,
  CREATE_WIDGET_SUCCESS,
  CREATE_WIDGET_ERROR,
  CREATE_INCIDENT_REQUEST,
  CREATE_INCIDENT_SUCCESS,
  CREATE_INCIDENT_ERROR,
  UPDATE_INCIDENT_REQUEST,
  UPDATE_INCIDENT_SUCCESS,
  UPDATE_INCIDENT_ERROR,
  CREATE_DASHBOARD_REQUEST,
  CREATE_DASHBOARD_SUCCESS,
  CREATE_DASHBOARD_ERROR,
  UPDATE_DASHBOARD_REQUEST,
  UPDATE_DASHBOARD_SUCCESS,
  UPDATE_DASHBOARD_ERROR,
  FETCH_DASHBOARDS_REQUEST,
  FETCH_DASHBOARDS_SUCCESS,
  FETCH_DASHBOARDS_ERROR,
  TOGGLE_WIDGET_REQUEST,
  TOGGLE_WIDGET_SUCCESS,
  TOGGLE_WIDGET_ERROR,
  UPDATE_WIDGET_REQUEST,
  UPDATE_WIDGET_SUCCESS,
  UPDATE_WIDGET_ERROR,
  REMOVE_WIDGET_REQUEST,
  REMOVE_WIDGET_SUCCESS,
  REMOVE_WIDGET_ERROR,
  FETCH_DASHBOARD_DATA_REQUEST,
  FETCH_DASHBOARD_DATA_SUCCESS,
  FETCH_DASHBOARD_DATA_ERROR,
  UPDATE_CURRENT_DASHBOARD_REQUEST,
  UPDATE_CURRENT_DASHBOARD_SUCCESS,
  UPDATE_CURRENT_DASHBOARD_ERROR,
  UPDATE_MAP_WIDGET_ALREADY_RELOADED_REQUEST,
  UPDATE_MAP_WIDGET_ALREADY_RELOADED_SUCCESS,
  UPDATE_MAP_WIDGET_ALREADY_RELOADED_ERROR,
  UPDATE_INCIDENT_GROUP_REQUEST,
  UPDATE_INCIDENT_GROUP_SUCCESS,
  UPDATE_INCIDENT_GROUP_ERROR,
  FETCH_INCIDENT_ASSETS_REQUEST,
  FETCH_INCIDENT_ASSETS_SUCCESS,
  FETCH_INCIDENT_ASSETS_ERROR,
  FETCH_INCIDENT_ASSET_LOGS_REQUEST,
  FETCH_INCIDENT_ASSET_LOGS_SUCCESS,
  FETCH_INCIDENT_ASSET_LOGS_ERROR,
  CREATE_INCIDENT_ASSET_REQUEST,
  CREATE_INCIDENT_ASSET_SUCCESS,
  CREATE_INCIDENT_ASSET_ERROR,
  UPDATE_INCIDENT_ASSET_REQUEST,
  UPDATE_INCIDENT_ASSET_SUCCESS,
  UPDATE_INCIDENT_ASSET_ERROR,
  FETCH_ROLES_BY_INCIDENT_REQUEST,
  FETCH_ROLES_BY_INCIDENT_SUCCESS,
  FETCH_ROLES_BY_INCIDENT_ERROR,
  EXPORT_MARKDOWN_TO_DOCUMENT_REQUEST,
  EXPORT_MARKDOWN_TO_DOCUMENT_SUCCESS,
  EXPORT_MARKDOWN_TO_DOCUMENT_ERROR,
  FETCH_DATASET_METADATA_FOR_WIDGET_REQUEST,
  FETCH_DATASET_METADATA_FOR_WIDGET_SUCCESS,
  FETCH_DATASET_METADATA_FOR_WIDGET_ERROR,
  UPDATE_CURRENTLY_SELECTED_GROUP_REQUEST,
  UPDATE_CURRENTLY_SELECTED_GROUP_SUCCESS,
  UPDATE_CURRENTLY_SELECTED_GROUP_ERROR,
  UPDATE_CROSS_FILTER_REQUEST,
  UPDATE_CROSS_FILTER_SUCCESS,
  UPDATE_CROSS_FILTER_ERROR,
  CLEAR_CROSS_FILTER_REQUEST,
  CLEAR_CROSS_FILTER_SUCCESS,
  CLEAR_CROSS_FILTER_ERROR,
  CLEAR_DATASET_METADATA_FOR_WIDGET_REQUEST,
  CLEAR_DATASET_METADATA_FOR_WIDGET_SUCCESS,
  CLEAR_DATASET_METADATA_FOR_WIDGET_ERROR,
  FETCH_DATASET_METADATA_REQUEST,
  FETCH_DATASET_METADATA_SUCCESS,
  FETCH_DATASET_METADATA_ERROR,
  CLEAR_DATASET_METADATA_REQUEST,
  CLEAR_DATASET_METADATA_SUCCESS,
  CLEAR_DATASET_METADATA_ERROR,
  SELECT_OPERATIONAL_PERIOD_REQUEST,
  SELECT_OPERATIONAL_PERIOD_SUCCESS,
  SELECT_OPERATIONAL_PERIOD_ERROR,
  SET_CLONE_DEFAULTS_REQUEST,
  SET_CLONE_DEFAULTS_SUCCESS,
  SET_CLONE_DEFAULTS_ERROR,
  FETCH_CLONE_DEFAULTS_REQUEST,
  FETCH_CLONE_DEFAULTS_SUCCESS,
  FETCH_CLONE_DEFAULTS_ERROR,
  FETCH_DICE_LOG_REQUEST,
  FETCH_DICE_LOG_SUCCESS,
  FETCH_DICE_LOG_ERROR,
  DELETE_LAYOUT_REQUEST,
  DELETE_LAYOUT_SUCCESS,
  DELETE_LAYOUT_ERROR,
  FETCH_DATASET_FOR_TABLE_REQUEST,
  FETCH_DATASET_FOR_TABLE_SUCCESS,
  FETCH_DATASET_FOR_TABLE_ERROR,
  GET_ALL_USER_SCHEDULE,
  ADD_USER_SCHEDULE_REQUEST,
  ADD_USER_SCHEDULE_SUCCESS,
  ADD_USER_SCHEDULE_ERROR,
  DELETE_USER_SCHEDULE_REQUEST,
  DELETE_USER_SCHEDULE_SUCCESS,
  GET_ALL_USER_SCHEDULE_REQUEST,
  GET_ALL_USER_SCHEDULE_SUCCESS,
  UPLOAD_FILE_REQUEST,
  UPLOAD_FILE_SUCCESS,
  GENERATE_SIGNED_URL_REQUEST,
  GENERATE_SIGNED_URL_SUCCESS,
  FETCH_GROUP_IMAGES_SUCCESS,
  FETCH_GROUP_IMAGES_REQUEST,
  FETCH_BRIEFING_REQUEST,
  FETCH_BRIEFING_SUCCESS,
  SET_BRIEFING_SKETCH_REQUEST,
  SET_BRIEFING_SKETCH_SUCCESS,
} from 'constants/dice-action-types';

import {
  fetchIncidents,
  updateCurrentlySelected,
  fetchUserGroupsRolesIncidents,
} from './profileActions';

import { validateRBACPermissionForAction } from './validationActions';
import { toast } from 'react-toastify';
import { endLoading } from 'reducers/loading/loading.action';
import { noAutoClose } from 'assets/data/config';

function updateCurrentIncidentRequest() {
  return {
    type: UPDATE_CURRENT_INCIDENT_REQUEST,
  };
}

export const updateCurrentIncidentSuccess = (currentIncident) => ({
  type: UPDATE_CURRENT_INCIDENT_SUCCESS,
  payload: currentIncident,
});

function updateCurrentIncidentError(error) {
  const errorMessage =
    'updateCurrentIncidentError\n\n' +
    parseErrorMessage(error) +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: UPDATE_CURRENT_INCIDENT_ERROR,
  };
}

export function updateCurrentIncident(currentIncident) {
  return (dispatch) => {
    try {
      dispatch(updateCurrentIncidentRequest());
      dispatch(updateCurrentIncidentSuccess(currentIncident));
      return currentIncident;
    } catch (error) {
      dispatch(updateCurrentIncidentError(error));
    }
  };
}

export function updateCurrentIncidentById(incidentId) {
  return (dispatch, getState) => {
    const state = getState();
    const incidents = state.app.incidents;
    const foundIncident = incidents.find(
      (incident) => incident.id === incidentId
    );
    if (foundIncident) {
      dispatch(updateCurrentIncident(foundIncident));
    }
  };
}

function createWidgetRequest() {
  return {
    type: CREATE_WIDGET_REQUEST,
  };
}

function createWidgetSuccess(widget) {
  return {
    type: CREATE_WIDGET_SUCCESS,
    payload: widget,
  };
}

function createWidgetError(error) {
  const errorMessage =
    'createWidgetError\n\n' +
    parseErrorMessage(error) +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: CREATE_WIDGET_ERROR,
  };
}

export const createWidget = (widgetRequestBody) => {
  return (dispatch, getState) => {
    dispatch(createWidgetRequest());

    const { group_guid } = getState().app.currentlySelectedGroup;
    const { id } = getState().app.currentIncident;

    var formData = new FormData();
    let fieldData;
    for (const field in widgetRequestBody) {
      fieldData = widgetRequestBody[field];
      if (typeof fieldData === 'object' && field !== 'file') {
        fieldData = JSON.stringify(fieldData);
      }
      formData.append(field, fieldData);
    }
    formData.append(
      'dashboard',
      JSON.stringify(getState().app.currentDashboard)
    );
    formData.append('group_guid', group_guid);
    formData.append('incident_id', id);

    return axios
      .post(config.apiGateway.createWidget, formData, {
        method: 'POST',
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        const widget = response.data || {};
        dispatch(createWidgetSuccess(widget));
        return widget;
      })
      .catch((error) => {
        dispatch(createWidgetError(error));
      });
  };
};

function createIncidentRequest() {
  return {
    type: CREATE_INCIDENT_REQUEST,
  };
}

function createIncidentSuccess(incident) {
  return {
    type: CREATE_INCIDENT_SUCCESS,
    payload: incident,
  };
}

function createIncidentError(error) {
  const errorMessage =
    'createIncidentError\n\n' +
    parseErrorMessage(error) +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: CREATE_INCIDENT_ERROR,
  };
}

export const createIncident = ({
  name,
  group_guid,
  severity,
  incidentType,
  incident_types,
}) => {
  return (dispatch, getState) => {
    dispatch(createIncidentRequest());

    const {featureFlags} = getState().app
    // This ensures the creation of ics incident data on the back end if feature flag turned on here
    const todo_remove_new_ics_feature_flag_on = featureFlags.includes("NEW_ICS")

    const { user_guid } = getState().app.user;
    return axios(config.apiGateway.createIncident, {
      method: 'POST',
      data: JSON.stringify({
        user_guid: user_guid,
        name: name.trim(),
        group_guid,
        severity,
        type: incidentType,
        incident_types,
        todo_remove_new_ics_feature_flag_on: todo_remove_new_ics_feature_flag_on
      }),
    })
      .then((response) => {
        const incident = response.data || {};
        dispatch(fetchIncidents());
        dispatch(createIncidentSuccess());
        if (getState().app.currentlySelectedGroup.group_guid === group_guid) {
          dispatch(updateCurrentIncident(incident));
        }
        return incident;
      })
      .catch((error) => {
        // Handle exception where name exists
        if (
          error.response &&
          error.response.data &&
          error.response.data.ERROR &&
          error.response.data.ERROR === 'Incident Name Exists'
        ) {
          dispatch(
            createIncidentError({
              message: 'This Incident name is in use.  Please enter a new one.',
            })
          );
          dispatch(endLoading());
        } else {
          dispatch(createIncidentError(error));
        }
      })
      .finally(() => {
        dispatch(endLoading());
      });
  };
};

function updateIncidentRequest() {
  return {
    type: UPDATE_INCIDENT_REQUEST,
  };
}

function updateIncidentSuccess(incident) {
  return {
    type: UPDATE_INCIDENT_SUCCESS,
    payload: incident,
  };
}

function updateIncidentError(error) {
  const errorMessage =
    'updateIncidentError\n\n' +
    parseErrorMessage(error) +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: UPDATE_INCIDENT_ERROR,
  };
}

export const updateIncident = (
  updatedIncident,
  archived = false,
  isIncidentNameUpdated = false
) => {
  return (dispatch, getState) => {
    dispatch(updateIncidentRequest());
    const { user_guid } = getState().app.user;
    return axios(config.apiGateway.updateIncident, {
      method: 'POST',
      data: JSON.stringify({
        user_guid,
        incident: updatedIncident,
      }),
    })
      .then((response) => {
        if (archived 
          && updatedIncident.teams_id
        ) {
          toast.warning(
            'Incident has been archived but the existing team site is not deleted.'
          );
        }
        if (isIncidentNameUpdated 
          && updatedIncident.teams_id
        ) {
          toast.warning(
            'Incident has been renamed but the existing team site will not be renamed.'
          );
        }
        const incident = response.data || {};
        dispatch(updateIncidentSuccess(incident));
      })
      .catch((error) => {
        dispatch(updateIncidentError(error));
        dispatch(endLoading());
      });
  };
};

function createDashboardRequest() {
  return {
    type: CREATE_DASHBOARD_REQUEST,
  };
}

function createDashboardSuccess(dashboard) {
  return {
    type: CREATE_DASHBOARD_SUCCESS,
    payload: dashboard,
  };
}

function createDashboardError(error) {
  const errorMessage =
    'createDashboardError\n\n' +
    parseErrorMessage(error) +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: CREATE_DASHBOARD_ERROR,
  };
}

export const createDashboard = ({ incident_id, name }) => {
  return (dispatch, getState) => {
    dispatch(createDashboardRequest());

    const { user_guid } = getState().app.user;
    const { group_guid } = getState().app.currentlySelectedGroup;
    return axios(config.apiGateway.createDashboard, {
      method: 'POST',
      data: JSON.stringify({
        user_guid,
        name,
        incident_id,
        group_guid,
      }),
    })
      .then((response) => {
        const dashboard = response.data || {};
        dispatch(createDashboardSuccess(dashboard));
      })
      .catch((error) => {
        dispatch(createDashboardError(error));
      });
  };
};

function updateDashboardRequest() {
  return {
    type: UPDATE_DASHBOARD_REQUEST,
  };
}

function updateDashboardSuccess(dashboard, updatedDashboard) {
  return {
    type: UPDATE_DASHBOARD_SUCCESS,
    payload: { dashboard, updatedDashboard },
  };
}

function updateDashboardError(error) {
  const errorMessage =
    'updateDashboardError\n\n' +
    parseErrorMessage(error) +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: UPDATE_DASHBOARD_ERROR,
  };
}

export const updateDashboard = ({ updatedDashboard, isLarge }) => {
  return (dispatch, getState) => {
    dispatch(updateDashboardRequest());

    const { user_guid } = getState().app.user;
    const { group_guid } = getState().app.currentlySelectedGroup;
    const { currentIncident } = getState().app;
    const { id } = currentIncident;
    return axios(config.apiGateway.updateDashboard, {
      method: 'POST',
      data: JSON.stringify({
        user_guid,
        group_guid,
        incident_id: id,
        dashboard: updatedDashboard,
        is_large: isLarge,
      }),
    })
      .then((response) => {
        const dashboard = response.data || {};

        // Hack.
        // if we're adding 3D buildings, relead the window.
        if (updatedDashboard.settings.buildings3DSelected) {
          location.reload();
        } else {
          dispatch(updateDashboardSuccess(dashboard, updatedDashboard));
        }
      })
      .catch((error) => {
        dispatch(updateDashboardError(error));
      });
  };
};

function fetchDashboardsRequest() {
  return {
    type: FETCH_DASHBOARDS_REQUEST,
  };
}

const fetchDashboardsSuccess = (dashboards) => ({
  type: FETCH_DASHBOARDS_SUCCESS,
  payload: dashboards,
});

function fetchDashboardsError(error) {
  // TODO CRITICAL
  const errorMessage =
    'fetchDashboardsError\n\n' +
    parseErrorMessage(error) +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: FETCH_DASHBOARDS_ERROR,
  };
}

export const fetchDashboards = (incident) => {
  return (dispatch) => {
    dispatch(fetchDashboardsRequest());
    return axios(config.apiGateway.fetchDashboards, {
      method: 'POST',
      data: JSON.stringify({
        incident,
      }),
    })
      .then((response) => {
        const dashboards = response.data || {};
        dispatch(fetchDashboardsSuccess(dashboards));

        return dashboards;
      })
      .catch((error) => {
        dispatch(fetchDashboardsError(error));
      });
  };
};

function toggleWidgetRequest(toggledWidget) {
  return {
    type: TOGGLE_WIDGET_REQUEST,
    payload: toggledWidget,
  };
}

const toggleWidgetSuccess = (toggledWidget) => ({
  type: TOGGLE_WIDGET_SUCCESS,
  payload: toggledWidget,
});

function toggleWidgetError(error) {
  const errorMessage =
    'toggleWidgetError\n\n' +
    parseErrorMessage(error) +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: TOGGLE_WIDGET_ERROR,
  };
}

export const toggleWidget = ({ widget, enabled }) => {
  return (dispatch, getState) => {
    let initToggledWidget = {
      ...widget,
      enabled: enabled,
      data: undefined,
      dataset: undefined,
    };
    const { currentDashboard } = getState().app;
    dispatch(toggleWidgetRequest(initToggledWidget));
    const { group_guid } = getState().app.currentlySelectedGroup;
    const { id } = getState().app.currentIncident;
    return axios(config.apiGateway.toggleWidget, {
      method: 'POST',
      data: JSON.stringify({
        widget: initToggledWidget,
        dashboard: currentDashboard,
        group_guid,
        incident_id: id,
      }),
    })
      .then((response) => {
        const toggledWidget = response.data || {};
        dispatch(toggleWidgetSuccess(toggledWidget));
        return toggledWidget;
      })
      .catch((error) => {
        dispatch(toggleWidgetError(error));
      });
  };
};

function updateWidgetRequest() {
  return {
    type: UPDATE_WIDGET_REQUEST,
  };
}

const updateWidgetSuccess = (
  updatedWidgetResponse,
  dataSourceSelectionHasChanged
) => ({
  type: UPDATE_WIDGET_SUCCESS,
  payload: {
    updatedWidgetResponse: updatedWidgetResponse,
    dataSourceSelectionHasChanged: dataSourceSelectionHasChanged,
  },
});

function updateWidgetError(error) {
  const errorMessage =
    'updateWidgetError\n\n' +
    parseErrorMessage(error) +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: UPDATE_WIDGET_ERROR,
  };
}

export const updateWidget = (widget, dataSourceSelectionHasChanged = false) => {
  return (dispatch, getState) => {
    dispatch(updateWidgetRequest());
    const { currentDashboard } = getState().app;
    const { group_guid } = getState().app.currentlySelectedGroup;
    const { id } = getState().app.currentIncident;
    let widgetToUpdate = {
      ...widget,
      dataset: undefined,
      data: undefined,
    };
    return axios(config.apiGateway.updateWidget, {
      method: 'POST',
      data: JSON.stringify({
        widget: widgetToUpdate,
        dashboard: currentDashboard,
        dataSourceSelectionHasChanged,
        group_guid,
        incident_id: id,
      }),
    })
      .then((response) => {
        const updatedWidgetResponse = response.data || {};
        dispatch(
          updateWidgetSuccess(
            updatedWidgetResponse,
            dataSourceSelectionHasChanged
          )
        );
        return updatedWidgetResponse;
      })
      .catch((error) => {
        dispatch(updateWidgetError(error));
      });
  };
};

function removeWidgetRequest(removedWidget) {
  return {
    type: REMOVE_WIDGET_REQUEST,
    payload: removedWidget,
  };
}

const removeWidgetSuccess = (removedWidget) => ({
  type: REMOVE_WIDGET_SUCCESS,
  payload: removedWidget,
});

function removeWidgetError(error) {
  const errorMessage =
    'updateWidgetError\n\n' +
    parseErrorMessage(error) +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: REMOVE_WIDGET_ERROR,
  };
}

export const removeWidget = ({ widget }) => {
  return (dispatch, getState) => {
    dispatch(removeWidgetRequest(widget));
    const { group_guid } = getState().app.currentlySelectedGroup;
    const { id } = getState().app.currentIncident;
    return axios(config.apiGateway.removeWidget, {
      method: 'POST',
      data: JSON.stringify({
        widget,
        group_guid,
        incident_id: id,
      }),
    })
      .then((response) => {
        const removedWidget = response.data || {};
        dispatch(removeWidgetSuccess(removedWidget));
        return removedWidget;
      })
      .catch((error) => {
        dispatch(removeWidgetError(error));
      });
  };
};

function fetchDashboardDataRequest() {
  return {
    type: FETCH_DASHBOARD_DATA_REQUEST,
  };
}

const fetchDashboardDataSuccess = (dashboardData) => ({
  type: FETCH_DASHBOARD_DATA_SUCCESS,
  payload: dashboardData,
});

function fetchDashboardDataError(error) {
  // TODO CRITICAL
  const errorMessage =
    'fetchDashboardDataError\n\n' +
    parseErrorMessage(error) +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: FETCH_DASHBOARD_DATA_ERROR,
  };
}

export const fetchDashboardData = (dashboard, omitDatasetBlob = false) => {
  return (dispatch, getState) => {
    dispatch(fetchDashboardDataRequest());
    const { anomalies } = getState().app;
    // If Anomaly dashboard, ignore incident membership check
    const nonIncidentDashboard = !!anomalies.find(
      (a) => a.layout_settings.dashboard_id === dashboard.id
    );
    return axios(config.apiGateway.fetchDashboardData, {
      method: 'POST',
      data: JSON.stringify({
        dashboard,
        omitDatasetBlob,
        nonIncidentDashboard,
      }),
    })
      .then((response) => {
        const dashboardData = response.data || {};
        // We want to map the datasets to widgets, but we don't want to include their data for two reasons:
        // 1: dashboardData as the single source of truth (important for filters)
        // 2: Less copying around big datasets is easier on the computer for performance (also important for filters)
        dispatch(
          fetchDashboardDataSuccess({
            dashboardData,
            dashboard,
          })
        );
      })
      .catch((error) => {
        dispatch(fetchDashboardDataError(error));
      });
  };
};

function updateCurrentDashboardRequest() {
  return {
    type: UPDATE_CURRENT_DASHBOARD_REQUEST,
  };
}

export const updateCurrentDashboardSuccess = (currentDashboard) => ({
  type: UPDATE_CURRENT_DASHBOARD_SUCCESS,
  payload: currentDashboard,
});

function updateCurrentDashboardError(error) {
  const errorMessage =
    'updateCurrentDashboardError\n\n' +
    parseErrorMessage(error) +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: UPDATE_CURRENT_DASHBOARD_ERROR,
  };
}

export function updateCurrentDashboard(currentDashboard) {
  return (dispatch) => {
    try {
      dispatch(updateCurrentDashboardRequest());
      dispatch(updateCurrentDashboardSuccess(currentDashboard));
      return currentDashboard;
    } catch (error) {
      dispatch(updateCurrentDashboardError(error));
    }
  };
}

function updateMapWidgetAlreadyReloadedRequest() {
  return {
    type: UPDATE_MAP_WIDGET_ALREADY_RELOADED_REQUEST,
  };
}

export const updateMapWidgetAlreadyReloadedSuccess = (widget) => ({
  type: UPDATE_MAP_WIDGET_ALREADY_RELOADED_SUCCESS,
  payload: widget,
});

function updateMapWidgetAlreadyReloadedError(error) {
  const errorMessage =
    'updateMapWidgetAlreadyReloadedError\n\n' +
    parseErrorMessage(error) +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: UPDATE_MAP_WIDGET_ALREADY_RELOADED_ERROR,
  };
}

export function updateMapWidgetAlreadyReloaded(widget) {
  return (dispatch) => {
    try {
      dispatch(updateMapWidgetAlreadyReloadedRequest());
      dispatch(updateMapWidgetAlreadyReloadedSuccess(widget));
      return widget;
    } catch (error) {
      dispatch(updateMapWidgetAlreadyReloadedError(error));
    }
  };
}

function updateIncidentGroupRequest() {
  return {
    type: UPDATE_INCIDENT_GROUP_REQUEST,
  };
}

const updateIncidentGroupSuccess = (payload) => ({
  type: UPDATE_INCIDENT_GROUP_SUCCESS,
  payload,
});

function updateIncidentGroupError(error) {
  const errorMessage =
    'updateIncidentGroupError\n\n' +
    parseErrorMessage(error) +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: UPDATE_INCIDENT_GROUP_ERROR,
  };
}

export const updateIncidentGroup = ({ incidentGuid, groupGuid }) => {
  return (dispatch, getState) => {
    const { user_guid } = getState().app.user;
    const group = getState().app.groups.find((g) => g.group_guid === groupGuid);
    dispatch(updateIncidentGroupRequest());
    return axios(config.apiGateway.updateIncidentGroup, {
      method: 'POST',
      data: JSON.stringify({
        incident_id: incidentGuid,
        group_guid: groupGuid,
        user_guid,
      }),
    })
      .then((response) => {
        dispatch(validateRBACPermissionForAction(groupGuid));
        dispatch(fetchUserGroupsRolesIncidents(user_guid));
        dispatch(updateCurrentlySelectedGroup(group));
        dispatch(updateIncidentGroupSuccess(response.data || {}));
      })
      .catch((error) => {
        dispatch(updateIncidentGroupError(error));
      });
  };
};

function fetchIncidentAssetsRequest() {
  return {
    type: FETCH_INCIDENT_ASSETS_REQUEST,
  };
}

const fetchIncidentAssetsSuccess = (incidentAssets) => ({
  type: FETCH_INCIDENT_ASSETS_SUCCESS,
  payload: incidentAssets,
});

function fetchIncidentAssetsError(error) {
  // TODO CRITICAL
  const errorMessage =
    'fetchIncidentAssetsError\n\n' +
    parseErrorMessage(error) +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: FETCH_INCIDENT_ASSETS_ERROR,
  };
}

export const fetchIncidentAssets = (group_id) => {
  return (dispatch) => {
    dispatch(fetchIncidentAssetsRequest());
    return axios(config.apiGateway.fetchIncidentAssets, {
      method: 'POST',
      data: JSON.stringify({
        group_id,
      }),
    })
      .then((response) => {
        const incidentAssets = response.data || {};
        dispatch(fetchIncidentAssetsSuccess(incidentAssets));

        return incidentAssets;
      })
      .catch((error) => {
        dispatch(fetchIncidentAssetsError(error));
      });
  };
};

function fetchIncidentAssetLogsRequest() {
  return {
    type: FETCH_INCIDENT_ASSET_LOGS_REQUEST,
  };
}

const fetchIncidentAssetLogsSuccess = (incidentAssetLogs) => ({
  type: FETCH_INCIDENT_ASSET_LOGS_SUCCESS,
  payload: incidentAssetLogs,
});

function fetchIncidentAssetLogsError(error) {
  const errorMessage =
    'fetchIncidentAssetLogsError\n\n' +
    parseErrorMessage(error) +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: FETCH_INCIDENT_ASSET_LOGS_ERROR,
  };
}

export const fetchIncidentAssetLogs = (group_id) => {
  return (dispatch) => {
    dispatch(fetchIncidentAssetLogsRequest());
    return axios(config.apiGateway.fetchIncidentAssetLogs, {
      method: 'POST',
      data: JSON.stringify({
        group_id,
      }),
    })
      .then((response) => {
        const incidentAssetLogs = response.data || {};
        dispatch(fetchIncidentAssetLogsSuccess(incidentAssetLogs));

        return incidentAssetLogs;
      })
      .catch((error) => {
        dispatch(fetchIncidentAssetLogsError(error));
      });
  };
};

function createIncidentAssetRequest() {
  return {
    type: CREATE_INCIDENT_ASSET_REQUEST,
  };
}

function createIncidentAssetSuccess(createdIncidentAsset) {
  return {
    type: CREATE_INCIDENT_ASSET_SUCCESS,
    payload: createdIncidentAsset,
  };
}

function createIncidentAssetError(error) {
  const errorMessage =
    'createIncidentAssetError\n\n' +
    parseErrorMessage(error) +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: CREATE_INCIDENT_ASSET_ERROR,
  };
}

export const createIncidentAsset = (asset) => {
  return (dispatch) => {
    dispatch(createIncidentAssetRequest());
    return axios(config.apiGateway.createIncidentAsset, {
      method: 'POST',
      data: JSON.stringify({
        asset,
      }),
    })
      .then((response) => {
        const createdIncidentAsset = response.data || {};
        dispatch(fetchIncidentAssets(asset.group_id));
        dispatch(fetchIncidentAssetLogs(asset.group_id));
        dispatch(createIncidentAssetSuccess(createdIncidentAsset));
      })
      .catch((error) => {
        dispatch(createIncidentAssetError(error));
      });
  };
};

function updateIncidentAssetRequest() {
  return {
    type: UPDATE_INCIDENT_ASSET_REQUEST,
  };
}

function updateIncidentAssetSuccess(updatedIncidentAsset) {
  return {
    type: UPDATE_INCIDENT_ASSET_SUCCESS,
    payload: updatedIncidentAsset,
  };
}

function updateIncidentAssetError(error) {
  const errorMessage =
    'updateIncidentAssetError\n\n' +
    parseErrorMessage(error) +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: UPDATE_INCIDENT_ASSET_ERROR,
  };
}

export const updateIncidentAsset = (previousAsset, asset) => {
  return (dispatch) => {
    dispatch(updateIncidentAssetRequest());
    return axios(config.apiGateway.updateIncidentAsset, {
      method: 'POST',
      data: JSON.stringify({
        previousAsset,
        asset,
      }),
    })
      .then((response) => {
        const updatedIncidentAsset = response.data || {};
        dispatch(fetchIncidentAssets(asset.group_id));
        dispatch(fetchIncidentAssetLogs(asset.group_id));
        dispatch(updateIncidentAssetSuccess(updatedIncidentAsset));
      })
      .catch((error) => {
        dispatch(updateIncidentAssetError(error));
      });
  };
};

function fetchRolesByIncidentRequest() {
  return {
    type: FETCH_ROLES_BY_INCIDENT_REQUEST,
  };
}

const fetchRolesByIncidentSuccess = (roles) => ({
  type: FETCH_ROLES_BY_INCIDENT_SUCCESS,
  payload: roles,
});

function fetchRolesByIncidentError(error) {
  // TODO CRITICAL
  const errorMessage =
    'fetchRolesByIncidentError\n\n' +
    parseErrorMessage(error) +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: FETCH_ROLES_BY_INCIDENT_ERROR,
  };
}

export const fetchRolesByIncident = () => {
  return (dispatch, getState) => {
    const state = getState();
    const {
      app: {
        currentIncident: { id },
      },
    } = state;
    dispatch(fetchRolesByIncidentRequest());
    return axios(config.apiGateway.fetchRolesByIncident, {
      method: 'POST',
      data: JSON.stringify({
        incident_id: id,
      }),
    })
      .then((response) => {
        const roles = response.data || {};
        dispatch(fetchRolesByIncidentSuccess(roles));

        return roles;
      })
      .catch((error) => {
        dispatch(fetchRolesByIncidentError(error));
      });
  };
};

function exportMarkdownToDocumentRequest() {
  return {
    type: EXPORT_MARKDOWN_TO_DOCUMENT_REQUEST,
  };
}

export const exportMarkdownToDocumentSuccess = (
  exportedMarkdownToDocument
) => ({
  type: EXPORT_MARKDOWN_TO_DOCUMENT_SUCCESS,
  payload: exportedMarkdownToDocument,
});

function exportMarkdownToDocumentError(error) {
  const errorMessage =
    'exportMarkdownToDocumentError\n\n' +
    parseErrorMessage(error) +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: EXPORT_MARKDOWN_TO_DOCUMENT_ERROR,
  };
}

// MarkdownType: string | report
// FileType: PDF | Word
export function exportMarkdownToDocument(
  markdown,
  markdownType = 'string',
  fileName,
  fileType
) {
  return (dispatch) => {
    dispatch(exportMarkdownToDocumentRequest());
    return axios(config.apiGateway.exportMarkdownToDocument, {
      method: 'POST',
      data: JSON.stringify({
        markdown,
        markdownType,
        fileName,
        fileType,
      }),
    })
      .then((response) => {
        const statementAsDocumentFile = response.data || {};
        let statementBlob;
        // Convert the data to be blob-able
        let binary = atob(statementAsDocumentFile.replace(/\s/g, ''));
        let len = binary.length;
        let buffer = new ArrayBuffer(len);
        let view = new Uint8Array(buffer);
        for (let i = 0; i < len; i++) {
          view[i] = binary.charCodeAt(i);
        }

        if (fileType === 'PDF') {
          statementBlob = new Blob([view], { type: 'application/pdf' });
        } else if (fileType === 'DOCX') {
          statementBlob = new Blob([view], { type: 'application/docx' });
        }

        saveAs(statementBlob, fileName + '.' + fileType);
        const exportedMarkdownToDocument = statementAsDocumentFile;
        dispatch(exportMarkdownToDocumentSuccess(exportedMarkdownToDocument));
        return exportedMarkdownToDocument;
      })
      .catch((error) => {
        dispatch(exportMarkdownToDocumentError(error));
      });
  };
}

function fetchDatasetMetadataForWidgetRequest() {
  return {
    type: FETCH_DATASET_METADATA_FOR_WIDGET_REQUEST,
  };
}

const fetchDatasetMetadataForWidgetSuccess = (datasetMetadataForWidget) => ({
  type: FETCH_DATASET_METADATA_FOR_WIDGET_SUCCESS,
  payload: datasetMetadataForWidget,
});

function fetchDatasetMetadataForWidgetError(error) {
  const errorMessage =
    'fetchDatasetMetadataForWidgetError\n\n' +
    parseErrorMessage(error) +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: FETCH_DATASET_METADATA_FOR_WIDGET_ERROR,
  };
}

export const fetchDatasetMetadataForWidget = (widget, dataSourceSelection) => {
  return (dispatch) => {
    dispatch(fetchDatasetMetadataForWidgetRequest());
    return axios(config.apiGateway.fetchDatasetMetadataForWidget, {
      method: 'POST',
      data: JSON.stringify({
        widget: widget,
        dataSourceSelection: dataSourceSelection,
      }),
    })
      .then((response) => {
        const datasetMetadataForWidgetData = response.data || {};
        const datasetMetadataForWidget = {
          datasetMetadataForWidgetData,
          widget,
        };
        dispatch(
          fetchDatasetMetadataForWidgetSuccess(datasetMetadataForWidget)
        );
        return datasetMetadataForWidget;
      })
      .catch((error) => {
        dispatch(fetchDatasetMetadataForWidgetError(error));
      });
  };
};

function updateCurrentlySelectedGroupRequest() {
  return {
    type: UPDATE_CURRENTLY_SELECTED_GROUP_REQUEST,
  };
}

const updateCurrentlySelectedGroupSuccess = (currentlySelectedGroup) => ({
  type: UPDATE_CURRENTLY_SELECTED_GROUP_SUCCESS,
  payload: currentlySelectedGroup,
});

function updateCurrentlySelectedGroupError(error) {
  const errorMessage =
    'updateCurrentlySelectedGroupError\n\n' +
    parseErrorMessage(error) +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: UPDATE_CURRENTLY_SELECTED_GROUP_ERROR,
  };
}

export const updateCurrentlySelectedGroup = (group) => {
  return (dispatch) => {
    try {
      dispatch(updateCurrentlySelectedGroupRequest());
      dispatch(updateCurrentlySelectedGroupSuccess(group));
      dispatch(updateCurrentlySelected({ groupId: group.group_guid }));
      return group;
    } catch (error) {
      dispatch(updateCurrentlySelectedGroupError(error));
    }
  };
};

function updateCrossFilterRequest() {
  return {
    type: UPDATE_CROSS_FILTER_REQUEST,
  };
}

function updateCrossFilterSuccess({
  updatedCrossFilter,
  filteredDashboardDatasets,
  mapBoundingBox,
}) {
  return {
    type: UPDATE_CROSS_FILTER_SUCCESS,
    payload: { updatedCrossFilter, filteredDashboardDatasets, mapBoundingBox },
  };
}

function updateCrossFilterError(error) {
  const errorMessage =
    'updateCrossFilterError\n\n' +
    parseErrorMessage(error) +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: UPDATE_CROSS_FILTER_ERROR,
  };
}

export const updateCrossFilter = (widget, rowSelectionIndices) => {
  return (dispatch, getState) => {
    try {
      dispatch(updateCrossFilterRequest());
      let updatedCrossFilter = getState().app.crossFilter;
      let filteredDashboardDatasets = getState().app.filteredDashboardDatasets.map(
        (ds) => {
          if (ds.widgets.find((w) => w.id === widget.id)) {
            return {
              ...ds,
              crossFilteredRows: ds.lassoFilteredRows.filter(
                (row, i) => !isNaN(rowSelectionIndices.find((rsi) => rsi === i))
              ),
            };
          } else {
            return ds;
          }
        }
      );

      let mapBoundingBox = cfRows2BoundingBox(filteredDashboardDatasets);
      dispatch(
        updateCrossFilterSuccess({
          updatedCrossFilter,
          filteredDashboardDatasets,
          mapBoundingBox,
        })
      );
      return updatedCrossFilter;
    } catch (error) {
      dispatch(updateCrossFilterError(error));
    }
  };
};

function clearCrossFilterRequest() {
  return {
    type: CLEAR_CROSS_FILTER_REQUEST,
  };
}

function clearCrossFilterSuccess({
  clearedCrossFilter,
  filteredDashboardDatasets,
  mapBoundingBox,
}) {
  return {
    type: CLEAR_CROSS_FILTER_SUCCESS,
    payload: { clearedCrossFilter, filteredDashboardDatasets, mapBoundingBox },
  };
}

function clearCrossFilterError(error) {
  const errorMessage =
    'clearCrossFilterError\n\n' +
    parseErrorMessage(error) +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: CLEAR_CROSS_FILTER_ERROR,
  };
}

export const clearCrossFilter = () => {
  return (dispatch, getState) => {
    try {
      dispatch(clearCrossFilterRequest());
      let clearedCrossFilter = [];
      let filteredDashboardDatasets = getState().app.filteredDashboardDatasets.map(
        (ds) => {
          return {
            ...ds,
            crossFilteredRows: [],
          };
        }
      );

      let mapBoundingBox = undefined;
      dispatch(setSelectedFeatures({}));
      dispatch(
        clearCrossFilterSuccess({
          clearedCrossFilter,
          filteredDashboardDatasets,
          mapBoundingBox,
        })
      );
      return clearedCrossFilter;
    } catch (error) {
      dispatch(clearCrossFilterError(error));
    }
  };
};

function clearDatasetMetadataForWidgetRequest() {
  return {
    type: CLEAR_DATASET_METADATA_FOR_WIDGET_REQUEST,
  };
}

function clearDatasetMetadataForWidgetSuccess({ datasetMetadataForWidgets }) {
  return {
    type: CLEAR_DATASET_METADATA_FOR_WIDGET_SUCCESS,
    payload: datasetMetadataForWidgets,
  };
}

function clearDatasetMetadataForWidgetError(error) {
  const errorMessage =
    'clearDatasetMetadataForWidgetError\n\n' +
    parseErrorMessage(error) +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: CLEAR_DATASET_METADATA_FOR_WIDGET_ERROR,
  };
}

export const clearDatasetMetadataForWidget = (widget) => {
  return (dispatch, getState) => {
    try {
      dispatch(clearDatasetMetadataForWidgetRequest());
      let datasetMetadataForWidgets = getState().app.datasetMetadataForWidgets.filter(
        (metadata) => metadata.widget.id !== widget.id
      );
      dispatch(
        clearDatasetMetadataForWidgetSuccess({ datasetMetadataForWidgets })
      );
      return datasetMetadataForWidgets;
    } catch (error) {
      dispatch(clearDatasetMetadataForWidgetError(error));
    }
  };
};

function fetchDatasetMetadataRequest() {
  return {
    type: FETCH_DATASET_METADATA_REQUEST,
  };
}

const fetchDatasetMetadataSuccess = (datasetMetadata) => ({
  type: FETCH_DATASET_METADATA_SUCCESS,
  payload: datasetMetadata,
});

function fetchDatasetMetadataError(error) {
  const errorMessage =
    'fetchDatasetMetadataError\n\n' +
    parseErrorMessage(error) +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: FETCH_DATASET_METADATA_ERROR,
  };
}

export const fetchDatasetMetadata = (dataSourceSelection) => {
  return (dispatch) => {
    dispatch(fetchDatasetMetadataRequest());
    return axios(config.apiGateway.fetchDatasetMetadataForWidget, {
      method: 'POST',
      data: JSON.stringify({
        widget: undefined,
        dataSourceSelection: dataSourceSelection,
      }),
    })
      .then((response) => {
        const datasetMetadata = response.data || {};
        dispatch(fetchDatasetMetadataSuccess(datasetMetadata));

        return datasetMetadata;
      })
      .catch((error) => {
        dispatch(fetchDatasetMetadataError(error));
      });
  };
};

function clearDatasetMetadataRequest() {
  return {
    type: CLEAR_DATASET_METADATA_REQUEST,
  };
}

const clearDatasetMetadataSuccess = () => ({
  type: CLEAR_DATASET_METADATA_SUCCESS,
});

function clearDatasetMetadataError(error) {
  const errorMessage =
    'fetchDatasetMetadataError\n\n' +
    parseErrorMessage(error) +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: CLEAR_DATASET_METADATA_ERROR,
  };
}

export const clearDatasetMetadata = () => {
  return (dispatch) => {
    try {
      dispatch(clearDatasetMetadataRequest());
      dispatch(clearDatasetMetadataSuccess());
    } catch (error) {
      dispatch(clearDatasetMetadataError(error));
    }
  };
};

function selectOperationalPeriodRequest() {
  return {
    type: SELECT_OPERATIONAL_PERIOD_REQUEST,
  };
}

const selectOperationalPeriodSuccess = (selectedOP) => ({
  type: SELECT_OPERATIONAL_PERIOD_SUCCESS,
  payload: selectedOP,
});

function selectOperationalPeriodError(error) {
  const errorMessage =
    'selectOperationalPeriodError\n\n' +
    parseErrorMessage(error) +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: SELECT_OPERATIONAL_PERIOD_ERROR,
  };
}

export const selectOperationalPeriod = (selectedOP) => {
  return (dispatch) => {
    try {
      dispatch(selectOperationalPeriodRequest());
      dispatch(selectOperationalPeriodSuccess(selectedOP));
    } catch (error) {
      dispatch(selectOperationalPeriodError(error));
    }
  };
};

function setCloneDefaultsRequest() {
  return {
    type: SET_CLONE_DEFAULTS_REQUEST,
  };
}

function setCloneDefaultsSuccess(cloneDefaults) {
  return {
    type: SET_CLONE_DEFAULTS_SUCCESS,
    payload: cloneDefaults,
  };
}

function setCloneDefaultsError(error) {
  const errorMessage =
    'setCloneDefaultsError\n\n' +
    parseErrorMessage(error) +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: SET_CLONE_DEFAULTS_ERROR,
  };
}

export function setCloneDefaults(group_guid, incident_id) {
  return (dispatch) => {
    dispatch(setCloneDefaultsRequest());
    return axios(config.apiGateway.setCloneDefaults, {
      method: 'POST',
      data: JSON.stringify({
        group_guid,
        incident_id,
      }),
    })
      .then((response) => {
        const cloneDefaults = response.data || {};
        dispatch(setCloneDefaultsSuccess(cloneDefaults));
      })
      .catch((error) => {
        dispatch(setCloneDefaultsError(error));
      });
  };
}

function fetchCloneDefaultsRequest() {
  return {
    type: FETCH_CLONE_DEFAULTS_REQUEST,
  };
}

function fetchCloneDefaultsSuccess(cloneDefaults) {
  return {
    type: FETCH_CLONE_DEFAULTS_SUCCESS,
    payload: cloneDefaults,
  };
}

function fetchCloneDefaultsError(error) {
  const errorMessage =
    'fetchCloneDefaultsError\n\n' +
    parseErrorMessage(error) +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: FETCH_CLONE_DEFAULTS_ERROR,
  };
}

export function fetchCloneDefaults() {
  return (dispatch) => {
    dispatch(fetchCloneDefaultsRequest());
    return axios(config.apiGateway.fetchCloneDefaults, {
      method: 'POST',
    })
      .then((response) => {
        const cloneDefaults = response.data || {};
        dispatch(fetchCloneDefaultsSuccess(cloneDefaults));
      })
      .catch((error) => {
        dispatch(fetchCloneDefaultsError(error));
      });
  };
}

function fetchDICELogRequest() {
  return {
    type: FETCH_DICE_LOG_REQUEST,
  };
}

const fetchDICELogSuccess = ({ DICELog, log_name }) => ({
  type: FETCH_DICE_LOG_SUCCESS,
  payload: { DICELog, log_name },
});

function fetchDICELogError(error) {
  const errorMessage =
    'fetchDICELogError\n\n' +
    parseErrorMessage(error) +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: FETCH_DICE_LOG_ERROR,
  };
}

export function fetchDICELog(incident_id, log_name = '', download = false) {
  return (dispatch) => {
    dispatch(fetchDICELogRequest());
    return axios(config.apiGateway.fetchDICELog, {
      method: 'POST',
      data: JSON.stringify({
        incident_id,
        log_name,
      }),
    })
      .then((response) => {
        const DICELog = response.data || {};

        if (!!download) {
          // Download the log itself as a CSV
          let downloadDataBlob;
          if (!!DICELog.length) {
            const fields = Object.keys(DICELog[0]);
            const opts = { fields };
            downloadDataBlob = new Blob([json2csv.parse(DICELog, opts)], {
              type: 'application/csv',
            });
            saveAs(downloadDataBlob, 'DICE ' + log_name + '.csv');
          } else {
            toast.error('There are no records in this log.');
          }
        }

        dispatch(fetchDICELogSuccess({ DICELog, log_name }));

        return DICELog;
      })
      .catch((error) => {
        dispatch(fetchDICELogError(error));
      });
  };
}

function deleteLayoutRequest() {
  return {
    type: DELETE_LAYOUT_REQUEST,
  };
}

const deleteLayoutSuccess = (layout) => ({
  type: DELETE_LAYOUT_SUCCESS,
  payload: layout,
});

function deleteLayoutError(error) {
  const errorMessage =
    'deleteLayoutError\n\n' +
    parseErrorMessage(error) +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: DELETE_LAYOUT_ERROR,
  };
}

function addUserScheduleError(error) {
  const errorMessage =
    'addUserSchedule\n\n' +
    parseErrorMessage(error) +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: ADD_USER_SCHEDULE_ERROR,
  };
}

function deleteUserScheduleError(error) {
  const errorMessage =
    'deleteUserSchedule\n\n' +
    parseErrorMessage(error) +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return;
}

export const deleteLayout = (layout) => {
  return (dispatch, getState) => {
    dispatch(deleteLayoutRequest());
    const { group_guid } = getState().app.currentlySelectedGroup;
    const { id } = getState().app.currentIncident;
    return axios(config.apiGateway.deleteLayout, {
      method: 'POST',
      data: JSON.stringify({
        layout,
        group_guid,
        incident_id: id,
      }),
    })
      .then(() => {
        const { dashboards } = getState().app;
        const filteredDashboards = dashboards.filter((d) => d.id !== layout.id);
        if (!!filteredDashboards.length)
          dispatch(fetchDashboardData(filteredDashboards[0]));
        dispatch(deleteLayoutSuccess(layout));
      })
      .catch((error) => {
        dispatch(deleteLayoutError(error));
      });
  };
};

function fetchDatasetForTableRequest() {
  return {
    type: FETCH_DATASET_FOR_TABLE_REQUEST,
  };
}

const fetchDatasetForTableSuccess = ({ widget, tableData, geoTableData }) => ({
  type: FETCH_DATASET_FOR_TABLE_SUCCESS,
  payload: { widget, tableData, geoTableData },
});

function fetchDatasetForTableError(error) {
  const errorMessage =
    'fetchDatasetForTableError\n\n' +
    parseErrorMessage(error) +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: FETCH_DATASET_FOR_TABLE_ERROR,
  };
}

function fetchAllUserScheduleError(error) {
  const errorMessage =
    'getAllUserSchedule\n\n' +
    parseErrorMessage(error) +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return;
}

export const fetchDatasetForTable = (widget) => {
  return (dispatch, getState) => {
    dispatch(fetchDatasetForTableRequest());
    const reduxCurrentDashboard = getState().app.currentDashboard;
    let tableData = [];
    let geoTableData = [];

    if (
      !!widget &&
      !!widget.dataStrategyParameters &&
      !!widget.dataStrategyParameters.pod_dataset_id
    ) {
      axios(config.apiGateway.fetchDashboardDataForWidget, {
        method: 'POST',
        data: JSON.stringify({
          widget,
        }),
      })
        .then((response) => {
          const resultWidget = response.data;
          if (!!resultWidget.data) {
            if (!!resultWidget.data.length) {
              tableData = resultWidget.data;
            } else if (!!resultWidget.data.features) {
              tableData = tabularizeData(resultWidget.data);
              geoTableData = resultWidget.data;
            } else if (!!resultWidget.data?.data?.length) {
              tableData = resultWidget.data?.data;
            } else if (!!resultWidget.data?.data?.features) {
              tableData = tabularizeData(resultWidget.data?.data);
              geoTableData = resultWidget.data?.data;
            }
          }
          dispatch(
            fetchDatasetForTableSuccess({
              widget,
              tableData: tableData,
              geoTableData: geoTableData,
            })
          );
        })
        .catch((error) => {
          dispatch(fetchDatasetForTableError(error));
        });
    }
    // TODO Restore ODDS/Open Database or scrap the idea altogether
    /*
    else if(!!widget && !!widget.dataStrategyParameters && !!widget.dataStrategyParameters.odds_id)
    {
      axios(config.apiGateway.fetchOddsDatasetForDashboard, {
        method: 'POST',
        data: JSON.stringify({
          event_type_id: widget.dataStrategyParameters.odds_id
        })
      }).then(response => {
        const data = response.data || {};
        if(!!data.length)
        {
          tableData = data
        }
        else if(!!data.features)
        {
          tableData = tabularizeData(data)
          geoTableData = data
        }
        else
        {
          tableData = []
        }
        dispatch(fetchDatasetForTableSuccess({widget,tableData,geoTableData}))
      }).catch(error=>{
        dispatch(fetchDatasetForTableError(error))
      })    
    }
    */
  };
};

export const addUserSchedule = (schedule) => {
  return (dispatch, getState) => {
    dispatch({ type: ADD_USER_SCHEDULE_REQUEST });
    const { group_guid } = getState().app.currentlySelectedGroup;
    return axios(config.apiGateway.addUserSchedule, {
      method: 'POST',
      data: JSON.stringify({
        group_guid: group_guid,
        schedule: schedule,
      }),
    })
      .then(() => {
        dispatch({ type: ADD_USER_SCHEDULE_SUCCESS });
        dispatch(getAllUserSchedule());
      })
      .catch((error) => {
        dispatch(addUserScheduleError(error));
        dispatch(endLoading());
      });
  };
};

export const deleteUserSchedule = (schedule) => {
  return (dispatch, getState) => {
    dispatch({ type: DELETE_USER_SCHEDULE_REQUEST });
    const { group_guid } = getState().app.currentlySelectedGroup;
    return axios(config.apiGateway.deleteUserSchedule, {
      method: 'POST',
      data: JSON.stringify({
        group_guid: group_guid,
        schedule: schedule,
      }),
    })
      .then(() => {
        dispatch({ type: DELETE_USER_SCHEDULE_SUCCESS });
        dispatch(getAllUserSchedule());
      })
      .catch((error) => {
        dispatch(deleteUserScheduleError(error));
      });
  };
};

export const getAllUserSchedule = (userGuid) => {
  return (dispatch, getState) => {
    dispatch({ type: GET_ALL_USER_SCHEDULE_REQUEST });
    const { user_guid } = getState().app.user;
    const { group_guid } = getState().app.currentlySelectedGroup;
    return axios(config.apiGateway.getAllUserSchedule, {
      method: 'POST',
      data: JSON.stringify({
        user_guid: userGuid || user_guid,
        group_guid,
      }),
    })
      .then((response) => {
        dispatch({ type: GET_ALL_USER_SCHEDULE_SUCCESS });
        dispatch({ type: GET_ALL_USER_SCHEDULE, payload: response.data });
      })
      .catch((error) => {
        dispatch(fetchAllUserScheduleError(error));
      });
  };
};

function uploadToBlobStorageError(error) {
  const errorMessage =
    'uploadToBlobStorage\n\n' +
    parseErrorMessage(error) +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return;
}

function generateSignedUrlError(error) {
  const errorMessage =
    'generateSignedURL\n\n' +
    parseErrorMessage(error) +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return;
}

function fetchGroupImagesError(error) {
  const errorMessage =
    'fetchGroupImages\n\n' +
    parseErrorMessage(error) +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return;
}

function fetchBriefingError(error) {
  const errorMessage =
    'fetchBriefing\n\n' +
    parseErrorMessage(error) +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return;
}

function setBriefingSketchError(error) {
  const errorMessage =
    'setBriefingSketch\n\n' +
    parseErrorMessage(error) +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return;
}

export const uploadImageToBlobStorage = ({ file, fileName }) => {
  return (dispatch, getState) => {
    dispatch({ type: UPLOAD_FILE_REQUEST });
    const { group_guid } = getState().app.currentlySelectedGroup;
    const { id } = getState().app.currentIncident;

    return axios
      .post(config.apiGateway.uploadImageToBlobStorage, {
        method: 'POST',
        data: JSON.stringify({
          file,
          fileName,
          group_guid,
          incident_guid: id,
        }),
      })
      .then((response) => {
        const uploadedFileUrl = response.data || {};
        dispatch({
          type: UPLOAD_FILE_SUCCESS,
          payload: uploadedFileUrl.fileUrl,
        });
        dispatch(generateSignedUrl(uploadedFileUrl.fileUrl));
        dispatch(fetchGroupImages());
        return uploadedFileUrl.fileUrl;
      })
      .catch((e) => {
        dispatch(endLoading());
        dispatch(uploadToBlobStorageError(e));
      });
  };
};

export const fetchGroupImages = () => {
  return (dispatch, getState) => {
    dispatch({ type: FETCH_GROUP_IMAGES_REQUEST });
    const { group_guid } = getState().app.currentlySelectedGroup;

    return axios
      .post(config.apiGateway.fetchGroupImages, {
        method: 'POST',
        data: JSON.stringify({
          group_guid,
        }),
      })
      .then((response) => {
        const groupImages = response.data || {};
        dispatch({
          type: FETCH_GROUP_IMAGES_SUCCESS,
          payload: groupImages,
        });
        return groupImages;
      })
      .catch((e) => {
        dispatch(fetchGroupImagesError(e));
      });
  };
};

export const fetchBriefing = () => {
  return (dispatch, getState) => {
    dispatch({ type: FETCH_BRIEFING_REQUEST });
    const { group_guid } = getState().app.currentlySelectedGroup;
    const { id: incident_id } = getState().app.currentIncident;

    return axios
      .post(config.apiGateway.fetchBriefing, {
        method: 'POST',
        data: JSON.stringify({
          group_guid,
          incident_id,
        }),
      })
      .then((response) => {
        const briefing = response.data || {};
        dispatch({
          type: FETCH_BRIEFING_SUCCESS,
          payload: briefing,
        });
        return briefing;
      })
      .catch((e) => {
        dispatch(fetchBriefingError(e));
      });
  };
};

export const setBriefingSketch = (sketch) => {
  return (dispatch, getState) => {
    dispatch({ type: SET_BRIEFING_SKETCH_REQUEST });

    const { group_guid } = getState().app.currentlySelectedGroup;
    const { id: incident_id } = getState().app.currentIncident;

    return axios
      .post(config.apiGateway.setBriefingSketch, {
        method: 'POST',
        data: JSON.stringify({
          sketch,
          group: group_guid,
          incident: incident_id,
        }),
      })
      .then(() => {
        dispatch({
          type: SET_BRIEFING_SKETCH_SUCCESS,
        });
      })
      .catch((e) => {
        dispatch(setBriefingSketchError(e));
      });
  };
};

export const generateSignedUrl = (url) => {
  return (dispatch) => {
    dispatch({ type: GENERATE_SIGNED_URL_REQUEST });

    return axios
      .post(config.apiGateway.generateSignedUrl, {
        method: 'POST',
        data: JSON.stringify({
          url,
        }),
      })
      .then((response) => {
        const result = response.data || {};
        const { signedUrl } = result;
        dispatch({
          type: GENERATE_SIGNED_URL_SUCCESS,
          payload: signedUrl,
        });
        return signedUrl;
      })
      .catch((e) => {
        dispatch(generateSignedUrlError(e));
      });
  };
};
