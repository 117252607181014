import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchGroupSolicitations } from 'actions/profileActions';
import { deleteGroup } from 'actions/roleActions';
import GroupConfigureSection from './GroupConfigureSection';
import { validateRBACPermissionForAction } from 'actions/validationActions';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { StylishConfirmDialog } from 'components/DesignSystems/New/StylishConfirmDialog';
import { GroupSelectionDialog } from './GroupSelectionDialog';
import { startLoading } from 'reducers/loading/loading.action';
import { AddMemberDialog } from './AddMemberDialog';
import BulkUserImportDialog from './BulkUserImportDialog';
import StylishNewSelect from 'components/DesignSystems/New/StylishNewSelect';
import { SharedIcon } from 'components/SharedIcon/SharedIcon';

export default function GroupsContent() {
  const reduxDispatch = useDispatch();
  const navigate = useNavigate();

  const [tableView, setTableView] = useState({
    label: 'Members',
    value: 'Members',
  });
  const [
    showSelectOrganizationDialog,
    setShowSelectOrganizationDialogDialog,
  ] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [showNewMemberDialog, setShowNewMemberDialog] = useState(false);
  const [showBulkUserImportDialog, setShowBulkUserImportDialog] = useState(
    false
  );

  const { user_guid } =
    useSelector((state) => {
      return state.app.user;
    }) || {};

  const reduxCurrentlySelectedGroup = useSelector((state) => {
    return state.app.currentlySelectedGroup;
  });

  useEffect(() => {
    if (user_guid) {
      reduxDispatch(fetchGroupSolicitations());
    }
  }, [user_guid]);

  const groups = useSelector((state) => {
    const rawGroups = state.app.groups || [];
    // If a group 'isClosed', it really shouldn't be sent to the client
    // So we need to push that down into COMS
    return rawGroups
      .filter((group) => group.is_closed === false || group.is_closed === null)
      .map((group) => ({
        value: group.group_guid,
        key: group.group_name,
        dailyDigestTeam: group.daily_digest_team,
        isCurrentUserAdmin: (!!(group.role === 'Admin')).toString(),
        isCurrentUserPending: (!!(group.role === 'Pending')).toString(),
      }));
  });

  const reduxValidateRBACPermissionForAction = useSelector((state) => {
    return state.app.validateRBACPermissionForActionResult;
  });

  const isValidateRBACPermissionForActionLoaded = useSelector((state) => {
    return state.app.isValidateRBACPermissionForActionLoaded;
  });

  const reduxFeatureFlags = useSelector((state) => {
    return state.app.featureFlags;
  });

  useEffect(() => {
    if (
      reduxCurrentlySelectedGroup &&
      isValidateRBACPermissionForActionLoaded &&
      !reduxValidateRBACPermissionForAction // don't looad it if a request is in flight
    ) {
      reduxDispatch(
        validateRBACPermissionForAction(reduxCurrentlySelectedGroup.group_guid)
      );
    }
  }, [
    reduxValidateRBACPermissionForAction,
    isValidateRBACPermissionForActionLoaded,
    reduxCurrentlySelectedGroup,
  ]);

  let toggleData = [
    { label: 'Members', value: 'Members' },
    { label: 'Team Rosters', value: 'Team Rosters' },
    { label: 'Roles', value: 'Roles' },
    { label: 'Incidents', value: 'Incidents' },
    { label: 'Exercises', value: 'Events' },
    { label: 'Alerts', value: 'Alerts' },
    { label: 'Areas of Responsibility', value: 'Areas of Responsibility' },
  ];
  if(!!reduxFeatureFlags.includes("DISASTERGPT"))
  {
    toggleData = [...toggleData,{
      label: "AI Usage",
      value: "AI Usage",
    }]
  }
  if(!!reduxFeatureFlags.includes("NEW_ICS"))
  {
    toggleData = [...toggleData,{
      label: "ICS",
      value: "ICS",
    }]
  }

  const onCloseSelectOrganizationDialog = () => {
    setShowSelectOrganizationDialogDialog(false);
  };

  const deleteGroupButtonClicked = () => {
    setShowSelectOrganizationDialogDialog(false);
    setShowConfirmDialog(true);
  };

  const onDeleteConfirmDialog = () => {
    reduxDispatch(startLoading());
    reduxDispatch(deleteGroup(reduxCurrentlySelectedGroup.group_guid));
    setShowConfirmDialog(false);
  };

  const onCloseConfirmDialog = () => {
    setShowConfirmDialog(false);
  };

  const onCloseNewMemberDialog = () => {
    setShowNewMemberDialog(false);
  };

  const bulkUserImportDialogHandler = () => {
    setShowNewMemberDialog(false);
    setShowBulkUserImportDialog(true);
  };

  return (
    <>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <button onClick={() => navigate(-1)}>
              <SharedIcon iconName="keyboard_arrow_left" classes="me-2" />
              Back
            </button>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Organization Configuration
          </li>
        </ol>
      </nav>
      <div className="d-flex flex-column flex-md-row align-items-md-center mb-4">
        <div className="button-group d-block d-md-flex">
          <StylishNewButton
            className="button--primary w-100 w-md-auto"
            onClick={() => setShowSelectOrganizationDialogDialog(true)}
          >
            Select Organization
          </StylishNewButton>
          {reduxCurrentlySelectedGroup && (
            <StylishNewButton
              className="button--primary w-100 w-md-auto ms-0 ms-md-3 mt-2 mt-md-0"
              onClick={() => setShowNewMemberDialog(true)}
            >
              Add Members
            </StylishNewButton>
          )}
        </div>
        <StylishNewSelect
          options={toggleData}
          value={tableView}
          onChange={(e) => setTableView(e)}
          isClearable={false}
          isSearchable={false}
          classes={'ms-md-auto mt-4 mt-md-0 flex-shrink-0 w-md-max-auto'}
          style={{ width: '250px' }}
        />
      </div>

      {reduxCurrentlySelectedGroup &&
      isValidateRBACPermissionForActionLoaded &&
      !reduxValidateRBACPermissionForAction ? (
        <>
          <div className="row">
            <div className="col-md-12">Loading permissions...</div>
          </div>
        </>
      ) : (
        <>
          <GroupConfigureSection
            tableView={tableView?.value}
            reduxValidateRBACPermissionForActionResult={
              reduxValidateRBACPermissionForAction
            }
          />
        </>
      )}
      {showSelectOrganizationDialog && (
        <GroupSelectionDialog
          show={showSelectOrganizationDialog}
          onClose={onCloseSelectOrganizationDialog}
          onDelete={deleteGroupButtonClicked}
          group={groups
            .filter(
              ({ isCurrentUserPending }) => isCurrentUserPending !== 'true'
            )
            .find(
              (group) => group.value === reduxCurrentlySelectedGroup.group_guid
            )}
          currentlySelectedGroup={reduxCurrentlySelectedGroup}
          isEditDisabled={
            !reduxValidateRBACPermissionForAction['Edit Group Name']
          }
          isDeleteDisabled={
            !reduxValidateRBACPermissionForAction['Open or Close Group']
          }
        />
      )}
      {showConfirmDialog && (
        <StylishConfirmDialog
          show={showConfirmDialog}
          dialogTitle={'Delete this Group?'}
          dialogContent={
            'This action cannot be undone, and will delete all group data. This will not delete Incidents linked to this Group.'
          }
          dialogNote={
            'After deleting this group this page will be reloaded for security and performance purposes.'
          }
          onClose={onCloseConfirmDialog}
          onConfirm={onDeleteConfirmDialog}
        />
      )}
      {showNewMemberDialog && (
        <AddMemberDialog
          show={showNewMemberDialog}
          onClose={onCloseNewMemberDialog}
          onBulkImportClick={bulkUserImportDialogHandler}
        />
      )}
      {showBulkUserImportDialog && (
        <BulkUserImportDialog
          show={showBulkUserImportDialog}
          onClose={() => setShowBulkUserImportDialog(false)}
        />
      )}
    </>
  );
}
