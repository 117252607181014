import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { inputStringLength, toastConfig } from 'assets/data/config';
import IconChooseMedia from 'assets/images/icon__choosemedia.svg';
import ChooseMediaDialog from '../ChooseMediaModal/ChooseMediaDialog';
import API from '../../../service/api';
import Network from '../../../service/Network';
import { toast } from 'react-toastify';
import { useForm, Controller } from 'react-hook-form';
import { useEffect } from 'react';
import StylishNewInput from 'components/DesignSystems/New/StylishNewInput';
import StylishTextArea from 'components/DesignSystems/form/StylishTextArea';
import IconTimes from 'assets/images/icon__times.svg';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { PreparePath } from 'utils/sharedUtils/sharedConst';
import {
  endLoading,
  startLoading,
} from '../../../../reducers/loading/loading.action';

export default function CreateNetworkDialog({ onClose, show, dialogType }) {
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm();

  const [showChooseMediaDialog, setShowChooseMediaDialog] = useState(false);
  const [imageAvatar, setImageAvatar] = useState(null);
  const [isVisibleAvatar, setIsVisibleAvatar] = useState(false);
  const [imageAvatarFrom, setImageAvatarFrom] = useState(false);

  const workspaceId = useSelector(
    (state) => state.prepare.workspace.selectedWorkspace
  );

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const onChooseMediaDialog = () => {
    setShowChooseMediaDialog(true);
  };
  const onChooseMediaDialogClose = () => {
    setShowChooseMediaDialog(false);
  };

  const onSubmitImageAvatar = (data) => {
    setShowChooseMediaDialog(false);
    if (data !== undefined) {
      setImageAvatar(data);
      setIsVisibleAvatar(true);
    }
  };
  const onSubmitImageAvatarFrom = (data) => {
    if (data !== undefined) {
      setImageAvatarFrom(data);
    }
  };

  const onSubmit = async (data) => {
    dispatch(startLoading());
    try {
      const newNetwork = await Network.post(API.createNetwork, {
        name: data?.networkName,
        description: data?.networkDescription,
        avatar: imageAvatar,
        workspaceId: workspaceId,
      })
        .then((resp) => {
          onClose(resp);
          toast.success('Network created successfully', toastConfig);
          navigate(`${PreparePath}/dashboard/media/networks`);
        })
        .catch(() => {
          toast.error('Network already exists', toastConfig);
        })
        .finally(() => {
          dispatch(endLoading());
        });
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.response.status.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };

  useEffect(() => {
    const listener = (event) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        event.preventDefault();
        handleSubmit(onSubmit)();
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, []);

  return (
    <Modal
      show={show}
      onHide={onClose}
      centered
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title>{dialogType}Create Network</Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <div className="mb-3">
            <label className="form-label">Name</label>
            <Controller
              control={control}
              name="networkName"
              rules={{ required: true, maxLength: inputStringLength }}
              render={({ field: { onChange, value } }) => (
                <StylishNewInput
                  type="text"
                  className="form-control"
                  placeholder="Name"
                  name="networkName"
                  onChange={onChange}
                  value={value || ''}
                />
              )}
            />
            {errors?.networkName?.type === 'required' && (
              <span className="form-text form-error">
                This is a required field
              </span>
            )}
            {errors?.networkName?.type === 'maxLength' && (
              <span className="form-text form-error">
                Maximum {inputStringLength} characters allowed
              </span>
            )}
          </div>
          <div className="mb-3">
            <label className="form-label">Description</label>
            <Controller
              control={control}
              name="networkDescription"
              rules={{ required: false }}
              render={({ field: { onChange, value } }) => (
                <StylishTextArea
                  type="text"
                  className="form-control form-textarea"
                  onChange={onChange}
                  value={value || ''}
                />
              )}
            />
          </div>
          <div>
            <label className="form-label d-block"> Avatar</label>
            <span
              className="badge cursor-pointer"
              onClick={onChooseMediaDialog}
            >
              <span className="icon">
                <img src={IconChooseMedia} alt="" />
                Choose Media
              </span>
            </span>
            {isVisibleAvatar && (
              <>
                <div className="d-flex align-items-start mt-3">
                  <img
                    src={imageAvatar}
                    className="me-3 rounded-5"
                    style={{ height: '200px', width: 'auto' }}
                    alt=""
                  />
                  <StylishNewButton
                    customButton
                    className={'button--icon'}
                    onClick={(e) => {
                      setIsVisibleAvatar(false);
                      setImageAvatar(null);
                    }}
                  >
                    <img src={IconTimes} alt="" />
                  </StylishNewButton>
                </div>
              </>
            )}
          </div>

          {showChooseMediaDialog && (
            <ChooseMediaDialog
              show={showChooseMediaDialog}
              onClose={onChooseMediaDialogClose}
              onSubmitImageAvatar={onSubmitImageAvatar}
              typeModalProfile={'Avatar'}
              onSelectedImageAvatar={imageAvatar}
              imageAvatarFrom={imageAvatarFrom}
              onSubmitImageAvatarFrom={onSubmitImageAvatarFrom}

              // updateDialogData={updateDialogData}
            />
          )}
        </Modal.Body>

        <Modal.Footer>
          <div className="button-group">
            <StylishNewButton
              className="button--secondary button--reverse"
              onClick={onClose}
            >
              Cancel
            </StylishNewButton>
            <StylishNewButton className="button--primary" type="submit">
              Save
            </StylishNewButton>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
