import { Offcanvas, Row } from 'react-bootstrap';
import React, { useState } from 'react';
import { StylishNewButton } from '../DesignSystems/New/StylishNewButton';
import { FormProvider, useForm } from 'react-hook-form';
import { AddEntityDrawerProps } from './EntityListProps';
import { WorkAssignment, useCreateWorkAssignment } from './hooks/workAssignmentHooks';
import DrawerWrapper, { DrawerFooter } from './DrawerWrapper';
import FormTextField from './formFields/FormTextField';
import FormTextAreaField from './formFields/FormTextAreaField';
import FormDateTimeField from './formFields/FormDateTimeField';
import { useActualIncidentId } from './hooks/commonHooks';
import GeoLocationComponent from 'components/GeoLocationComponent/GeoLocationComponent';


type FormFields = {
  work_assignment_id: string;
  branch?: string;
  division?: string;
  work_assignment: string;
  resources?: string;
  overhead_positions?: string;
  special_equipment_supplies?: string;
  reporting_location?: string;
  requested_arrival_time?: Date;
  special_instructions?: string;
  archived?: boolean;
  geolocation: any;
};

const WorkAssignmentAddDrawer: React.FC<AddEntityDrawerProps> = ({ toggle }) => {
  const form = useForm<FormFields>({ mode: 'onChange' });
  const { handleSubmit } = form;

  const mutation = useCreateWorkAssignment();

  const incidentId = useActualIncidentId();
  const [geolocation, setGeolocation] = useState()

  const onNewWorkAssignmentDataSubmit = async (formData: FormFields) => {
    const payload = {
      ...formData,
      archived: formData.archived ?? false,
      incident_id: incidentId,
      geolocation: geolocation,
    };
    await mutation.mutateAsync(payload);
    toggle();
  };

  return (
    <DrawerWrapper toggle={toggle} title="Add Work Assignment">
      <FormProvider {...form}>
        <form
          onSubmit={handleSubmit(onNewWorkAssignmentDataSubmit)}
          className="d-flex flex-column justify-content-between flex-grow-1"
        >
          <Offcanvas.Body>
            <Row>
              <FormTextAreaField
                title="Work Assignment"
                fieldName="work_assignment"
                rules={{
                  required: { value: true, message: 'This field is required' },
                }}
                variant="full"
              />
            </Row>
            <Row>
              <FormTextField
                title="Branch"
                fieldName="branch"
                rules={{ maxLength: { value: 255, message: 'This maximum length is 255 characters' } }}
                variant="half"
              />
            </Row>
            <Row>
              <FormTextField
                title="Division"
                fieldName="division"
                rules={{ maxLength: { value: 255, message: 'This maximum length is 255 characters' } }}
                variant="half"
              />
            </Row>
            
            <Row>
              <FormTextAreaField
                title="Resources"
                fieldName="resources"
                variant="full"
              />
            </Row>
            <Row>
              <FormTextAreaField
                title="Overhead Positions"
                fieldName="overhead_positions"
                variant="full"
              />
            </Row>
            <Row>
              <FormTextAreaField
                title="Special Equipment Supplies"
                fieldName="special_equipment_supplies"
                variant="full"
              />
            </Row>
            <Row> 
              <div className="m-0">
                <label className="form-label">Reporting Location:</label>
                <GeoLocationComponent
                  location={geolocation}
                  setLocation={setGeolocation}
                />
              </div>
            </Row>
            <Row>
              <FormDateTimeField title="Requested Arrival" fieldName="requested_arrival_time" variant="half" type="datetime-local" />
            </Row>
            <Row>
              <FormTextAreaField
                title="Special Instructions"
                fieldName="special_instructions"
                variant="full"
              />
            </Row>
            {/* Add other fields as necessary */}
          </Offcanvas.Body>
          <DrawerFooter>
            <div className="button-group">
              <StylishNewButton
                className="button--secondary button--reverse"
                type="button"
                onClick={toggle}
              >
                Close
              </StylishNewButton>
              <StylishNewButton
                className="button--primary"
                type="submit"
                disabled={mutation.isPending}
              >
                Save
              </StylishNewButton>
            </div>
          </DrawerFooter>
        </form>
      </FormProvider>
    </DrawerWrapper>
  );
};

export default WorkAssignmentAddDrawer;
