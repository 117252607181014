export const action_status_options = [
  { label: 'Not Started', value: 'Not Started' },
  { label: 'In Progress', value: 'In Progress' },
  { label: 'On Hold', value: 'On Hold' },
  { label: 'Complete Pending Approval', value: 'Complete Pending Approval' },
  { label: 'Complete', value: 'Complete' },
  { label: 'Failed', value: 'Failed' },
  { label: 'Cancelled', value: 'Cancelled' },
];

export const rr_status_options = [
  {label: 'Awaiting Approval', value: 'Awaiting Approval', hint: "All necessary personnel must mark their approval for the Resource Request to be Approved and allow the purchase to be conducted."},
  {label: 'Approved', value: 'Approved', hint: ""},
  {label: 'In Progress', value: 'In Progress'},
  {label: 'Complete', value: 'Complete'},
  {label: 'Incomplete', value: 'Incomplete'},
]

export const rri_status_options = [
  {label: 'Not Started', value: 'Not Started'},
  {label: 'Ordered', value: 'Ordered'},
  {label: 'Arrived', value: 'Arrived'},
  {label: 'Cancelled', value: 'Cancelled'},
  {label: 'Incomplete', value: 'Incomplete'},
]

export const resource_site_types = [
  {label: 'Staging Area', value: 'Staging Area'},
  {label: 'Incident Command Post', value: 'Incident Command Post'},
  {label: 'Warehouse', value: 'Warehouse'},
  {label: 'Hotel/Lodging', value: 'Hotel/Lodging'},
]

export const resource_set_statuses = [
  {label: 'Onsite', value: 'Onsite'},
  {label: 'Deployed', value: 'Deployed'},
  {label: 'In Transit', value: 'In Transit'},
]

export const strike_team_statuses = [
  'Onsite',
  'Deployed',
  'In Transit'
]


export const strike_team_statuses_object = [
  {label: 'Onsite', value: 'Onsite'},
  {label: 'Deployed', value: 'Deployed'},
  {label: 'In Transit', value: 'In Transit'},
]

export const ComsTypes = [
  {label: 'Radio', value: 'Radio'},
  {label: 'Nonradio', value: 'Nonradio'},
]

export const ComsFunctions = [
  {label: 'Command', value: 'Command'},
  {label: 'Tactical', value: 'Tactical'},
  {label: 'Ground-to-Air', value: 'Ground-to-Air'},
  {label: 'Air-to-Air', value: 'Air-to-Air'},
  {label: 'Support', value: 'Support'},
  {label: 'Dispatch', value: 'Dispatch'},
]
