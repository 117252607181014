import React from 'react';
import {useSelector} from 'react-redux';

export default function LogoutRoute()
{
	const {msalInstance, B2CmsalInstance} = useSelector(state => {
    return state.auth
  })

  const B2CAccounts = B2CmsalInstance.getAllAccounts();
  const handleLogout = () => {
    if (!!B2CAccounts.length) {
      const logoutRequest = {
        account: B2CAccounts[0]
      };
      B2CmsalInstance.logout(logoutRequest);
    } else {
      msalInstance.logout()
    }
  }

  handleLogout()

	return (
		<div>Logging out...</div>
	)
}

