import React from 'react';
import { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import IconChecked from 'assets/images/icon__tick.svg';
import API from '../../../../../service/api';
import { toast } from 'react-toastify';
import Network from '../../../../../service/Network';
import IconSelected from '../../../../../../components/DesignSystems/New/StylishIconSelected/StylishIconSelected';
import { inputStringLength, toastConfig } from 'assets/data/config';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import StylishNewInput from 'components/DesignSystems/New/StylishNewInput';
import { endLoading, startLoading } from "../../../../../../reducers/loading/loading.action";

export default function AddMethodModal({ show, onClose }) {
  const dispatch = useDispatch();

  const workspaceId = useSelector(
    (state) => state.prepare.workspace.selectedWorkspace
  );

  const colors = [
    '#1FBC9C',
    '#1CA085',
    '#2ECC70',
    '#27AF60',
    '#3398DB',
    '#2980B9',
    '#A463BF',
    '#8E43AD',
    '#3D556E',
    '#222F3D',
    '#F2C511',
    '#F39C19',
    '#E84B3C',
    '#C0382B',
    '#DDE6E8',
  ];

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    control,
    formState: { errors },
  } = useForm();

  const predefinedColorHandler = () => {
    const checkedValue = getValues('color');
    setValue('customColor', checkedValue);
  };

  const enteredColorHandler = () => {
    const enteredValue = getValues('customColor');
    const isPredefinedColor = colors.includes(enteredValue);
    isPredefinedColor ? setValue('color', enteredValue) : setValue('color', '');
  };

  const onSubmit = async (data) => {
    dispatch(startLoading());
    try {
      const newInjectMethod = await Network.post(API.createPlanInjectMethod, {
        name: data.name,
        icon: data?.iconSelect?.name,
        color: data.color.length ? data.color : data.customColor,
        workspaceId: workspaceId,
      });
      onClose(newInjectMethod.data.response.dataset[0]);
      toast.success('Inject Method created successfully', toastConfig);
    } catch (error) {
      toast.error(error.response.data.response.status.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };
  useEffect(() => {
    const listener = (event) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        event.preventDefault();
        handleSubmit(onSubmit)();
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, []);
  return (
    <Modal
      show={show}
      onHide={onClose}
      centered
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title>Method</Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <div className="mb-3">
            <label className="form-label">Name</label>
            <Controller
              control={control}
              name={'name'}
              rules={{
                required: true,
              }}
              render={({ field: { onChange, value } }) => (
                <StylishNewInput
                  onChange={(e) => {
                    onChange(e);
                  }}
                  value={value}
                  type="text"
                  name="name"
                  maxLength={inputStringLength}
                  className="form-control"
                  placeholder="Name"
                />
              )}
            />
            {errors?.name && (
              <span className="form-text form-error">
                This is a required field
              </span>
            )}
          </div>
          <div className="mb-3">
            <label className="form-label d-block">Icon</label>
            <Controller
              control={control}
              name="iconSelect"
              rules={{ required: false }}
              render={({ field: { onChange, value } }) => (
                <IconSelected onIconChange={onChange} selectedIcon={value} />
              )}
            />
          </div>
          <div className="mb-0">
            <label className="form-label">Color</label>
            <div className="mb-3">
              <ul className="nav nav__form-color">
                {colors.map((item, index) => (
                  <li key={index}>
                    <div className="form-color" style={{ background: item }}>
                      <input
                        type="radio"
                        name="colors"
                        value={item}
                        {...register('color', {
                          onChange: predefinedColorHandler,
                        })}
                      />
                      <span className="icon">
                        <img src={IconChecked} alt="" />
                      </span>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
            <div className="m-0">
              <Controller
                control={control}
                name={'customColor'}
                rules={{
                  required: true,
                  pattern: /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/i,
                }}
                render={({ field: { onChange, value } }) => (
                  <StylishNewInput
                    onChange={(e) => {
                      enteredColorHandler;
                    }}
                    value={value}
                    type="text"
                    name="customColor"
                    maxLength={inputStringLength}
                    className="form-control flex-grow-1"
                    placeholder="HEX Value"
                  />
                )}
              />
            </div>
            {errors?.customColor?.type === 'required' && (
              <span className="form-text form-error">
                This is a required field
              </span>
            )}
            {errors?.customColor?.type === 'pattern' && (
              <div className="alert alert-info mt-2" role="alert">
                <ul className="list-ordered">
                  <li>The HEX value must start with '#'</li>
                  <li>The HEX value must contain at least 3 characters</li>
                  <li>The HEX value can contain max 6 characters</li>
                </ul>
              </div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="button-group">
            <StylishNewButton
              className="button button--secondary button--reverse"
              onClick={onClose}
            >
              Close
            </StylishNewButton>
            <StylishNewButton className="button button--primary" type="submit">
              Save
            </StylishNewButton>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
