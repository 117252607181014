import React from 'react';

import ProfileContent from './ProfileContent';
import { AppHeader } from 'components/headers/appHeader/AppHeader';
import { SharedIcon } from 'components/SharedIcon/SharedIcon';
import { useNavigate } from 'react-router-dom';
import { useNewUIFlag } from '../../utils/featureFlags';

export default function ProfilePage() {
  const navigate = useNavigate();
  const newUI = useNewUIFlag();
  return (
    <>
      {!newUI ? <AppHeader context={'Profile'} /> : null}
      <div className="site-main">
        <div className="site-main__profile">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <button onClick={() => navigate(-1)}>
                      <SharedIcon iconName="keyboard_arrow_left" classes="me-2" />
                        Back
                      </button>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Profile
                    </li>
                  </ol>
                </nav>
                <ProfileContent />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
