import React, { useState, useEffect } from 'react';
import API from '../../../../service/api';
import Network from '../../../../service/Network';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import { toast } from 'react-toastify';
import IconChecked from 'assets/images/icon__tick.svg';
import Dropdown from 'react-bootstrap/Dropdown';
import IconEmpty from 'assets/images/icon__empty.svg';
import { HelpPlanEvent, useSelfHostedKnowledgeBase } from 'assets/data/HelpContent';
import { textareaStringLength, toastConfig } from 'assets/data/config';
import { inputStringLength } from 'assets/data/config';
import { PreparePath } from 'utils/sharedUtils/sharedConst';
import StylishNewSelect from 'components/DesignSystems/New/StylishNewSelect';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import StylishNewInput from 'components/DesignSystems/New/StylishNewInput';
import StylishDateTimePicker from 'components/DesignSystems/form/StylishDateTimePicker';
import StylishNewTextArea from 'components/DesignSystems/New/StylishNewTextArea';
import StylishHelp from 'components/DesignSystems/New/StylishHelp';
import {
  endLoading,
  startLoading,
} from '../../../../../reducers/loading/loading.action';
import { SharedIcon } from 'components/SharedIcon/SharedIcon';

export const EditOrCreateEvent = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const params = useParams();

  const workspaceId = useSelector(
    (state) => state.prepare.workspace.selectedWorkspace
  );
  const reduxUser = useSelector((state) => state.prepare.user);
  const reduxTimezone = useSelector(
    (state) => state.prepare.workspace.timezone
  );

  const selfHostedKnowledgeBase = useSelfHostedKnowledgeBase();
  
  const [singleEvent, setSingleEvent] = useState([]);
  const [labelColor, setLabelColor] = useState('');
  const [eventName, setEventName] = useState([]);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [keyPressForEventName, setKeyPressForEventName] = useState('');

  const eventId = params.id;
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    getValues,
    setError,
    clearErrors,
  } = useForm();

  const colors = [
    '#D32F2F',
    '#C2185B',
    '#7B1FA2',
    '#512DA8',
    '#303F9F',
    '#1976D2',
    '#0288D1',
    '#0097A7',
    '#00796B',
    '#388E3C',
    '#689F38',
    '#AFB42B',
    '#FBC02D',
    '#FFA000',
    '#F57C00',
    '#E64A19',
    '#5D4037',
    '#616161',
    '#455A64',
  ];

  const overallMethod = [
    { value: 'live', label: 'Live' },
    { value: 'virtual', label: 'Virtual' },
    { value: 'constructive', label: 'Constructive' },
    { value: 'integratedLVC', label: 'Integrated LVC' },
  ];

  const predefinedColorHandler = () => {
    const checkedValue = getValues('color');
    setValue('customColor', checkedValue);
    setLabelColor(checkedValue);
    if (!checkedValue) {
      setError('customColor');
    } else if (checkedValue) {
      clearErrors('customColor');
    }
  };
  const onKeyPressForEventName = () => {
    let eventName = document.getElementById('name');
    setKeyPressForEventName(eventName.value);
  };

  const enteredColorHandler = () => {
    const enteredValue = getValues('customColor');
    const isPredefinedColor = colors.includes(enteredValue);
    isPredefinedColor ? setValue('color', enteredValue) : setValue('color', '');
    setLabelColor(enteredValue);
  };

  const editData = async () => {
    dispatch(startLoading());
    try {
      const events = await Network.get(API.fetchSingleEvent, {
        workspaceId: workspaceId,
        id: eventId,
      });
      setSingleEvent(events.data.response.dataset[0]);
      const data = overallMethod.find(
        (oM) => events.data.response.dataset[0].overall_method === oM.value
      );
      setValue('overall_method', data);
      events.data.response.dataset[0].endDate &&
        setValue(
          'endingDate',
          moment(events.data.response.dataset[0].endDate).format(
            'MM/DD/YYYY HH:mm'
          )
        );
      events.data.response.dataset[0].startDate &&
        setValue(
          'startingDate',
          moment(events.data.response.dataset[0].startDate).format(
            'MM/DD/YYYY HH:mm'
          )
        );
      // setEndDate(
      //   moment(events.data.response.dataset[0].endDate).format(
      //     'MM/DD/YYYY HH:mm'
      //   )
      // );
      // setStartDate(
      //   moment(events.data.response.dataset[0].startDate).format(
      //     'MM/DD/YYYY HH:mm'
      //   )
      // );
      events.data.response.dataset[0].endDate &&
        setEndDate(
          moment(events.data.response.dataset[0].endDate).format(
            'MM/DD/YYYY HH:mm'
          )
        );
      events.data.response.dataset[0].startDate &&
        setStartDate(
          moment(events.data.response.dataset[0].startDate).format(
            'MM/DD/YYYY HH:mm'
          )
        );
      setEventName(events.data.response.dataset[0]?.name);
      setValue('name', events.data.response.dataset[0]?.name);
      setValue('scenario', events.data.response.dataset[0]?.scenario);
      setValue('remarks', events.data.response.dataset[0]?.remarks);
      setValue('color', events.data.response.dataset[0]?.color);
      setValue('customColor', events.data.response.dataset[0]?.color);
      setLabelColor(events.data.response.dataset[0]?.color);
    } catch (error) {
      toast.error(error.response.data.response.status.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };

  useEffect(() => {
    if (eventId) {
      editData();
    }
  }, [reduxUser, eventId]);

  const onSubmit = async (data) => {
    dispatch(startLoading());
    try {
      await Network.post(eventId ? API.updateEvent : API.createEvent, {
        workspaceId: workspaceId,
        name: data.name,
        type: 'event',
        startDate: data.startingDate,
        endDate: data.endingDate,
        overall_method: data.overall_method.value,
        remarks: data.remarks,
        scenario: data.scenario,
        id: eventId,
        color: data.color ? data.color : data.customColor,
      });
      navigate(`${PreparePath}/dashboard/plan/prepare/events`);
      navigate(`${PreparePath}/dashboard/plan/prepare/events`);
      eventId
        ? toast.success('Scenario updated successfully', toastConfig)
        : toast.success('Scenario created successfully', toastConfig);
    } catch (error) {
      toast.error(error.response.data.response.status.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };

  return (
    <>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <StylishNewButton onClick={() => navigate(-1)}>
              <SharedIcon iconName="keyboard_arrow_left" classes="me-2" />
              Back
            </StylishNewButton>
          </li>
          <li className="breadcrumb-item">
            <StylishNewButton
              onClick={() =>
                navigate(`${PreparePath}/dashboard/plan/prepare/events`)
              }
            >
              <StylishNewButton
                onClick={() =>
                  navigate(`${PreparePath}/dashboard/plan/prepare/events`)
                }
              >
                Scenario
              </StylishNewButton>
            </StylishNewButton>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            {keyPressForEventName || eventName}
          </li>
        </ol>
      </nav>
      <div className="d-flex align-items-center mb-4">
        <h4 className="m-0">{eventId ? 'Edit' : 'Create'} Scenario</h4>
        <StylishHelp
          classes={'sml ms-auto'}
          title={HelpPlanEvent.title}
          content={HelpPlanEvent.content}
          link={selfHostedKnowledgeBase || HelpPlanEvent.link}
        />
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-block">
          <div className="row">
            <div className="col-md-6 mb-3">
              <label className="form-label">
                Name
                <span aria-label="Required field" className="required">
                  *
                </span>
              </label>
              <Controller
                control={control}
                name={'name'}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, value } }) => (
                  <StylishNewInput
                    onChange={(e) => {
                      onChange(e);
                    }}
                    value={value}
                    type="text"
                    maxLength={inputStringLength}
                    className="form-control"
                    placeholder="Name"
                    id="name"
                    onKeyUp={() => {
                      onKeyPressForEventName();
                    }}
                  />
                )}
              />
              {errors?.name && (
                <span className="form-text form-error">
                  This is a required field
                </span>
              )}
            </div>
            <div className="col-md-6 mb-3">
              <label className="form-label">
                Overall Method
                <span aria-label="Required field" className="required">
                  *
                </span>
              </label>
              <Controller
                control={control}
                name="overall_method"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <StylishNewSelect
                    options={overallMethod}
                    value={value}
                    onChange={onChange}
                    placeholder={'Select overall method'}
                    isClearable={true}
                    isSearchable={true}
                    isMulti={false}
                    isDisabled={false}
                  />
                )}
              />
              {errors.overall_method && (
                <span className="form-text form-error">
                  This is a required field
                </span>
              )}
            </div>
            <div className="col-md-6 mb-3">
              <label className="form-label">
                Start Date ({reduxTimezone.split('/')[0]})
                <span aria-label="Required field" className="required">
                  *
                </span>
              </label>
              <Controller
                control={control}
                name="startingDate"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <StylishDateTimePicker
                    onChange={(date) => {
                      setStartDate(date || null);
                      if (date && moment(date).isAfter(moment(endDate))) {
                        // setEndDate(new Date(moment(date).toISOString()));
                        setValue('endingDate', date);
                      }
                      if (date && !endDate) {
                        // setEndDate(new Date(moment(date).toISOString()));
                        setValue('endingDate', date);
                      }
                      return onChange(date);
                    }}
                    selected={value}
                    selectsStart
                    value={value}
                    endDate={endDate}
                    isClearable={true}
                    timeFormat="HH:mm"
                    timeIntervals={20}
                    timeCaption="time"
                    dateFormat={
                      reduxUser?.timeFormat === 'hour12Mode'
                        ? 'MMMM d, yyyy hh:mm a'
                        : 'MMMM d, yyyy HH:mm'
                    }
                    placeholderText={'Choose Start Date'}
                    disableDateTimeLabel={true}
                    type="datetime-local"
                  />
                )}
              />
              {errors.startingDate && (
                <span className="form-text form-error">
                  This is a required field
                </span>
              )}
            </div>
            <div className="col-md-6 mb-3">
              <label className="form-label">
                End Date ({reduxTimezone.split('/')[0]})
                <span aria-label="Required field" className="required">
                  *
                </span>
              </label>
              <Controller
                control={control}
                name="endingDate"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <StylishDateTimePicker
                    onChange={(date) => {
                      // if (!date) {
                      //   setEndDate(null);
                      // } else {
                      //   setEndDate(date);
                      // }
                      return onChange(date);
                    }}
                    value={value}
                    selected={value || endDate}
                    selectsEnd
                    minDate={
                      !!startDate
                        ? new Date(moment(startDate).toISOString())
                        : null
                    }
                    isClearable={true}
                    timeFormat="HH:mm"
                    timeIntervals={20}
                    timeCaption="time"
                    dateFormat={
                      reduxUser?.timeFormat === 'hour12Mode'
                        ? 'MMMM d, yyyy hh:mm a'
                        : 'MMMM d, yyyy HH:mm'
                    }
                    placeholderText={'Choose End Date'}
                    type="datetime-local"
                    disableDateTimeLabel={true}
                  />
                )}
              />
              {errors.endingDate && (
                <span className="form-text form-error">
                  This is a required field
                </span>
              )}
            </div>
            <div className="col-md-12 mb-3">
              <label className="form-label">Scenario</label>
              <Controller
                control={control}
                name={'scenario'}
                rules={{ required: false }}
                render={({ field: { onChange, value } }) => (
                  <StylishNewTextArea
                    onChange={(e) => {
                      onChange(e);
                    }}
                    value={value}
                    type="text"
                    name="scenario"
                    maxLength={textareaStringLength}
                    className="form-control"
                    placeholder="Scenario"
                  />
                )}
              />
            </div>
            <div className="col-md-12 mb-3">
              <label className="form-label">Remarks</label>
              <Controller
                control={control}
                name={'remarks'}
                rules={{ required: false }}
                render={({ field: { onChange, value } }) => (
                  <StylishNewTextArea
                    onChange={(e) => {
                      onChange(e);
                    }}
                    value={value}
                    type="text"
                    name="remarks"
                    maxLength={textareaStringLength}
                    className="form-control"
                    placeholder="Remarks"
                  />
                )}
              />
            </div>
            <div className="col-md-6">
              <label className="form-label d-block">Color</label>
              <Dropdown className="dropdown-colorPicker">
                <Dropdown.Toggle className="button--icon lrg">
                  {!eventId && <img src={IconEmpty} alt="" />}
                  <span
                    style={{
                      backgroundColor: labelColor,
                    }}
                  ></span>
                </Dropdown.Toggle>
                <Dropdown.Menu align="end">
                  <h5 className="d-block mb-2">Color</h5>
                  <div className="mb-3">
                    <ul className="nav nav__form-color">
                      {colors.map((item, index) => (
                        <li key={index}>
                          <div
                            className="form-color"
                            style={{ background: item }}
                          >
                            <input
                              type="radio"
                              name="colors"
                              value={item}
                              {...register('color', {
                                onChange: predefinedColorHandler,
                              })}
                            />
                            <span className="icon">
                              <img src={IconChecked} alt="" />
                            </span>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="m-0">
                    <Controller
                      control={control}
                      name={'customColor'}
                      rules={{
                        required: true,
                        pattern: /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/i,
                        onChange: enteredColorHandler,
                      }}
                      render={({ field: { onChange, value } }) => (
                        <StylishNewInput
                          onChange={(e) => {
                            onChange(e);
                          }}
                          value={value}
                          type="text"
                          maxLength={inputStringLength}
                          className="form-control flex-grow-1"
                          placeholder="HEX Value"
                        />
                      )}
                    />
                  </div>
                  {errors?.customColor?.type === 'required' && (
                    <span className="form-text form-error">
                      This is a required field
                    </span>
                  )}
                  {errors?.customColor?.type === 'pattern' && (
                    <div className="alert alert-info mt-2" role="alert">
                      <ul className="list-ordered">
                        <li>{"The HEX value must start with '#'"}</li>
                        <li>
                          The HEX value must contain at least 3 characters
                        </li>
                        <li>The HEX value can contain max 6 characters</li>
                      </ul>
                    </div>
                  )}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>
        <div className="button-group justify-content-end mt-4">
          <StylishNewButton
            className="button button--secondary button--reverse"
            type="button"
            onClick={() => navigate(-1)}
          >
            Cancel
          </StylishNewButton>
          <StylishNewButton className="button button--primary" type="submit">
            Save
          </StylishNewButton>
        </div>
      </form>
    </>
  );
};
