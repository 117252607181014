import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import Network from '../../../../../service/Network';
import API from '../../../../../service/api';
import moment from 'moment';
import { HelpObjectivesMethods, useSelfHostedKnowledgeBase } from 'assets/data/HelpContent';
import { inputStringLength, toastConfig } from 'assets/data/config';
import StylishNewSelect from 'components/DesignSystems/New/StylishNewSelect';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import StylishNewTable from 'components/DesignSystems/New/StylishNewTable';
import { StylishNewSearchBar } from 'components/DesignSystems/New/StylishNewSearchBar';
import StylishHelp from 'components/DesignSystems/New/StylishHelp';
import { PaginationDropdown } from '../../../../../../components/DesignSystems/PaginationDropdown';
import { endLoading, startLoading } from "../../../../../../reducers/loading/loading.action";

export const MSELObjectivesMethods = (props) => {
  const [selectedObjectives, setSelectedObjectives] = useState([]);
  const [selectedPerPage, setSelectedPerPage] = useState(15);
  const [selectedPage, setSelectedPage] = useState(1);
  const [selectedTotal, setSelectedTotal] = useState(0);
  const [remainingObjectives, setRemainingObjectives] = useState([]);
  const [remainingPerPage, setRemainingPerPage] = useState(15);
  const [remainingPage, setRemainingPage] = useState(1);
  const [remainingTotal, setRemainingTotal] = useState(0);
  const [remainingSearchTerm, setRemainingSearchTerm] = useState('');

  const seflHostedKnowledgeBase = useSelfHostedKnowledgeBase()

  const dispatch = useDispatch();

  const navigate = useNavigate();
  const workspaceType = useSelector((state) => state.prepare.workspace.type);

  const participantOptions = [
    { value: 'DOD', label: 'DOD' },
    { value: 'HSEEP', label: 'HSEEP' },
  ];
  const [participantOption, setParticipantOption] = useState(
    participantOptions[0]
  );

  const reduxUser = useSelector((state) => state.prepare.user);
  const { selectedWorkspace } = useSelector((state) => state.prepare.workspace);
  const reduxTimezone = useSelector(
    (state) => state.prepare.workspace.timezone
  );

  const fetchAllInjectObjective = async () => {
    dispatch(startLoading());
    try {
      const objectives = await Network.get(API.fetchAllInjectObjective, {
        workspaceId: selectedWorkspace,
        injectId: props.injectId,
        selectedLimit: selectedPerPage,
        selectedPage: selectedPage,
        remainingLimit: remainingPerPage,
        remainingPage: remainingPage,
        term: remainingSearchTerm,
        participant_type: participantOption.value,
      });
      setSelectedObjectives(
        objectives?.data?.response?.trainingObjectiveAssociated || []
      );
      setSelectedTotal(
        objectives?.data?.response?.trainingObjectiveAssociatedTotal || 0
      );
      setRemainingObjectives(
        objectives?.data?.response?.trainingObjectiveNotAssociated || []
      );
      setRemainingTotal(
        objectives?.data?.response?.trainingObjectiveNotAssociatedTotal || 0
      );
    } catch (error) {
      toast.error(error?.response?.data?.response?.status?.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };

  useEffect(() => {
    fetchAllInjectObjective();
  }, [
    reduxUser,
    // selectedWorkspace,
    selectedPage,
    selectedPerPage,
    remainingPage,
    remainingPerPage,
    remainingSearchTerm,
    participantOption,
  ]);

  const training_method = [
    { value: 'live', label: 'Live' },
    { value: 'virtual', label: 'Virtual' },
    { value: 'constructive', label: 'Constructive' },
    { value: 'integrated_lvc', label: 'Integrated LVC' },
  ];

  const columns__assigned_training_objectives = [
    {
      dataField: 'participant',
      text: 'Participant',
      sort: true,
      headerAttrs: { title: 'Sort By:' },
      attrs: { title: 'Participant' },
    },
    {
      dataField: 'platformType',
      text: 'Platform Type',
      sort: true,
      attrs: { title: 'Platform Type' },
    },
    {
      dataField: 'jmet',
      // text: "Objective",
      text: participantOption.value === 'DOD' ? 'JMET' : 'Objective',

      sort: true,
      attrs: { title: 'Objective' },
    },
    {
      text: 'Priority',
      sort: true,
      // eslint-disable-next-line
      formatter: (cell, row, rowIndex) => (
        <>
          <span
            className="status"
            style={{ backgroundColor: row.priority_color }}
          >
            {row.priority}
          </span>
        </>
      ),
      attrs: { title: 'Priority' },
    },
    {
      text: 'Inject Count / Required Runs',
      sort: false,
      // eslint-disable-next-line
      formatter: (cell, row, rowIndex) => (
        <>
          {row.inject_count} / {row.required_runs}
        </>
      ),
      attrs: { title: 'Inject Count / Required Runs' },
    },
    {
      dataField: 'requested_method',
      text: 'Requested Method',
      sort: true,
      attrs: { title: 'Requested Method' },
    },
    {
      dataField: 'training_method',
      text: 'Training Method',
      sort: true,
      // eslint-disable-next-line
      formatter: (cell, row, rowIndex) => (
        <>
          <StylishNewSelect
            options={training_method}
            defaultValue={
              training_method.find((tm) => tm.value == row.training_method) ||
              {}
            }
            isClearable={false}
            isSearchable={false}
            isMulti={false}
            isDisabled={false}
            onChange={(e) => {
              const submitData = {
                id: row.id,
                trainedMethodType: e.value,
                workspaceId: selectedWorkspace,
              };
              updateTrainingObjective(submitData);
            }}
          />
        </>
      ),
      headerStyle: {
        width: '232px',
      },
      attrs: { title: 'Training Method' },
    },
    {
      dataField: '',

      text: 'Action',
      // eslint-disable-next-line
      formatter: (cell, row, rowIndex) => (
        <StylishNewButton
          className="button--action remove"
          type="button"
          onClick={() => {
            const submitData = {
              trainingObjectiveId: row.id,
              injectId: props.injectId,
              workspaceId: selectedWorkspace,
            };
            removeInjectTrainingObjective(submitData);
          }}
        >
          <i className="fa fa-minus" aria-hidden="true"></i>
        </StylishNewButton>
      ),
      headerStyle: {
        width: '100px',
      },
      headerAlign: 'center',
      align: 'center',
      attrs: { title: 'Action' },
    },
  ];

  const columns__available_training_objectives = [
    {
      dataField: 'participant',
      text: 'Participant',
      sort: true,
      headerAttrs: { title: 'Sort By:' },
      attrs: { title: 'Participant' },
    },
    {
      dataField: 'platformType',
      text: 'Platform Type',
      sort: true,
      attrs: { title: 'Platform Type' },
    },
    {
      dataField: 'jmet',
      text: participantOption.value === 'DOD' ? 'JMET' : 'Objective',
      sort: true,
      attrs: { title: 'JMET' },
    },
    {
      text: 'Priority',
      sort: true,
      // eslint-disable-next-line
      formatter: (cell, row, rowIndex) => (
        <>
          <span
            className="status"
            style={{ backgroundColor: row.priority_color }}
          >
            {row.priority}
          </span>
        </>
      ),
      attrs: { title: 'Priority' },
    },
    {
      text: 'Inject Count / Required Runs',
      sort: false,
      // eslint-disable-next-line
      formatter: (cell, row, rowIndex) => (
        <>
          {row.inject_count} / {row.required_runs}
        </>
      ),
      attrs: { title: 'Inject Count / Required Runs' },
    },
    {
      dataField: 'requested_method',
      text: 'Requested Method',
      sort: true,
      attrs: { title: 'Requested Method' },
    },
    {
      dataField: '',

      text: 'Action',
      // eslint-disable-next-line
      formatter: (cell, row, rowIndex) => (
        <StylishNewButton
          className="button--action add"
          type="button"
          onClick={() => {
            const submitData = {
              trainingObjectiveId: row.id,
              injectId: props.injectId,
              // eventId: ,
              workspaceId: selectedWorkspace,
            };
            addInjectTrainingObjective(submitData);
          }}
        >
          <i className="fa fa-plus" aria-hidden="true"></i>
        </StylishNewButton>
      ),
      headerStyle: {
        width: '100px',
      },
      headerAlign: 'center',
      align: 'center',
      attrs: { title: 'Action' },
    },
  ];

  const expandRowHSEEP = {
    parentClassName: 'parent-expand-tr',
    className: 'child-expand-tr',
    renderer: (row, rowIndex) => {
      return (
        <div key={rowIndex}>
          <div className="row mb-md-4">
            <div className="col-md-6 col-lg-3 mb-3 mb-lg-0">
              <label className="form-label">Source</label>
              <input
                type="text"
                maxLength={inputStringLength}
                className="form-control"
                value={row?.source_title || ''}
                readOnly
              />
            </div>
            <div className="col-md-6 col-lg-3 mb-3 mb-lg-0">
              <label className="form-label">Reference (Paragraph)</label>
              <input
                type="text"
                maxLength={inputStringLength}
                className="form-control"
                value={row?.reference_title || ''}
                readOnly
              />
            </div>
            <div className="col-md-6 col-lg-3 mb-3 mb-md-0">
              <label className="form-label">
                Paragraph Title / Description
              </label>
              <input
                type="text"
                maxLength={inputStringLength}
                className="form-control"
                value={row?.para_title || ''}
                readOnly
              />
            </div>
            <div className="col-md-6 col-lg-3 mb-3 mb-md-0">
              <label className="form-label">Level / Competency</label>
              <input
                type="text"
                maxLength={inputStringLength}
                className="form-control"
                value={row?.competence_title || ''}
                readOnly
              />
            </div>
          </div>

          {row?.tasks &&
            row?.tasks.map((taskData, index) => (
              <>
                <div className={`row ${index > 0 ? 'mb-md-4' : 'mb-md-4'}`}>
                  <div className="col-md-4 mb-3 mb-md-0" key={index}>
                    <label className="form-label">
                      Task / Behavior from PTB {index + 1}
                    </label>
                    <input
                      type="text"
                      maxLength={inputStringLength}
                      className="form-control"
                      value={taskData?.taskTitle || ''}
                      readOnly
                    />
                  </div>
                  <div className="col-md-4 mb-3 mb-md-0" key={index}>
                    <label className="form-label">
                      Standard / Measurement {index + 1}
                    </label>
                    <input
                      type="text"
                      maxLength={inputStringLength}
                      className="form-control"
                      value={taskData?.measureTitle || ''}
                      readOnly
                    />
                  </div>
                  <div className="col-md-4 mb-3 mb-md-0" key={index}>
                    <label className="form-label">
                      Code / Condition {index + 1}
                    </label>
                    <input
                      type="text"
                      maxLength={inputStringLength}
                      className="form-control"
                      value={taskData?.codeTitle || ''}
                      readOnly
                    />
                  </div>
                </div>
              </>
            ))}
          <div className="row">
            <div className="col-md-12">
              <label className="form-label">Associated Injects</label>
              <p className="m-0">
                {row?.associatedInjects?.map((associateData, index) => (
                  <span key={index} className="d-block">
                    {associateData.number
                      ? `# ${associateData.number} - `
                      : null}
                    {associateData?.title} -{' '}
                    {associateData?.responseDate !== null
                      ? moment(associateData?.responseDate).format(
                          'DD MMM YY - HHmm'
                        )
                      : ''}
                    {associateData?.responseDate !== null
                      ? reduxTimezone.split('/')[0]
                      : ''}
                    {associateData?.statusTitle ? '- ' : null}
                    {associateData?.statusTitle ? (
                      <span
                        className="status status--xsml"
                        style={{ backgroundColor: associateData?.color }}
                      >
                        {associateData?.statusTitle}
                      </span>
                    ) : null}
                  </span>
                ))}
              </p>
            </div>
          </div>
          <hr className="dashed my-4" />
          {workspaceType === 'DOD' ? (
            <div className="row">
              <div className="col-md-6 col-xl-3 mb-3 mb-xl-0">
                <>
                  <label className="form-label d-block">
                    Command Training Priorities
                  </label>
                  <p className="m-0">
                    {row?.commandPriorities.map((commandData, index) => (
                      <span className="d-block" key={index}>
                        {commandData?.title}
                      </span>
                    ))}
                  </p>
                </>
              </div>
              <div className="col-md-6 col-xl-3 mb-3 mb-xl-0">
                <>
                  <label className="form-label d-block">
                    Exercise Objectives
                  </label>
                  <p className="m-0">
                    {row?.exerciseObjectives &&
                      row?.exerciseObjectives.map((objectiveData, index) => (
                        <span className="d-block" key={index}>
                          {objectiveData?.title}
                        </span>
                      ))}
                  </p>
                </>
              </div>
              <div className="col-md-6 col-xl-3 mb-3 mb-md-0">
                <>
                  <label className="form-label d-block">
                    Strategic Priority
                  </label>
                  <p className="m-0">
                    {row?.trainingPriorities &&
                      row?.trainingPriorities.map((trainingData, index) => (
                        <span className="d-block" key={index}>
                          {trainingData?.title}
                        </span>
                      ))}
                  </p>
                </>
              </div>
              <div className="col-md-6 col-xl-3">
                <>
                  <label className="form-label d-block">Accredited Tasks</label>
                  <p className="m-0">
                    {row?.accrediatedTasks &&
                      row?.accrediatedTasks.map((accData, index) => (
                        <span className="d-block" key={index}>
                          {(accData?.levelWar || '') +
                            ' ' +
                            (accData?.taskNumber || '') +
                            ' ' +
                            (accData?.taskTitle || '')}
                        </span>
                      ))}
                  </p>
                </>
              </div>
            </div>
          ) : (
            <div className="row">
              <div className="col-md-4 mb-3 mb-md-0">
                <label className="form-label">
                  Core Capabilities / Mission Area(s)
                </label>
                <p className="m-0">
                  {row?.corecapabilities &&
                    row?.corecapabilities.map((capabilityData, index) => (
                      <span className="d-block" key={index}>
                        {capabilityData?.title}
                      </span>
                    ))}
                </p>
              </div>

              <div className="col-md-4 mb-3 mb-md-0">
                <label className="form-label">
                  Organizational Capability Targets
                </label>
                <p className="m-0">
                  {row?.organizationalcapabilitytarget &&
                    row?.organizationalcapabilitytarget.map(
                      (targetData, index) => (
                        <span className="d-block" key={index}>
                          {targetData?.title}
                        </span>
                      )
                    )}
                </p>
              </div>
              <div className="col-md-4">
                <label className="form-label">Strategic Priorities</label>
                <p className="m-0">
                  {row?.trainingPriorities &&
                    row?.trainingPriorities.map((trainingData, index) => (
                      <span className="d-block" key={index}>
                        {trainingData?.title}
                      </span>
                    ))}
                </p>
              </div>
            </div>
          )}
        </div>
      );
    },
    showExpandColumn: true,
    expandByColumnOnly: true,
    expandHeaderColumnRenderer: ({ isAnyExpands }) => {
      if (isAnyExpands) {
        return <i className="fa fa-chevron-down" aria-hidden="true"></i>;
      }
      return <i className="fa fa-chevron-right" aria-hidden="true"></i>;
    },
    expandColumnRenderer: ({ expanded }) => {
      if (expanded) {
        return (
          <>
            <div className="d-flex align-items-center justify-content-md-center">
              <span className="me-2">Details</span>
              <i className="fa fa-chevron-down" aria-hidden="true"></i>
            </div>
          </>
        );
      }
      return (
        <>
          <div className="d-flex align-items-center justify-content-md-center">
            <span className="me-2">Details</span>
            <i className="fa fa-chevron-right" aria-hidden="true"></i>
          </div>
        </>
      );
    },
  };
  const expandRowDOD = {
    parentClassName: 'parent-expand-tr',
    className: 'child-expand-tr',
    renderer: (row, rowIndex) => {
      return (
        <div key={rowIndex}>
          <div className="row mb-4">
            <div className="col-md-6 mb-3">
              <label className="form-label">Task</label>
              <input
                type="text"
                maxLength={inputStringLength}
                className="form-control"
                value={row?.task || ''}
                readOnly
              />
            </div>
            <div className="col-md-6 mb-3">
              <label className="form-label">Condition</label>
              <input
                type="text"
                maxLength={inputStringLength}
                className="form-control"
                value={row?.condition || ''}
                readOnly
              />
            </div>
            <div className="col-md-12">
              <label className="form-label">Standard</label>
              <input
                type="text"
                maxLength={inputStringLength}
                className="form-control"
                value={row?.standard || ''}
                readOnly
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <label className="form-label d-block">Measures</label>
              <p className="m-0">
                {row?.measure &&
                  row?.measure.map((measureData, index) => (
                    <span className="d-block" key={index}>
                      M{index + 1} - {measureData?.measureTitle}
                    </span>
                  ))}
              </p>
            </div>
            <div className="col-md-6">
              <label className="form-label d-block">Associated Injects</label>
              <p className="m-0">
                {row?.associatedInjects?.map((associateData, index) => (
                  <span key={index} className="d-block">
                    {associateData.number
                      ? `# ${associateData.number} - `
                      : null}
                    {associateData?.title} -{' '}
                    {associateData?.responseDate !== null
                      ? moment(associateData?.responseDate).format(
                          'DD MMM YY - HHmm'
                        )
                      : ''}
                    {associateData?.responseDate !== null
                      ? reduxTimezone.split('/')[0]
                      : ''}
                    {associateData?.statusTitle ? '- ' : null}
                    {associateData?.statusTitle ? (
                      <span
                        className="status status--xsml"
                        style={{ backgroundColor: associateData?.color }}
                      >
                        {associateData?.statusTitle}
                      </span>
                    ) : null}
                  </span>
                ))}
              </p>
            </div>
          </div>
          <hr className="dashed my-4" />
          {workspaceType === 'DOD' ? (
            <div className="row">
              <div className="col-md-6 col-xl-3 mb-3 mb-xl-0">
                <>
                  <label className="form-label d-block">
                    Command Training Priorities
                  </label>
                  <p className="m-0">
                    {row?.commandPriorities &&
                      row?.commandPriorities.map((commandData, index) => (
                        <span className="d-block" key={index}>
                          {commandData?.title}
                        </span>
                      ))}
                  </p>
                </>
              </div>
              <div className="col-md-6 col-xl-3 mb-3 mb-xl-0">
                <>
                  <label className="form-label d-block">
                    Exercise Objectives
                  </label>
                  <p className="m-0">
                    {row?.exerciseObjectives &&
                      row?.exerciseObjectives.map((objectiveData, index) => (
                        <span className="d-block" key={index}>
                          {objectiveData?.title}
                        </span>
                      ))}
                  </p>
                </>
              </div>
              <div className="col-md-6 col-xl-3 mb-3 mb-md-0">
                <>
                  <label className="form-label d-block">
                    Strategic Priority
                  </label>
                  <p className="m-0">
                    {row?.trainingPriorities &&
                      row?.trainingPriorities.map((trainingData, index) => (
                        <span className="d-block" key={index}>
                          {trainingData?.title}
                        </span>
                      ))}
                  </p>
                </>
              </div>
              <div className="col-md-6 col-xl-3">
                <>
                  <label className="form-label d-block">Accredited Tasks</label>
                  <p className="m-0">
                    {row?.accrediatedTasks &&
                      row?.accrediatedTasks.map((accData, index) => (
                        <span className="d-block" key={index}>
                          {(accData?.levelWar || '') +
                            ' ' +
                            (accData?.taskNumber || '') +
                            ' ' +
                            (accData?.taskTitle || '')}
                        </span>
                      ))}
                  </p>
                </>
              </div>
            </div>
          ) : (
            <div className="row">
              <div className="col-md-4 mb-3 mb-md-0">
                <label className="form-label">
                  Core Capabilities / Mission Area(s)
                </label>
                <p className="m-0">
                  {row?.corecapabilities &&
                    row?.corecapabilities.map((capabilityData, index) => (
                      <span className="d-block" key={index}>
                        {capabilityData?.title}
                      </span>
                    ))}
                </p>
              </div>

              <div className="col-md-4 mb-3 mb-md-0">
                <label className="form-label">
                  Organizational Capability Targets
                </label>
                <p className="m-0">
                  {row?.organizationalcapabilitytarget &&
                    row?.organizationalcapabilitytarget.map(
                      (targetData, index) => (
                        <span className="d-block" key={index}>
                          {targetData?.title}
                        </span>
                      )
                    )}
                </p>
              </div>
              <div className="col-md-4">
                <label className="form-label">Strategic Priorities</label>
                <p className="m-0">
                  {row?.trainingPriorities &&
                    row?.trainingPriorities.map((trainingData, index) => (
                      <span className="d-block" key={index}>
                        {trainingData?.title}
                      </span>
                    ))}
                </p>
              </div>
            </div>
          )}
          {/* <div className="col-md-12 mt-4">
            <div className="button-group">
              <button
                type="button"
                className="button button--primary"
                onClick={() => onEditTrainingObjective(row)}
              >
                Edit
              </button>
              <button
                type="button"
                className="button button--tertiary button--reverse"
                onClick={() => onDeleteTrainingObjective(row)}
              >
                Delete
              </button>
            </div>
          </div>
          {deleteTrainingObjective && (
            <ConfirmModal
              show={deleteTrainingObjective}
              onClose={() => setDeleteTrainingObjective(null)}
              heading={"Delete  Objective"}
              text={"Are you sure you want this?"}
              onConfirm={onDeleteConfirm}
            />
          )} */}
        </div>
      );
    },
    showExpandColumn: true,
    expandByColumnOnly: true,
    expandHeaderColumnRenderer: ({ isAnyExpands }) => {
      if (isAnyExpands) {
        return <i className="fa fa-chevron-down" aria-hidden="true"></i>;
      }
      return <i className="fa fa-chevron-right" aria-hidden="true"></i>;
    },
    expandColumnRenderer: ({ expanded }) => {
      if (expanded) {
        return (
          <>
            <div className="d-flex align-items-center justify-content-md-center">
              <span className="me-2">Details</span>
              <i className="fa fa-chevron-down" aria-hidden="true"></i>
            </div>
          </>
        );
      }
      return (
        <>
          <div className="d-flex align-items-center justify-content-md-center">
            <span className="me-2">Details</span>
            <i className="fa fa-chevron-right" aria-hidden="true"></i>
          </div>
        </>
      );
    },
  };

  const addInjectTrainingObjective = async (submitData) => {
    dispatch(startLoading());
    try {
      await Network.post(API.addInjectTrainingObjective, submitData);
      await fetchAllInjectObjective();
    } catch (error) {
      // toast.error(error?.response?.data?.response?.status?.msg);
    } finally {
      dispatch(endLoading());
    }
  };

  const removeInjectTrainingObjective = async (submitData) => {
    dispatch(startLoading());
    try {
      await Network.post(API.removeInjectTrainingObjective, submitData);
      await fetchAllInjectObjective();
    } catch (error) {
      // toast.error(error?.response?.data?.response?.status?.msg);
    } finally {
      dispatch(endLoading());
    }
  };

  const updateTrainingObjective = async (submitData) => {
    dispatch(startLoading());
    try {
      await Network.post(API.updateTrainingObjective, submitData);
      await fetchAllInjectObjective();
    } catch (error) {
      // toast.error(error?.response?.data?.response?.status?.msg);
    } finally {
      dispatch(endLoading());
    }
  };

  const onChangeSearchTerm = (term) => {
    setRemainingPage(1);
    setRemainingSearchTerm(term);
  };

  return (
    <>
      <div className="d-flex align-items-md-center flex-column flex-md-row mb-4">
        <h4 className="m-0 mb-3 mb-md-0">Objectives &amp; Methods</h4>
        <div className="d-flex align-items-sm-center flex-column flex-sm-row ms-md-auto ms-0">
          <p className="mb-3 mb-sm-0 me-sm-3">Participant Type</p>

          <div className="d-flex ms-sm-auto justify-content-between">
            <StylishNewSelect
              options={participantOptions}
              onChange={(e) => {
                setParticipantOption(e);
              }}
              value={participantOption || participantOptions[0]}
              placeholder={'Sort By...'}
              isClearable={false}
              isSearchable={false}
              isMulti={false}
            />

            <StylishHelp
              classes={'ms-sm-3 ms-auto flex-shrink-0'}
              title={HelpObjectivesMethods.title}
              content={HelpObjectivesMethods.content}
              link={seflHostedKnowledgeBase || HelpObjectivesMethods.link}
            />
          </div>
        </div>
      </div>
      <div className="form-block mb-4">
        <div className="d-md-flex align-items-center mb-3">
          <p className="m-0 me-3 weight-600 mb-2 mb-md-0">
            Assigned Objectives: {selectedTotal}
          </p>
          <div className="ms-auto flex-shrink-0">
            <PaginationDropdown
              setPage={setSelectedPage}
              setPerPage={setSelectedPerPage}
              options={[
                { value: 15, label: '15 per page' },
                { value: 30, label: '30 per page' },
                { value: 75, label: '75 per page' },
                { value: 100, label: '100 per page' },
              ]}
            />
          </div>
        </div>

        <StylishNewTable
          keyField={'id'}
          columns={columns__assigned_training_objectives}
          // rows={rows_assigned_training_objectives}
          rows={selectedObjectives.map((rO) => ({
            id: rO.trainingObjectiveId,
            participant: rO.participantName,
            exerciseObjectivesHSEEP: rO?.exerciseObjectivesHSEEP,
            participantType: rO?.participantType,

            platformType: (rO?.platformTitle || '') + ':' + rO?.platformType,
            jmet:
              rO.participantType === 'DOD'
                ? (rO?.levelWar || '') +
                  ' ' +
                  (rO?.taskNumber || '') +
                  ' ' +
                  (rO?.taskTitle || '')
                : (rO?.exerciseObjectivesHSEEP || '') +
                  ' ' +
                  (rO?.platformTitle || '') +
                  ' ' +
                  (rO?.platformType || ''),

            priority: rO.priority_title,
            priority_color: rO.color,
            inject_count: rO.injectCount,
            required_runs: rO.requiredRuns,
            requested_method: rO.requestedMethod,
            training_method: rO.trainedMethodType,
            task: rO.task,
            condition: rO.condition,
            standard: rO.standard,
            measure: rO.measure,

            commandPriorities: rO.commandPriorities,
            exerciseObjectives: rO?.exerciseObjectives,
            trainingPriorities: rO.trainingPriorities,
            accrediatedTasks: rO.accrediatedTasks,
            associatedInjects: rO.associatedInjects,
            corecapabilities: rO.corecapabilities,
            organizationalcapabilitytarget: rO.organizationalcapabilitytarget,
            source_title: rO.source_title,
            reference_title: rO.reference_title,
            para_title: rO.para_title,
            competence_title: rO.competence_title,
          }))}
          // expandRow={expandRow_assigned_training_objectives}
          expandRow={
            participantOption.value === 'DOD' ? expandRowDOD : expandRowHSEEP
          }
          pagination={true}
          page={selectedPage}
          perPage={selectedPerPage}
          total={selectedTotal}
          onPageChange={(page) => setSelectedPage(page)}
          errorMessage={
            'No  Objectives found. Try a different search or add a new entry.'
          }
        />
      </div>
      <div className="form-block">
        <div className="d-md-flex align-items-center mb-3">
          <p className="m-0 me-3 weight-600 mb-2 mb-md-0">
            Available Objectives: {remainingTotal}
          </p>
          <div className="ms-auto me-md-3 mb-3 mb-md-0">
            <StylishNewSearchBar onChangeSearchTerm={onChangeSearchTerm} />
          </div>
          <div className="">
            <PaginationDropdown
              setPage={setRemainingPage}
              setPerPage={setRemainingPerPage}
              options={[
                { value: 15, label: '15 per page' },
                { value: 30, label: '30 per page' },
                { value: 75, label: '75 per page' },
                { value: 100, label: '100 per page' },
              ]}
            />
          </div>
        </div>
        <StylishNewTable
          keyField={'id'}
          columns={columns__available_training_objectives}
          rows={remainingObjectives.map((rO) => ({
            id: rO.trainingObjectiveId,
            participant: rO.participantName,
            exerciseObjectivesHSEEP: rO?.exerciseObjectivesHSEEP,
            platformType: (rO?.platformTitle || '') + ':' + rO?.platformType,
            exerciseObjectives: rO?.exerciseObjectives,
            participantType: rO?.participantType,
            para_title: rO.para_title,

            jmet:
              rO.participantType === 'DOD'
                ? (rO?.levelWar || '') +
                  ' ' +
                  (rO?.taskNumber || '') +
                  ' ' +
                  (rO?.taskTitle || '')
                : (rO?.exerciseObjectivesHSEEP || '') +
                  ' ' +
                  (rO?.platformTitle || '') +
                  ' ' +
                  (rO?.platformType || ''),

            priority: rO.priority_title,
            priority_color: rO.color,
            inject_count: rO.injectCount,
            required_runs: rO.requiredRuns,
            requested_method: rO.requestedMethod,
            task: rO.task,
            condition: rO.condition,
            standard: rO.standard,
            measure: rO.measure,
            commandPriorities: rO.commandPriorities,
            trainingPriorities: rO.trainingPriorities,
            accrediatedTasks: rO.accrediatedTasks,
            associatedInjects: rO.associatedInjects,
            corecapabilities: rO.corecapabilities,
            organizationalcapabilitytarget: rO.organizationalcapabilitytarget,
            source_title: rO.source_title,
            reference_title: rO.reference_title,
            competence_title: rO.competence_title,
          }))}
          expandRow={
            participantOption.value === 'DOD' ? expandRowDOD : expandRowHSEEP
          }
          // expandRow={expandRowHSEEP}
          pagination={true}
          page={remainingPage}
          perPage={remainingPerPage}
          total={remainingTotal}
          onPageChange={(page) => setRemainingPage(page)}
          errorMessage={
            'No  Objectives found. Try a different search or add a new entry.'
          }
        />
      </div>
      <div className="col-md-12 mt-4">
        <div className="button-group d-block d-md-flex">
          <StylishNewButton
            type="submit"
            className="button button--primary button--reverse w-100 w-md-auto"
            onClick={(e) => {
              e.preventDefault();
              props.onPrevClick(2);
            }}
          >
            Previous
          </StylishNewButton>
          <StylishNewButton
            type="button"
            className="button button--tertiary button--reverse ms-auto mt-3 mt-md-0 w-100 w-md-auto"
            onClick={(e) => {
              e.preventDefault();
              navigate(-1, {
                replace: true,
              });
            }}
          >
            Save &amp; Exit
          </StylishNewButton>
          <StylishNewButton
            type="submit"
            className="button button--primary  w-100 w-md-auto mt-3 mt-md-0 ms-0 ms-md-3"
            onClick={(e) => {
              e.preventDefault();
              props.onNextClick(4);
            }}
          >
            Next
          </StylishNewButton>
        </div>
      </div>
    </>
  );
};
