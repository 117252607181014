import React from 'react';
import { useNavigate } from 'react-router';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AllIcon } from '../Icon';
import { useForm, Controller } from 'react-hook-form';
import Network from '../../../../../service/Network';
import API from '../../../../../service/api';
import { updateParticipantData as updateData } from '../../../../../store/Participants/participant.action';
import { toast } from 'react-toastify';
import { Service } from '../AddNewModals/Service';
import { Command } from '../AddNewModals/Command';
import { DepartmentType } from '../AddNewModals/DepartmentType';
import { OrganisationType } from '../AddNewModals/OrganisationType';
import { HelpParticipantDetails, useSelfHostedKnowledgeBase } from 'assets/data/HelpContent';
import IconSelected from '../../../../../../components/DesignSystems/New/StylishIconSelected/StylishIconSelected';
import { inputStringLength, toastConfig } from 'assets/data/config';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import StylishNewSelect from 'components/DesignSystems/New/StylishNewSelect';
import StylishNewInput from 'components/DesignSystems/New/StylishNewInput';
import { PreparePath } from 'utils/sharedUtils/sharedConst';
import StylishHelp from 'components/DesignSystems/New/StylishHelp';
import { endLoading, startLoading } from "../../../../../../reducers/loading/loading.action";

export default function ParticipantDetails(props) {
  const [term, setTerm] = useState('');
  const [allIcons, setAllIcons] = useState([]);
  const [selectedIcon, setSelectedIcon] = useState();
  const [allDepartmentType, setAllDepartmentType] = useState([]);
  const [allCommand, setAllCommands] = useState([]);
  const [allOrganisationType, setAllOrganisationType] = useState([]);
  const [allDepartments, setAllDepartments] = useState([]);
  const [participantName, setParticipantName] = useState('');
  const [participantType, setParticipantType] = useState('');
  const [homeStation, setHomeStation] = useState('');
  const [showAddDepartmentTypeModal, setShowAddDepartmentTypeModal] = useState(
    false
  );
  const [
    showAddOrganizationTypeModal,
    setShowAddOrganizationTypeModal,
  ] = useState(false);
  const [showAddServiceModal, setShowAddServiceModal] = useState(false);
  const [showAddCommandModal, setShowAddCommandModal] = useState(false);
  const [allListLoaded, setAllListLoaded] = useState(false);

  const reduxUser = useSelector((state) => state.prepare.user);
  const { selectedWorkspace } = useSelector((state) => state.prepare.workspace);

  const seflHostedKnowledgeBase = useSelfHostedKnowledgeBase()

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    getValues,
    trigger,
    clearErrors,
    setError,
  } = useForm();

  useEffect(() => {
    const newIcons = AllIcon.filter((icon) =>
      icon.name.toLocaleLowerCase().match(term.toLocaleLowerCase())
    );
    setAllIcons(newIcons);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [term]);

  const planDepartmentList = async () => {
    dispatch(startLoading());
    try {
      const department = await Network.get(API.planDepartmentList, {
        workspaceId: selectedWorkspace,
      });
      setAllDepartments([
        { value: 'new', label: 'Add New' },
        ...department.data.response.dataset.map((e) => {
          return {
            value: e.id,
            label: e.title,
          };
        }),
      ]);
    } catch (error) {
      toast.error(error?.response?.data?.response?.status?.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };

  const getOrganizationType = async () => {
    dispatch(startLoading());
    try {
      const organizationType = await Network.get(API.getOrganizationType, {
        workspaceId: selectedWorkspace,
      });
      setAllOrganisationType([
        { value: 'new', label: 'Add New' },
        ...organizationType.data.response.dataset.map((e) => {
          return {
            value: e.id,
            label: e.title,
          };
        }),
      ]);
    } catch (error) {
      toast.error(error?.response?.data?.response?.status?.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };

  const planDepartmentType = async () => {
    dispatch(startLoading());
    try {
      const departmentType = await Network.get(API.planDepartmentType, {
        workspaceId: selectedWorkspace,
      });
      setAllDepartmentType([
        { value: 'new', label: 'Add New' },
        ...departmentType.data.response.dataset.map((e) => {
          return {
            value: e.id,
            label: e.title,
          };
        }),
      ]);
    } catch (error) {
      toast.error(error?.response?.data?.response?.status?.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };

  const planCommandAgency = async () => {
    dispatch(startLoading());
    try {
      const command = await Network.get(API.planCommandAgency, {
        workspaceId: selectedWorkspace,
      });

      setAllCommands([
        { value: 'new', label: 'Add New' },
        ...command.data.response.dataset.map((e) => {
          return {
            value: e.id,
            label: e.title,
          };
        }),
      ]);
    } catch (error) {
      toast.error(error?.response?.data?.response?.status?.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };

  const fetchParticipant = async () => {
    dispatch(startLoading());
    try {
      const participant = await Network.get(API.fetchParticipant, {
        id: props.participantId,
      });

      const deptData = allDepartments.find(
        (dept) => dept.value === participant.data.response.dataset[0].serviceId
      );
      const commandData = allCommand.find(
        (command) =>
          command.value === participant.data.response.dataset[0].commandAgencyId
      );
      const deptTypeData = allDepartmentType.find(
        (dType) =>
          dType.value === participant.data.response.dataset[0].serviceTypeId
      );
      const orgTypeData = allOrganisationType.find(
        (orgType) =>
          orgType.value ===
          participant.data.response.dataset[0].participantTypeId
      );

      setValue('department', deptData);
      setValue('command', commandData);
      setValue('departmentType', deptTypeData);
      setValue('organisationType', orgTypeData);

      setParticipantName(participant.data.response.dataset[0].name);
      setValue('name', participant.data.response.dataset[0].name);
      setHomeStation(participant.data.response.dataset[0].homeStation);
      setValue('homeStation', participant.data.response.dataset[0].homeStation);
      setSelectedIcon(
        allIcons.find(
          (icon) => icon.name === participant.data.response.dataset[0].icon
        )
      );
      setValue(
        'iconSelect',
        allIcons.find(
          (icon) => icon.name === participant.data.response.dataset[0].icon
        )
      );
      if (participant.data.response.dataset[0].totalAudience === 0) {
        setValue('totalAudience', null);
      } else {
        setValue(
          'totalAudience',
          participant.data.response.dataset[0].totalAudience
        );
      }

      setParticipantType(participant.data.response.dataset[0].type);
      setValue('participantType', participant.data.response.dataset[0].type);
      props.onNameChange(participant.data.response.dataset[0].name);
    } catch (error) {
      toast.error(error?.response?.data?.response?.status?.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };

  useEffect(() => {
    planDepartmentList();
    getOrganizationType();
    planDepartmentType();
    planCommandAgency();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reduxUser]);

  // useEffect(() => {
  //   navigate("/dashboard/plan/prepare/participants", { replace: true });
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [selectedWorkspace]);

  useEffect(() => {
    if (
      allDepartments.length &&
      allCommand.length &&
      allDepartmentType.length &&
      allOrganisationType.length &&
      !allListLoaded
    ) {
      fetchParticipant();
      setAllListLoaded(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    reduxUser,
    allDepartments,
    allCommand,
    allDepartmentType,
    allOrganisationType,
  ]);

  const updateParticipantData = (data) => {
    return Network.post(API.updateParticipant, {
      id: props.participantId,
      icon: data?.iconSelect?.name || '',
      name: data.name,
      workspaceId: selectedWorkspace,
      commandAgencyId: data.command.value,
      homeStation: data.homeStation,
      serviceTypeId: data.departmentType.value,
      serviceId: data.department.value,
      totalAudience: data.totalAudience,
      participantTypeId: data.organisationType.value,
    });
  };

  const onSubmit = (data) => {
    dispatch(startLoading());
    try {
      updateParticipantData(data).then(() => {
        props.onNameChange(data.name);
        props.onNextClick(1);
        dispatch(
          updateData(
            props.participantId,
            data.name,
            selectedIcon?.name || '',
            data.command.value,
            data.homeStation,
            data.departmentType.value,
            data.department.value
          )
        );
      });
    } catch (error) {
      toast.error(error?.response?.data?.response?.status?.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };

  const submitAndExit = () => {
    dispatch(startLoading());
    try {
      const data = getValues();
      Network.post(API.updateParticipant, {
        name: data.name,
        id: props.participantId,
        icon: data?.iconSelect?.name || '',
        workspaceId: selectedWorkspace,
        commandAgencyId: data.command.value,
        homeStation: data.homeStation,
        serviceTypeId: data.departmentType.value,
        serviceId: data.department.value,
        totalAudience: data.totalAudience,
        participantTypeId: data.organisationType.value,
      }).then((resp) => {
        navigate(`${PreparePath}/dashboard/plan/prepare/participants`, {
          replace: true,
        });
      });
    } catch (error) {
      toast.error(error?.response?.data?.response?.status?.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };

  const onCloseAfterSave = (afterSave) => {
    setShowAddServiceModal(false);
    if (afterSave?.id) {
      setAllDepartments([
        ...allDepartments,
        {
          label: afterSave?.title,
          value: afterSave?.id,
        },
      ]);

      clearErrors('department');
      setValue('department', {
        label: afterSave?.title,
        value: afterSave?.id,
      });
    } else {
      setValue('department', '');
    }
  };

  const onCloseAfterSaveDepartmntType = (afterSave) => {
    setShowAddDepartmentTypeModal(false);
    if (afterSave?.id) {
      setAllDepartmentType([
        ...allDepartmentType,
        {
          label: afterSave?.title,
          value: afterSave?.id,
        },
      ]);
      clearErrors('departmentType');
      setValue('departmentType', {
        label: afterSave?.title,
        value: afterSave?.id,
      });
    } else {
      setValue('departmentType', '');
    }
  };

  const onCloseAfterSaveOrganizationType = (afterSave) => {
    setShowAddOrganizationTypeModal(false);
    if (afterSave?.id) {
      setAllOrganisationType([
        ...allOrganisationType,
        {
          label: afterSave?.title,
          value: afterSave?.id,
        },
      ]);
      clearErrors('organisationType');
      setValue('organisationType', {
        label: afterSave?.title,
        value: afterSave?.id,
      });
    } else {
      setValue('organisationType', '');
    }
  };

  const onCloseAfterSaveCommand = (afterSave) => {
    setShowAddCommandModal(false);
    if (afterSave?.id) {
      setAllCommands([
        ...allCommand,
        {
          label: afterSave?.title,
          value: afterSave?.id,
        },
      ]);
      clearErrors('command');
      setValue('command', {
        label: afterSave?.title,
        value: afterSave?.id,
      });
    } else {
      setValue('command', '');
    }
  };
  const handleKeyPress = (e) => {
    const keyCode = e.keyCode || e.which;
    const keyValue = String.fromCharCode(keyCode);
    const isValidKey = /[0-9]|Backspace/.test(keyValue);
    if (!isValidKey) {
      e.preventDefault();
    }
  };

  const customFilter = (option, searchText) => {
    if (option?.value === 'new') return true;
    if (option?.data?.label?.toLowerCase().includes(searchText.toLowerCase())) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      <div className="d-flex align-items-center mb-4">
        <h4 className="mb-0">{participantName} Details </h4>

        <StylishHelp
          classes={'ms-auto sml flex-shrink-0'}
          title={HelpParticipantDetails.title}
          content={HelpParticipantDetails.content}
          link={seflHostedKnowledgeBase || HelpParticipantDetails.link}
        />
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-block">
          <div className="row">
            <div className="col-xl-9">
              <div className="row">
                <div className="col-md-6 mb-3">
                  <label className="form-label">Name</label>
                  <Controller
                    control={control}
                    name={'name'}
                    rules={{
                      required: true,
                      // pattern: /^[A-Za-z]*[A-Za-z][A-Za-z0-9-. _]*$/,
                    }}
                    render={({ field: { onChange, value } }) => (
                      <StylishNewInput
                        onChange={(e) => {
                          onChange(e);
                        }}
                        value={value}
                        type="text"
                        name="name"
                        placeholder="Name"
                        maxLength={inputStringLength}
                        className="form-control"
                        // {...register('amount', { required: true })}
                      />
                    )}
                  />
                  {errors?.name && (
                    <span className="form-text form-error">
                      This field is required
                    </span>
                  )}
                  {/* {errors?.name?.type === 'pattern' && (
                    <span className="form-text form-error">
                      Name cannot start with a number
                    </span>
                  )} */}
                </div>
                <div className="col-md-6 mb-3">
                  <label className="form-label">Participant Type</label>
                  <Controller
                    control={control}
                    name={'participantType'}
                    rules={{
                      required: true,
                    }}
                    render={({ field: { onChange, value } }) => (
                      <StylishNewInput
                        onChange={(e) => {
                          onChange(e);
                        }}
                        value={value}
                        type="text"
                        name="participantType"
                        maxLength={inputStringLength}
                        className="form-control"
                        readOnly
                        // {...register('amount', { required: true })}
                      />
                    )}
                  />
                </div>
                <div className="col-md-6 mb-3">
                  {participantType === 'DOD' ? (
                    <label className="form-label">Service or Department</label>
                  ) : (
                    <label className="form-label">
                      City/Town/Service/Department
                    </label>
                  )}
                  <Controller
                    control={control}
                    name="department"
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <StylishNewSelect
                        options={allDepartments}
                        defaultValue={value}
                        value={value}
                        onChange={(e) => {
                          if (e?.value === 'new') {
                            setShowAddServiceModal(true);
                          } else if (!e) {
                            setError('department');
                          } else if (e.value) {
                            clearErrors('department');
                          }

                          return onChange(e);
                        }}
                        filterOption={customFilter}
                        placeholder={'Select Organization'}
                        isClearable={true}
                        isSearchable={true}
                        isMulti={false}
                        isDisabled={false}
                      />
                    )}
                  />
                  {errors.department && (
                    <span className="form-text form-error">
                      This is a required field
                    </span>
                  )}
                </div>
                <div className="col-md-6 mb-3">
                  {participantType === 'DOD' ? (
                    <label className="form-label">
                      Service/Department Type
                    </label>
                  ) : (
                    <label className="form-label">
                      Jurisdiction/Service/Department Types
                    </label>
                  )}
                  <Controller
                    control={control}
                    name="departmentType"
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <StylishNewSelect
                        options={allDepartmentType}
                        defaultValue={value}
                        value={value}
                        onChange={(e) => {
                          if (e?.value === 'new') {
                            setShowAddDepartmentTypeModal(true);
                          } else if (!e) {
                            setError('departmentType');
                          } else if (e.value) {
                            clearErrors('departmentType');
                          }
                          return onChange(e);
                        }}
                        filterOption={customFilter}
                        placeholder={'Select Department Type'}
                        isClearable={true}
                        isSearchable={true}
                        isMulti={false}
                        isDisabled={false}
                      />
                    )}
                  />
                  {errors.departmentType && (
                    <span className="form-text form-error">
                      This is a required field
                    </span>
                  )}
                </div>
                <div className="col-md-6 mb-3">
                  {participantType === 'DOD' ? (
                    <label className="form-label">Command or Agency</label>
                  ) : (
                    <label className="form-label">
                      State/County/Chapter/Command/Agency
                    </label>
                  )}
                  <Controller
                    control={control}
                    name="command"
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <StylishNewSelect
                        options={allCommand}
                        defaultValue={value}
                        value={value}
                        onChange={(e) => {
                          if (e?.value === 'new') {
                            setShowAddCommandModal(true);
                          } else if (!e) {
                            setError('command');
                          } else if (e.value) {
                            clearErrors('command');
                          }
                          return onChange(e);
                        }}
                        filterOption={customFilter}
                        placeholder={'Select Command'}
                        isClearable={true}
                        isSearchable={true}
                        isMulti={false}
                        isDisabled={false}
                      />
                    )}
                  />
                  {errors.command && (
                    <span className="form-text form-error">
                      This is a required field
                    </span>
                  )}
                </div>
                <div className="col-md-6 mb-3">
                  {participantType === 'DOD' ? (
                    <label className="form-label">Home Station</label>
                  ) : (
                    <label className="form-label">Office Location</label>
                  )}
                  <Controller
                    control={control}
                    name="homeStation"
                    rules={{
                      required: true,
                    }}
                    render={({ field: { onChange, value } }) => (
                      <StylishNewInput
                        onChange={(e) => {
                          onChange(e);
                          if (e.target.value !== '') {
                            clearErrors('homeStation');
                          } else {
                            setError('homeStation');
                          }
                        }}
                        // onChange={(event) => {
                        //   if (!event.target.value) {
                        //     setError('homeStation');
                        //   } else if (event.target.value) {
                        //     clearErrors('homeStation');
                        //   }
                        // }}
                        value={value}
                        type="text"
                        name="homeStation"
                        maxLength={inputStringLength}
                        className="form-control"
                        // defaultValue={value || homeStation}
                        placeholder="Home Station Location"
                        // {...register('amount', { required: true })}
                      />
                    )}
                  />
                  {errors?.homeStation && (
                    <span className="form-text form-error">
                      This is a required field
                    </span>
                  )}
                </div>
                <div className="col-md-6 mb-3 mb-xl-0">
                  <label className="form-label">Total Training Audience</label>
                  <Controller
                    control={control}
                    name={'totalAudience'}
                    rules={{
                      required: true,
                      valueAsNumber: true,
                      max: 1000000000,
                      min: 0,
                    }}
                    render={({ field: { onChange, value } }) => (
                      <StylishNewInput
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          const sanitizedValue = inputValue.replace(
                            /[^0-9]/g,
                            ''
                          );
                          onChange(sanitizedValue);
                        }}
                        value={value}
                        type="number"
                        name="totalAudience"
                        className="form-control"
                        placeholder="Number of Trainees"
                      />
                    )}
                  />
                  {errors?.totalAudience?.type === 'required' && (
                    <span className="form-text form-error">
                      This is a required field
                    </span>
                  )}
                  {errors?.totalAudience?.type === 'max' && (
                    <span className="form-text form-error">
                      Max 1000000000 allowed.
                    </span>
                  )}
                  {errors?.totalAudience?.type === 'min' && (
                    <span className="form-text form-error">
                      Please enter a positive value
                    </span>
                  )}
                </div>
                <div className="col-md-6 mb-3 mb-xl-0">
                  {participantType === 'DOD' ? (
                    <label className="form-label">Organization Type</label>
                  ) : (
                    <label className="form-label">
                      ESF / Organization Type
                    </label>
                  )}
                  <Controller
                    control={control}
                    name="organisationType"
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <StylishNewSelect
                        options={allOrganisationType}
                        defaultValue={value}
                        value={value}
                        onChange={(e) => {
                          if (e?.value === 'new') {
                            setShowAddOrganizationTypeModal(true);
                          } else if (!e) {
                            setError('organisationType');
                          } else if (e.value) {
                            clearErrors('organisationType');
                          }
                          return onChange(e);
                        }}
                        filterOption={customFilter}
                        placeholder={'Select Organization Type'}
                        isClearable={true}
                        isSearchable={true}
                        isMulti={false}
                        isDisabled={false}
                      />
                    )}
                  />
                  {errors.organisationType && (
                    <span className="form-text form-error">
                      This is a required field
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div className="col-md-6 col-xl-3">
              <label className="form-label d-block">Select an Icon</label>
              <Controller
                control={control}
                name="iconSelect"
                rules={{ required: false }}
                render={({ field: { onChange, value } }) => (
                  <IconSelected onIconChange={onChange} selectedIcon={value} />
                )}
              />
            </div>
          </div>
        </div>
        <div className="col-md-12 mt-4">
          <div className="button-group justify-content-end">
            <StylishNewButton
              className="button button--tertiary button--reverse"
              type="button"
              onClick={async () => {
                const valid = await trigger();
                if (valid) {
                  submitAndExit();
                }
              }}
            >
              Save &amp; Exit
            </StylishNewButton>
            <StylishNewButton className="button button--primary" type="submit">
              Next
            </StylishNewButton>
          </div>
        </div>
      </form>

      {showAddServiceModal && (
        <Service
          show={showAddServiceModal}
          onClose={(afterSave) => onCloseAfterSave(afterSave)}
        />
      )}
      {showAddCommandModal && (
        <Command
          show={showAddCommandModal}
          onClose={(afterSave) => onCloseAfterSaveCommand(afterSave)}
        />
      )}
      {showAddDepartmentTypeModal && (
        <DepartmentType
          show={showAddDepartmentTypeModal}
          onClose={(afterSave) => onCloseAfterSaveDepartmntType(afterSave)}
        />
      )}
      {showAddOrganizationTypeModal && (
        <OrganisationType
          show={showAddOrganizationTypeModal}
          onClose={(afterSave) => onCloseAfterSaveOrganizationType(afterSave)}
        />
      )}
    </>
  );
}
