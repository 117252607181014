import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import { StylishNewSearchBar } from 'components/DesignSystems/New/StylishNewSearchBar';
// import SearchBar from '../../../component/SearchBar/SearchBar';
import ReactPaginate from 'react-paginate';
import {
  MiscellaneousHelpLink,
  ViewProfileHelp,
  useSelfHostedKnowledgeBase,
} from 'assets/data/HelpContent';
import IconEdit from 'assets/images/icon__edit.svg';
import IconComment from 'assets/images/icon__comment.svg';
import IconDelete from 'assets/images/icon__delete.svg';
import ShareProfileDialog from './ShareProfileDialog';
import ViewPostDialog from '../ViewPostDialog/ViewPostDialog';
import API from '../../../service/api';
import Network from '../../../service/Network';
import { PreparePath } from 'utils/sharedUtils/sharedConst';
import { AllIcon } from 'assets/Icon/Icon';
import moment from 'moment';
import IconEmptyExercise from 'assets/images/icon__exercise-details-empty.svg';
import CreatePostDialog from '../Posts/CreatePostDialog';
import { StylishConfirmDialog } from 'components/DesignSystems/New/StylishConfirmDialog';
import { toast } from 'react-toastify';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { contentBlockLength, toastConfig } from 'assets/data/config';
import StylishHelp from 'components/DesignSystems/New/StylishHelp';
import IconCommentLocation from 'assets/images/icon__location--post.svg';
import IconCommentImage from 'assets/images/icon__developer--users.svg';
import { LinkedPersonasDialog } from './LinkedPersonas';
import { sanitizeHtml } from '../../../../utils/sanitizeHtml';
import { endLoading, startLoading } from "../../../../reducers/loading/loading.action";
import isRoleReadOnly from 'PREPAREsrc/utils/isRoleReadOnly'

export default function ViewPosts() {
  const [showShareProfileDialog, setShowShareProfileDialog] = useState(false);
  const [showViewPostDialog, setShowViewPostDialog] = useState(false);
  const [profileDetails, setProfileDetails] = useState([]);
  const [deleteProfile, setDeleteProfile] = useState(null);
  const [postDetails, setPostDetails] = useState([]);
  const [postTotal, setPostTotal] = useState(0);
  const [showCreatePostDialog, setShowCreatePostDialog] = useState(false);
  const [selectedSiteId, setSelectedSiteId] = useState('');
  const [selectedPostid, setSelectedPostid] = useState('');
  const [deletePost, setDeletePost] = useState(null);
  const [filter, setFilter] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedPostId, setSelectedPostId] = useState('');
  const [profileId, setProfileId] = useState(null);
  const [viewSites, setViewSites] = useState([]);
  const [platform, setPlatform] = useState(null);
  const [showLinkedPersonsDialog, setShowLinkedPersonsDialog] = useState(false);
  const [isPersonasUpdated, setIsPersonasUpdated] = useState(false);

  const selfHostedKnowledgeBase = useSelfHostedKnowledgeBase()

  const [page, setPage] = useState(1);
  const perPage = 8;

  const navigate = useNavigate();

  const reduxUser = useSelector((state) => state.prepare.user);

  const { site, name, id } = useParams();

  const formattedName = name.replace(/ /g, '%20');

  const workspaceId = useSelector(
    (state) => state.prepare.workspace.selectedWorkspace
  );
  const dispatch = useDispatch();

  const onShareProfileDialog = () => {
    setShowShareProfileDialog(true);
  };
  const onChangeSearchTerm = (termSearched) => {
    setSearchTerm(termSearched);
    setPage(1);
  };
  const handlePageClick = (event) => {
    setPage(event.selected + 1);
  };

  const onShareProfileDialogClose = () => {
    setShowShareProfileDialog(false);
  };

  const onViewPostDialog = (postId) => {
    postId && setSelectedPostId(postId);
    setShowViewPostDialog(true);
  };

  const onViewPostDialogClose = () => {
    setShowViewPostDialog(false);
  };
  const onDeletePost = (row) => {
    setDeletePost(row.id);
  };

  const onDeleteConfirmPost = async () => {
    dispatch(startLoading());
    try {
      await Network.post(API.deleteMediaPost, {
        postId: deletePost,
      });
      await profileDetailsPostByProfileId();

      setDeletePost(null);
      toast.success('Post deleted successfully', toastConfig);
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(endLoading());
    }
  };

  const onDeleteConfirm = async () => {
    dispatch(startLoading());
    try {
      await Network.post(API.deleteMediaProfile, { profileId: id });
      setDeleteProfile(null);

      navigate(`${PreparePath}/dashboard/media/profiles`);
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(endLoading());
    }
  };

  const onDeleteProfile = (row) => {
    setDeleteProfile(row.profileId);
  };

  const profileDetailsPostByProfileId = async () => {
    dispatch(startLoading());
    try {
      const response = await Network.get(API.profileDetailsPostByProfileId, {
        workspaceId: workspaceId,
        profileId: id,
        filter: filter,
        search: searchTerm,
        limit: perPage,
        page: page,
      });
      if (workspaceId) {
        if (workspaceId !== response?.data?.response?.dataset[0]?.workspaceId) {
          navigate(`${PreparePath}/dashboard/media/profiles`);
        }
      }
      setProfileDetails(response?.data?.response?.dataset[0]);
      setPostDetails(response?.data?.response?.dataset[0]?.posts);
      setPostTotal(response?.data?.response?.dataset[0]?.total_post_count);
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(endLoading());
    }
  };

  useEffect(() => {
    profileDetailsPostByProfileId();
  }, [filter, searchTerm, perPage, page, workspaceId, isPersonasUpdated]);

  useEffect(() => {
    const event = {
      selected: 0,
    };
    handlePageClick(event);
  }, [filter]);

  const onCreatePostDialog = (data) => {
    setShowCreatePostDialog(true);
    setSelectedSiteId(profileDetails?.mediaSiteId);
    setSelectedPostid(data?.id);
  };

  const onCreate = async () => {
    dispatch(startLoading());
    try {
      const newProfile = await Network.post(API.createMediaPost, {
        status: 'drafted',
        mediaProfileId: profileId,
        workspaceId: workspaceId,
        type: 'post',
        mediaSiteId: platform,
      });
      navigate(
        `${PreparePath}/dashboard/media/posts/edit-post/${newProfile?.data?.response?.MediaPost?.id}`
      );
    } catch (error) {
      console.log(error);
      toast.error('Something went wrong', toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };

  const mediaSiteList = async () => {
    dispatch(startLoading());
    try {
      const response = await Network.get(API.mediasiteList, {
        workspaceId: workspaceId,
      });
      const mediaList = response.data.response.dataset.map(
        (updatedMediaList) => {
          return {
            label: updatedMediaList.displayName,
            value: updatedMediaList.id,
          };
        }
      );
      setViewSites(mediaList);
      return mediaList;
    } catch (error) {
    } finally {
      dispatch(endLoading());
    }
  };

  useEffect(() => {
    const fetchMediaSiteListAndSetPlatform = async () => {
      const mediaList = await mediaSiteList();

      const currentUrl = window.location.href;
      const urlParts = currentUrl.split('/');
      const profilesIndex = urlParts.indexOf('profiles');

      if (profilesIndex !== -1 && profilesIndex + 2 < urlParts.length) {
        const platformName = urlParts[profilesIndex + 1];
        setProfileId(urlParts[profilesIndex + 3]);
        const platformNameWithUppercase =
          platformName.charAt(0).toUpperCase() + platformName.slice(1);
        const platform = mediaList.find(
          (item) => item.label === platformNameWithUppercase
        );
        if (platform) {
          setPlatform(platform.value);
        }
      }
    };

    fetchMediaSiteListAndSetPlatform();
  }, [reduxUser]);

  const onCreatePostDialogClose = () => {
    setShowCreatePostDialog(false);
  };
  
  const user = useSelector((state) => state.prepare.user);

  return (
    <>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <StylishNewButton
              onClick={() =>
                navigate(`${PreparePath}/dashboard/media/profiles`)
              }
            >
              <i className="fa fa-angle-left me-2" aria-hidden="true"></i>
              Back
            </StylishNewButton>
          </li>
          <li className="breadcrumb-item">
            <StylishNewButton
              onClick={() =>
                navigate(`${PreparePath}/dashboard/media/profiles`)
              }
            >
              Profile
            </StylishNewButton>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Posts by {profileDetails?.profileName}
          </li>
        </ol>
      </nav>

      <div className="d-flex align-items-md-center flex-wrap flex-column flex-md-row mb-4">
        {profileDetails?.isUserGenerated === false ? null : (
          <div className="flex-shrink-0 flex-wrap mb-3 mb-md-0">
            <StylishNewButton
              className={`button--primary button--reverse w-100 w-md-auto mb-3 mb-md-0
              ${isRoleReadOnly(user.roles)?' disabled':''}`}
              onClick={onCreate}
            >
              Create Post
            </StylishNewButton>

            <StylishNewButton
              className="button--primary button--reverse ms-md-3 w-100 w-md-auto"
              onClick={onShareProfileDialog}
            >
              Share Profile
            </StylishNewButton>
          </div>
        )}

        <p className="ms-md-auto mb-0">{postTotal} Posts</p>

        <div className="d-flex align-items-center w-100 w-lg-auto mt-3 mt-lg-0 ">
          <StylishNewSearchBar
            className="ms-lg-3 flex-grow-1"
            onChangeSearchTerm={onChangeSearchTerm}
          />

          <StylishHelp
            classes={'flex-shrink-0 ms-3'}
            title={ViewProfileHelp.title}
            content={ViewProfileHelp.content}
            link={selfHostedKnowledgeBase || ViewProfileHelp.link}
          />
        </div>
      </div>
      <div className="my-4 posts-tab">
        <div className="d-flex align-items-center justify-content-start justify-content-md-end flex-wrap">
          <StylishNewButton
            onClick={() => setFilter(null)}
            className={`cursor-pointer ${filter === null ? 'color-brand' : ''}`}
          >
            All
          </StylishNewButton>
          <div className="separator--y bg-white flex-shrink-0 h--16"></div>
          <StylishNewButton
            onClick={() => setFilter('published')}
            className={`cursor-pointer ${
              filter === 'published' ? 'color-brand' : ''
            }`}
          >
            Published
          </StylishNewButton>
          <div className="separator--y bg-white flex-shrink-0 h--16"></div>
          <StylishNewButton
            onClick={() => setFilter('scheduled')}
            className={`cursor-pointer ${
              filter === 'scheduled' ? 'color-brand' : ''
            }`}
          >
            Scheduled
          </StylishNewButton>
          <div className="separator--y bg-white flex-shrink-0 h--16"></div>
          <StylishNewButton
            onClick={() => setFilter('drafted')}
            className={`cursor-pointer ${
              filter === 'drafted' ? 'color-brand' : ''
            }`}
          >
            Drafted
          </StylishNewButton>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-4 col-xl-3 mb-4 mb-lg-0">
          <div className="bg-gray-800 rounded-top d-flex flex-column overflow-hidden">
            <div className="position-relative">
              {profileDetails?.profilebanner ? (
                <div className="card-banner card-banner__big position-relative">
                  <img
                    className="position-absolute top-0 start-0"
                    src={profileDetails?.profilebanner}
                    alt=""
                  />
                </div>
              ) : (
                <div className="card-banner card-banner__big position-relative"></div>
              )}
              <Dropdown className="position-absolute top-0 end-0 mt-3 me-3">
                <Dropdown.Toggle className="button--icon card-banner__button bg-gray-600 ">
                  <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                </Dropdown.Toggle>
                <Dropdown.Menu align="end">
                  <Dropdown.Item
                    onClick={() => {
                      navigate(
                        `${PreparePath}/dashboard/media/edit-profile/${id}`
                      );
                    }}
                  >
                    <img src={IconEdit} alt="" />
                    Edit Profile
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => onDeleteProfile(profileDetails)}
                  >
                    <img src={IconDelete} alt="" />
                    Delete Profile
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="p-4 card-content position-relative card-content__pt-80">
              {profileDetails?.profileavatar ? (
                <div className="profile-img profile-img__big position-absolute top-80">
                  <img src={profileDetails?.profileavatar} alt="" />
                </div>
              ) : (
                <div className="profile-img profile-img__big position-absolute top-80"></div>
              )}

              <h4 className="mb-0 break-word">{profileDetails?.profileName}</h4>
              <p className="mb-3 ">
                {'@'}
                {profileDetails?.profileUsername}
              </p>
              {(profileDetails?.mediapersonaprofilerelation &&
                !!profileDetails?.mediapersonaprofilerelation?.length && (
                  <p className="color-brand weight-500">
                    {profileDetails?.mediapersonaprofilerelation?.length} Linked
                    Persona
                  </p>
                )) ||
                null}
              <div className="d-flex align-items-center mb-3">
                {profileDetails?.siteicon ? (
                  <div style={{ color: profileDetails?.sitecolor }}>
                    {
                      AllIcon.find(
                        (icon) => icon.name === profileDetails?.siteicon
                      )?.icon
                    }
                  </div>
                ) : null}
                {profileDetails?.site_image ? (
                  <div className="has-img-h-24 me-2">
                    <img
                      src={profileDetails?.site_image}
                      alt=""
                      className="rounded-5 d-block"
                    />
                  </div>
                ) : null}
                <p className="mb-0 ">{profileDetails?.siteName}</p>
              </div>

              {profileDetails?.profileDescription && (
                <p className="mb-3">{profileDetails?.profileDescription} </p>
              )}
              {profileDetails?.isUserGenerated === false ? null : (
                <div className="button-group flex-column flex-md-row">
                  <StylishNewButton
                    className={`button--primary button--reverse w-100 w-md-auto mb-3 px-4 weight-500
                      ${isRoleReadOnly(user.roles)?' disabled':""}`}
                    onClick={onCreate}
                  >
                    Create Post
                  </StylishNewButton>

                </div>
              )}
              <StylishNewButton
                className="button--primary button--reverse w-100 w-md-auto px-4 weight-500"
                onClick={() => setShowLinkedPersonsDialog(true)}
              >
                View Linked Personas
              </StylishNewButton>
            </div>
          </div>
        </div>
        {postTotal > 0 ? (
          <div className="col-lg-8 col-xl-9">
            <div className="bg-gray-800 mb-4 p-4">
              {postDetails.map((post) => {
                const truncatedPost =
                  post?.post?.length > contentBlockLength
                    ? `${post?.post?.slice(0, contentBlockLength)}....`
                    : post?.post;
                const cleanPost = sanitizeHtml(truncatedPost);
                return (
                  <div className="posts-row" key={post?.id}>
                    <div className="d-flex">
                      <div
                        className="flex-grow-1 cursor-pointer"
                        onClick={(e) => {
                          e.preventDefault();
                          onViewPostDialog(post?.id);
                        }}
                      >
                        <div className="d-flex flex-column flex-md-row">
                          {post?.type === 'comment' &&
                            `In response to  @${post?.username}'s post`}
                          {post?.commentCount > 0 &&
                            `comments ${post?.commentCount}`}

                          {post?.mediaFile?.length > 0 && (
                            <>
                              <img src={IconCommentImage} alt="" />
                            </>
                          )}

                          {profileDetails?.profileavatar ? (
                            <div className="profile-img flex-shrink-0">
                              <img src={profileDetails?.profileavatar} alt="" />
                            </div>
                          ) : (
                            <div className="profile-img flex-shrink-0"></div>
                          )}

                          <div className="flex-grow-1 ps-0 ps-md-3 pt-3 pt-md-0">
                            <div className="d-flex align-items-start mb-2 flex-column flex-md-row align-items-md-center">
                              <h4 className="mb-0">
                                {profileDetails?.profileName}
                              </h4>
                              {post?.lat !== null && (
                                <>
                                  <img
                                    className="ms-md-auto mb-1 mb-md-0"
                                    src={IconCommentLocation}
                                    alt=""
                                  />
                                </>
                              )}

                              <p
                                className={`mb-0  ${
                                  post?.lat !== null ? 'ms-md-3' : 'ms-md-auto'
                                }`}
                              >
                                {post?.status?.charAt(0).toUpperCase() +
                                  post?.status?.slice(1)}
                              </p>
                            </div>

                            <div className="d-flex flex-column flex-md-row">
                              <div className="d-flex">
                                <p className="mb-0 ">
                                  {'@'}
                                  {profileDetails?.profileUsername}
                                </p>
                                <span className="mx-3">|</span>
                                <div className="d-flex align-items-center">
                                  {profileDetails?.siteicon ? (
                                    <div
                                      className="me-2"
                                      style={{
                                        color: profileDetails?.sitecolor,
                                      }}
                                    >
                                      {
                                        AllIcon.find(
                                          (icon) =>
                                            icon.name ===
                                            profileDetails?.siteicon
                                        )?.icon
                                      }
                                    </div>
                                  ) : null}
                                  {profileDetails?.site_image ? (
                                    <div className="has-img-h-24 me-2">
                                      <img
                                        src={profileDetails?.site_image}
                                        alt=""
                                        className="rounded-5 d-block"
                                      />
                                    </div>
                                  ) : null}

                                  <p className="mb-0 ">
                                    {profileDetails?.siteName}
                                  </p>
                                </div>
                              </div>

                              <p className="mb-0 ms-0 ms-md-auto mt-2 mt-md-0">
                                {moment(post?.createdAt)?.fromNow()} at{' '}
                                {moment(post?.createdAt)?.format(
                                  'MMM DD, YYYY  HH:mm'
                                )}
                              </p>
                            </div>
                          </div>
                        </div>
                        <p
                          className="mb-0 mt-3"
                          dangerouslySetInnerHTML={{ __html: cleanPost }}
                        ></p>
                      </div>

                      <div className="ms-3 flex-shrink-0">
                        <Dropdown className="flex-shrink-0 ms-auto">
                          <Dropdown.Toggle className="button--icon">
                            <i
                              className="fa fa-ellipsis-v"
                              aria-hidden="true"
                            ></i>
                          </Dropdown.Toggle>
                          <Dropdown.Menu align="end">
                            <Dropdown.Item
                              onClick={(e) => {
                                e.preventDefault();
                                navigate(
                                  `${PreparePath}/dashboard/media/posts/edit-post/${post?.id}`
                                );
                              }}
                            >
                              <img src={IconEdit} alt="" />
                              {post?.type === 'post'
                                ? 'Edit Post'
                                : 'Edit Comment'}
                            </Dropdown.Item>
                            {post?.type === 'post' &&
                              post?.status !== 'drafted' &&
                              post?.status !== 'scheduled' && (
                                <Dropdown.Item
                                  onClick={() => {
                                    onCreatePostDialog(post);
                                  }}
                                >
                                  <img src={IconComment} alt="" />
                                  Create Comment
                                </Dropdown.Item>
                              )}
                            <Dropdown.Item onClick={() => onDeletePost(post)}>
                              <img src={IconDelete} alt="" />
                              Delete Post
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>

                    <hr className="dashed" />
                  </div>
                );
              })}
            </div>
            <div className="d-flex justify-content-center justify-content-md-end mt-4">
              <ReactPaginate
                className="pagination"
                breakLabel="..."
                nextLabel="Next"
                onPageChange={handlePageClick}
                pageCount={Math.ceil(postTotal / perPage)}
                previousLabel="Prev"
                forcePage={page - 1}
              />
            </div>
          </div>
        ) : (
          <>
            <div className="col-lg-8 col-xl-9">
              <div className="bg-gray-800 text-center p-5">
                <div className="py-0 py-md-4">
                  <div className="d-flex justify-content-center mb-4">
                    <img src={IconEmptyExercise} alt="" />
                  </div>
                  <p className="weight-600 mb-4">
                    A fresh start. Create the first entry.
                    <a href={selfHostedKnowledgeBase || MiscellaneousHelpLink.link} target="_blank">
                      {' '}
                      Need more help?
                    </a>
                  </p>
                  {(profileDetails?.isUserGenerated === false && !isRoleReadOnly(user.roles)) ? null : (
                    <a
                      className="button button--primary cursor-pointer"
                      onClick={onCreate}
                    >
                      Create Post
                    </a>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      {showShareProfileDialog && (
        <ShareProfileDialog
          show={showShareProfileDialog}
          onClose={onShareProfileDialogClose}
          userName={formattedName}
          userSite={profileDetails?.siteslug}
          id={id}
          // updateDialogData={updateDialogData}
        />
      )}
      {showViewPostDialog && (
        <ViewPostDialog
          show={showViewPostDialog}
          onClose={onViewPostDialogClose}
          postId={selectedPostId}
        />
      )}
      {deleteProfile && (
        <StylishConfirmDialog
          show={deleteProfile}
          onClose={() => setDeleteProfile(null)}
          dialogTitle={'Delete Profile'}
          dialogContent={
            'Deleting the profile, will delete the posts and comments created with this profile - Are you sure?'
          }
          onConfirm={onDeleteConfirm}
        />
      )}
      {showCreatePostDialog && (
        <CreatePostDialog
          show={showCreatePostDialog}
          onClose={onCreatePostDialogClose}
          selectedSiteId={selectedSiteId}
          selectedPostid={selectedPostid}
          isFromProfile="yes"
          site={site}
          name={name}
          id={id}
        />
      )}
      {deletePost && (
        <StylishConfirmDialog
          show={deletePost}
          onClose={() => setDeletePost(null)}
          dialogTitle={'Delete Post'}
          dialogContent={'Are you sure you want this?'}
          onConfirm={onDeleteConfirmPost}
        />
      )}
      {(showLinkedPersonsDialog && (
        <LinkedPersonasDialog
          show={showLinkedPersonsDialog}
          setIsPersonasUpdated={setIsPersonasUpdated}
          onClose={() => setShowLinkedPersonsDialog(false)}
          data={profileDetails}
          workspaceId={workspaceId}
        />
      )) ||
        null}
    </>
  );
}
