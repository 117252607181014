import { useIncidentInfo } from './hooks/incidentInfoHooks';
import { StylishNewButton } from '../DesignSystems/New/StylishNewButton';
import { useToggle } from 'react-use';
import React from 'react';
import IncidentInfoEditDrawer from './IncidentInfoEditDrawer';

const IncidentSummary: React.FC = () => {
  const { data: incidentInfo } = useIncidentInfo();
  const [open, toggle] = useToggle(false);

  return (
    <>
      <div
        className="w-100 rounded rounded-1 bg-bgdark d-flex flex-column gap-3"
        style={{
          padding: '0.75rem 0.75rem 1rem 1rem',
        }}
      >
        <div className="d-flex justify-content-between align-items-center">
          <div> Situation Overview</div>
          <StylishNewButton onClick={toggle} className="button--secondary">
            Edit
          </StylishNewButton>
        </div>

        <div>{incidentInfo?.situation_summary}</div>
      </div>
      {open && incidentInfo ? (
        <IncidentInfoEditDrawer toggle={toggle} incidentInfo={incidentInfo} />
      ) : null}
    </>
  );
};

export default IncidentSummary;
