import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Network from '../../../../../service/Network';
import API from '../../../../../service/api';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';
import { CreateParticipantPlatform } from './CreateParticipantPlatform';
import { HelpParticipantPlatforms, useSelfHostedKnowledgeBase } from 'assets/data/HelpContent';
import ConfirmModal from '../../../../../component/ConfirmModal/ConfirmModal';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { PreparePath } from 'utils/sharedUtils/sharedConst';
import StylishNewTable from 'components/DesignSystems/New/StylishNewTable';
import StylishNewTableAction from 'components/DesignSystems/New/StylishNewTableAction';
import StylishHelp from 'components/DesignSystems/New/StylishHelp';
import { toastConfig } from 'assets/data/config';
import { PaginationDropdown } from '../../../../../../components/DesignSystems/PaginationDropdown';
import { endLoading, startLoading } from "../../../../../../reducers/loading/loading.action";

export const ParticipantPlatform = (props) => {
  const [showcreateParticipant, setshowcreateParticipant] = useState(false);
  const [total, setTotal] = useState(0);
  const [viewPlatform, setViewPlatform] = useState([]);
  const [dialogType, setDialogType] = useState('Add');
  const [updateDialogData, setUpdateDialogData] = useState(null);
  const [perPage, setPerPage] = useState(5);
  const [page, setPage] = useState(1);
  const [orderBy, setOrderBy] = useState('');
  const [deletePlatform, setDeletePlatform] = useState(null);

  const selfHostedKnowledgeBase = useSelfHostedKnowledgeBase()

  const { selectedWorkspace } = useSelector((state) => state.prepare.workspace);
  const reduxUser = useSelector((state) => state.prepare.user);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const columns = [
    {
      dataField: 'title',
      text: 'Platform Title',
      sort: true,
      headerAttrs: { title: 'Sort By:' },
      attrs: { title: 'Platform Title' },
      onSort: (field, order) => {
        if (order === 'asc') {
          setOrderBy('title_ASC');
        } else if (order === 'desc') {
          setOrderBy('title_DESC');
        } else {
          setOrderBy('');
        }
      },
    },
    {
      dataField: 'platform_type',
      text: 'Platform Type',
      sort: true,
      attrs: { title: 'Platform Type' },
      onSort: (field, order) => {
        if (order === 'asc') {
          setOrderBy('platformType_ASC');
        } else if (order === 'desc') {
          setOrderBy('platformType_DESC');
        } else {
          setOrderBy('');
        }
      },
    },
    {
      dataField: 'beddown',
      text: 'Bed-down',
      sort: true,
      attrs: { title: 'Bed-down' },
      onSort: (field, order) => {
        if (order === 'asc') {
          setOrderBy('beddown_ASC');
        } else if (order === 'desc') {
          setOrderBy('beddown_DESC');
        } else {
          setOrderBy('');
        }
      },
    },
    {
      dataField: '',

      text: 'Action',
      isDummyField: true,
      formatter: (cell, row, rowIndex) => (
        <StylishNewTableAction
          name="Participant Platforms"
          cell={cell}
          row={row}
          rowIndex={rowIndex}
          onDelete={onDeletePlatform}
          onEdit={OnEdit}
        />
      ),
      attrs: { title: 'Action' },
      headerStyle: {
        width: '100px',
      },
      headerAlign: 'center',
      align: 'center',
    },
  ];

  const onDeletePlatform = (row) => {
    setDeletePlatform(row.id);
  };

  const fetchAllPlatform = async () => {
    dispatch(startLoading());
    try {
      const platform = await Network.get(API.fetchAllPlatform, {
        workspaceId: selectedWorkspace,
        participantId: props.participantId,
        limit: perPage,
        page: page,
        order_by: orderBy,
      });
      setViewPlatform(
        platform.data.response.dataset.map((data) => ({
          id: data.ppId,
          title: data.title,
          platform_type: data.platformType,
          beddown: data.beddown,
          ppId: data.id,
        }))
      );
      setTotal(platform.data.response.total);
    } catch (error) {
      toast.error(error.response.data.response.status.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };

  useEffect(() => {
    fetchAllPlatform();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reduxUser, perPage, page, orderBy]);

  const onDeleteConfirm = async () => {
    dispatch(startLoading());
    try {
      await Network.post(API.deletePlanPlatform, {
        id: deletePlatform,
      });
      setDeletePlatform(null);
      fetchAllPlatform();
      toast.success('Platform deleted successfully', toastConfig);
    } catch (error) {
      toast.error(error?.response?.data?.response?.status?.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };

  const submitAndExit = () => {
    try {
      navigate(`${PreparePath}/dashboard/plan/prepare/participants`, {
        replace: true,
      });
    } catch (error) {
      toast.error(error.response.data.response.status.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };

  const OnEdit = (data) => {
    setDialogType('Edit');
    setUpdateDialogData(data);
    setshowcreateParticipant(true);
  };

  const onAddParticipants = () => {
    setDialogType('Add');
    fetchAllPlatform();
    setshowcreateParticipant(true);
  };

  const onCloseModal = (e) => {
    setshowcreateParticipant(false);
    if (e?.id) {
      fetchAllPlatform();
    }
  };

  const handlePageClick = (selectedPage) => {
    setPage(selectedPage);
  };

  return (
    <>
      <div className="d-flex align-items-center mb-4">
        <h4 className="mb-0">Participant Platforms</h4>
        <StylishHelp
          classes={'ms-auto sml flex-shrink-0'}
          title={HelpParticipantPlatforms.title}
          content={HelpParticipantPlatforms.content}
          link={selfHostedKnowledgeBase || HelpParticipantPlatforms.link}
        />
      </div>
      <div className="form-block">
        <StylishNewButton
          className="button button--primary mb-3 w-100 w-md-auto"
          onClick={onAddParticipants}
        >
          Add Platforms
        </StylishNewButton>
        <div className="row mb-2">
          <div className="col-md-12">
            <div className="d-flex align-items-center">
              <p className="m-0 me-3 text-nowrap weight-600">
                Participant Platforms: {viewPlatform?.length}
              </p>
              <div className="ms-auto">
                <PaginationDropdown
                  setPage={setPage}
                  setPerPage={setPerPage}
                  options={[
                    { value: 5, label: '5 per page' },
                    { value: 15, label: '15 per page' },
                    { value: 30, label: '30 per page' },
                    { value: 60, label: '60 per page' },
                    { value: 120, label: '120 per page' },
                  ]}
                />
              </div>
            </div>
          </div>
        </div>
        <StylishNewTable
          keyField={'id'}
          columns={columns}
          rows={viewPlatform}
          page={page}
          total={total}
          perPage={perPage}
          onPageChange={handlePageClick}
          pagination={true}
        />
        {showcreateParticipant && (
          <CreateParticipantPlatform
            show={showcreateParticipant}
            onClose={onCloseModal}
            dialogType={dialogType}
            updateDialogData={dialogType === 'Add' ? {} : updateDialogData}
            participantId={props.participantId}
          />
        )}
        {deletePlatform && (
          <ConfirmModal
            show={deletePlatform}
            onClose={() => setDeletePlatform(null)}
            heading={'Delete Platform'}
            text={'Are you sure you want to do this?'}
            onConfirm={onDeleteConfirm}
          />
        )}
      </div>
      <div className="col-md-12 mt-4">
        <div className="button-group d-block d-md-flex">
          <StylishNewButton
            className="button button--primary button--reverse w-100 w-md-auto"
            type="submit"
            onClick={() => props.onPrevClick(0)}
          >
            Previous
          </StylishNewButton>
          <StylishNewButton
            className="button button--tertiary button--reverse ms-auto mt-3 mt-md-0 w-100 w-md-auto"
            onClick={submitAndExit}
          >
            Save &amp; Exit
          </StylishNewButton>
          <StylishNewButton
            className="button button--primary  w-100 w-md-auto mt-3 mt-md-0 ms-0 ms-md-3"
            onClick={() => props.onNextClick(2)}
          >
            Next
          </StylishNewButton>
        </div>
      </div>
    </>
  );
};
