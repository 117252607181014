import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { PreparePath } from 'utils/sharedUtils/sharedConst';
import { TimelineEventDetails } from './TimelineEventDetails/TimelineEventDetails';
import { MissionSystems } from './MissionSystems/MissionSystems';
import { TestObjectives } from './TestObjectives/TestObjectives';
import { Review } from './Review/Review';
import IconTick from 'assets/images/icon__tick-plain.svg';
import { SharedIcon } from 'components/SharedIcon/SharedIcon';

export const TimeLineStepFormDashboard = () => {
  const navigate = useNavigate();
  const { type: workspaceType } = useSelector(
    (state) => state?.prepare?.workspace
  );

  const [searchParams, _] = useSearchParams();
  const params = useParams();
  const [step, setStep] = useState(0);
  const [timelineName, setTimelineName] = useState(null);

  useEffect(() => {
    const page = searchParams.get('page');
    if (!!params.id) {
      if (page && page > 0 && page < 5) {
        setStep(Number(page) - 1);
      } else {
        setStep(0);
      }
    }
  }, [params?.id, searchParams]);

  useEffect(() => {
    if (workspaceType && workspaceType !== 'T&E')
      navigate(`${PreparePath}/dashboard/plan`);
  }, [workspaceType]);

  const steps = [
    {
      label: 'Timeline Event Details',
      isValid: true,
      component: <TimelineEventDetails setTimelineName={setTimelineName} />,
      className: 'step-item col-6 col-md-3 mb-3 mb-md-0',
    },
    {
      label: 'Mission Systems',
      isValid: false,
      component: <MissionSystems />,
      className: 'step-item col-6 col-md-3 mb-3 mb-md-0',
    },
    {
      label: 'Test Objectives',
      isValid: false,
      component: <TestObjectives />,
      className: 'step-item col-6 col-md-3',
    },
    {
      label: 'Review',
      isValid: false,
      component: <Review setTimelineName={setTimelineName} />,
      className: 'step-item col-6 col-md-3',
    },
  ];

  return (
    <>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <StylishNewButton
              onClick={() =>
                navigate(`${PreparePath}/dashboard/plan/missions/timeline`)
              }
            >
              <SharedIcon iconName="keyboard_arrow_left" classes="me-2" />
              Back
            </StylishNewButton>
          </li>
          <li className="breadcrumb-item">
            <Link to={`${PreparePath}/dashboard/plan/missions/timeline`}>
              Mission Timeline Events
            </Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            {timelineName || 'Timeline'}
          </li>
        </ol>
      </nav>
      <div className="steps my-4 my-md-5">
        <div className="row">
          <div
            className={
              steps[0].className +
              (step === 0 ? ' step-current' : ' step-completed')
            }
          >
            <span>
              <span className="step-marker">1</span>
              <span className="step-title">Timeline Event Details</span>
            </span>
          </div>
          <div
            className={
              steps[1].className +
              (step === 1 ? ' step-current' : step > 1 ? ' step-completed' : '')
            }
          >
            <span>
              <span className="step-marker">2</span>
              <span className="step-title">Mission Systems</span>
            </span>
          </div>
          <div
            className={
              steps[2].className +
              (step === 2 ? ' step-current' : step > 2 ? ' step-completed' : '')
            }
          >
            <span>
              <span className="step-marker">3</span>
              <span className="step-title">Test Objectives</span>
            </span>
          </div>
          <div
            className={
              steps[3].className +
              (step === 3 ? ' step-current' : step > 3 ? ' step-completed' : '')
            }
          >
            <span>
              <span className="step-marker">
                <img src={IconTick} alt="" />
              </span>
              <span className="step-title">Review</span>
            </span>
          </div>
        </div>
      </div>
      {steps[step].component}
    </>
  );
};
