import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { inputStringLength, toastConfig } from 'assets/data/config';
import IconChooseMedia from 'assets/images/icon__choosemedia.svg';
import { useForm, Controller } from 'react-hook-form';
import StylishNewInput from 'components/DesignSystems/New/StylishNewInput';
import { useDispatch, useSelector } from 'react-redux';
import Network from 'PREPAREsrc/service/Network';
import API from 'PREPAREsrc/service/api';
import { startLoading, endLoading } from 'reducers/loading/loading.action';
import { toast } from 'react-toastify';
import { PreparePath } from 'utils/sharedUtils/sharedConst';
import { useNavigate } from 'react-router-dom';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';

export default function QuickCreatePersonaDialog({
  onClose,
  show,
  onSaveCloseModal,
}) {
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm();

  const { selectedWorkspace } = useSelector((state) => state.prepare.workspace);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const onSubmit = async (data) => {
    dispatch(startLoading());
    try {
      Network.post(API.createMediaPersona, {
        workspaceId: selectedWorkspace,
        name: data?.name,
        role: data?.role,
      })
        .then((resp) => {
          onClose(resp);
          onSaveCloseModal(resp);
          toast.success('Persona created successfully', toastConfig);
        })

        .catch(console.log)
        .finally(() => {
          dispatch(endLoading());
        });
    } catch (error) {
      console.log('error', error);
      toast.error(error?.response?.data?.response?.status?.msg, toastConfig);
    }
  };

  return (
    <Modal
      show={show}
      onHide={onClose}
      centered
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title>Quick Create Persona</Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <div className="mb-3">
            <label className="form-label">Name</label>
            <Controller
              control={control}
              name={'name'}
              rules={{
                required: true,
              }}
              render={({ field: { onChange, value } }) => (
                <StylishNewInput
                  onChange={(e) => {
                    onChange(e);
                  }}
                  value={value || ''}
                  type="text"
                  maxLength={inputStringLength}
                  className="form-control"
                  placeholder="Name"
                />
              )}
            />
            {errors?.name && (
              <span className="form-text form-error">
                This is a required field
              </span>
            )}

            {/* <input type="text" className="form-control" /> */}
          </div>

          <div>
            <label className="form-label">Role</label>
            <Controller
              control={control}
              name={'role'}
              rules={{
                required: true,
              }}
              render={({ field: { onChange, value } }) => (
                <StylishNewInput
                  onChange={(e) => {
                    onChange(e);
                  }}
                  value={value || ''}
                  type="text"
                  maxLength={inputStringLength}
                  className="form-control"
                  placeholder="Role"
                />
              )}
            />
            {errors?.role && (
              <span className="form-text form-error">
                This is a required field
              </span>
            )}

            {/* <input type="text" className="form-control" /> */}
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div className="button-group">
            <StylishNewButton
              className="button--secondary button--reverse"
              onClick={onClose}
            >
              Cancel
            </StylishNewButton>
            <StylishNewButton className="button--primary" type="submit">
              Save
            </StylishNewButton>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
