import React from 'react';
import { Modal } from 'react-bootstrap';
import API from '../../../../../service/api';
import Network from '../../../../../service/Network';
import { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { updateFundingSourceinParticipant } from '../../../../../store/Participants/participant.action';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import StylishNewSelect from 'components/DesignSystems/New/StylishNewSelect';
import StylishNewInput from 'components/DesignSystems/New/StylishNewInput';
import { inputStringLength, toastConfig } from 'assets/data/config';
import { endLoading, startLoading } from "../../../../../../reducers/loading/loading.action";

export default function CreateFundingSources({
  onClose,
  show,
  dialogType,
  updateDialogData,
  participantId,
  exerciseId,
}) {
  const [allFundingSource, setAllFundingSource] = useState([]);
  const [amount, setAmount] = useState(0);
  const [availableValue, setAvailableValue] = useState(0);
  const [fundings, setFundings] = useState({});
  const [allFunding, setAllFunding] = useState([]);

  const { selectedWorkspace } = useSelector((state) => state.prepare.workspace);

  const dispatch = useDispatch();

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    clearErrors,
  } = useForm();

  const fetchAllParticipantFunding = async () => {
    dispatch(startLoading());
    try {
      const funding =
        exerciseId &&
        (await Network.get(API.fetchAllFundingSources, {
          workspaceId: selectedWorkspace,
          planEventId: exerciseId,
        }));

      setAllFundingSource(
        funding.data.response.dataset.map((e) => {
          return {
            value: e.id,
            label: `${e.primarysource} - ${e.subsource}`,
          };
        })
      );

      if (updateDialogData?.id) {
        setValue('funding', {
          value: updateDialogData?.id,
          label: updateDialogData?.primarysource,
        });
        setFundings({
          value: updateDialogData?.id,
          label: updateDialogData?.primarysource,
        });
        setAmount(updateDialogData?.ppfs_amount);
        setValue('amount', updateDialogData?.ppfs_amount);
      }
      setAllFunding(funding?.data?.response?.dataset);
    } catch (error) {
      toast.error(error?.response?.data?.response?.status?.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };
  useEffect(() => {
    allFunding.map((e) => {
      e.id === fundings.value
        ? setAvailableValue(e.amount - Number(e.total_availed))
        : '';
    });
  }, [fundings]);

  useEffect(() => {
    fetchAllParticipantFunding();
  }, []);

  const onSubmit = async (data) => {
    dispatch(startLoading());
    try {
      await Network.post(
        dialogType === 'Add' ? API.addFundingParticipant : API.updateFunding,
        {
          workspaceId: selectedWorkspace,
          participantId: participantId,
          amount: data.amount,
          fundingSourceId: data.funding.value,
          id: updateDialogData?.ppfsId,
        }
      );

      dispatch(
        updateFundingSourceinParticipant(
          selectedWorkspace,
          participantId,
          data.amount,
          data.funding.value
        )
      );

      onClose(true);
    } catch (error) {
      toast.error(error.response.data.response.status.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };
  useEffect(() => {
    const listener = (event) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        event.preventDefault();
        handleSubmit(onSubmit)();
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, []);
  return (
    <div>
      <Modal
        show={show}
        onHide={onClose}
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton closeVariant="white">
          <Modal.Title>{dialogType} Funding Allocations </Modal.Title>
        </Modal.Header>
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <Modal.Body>
            <div className="mb-3">
              <label className="form-label">Funding Allocations</label>
              <Controller
                control={control}
                name="funding"
                rules={{ required: true }}
                render={({ field: { value } }) => (
                  <StylishNewSelect
                    options={allFundingSource}
                    value={value}
                    onChange={(e) => {
                      setFundings(e);
                      setValue('funding', e);
                      clearErrors('funding');
                    }}
                    placeholder={'Select funding'}
                    isClearable={false}
                    isSearchable={true}
                    isMulti={false}
                    isDisabled={false}
                  />
                )}
              />
              {errors.funding && (
                <span className="form-text form-error">
                  This is a required field
                </span>
              )}
            </div>
            {fundings.value !== undefined ? (
              <label className="form-label mb-3">
                Remaining Funding: $ {availableValue?.toLocaleString()}
              </label>
            ) : (
              <></>
            )}
            <div className="mb-0">
              <label className="form-label">Amount</label>
              <Controller
                control={control}
                name={'amount'}
                rules={{
                  required: true,
                  valueAsNumber: true,
                  max: availableValue + (amount || 0),
                  validate: (value) => {
                    const numericValue = parseFloat(value);
                    return (
                      numericValue && /^[0-9]+(\.[0-9]{1,2})?$/.test(value)
                    );
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <StylishNewInput
                    onChange={(e) => {
                      onChange(e);
                    }}
                    value={value}
                    type="number"
                    name="amount"
                    defaultValue={
                      dialogType === 'Edit' ? updateDialogData?.name : ''
                    }
                    maxLength={inputStringLength}
                    className="form-control"
                    step="0.01"
                    placeholder="$1000"
                  />
                )}
              />
              {errors?.amount && (
                <span className="form-text form-error">
                  {errors?.amount?.type === 'validate' &&
                    'Please enter a valid amount (up to 2 decimal places).'}
                  {errors?.amount?.type === 'max' &&
                    'Participant funding cannot exceed exercise funding'}
                  {errors?.amount?.type === 'required' &&
                    'This is a required field'}
                </span>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="button-group">
              <StylishNewButton
                className="button button--secondary button--reverse"
                onClick={onClose}
              >
                Close
              </StylishNewButton>
              <StylishNewButton
                className="button button--primary"
                type="submit"
              >
                Save
              </StylishNewButton>
            </div>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
}
