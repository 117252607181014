import Network from 'PREPAREsrc/service/Network';
import API from 'PREPAREsrc/service/api';
import { inputStringLength } from 'assets/data/config';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import StylishNewInput from 'components/DesignSystems/New/StylishNewInput';
import { Modal } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { endLoading, startLoading } from 'reducers/loading/loading.action';
import { TechnicalDisciplineContext } from './TRRList';
import { useContext } from 'react';

export const CreateTechnicalDisciplinesDialog = ({ show, onClose }) => {
  const { technicalDisciplines, setTechnicalDisciplines } = useContext(TechnicalDisciplineContext);

  const reduxDispatch = useDispatch();
  const selectedWorkspace = useSelector((state) => state.prepare.workspace.selectedWorkspace);
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    reduxDispatch(startLoading());
    try {
      const resp = await Network.post(API.createTd, {
        workspaceId: selectedWorkspace,
        discipline_name: data.name,
      });
      const newTD = {
        label: data.name,
        value: resp.data.response.dataset.id,
      };
      setTechnicalDisciplines([...technicalDisciplines, newTD])
      onClose({ label: data.name, value: resp.data.response.dataset.id })
    } catch (error) {
      reduxDispatch(endLoading());
    }

  };

  return (
    <Modal
      show={show}
      onHide={onClose}
      centered
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title>Add Technical Discipline</Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <label className="form-label">Name</label>
          <Controller
            control={control}
            name="name"
            rules={{
              required: true,
              maxLength: inputStringLength,
            }}
            render={({ field: { onChange, value } }) => (
              <StylishNewInput
                type="text"
                onChange={onChange}
                value={value || ''}
              />
            )}
          />
          {errors.name?.type === 'required' && (
            <span className="form-text form-error">This field is required</span>
          )}
          {errors.name?.type === 'maxLength' && (
            <span className="form-text form-error">
              Max {inputStringLength} character allowed
            </span>
          )}
        </Modal.Body>

        <Modal.Footer>
          <div className="button-group">
            <StylishNewButton
              className="button--secondary button--reverse"
              onClick={() => onClose()}
              type="button"
            >
              Cancel
            </StylishNewButton>

            <StylishNewButton className="button--primary" type="submit">
              Save
            </StylishNewButton>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
};
