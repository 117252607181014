import React, { useEffect, useRef, useState } from 'react';
import { inputStringLength } from 'assets/data/config';
import { StylishNewTextEditor } from 'components/DesignSystems/New/StylishNewTextEditor';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import StylishNewInput from 'components/DesignSystems/New/StylishNewInput';
import { sanitizeHtml } from "../../../../utils/sanitizeHtml";
import { SharedIcon } from 'components/SharedIcon/SharedIcon';

const EditPage = ({
  selectedPage,
  isEdit,
  changePageTitle,
  onChangeText,
  onDeletePage,
  saveData,
}) => {
  const [inital, setInitial] = useState(
    selectedPage?.text !== null ? selectedPage?.text : ''
  );
  const editorRef = useRef(null);

  /**
   * We need two ways of setting the editor content during page changes:
   *  1) Before the editor has initialized, we need to set the initial content of the editor. It does not hurt to set
   *      this initial value if an editor already exists because it is ignored when necessary.
   *  2) If an editor already exists, we should set the content of the editor directly.
   * */
  useEffect(() => {
    if (isEdit) {
      const newContent  = selectedPage?.text !== null ? selectedPage?.text : '';
      setInitial(newContent);
      if (editorRef.current !== null) {
        editorRef.current.setContent(newContent);
      }
    }
  }, [isEdit, selectedPage.pageId]);

  return (
    <>
      {selectedPage?.pageId !== '' ? (
        <div className="form-block h-xl-100">
          {isEdit ? (
            <div className="mb-4">
              <div className="form-label mb-3">Title</div>
              <StylishNewInput
                value={selectedPage?.pageTitle}
                onChange={changePageTitle}
                className="form-control "
                maxLength={inputStringLength}
              />
              {selectedPage?.pageTitle?.length === 0 && (
                <span className="form-text form-error">
                  This field is required{' '}
                </span>
              )}
            </div>
          ) : (
            <h3 className="weight-500 p-0">
              {selectedPage?.pageTitle}
              <hr className="dashed my-4" />
            </h3>
          )}

          {isEdit ? (
            <>
              <StylishNewTextEditor
                readOnly={false}
                placeholder="Enter text here"
                editorRef={editorRef}
                initialValue={inital}
                onChange={(content) => {
                  onChangeText(content);
                }}
              />
            </>
          ) : (
            <>
              {selectedPage?.text !== null && selectedPage?.text?.length > 0 ? (
                <div
                  dangerouslySetInnerHTML={{
                    __html:
                      selectedPage?.text !== null
                        ? sanitizeHtml(selectedPage?.text)
                        : '',
                  }}
                  className=""
                ></div>
              ) : (
                <></>
              )}
            </>
          )}

          <div className="d-flex align-items-center mt-4">
            {isEdit ? (
              <>
                <StylishNewButton
                  type="button"
                  customButton
                  className="button--icon lrg primary flex-shrink-0"
                  onClick={() => saveData(null)}
                >
                  <i className="fa fa-floppy-o" aria-hidden="true"></i>
                </StylishNewButton>

                <StylishNewButton
                  type="button"
                  customButton
                  className="button--icon lrg primary flex-shrink-0 ms-3"
                  onClick={onDeletePage}
                >
                  <SharedIcon iconName="delete" />
                </StylishNewButton>
              </>
            ) : null}
          </div>
        </div>
      ) : (
        <div className="form-block h-xl-100">
          {isEdit ? (
            <div className="mb-4"></div>
          ) : (
            <h3 className="weight-500 p-0">
              <hr className="dashed my-4" />
            </h3>
          )}
        </div>
      )}
    </>
  );
};

export default EditPage;
