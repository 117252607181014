import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import IconBack from 'assets/images/socials/icon__back.svg';
import Logo from 'assets/images/socials/twitter__logo.svg';
import IconSearch from 'assets/images/socials/youtube__icon__search.svg';
import IconComment from 'assets/images/socials/twitter__icon__comment.svg';
import IconRetweet from 'assets/images/socials/twitter__icon__retweet.svg';
import IconLike from 'assets/images/socials/twitter__icon__like.svg';
import IconShare from 'assets/images/socials/twitter__icon__share.svg';
import { Link, useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import IconEmptyExercise from 'assets/images/icon__exercise-details-empty.svg';
import { PreparePath } from 'utils/sharedUtils/sharedConst';
import { useSelector } from 'react-redux';

export const PostTwitter = ({ data }) => {
  const navigate = useNavigate();

  const { workspaceName } = useSelector((state) => {
    return state?.prepare?.workspace;
  });

  const params = useParams();

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <>
      <div className="twitter">
        <div className="twitter__header">
          <StylishNewButton
            customButton
            className={'button--icon'}
            onClick={handleBack}
          >
            <img src={IconBack} alt="" />
          </StylishNewButton>
          <span className="logo d-flex align-items-center">
            <img src={Logo} alt="" className="" />
            <h5 className="m-0 ms-3">Home</h5>
          </span>
          <div className="search">
            <img className="icon" src={IconSearch} alt="" />
            <input type="search" placeholder="Search..." />
          </div>
        </div>
        <div className="twitter__main">
          <div className="row justify-content-center">
            <div className="col-lg-10 col-xl-6">
              {sessionStorage.getItem('mediaModuleSocialMediaType') ===
              'sites' ? (
                <div className="bg-white p-4 p-md-5">
                  <div className="d-flex align-items-center mb-4">
                    <div className="avatar flex-shrink-0">
                      {data?.profileAvatar ? (
                        <img src={data?.profileAvatar} alt="" />
                      ) : null}
                    </div>
                    <div className="d-md-flex align-items-center ms-3 ms-md-4">
                      {data?.profileName ? (
                        <h5 className="m-0">{data?.profileName}</h5>
                      ) : null}
                      {data?.profileUsername ? (
                        <p className="m-0 ms-md-4">@{data?.profileUsername}</p>
                      ) : null}
                    </div>
                  </div>
                  {data?.post ? <p>{data?.post}</p> : null}
                  {data?.mediaFile ? (
                    <div className="d-flex mb-3">
                      <img
                        src={data?.mediaFile}
                        alt=""
                        style={{ width: '200px', height: 'auto' }}
                      />
                    </div>
                  ) : null}
                  {data?.postPublishTime ? (
                    <p>
                      {moment(data?.postPublishTime).format(
                        'HH:mm - DD/MM/YYYY'
                      )}
                    </p>
                  ) : null}
                  <div className="mt-4 mt-md-5">
                    <ul className="nav">
                      <li className="d-flex align-items-center cursor-pointer">
                        <img src={IconComment} className="me-3" alt="" />
                        {data?.comments?.length || '0'}
                      </li>
                      <li className="d-flex align-items-center cursor-pointer ms-4 ms-md-5">
                        <img src={IconRetweet} className="me-3" alt="" />
                        {data?.shares || '0'}
                      </li>
                      <li className="d-flex align-items-center cursor-pointer ms-4 ms-md-5">
                        <img src={IconLike} className="me-3" alt="" />
                        {data?.likes || '0'}
                      </li>
                      <li className="d-flex align-items-center cursor-pointer ms-4 ms-md-5">
                        <img src={IconShare} alt="" />
                      </li>
                    </ul>
                  </div>
                  {data?.comments && !!data?.comments.length ? (
                    <div className="mt-5 ms-md-5">
                      {data?.comments.map((i, idx) =>
                        i?.status === 'drafted' ? null : (
                          <div key={i?.id}>
                            {idx !== 0 ? (
                              <hr className="dashed thin my-4" />
                            ) : null}
                            <div className="d-flex align-items-start">
                              <div className="avatar flex-shrink-0">
                                {i?.profileAvatar ? (
                                  <img src={i?.profileAvatar} alt="" />
                                ) : null}
                              </div>
                              <div className="ms-3">
                                {i?.profileName ? (
                                  <h6 className="mb-2">{i?.profileName}</h6>
                                ) : null}
                                {i?.comment ? (
                                  <p className="m-0">{i?.comment}</p>
                                ) : null}
                                {i?.commentImg ? (
                                  <div className="d-flex mt-3">
                                    <img
                                      src={i?.commentImg}
                                      alt=""
                                      style={{ width: '100px', height: 'auto' }}
                                    />
                                  </div>
                                ) : null}
                                <p className="m-0 mt-3 weight-500 d-flex align-items-md-center flex-column flex-md-row txt--sml">
                                  {i?.publishTime ? (
                                    <>{moment(i?.publishTime).fromNow()}</>
                                  ) : null}
                                  <span className="mt-2 mt-md-0 ms-md-4">
                                    {i?.likes || '0'} Likes
                                  </span>
                                  <span className="mt-2 mt-md-0 ms-md-4">
                                    Reply
                                  </span>
                                </p>
                              </div>
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  ) : null}
                </div>
              ) : null}
              {sessionStorage.getItem('mediaModuleSocialMediaType') ===
              'profiles' ? (
                <>
                  {!params?.postId ? (
                    <>
                      {data?.profiledata?.posts &&
                      !!data?.profiledata?.posts?.length ? (
                        <>
                          {data?.profiledata?.posts.map((i, idx) => (
                            <div key={i.id}>
                              {idx !== 0 ? (
                                <hr className="dashed my-5" />
                              ) : null}
                              <div className="bg-white p-4 p-md-5 position-relative">
                                <Link
                                  className="stretched-link"
                                  to={`${PreparePath}/dashboard/media/profiles/${
                                    workspaceName &&
                                    workspaceName.charAt(0).toUpperCase()
                                  }/${data?.sitedata?.slug}/${
                                    data?.profiledata?.username
                                  }/${i?.id}`}
                                ></Link>
                                <div className="d-flex align-items-center mb-4">
                                  <div className="avatar flex-shrink-0">
                                    {data?.profiledata?.avatar ? (
                                      <img
                                        src={data?.profiledata?.avatar}
                                        alt=""
                                      />
                                    ) : null}
                                  </div>
                                  <div className="d-md-flex align-items-center ms-3 ms-md-4">
                                    {data?.profiledata?.profileName ? (
                                      <h5 className="m-0">
                                        {data?.profiledata?.profileName}
                                      </h5>
                                    ) : null}
                                    {data?.profiledata?.username ? (
                                      <p className="m-0 ms-md-4">
                                        @{data?.profiledata?.username}
                                      </p>
                                    ) : null}
                                  </div>
                                </div>
                                {i?.post ? <p>{i?.post}</p> : null}
                                {i?.mediaFile ? (
                                  <div className="d-flex mb-3">
                                    <img
                                      src={i?.mediaFile}
                                      alt=""
                                      style={{ width: '200px', height: 'auto' }}
                                    />
                                  </div>
                                ) : null}
                                {i?.publishTime ? (
                                  <p>
                                    {moment(i?.publishTime).format(
                                      'HH:mm - DD/MM/YYYY'
                                    )}
                                  </p>
                                ) : null}
                                <div className="mt-4 mt-md-5">
                                  <ul className="nav">
                                    <li className="d-flex align-items-center cursor-pointer">
                                      <img
                                        src={IconComment}
                                        className="me-3"
                                        alt=""
                                      />
                                      {i?.comments?.length || '0'}
                                    </li>
                                    <li className="d-flex align-items-center cursor-pointer ms-4 ms-md-5">
                                      <img
                                        src={IconRetweet}
                                        className="me-3"
                                        alt=""
                                      />
                                      {i?.shares || '0'}
                                    </li>
                                    <li className="d-flex align-items-center cursor-pointer ms-4 ms-md-5">
                                      <img
                                        src={IconLike}
                                        className="me-3"
                                        alt=""
                                      />
                                      {i?.likes || '0'}
                                    </li>
                                    <li className="d-flex align-items-center cursor-pointer ms-4 ms-md-5">
                                      <img src={IconShare} alt="" />
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          ))}
                        </>
                      ) : (
                        <div className="text-center p-5">
                          <div className="d-flex justify-content-center mb-4">
                            <img
                              src={IconEmptyExercise}
                              alt=""
                              style={{ opacity: '0.5' }}
                            />
                          </div>
                          <p className="weight-600 mb-4">No Post Found!</p>
                        </div>
                      )}
                    </>
                  ) : (
                    <>
                      <div className="bg-white p-4 p-md-5">
                        <div className="d-flex align-items-center mb-4">
                          <div className="avatar flex-shrink-0">
                            {data?.profileAvatar ? (
                              <img src={data?.profileAvatar} alt="" />
                            ) : null}
                          </div>
                          <div className="d-md-flex align-items-center ms-3 ms-md-4">
                            {data?.profileName ? (
                              <h5 className="m-0">{data?.profileName}</h5>
                            ) : null}
                            {data?.profileUsername ? (
                              <p className="m-0 ms-md-4">
                                @{data?.profileUsername}
                              </p>
                            ) : null}
                          </div>
                        </div>
                        {data?.post ? <p>{data?.post}</p> : null}
                        {data?.mediaFile ? (
                          <div className="d-flex mb-3">
                            <img
                              src={data?.mediaFile}
                              alt=""
                              style={{ width: '200px', height: 'auto' }}
                            />
                          </div>
                        ) : null}
                        {data?.postPublishTime ? (
                          <p>
                            {moment(data?.postPublishTime).format(
                              'HH:mm - DD/MM/YYYY'
                            )}
                          </p>
                        ) : null}
                        <div className="mt-4 mt-md-5">
                          <ul className="nav">
                            <li className="d-flex align-items-center cursor-pointer">
                              <img src={IconComment} className="me-3" alt="" />
                              {data?.comments?.length || '0'}
                            </li>
                            <li className="d-flex align-items-center cursor-pointer ms-4 ms-md-5">
                              <img src={IconRetweet} className="me-3" alt="" />
                              {data?.shares || '0'}
                            </li>
                            <li className="d-flex align-items-center cursor-pointer ms-4 ms-md-5">
                              <img src={IconLike} className="me-3" alt="" />
                              {data?.likes || '0'}
                            </li>
                            <li className="d-flex align-items-center cursor-pointer ms-4 ms-md-5">
                              <img src={IconShare} alt="" />
                            </li>
                          </ul>
                        </div>
                        {data?.comments && !!data?.comments.length ? (
                          <div className="mt-5 ms-md-5">
                            {data?.comments.map((i, idx) => (
                              <div key={i?.id}>
                                {idx !== 0 ? (
                                  <hr className="dashed thin my-4" />
                                ) : null}
                                <div className="d-flex align-items-start">
                                  <div className="avatar flex-shrink-0">
                                    {i?.profileAvatar ? (
                                      <img src={i?.profileAvatar} alt="" />
                                    ) : null}
                                  </div>
                                  <div className="ms-3">
                                    {i?.profileName ? (
                                      <h6 className="mb-2">{i?.profileName}</h6>
                                    ) : null}
                                    {i?.comment ? (
                                      <p className="m-0">{i?.comment}</p>
                                    ) : null}
                                    {i?.commentImg ? (
                                      <div className="d-flex mt-3">
                                        <img
                                          src={i?.commentImg}
                                          alt=""
                                          style={{
                                            width: '100px',
                                            height: 'auto',
                                          }}
                                        />
                                      </div>
                                    ) : null}
                                    <p className="m-0 mt-3 weight-500 d-flex align-items-md-center flex-column flex-md-row txt--sml">
                                      {i?.publishTime ? (
                                        <>{moment(i?.publishTime).fromNow()}</>
                                      ) : null}
                                      <span className="mt-2 mt-md-0 ms-md-4">
                                        {i?.likes || '0'} Likes
                                      </span>
                                      <span className="mt-2 mt-md-0 ms-md-4">
                                        Reply
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        ) : null}
                      </div>
                    </>
                  )}
                </>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
