import React from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { Modal } from 'react-bootstrap';
import { inputStringLength, toastConfig } from 'assets/data/config';
import API from '../../../service/api';
import Network from '../../../service/Network';
import { toast } from 'react-toastify';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import StylishNewInput from 'components/DesignSystems/New/StylishNewInput';
import { endLoading, startLoading } from "../../../../reducers/loading/loading.action";

export default function RenameFileDialog({
  onClose,
  show,
  dialogType,
  updateDialogData,
}) {
  const dispatch = useDispatch();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: 'onChange' });

  const renameFile = async (data) => {
    dispatch(startLoading());
    try {
      const response = await Network.post(API.updateNoteFile, {
        file_name:
          data.file_name + '.' + updateDialogData?.file_name.split('.')[1],
        fileId: updateDialogData.id,
        workspaceId: updateDialogData.workspaceId,
      });
      onClose(response.data.response.dataset[0]);
      toast.success('File Renamed successfully', toastConfig);
    } catch (error) {
      toast.error(error.response.data.response.status.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };

  useEffect(() => {
    const listener = (event) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        event.preventDefault();
        handleSubmit(renameFile)();
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, []);

  return (
    <Modal
      show={show}
      onHide={onClose}
      centered
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title>{dialogType}Rename File</Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit(renameFile)}>
        <Modal.Body>
          <label className="form-label">Display Name</label>
          <Controller
            control={control}
            defaultValue={updateDialogData?.file_name.substring(
              0,
              updateDialogData?.file_name.indexOf('.')
            )}
            name="file_name"
            rules={{ required: true, maxLength: inputStringLength }}
            render={({ field: { onChange, value } }) => (
              <StylishNewInput
                type="text"
                onChange={onChange}
                value={value}
                placeholder="Filename"
              />
            )}
          />
          {errors?.file_name?.type === 'required' && (
            <span className="form-text form-error">
              This is a required field
            </span>
          )}
          {errors?.file_name?.type === 'maxLength' && (
            <span className="form-text form-error">
              Max {inputStringLength} characters allowed
            </span>
          )}
        </Modal.Body>

        <Modal.Footer>
          <div className="button-group">
            <StylishNewButton
              className="button--secondary button--reverse"
              onClick={onClose}
              type="button"
            >
              Cancel
            </StylishNewButton>
            <StylishNewButton className="button--primary" type="submit">
              Rename
            </StylishNewButton>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
