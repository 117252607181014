import React, { useEffect, useState } from 'react';
import AddEditSubSourceDialog from './AddEditSubSourceDialog';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Network from '../../../service/Network';
import API from '../../../service/api';
import StylishNewTable from 'components/DesignSystems/New/StylishNewTable';
import { StylishNewSearchBar } from 'components/DesignSystems/New/StylishNewSearchBar';
import StylishNewTableAction from 'components/DesignSystems/New/StylishNewTableAction';
import { StylishConfirmDialog } from 'components/DesignSystems/New/StylishConfirmDialog';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import StylishHelp from 'components/DesignSystems/New/StylishHelp';
import { HelpSubSource, useSelfHostedKnowledgeBase } from 'assets/data/HelpContent';
import { toastConfig } from 'assets/data/config';
import { PaginationDropdown } from '../../../../components/DesignSystems/PaginationDropdown';
import {
  endLoading,
  startLoading,
} from '../../../../reducers/loading/loading.action';

export default function SubSource() {
  const [allSubSource, setAllSubSource] = useState([]);
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [perPage, setPerPage] = useState(15);
  const [total, setTotal] = useState(0);
  const [showSubSourceAddEditDialog, setShowSubSourceAddEditDialog] = useState(
    false
  );
  const [dialogType, setDialogType] = useState('Add');
  const [updateDialogData, setUpdateDialogData] = useState(null);
  const [deleteSubSourceId, setDeleteSubSourceId] = useState(null);
  const [orderBy, setOrderBy] = useState('');
  const [deleteSubSourceTitle, setDeleteSubSourceTitle] = useState('');

  const selfHostedKnowledgeBase = useSelfHostedKnowledgeBase()

  const dispatch = useDispatch();
  const workspaceId = useSelector(
    (state) => state.prepare.workspace.selectedWorkspace
  );
  const columns = [
    {
      dataField: 'title',
      text: 'Title',
      sort: true,
      headerAttrs: { title: 'Sort By:' },
      attrs: { title: 'Title' },
      onSort: (field, order) => {
        if (order === 'asc') {
          setOrderBy(0);
        } else if (order === 'desc') {
          setOrderBy(1);
        } else {
          setOrderBy('');
        }
      },
    },
    {
      dataField: '',
      text: 'Action',
      isDummyField: true,
      formatter: (cell, row, rowIndex) => (
        <StylishNewTableAction
          name="Sub Source"
          cell={cell}
          row={row}
          rowIndex={rowIndex}
          onDelete={onDeletegetAllSubSource}
          onEdit={onEditgetAllPositon}
        />
      ),
      headerStyle: {
        width: '100px',
      },
      headerAlign: 'center',
      align: 'center',
      attrs: { title: 'Action' },
    },
  ];

  const SubSourceList = async () => {
    dispatch(startLoading());
    try {
      const newSubSource = await Network.get(API.planSubSourceList, {
        limit: perPage,
        page: page,
        search: searchTerm,
        workspaceId: workspaceId,
        sort: orderBy,
      });

      setAllSubSource(newSubSource.data.response.dataset);
      setTotal(newSubSource?.data?.response?.count);
    } catch (error) {
      toast.error(error.response.data.response.status.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };
  useEffect(() => {
    SubSourceList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, perPage, searchTerm, workspaceId, orderBy]);

  const onAddgetAllExerciseType = () => {
    setDialogType('Add');
    setShowSubSourceAddEditDialog(true);
  };
  const onDeletegetAllSubSource = (row) => {
    setDeleteSubSourceId(row.id);
    setDeleteSubSourceTitle(row.title);
  };
  const onEditgetAllPositon = (row) => {
    setUpdateDialogData(row);
    setDialogType('Edit');
    setShowSubSourceAddEditDialog(true);
  };
  const OnAddOrEditgetAllExerciseType = (afterSave) => {
    setUpdateDialogData(null);
    setDialogType('New');
    setShowSubSourceAddEditDialog(false);
    if (afterSave?.id) {
      SubSourceList();
    }
  };
  const onDeleteConfirm = async () => {
    dispatch(startLoading());
    try {
      await Network.post(API.deletePlanSubSource, {
        sourceId: deleteSubSourceId,
      });
      setDeleteSubSourceId(null);
      SubSourceList();
      toast.success('Deleted  successfully', toastConfig);
    } catch (error) {
      toast.error(error.response.data.response.status.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };
  const onChangeSearchTerm = (term) => {
    setPage(1);
    setSearchTerm(term);
  };
  return (
    <>
      <div className="settings__exercise-type">
        <StylishNewButton
          className="button button--primary mb-4"
          onClick={onAddgetAllExerciseType}
        >
          Add Sub Source
        </StylishNewButton>
        <div className="form-block">
          <div className="row">
            <div className="col-md-12">
              <div className="d-flex flex-column flex-md-row align-items-md-center mb-4">
                <div className="d-flex align-items-center justify-content-between">
                  <p className="m-0 me-3 text-nowrap">
                    {total || 0} Sub Sources
                  </p>
                  <div className="">
                    <PaginationDropdown
                      setPage={setPage}
                      setPerPage={setPerPage}
                      options={[
                        { value: 15, label: '15 per page' },
                        { value: 30, label: '30 per page' },
                        { value: 60, label: '60 per page' },
                        { value: 100, label: '100 per page' },
                      ]}
                    />
                  </div>
                </div>
                <div className="ms-md-auto mt-3 mt-md-0 d-flex align-items-center">
                  <div className="flex-grow-1">
                    <StylishNewSearchBar
                      onChangeSearchTerm={onChangeSearchTerm}
                    />
                  </div>
                  <StylishHelp
                    classes={'ms-3 flex-shrink-0 sml'}
                    title={HelpSubSource.title}
                    content={HelpSubSource.content}
                    link={selfHostedKnowledgeBase || HelpSubSource.link}
                  />
                </div>
              </div>
            </div>
          </div>
          <StylishNewTable
            keyField={'id'}
            columns={columns}
            rows={allSubSource}
            onDelete={onDeletegetAllSubSource}
            onEdit={onEditgetAllPositon}
            pagination
            page={page}
            perPage={perPage}
            total={total}
            onPageChange={(page) => setPage(page)}
          />
        </div>
        {showSubSourceAddEditDialog && (
          <AddEditSubSourceDialog
            show={showSubSourceAddEditDialog}
            onClose={OnAddOrEditgetAllExerciseType}
            dialogType={dialogType}
            updateDialogData={updateDialogData}
          />
        )}
        {deleteSubSourceId && (
          <StylishConfirmDialog
            show={deleteSubSourceId}
            onClose={() => setDeleteSubSourceId(null)}
            dialogTitle={'Delete Sub Source'}
            dialogContentType={'html'}
            dialogContent={`Deleting <span class="weight-700 break-word">${deleteSubSourceTitle}</span> will remove all its occurance from this workspace.<span class="d-block pt-3">Are you sure you want to delete this Sub Source ?</span>`}
            onConfirm={onDeleteConfirm}
          />
        )}
      </div>
    </>
  );
}
