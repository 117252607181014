import { inputStringLength, textareaStringLength } from 'assets/data/config';
import StylishIconSelected from 'components/DesignSystems/New/StylishIconSelected/StylishIconSelected';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import StylishNewInput from 'components/DesignSystems/New/StylishNewInput';
import { StylishNewRadio } from 'components/DesignSystems/New/StylishNewRadio';
import StylishNewTextArea from 'components/DesignSystems/New/StylishNewTextArea';
import { useEffect, useState } from 'react';
import { Controller, useForm, useFieldArray } from 'react-hook-form';
import IconAdd from 'assets/images/icon__add.svg';
import IconDelete from 'assets/images/icon__delete.svg';
import { useDispatch, useSelector } from 'react-redux';
import Network from 'PREPAREsrc/service/Network';
import API from 'PREPAREsrc/service/api';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { AllIcon } from 'PREPAREsrc/pages/Settings/InjectMethod/Icon';
import { PreparePath } from 'utils/sharedUtils/sharedConst';
import { endLoading, startLoading } from 'reducers/loading/loading.action';

export const SystemDetails = () => {
  const dispatch = useDispatch();
  const { selectedWorkspace } = useSelector((state) => {
    return state?.prepare?.workspace;
  });
  const params = useParams();
  const navigate = useNavigate();
  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    getValues,
    trigger,
  } = useForm({
    mode: 'onChange',
    defaultValues: { components: [{ name: '', type: '' }] },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'components',
  });

  const [showComponent, setShowComponent] = useState(false);
  const [systemDetails, setSystemDetails] = useState();

  useEffect(() => {
    if (params.id) {
      getSystemData();
    }
  }, [params.id]);

  const getSystemData = async () => {
    dispatch(startLoading());
    try {
      const resp = await Network.get(API.getSystemDetails, { id: params.id });
      const systemData = resp.data.response.dataset[0];
      setSystemDetails(systemData);
      setValue('event_cell_type', systemData.event_cell_type);
      setValue('name', systemData.name);
      setValue('category', systemData.category);
      setValue('quantity', systemData.quantity);
      setValue(
        'icon',
        AllIcon.find((icon) => icon.name === systemData.icon)
      );
      setValue('provider', systemData.provider);
      setValue('command', systemData.command);
      setValue('service_branch', systemData.service_branch);
      setValue('capabilities_limitations', systemData.capabilities_limitations);
      setValue('data_link_connections', systemData.data_link_connections);
      //Components
      systemData?.components?.length > 0 && setShowComponent(true);
      setValue('components', systemData.components);
      dispatch(endLoading());
    } catch (e) {
      dispatch(endLoading());
    }
  };

  const onSaveAndExitClick = async () => {
    const valid = await trigger();
    if (valid) {
      const data = getValues();
      onSubmit(data, 'exit');
    }
  };
  const onSubmit = async (formData, type) => {
    try {
      dispatch(startLoading());

      const data = {
        ...formData,
        system_id: systemDetails?.id,
        icon: formData?.icon?.name,
        workspaceId: selectedWorkspace,
        components: formData.components.filter((comp) => comp.name !== ''),
      };
      const resp = await Network.post(API.createOrUpdateSystem, data);
      dispatch(endLoading());

      if (type === 'exit') {
        navigate(`${PreparePath}/dashboard/plan/systems`);
      } else {
        navigate(
          `${PreparePath}/dashboard/plan/systems/${resp.data.response.dataset[0]?.PlanSystemDetails?.id}?page=2`
        );
      }
    } catch (e) {
      dispatch(endLoading());
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <h4>System Details</h4>
        <div className="form-block">
          <div className={'mb-4'}>
            <h6>
              Assigned Event Cell
              <span aria-label="Required field" className="required">
                *
              </span>
            </h6>
            <Controller
              name="event_cell_type"
              control={control}
              defaultValue=""
              rules={{
                required: true,
              }}
              render={({ field: { onChange, value } }) => (
                <div className="form-radio-group">
                  <StylishNewRadio
                    name="cell"
                    value="red_cell"
                    onChange={onChange}
                    checked={value === 'red_cell'}
                    label={'Red Cell'}
                  />
                  <StylishNewRadio
                    name="cell"
                    value="blue_cell"
                    onChange={onChange}
                    checked={value === 'blue_cell'}
                    label={'Blue Cell'}
                  />
                </div>
              )}
            />
            {(errors?.event_cell_type?.type === 'required' && (
              <span className="form-text form-error">
                Please choose a cell to proceed
              </span>
            )) ||
              null}
          </div>
          <div className="row">
            <div className="col-md-3 mb-3">
              <label className="form-label">
                System Name
                <span aria-label="Required field" className="required">
                  *
                </span>
              </label>
              <Controller
                name="name"
                control={control}
                rules={{
                  required: true,
                  maxLength: inputStringLength,
                }}
                render={({ field: { onChange, value } }) => (
                  <StylishNewInput
                    type="text"
                    value={value || ''}
                    onChange={onChange}
                  />
                )}
              />
              {(errors?.name?.type === 'required' && (
                <span className="form-text form-error">
                  This is a required field
                </span>
              )) ||
                null}
              {(errors?.name?.type === 'maxLength' && (
                <span className="form-text form-error">
                  Only {inputStringLength} characters allowed.
                </span>
              )) ||
                null}
            </div>
            <div className="col-md-3 mb-3">
              <label className="form-label">
                Category
                <span aria-label="Required field" className="required">
                  *
                </span>
              </label>
              <Controller
                name="category"
                control={control}
                rules={{
                  required: true,
                  maxLength: inputStringLength,
                }}
                render={({ field: { onChange, value } }) => (
                  <StylishNewInput
                    type="text"
                    value={value || ''}
                    onChange={onChange}
                  />
                )}
              />
              {(errors?.category?.type === 'required' && (
                <span className="form-text form-error">
                  This is a required field
                </span>
              )) ||
                null}
              {(errors?.category?.type === 'maxLength' && (
                <span className="form-text form-error">
                  Only {inputStringLength} characters allowed.
                </span>
              )) ||
                null}
            </div>
            <div className="col-md-3 mb-3">
              <label className="form-label">
                Quantity
                <span aria-label="Required field" className="required">
                  *
                </span>
              </label>
              <Controller
                name="quantity"
                control={control}
                rules={{
                  required: true,
                  validate: (value) => {
                    return value >= 0;
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <StylishNewInput
                    type="number"
                    value={value || ''}
                    onChange={onChange}
                  />
                )}
              />
              {(errors?.quantity?.type === 'required' && (
                <span className="form-text form-error">
                  This is a required field
                </span>
              )) ||
                null}
              {(errors?.quantity?.type === 'validate' && (
                <span className="form-text form-error">
                  Only positive number allowed.
                </span>
              )) ||
                null}
            </div>
            <div className="col-md-3 mb-3">
              <label className="form-label d-block">Icon</label>
              <Controller
                control={control}
                name="icon"
                render={({ field: { onChange, value } }) => (
                  <StylishIconSelected
                    onIconChange={onChange}
                    selectedIcon={value}
                  />
                )}
              />
            </div>
            <div className="col-md-4 mb-3">
              <label className="form-label">
                Provider
                <span aria-label="Required field" className="required">
                  *
                </span>
              </label>
              <Controller
                name="provider"
                control={control}
                rules={{
                  required: true,
                  maxLength: inputStringLength,
                }}
                render={({ field: { onChange, value } }) => (
                  <StylishNewInput
                    type="text"
                    value={value || ''}
                    onChange={onChange}
                  />
                )}
              />
              {(errors?.provider?.type === 'required' && (
                <span className="form-text form-error">
                  This is a required field
                </span>
              )) ||
                null}
              {(errors?.provider?.type === 'maxLength' && (
                <span className="form-text form-error">
                  Only {inputStringLength} characters allowed.
                </span>
              )) ||
                null}
            </div>
            <div className="col-md-4 mb-3">
              <label className="form-label">Command</label>
              <Controller
                name="command"
                control={control}
                rules={{
                  maxLength: inputStringLength,
                }}
                render={({ field: { onChange, value } }) => (
                  <StylishNewInput
                    type="text"
                    value={value || ''}
                    onChange={onChange}
                  />
                )}
              />
              {(errors?.command?.type === 'maxLength' && (
                <span className="form-text form-error">
                  Only {inputStringLength} characters allowed.
                </span>
              )) ||
                null}
            </div>
            <div className="col-md-4 mb-3">
              <label className="form-label">Service Branch</label>
              <Controller
                name="service_branch"
                control={control}
                rules={{
                  maxLength: inputStringLength,
                }}
                render={({ field: { onChange, value } }) => (
                  <StylishNewInput
                    type="text"
                    value={value || ''}
                    onChange={onChange}
                  />
                )}
              />
              {(errors?.service_branch?.type === 'maxLength' && (
                <span className="form-text form-error">
                  Only {inputStringLength} characters allowed.
                </span>
              )) ||
                null}
            </div>
            <div className="col-md-12 mb-3">
              <label className="form-label">Capabilities and Limitations</label>
              <Controller
                name="capabilities_limitations"
                control={control}
                rules={{
                  maxLength: textareaStringLength,
                }}
                render={({ field: { onChange, value } }) => (
                  <StylishNewTextArea value={value || ''} onChange={onChange} />
                )}
              />
              {(errors?.capabilities_limitations?.type === 'maxLength' && (
                <span className="form-text form-error">
                  Only {textareaStringLength} characters allowed.
                </span>
              )) ||
                null}
            </div>
            <div className="col-md-12">
              <label className="form-label">Data Link Connection(s)</label>
              <Controller
                name="data_link_connections"
                control={control}
                rules={{
                  maxLength: textareaStringLength,
                }}
                render={({ field: { onChange, value } }) => (
                  <StylishNewTextArea value={value || ''} onChange={onChange} />
                )}
              />
              {(errors?.data_link_connections?.type === 'maxLength' && (
                <span className="form-text form-error">
                  Only {textareaStringLength} characters allowed.
                </span>
              )) ||
                null}
            </div>
          </div>
          <StylishNewButton
            type="button"
            className={'button--primary mt-4 w-100 w-md-auto'}
            onClick={() => {
              setShowComponent(true);
              setValue('components', [{ name: '', type: '' }]);
            }}
            disabled={showComponent}
          >
            Add Components
          </StylishNewButton>
          {(showComponent && (
            <>
              <hr className="my-4" />
              <h5>Components</h5>
              {fields?.map((item, index) => (
                <div className="row align-items-start" key={item?.id}>
                  <div className={`col-md-4 mb-3`}>
                    <label className="form-label">
                      Component Name
                      <span aria-label="Required field" className="required">
                        *
                      </span>
                    </label>
                    <Controller
                      name={`components.${index}.name`}
                      control={control}
                      rules={{
                        required: true,
                        maxLength: inputStringLength,
                      }}
                      render={({ field: { onChange, value } }) => (
                        <StylishNewInput
                          type="text"
                          value={value || ''}
                          onChange={onChange}
                        />
                      )}
                    />
                    {(errors?.components &&
                      !!errors?.components?.length &&
                      errors?.components?.[index]?.name?.type ===
                        'required' && (
                        <span className="form-text form-error">
                          This is a required field
                        </span>
                      )) ||
                      null}
                    {(errors?.components &&
                      !!errors?.components?.length &&
                      errors?.components?.[index]?.name?.type ===
                        'maxLength' && (
                        <span className="form-text form-error">
                          Only {inputStringLength} characters allowed.
                        </span>
                      )) ||
                      null}
                  </div>
                  <div className={`col-md-4 mb-3`}>
                    <label className="form-label">
                      Component Type
                      <span aria-label="Required field" className="required">
                        *
                      </span>
                    </label>
                    <Controller
                      name={`components.${index}.type`}
                      control={control}
                      rules={{
                        required: true,
                        maxLength: inputStringLength,
                      }}
                      render={({ field: { onChange, value } }) => (
                        <StylishNewInput
                          type="text"
                          value={value || ''}
                          onChange={onChange}
                        />
                      )}
                    />
                    {(errors?.components &&
                      !!errors?.components?.length &&
                      errors?.components?.[index]?.type?.type ===
                        'required' && (
                        <span className="form-text form-error">
                          This is a required field
                        </span>
                      )) ||
                      null}
                    {(errors?.components &&
                      !!errors?.components?.length &&
                      errors?.components?.[index]?.type?.type ===
                        'maxLength' && (
                        <span className="form-text form-error">
                          Only {inputStringLength} characters allowed.
                        </span>
                      )) ||
                      null}
                  </div>
                  <div className={`col-md-4 d-flex mb-3`}>
                    <div className="button-group ms-auto mt-md-4 pt-md-1">
                      {(index === fields?.length - 1 && (
                        <StylishNewButton
                          type="button"
                          customButton
                          className="button--icon primary lrg"
                          onClick={() => append({ name: '', type: '' })}
                        >
                          <img src={IconAdd} alt="" />
                        </StylishNewButton>
                      )) ||
                        null}

                      <StylishNewButton
                        type="button"
                        customButton
                        className="button--icon primary lrg"
                        onClick={() => {
                          if (fields.length === 1) {
                            remove(0);
                            append({ name: '', type: '' });
                            setShowComponent(false);
                          } else {
                            remove(index);
                          }
                        }}
                      >
                        <img src={IconDelete} alt="" />
                      </StylishNewButton>
                    </div>
                  </div>
                </div>
              ))}
            </>
          )) ||
            null}
        </div>
        <div className="button-group d-block d-md-flex mt-4">
          <StylishNewButton
            type="button"
            className={
              'button--tertiary button--reverse ms-auto w-100 w-md-auto'
            }
            onClick={onSaveAndExitClick}
          >
            Save & Exit
          </StylishNewButton>
          <StylishNewButton
            type="submit"
            className={
              'button--primary w-100 w-md-auto ms-0 ms-md-3 mt-2 mt-md-0'
            }
          >
            Next
          </StylishNewButton>
        </div>
      </form>
    </>
  );
};
