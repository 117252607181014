import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Network from '../../../../service/Network';
import API from '../../../../service/api';
import { setExerciseDetailsId } from '../../../../store/exercise/exercise.action';
import {
  Link,
  Outlet,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { toast } from 'react-toastify';
import moment from 'moment';
import ViewPlanMeetingDialog from './ViewPlanMeetingDialog';
import FullCalendar from '@fullcalendar/react'; // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin!
import timegrid from '@fullcalendar/timegrid'; // a plugin!
import listPlugin from '@fullcalendar/list'; // a plugin!
import interactionPlugin from '@fullcalendar/interaction'; // a plugin!
import IconEmptyExercise from 'assets/images/icon__exercise-details-empty.svg';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import {
  HelpExerciseMain,
  MiscellaneousHelpLink,
  useSelfHostedKnowledgeBase,
} from 'assets/data/HelpContent';
import CurrencyFormat from 'react-currency-format';
import { PreparePath } from 'utils/sharedUtils/sharedConst';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import StylishNewTable from 'components/DesignSystems/New/StylishNewTable';
import StylishHelp from 'components/DesignSystems/New/StylishHelp';
import { toastConfig } from 'assets/data/config';
import {
  endLoading,
  startLoading,
} from '../../../../../reducers/loading/loading.action';
import PageTitle from 'components/headers/PageTitle';
import { useNewUIFlag } from 'utils/featureFlags';
import { SharedIcon } from 'components/SharedIcon/SharedIcon';

const getRoutePath = (location, params) => {
  const { pathname } = location;


  if (!Object.keys(params).length) {
    return pathname;
  }

  let path = pathname;
  Object.entries(params).forEach(([paramName, paramValue]) => {
    if (paramValue) {
      path = path.replace(paramValue, `:${paramName}`);
    }
  });
  return path;
};

export default function ExerciseMain() {
  const selfHostedKnowledgeBase = useSelfHostedKnowledgeBase()
  
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const path = getRoutePath(location, params);
  const [pageCC, setPageCC] = useState(1);
  const [pageOCT, setPageOCT] = useState(1);
  const [pageFS, setPageFS] = useState(1);
  const page = 1;
  const [total, setTotal] = useState(0);
  const [totalFundingAmount, setTotalFundingAmount] = useState(0);
  const perPage = 5;
  const [pageExercise, setPageExercise] = useState(1);
  const [totalExercise, setTotalExercise] = useState(0);
  const perPageExercise = 10;
  const [pageAccTask, setPageAccTask] = useState(1);
  const [totalAccTask, setTotalAccTask] = useState(0);
  const perPageAccTask = 10;
  const [pageCommand, setPageCommand] = useState(1);
  const [totalCommand, setTotalCommand] = useState(0);
  const perPageCommand = 10;
  const searchTerm = '';
  const [planMeetings, setPlanMeetings] = useState([]);
  const [refinedPlanMeetings, setRefinedPlanMeetings] = useState([]);
  const [existingEvent, setExistingEvent] = useState('');
  const [exerciseData, setExerciseData] = useState('');
  const [objectives, setObjectives] = useState([]);
  const [fundingSources, setFundingSources] = useState([]);
  const [accreditedTasks, setAccreditedTasks] = useState([]);
  const [commandPriority, setCommandPriority] = useState([]);
  const [coreCapabilities, setCoreCapabilities] = useState([]);
  const [coreCapabilitiesTotal, setCoreCapabilitiesTotal] = useState([]);
  const [totalFunding, setTotalFunding] = useState(0);
  const [oganizationalCapability, setOganizationalCapability] = useState([]);
  const [key, setKey] = useState('guidance_objectives');
  const [
    addScheduleActivationDialogOpen,
    setAddScheduleActivationDialogOpen,
  ] = useState(false);
  const [selectedIncidentMember, setSelectedIncidentMember] = useState();
  const [orderBy, setOrderBy] = useState('');
  const [orderByFS, setOrderByFS] = useState('amount_ASC');
  const [orderByForHseep, setOrderByForHseep] = useState('');
  const [orderByCTP, setOrderByCTP] = useState('');

  const dispatch = useDispatch();

  const { selectedWorkspace, workspaceName } = useSelector(
    (state) => state.prepare.workspace
  );
  const reduxUser = useSelector((state) => state.prepare.user);
  const exerciseName = useSelector(
    (state) => state.prepare.exercise.exerciseDetailsName
  );
  const workspaceType = useSelector((state) => state.prepare.workspace.type);
  const exerciseId = useSelector(
    (state) => state.prepare.exercise.exerciseDetailsId
  );
  const newUI = useNewUIFlag();

  const useQuary = () => {
    return new URLSearchParams(location.search);
  };

  const quaryParams = useQuary();
  const tabkey = quaryParams.get('tabKey') || 'guidance_objectives';

  useEffect(() => {
    setKey(tabkey);
  }, [tabkey !== '']);

  const getAllPlanMeetings = async () => {
    dispatch(startLoading());
    try {
      Network.get(API.fetchAllMeeting, {
        search: searchTerm,
        workspaceId: selectedWorkspace,
        planEventId: exerciseId,
      }).then((resp) => {
        let allPlanMeetings = {};
        allPlanMeetings =
          resp.data.response.dataset.length &&
          resp.data.response.dataset.map((e) => {
            return {
              ...e,
              startDate: moment(e?.startDate).toISOString(),
              endDate: moment(e?.endDate).toISOString(),
            };
          });
        setPlanMeetings(allPlanMeetings);
      });
    } catch (error) {
      toast.error(error.response.data.response.status.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };
  const planDashhboard = async () => {
    dispatch(startLoading());
    if (selectedWorkspace) {
      try {
        const resp = await Network.get(API.planDashhboard, {
          workspaceId: selectedWorkspace,
          startDate: moment().clone().startOf('month').toISOString(),
          endDate: moment().toISOString(),
        });
        setPlanMeetings(resp.data.response.calendarData);
      } catch (error) {
        toast.error(error?.response?.data?.response?.status?.msg, toastConfig);
      } finally {
        dispatch(endLoading());
      }
    }
  };

  useEffect(() => {
    if (planMeetings) {
      const planCalen = planMeetings.map((e) => {
        const refinedPlanCalendar = {
          title: e.eventName,
          start: moment(e?.startDate).toISOString(),
          end: moment(e?.endDate).toISOString(),
          type: e.type,
          id: e.id,
          color: e?.color,
        };
        return refinedPlanCalendar;
      });
      setRefinedPlanMeetings(planCalen);
    } else {
      setRefinedPlanMeetings([]);
    }
  }, [planMeetings, reduxUser, exerciseData]);

  const editExistingEvent = (e) => {
    const incidentMember = {
      title: e._def.title,
      start: e.start,
      end: e.end,
    };
    setSelectedIncidentMember(incidentMember);
    setExistingEvent(e);
    setAddScheduleActivationDialogOpen(true);
  };

  useEffect(() => {
    exerciseId && planDashhboard();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, perPage, searchTerm, reduxUser, exerciseId]);

  const getExerciseDetails = async () => {
    dispatch(startLoading());

    if (selectedWorkspace) {
      try {
        Network.get(API.planExerciseTypeList, {
          workspaceId: selectedWorkspace,
        }).then((resp) => {
          if (resp.data.response.dataset) {
            let id = resp.data.response.dataset[0]?.id;
            dispatch(setExerciseDetailsId(id));
          }
          let allExercise = {};
          allExercise =
            resp.data.response.dataset.length &&
            resp.data.response.dataset.map((e) => {
              return {
                ...e,
                startDate: moment(e?.startDate).format('DD MMM YYYY'),
                endDate: moment(e?.endDate).format('DD MMM YYYY'),
              };
            });
          setExerciseData(allExercise);
        });
      } catch (error) {
        toast.error(error.response.data.response.status.msg, toastConfig);
      } finally {
        dispatch(endLoading());
      }
    }
  };
  const getAllObjectives = async () => {
    dispatch(startLoading());
    try {
      const obj = await Network.get(API.fetchAllObjectives, {
        limit: perPageExercise,
        page: pageExercise,
        search: searchTerm,
        workspaceId: selectedWorkspace,
        sort: orderBy,
      });
      setObjectives(obj.data.response.dataset);
      if (obj.data.response.total) {
        setTotalExercise(obj.data.response.total);
      }
    } catch (error) {
      toast.error(error.response.data.response.status.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };

  const getAllFundingSources = async () => {
    dispatch(startLoading());
    try {
      const funding = await Network.get(API.fetchAllFundingSources, {
        limit: perPage,
        page: pageFS,
        search: searchTerm,
        workspaceId: selectedWorkspace,
        planEventId: exerciseId,
        sort: orderByFS,
      });
      setFundingSources(
        funding.data.response.dataset.map((e) => {
          return {
            ...e,
            amountShown: (
              <CurrencyFormat
                value={e?.amount}
                displayType={'text'}
                thousandSeparator={true}
                prefix={'$ '}
              />
            ),
          };
        })
      );
      if (funding.data.response.total) {
        setTotalFundingAmount(funding.data.response.total);
        setTotalFunding(funding.data.response.totalFund);
      }
    } catch (error) {
      toast.error(error.response.data.response.status.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };

  const getAllAccreditedTasks = async () => {
    dispatch(startLoading());
    try {
      Network.get(API.fetchAllAccreditedTasks, {
        limit: perPage,
        page: pageAccTask,
        workspaceId: selectedWorkspace,
      }).then((resp) => {
        setAccreditedTasks(resp.data.response.dataset);
        setTotalAccTask(resp.data.response.total);
      });
    } catch (error) {
      toast.error(error.response.data.response.status.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };

  const getAllCommandPriority = async () => {
    dispatch(startLoading());
    try {
      const commandPrio = await Network.get(API.fetchAllCommandPriority, {
        limit: perPage,
        page: pageCommand,
        workspaceId: selectedWorkspace,
        sort: orderByCTP,
      });
      setCommandPriority(commandPrio.data.response.dataset);
      setTotalCommand(commandPrio.data.response.total);
    } catch (error) {
      toast.error(error.response.data.response.status.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };

  const getOganizationalCapabilityTarget = async () => {
    dispatch(startLoading());
    try {
      const obj = await Network.get(API.fetchAllOganizationalCapabilityTarget, {
        limit: perPage,
        page: pageOCT,
        search: searchTerm,
        workspaceId: selectedWorkspace,
        sort: orderByForHseep,
      });
      setOganizationalCapability(obj.data.response.dataset);
      if (obj.data.response.total) {
        setTotal(obj.data.response.total);
      }
    } catch (error) {
      toast.error(error.response.data.response.status.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };

  const currentPage =
    path === `${PreparePath}/dashboard/plan/prepare/exercise-details`
      ? 'Exercise Details'
      : null;

  const columnsForCoreCapabilities = [
    {
      dataField: 'title',
      text: 'Core Capabilities',
      sort: true,
      headerAttrs: { title: 'Sort By:' },
      attrs: { title: 'Core Capabilities' },
    },
    {
      dataField: 'area',
      text: 'Associated Mission Area(s)',
      attrs: { title: 'Associated Mission Area(s)' },
    },
  ];

  const columns_guidance_objectives = [
    {
      dataField: 'title',
      text: 'Exercise Objective',
      sort: true,
      onSort: (field, order) => {
        if (order === 'asc') {
          setOrderBy('title_ASC');
        } else if (order === 'desc') {
          setOrderBy('title_DESC');
        } else {
          setOrderBy('');
        }
      },

      headerAttrs: { title: 'Sort By:' },
      attrs: { title: 'Exercise Objective' },
    },
  ];

  const columns_guidance_targets = [
    {
      dataField: 'title',
      text: 'Organizational Capability Targets',
      sort: true,
      onSort: (field, order) => {
        if (order === 'asc') {
          setOrderByForHseep('title_ASC');
        } else if (order === 'desc') {
          setOrderByForHseep('title_DESC');
        } else {
          setOrderByForHseep('');
        }
      },

      headerAttrs: { title: 'Sort By:' },
      attrs: { title: 'Organizational Capability Targets' },
    },
  ];

  const expandRow_guidance_objectives = {
    parentClassName: 'parent-expand-tr',
    className: 'child-expand-tr',
    renderer: (row) => {
      return (
        <>
          <div className="mb-3">
            <label className="form-label d-block mb-0">Objective</label>
            {row.exerciseobjective}
          </div>
          <div className="mb-3">
            <label className="form-label d-block mb-0">
              Measure of Performance
            </label>
            {row.measureperformane}
          </div>
          <div>
            <label className="form-label d-block mb-0">
              Measure of Effectiveness
            </label>
            {row.measureeffectiveness}
          </div>
        </>
      );
    },
    showExpandColumn: true,
    expandByColumnOnly: false,
    expandHeaderColumnRenderer: ({ isAnyExpands }) => {
      if (isAnyExpands) {
        return <i className="fa fa-chevron-down" aria-hidden="true"></i>;
      }
      return <i className="fa fa-chevron-right" aria-hidden="true"></i>;
    },
    expandColumnRenderer: ({ expanded }) => {
      if (expanded) {
        return (
          <>
            <div className="d-flex align-items-center justify-content-md-center">
              <span className="me-2">Details</span>
              <i className="fa fa-chevron-down" aria-hidden="true"></i>
            </div>
          </>
        );
      }
      return (
        <>
          <div className="d-flex align-items-center justify-content-md-center">
            <span className="me-2">Details</span>
            <i className="fa fa-chevron-right" aria-hidden="true"></i>
          </div>
        </>
      );
    },
  };

  const expandRow_guidance_targets = {
    parentClassName: 'parent-expand-tr',
    className: 'child-expand-tr',
    renderer: (row) => {
      return (
        <>
          <div className="mb-3">
            <label className="form-label d-block mb-0">Title</label>
            {row.title}
          </div>
          <div className="mb-3">
            <label className="form-label d-block mb-0">Description</label>
            {row.description}
          </div>
        </>
      );
    },
    showExpandColumn: true,
    expandByColumnOnly: false,
    expandHeaderColumnRenderer: ({ isAnyExpands }) => {
      if (isAnyExpands) {
        return <i className="fa fa-chevron-down" aria-hidden="true"></i>;
      }
      return <i className="fa fa-chevron-right" aria-hidden="true"></i>;
    },
    expandColumnRenderer: ({ expanded }) => {
      if (expanded) {
        return (
          <>
            <div className="d-flex align-items-center justify-content-md-center">
              <span className="me-2">Details</span>
              <i className="fa fa-chevron-down" aria-hidden="true"></i>
            </div>
          </>
        );
      }
      return (
        <>
          <div className="d-flex align-items-center justify-content-md-center">
            <span className="me-2">Details</span>
            <i className="fa fa-chevron-right" aria-hidden="true"></i>
          </div>
        </>
      );
    },
  };

  const columns_funding_sources = [
    {
      dataField: 'primarysource',
      text: 'Primary Source',
      sort: true,
      onSort: (field, order) => {
        if (order === 'asc') {
          setOrderByFS('primarysource_ASC');
        } else if (order === 'desc') {
          setOrderByFS('primarysource_DESC');
        } else {
          setOrderByFS('');
        }
      },

      headerAttrs: { title: 'Sort By:' },
      attrs: { title: 'Primary Source' },
    },
    {
      dataField: 'subsource',
      text: 'Sub-Source',
      sort: true,
      onSort: (field, order) => {
        if (order === 'asc') {
          setOrderByFS('subsource_ASC');
        } else if (order === 'desc') {
          setOrderByFS('subsource_DESC');
        } else {
          setOrderByFS('');
        }
      },

      attrs: { title: 'Sub-Source' },
    },
    {
      dataField: 'amountShown',
      text: 'Amount',
      sort: true,
      onSort: (field, order) => {
        if (order === 'asc') {
          setOrderByFS('amount_ASC');
        } else if (order === 'desc') {
          setOrderByFS('amount_DESC');
        } else {
          setOrderByFS('');
        }
      },

      attrs: { title: 'Amount' },
    },
  ];

  const columns_accredited_tasks = [
    {
      dataField: 'level',
      text: 'Level',
      sort: true,
      headerAttrs: { title: 'Sort By:' },
      attrs: { title: 'Level' },
    },
    {
      dataField: 'number',
      text: 'Number',
      sort: true,
      attrs: { title: 'Number' },
    },
    {
      dataField: 'title',
      text: 'Title',
      sort: true,
      attrs: { title: 'Title' },
    },
  ];

  const expandRow_accredited_tasks = {
    parentClassName: 'parent-expand-tr',
    className: 'child-expand-tr',
    renderer: (row) => {
      return (
        <>
          <p className="m-0">{row.description}</p>
        </>
      );
    },
    showExpandColumn: true,
    expandByColumnOnly: false,
    expandHeaderColumnRenderer: ({ isAnyExpands }) => {
      if (isAnyExpands) {
        return <i className="fa fa-chevron-down" aria-hidden="true"></i>;
      }
      return <i className="fa fa-chevron-right" aria-hidden="true"></i>;
    },
    expandColumnRenderer: ({ expanded }) => {
      if (expanded) {
        return (
          <>
            <div className="d-flex align-items-center justify-content-md-center">
              <span className="me-2">Details</span>
              <i className="fa fa-chevron-down" aria-hidden="true"></i>
            </div>
          </>
        );
      }
      return (
        <>
          <div className="d-flex align-items-center justify-content-md-center">
            <span className="me-2">Details</span>
            <i className="fa fa-chevron-right" aria-hidden="true"></i>
          </div>
        </>
      );
    },
  };

  const columns_command_training_priorities = [
    {
      dataField: 'title',
      text: 'Title',
      sort: true,
      onSort: (field, order) => {
        if (order === 'asc') {
          setOrderByCTP('title_ASC');
        } else if (order === 'desc') {
          setOrderByCTP('title_DESC');
        } else {
          setOrderByCTP('');
        }
      },

      headerAttrs: { title: 'Sort By:' },
      attrs: { title: 'Title' },
    },
  ];

  const expandRow_command_training_priorities = {
    parentClassName: 'parent-expand-tr',
    className: 'child-expand-tr',
    renderer: (row) => {
      return (
        <>
          <p className="m-0">
            <span className="weight-600 d-block mb-1">Description</span>
            {row.description}
          </p>
        </>
      );
    },
    showExpandColumn: true,
    expandByColumnOnly: false,
    expandHeaderColumnRenderer: ({ isAnyExpands }) => {
      if (isAnyExpands) {
        return <i className="fa fa-chevron-down" aria-hidden="true"></i>;
      }
      return <i className="fa fa-chevron-right" aria-hidden="true"></i>;
    },
    expandColumnRenderer: ({ expanded }) => {
      if (expanded) {
        return (
          <>
            <div className="d-flex align-items-center justify-content-md-center">
              <span className="me-2">Details</span>
              <i className="fa fa-chevron-down" aria-hidden="true"></i>
            </div>
          </>
        );
      }
      return (
        <>
          <div className="d-flex align-items-center justify-content-md-center">
            <span className="me-2">Details</span>
            <i className="fa fa-chevron-right" aria-hidden="true"></i>
          </div>
        </>
      );
    },
  };

  const getAllCoreCapabilities = async () => {
    dispatch(startLoading());
    try {
      Network.get(API.fetchAllCoreCapabilities, {
        limit: perPage,
        page: pageCC,
        workspaceId: selectedWorkspace,
        planEventId: exerciseId,
      })
        .then((resp) => {
          const coreData = resp.data.response.dataset.map((ele) => {
            return {
              ...ele,
              title: ele?.title,
              area: ele?.areas.map((element) => element.area).join(', '),
            };
          });
          setCoreCapabilities(coreData);
          if (resp.data.response.total) {
            setCoreCapabilitiesTotal(resp.data.response.total);
          }
        })
        .catch(console.log)
        .finally(() => {
          dispatch(endLoading());
        });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getExerciseDetails();
    getAllObjectives();
    exerciseId && getAllFundingSources();
    getAllAccreditedTasks();
    getAllCommandPriority();
    getOganizationalCapabilityTarget();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exerciseId, workspaceName]); // workspaceName

  useEffect(() => {
    exerciseId && getAllFundingSources();
  }, [orderByFS, exerciseId, pageFS]);

  useEffect(() => {
    getAllCommandPriority();
  }, [orderByCTP, pageCommand]);

  useEffect(() => {
    getAllAccreditedTasks();
  }, [orderByCTP, pageAccTask]);

  useEffect(() => {
    getAllObjectives();
  }, [orderBy]);

  useEffect(() => {
    getOganizationalCapabilityTarget();
  }, [orderByForHseep, pageOCT]);

  useEffect(() => {
    exerciseId && getAllCoreCapabilities();
  }, [reduxUser, pageCC]);

  useEffect(() => {
    if (workspaceType && workspaceType === 'T&E') {
      navigate(`${PreparePath}/dashboard/plan`);
    }
  }, [workspaceType]);

  return (
    <>
      {newUI ? (
        <PageTitle title="Exercise Details" parents={['Exercise Management', 'Design']}>
          <StylishHelp
            classes={'ms-auto sml'}
            title={HelpExerciseMain.title}
            content={HelpExerciseMain.content}
            link={selfHostedKnowledgeBase || HelpExerciseMain.link}
          />
        </PageTitle>
      ) : (
        <>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <StylishNewButton onClick={() => navigate(-1)}>
                  <SharedIcon iconName="keyboard_arrow_left" classes="me-2" />
                  Back
                </StylishNewButton>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                {/* {currentPage} */}Exercise Details
              </li>
            </ol>
          </nav>
        </>
      )}
      <div className="plan-prepare__exercise-details">
        {exerciseData.length ? (
          <>
            <div className="mb-4">
              <div className="d-flex align-items-center mb-4">
                {!newUI ? (
                  <>
                    <h4 className="mb-2">
                      {workspaceName ||
                        exerciseData[0]?.eventName ||
                        exerciseName}{' '}
                      Exercise Details
                    </h4>
                    <StylishHelp
                      classes={'ms-auto sml'}
                      title={HelpExerciseMain.title}
                      content={HelpExerciseMain.content}
                      link={selfHostedKnowledgeBase || HelpExerciseMain.link}
                    />
                  </>
                ) : (
                  <h4 className="mb-0">
                    {workspaceName ||
                      exerciseData[0]?.eventName ||
                      exerciseName}
                  </h4>
                )}
              </div>
              <p className="m-0 break-word">
                {exerciseData[0]?.startDate} - {exerciseData[0]?.endDate}
              </p>
              <p className="m-0 break-word">
                {exerciseData[0]?.organizationName} - {exerciseData[0]?.group}
              </p>
            </div>
            <div className="tab-wrapper">
              {workspaceType === 'DOD' ? (
                <Tabs activeKey={key} onSelect={(k) => setKey(k)}>
                  <Tab
                    eventKey="guidance_objectives"
                    title="Guidance & Objectives"
                  >
                    <div className="button-group d-block d-md-flex mb-4">
                      <Link
                        className="button button--primary w-100 w-md-auto"
                        to={`${PreparePath}/dashboard/plan/prepare/all-details`}
                      >
                        Edit Guidance
                      </Link>
                      <Link
                        className="button button--primary w-100 w-md-auto ms-0 ms-md-3 mt-3 mt-md-0"
                        to={`${PreparePath}/dashboard/plan/prepare/all-details`}
                        state={{ step: 4 }}
                      >
                        Edit Objectives
                      </Link>
                    </div>
                    <p className="mb-4">
                      <span className="weight-600">Commander's Guidance</span>{' '}
                      <br />
                      {exerciseData[0]?.exerciseGuidance}
                    </p>
                    <StylishNewTable
                      keyField={'id'}
                      columns={columns_guidance_objectives}
                      rows={objectives}
                      expandRow={expandRow_guidance_objectives}
                      pagination={true}
                      page={pageExercise}
                      perPage={perPageExercise}
                      total={totalExercise}
                      onPageChange={(page) => setPageExercise(page)}
                    />
                  </Tab>
                  <Tab eventKey="planning_meetings" title="Planning Meetings">
                    <div className="button-group d-block d-md-flex mb-4">
                      <Link
                        className="button button--primary w-100 w-md-auto"
                        to={`${PreparePath}/dashboard/plan/prepare/all-details`}
                        state={{ step: 2 }}
                      >
                        Edit Meetings
                      </Link>
                    </div>
                    {key === 'planning_meetings' && (
                      <>
                        <div className="calendar">
                          <FullCalendar
                            validRange={''}
                            plugins={[
                              dayGridPlugin,
                              timegrid,
                              listPlugin,
                              interactionPlugin,
                            ]}
                            headerToolbar={{
                              left: 'prev,next today',
                              center: 'title',
                              right:
                                'timeGridDay,timeGridWeek,dayGridMonth,listMonth',
                            }}
                            initialView="dayGridMonth"
                            events={refinedPlanMeetings}
                            eventClick={(info) => editExistingEvent(info.event)}
                            eventTimeFormat={{
                              hour: '2-digit',
                              minute: '2-digit',
                              meridiem: 'short',
                              hour12:
                                reduxUser?.timeFormat === 'hour12Mode'
                                  ? true
                                  : false,
                            }}
                            displayEventTime={false}
                            eventBorderColor={'#000000'}
                            slotLabelFormat={{
                              hour: '2-digit',
                              minute: '2-digit',
                              meridiem: 'short',
                              hour12:
                                reduxUser?.timeFormat === 'hour12Mode'
                                  ? true
                                  : false,
                            }}
                            slotLabelContent={(info) => {
                              if (info.text === '24:00') {
                                info.text = '00:00';
                              }
                            }}
                          />
                          {addScheduleActivationDialogOpen && (
                            <ViewPlanMeetingDialog
                              show={existingEvent}
                              start={exerciseData[0]?.startDate}
                              end={exerciseData[0]?.endDate}
                              selectedIncidentMember={selectedIncidentMember}
                              onClose={() =>
                                setAddScheduleActivationDialogOpen(false)
                              }
                              setSelectedIncidentMember={
                                setSelectedIncidentMember
                              }
                            ></ViewPlanMeetingDialog>
                          )}
                        </div>
                      </>
                    )}
                  </Tab>
                  <Tab eventKey="funding_sources" title="Funding Sources">
                    <div className="button-group d-block d-md-flex mb-4">
                      <Link
                        className="button button--primary w-100 w-md-auto"
                        to={`${PreparePath}/dashboard/plan/prepare/all-details`}
                        state={{ step: 3 }}
                      >
                        Edit Funding Sources
                      </Link>
                    </div>
                    <p className="mb-4 weight-600">
                      Total Funding: ${' '}
                      {totalFunding > 0 && totalFunding?.toLocaleString()}
                    </p>
                    <StylishNewTable
                      keyField={'id'}
                      columns={columns_funding_sources}
                      rows={fundingSources}
                      pagination={true}
                      page={pageFS}
                      perPage={perPage}
                      total={totalFundingAmount}
                      onPageChange={(page) => setPageFS(page)}
                    />
                  </Tab>
                  <Tab eventKey="tasks_priorities" title="Tasks & Priorities">
                    <div className="button-group d-block d-md-flex mb-4">
                      <Link
                        className="button button--primary w-100 w-md-auto"
                        to={`${PreparePath}/dashboard/plan/prepare/all-details`}
                        state={{ step: 5 }}
                      >
                        Edit Task &amp; Priorities
                      </Link>
                    </div>
                    <div className="mb-5">
                      <p className="mb-3 weight-600">Accredited Tasks</p>
                      <StylishNewTable
                        keyField={'id'}
                        columns={columns_accredited_tasks}
                        rows={accreditedTasks.map((data) => ({
                          id: data.id,
                          title: data.jmets.taskTitle,
                          level: data.jmets.levelWar,
                          number: data.jmets.taskNumber,
                          jmetId: data.jmets.id,
                          description: data.jmets.description,
                        }))}
                        expandRow={expandRow_accredited_tasks}
                        pagination={true}
                        page={pageAccTask}
                        perPage={perPage}
                        total={totalAccTask}
                        onPageChange={(page) => setPageAccTask(page)}
                      />
                    </div>
                    <div className="mb-0">
                      <p className="mb-3 weight-600">
                        Command Training Priorities
                      </p>
                      <StylishNewTable
                        keyField={'id'}
                        columns={columns_command_training_priorities}
                        rows={commandPriority}
                        expandRow={expandRow_command_training_priorities}
                        pagination={true}
                        page={pageCommand}
                        perPage={perPage}
                        total={totalCommand}
                        onPageChange={(page) => setPageCommand(page)}
                      />
                    </div>
                  </Tab>
                </Tabs>
              ) : (
                <Tabs activeKey={key} onSelect={(k) => setKey(k)}>
                  <Tab eventKey="guidance_objectives" title="Scope & Targets">
                    <div className="button-group d-block d-md-flex mb-4">
                      <Link
                        className="button button--primary w-100 w-md-auto"
                        to={`${PreparePath}/dashboard/plan/prepare/all-details`}
                      >
                        Edit Scope
                      </Link>
                      <Link
                        className="button button--secondary w-100 w-md-auto ms-0 ms-md-3 mt-3 mt-md-0"
                        to={`${PreparePath}/dashboard/plan/prepare/all-details`}
                        state={{ step: 4 }}
                      >
                        Edit Targets
                      </Link>
                    </div>
                    <p className="mb-4">
                      <span className="weight-600">Exercise Scope</span> <br />
                      {exerciseData[0]?.exerciseGuidance}
                    </p>
                    <StylishNewTable
                      keyField={'id'}
                      columns={columns_guidance_targets}
                      rows={oganizationalCapability}
                      expandRow={expandRow_guidance_targets}
                      pagination={true}
                      page={pageOCT}
                      perPage={perPage}
                      total={total}
                      onPageChange={(page) => setPageOCT(page)}
                    />
                  </Tab>
                  <Tab eventKey="planning_meetings" title="Planning Meetings">
                    <div className="button-group d-block d-md-flex mb-4">
                      <Link
                        className="button button--primary w-100 w-md-auto"
                        to={`${PreparePath}/dashboard/plan/prepare/all-details`}
                        state={{ step: 2 }}
                      >
                        Edit Meetings
                      </Link>
                    </div>
                    {key === 'planning_meetings' && (
                      <>
                        <div className="calendar">
                          <FullCalendar
                            validRange={''}
                            plugins={[
                              dayGridPlugin,
                              timegrid,
                              listPlugin,
                              interactionPlugin,
                            ]}
                            headerToolbar={{
                              left: 'prev,next today',
                              center: 'title',
                              right:
                                'timeGridDay,timeGridWeek,dayGridMonth,listMonth',
                            }}
                            initialView="dayGridMonth"
                            events={refinedPlanMeetings}
                            eventClick={(info) => editExistingEvent(info.event)}
                            eventTimeFormat={{
                              hour: '2-digit',
                              minute: '2-digit',
                              meridiem: 'short',
                              hour12:
                                reduxUser?.timeFormat === 'hour12Mode'
                                  ? true
                                  : false,
                            }}
                            displayEventTime={false}
                            eventBorderColor={'#000000'}
                            slotLabelFormat={{
                              hour: '2-digit',
                              minute: '2-digit',
                              meridiem: 'short',
                              hour12:
                                reduxUser?.timeFormat === 'hour12Mode'
                                  ? true
                                  : false,
                            }}
                            slotLabelContent={(info) => {
                              if (info.text === '24:00') {
                                info.text = '00:00';
                              }
                            }}
                          />
                          {addScheduleActivationDialogOpen && (
                            <ViewPlanMeetingDialog
                              show={existingEvent}
                              selectedIncidentMember={selectedIncidentMember}
                              onClose={() =>
                                setAddScheduleActivationDialogOpen(false)
                              }
                              setSelectedIncidentMember={
                                setSelectedIncidentMember
                              }
                            ></ViewPlanMeetingDialog>
                          )}
                        </div>
                      </>
                    )}
                  </Tab>
                  <Tab eventKey="funding_sources" title="Funding Sources">
                    <div className="button-group d-block d-md-flex mb-4">
                      <Link
                        className="button button--primary w-100 w-md-auto"
                        to={`${PreparePath}/dashboard/plan/prepare/all-details`}
                        state={{ step: 3 }}
                      >
                        Edit Funding Sources
                      </Link>
                    </div>
                    <p className="mb-4 weight-600">
                      Total Funding: ${' '}
                      {totalFunding > 0 && totalFunding?.toLocaleString()}
                    </p>
                    <StylishNewTable
                      keyField={'id'}
                      columns={columns_funding_sources}
                      rows={fundingSources}
                      pagination={true}
                      page={pageFS}
                      perPage={perPage}
                      total={totalFundingAmount}
                      onPageChange={(page) => setPageFS(page)}
                    />
                  </Tab>
                  <Tab eventKey="tasks_priorities" title="Core Capabilities">
                    <div className="button-group d-block d-md-flex mb-4">
                      <Link
                        className="button button--primary w-100 w-md-auto"
                        to={`${PreparePath}/dashboard/plan/prepare/all-details`}
                        state={{ step: 5 }}
                      >
                        Edit Core Capabilities
                      </Link>
                    </div>
                    <p className="mb-4 weight-600">
                      Core Capabilities and Associated Mission Area(s):{' '}
                      {coreCapabilitiesTotal}
                    </p>
                    <StylishNewTable
                      keyField={'id'}
                      columns={columnsForCoreCapabilities}
                      rows={coreCapabilities}
                      pagination
                      page={pageCC}
                      perPage={perPage}
                      total={coreCapabilitiesTotal}
                      onPageChange={(page) => setPageCC(page)}
                    />
                  </Tab>
                </Tabs>
              )}
            </div>
            <div className="col-md-9">
              <Outlet />
            </div>
          </>
        ) : (
          <div className="bg-gray-800 text-center p-5">
            <div className="py-0 py-md-4">
              <div className="d-flex justify-content-center mb-4">
                <img src={IconEmptyExercise} alt="" />
              </div>
              {/* <p className="weight-600 mb-4">
                A fresh start. Create the first entry.
                <a href="https://help.epicready.com/" target="_blank">
                  {' '}
                  Need more help?
                </a>
              </p> */}

              <p className="weight-600 mb-4">
                A fresh start. Create the first entry.
                <a href={selfHostedKnowledgeBase || MiscellaneousHelpLink.link} target="_blank">
                  {' '}
                  Need more help?
                </a>
              </p>
              <Link
                className="button button--primary"
                to={`${PreparePath}/dashboard/plan/prepare/all-details`}
              >
                Enter Details
              </Link>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
