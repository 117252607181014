import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import API from '../../../../service/api';
import Network from '../../../../service/Network';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';
import { inputStringLength, toastConfig } from 'assets/data/config';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import StylishNewInput from 'components/DesignSystems/New/StylishNewInput';
import StylishNewSelect from 'components/DesignSystems/New/StylishNewSelect';
import { PreparePath } from 'utils/sharedUtils/sharedConst';
import { endLoading, startLoading } from "../../../../../reducers/loading/loading.action";

export default function CreateParticipant({ onClose, show }) {
  const {
    handleSubmit,
    formState: { errors },
    setError,
    control,
    clearErrors,
  } = useForm();

  const [allParticipantType, setAllParticipantType] = useState('');

  const { selectedWorkspace } = useSelector((state) => state.prepare.workspace);
  const workspaceId = useSelector(
    (state) => state.prepare.workspace.selectedWorkspace
  );
  const reduxUser = useSelector((state) => state.prepare.user);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const participantWorkspaceType = [
    {
      value: 'dod',
      label: 'DOD',
    },
    {
      value: 'hseep',
      label: 'HSEEP',
    },
  ];

  const onSubmit = async (data) => {
    dispatch(startLoading());
    try {
      const newParticipant = await Network.post(API.addParticipant, {
        workspaceId: workspaceId,
        name: data.name,
        type: data.participantType.label,
      });
      const newParticipantId = newParticipant.data.response.dataset.map(
        (e) => e.id
      );
      onClose(true);
      toast.success('Participant created successfully', toastConfig);
      navigate(
        `${PreparePath}/dashboard/plan/prepare/participants/${newParticipantId}`
      );
    } catch (error) {
      toast.error(error.response.data.response.status.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };
  useEffect(() => {
    const listener = (event) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        event.preventDefault();
        handleSubmit(onSubmit)();
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, []);
  return (
    <div>
      <Modal
        show={show}
        onHide={onClose}
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton closeVariant="white">
          <Modal.Title>Create Participant</Modal.Title>
        </Modal.Header>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body>
            <div className="mb-3">
              <label className="form-label">Name</label>
              <Controller
                control={control}
                name={'name'}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, value } }) => (
                  <StylishNewInput
                    onChange={(e) => {
                      onChange(e);
                    }}
                    value={value}
                    type="text"
                    name="name"
                    maxLength={inputStringLength}
                    className="form-control"
                    // {...register('amount', { required: true })}
                  />
                )}
              />
              {errors?.name?.type === 'required' && (
                <span className="form-text form-error">
                  This field is required
                </span>
              )}
              {errors?.name?.type === 'pattern' && (
                <span className="form-text form-error">
                  Name cannot start with a number
                </span>
              )}
            </div>
            <div>
              <label className="form-label">Participant Type</label>
              <div className="">
                <Controller
                  control={control}
                  name="participantType"
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <StylishNewSelect
                      options={participantWorkspaceType}
                      defaultValue={value}
                      value={value || allParticipantType}
                      onChange={(e) => {
                        if (!e) {
                          setAllParticipantType('');
                          setError('participantType');
                        } else if (e.value) {
                          clearErrors('participantType');
                        }
                        return onChange(e);
                      }}
                      placeholder={'Select Participant Type'}
                      isClearable={true}
                      isSearchable={true}
                      isMulti={false}
                      isDisabled={false}
                    />
                  )}
                />
                {errors.participantType && (
                  <span className="form-text form-error">
                    This is a required field
                  </span>
                )}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="button-group">
              <StylishNewButton
                className="button button--secondary button--reverse"
                onClick={onClose}
              >
                Close
              </StylishNewButton>

              <StylishNewButton
                className="button button--primary"
                type="submit"
              >
                Save
              </StylishNewButton>
            </div>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
}
