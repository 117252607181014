import React from 'react';
import * as FA from "react-icons/fa";

export const AllIcon = [
  {
    icon: <FA.Fa500Px />,
    name: "500Px",
  },
  {
    icon: <FA.FaAccessibleIcon />,
    name: "AccessibleIcon",
  },
  {
    icon: <FA.FaAccusoft />,
    name: "Accusoft",
  },
  {
    icon: <FA.FaAcquisitionsIncorporated />,
    name: "AcquisitionsIncorporated",
  },
  {
    icon: <FA.FaAdn />,
    name: "Adn",
  },
  {
    icon: <FA.FaAdversal />,
    name: "Adversal",
  },
  {
    icon: <FA.FaAffiliatetheme />,
    name: "Affiliatetheme",
  },
  {
    icon: <FA.FaAirbnb />,
    name: "Airbnb",
  },
  {
    icon: <FA.FaAlgolia />,
    name: "Algolia",
  },
  {
    icon: <FA.FaAlipay />,
    name: "Alipay",
  },
  {
    icon: <FA.FaAmazonPay />,
    name: "AmazonPay",
  },
  {
    icon: <FA.FaAmazon />,
    name: "Amazon",
  },
  {
    icon: <FA.FaAmilia />,
    name: "Amilia",
  },
  {
    icon: <FA.FaAndroid />,
    name: "Android",
  },
  {
    icon: <FA.FaAngellist />,
    name: "Angellist",
  },
  {
    icon: <FA.FaAngrycreative />,
    name: "Angrycreative",
  },
  {
    icon: <FA.FaAngular />,
    name: "Angular",
  },
  {
    icon: <FA.FaAppStoreIos />,
    name: "AppStoreIos",
  },
  {
    icon: <FA.FaAppStore />,
    name: "AppStore",
  },
  {
    icon: <FA.FaApper />,
    name: "Apper",
  },
  {
    icon: <FA.FaApplePay />,
    name: "ApplePay",
  },
  {
    icon: <FA.FaApple />,
    name: "Apple",
  },
  {
    icon: <FA.FaArtstation />,
    name: "Artstation",
  },
  {
    icon: <FA.FaAsymmetrik />,
    name: "Asymmetrik",
  },
  {
    icon: <FA.FaAtlassian />,
    name: "Atlassian",
  },
  {
    icon: <FA.FaAudible />,
    name: "Audible",
  },
  {
    icon: <FA.FaAutoprefixer />,
    name: "Autoprefixer",
  },
  {
    icon: <FA.FaAvianex />,
    name: "Avianex",
  },
  {
    icon: <FA.FaAviato />,
    name: "Aviato",
  },
  {
    icon: <FA.FaAws />,
    name: "Aws",
  },
  {
    icon: <FA.FaBandcamp />,
    name: "Bandcamp",
  },
  {
    icon: <FA.FaBattleNet />,
    name: "BattleNet",
  },
  {
    icon: <FA.FaBehanceSquare />,
    name: "BehanceSquare",
  },
  {
    icon: <FA.FaBehance />,
    name: "Behance",
  },
  {
    icon: <FA.FaBimobject />,
    name: "Bimobject",
  },
  {
    icon: <FA.FaBitbucket />,
    name: "Bitbucket",
  },
  {
    icon: <FA.FaBitcoin />,
    name: "Bitcoin",
  },
  {
    icon: <FA.FaBity />,
    name: "Bity",
  },
  {
    icon: <FA.FaBlackTie />,
    name: "BlackTie",
  },
  {
    icon: <FA.FaBlackberry />,
    name: "Blackberry",
  },
  {
    icon: <FA.FaBloggerB />,
    name: "BloggerB",
  },
  {
    icon: <FA.FaBlogger />,
    name: "Blogger",
  },
  {
    icon: <FA.FaBluetoothB />,
    name: "BluetoothB",
  },
  {
    icon: <FA.FaBluetooth />,
    name: "Bluetooth",
  },
  {
    icon: <FA.FaBootstrap />,
    name: "Bootstrap",
  },
  {
    icon: <FA.FaBtc />,
    name: "Btc",
  },
  {
    icon: <FA.FaBuffer />,
    name: "Buffer",
  },
  {
    icon: <FA.FaBuromobelexperte />,
    name: "Buromobelexperte",
  },
  {
    icon: <FA.FaBuyNLarge />,
    name: "BuyNLarge",
  },
  {
    icon: <FA.FaBuysellads />,
    name: "Buysellads",
  },
  {
    icon: <FA.FaCanadianMapleLeaf />,
    name: "CanadianMapleLeaf",
  },
  {
    icon: <FA.FaCcAmazonPay />,
    name: "CcAmazonPay",
  },
  {
    icon: <FA.FaCcAmex />,
    name: "CcAmex",
  },
  {
    icon: <FA.FaCcApplePay />,
    name: "CcApplePay",
  },
  {
    icon: <FA.FaCcDinersClub />,
    name: "CcDinersClub",
  },
  {
    icon: <FA.FaCcDiscover />,
    name: "CcDiscover",
  },
  {
    icon: <FA.FaCcJcb />,
    name: "CcJcb",
  },
  {
    icon: <FA.FaCcMastercard />,
    name: "CcMastercard",
  },
  {
    icon: <FA.FaCcPaypal />,
    name: "CcPaypal",
  },
  {
    icon: <FA.FaCcStripe />,
    name: "CcStripe",
  },
  {
    icon: <FA.FaCcVisa />,
    name: "CcVisa",
  },
  {
    icon: <FA.FaCentercode />,
    name: "Centercode",
  },
  {
    icon: <FA.FaCentos />,
    name: "Centos",
  },
  {
    icon: <FA.FaChrome />,
    name: "Chrome",
  },
  {
    icon: <FA.FaChromecast />,
    name: "Chromecast",
  },
  {
    icon: <FA.FaCloudflare />,
    name: "Cloudflare",
  },
  {
    icon: <FA.FaCloudscale />,
    name: "Cloudscale",
  },
  {
    icon: <FA.FaCloudsmith />,
    name: "Cloudsmith",
  },
  {
    icon: <FA.FaCloudversify />,
    name: "Cloudversify",
  },
  {
    icon: <FA.FaCodepen />,
    name: "Codepen",
  },
  {
    icon: <FA.FaCodiepie />,
    name: "Codiepie",
  },
  {
    icon: <FA.FaConfluence />,
    name: "Confluence",
  },
  {
    icon: <FA.FaConnectdevelop />,
    name: "Connectdevelop",
  },
  {
    icon: <FA.FaContao />,
    name: "Contao",
  },
  {
    icon: <FA.FaCottonBureau />,
    name: "CottonBureau",
  },
  {
    icon: <FA.FaCpanel />,
    name: "Cpanel",
  },
  {
    icon: <FA.FaCreativeCommonsBy />,
    name: "CreativeCommonsBy",
  },
  {
    icon: <FA.FaCreativeCommonsNcEu />,
    name: "CreativeCommonsNcEu",
  },
  {
    icon: <FA.FaCreativeCommonsNcJp />,
    name: "CreativeCommonsNcJp",
  },
  {
    icon: <FA.FaCreativeCommonsNc />,
    name: "CreativeCommonsNc",
  },
  {
    icon: <FA.FaCreativeCommonsNd />,
    name: "CreativeCommonsNd",
  },
  {
    icon: <FA.FaCreativeCommonsPdAlt />,
    name: "CreativeCommonsPdAlt",
  },
  {
    icon: <FA.FaCreativeCommonsPd />,
    name: "CreativeCommonsPd",
  },
  {
    icon: <FA.FaCreativeCommonsRemix />,
    name: "CreativeCommonsRemix",
  },
  {
    icon: <FA.FaCreativeCommonsSa />,
    name: "CreativeCommonsSa",
  },
  {
    icon: <FA.FaCreativeCommonsSamplingPlus />,
    name: "CreativeCommonsSamplingPlus",
  },
  {
    icon: <FA.FaCreativeCommonsSampling />,
    name: "CreativeCommonsSampling",
  },
  {
    icon: <FA.FaCreativeCommonsShare />,
    name: "CreativeCommonsShare",
  },
  {
    icon: <FA.FaCreativeCommonsZero />,
    name: "CreativeCommonsZero",
  },
  {
    icon: <FA.FaCreativeCommons />,
    name: "CreativeCommons",
  },
  {
    icon: <FA.FaCriticalRole />,
    name: "CriticalRole",
  },
  {
    icon: <FA.FaCss3Alt />,
    name: "Css3Alt",
  },
  {
    icon: <FA.FaCss3 />,
    name: "Css3",
  },
  {
    icon: <FA.FaCuttlefish />,
    name: "Cuttlefish",
  },
  {
    icon: <FA.FaDAndDBeyond />,
    name: "DAndDBeyond",
  },
  {
    icon: <FA.FaDAndD />,
    name: "DAndD",
  },
  {
    icon: <FA.FaDailymotion />,
    name: "Dailymotion",
  },
  {
    icon: <FA.FaDashcube />,
    name: "Dashcube",
  },
  {
    icon: <FA.FaDeezer />,
    name: "Deezer",
  },
  {
    icon: <FA.FaDelicious />,
    name: "Delicious",
  },
  {
    icon: <FA.FaDeploydog />,
    name: "Deploydog",
  },
  {
    icon: <FA.FaDeskpro />,
    name: "Deskpro",
  },
  {
    icon: <FA.FaDev />,
    name: "Dev",
  },
  {
    icon: <FA.FaDeviantart />,
    name: "Deviantart",
  },
  {
    icon: <FA.FaDhl />,
    name: "Dhl",
  },
  {
    icon: <FA.FaDiaspora />,
    name: "Diaspora",
  },
  {
    icon: <FA.FaDigg />,
    name: "Digg",
  },
  {
    icon: <FA.FaDigitalOcean />,
    name: "DigitalOcean",
  },
  {
    icon: <FA.FaDiscord />,
    name: "Discord",
  },
  {
    icon: <FA.FaDiscourse />,
    name: "Discourse",
  },
  {
    icon: <FA.FaDochub />,
    name: "Dochub",
  },
  {
    icon: <FA.FaDocker />,
    name: "Docker",
  },
  {
    icon: <FA.FaDraft2Digital />,
    name: "Draft2Digital",
  },
  {
    icon: <FA.FaDribbbleSquare />,
    name: "DribbbleSquare",
  },
  {
    icon: <FA.FaDribbble />,
    name: "Dribbble",
  },
  {
    icon: <FA.FaDropbox />,
    name: "Dropbox",
  },
  {
    icon: <FA.FaDrupal />,
    name: "Drupal",
  },
  {
    icon: <FA.FaDyalog />,
    name: "Dyalog",
  },
  {
    icon: <FA.FaEarlybirds />,
    name: "Earlybirds",
  },
  {
    icon: <FA.FaEbay />,
    name: "Ebay",
  },
  {
    icon: <FA.FaEdgeLegacy />,
    name: "EdgeLegacy",
  },
  {
    icon: <FA.FaEdge />,
    name: "Edge",
  },
  {
    icon: <FA.FaElementor />,
    name: "Elementor",
  },
  {
    icon: <FA.FaEllo />,
    name: "Ello",
  },
  {
    icon: <FA.FaEmber />,
    name: "Ember",
  },
  {
    icon: <FA.FaEmpire />,
    name: "Empire",
  },
  {
    icon: <FA.FaEnvira />,
    name: "Envira",
  },
  {
    icon: <FA.FaErlang />,
    name: "Erlang",
  },
  {
    icon: <FA.FaEthereum />,
    name: "Ethereum",
  },
  {
    icon: <FA.FaEtsy />,
    name: "Etsy",
  },
  {
    icon: <FA.FaEvernote />,
    name: "Evernote",
  },
  {
    icon: <FA.FaExpeditedssl />,
    name: "Expeditedssl",
  },
  {
    icon: <FA.FaFacebookF />,
    name: "FacebookF",
  },
  {
    icon: <FA.FaFacebookMessenger />,
    name: "FacebookMessenger",
  },
  {
    icon: <FA.FaFacebookSquare />,
    name: "FacebookSquare",
  },
  {
    icon: <FA.FaFacebook />,
    name: "Facebook",
  },
  {
    icon: <FA.FaFantasyFlightGames />,
    name: "FantasyFlightGames",
  },
  {
    icon: <FA.FaFedex />,
    name: "Fedex",
  },
  {
    icon: <FA.FaFedora />,
    name: "Fedora",
  },
  {
    icon: <FA.FaFigma />,
    name: "Figma",
  },
  {
    icon: <FA.FaFirefoxBrowser />,
    name: "FirefoxBrowser",
  },
  {
    icon: <FA.FaFirefox />,
    name: "Firefox",
  },
  {
    icon: <FA.FaFirstOrderAlt />,
    name: "FirstOrderAlt",
  },
  {
    icon: <FA.FaFirstOrder />,
    name: "FirstOrder",
  },
  {
    icon: <FA.FaFirstdraft />,
    name: "Firstdraft",
  },
  {
    icon: <FA.FaFlickr />,
    name: "Flickr",
  },
  {
    icon: <FA.FaFlipboard />,
    name: "Flipboard",
  },
  {
    icon: <FA.FaFly />,
    name: "Fly",
  },
  {
    icon: <FA.FaFontAwesomeAlt />,
    name: "FontAwesomeAlt",
  },
  {
    icon: <FA.FaFontAwesomeFlag />,
    name: "FontAwesomeFlag",
  },
  {
    icon: <FA.FaFontAwesomeLogoFull />,
    name: "FontAwesomeLogoFull",
  },
  {
    icon: <FA.FaFontAwesome />,
    name: "FontAwesome",
  },
  {
    icon: <FA.FaFonticonsFi />,
    name: "FonticonsFi",
  },
  {
    icon: <FA.FaFonticons />,
    name: "Fonticons",
  },
  {
    icon: <FA.FaFortAwesomeAlt />,
    name: "FortAwesomeAlt",
  },
  {
    icon: <FA.FaFortAwesome />,
    name: "FortAwesome",
  },
  {
    icon: <FA.FaForumbee />,
    name: "Forumbee",
  },
  {
    icon: <FA.FaFoursquare />,
    name: "Foursquare",
  },
  {
    icon: <FA.FaFreeCodeCamp />,
    name: "FreeCodeCamp",
  },
  {
    icon: <FA.FaFreebsd />,
    name: "Freebsd",
  },
  {
    icon: <FA.FaFulcrum />,
    name: "Fulcrum",
  },
  {
    icon: <FA.FaGalacticRepublic />,
    name: "GalacticRepublic",
  },
  {
    icon: <FA.FaGalacticSenate />,
    name: "GalacticSenate",
  },
  {
    icon: <FA.FaGetPocket />,
    name: "GetPocket",
  },
  {
    icon: <FA.FaGgCircle />,
    name: "GgCircle",
  },
  {
    icon: <FA.FaGg />,
    name: "Gg",
  },
  {
    icon: <FA.FaGitAlt />,
    name: "GitAlt",
  },
  {
    icon: <FA.FaGitSquare />,
    name: "GitSquare",
  },
  {
    icon: <FA.FaGit />,
    name: "Git",
  },
  {
    icon: <FA.FaGithubAlt />,
    name: "GithubAlt",
  },
  {
    icon: <FA.FaGithubSquare />,
    name: "GithubSquare",
  },
  {
    icon: <FA.FaGithub />,
    name: "Github",
  },
  {
    icon: <FA.FaGitkraken />,
    name: "Gitkraken",
  },
  {
    icon: <FA.FaGitlab />,
    name: "Gitlab",
  },
  {
    icon: <FA.FaGitter />,
    name: "Gitter",
  },
  {
    icon: <FA.FaGlideG />,
    name: "GlideG",
  },
  {
    icon: <FA.FaGlide />,
    name: "Glide",
  },
  {
    icon: <FA.FaGofore />,
    name: "Gofore",
  },
  {
    icon: <FA.FaGoodreadsG />,
    name: "GoodreadsG",
  },
  {
    icon: <FA.FaGoodreads />,
    name: "Goodreads",
  },
  {
    icon: <FA.FaGoogleDrive />,
    name: "GoogleDrive",
  },
  {
    icon: <FA.FaGooglePay />,
    name: "GooglePay",
  },
  {
    icon: <FA.FaGooglePlay />,
    name: "GooglePlay",
  },
  {
    icon: <FA.FaGooglePlusG />,
    name: "GooglePlusG",
  },
  {
    icon: <FA.FaGooglePlusSquare />,
    name: "GooglePlusSquare",
  },
  {
    icon: <FA.FaGooglePlus />,
    name: "GooglePlus",
  },
  {
    icon: <FA.FaGoogleWallet />,
    name: "GoogleWallet",
  },
  {
    icon: <FA.FaGoogle />,
    name: "Google",
  },
  {
    icon: <FA.FaGratipay />,
    name: "Gratipay",
  },
  {
    icon: <FA.FaGrav />,
    name: "Grav",
  },
  {
    icon: <FA.FaGripfire />,
    name: "Gripfire",
  },
  {
    icon: <FA.FaGrunt />,
    name: "Grunt",
  },
  {
    icon: <FA.FaGuilded />,
    name: "Guilded",
  },
  {
    icon: <FA.FaGulp />,
    name: "Gulp",
  },
  {
    icon: <FA.FaHackerNewsSquare />,
    name: "HackerNewsSquare",
  },
  {
    icon: <FA.FaHackerNews />,
    name: "HackerNews",
  },
  {
    icon: <FA.FaHackerrank />,
    name: "Hackerrank",
  },
  {
    icon: <FA.FaHips />,
    name: "Hips",
  },
  {
    icon: <FA.FaHireAHelper />,
    name: "HireAHelper",
  },
  {
    icon: <FA.FaHive />,
    name: "Hive",
  },
  {
    icon: <FA.FaHooli />,
    name: "Hooli",
  },
  {
    icon: <FA.FaHornbill />,
    name: "Hornbill",
  },
  {
    icon: <FA.FaHotjar />,
    name: "Hotjar",
  },
  {
    icon: <FA.FaHouzz />,
    name: "Houzz",
  },
  {
    icon: <FA.FaHtml5 />,
    name: "Html5",
  },
  {
    icon: <FA.FaHubspot />,
    name: "Hubspot",
  },
  {
    icon: <FA.FaIdeal />,
    name: "Ideal",
  },
  {
    icon: <FA.FaImdb />,
    name: "Imdb",
  },
  {
    icon: <FA.FaInnosoft />,
    name: "Innosoft",
  },
  {
    icon: <FA.FaInstagramSquare />,
    name: "InstagramSquare",
  },
  {
    icon: <FA.FaInstagram />,
    name: "Instagram",
  },
  {
    icon: <FA.FaInstalod />,
    name: "Instalod",
  },
  {
    icon: <FA.FaIntercom />,
    name: "Intercom",
  },
  {
    icon: <FA.FaInternetExplorer />,
    name: "InternetExplorer",
  },
  {
    icon: <FA.FaInvision />,
    name: "Invision",
  },
  {
    icon: <FA.FaIoxhost />,
    name: "Ioxhost",
  },
  {
    icon: <FA.FaItchIo />,
    name: "ItchIo",
  },
  {
    icon: <FA.FaItunesNote />,
    name: "ItunesNote",
  },
  {
    icon: <FA.FaItunes />,
    name: "Itunes",
  },
  {
    icon: <FA.FaJava />,
    name: "Java",
  },
  {
    icon: <FA.FaJediOrder />,
    name: "JediOrder",
  },
  {
    icon: <FA.FaJenkins />,
    name: "Jenkins",
  },
  {
    icon: <FA.FaJira />,
    name: "Jira",
  },
  {
    icon: <FA.FaJoget />,
    name: "Joget",
  },
  {
    icon: <FA.FaJoomla />,
    name: "Joomla",
  },
  {
    icon: <FA.FaJsSquare />,
    name: "JsSquare",
  },
  {
    icon: <FA.FaJs />,
    name: "Js",
  },
  {
    icon: <FA.FaJsfiddle />,
    name: "Jsfiddle",
  },
  {
    icon: <FA.FaKaggle />,
    name: "Kaggle",
  },
  {
    icon: <FA.FaKeybase />,
    name: "Keybase",
  },
  {
    icon: <FA.FaKeycdn />,
    name: "Keycdn",
  },
  {
    icon: <FA.FaKickstarterK />,
    name: "KickstarterK",
  },
  {
    icon: <FA.FaKickstarter />,
    name: "Kickstarter",
  },
  {
    icon: <FA.FaKorvue />,
    name: "Korvue",
  },
  {
    icon: <FA.FaLaravel />,
    name: "Laravel",
  },
  {
    icon: <FA.FaLastfmSquare />,
    name: "LastfmSquare",
  },
  {
    icon: <FA.FaLastfm />,
    name: "Lastfm",
  },
  {
    icon: <FA.FaLeanpub />,
    name: "Leanpub",
  },
  {
    icon: <FA.FaLess />,
    name: "Less",
  },
  {
    icon: <FA.FaLine />,
    name: "Line",
  },
  {
    icon: <FA.FaLinkedinIn />,
    name: "LinkedinIn",
  },
  {
    icon: <FA.FaLinkedin />,
    name: "Linkedin",
  },
  {
    icon: <FA.FaLinode />,
    name: "Linode",
  },
  {
    icon: <FA.FaLinux />,
    name: "Linux",
  },
  {
    icon: <FA.FaLyft />,
    name: "Lyft",
  },
  {
    icon: <FA.FaMagento />,
    name: "Magento",
  },
  {
    icon: <FA.FaMailchimp />,
    name: "Mailchimp",
  },
  {
    icon: <FA.FaMandalorian />,
    name: "Mandalorian",
  },
  {
    icon: <FA.FaMarkdown />,
    name: "Markdown",
  },
  {
    icon: <FA.FaMastodon />,
    name: "Mastodon",
  },
  {
    icon: <FA.FaMaxcdn />,
    name: "Maxcdn",
  },
  {
    icon: <FA.FaMdb />,
    name: "Mdb",
  },
  {
    icon: <FA.FaMedapps />,
    name: "Medapps",
  },
  {
    icon: <FA.FaMediumM />,
    name: "MediumM",
  },
  {
    icon: <FA.FaMedium />,
    name: "Medium",
  },
  {
    icon: <FA.FaMedrt />,
    name: "Medrt",
  },
  {
    icon: <FA.FaMeetup />,
    name: "Meetup",
  },
  {
    icon: <FA.FaMegaport />,
    name: "Megaport",
  },
  {
    icon: <FA.FaMendeley />,
    name: "Mendeley",
  },
  {
    icon: <FA.FaMicroblog />,
    name: "Microblog",
  },
  {
    icon: <FA.FaMicrosoft />,
    name: "Microsoft",
  },
  {
    icon: <FA.FaMix />,
    name: "Mix",
  },
  {
    icon: <FA.FaMixcloud />,
    name: "Mixcloud",
  },
  {
    icon: <FA.FaMixer />,
    name: "Mixer",
  },
  {
    icon: <FA.FaMizuni />,
    name: "Mizuni",
  },
  {
    icon: <FA.FaModx />,
    name: "Modx",
  },
  {
    icon: <FA.FaMonero />,
    name: "Monero",
  },
  {
    icon: <FA.FaNapster />,
    name: "Napster",
  },
  {
    icon: <FA.FaNeos />,
    name: "Neos",
  },
  {
    icon: <FA.FaNimblr />,
    name: "Nimblr",
  },
  {
    icon: <FA.FaNodeJs />,
    name: "NodeJs",
  },
  {
    icon: <FA.FaNode />,
    name: "Node",
  },
  {
    icon: <FA.FaNpm />,
    name: "Npm",
  },
  {
    icon: <FA.FaNs8 />,
    name: "Ns8",
  },
  {
    icon: <FA.FaNutritionix />,
    name: "Nutritionix",
  },
  {
    icon: <FA.FaOctopusDeploy />,
    name: "OctopusDeploy",
  },
  {
    icon: <FA.FaOdnoklassnikiSquare />,
    name: "OdnoklassnikiSquare",
  },
  {
    icon: <FA.FaOdnoklassniki />,
    name: "Odnoklassniki",
  },
  {
    icon: <FA.FaOldRepublic />,
    name: "OldRepublic",
  },
  {
    icon: <FA.FaOpencart />,
    name: "Opencart",
  },
  {
    icon: <FA.FaOpenid />,
    name: "Openid",
  },
  {
    icon: <FA.FaOpera />,
    name: "Opera",
  },
  {
    icon: <FA.FaOptinMonster />,
    name: "OptinMonster",
  },
  {
    icon: <FA.FaOrcid />,
    name: "Orcid",
  },
  {
    icon: <FA.FaOsi />,
    name: "Osi",
  },
  {
    icon: <FA.FaPage4 />,
    name: "Page4",
  },
  {
    icon: <FA.FaPagelines />,
    name: "Pagelines",
  },
  {
    icon: <FA.FaPalfed />,
    name: "Palfed",
  },
  {
    icon: <FA.FaPatreon />,
    name: "Patreon",
  },
  {
    icon: <FA.FaPaypal />,
    name: "Paypal",
  },
  {
    icon: <FA.FaPennyArcade />,
    name: "PennyArcade",
  },
  {
    icon: <FA.FaPerbyte />,
    name: "Perbyte",
  },
  {
    icon: <FA.FaPeriscope />,
    name: "Periscope",
  },
  {
    icon: <FA.FaPhabricator />,
    name: "Phabricator",
  },
  {
    icon: <FA.FaPhoenixFramework />,
    name: "PhoenixFramework",
  },
  {
    icon: <FA.FaPhoenixSquadron />,
    name: "PhoenixSquadron",
  },
  {
    icon: <FA.FaPhp />,
    name: "Php",
  },
  {
    icon: <FA.FaPiedPiperAlt />,
    name: "PiedPiperAlt",
  },
  {
    icon: <FA.FaPiedPiperHat />,
    name: "PiedPiperHat",
  },
  {
    icon: <FA.FaPiedPiperPp />,
    name: "PiedPiperPp",
  },
  {
    icon: <FA.FaPiedPiperSquare />,
    name: "PiedPiperSquare",
  },
  {
    icon: <FA.FaPiedPiper />,
    name: "PiedPiper",
  },
  {
    icon: <FA.FaPinterestP />,
    name: "PinterestP",
  },
  {
    icon: <FA.FaPinterestSquare />,
    name: "PinterestSquare",
  },
  {
    icon: <FA.FaPinterest />,
    name: "Pinterest",
  },
  {
    icon: <FA.FaPlaystation />,
    name: "Playstation",
  },
  {
    icon: <FA.FaProductHunt />,
    name: "ProductHunt",
  },
  {
    icon: <FA.FaPushed />,
    name: "Pushed",
  },
  {
    icon: <FA.FaPython />,
    name: "Python",
  },
  {
    icon: <FA.FaQq />,
    name: "Qq",
  },
  {
    icon: <FA.FaQuinscape />,
    name: "Quinscape",
  },
  {
    icon: <FA.FaQuora />,
    name: "Quora",
  },
  {
    icon: <FA.FaRProject />,
    name: "RProject",
  },
  {
    icon: <FA.FaRaspberryPi />,
    name: "RaspberryPi",
  },
  {
    icon: <FA.FaRavelry />,
    name: "Ravelry",
  },
  {
    icon: <FA.FaReact />,
    name: "React",
  },
  {
    icon: <FA.FaReacteurope />,
    name: "Reacteurope",
  },
  {
    icon: <FA.FaReadme />,
    name: "Readme",
  },
  {
    icon: <FA.FaRebel />,
    name: "Rebel",
  },
  {
    icon: <FA.FaRedRiver />,
    name: "RedRiver",
  },
  {
    icon: <FA.FaRedditAlien />,
    name: "RedditAlien",
  },
  {
    icon: <FA.FaRedditSquare />,
    name: "RedditSquare",
  },
  {
    icon: <FA.FaReddit />,
    name: "Reddit",
  },
  {
    icon: <FA.FaRedhat />,
    name: "Redhat",
  },
  {
    icon: <FA.FaRenren />,
    name: "Renren",
  },
  {
    icon: <FA.FaReplyd />,
    name: "Replyd",
  },
  {
    icon: <FA.FaResearchgate />,
    name: "Researchgate",
  },
  {
    icon: <FA.FaResolving />,
    name: "Resolving",
  },
  {
    icon: <FA.FaRev />,
    name: "Rev",
  },
  {
    icon: <FA.FaRocketchat />,
    name: "Rocketchat",
  },
  {
    icon: <FA.FaRockrms />,
    name: "Rockrms",
  },
  {
    icon: <FA.FaRust />,
    name: "Rust",
  },
  {
    icon: <FA.FaSafari />,
    name: "Safari",
  },
  {
    icon: <FA.FaSalesforce />,
    name: "Salesforce",
  },
  {
    icon: <FA.FaSass />,
    name: "Sass",
  },
  {
    icon: <FA.FaSchlix />,
    name: "Schlix",
  },
  {
    icon: <FA.FaScribd />,
    name: "Scribd",
  },
  {
    icon: <FA.FaSearchengin />,
    name: "Searchengin",
  },
  {
    icon: <FA.FaSellcast />,
    name: "Sellcast",
  },
  {
    icon: <FA.FaSellsy />,
    name: "Sellsy",
  },
  {
    icon: <FA.FaServicestack />,
    name: "Servicestack",
  },
  {
    icon: <FA.FaShirtsinbulk />,
    name: "Shirtsinbulk",
  },
  {
    icon: <FA.FaShopify />,
    name: "Shopify",
  },
  {
    icon: <FA.FaShopware />,
    name: "Shopware",
  },
  {
    icon: <FA.FaSimplybuilt />,
    name: "Simplybuilt",
  },
  {
    icon: <FA.FaSistrix />,
    name: "Sistrix",
  },
  {
    icon: <FA.FaSith />,
    name: "Sith",
  },
  {
    icon: <FA.FaSketch />,
    name: "Sketch",
  },
  {
    icon: <FA.FaSkyatlas />,
    name: "Skyatlas",
  },
  {
    icon: <FA.FaSkype />,
    name: "Skype",
  },
  {
    icon: <FA.FaSlackHash />,
    name: "SlackHash",
  },
  {
    icon: <FA.FaSlack />,
    name: "Slack",
  },
  {
    icon: <FA.FaSlideshare />,
    name: "Slideshare",
  },
  {
    icon: <FA.FaSnapchatGhost />,
    name: "SnapchatGhost",
  },
  {
    icon: <FA.FaSnapchatSquare />,
    name: "SnapchatSquare",
  },
  {
    icon: <FA.FaSnapchat />,
    name: "Snapchat",
  },
  {
    icon: <FA.FaSoundcloud />,
    name: "Soundcloud",
  },
  {
    icon: <FA.FaSourcetree />,
    name: "Sourcetree",
  },
  {
    icon: <FA.FaSpeakap />,
    name: "Speakap",
  },
  {
    icon: <FA.FaSpeakerDeck />,
    name: "SpeakerDeck",
  },
  {
    icon: <FA.FaSpotify />,
    name: "Spotify",
  },
  {
    icon: <FA.FaSquarespace />,
    name: "Squarespace",
  },
  {
    icon: <FA.FaStackExchange />,
    name: "StackExchange",
  },
  {
    icon: <FA.FaStackOverflow />,
    name: "StackOverflow",
  },
  {
    icon: <FA.FaStackpath />,
    name: "Stackpath",
  },
  {
    icon: <FA.FaStaylinked />,
    name: "Staylinked",
  },
  {
    icon: <FA.FaSteamSquare />,
    name: "SteamSquare",
  },
  {
    icon: <FA.FaSteamSymbol />,
    name: "SteamSymbol",
  },
  {
    icon: <FA.FaSteam />,
    name: "Steam",
  },
  {
    icon: <FA.FaStickerMule />,
    name: "StickerMule",
  },
  {
    icon: <FA.FaStrava />,
    name: "Strava",
  },
  {
    icon: <FA.FaStripeS />,
    name: "StripeS",
  },
  {
    icon: <FA.FaStripe />,
    name: "Stripe",
  },
  {
    icon: <FA.FaStudiovinari />,
    name: "Studiovinari",
  },
  {
    icon: <FA.FaStumbleuponCircle />,
    name: "StumbleuponCircle",
  },
  {
    icon: <FA.FaStumbleupon />,
    name: "Stumbleupon",
  },
  {
    icon: <FA.FaSuperpowers />,
    name: "Superpowers",
  },
  {
    icon: <FA.FaSupple />,
    name: "Supple",
  },
  {
    icon: <FA.FaSuse />,
    name: "Suse",
  },
  {
    icon: <FA.FaSwift />,
    name: "Swift",
  },
  {
    icon: <FA.FaSymfony />,
    name: "Symfony",
  },
  {
    icon: <FA.FaTeamspeak />,
    name: "Teamspeak",
  },
  {
    icon: <FA.FaTelegramPlane />,
    name: "TelegramPlane",
  },
  {
    icon: <FA.FaTelegram />,
    name: "Telegram",
  },
  {
    icon: <FA.FaTencentWeibo />,
    name: "TencentWeibo",
  },
  {
    icon: <FA.FaTheRedYeti />,
    name: "TheRedYeti",
  },
  {
    icon: <FA.FaThemeco />,
    name: "Themeco",
  },
  {
    icon: <FA.FaThemeisle />,
    name: "Themeisle",
  },
  {
    icon: <FA.FaThinkPeaks />,
    name: "ThinkPeaks",
  },
  {
    icon: <FA.FaTiktok />,
    name: "Tiktok",
  },
  {
    icon: <FA.FaTradeFederation />,
    name: "TradeFederation",
  },
  {
    icon: <FA.FaTrello />,
    name: "Trello",
  },
  {
    icon: <FA.FaTripadvisor />,
    name: "Tripadvisor",
  },
  {
    icon: <FA.FaTumblrSquare />,
    name: "TumblrSquare",
  },
  {
    icon: <FA.FaTumblr />,
    name: "Tumblr",
  },
  {
    icon: <FA.FaTwitch />,
    name: "Twitch",
  },
  {
    icon: <FA.FaTwitterSquare />,
    name: "TwitterSquare",
  },
  {
    icon: <FA.FaTwitter />,
    name: "Twitter",
  },
  {
    icon: <FA.FaTypo3 />,
    name: "Typo3",
  },
  {
    icon: <FA.FaUber />,
    name: "Uber",
  },
  {
    icon: <FA.FaUbuntu />,
    name: "Ubuntu",
  },
  {
    icon: <FA.FaUikit />,
    name: "Uikit",
  },
  {
    icon: <FA.FaUmbraco />,
    name: "Umbraco",
  },
  {
    icon: <FA.FaUncharted />,
    name: "Uncharted",
  },
  {
    icon: <FA.FaUniregistry />,
    name: "Uniregistry",
  },
  {
    icon: <FA.FaUnity />,
    name: "Unity",
  },
  {
    icon: <FA.FaUnsplash />,
    name: "Unsplash",
  },
  {
    icon: <FA.FaUntappd />,
    name: "Untappd",
  },
  {
    icon: <FA.FaUps />,
    name: "Ups",
  },
  {
    icon: <FA.FaUsb />,
    name: "Usb",
  },
  {
    icon: <FA.FaUsps />,
    name: "Usps",
  },
  {
    icon: <FA.FaUssunnah />,
    name: "Ussunnah",
  },
  {
    icon: <FA.FaVaadin />,
    name: "Vaadin",
  },
  {
    icon: <FA.FaViacoin />,
    name: "Viacoin",
  },
  {
    icon: <FA.FaViadeoSquare />,
    name: "ViadeoSquare",
  },
  {
    icon: <FA.FaViadeo />,
    name: "Viadeo",
  },
  {
    icon: <FA.FaViber />,
    name: "Viber",
  },
  {
    icon: <FA.FaVimeoSquare />,
    name: "VimeoSquare",
  },
  {
    icon: <FA.FaVimeoV />,
    name: "VimeoV",
  },
  {
    icon: <FA.FaVimeo />,
    name: "Vimeo",
  },
  {
    icon: <FA.FaVine />,
    name: "Vine",
  },
  {
    icon: <FA.FaVk />,
    name: "Vk",
  },
  {
    icon: <FA.FaVnv />,
    name: "Vnv",
  },
  {
    icon: <FA.FaVuejs />,
    name: "Vuejs",
  },
  {
    icon: <FA.FaWatchmanMonitoring />,
    name: "WatchmanMonitoring",
  },
  {
    icon: <FA.FaWaze />,
    name: "Waze",
  },
  {
    icon: <FA.FaWeebly />,
    name: "Weebly",
  },
  {
    icon: <FA.FaWeibo />,
    name: "Weibo",
  },
  {
    icon: <FA.FaWeixin />,
    name: "Weixin",
  },
  {
    icon: <FA.FaWhatsappSquare />,
    name: "WhatsappSquare",
  },
  {
    icon: <FA.FaWhatsapp />,
    name: "Whatsapp",
  },
  {
    icon: <FA.FaWhmcs />,
    name: "Whmcs",
  },
  {
    icon: <FA.FaWikipediaW />,
    name: "WikipediaW",
  },
  {
    icon: <FA.FaWindows />,
    name: "Windows",
  },
  {
    icon: <FA.FaWix />,
    name: "Wix",
  },
  {
    icon: <FA.FaWizardsOfTheCoast />,
    name: "WizardsOfTheCoast",
  },
  {
    icon: <FA.FaWodu />,
    name: "Wodu",
  },
  {
    icon: <FA.FaWolfPackBattalion />,
    name: "WolfPackBattalion",
  },
  {
    icon: <FA.FaWordpressSimple />,
    name: "WordpressSimple",
  },
  {
    icon: <FA.FaWordpress />,
    name: "Wordpress",
  },
  {
    icon: <FA.FaWpbeginner />,
    name: "Wpbeginner",
  },
  {
    icon: <FA.FaWpexplorer />,
    name: "Wpexplorer",
  },
  {
    icon: <FA.FaWpforms />,
    name: "Wpforms",
  },
  {
    icon: <FA.FaWpressr />,
    name: "Wpressr",
  },
  {
    icon: <FA.FaXbox />,
    name: "Xbox",
  },
  {
    icon: <FA.FaXingSquare />,
    name: "XingSquare",
  },
  {
    icon: <FA.FaXing />,
    name: "Xing",
  },
  {
    icon: <FA.FaYCombinator />,
    name: "YCombinator",
  },
  {
    icon: <FA.FaYahoo />,
    name: "Yahoo",
  },
  {
    icon: <FA.FaYammer />,
    name: "Yammer",
  },
  {
    icon: <FA.FaYandexInternational />,
    name: "YandexInternational",
  },
  {
    icon: <FA.FaYandex />,
    name: "Yandex",
  },
  {
    icon: <FA.FaYarn />,
    name: "Yarn",
  },
  {
    icon: <FA.FaYelp />,
    name: "Yelp",
  },
  {
    icon: <FA.FaYoast />,
    name: "Yoast",
  },
  {
    icon: <FA.FaYoutubeSquare />,
    name: "YoutubeSquare",
  },
  {
    icon: <FA.FaYoutube />,
    name: "Youtube",
  },
  {
    icon: <FA.FaZhihu />,
    name: "Zhihu",
  },
  {
    icon: <FA.FaAd />,
    name: "Ad",
  },
  {
    icon: <FA.FaAddressBook />,
    name: "AddressBook",
  },
  {
    icon: <FA.FaAddressCard />,
    name: "AddressCard",
  },
  {
    icon: <FA.FaAdjust />,
    name: "Adjust",
  },
  {
    icon: <FA.FaAirFreshener />,
    name: "AirFreshener",
  },
  {
    icon: <FA.FaAlignCenter />,
    name: "AlignCenter",
  },
  {
    icon: <FA.FaAlignJustify />,
    name: "AlignJustify",
  },
  {
    icon: <FA.FaAlignLeft />,
    name: "AlignLeft",
  },
  {
    icon: <FA.FaAlignRight />,
    name: "AlignRight",
  },
  {
    icon: <FA.FaAllergies />,
    name: "Allergies",
  },
  {
    icon: <FA.FaAmbulance />,
    name: "Ambulance",
  },
  {
    icon: <FA.FaAmericanSignLanguageInterpreting />,
    name: "AmericanSignLanguageInterpreting",
  },
  {
    icon: <FA.FaAnchor />,
    name: "Anchor",
  },
  {
    icon: <FA.FaAngleDoubleDown />,
    name: "AngleDoubleDown",
  },
  {
    icon: <FA.FaAngleDoubleLeft />,
    name: "AngleDoubleLeft",
  },
  {
    icon: <FA.FaAngleDoubleRight />,
    name: "AngleDoubleRight",
  },
  {
    icon: <FA.FaAngleDoubleUp />,
    name: "AngleDoubleUp",
  },
  {
    icon: <FA.FaAngleDown />,
    name: "AngleDown",
  },
  {
    icon: <FA.FaAngleLeft />,
    name: "AngleLeft",
  },
  {
    icon: <FA.FaAngleRight />,
    name: "AngleRight",
  },
  {
    icon: <FA.FaAngleUp />,
    name: "AngleUp",
  },
  {
    icon: <FA.FaAngry />,
    name: "Angry",
  },
  {
    icon: <FA.FaAnkh />,
    name: "Ankh",
  },
  {
    icon: <FA.FaAppleAlt />,
    name: "AppleAlt",
  },
  {
    icon: <FA.FaArchive />,
    name: "Archive",
  },
  {
    icon: <FA.FaArchway />,
    name: "Archway",
  },
  {
    icon: <FA.FaArrowAltCircleDown />,
    name: "ArrowAltCircleDown",
  },
  {
    icon: <FA.FaArrowAltCircleLeft />,
    name: "ArrowAltCircleLeft",
  },
  {
    icon: <FA.FaArrowAltCircleRight />,
    name: "ArrowAltCircleRight",
  },
  {
    icon: <FA.FaArrowAltCircleUp />,
    name: "ArrowAltCircleUp",
  },
  {
    icon: <FA.FaArrowCircleDown />,
    name: "ArrowCircleDown",
  },
  {
    icon: <FA.FaArrowCircleLeft />,
    name: "ArrowCircleLeft",
  },
  {
    icon: <FA.FaArrowCircleRight />,
    name: "ArrowCircleRight",
  },
  {
    icon: <FA.FaArrowCircleUp />,
    name: "ArrowCircleUp",
  },
  {
    icon: <FA.FaArrowDown />,
    name: "ArrowDown",
  },
  {
    icon: <FA.FaArrowLeft />,
    name: "ArrowLeft",
  },
  {
    icon: <FA.FaArrowRight />,
    name: "ArrowRight",
  },
  {
    icon: <FA.FaArrowUp />,
    name: "ArrowUp",
  },
  {
    icon: <FA.FaArrowsAltH />,
    name: "ArrowsAltH",
  },
  {
    icon: <FA.FaArrowsAltV />,
    name: "ArrowsAltV",
  },
  {
    icon: <FA.FaArrowsAlt />,
    name: "ArrowsAlt",
  },
  {
    icon: <FA.FaAssistiveListeningSystems />,
    name: "AssistiveListeningSystems",
  },
  {
    icon: <FA.FaAsterisk />,
    name: "Asterisk",
  },
  {
    icon: <FA.FaAt />,
    name: "At",
  },
  {
    icon: <FA.FaAtlas />,
    name: "Atlas",
  },
  {
    icon: <FA.FaAtom />,
    name: "Atom",
  },
  {
    icon: <FA.FaAudioDescription />,
    name: "AudioDescription",
  },
  {
    icon: <FA.FaAward />,
    name: "Award",
  },
  {
    icon: <FA.FaBabyCarriage />,
    name: "BabyCarriage",
  },
  {
    icon: <FA.FaBaby />,
    name: "Baby",
  },
  {
    icon: <FA.FaBackspace />,
    name: "Backspace",
  },
  {
    icon: <FA.FaBackward />,
    name: "Backward",
  },
  {
    icon: <FA.FaBacon />,
    name: "Bacon",
  },
  {
    icon: <FA.FaBacteria />,
    name: "Bacteria",
  },
  {
    icon: <FA.FaBacterium />,
    name: "Bacterium",
  },
  {
    icon: <FA.FaBahai />,
    name: "Bahai",
  },
  {
    icon: <FA.FaBalanceScaleLeft />,
    name: "BalanceScaleLeft",
  },
  {
    icon: <FA.FaBalanceScaleRight />,
    name: "BalanceScaleRight",
  },
  {
    icon: <FA.FaBalanceScale />,
    name: "BalanceScale",
  },
  {
    icon: <FA.FaBan />,
    name: "Ban",
  },
  {
    icon: <FA.FaBandAid />,
    name: "BandAid",
  },
  {
    icon: <FA.FaBarcode />,
    name: "Barcode",
  },
  {
    icon: <FA.FaBars />,
    name: "Bars",
  },
  {
    icon: <FA.FaBaseballBall />,
    name: "BaseballBall",
  },
  {
    icon: <FA.FaBasketballBall />,
    name: "BasketballBall",
  },
  {
    icon: <FA.FaBath />,
    name: "Bath",
  },
  {
    icon: <FA.FaBatteryEmpty />,
    name: "BatteryEmpty",
  },
  {
    icon: <FA.FaBatteryFull />,
    name: "BatteryFull",
  },
  {
    icon: <FA.FaBatteryHalf />,
    name: "BatteryHalf",
  },
  {
    icon: <FA.FaBatteryQuarter />,
    name: "BatteryQuarter",
  },
  {
    icon: <FA.FaBatteryThreeQuarters />,
    name: "BatteryThreeQuarters",
  },
  {
    icon: <FA.FaBed />,
    name: "Bed",
  },
  {
    icon: <FA.FaBeer />,
    name: "Beer",
  },
  {
    icon: <FA.FaBellSlash />,
    name: "BellSlash",
  },
  {
    icon: <FA.FaBell />,
    name: "Bell",
  },
  {
    icon: <FA.FaBezierCurve />,
    name: "BezierCurve",
  },
  {
    icon: <FA.FaBible />,
    name: "Bible",
  },
  {
    icon: <FA.FaBicycle />,
    name: "Bicycle",
  },
  {
    icon: <FA.FaBiking />,
    name: "Biking",
  },
  {
    icon: <FA.FaBinoculars />,
    name: "Binoculars",
  },
  {
    icon: <FA.FaBiohazard />,
    name: "Biohazard",
  },
  {
    icon: <FA.FaBirthdayCake />,
    name: "BirthdayCake",
  },
  {
    icon: <FA.FaBlenderPhone />,
    name: "BlenderPhone",
  },
  {
    icon: <FA.FaBlender />,
    name: "Blender",
  },
  {
    icon: <FA.FaBlind />,
    name: "Blind",
  },
  {
    icon: <FA.FaBlog />,
    name: "Blog",
  },
  {
    icon: <FA.FaBold />,
    name: "Bold",
  },
  {
    icon: <FA.FaBolt />,
    name: "Bolt",
  },
  {
    icon: <FA.FaBomb />,
    name: "Bomb",
  },
  {
    icon: <FA.FaBone />,
    name: "Bone",
  },
  {
    icon: <FA.FaBong />,
    name: "Bong",
  },
  {
    icon: <FA.FaBookDead />,
    name: "BookDead",
  },
  {
    icon: <FA.FaBookMedical />,
    name: "BookMedical",
  },
  {
    icon: <FA.FaBookOpen />,
    name: "BookOpen",
  },
  {
    icon: <FA.FaBookReader />,
    name: "BookReader",
  },
  {
    icon: <FA.FaBook />,
    name: "Book",
  },
  {
    icon: <FA.FaBookmark />,
    name: "Bookmark",
  },
  {
    icon: <FA.FaBorderAll />,
    name: "BorderAll",
  },
  {
    icon: <FA.FaBorderNone />,
    name: "BorderNone",
  },
  {
    icon: <FA.FaBorderStyle />,
    name: "BorderStyle",
  },
  {
    icon: <FA.FaBowlingBall />,
    name: "BowlingBall",
  },
  {
    icon: <FA.FaBoxOpen />,
    name: "BoxOpen",
  },
  {
    icon: <FA.FaBoxTissue />,
    name: "BoxTissue",
  },
  {
    icon: <FA.FaBox />,
    name: "Box",
  },
  {
    icon: <FA.FaBoxes />,
    name: "Boxes",
  },
  {
    icon: <FA.FaBraille />,
    name: "Braille",
  },
  {
    icon: <FA.FaBrain />,
    name: "Brain",
  },
  {
    icon: <FA.FaBreadSlice />,
    name: "BreadSlice",
  },
  {
    icon: <FA.FaBriefcaseMedical />,
    name: "BriefcaseMedical",
  },
  {
    icon: <FA.FaBriefcase />,
    name: "Briefcase",
  },
  {
    icon: <FA.FaBroadcastTower />,
    name: "BroadcastTower",
  },
  {
    icon: <FA.FaBroom />,
    name: "Broom",
  },
  {
    icon: <FA.FaBrush />,
    name: "Brush",
  },
  {
    icon: <FA.FaBug />,
    name: "Bug",
  },
  {
    icon: <FA.FaBuilding />,
    name: "Building",
  },
  {
    icon: <FA.FaBullhorn />,
    name: "Bullhorn",
  },
  {
    icon: <FA.FaBullseye />,
    name: "Bullseye",
  },
  {
    icon: <FA.FaBurn />,
    name: "Burn",
  },
  {
    icon: <FA.FaBusAlt />,
    name: "BusAlt",
  },
  {
    icon: <FA.FaBus />,
    name: "Bus",
  },
  {
    icon: <FA.FaBusinessTime />,
    name: "BusinessTime",
  },
  {
    icon: <FA.FaCalculator />,
    name: "Calculator",
  },
  {
    icon: <FA.FaCalendarAlt />,
    name: "CalendarAlt",
  },
  {
    icon: <FA.FaCalendarCheck />,
    name: "CalendarCheck",
  },
  {
    icon: <FA.FaCalendarDay />,
    name: "CalendarDay",
  },
  {
    icon: <FA.FaCalendarMinus />,
    name: "CalendarMinus",
  },
  {
    icon: <FA.FaCalendarPlus />,
    name: "CalendarPlus",
  },
  {
    icon: <FA.FaCalendarTimes />,
    name: "CalendarTimes",
  },
  {
    icon: <FA.FaCalendarWeek />,
    name: "CalendarWeek",
  },
  {
    icon: <FA.FaCalendar />,
    name: "Calendar",
  },
  {
    icon: <FA.FaCameraRetro />,
    name: "CameraRetro",
  },
  {
    icon: <FA.FaCamera />,
    name: "Camera",
  },
  {
    icon: <FA.FaCampground />,
    name: "Campground",
  },
  {
    icon: <FA.FaCandyCane />,
    name: "CandyCane",
  },
  {
    icon: <FA.FaCannabis />,
    name: "Cannabis",
  },
  {
    icon: <FA.FaCapsules />,
    name: "Capsules",
  },
  {
    icon: <FA.FaCarAlt />,
    name: "CarAlt",
  },
  {
    icon: <FA.FaCarBattery />,
    name: "CarBattery",
  },
  {
    icon: <FA.FaCarCrash />,
    name: "CarCrash",
  },
  {
    icon: <FA.FaCarSide />,
    name: "CarSide",
  },
  {
    icon: <FA.FaCar />,
    name: "Car",
  },
  {
    icon: <FA.FaCaravan />,
    name: "Caravan",
  },
  {
    icon: <FA.FaCaretDown />,
    name: "CaretDown",
  },
  {
    icon: <FA.FaCaretLeft />,
    name: "CaretLeft",
  },
  {
    icon: <FA.FaCaretRight />,
    name: "CaretRight",
  },
  {
    icon: <FA.FaCaretSquareDown />,
    name: "CaretSquareDown",
  },
  {
    icon: <FA.FaCaretSquareLeft />,
    name: "CaretSquareLeft",
  },
  {
    icon: <FA.FaCaretSquareRight />,
    name: "CaretSquareRight",
  },
  {
    icon: <FA.FaCaretSquareUp />,
    name: "CaretSquareUp",
  },
  {
    icon: <FA.FaCaretUp />,
    name: "CaretUp",
  },
  {
    icon: <FA.FaCarrot />,
    name: "Carrot",
  },
  {
    icon: <FA.FaCartArrowDown />,
    name: "CartArrowDown",
  },
  {
    icon: <FA.FaCartPlus />,
    name: "CartPlus",
  },
  {
    icon: <FA.FaCashRegister />,
    name: "CashRegister",
  },
  {
    icon: <FA.FaCat />,
    name: "Cat",
  },
  {
    icon: <FA.FaCertificate />,
    name: "Certificate",
  },
  {
    icon: <FA.FaChair />,
    name: "Chair",
  },
  {
    icon: <FA.FaChalkboardTeacher />,
    name: "ChalkboardTeacher",
  },
  {
    icon: <FA.FaChalkboard />,
    name: "Chalkboard",
  },
  {
    icon: <FA.FaChargingStation />,
    name: "ChargingStation",
  },
  {
    icon: <FA.FaChartArea />,
    name: "ChartArea",
  },
  {
    icon: <FA.FaChartBar />,
    name: "ChartBar",
  },
  {
    icon: <FA.FaChartLine />,
    name: "ChartLine",
  },
  {
    icon: <FA.FaChartPie />,
    name: "ChartPie",
  },
  {
    icon: <FA.FaCheckCircle />,
    name: "CheckCircle",
  },
  {
    icon: <FA.FaCheckDouble />,
    name: "CheckDouble",
  },
  {
    icon: <FA.FaCheckSquare />,
    name: "CheckSquare",
  },
  {
    icon: <FA.FaCheck />,
    name: "Check",
  },
  {
    icon: <FA.FaCheese />,
    name: "Cheese",
  },
  {
    icon: <FA.FaChessBishop />,
    name: "ChessBishop",
  },
  {
    icon: <FA.FaChessBoard />,
    name: "ChessBoard",
  },
  {
    icon: <FA.FaChessKing />,
    name: "ChessKing",
  },
  {
    icon: <FA.FaChessKnight />,
    name: "ChessKnight",
  },
  {
    icon: <FA.FaChessPawn />,
    name: "ChessPawn",
  },
  {
    icon: <FA.FaChessQueen />,
    name: "ChessQueen",
  },
  {
    icon: <FA.FaChessRook />,
    name: "ChessRook",
  },
  {
    icon: <FA.FaChess />,
    name: "Chess",
  },
  {
    icon: <FA.FaChevronCircleDown />,
    name: "ChevronCircleDown",
  },
  {
    icon: <FA.FaChevronCircleLeft />,
    name: "ChevronCircleLeft",
  },
  {
    icon: <FA.FaChevronCircleRight />,
    name: "ChevronCircleRight",
  },
  {
    icon: <FA.FaChevronCircleUp />,
    name: "ChevronCircleUp",
  },
  {
    icon: <FA.FaChevronDown />,
    name: "ChevronDown",
  },
  {
    icon: <FA.FaChevronLeft />,
    name: "ChevronLeft",
  },
  {
    icon: <FA.FaChevronRight />,
    name: "ChevronRight",
  },
  {
    icon: <FA.FaChevronUp />,
    name: "ChevronUp",
  },
  {
    icon: <FA.FaChild />,
    name: "Child",
  },
  {
    icon: <FA.FaChurch />,
    name: "Church",
  },
  {
    icon: <FA.FaCircleNotch />,
    name: "CircleNotch",
  },
  {
    icon: <FA.FaCircle />,
    name: "Circle",
  },
  {
    icon: <FA.FaCity />,
    name: "City",
  },
  {
    icon: <FA.FaClinicMedical />,
    name: "ClinicMedical",
  },
  {
    icon: <FA.FaClipboardCheck />,
    name: "ClipboardCheck",
  },
  {
    icon: <FA.FaClipboardList />,
    name: "ClipboardList",
  },
  {
    icon: <FA.FaClipboard />,
    name: "Clipboard",
  },
  {
    icon: <FA.FaClock />,
    name: "Clock",
  },
  {
    icon: <FA.FaClone />,
    name: "Clone",
  },
  {
    icon: <FA.FaClosedCaptioning />,
    name: "ClosedCaptioning",
  },
  {
    icon: <FA.FaCloudDownloadAlt />,
    name: "CloudDownloadAlt",
  },
  {
    icon: <FA.FaCloudMeatball />,
    name: "CloudMeatball",
  },
  {
    icon: <FA.FaCloudMoonRain />,
    name: "CloudMoonRain",
  },
  {
    icon: <FA.FaCloudMoon />,
    name: "CloudMoon",
  },
  {
    icon: <FA.FaCloudRain />,
    name: "CloudRain",
  },
  {
    icon: <FA.FaCloudShowersHeavy />,
    name: "CloudShowersHeavy",
  },
  {
    icon: <FA.FaCloudSunRain />,
    name: "CloudSunRain",
  },
  {
    icon: <FA.FaCloudSun />,
    name: "CloudSun",
  },
  {
    icon: <FA.FaCloudUploadAlt />,
    name: "CloudUploadAlt",
  },
  {
    icon: <FA.FaCloud />,
    name: "Cloud",
  },
  {
    icon: <FA.FaCocktail />,
    name: "Cocktail",
  },
  {
    icon: <FA.FaCodeBranch />,
    name: "CodeBranch",
  },
  {
    icon: <FA.FaCode />,
    name: "Code",
  },
  {
    icon: <FA.FaCoffee />,
    name: "Coffee",
  },
  {
    icon: <FA.FaCog />,
    name: "Cog",
  },
  {
    icon: <FA.FaCogs />,
    name: "Cogs",
  },
  {
    icon: <FA.FaCoins />,
    name: "Coins",
  },
  {
    icon: <FA.FaColumns />,
    name: "Columns",
  },
  {
    icon: <FA.FaCommentAlt />,
    name: "CommentAlt",
  },
  {
    icon: <FA.FaCommentDollar />,
    name: "CommentDollar",
  },
  {
    icon: <FA.FaCommentDots />,
    name: "CommentDots",
  },
  {
    icon: <FA.FaCommentMedical />,
    name: "CommentMedical",
  },
  {
    icon: <FA.FaCommentSlash />,
    name: "CommentSlash",
  },
  {
    icon: <FA.FaComment />,
    name: "Comment",
  },
  {
    icon: <FA.FaCommentsDollar />,
    name: "CommentsDollar",
  },
  {
    icon: <FA.FaComments />,
    name: "Comments",
  },
  {
    icon: <FA.FaCompactDisc />,
    name: "CompactDisc",
  },
  {
    icon: <FA.FaCompass />,
    name: "Compass",
  },
  {
    icon: <FA.FaCompressAlt />,
    name: "CompressAlt",
  },
  {
    icon: <FA.FaCompressArrowsAlt />,
    name: "CompressArrowsAlt",
  },
  {
    icon: <FA.FaCompress />,
    name: "Compress",
  },
  {
    icon: <FA.FaConciergeBell />,
    name: "ConciergeBell",
  },
  {
    icon: <FA.FaCookieBite />,
    name: "CookieBite",
  },
  {
    icon: <FA.FaCookie />,
    name: "Cookie",
  },
  {
    icon: <FA.FaCopy />,
    name: "Copy",
  },
  {
    icon: <FA.FaCopyright />,
    name: "Copyright",
  },
  {
    icon: <FA.FaCouch />,
    name: "Couch",
  },
  {
    icon: <FA.FaCreditCard />,
    name: "CreditCard",
  },
  {
    icon: <FA.FaCropAlt />,
    name: "CropAlt",
  },
  {
    icon: <FA.FaCrop />,
    name: "Crop",
  },
  {
    icon: <FA.FaCross />,
    name: "Cross",
  },
  {
    icon: <FA.FaCrosshairs />,
    name: "Crosshairs",
  },
  {
    icon: <FA.FaCrow />,
    name: "Crow",
  },
  {
    icon: <FA.FaCrown />,
    name: "Crown",
  },
  {
    icon: <FA.FaCrutch />,
    name: "Crutch",
  },
  {
    icon: <FA.FaCube />,
    name: "Cube",
  },
  {
    icon: <FA.FaCubes />,
    name: "Cubes",
  },
  {
    icon: <FA.FaCut />,
    name: "Cut",
  },
  {
    icon: <FA.FaDatabase />,
    name: "Database",
  },
  {
    icon: <FA.FaDeaf />,
    name: "Deaf",
  },
  {
    icon: <FA.FaDemocrat />,
    name: "Democrat",
  },
  {
    icon: <FA.FaDesktop />,
    name: "Desktop",
  },
  {
    icon: <FA.FaDharmachakra />,
    name: "Dharmachakra",
  },
  {
    icon: <FA.FaDiagnoses />,
    name: "Diagnoses",
  },
  {
    icon: <FA.FaDiceD20 />,
    name: "DiceD20",
  },
  {
    icon: <FA.FaDiceD6 />,
    name: "DiceD6",
  },
  {
    icon: <FA.FaDiceFive />,
    name: "DiceFive",
  },
  {
    icon: <FA.FaDiceFour />,
    name: "DiceFour",
  },
  {
    icon: <FA.FaDiceOne />,
    name: "DiceOne",
  },
  {
    icon: <FA.FaDiceSix />,
    name: "DiceSix",
  },
  {
    icon: <FA.FaDiceThree />,
    name: "DiceThree",
  },
  {
    icon: <FA.FaDiceTwo />,
    name: "DiceTwo",
  },
  {
    icon: <FA.FaDice />,
    name: "Dice",
  },
  {
    icon: <FA.FaDigitalTachograph />,
    name: "DigitalTachograph",
  },
  {
    icon: <FA.FaDirections />,
    name: "Directions",
  },
  {
    icon: <FA.FaDisease />,
    name: "Disease",
  },
  {
    icon: <FA.FaDivide />,
    name: "Divide",
  },
  {
    icon: <FA.FaDizzy />,
    name: "Dizzy",
  },
  {
    icon: <FA.FaDna />,
    name: "Dna",
  },
  {
    icon: <FA.FaDog />,
    name: "Dog",
  },
  {
    icon: <FA.FaDollarSign />,
    name: "DollarSign",
  },
  {
    icon: <FA.FaDollyFlatbed />,
    name: "DollyFlatbed",
  },
  {
    icon: <FA.FaDolly />,
    name: "Dolly",
  },
  {
    icon: <FA.FaDonate />,
    name: "Donate",
  },
  {
    icon: <FA.FaDoorClosed />,
    name: "DoorClosed",
  },
  {
    icon: <FA.FaDoorOpen />,
    name: "DoorOpen",
  },
  {
    icon: <FA.FaDotCircle />,
    name: "DotCircle",
  },
  {
    icon: <FA.FaDove />,
    name: "Dove",
  },
  {
    icon: <FA.FaDownload />,
    name: "Download",
  },
  {
    icon: <FA.FaDraftingCompass />,
    name: "DraftingCompass",
  },
  {
    icon: <FA.FaDragon />,
    name: "Dragon",
  },
  {
    icon: <FA.FaDrawPolygon />,
    name: "DrawPolygon",
  },
  {
    icon: <FA.FaDrumSteelpan />,
    name: "DrumSteelpan",
  },
  {
    icon: <FA.FaDrum />,
    name: "Drum",
  },
  {
    icon: <FA.FaDrumstickBite />,
    name: "DrumstickBite",
  },
  {
    icon: <FA.FaDumbbell />,
    name: "Dumbbell",
  },
  {
    icon: <FA.FaDumpsterFire />,
    name: "DumpsterFire",
  },
  {
    icon: <FA.FaDumpster />,
    name: "Dumpster",
  },
  {
    icon: <FA.FaDungeon />,
    name: "Dungeon",
  },
  {
    icon: <FA.FaEdit />,
    name: "Edit",
  },
  {
    icon: <FA.FaEgg />,
    name: "Egg",
  },
  {
    icon: <FA.FaEject />,
    name: "Eject",
  },
  {
    icon: <FA.FaEllipsisH />,
    name: "EllipsisH",
  },
  {
    icon: <FA.FaEllipsisV />,
    name: "EllipsisV",
  },
  {
    icon: <FA.FaEnvelopeOpenText />,
    name: "EnvelopeOpenText",
  },
  {
    icon: <FA.FaEnvelopeOpen />,
    name: "EnvelopeOpen",
  },
  {
    icon: <FA.FaEnvelopeSquare />,
    name: "EnvelopeSquare",
  },
  {
    icon: <FA.FaEnvelope />,
    name: "Envelope",
  },
  {
    icon: <FA.FaEquals />,
    name: "Equals",
  },
  {
    icon: <FA.FaEraser />,
    name: "Eraser",
  },
  {
    icon: <FA.FaEthernet />,
    name: "Ethernet",
  },
  {
    icon: <FA.FaEuroSign />,
    name: "EuroSign",
  },
  {
    icon: <FA.FaExchangeAlt />,
    name: "ExchangeAlt",
  },
  {
    icon: <FA.FaExclamationCircle />,
    name: "ExclamationCircle",
  },
  {
    icon: <FA.FaExclamationTriangle />,
    name: "ExclamationTriangle",
  },
  {
    icon: <FA.FaExclamation />,
    name: "Exclamation",
  },
  {
    icon: <FA.FaExpandAlt />,
    name: "ExpandAlt",
  },
  {
    icon: <FA.FaExpandArrowsAlt />,
    name: "ExpandArrowsAlt",
  },
  {
    icon: <FA.FaExpand />,
    name: "Expand",
  },
  {
    icon: <FA.FaExternalLinkAlt />,
    name: "ExternalLinkAlt",
  },
  {
    icon: <FA.FaExternalLinkSquareAlt />,
    name: "ExternalLinkSquareAlt",
  },
  {
    icon: <FA.FaEyeDropper />,
    name: "EyeDropper",
  },
  {
    icon: <FA.FaEyeSlash />,
    name: "EyeSlash",
  },
  {
    icon: <FA.FaEye />,
    name: "Eye",
  },
  {
    icon: <FA.FaFan />,
    name: "Fan",
  },
  {
    icon: <FA.FaFastBackward />,
    name: "FastBackward",
  },
  {
    icon: <FA.FaFastForward />,
    name: "FastForward",
  },
  {
    icon: <FA.FaFaucet />,
    name: "Faucet",
  },
  {
    icon: <FA.FaFax />,
    name: "Fax",
  },
  {
    icon: <FA.FaFeatherAlt />,
    name: "FeatherAlt",
  },
  {
    icon: <FA.FaFeather />,
    name: "Feather",
  },
  {
    icon: <FA.FaFemale />,
    name: "Female",
  },
  {
    icon: <FA.FaFighterJet />,
    name: "FighterJet",
  },
  {
    icon: <FA.FaFileAlt />,
    name: "FileAlt",
  },
  {
    icon: <FA.FaFileArchive />,
    name: "FileArchive",
  },
  {
    icon: <FA.FaFileAudio />,
    name: "FileAudio",
  },
  {
    icon: <FA.FaFileCode />,
    name: "FileCode",
  },
  {
    icon: <FA.FaFileContract />,
    name: "FileContract",
  },
  {
    icon: <FA.FaFileCsv />,
    name: "FileCsv",
  },
  {
    icon: <FA.FaFileDownload />,
    name: "FileDownload",
  },
  {
    icon: <FA.FaFileExcel />,
    name: "FileExcel",
  },
  {
    icon: <FA.FaFileExport />,
    name: "FileExport",
  },
  {
    icon: <FA.FaFileImage />,
    name: "FileImage",
  },
  {
    icon: <FA.FaFileImport />,
    name: "FileImport",
  },
  {
    icon: <FA.FaFileInvoiceDollar />,
    name: "FileInvoiceDollar",
  },
  {
    icon: <FA.FaFileInvoice />,
    name: "FileInvoice",
  },
  {
    icon: <FA.FaFileMedicalAlt />,
    name: "FileMedicalAlt",
  },
  {
    icon: <FA.FaFileMedical />,
    name: "FileMedical",
  },
  {
    icon: <FA.FaFilePdf />,
    name: "FilePdf",
  },
  {
    icon: <FA.FaFilePowerpoint />,
    name: "FilePowerpoint",
  },
  {
    icon: <FA.FaFilePrescription />,
    name: "FilePrescription",
  },
  {
    icon: <FA.FaFileSignature />,
    name: "FileSignature",
  },
  {
    icon: <FA.FaFileUpload />,
    name: "FileUpload",
  },
  {
    icon: <FA.FaFileVideo />,
    name: "FileVideo",
  },
  {
    icon: <FA.FaFileWord />,
    name: "FileWord",
  },
  {
    icon: <FA.FaFile />,
    name: "File",
  },
  {
    icon: <FA.FaFillDrip />,
    name: "FillDrip",
  },
  {
    icon: <FA.FaFill />,
    name: "Fill",
  },
  {
    icon: <FA.FaFilm />,
    name: "Film",
  },
  {
    icon: <FA.FaFilter />,
    name: "Filter",
  },
  {
    icon: <FA.FaFingerprint />,
    name: "Fingerprint",
  },
  {
    icon: <FA.FaFireAlt />,
    name: "FireAlt",
  },
  {
    icon: <FA.FaFireExtinguisher />,
    name: "FireExtinguisher",
  },
  {
    icon: <FA.FaFire />,
    name: "Fire",
  },
  {
    icon: <FA.FaFirstAid />,
    name: "FirstAid",
  },
  {
    icon: <FA.FaFish />,
    name: "Fish",
  },
  {
    icon: <FA.FaFistRaised />,
    name: "FistRaised",
  },
  {
    icon: <FA.FaFlagCheckered />,
    name: "FlagCheckered",
  },
  {
    icon: <FA.FaFlagUsa />,
    name: "FlagUsa",
  },
  {
    icon: <FA.FaFlag />,
    name: "Flag",
  },
  {
    icon: <FA.FaFlask />,
    name: "Flask",
  },
  {
    icon: <FA.FaFlushed />,
    name: "Flushed",
  },
  {
    icon: <FA.FaFolderMinus />,
    name: "FolderMinus",
  },
  {
    icon: <FA.FaFolderOpen />,
    name: "FolderOpen",
  },
  {
    icon: <FA.FaFolderPlus />,
    name: "FolderPlus",
  },
  {
    icon: <FA.FaFolder />,
    name: "Folder",
  },
  {
    icon: <FA.FaFont />,
    name: "Font",
  },
  {
    icon: <FA.FaFootballBall />,
    name: "FootballBall",
  },
  {
    icon: <FA.FaForward />,
    name: "Forward",
  },
  {
    icon: <FA.FaFrog />,
    name: "Frog",
  },
  {
    icon: <FA.FaFrownOpen />,
    name: "FrownOpen",
  },
  {
    icon: <FA.FaFrown />,
    name: "Frown",
  },
  {
    icon: <FA.FaFunnelDollar />,
    name: "FunnelDollar",
  },
  {
    icon: <FA.FaFutbol />,
    name: "Futbol",
  },
  {
    icon: <FA.FaGamepad />,
    name: "Gamepad",
  },
  {
    icon: <FA.FaGasPump />,
    name: "GasPump",
  },
  {
    icon: <FA.FaGavel />,
    name: "Gavel",
  },
  {
    icon: <FA.FaGem />,
    name: "Gem",
  },
  {
    icon: <FA.FaGenderless />,
    name: "Genderless",
  },
  {
    icon: <FA.FaGhost />,
    name: "Ghost",
  },
  {
    icon: <FA.FaGift />,
    name: "Gift",
  },
  {
    icon: <FA.FaGifts />,
    name: "Gifts",
  },
  {
    icon: <FA.FaGlassCheers />,
    name: "GlassCheers",
  },
  {
    icon: <FA.FaGlassMartiniAlt />,
    name: "GlassMartiniAlt",
  },
  {
    icon: <FA.FaGlassMartini />,
    name: "GlassMartini",
  },
  {
    icon: <FA.FaGlassWhiskey />,
    name: "GlassWhiskey",
  },
  {
    icon: <FA.FaGlasses />,
    name: "Glasses",
  },
  {
    icon: <FA.FaGlobeAfrica />,
    name: "GlobeAfrica",
  },
  {
    icon: <FA.FaGlobeAmericas />,
    name: "GlobeAmericas",
  },
  {
    icon: <FA.FaGlobeAsia />,
    name: "GlobeAsia",
  },
  {
    icon: <FA.FaGlobeEurope />,
    name: "GlobeEurope",
  },
  {
    icon: <FA.FaGlobe />,
    name: "Globe",
  },
  {
    icon: <FA.FaGolfBall />,
    name: "GolfBall",
  },
  {
    icon: <FA.FaGopuram />,
    name: "Gopuram",
  },
  {
    icon: <FA.FaGraduationCap />,
    name: "GraduationCap",
  },
  {
    icon: <FA.FaGreaterThanEqual />,
    name: "GreaterThanEqual",
  },
  {
    icon: <FA.FaGreaterThan />,
    name: "GreaterThan",
  },
  {
    icon: <FA.FaGrimace />,
    name: "Grimace",
  },
  {
    icon: <FA.FaGrinAlt />,
    name: "GrinAlt",
  },
  {
    icon: <FA.FaGrinBeamSweat />,
    name: "GrinBeamSweat",
  },
  {
    icon: <FA.FaGrinBeam />,
    name: "GrinBeam",
  },
  {
    icon: <FA.FaGrinHearts />,
    name: "GrinHearts",
  },
  {
    icon: <FA.FaGrinSquintTears />,
    name: "GrinSquintTears",
  },
  {
    icon: <FA.FaGrinSquint />,
    name: "GrinSquint",
  },
  {
    icon: <FA.FaGrinStars />,
    name: "GrinStars",
  },
  {
    icon: <FA.FaGrinTears />,
    name: "GrinTears",
  },
  {
    icon: <FA.FaGrinTongueSquint />,
    name: "GrinTongueSquint",
  },
  {
    icon: <FA.FaGrinTongueWink />,
    name: "GrinTongueWink",
  },
  {
    icon: <FA.FaGrinTongue />,
    name: "GrinTongue",
  },
  {
    icon: <FA.FaGrinWink />,
    name: "GrinWink",
  },
  {
    icon: <FA.FaGrin />,
    name: "Grin",
  },
  {
    icon: <FA.FaGripHorizontal />,
    name: "GripHorizontal",
  },
  {
    icon: <FA.FaGripLinesVertical />,
    name: "GripLinesVertical",
  },
  {
    icon: <FA.FaGripLines />,
    name: "GripLines",
  },
  {
    icon: <FA.FaGripVertical />,
    name: "GripVertical",
  },
  {
    icon: <FA.FaGuitar />,
    name: "Guitar",
  },
  {
    icon: <FA.FaHSquare />,
    name: "HSquare",
  },
  {
    icon: <FA.FaHamburger />,
    name: "Hamburger",
  },
  {
    icon: <FA.FaHammer />,
    name: "Hammer",
  },
  {
    icon: <FA.FaHamsa />,
    name: "Hamsa",
  },
  {
    icon: <FA.FaHandHoldingHeart />,
    name: "HandHoldingHeart",
  },
  {
    icon: <FA.FaHandHoldingMedical />,
    name: "HandHoldingMedical",
  },
  {
    icon: <FA.FaHandHoldingUsd />,
    name: "HandHoldingUsd",
  },
  {
    icon: <FA.FaHandHoldingWater />,
    name: "HandHoldingWater",
  },
  {
    icon: <FA.FaHandHolding />,
    name: "HandHolding",
  },
  {
    icon: <FA.FaHandLizard />,
    name: "HandLizard",
  },
  {
    icon: <FA.FaHandMiddleFinger />,
    name: "HandMiddleFinger",
  },
  {
    icon: <FA.FaHandPaper />,
    name: "HandPaper",
  },
  {
    icon: <FA.FaHandPeace />,
    name: "HandPeace",
  },
  {
    icon: <FA.FaHandPointDown />,
    name: "HandPointDown",
  },
  {
    icon: <FA.FaHandPointLeft />,
    name: "HandPointLeft",
  },
  {
    icon: <FA.FaHandPointRight />,
    name: "HandPointRight",
  },
  {
    icon: <FA.FaHandPointUp />,
    name: "HandPointUp",
  },
  {
    icon: <FA.FaHandPointer />,
    name: "HandPointer",
  },
  {
    icon: <FA.FaHandRock />,
    name: "HandRock",
  },
  {
    icon: <FA.FaHandScissors />,
    name: "HandScissors",
  },
  {
    icon: <FA.FaHandSparkles />,
    name: "HandSparkles",
  },
  {
    icon: <FA.FaHandSpock />,
    name: "HandSpock",
  },
  {
    icon: <FA.FaHandsHelping />,
    name: "HandsHelping",
  },
  {
    icon: <FA.FaHandsWash />,
    name: "HandsWash",
  },
  {
    icon: <FA.FaHands />,
    name: "Hands",
  },
  {
    icon: <FA.FaHandshakeAltSlash />,
    name: "HandshakeAltSlash",
  },
  {
    icon: <FA.FaHandshakeSlash />,
    name: "HandshakeSlash",
  },
  {
    icon: <FA.FaHandshake />,
    name: "Handshake",
  },
  {
    icon: <FA.FaHanukiah />,
    name: "Hanukiah",
  },
  {
    icon: <FA.FaHardHat />,
    name: "HardHat",
  },
  {
    icon: <FA.FaHashtag />,
    name: "Hashtag",
  },
  {
    icon: <FA.FaHatCowboySide />,
    name: "HatCowboySide",
  },
  {
    icon: <FA.FaHatCowboy />,
    name: "HatCowboy",
  },
  {
    icon: <FA.FaHatWizard />,
    name: "HatWizard",
  },
  {
    icon: <FA.FaHdd />,
    name: "Hdd",
  },
  {
    icon: <FA.FaHeadSideCoughSlash />,
    name: "HeadSideCoughSlash",
  },
  {
    icon: <FA.FaHeadSideCough />,
    name: "HeadSideCough",
  },
  {
    icon: <FA.FaHeadSideMask />,
    name: "HeadSideMask",
  },
  {
    icon: <FA.FaHeadSideVirus />,
    name: "HeadSideVirus",
  },
  {
    icon: <FA.FaHeading />,
    name: "Heading",
  },
  {
    icon: <FA.FaHeadphonesAlt />,
    name: "HeadphonesAlt",
  },
  {
    icon: <FA.FaHeadphones />,
    name: "Headphones",
  },
  {
    icon: <FA.FaHeadset />,
    name: "Headset",
  },
  {
    icon: <FA.FaHeartBroken />,
    name: "HeartBroken",
  },
  {
    icon: <FA.FaHeart />,
    name: "Heart",
  },
  {
    icon: <FA.FaHeartbeat />,
    name: "Heartbeat",
  },
  {
    icon: <FA.FaHelicopter />,
    name: "Helicopter",
  },
  {
    icon: <FA.FaHighlighter />,
    name: "Highlighter",
  },
  {
    icon: <FA.FaHiking />,
    name: "Hiking",
  },
  {
    icon: <FA.FaHippo />,
    name: "Hippo",
  },
  {
    icon: <FA.FaHistory />,
    name: "History",
  },
  {
    icon: <FA.FaHockeyPuck />,
    name: "HockeyPuck",
  },
  {
    icon: <FA.FaHollyBerry />,
    name: "HollyBerry",
  },
  {
    icon: <FA.FaHome />,
    name: "Home",
  },
  {
    icon: <FA.FaHorseHead />,
    name: "HorseHead",
  },
  {
    icon: <FA.FaHorse />,
    name: "Horse",
  },
  {
    icon: <FA.FaHospitalAlt />,
    name: "HospitalAlt",
  },
  {
    icon: <FA.FaHospitalSymbol />,
    name: "HospitalSymbol",
  },
  {
    icon: <FA.FaHospitalUser />,
    name: "HospitalUser",
  },
  {
    icon: <FA.FaHospital />,
    name: "Hospital",
  },
  {
    icon: <FA.FaHotTub />,
    name: "HotTub",
  },
  {
    icon: <FA.FaHotdog />,
    name: "Hotdog",
  },
  {
    icon: <FA.FaHotel />,
    name: "Hotel",
  },
  {
    icon: <FA.FaHourglassEnd />,
    name: "HourglassEnd",
  },
  {
    icon: <FA.FaHourglassHalf />,
    name: "HourglassHalf",
  },
  {
    icon: <FA.FaHourglassStart />,
    name: "HourglassStart",
  },
  {
    icon: <FA.FaHourglass />,
    name: "Hourglass",
  },
  {
    icon: <FA.FaHouseDamage />,
    name: "HouseDamage",
  },
  {
    icon: <FA.FaHouseUser />,
    name: "HouseUser",
  },
  {
    icon: <FA.FaHryvnia />,
    name: "Hryvnia",
  },
  {
    icon: <FA.FaICursor />,
    name: "ICursor",
  },
  {
    icon: <FA.FaIceCream />,
    name: "IceCream",
  },
  {
    icon: <FA.FaIcicles />,
    name: "Icicles",
  },
  {
    icon: <FA.FaIcons />,
    name: "Icons",
  },
  {
    icon: <FA.FaIdBadge />,
    name: "IdBadge",
  },
  {
    icon: <FA.FaIdCardAlt />,
    name: "IdCardAlt",
  },
  {
    icon: <FA.FaIdCard />,
    name: "IdCard",
  },
  {
    icon: <FA.FaIgloo />,
    name: "Igloo",
  },
  {
    icon: <FA.FaImage />,
    name: "Image",
  },
  {
    icon: <FA.FaImages />,
    name: "Images",
  },
  {
    icon: <FA.FaInbox />,
    name: "Inbox",
  },
  {
    icon: <FA.FaIndent />,
    name: "Indent",
  },
  {
    icon: <FA.FaIndustry />,
    name: "Industry",
  },
  {
    icon: <FA.FaInfinity />,
    name: "Infinity",
  },
  {
    icon: <FA.FaInfoCircle />,
    name: "InfoCircle",
  },
  {
    icon: <FA.FaInfo />,
    name: "Info",
  },
  {
    icon: <FA.FaItalic />,
    name: "Italic",
  },
  {
    icon: <FA.FaJedi />,
    name: "Jedi",
  },
  {
    icon: <FA.FaJoint />,
    name: "Joint",
  },
  {
    icon: <FA.FaJournalWhills />,
    name: "JournalWhills",
  },
  {
    icon: <FA.FaKaaba />,
    name: "Kaaba",
  },
  {
    icon: <FA.FaKey />,
    name: "Key",
  },
  {
    icon: <FA.FaKeyboard />,
    name: "Keyboard",
  },
  {
    icon: <FA.FaKhanda />,
    name: "Khanda",
  },
  {
    icon: <FA.FaKissBeam />,
    name: "KissBeam",
  },
  {
    icon: <FA.FaKissWinkHeart />,
    name: "KissWinkHeart",
  },
  {
    icon: <FA.FaKiss />,
    name: "Kiss",
  },
  {
    icon: <FA.FaKiwiBird />,
    name: "KiwiBird",
  },
  {
    icon: <FA.FaLandmark />,
    name: "Landmark",
  },
  {
    icon: <FA.FaLanguage />,
    name: "Language",
  },
  {
    icon: <FA.FaLaptopCode />,
    name: "LaptopCode",
  },
  {
    icon: <FA.FaLaptopHouse />,
    name: "LaptopHouse",
  },
  {
    icon: <FA.FaLaptopMedical />,
    name: "LaptopMedical",
  },
  {
    icon: <FA.FaLaptop />,
    name: "Laptop",
  },
  {
    icon: <FA.FaLaughBeam />,
    name: "LaughBeam",
  },
  {
    icon: <FA.FaLaughSquint />,
    name: "LaughSquint",
  },
  {
    icon: <FA.FaLaughWink />,
    name: "LaughWink",
  },
  {
    icon: <FA.FaLaugh />,
    name: "Laugh",
  },
  {
    icon: <FA.FaLayerGroup />,
    name: "LayerGroup",
  },
  {
    icon: <FA.FaLeaf />,
    name: "Leaf",
  },
  {
    icon: <FA.FaLemon />,
    name: "Lemon",
  },
  {
    icon: <FA.FaLessThanEqual />,
    name: "LessThanEqual",
  },
  {
    icon: <FA.FaLessThan />,
    name: "LessThan",
  },
  {
    icon: <FA.FaLevelDownAlt />,
    name: "LevelDownAlt",
  },
  {
    icon: <FA.FaLevelUpAlt />,
    name: "LevelUpAlt",
  },
  {
    icon: <FA.FaLifeRing />,
    name: "LifeRing",
  },
  {
    icon: <FA.FaLightbulb />,
    name: "Lightbulb",
  },
  {
    icon: <FA.FaLink />,
    name: "Link",
  },
  {
    icon: <FA.FaLiraSign />,
    name: "LiraSign",
  },
  {
    icon: <FA.FaListAlt />,
    name: "ListAlt",
  },
  {
    icon: <FA.FaListOl />,
    name: "ListOl",
  },
  {
    icon: <FA.FaListUl />,
    name: "ListUl",
  },
  {
    icon: <FA.FaList />,
    name: "List",
  },
  {
    icon: <FA.FaLocationArrow />,
    name: "LocationArrow",
  },
  {
    icon: <FA.FaLockOpen />,
    name: "LockOpen",
  },
  {
    icon: <FA.FaLock />,
    name: "Lock",
  },
  {
    icon: <FA.FaLongArrowAltDown />,
    name: "LongArrowAltDown",
  },
  {
    icon: <FA.FaLongArrowAltLeft />,
    name: "LongArrowAltLeft",
  },
  {
    icon: <FA.FaLongArrowAltRight />,
    name: "LongArrowAltRight",
  },
  {
    icon: <FA.FaLongArrowAltUp />,
    name: "LongArrowAltUp",
  },
  {
    icon: <FA.FaLowVision />,
    name: "LowVision",
  },
  {
    icon: <FA.FaLuggageCart />,
    name: "LuggageCart",
  },
  {
    icon: <FA.FaLungsVirus />,
    name: "LungsVirus",
  },
  {
    icon: <FA.FaLungs />,
    name: "Lungs",
  },
  {
    icon: <FA.FaMagic />,
    name: "Magic",
  },
  {
    icon: <FA.FaMagnet />,
    name: "Magnet",
  },
  {
    icon: <FA.FaMailBulk />,
    name: "MailBulk",
  },
  {
    icon: <FA.FaMale />,
    name: "Male",
  },
  {
    icon: <FA.FaMapMarkedAlt />,
    name: "MapMarkedAlt",
  },
  {
    icon: <FA.FaMapMarked />,
    name: "MapMarked",
  },
  {
    icon: <FA.FaMapMarkerAlt />,
    name: "MapMarkerAlt",
  },
  {
    icon: <FA.FaMapMarker />,
    name: "MapMarker",
  },
  {
    icon: <FA.FaMapPin />,
    name: "MapPin",
  },
  {
    icon: <FA.FaMapSigns />,
    name: "MapSigns",
  },
  {
    icon: <FA.FaMap />,
    name: "Map",
  },
  {
    icon: <FA.FaMarker />,
    name: "Marker",
  },
  {
    icon: <FA.FaMarsDouble />,
    name: "MarsDouble",
  },
  {
    icon: <FA.FaMarsStrokeH />,
    name: "MarsStrokeH",
  },
  {
    icon: <FA.FaMarsStrokeV />,
    name: "MarsStrokeV",
  },
  {
    icon: <FA.FaMarsStroke />,
    name: "MarsStroke",
  },
  {
    icon: <FA.FaMars />,
    name: "Mars",
  },
  {
    icon: <FA.FaMask />,
    name: "Mask",
  },
  {
    icon: <FA.FaMedal />,
    name: "Medal",
  },
  {
    icon: <FA.FaMedkit />,
    name: "Medkit",
  },
  {
    icon: <FA.FaMehBlank />,
    name: "MehBlank",
  },
  {
    icon: <FA.FaMehRollingEyes />,
    name: "MehRollingEyes",
  },
  {
    icon: <FA.FaMeh />,
    name: "Meh",
  },
  {
    icon: <FA.FaMemory />,
    name: "Memory",
  },
  {
    icon: <FA.FaMenorah />,
    name: "Menorah",
  },
  {
    icon: <FA.FaMercury />,
    name: "Mercury",
  },
  {
    icon: <FA.FaMeteor />,
    name: "Meteor",
  },
  {
    icon: <FA.FaMicrochip />,
    name: "Microchip",
  },
  {
    icon: <FA.FaMicrophoneAltSlash />,
    name: "MicrophoneAltSlash",
  },
  {
    icon: <FA.FaMicrophoneAlt />,
    name: "MicrophoneAlt",
  },
  {
    icon: <FA.FaMicrophoneSlash />,
    name: "MicrophoneSlash",
  },
  {
    icon: <FA.FaMicrophone />,
    name: "Microphone",
  },
  {
    icon: <FA.FaMicroscope />,
    name: "Microscope",
  },
  {
    icon: <FA.FaMinusCircle />,
    name: "MinusCircle",
  },
  {
    icon: <FA.FaMinusSquare />,
    name: "MinusSquare",
  },
  {
    icon: <FA.FaMinus />,
    name: "Minus",
  },
  {
    icon: <FA.FaMitten />,
    name: "Mitten",
  },
  {
    icon: <FA.FaMobileAlt />,
    name: "MobileAlt",
  },
  {
    icon: <FA.FaMobile />,
    name: "Mobile",
  },
  {
    icon: <FA.FaMoneyBillAlt />,
    name: "MoneyBillAlt",
  },
  {
    icon: <FA.FaMoneyBillWaveAlt />,
    name: "MoneyBillWaveAlt",
  },
  {
    icon: <FA.FaMoneyBillWave />,
    name: "MoneyBillWave",
  },
  {
    icon: <FA.FaMoneyBill />,
    name: "MoneyBill",
  },
  {
    icon: <FA.FaMoneyCheckAlt />,
    name: "MoneyCheckAlt",
  },
  {
    icon: <FA.FaMoneyCheck />,
    name: "MoneyCheck",
  },
  {
    icon: <FA.FaMonument />,
    name: "Monument",
  },
  {
    icon: <FA.FaMoon />,
    name: "Moon",
  },
  {
    icon: <FA.FaMortarPestle />,
    name: "MortarPestle",
  },
  {
    icon: <FA.FaMosque />,
    name: "Mosque",
  },
  {
    icon: <FA.FaMotorcycle />,
    name: "Motorcycle",
  },
  {
    icon: <FA.FaMountain />,
    name: "Mountain",
  },
  {
    icon: <FA.FaMousePointer />,
    name: "MousePointer",
  },
  {
    icon: <FA.FaMouse />,
    name: "Mouse",
  },
  {
    icon: <FA.FaMugHot />,
    name: "MugHot",
  },
  {
    icon: <FA.FaMusic />,
    name: "Music",
  },
  {
    icon: <FA.FaNetworkWired />,
    name: "NetworkWired",
  },
  {
    icon: <FA.FaNeuter />,
    name: "Neuter",
  },
  {
    icon: <FA.FaNewspaper />,
    name: "Newspaper",
  },
  {
    icon: <FA.FaNotEqual />,
    name: "NotEqual",
  },
  {
    icon: <FA.FaNotesMedical />,
    name: "NotesMedical",
  },
  {
    icon: <FA.FaObjectGroup />,
    name: "ObjectGroup",
  },
  {
    icon: <FA.FaObjectUngroup />,
    name: "ObjectUngroup",
  },
  {
    icon: <FA.FaOilCan />,
    name: "OilCan",
  },
  {
    icon: <FA.FaOm />,
    name: "Om",
  },
  {
    icon: <FA.FaOtter />,
    name: "Otter",
  },
  {
    icon: <FA.FaOutdent />,
    name: "Outdent",
  },
  {
    icon: <FA.FaPager />,
    name: "Pager",
  },
  {
    icon: <FA.FaPaintBrush />,
    name: "PaintBrush",
  },
  {
    icon: <FA.FaPaintRoller />,
    name: "PaintRoller",
  },
  {
    icon: <FA.FaPalette />,
    name: "Palette",
  },
  {
    icon: <FA.FaPallet />,
    name: "Pallet",
  },
  {
    icon: <FA.FaPaperPlane />,
    name: "PaperPlane",
  },
  {
    icon: <FA.FaPaperclip />,
    name: "Paperclip",
  },
  {
    icon: <FA.FaParachuteBox />,
    name: "ParachuteBox",
  },
  {
    icon: <FA.FaParagraph />,
    name: "Paragraph",
  },
  {
    icon: <FA.FaParking />,
    name: "Parking",
  },
  {
    icon: <FA.FaPassport />,
    name: "Passport",
  },
  {
    icon: <FA.FaPastafarianism />,
    name: "Pastafarianism",
  },
  {
    icon: <FA.FaPaste />,
    name: "Paste",
  },
  {
    icon: <FA.FaPauseCircle />,
    name: "PauseCircle",
  },
  {
    icon: <FA.FaPause />,
    name: "Pause",
  },
  {
    icon: <FA.FaPaw />,
    name: "Paw",
  },
  {
    icon: <FA.FaPeace />,
    name: "Peace",
  },
  {
    icon: <FA.FaPenAlt />,
    name: "PenAlt",
  },
  {
    icon: <FA.FaPenFancy />,
    name: "PenFancy",
  },
  {
    icon: <FA.FaPenNib />,
    name: "PenNib",
  },
  {
    icon: <FA.FaPenSquare />,
    name: "PenSquare",
  },
  {
    icon: <FA.FaPen />,
    name: "Pen",
  },
  {
    icon: <FA.FaPencilAlt />,
    name: "PencilAlt",
  },
  {
    icon: <FA.FaPencilRuler />,
    name: "PencilRuler",
  },
  {
    icon: <FA.FaPeopleArrows />,
    name: "PeopleArrows",
  },
  {
    icon: <FA.FaPeopleCarry />,
    name: "PeopleCarry",
  },
  {
    icon: <FA.FaPepperHot />,
    name: "PepperHot",
  },
  {
    icon: <FA.FaPercent />,
    name: "Percent",
  },
  {
    icon: <FA.FaPercentage />,
    name: "Percentage",
  },
  {
    icon: <FA.FaPersonBooth />,
    name: "PersonBooth",
  },
  {
    icon: <FA.FaPhoneAlt />,
    name: "PhoneAlt",
  },
  {
    icon: <FA.FaPhoneSlash />,
    name: "PhoneSlash",
  },
  {
    icon: <FA.FaPhoneSquareAlt />,
    name: "PhoneSquareAlt",
  },
  {
    icon: <FA.FaPhoneSquare />,
    name: "PhoneSquare",
  },
  {
    icon: <FA.FaPhoneVolume />,
    name: "PhoneVolume",
  },
  {
    icon: <FA.FaPhone />,
    name: "Phone",
  },
  {
    icon: <FA.FaPhotoVideo />,
    name: "PhotoVideo",
  },
  {
    icon: <FA.FaPiggyBank />,
    name: "PiggyBank",
  },
  {
    icon: <FA.FaPills />,
    name: "Pills",
  },
  {
    icon: <FA.FaPizzaSlice />,
    name: "PizzaSlice",
  },
  {
    icon: <FA.FaPlaceOfWorship />,
    name: "PlaceOfWorship",
  },
  {
    icon: <FA.FaPlaneArrival />,
    name: "PlaneArrival",
  },
  {
    icon: <FA.FaPlaneDeparture />,
    name: "PlaneDeparture",
  },
  {
    icon: <FA.FaPlaneSlash />,
    name: "PlaneSlash",
  },
  {
    icon: <FA.FaPlane />,
    name: "Plane",
  },
  {
    icon: <FA.FaPlayCircle />,
    name: "PlayCircle",
  },
  {
    icon: <FA.FaPlay />,
    name: "Play",
  },
  {
    icon: <FA.FaPlug />,
    name: "Plug",
  },
  {
    icon: <FA.FaPlusCircle />,
    name: "PlusCircle",
  },
  {
    icon: <FA.FaPlusSquare />,
    name: "PlusSquare",
  },
  {
    icon: <FA.FaPlus />,
    name: "Plus",
  },
  {
    icon: <FA.FaPodcast />,
    name: "Podcast",
  },
  {
    icon: <FA.FaPollH />,
    name: "PollH",
  },
  {
    icon: <FA.FaPoll />,
    name: "Poll",
  },
  {
    icon: <FA.FaPooStorm />,
    name: "PooStorm",
  },
  {
    icon: <FA.FaPoo />,
    name: "Poo",
  },
  {
    icon: <FA.FaPoop />,
    name: "Poop",
  },
  {
    icon: <FA.FaPortrait />,
    name: "Portrait",
  },
  {
    icon: <FA.FaPoundSign />,
    name: "PoundSign",
  },
  {
    icon: <FA.FaPowerOff />,
    name: "PowerOff",
  },
  {
    icon: <FA.FaPray />,
    name: "Pray",
  },
  {
    icon: <FA.FaPrayingHands />,
    name: "PrayingHands",
  },
  {
    icon: <FA.FaPrescriptionBottleAlt />,
    name: "PrescriptionBottleAlt",
  },
  {
    icon: <FA.FaPrescriptionBottle />,
    name: "PrescriptionBottle",
  },
  {
    icon: <FA.FaPrescription />,
    name: "Prescription",
  },
  {
    icon: <FA.FaPrint />,
    name: "Print",
  },
  {
    icon: <FA.FaProcedures />,
    name: "Procedures",
  },
  {
    icon: <FA.FaProjectDiagram />,
    name: "ProjectDiagram",
  },
  {
    icon: <FA.FaPumpMedical />,
    name: "PumpMedical",
  },
  {
    icon: <FA.FaPumpSoap />,
    name: "PumpSoap",
  },
  {
    icon: <FA.FaPuzzlePiece />,
    name: "PuzzlePiece",
  },
  {
    icon: <FA.FaQrcode />,
    name: "Qrcode",
  },
  {
    icon: <FA.FaQuestionCircle />,
    name: "QuestionCircle",
  },
  {
    icon: <FA.FaQuestion />,
    name: "Question",
  },
  {
    icon: <FA.FaQuidditch />,
    name: "Quidditch",
  },
  {
    icon: <FA.FaQuoteLeft />,
    name: "QuoteLeft",
  },
  {
    icon: <FA.FaQuoteRight />,
    name: "QuoteRight",
  },
  {
    icon: <FA.FaQuran />,
    name: "Quran",
  },
  {
    icon: <FA.FaRadiationAlt />,
    name: "RadiationAlt",
  },
  {
    icon: <FA.FaRadiation />,
    name: "Radiation",
  },
  {
    icon: <FA.FaRainbow />,
    name: "Rainbow",
  },
  {
    icon: <FA.FaRandom />,
    name: "Random",
  },
  {
    icon: <FA.FaReceipt />,
    name: "Receipt",
  },
  {
    icon: <FA.FaRecordVinyl />,
    name: "RecordVinyl",
  },
  {
    icon: <FA.FaRecycle />,
    name: "Recycle",
  },
  {
    icon: <FA.FaRedoAlt />,
    name: "RedoAlt",
  },
  {
    icon: <FA.FaRedo />,
    name: "Redo",
  },
  {
    icon: <FA.FaRegistered />,
    name: "Registered",
  },
  {
    icon: <FA.FaRemoveFormat />,
    name: "RemoveFormat",
  },
  {
    icon: <FA.FaReplyAll />,
    name: "ReplyAll",
  },
  {
    icon: <FA.FaReply />,
    name: "Reply",
  },
  {
    icon: <FA.FaRepublican />,
    name: "Republican",
  },
  {
    icon: <FA.FaRestroom />,
    name: "Restroom",
  },
  {
    icon: <FA.FaRetweet />,
    name: "Retweet",
  },
  {
    icon: <FA.FaRibbon />,
    name: "Ribbon",
  },
  {
    icon: <FA.FaRing />,
    name: "Ring",
  },
  {
    icon: <FA.FaRoad />,
    name: "Road",
  },
  {
    icon: <FA.FaRobot />,
    name: "Robot",
  },
  {
    icon: <FA.FaRocket />,
    name: "Rocket",
  },
  {
    icon: <FA.FaRoute />,
    name: "Route",
  },
  {
    icon: <FA.FaRssSquare />,
    name: "RssSquare",
  },
  {
    icon: <FA.FaRss />,
    name: "Rss",
  },
  {
    icon: <FA.FaRubleSign />,
    name: "RubleSign",
  },
  {
    icon: <FA.FaRulerCombined />,
    name: "RulerCombined",
  },
  {
    icon: <FA.FaRulerHorizontal />,
    name: "RulerHorizontal",
  },
  {
    icon: <FA.FaRulerVertical />,
    name: "RulerVertical",
  },
  {
    icon: <FA.FaRuler />,
    name: "Ruler",
  },
  {
    icon: <FA.FaRunning />,
    name: "Running",
  },
  {
    icon: <FA.FaRupeeSign />,
    name: "RupeeSign",
  },
  {
    icon: <FA.FaSadCry />,
    name: "SadCry",
  },
  {
    icon: <FA.FaSadTear />,
    name: "SadTear",
  },
  {
    icon: <FA.FaSatelliteDish />,
    name: "SatelliteDish",
  },
  {
    icon: <FA.FaSatellite />,
    name: "Satellite",
  },
  {
    icon: <FA.FaSave />,
    name: "Save",
  },
  {
    icon: <FA.FaSchool />,
    name: "School",
  },
  {
    icon: <FA.FaScrewdriver />,
    name: "Screwdriver",
  },
  {
    icon: <FA.FaScroll />,
    name: "Scroll",
  },
  {
    icon: <FA.FaSdCard />,
    name: "SdCard",
  },
  {
    icon: <FA.FaSearchDollar />,
    name: "SearchDollar",
  },
  {
    icon: <FA.FaSearchLocation />,
    name: "SearchLocation",
  },
  {
    icon: <FA.FaSearchMinus />,
    name: "SearchMinus",
  },
  {
    icon: <FA.FaSearchPlus />,
    name: "SearchPlus",
  },
  {
    icon: <FA.FaSearch />,
    name: "Search",
  },
  {
    icon: <FA.FaSeedling />,
    name: "Seedling",
  },
  {
    icon: <FA.FaServer />,
    name: "Server",
  },
  {
    icon: <FA.FaShapes />,
    name: "Shapes",
  },
  {
    icon: <FA.FaShareAltSquare />,
    name: "ShareAltSquare",
  },
  {
    icon: <FA.FaShareAlt />,
    name: "ShareAlt",
  },
  {
    icon: <FA.FaShareSquare />,
    name: "ShareSquare",
  },
  {
    icon: <FA.FaShare />,
    name: "Share",
  },
  {
    icon: <FA.FaShekelSign />,
    name: "ShekelSign",
  },
  {
    icon: <FA.FaShieldAlt />,
    name: "ShieldAlt",
  },
  {
    icon: <FA.FaShieldVirus />,
    name: "ShieldVirus",
  },
  {
    icon: <FA.FaShip />,
    name: "Ship",
  },
  {
    icon: <FA.FaShippingFast />,
    name: "ShippingFast",
  },
  {
    icon: <FA.FaShoePrints />,
    name: "ShoePrints",
  },
  {
    icon: <FA.FaShoppingBag />,
    name: "ShoppingBag",
  },
  {
    icon: <FA.FaShoppingBasket />,
    name: "ShoppingBasket",
  },
  {
    icon: <FA.FaShoppingCart />,
    name: "ShoppingCart",
  },
  {
    icon: <FA.FaShower />,
    name: "Shower",
  },
  {
    icon: <FA.FaShuttleVan />,
    name: "ShuttleVan",
  },
  {
    icon: <FA.FaSignInAlt />,
    name: "SignInAlt",
  },
  {
    icon: <FA.FaSignLanguage />,
    name: "SignLanguage",
  },
  {
    icon: <FA.FaSignOutAlt />,
    name: "SignOutAlt",
  },
  {
    icon: <FA.FaSign />,
    name: "Sign",
  },
  {
    icon: <FA.FaSignal />,
    name: "Signal",
  },
  {
    icon: <FA.FaSignature />,
    name: "Signature",
  },
  {
    icon: <FA.FaSimCard />,
    name: "SimCard",
  },
  {
    icon: <FA.FaSink />,
    name: "Sink",
  },
  {
    icon: <FA.FaSitemap />,
    name: "Sitemap",
  },
  {
    icon: <FA.FaSkating />,
    name: "Skating",
  },
  {
    icon: <FA.FaSkiingNordic />,
    name: "SkiingNordic",
  },
  {
    icon: <FA.FaSkiing />,
    name: "Skiing",
  },
  {
    icon: <FA.FaSkullCrossbones />,
    name: "SkullCrossbones",
  },
  {
    icon: <FA.FaSkull />,
    name: "Skull",
  },
  {
    icon: <FA.FaSlash />,
    name: "Slash",
  },
  {
    icon: <FA.FaSleigh />,
    name: "Sleigh",
  },
  {
    icon: <FA.FaSlidersH />,
    name: "SlidersH",
  },
  {
    icon: <FA.FaSmileBeam />,
    name: "SmileBeam",
  },
  {
    icon: <FA.FaSmileWink />,
    name: "SmileWink",
  },
  {
    icon: <FA.FaSmile />,
    name: "Smile",
  },
  {
    icon: <FA.FaSmog />,
    name: "Smog",
  },
  {
    icon: <FA.FaSmokingBan />,
    name: "SmokingBan",
  },
  {
    icon: <FA.FaSmoking />,
    name: "Smoking",
  },
  {
    icon: <FA.FaSms />,
    name: "Sms",
  },
  {
    icon: <FA.FaSnowboarding />,
    name: "Snowboarding",
  },
  {
    icon: <FA.FaSnowflake />,
    name: "Snowflake",
  },
  {
    icon: <FA.FaSnowman />,
    name: "Snowman",
  },
  {
    icon: <FA.FaSnowplow />,
    name: "Snowplow",
  },
  {
    icon: <FA.FaSoap />,
    name: "Soap",
  },
  {
    icon: <FA.FaSocks />,
    name: "Socks",
  },
  {
    icon: <FA.FaSolarPanel />,
    name: "SolarPanel",
  },
  {
    icon: <FA.FaSortAlphaDownAlt />,
    name: "SortAlphaDownAlt",
  },
  {
    icon: <FA.FaSortAlphaDown />,
    name: "SortAlphaDown",
  },
  {
    icon: <FA.FaSortAlphaUpAlt />,
    name: "SortAlphaUpAlt",
  },
  {
    icon: <FA.FaSortAlphaUp />,
    name: "SortAlphaUp",
  },
  {
    icon: <FA.FaSortAmountDownAlt />,
    name: "SortAmountDownAlt",
  },
  {
    icon: <FA.FaSortAmountDown />,
    name: "SortAmountDown",
  },
  {
    icon: <FA.FaSortAmountUpAlt />,
    name: "SortAmountUpAlt",
  },
  {
    icon: <FA.FaSortAmountUp />,
    name: "SortAmountUp",
  },
  {
    icon: <FA.FaSortDown />,
    name: "SortDown",
  },
  {
    icon: <FA.FaSortNumericDownAlt />,
    name: "SortNumericDownAlt",
  },
  {
    icon: <FA.FaSortNumericDown />,
    name: "SortNumericDown",
  },
  {
    icon: <FA.FaSortNumericUpAlt />,
    name: "SortNumericUpAlt",
  },
  {
    icon: <FA.FaSortNumericUp />,
    name: "SortNumericUp",
  },
  {
    icon: <FA.FaSortUp />,
    name: "SortUp",
  },
  {
    icon: <FA.FaSort />,
    name: "Sort",
  },
  {
    icon: <FA.FaSpa />,
    name: "Spa",
  },
  {
    icon: <FA.FaSpaceShuttle />,
    name: "SpaceShuttle",
  },
  {
    icon: <FA.FaSpellCheck />,
    name: "SpellCheck",
  },
  {
    icon: <FA.FaSpider />,
    name: "Spider",
  },
  {
    icon: <FA.FaSpinner />,
    name: "Spinner",
  },
  {
    icon: <FA.FaSplotch />,
    name: "Splotch",
  },
  {
    icon: <FA.FaSprayCan />,
    name: "SprayCan",
  },
  {
    icon: <FA.FaSquareFull />,
    name: "SquareFull",
  },
  {
    icon: <FA.FaSquareRootAlt />,
    name: "SquareRootAlt",
  },
  {
    icon: <FA.FaSquare />,
    name: "Square",
  },
  {
    icon: <FA.FaStamp />,
    name: "Stamp",
  },
  {
    icon: <FA.FaStarAndCrescent />,
    name: "StarAndCrescent",
  },
  {
    icon: <FA.FaStarHalfAlt />,
    name: "StarHalfAlt",
  },
  {
    icon: <FA.FaStarHalf />,
    name: "StarHalf",
  },
  {
    icon: <FA.FaStarOfDavid />,
    name: "StarOfDavid",
  },
  {
    icon: <FA.FaStarOfLife />,
    name: "StarOfLife",
  },
  {
    icon: <FA.FaStar />,
    name: "Star",
  },
  {
    icon: <FA.FaStepBackward />,
    name: "StepBackward",
  },
  {
    icon: <FA.FaStepForward />,
    name: "StepForward",
  },
  {
    icon: <FA.FaStethoscope />,
    name: "Stethoscope",
  },
  {
    icon: <FA.FaStickyNote />,
    name: "StickyNote",
  },
  {
    icon: <FA.FaStopCircle />,
    name: "StopCircle",
  },
  {
    icon: <FA.FaStop />,
    name: "Stop",
  },
  {
    icon: <FA.FaStopwatch20 />,
    name: "Stopwatch20",
  },
  {
    icon: <FA.FaStopwatch />,
    name: "Stopwatch",
  },
  {
    icon: <FA.FaStoreAltSlash />,
    name: "StoreAltSlash",
  },
  {
    icon: <FA.FaStoreAlt />,
    name: "StoreAlt",
  },
  {
    icon: <FA.FaStoreSlash />,
    name: "StoreSlash",
  },
  {
    icon: <FA.FaStore />,
    name: "Store",
  },
  {
    icon: <FA.FaStream />,
    name: "Stream",
  },
  {
    icon: <FA.FaStreetView />,
    name: "StreetView",
  },
  {
    icon: <FA.FaStrikethrough />,
    name: "Strikethrough",
  },
  {
    icon: <FA.FaStroopwafel />,
    name: "Stroopwafel",
  },
  {
    icon: <FA.FaSubscript />,
    name: "Subscript",
  },
  {
    icon: <FA.FaSubway />,
    name: "Subway",
  },
  {
    icon: <FA.FaSuitcaseRolling />,
    name: "SuitcaseRolling",
  },
  {
    icon: <FA.FaSuitcase />,
    name: "Suitcase",
  },
  {
    icon: <FA.FaSun />,
    name: "Sun",
  },
  {
    icon: <FA.FaSuperscript />,
    name: "Superscript",
  },
  {
    icon: <FA.FaSurprise />,
    name: "Surprise",
  },
  {
    icon: <FA.FaSwatchbook />,
    name: "Swatchbook",
  },
  {
    icon: <FA.FaSwimmer />,
    name: "Swimmer",
  },
  {
    icon: <FA.FaSwimmingPool />,
    name: "SwimmingPool",
  },
  {
    icon: <FA.FaSynagogue />,
    name: "Synagogue",
  },
  {
    icon: <FA.FaSyncAlt />,
    name: "SyncAlt",
  },
  {
    icon: <FA.FaSync />,
    name: "Sync",
  },
  {
    icon: <FA.FaSyringe />,
    name: "Syringe",
  },
  {
    icon: <FA.FaTableTennis />,
    name: "TableTennis",
  },
  {
    icon: <FA.FaTable />,
    name: "Table",
  },
  {
    icon: <FA.FaTabletAlt />,
    name: "TabletAlt",
  },
  {
    icon: <FA.FaTablet />,
    name: "Tablet",
  },
  {
    icon: <FA.FaTablets />,
    name: "Tablets",
  },
  {
    icon: <FA.FaTachometerAlt />,
    name: "TachometerAlt",
  },
  {
    icon: <FA.FaTag />,
    name: "Tag",
  },
  {
    icon: <FA.FaTags />,
    name: "Tags",
  },
  {
    icon: <FA.FaTape />,
    name: "Tape",
  },
  {
    icon: <FA.FaTasks />,
    name: "Tasks",
  },
  {
    icon: <FA.FaTaxi />,
    name: "Taxi",
  },
  {
    icon: <FA.FaTeethOpen />,
    name: "TeethOpen",
  },
  {
    icon: <FA.FaTeeth />,
    name: "Teeth",
  },
  {
    icon: <FA.FaTemperatureHigh />,
    name: "TemperatureHigh",
  },
  {
    icon: <FA.FaTemperatureLow />,
    name: "TemperatureLow",
  },
  {
    icon: <FA.FaTenge />,
    name: "Tenge",
  },
  {
    icon: <FA.FaTerminal />,
    name: "Terminal",
  },
  {
    icon: <FA.FaTextHeight />,
    name: "TextHeight",
  },
  {
    icon: <FA.FaTextWidth />,
    name: "TextWidth",
  },
  {
    icon: <FA.FaThLarge />,
    name: "ThLarge",
  },
  {
    icon: <FA.FaThList />,
    name: "ThList",
  },
  {
    icon: <FA.FaTh />,
    name: "Th",
  },
  {
    icon: <FA.FaTheaterMasks />,
    name: "TheaterMasks",
  },
  {
    icon: <FA.FaThermometerEmpty />,
    name: "ThermometerEmpty",
  },
  {
    icon: <FA.FaThermometerFull />,
    name: "ThermometerFull",
  },
  {
    icon: <FA.FaThermometerHalf />,
    name: "ThermometerHalf",
  },
  {
    icon: <FA.FaThermometerQuarter />,
    name: "ThermometerQuarter",
  },
  {
    icon: <FA.FaThermometerThreeQuarters />,
    name: "ThermometerThreeQuarters",
  },
  {
    icon: <FA.FaThermometer />,
    name: "Thermometer",
  },
  {
    icon: <FA.FaThumbsDown />,
    name: "ThumbsDown",
  },
  {
    icon: <FA.FaThumbsUp />,
    name: "ThumbsUp",
  },
  {
    icon: <FA.FaThumbtack />,
    name: "Thumbtack",
  },
  {
    icon: <FA.FaTicketAlt />,
    name: "TicketAlt",
  },
  {
    icon: <FA.FaTimesCircle />,
    name: "TimesCircle",
  },
  {
    icon: <FA.FaTimes />,
    name: "Times",
  },
  {
    icon: <FA.FaTintSlash />,
    name: "TintSlash",
  },
  {
    icon: <FA.FaTint />,
    name: "Tint",
  },
  {
    icon: <FA.FaTired />,
    name: "Tired",
  },
  {
    icon: <FA.FaToggleOff />,
    name: "ToggleOff",
  },
  {
    icon: <FA.FaToggleOn />,
    name: "ToggleOn",
  },
  {
    icon: <FA.FaToiletPaperSlash />,
    name: "ToiletPaperSlash",
  },
  {
    icon: <FA.FaToiletPaper />,
    name: "ToiletPaper",
  },
  {
    icon: <FA.FaToilet />,
    name: "Toilet",
  },
  {
    icon: <FA.FaToolbox />,
    name: "Toolbox",
  },
  {
    icon: <FA.FaTools />,
    name: "Tools",
  },
  {
    icon: <FA.FaTooth />,
    name: "Tooth",
  },
  {
    icon: <FA.FaTorah />,
    name: "Torah",
  },
  {
    icon: <FA.FaToriiGate />,
    name: "ToriiGate",
  },
  {
    icon: <FA.FaTractor />,
    name: "Tractor",
  },
  {
    icon: <FA.FaTrademark />,
    name: "Trademark",
  },
  {
    icon: <FA.FaTrafficLight />,
    name: "TrafficLight",
  },
  {
    icon: <FA.FaTrailer />,
    name: "Trailer",
  },
  {
    icon: <FA.FaTrain />,
    name: "Train",
  },
  {
    icon: <FA.FaTram />,
    name: "Tram",
  },
  {
    icon: <FA.FaTransgenderAlt />,
    name: "TransgenderAlt",
  },
  {
    icon: <FA.FaTransgender />,
    name: "Transgender",
  },
  {
    icon: <FA.FaTrashAlt />,
    name: "TrashAlt",
  },
  {
    icon: <FA.FaTrashRestoreAlt />,
    name: "TrashRestoreAlt",
  },
  {
    icon: <FA.FaTrashRestore />,
    name: "TrashRestore",
  },
  {
    icon: <FA.FaTrash />,
    name: "Trash",
  },
  {
    icon: <FA.FaTree />,
    name: "Tree",
  },
  {
    icon: <FA.FaTrophy />,
    name: "Trophy",
  },
  {
    icon: <FA.FaTruckLoading />,
    name: "TruckLoading",
  },
  {
    icon: <FA.FaTruckMonster />,
    name: "TruckMonster",
  },
  {
    icon: <FA.FaTruckMoving />,
    name: "TruckMoving",
  },
  {
    icon: <FA.FaTruckPickup />,
    name: "TruckPickup",
  },
  {
    icon: <FA.FaTruck />,
    name: "Truck",
  },
  {
    icon: <FA.FaTshirt />,
    name: "Tshirt",
  },
  {
    icon: <FA.FaTty />,
    name: "Tty",
  },
  {
    icon: <FA.FaTv />,
    name: "Tv",
  },
  {
    icon: <FA.FaUmbrellaBeach />,
    name: "UmbrellaBeach",
  },
  {
    icon: <FA.FaUmbrella />,
    name: "Umbrella",
  },
  {
    icon: <FA.FaUnderline />,
    name: "Underline",
  },
  {
    icon: <FA.FaUndoAlt />,
    name: "UndoAlt",
  },
  {
    icon: <FA.FaUndo />,
    name: "Undo",
  },
  {
    icon: <FA.FaUniversalAccess />,
    name: "UniversalAccess",
  },
  {
    icon: <FA.FaUniversity />,
    name: "University",
  },
  {
    icon: <FA.FaUnlink />,
    name: "Unlink",
  },
  {
    icon: <FA.FaUnlockAlt />,
    name: "UnlockAlt",
  },
  {
    icon: <FA.FaUnlock />,
    name: "Unlock",
  },
  {
    icon: <FA.FaUpload />,
    name: "Upload",
  },
  {
    icon: <FA.FaUserAltSlash />,
    name: "UserAltSlash",
  },
  {
    icon: <FA.FaUserAlt />,
    name: "UserAlt",
  },
  {
    icon: <FA.FaUserAstronaut />,
    name: "UserAstronaut",
  },
  {
    icon: <FA.FaUserCheck />,
    name: "UserCheck",
  },
  {
    icon: <FA.FaUserCircle />,
    name: "UserCircle",
  },
  {
    icon: <FA.FaUserClock />,
    name: "UserClock",
  },
  {
    icon: <FA.FaUserCog />,
    name: "UserCog",
  },
  {
    icon: <FA.FaUserEdit />,
    name: "UserEdit",
  },
  {
    icon: <FA.FaUserFriends />,
    name: "UserFriends",
  },
  {
    icon: <FA.FaUserGraduate />,
    name: "UserGraduate",
  },
  {
    icon: <FA.FaUserInjured />,
    name: "UserInjured",
  },
  {
    icon: <FA.FaUserLock />,
    name: "UserLock",
  },
  {
    icon: <FA.FaUserMd />,
    name: "UserMd",
  },
  {
    icon: <FA.FaUserMinus />,
    name: "UserMinus",
  },
  {
    icon: <FA.FaUserNinja />,
    name: "UserNinja",
  },
  {
    icon: <FA.FaUserNurse />,
    name: "UserNurse",
  },
  {
    icon: <FA.FaUserPlus />,
    name: "UserPlus",
  },
  {
    icon: <FA.FaUserSecret />,
    name: "UserSecret",
  },
  {
    icon: <FA.FaUserShield />,
    name: "UserShield",
  },
  {
    icon: <FA.FaUserSlash />,
    name: "UserSlash",
  },
  {
    icon: <FA.FaUserTag />,
    name: "UserTag",
  },
  {
    icon: <FA.FaUserTie />,
    name: "UserTie",
  },
  {
    icon: <FA.FaUserTimes />,
    name: "UserTimes",
  },
  {
    icon: <FA.FaUser />,
    name: "User",
  },
  {
    icon: <FA.FaUsersCog />,
    name: "UsersCog",
  },
  {
    icon: <FA.FaUsersSlash />,
    name: "UsersSlash",
  },
  {
    icon: <FA.FaUsers />,
    name: "Users",
  },
  {
    icon: <FA.FaUtensilSpoon />,
    name: "UtensilSpoon",
  },
  {
    icon: <FA.FaUtensils />,
    name: "Utensils",
  },
  {
    icon: <FA.FaVectorSquare />,
    name: "VectorSquare",
  },
  {
    icon: <FA.FaVenusDouble />,
    name: "VenusDouble",
  },
  {
    icon: <FA.FaVenusMars />,
    name: "VenusMars",
  },
  {
    icon: <FA.FaVenus />,
    name: "Venus",
  },
  {
    icon: <FA.FaVestPatches />,
    name: "VestPatches",
  },
  {
    icon: <FA.FaVest />,
    name: "Vest",
  },
  {
    icon: <FA.FaVial />,
    name: "Vial",
  },
  {
    icon: <FA.FaVials />,
    name: "Vials",
  },
  {
    icon: <FA.FaVideoSlash />,
    name: "VideoSlash",
  },
  {
    icon: <FA.FaVideo />,
    name: "Video",
  },
  {
    icon: <FA.FaVihara />,
    name: "Vihara",
  },
  {
    icon: <FA.FaVirusSlash />,
    name: "VirusSlash",
  },
  {
    icon: <FA.FaVirus />,
    name: "Virus",
  },
  {
    icon: <FA.FaViruses />,
    name: "Viruses",
  },
  {
    icon: <FA.FaVoicemail />,
    name: "Voicemail",
  },
  {
    icon: <FA.FaVolleyballBall />,
    name: "VolleyballBall",
  },
  {
    icon: <FA.FaVolumeDown />,
    name: "VolumeDown",
  },
  {
    icon: <FA.FaVolumeMute />,
    name: "VolumeMute",
  },
  {
    icon: <FA.FaVolumeOff />,
    name: "VolumeOff",
  },
  {
    icon: <FA.FaVolumeUp />,
    name: "VolumeUp",
  },
  {
    icon: <FA.FaVoteYea />,
    name: "VoteYea",
  },
  {
    icon: <FA.FaVrCardboard />,
    name: "VrCardboard",
  },
  {
    icon: <FA.FaWalking />,
    name: "Walking",
  },
  {
    icon: <FA.FaWallet />,
    name: "Wallet",
  },
  {
    icon: <FA.FaWarehouse />,
    name: "Warehouse",
  },
  {
    icon: <FA.FaWater />,
    name: "Water",
  },
  {
    icon: <FA.FaWaveSquare />,
    name: "WaveSquare",
  },
  {
    icon: <FA.FaWeightHanging />,
    name: "WeightHanging",
  },
  {
    icon: <FA.FaWeight />,
    name: "Weight",
  },
  {
    icon: <FA.FaWheelchair />,
    name: "Wheelchair",
  },
  {
    icon: <FA.FaWifi />,
    name: "Wifi",
  },
  {
    icon: <FA.FaWind />,
    name: "Wind",
  },
  {
    icon: <FA.FaWindowClose />,
    name: "WindowClose",
  },
  {
    icon: <FA.FaWindowMaximize />,
    name: "WindowMaximize",
  },
  {
    icon: <FA.FaWindowMinimize />,
    name: "WindowMinimize",
  },
  {
    icon: <FA.FaWindowRestore />,
    name: "WindowRestore",
  },
  {
    icon: <FA.FaWineBottle />,
    name: "WineBottle",
  },
  {
    icon: <FA.FaWineGlassAlt />,
    name: "WineGlassAlt",
  },
  {
    icon: <FA.FaWineGlass />,
    name: "WineGlass",
  },
  {
    icon: <FA.FaWonSign />,
    name: "WonSign",
  },
  {
    icon: <FA.FaWrench />,
    name: "Wrench",
  },
  {
    icon: <FA.FaXRay />,
    name: "XRay",
  },
  {
    icon: <FA.FaYenSign />,
    name: "YenSign",
  },
  {
    icon: <FA.FaYinYang />,
    name: "YinYang",
  },
  {
    icon: <FA.FaRegAddressBook />,
    name: "RegAddressBook",
  },
  {
    icon: <FA.FaRegAddressCard />,
    name: "RegAddressCard",
  },
  {
    icon: <FA.FaRegAngry />,
    name: "RegAngry",
  },
  {
    icon: <FA.FaRegArrowAltCircleDown />,
    name: "RegArrowAltCircleDown",
  },
  {
    icon: <FA.FaRegArrowAltCircleLeft />,
    name: "RegArrowAltCircleLeft",
  },
  {
    icon: <FA.FaRegArrowAltCircleRight />,
    name: "RegArrowAltCircleRight",
  },
  {
    icon: <FA.FaRegArrowAltCircleUp />,
    name: "RegArrowAltCircleUp",
  },
  {
    icon: <FA.FaRegBellSlash />,
    name: "RegBellSlash",
  },
  {
    icon: <FA.FaRegBell />,
    name: "RegBell",
  },
  {
    icon: <FA.FaRegBookmark />,
    name: "RegBookmark",
  },
  {
    icon: <FA.FaRegBuilding />,
    name: "RegBuilding",
  },
  {
    icon: <FA.FaRegCalendarAlt />,
    name: "RegCalendarAlt",
  },
  {
    icon: <FA.FaRegCalendarCheck />,
    name: "RegCalendarCheck",
  },
  {
    icon: <FA.FaRegCalendarMinus />,
    name: "RegCalendarMinus",
  },
  {
    icon: <FA.FaRegCalendarPlus />,
    name: "RegCalendarPlus",
  },
  {
    icon: <FA.FaRegCalendarTimes />,
    name: "RegCalendarTimes",
  },
  {
    icon: <FA.FaRegCalendar />,
    name: "RegCalendar",
  },
  {
    icon: <FA.FaRegCaretSquareDown />,
    name: "RegCaretSquareDown",
  },
  {
    icon: <FA.FaRegCaretSquareLeft />,
    name: "RegCaretSquareLeft",
  },
  {
    icon: <FA.FaRegCaretSquareRight />,
    name: "RegCaretSquareRight",
  },
  {
    icon: <FA.FaRegCaretSquareUp />,
    name: "RegCaretSquareUp",
  },
  {
    icon: <FA.FaRegChartBar />,
    name: "RegChartBar",
  },
  {
    icon: <FA.FaRegCheckCircle />,
    name: "RegCheckCircle",
  },
  {
    icon: <FA.FaRegCheckSquare />,
    name: "RegCheckSquare",
  },
  {
    icon: <FA.FaRegCircle />,
    name: "RegCircle",
  },
  {
    icon: <FA.FaRegClipboard />,
    name: "RegClipboard",
  },
  {
    icon: <FA.FaRegClock />,
    name: "RegClock",
  },
  {
    icon: <FA.FaRegClone />,
    name: "RegClone",
  },
  {
    icon: <FA.FaRegClosedCaptioning />,
    name: "RegClosedCaptioning",
  },
  {
    icon: <FA.FaRegCommentAlt />,
    name: "RegCommentAlt",
  },
  {
    icon: <FA.FaRegCommentDots />,
    name: "RegCommentDots",
  },
  {
    icon: <FA.FaRegComment />,
    name: "RegComment",
  },
  {
    icon: <FA.FaRegComments />,
    name: "RegComments",
  },
  {
    icon: <FA.FaRegCompass />,
    name: "RegCompass",
  },
  {
    icon: <FA.FaRegCopy />,
    name: "RegCopy",
  },
  {
    icon: <FA.FaRegCopyright />,
    name: "RegCopyright",
  },
  {
    icon: <FA.FaRegCreditCard />,
    name: "RegCreditCard",
  },
  {
    icon: <FA.FaRegDizzy />,
    name: "RegDizzy",
  },
  {
    icon: <FA.FaRegDotCircle />,
    name: "RegDotCircle",
  },
  {
    icon: <FA.FaRegEdit />,
    name: "RegEdit",
  },
  {
    icon: <FA.FaRegEnvelopeOpen />,
    name: "RegEnvelopeOpen",
  },
  {
    icon: <FA.FaRegEnvelope />,
    name: "RegEnvelope",
  },
  {
    icon: <FA.FaRegEyeSlash />,
    name: "RegEyeSlash",
  },
  {
    icon: <FA.FaRegEye />,
    name: "RegEye",
  },
  {
    icon: <FA.FaRegFileAlt />,
    name: "RegFileAlt",
  },
  {
    icon: <FA.FaRegFileArchive />,
    name: "RegFileArchive",
  },
  {
    icon: <FA.FaRegFileAudio />,
    name: "RegFileAudio",
  },
  {
    icon: <FA.FaRegFileCode />,
    name: "RegFileCode",
  },
  {
    icon: <FA.FaRegFileExcel />,
    name: "RegFileExcel",
  },
  {
    icon: <FA.FaRegFileImage />,
    name: "RegFileImage",
  },
  {
    icon: <FA.FaRegFilePdf />,
    name: "RegFilePdf",
  },
  {
    icon: <FA.FaRegFilePowerpoint />,
    name: "RegFilePowerpoint",
  },
  {
    icon: <FA.FaRegFileVideo />,
    name: "RegFileVideo",
  },
  {
    icon: <FA.FaRegFileWord />,
    name: "RegFileWord",
  },
  {
    icon: <FA.FaRegFile />,
    name: "RegFile",
  },
  {
    icon: <FA.FaRegFlag />,
    name: "RegFlag",
  },
  {
    icon: <FA.FaRegFlushed />,
    name: "RegFlushed",
  },
  {
    icon: <FA.FaRegFolderOpen />,
    name: "RegFolderOpen",
  },
  {
    icon: <FA.FaRegFolder />,
    name: "RegFolder",
  },
  {
    icon: <FA.FaRegFontAwesomeLogoFull />,
    name: "RegFontAwesomeLogoFull",
  },
  {
    icon: <FA.FaRegFrownOpen />,
    name: "RegFrownOpen",
  },
  {
    icon: <FA.FaRegFrown />,
    name: "RegFrown",
  },
  {
    icon: <FA.FaRegFutbol />,
    name: "RegFutbol",
  },
  {
    icon: <FA.FaRegGem />,
    name: "RegGem",
  },
  {
    icon: <FA.FaRegGrimace />,
    name: "RegGrimace",
  },
  {
    icon: <FA.FaRegGrinAlt />,
    name: "RegGrinAlt",
  },
  {
    icon: <FA.FaRegGrinBeamSweat />,
    name: "RegGrinBeamSweat",
  },
  {
    icon: <FA.FaRegGrinBeam />,
    name: "RegGrinBeam",
  },
  {
    icon: <FA.FaRegGrinHearts />,
    name: "RegGrinHearts",
  },
  {
    icon: <FA.FaRegGrinSquintTears />,
    name: "RegGrinSquintTears",
  },
  {
    icon: <FA.FaRegGrinSquint />,
    name: "RegGrinSquint",
  },
  {
    icon: <FA.FaRegGrinStars />,
    name: "RegGrinStars",
  },
  {
    icon: <FA.FaRegGrinTears />,
    name: "RegGrinTears",
  },
  {
    icon: <FA.FaRegGrinTongueSquint />,
    name: "RegGrinTongueSquint",
  },
  {
    icon: <FA.FaRegGrinTongueWink />,
    name: "RegGrinTongueWink",
  },
  {
    icon: <FA.FaRegGrinTongue />,
    name: "RegGrinTongue",
  },
  {
    icon: <FA.FaRegGrinWink />,
    name: "RegGrinWink",
  },
  {
    icon: <FA.FaRegGrin />,
    name: "RegGrin",
  },
  {
    icon: <FA.FaRegHandLizard />,
    name: "RegHandLizard",
  },
  {
    icon: <FA.FaRegHandPaper />,
    name: "RegHandPaper",
  },
  {
    icon: <FA.FaRegHandPeace />,
    name: "RegHandPeace",
  },
  {
    icon: <FA.FaRegHandPointDown />,
    name: "RegHandPointDown",
  },
  {
    icon: <FA.FaRegHandPointLeft />,
    name: "RegHandPointLeft",
  },
  {
    icon: <FA.FaRegHandPointRight />,
    name: "RegHandPointRight",
  },
  {
    icon: <FA.FaRegHandPointUp />,
    name: "RegHandPointUp",
  },
  {
    icon: <FA.FaRegHandPointer />,
    name: "RegHandPointer",
  },
  {
    icon: <FA.FaRegHandRock />,
    name: "RegHandRock",
  },
  {
    icon: <FA.FaRegHandScissors />,
    name: "RegHandScissors",
  },
  {
    icon: <FA.FaRegHandSpock />,
    name: "RegHandSpock",
  },
  {
    icon: <FA.FaRegHandshake />,
    name: "RegHandshake",
  },
  {
    icon: <FA.FaRegHdd />,
    name: "RegHdd",
  },
  {
    icon: <FA.FaRegHeart />,
    name: "RegHeart",
  },
  {
    icon: <FA.FaRegHospital />,
    name: "RegHospital",
  },
  {
    icon: <FA.FaRegHourglass />,
    name: "RegHourglass",
  },
  {
    icon: <FA.FaRegIdBadge />,
    name: "RegIdBadge",
  },
  {
    icon: <FA.FaRegIdCard />,
    name: "RegIdCard",
  },
  {
    icon: <FA.FaRegImage />,
    name: "RegImage",
  },
  {
    icon: <FA.FaRegImages />,
    name: "RegImages",
  },
  {
    icon: <FA.FaRegKeyboard />,
    name: "RegKeyboard",
  },
  {
    icon: <FA.FaRegKissBeam />,
    name: "RegKissBeam",
  },
  {
    icon: <FA.FaRegKissWinkHeart />,
    name: "RegKissWinkHeart",
  },
  {
    icon: <FA.FaRegKiss />,
    name: "RegKiss",
  },
  {
    icon: <FA.FaRegLaughBeam />,
    name: "RegLaughBeam",
  },
  {
    icon: <FA.FaRegLaughSquint />,
    name: "RegLaughSquint",
  },
  {
    icon: <FA.FaRegLaughWink />,
    name: "RegLaughWink",
  },
  {
    icon: <FA.FaRegLaugh />,
    name: "RegLaugh",
  },
  {
    icon: <FA.FaRegLemon />,
    name: "RegLemon",
  },
  {
    icon: <FA.FaRegLifeRing />,
    name: "RegLifeRing",
  },
  {
    icon: <FA.FaRegLightbulb />,
    name: "RegLightbulb",
  },
  {
    icon: <FA.FaRegListAlt />,
    name: "RegListAlt",
  },
  {
    icon: <FA.FaRegMap />,
    name: "RegMap",
  },
  {
    icon: <FA.FaRegMehBlank />,
    name: "RegMehBlank",
  },
  {
    icon: <FA.FaRegMehRollingEyes />,
    name: "RegMehRollingEyes",
  },
  {
    icon: <FA.FaRegMeh />,
    name: "RegMeh",
  },
  {
    icon: <FA.FaRegMinusSquare />,
    name: "RegMinusSquare",
  },
  {
    icon: <FA.FaRegMoneyBillAlt />,
    name: "RegMoneyBillAlt",
  },
  {
    icon: <FA.FaRegMoon />,
    name: "RegMoon",
  },
  {
    icon: <FA.FaRegNewspaper />,
    name: "RegNewspaper",
  },
  {
    icon: <FA.FaRegObjectGroup />,
    name: "RegObjectGroup",
  },
  {
    icon: <FA.FaRegObjectUngroup />,
    name: "RegObjectUngroup",
  },
  {
    icon: <FA.FaRegPaperPlane />,
    name: "RegPaperPlane",
  },
  {
    icon: <FA.FaRegPauseCircle />,
    name: "RegPauseCircle",
  },
  {
    icon: <FA.FaRegPlayCircle />,
    name: "RegPlayCircle",
  },
  {
    icon: <FA.FaRegPlusSquare />,
    name: "RegPlusSquare",
  },
  {
    icon: <FA.FaRegQuestionCircle />,
    name: "RegQuestionCircle",
  },
  {
    icon: <FA.FaRegRegistered />,
    name: "RegRegistered",
  },
  {
    icon: <FA.FaRegSadCry />,
    name: "RegSadCry",
  },
  {
    icon: <FA.FaRegSadTear />,
    name: "RegSadTear",
  },
  {
    icon: <FA.FaRegSave />,
    name: "RegSave",
  },
  {
    icon: <FA.FaRegShareSquare />,
    name: "RegShareSquare",
  },
  {
    icon: <FA.FaRegSmileBeam />,
    name: "RegSmileBeam",
  },
  {
    icon: <FA.FaRegSmileWink />,
    name: "RegSmileWink",
  },
  {
    icon: <FA.FaRegSmile />,
    name: "RegSmile",
  },
  {
    icon: <FA.FaRegSnowflake />,
    name: "RegSnowflake",
  },
  {
    icon: <FA.FaRegSquare />,
    name: "RegSquare",
  },
  {
    icon: <FA.FaRegStarHalf />,
    name: "RegStarHalf",
  },
  {
    icon: <FA.FaRegStar />,
    name: "RegStar",
  },
  {
    icon: <FA.FaRegStickyNote />,
    name: "RegStickyNote",
  },
  {
    icon: <FA.FaRegStopCircle />,
    name: "RegStopCircle",
  },
  {
    icon: <FA.FaRegSun />,
    name: "RegSun",
  },
  {
    icon: <FA.FaRegSurprise />,
    name: "RegSurprise",
  },
  {
    icon: <FA.FaRegThumbsDown />,
    name: "RegThumbsDown",
  },
  {
    icon: <FA.FaRegThumbsUp />,
    name: "RegThumbsUp",
  },
  {
    icon: <FA.FaRegTimesCircle />,
    name: "RegTimesCircle",
  },
  {
    icon: <FA.FaRegTired />,
    name: "RegTired",
  },
  {
    icon: <FA.FaRegTrashAlt />,
    name: "RegTrashAlt",
  },
  {
    icon: <FA.FaRegUserCircle />,
    name: "RegUserCircle",
  },
  {
    icon: <FA.FaRegUser />,
    name: "RegUser",
  },
  {
    icon: <FA.FaRegWindowClose />,
    name: "RegWindowClose",
  },
  {
    icon: <FA.FaRegWindowMaximize />,
    name: "RegWindowMaximize",
  },
  {
    icon: <FA.FaRegWindowMinimize />,
    name: "RegWindowMinimize",
  },
  {
    icon: <FA.FaRegWindowRestore />,
    name: "RegWindowRestore",
  },
];
