import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import StylishNewTable from 'components/DesignSystems/New/StylishNewTable';
import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import Dropdown from 'react-bootstrap/Dropdown';
import IconUnlink from 'assets/images/icon__unlink.svg';
import { useDispatch } from 'react-redux';
import { endLoading, startLoading } from 'reducers/loading/loading.action';
import { toastConfig } from 'assets/data/config';
import { toast } from 'react-toastify';
import Network from 'PREPAREsrc/service/Network';
import API from 'PREPAREsrc/service/api';
import StylishNewSelect from 'components/DesignSystems/New/StylishNewSelect';
import { useForm, Controller } from 'react-hook-form';

export const LinkedPersonasDialog = ({
  show,
  onClose,
  data,
  workspaceId,
  setIsPersonasUpdated,
}) => {
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm();

  const reduxDispatch = useDispatch();

  const [linkedPersonas, setLinkedPersonas] = useState([]);
  const [personas, setPersonas] = useState([]);
  const [showLinkPersonas, setShowLinkpersonas] = useState(false);

  useEffect(() => {
    setIsPersonasUpdated && setIsPersonasUpdated(false);
  }, []);

  useEffect(() => {
    if (data && !!data?.mediapersonaprofilerelation?.length) {
      const personas = data?.mediapersonaprofilerelation?.map((i) => ({
        personaName: i?.personaName,
        personaId: i?.personaId,
      }));
      setLinkedPersonas(personas);
    }
  }, [data]);

  const columns = [
    {
      dataField: 'personaName',
      text: 'Name',
      sort: false,
      headerAttrs: { title: 'Name:' },
      attrs: { title: 'Name' },
    },
    {
      text: 'Action',
      isDummyField: true,
      dataField: '',
      formatter: (cell, row, rowIndex, formatExtraData) => (
        <Dropdown>
          <Dropdown.Toggle className="button--icon">
            <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
          </Dropdown.Toggle>
          <Dropdown.Menu align="end">
            <Dropdown.Item
              key={row.personaId}
              as="button"
              onClick={() => handleUnlinkPersona(row?.personaId)}
            >
              <img src={IconUnlink} className="me-2" alt="" />
              Unlink Persona
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      ),
      headerStyle: {
        width: '100px',
      },
      headerAlign: 'center',
      align: 'center',
      attrs: { title: 'Action' },
    },
  ];

  const handleUnlinkPersona = (personaId) => {
    const personas = linkedPersonas.filter(
      (persona) => persona?.personaId !== personaId
    );
    setLinkedPersonas(personas);
  };

  const fetchAllPersonas = async () => {
    reduxDispatch(startLoading());
    try {
      const response = await Network.get(API.mediaPersonaList, {
        workspaceId: workspaceId,
      });
      if (response !== undefined && response?.status === 200) {
        const data = response?.data?.response?.dataset?.map((i) => ({
          label: i?.name,
          value: i?.id,
        }));
        setPersonas(data);
      }
    } catch (error) {
      toast.error('Something went wrong', toastConfig);
    } finally {
      reduxDispatch(endLoading());
    }
  };

  useEffect(() => {
    fetchAllPersonas();
  }, []);

  useEffect(() => {
    const personas = data?.mediapersonaprofilerelation?.map((i) => ({
      label: i?.personaName,
      value: i?.personaId,
    }));
    setValue('personas', personas);
  }, [data]);

  const onSubmit = async (formData) => {
    let personas = [];
    if (showLinkPersonas) {
      personas =
        formData?.personas?.map((i) => ({ mediaPersonaId: i?.value })) || [];
    } else {
      personas =
        linkedPersonas?.map((i) => ({ mediaPersonaId: i?.personaId })) || [];
    }
    reduxDispatch(startLoading());
    try {
      await Network.post(API.updateMediaProfile, {
        profileId: data?.profileId || data?.id,
        personas,
        workspaceId,
      });
      toast.success('Linked personas updated successfully', toastConfig);
      reduxDispatch(endLoading());
    } catch (error) {
      toast.error('Something went wrong', toastConfig);
      console.log(error);
    } finally {
      onClose();
      setIsPersonasUpdated && setIsPersonasUpdated(true);
    }
  };

  return (
    <>
      <Modal
        show={show}
        onHide={onClose}
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton closeVariant="white">
          <Modal.Title>Linked Persona(s)</Modal.Title>
        </Modal.Header>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body>
            <div className="d-flex align-items-center">
              {((data?.profileavatar || data?.avatar) && (
                <div className="avatar mid me-4 flex-shrink-0">
                  <img src={data?.profileavatar || data?.avatar} alt="" />
                </div>
              )) ||
                null}
              {((data?.profileName || data?.name) && (
                <p className="p--l weight-700 m-0 flex-grow-1">
                  {data?.profileName || data?.name}
                </p>
              )) ||
                null}
            </div>
            {((data?.profileavatar ||
              data?.profileName ||
              data?.avatar ||
              data?.name) && <hr className="dashed my-4" />) ||
              null}
            {!showLinkPersonas ? (
              <>
                <p className="weight-700">Linked Personas</p>
                <StylishNewButton
                  className="button--primary"
                  onClick={() => setShowLinkpersonas(true)}
                >
                  Link Persona
                </StylishNewButton>
                {(linkedPersonas && !!linkedPersonas?.length && (
                  <StylishNewTable
                    classes="mt-4 no-filter"
                    headerClasses="empty"
                    keyField={'personaId'}
                    columns={columns}
                    rows={linkedPersonas}
                  />
                )) ||
                  null}
              </>
            ) : (
              <>
                <p className="weight-700">Link Existing Persona</p>
                <Controller
                  control={control}
                  name="personas"
                  rules={{ required: false }}
                  render={({ field: { onChange, value } }) => (
                    <StylishNewSelect
                      options={personas}
                      value={value}
                      onChange={onChange}
                      isClearable={false}
                      isSearchable={false}
                      isMulti={true}
                      isDisabled={false}
                      placeholder="Select Personas"
                    />
                  )}
                />
              </>
            )}
          </Modal.Body>

          <Modal.Footer>
            <div className="button-group w-100">
              <div className="ms-md-auto w-100 w-md-auto mt-3 mt-md-0">
                <StylishNewButton
                  className="button--secondary button--reverse"
                  onClick={onClose}
                >
                  Cancel
                </StylishNewButton>

                <StylishNewButton className="button--primary" type="submit">
                  Save
                </StylishNewButton>
              </div>
            </div>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};
