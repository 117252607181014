import { combineReducers } from 'redux';
import appReducer from './appReducer';
import authReducer from './authReducer';
import mapSlice from '../slices/mapSlice';
import ai2fpSlice from '../slices/ai2fpSlice';
import dataTableSlice from '../slices/dataTableSlice';
import eventMonitorSlice from '../slices/eventMonitorSlice';
import loading from './loading/loading.reducer';
import PREPPAREReducers from '../PREPAREsrc/store/rootReducers';

import ics_incident_infoReducer from '../slices/ics_incidentInfoSlice';
import ics_objectivesReducer from '../slices/ics_objectivesSlice';
import ics_incident_objectivesReducer from '../slices/ics_incidentObjectivesSlice';
import ics_strategiesReducer from '../slices/ics_strategiesSlice';
import ics_incident_strategiesReducer from '../slices/ics_incidentStrategiesSlice';
import ics_tacticsReducer from '../slices/ics_tacticsSlice';
import ics_incident_tacticsReducer from '../slices/ics_incidentTacticsSlice';
import ics_actionsReducer from '../slices/ics_actionsSlice';
import ics_incident_actionsReducer from '../slices/ics_incidentActionsSlice';
import ics_incident_resourcesReducer from '../slices/ics_incidentResourcesSlice';
import ics_resourceRequestsReducer from '../slices/ics_resourceRequestsSlice';
import ics_resourceRequestItemsReducer from '../slices/ics_resourceRequestItemsSlice';
import ics_medicalAidStationsReducer from '../slices/ics_medicalAidStationsSlice';
import ics_incidentMedicalAidStationsReducer from '../slices/ics_incidentMedicalAidStationsSlice';
import ics_medicalTransportationReducer from '../slices/ics_medicalTransportationSlice';
import ics_incidentMedicalTransportationReducer from '../slices/ics_incidentMedicalTransportationSlice';
import ics_hospitalsReducer from '../slices/ics_hospitalsSlice';
import ics_incidentHospitalsReducer from '../slices/ics_incidentHospitalsSlice';
import ics_work_assignmentsReducer from '../slices/ics_workAssignmentsSlice';
import ics_incident_work_assignmentsReducer from '../slices/ics_incidentWorkAssignmentsSlice';
import ics_comsReducer from '../slices/ics_comsSlice';
import ics_incident_comsReducer from '../slices/ics_incidentComsSlice';
import resourceSitesReducer from '../slices/ics_resourceSitesSlice';
import resourceSetsReducer from '../slices/ics_resourceSetsSlice';
import strikeTeamsReducer from '../slices/ics_strikeTeamsSlice';
import dchatReducer from '../slices/dchatSlice'

export default combineReducers({
  app: appReducer,
  auth: authReducer,
  map: mapSlice.reducer,
  ai2fp: ai2fpSlice.reducer,
  dataTable: dataTableSlice.reducer,
  eventMonitor: eventMonitorSlice.reducer,
  loading: loading,
  prepare: PREPPAREReducers,
  ics_incident_info: ics_incident_infoReducer,
  ics_objectives: ics_objectivesReducer,
  ics_incident_objectives: ics_incident_objectivesReducer,
  ics_strategies: ics_strategiesReducer,
  ics_incident_strategies: ics_incident_strategiesReducer,
  ics_tactics: ics_tacticsReducer,
  ics_incident_tactics: ics_incident_tacticsReducer,
  ics_actions: ics_actionsReducer,
  ics_incident_actions: ics_incident_actionsReducer,
  ics_incident_resources: ics_incident_resourcesReducer,
  ics_resource_requests: ics_resourceRequestsReducer,
  ics_resource_request_items: ics_resourceRequestItemsReducer,
  ics_medical_aid_stations: ics_medicalAidStationsReducer,
  ics_incident_medical_aid_stations: ics_incidentMedicalAidStationsReducer,
  ics_medical_transportation: ics_medicalTransportationReducer,
  ics_incident_medical_transportation: ics_incidentMedicalTransportationReducer,
  ics_hospitals: ics_hospitalsReducer,
  ics_incident_hospitals: ics_incidentHospitalsReducer,
  ics_work_assignments: ics_work_assignmentsReducer,
  ics_incident_work_assignments: ics_incident_work_assignmentsReducer,
  ics_coms: ics_comsReducer,
  ics_incident_coms: ics_incident_comsReducer,
  ics_resource_sites: resourceSitesReducer,
  ics_resource_sets: resourceSetsReducer,
  ics_strike_teams: strikeTeamsReducer,
  dchat: dchatReducer,
});
