import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';

import ProfileImage from 'assets/images/icon__profileimage.jpg';
import StylishNewSelect from 'components/DesignSystems/New/StylishNewSelect';
import { useForm, Controller } from 'react-hook-form';
import Network from 'PREPAREsrc/service/Network';
import API from 'PREPAREsrc/service/api';
import { startLoading, endLoading } from 'reducers/loading/loading.action';
import { useDispatch, useSelector } from 'react-redux';
import ReviewProfilesDialog from './ReviewProfilesDialog';
import CreateProfileDialog from '../Profiles/CreateProfileDialog';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import UserIcon from 'assets/images/icon__developer--users.svg';
export default function AddProfilestoPersonaDialog({
  onClose,
  show,
  profileSelectToCreatePersonaPage,
  connectedProfilesForDropDown,
  imageAvatar,
  keyPressForPersonaName,
}) {
  const {
    formState: { errors },
    control,
    setValue,
  } = useForm();

  const [profileList, setProfileList] = useState(null);
  const [selectedProfile, setSelectedProfile] = useState(null);
  const [showCreateProfileDialog, setShowCreateProfileDialog] = useState(false);

  const [showAddRelationshipsDialog, setShowAddRelationshipsDialog] = useState(
    null
  );

  const workspaceId = useSelector(
    (state) => state.prepare.workspace.selectedWorkspace
  );
  const dispatch = useDispatch();

  const onReviewProfileDialog = () => {
    setShowAddRelationshipsDialog(true);
  };
  const onReviewProfileDialogClose = () => {
    setShowAddRelationshipsDialog(false);
  };

  const onCreateProfile = () => {
    setShowCreateProfileDialog(true);
  };
  const onCreateProfileDialogClose = (aftersave) => {
    setShowCreateProfileDialog(false);
    if (aftersave?.id) {
      mediaProfileList();
    }
  };
  const onChooseMediaDialog = () => {
    // setShowChooseMediaDialog(true);
    setShowCreateProfileDialog(false);
  };
  const onprofileSelectToCreatePersonaPage = (data) => {
    profileSelectToCreatePersonaPage(data);
  };

  const mediaProfileList = async () => {
    dispatch(startLoading());
    try {
      const response = await Network.get(API.mediaProfileList, {
        workspaceId: workspaceId,
      });
      const newProfileList = response?.data?.response?.dataset.map(
        ({ name, id, avatar, banner, siteName, username, icon }) => ({
          value: id,
          label: name,
          avatar: avatar,
          banner: banner,
          siteName: siteName,
          username: username,
          icon: icon,
        })
      );
      setProfileList(newProfileList);
      setValue(
        'profiles',
        connectedProfilesForDropDown.map((data) => ({
          value: data?.value,
          label: data?.label,
          avatar: data?.avatar,
        }))
      );
      setSelectedProfile(
        connectedProfilesForDropDown.map((data) => ({
          value: data?.value,
          label: data?.label,
          avatar: data?.avatar,
        }))
      );
    } catch (error) {
    } finally {
      dispatch(endLoading());
    }
  };

  useEffect(() => {
    mediaProfileList();
  }, []);

  return (
    <Modal
      show={show}
      onHide={onClose}
      centered
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title>Add Profiles to Persona</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex align-items-center">
          {imageAvatar ? (
            <div className="profile-img me-3 flex-shrink-0">
              <img src={imageAvatar} alt="" />
            </div>
          ) : (
            <div className="profile-img me-3 flex-shrink-0"></div>
          )}
          {/* <img className="profile-img me-3" alt="" /> */}
          <h4 className="mb-0 me-2 break-word">{keyPressForPersonaName}</h4>
          <div className="d-flex align-items-center ms-auto flex-shrink-0">
            <img className="img-h-16" src={UserIcon} alt="" />
            {selectedProfile?.length ? (
              <p className="mb-0 ms-2">{selectedProfile?.length}</p>
            ) : (
              <p className="mb-0 ms-2">0</p>
            )}
          </div>
        </div>
        <hr className="dashed" />

        <div className="d-flex justify-content-center mb-3">
          <StylishNewButton
            className="button--primary"
            onClick={onCreateProfile}
          >
            Create Profile
          </StylishNewButton>
        </div>

        <p className="line-heading d-flex align-items-center justify-content-center my-4">
          <hr className="m-0 p-0 position-absolute col-10" />
          <span className="break-word px-3 text-center position-relative">
            Or Search Existing Profiles
          </span>
        </p>
        <Controller
          control={control}
          name="profiles"
          render={({ field: { onChange, value } }) => (
            <StylishNewSelect
              options={profileList}
              isClearable={false}
              isSearchable={true}
              value={value || ''}
              onChange={(e) => {
                setSelectedProfile(e);
                onChange(e);
              }}
              isMulti={true}
              isDisabled={false}
              placeholder="Search..."
            />
          )}
        />
      </Modal.Body>
      <Modal.Footer>
        <div className="button-group">
          <StylishNewButton
            className="button--secondary button--reverse"
            onClick={onClose}
          >
            Cancel
          </StylishNewButton>
          <StylishNewButton
            className="button--primary"
            onClick={onReviewProfileDialog}
          >
            Review
          </StylishNewButton>
        </div>
      </Modal.Footer>
      {showAddRelationshipsDialog && (
        <ReviewProfilesDialog
          show={showAddRelationshipsDialog}
          onClose={onReviewProfileDialogClose}
          profileSelectCount={selectedProfile?.length}
          profileSelect={selectedProfile}
          isCloseModals={onClose}
          profileSelectToCreatePersonaPage={onprofileSelectToCreatePersonaPage}
        />
      )}
      {showCreateProfileDialog && (
        <CreateProfileDialog
          show={showCreateProfileDialog}
          onClose={onCreateProfileDialogClose}
          onChooseMediaDialog={onChooseMediaDialog}
        />
      )}
    </Modal>
  );
}
