export const AVAILABLE_ROLES = [
  'Incident Commander',
  'Qualified Individual',
  'Legal Officer',
  'Public Information Officer',
  'Safety Officer',
  'Liaison Officer',
  'Medical Unit Leader',
  'Operations Section Chief',
  'Planning Section Chief',
  'Logistics Section Chief',
  'Finance Section Chief',
  'Communications Unit Leader',
  'Intelligence Officer',
  'Staging Area Manager',
  'Situation Unit Leader',
  'Resource Unit Leader',
  'Documentation Unit Leader',
  'Demobilization Unit Leader',
  'Environmental Unit Leader',
  'Technical Specialists',
  'Support Branch Director',
  'Supply Unit Leader',
  'Facilities Unit Leader',
  'Vessel Support Unit Leader',
  'Ground Support Unit Leader',
  'Service Branch Director',
  'Food Unit Leader',
  'Medical Unit Leader',
  'Cost Unit Leader',
  'Time Unit Leader',
  'Procurement Unit Leader',
  'Compensation Unit Leader',
  'Investigation Chief',
  'Intelligence Group Leader',
  'Investigations Group Leader'
];