import StylishHelp from 'components/DesignSystems/New/StylishHelp';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { StylishNewSearchBar } from 'components/DesignSystems/New/StylishNewSearchBar';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { PreparePath } from 'utils/sharedUtils/sharedConst';
import Dropdown from 'react-bootstrap/Dropdown';
import IconEdit from 'assets/images/icon__edit.svg';
import IconDelete from 'assets/images/icon__delete.svg';
import IconView from 'assets/images/icon__eye.svg';
import IconEmpty from 'assets/images/icon__exercise-details-empty.svg';
import { AllIcon } from 'assets/Icon/Icon';
import ReactPaginate from 'react-paginate';
import Network from 'PREPAREsrc/service/Network';
import API from 'PREPAREsrc/service/api';
import { StylishConfirmDialog } from 'components/DesignSystems/New/StylishConfirmDialog';
import { endLoading, startLoading } from 'reducers/loading/loading.action';
import { toast } from 'react-toastify';
import { toastConfig } from 'assets/data/config';

export const SystemsDashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { selectedWorkspace, type: workspaceType } = useSelector(
    (state) => state?.prepare?.workspace
  );
  const [searchTerm, setSearchTerm] = useState();
  const [page, setPage] = useState(1);
  const [totalSystems, setTotalSystems] = useState(0);
  const [allSystem, setAllSystem] = useState([]);
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
  const [modalData, setModalData] = useState();
  const perPage = 8;
  useEffect(() => {
    if (workspaceType && workspaceType !== 'T&E')
      navigate(`${PreparePath}/dashboard/plan`);
  }, [workspaceType]);

  useEffect(() => {
    if (selectedWorkspace) {
      getAllSystem();
    }
  }, [page, searchTerm, selectedWorkspace]);

  const getAllSystem = async () => {
    try {
      dispatch(startLoading());
      const resp = await Network.get(API.fetchAllSystemDetails, {
        page,
        search: searchTerm,
        workspaceId: selectedWorkspace,
        limit: perPage,
      });
      setAllSystem(resp.data?.response?.dataset);
      setTotalSystems(resp.data?.response?.total || 0);
      dispatch(endLoading());
    } catch (e) {
      console.error(e);
      dispatch(endLoading());
    }
  };

  const onChangeSearchTerm = (termSearched) => {
    setSearchTerm(termSearched);
    setPage(1);
  };

  const handleDeleteSystem = (id) => {
    setShowDeleteConfirmModal(true);
    setModalData(id);
  };
  const onDeleteConfirm = async () => {
    try {
      dispatch(startLoading());
      await Network.post(API.deleteSystem, {
        system_id: modalData,
        workspaceId: selectedWorkspace,
      });
      getAllSystem();
      setShowDeleteConfirmModal(false);
      toast.success('System deleted Successfully', toastConfig);

      dispatch(endLoading());
    } catch (e) {
      toast.error('Failed to delete system', toastConfig);
      dispatch(endLoading());
    }
  };

  const handlePageClick = (event) => {
    setPage(event.selected + 1);
  };

  const onCloseDeleteSystem = () => {
    setShowDeleteConfirmModal(false);
    setModalData(null);
  };

  return (
    <>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <StylishNewButton
              onClick={() => navigate(`${PreparePath}/dashboard/plan`)}
            >
              <i className="fa fa-angle-left me-2" aria-hidden="true"></i>
              Back
            </StylishNewButton>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Systems
          </li>
        </ol>
      </nav>

      <div className="d-md-flex align-items-center mb-4">
        <StylishNewButton
          className="button--primary w-100 w-md-auto mb-3 mb-md-0"
          onClick={() =>
            navigate(`${PreparePath}/dashboard/plan/systems/create`)
          }
        >
          Create System
        </StylishNewButton>
        <div className="d-flex flex-wrap align-items-center ms-auto">
          <p className="m-0 me-3 text-nowrap order-1 order-md-0">
            {totalSystems || 0} Systems
          </p>
          <StylishNewSearchBar
            className={'w-100 w-md-auto mb-3 mb-md-0'}
            onChangeSearchTerm={onChangeSearchTerm}
          />
          <StylishHelp classes={'ms-auto ms-md-3 flex-shrink-0 order-2'}
            title="Knowledge Base For U.S. Navy Test & Experimentation Workspace"
            content="Visit our internal reference guide for more information."
            link="/knowledge_base"
          />
        </div>
      </div>
      {(allSystem && allSystem?.length && (
        <>
          <div className="row">
            {allSystem?.map((system) => (
              <div
                className="col-md-6 col-lg-4 col-xl-3 pb-3 mb-4"
                key={system?.id}
              >
                <div className="section__system-item bg-gray-800 rounded p-4 cursor-pointer position-relative">
                  <span
                    className="stretched-link"
                    onClick={() =>
                      navigate(
                        `${PreparePath}/dashboard/plan/systems/view/${system?.id}`
                      )
                    }
                  ></span>
                  <div className="title d-flex align-items-start mb-4">
                    <div
                      className="d-flex align-items-center flex-grow-1 me-4"
                      style={{ width: 'calc(100% - (34px + 1.5rem))' }}
                    >
                      {(system?.icon && (
                        <span className="icon flex-shrink-0 me-3">
                          {
                            AllIcon.find((icon) => icon?.name === system?.icon)
                              .icon
                          }
                        </span>
                      )) ||
                        null}
                      <div
                        className="flex-grow-1"
                        style={{ width: 'calc(100% - 3.25rem)' }}
                      >
                        {(system?.name && (
                          <h4 className="m-0 text-truncate">{system?.name}</h4>
                        )) ||
                          null}
                        {(system?.event_cell_type && (
                          <span className="d-block text-truncate">
                            {system.event_cell_type === 'blue_cell'
                              ? 'Blue Cell'
                              : 'Red Cell'}
                          </span>
                        )) ||
                          null}
                      </div>
                    </div>
                    <Dropdown className="flex-shrink-0 ms-auto position-relative zIndex--1">
                      <Dropdown.Toggle className="button--icon">
                        <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                      </Dropdown.Toggle>
                      <Dropdown.Menu align="end">
                        <Link
                          className="dropdown-item"
                          to={`${PreparePath}/dashboard/plan/systems/${system.id}`}
                        >
                          <img src={IconEdit} alt="" />
                          Edit System
                        </Link>
                        <Link
                          className="dropdown-item"
                          to={`${PreparePath}/dashboard/plan/systems/view/${system?.id}`}
                        >
                          <img src={IconView} alt="" />
                          View System
                        </Link>
                        <Dropdown.Item
                          onClick={() => handleDeleteSystem(system.id)}
                        >
                          <img src={IconDelete} alt="" />
                          Delete System
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  {((system?.provider ||
                    system?.category ||
                    system?.quantity ||
                    system?.primaryPOC) && (
                    <ul className="list mb-3">
                      {(system?.provider && (
                        <li>Provider - {system?.provider}</li>
                      )) ||
                        null}
                      {(system?.category && (
                        <li>Category - {system?.category}</li>
                      )) ||
                        null}
                      {(system?.quantity && (
                        <li>Quantity - {system?.quantity}</li>
                      )) ||
                        null}
                      {(system?.is_primary && (
                        <li>
                          Primary POC - {system?.username || system?.useremail}
                        </li>
                      )) || <li>Primary POC - N/A</li>}
                    </ul>
                  )) ||
                    null}
                  <div
                    className="details"
                    onClick={() =>
                      navigate(
                        `${PreparePath}/dashboard/plan/systems/view/${system?.id}`
                      )
                    }
                  >
                    <i className="fa fa-angle-right" aria-hidden="true"></i>
                  </div>
                </div>
              </div>
            ))}
          </div>
          {(totalSystems > perPage && (
            <div className="d-flex justify-content-center justify-content-md-end mt-4">
              <ReactPaginate
                className="pagination"
                breakLabel="..."
                nextLabel="Next"
                onPageChange={handlePageClick}
                pageCount={Math.ceil(totalSystems / perPage)}
                previousLabel="Prev"
              />
            </div>
          )) ||
            null}
        </>
      )) || (
        <div className="bg-gray-800 text-center p-5">
          <div className="py-0 py-md-4">
            <div className="d-flex justify-content-center mb-4">
              <img src={IconEmpty} alt="" />
            </div>
            <p className="weight-600 mb-4">
              A fresh start. Create the first entry.
            </p>
            <StylishNewButton
              onClick={() =>
                navigate(`${PreparePath}/dashboard/plan/systems/create`)
              }
              className="button button--primary w-100 w-md-auto mb-3 mb-md-0"
            >
              Create System
            </StylishNewButton>
          </div>
        </div>
      )}
      {showDeleteConfirmModal && (
        <StylishConfirmDialog
          show={showDeleteConfirmModal}
          dialogTitle={`Delete System?`}
          dialogContent={`Are you sure you want to delete this system?`}
          onClose={onCloseDeleteSystem}
          onConfirm={onDeleteConfirm}
        />
      )}
    </>
  );
};
