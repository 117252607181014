import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { PreparePath } from 'utils/sharedUtils/sharedConst';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import moment from 'moment';

export const ViewFeedbackDod = ({ data }) => {
  const navigate = useNavigate();
  const reduxUser = useSelector((state) => state.prepare.user);

  const pdfHeaders = [
    {
      identifier: 'initials',
      data: [
        {
          date: 'Date',
          participant_plaform: 'Participant Plaform',
          objective: 'Objective',
          task: 'Task',
          condition: 'Condition',
          standard: 'Standard',
        },
      ],
    },

    {
      identifier: 'trainingObjectives',
      data: [
        {
          objective_completed: 'Objective Completed?',
          status: 'Status',
          reason: 'Reason',
          comment: 'Comment',
        },
      ],
    },
    {
      identifier: 'measures',
      data: [
        {
          name: 'Measure Title',
          value: 'Value',
        },
      ],
    },
    {
      identifier: 'metrics',
      data: [
        {
          metric: 'Metric',
          number_completed: 'Number Completed',
        },
      ],
    },
    {
      identifier: 'qualifications',
      data: [
        {
          qualification: 'Qualification',
          number_completed: 'Number Completed',
        },
      ],
    },
    {
      identifier: 'lessionlearned',
      data: [
        {
          potential_opr: 'Potential OPR',
          classification: 'Classification',
          title: 'Title',
          observation: 'Observation',
          discussion: 'Discussion',
          recommendation: 'Recommendation',
          implications: 'Implications',
          event_description: 'Event Description',
          comments: 'Comments',
        },
      ],
    },
  ];

  const generatePDF = () => {
    jsPDF.autoTableSetDefaults({
      headStyles: { fillColor: '#0099FF' },
    });

    var doc = new jsPDF({ orientation: 'landscape' });

    doc.text('Feedback Details', 7, 15);

    doc.autoTable({
      startY: 25,
      head: pdfHeaders.find((i) => i.identifier === 'initials').data,
      body: [
        {
          date: moment(data?.createdAt).isValid()
            ? `${moment(data?.createdAt).format('Do MMM YYYY - HH:MM')} (
                ${moment(data?.createdAt).tz(moment.tz.guess()).format('Z')})`
            : 'N/A',
          participant_plaform: `${data?.participantname} - ${data?.platformtitle}`,
          participant_plaform:
            data?.participantname || data?.platformtitle
              ? `${data?.participantname || ''} ${
                  data?.platformtitle ? `- ${data?.platformtitle}` : ''
                }`
              : 'N/A',
          objective: data?.jmet || 'N/A',
          task: data?.task || 'N/A',
          condition: data?.condition || 'N/A',
          standard: data?.standard || 'N/A',
        },
      ],
      showHead: 'firstPage',
      margin: { horizontal: 7 },
      pageBreak: 'avoid',
    });

    doc.autoTable({
      startY: doc.autoTable.previous.finalY + 10,
      head: pdfHeaders.find((i) => i.identifier === 'trainingObjectives').data,
      body: [
        {
          objective_completed: data?.evalcomplete ? 'Yes' : 'No',
          status: data?.evalstatus || 'N/A',
          reason: data?.reason || 'N/A',
          comment: data?.evalcomment || 'N/A',
        },
      ],
      showHead: 'firstPage',
      margin: { horizontal: 7 },
      pageBreak: 'avoid',
    });

    doc.autoTable({
      startY: doc.autoTable.previous.finalY + 10,
      head: pdfHeaders.find((i) => i.identifier === 'measures').data,
      body:
        (data?.measure &&
          !!data?.measure?.length &&
          data?.measure.map((i) => ({
            name: i?.name || 'N/A',
            value: i?.title || 'N/A',
          }))) ||
        pdfHeaders
          .find((i) => i.identifier === 'measures')
          .data.map((i) => ({
            name: 'N/A',
            value: 'N/A',
          })),
      showHead: 'firstPage',
      margin: { horizontal: 7 },
      pageBreak: 'avoid',
    });

    doc.autoTable({
      startY: doc.autoTable.previous.finalY + 10,
      head: pdfHeaders.find((i) => i.identifier === 'metrics').data,
      body:
        (data?.metrics &&
          !!data?.metrics?.length &&
          data?.metrics.map((i) => ({
            metric: i?.metricsTitle || 'N/A',
            number_completed: i?.feedbackData || 'N/A',
          }))) ||
        pdfHeaders
          .find((i) => i.identifier === 'metrics')
          .data.map((i) => ({
            metric: 'N/A',
            number_completed: 'N/A',
          })),
      showHead: 'firstPage',
      margin: { horizontal: 7 },
      pageBreak: 'avoid',
    });

    doc.autoTable({
      startY: doc.autoTable.previous.finalY + 10,
      head: pdfHeaders.find((i) => i.identifier === 'qualifications').data,
      body:
        (data?.qualification &&
          !!data?.qualification?.length &&
          data?.qualification.map((i) => ({
            qualification: i?.qualificationTask || 'N/A',
            number_completed: i?.numberCompleted || 'N/A',
          }))) ||
        pdfHeaders
          .find((i) => i.identifier === 'qualifications')
          .data.map((i) => ({
            qualification: 'N/A',
            number_completed: 'N/A',
          })),
      showHead: 'firstPage',
      margin: { horizontal: 7 },
      pageBreak: 'avoid',
    });

    doc.autoTable({
      startY: doc.autoTable.previous.finalY + 10,
      head: pdfHeaders.find((i) => i.identifier === 'lessionlearned').data,
      body:
        (data?.lessionlearned &&
          !!data?.lessionlearned?.length &&
          data?.lessionlearned.map((i) => ({
            potential_opr: i?.potentialOPR || 'N/A',
            classification: i?.classification || 'N/A',
            title: i?.title || 'N/A',
            observation: i?.observation || 'N/A',
            discussion: i?.discussion || 'N/A',
            recommendation: i?.recommendation || 'N/A',
            implications: i?.implications || 'N/A',
            event_description: i?.eventDescription || 'N/A',
            comments: i?.comments || 'N/A',
          }))) ||
        pdfHeaders
          .find((i) => i.identifier === 'lessionlearned')
          .data.map((i) => ({
            potential_opr: 'N/A',
            classification: 'N/A',
            title: 'N/A',
            observation: 'N/A',
            discussion: 'N/A',
            recommendation: 'N/A',
            implications: 'N/A',
            event_description: 'N/A',
            comments: 'N/A',
          })),
      showHead: 'firstPage',
      margin: { horizontal: 7 },
      pageBreak: 'avoid',
    });

    doc.save(
      `Feedback-DOD-${moment().format(
        reduxUser?.timeFormat === 'hour12Mode'
          ? 'YYYY_MM_DD_hh_mm a'
          : 'YYYY_MM_DD_HH_mm'
      )}.pdf`
    );
  };

  return (
    <>
      <div className="form-block">
        <div className="row">
          <div className="col-md-6 mb-4">
            <label className="form-label">Date</label>
            <p className="m-0">
              {data?.createdAt ? (
                <>
                  {moment(data?.createdAt).format('Do MMM YYYY - HH:MM')} (
                  {moment(data?.createdAt).tz(moment.tz.guess()).format('Z')})
                </>
              ) : (
                '-'
              )}
            </p>
          </div>
          <div className="col-md-6 mb-4">
            <label className="form-label">Participant Plaform</label>
            <p className="m-0">
              {data?.participantname || data?.platformtitle ? (
                <>
                  {data?.participantname ? (
                    <span>{data?.participantname}</span>
                  ) : null}
                  {data?.platformtitle ? (
                    <span> - {data?.platformtitle}</span>
                  ) : null}
                </>
              ) : (
                '-'
              )}
            </p>
          </div>
          <div className="col-md-6 mb-4">
            <label className="form-label">Objective</label>
            <p className="m-0">{data?.jmet ? <>{data?.jmet}</> : '-'}</p>
          </div>
          <div className="col-md-6 mb-4 mb-md-0">
            <label className="form-label">Task</label>
            <p className="m-0">{data?.task ? <>{data?.task}</> : '-'}</p>
          </div>
          <div className="col-md-6 mb-4 mb-md-0">
            <label className="form-label">Condition</label>
            <p className="m-0">
              {data?.condition ? <>{data?.condition}</> : '-'}
            </p>
          </div>
          <div className="col-md-6 mb-4 mb-md-0">
            <label className="form-label">Standard</label>
            <p className="m-0">
              {data?.standard ? <>{data?.standard}</> : '-'}
            </p>
          </div>
        </div>
      </div>
      <div className="form-block mt-4">
        <h4>Self Assessment</h4>
        <div className="row">
          <div className="col-md-12 mb-4">
            <label className="form-label">Was this objective completed?</label>
            <p className="m-0">
              {data?.evalcomplete ? (
                <>{data?.evalcomplete ? 'Yes' : 'No'}</>
              ) : (
                '-'
              )}
            </p>
          </div>
          <div className="col-md-12 mb-4">
            <label className="form-label">
              To which level of effectiveness was this objective completed?
            </label>
            <p className="m-0">
              {data?.evalstatus ? <>{data?.evalstatus}</> : '-'}
            </p>
          </div>
          <div className="col-md-12 mb-4">
            <label className="form-label">
              Why was the objective not effective?
            </label>
            <p className="m-0">{data?.reason ? <>{data?.reason}</> : '-'}</p>
          </div>
          <div className="col-md-12">
            <label className="form-label">Comment</label>
            <p className="m-0">
              {data?.evalcomment ? <>{data?.evalcomment}</> : '-'}
            </p>
          </div>
        </div>
      </div>
      <div className="form-block mt-4">
        <h4>Measures</h4>
        {data?.measure && !!data?.measure?.length ? (
          <>
            {data.measure.map((i, idx) => (
              <div className="row" key={idx}>
                {idx !== 0 ? (
                  <div className="col-md-12">
                    <hr className="dashed" />
                  </div>
                ) : null}

                <div className="col-md-6">
                  <label className="form-label">Name</label>
                  <p className="m-0">{i?.name ? <>{i?.name}</> : '-'}</p>
                </div>
                <div className="col-md-6">
                  <label className="form-label">Value</label>
                  <p className="m-0">{i?.title ? <>{i?.title}</> : '-'}</p>
                </div>
              </div>
            ))}
          </>
        ) : (
          <p className="m-0">No Measure Added</p>
        )}
      </div>
      <div className="form-block mt-4">
        <h4>Metrics</h4>
        {data?.metrics && !!data?.metrics?.length ? (
          <>
            {data.metrics.map((i, idx) => (
              <div className="row" key={idx}>
                {idx !== 0 ? (
                  <div className="col-md-12">
                    <hr className="dashed" />
                  </div>
                ) : null}
                <div className="col-md-6">
                  <label className="form-label">Metric</label>
                  <p className="m-0">
                    {i?.metricsTitle ? <>{i?.metricsTitle}</> : '-'}
                  </p>
                </div>
                <div className="col-md-6">
                  <label className="form-label">Number Completed</label>
                  <p className="m-0">
                    {i?.feedbackData ? <>{i?.feedbackData}</> : '-'}
                  </p>
                </div>
              </div>
            ))}
          </>
        ) : (
          <p className="m-0">No Metrics Added</p>
        )}
      </div>
      <div className="form-block mt-4">
        <h4>Qualifications</h4>
        {data?.qualification && !!data?.qualification?.length ? (
          <>
            {data.qualification.map((i, idx) => (
              <div className="row" key={idx}>
                {idx !== 0 ? (
                  <div className="col-md-12">
                    <hr className="dashed" />
                  </div>
                ) : null}
                <div className="col-md-6">
                  <label className="form-label">Qualification</label>
                  <p className="m-0">
                    {i?.qualificationTask ? <>{i?.qualificationTask}</> : '-'}
                  </p>
                </div>
                <div className="col-md-6">
                  <label className="form-label">Number Completed</label>
                  <p className="m-0">
                    {i?.numberCompleted ? <>{i?.numberCompleted}</> : '-'}
                  </p>
                </div>
              </div>
            ))}
          </>
        ) : (
          <p className="m-0">No Qualifications Added</p>
        )}
      </div>
      <div className="form-block mt-4">
        <h4>Lessons Learned</h4>
        {data?.lessionlearned && !!data?.lessionlearned?.length ? (
          <>
            {data.lessionlearned.map((i, idx) => (
              <div className="row" key={idx}>
                {idx !== 0 ? (
                  <div className="col-md-12">
                    <hr className="dashed" />
                  </div>
                ) : null}
                <div className="col-md-6 mb-4">
                  <label className="form-label">Potential OPR</label>
                  <p className="m-0">
                    {i?.potentialOPR ? <>{i?.potentialOPR}</> : '-'}
                  </p>
                </div>
                <div className="col-md-6 mb-4">
                  <label className="form-label">Classification</label>
                  <p className="m-0">
                    {i?.classification ? <>{i?.classification}</> : '-'}
                  </p>
                </div>
                <div className="col-md-12 mb-4">
                  <label className="form-label">Title</label>
                  <p className="m-0">{i?.title ? <>{i?.title}</> : '-'}</p>
                </div>
                <div className="col-md-6 mb-4">
                  <label className="form-label">Observation</label>
                  <p className="m-0">
                    {i?.observation ? <>{i?.observation}</> : '-'}
                  </p>
                </div>
                <div className="col-md-6 mb-4">
                  <label className="form-label">Discussion</label>
                  <p className="m-0">
                    {i?.discussion ? <>{i?.discussion}</> : '-'}
                  </p>
                </div>
                <div className="col-md-6 mb-4">
                  <label className="form-label">Recommendation</label>
                  <p className="m-0">
                    {i?.recommendation ? <>{i?.recommendation}</> : '-'}
                  </p>
                </div>
                <div className="col-md-6 mb-4">
                  <label className="form-label">Implications</label>
                  <p className="m-0">
                    {i?.implications ? <>{i?.implications}</> : '-'}
                  </p>
                </div>
                <div className="col-md-6 mb-4 mb-md-0">
                  <label className="form-label">Event Description</label>
                  <p className="m-0">
                    {i?.eventDescription ? <>{i?.eventDescription}</> : '-'}
                  </p>
                </div>
                <div className="col-md-6">
                  <label className="form-label">Comments</label>
                  <p className="m-0">
                    {i?.comments ? <>{i?.comments}</> : '-'}
                  </p>
                </div>
              </div>
            ))}
          </>
        ) : (
          <p className="m-0">No Lessons Learned Added</p>
        )}
      </div>
      <div className="button-group mt-4">
        <StylishNewButton
          className={'button--secondary button--reverse ms-auto'}
          onClick={() => navigate(`${PreparePath}/dashboard/plan/feedback`)}
        >
          Back
        </StylishNewButton>
        <StylishNewButton className={'button--primary'} onClick={generatePDF}>
          Download PDF
        </StylishNewButton>
      </div>
    </>
  );
};
