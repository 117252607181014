import React, { useEffect } from 'react';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { StylishNewSearchBar } from 'components/DesignSystems/New/StylishNewSearchBar';
import StylishNewSelect from 'components/DesignSystems/New/StylishNewSelect';
import StylishNewTable from 'components/DesignSystems/New/StylishNewTable';
import StylishNewTableAction from 'components/DesignSystems/New/StylishNewTableAction';
import { useState } from 'react';
import { AddEditSystemDataSourceDialog } from './AddEditSystemDataSourceDialog';
import { endLoading, startLoading } from 'reducers/loading/loading.action';
import { useDispatch, useSelector } from 'react-redux';
import API from 'PREPAREsrc/service/api';
import Network from 'PREPAREsrc/service/Network';
import { useForm, Controller } from 'react-hook-form';
import { toastConfig } from 'assets/data/config';
import { toast } from 'react-toastify';
import { StylishConfirmDialog } from 'components/DesignSystems/New/StylishConfirmDialog';
import IconPlaceholder from 'assets/images/icon__exercise-details-empty.svg';

const perPageOptions = [
  { value: '5', label: '5 per page' },
  { value: '15', label: '15 per page' },
  { value: '30', label: '30 per page' },
  { value: '50', label: '50 per page' },
];

export const DataCollection = ({ changeTab }) => {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(perPageOptions[0].value);
  const [orderBy, setOrderBy] = useState(0);
  const [searchTerm, setSearchTerm] = useState(null);
  const [showSystemDataSourceDialog, setShowSystemDataSourceDialog] = useState(
    false
  );
  const [allDataSources, setAllDataSources] = useState([]);
  const [dialogType, setDialogType] = useState('Add');
  const [updateDialogData, setUpdateDialogData] = useState(null);
  const [deleteDataSourceId, setDeleteDataSourceId] = useState(null);
  const [total, setTotal] = useState(0);

  const dispatch = useDispatch();
  const { setValue, control } = useForm();

  const workspaceId = useSelector(
    (state) => state.prepare.workspace.selectedWorkspace
  );

  // const workspace = useSelector((state) => {
  //   return state?.prepare?.workspace;
  // });

  const eventId = useSelector((state) => state.prepare.planDashboard.eventId);
  const { selectedWorkspace } = useSelector(
    (state) => state?.prepare?.workspace
  );

  const onChangeSearchTerm = (termSearched) => {
    setPage(1);
    setSearchTerm(termSearched);
  };

  const columns = [
    {
      dataField: 'source',
      text: 'Source',
      sort: true,
      attrs: { title: 'Source' },
      headerAttrs: { title: 'Sort By:' },
      onSort: (field, order) => onSortFunction(field, order),
    },
    {
      dataField: 'provider',
      text: 'Provider',
      sort: true,
      attrs: { title: 'Provider' },
      onSort: (field, order) => onSortFunction(field, order),
    },
    {
      dataField: 'collector',
      text: 'Collector',
      sort: true,
      attrs: { title: 'Collector' },
      onSort: (field, order) => onSortFunction(field, order),
    },
    {
      dataField: 'method',
      text: 'Method',
      sort: true,
      attrs: { title: 'Method' },
      onSort: (field, order) => onSortFunction(field, order),
    },
    {
      text: 'Action',
      dataField: 'action',
      isDummyField: true,
      formatter: (cell, row, rowIndex) => (
        <StylishNewTableAction
          name="Data Source"
          cell={cell}
          row={row}
          rowIndex={rowIndex}
          onDelete={onDeleteDataSource}
          onEdit={onEditDataSource}
        />
      ),
      attrs: { title: 'Action' },
      headerStyle: {
        width: '100px',
      },
      headerAlign: 'center',
      align: 'center',
    },
  ];

  const onSortFunction = (field, order) => {
    if (order === 'asc') {
      setOrderBy(0);
    } else if (order === 'desc') {
      setOrderBy(1);
    } else {
      setOrderBy(0);
    }
  };

  const dataSourceList = async () => {
    dispatch(startLoading());
    try {
      const sourceListData = await Network.get(API.getDataCollectionList, {
        workspaceId: workspaceId,
        planEventId: eventId,
        search: searchTerm,
        limit: perPage,
        page: page,
        sort: orderBy,
      });
      setAllDataSources(sourceListData?.data?.response?.dataset);
      setTotal(sourceListData?.data?.response?.count);
    } catch (error) {
      dispatch(endLoading());
      toast.error(error.response.data.response.status.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };

  useEffect(() => {
    if (changeTab === 'data_collection') {
      setPage(1);
      setPerPage(5);
      setSearchTerm(null);
    }
  }, [changeTab, selectedWorkspace, eventId]); // workspace.type, workspaceId may add for workspace related change

  useEffect(() => {
    if (changeTab === 'data_collection') {
      dataSourceList();
      setValue('page', {
        value: perPage,
        label: perPage + ' per page',
      });
    }
  }, [page, perPage, searchTerm, orderBy, eventId, selectedWorkspace]); // workspaceId may add for workspace related change

  const onAddDataSource = () => {
    setDialogType('Add');
    setShowSystemDataSourceDialog(true);
  };

  const onDeleteDataSource = (row) => {
    setDeleteDataSourceId(row?.id);
  };

  const onEditDataSource = (row) => {
    setUpdateDialogData(row);
    setDialogType('Edit');
    setShowSystemDataSourceDialog(true);
  };

  const onAddOrEditDataSource = (afterSave) => {
    setUpdateDialogData(null);
    setDialogType('New');
    setShowSystemDataSourceDialog(false);
    if (afterSave?.id) {
      dataSourceList();
    }
  };

  const onDeleteConfirm = async () => {
    dispatch(startLoading());
    try {
      await Network.post(API.deleteDataCollection, {
        id: deleteDataSourceId,
      });
      setDeleteDataSourceId(null);
      dataSourceList();
      toast.success('Deleted  successfully', toastConfig);
    } catch (error) {
      dispatch(endLoading());
      toast.error(error.response.data.response.status.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };

  return (
    <>
      {(((allDataSources && !!allDataSources?.length) ||
        searchTerm !== null) && (
        <>
          <div className="d-md-flex align-items-center mb-4">
            <StylishNewButton
              type="button"
              className={'button--primary w-100 w-md-auto mb-3 mb-md-0'}
              onClick={onAddDataSource}
            >
              Add Data Source
            </StylishNewButton>
            <div className="ms-auto d-md-flex align-items-center">
              <StylishNewSearchBar
                value={searchTerm}
                onChangeSearchTerm={onChangeSearchTerm}
              />

              <Controller
                control={control}
                name="page"
                rules={{ required: false }}
                render={({ field: { onChange, value } }) => (
                  <StylishNewSelect
                    options={perPageOptions}
                    value={value}
                    onChange={(e) => {
                      if (e.value !== perPage) {
                        setPerPage(e.value);
                        onChange(e.value);
                        setPage(1);
                      }
                    }}
                    placeholder={'Select per page'}
                    isClearable={false}
                    isSearchable={false}
                    isMulti={false}
                    isDisabled={false}
                    classes={'ms-md-3 mt-3 mt-md-0'}
                  />
                )}
              />
            </div>
          </div>
          <h5 className="mb-4">System Data Sources</h5>
          <StylishNewTable
            keyField={'id'}
            columns={columns}
            rows={allDataSources}
            onDelete={onDeleteDataSource}
            onEdit={onEditDataSource}
            pagination
            page={page}
            perPage={perPage}
            total={total}
            onPageChange={(page) => setPage(page)}
          />
        </>
      )) || (
        <div className="bg-gray-800 text-center px-4 py-5 p-md-5">
          <div className="py-0 py-md-4">
            <div className="d-flex justify-content-center mb-4">
              <img src={IconPlaceholder} alt="" />
            </div>
            <p className="weight-600 mb-3">
              A fresh start. Create the first entry.
            </p>
            <div className="d-flex justify-content-center">
              <StylishNewButton
                type="button"
                className={'button--primary'}
                onClick={onAddDataSource}
              >
                Add Data Source
              </StylishNewButton>
            </div>
          </div>
        </div>
      )}
      {(showSystemDataSourceDialog && (
        <AddEditSystemDataSourceDialog
          show={showSystemDataSourceDialog}
          onClose={onAddOrEditDataSource}
          dialogType={dialogType}
          updateDialogData={updateDialogData}
        />
      )) ||
        null}

      {(deleteDataSourceId && (
        <StylishConfirmDialog
          show={deleteDataSourceId}
          onClose={() => setDeleteDataSourceId(null)}
          dialogTitle={'Delete Data Source'}
          dialogContentType={'html'}
          dialogContent={`<span class="d-block pt-3">Are you sure you want to delete this Data Source?</span>`}
          onConfirm={onDeleteConfirm}
        />
      )) ||
        null}
    </>
  );
};
