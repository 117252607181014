import React, { useEffect, useState } from 'react';
import AddEditInjectMethodDialog from './AddEditInjectMethodDialog';
import { useDispatch, useSelector } from 'react-redux';
import Network from '../../../service/Network';
import API from '../../../service/api';
import { toast } from 'react-toastify';
import { AllIcon } from './Icon';
import StylishHelp from 'components/DesignSystems/New/StylishHelp';
import { HelpInjectMethod, useSelfHostedKnowledgeBase } from 'assets/data/HelpContent';
import StylishNewTable from 'components/DesignSystems/New/StylishNewTable';
import { StylishNewSearchBar } from 'components/DesignSystems/New/StylishNewSearchBar';
import StylishNewTableAction from 'components/DesignSystems/New/StylishNewTableAction';
import { StylishConfirmDialog } from 'components/DesignSystems/New/StylishConfirmDialog';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { toastConfig } from 'assets/data/config';
import { PaginationDropdown } from '../../../../components/DesignSystems/PaginationDropdown';
import {
  endLoading,
  startLoading,
} from '../../../../reducers/loading/loading.action';

export default function InjectMethod() {
  const dispatch = useDispatch();
  const workspaceId = useSelector(
    (state) => state.prepare.workspace.selectedWorkspace
  );

  const [total, setTotal] = useState(0);
  const [perPage, setPerPage] = useState(25);
  const [dialogType, setDialogType] = useState('Add');
  const [
    showInjectMethodAddEditDialog,
    setShowInjectMethodAddEditDialog,
  ] = useState(false);
  const [deleteInjectMethodId, setDeleteInjectMethodId] = useState(null);
  const [updateDialogData, setUpdateDialogData] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(1);
  const [injectMethods, setInjectMethods] = useState([]);
  const [deleteInjectMethodTitle, setDeleteInjectMethodTitle] = useState('');

  const selfHostedKnowledgeBase = useSelfHostedKnowledgeBase()

  const columns = [
    {
      dataField: 'name',
      text: 'Name',
      sort: true,
      headerAttrs: { title: 'Sort By:' },
      attrs: { title: 'Name' },
    },
    {
      dataField: 'icon_color',
      text: 'Icon & Color',
      formatter: (cell, row, rowIndex) => (
        <span className="status" style={{ backgroundColor: row.color }}>
          {AllIcon.find((icon) => icon.name === row.icon)?.icon}
        </span>
      ),
      attrs: { title: 'Icon & Color' },
    },
    {
      dataField: '',
      text: 'Action',
      isDummyField: true,
      formatter: (cell, row, rowIndex) => (
        <StylishNewTableAction
          name="Inject Methods"
          cell={cell}
          row={row}
          rowIndex={rowIndex}
          onDelete={() => onDeleteInjectMethod(row)}
          onEdit={() => onEditInjectMethod(row)}
        />
      ),
      headerStyle: {
        width: '100px',
      },
      headerAlign: 'center',
      align: 'center',
      attrs: { title: 'Action' },
    },
  ];
  useEffect(() => {
    getAllInjectMethod();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [perPage, searchTerm, page, workspaceId]);
  const getAllInjectMethod = async () => {
    dispatch(startLoading());
    try {
      const injectMethods = await Network.get(API.getAllInjectMethods, {
        limit: perPage,
        page: page,
        search: searchTerm,
        workspaceId: workspaceId,
      });

      setInjectMethods(injectMethods.data.response.dataset);

      setTotal(injectMethods.data.response.count);
    } catch (error) {
      toast.error(error.response.data.response.status.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };
  const onAddInjectMethod = () => {
    setDialogType('Add');
    setShowInjectMethodAddEditDialog(true);
  };
  const onDeleteInjectMethod = (row) => {
    setDeleteInjectMethodId(row.id);
    setDeleteInjectMethodTitle(row.name);
  };
  const onEditInjectMethod = (row) => {
    setUpdateDialogData(row);
    setDialogType('Edit');
    setShowInjectMethodAddEditDialog(true);
  };
  const OnAddOrEditInjectMethod = (afterSave) => {
    setUpdateDialogData(null);
    setDialogType('New');
    setShowInjectMethodAddEditDialog(false);
    if (afterSave?.id) {
      getAllInjectMethod();
    }
  };
  const onDeleteConfirm = async () => {
    dispatch(startLoading());
    try {
      await Network.post(API.deletePlanInjectMethod, {
        methodId: deleteInjectMethodId,
      });
      setDeleteInjectMethodId(null);
      getAllInjectMethod();
      toast.success('Inject Method deleted successfully', toastConfig);
    } catch (error) {
      toast.error(error.response.data.response.status.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };
  const onChangeSearchTerm = (term) => {
    setPage(1);
    setSearchTerm(term);
  };

  return (
    <>
      <div className="settings__inject-methods">
        <StylishNewButton
          className="button button--primary mb-4"
          onClick={onAddInjectMethod}
        >
          Create Method
        </StylishNewButton>
        <div className="form-block">
          <div className="row">
            <div className="col-md-12">
              <div className="d-flex flex-column flex-md-row align-items-md-center mb-4">
                <div className="d-flex align-items-center justify-content-between">
                  <p className="m-0 me-3 text-nowrap">{total || 0} Methods</p>
                  <div className="">
                    <PaginationDropdown
                      setPage={setPage}
                      setPerPage={setPerPage}
                      options={[
                        { value: 25, label: '25 per page' },
                        { value: 50, label: '50 per page' },
                        { value: 75, label: '75 per page' },
                        { value: 100, label: '100 per page' },
                      ]}
                    />
                  </div>
                </div>
                <div className="ms-md-auto mt-3 mt-md-0 d-flex align-items-center">
                  <div className="flex-grow-1">
                    <StylishNewSearchBar
                      onChangeSearchTerm={onChangeSearchTerm}
                    />
                  </div>
                  <StylishHelp
                    classes={'ms-3 flex-shrink-0 sml'}
                    title={HelpInjectMethod.title}
                    content={HelpInjectMethod.content}
                    link={selfHostedKnowledgeBase || HelpInjectMethod.link}
                  />
                </div>
              </div>
            </div>
          </div>
          <StylishNewTable
            keyField={'id'}
            columns={columns}
            rows={injectMethods}
            pagination
            page={page}
            perPage={perPage}
            bordered={false}
            striped={true}
            total={total}
            onPageChange={(page) => setPage(page)}
          />
        </div>
        {showInjectMethodAddEditDialog && (
          <AddEditInjectMethodDialog
            show={showInjectMethodAddEditDialog}
            onClose={OnAddOrEditInjectMethod}
            dialogType={dialogType}
            updateDialogData={updateDialogData}
          />
        )}
        {deleteInjectMethodId && (
          <StylishConfirmDialog
            show={deleteInjectMethodId}
            onClose={() => setDeleteInjectMethodId(null)}
            dialogTitle={'Delete Inject Method'}
            dialogContentType={'html'}
            dialogContent={`
              Deleting <span class="weight-700 break-word">${deleteInjectMethodTitle}</span> will remove all its occurance from this workspace.<span class="d-block pt-3">Are you sure you want to delete this Inject Method ?</span>`}
            onConfirm={onDeleteConfirm}
          />
        )}
      </div>
    </>
  );
}
