import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import Network from '../../../../service/Network';
import API from '../../../../service/api';
import Plot from 'react-plotly.js';
import {
  paperBgColor,
  plotBgColor,
  plotGreen,
  plotOrange,
  toastConfig,
} from 'assets/data/config';
import { toast } from 'react-toastify';
import moment from 'moment/moment';
import FullCalendar from '@fullcalendar/react';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import { HelpPrepareDashboard, useSelfHostedKnowledgeBase } from 'assets/data/HelpContent';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { PreparePath } from 'utils/sharedUtils/sharedConst';
import StylishNewSelect from 'components/DesignSystems/New/StylishNewSelect';
import StylishHelp from 'components/DesignSystems/New/StylishHelp';
import {
  endLoading,
  startLoading,
} from '../../../../../reducers/loading/loading.action';
import PageTitle from 'components/headers/PageTitle';
import { useNewUIFlag } from 'utils/featureFlags';
import { SharedIcon } from 'components/SharedIcon/SharedIcon';

export default function PrepareDashboard() {
  const [board, setBoard] = useState({});
  const [boardsList, setBoardsList] = useState([]);
  const [injectSelected, setInjectSelected] = useState({
    value: 'all',
    label: 'all',
  });
  const [mselInject, setMselInject] = useState([]);
  const [allOwner, setAllOwner] = useState([]);
  const [titleOfGraph, setTitleOfGraph] = useState('');
  const [meetings, setMeetings] = useState([]);
  const [refinedMeetings, setRefinedMeetings] = useState([]);
  const [mselInjectLength, setMselInjectLength] = useState(0);
  const [exerciseDetails, setExcerciseDetails] = useState([]);
  const [totalPlannedvsRequested, setTotalPlannedvsRequested] = useState(0);
  const [plannedvsRequested, setPlannedvsRequested] = useState({});
  const [totalBudget, setTotalBudget] = useState();
  const [budget, setBudget] = useState({});
  const [percentAllocatd, setPercentAllocated] = useState([]);

  const selfHostedKnowledgeBase = useSelfHostedKnowledgeBase()

  const navigate = useNavigate();

  const { control } = useForm();

  const dispatch = useDispatch();

  const newUI = useNewUIFlag();

  const { selectedWorkspace } = useSelector((state) => state.prepare.workspace);
  const workspaceType = useSelector((state) => state.prepare.workspace.type);
  const reduxUser = useSelector((state) => state.prepare.user);
  const exerciseId = useSelector(
    (state) => state.prepare.exercise.exerciseDetailsId
  );
  useEffect(() => {
    if (!!workspaceType && workspaceType === 'T&E') {
      navigate(`${PreparePath}/dashboard/plan`);
    }
  }, [workspaceType]);

  const planStatusBoardList = async () => {
    dispatch(startLoading());
    try {
      const newStatusBoard = await Network.get(API.planStatusBoardList, {
        workspaceId: selectedWorkspace,
      });
      const boardListData = newStatusBoard.data.response.dataset.map((e) => {
        return {
          value: e.boardId,
          label: e.title,
        };
      });
      setBoardsList(boardListData);
      const sBoard = boardListData?.length
        ? boardListData[Math.floor(Math.random() * boardListData.length)]
        : {};
      setBoard(sBoard);
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(endLoading());
    }
  };

  const fetchAllBoardInject = async () => {
    dispatch(startLoading());
    try {
      await Network.get(API.fetchAllBoardInject, {
        workspaceId: selectedWorkspace,
        boardId: board.value,
      });
    } catch (error) {
      toast.error(error?.response?.data?.response?.status?.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };

  const getInjectOwnerList = async () => {
    dispatch(startLoading());
    try {
      const injectOwner = await Network.get(API.getPlanInjectOwnerList, {
        workspaceId: selectedWorkspace,
      });
      setAllOwner([
        { value: 'all', label: 'all' },
        ...injectOwner.data.response.dataset.map((e) => {
          return {
            value: e.id,
            label: e.title,
          };
        }),
      ]);
    } catch (error) {
      toast.error(error?.response?.data?.response?.status?.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };

  const prepareDashboard = async () => {
    dispatch(startLoading());
    try {
      // const resp =

      Network.get(API.prepareDashboard, {
        workspaceId: selectedWorkspace,
        startDate: moment().clone().startOf('month').toISOString(),
        endDate: moment().toISOString(),
        eventId: exerciseId,
        boardId: board.value,
        filter: injectSelected.value,
      })
        .then((resp) => {
          setMselInject(resp.data.response.mselData);
          setMeetings(resp.data.response.calendarData);
          setMselInjectLength(
            resp?.data?.response?.mselData.reduce(
              (len, data) => len + Number(data.count || 0),
              0
            )
          );
          setExcerciseDetails({
            ...resp?.data?.response?.details,
            orgCapTarget: resp?.data?.response?.orgCapTarget || 0,
          });
          setTotalPlannedvsRequested(
            (Number(
              resp?.data?.response?.details?.total_training_objectives_planned
            ) || 0) +
              (Number(
                resp?.data?.response?.details
                  ?.total_training_objectives_requested
              ) || 0)
          );
          setPlannedvsRequested({
            Planned:
              Number(
                resp?.data?.response?.details?.total_training_objectives_planned
              ) || 0,
            RequestedRemaining:
              Number(
                resp?.data?.response?.details
                  ?.total_training_objectives_requested
              ) || 0,
          });

          // if()

          setBudget({
            NonAllocated:
              Number(
                resp?.data?.response?.details?.total_budget -
                  resp?.data?.response?.details?.total_allocated
              ) || null,
            Allocated:
              Number(resp?.data?.response?.details?.total_allocated) || null,
          });
          setTotalBudget(
            Number(resp?.data?.response?.details?.total_budget) || null
          );
          // if (resp.data.response.details.total_allocated === null) {
          // }
          setPercentAllocated(
            // Math.floor(
            Number(
              (resp?.data?.response?.details?.total_allocated /
                resp?.data?.response?.details?.total_budget) *
                100
            ).toFixed(4)
            // )
          );
        })
        .catch(console.log)
        .finally(() => {
          dispatch(endLoading());
        });
    } catch (error) {
      toast.error(error?.response?.data?.response?.status?.msg, toastConfig);
    }
  };

  useEffect(() => {
    planStatusBoardList();
    getInjectOwnerList();
    if (workspaceType === 'DOD') {
      setTitleOfGraph(' Objectives');
    }
    if (workspaceType === 'HSEEP') {
      setTitleOfGraph('Excercise Objectives');
    }
  }, [reduxUser]);

  useEffect(() => {
    if (meetings) {
      const planCalen = meetings.map((e) => {
        const refinedPlanCalendar = {
          title: e.eventName,
          start: moment(e?.startDate).toISOString(),
          end: moment(e?.endDate).toISOString(),
          type: e.type,
          id: e.id,
          color: e?.color,
        };
        return refinedPlanCalendar;
      });
      setRefinedMeetings(planCalen);
    } else {
      setRefinedMeetings([]);
    }
  }, [meetings, reduxUser]);

  useEffect(() => {
    board?.value && fetchAllBoardInject();
    board?.value && prepareDashboard();
  }, [reduxUser, board, injectSelected, exerciseId]);

  const onViewInject = (type, id) => {
    if (type === 'exercise') {
      navigate(`${PreparePath}/dashboard/plan/prepare/exercise-details`, {
        replace: true,
      });
    } else if (type === 'event') {
      navigate(`${PreparePath}/dashboard/plan/prepare/event/view/${id}`, {
        replace: true,
      });
    } else {
      setViewId(id);
      setEditMSELTimeline(true);
    }
  };

  return (
    <>
      {newUI ? (
        <PageTitle
          title="Overview (Dashboard)"
          parents={['Exercise Management', 'Design']}
        >
          <StylishHelp
            classes={'ms-auto sml'}
            title={HelpPrepareDashboard.title}
            content={HelpPrepareDashboard.content}
            link={selfHostedKnowledgeBase || HelpPrepareDashboard.link}
          />
        </PageTitle>
      ) : null}
      <div className="d-flex align-items-start flex-column mb-4">
        {!newUI && (
          <>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb m-0">
                <li className="breadcrumb-item">
                  <StylishNewButton onClick={() => navigate(-1)}>
                    <SharedIcon iconName="keyboard_arrow_left" classes="me-2" />
                    Back
                  </StylishNewButton>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Dashboard
                </li>
              </ol>
            </nav>
            <StylishHelp
              classes={'ms-auto sml'}
              title={HelpPrepareDashboard.title}
              content={HelpPrepareDashboard.content}
              link={selfHostedKnowledgeBase || HelpPrepareDashboard.link}
            />
          </>
        )}
      </div>

      <div className="plan-prepare__dashboard">
        <div className="row">
          <div className="col-xl-4 mb-4 mb-xl-0">
            <div className="section section__exercise-details">
              <div className="d-flex align-items-center mb-4">
                <h4 className="m-0">Exercise Details</h4>
                <Dropdown className="flex-shrink-0 ms-auto">
                  <Dropdown.Toggle className="button--icon">
                    <SharedIcon iconName="more_vert" bold />
                  </Dropdown.Toggle>
                  <Dropdown.Menu align="end">
                    <Dropdown.Item
                      onClick={() => {
                        navigate(
                          `${PreparePath}/dashboard/plan/prepare/all-details`
                        );
                      }}
                    >
                      <SharedIcon iconName="stylus" />
                      Edit Exercise Details
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              {board?.value && exerciseId ? (
                <>
                  {workspaceType === 'DOD' ? (
                    <>
                      <p className="weight-600">
                        <span className="d-block">
                          {exerciseDetails.eventName}-
                          {exerciseDetails.exerciseType}
                        </span>
                        {!!exerciseDetails?.startDate &&
                          moment(exerciseDetails.startDate).format('DD MMM YY')}
                        -{' '}
                        {!!exerciseDetails?.endDate &&
                          moment(exerciseDetails.endDate).format('DD MMM YY')}
                      </p>
                      <span className="d-block">
                        {exerciseDetails.total_exercise_objectives} - Exercise
                        Objectives
                      </span>
                      <span className="d-block">
                        {exerciseDetails.total_participants} - Participants with{' '}
                        {exerciseDetails.total_participant_platforms} -
                        Platforms
                      </span>
                      <span className="d-block">
                        {exerciseDetails.total_training_objectives} - Objectives
                      </span>
                      <span className="d-block">
                        {exerciseDetails.total_events} - Scenarios
                      </span>
                      <span className="d-block">
                        {exerciseDetails.total_injects} - MSEL Injects
                      </span>
                    </>
                  ) : (
                    <>
                      <p className="weight-600">
                        <span className="d-block">
                          {exerciseDetails.eventName}-
                          {exerciseDetails.exerciseType}
                        </span>
                        {!!exerciseDetails?.startDate &&
                          moment(exerciseDetails.startDate).format('DD MMM YY')}
                        -{' '}
                        {!!exerciseDetails?.endDate &&
                          moment(exerciseDetails.endDate).format('DD MMM YY')}
                      </p>
                      <span className="d-block">
                        {exerciseDetails.orgCapTarget} - Organizational
                        Capability Targets
                      </span>
                      <span className="d-block">
                        {exerciseDetails.total_participants} - Participants with{' '}
                        {exerciseDetails.total_participant_platforms} -
                        Platforms
                      </span>
                      <span className="d-block">
                        {exerciseDetails.total_training_objectives} - Exercise
                        Objectives
                      </span>
                      <span className="d-block">
                        {exerciseDetails.total_events} - Scenarios
                      </span>
                      <span className="d-block">
                        {exerciseDetails.total_injects} - MSEL Injects
                      </span>
                    </>
                  )}
                </>
              ) : (
                <>
                  <div className="placeholders">
                    <p>
                      No exercise details found. Start planning <br />
                      by entering them now.
                    </p>
                    <StylishNewButton
                      className="button button--primary"
                      onClick={() => {
                        navigate(
                          `${PreparePath}/dashboard/plan/prepare/all-details`
                        );
                      }}
                    >
                      Enter Details
                    </StylishNewButton>
                  </div>
                </>
              )}
            </div>
            <div className="separator--x my-4"></div>
            <div className="section section__graph">
              <div className="p-3 p-md-4 bg-gray-800">
                <h4 className="mb-1">MSEL Injects</h4>
                <p className="weight-600 mb-3">Total: {mselInjectLength}</p>

                <div className="row mb-2">
                  <div className="col-md-6 mb-2 mb-md-0">
                    <Controller
                      control={control}
                      name="Owner"
                      rules={{ required: true }}
                      render={({ field: { value } }) => (
                        <StylishNewSelect
                          options={allOwner}
                          defaultValue={value}
                          value={value || injectSelected}
                          onChange={(e) => {
                            setInjectSelected(e);
                          }}
                          isClearable={false}
                          isSearchable={true}
                          isMulti={false}
                          isDisabled={false}
                        />
                      )}
                    />
                  </div>
                </div>
                <Plot
                  data={[
                    {
                      x: [...mselInject.map((e) => e.title)],
                      y: [...mselInject.map((e) => e.count)],
                      type: 'bar',
                      marker: {
                        color: [...mselInject.map((e) => e.color)],
                      },
                      text: [...mselInject.map((e) => e.count)].map(String),
                    },
                  ]}
                  layout={{
                    autosize: true,
                    paper_bgcolor: paperBgColor,
                    plot_bgcolor: plotBgColor,
                    font: {
                      color: 'white',
                    },

                    // title: `Msel Injects <br> Total: ${mselInjectLength}`,
                  }}
                  useResizeHandler
                  className="w-100"
                  config={{
                    displayModeBar: true,
                    modeBarButtons: [['toImage']],
                    displaylogo: false,
                  }}
                />
              </div>{' '}
            </div>
          </div>
          <div className="col-xl-4 mb-4 mb-xl-0">
            <div className="section section__graph p-3 p-md-4 bg-gray-800">
              {workspaceType === 'DOD' ? (
                <h4 className="mb-1">Objectives (Requested vs Planned)</h4>
              ) : (
                <h4 className="mb-1">
                  Exercise Objectives (Requested vs Planned)
                </h4>
              )}
              <p className="weight-600 mb-3">
                Total Requested: {totalPlannedvsRequested}
              </p>
              <Plot
                data={[
                  {
                    values:
                      !!plannedvsRequested && Object.values(plannedvsRequested),
                    labels: ['Planned', 'Requested'],
                    type: 'pie',
                    marker: {
                      colors: [plotGreen, plotOrange],
                    },
                    text:
                      !!plannedvsRequested &&
                      Object.values(plannedvsRequested).map(String),
                  },
                ]}
                layout={{
                  autosize: true,
                  paper_bgcolor: paperBgColor,
                  plot_bgcolor: plotBgColor,
                  font: {
                    color: 'white',
                  },

                  // title: `${titleOfGraph} Completion<br>Total Remaining: ${totalPlannedvsRequested}`,
                }}
                onLegendClick={() => false}
                useResizeHandler
                className="w-100"
                config={{
                  displayModeBar: true,
                  modeBarButtons: [['toImage']],
                  displaylogo: false,
                }}
              />
            </div>

            <div className="separator--x my-4"></div>
            <div className="section section__graph p-3 p-md-4 bg-gray-800">
              <h4 className="mb-1">Budget</h4>
              <p className="weight-600 mb-3">
                {totalBudget === null || totalBudget === undefined
                  ? `Total Budget: $ 0`
                  : `Total Budget: $ ${totalBudget?.toLocaleString()}`}
                <br />
                {isNaN(percentAllocatd) ||
                percentAllocatd === undefined ||
                percentAllocatd.length === 0
                  ? `0 % Allocated`
                  : `${percentAllocatd} % Allocated`}
                {/* {titleOfGraph {"Completion"}<br>"Total Budget:" ${totalBudget} <br>{percentAllocatd} % Allocated} */}
              </p>
              <Plot
                data={[
                  {
                    values: !!budget && Object.values(budget),
                    labels: ['Non-Allocated', 'Allocated'],

                    type: 'pie',
                    marker: {
                      colors: [plotGreen, plotOrange],
                    },
                    text: !!budget && Object.values(budget).map(String),
                  },
                ]}
                layout={{
                  autosize: true,
                  paper_bgcolor: paperBgColor,
                  plot_bgcolor: plotBgColor,
                  font: {
                    color: 'white',
                  },

                  // title: `Total Budget: $ ${totalBudget} <br>${percentAllocatd} % Allocated`,
                }}
                onLegendClick={() => false}
                useResizeHandler
                className="w-100"
                config={{
                  displayModeBar: true,
                  modeBarButtons: [['toImage']],
                  displaylogo: false,
                }}
              />
            </div>
          </div>
          <div className="col-xl-4">
            <div className="section section__calendar">
              <div className="d-flex align-items-center mb-4">
                <h4 className="m-0">Calendar</h4>
              </div>
              <div className="calendar calendar-dashboard">
                <FullCalendar
                  validRange={''}
                  plugins={[listPlugin, interactionPlugin]}
                  headerToolbar={{
                    left: 'title',
                    center: '',
                    right: 'prev,next today',
                  }}
                  initialView="listMonth"
                  events={refinedMeetings}
                  eventClick={(info) =>
                    onViewInject(
                      info.event._def?.extendedProps?.type,
                      info.event._def?.publicId
                    )
                  }
                  eventTimeFormat={{
                    hour: '2-digit',
                    minute: '2-digit',
                    meridiem: 'short',
                    hour12:
                      reduxUser?.timeFormat === 'hour12Mode' ? true : false,
                  }}
                  displayEventTime={true}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
