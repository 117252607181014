import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { toast } from 'react-toastify';
import Network from '../../../../../service/Network';
import API from '../../../../../service/api';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { HelpTrainingObjectiveMeasures, useSelfHostedKnowledgeBase } from 'assets/data/HelpContent';
import { textareaStringLength, toastConfig } from 'assets/data/config';
import { inputStringLength } from 'assets/data/config';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { PreparePath } from 'utils/sharedUtils/sharedConst';
import StylishNewTextArea from 'components/DesignSystems/New/StylishNewTextArea';
import StylishNewInput from 'components/DesignSystems/New/StylishNewInput';
import StylishHelp from 'components/DesignSystems/New/StylishHelp';
import { endLoading, startLoading } from "../../../../../../reducers/loading/loading.action";
import { SharedIcon } from 'components/SharedIcon/SharedIcon';

export default function TrainingObjectivePerformance(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const workspaceId = useSelector(
    (state) => state.prepare.workspace.selectedWorkspace
  );
  const reduxUser = useSelector((state) => state.prepare.user);

  const selfHostedKnowledgeBase = useSelfHostedKnowledgeBase()

  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    getValues,
    clearErrors,
    trigger,
  } = useForm();

  const { fields, append, remove } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: 'measure', // unique name for your Field Array
  });

  useEffect(() => {
    fetchTrainingObjectiveDetail();
  }, [reduxUser]);

  const fetchTrainingObjectiveDetail = () => {
    dispatch(startLoading());
    try {
      Network.get(API.updateTrainingObjective, {
        workspaceId: workspaceId,
        id: props.id,
      }).then((resp) => {
        setValue('task', resp.data.response.dataset[0].task);
        setValue('condition', resp.data.response.dataset[0].condition);
        setValue('standard', resp.data.response.dataset[0].standard);
        setValue(
          'measure',
          resp.data.response.dataset[0].measures.length
            ? resp.data.response.dataset[0].measures
            : [
                {
                  title: '',
                  workspaceId: workspaceId,
                  trainingObjectiveId: props.id,
                },
              ]
        );
      });
    } catch (error) {
      toast.error(error?.response?.data?.response.status.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };

  const onSubmit = (data) => {
    let measureData = data.measure.map((e) => ({
      // id: e.id,
      title: e.title,
      workspaceId: workspaceId,
      trainingObjectiveId: props.id,
    }));
    dispatch(startLoading());
    Network.post(API.updateTrainingObjective, {
      workspaceId: workspaceId,
      condition: data.condition,
      participantId: props?.participantId,
      id: props?.id,
      measure: measureData,
      standard: data.standard,
      task: data.task,
    })
      .then((resp) => {
        props.onNextClick(2);
        toast.success('Saving', toastConfig);
      })
      .catch((err) => {
        toast.error(err.response.data.response.status.msg, toastConfig);
      })
      .finally(() => {
        dispatch(endLoading());
      });
  };

  const saveAndExit = () => {
    const data = getValues();
    dispatch(startLoading());
    Network.post(API.updateTrainingObjective, {
      workspaceId: workspaceId,
      condition: data.condition,
      participantId: props?.participantId,
      id: props?.id,
      measure: data.measure,
      standard: data.standard,
      task: data.task,
    })
      .then((resp) => {
        navigate(`${PreparePath}/dashboard/plan/prepare/training-objective`);
        toast.success('Saving', toastConfig);
      })
      .catch((err) => {
        toast.error(err.response.data.response.status.msg, toastConfig);
      })
      .finally(() => {
        dispatch(endLoading());
      });
  };
  return (
    <>
      <div className="d-flex align-items-center mb-4">
        <h4 className="m-0">{props?.participantName} Objective Performance</h4>
        <StylishHelp
          classes={'ms-auto sml flex-shrink-0'}
          title={HelpTrainingObjectiveMeasures.title}
          content={HelpTrainingObjectiveMeasures.content}
          link={selfHostedKnowledgeBase || HelpTrainingObjectiveMeasures.link}
        />
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-block">
          <div className="row">
            <div className="col-md-12 mb-3">
              <label className="form-label d-block m-0">Task</label>
              <span className="form-text d-block mb-2 pb-1">
                The Task (Performance Objective) defines what needs to be
                accomplished to complete the objective.
              </span>
              <Controller
                control={control}
                name={'task'}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <StylishNewTextArea
                    onChange={(e) => {
                      if (e) {
                        clearErrors('task');
                      }
                      onChange(e);
                    }}
                    value={value}
                    type="text"
                    name="task"
                    maxLength={textareaStringLength}
                    className="form-control"
                    placeholder="Performance Objective"
                  />
                )}
              />
              {errors.task && (
                <span className="form-text form-error">
                  This is a required field
                </span>
              )}
            </div>
            <div className="col-md-12 mb-3">
              <label className="form-label d-block m-0">Condition</label>
              <span className="form-text d-block mb-2 pb-1">
                The Condition (Training Situation) directly affects the ability
                to perform a task and challenges the training audience when
                completing a JMET.
              </span>
              <Controller
                control={control}
                name={'condition'}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <StylishNewTextArea
                    onChange={(e) => {
                      if (e) {
                        clearErrors('condition');
                      }
                      onChange(e);
                    }}
                    value={value}
                    type="text"
                    name="condition"
                    maxLength={textareaStringLength}
                    className="form-control"
                    placeholder="Training Situation"
                  />
                )}
              />
              {errors.condition && (
                <span className="form-text form-error">
                  This is a required field
                </span>
              )}
            </div>
            <div className="col-md-12 mb-3">
              <label className="form-label d-block m-0">Standard</label>
              <span className="form-text d-block mb-2 pb-1">
                The Standard (Level of Performance) should describe the overall
                effectiveness goal of the objective, based on the
                measurement(s).{' '}
              </span>
              <Controller
                control={control}
                name={'standard'}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <StylishNewTextArea
                    onChange={(e) => {
                      if (e) {
                        clearErrors('standard');
                      }
                      onChange(e);
                    }}
                    value={value}
                    type="text"
                    name="standard"
                    maxLength={textareaStringLength}
                    className="form-control"
                    placeholder="Level of Performance"
                  />
                )}
              />
              {errors.standard && (
                <span className="form-text form-error">
                  This is a required field
                </span>
              )}
            </div>
            <div className="col-md-12 mt-3">
              <label className="form-label d-block mb-3">Measure</label>
              <hr className="m-0" />
              {/* This row will be a repeater */}
              {fields.map((field, index) => (
                <div className="pt-3 pb-4" key={field.id}>
                  <label className="form-label d-block">M{index + 1}</label>
                  <Controller
                    control={control}
                    name={`measure.${index}.title`}
                    rules={{
                      required: false,
                    }}
                    render={({ field: { onChange, value } }) => (
                      <StylishNewInput
                        onChange={(e) => {
                          onChange(e);
                        }}
                        value={value}
                        type="text"
                        name={`measure.${index}.title`}
                        maxLength={inputStringLength}
                        className="form-control"
                        placeholder="Title"
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name={`measure.${index}.trainingObjectiveId`}
                    rules={{
                      required: false,
                    }}
                    render={({ field: { onChange, value } }) => (
                      <StylishNewInput
                        onChange={(e) => {
                          onChange(e);
                        }}
                        value={value}
                        type="text"
                        name={`measure.${index}.trainingObjectiveId`}
                        maxLength={inputStringLength}
                        className="form-control"
                        placeholder="Title"
                        hidden={true}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name={`measure.${index}.workspaceId`}
                    rules={{
                      required: false,
                    }}
                    render={({ field: { onChange, value } }) => (
                      <StylishNewInput
                        onChange={(e) => {
                          onChange(e);
                        }}
                        value={value}
                        type="text"
                        name={`measure.${index}.workspaceId`}
                        maxLength={inputStringLength}
                        className="form-control"
                        placeholder="Title"
                        hidden={true}
                      />
                    )}
                  />
                  <div className="button-group mt-3">
                    <StylishNewButton
                      type="button"
                      className="button--icon primary"
                      onClick={() =>
                        append({
                          id: field.id,
                          title: '',
                          // workspaceId: workspaceId,
                          // trainingObjectiveId: props.id,
                        })
                      }
                    >
                      <SharedIcon iconName="add" bold />
                    </StylishNewButton>
                    {fields.length > 1 ? (
                      <StylishNewButton
                        type="button"
                        className="button--icon primary ms-2"
                        onClick={() => remove(index)}
                      >
                        <SharedIcon iconName="delete" />
                      </StylishNewButton>
                    ) : null}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="col-md-12 mt-4">
          <div className="button-group d-block d-md-flex">
            <StylishNewButton
              type="submit"
              className="button button--primary button--reverse w-100 w-md-auto"
              onClick={() => props.onPrevClick(0)}
            >
              Previous
            </StylishNewButton>
            <StylishNewButton
              type="button"
              className="button button--tertiary button--reverse ms-auto mt-3 mt-md-0 w-100 w-md-auto"
              onClick={async () => {
                const valid = await trigger();

                if (valid) {
                  saveAndExit();
                }
              }}
            >
              Save &amp; Exit
            </StylishNewButton>
            <StylishNewButton
              type="submit"
              className="button button--primary  w-100 w-md-auto mt-3 mt-md-0 ms-0 ms-md-3"
            >
              Next
            </StylishNewButton>
          </div>
        </div>
      </form>
    </>
  );
}
