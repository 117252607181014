import StylishNewInput from 'components/DesignSystems/New/StylishNewInput';
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { PreparePath } from 'utils/sharedUtils/sharedConst';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { toastConfig } from 'assets/data/config';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';

export default function ShareProfileDialog({
  onClose,
  show,
  userName,
  userSite,
  id,
}) {
  const { workspaceName } = useSelector((state) => state.prepare.workspace);

  const location = useLocation();
  const link = window.location.href.replace(location.pathname, '');

  return (
    <Modal
      show={show}
      onHide={onClose}
      centered
      backdrop="static"
      keyboard={false}
      // size="md"
    >
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title>Share Profile</Modal.Title>
      </Modal.Header>
      <form>
        <Modal.Body>
          <div className="mb-0">
            <label className="form-label">Name</label>

            <StylishNewInput
              value={`${link}${PreparePath}/dashboard/media/profiles/${userSite?.toLowerCase()}/${userName?.toLowerCase()}/${id}`}
              type="text"
              name=""
              className="form-control"
              placeholder=""
              readOnly="true"
            />
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div className="button-group">
            {/* <button
                className="button button--secondary button--reverse"
                onClick={onClose}
              >
                Cancel
              </button> */}

            <StylishNewButton
              className="button--primary"
              onClick={(e) => {
                e.preventDefault();
                navigator.clipboard.writeText(
                  `${link}${PreparePath}/dashboard/media/profiles/${userSite}/${userName}/${id}`
                );
                toast.success('Link Copied', toastConfig);
              }}
            >
              Copy To Clipboard
            </StylishNewButton>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
