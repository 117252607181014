import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { PreparePath } from 'utils/sharedUtils/sharedConst';
import { useSelector } from 'react-redux';

export const ViewAssessmentDOD = ({ data }) => {
  const navigate = useNavigate();
  const reduxUser = useSelector((state) => state.prepare.user);

  const pdfHeaders = [
    {
      identifier: 'initials',
      data: [
        {
          date: 'Date',
          participant_plaform: 'Participant Plaform',
          objective: 'Objective',
          requested_method: 'Requested Method',
          method: 'Method',
          associated_inject: 'Associated Inject',
        },
      ],
    },
    {
      identifier: 'tasks',
      data: [
        {
          task: 'Task',
          condition: 'Condition',
          standard: 'Standard',
          injects: 'Injects',
        },
      ],
    },
    {
      identifier: 'trainingObjectives',
      data: [
        {
          objective_completed: 'Objective Completed?',
          status: 'Status',
          reason: 'Reason',
          comment: 'Comment',
        },
      ],
    },
    {
      identifier: 'metrics',
      data: [
        {
          metric: 'Metric',
          number_completed: 'Number Completed',
        },
      ],
    },
    {
      identifier: 'leasonLearned',
      data: [
        {
          potential_opr: 'Potential OPR',
          classification: 'Classification',
          title: 'Title',
          observation: 'Observation',
          discussion: 'Discussion',
          recommendation: 'Recommendation',
          implications: 'Implications',
          event_description: 'Event Description',
          comments: 'Comments',
        },
      ],
    },
  ];

  const generatePDF = () => {
    jsPDF.autoTableSetDefaults({
      headStyles: { fillColor: '#0099FF' },
    });

    const injectsData = data?.injects
      .map((i) => {
        return `#${i?.number} - ${i?.title} - ${moment(i?.responseDate).format(
          'DD MMM yy - hh:mm'
        )}`;
      })
      .join('\n\u2022 ');

    var doc = new jsPDF({ orientation: 'landscape' });

    doc.text('Assessment Details', 7, 15);

    doc.autoTable({
      startY: 25,
      head: pdfHeaders.find((i) => i.identifier === 'initials').data,
      body: [
        {
          date: moment(data?.createdAt).isValid()
            ? `${moment(data?.createdAt).format('Do MMM YYYY - HH:MM')} (
                ${moment(data?.createdAt).tz(moment.tz.guess()).format('Z')})`
            : 'N/A',
          participant_plaform:
            data?.participantName || data?.platformTitle || data?.platformType
              ? `${data?.participantName || ''} ${
                  data?.platformTitle ? `- ${data?.platformTitle}` : ''
                } ${data?.platformType ? `: ${data?.platformType}` : ''}`
              : 'N/A',
          objective:
            data?.levelWar || data?.taskNumber || data?.taskTitle
              ? `${data?.levelWar} ${data?.taskNumber} ${data?.taskTitle}`
              : 'N/A',
          requested_method: data?.requestedMethod || 'N/A',
          method: data?.trainedMethodType || 'N/A',
          associated_inject: data?.associatedInjectCount || 'N/A',
        },
      ],
      showHead: 'firstPage',
      margin: { horizontal: 7 },
      pageBreak: 'avoid',
    });

    doc.autoTable({
      startY: doc.autoTable.previous.finalY + 10,
      head: pdfHeaders.find((i) => i.identifier === 'tasks').data,
      body: [
        {
          task: data?.task || 'N/A',
          condition: data?.conditio || 'N/A',
          standard: data?.standard || 'N/A',
          injects: injectsData ? `\u2022 ${injectsData}` : 'N/A',
        },
      ],
      showHead: 'firstPage',
      margin: { horizontal: 7 },
      pageBreak: 'avoid',
    });

    doc.autoTable({
      startY: doc.autoTable.previous.finalY + 10,
      head: pdfHeaders.find((i) => i.identifier === 'trainingObjectives').data,
      body: [
        {
          objective_completed: data?.complete ? 'Yes' : 'No',
          status: data?.status || 'N/A',
          reason: data?.reason || 'N/A',
          comment: data?.comment || 'N/A',
        },
      ],
      showHead: 'firstPage',
      margin: { horizontal: 7 },
      pageBreak: 'avoid',
    });

    doc.autoTable({
      startY: doc.autoTable.previous.finalY + 10,
      head: pdfHeaders.find((i) => i.identifier === 'metrics').data,
      body:
        (data?.metrics &&
          !!data?.metrics?.length &&
          data?.metrics.map((i) => ({
            metric: i?.metricsTitle || 'N/A',
            number_completed: i?.assessmentData || 'N/A',
          }))) ||
        pdfHeaders
          .find((i) => i.identifier === 'metrics')
          .data.map((i) => ({
            metric: 'N/A',
            number_completed: 'N/A',
          })),
      showHead: 'firstPage',
      margin: { horizontal: 7 },
      pageBreak: 'avoid',
    });

    doc.autoTable({
      startY: doc.autoTable.previous.finalY + 10,
      head: pdfHeaders.find((i) => i.identifier === 'leasonLearned').data,
      body:
        (data?.lessionlearned &&
          !!data?.lessionlearned?.length &&
          data?.lessionlearned.map((i) => ({
            potential_opr: i?.potentialOPR || 'N/A',
            classification: i?.classification || 'N/A',
            title: i?.title || 'N/A',
            observation: i?.observation || 'N/A',
            discussion: i?.discussion || 'N/A',
            recommendation: i?.recommendation || 'N/A',
            implications: i?.implications || 'N/A',
            event_description: i?.eventDescription || 'N/A',
            comments: i?.comments || 'N/A',
          }))) ||
        pdfHeaders
          .find((i) => i.identifier === 'leasonLearned')
          .data.map((i) => ({
            potential_opr: 'N/A',
            classification: 'N/A',
            title: 'N/A',
            observation: 'N/A',
            discussion: 'N/A',
            recommendation: 'N/A',
            implications: 'N/A',
            event_description: 'N/A',
            comments: 'N/A',
          })),
      showHead: 'firstPage',
      margin: { horizontal: 7 },
      pageBreak: 'avoid',
    });

    doc.save(
      `Assessment-DOD-${moment().format(
        reduxUser?.timeFormat === 'hour12Mode'
          ? 'YYYY_MM_DD_hh_mm a'
          : 'YYYY_MM_DD_HH_mm'
      )}.pdf`
    );
  };

  return (
    <>
      <div className="form-block">
        <div className="row">
          <div className="col-md-6 mb-4">
            <label className="form-label">Date dod</label>
            <p className="m-0">
              {data?.createdAt ? (
                <>
                  {moment(data?.createdAt).format('Do MMM YYYY - HH:MM')} (
                  {moment(data?.createdAt).tz(moment.tz.guess()).format('Z')})
                </>
              ) : (
                '-'
              )}
            </p>
          </div>
          <div className="col-md-6 mb-4">
            <label className="form-label">Participant Plaform</label>
            <p className="m-0">
              {data?.participantName ||
              data?.platformTitle ||
              data?.platformType ? (
                <>
                  {data?.participantName ? (
                    <span>{data?.participantName}</span>
                  ) : null}
                  {data?.platformTitle ? (
                    <span> - {data?.platformTitle}</span>
                  ) : null}
                  {data?.platformType ? (
                    <span> : {data?.platformType}</span>
                  ) : null}
                </>
              ) : (
                '-'
              )}
            </p>
          </div>
          <div className="col-md-6 mb-4">
            <label className="form-label">Objective</label>
            <p className="m-0">
              {data?.levelWar || data?.taskNumber || data?.taskTitle ? (
                <>
                  {data?.levelWar ? <span>{data?.levelWar} </span> : null}
                  {data?.taskNumber ? <span>{data?.taskNumber} </span> : null}
                  {data?.taskTitle ? <span>{data?.taskTitle}</span> : null}
                </>
              ) : (
                '-'
              )}
            </p>
          </div>
          <div className="col-md-6 mb-4">
            <label className="form-label">Requested Method</label>
            <p className="m-0">
              {data?.requestedMethod ? <>{data?.requestedMethod}</> : '-'}
            </p>
          </div>
          <div className="col-md-6 mb-4 mb-md-0">
            <label className="form-label">Method</label>
            <p className="m-0">
              {data?.trainedMethodType ? <>{data?.trainedMethodType}</> : '-'}
            </p>
          </div>
          <div className="col-md-6">
            <label className="form-label">Associated Inject</label>
            <p className="m-0">
              {data?.associatedInjectCount ? (
                <>{data?.associatedInjectCount}</>
              ) : (
                '-'
              )}
            </p>
          </div>
        </div>
        <hr className="dashed" />
        <div className="row">
          <div className="col-md-6 mb-4">
            <label className="form-label">Task</label>
            <p className="m-0">{data?.task ? <>{data?.task}</> : '-'}</p>
          </div>
          <div className="col-md-6 mb-4">
            <label className="form-label">Condition</label>
            <p className="m-0">
              {data?.condition ? <>{data?.condition}</> : '-'}
            </p>
          </div>
          <div className="col-md-6 mb-4 mb-md-0">
            <label className="form-label">Standard</label>
            <p className="m-0">
              {data?.standard ? <>{data?.standard}</> : '-'}
            </p>
          </div>

          <div className="col-md-6">
            <label className="form-label">Injects</label>
            <p className="m-0">
              {data?.injects && !!data?.injects?.length ? (
                <>
                  {data?.injects?.map((inject, idx) => (
                    <span className="d-block" key={idx}>
                      #{inject?.number} - {inject?.title} -{' '}
                      {moment(inject?.responseDate).format('DD MMM yy - hh:mm')}
                    </span>
                  ))}
                </>
              ) : (
                '-'
              )}
            </p>
          </div>
        </div>
        <hr className="dashed" />
        <div className="row">
          <div className="col-md-12 mb-4">
            <label className="form-label">
              Was this training objective completed?
            </label>
            <p className="m-0">{data?.complete ? 'Yes' : 'No'}</p>
          </div>
          {data?.complete ? (
            <div className="col-md-12 mb-4">
              <label className="form-label">
                To which level of effectiveness was this objective completed?
              </label>
              <p className="m-0">{data?.status ? <>{data?.status}</> : '-'}</p>
            </div>
          ) : null}
          <div className="col-md-12 mb-4">
            <label className="form-label">
              Why was the objective not effective?
            </label>
            <p className="m-0">{data?.reason ? <>{data?.reason}</> : '-'}</p>
          </div>
          <div className="col-md-12">
            <label className="form-label">Comment</label>
            <p className="m-0">{data?.comment ? <>{data?.comment}</> : '-'}</p>
          </div>
        </div>
      </div>
      <div className="form-block mt-4">
        <h4>Metrics</h4>
        {data?.metrics && !!data?.metrics?.length ? (
          <>
            {data.metrics.map((i, idx) => (
              <div className="row" key={idx}>
                {idx !== 0 ? (
                  <div className="col-md-12">
                    <hr className="dashed" />
                  </div>
                ) : null}
                <div className="col-md-6">
                  <label className="form-label">Metric</label>
                  <p className="m-0">
                    {i?.metricsTitle ? <>{i?.metricsTitle}</> : '-'}
                  </p>
                </div>
                <div className="col-md-6">
                  <label className="form-label">Number Completed</label>
                  <p className="m-0">
                    {i?.assessmentData ? <>{i?.assessmentData}</> : '-'}
                  </p>
                </div>
              </div>
            ))}
          </>
        ) : (
          <p className="m-0">No Metrics Added</p>
        )}
      </div>
      <div className="form-block mt-4">
        <h4>Lessons Learned</h4>
        {data?.lessionlearned && !!data?.lessionlearned?.length ? (
          <>
            {data.lessionlearned.map((i, idx) => (
              <div className="row" key={idx}>
                {idx !== 0 ? (
                  <div className="col-md-12">
                    <hr className="dashed" />
                  </div>
                ) : null}
                <div className="col-md-6 mb-4">
                  <label className="form-label">Potential OPR</label>
                  <p className="m-0">
                    {i?.potentialOPR ? <>{i?.potentialOPR}</> : '-'}
                  </p>
                </div>
                <div className="col-md-6 mb-4">
                  <label className="form-label">Classification</label>
                  <p className="m-0">
                    {i?.classification ? <>{i?.classification}</> : '-'}
                  </p>
                </div>
                <div className="col-md-12 mb-4">
                  <label className="form-label">Title</label>
                  <p className="m-0">{i?.title ? <>{i?.title}</> : '-'}</p>
                </div>
                <div className="col-md-6 mb-4">
                  <label className="form-label">Observation</label>
                  <p className="m-0">
                    {i?.observation ? <>{i?.observation}</> : '-'}
                  </p>
                </div>
                <div className="col-md-6 mb-4">
                  <label className="form-label">Discussion</label>
                  <p className="m-0">
                    {i?.discussion ? <>{i?.discussion}</> : '-'}
                  </p>
                </div>
                <div className="col-md-6 mb-4">
                  <label className="form-label">Recommendation</label>
                  <p className="m-0">
                    {i?.recommendation ? <>{i?.recommendation}</> : '-'}
                  </p>
                </div>
                <div className="col-md-6 mb-4">
                  <label className="form-label">Implications</label>
                  <p className="m-0">
                    {i?.implications ? <>{i?.implications}</> : '-'}
                  </p>
                </div>
                <div className="col-md-6 mb-4 mb-md-0">
                  <label className="form-label">Event Description</label>
                  <p className="m-0">
                    {i?.eventDescription ? <>{i?.eventDescription}</> : '-'}
                  </p>
                </div>
                <div className="col-md-6">
                  <label className="form-label">Comments</label>
                  <p className="m-0">
                    {i?.comments ? <>{i?.comments}</> : '-'}
                  </p>
                </div>
              </div>
            ))}
          </>
        ) : (
          <p className="m-0">No Lessons Learned Added</p>
        )}
      </div>

      <div className="button-group mt-4">
        <StylishNewButton
          className={'button--secondary button--reverse ms-auto'}
          onClick={() =>
            navigate(`${PreparePath}/dashboard/plan/assessment/assess-list`)
          }
        >
          Back
        </StylishNewButton>
        <StylishNewButton className={'button--primary'} onClick={generatePDF}>
          Download PDF
        </StylishNewButton>
      </div>
    </>
  );
};
