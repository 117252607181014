import LogoOrganizationConfiguration from 'assets/images/logo__organization-configuration.svg';
import LogoIncidentConfiguration from 'assets/images/logo__incident-configuration.svg';
import LogoNotification from 'assets/images/logo__notification.svg';
import LogoSituationIntelligence from 'assets/images/logo__situation-intelligence.svg';
import LogoIncidentActionPlanning from 'assets/images/logo__incident-action-planning.svg';
import LogoReport from 'assets/images/logo__report.svg';
import LogoPlan from 'assets/images/logo__plan.svg';
import LogoNotebook from 'assets/images/logo__notebook.svg';
import LogoMedia from 'assets/images/logo__media.svg';
import LogoTaskManagement from 'assets/images/logo__task_management.svg';
import LogoSettings from 'assets/images/logo__settings.svg';

export const AppList = [
  {
    id: 1,
    className: 'logo--organization-configuration',
    logo: LogoOrganizationConfiguration,
    title: 'Organization',
    intro: 'Manage users, workspace settings, data, and more.',
    link: '/groups',
  },
  {
    id: 2,
    className: 'logo--incident-configuration',
    logo: LogoIncidentConfiguration,
    title: 'Incident Workspace',
    intro: 'Configure incident roles and permissions.',
    link: '/incident_configuration',
  },
  {
    id: 3,
    className: 'logo--notification',
    logo: LogoNotification,
    title: 'Notifications',
    intro: 'Mass notifications and alerting tools.',
    link: '/notifications',
    featureFlagRestricted: ['STREAMLINE_PLATFORM'],
  },
  {
    id: 4.5,
    className: 'logo--situation-intelligence',
    logo: LogoSituationIntelligence,
    title: 'Map',
    intro:
      'Geospatial analytics tools for situational awareness and decision support.',
    link: '/map',
  },
  {
    id: 5,
    className: 'logo--incident-action-planning',
    logo: LogoIncidentActionPlanning,
    title: 'Incident Management',
    intro: 'Create and manage incident response plans and resources.',
    link: '/incident_management',
  },
  {
    id: 6,
    className: 'logo--report',
    logo: LogoReport,
    title: 'Report',
    intro: 'Initial Incident Report form.',
    link: '/report',
  },
  {
    id: 11,
    className: 'logo--osint',
    logo: LogoIncidentConfiguration,
    title: 'OSINT',
    intro:
      'Perform search queries to analyze publicly available information from social media platforms.',
    link: '/osint',
    featureFlagRestricted: ['STREAMLINE_PLATFORM'],
  },
  {
    id: 13,
    className: 'logo--plan',
    logo: LogoPlan,
    title: 'Plan',
    intro: 'Exercise Design, Management, Assessment and Analytics.',
    link: '/prepare/dashboard/plan',
  },
  {
    id: 15,
    className: 'logo--settings',
    logo: LogoSettings,
    title: 'Exercise Settings',
    intro: 'Create and manage workspaces. Invite and manage users.',
    link: '/prepare/dashboard/settings/members',
  },
  {
    id: 16,
    className: 'logo--notebook',
    logo: LogoNotebook,
    title: 'Notebook',
    intro: 'Knowledge sharing in a collaborative environment.',
    link: '/prepare/dashboard/notebook/notebooks',
    featureFlagRestricted: ['STREAMLINE_PLATFORM'],
  },
  {
    id: 17,
    className: 'logo--media',
    logo: LogoMedia,
    title: 'Media',
    intro: 'Replicate an online media environment.',
    link: '/prepare/dashboard/media',
    featureFlag: [],
    featureFlagRestricted: ['STREAMLINE_PLATFORM'],
  },
  {
    id: 18,
    className: 'logo--task-management',
    logo: LogoTaskManagement,
    title: 'Task Management',
    intro: 'Manage all exercises and identify trends.',
    link: '/task_management',
    featureFlag: [],
    featureFlagRestricted: ['STREAMLINE_PLATFORM'],
  },
];
