import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import IconBack from 'assets/images/socials/icon__back.svg';
import Logo from 'assets/images/socials/youtube__logo.svg';
import Placeholder from 'assets/images/socials/youtube__icon__placeholder.svg';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import IconSubscribe from 'assets/images/socials/youtube__icon__subscribe.svg';
import IconArrowDown from 'assets/images/socials/youtube__icon__arrow--down.svg';
import IconLike from 'assets/images/socials/youtube__icon__like.svg';
import IconDislike from 'assets/images/socials/youtube__icon__dislike.svg';
import IconShare from 'assets/images/socials/youtube__icon__share.svg';
import IconDownload from 'assets/images/socials/youtube__icon__download.svg';
import IconDots from 'assets/images/socials/youtube__icon__dotsX.svg';
import IconSearch from 'assets/images/socials/youtube__icon__search.svg';
import { contentBlockLength } from 'assets/data/config';
import moment from 'moment';
import IconEmptyExercise from 'assets/images/icon__exercise-details-empty.svg';
import { useSelector } from 'react-redux';
import { PreparePath } from 'utils/sharedUtils/sharedConst';
import { sanitizeHtml } from '../../../../utils/sanitizeHtml';

export const PostYouTube = ({ data }) => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };

  const { workspaceName } = useSelector((state) => {
    return state?.prepare?.workspace;
  });

  const params = useParams();

  const [sidebarData, setSidebarData] = useState([]);
  useEffect(() => {
    sideBarData();
  }, []);

  const sideBarData = () => {
    const data = [];
    for (let index = 0; index < 6; index++) {
      data.push(`
          <div class="placeholder sml flex-shrink-0">
            <img src='${Placeholder}' alt="" />
            <span>No Video Found</span>
          </div>
          <div class="ms-3 flex-grow-1">
            <span class="mask"></span>
            <span class="mask" style='width: 75%'></span>
            <span class="mask" style='width: 50%'></span>
          </div>
       `);
    }
    setSidebarData(data);
  };

  const truncatedTopPost =
    data?.post?.length > contentBlockLength
      ? `${data?.post?.slice(0, contentBlockLength)}....`
      : data?.post;
  const cleanTopPost = sanitizeHtml(truncatedTopPost);

  return (
    <>
      <div className="youtube">
        <div className="youtube__header">
          <StylishNewButton
            customButton
            className={'button--icon'}
            onClick={handleBack}
          >
            <img src={IconBack} alt="" />
          </StylishNewButton>
          <span className="logo">
            <img src={Logo} alt="" className="" />
          </span>
          <div className="search">
            <img className="icon" src={IconSearch} alt="" />
            <input type="search" placeholder="Search..." />
          </div>
          <Link>Sign In</Link>
        </div>
        <div className="youtube__main">
          <div className="row">
            <div className="col-lg-8 col-xl-9">
              <div className="placeholder flex-shrink-0 mb-4">
                <img src={Placeholder} alt="" />
                <span>No Video Found</span>
              </div>
              {sessionStorage.getItem('mediaModuleSocialMediaType') ===
              'sites' ? (
                <>
                  <div className="d-flex align-items-start">
                    <div className="avatar flex-shrink-0 d-none d-md-flex">
                      {data?.profileAvatar ? (
                        <img src={data?.profileAvatar} alt="" />
                      ) : null}
                    </div>
                    <div className="ms-md-3 flex-grow-1">
                      <div className="d-xl-flex align-items-start mb-3">
                        <div className="mb-3 mb-xl-0 d-flex align-items-center">
                          <div className="avatar flex-shrink-0 d-md-none">
                            {data?.profileAvatar ? (
                              <img src={data?.profileAvatar} alt="" />
                            ) : null}
                          </div>
                          <div className="ms-3 ms-md-0">
                            {data?.profileName ? (
                              <h6 className="m-0">{data?.profileName}</h6>
                            ) : null}
                            {data?.postPublishTime ? (
                              <p className="m-0">
                                Published on{' '}
                                {moment(data?.postPublishTime).format(
                                  'Do MMM, YYYY'
                                )}
                              </p>
                            ) : null}
                          </div>
                        </div>
                        <div className="ms-auto d-flex align-items-center flex-wrap flex-md-nowrap">
                          <div className="youtube__button me-2 mt-2 mt-md-0">
                            <img src={IconSubscribe} alt="" />
                            <span className="mx-2">Subscribed</span>
                            <img
                              src={IconArrowDown}
                              alt=""
                              style={{ height: '.375rem' }}
                            />
                          </div>
                          <div className="youtube__button me-2 mt-2 mt-md-0">
                            <img src={IconLike} alt="" />
                            <span className="mx-2">{data?.likes || '0'}</span>
                            <span
                              style={{
                                width: '1px',
                                background: '#CFD2D3',
                                height: '20px',
                              }}
                              className="me-2"
                            ></span>
                            <img src={IconDislike} alt="" />
                          </div>
                          <div className="youtube__button me-2 mt-2 mt-md-0">
                            <img src={IconShare} alt="" />
                            <span className="ms-2">Share</span>
                          </div>
                          <div className="youtube__button me-2 mt-2 mt-md-0">
                            <img src={IconDownload} alt="" />
                            <span className="ms-2">Download</span>
                          </div>
                          <div
                            className="youtube__button mt-2 mt-md-0"
                            style={{
                              height: '34px',
                              width: '34px',
                              borderRadius: '50%',
                              padding: '0',
                              justifyContent: 'center',
                            }}
                          >
                            <img
                              src={IconDots}
                              alt=""
                              style={{ height: '3px', width: 'auto' }}
                            />
                          </div>
                        </div>
                      </div>
                      <p
                        className="mb-0 mt-3"
                        dangerouslySetInnerHTML={{
                          __html: cleanTopPost,
                        }}
                      ></p>
                      {data?.mediaFile ? (
                        <div className="d-flex mt-3 mb-5">
                          <img
                            src={data?.mediaFile}
                            alt=""
                            style={{ width: '200px', height: 'auto' }}
                          />
                        </div>
                      ) : null}
                      {data?.comments && !!data?.comments.length ? (
                        <>
                          {data?.comments.map((i, idx) => {
                            const truncatedComment =
                              i?.comment?.length > contentBlockLength
                                ? `${i?.comment?.slice(
                                    0,
                                    contentBlockLength
                                  )}....`
                                : i?.comment;
                            const cleanComment = sanitizeHtml(truncatedComment);
                            return i?.status === 'drafted' ? null : (
                              <div key={i?.id} className="mt-4">
                                {idx !== 0 ? (
                                  <hr className="dashed thin my-4" />
                                ) : null}
                                <div className="d-flex align-items-start">
                                  <div className="avatar flex-shrink-0">
                                    {i?.profileAvatar ? (
                                      <img src={i?.profileAvatar} alt="" />
                                    ) : null}
                                  </div>
                                  <div className="ms-3">
                                    {i?.profileName ? (
                                      <h6 className="mb-2">{i?.profileName}</h6>
                                    ) : null}
                                    {i?.comment ? (
                                      <p
                                        className="m-0"
                                        dangerouslySetInnerHTML={{
                                          __html: cleanComment,
                                        }}
                                      ></p>
                                    ) : null}
                                    {i?.commentImg ? (
                                      <div className="d-flex mt-3">
                                        <img
                                          src={i?.commentImg}
                                          alt=""
                                          style={{
                                            width: '100px',
                                            height: 'auto',
                                          }}
                                        />
                                      </div>
                                    ) : null}
                                    <p className="m-0 mt-3 weight-500 d-flex align-items-md-center flex-column flex-md-row txt--sml">
                                      {i?.publishTime ? (
                                        <>{moment(i?.publishTime).fromNow()}</>
                                      ) : null}
                                      <span className="mt-2 mt-md-0 ms-md-4">
                                        {i?.likes || '0'} Likes
                                      </span>
                                      <span className="mt-2 mt-md-0 ms-md-4">
                                        Reply
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </>
                      ) : null}
                    </div>
                  </div>
                </>
              ) : null}
              {sessionStorage.getItem('mediaModuleSocialMediaType') ===
              'profiles' ? (
                <>
                  {!params?.postId ? (
                    <>
                      {data?.profiledata?.posts &&
                      !!data?.profiledata?.posts?.length ? (
                        <>
                          {data?.profiledata?.posts.map((i, idx) => {
                            const truncatedPost =
                              i?.post?.length > contentBlockLength
                                ? `${i?.post?.slice(0, contentBlockLength)}....`
                                : i?.post;
                            const cleanPost = sanitizeHtml(truncatedPost);
                            return (
                              <div key={i.id}>
                                {idx !== 0 ? (
                                  <hr className="dashed my-5" />
                                ) : null}
                                <div className="position-relative">
                                  <Link
                                    className="stretched-link"
                                    to={`${PreparePath}/dashboard/media/profiles/${
                                      workspaceName &&
                                      workspaceName.charAt(0).toUpperCase()
                                    }/${data?.sitedata?.slug}/${
                                      data?.profiledata?.username
                                    }/${i?.id}`}
                                  ></Link>
                                  <div className="d-flex align-items-start">
                                    <div className="avatar flex-shrink-0 d-none d-md-flex">
                                      {data?.profiledata?.avatar ? (
                                        <img
                                          src={data?.profiledata?.avatar}
                                          alt=""
                                        />
                                      ) : null}
                                    </div>
                                    <div className="ms-md-3 flex-grow-1">
                                      <div className="d-xl-flex align-items-start mb-3">
                                        <div className="mb-3 mb-xl-0 d-flex align-items-center">
                                          <div className="avatar flex-shrink-0 d-md-none">
                                            {data?.profiledata?.avatar ? (
                                              <img
                                                src={data?.profiledata?.avatar}
                                                alt=""
                                              />
                                            ) : null}
                                          </div>
                                          <div className="ms-3 ms-md-0">
                                            {data?.profiledata?.profileName ? (
                                              <h6 className="m-0">
                                                {data?.profiledata?.profileName}
                                              </h6>
                                            ) : null}
                                            {i?.publishTime ? (
                                              <p className="m-0">
                                                Published on{' '}
                                                {moment(i?.publishTime).format(
                                                  'Do MMM, YYYY'
                                                )}
                                              </p>
                                            ) : null}
                                          </div>
                                        </div>
                                        <div className="ms-auto d-flex align-items-center flex-wrap flex-md-nowrap">
                                          <div className="youtube__button me-2 mt-2 mt-md-0">
                                            <img src={IconSubscribe} alt="" />
                                            <span className="mx-2">
                                              Subscribed
                                            </span>
                                            <img
                                              src={IconArrowDown}
                                              alt=""
                                              style={{ height: '.375rem' }}
                                            />
                                          </div>
                                          <div className="youtube__button me-2 mt-2 mt-md-0">
                                            <img src={IconLike} alt="" />
                                            <span className="mx-2">
                                              {i?.likes || '0'}
                                            </span>
                                            <span
                                              style={{
                                                width: '1px',
                                                background: '#CFD2D3',
                                                height: '20px',
                                              }}
                                              className="me-2"
                                            ></span>
                                            <img src={IconDislike} alt="" />
                                          </div>
                                          <div className="youtube__button me-2 mt-2 mt-md-0">
                                            <img src={IconShare} alt="" />
                                            <span className="ms-2">Share</span>
                                          </div>
                                          <div className="youtube__button me-2 mt-2 mt-md-0">
                                            <img src={IconDownload} alt="" />
                                            <span className="ms-2">
                                              Download
                                            </span>
                                          </div>
                                          <div
                                            className="youtube__button mt-2 mt-md-0"
                                            style={{
                                              height: '34px',
                                              width: '34px',
                                              borderRadius: '50%',
                                              padding: '0',
                                              justifyContent: 'center',
                                            }}
                                          >
                                            <img
                                              src={IconDots}
                                              alt=""
                                              style={{
                                                height: '3px',
                                                width: 'auto',
                                              }}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <p
                                        className="mb-0 mt-3"
                                        dangerouslySetInnerHTML={{
                                          __html: cleanPost,
                                        }}
                                      ></p>
                                      {i?.mediaFile ? (
                                        <div className="d-flex mt-3">
                                          <img
                                            src={i?.mediaFile}
                                            alt=""
                                            style={{
                                              width: '200px',
                                              height: 'auto',
                                            }}
                                          />
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </>
                      ) : (
                        <div className="text-center p-5">
                          <div className="d-flex justify-content-center mb-4">
                            <img
                              src={IconEmptyExercise}
                              alt=""
                              style={{ opacity: '0.5' }}
                            />
                          </div>
                          <p className="weight-600 mb-4">No Post Found!</p>
                        </div>
                      )}
                    </>
                  ) : (
                    <div className="d-flex align-items-start">
                      <div className="avatar flex-shrink-0 d-none d-md-flex">
                        {data?.profileAvatar ? (
                          <img src={data?.profileAvatar} alt="" />
                        ) : null}
                      </div>
                      <div className="ms-md-3 flex-grow-1">
                        <div className="d-xl-flex align-items-start mb-3">
                          <div className="mb-3 mb-xl-0 d-flex align-items-center">
                            <div className="avatar flex-shrink-0 d-md-none">
                              {data?.profileAvatar ? (
                                <img src={data?.profileAvatar} alt="" />
                              ) : null}
                            </div>
                            <div className="ms-3 ms-md-0">
                              {data?.profileName ? (
                                <h6 className="m-0">{data?.profileName}</h6>
                              ) : null}
                              {data?.postPublishTime ? (
                                <p className="m-0">
                                  Published on{' '}
                                  {moment(data?.postPublishTime).format(
                                    'Do MMM, YYYY'
                                  )}
                                </p>
                              ) : null}
                            </div>
                          </div>
                          <div className="ms-auto d-flex align-items-center flex-wrap flex-md-nowrap">
                            <div className="youtube__button me-2 mt-2 mt-md-0">
                              <img src={IconSubscribe} alt="" />
                              <span className="mx-2">Subscribed</span>
                              <img
                                src={IconArrowDown}
                                alt=""
                                style={{ height: '.375rem' }}
                              />
                            </div>
                            <div className="youtube__button me-2 mt-2 mt-md-0">
                              <img src={IconLike} alt="" />
                              <span className="mx-2">{data?.likes || '0'}</span>
                              <span
                                style={{
                                  width: '1px',
                                  background: '#CFD2D3',
                                  height: '20px',
                                }}
                                className="me-2"
                              ></span>
                              <img src={IconDislike} alt="" />
                            </div>
                            <div className="youtube__button me-2 mt-2 mt-md-0">
                              <img src={IconShare} alt="" />
                              <span className="ms-2">Share</span>
                            </div>
                            <div className="youtube__button me-2 mt-2 mt-md-0">
                              <img src={IconDownload} alt="" />
                              <span className="ms-2">Download</span>
                            </div>
                            <div
                              className="youtube__button mt-2 mt-md-0"
                              style={{
                                height: '34px',
                                width: '34px',
                                borderRadius: '50%',
                                padding: '0',
                                justifyContent: 'center',
                              }}
                            >
                              <img
                                src={IconDots}
                                alt=""
                                style={{ height: '3px', width: 'auto' }}
                              />
                            </div>
                          </div>
                        </div>
                        {data?.post ? (
                          <p className="m-0">{data?.post}</p>
                        ) : null}
                        {data?.mediaFile ? (
                          <div className="d-flex mt-3 mb-5">
                            <img
                              src={data?.mediaFile}
                              alt=""
                              style={{ width: '200px', height: 'auto' }}
                            />
                          </div>
                        ) : null}
                        {data?.comments && !!data?.comments.length ? (
                          <>
                            {data?.comments.map((i, idx) => {
                              const truncatedComment =
                                i?.comment?.length > contentBlockLength
                                  ? `${i?.comment?.slice(
                                      0,
                                      contentBlockLength
                                    )}....`
                                  : i?.comment;
                              const cleanComment = sanitizeHtml(
                                truncatedComment
                              );
                              return (
                                <div key={i?.id} className="mt-4">
                                  {idx !== 0 ? (
                                    <hr className="dashed thin my-4" />
                                  ) : null}
                                  <div className="d-flex align-items-start">
                                    <div className="avatar flex-shrink-0">
                                      {i?.profileAvatar ? (
                                        <img src={i?.profileAvatar} alt="" />
                                      ) : null}
                                    </div>
                                    <div className="ms-3">
                                      {i?.profileName ? (
                                        <h6 className="mb-2">
                                          {i?.profileName}
                                        </h6>
                                      ) : null}
                                      {i?.comment.length ? (
                                        <p
                                          className="m-0"
                                          dangerouslySetInnerHTML={{
                                            __html: cleanComment,
                                          }}
                                        ></p>
                                      ) : null}
                                      {i?.commentImg ? (
                                        <div className="d-flex mt-3">
                                          <img
                                            src={i?.commentImg}
                                            alt=""
                                            style={{
                                              width: '100px',
                                              height: 'auto',
                                            }}
                                          />
                                        </div>
                                      ) : null}
                                      <p className="m-0 mt-3 weight-500 d-flex align-items-md-center flex-column flex-md-row txt--sml">
                                        {i?.publishTime ? (
                                          <>
                                            {moment(i?.publishTime).fromNow()}
                                          </>
                                        ) : null}
                                        <span className="mt-2 mt-md-0 ms-md-4">
                                          {i?.likes || '0'} Likes
                                        </span>
                                        <span className="mt-2 mt-md-0 ms-md-4">
                                          Reply
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </>
                        ) : null}
                      </div>
                    </div>
                  )}
                </>
              ) : null}
            </div>
            <div className="col-lg-4 col-xl-3 d-none d-lg-block">
              <h6>Related Videos</h6>
              {sidebarData.map((item, idx) => {
                return (
                  <div
                    key={idx}
                    className={`d-flex align-items-start ${
                      idx === sidebarData.length - 1 ? 'm-0' : 'mb-4'
                    }`}
                    dangerouslySetInnerHTML={{ __html: sanitizeHtml(item) }}
                  ></div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
