import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Network from '../../../../../service/Network';
import API from '../../../../../service/api';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';
import CreatePlanner from './CreatePlanner';
import { HelpParticipantPlanners, useSelfHostedKnowledgeBase } from 'assets/data/HelpContent';
import ConfirmModal from '../../../../../component/ConfirmModal/ConfirmModal';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import { PreparePath } from 'utils/sharedUtils/sharedConst';
import StylishNewTable from 'components/DesignSystems/New/StylishNewTable';
import StylishNewTableAction from 'components/DesignSystems/New/StylishNewTableAction';
import StylishHelp from 'components/DesignSystems/New/StylishHelp';
import { toastConfig } from 'assets/data/config';
import { PaginationDropdown } from '../../../../../../components/DesignSystems/PaginationDropdown';
import { endLoading, startLoading } from "../../../../../../reducers/loading/loading.action";

export const ParticipantPlanner = (props) => {
  const [showCreateParticipant, setShowCreateParticipant] = useState(false);
  const [dialogType, setDialogType] = useState('Add');
  const [updateDialogData, setUpdateDialogData] = useState({});
  const [viewPlanner, setViewPlanner] = useState([]);
  const [perPage, setPerPage] = useState(5);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [orderBy, setOrderBy] = useState('');
  const [deletePlanner, setDeletePlanner] = useState(null);

  const selfHostedKnowledgeBase = useSelfHostedKnowledgeBase()
  const { selectedWorkspace } = useSelector((state) => state.prepare.workspace);
  const reduxUser = useSelector((state) => state.prepare.user);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const columns = [
    {
      dataField: 'name',
      text: 'Name',
      sort: true,
      headerAttrs: { title: 'Sort By:' },
      attrs: { title: 'Name' },
      onSort: (field, order) => {
        if (order === 'asc') {
          setOrderBy('name_ASC');
        } else if (order === 'desc') {
          setOrderBy('name_DESC');
        } else {
          setOrderBy('');
        }
      },
    },
    {
      dataField: 'email',
      text: 'Email',
      sort: true,
      attrs: { title: 'Email' },
      onSort: (field, order) => {
        if (order === 'asc') {
          setOrderBy('email_ASC');
        } else if (order === 'desc') {
          setOrderBy('email_DESC');
        } else {
          setOrderBy('');
        }
      },
    },
    {
      dataField: 'phnNumber',
      text: 'Phone Number',
      attrs: { title: 'Phone Number' },
    },
    {
      dataField: 'isPrimary',
      text: 'Primary Contact',
      attrs: { title: 'Primary Contact' },
    },
    {
      dataField: 'position',
      text: 'Position',
      attrs: { title: 'Position' },
    },

    {
      dataField: '',

      text: 'Action',
      isDummyField: true,
      formatter: (cell, row, rowIndex) => (
        <StylishNewTableAction
          name="Participant Members"
          cell={cell}
          row={row}
          rowIndex={rowIndex}
          onDelete={onDeletePlanner}
          onEdit={OnEdit}
        />
      ),
      attrs: { title: 'Action' },
      headerStyle: {
        width: '100px',
      },
      headerAlign: 'center',
      align: 'center',
    },
  ];

  const fetchAllPlanner = async () => {
    dispatch(startLoading());
    try {
      // const planner =

      Network.get(API.fetchAllPlanner, {
        workspaceId: selectedWorkspace,
        participantId: props.participantId,
        limit: perPage,
        page: page,
        order_by: orderBy,
      })
        .then((planner) => {
          setTotal(planner.data.response.total);
          setViewPlanner(
            planner.data.response.dataset.map((planner) => ({
              id: planner.id,
              name: planner.name,
              phnNumber: planner.phnNumber
                ? formatPhoneNumberIntl(planner.phnNumber)
                : '',
              email: planner.email,
              position: planner?.position,
              isPrimary: planner.isPrimary ? 'Yes' : 'No',
            }))
          );
        })
        .catch(console.log)
        .finally(() => {
          dispatch(endLoading());
        });
    } catch (error) {
      console.log(error);
      //   toast.error(error.response.data.response.status.msg);
      // } finally {
      //   dispatch(endLoading());
    }
  };

  useEffect(() => {
    fetchAllPlanner();
  }, [reduxUser, perPage, page, orderBy]);

  const onDeletePlanner = (row) => {
    setDeletePlanner(row.id);
  };

  const onDeleteConfirm = async (data) => {
    dispatch(startLoading());
    try {
      await Network.post(API.deletePlanner, {
        id: deletePlanner,
      });
      setDeletePlanner(null);
      fetchAllPlanner();
      toast.success('Member deleted successfully', toastConfig);
    } catch (error) {
      toast.error(error.response.data.response.status.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };

  const submitAndExit = () => {
    try {
      navigate(`${PreparePath}/dashboard/plan/prepare/participants`, {
        replace: true,
      });
    } catch (error) {
      toast.error(error.response.data.response.status.msg, toastConfig);
    } finally {
      dispatch(endLoading());
    }
  };
  const onAddPlanner = () => {
    setDialogType('Add');
    setShowCreateParticipant(true);
  };

  const onCloseModal = (e) => {
    setShowCreateParticipant(false);
    if (e?.id) {
      fetchAllPlanner();
    }
  };

  const OnEdit = (data) => {
    setDialogType('Edit');
    setShowCreateParticipant(true);
    setUpdateDialogData(data);
  };

  const handlePageClick = (selectedPage) => {
    setPage(selectedPage);
  };

  return (
    <>
      <div className="d-flex align-items-center mb-4">
        <h4 className="mb-0">Participant Members</h4>
        <StylishHelp
          classes={'ms-auto sml flex-shrink-0'}
          title={HelpParticipantPlanners.title}
          content={HelpParticipantPlanners.content}
          link={selfHostedKnowledgeBase || HelpParticipantPlanners.link}
        />
      </div>
      <div className="form-block">
        <StylishNewButton
          className="button button--primary mb-3 w-100 w-md-auto"
          onClick={onAddPlanner}
        >
          Add Members
        </StylishNewButton>
        <div className="row mb-2">
          <div className="col-md-12">
            <div className="d-flex align-items-center">
              <p className="m-0 me-3 text-nowrap weight-600">
                Participant Member: {viewPlanner.length}
              </p>
              <div className="ms-auto">
                <PaginationDropdown
                  setPage={setPage}
                  setPerPage={setPerPage}
                  options={[
                    { value: 5, label: '5 per page' },
                    { value: 15, label: '15 per page' },
                    { value: 30, label: '30 per page' },
                    { value: 60, label: '60 per page' },
                    { value: 120, label: '120 per page' },
                  ]}
                />
              </div>
            </div>
          </div>
        </div>
        <StylishNewTable
          keyField={'id'}
          columns={columns}
          rows={viewPlanner}
          page={page}
          total={total}
          perPage={perPage}
          onPageChange={handlePageClick}
          pagination={true}
        />
        {showCreateParticipant && (
          <CreatePlanner
            show={showCreateParticipant}
            onClose={onCloseModal}
            dialogType={dialogType}
            updateDialogData={updateDialogData}
            participantId={props.participantId}
          />
        )}
        {deletePlanner && (
          <ConfirmModal
            show={deletePlanner}
            onClose={() => setDeletePlanner(null)}
            heading={'Delete Member'}
            text={'Are you sure you want to do this?'}
            onConfirm={onDeleteConfirm}
          />
        )}
      </div>
      <div className="col-md-12 mt-4">
        <div className="button-group d-block d-md-flex">
          <StylishNewButton
            className="button button--primary button--reverse w-100 w-md-auto"
            onClick={() => props.onPrevClick(1)}
            type="submit"
          >
            Previous
          </StylishNewButton>
          <StylishNewButton
            className="button button--tertiary button--reverse ms-auto mt-3 mt-md-0 w-100 w-md-auto"
            onClick={submitAndExit}
          >
            Save &amp; Exit
          </StylishNewButton>
          <StylishNewButton
            className="button button--primary  w-100 w-md-auto mt-3 mt-md-0 ms-0 ms-md-3"
            onClick={() => props.onNextClick(3)}
            type="submit"
          >
            Next
          </StylishNewButton>
        </div>
      </div>
    </>
  );
};
