import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import API from '../../../service/api';
import Network from '../../../service/Network';
import { inputStringLength, toastConfig } from 'assets/data/config';
import { useEffect } from 'react';
import StylishNewInput from 'components/DesignSystems/New/StylishNewInput';
import { StylishNewButton } from 'components/DesignSystems/New/StylishNewButton';
import StylishNewSelect from 'components/DesignSystems/New/StylishNewSelect';
import { endLoading, startLoading } from "../../../../reducers/loading/loading.action";

export default function AddEditMetricDialog({
  onClose,
  show,
  dialogType,
  updateDialogData,
}) {
  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm();

  const workspaceId = useSelector(
    (state) => state.prepare.workspace.selectedWorkspace
  );

  const dispatch = useDispatch();

  const participantOptions = [
    {
      value: 'DOD',
      label: 'DOD',
    },
    {
      value: 'HSEEP',
      label: 'HSEEP',
    },
  ];

  const onSubmit = async (data) => {
    dispatch(startLoading());
    try {
      console.log(data);
      const newMetric = await Network.post(
        dialogType === 'Add' ? API.createMetric : API.updateMetric,
        {
          title: data?.title,
          participantType: data?.metricParticipantType.value,
          workspaceId: workspaceId,
          metricId: updateDialogData?.id,
        }
      );
      onClose(newMetric?.data?.response?.dataset[0]);
      toast.success(
        dialogType === 'Add'
          ? 'Metric created successfully'
          : 'Metric updated successfully',
        toastConfig
      );
      console.log('here');
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(endLoading());
    }
  };

  useEffect(() => {
    if (dialogType === 'Edit' && updateDialogData) {
      setValue('title', updateDialogData?.title);

      if (updateDialogData?.participantType == 'DOD') {
        setValue('metricParticipantType', participantOptions[0]);
      } else {
        setValue('metricParticipantType', participantOptions[1]);
      }
    } else {
      setValue('metricParticipantType', participantOptions[0]);
    }
  }, []);

  useEffect(() => {
    const listener = (event) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        event.preventDefault();
        handleSubmit(onSubmit)();
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, []);

  return (
    <Modal
      show={show}
      onHide={onClose}
      centered
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title>{dialogType} Metric</Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <div className="mb-3">
            <label className="form-label">Name</label>
            <Controller
              control={control}
              name="title"
              defaultValue={updateDialogData?.title}
              rules={{ required: true }}
              maxLength={inputStringLength}
              render={({ field: { onChange, value } }) => (
                <StylishNewInput
                  type="text"
                  className="form-control"
                  placeholder="Name"
                  onChange={onChange}
                  value={value}
                />
              )}
            />
            {errors?.title && (
              <span className="form-text form-error">
                *This is a required field{' '}
              </span>
            )}
          </div>
          <div className="mb-0">
            <label className="form-label">Participant Type</label>
            <Controller
              control={control}
              name="metricParticipantType"
              rules={{ required: true }}
              // defaultValue={updateDialogData?.id}
              render={({ field: { onChange, value } }) => (
                <StylishNewSelect
                  options={participantOptions}
                  isDisabled={updateDialogData?.participantType ? true : false}
                  value={value}
                  onChange={onChange}
                  isClearable={false}
                  isSearchable={false}
                  isMulti={false}
                />
              )}
            />
            {errors?.metricParticipantType && (
              <span className="form-text form-error">
                *This is a required field{' '}
              </span>
            )}
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div className="button-group">
            <StylishNewButton
              className="button button--secondary button--reverse"
              onClick={onClose}
            >
              Close
            </StylishNewButton>
            <StylishNewButton className="button button--primary" type="submit">
              Save
            </StylishNewButton>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
