import config from 'constants/apiConfig';
import parseErrorMessage from 'utils/parseErrorMessage';
import {
  GET_ROSTER_FOR_INCIDENT_REQUEST,
  GET_ROSTER_FOR_INCIDENT_SUCCESS,
  GET_ROSTER_FOR_INCIDENT_ERROR,
  ADD_USER_TO_INCIDENT_BY_ADMIN_REQUEST,
  ADD_USER_TO_INCIDENT_BY_ADMIN_SUCCESS,
  ADD_USER_TO_INCIDENT_BY_ADMIN_ERROR,
  EDIT_INCIDENT_MEMBER_BY_ADMIN_REQUEST,
  EDIT_INCIDENT_MEMBER_BY_ADMIN_SUCCESS,
  EDIT_INCIDENT_MEMBER_BY_ADMIN_ERROR,
  REMOVE_USER_FROM_INCIDENT_BY_ADMIN_REQUEST,
  REMOVE_USER_FROM_INCIDENT_BY_ADMIN_SUCCESS,
  REMOVE_USER_FROM_INCIDENT_BY_ADMIN_ERROR,
  REMOVE_SELF_FROM_INCIDENT_REQUEST,
  REMOVE_SELF_FROM_INCIDENT_SUCCESS,
  REMOVE_SELF_FROM_INCIDENT_ERROR,
  RESPOND_TO_REQUEST_TO_JOIN_INCIDENT_REQUEST,
  RESPOND_TO_REQUEST_TO_JOIN_INCIDENT_SUCCESS,
  RESPOND_TO_REQUEST_TO_JOIN_INCIDENT_ERROR,
  GET_DASHBOARD_REQUEST,
  GET_DASHBOARD_SUCCESS,
  GET_DASHBOARD_ERROR,
  ADD_USER_TO_INCIDENT_SUCCESS,
  GET_INCIDENT_LOGS,
  ADD_NON_USER_TO_INCIDENT_BY_ADMIN_REQUEST,
  ADD_NON_USER_TO_INCIDENT_SUCCESS,
  DEACTIVATE_USER_TO_INCIDENT_REQUEST,
  DEACTIVATE_USER_TO_INCIDENT_SUCCESS,
  UPDATE_INCIDENT_LOGS_WITH_WORKLOG,
  GET_ALL_INCIDENT_OBJECTIVES,
  GET_ALL_INCIDENT_OBJECTIVES_REQUEST,
  GET_ALL_INCIDENT_OBJECTIVES_SUCCESS,
  ADD_INCIDENT_OBJECTIVES_REQUEST,
  ADD_INCIDENT_OBJECTIVES_SUCCESS,
  ADD_INCIDENT_RESOURCES_REQUEST,
  ADD_INCIDENT_RESOURCES_SUCCESS,
  GET_ALL_INCIDENT_RESOURCES_SUCCESS,
  GET_ALL_INCIDENT_RESOURCES_REQUEST,
  GET_ALL_INCIDENT_RESOURCES,
  GET_ALL_GROUP_OBJECTIVES,
  GET_ALL_GROUP_OBJECTIVES_REQUEST,
  GET_ALL_GROUP_RESOURCES,
  GET_ALL_GROUP_RESOURCES_REQUEST,
  SELF_ACTIVATION_REQUEST,
  SELF_ACTIVATION_SUCCESS,
  ADD_SCHEDULE_TO_INCIDENT_REQUEST,
  ADD_SCHEDULE_TO_INCIDENT_SUCCESS,
  GET_ALL_SCHEDULE_TO_INCIDENT_REQUEST,
  GET_ALL_SCHEDULE_TO_INCIDENT,
  GET_USER_SCHEDULE_TO_INCIDENT,
  UPSERT_OPERATIONAL_PERIOD_REQUEST,
  UPSERT_OPERATIONAL_PERIOD_SUCCESS,
  UPDATE_INCIDENT_MEMBER_WORK_LOG_REQUEST,
  UPDATE_INCIDENT_MEMBER_WORK_LOG_SUCCESS,
  SET_INCIDENT_ACTIVE_STATUS_REQUEST,
  SET_INCIDENT_ACTIVE_STATUS_SUCCESS,
  SET_INCIDENT_ACTIVE_STATUS_ERROR,
  UPDATE_INCIDENT_OBJECTIVES_SELECTION_REQUEST,
  UPDATE_INCIDENT_OBJECTIVES_SELECTION_SUCCESS,
  UPDATE_INCIDENT_RESOURCES_SELECTION_REQUEST,
  UPDATE_INCIDENT_RESOURCES_SELECTION_SUCCESS,
  BULK_ADD_OBJECTIVES_REQUEST,
  BULK_ADD_OBJECTIVES_SUCCESS,
  BULK_ADD_OBJECTIVES_ERROR,
  BULK_ADD_ACTIONS_REQUEST,
  BULK_ADD_ACTIONS_SUCCESS,
  BULK_ADD_ACTIONS_ERROR,
  BULK_ADD_RESOURCES_REQUEST,
  BULK_ADD_RESOURCES_SUCCESS,
  BULK_ADD_RESOURCES_ERROR,
  UPDATE_INCIDENT_ACTION_SELECTION_REQUEST,
  UPDATE_INCIDENT_ACTION_SELECTION_SUCCESS,
  UPDATE_INCIDENT_ACTION_SELECTION_ERROR,
} from 'constants/incident-action-types';
import axios from 'axios';
import { saveAs } from 'file-saver';

import Blob from 'blob';
import json2csv from 'json2csv';
import { fetchIncidents } from './profileActions';
import { endLoading } from 'reducers/loading/loading.action';
import { toast } from 'react-toastify';
import { getAllAction, getAllActionByIncident } from './IAPForm201Actions';
import { noAutoClose } from 'assets/data/config';
import { RemoveUserFromTeamSite, addMemberToTeams } from './teamsActions';

function getRosterForIncidentRequest() {
  return {
    type: GET_ROSTER_FOR_INCIDENT_REQUEST,
  };
}

function getRosterForIncidentSuccess(rosterData) {
  return {
    type: GET_ROSTER_FOR_INCIDENT_SUCCESS,
    payload: rosterData,
  };
}

function getRosterForIncidentError(error) {
  // TODO CRITICAL
  const errorMessage =
    'getRosterForIncidentError\n\n' +
    parseErrorMessage(error) +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: GET_ROSTER_FOR_INCIDENT_ERROR,
  };
}

export function getRosterForIncident() {
  return (dispatch, useState) => {
    const {
      app: { currentIncident },
    } = useState();
    const { id: incidentId } = currentIncident || {};
    dispatch(getRosterForIncidentRequest());
    if (incidentId) {
      return axios(config.apiGateway.getRosterForIncident, {
        method: 'POST',
        data: JSON.stringify({
          incident_id: incidentId,
        }),
      })
        .then((response) => {
          const rolesData = response.data || [];
          dispatch(getRosterForIncidentSuccess(rolesData));

          return rolesData;
        })
        .catch((error) => {
          dispatch(getRosterForIncidentError(error));
        });
    } else {
      dispatch(getRosterForIncidentSuccess([]));
    }
  };
}

function addUserToIncidentByAdminRequest() {
  return {
    type: ADD_USER_TO_INCIDENT_BY_ADMIN_REQUEST,
  };
}

function addNonUserToIncidentByAdminRequest() {
  return {
    type: ADD_NON_USER_TO_INCIDENT_BY_ADMIN_REQUEST,
  };
}

function deactivateUserToIncidentRequest() {
  return {
    type: DEACTIVATE_USER_TO_INCIDENT_REQUEST,
  };
}

function addUserToIncidentByAdminSuccess(userAddedToIncident) {
  return {
    type: ADD_USER_TO_INCIDENT_BY_ADMIN_SUCCESS,
    payload: userAddedToIncident,
  };
}

function addUserToIncidentSuccess() {
  return {
    type: ADD_USER_TO_INCIDENT_SUCCESS,
  };
}

function addNonUserToIncidentSuccess() {
  return {
    type: ADD_NON_USER_TO_INCIDENT_SUCCESS,
  };
}

function deactivateUserToIncidentSuccess(incident_member_id) {
  return {
    type: DEACTIVATE_USER_TO_INCIDENT_SUCCESS,
    payload: incident_member_id,
  };
}

function addUserToIncidentByAdminError(error) {
  const errorMessage =
    'addUserToIncidentByAdminError\n\n' +
    parseErrorMessage(error) +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: ADD_USER_TO_INCIDENT_BY_ADMIN_ERROR,
  };
}

export function addUserToIncidentByAdmin({
  emailAddress: email_address,
  incidentSeat: incident_seat_id,
  incidentSeatName: incident_seat_name,
}) {
  return (dispatch, useState) => {
    const {
      app: { currentIncident },
    } = useState();
    const { id: incidentId } = currentIncident || {};
    const { group_guid } = useState().app.currentlySelectedGroup;
    dispatch(addUserToIncidentByAdminRequest());
    return axios(config.apiGateway.addUserToIncidentByAdmin, {
      method: 'POST',
      data: JSON.stringify({
        incident_id: incidentId,
        email_address,
        incident_seat_id,
        incident_seat_name,
        group_guid,
      }),
    })
      .then((response) => {
        const userAddedToIncident = response.data || [];
        dispatch(addUserToIncidentByAdminSuccess(userAddedToIncident));
        dispatch(getRosterForIncident());
        return userAddedToIncident;
      })
      .catch((error) => {
        if (
          JSON.stringify(error.response.data) ===
          '{"ERROR":"User does not exist"}'
        ) {
          dispatch(
            addUserToIncidentByAdminError({ message: 'User does not exist' })
          );
        } else if (
          JSON.stringify(error.response.data) ===
          '{"ERROR":"User already in this Incident"}'
        ) {
          dispatch(
            addUserToIncidentByAdminError({
              message: 'User already in this Incident',
            })
          );
        } else {
          dispatch(
            addUserToIncidentByAdminError('ERROR adding incident member')
          );
        }
      });
  };
}

export const addUserToIncident = ({
  incidentMembers,
  incident_id,
  memberEmails,
}) => {
  return (dispatch, getState) => {
    const { currentIncident } = getState().app;
    dispatch(addUserToIncidentByAdminRequest());
    return axios(config.apiGateway.addUserToIncident, {
      method: 'POST',
      data: JSON.stringify({
        incident_id: incident_id,
        incidentMembers: incidentMembers,
        group_guid: getState().app.currentlySelectedGroup.group_guid,
      }),
    })
      .then(() => {
        dispatch(addUserToIncidentSuccess());
        dispatch(getRosterForIncident());
        if (currentIncident.teams_id) {
          dispatch(addMemberToTeams(incident_id, memberEmails));
        }
        // Get ALL incident Members
      })
      .catch((error) => {
        if (
          JSON.stringify(error.response.data) ===
          '{"ERROR":"User does not exist"}'
        ) {
          dispatch(
            addUserToIncidentByAdminError({ message: 'User does not exist' })
          );
        } else if (
          JSON.stringify(error.response.data) ===
          '{"ERROR":"User already in this Incident"}'
        ) {
          dispatch(
            addUserToIncidentByAdminError({
              message: 'User already in this Incident',
            })
          );
        } else {
          dispatch(
            addUserToIncidentByAdminError('ERROR adding incident member')
          );
        }
      });
  };
};

function editIncidentMemberByAdminRequest() {
  return {
    type: EDIT_INCIDENT_MEMBER_BY_ADMIN_REQUEST,
  };
}

function editIncidentMemberByAdminSuccess(editedIncidentMember) {
  return {
    type: EDIT_INCIDENT_MEMBER_BY_ADMIN_SUCCESS,
    payload: editedIncidentMember,
  };
}

function editIncidentMemberByAdminError(error) {
  const errorMessage =
    'editIncidentMemberByAdminError\n\n' +
    parseErrorMessage(error) +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: EDIT_INCIDENT_MEMBER_BY_ADMIN_ERROR,
  };
}

export function editIncidentMemberByAdmin(member, incident_member_id) {
  return (dispatch, useState) => {
    dispatch(editIncidentMemberByAdminRequest());
    return axios(config.apiGateway.editIncidentMemberByAdmin, {
      method: 'POST',
      data: JSON.stringify({
        incidentMember: member,
        group_guid: useState().app.currentlySelectedGroup.group_guid,
      }),
    })
      .then(() => {
        dispatch(editIncidentMemberByAdminSuccess(member));
        dispatch(getRosterForIncident());
        return dispatch(getALlScheduleToIncident());
      })
      .then(() => {
        dispatch(getUserIncidentSchedule(member.user_guid, incident_member_id));
        return member;
      })
      .catch((error) => {
        dispatch(editIncidentMemberByAdminError(error));
      });
  };
}

function removeUserFromIncidentByAdminRequest() {
  return {
    type: REMOVE_USER_FROM_INCIDENT_BY_ADMIN_REQUEST,
  };
}

function removeUserFromIncidentBySuccess(removedIncidentMember) {
  return {
    type: REMOVE_USER_FROM_INCIDENT_BY_ADMIN_SUCCESS,
    payload: removedIncidentMember,
  };
}

function removeUserFromIncidentByAdminError(error) {
  const errorMessage =
    'removeUserFromIncidentByAdminError\n\n' +
    parseErrorMessage(error) +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: REMOVE_USER_FROM_INCIDENT_BY_ADMIN_ERROR,
  };
}

export function removeUserFromIncidentByAdmin(user) {
  return (dispatch, useState) => {
    const {
      app: { currentIncident },
    } = useState();
    const { id: incidentId } = currentIncident || {};
    const { group_guid } = useState().app.currentlySelectedGroup;
    dispatch(removeUserFromIncidentByAdminRequest());
    return axios(config.apiGateway.removeUserFromIncidentByAdmin, {
      method: 'POST',
      data: JSON.stringify({
        incident_id: incidentId,
        user_guid: user.user_guid,
        group_guid,
      }),
    })
      .then((response) => {
        const removedIncidentMember = response.data || [];
        dispatch(removeUserFromIncidentBySuccess(removedIncidentMember));
        dispatch(getRosterForIncident());
        return removedIncidentMember;
      })
      .catch((error) => {
        dispatch(removeUserFromIncidentByAdminError(error));
      });
  };
}

function removeSelfFromIncidentRequest() {
  return {
    type: REMOVE_SELF_FROM_INCIDENT_REQUEST,
  };
}

function removeSelfFromIncidentSuccess(removedSelfIncidentMember) {
  return {
    type: REMOVE_SELF_FROM_INCIDENT_SUCCESS,
    payload: removedSelfIncidentMember,
  };
}

function removeSelfFromIncidentError(error) {
  const errorMessage =
    'removeSelfFromIncidentError\n\n' +
    parseErrorMessage(error) +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: REMOVE_SELF_FROM_INCIDENT_ERROR,
  };
}

export function removeSelfFromIncident(user) {
  return (dispatch, useState) => {
    const {
      app: { currentIncident },
    } = useState();
    const { id: incidentId } = currentIncident || {};
    dispatch(removeSelfFromIncidentRequest());
    return axios(config.apiGateway.removeSelfFromIncident, {
      method: 'POST',
      data: JSON.stringify({
        incident_id: incidentId,
        user_guid: user.user_guid,
      }),
    })
      .then((response) => {
        const removedSelfIncidentMember = response.data || [];
        dispatch(removeSelfFromIncidentSuccess(removedSelfIncidentMember));
        dispatch(fetchIncidents());
        return removedSelfIncidentMember;
      })
      .catch((error) => {
        dispatch(removeSelfFromIncidentError(error));
      });
  };
}

function getDashboardForSharablePageRequest() {
  return {
    type: GET_DASHBOARD_REQUEST,
  };
}

function getDashboardForSharablePageSuccess(dashboardId) {
  return {
    type: GET_DASHBOARD_SUCCESS,
    payload: dashboardId,
  };
}

function getDashboardForSharablePageError(error) {
  const errorMessage =
    'getDashboardForSharablePageError\n\n' +
    parseErrorMessage(error) +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: GET_DASHBOARD_ERROR,
  };
}

export function getDashboardForSharablePage({ dashboardId }) {
  return (dispatch) => {
    try {
      dispatch(getDashboardForSharablePageRequest());
      dispatch(getDashboardForSharablePageSuccess(dashboardId));
    } catch (error) {
      dispatch(getDashboardForSharablePageError(error));
    }
  };
}

export const addNonMemberToIncident = ({ incidentNonMember, incident_id }) => {
  return (dispatch, getState) => {
    dispatch(addNonUserToIncidentByAdminRequest());
    return axios(config.apiGateway.addNonMemberToIncident, {
      method: 'POST',
      data: JSON.stringify({
        incident_id: incident_id,
        incidentNonMember: incidentNonMember,
        group_guid: getState().app.currentlySelectedGroup.group_guid,
      }),
    })
      .then(() => {
        dispatch(addNonUserToIncidentSuccess());
        dispatch(getRosterForIncident());
        // Get ALL incident Members
      })
      .catch(() => {
        dispatch(
          incidentUserError('createIncidentNonMembers', {
            message: 'There was a problem adding the incident member',
          })
        );
      });
  };
};

export const getAllIncidentLogs = (incident_id) => {
  return (dispatch, getState) => {
    return axios(config.apiGateway.getIncidentLogs, {
      method: 'POST',
      data: JSON.stringify({
        incident_id: incident_id,
        group_guid: getState().app.currentlySelectedGroup.group_guid,
      }),
    })
      .then((response) => {
        dispatch({ type: GET_INCIDENT_LOGS, payload: response.data });
      })
      .catch(() => {
        dispatch(
          incidentUserError('getIncidentLogs', {
            message: 'There was a problem with getting the incident logs',
          })
        );
      });
  };
};

export const markIncidentUserActive = (
  incident_id,
  user_guid,
  incident_member_id
) => {
  return (dispatch, getState) => {
    dispatch({ type: SELF_ACTIVATION_REQUEST });
    return axios(config.apiGateway.markIncidentMemberActive, {
      method: 'POST',
      data: JSON.stringify({
        incident_id: incident_id,
        user_guid: user_guid,
        group_guid: getState().app.currentlySelectedGroup.group_guid,
      }),
    })
      .then(() => {
        dispatch({
          type: SELF_ACTIVATION_SUCCESS,
          payload: incident_member_id,
        });
      })
      .catch((error) => {
        dispatch({ type: SELF_ACTIVATION_SUCCESS });
        if (
          error.response.data.ERROR.includes(
            'You can not work on two different incident at the same time.'
          )
        ) {
          toast.error(
            'User is already working on another incident. You can not work on two different incidents at the same time.',
            { autoClose: 10000 }
          );
        } else {
          dispatch(
            incidentUserError('markIncidentMemberActive', {
              message: 'Failed to activate incident user',
            })
          );
        }
      });
  };
};

export const markIncidentUserDeactive = (
  incident_id,
  user_guid,
  logout_time,
  minutes_worked,
  incident_member_id
) => {
  return (dispatch, getState) => {
    dispatch(deactivateUserToIncidentRequest());
    return axios(config.apiGateway.markIncidentMemberDeactive, {
      method: 'POST',
      data: JSON.stringify({
        incident_id: incident_id,
        user_guid: user_guid,
        logout_time: logout_time,
        minutes_worked,
        group_guid: getState().app.currentlySelectedGroup.group_guid,
      }),
    })
      .then(() => {
        dispatch(deactivateUserToIncidentSuccess(incident_member_id));
      })
      .catch(() => {
        dispatch(
          incidentUserError('markIncidentMemberDeactive', {
            message: 'Failed to deactivate incident user',
          })
        );
      });
  };
};

export const updateIncidentMemberWorkLog = ({
  loginDateTime,
  logoutDateTime,
  workLogId,
}) => {
  return (dispatch, getState) => {
    const groupGuid = getState().app.currentlySelectedGroup.group_guid;
    const incidentGuid = getState().app.currentIncident.id;
    dispatch({ type: UPDATE_INCIDENT_MEMBER_WORK_LOG_REQUEST });
    return axios(config.apiGateway.updateIncidentMemberWorkLog, {
      method: 'POST',
      data: JSON.stringify({
        work_log_id: workLogId,
        login_time: loginDateTime,
        logout_time: logoutDateTime,
        group_guid: groupGuid,
      }),
    })
      .then(() => {
        dispatch({ type: UPDATE_INCIDENT_MEMBER_WORK_LOG_SUCCESS });
        dispatch(getAllIncidentLogs(incidentGuid));
      })
      .catch((e) => {
        console.warn(e);
        dispatch(
          incidentUserError(
            'updateIncidentMemberWorkLog',
            'There was a problem with updating the work log.'
          )
        );
      });
  };
};

export const getUserIncidentWorkLogTimes = (log_row) => {
  return (dispatch, getState) => {
    return axios(config.apiGateway.incidentMemberWorkLogs, {
      method: 'POST',
      data: JSON.stringify({
        incident_id: log_row.incident_id,
        user_guid: log_row.user_guid,
        group_guid: getState().app.currentlySelectedGroup.group_guid,
      }),
    })
      .then((response) => {
        if (response.data.length > 0) {
          dispatch({
            type: UPDATE_INCIDENT_LOGS_WITH_WORKLOG,
            payload: { logs: response.data, id: log_row.incident_member_id },
          });
        }
      })
      .catch(() => {
        dispatch(
          incidentUserError(
            'markIncidentMemberdeactive',
            'There was a problem with de-activating incident member'
          )
        );
      });
  };
};

export const downloadIncidentLogs = () => {
  return (dispatch, getState) => {
    const incidentMembersLogs = [
      ...getState().app.incidentLogs.incidentMembersLogs.map((e) => {
        return {
          'email_address/name': e.email_address,
          hours_worked: e.minutes_worked_in_words,
          incident_seat: e.incident_seat,
          location: '',
          notes: '',
        };
      }),
    ];
    const incidentNonMembersLogs = [
      ...getState().app.incidentLogs.incidentNonMembersLogs.map((e) => {
        return {
          'email_address/name': e.email_address,
          hours_worked: e.minutes_worked_in_words,
          incident_seat: e.incident_seat,
          location: e.location,
          notes: e.notes,
        };
      }),
    ];
    const IncidentLogs = [...incidentMembersLogs, ...incidentNonMembersLogs];
    let downloadDataBlob;
    if (!!IncidentLogs.length) {
      const fields = Object.keys(IncidentLogs[0]);
      const opts = { fields };
      downloadDataBlob = new Blob([json2csv.parse(IncidentLogs, opts)], {
        type: 'application/csv',
      });
      saveAs(
        downloadDataBlob,
        'DICE ' + getState().app.currentIncident.name + ' logs.csv'
      );
    } else {
      toast.error('There are no records in this log.');
    }
  };
};

export const addObjectiveToIncident = (objective) => {
  return (dispatch, getState) => {
    dispatch({ type: ADD_INCIDENT_OBJECTIVES_REQUEST });
    return axios(config.apiGateway.addObjectiveToIncident, {
      method: 'POST',
      data: JSON.stringify({
        incident_id: getState().app.currentIncident.id,
        objective: objective,
        group_guid: getState().app.currentlySelectedGroup.group_guid,
      }),
    })
      .then(() => {
        dispatch({ type: ADD_INCIDENT_OBJECTIVES_SUCCESS });
        dispatch(getAllObjectiveToIncident());
        dispatch(getAllObjectivesToGroup());
      })
      .catch(() => {
        dispatch(
          incidentUserError('addObjectiveToIncident', {
            message: 'ERROR Adding objective to incident',
          })
        );
        dispatch(endLoading());
      });
  };
};

export const updateIncidentObjectiveSelection = (objectives) => {
  return (dispatch, getState) => {
    dispatch({ type: UPDATE_INCIDENT_OBJECTIVES_SELECTION_REQUEST });
    return axios(config.apiGateway.updateIncidentObjectiveSelection, {
      method: 'POST',
      data: JSON.stringify({
        incident_id: getState().app.currentIncident.id,
        objectives: objectives,
        group_guid: getState().app.currentlySelectedGroup.group_guid,
      }),
    })
      .then(() => {
        dispatch({ type: UPDATE_INCIDENT_OBJECTIVES_SELECTION_SUCCESS });
        dispatch(getAllObjectiveToIncident());
        dispatch(getAllObjectivesToGroup());
      })
      .catch(() => {
        dispatch(
          incidentUserError('updateIncidentObjectiveSelection', {
            message: 'ERROR Updating selected objectives for incident',
          })
        );
        dispatch(endLoading());
      });
  };
};

export const updateIncidentActionSelection = (actions) => {
  return (dispatch, getState) => {
    dispatch({ type: UPDATE_INCIDENT_ACTION_SELECTION_REQUEST });
    return axios(config.apiGateway.updateIncidentActionSelection, {
      method: 'POST',
      data: JSON.stringify({
        incident_id: getState().app.currentIncident.id,
        actions: actions,
        group_guid: getState().app.currentlySelectedGroup.group_guid,
      }),
    })
      .then(() => {
        dispatch({ type: UPDATE_INCIDENT_ACTION_SELECTION_SUCCESS });
        dispatch(getAllAction());
        dispatch(getAllActionByIncident());
      })
      .catch(() => {
        dispatch({ type: UPDATE_INCIDENT_ACTION_SELECTION_ERROR });
        dispatch(endLoading());
      });
  };
};

export const updateIncidentResourceSelection = (resources) => {
  return (dispatch, getState) => {
    dispatch({ type: UPDATE_INCIDENT_RESOURCES_SELECTION_REQUEST });
    return axios(config.apiGateway.updateIncidentResourceSelection, {
      method: 'POST',
      data: JSON.stringify({
        incident_id: getState().app.currentIncident.id,
        resources: resources,
        group_guid: getState().app.currentlySelectedGroup.group_guid,
      }),
    })
      .then(() => {
        dispatch({ type: UPDATE_INCIDENT_RESOURCES_SELECTION_SUCCESS });
        dispatch(getAllResourcesToIncident());
        dispatch(getAllResourcesToGroup());
      })
      .catch(() => {
        dispatch(
          incidentUserError('updateIncidentResourceSelection', {
            message: 'ERROR Updating selected resources to incident',
          })
        );
        dispatch(endLoading());
      });
  };
};

export const getAllObjectiveToIncident = () => {
  return (dispatch, getState) => {
    dispatch({ type: GET_ALL_INCIDENT_OBJECTIVES_REQUEST });

    const incident_id = getState().app.currentIncident?.id;

    if (incident_id) {
      return axios(config.apiGateway.getAllObjectiveToIncident, {
        method: 'POST',
        data: JSON.stringify({
          incident_id: getState().app.currentIncident.id,
          group_guid: getState().app.currentlySelectedGroup.group_guid,
        }),
      })
        .then((response) => {
          // dispatch();
          dispatch({ type: GET_ALL_INCIDENT_OBJECTIVES_SUCCESS });
          dispatch({
            type: GET_ALL_INCIDENT_OBJECTIVES,
            payload: response.data,
          });
        })
        .catch(() => {
          dispatch(
            incidentUserError('getAllObjectiveToIncident', {
              message: 'ERROR getting objectives for an incident',
            })
          );
        })
        .finally(() => {
          dispatch(endLoading());
        });
    } else {
      dispatch({ type: GET_ALL_INCIDENT_OBJECTIVES_SUCCESS });
      dispatch({ type: GET_ALL_INCIDENT_OBJECTIVES, payload: [] });
    }
  };
};

export const deleteObjectiveToIncident = (objective_id, type) => {
  return (dispatch, getState) => {
    return axios(config.apiGateway.deleteObjectiveToIncident, {
      method: 'POST',
      data: JSON.stringify({
        incident_id: getState().app.currentIncident.id,
        group_guid: getState().app.currentlySelectedGroup.group_guid,
        objective_id: objective_id,
      }),
    })
      .then(() => {
        dispatch(getAllObjectiveToIncident());
        dispatch(getAllObjectivesToGroup());
        if (type && type === 'ICS 201') {
          dispatch(getAllAction());
        }
      })
      .catch(() => {
        dispatch(
          incidentUserError('deleteObjectToIncident', {
            message: 'ERROR failed to delete objective for an incident',
          })
        );
      });
  };
};

export const updateObjectiveToIncident = (objective) => {
  return (dispatch, getState) => {
    dispatch({ type: ADD_INCIDENT_OBJECTIVES_REQUEST });
    return axios(config.apiGateway.updateObjectiveToIncident, {
      method: 'POST',
      data: JSON.stringify({
        incident_id: getState().app.currentIncident.id,
        group_guid: getState().app.currentlySelectedGroup.group_guid,
        objective: objective,
      }),
    })
      .then(() => {
        dispatch({ type: ADD_INCIDENT_OBJECTIVES_SUCCESS });

        dispatch(getAllObjectiveToIncident());
        dispatch(getAllObjectivesToGroup());
      })
      .catch(() => {
        dispatch(
          incidentUserError('updateObjectiveToIncident', {
            message: 'ERROR failed to update objective for an incident',
          })
        );
      });
  };
};

export const addResourceToIncident = (resource) => {
  return (dispatch, getState) => {
    dispatch({ type: ADD_INCIDENT_RESOURCES_REQUEST });
    return axios(config.apiGateway.addResourcesToIncident, {
      method: 'POST',
      data: JSON.stringify({
        incident_id: getState().app.currentIncident.id,
        resource: resource,
        group_guid: getState().app.currentlySelectedGroup.group_guid,
      }),
    })
      .then(() => {
        dispatch({ type: ADD_INCIDENT_RESOURCES_SUCCESS });
        dispatch(getAllResourcesToIncident());
        dispatch(getAllResourcesToGroup());
      })
      .catch(() => {
        dispatch(
          incidentUserError('addResourcesToIncident', {
            message: 'ERROR Adding Resource to incident',
          })
        );
      });
  };
};

export const getAllResourcesToIncident = () => {
  return (dispatch, getState) => {
    dispatch({ type: GET_ALL_INCIDENT_RESOURCES_REQUEST });
    const incident_id = getState().app.currentIncident?.id;

    if (incident_id) {
      return axios(config.apiGateway.getAllResourcesToIncident, {
        method: 'POST',
        data: JSON.stringify({
          incident_id: getState().app.currentIncident.id,
          group_guid: getState().app.currentlySelectedGroup.group_guid,
        }),
      })
        .then((response) => {
          // dispatch();
          dispatch({ type: GET_ALL_INCIDENT_RESOURCES_SUCCESS });
          dispatch({
            type: GET_ALL_INCIDENT_RESOURCES,
            payload: response.data,
          });
        })
        .catch(() => {
          dispatch(
            incidentUserError('getAllResourcesToIncident', {
              message: 'ERROR getting Resource for an incident',
            })
          );
        })
        .finally(() => {
          dispatch(endLoading());
        });
    } else {
      dispatch({ type: GET_ALL_INCIDENT_RESOURCES_SUCCESS });
      dispatch({ type: GET_ALL_INCIDENT_RESOURCES, payload: [] });
    }
  };
};

export const deleteResourceToIncident = (resource_id) => {
  return (dispatch, getState) => {
    return axios(config.apiGateway.deleteResourcesToIncident, {
      method: 'POST',
      data: JSON.stringify({
        incident_id: getState().app.currentIncident.id,
        group_guid: getState().app.currentlySelectedGroup.group_guid,
        resource_id: resource_id,
      }),
    })
      .then(() => {
        dispatch(getAllResourcesToIncident());
        dispatch(getAllResourcesToGroup());
      })
      .catch(() => {
        dispatch(endLoading());
        dispatch(
          incidentUserError('deleteResourcesToIncident', {
            message: 'ERROR failed to delete Resource for an incident',
          })
        );
      });
  };
};

export const updateResourceToIncident = (resource) => {
  return (dispatch, getState) => {
    dispatch({ type: ADD_INCIDENT_RESOURCES_REQUEST });
    return axios(config.apiGateway.updateResourcesToIncident, {
      method: 'POST',
      data: JSON.stringify({
        incident_id: getState().app.currentIncident.id,
        group_guid: getState().app.currentlySelectedGroup.group_guid,
        resource: resource,
      }),
    })
      .then(() => {
        dispatch({ type: ADD_INCIDENT_RESOURCES_SUCCESS });

        dispatch(getAllResourcesToIncident());
        dispatch(getAllResourcesToGroup());
      })
      .catch(() => {
        dispatch(
          incidentUserError('updateResourcesToIncident', {
            message: 'ERROR failed to update Resource for an incident',
          })
        );
      });
  };
};
export const getAllResourcesToGroup = () => {
  return (dispatch, getState) => {
    dispatch({ type: GET_ALL_GROUP_RESOURCES_REQUEST });
    return axios(config.apiGateway.getAllResourcesToGroup, {
      method: 'POST',
      data: JSON.stringify({
        group_guid: getState().app.currentlySelectedGroup.group_guid,
      }),
    })
      .then((response) => {
        // dispatch();
        dispatch({ type: GET_ALL_GROUP_RESOURCES, payload: response.data });
      })
      .catch(() => {
        dispatch(
          incidentUserError('getAllResourcesToGroup', {
            message: 'ERROR getting Resource for a group',
          })
        );
      });
  };
};
export const getAllObjectivesToGroup = () => {
  return (dispatch, getState) => {
    dispatch({ type: GET_ALL_GROUP_OBJECTIVES_REQUEST });
    return axios(config.apiGateway.getAllObjectivesToGroup, {
      method: 'POST',
      data: JSON.stringify({
        group_guid: getState().app.currentlySelectedGroup.group_guid,
      }),
    })
      .then((response) => {
        // dispatch();
        dispatch({ type: GET_ALL_GROUP_OBJECTIVES, payload: response.data });
      })
      .catch(() => {
        dispatch(
          incidentUserError('getAllObjectivesToGroup', {
            message: 'ERROR getting Objectives for a group',
          })
        );
      });
  };
};

export const addScheduleToIncident = (addScheduleData) => {
  return (dispatch, getState) => {
    dispatch({ type: ADD_SCHEDULE_TO_INCIDENT_REQUEST });
    return axios(config.apiGateway.addScheduleToIncident, {
      method: 'POST',
      data: JSON.stringify({
        schedule: {
          ...addScheduleData,
          incident_id: getState().app.currentIncident.id,
        },
        group_guid: getState().app.currentlySelectedGroup.group_guid,
      }),
    })
      .then(() => {
        dispatch({ type: ADD_SCHEDULE_TO_INCIDENT_SUCCESS });
        dispatch(getALlScheduleToIncident());
        dispatch(
          getUserIncidentSchedule(
            addScheduleData.user_guid,
            addScheduleData.incident_member_id
          )
        );
      })
      .catch((error) => {
        if (
          JSON.stringify(error.response.data) ===
          '{"ERROR":"BAD REQUEST exception Error: User is already occupied at this selected schedule"}'
        ) {
          dispatch({ type: ADD_SCHEDULE_TO_INCIDENT_SUCCESS });
          dispatch(
            incidentUserError('addScheduleToIncident', {
              message:
                'ERROR User is already occupied at this selected schedule',
            })
          );
          dispatch(endLoading());
        } else {
          dispatch(
            incidentUserError('addScheduleToIncident', {
              message: 'ERROR failed to add schedule to an incident',
            })
          );
          dispatch(endLoading());
        }
      });
  };
};

export const getALlScheduleToIncident = () => {
  return (dispatch, getState) => {
    dispatch({ type: GET_ALL_SCHEDULE_TO_INCIDENT_REQUEST });
    return axios(config.apiGateway.getAllScheduleToIncident, {
      method: 'POST',
      data: JSON.stringify({
        incident_id: getState().app.currentIncident.id,
        group_guid: getState().app.currentlySelectedGroup.group_guid,
      }),
    })
      .then((response) => {
        dispatch({
          type: GET_ALL_SCHEDULE_TO_INCIDENT,
          payload: response.data,
        });
      })
      .catch(() => {
        dispatch(
          incidentUserError('getAllScheduleToIncident', {
            message: 'ERROR failed to get schedule for an incident',
          })
        );
      });
  };
};

export const getUserIncidentSchedule = (user_guid, incident_member_id) => {
  return (dispatch, getState) => {
    return axios(config.apiGateway.getAllUserScheduleToIncident, {
      method: 'POST',
      data: JSON.stringify({
        user_guid: user_guid,
        incident_id: getState().app.currentIncident.id,
        group_guid: getState().app.currentlySelectedGroup.group_guid,
      }),
    })
      .then((response) => {
        dispatch({
          type: GET_USER_SCHEDULE_TO_INCIDENT,
          payload: {
            schedule: response.data,
            incident_member_id: incident_member_id,
          },
        });
      })
      .catch(() => {
        dispatch(
          incidentUserError('getAllScheduleToIncident', {
            message: "ERROR failed to get user's schedule to an incident",
          })
        );
      });
  };
};

export const deleteIncidentSchedule = (
  scheduleId,
  user_guid,
  incident_member_id
) => {
  return (dispatch, getState) => {
    return axios(config.apiGateway.deleteScheduleToIncident, {
      method: 'POST',
      data: JSON.stringify({
        scheduleId: scheduleId,
        group_guid: getState().app.currentlySelectedGroup.group_guid,
      }),
    })
      .then(() => {
        dispatch(getALlScheduleToIncident());
        dispatch(getUserIncidentSchedule(user_guid, incident_member_id));
      })
      .catch(() => {
        dispatch(
          incidentUserError('deleteScheduleToIncident', {
            message: 'ERROR failed to delete schedule to an incident',
          })
        );
      });
  };
};

function incidentUserError(errorApi, error) {
  const errorMessage =
    errorApi +
    '\n\n' +
    parseErrorMessage(error) +
    '\n\n If you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: ADD_USER_TO_INCIDENT_BY_ADMIN_ERROR,
  };
}

export const upsertOperationalPeriod = ({
  startDateTime,
  operationalPeriodInterval,
}) => {
  return (dispatch, getState) => {
    dispatch({ type: UPSERT_OPERATIONAL_PERIOD_REQUEST });
    return axios(config.apiGateway.upsertOperationalPeriod, {
      method: 'POST',
      data: JSON.stringify({
        incident_id: getState().app.currentIncident.id,
        start_date_time: startDateTime,
        operational_period_interval: operationalPeriodInterval,
      }),
    })
      .then(() => {
        dispatch({ type: UPSERT_OPERATIONAL_PERIOD_SUCCESS });
        dispatch(fetchIncidents());
      })
      .catch(() => {
        dispatch(
          incidentUserError('upsertOperationalPeriod', {
            message: 'ERROR adding or changing operational period',
          })
        );
      });
  };
};

function updateIncidentActiveStatusError(errorApi, error) {
  const errorMessage =
    errorApi +
    '\n\n' +
    parseErrorMessage(error) +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: SET_INCIDENT_ACTIVE_STATUS_ERROR,
  };
}

export const updateIncidentActiveStatus = (active) => {
  return (dispatch, getState) => {
    dispatch({ type: SET_INCIDENT_ACTIVE_STATUS_REQUEST });
    return axios(config.apiGateway.setIncidentActiveStatus, {
      method: 'POST',
      data: JSON.stringify({
        incident_id: getState().app.currentIncident.id,
        group_guid: getState().app.currentlySelectedGroup.group_guid,
        active,
      }),
    })
      .then(() => {
        dispatch({ type: SET_INCIDENT_ACTIVE_STATUS_SUCCESS });
        dispatch(fetchIncidents());
      })
      .catch(() => {
        dispatch(
          updateIncidentActiveStatusError('setIncidentActiveStatus', {
            message: 'ERROR adding or changing incident active status',
          })
        );
      });
  };
};

function bulkAddObjectivesRequest() {
  return {
    type: BULK_ADD_OBJECTIVES_REQUEST,
  };
}

function bulkAddObjectivesSuccess() {
  return {
    type: BULK_ADD_OBJECTIVES_SUCCESS,
  };
}

function bulkdAddObjectivesError(error) {
  const errorMessage =
    'bulkdAddObjectivesError\n\n' +
    ((!!error &&
      !!error.message &&
      typeof error.message === 'string' &&
      error.message) ||
      '') +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: BULK_ADD_OBJECTIVES_ERROR,
  };
}

export const bulkAddObjectives = ({
  newObjectives = [],
  allIncidentObjectives = [],
}) => {
  return (dispatch, getState) => {
    dispatch(bulkAddObjectivesRequest());
    const { group_guid } = getState().app.currentlySelectedGroup;
    const { id } = getState().app.currentIncident || {};
    axios(config.apiGateway.bulkAddObjectives, {
      method: 'POST',
      data: JSON.stringify({
        newObjectives,
        group_guid,
        incident_id: id,
      }),
    })
      .then((response) => {
        dispatch(bulkAddObjectivesSuccess());
        dispatch(
          updateIncidentObjectiveSelection(
            allIncidentObjectives.map((o) => o.id)
          )
        );
      })
      .catch((error) => {
        console.error('Error with DGPT task:', error);
        dispatch(bulkdAddObjectivesError(error));
      });
  };
};

function bulkAddActionsRequest() {
  return {
    type: BULK_ADD_ACTIONS_REQUEST,
  };
}

function bulkAddActionsSuccess() {
  return {
    type: BULK_ADD_ACTIONS_SUCCESS,
  };
}

function bulkAddActionsError(error) {
  const errorMessage =
    'bulkAddActionsError\n\n' +
    ((!!error &&
      !!error.message &&
      typeof error.message === 'string' &&
      error.message) ||
      '') +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: BULK_ADD_ACTIONS_ERROR,
  };
}

export const bulkAddActions = ({
  newActions = [],
  allIncidentActions = [],
}) => {
  return (dispatch, getState) => {
    dispatch(bulkAddActionsRequest());
    const { group_guid } = getState().app.currentlySelectedGroup;
    const { id } = getState().app.currentIncident || {};
    axios(config.apiGateway.bulkAddActions, {
      method: 'POST',
      data: JSON.stringify({
        newActions,
        group_guid,
        incident_id: id,
      }),
    })
      .then((response) => {
        dispatch(bulkAddActionsSuccess());
        dispatch(updateIncidentActionSelection(allIncidentActions));
      })
      .catch((error) => {
        console.error('Error with DGPT task:', error);
        dispatch(bulkAddActionsError(error));
      });
  };
};

function bulkAddResourcesRequest() {
  return {
    type: BULK_ADD_RESOURCES_REQUEST,
  };
}

function bulkAddResourcesSuccess() {
  return {
    type: BULK_ADD_RESOURCES_SUCCESS,
  };
}

function bulkAddResourcesError(error) {
  const errorMessage =
    'bulkAddResourcesError\n\n' +
    ((!!error &&
      !!error.message &&
      typeof error.message === 'string' &&
      error.message) ||
      '') +
    '\n\nIf you continue to experience this error, contact support@disastertech.com';
  console.error(errorMessage);
  toast.error(errorMessage, noAutoClose);
  return {
    type: BULK_ADD_RESOURCES_ERROR,
  };
}

export const bulkAddResources = ({
  newResources = [],
  allIncidentResources = [],
}) => {
  return (dispatch, getState) => {
    dispatch(bulkAddResourcesRequest());
    const { group_guid } = getState().app.currentlySelectedGroup;
    const { id } = getState().app.currentIncident || {};
    axios(config.apiGateway.bulkAddResources, {
      method: 'POST',
      data: JSON.stringify({
        newResources,
        group_guid,
        incident_id: id,
      }),
    })
      .then((response) => {
        dispatch(bulkAddResourcesSuccess());
        dispatch(updateIncidentResourceSelection(allIncidentResources));
      })
      .catch((error) => {
        console.error('Error with DGPT task:', error);
        dispatch(bulkAddResourcesError(error));
      });
  };
};
